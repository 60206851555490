import React, { useEffect, useState, useCallback, useMemo } from "react";
import { getShortText, showToast } from "../../../../services/functions";
import styles from "./UserRole.module.scss";
import {
  Grid,
  Button,
  Typography,
  Card,
  CardHeader,
  CardContent,
  TextField,
  CircularProgress,
  FormControl,
  Autocomplete,
  OutlinedInput,
  InputAdornment,
  InputLabel,
  Box,
  Tooltip,
  TablePagination,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import {
  Add,
  ExpandMore,
  Edit,
  DisabledByDefaultRounded,
  Delete,
  DoNotDisturbAltOutlined,
  DoneOutlined,
} from "@mui/icons-material";
import { Search, KeyboardArrowDown } from "@mui/icons-material";
import { useSelector, useDispatch } from "react-redux";
import CreateUserRoleModal from "./components/CreateUserRoleModal";
import {
  getPermissionRoles,
  updatePermissionRole,
  getAllOrganisations,
  deletePermissionRole,
  getOrganisation,
  getUserRolesStatusWiseCount,
} from "../../../../redux/actions";
import GenericTabsForUsers from "../../../Components/Generic/GenericFilterTabs";
import { selectStylesOverride } from "../../../../utils/util";
import CustomSelect from "../../../../components/FormUI/CustomSelect";
import EmptyPage from "../../EmptyPage";
import Actions from "../../../../utils/constants";
import DisableDeleteUserRole from "../components/DisableDeleteUserRole";
import loaderGif from "../../../../static/images/drawer/loader.gif";
import { havePermission } from "../../../../utils/permissionCheck";

const UserRole = () => {
  const dispatch = useDispatch();
  const [visibility, setVisibility] = useState(false);
  const [loader, setLoader] = useState(false);
  const [filter, setFilter] = useState({
    page: 1,
    limit: 10,
  });
  // const [pagination, setPagination] = useState({
  //   page: 1,
  //   limit: 10,
  // });
  const [actionStatus, setActionStatus] = useState(Actions.ADD);
  const { message, error } = useSelector((state) => state.common);
  const [selectedCard, setSelectedCard] = useState([]);
  const [disableDeleteRole, setDisableDeleteRole] = useState(false);
  const { OrganisationListing } = useSelector(
    (state) => state.Organisationlisting
  );
  const [more, setMore] = useState({});
  const [searchFields, setSearchFields] = useState({
    org: null,
    createOrg: null
  });
  const [updateRoleStatus, setUpdateRoleStatus] = useState(false);
  const userDetailSelector = useSelector(
    (state) => state.auth.currentUserDetail
  );
  const [deleteRoleStatus, setDeleteRoleStatus] = useState(false);
  // const userPermissionRoles = useSelector(state => state.userPermission.userPermissionRoles);
  const allPermissionRoles = useSelector(
    (state) => state.userPermission.permissionRoles
  );
  const allPermissionCount = useSelector(
    (state) => state.userPermission.permissionRoleCount
  );
  // const userId = useSelector(state => state.auth.userId);
  const userRolesCount = useSelector(
    (state) => state.userPermission.userRolesCount
  );
  const isDarkThemeEnabledSelector = useSelector(
    (state) => state.theme.themeDark
  );
  const themeType = isDarkThemeEnabledSelector
    ? require("../../../../static/styles/darktheme")
    : require("../../../../static/styles/theme");
  const { buttonClasses, inputClasses, textClasses, tableClasses } = themeType;

  const isSuperAdmin = userDetailSelector?.adminSpecificPermissions;
  // const isSuperAdmin = JSON.parse(localStorage.getItem("isSuperAdmin"));

  useEffect(() => {
    if (message && updateRoleStatus) {
      showToast(message, false);
      setUpdateRoleStatus(false);
    } else if (error && updateRoleStatus) {
      showToast(error, true);
      setUpdateRoleStatus(false);
    }
  }, [message, updateRoleStatus, error]);

  const isFilterApplied = useMemo(() => {
    const filters = Object.keys(filter);
    if (
      Object.keys(filter).some((filter) =>
        ["type", "orgId", "searchValue"].includes(filter)
      )
    ) {
      return true;
    }
    return false;
  }, [filter]);

  useEffect(() => {
    if (isSuperAdmin) {
      dispatch(getAllOrganisations());
    }
  }, [dispatch, isSuperAdmin]);

  useEffect(() => {
    if (message && deleteRoleStatus) {
      showToast(message, false);
      setDeleteRoleStatus(false);
    } else if (error && deleteRoleStatus) {
      showToast(error, true);
      setDeleteRoleStatus(false);
    }
  }, [message, deleteRoleStatus, error]);

  const getOrganisation = useCallback(
    (id) => {
      const organisation = OrganisationListing?.find((org) => {
        return org._id === id;
      });
      return organisation?.name;
    },
    [dispatch, OrganisationListing]
  );

  // useEffect(() => {
  //     if (userId) {
  //         setLoader(true)
  //         dispatch(getUserPermissionForSubUsers({ id: userId }, () => {
  //             setLoader(false)
  //         }))
  //     }
  // }, [userId, dispatch])

  const handleOpenAddNew = (data) => {
    setActionStatus(Actions.EDIT);
    setSelectedCard(data);
    setVisibility(true);
  };

  const handleDisableDeleteAction = useCallback(
    (data) => {
      setSelectedCard(data);
      setDisableDeleteRole(true);
    },
    [setSelectedCard, setDisableDeleteRole]
  );

  const addClass = (item, index) => {
    var element = document.getElementById(`custom-${index}`);
    if (element.classList.length === 4) {
      element.classList.remove(styles.cardPermissionsScroll);
      setMore({ ...more, [index]: false });
    } else {
      element.classList.add(styles.cardPermissionsScroll);
      setMore({ ...more, [index]: true });
    }
  };

  const handleSearchOrg = (empty) => {
    if (empty === true) {
      dispatch(getAllOrganisations({ selection: ["name"] }));
    } else {
      if (searchFields?.org || searchFields?.org == "")
        dispatch(
          getAllOrganisations({
            selection: ["name"],
            searchValue: searchFields.org,
          })
        );
    }
  };

  const handleSearchOrgForCreate = (empty) => {
    if (empty === true) {
      dispatch(getAllOrganisations({ selection: ["name"] }));
    } else {
      if (searchFields?.createOrg || searchFields?.createOrg == "")
        dispatch(
          getAllOrganisations({
            // selection: ["name", "isApproved"],
            searchValue: searchFields.createOrg,
          })
        );
    }
  };

  const debounceSearch = () => {
    dispatch(getPermissionRoles({ ...filter}, () => {
      setLoader(false);
    }));
  };

  useEffect(() => {
    dispatch(getUserRolesStatusWiseCount());
    setSearchFields({
      org: null,
      createOrg: null,
    });
  }, []);

  useEffect(() => {
    let timeoutId;
    setLoader(true);
    if (filter && filter?.searchValue && filter?.searchValue?.length > 3) {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(debounceSearch, 700);
      return () => {
        clearTimeout(timeoutId);
      };
    }

    if (filter && filter?.searchValue && filter?.searchValue?.length < 3) {
      setLoader(false);
      return;
    }
    if (filter || filter?.limit || filter?.page || filter.searchValue !== "") {
      dispatch(
        getPermissionRoles({...filter}, () => {
          setLoader(false);
        })
      );
    }
  }, [dispatch, filter, setLoader, filter?.limit, filter?.page]);

  const handleDisableDeleteRole = useCallback(
    (item) => {
      if (actionStatus === Actions.BLOCK || actionStatus === Actions.ACTIVE) {
        const temp = {
          id: selectedCard._id,
          isActive: !selectedCard.isActive,
          remark: item,
        };
        setUpdateRoleStatus(true);
        dispatch(
          updatePermissionRole(temp, () => {
            dispatch(getPermissionRoles({...filter}));
            setUpdateRoleStatus(false);
            dispatch(getUserRolesStatusWiseCount());
          })
        );
      } else if (actionStatus === Actions.DELETE) {
        const temp = { ids: [selectedCard._id], remark: item };
        setDeleteRoleStatus(true);
        dispatch(
          deletePermissionRole(temp, () => {
            dispatch(getPermissionRoles({...filter}));
            setDeleteRoleStatus(false);
            dispatch(getUserRolesStatusWiseCount());
          })
        );
      }
      setDisableDeleteRole(false);
      setSelectedCard([]);
    },
    [
      dispatch,
      filter,
      setDeleteRoleStatus,
      setDisableDeleteRole,
      actionStatus,
      setSelectedCard,
      selectedCard,
    ]
  );

  return (
    <>
      <Grid container>
        <Grid item sm={6} data-testid="pageTitle">
          <Typography sx={{ ...textClasses.cardTitle }}>
            Manage User Role's Access
          </Typography>
        </Grid>
        <Grid
          container
          item
          sm={6}
          justifyContent="end"
          alignItems="end"
          gap={1}
        >
          {userDetailSelector &&
            havePermission(
              userDetailSelector,
              "userRole",
              "addPermissionsRole"
            ) && (
              <Button
                data-testid="customRole"
                variant="contained"
                size="small"
                startIcon={<Add />}
                sx={{
                  ...buttonClasses.small,
                  minHeight: "36px",
                  borderColor: themeType.default.themeOrange,
                  color: buttonClasses.lynkitOrangeEmpty,
                }}
                onClick={() => {
                  setVisibility(true);
                  setActionStatus(Actions.ADD);
                }}
              >
                Create Custom Role
              </Button>
            )}
        </Grid>
      </Grid>
      <GenericTabsForUsers
        data-testid="tabs"
        filter={filter}
        setFilter={setFilter}
        isFilterApplied={isFilterApplied}
        isUserRole={true}
        count={userRolesCount}
        module={"Roles"}
      />
      <Grid container sm={12} sx={4} spacing={2} mt={1}>
        <Grid item data-testid="search">
          <FormControl
            variant="outlined"
            size="small"
            sx={selectStylesOverride(isDarkThemeEnabledSelector)}
          >
            <InputLabel
              htmlFor="outlined-adornment-password"
              sx={{ fontSize: "12px" }}
            >
              Search by role name
            </InputLabel>
            <OutlinedInput
              onChange={(e) =>
                setFilter((f) => {
                  if (e.target.value === "" || e.target === undefined) {
                    const { searchValue, ...rest } = f;
                    return {
                      ...rest,
                      page: 1
                    };
                  } else {
                    return {
                      ...f,
                      searchValue: e.target.value.trimStart(),
                      page: 1
                    };
                  }
                })
              }
              sx={{
                fontSize: "12px",
                ...selectStylesOverride,
                ...inputClasses.filterField,
                "& input::placeholder": {
                  fontSize: "12px",
                },
              }}
              value={filter.searchValue || ""}
              placeholder={"Search by role name"}
              label={"Search by role name"}
              endAdornment={
                <InputAdornment position="end">
                  <Search fontSize="small" />
                </InputAdornment>
              }
            />
          </FormControl>
        </Grid>

        <Grid item sx={4} sm={2}>
          <CustomSelect
            data-testid="roleType"
            label="Role type"
            extraClasses={inputClasses.filterField}
            value={{
              value: filter.type ?? undefined,
              label:
                [
                  { value: "admin", label: "Administrative" },
                  { value: "user", label: "Sub user" },
                ].find((dl) => dl.value == filter.type)?.label || "",
            }}
            options={[
              { value: "admin", label: "Administrative" },
              { value: "user", label: "Sub user" },
            ]}
            onChange={(event, option) => {
              setFilter((f) => {
                if (option === null || option === undefined) {
                  const { type, ...rest } = f;
                  return {
                    ...rest,
                    page: 1
                  };
                } else {
                  return {
                    ...f,
                    type: option?.value,
                    page: 1
                  };
                }
              });
            }}
            selectStylesOverride={selectStylesOverride(
              isDarkThemeEnabledSelector
            )}
          />
        </Grid>
        {isSuperAdmin && (
          <Grid item sx={4} sm={2}>
            <Box>
              <CustomSelect
                data-testid="organiation"
                label="Select Organisation"
                variant="outlined"
                extraClasses={inputClasses.filterField}
                //  size="small"
                fullWidth
                options={OrganisationListing}
                renderOption={(props, option) => (
                  <li {...props} style={{ fontSize: "13px" }}>
                    {option.name}
                  </li>
                )}
                searchValue={
                  searchFields?.org && searchFields?.org !== ""
                    ? searchFields?.org
                    : null
                }
                onSearch={handleSearchOrg}
                onInputChange={(e) => {
                  if (e?.type === "change") {
                    if (e?.target?.value === "") handleSearchOrg(true);
                    setSearchFields((prev) => ({
                      ...prev,
                      org: e?.target?.value?.trim(),
                    }));
                  }
                }}
                onChange={(event, option, reason) => {
                  if (reason === "clear") handleSearchOrg(true);
                  setFilter((f) => {
                    if (option === undefined || option === null) {
                      const { orgId, ...rest } = f;
                      return {
                        ...rest,
                        page: 1
                      };
                    } else {
                      return {
                        ...f,
                        orgId: option?._id,
                        page: 1
                      };
                    }
                  });
                }}
                labelKey="name"
                getOptionLabel={(option) => {
                  return option?.name;
                }}
                value={
                  OrganisationListing.find((dl) => dl._id === filter?.orgId) ||
                  null
                }
                sx={selectStylesOverride(isDarkThemeEnabledSelector)}
              />
            </Box>
          </Grid>
        )}
      </Grid>
      {loader ? (
        <Grid
          container
          direction={"column"}
          justifyContent={"center"}
          alignItems="center"
          xs={12}
          sx={{ p: 15 }}
        >
          <Grid item>
            <img src={loaderGif} />
          </Grid>
        </Grid>
      ) : (
        <Grid
          data-testid="card"
          container
          spacing={2}
          mt={1.5}
          sx={{ height: "70vh", overflowY: "auto" }}
        >
          {allPermissionRoles.length ? (
            <Box position="relative" width="100%" height="100%">
              <Grid container m={0} p={0}>
                {allPermissionRoles.map((item, index) => {
                  let allCategoryPermissions = [];
                  if (item?.permissions) {
                    allCategoryPermissions = Object.keys(item?.permissions)
                      ?.map((category) => {
                        const categories = Object.keys(
                          item?.permissions[category]
                        );
                        const permissions = categories
                          ?.filter((permission) => permission !== "label")
                          ?.map((permission) => {
                            return (
                              item?.permissions[category][permission]?.label ??
                              "-"
                            );
                          });
                        return permissions;
                      })
                      ?.flat();
                  }
                  const orgName = getOrganisation(item.orgId);
                  return (
                    <Grid
                      data-testid="card"
                      key={index}
                      item
                      sm={3.85}
                      sx={{ margin: "0.5vw" }}
                    >
                      <Paper elevation={3}>
                        <Card
                          key={index}
                          sx={{ boxShadow: "none", margin: "0px", ...themeType }}
                          className={styles.card}
                        >
                          <CardHeader
                            title={
                              <Grid
                                container
                                direction={"row"}
                                justifyContent={"space-between"}
                                alignItems="flex-start"
                              >
                                <Box maxWidth={"60%"}>
                                  <Tooltip
                                    arrow
                                    title={item?.name ?? "-"}
                                    placement="top"
                                  >
                                    <Typography
                                      data-testid="title"
                                      sx={{
                                        textAlign: "left",
                                        fontWeight: "bold",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        whiteSpace: "nowrap",
                                      }}
                                      variant="h6"
                                      gutterBottom
                                    >
                                      {item.name ?? "-"}
                                    </Typography>
                                  </Tooltip>
                                  {isSuperAdmin && (
                                    <Tooltip
                                      arrow
                                      title={
                                        orgName && orgName?.length > 30
                                          ? orgName
                                          : ""
                                      }
                                      placement="top"
                                    >
                                      <Typography
                                        data-testid="orgHeading"
                                        sx={{
                                          textAlign: "left",
                                          overflow: "hidden",
                                          textOverflow: "ellipsis",
                                          width: "200px",
                                          whiteSpace: "nowrap",
                                          color: "GrayText",
                                        }}
                                        variant="body2"
                                        gutterBottom
                                      >
                                        {getShortText(orgName || "-", 30)}
                                      </Typography>
                                    </Tooltip>
                                  )}
                                </Box>
                                {havePermission(
                                  userDetailSelector,
                                  "userRole",
                                  "updatePermissionsRole"
                                ) && (
                                  <Button
                                    data-testid="editBtn"
                                    variant="contained"
                                    size="small"
                                    startIcon={<Edit />}
                                    sx={{
                                      ...buttonClasses.lynkitOrangeFill,
                                      paddingTop: "5.5px",
                                      marginRight: "7px",
                                      paddingRight: "15px",
                                      paddingBottom: "5.5px",
                                      height: "30px",
                                      borderRadius: "7px",
                                      textTransform: "none",
                                    }}
                                    onClick={() => handleOpenAddNew(item)}
                                  >
                                    Edit Role
                                  </Button>
                                )}
                              </Grid>
                            }
                          />
                          <CardContent
                            className={styles.cardPermissionsScrollable}
                            id={`custom-${index}`}
                          >
                            {allCategoryPermissions.length
                              ? allCategoryPermissions.map((category) => {
                                  return (
                                    <Typography
                                      key={`${item} + "-" + ${category}-${index}`}
                                      sx={{
                                        textAlign: "left",
                                        paddingBottom: "6px",
                                        paddingLeft: "10px",
                                      }}
                                      variant="body1"
                                      data-testid="categoryName"
                                    >
                                      {category}
                                    </Typography>
                                  );
                                })
                              : ""}
                          </CardContent>

                          <CardHeader
                            data-testid="showAll"
                            title={
                              <Grid
                                container
                                direction={"row"}
                                justifyContent={"space-between"}
                                alignItems="center"
                              >
                                <Typography
                                  onClick={() => addClass("custom", index)}
                                  sx={{
                                    textAlign: "left",
                                    paddingBottom: "0px",
                                    color: "#ff7200",
                                    fontWeight: "900",
                                    paddingLeft: "10px",
                                    cursor: "pointer",
                                    visibility:
                                      allCategoryPermissions &&
                                      allCategoryPermissions.length > 6
                                        ? ""
                                        : "hidden",
                                  }}
                                  variant="body2"
                                >
                                  {((more[index] && "Collapse") || "Show All") +
                                    " (" +
                                    allCategoryPermissions.length +
                                    ")"}
                                </Typography>
                                <Grid
                                  item
                                  className={styles.actions}
                                  direction={"row"}
                                  justifyContent={"flex-end"}
                                  alignItems="center"
                                  display={"flex"}
                                >
                                  {havePermission(
                                    userDetailSelector,
                                    "userRole",
                                    "updatePermissionsRole"
                                  ) && (
                                    <Button
                                      className={styles.buttons}
                                      data-testid="block"
                                      variant="contained"
                                      size="small"
                                      startIcon={
                                        item.isActive ? (
                                          <DoNotDisturbAltOutlined />
                                        ) : (
                                          <DoneOutlined />
                                        )
                                      }
                                      sx={{
                                        ...(item.isActive
                                          ? buttonClasses.lynkitDisabled
                                          : buttonClasses.lynkitGreenFill),
                                        paddingTop: "5.5px",
                                        marginRight: "7px",
                                        paddingRight: "15px",
                                        paddingBottom: "5.5px",
                                        height: "30px",
                                        borderRadius: "7px",
                                        textTransform: "none",
                                      }}
                                      onClick={() => {
                                        setActionStatus(
                                          item.isActive === true
                                            ? Actions.BLOCK
                                            : Actions.ACTIVE
                                        );
                                        handleDisableDeleteAction(item);
                                      }}
                                    >
                                      {item.isActive === true
                                        ? "Block"
                                        : "Active"}
                                    </Button>
                                  )}
                                  {havePermission(
                                    userDetailSelector,
                                    "userRole",
                                    "deletePermissionsRole"
                                  ) && (
                                    <Button
                                      className={styles.buttons}
                                      data-testid="delete"
                                      variant="contained"
                                      size="small"
                                      startIcon={<Delete />}
                                      sx={{
                                        ...buttonClasses.lynkitDelete,
                                        paddingTop: "5.5px",
                                        marginRight: "7px",
                                        paddingRight: "15px",
                                        paddingBottom: "5.5px",
                                        height: "30px",
                                        borderRadius: "7px",
                                        textTransform: "none",
                                      }}
                                      onClick={() => {
                                        setActionStatus(Actions.DELETE);
                                        handleDisableDeleteAction(item);
                                      }}
                                    >
                                      Delete
                                    </Button>
                                  )}
                                </Grid>
                              </Grid>
                            }
                          />
                        </Card>
                      </Paper>
                    </Grid>
                  );
                })}
              </Grid>

              <TablePagination
                component="div"
                count={allPermissionCount}
                page={filter?.page - 1}
                onPageChange={(e, newPage) => {
                  setFilter((v) => ({
                    ...v,
                    page: newPage + 1,
                  }));
                }}
                rowsPerPage={filter?.limit}
                onRowsPerPageChange={(e) => {
                  setFilter((v) => ({
                    ...v,
                    page: 1,
                    limit: e.target.value,
                  }));
                }}
                sx={{
                  ...tableClasses.tableHeader,
                  ...tableClasses.tablePagination,
                  borderTop: "1px solid lightgray",
                  display: "flex",
                  justifyContent: "center",
                  position: "sticky",
                  bottom: "0px",
                  alignItems: "center",
                  mt: "0em",
                  "& .MuiTablePagination-selectLabel ": {
                    margin: "0",
                  },
                  "& .MuiTablePagination-displayedRows": {
                    margin: "0",
                  },
                }}
              />
            </Box>
          ) : (
            <EmptyPage />
          )}
        </Grid>
      )}

      {visibility && (
        <CreateUserRoleModal
          openModal={visibility}
          themeType={themeType}
          userDetails={userDetailSelector}
          action={actionStatus}
          permissionRoles={userDetailSelector.permissions}
          setAction={setActionStatus}
          selectedCard={selectedCard}
          loader={loader}
          hide={() => setVisibility(false)}
          setSelectedCard={setSelectedCard}
          selectStylesOverride={selectStylesOverride(
            isDarkThemeEnabledSelector
          )}
          isSuperAdmin={isSuperAdmin}
          OrganisationListing={OrganisationListing}
          handleSearchOrgForCreate={handleSearchOrgForCreate}
          searchFields={searchFields}
          setSearchFields={setSearchFields}
        />
      )}

      <DisableDeleteUserRole
        selectedCard={selectedCard}
        onSubmit={handleDisableDeleteRole}
        action={actionStatus}
        onHide={() => {
          setDisableDeleteRole(false);
          setSelectedCard([]);
        }}
        show={disableDeleteRole}
        selectStylesOverride={selectStylesOverride(isDarkThemeEnabledSelector)}
      />
    </>
  );
};

export default UserRole;
