import {
  Button,
  Autocomplete,
  TextField,
  Select,
  Grid,
  MenuItem,
  Paper,
  Menu,
  CardContent,
  Typography,
  FormControl,
  OutlinedInput,
  IconButton,
  InputAdornment,
  Card,
  CircularProgress,
  TablePagination,
  CardHeader,
  Skeleton,
  Box,
} from "@mui/material";
import {
  Close,
  ControlPoint,
  FilterAlt,
  KeyboardArrowDown,
  Label,
  QrCodeScanner,
  QuestionMark,
  Search,
  Sell,
  SellOutlined,
  Upload,
} from "@mui/icons-material";

import React, { useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import { useDispatch, useSelector } from "react-redux";
import { clearConsole, deleteEmptyKeys, selectStylesOverride, sendCustomEventAnalytics } from "../../../utils/util";
import history from "../../../services/history";
import TripShareModal from "./components/TripShareModal";
import DevicesDetails from "./components/DeviceDetailModal";
import TripReport from "./components/ReportModal";
import { dateTimePickerSlotProps } from "../../../utils/util";
import { DateTimePicker } from "@mui/x-date-pickers";
import AdditionalInfo from "./components/AdditionalInfo";
import TripViaComponent from "./components/TripViaComponenet";
import DocUpload from "./components/DocUpload";


import {
  getAllDeviceCategoryNames,
  getAllTrips,
  getAllUsers,
  getBoards,
  tripStatusChart,
  tripLockStatusChart,
  tripAssetStatusChart,
  getTripReportKeys,
  closeTripForcefully,
  emptyTripDetails,
  deletetTrip,
  emptyTripList,
  getAllOrganisations,
  getAllRoutesForTrips,
  flushUsersList,
  getTripsListCount,
  saveFilters,
  getTripETA
} from "../../../redux/actions";
import { main } from "./helper";
import { setTripsFilters, updateTripFlag } from "../../../redux/reducers/TripsReducer";
import loaderGif from "../../../static/images/drawer/loader.gif";
import EmptyPage from "../EmptyPage";
import FiltersWrapper from "../../Components/FiltersWrapper/FiltersWrapper";
import { borderColorLists1, borderColorLists2, borderColorLists3, graphColors } from "../../../services/constants";
import { showToast } from "../../../services/functions";
import DriverDetails from "./components/DriverDetails";
import TripClosureModal from "./components/TripClosure";
import { havePermission } from "../../../utils/permissionCheck";
import DonutChart from "../../Components/D3/DonutChart";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import { indexOf } from "lodash";
import FlightDetails from "./components/FlightDetails";

let payload = {
  page: 1,
  limit: 10,
  assetType: 'elock',
  status: 'allTrips',
};
let payloadBoard = {
  module: "trip",
  search: "",
  page: 1,
  limit: 10,
};

const tripStatusOptions = [
  { label: "All Trips", value: 'allTrips', },
  { label: "Created", value: "initiated" },
  { label: "Mapped", value: "mapped" },
  { label: "In Transit", value: "in_transit" },
  { label: "Reached", value: "reached" },
  { label: "Completed", value: "completed" },
];

const deviceStatusOptions = [
  { label: "Authorised Unsealing", value: "authorisedUnseal" },
  { label: "Unauthorised Unsealing", value: "unauthorisedUnseal" },
  { label: "Tampered", value: "tampered" },
];

export default function TripDashboard({ previousRoute }) {
  const dispatch = useDispatch();
  const location = useLocation();
  const userPermissions = useSelector((state) => state.auth?.currentUserDetail) || {};
  const [loader, setLoader] = useState(false);
  const [chartLoader, setChartLoader] = useState(false);
  const [open, setOpen] = useState(false);
  const [tripShareModal, setTripShareModal] = useState(false);
  const [d3DougnutArray, setD3DougnutArray] = useState([]);
  const [filter, setFilter] = useState({});
  const [finalFilter, setFinalFilter] = useState({ assetType: 'elock' });
  const [closureState, setClosureState] = useState({ remark: '', endTime: null })
  const [keysArray, setKeysArray] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [tripShare, setTripShare] = useState({})
  const [actionIndex, setActionIndex] = useState(null);
  const [tableIndex, setTableIndex] = useState(null);
  const [reportsModal, setReportsModal] = useState(false)
  const [driverModal, setDriverModal] = useState(false)
  const [closureModal, setClosureModal] = useState(false)
  const [devicesModal, setDevicesModal] = useState(false)
  const [tripDetails, setTripDetails] = useState({})
  const [closureDetails, setClosureDetails] = useState({})
  const [driverDetails, setDriverDetails] = useState({})
  const [closureLoader, setClosureLoader] = useState(false)
  const [deleteStatus, setDeletestatus] = useState(false)
  const [action, setAction] = useState('')
  const [reportKeysLoader, setReportKeysLoader] = useState(false);
  const [additionalModal, setAdditionalModal] = useState(false);
  const [additionalData, setAdditionalData] = useState({});
  const { fetchedAddress } = useSelector((state) => state.deviceslist);
  const [isVisible, setIsVisible] = useState(true);
  const [selectedBtn, setSelectedBtn] = useState('devices');
  const [btnIndex, setBtnIndex] = useState(null)
  const [viaModalArray, setViaModalArray] = useState([]);
  const [searchFields, setSearchFields] = React.useState({
    users: null,
    route: null
  });
  const [searchFieldsOrg, setSearchFieldsOrg] = React.useState({
    org: null,
  });
  const [hasLoaded, setHasLoaded] = React.useState(false);

  const isDarkThemeEnabledSelector = useSelector(
    (state) => state.theme.themeDark
  );
  const boardListing = useSelector(
    (state) => state.dynamicDashboard.boardsListing[0]
  );
  const tripListing = useSelector((state) => state.tripsData.tripListing);
  const closureMssg = useSelector((state) => state.tripsData.closureMessage);
  const { allFilters } = useSelector((state) => state.filters);

  const tripstatusData = useSelector((state) => state.tripsData.tripstatuschartData);
  const triplockstatusData = useSelector((state) => state.tripsData.triplockstatuschartData);
  const tripassetstatusData = useSelector((state) => state.tripsData.tripassetstatuschartData);
  const routeListing = useSelector((state) => state.tripsData.routeListForTrips);
  const totalRows = useSelector((state) => state.tripsData.totalRows);
  const paginationLoading = useSelector((state) => state.tripsData.tripPaginationLoading);
  const fetchTripsFlag = useSelector((state) => state.tripsData.fetchTripsFlag);
  const reportKeys = useSelector((state) => state.tripsData.reportKeys);
  const tripsFilters = useSelector((state) => state.tripsData.tripsFilters);
  const orgListing = useSelector((state) => state.Organisationlisting.OrganisationListing);
  const { socketInstance, connected } = useSelector((_) => _?.socket);
  const deviceTypeList = useSelector(
    (state) => state.deviceslist.allDeviceCategoryNames
  );
  const userList = useSelector((state) => state.userlist.allUsers);
  const { message, error } = useSelector((state) => state.common);
  const themeType = isDarkThemeEnabledSelector
    ? require("../../../static/styles/darktheme")
    : require("../../../static/styles/theme");
  const {
    theme,
    buttonClasses,
    cardClasses,
    inputClasses,
    textClasses,
    tableClasses,
    dividerClass,
  } = themeType;

  const dynamiHeader =
    boardListing?.boardData?.map((item, index) => item.columnName) || [];
  const tableHeader = ["#", ...dynamiHeader, "Actions"];
  const showSavedFilters = React.useMemo(() => {
    if (previousRoute) {
      if (previousRoute?.state?.from) {
        return previousRoute?.state?.from === "trip-map-page" || previousRoute?.state?.from === "create-trip-page" ? true : false;
      } else {
        return previousRoute?.pathname?.includes("/trip-map/") || previousRoute?.pathname?.includes("/trips/edit/") ? true : false;
      }
    } else {
      return false;
    }
  }, [previousRoute]);

  const handleOpenAction = (event, i) => {
    setAnchorEl(event.currentTarget);
    setActionIndex(i);
    setTableIndex(i);
  };
  const handleCloseAction = () => {
    setActionIndex(null);
    setTimeout(() => {
      setAnchorEl(null);
    }, 1);
  };

  // ------ HANDLERS ------
  // clearConsole();

  const handleFetchAllTrips = (item, from) => {
    try {
      setLoader(true);
      setChartLoader(true);
      let updatedFilter = { ...filter };

      if (item === 'clear') {
        updatedFilter = {
          ...filter,
          status: '',
          assetType: 'elock',
          orgId: '',
          userId: '',
          elockFilter: '',
          regex: '',
          route: undefined,
          fromDate: '',
          toDate: '',
          selection: keysArray && keysArray?.length > 0 ? keysArray : [],
        };
      }
      else {

        if (filter && filter.status === 'allTrips') {
          updatedFilter = {
            ...filter,
            status: '',
            selection: keysArray && keysArray?.length > 0 ? keysArray : [],
          };
        }
        else {
          updatedFilter = {
            ...filter,
            selection: keysArray && keysArray?.length > 0 ? keysArray : [],
          };
        }
      }
      if (updatedFilter) {
        dispatch(
          getAllTrips(updatedFilter, (res) => {
            if (res?.success) {
              dispatch(updateTripFlag(false));
              dispatch(tripStatusChart({ assetType: filter?.assetType ? filter?.assetType : 'elock' }))
              dispatch(tripLockStatusChart({ assetType: filter?.assetType ? filter?.assetType : 'elock' }))
              dispatch(tripAssetStatusChart({ assetType: filter?.assetType ? filter?.assetType : 'elock' }))
              dispatch(getTripsListCount(updatedFilter))
            }
            setIsVisible(true)
            setChartLoader(false);
            setBtnIndex(null);
            setLoader(false);
          }
          )
        );
      }
    } catch (e) {
      setChartLoader(false);
      setLoader(false);
    }
  };

  const handleApplyFilters = React.useCallback(() => {
    if (filter) {
      const { page, limit, ...rest } = filter;
      if (filter.page == 1 && filter.limit == 10) {
        handleFetchAllTrips();
      } else {
        setFilter({
          ...rest,
          page: 1,
          limit: 10,
        })
      }
      setFinalFilter(rest ? rest : null);
    }
  }, [filter]);

  const handleSearchUsers = (empty) => {
    if (empty === true) {
      dispatch(
        getAllUsers({
          selection: ["name", "orgName"],
          searchValue: "",
        })
      );
    } else {
      if (searchFields?.users || searchFields?.users == "")
        dispatch(
          getAllUsers({
            selection: ["name", "orgName"],
            searchValue: searchFields?.users,
          })
        );
    }
  };

  const handleSearchRoutes = (empty) => {
    if (empty === true) {
      dispatch(getAllRoutesForTrips({ "regex": "", limit: 20 }))
    } else {
      if (searchFields?.route || searchFields?.route == "")
        dispatch(getAllRoutesForTrips({ "regex": searchFields?.route, limit: 20 }))
    }
  }

  const handleSearchOrgs = (empty) => {
    if (empty === true) {
      dispatch(
        getAllOrganisations({
          selection: ["name"],
          searchValue: "",
        })
      );
    } else {
      if (searchFieldsOrg?.org || searchFieldsOrg?.org == "")
        dispatch(
          getAllOrganisations({
            selection: ["name"],
            searchValue: searchFieldsOrg?.org,
          })
        );
    }
  }

  const handleSaveFiltersForStore = () => {
    const tempFilters = filter;
    dispatch(setTripsFilters({ ...tempFilters }));
  }

  const debounceSearch = () => {
    handleFetchAllTrips();
  };

  function removeSuperAdminFilter(filters) {
    if (userPermissions && !userPermissions.adminSpecificPermissions) {
      let filteredRes = filters.filter(item => !item.superAdmin);
      return filteredRes;
    }
    return filters
  }

  const handleBtnClick = (item, index) => {
    setBtnIndex(index);
    if (item === 'status') {
      setSelectedBtn('status')
      setViaModalArray(tripListing[index])
      const tripDetail = tripListing ? tripListing[index] : null;
      if (tripDetail) {
        dispatch(
          getTripETA({
            trip_id: tripDetail?._id,
            deviceId: tripDetail?.detailsDeviceID?.deviceID,
          })
        );
      }
    }
    else if (item === 'details') {
      setSelectedBtn('details')
      setAdditionalData(tripListing[index])
    }
    else if (item === 'devices') {
      setSelectedBtn('devices')
      setTripDetails(tripListing[index])
    }
    else if (item === 'documents') {
      setSelectedBtn('documents')
    }
    else if (item === 'flight_details') {
      setSelectedBtn('flight_details')
    }

  }

  const handleSaveFilters = () => {
    if (filter) {
      const { page, limit, selection, ...rest } = filter
      const filteredObject = deleteEmptyKeys(rest);
      const payload = {
        type: "trips",
        data: { ...filteredObject },
        status: allFilters && allFilters?.tripsFilter ? true : false
      }

      dispatch(saveFilters(payload, (res) => {
        if (res && res?.success) {

        }
      }))
    }
  }

  const editTrip = () => {
    handleSaveFiltersForStore();
    history.push({
      pathname: `/dashboard/trips/edit/${tripListing[tableIndex]._id}`,
      state: { param: "ViewEdit", id: tripListing[tableIndex]._id, cdtn: 'edit' },
    });
  };

  const shareTrip = () => {
    let temp = tripListing[tableIndex]
    setTripShareModal(true)
    setTripShare(temp)
    setActionIndex(null)
  }

  const getReportFields = () => {
    if (filter?.fromDate < filter?.toDate) {
      let payload = {
        "reportType": "trip",
        "keyType": "all"
      }
      setReportKeysLoader(true)
      dispatch(
        getTripReportKeys({ ...payload }, () => {
          setReportKeysLoader(false);
        })
      );
      setReportsModal(true)
    }
    else {
      showToast('To date should be greater than From date.', true)
    }
  }

  const closeDeleteTrip = (item) => {
    if (item === 'close') {
      setAction('Close')
      setClosureDetails(tripListing[tableIndex])
      setClosureModal(true)
      setActionIndex(null)
    }
    else {
      setAction('Delete')
      setClosureDetails(tripListing[tableIndex])
      setClosureModal(true)
      setActionIndex(null)

    }
  }

  const handleSubmitFn = () => {
    sendCustomEventAnalytics("trips", "Trip Closure", "Trip Closure")
    let paylaod = {
      remark: closureState?.remark,
      endTime: closureState?.endTime,
      trip_id: tripListing[tableIndex]?._id
    }

    setClosureLoader(true);

    dispatch(
      closeTripForcefully({ ...paylaod }, (res) => {
        if (res && res?.success) {
          setClosureModal(false)
          handleFetchAllTrips()
        }
        setClosureLoader(false);
      })
    );
  }

  const handleDelete = () => {
    let paylaod = {
      trip_id: tripListing[tableIndex]?._id
    }
    setDeletestatus(true)
    dispatch(
      deletetTrip({ ...paylaod }, () => {
        setDeletestatus(false);

      })
    );
  }

  const filterDropdowns = [
    {
      key: "tripStatus",
      name: "Trip Status",
      options: tripStatusOptions || [],
      labelKey: "label",
      valueKey: "value",
      superAdmin: false,
      value: filter?.status,
      onChange: (newVal) => {
        if (newVal) {
          setFilter((prev) => ({
            ...prev,
            status: newVal?.value,
          }));
        } else {
          let temp = { ...filter };
          temp.status = "";
          setFilter(temp);
        }
      },
    },
    {
      key: "deviceType",
      name: "Device Type",
      options: deviceTypeList ?? [],
      labelKey: "label",
      valueKey: "category",
      superAdmin: false,
      value: filter?.assetType || null,
      onChange: (newVal) => {
        if (newVal) {
          setFilter((prev) => ({
            ...prev,
            assetType: newVal?.category,
          }));
        } else {
          let temp = { ...filter };
          temp.assetType = "elock";
          setFilter(temp);
        }
      },
    },
    {
      key: "elockFilter",
      name: "Device Status",
      options: deviceStatusOptions || [],
      labelKey: "label",
      superAdmin: false,
      valueKey: "value",
      value: filter?.elockFilter || null,
      onChange: (newVal) => {
        if (newVal) {
          setFilter((prev) => ({
            ...prev,
            elockFilter: newVal?.value,
          }));
        } else {
          let temp = { ...filter };
          delete temp["elockFilter"];
          setFilter(temp);
        }
      },
    },
    {
      key: "route",
      name: "Route",
      options: routeListing?.routes || [],
      labelKey: "label",
      valueKey: "value",
      superAdmin: false,
      value: filter?.route || [],
      type: 'multiSelect',
      onChange: (newVal) => {
        const { target: { value }, } = newVal;
        setFilter((prevFormState) => ({
          ...prevFormState,
          route: value,
        }));
      },
      onInputChange: (e) => {
        if (e?.target?.value === "") handleSearchRoutes(true);
        setSearchFields((prev) => ({
          ...prev,
          route: e?.target?.value?.trim(),
        }));
      },
      onClearSearchField: () => {
        setSearchFields((prev) => ({
          ...prev,
          route: null,
        }));
      },
      onSearch: handleSearchRoutes,
      searchValue:
        searchFields?.route && searchFields?.route !== ""
          ? searchFields?.route
          : null,
    },
    {
      key: "_id",
      name: "Organisation",
      options: orgListing || [],
      labelKey: "name",
      valueKey: "_id",
      superAdmin: true,
      value: filter?.orgId || null,
      onChange: (newVal) => {
        if (newVal) {
          setFilter((prev) => ({
            ...prev,
            orgId: newVal?._id,
          }));
          dispatch(getAllUsers({ orgId: newVal?._id, selection: ["name", "orgName"] }));
        } else {
          let temp = { ...filter };
          temp.orgId = "";
          temp.userId = "";
          setFilter(temp);
          dispatch(flushUsersList())
        }
      },
      searchValue:
        searchFieldsOrg?.org && searchFieldsOrg?.org !== ""
          ? searchFieldsOrg?.org
          : null,
      onSearch: handleSearchOrgs,
      onInputChange: (e) => {
        if (e?.type === "change") {
          if (e?.target?.value === "") handleSearchOrgs(true);
          setSearchFieldsOrg((prev) => ({
            ...prev,
            org: e?.target?.value?.trim(),
          }));
        }
      },
    },
    {
      key: "_id",
      name: "User",
      // options: userList || [],
      options: userList?.length > 0 ? userList.map((ele) => ({ "name": `${ele.name} - ${ele.orgName}`, "_id": ele._id })) : [],
      labelKey: "name",
      superAdmin: false,
      valueKey: "_id",
      value: filter?.userId || null,
      onChange: (newVal) => {
        if (newVal) {
          setFilter((prev) => ({
            ...prev,
            userId: newVal?._id,
          }));
        } else {
          let temp = { ...filter };
          temp.userId = "";
          setFilter(temp);
        }
      },
      searchValue:
        searchFields?.users && searchFields?.users !== ""
          ? searchFields?.users
          : null,
      onSearch: handleSearchUsers,
      onInputChange: (e) => {
        if (e?.type === "change") {
          if (e?.target?.value === "") handleSearchUsers(true);
          setSearchFields((prev) => ({
            ...prev,
            users: e?.target?.value?.trim(),
          }));
        }
      },
    }
  ];

  // ------ EFFECTS ------
  useEffect(() => {
    if (tripsFilters && showSavedFilters
      // (location.state?.from === "trip-map-page" || location.state?.from === "create-trip-page")
    ) {
      const tempFilters = tripsFilters;
      setFilter({ ...tempFilters });
      setFinalFilter({ ...tempFilters });
    } else if (allFilters && allFilters?.tripsFilter) {
      let tripsFilter = typeof allFilters?.tripsFilter === "string" ? JSON.parse(allFilters?.tripsFilter) : allFilters?.tripsFilter;
      const updatedFilters = {
        page: 1,
        limit: 10,
        ...tripsFilter
      }
      setFilter(updatedFilters);
      setFinalFilter(updatedFilters);
    } else {
      setFilter(payload);
      setFinalFilter(payload);
    }
    if (!hasLoaded) {
      dispatch(
        getBoards(payloadBoard, (keys) => {
          if (keys && keys?.length > 0) {
            setKeysArray(keys);
          } else {
            setKeysArray([]);
          }
        })
      );
      dispatch(flushUsersList());
      dispatch(emptyTripList());
      dispatch(getAllDeviceCategoryNames({ selection: ["category", "label"] }));

      dispatch(updateTripFlag(true));
      setTimeout(() => {
        dispatch(getAllRoutesForTrips({ "regex": "", limit: 20 }))
      }, 100)
      setHasLoaded(true);
    }
  }, [dispatch, allFilters, tripsFilters]);

  useEffect(() => {
    if (userPermissions && userPermissions?.adminSpecificPermissions) {
      dispatch(getAllOrganisations({ selection: ["name"] }));
    }
  }, [userPermissions]);

  useEffect(() => {
    if (keysArray !== null) handleFetchAllTrips(null, "keysArray useEffect");
  }, [keysArray]);

  useEffect(() => {
    if ((filter.page || filter.limit) && fetchTripsFlag === false)
      handleFetchAllTrips(null, "filter.page, filter.limit useEffect");
  }, [filter.page, filter.limit]);

  useEffect(() => {
    let timeoutId;

    if (filter && filter?.regex && filter?.regex?.length > 3) {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(debounceSearch, 700);
      return () => {
        clearTimeout(timeoutId);
      };
    }

    if (filter?.regex === "") {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(debounceSearch, 700);
      return () => {
        clearTimeout(timeoutId);
      };
    }

    if (filter && filter?.regex && filter?.regex?.length < 3) {
      return;
    }
  }, [filter?.regex]);

  useEffect(() => {
    const d3Arr = [];
    if (tripstatusData && Object.keys(tripstatusData).length && triplockstatusData && Object.keys(triplockstatusData).length && tripassetstatusData && Object.keys(tripassetstatusData).length && finalFilter['assetType']) {
      let tripStatusArr = [
        { label: "Created", value: tripstatusData?.tripGraphData?.initiated, color: graphColors?.pink },
        { label: "Mapped", value: tripstatusData?.tripGraphData?.mapped, color: graphColors?.yellow },
        { label: "In Transit", value: tripstatusData?.tripGraphData?.in_transit, color: graphColors?.brightGreen },
        { label: "Reached", value: tripstatusData?.tripGraphData?.reached, color: graphColors?.orange },
        { label: "Completed", value: tripstatusData?.tripGraphData?.completed, color: graphColors?.grey },
        { chartTitle: "Trip Status" }
      ]
      let lockStatusArr = [
        { label: "Tampered", value: triplockstatusData?.finalData.tampered, color: graphColors?.red },
        { label: "Locked", value: triplockstatusData?.finalData.locked, color: graphColors?.brightGreen },
        { label: "Unauthorised Unsealing", value: triplockstatusData?.finalData.unauth, color: graphColors?.orange },
        { label: "Authorised sealing", value: triplockstatusData?.finalData?.unlocked, color: graphColors?.yellow },
        { chartTitle: "Lock Status" }
      ]
      let deviceStatusArr = [
        { label: "Moving", value: tripassetstatusData[finalFilter?.assetType]?.motion, color: graphColors?.dullGreen },
        { label: "Idle", value: tripassetstatusData[finalFilter?.assetType]?.idle, color: graphColors?.yellow },
        { label: "Offline", value: tripassetstatusData[finalFilter?.assetType]?.offline, color: graphColors?.black },
        { label: "Low Battery", value: tripassetstatusData[finalFilter?.assetType]?.lowBattery, color: graphColors?.red },
        { chartTitle: "Device Status" }
      ]

      if (["assetTracker", "fixedTracker"].includes(finalFilter['assetType'])) {
        lockStatusArr = [];
        d3Arr.push([...tripStatusArr], [...deviceStatusArr])
      }
      else {
        d3Arr.push(
          [...tripStatusArr],
          [...lockStatusArr],
          [...deviceStatusArr]
        );
      }
    }
    setD3DougnutArray(d3Arr)
  }, [tripstatusData, triplockstatusData, tripassetstatusData, finalFilter])

  useEffect(() => {
    if (message && reportKeysLoader) {
      showToast(message, false);
      setReportKeysLoader(false);
    }
    if (error && reportKeysLoader) {
      setReportKeysLoader(false);
      showToast(error, true);
    }
  }, [message, error]);

  useEffect(() => {
    if (message && closureLoader) {
      showToast(message, false);
      setClosureLoader(false);
      handleFetchAllTrips(null, "msg error closure useEffect")
      setIsVisible(true)
    }
    if (error && closureLoader) {
      setClosureLoader(false);
      showToast(error, true);
    }
  }, [message, error]);

  useEffect(() => {
    if (message && deleteStatus) {
      showToast(message, false);
      setDeletestatus(false);
      setClosureLoader(false);
      setClosureModal(false)
      setIsVisible(true)
      handleFetchAllTrips(null, "msg error deleteStatus useEffect")
    }
    if (error && deleteStatus) {
      setDeletestatus(false);
      showToast(error, true);
    }
  }, [message, error]);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const scrollDiv = document.getElementById('scrollDiv');
      const scrollTable = document.getElementById('scrollTable');

      if (scrollPosition > 0 && isVisible && scrollDiv && scrollTable) {
        setIsVisible(false);
        if (scrollDiv) {
          scrollDiv.style.top = `-${scrollDiv.offsetHeight}px`;
        }
        if (scrollTable) {
          scrollTable.style.marginTop = '10';
        }
      } else if (scrollPosition === 0 && !isVisible) {
        setIsVisible(true);
        if (scrollDiv) {
          scrollDiv.style.top = '10';
        }
        if (scrollTable && scrollDiv) {
          scrollTable.style.marginTop = `${scrollDiv.offsetHeight}px`;
        }
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [isVisible]);

  useEffect(() => {
    if (userPermissions) {
      if (!userPermissions?.adminSpecificPermissions) {
        dispatch(getAllUsers({ orgId: userPermissions?.orgId, selection: ["name", "orgName"] }));
      }
    }
  }, [userPermissions]);

  React.useEffect(() => {
    if (socketInstance && connected) {
      socketInstance?.on("data", (response) => {
        if (response) {
          if (response?.data && response?.data?.tripSocketKey) {
            // call getAllTrips API
            handleFetchAllTrips();
          }
        }
      });

      return () => {
        socketInstance.off("data");
      };
    }
  }, [socketInstance, connected]);

  const tableRows = tripListing && tripListing?.map((item, index) => {

    let items = [];
    items.push(
      <Typography sx={{ ...textClasses.normalText, textAlign: "center" }}>
        {(filter?.page - 1) * filter?.limit + index + 1}
      </Typography>
    );
    let rowKeysArr = boardListing?.boardData || [];
    for (let obj of rowKeysArr) {
      let eachColStr = "";
      for (let ob of obj.group) {
        let tmpInnerStr = main(ob.fnc, ob.key, ob.label, item, index, setDevicesModal, setTripDetails, setDriverModal, setDriverDetails, dispatch, fetchedAddress, tableIndex, setTableIndex, setAdditionalModal, setAdditionalData, userPermissions, setBtnIndex, setSelectedBtn, btnIndex, handleSaveFiltersForStore);
        eachColStr += tmpInnerStr + "<br/>";
      }
      items.push(
        <Typography
          sx={{ ...textClasses.normalText, textAlign: "center" }}
          dangerouslySetInnerHTML={{ __html: eachColStr }}
        />
      );
    }

    {
      item.status !== 'completed' ?
        items.push(
          <Typography
            sx={{
              ...textClasses.normalText,
              color: themeType.default.themeOrange,
              cursor: "pointer",
            }}
            onClick={(e) => handleOpenAction(e, index)}
          >
            Action <KeyboardArrowDown />
          </Typography>
        )
        :
        items.push(
          <Typography
            sx={{
              ...textClasses.normalText,
              color: themeType.default.themeGreen,
              cursor: "pointer",
            }}

          >
            Completed
          </Typography>
        )
    }
    return items;
  });

  return (
    <FiltersWrapper
      handleDrawerToggle={() => setOpen(!open)}
      open={open}
      userPermissions={userPermissions}
      filters={removeSuperAdminFilter(filterDropdowns)}
      isDarkThemeEnabledSelector={isDarkThemeEnabledSelector}
      selectStylesOverride={selectStylesOverride(isDarkThemeEnabledSelector)}
      themeType={themeType}
      handleApplyFilters={handleApplyFilters}
      saveFilters={handleSaveFilters}
      defaultFiltersExists={allFilters && allFilters?.tripsFilter ? true : false}
      searchElement={
        <Grid container spacing={2} mt={0}>
          <Grid item md={12} ml={2} mr={2} style={{ width: '100%' }}>
            <FormControl
              variant="outlined"
              size="small"
              fullWidth
              sx={selectStylesOverride(isDarkThemeEnabledSelector)}
            >
              <OutlinedInput
                data-testid="searchelement"
                sx={{ ...inputClasses.filterField, height: "36px" }}
                placeholder="Search by Trip Id, Device Id, Vehicle No."
                value={filter?.regex || ""}
                onChange={(e) =>
                  setFilter({
                    ...filter,
                    regex: e.target.value.trimStart().replace(/\s{2,}/g, " "),
                  })
                }
                endAdornment={
                  <InputAdornment position="end">
                    <Search fontSize="small" />
                  </InputAdornment>
                }
              />
            </FormControl>
          </Grid>
        </Grid>
      }
      additionalActions={
        <Grid container>
          <Grid item md={12} ml={2} mr={2} style={{ width: '100%' }}>
            <FormControl sx={{ ...selectStylesOverride(isDarkThemeEnabledSelector), width: '100%' }}>
              <DateTimePicker
                label="From"
                value={filter?.fromDate || null}
                onChange={(date) => {
                  if (date) {
                    setFilter((prev) => ({ ...prev, fromDate: new Date(date).getTime() }))
                  }
                  else {
                    let temp = { ...filter };
                    temp.fromDate = "";
                    setFilter(temp);
                  }
                }}
                format="dd-MM-yyyy hh:mm a"
                className="customDatePicker"
                sx={{
                  width: "100%",
                  ...selectStylesOverride
                }}
                slotProps={dateTimePickerSlotProps(inputClasses, isDarkThemeEnabledSelector)}
              />
            </FormControl>
          </Grid>

          <Grid item mt={2} md={12} ml={2} mr={2} style={{ width: '100%' }}>
            <FormControl sx={{ ...selectStylesOverride(isDarkThemeEnabledSelector), width: '100%' }}>
              <DateTimePicker
                label="To"
                value={filter?.toDate || null}
                // value={new Date(scheduleData?.routeSource?.STD)}
                onChange={(date) => {
                  if (date) {
                    setFilter((prev) => ({ ...prev, toDate: new Date(date).getTime() }))
                  }
                  else {
                    let temp = { ...filter };
                    temp.toDate = "";
                    setFilter(temp);
                  }
                }}
                format="dd-MM-yyyy hh:mm a"
                className="customDatePicker"
                sx={{
                  width: "100%",
                  ...selectStylesOverride
                }}
                slotProps={dateTimePickerSlotProps(inputClasses, isDarkThemeEnabledSelector)}
              />
            </FormControl>
          </Grid>



          <Button
            sx={{
              ...buttonClasses.lynkitBlackFill,
              minHeight: "36px",
              margin: 2,
              width: '100%',
              mt: 2,
              mb: 2,
            }}
            disabled={filter.hasOwnProperty('fromDate') && filter.hasOwnProperty('toDate') ? false : true}
            onClick={() => {
              getReportFields()
            }}
          >
            Download Report
          </Button>
        </Grid>

      }
    >
      <Grid container>
        <Grid item sm={12} xs={12}>
          <Grid container>
            <Grid item sm={6} xs={4}>
              <Typography sx={{ ...textClasses.cardTitle }} data-testid="title">
                Trips
              </Typography>
            </Grid>
            <Grid
              container
              item
              sm={6}
              xs={6}
              sx={{ justifyContent: "flex-end", gap: 1 }}
            >
              {finalFilter?.assetType !== 'elock' || finalFilter['userId'] || finalFilter['status'] || finalFilter['elockFilter'] || filter["regex"]
                ?
                <Button variant="text">
                  <Typography
                    sx={{
                      ...textClasses.boldText,
                      color: themeType.default.themeOrange,
                      textDecoration: "underline",
                      cursor: "pointer",
                      textTransform: "none",
                    }}
                    onClick={() => {
                      setFilter({ ...payload, assetType: 'elock', status: 'allTrips' });
                      setFinalFilter({ assetType: 'elock' });
                      searchFields?.route && handleSearchRoutes(true)
                      setSearchFields({
                        users: null,
                        route: null,
                      });
                      handleFetchAllTrips('clear');
                    }}
                  >
                    Clear All Filter(s)
                  </Typography>
                </Button>
                : null}
              {!isVisible ?
                <Button
                  variant="contained"
                  sx={{
                    ...buttonClasses?.lynkitOrangeEmpty,
                    minHeight: "36px",
                  }}
                  onClick={() => {
                    setIsVisible(true)
                  }}
                >
                  Show Charts
                </Button>
                : null}
              {!open && (
                <Button
                  variant="contained"
                  sx={{
                    ...buttonClasses?.lynkitOrangeEmpty,
                    minHeight: "36px",
                  }}
                  onClick={() => setOpen(!open)}
                  startIcon={<FilterAlt />}
                  data-testid="filtersBtn"
                >
                  Filters
                  {finalFilter?.filter &&
                    `(${Object.keys(finalFilter?.filter)?.length})`}
                </Button>
              )}
              {/* {userPermissions &&
                havePermission(
                  userPermissions,
                  "trip",
                  "createTrip"
                ) ?
                Object.keys(userPermissions).length ? (
                  <>
                    <Button
                      variant="outlined"
                      size="small"
                      sx={{
                        ...buttonClasses.small,
                        minHeight: "36px",
                        borderColor: themeType.default.themeOrange,
                        color: buttonClasses.lynkitOrangeEmpty,
                      }}
                      onClick={() => {
                        dispatch(emptyTripDetails())
                        history.push("/dashboard/trips/create");
                      }}
                      startIcon={<AddIcon />}
                    >
                      Create Trip
                    </Button>
                  </>
                ) : null : null} */}
            </Grid>
          </Grid>
        </Grid>

        {/* <Grid container spacing={2} mt={0}>
          <Grid item sx={4} sm={3}>
            <FormControl
              variant="outlined"
              size="small"
              fullWidth
              sx={selectStylesOverride(isDarkThemeEnabledSelector)}
            >
              <OutlinedInput
                data-testid="searchelement"
                sx={{ ...inputClasses.filterField, height: "36px" }}
                placeholder="Search by Trip Id"
                value={filter?.regex || ""}
                onChange={(e) =>
                  setFilter({
                    ...filter,
                    regex: e.target.value.trimStart().replace(/\s{2,}/g, " "),
                  })
                }
                endAdornment={
                  <InputAdornment position="end">
                    <Search fontSize="small" />
                  </InputAdornment>
                }
              />
            </FormControl>
          </Grid>
        </Grid> */}

        {chartLoader ?
          <Box width="100%" justifyContent="center" alignItems="center" mt={2} display="flex" gap={2}>
            <Skeleton variant="rounded" width={"30%"} height={200} />
            <Skeleton variant="rounded" width={"30%"} height={200} />
            <Skeleton variant="rounded" width={"30%"} height={200} />
          </Box>
          :
          tripListing.length && isVisible && finalFilter?.assetType && d3DougnutArray.length ? (
            ['elock', 'assetTracker', 'fixedTracker', 'fixedELock'].includes(finalFilter?.assetType) ? (
              <Grid id="scrollDiv" style={{ position: 'relative', transition: 'top 0.3s' }} container justifyContent="center" mt={2}>
                <DonutChart data={d3DougnutArray} widthProp={4} />
              </Grid>
            ) : null
          ) : null}

        {loader ? (
          <Grid
            container
            direction={"column"}
            justifyContent={"center"}
            alignItems="center"
            item
            xs={12}
            sx={{ p: 15 }}
            data-testid="loader"
          >
            <Grid item>
              <img src={loaderGif} />
            </Grid>
          </Grid>
        ) : (
          <>
            {tableRows && tableRows?.length === 0 && !loader ? (
              <EmptyPage body="No Trips found" />
            ) : (
              <Grid id="scrollTable" container sx={{ mt: 2 }} >
                <Grid item xs={12} sm={12}>
                  <Paper
                    sx={{
                      height: '81vh',
                      overflow: "auto",
                      width: "100%",
                      display: "block",

                    }}
                  >
                    <Table
                      header={tableHeader}
                      rows={tableRows}
                      totalRows={totalRows}
                      filter={filter}
                      setFilter={setFilter}
                      themeType={themeType}
                      handleBtnClick={handleBtnClick}
                      selectedBtn={selectedBtn}
                      btnIndex={btnIndex}
                      additionalData={additionalData}
                      setAdditionalModal={setAdditionalModal}
                      setAdditionalData={setAdditionalData}
                      isDarkThemeEnabledSelector={isDarkThemeEnabledSelector}
                      tripDetails={tripDetails}
                      viaModalArray={viaModalArray}
                      userPermissions={userPermissions}
                      paginationLoading={paginationLoading}
                      tripListing={tripListing}
                    />
                  </Paper>
                </Grid>
              </Grid>
            )}
          </>
        )}
        {tripShareModal && (
          <TripShareModal
            open={tripShareModal}
            hide={() => {
              setTripShareModal(false);
            }}
            tripShare={tripShare}
            userPermissions={userPermissions}
            setTripShareModal={setTripShareModal}
          />
        )}
        {reportsModal && (
          <TripReport
            open={reportsModal}
            hide={() => {
              setReportsModal(false);
            }}
            finalFilter={finalFilter}
            setReportsModal={setReportsModal}
            reportKeys={reportKeys}

            filter={filter}
            reportKeysLoader={reportKeysLoader}
          />
        )}
        {/* {devicesModal && (
          <DevicesDetails
            open={devicesModal}
            tripDetails={tripDetails}
            hide={() => {
              setDevicesModal(false);
            }}
          />
        )} */}
        {driverModal && (
          <DriverDetails
            open={driverModal}
            driverDetails={driverDetails}
            hide={() => {
              setDriverModal(false);
            }}
          />
        )}

        {closureModal && (
          <TripClosureModal
            closureState={closureState}
            setClosureState={setClosureState}
            open={closureModal}
            loading={closureLoader}
            // loading={loader}
            action={action}
            handleDelete={handleDelete}
            closureDetails={closureDetails}
            handleSubmitFn={handleSubmitFn}
            selectStylesOverride={selectStylesOverride}
            hide={() => {
              setClosureModal(false);
            }}
          />
        )}

        {/* {additionalModal && (
          <AdditionalInfo
            open={additionalModal}
            selectStylesOverride={selectStylesOverride}
            additionalData={additionalData}
            setAdditionalModal={setAdditionalModal}
            setAdditionalData={setAdditionalData}
            hide={() => {
              setAdditionalModal(false);
            }}
          />
        )} */}





        {actionIndex != null && (
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={actionIndex != null}
            onClose={handleCloseAction}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
            sx={{
              display:
                actionIndex != null ? "block !important" : "none !important",
            }}
          >
            {userPermissions &&
              havePermission(
                userPermissions,
                "trip",
                "editTrip"
              ) ?
              tripListing[tableIndex]?.status === 'reached' || tripListing[tableIndex]?.status === 'completed' || tripListing[tableIndex]?.status === 'initiated' ? null :
                <MenuItem key={1} onClick={() => editTrip()}>
                  Edit
                </MenuItem>
              : null}

            {userPermissions && havePermission(userPermissions, "trip", "allowSharingTripTrackingLink") || havePermission(userPermissions, "trip", "allowSharingTripAlert") ?
              tripListing[tableIndex]?.status !== 'reached' && tripListing[tableIndex]?.status !== 'initiated' ?
                <MenuItem key={2} onClick={() => shareTrip()}>
                  Share
                </MenuItem>
                : null
              : null
            }

            {userPermissions && havePermission(userPermissions, "trip", "forcefullyCloseTrip") ?
              tripListing[tableIndex]?.status !== 'initiated' && tripListing[tableIndex]?.status !== 'completed' ?
                <MenuItem key={3} onClick={() => {
                  closeDeleteTrip('close')
                }}>
                  Close Forcefully
                </MenuItem> : null
              : null}

            {userPermissions && havePermission(userPermissions, "trip", "deleteTrip") ?
              tripListing[tableIndex]?.status === 'initiated' ?
                <MenuItem
                  key={4}
                  onClick={() => {
                    closeDeleteTrip('delete');
                  }}
                >
                  Delete
                </MenuItem>
                : null
              : null}
          </Menu>
        )}
      </Grid>
    </FiltersWrapper>
  );
}

export const Table = ({
  header,
  rows,
  totalRows,
  filter,
  setFilter,
  themeType,
  handleBtnClick,
  selectedBtn,
  btnIndex,
  additionalData,
  setAdditionalModal,
  setAdditionalData,
  isDarkThemeEnabledSelector,
  tripDetails,
  viaModalArray,
  userPermissions,
  paginationLoading,
  tripListing
}) => {
  const {
    theme,
    buttonClasses,
    cardClasses,
    inputClasses,
    textClasses,
    tableClasses,
  } = themeType;


  const getHeight = () => {
    if (selectedBtn === 'details') {
      return "190px"
    }
    else if (selectedBtn === 'devices') {
      return '185px'
    }
    else if (selectedBtn === 'status') {
      return '215px'
    }
    else if (selectedBtn === 'documents') {
      return '260px'
    }
    else if (selectedBtn === 'flight_details') {
      return '200px'
    }
    else {
      return '180px'
    }
  }

  return (
    <>
      <table style={{ ...tableClasses.table }}>
        <thead
          style={{
            backgroundColor: themeType.default.themeGray,
            position: "sticky",
            top: "-1px",
            zIndex: 111,
          }}
        >
          <tr>
            {header.slice(0, 2).map((head, i) => {
              return (
                <td
                  key={i}
                  style={{
                    ...textClasses.boldText,
                    ...tableClasses.tableHeader,
                    textAlign: "center",
                  }}
                >
                  {head}
                </td>
              );
            })}
            {header.slice(2, header.length).map((head, i) => {
              return (
                <td
                  key={i}
                  style={{
                    ...textClasses.boldText,
                    ...tableClasses.tableHeader,
                    textAlign: "center",
                  }}
                >
                  {head}
                </td>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {rows.length > 0 ? (
            <>
              {rows.map((row, i) => (
                <>
                  <tr data-testid="tablerow" key={i}>
                    {row.slice(0, 2).map((col, j) => {
                      return (
                        <td
                          key={j}
                          data-testid="tablecell"
                          style={{
                            textAlign: "center",
                            ...textClasses.normalText,
                            ...tableClasses.dark,
                            ...tableClasses.tableBodyClass,
                            textAlign: "center",
                          }}
                        >
                          {col}
                        </td>
                      );
                    })}
                    {row.slice(2, row.length).map((col, j) => {
                      return (
                        <td
                          key={j}
                          style={{
                            textAlign: "center",
                            ...textClasses.normalText,
                            ...tableClasses.dark,
                            ...tableClasses.tableBodyClass,
                            textAlign: "center",
                          }}
                        >
                          {col}
                        </td>
                      );
                    })}
                  </tr>
                  <tr
                    className="tripDEtailsBox"
                    data-testid="device-details-area"
                    style={{
                      borderBottom: !isDarkThemeEnabledSelector
                        ? "1.6px solid #D8D8D8"
                        : "2px solid rgb(56, 56, 56)",
                      height: getHeight(),
                      display: btnIndex === i ? "" : "none",
                    }}
                  >
                    <td colSpan="12" className="button-container">
                      <Button
                        variant="outlined"
                        size="small"
                        data-testid="linked-devices-btn"
                        onClick={() => {
                          sendCustomEventAnalytics(
                            "trips",
                            "Linked Devices",
                            "Linked Devices"
                          );
                          handleBtnClick("devices", i);
                        }}
                        sx={{
                          ...buttonClasses.small,
                          minHeight: "30px",
                          borderColor: themeType.default.themeBlack,
                          color:
                            btnIndex === i && selectedBtn === "devices"
                              ? {
                                ...buttonClasses.lynkitOrangeFill,
                                borderRadius: "8px",
                                minHeight: "34px",
                              }
                              : buttonClasses.lynkitNewBlackEmpty,
                          minWidth: "120px",
                          marginRight: "12px",
                        }}
                      >
                        Linked Devices
                      </Button>
                      {userPermissions && havePermission(userPermissions, "trip", "enableETA") ?
                        <Button
                          variant="outlined"
                          size="small"
                          data-testid="running-status-btn"
                          onClick={() => {
                            sendCustomEventAnalytics(
                              "trips",
                              "Running Status",
                              "Running Status"
                            );
                            handleBtnClick("status", i);
                          }}
                          sx={{
                            ...buttonClasses.small,
                            minHeight: "30px",
                            borderColor: themeType.default.themeBlack,
                            color:
                              btnIndex === i && selectedBtn === "status"
                                ? {
                                  ...buttonClasses.lynkitOrangeFill,
                                  borderRadius: "8px",
                                  minHeight: "34px",
                                }
                                : buttonClasses.lynkitNewBlackEmpty,
                            minWidth: "120px",
                            marginRight: "12px",
                          }}
                        >
                          Running Status
                        </Button>
                        : null}
                      <Button
                        variant="outlined"
                        size="small"
                        data-testid="trip-details-btn"
                        onClick={() => {
                          sendCustomEventAnalytics(
                            "trips",
                            "Trip Details",
                            "Trip Details"
                          );
                          handleBtnClick("details", i);
                        }}
                        sx={{
                          ...buttonClasses.small,
                          minHeight: "30px",
                          borderColor: themeType.default.themeBlack,
                          color:
                            btnIndex === i && selectedBtn === "details"
                              ? {
                                ...buttonClasses.lynkitOrangeFill,
                                borderRadius: "8px",
                                minHeight: "34px",
                              }
                              : buttonClasses.lynkitNewBlackEmpty,
                          minWidth: "120px",
                          minWidth: "120px",
                          marginRight: "12px",
                        }}
                      >
                        Trip Details
                      </Button>
                      <Button
                        variant="outlined"
                        size="small"
                        data-testid="documents-btn"
                        onClick={() => {
                          sendCustomEventAnalytics(
                            "trips",
                            "Documents",
                            "Documents"
                          );
                          handleBtnClick("documents", i);
                        }}
                        sx={{
                          ...buttonClasses.small,
                          minHeight: "30px",
                          borderColor: themeType.default.themeBlack,
                          color:
                            btnIndex === i && selectedBtn === "documents"
                              ? {
                                ...buttonClasses.lynkitOrangeFill,
                                borderRadius: "8px",
                                minHeight: "34px",
                              }
                              : buttonClasses.lynkitNewBlackEmpty,
                          minWidth: "120px",
                          marginRight: "12px",
                        }}
                      >
                        Documents
                      </Button>
                      {userPermissions && havePermission(userPermissions, "trip", "flightDetails") && (
                        <Button
                          variant="outlined"
                          size="small"
                          data-testid="documents-btn"
                          onClick={() => {
                            sendCustomEventAnalytics(
                              "trips",
                              "flight_details",
                              "flight_details"
                            );
                            handleBtnClick("flight_details", i);
                          }}
                          sx={{
                            ...buttonClasses.small,
                            minHeight: "30px",
                            borderColor: themeType.default.themeBlack,
                            color:
                              btnIndex === i && selectedBtn === "flight_details"
                                ? {
                                  ...buttonClasses.lynkitOrangeFill,
                                  borderRadius: "8px",
                                  minHeight: "34px",
                                }
                                : buttonClasses.lynkitNewBlackEmpty,
                            minWidth: "120px",
                          }}
                        >
                          Flight Details
                        </Button>
                      )}
                    </td>
                    <td colSpan="12" className="comp-container">
                      {btnIndex === i && selectedBtn === "details" ? (
                        <AdditionalInfo
                          selectStylesOverride={selectStylesOverride}
                          additionalData={additionalData}
                          setAdditionalModal={setAdditionalModal}
                          setAdditionalData={setAdditionalData}
                          index={i}
                        />
                      ) : null}

                      {btnIndex === i && selectedBtn === "devices" ? (
                        <DevicesDetails tripDetails={tripDetails} index={i} />
                      ) : null}

                      {btnIndex === i &&
                        selectedBtn === "status" && userPermissions && havePermission(userPermissions, "trip", "enableETA") ? (
                        <TripViaComponent
                          viaModalArray={viaModalArray}
                          index={i}
                        />
                      ) : null}
                      {btnIndex === i && selectedBtn === "documents" ? (
                        <DocUpload details={tripDetails} index={i} />
                      ) : null}
                      {btnIndex === i && selectedBtn === "flight_details" && userPermissions && havePermission(userPermissions, "trip", "flightDetails") ? (
                        <FlightDetails details={tripDetails} index={i} />
                      ) : null}
                    </td>
                  </tr>
                </>
              ))}
            </>
          ) : (
            <tr sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
              <td
                data-testid="nodata"
                component="th"
                scope="row"
                style={{
                  ...textClasses.normalText,
                  textAlign: "left",
                  overflowWrap: "break-word",
                }}
              ></td>
            </tr>
          )}
        </tbody>
      </table>

      {paginationLoading ? (
        <Paper
          sx={{
            px: 2,
            py: 1,
            marginBottom: "8px",
            boxShadow: "none",
            borderTop: 1,
            borderColor: "divider",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "sticky",
            bottom: "0px",
            height: "52px",
            width: "100%",
          }}
        >
          <CircularProgress size={22} />
        </Paper>
      ) : (
        <TablePagination
          component="div"
          data-testid="pagination"
          sx={{
            ...tableClasses.tableHeader,
            ...tableClasses.tablePagination,
            display: "flex",
            justifyContent: "center",
            position: "sticky",
            bottom: "0px",
            alignItems: "center",
            mt: "0em",
            "& .MuiTablePagination-selectLabel ": {
              margin: "0",
            },
            "& .MuiTablePagination-displayedRows": {
              margin: "0",
            },
          }}
          count={totalRows || 0}
          page={(filter.page || 1) - 1}
          onPageChange={(e, newPage) =>
            setFilter({ ...filter, page: newPage + 1 })
          }
          rowsPerPage={filter.limit}
          onRowsPerPageChange={(e) =>
            setFilter({ ...filter, page: 1, limit: e.target.value })
          }
        />
      )}
    </>
  );
};


