// // 429def97-2d3c-40db-9dd6-10d902c69be7
// import React, { useState, useRef, useEffect } from 'react'
// import { Card, CardHeader, CardContent, Button, Typography, Grid, Select, MenuItem, Divider } from '@mui/material'
// import { Add, Delete } from '@mui/icons-material'
// import { useDrop } from 'react-dnd';
// import GenericInputField from './GenericInputField'
// import theme, { textClasses } from '../../../static/styles/theme'
// import { useDispatch, useSelector } from 'react-redux';
// import { addField, addSection, getEntityMasterTemplates, removeField, removeSection, selectMasterTemplate } from '../../../redux/actions';
// import { getTemplateTypeName } from '../../../services/constants';
// import { v4 } from "uuid"
// import { preDefineElement } from '../../Modules/Templates/componentList';
// import { showToast } from '../../../services/functions';

// let inputCount = 0;
// let dateCount = 0;
// export default function GenericInputSection({ components, sectionName, helperText, sectionId, removable, sx }) {
//     const ref = useRef(null);
//     const dispatch = useDispatch();

//     const selectedTemplate = useSelector(state => state.canvas.selectedTemplate);
//     const selectedSubTemplate = useSelector(state => state.canvas.selectedSubTemplate);

//     const handleItemDrop = (item) => {
//             item = JSON.parse(JSON.stringify(item))

//             const uuid = v4()
//             item._id = uuid;

//             if (item.type === 'input') {
//                 item.key = `input_${inputCount}`
//                 inputCount++;
//             }
//             else if (item.type === 'date') {
//                 item.key = `date_${dateCount}`;
//                 dateCount++;
//             }
//         dispatch(addField(sectionId, [item]))
//     }

// const handleRemoveField = (index) => {
//     dispatch(removeField(sectionId, index))
// }

// const handleDeleteSection = () => {
//     dispatch(removeSection(sectionId))
// }

// const handleAddSection = () => {
//     dispatch(addSection(sectionId))
// }

// const [{ isOver }, drop] = useDrop({
//     accept: "component",
//     drop: handleItemDrop,
//     collect: (monitor) => ({
//         isOver: monitor.isOver()
//     })
// })

// drop(ref);

// return (
//     <Card sx={{ width: "100%", borderRadius: "10px", ...sx }}>
//         <CardHeader
//             sx={
//                 {
//                     backgroundColor: theme.themeGray,
//                     p: 1,
//                 }
//             }
//             title={
//                 <Typography sx={{ ...textClasses.boldText }}>
//                     {/* {
//                             ["inwardDataEntry", "ASNTemplate"].indexOf(selectedSubTemplate) < 0  ? getTemplateTypeName(selectedSubTemplate) : sectionName
//                         } */}
//                     {sectionName}
//                     <i style={{ marginLeft: "15px", fontSize: "11px", fontWeight: "400", textDecoration: "italic" }}>
//                         {helperText}
//                     </i>
//                 </Typography>
//             }
//             action={
//                 selectedSubTemplate == "catUser" &&
//                 <>
//                     {
//                         components[sectionId].status
//                             ?
//                             <Button disabled={!removable} onClick={() => handleDeleteSection()} variant='outlined' color='inherit' size='small' startIcon={<Delete />} sx={{ fontSize: "14px", p: 0, pl: 1, pr: 1, mr: 2, mt: "2px", backgroundColor: "white", textTransform: "none" }}>
//                                 Remove
//                             </Button>
//                             :
//                             <Button disabled={!removable} onClick={() => handleAddSection()} variant='outlined' color='inherit' size='small' startIcon={<Add />} sx={{ fontSize: "14px", p: 0, pl: 1, pr: 1, mr: 2, mt: "2px", backgroundColor: "white", textTransform: "none" }}>
//                                 Include
//                             </Button>
//                     }
//                 </>

//             }
//         />
//         {
//             components[sectionId].status &&
//             <CardContent>
//                 {
//                     components[sectionId].other.map((component, i) => {
//                         return (
//                             <GenericInputField
//                                 component={component}
//                                 key={i}
//                                 index={i}
//                                 removable={component.removable != null ? component.removable : true}
//                                 editable
//                                 movable
//                                 handleRemove={handleRemoveField}
//                                 sectionId={sectionId}
//                                 type="other"
//                             />
//                         )
//                     })
//                 }
//                 <Grid ref={ref} container sx={{ border: "1px dashed gray", minHeight: "40px", mt: 2, borderRadius: "5px" }} justifyContent="center" alignItems={"center"}>
//                     <Typography variant='string' color={"gray"}>Drop Here</Typography>
//                 </Grid>
//             </CardContent>
//         }
//     </Card>
// )
// }

// 429def97-2d3c-40db-9dd6-10d902c69be7
import React, { useState, useRef, useEffect } from "react";
import {
  Card,
  CardHeader,
  CardContent,
  Button,
  Typography,
  Grid,
  Select,
  MenuItem,
  Divider,
} from "@mui/material";
import { Add, Delete } from "@mui/icons-material";
import { useDrop } from "react-dnd";
import GenericInputField from "./GenericInputField";
import theme, { textClasses } from "../../../static/styles/theme";
import { useDispatch, useSelector } from "react-redux";
import {
  addField,
  addSection,
  getEntityMasterTemplates,
  removeField,
  removeSection,
  selectMasterTemplate,
} from "../../../redux/actions";
import { getTemplateTypeName } from "../../../services/constants";
import { v4 } from "uuid";
import { preDefineElement } from "../../Modules/Templates/componentList";
import { showToast } from "../../../services/functions";



let inputCount = 0;
let numberCount = 0;
let dateCount = 0;
let textareaCount = 0;
let selectCount = 0;
let checkboxCount = 0;
let radioCount = 0;
let fileCount = 0;


export default function GenericInputSection({
  components,
  sectionName,
  helperText,
  sectionId,
  removable,
  bifurcattion,
  sx,
}) {
  const ref = useRef(null);
  const dispatch = useDispatch();
  const selectedTemplate = useSelector(
    (state) => state.canvas.selectedTemplate
  );
  const selectedSubTemplate = useSelector(
    (state) => state.canvas.selectedSubTemplate
  );
  const template = useSelector((state) => state.inward.selectedTemplate);

  const isDarkThemeEnabledSelector = useSelector(
    (state) => state.theme.themeDark
  );
  const themeType = isDarkThemeEnabledSelector
    ? require("../../../static/styles/darktheme")
    : require("../../../static/styles/theme");
  const {
    theme,
    buttonClasses,
    cardClasses,
    inputClasses,
    textClasses,
    tableClasses,
    dividerClass,
  } = themeType;

  const handleItemDrop = (item) => {
    item = JSON.parse(JSON.stringify(item));
    item.required = false;

    if (selectedSubTemplate === "device") {
      item.templateType = "device";
    } else if (selectedSubTemplate === "user") {
      item.templateType = "user";
    } else if (selectedSubTemplate === "geofence") {
      item.templateType = "geofence";
    } else if (selectedSubTemplate === "route") {
      item.templateType = "route";
    }else if (selectedSubTemplate === "trip") {
      item.templateType = "trip";
    }

    if (template && (typeof template === 'object') && Object.keys(template).length > 0) {
      if (item.type === "input") {
        const totalCount = (Number(template?.countElements?.inputCount) || 0) + inputCount;
        console.log('totalCount',totalCount);
        item.key = `dynamic_input_${totalCount}`;
        item._id = `dynamic_input_${totalCount}`;
        inputCount++;
      } else if (item.type === "date") {
        const totalCount = (Number(template?.countElements?.dateCount) || 0) + dateCount;
        item.key = `dynamic_date_${totalCount}`;
        item._id = `dynamic_date_${totalCount}`;
        dateCount++;
      } else if (item.type === "number") {
        const totalCount = (Number(template?.countElements?.numberCount) || 0) + numberCount;
        item.key = `dynamic_number_${totalCount}`;
        item._id = `dynamic_number_${totalCount}`;
        numberCount++;
      } else if (item.type === "select") {
        const totalCount = (Number(template?.countElements?.selectCount) || 0) + selectCount;
        item.key = `dynamic_select_${totalCount}`;
        item._id = `dynamic_select_${totalCount}`;
        selectCount++;
      } else if (item.type === "radio") {
        const totalCount = (Number(template?.countElements?.radioCount) || 0) + radioCount;
        item.key = `dynamic_radio_${totalCount}`;
        item._id = `dynamic_radio_${totalCount}`;
        radioCount++;
      } else if (item.type === "textarea") {
        const totalCount = (Number(template?.countElements?.textareaCount) || 0) + textareaCount;
        item.key = `dynamic_textarea_${totalCount}`;
        item._id = `dynamic_textarea_${totalCount}`;
        textareaCount++;
      } else if (item.type === "file") {
        const totalCount = (Number(template?.countElements?.fileCount) || 0) + fileCount;
        item.key = `dynamic_file_${totalCount}`;
        item._id = `dynamic_file_${totalCount}`;
        fileCount++;
      } else if (item.type === "checkbox") {
        const totalCount = (Number(template?.countElements?.checkboxCount) || 0) + checkboxCount;
        item.key = `dynamic_checkbox_${totalCount}`;
        item._id = `dynamic_checkbox_${totalCount}`;
        checkboxCount++;
      }
    }

    else {
      if (item.type === "input") {
        item.key = `dynamic_input_${inputCount}`;
        item._id = `dynamic_input_${inputCount}`;
        inputCount++;
      } else if (item.type === "date") {
        item.key = `dynamic_date_${dateCount}`;
        item._id = `dynamic_date_${dateCount}`;
        dateCount++;
      } else if (item.type === "number") {
        item.key = `dynamic_number_${numberCount}`;
        item._id = `dynamic_number_${numberCount}`;
        numberCount++;
      } else if (item.type === "select") {
        item.key = `dynamic_select_${selectCount}`;
        item._id = `dynamic_select_${selectCount}`;
        selectCount++;
      } else if (item.type === "radio") {
        item.key = `dynamic_radio_${radioCount}`;
        item._id = `dynamic_radio_${radioCount}`;
        radioCount++;
      } else if (item.type === "textarea") {
        item.key = `dynamic_textarea_${textareaCount}`;
        item._id = `dynamic_textarea_${textareaCount}`;
        textareaCount++;
      } else if (item.type === "file") {
        item.key = `dynamic_file_${fileCount}`;
        item._id = `dynamic_file_${fileCount}`;
        fileCount++;
      } else if (item.type === "checkbox") {
        item.key = `dynamic_checkbox_${checkboxCount}`;
        item._id = `dynamic_checkbox_${checkboxCount}`;
        checkboxCount++;
      }
    }

    if (template && (typeof template === 'object') && Object.keys(template).length > 0) {
      let countElements = {
        inputCount: inputCount + Number(template?.countElements?.inputCount),
        numberCount: numberCount + Number(template?.countElements?.numberCount),
        dateCount: dateCount + Number(template?.countElements?.dateCount),
        textareaCount: textareaCount + Number(template?.countElements?.textareaCount),
        selectCount: selectCount + Number(template?.countElements?.selectCount),
        checkboxCount: checkboxCount + Number(template?.countElements?.checkboxCount),
        radioCount: radioCount + Number(template?.countElements?.radioCount),
        fileCount: fileCount + Number(template?.countElements?.fileCount)
      }
      dispatch(addField(sectionId, [item], countElements));
    }
    else {
      let countElements = {
        inputCount: inputCount,
        numberCount: numberCount,
        dateCount: dateCount,
        textareaCount: textareaCount,
        selectCount: selectCount,
        checkboxCount: checkboxCount,
        radioCount: radioCount,
        fileCount: fileCount
      }
      dispatch(addField(sectionId, [item], countElements));
    }



  };



  const handleRemoveField = (index) => {
    dispatch(removeField(sectionId, index));
  };

  const handleDeleteSection = () => {
    dispatch(removeSection(sectionId));
  };

  const handleAddSection = () => {
    dispatch(addSection(sectionId));
  };

  const [{ isOver }, drop] = useDrop({
    accept: "component",
    drop: handleItemDrop,
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
  });

  drop(ref);

  return (
    <Card sx={{ width: "100%", borderRadius: "10px", ...sx }}>
      <CardHeader
        sx={{
          backgroundColor: themeType.default.themeGray,
          p: 1,
        }}
        title={
          <Typography sx={{ ...textClasses.boldText }}>
            {["user", "device", "geofence", "route", "trip"].indexOf(
              selectedSubTemplate
            ) < 0
              ? getTemplateTypeName(selectedSubTemplate)
              : sectionName}
            <i
              style={{
                marginLeft: "15px",
                fontSize: "11px",
                fontWeight: "400",
                textDecoration: "italic",
              }}
            >
              {helperText}
            </i>
          </Typography>
        }
        action={
          selectedSubTemplate == "user" ||
          ("device" && (
            <>
              {bifurcattion === "others" &&
                components[sectionId].other.length ? (
                <Button
                  disabled={!removable}
                  onClick={() => handleDeleteSection()}
                  variant="outlined"
                  color="inherit"
                  size="small"
                  startIcon={<Delete />}
                  sx={{
                    fontSize: "14px",
                    p: 0,
                    pl: 1,
                    pr: 1,
                    mr: 2,
                    mt: "2px",
                    backgroundColor: "background.paper",
                    textTransform: "none",
                  }}
                >
                  Remove All
                </Button>
              ) : null}

              {/* <Button disabled={!removable} onClick={() => handleAddSection()} variant='outlined' color='inherit' size='small' startIcon={<Add />} sx={{ fontSize: "14px", p: 0, pl: 1, pr: 1, mr: 2, mt: "2px", backgroundColor: "white", textTransform: "none" }}>
                                    Include
                                </Button> */}
            </>
          ))
        }
      />
      {
        <CardContent>
          {bifurcattion == "attributes"
            ? components[sectionId].mandatory
              .filter((f) => f.templateType == selectedSubTemplate)
              .map((component, i) => {
                return (
                  <GenericInputField
                    component={component}
                    key={i}
                    index={i}
                    editable={false}
                    sectionId={sectionId}
                    isDarkThemeEnabledSelector={isDarkThemeEnabledSelector}
                    inputClasses={inputClasses}
                    type="mandatory"
                  />
                );
              })
            : null}
          {bifurcattion === "others"
            ? components[sectionId].other
              .filter((f) => f.templateType == selectedSubTemplate)
              .map((component, i) => {
                return (
                  <GenericInputField
                    component={component}
                    key={i}
                    index={i}
                    removable={
                      component.removable != null ? component.removable : true
                    }
                    editable
                    movable
                    handleRemove={handleRemoveField}
                    sectionId={sectionId}
                    isDarkThemeEnabledSelector={isDarkThemeEnabledSelector}
                    type="other"
                  />
                );
              })
            : null}

          {bifurcattion === "others" ? (
            <Grid
              ref={ref}
              container
              sx={{
                border: "1px dashed gray",
                minHeight: "40px",
                mt: 2,
                borderRadius: "5px",
              }}
              justifyContent="center"
              alignItems={"center"}
            >
              <Typography variant="string" color={"gray"}>
                Drop Here
              </Typography>
            </Grid>
          ) : (
            ""
          )}
        </CardContent>
      }
    </Card>
  );
}
