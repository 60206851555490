import { LOGS_LIST, LOGS_DOC, ALERT_LIST_For_AdminAndSubuser,EMPTY_LOGS_REPORT } from "../types";

const INIT_STATE = {
    allLogs: {},
    logDoc : {},
    alertListForAdminAndSubuser : {}
};

export default (state = INIT_STATE, action) => {
    // console.log('action redux', action)
    switch (action.type) {
        case LOGS_LIST: return { ...state, allLogs: action.payload.data, count : action.payload.count }
        case LOGS_DOC : return { ...state, logDoc: action.payload }
        case EMPTY_LOGS_REPORT : return { ...state, logDoc: {}}
        case ALERT_LIST_For_AdminAndSubuser : return { ...state, alertListForAdminAndSubuser: action.payload }
        default:
            return state;
    }
}