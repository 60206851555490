import React, { useState } from "react";
import CustomModel from "../../../Components/CustomModels/CustomModel1";
import {
    Box,
    Button,
    CircularProgress,
    Grid,
    Paper,
    MenuItem,
    Typography,
    capitalize,
} from "@mui/material";
import { Controller, useForm, useWatch } from "react-hook-form";
import CustomSelect from "../../../../components/FormUI/CustomSelect";
import { useDispatch, useSelector } from "react-redux";
import { getWebCommandLogs, getSmsCommandLogs } from "../../../../redux/actions";
import ThreeDotsLoader from "../../../Components/ThreeDots/ThreeDotsLoader";

const LogsModal = ({
    open,
    setLogsModal,
    deviceDetails,
    themeType,
    data,
    setData,
    loader,
    setLoader,
    selectStylesOverride,
}) => {
    const dispatch = useDispatch();
    const [buttonState, setButtonState] = useState('1')
    const activeStyle = {
        background: "#ff7200",
        color: "#fff",
        width: "25%",
        height: '33px',
        borderRadius: "0px",
        transition: "background 0.3s",
        "&:hover": {
            background: "#ff7200",
            color: "#fff",
        }
    }
    const inactiveStyle = {
        background: "#fff",
        color: "#ff7200",
        border: "1px solid #ff7200",
        width: "25%",
        height: '33px',
        borderRadius: "0px",
        transition: "background 0.3s",
        "&:hover": {
            background: "#fff",
            color: "#ff7200",
        }
    }
    const { tableClasses, textClasses } = themeType;
    const hide = () => {
        setLogsModal(false);
    };

    const setBtnActive = (item) => {
        if (item === '1') {
            setButtonState('1')
            setLoader(true)
            dispatch(getWebCommandLogs({ deviceId: deviceDetails?.client_id }, (res) => {
                setLoader(false)
                if (res?.success) {
                    setData(res?.data)
                }
                else{
                    setData([])
                }
            })
            );
        }
        else {
            setButtonState('2')
            setLoader(true)
            dispatch(getSmsCommandLogs({ deviceId: deviceDetails?.client_id }, (res) => {
                setLoader(false)
                if (res?.success) {
                    setData(res?.data)
                }
                else{
                    setData([])
                }
            })
            );
        }
    }

    const renderTable = (tableData) => {
        if (tableData && tableData.length) {
            return (
                <table style={{ ...tableClasses.table }}>
                    <thead style={{ backgroundColor: themeType.default.themeGray, position: 'sticky', top: -4, zIndex: 1 }}>
                        <tr>
                            <td
                                style={{
                                    ...textClasses.boldText,
                                    ...tableClasses.tableHeader,
                                    textAlign: "center",
                                }}
                            >
                                Action
                            </td>
                            <td
                                style={{
                                    ...textClasses.boldText,
                                    ...tableClasses.tableHeader,
                                    textAlign: "center",
                                }}
                            >
                                Response
                            </td>
                        </tr>
                    </thead>
                    <tbody>
                        {tableData.length > 0 ? (
                            <>
                                {tableData.map((item, index) => (
                                    <tr key={index} data-testid="tablerow">
                                        <td
                                            data-testid="tablecell"
                                            style={{
                                                textAlign: "center",
                                                ...textClasses.normalText,
                                                ...tableClasses.dark,
                                                ...tableClasses.tableBodyClass,
                                            }}
                                        >
                                            {item.key}
                                        </td>
                                        <td
                                            data-testid="tablecell"
                                            style={{
                                                textAlign: "center",
                                                ...textClasses.normalText,
                                                ...tableClasses.dark,
                                                ...tableClasses.tableBodyClass,
                                            }}
                                        >
                                            {item.value || '-'}
                                        </td>
                                    </tr>
                                ))}
                            </>
                        ) : (
                            <tr>
                                <td
                                    data-testid="nodata"
                                    component="th"
                                    scope="row"
                                    style={{
                                        ...textClasses.normalText,
                                        textAlign: "left",
                                        overflowWrap: "break-word",
                                    }}
                                >
                                    No data found.
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            );
        } else {
            return (
                <Grid sx={{ display: 'flex', justifyContent: 'center', height: '45px', alignItems: 'end' }}>
                    <Typography>No data found.</Typography>
                </Grid>
            );
        }
    };




    return (
        <CustomModel
            autoClose={false}
            show={open || false}
            onClose={() => hide()}
            minWidth={650}
            maxWidth={650}
            pb={3}
            pl={3}
            pr={3}
            maxHeight={"81vh"}
            childrenMaxHeight={"81vh"}
            Title="Command Logs"
            viewCloseIcon={open}
        >
            <Grid container my={2} rowGap={2}>
                <Grid
                    item
                    xs={12}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                >
                    <Grid item xs={12} display={'flex'} justifyContent={'center'}>
                        <Button onClick={() => setBtnActive('1')} sx={buttonState === "1" ? activeStyle : inactiveStyle}>Web Logs</Button>
                        <Button onClick={() => setBtnActive('2')} sx={buttonState === "2" ? activeStyle : inactiveStyle}>Sms Logs</Button>
                    </Grid>
                </Grid>

                {loader ?
                    <Grid item={true} data-testid='loader' container direction={"column"} justifyContent={"center"} alignItems="center" xs={12} sx={{ p: 1 }}>
                        <div className="spinner" style={{ margin: '13px' }}>
                            <div className="bounce1"></div>
                            <div className="bounce2"></div>
                            <div className="bounce3"></div>
                        </div>
                    </Grid>
                    :

                    <Grid
                        sx={{
                            height: 'auto',
                            overflow: 'auto',
                            width: "100%",
                            display: "block",
                            maxHeight: '52vh'
                        }}
                    >
                        {Array.isArray(data) ? (
                            renderTable(data)
                        ) : (
                            Object.keys(data).map((key) => (
                                <div key={key} style={{ marginBottom: '8px' }}>
                                    <span style={{ fontSize: '18px', textTransform: 'uppercase' }}>{key}</span>
                                    {renderTable(data[key])}
                                </div>
                            ))
                        )}
                    </Grid>
                }
            </Grid>
        </CustomModel>
    );
};

export default LogsModal;
