import { useEffect, useRef, useState, Fragment } from "react";
// eslint-disable-next-line import/no-webpack-loader-syntax
import mapboxgl from "!mapbox-gl";
import MapboxDraw from "@mapbox/mapbox-gl-draw";
// import { MapBoxAccessToken } from "../../../util/constant";
import tampered from "../../../../static/images/map/tampered.png";
import unlocked from "../../../../static/images/map/unlocked.png";
import moment from "moment";
import "../style.css";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import { useSelector } from "react-redux";
import { useMemo } from "react";
import { mapBoxToken } from "../../../../services/config";
import { selectMonitoringLocationData } from "../../../../redux/reducers/Monitoring";
var draw;
mapboxgl.accessToken = mapBoxToken;

const images = [
  { url: unlocked, id: "unlocked" },
  { url: tampered, id: "tampered" },
];
const Map = ({ alertType, isAreaCreated, addGeofence, removeGeofence }) => {
  var popup = new mapboxgl.Popup();
  const mapContainer = useRef(null);
  const map = useRef(null);

  const [lng, setLng] = useState(77.2914);
  const [lat, setLat] = useState(28.5626);
  const [zoom, setZoom] = useState(10);

  const toggle = () => setModal(!modal);
  const [modal, setModal] = useState(false);
  const draw = useRef(null);
  const [type, setType] = useState("");
  const [description, setDescription] = useState("");
  const [form, setForm] = useState({
    type: "highRisk",
    description: "",
    color: "#563d7c",
  });
  const [currGeofenceId, setCurrGeofenceId] = useState(null);
  const monitoringLocation = useSelector(selectMonitoringLocationData);

  useEffect(() => {
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: "mapbox://styles/mapbox/streets-v11",
      center: [lng, lat],
      zoom: zoom,
    });

    map.current.on("load", () => {
      images.forEach((img) => {
        map.current.loadImage(img.url, (error, image) => {
          if (error) throw error;

          map.current.addImage(img.id, image);
        });
      });
    });

    return () => {
      map.current.remove();
    };
  }, []);

  useEffect(() => {
    if (!map.current) return;

    if (isAreaCreated) {
      draw.current = new MapboxDraw({
        displayControlsDefault: false,
        // Select which mapbox-gl-draw control buttons to add to the map.
        controls: {
          polygon: true,
          trash: true,
          circular: true,
        },
      });
      map.current.addControl(draw.current);
      map.current.on("draw.create", function (e) {
        const id = e.features[0].id;
        // console.log(e.features, id);
        setCurrGeofenceId(id);
        toggle();
      });
      map.current.on("draw.delete", (e) => {
        // console.log(e.features);
        const id = e.features[0].id;
        removeGeofence(id);
      });
    } else {
      if (draw.current) {
        map.current.removeControl(draw.current);
      }
    }
  }, [isAreaCreated]);

  useEffect(() => {
    var condinatesArray = [];
    // console.log("monitoring effect");
    if (monitoringLocation.length) {
      map.current.setCenter([
        monitoringLocation[0].longitude,
        monitoringLocation[0].latitude,
      ]);

      monitoringLocation.forEach((elem) => {
        condinatesArray.push({
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: [elem.longitude, elem.latitude],
          },
        });
      });
      plotMarkers(monitoringLocation, condinatesArray);
    }
  }, [monitoringLocation]);

  const plotMarkers = (dataPoints, array) => {
    var colors = [
      [1, "#04B431"],
      [10, "#04B431"],
      [11, "#4264fb"],
      [50, "#4264fb"],
      [51, "#FFFF00"],
      [200, "#FFFF00"],
      [201, "#FF7200"],
      [500, "#FF7200"],
      [501, "#EE1B0C"],
    ];

    // console.log('array', array);
    if (dataPoints && dataPoints.length > 0) {
      if (typeof map.current.getLayer("points") !== "undefined") {
        map.current.removeLayer("points").removeSource("points");
      }
      // if (typeof map.current.getLayer('cluster-counts') !== 'undefined') {
      //     map.current.removeLayer('cluster-counts').removeSource('points');
      // }
      setTimeout(() => {
        if (!map.current.getSource("points")) {
          map.current.addSource("points", {
            type: "geojson",
            data: {
              type: "FeatureCollection",
              // 'features': dataPoints
              features: array,
            },
            cluster: true,
            // clusterMaxZoom: 18,
            clusterRadius: 40,
          });

          map.current.addLayer({
            id: "points",
            type: "symbol",
            source: "points",
            layout: { "icon-image": alertType, "icon-size": 0.25 },
          });

          // map.current.addLayer({
          //     id: 'cluster',
          //     type: 'circle',
          //     source: 'points',
          //     filter: ['has', 'point_count'],
          //     paint: {
          //         'circle-color': {
          //             property: 'point_count',
          //             type: 'interval',
          //             stops: colors
          //         },
          //         'circle-radius': {
          //             property: 'point_count',
          //             type: 'interval',
          //             stops: [
          //                 [0, 30],
          //                 [100, 30],
          //                 [750, 40]
          //             ]
          //         }
          //     },
          // });

          // map.current.addLayer({

          //     "id": "cluster-counts",
          //     "type": "symbol",
          //     "source": "points",
          //     "layout": {
          //         "text-field": "{point_count}",
          //         "text-font": [
          //             "DIN Offc Pro Medium",
          //             "Arial Unicode MS Bold"
          //         ],
          //         "text-size": 15,
          //     }
          // });
        }
      }, 100);
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    // console.log(name, value);
    setForm((f) => ({ ...f, [name]: value }));
  };

  const submitForm = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const currGeofence = draw.current.get(currGeofenceId);
    addGeofence({ ...currGeofence, properties: form });
    toggle();
    setCurrGeofenceId(null);
    setForm({
      type: "highRisk",
      description: "",
      color: "#563d7c",
    });
  };

  const resetModal = () => {
    toggle();
    setForm({
      type: "highRisk",
      description: "",
      color: "#563d7c",
    });
  };

  return (
    <>
      <div>
        <Card className={`card-absolute b-rad-12px`}>
          <CardBody className="p-0 m-0">
            <div
              ref={mapContainer}
              className={`map-container b-rad-12px h-80vh monitoringDashboard__map-container`}
            />

            {/* <ResizeMapIcon
                                className="monitorDashboard__map-resize-icon"
                                id="resizeMap"
                                onClick={mapHeightChange}
                            /> */}
          </CardBody>
        </Card>
      </div>
      <Modal isOpen={modal} toggle={toggle} size="md" centered>
        <ModalHeader
          color="primary"
          className="bg-primary py-2"
          toggle={toggle}
        >
          {"Details"}
        </ModalHeader>
        <ModalBody>
          <Fragment>
            <Container fluid={true} className="p-0">
              <Row>
                <Col sm="12">
                  <Card>
                    <Form className="form theme-form">
                      <CardBody className="p-0">
                        <Row>
                          <Col lg="6">
                            <FormGroup>
                              <Label htmlFor="exampleFormControlSelect3">
                                Area Type
                              </Label>
                              <Input
                                type="select"
                                name="type"
                                className="form-control digits"
                                onChange={handleChange}
                                value={form.type}
                              >
                                <option value="highRisk">High Risk</option>
                                <option value="lowRisk">Low Risk</option>
                                <option value="moderateRisk">
                                  Moderate Risk
                                </option>
                              </Input>
                            </FormGroup>
                          </Col>
                          <Col lg="6">
                            <FormGroup>
                              <Label htmlFor="exampleInputPassword2">
                                Color
                              </Label>
                              <Input
                                onChange={handleChange}
                                name="color"
                                className="form-control"
                                style={{ height: "35px" }}
                                type="color"
                                value={form.color}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <FormGroup className="mb-0">
                              <Label>{"Description (optional)"}</Label>
                              <Input
                                type="textarea"
                                className="form-control"
                                name="description"
                                rows="1"
                                onChange={handleChange}
                                value={form.description}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                      </CardBody>
                    </Form>
                  </Card>
                </Col>
              </Row>
            </Container>
          </Fragment>
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={resetModal}>
            {"Reset"}
          </Button>
          <Button color="primary" onClick={submitForm}>
            {"Submit"}
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default Map;
