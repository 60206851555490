import { Paper, TablePagination, Grid } from "@mui/material";
import React, { useEffect } from "react";
import ThreeDotsLoader from "../ThreeDots/ThreeDotsLoader";
import { getBsjCameraAlerts } from "../../../redux/actions";
import { useDispatch } from "react-redux";

const DataTable = ({
  tableColumns,
  data,
  pagination,
  pageFilterKey="page",
  limitFilterKey="limit",
  onRowChange = () => { },
  themeType,
  activeIndex,
  dynamicCols = false,
  dynamicHeaders = null,
  dynamicRows = null,
  tableactiveIndex,
  totalRecords,
  setPagination,
}) => {
  const { textClasses, tableClasses } = themeType;
  
  return (
    <Paper
      className="muitable"
      sx={{
        maxWidth: "100%",
        height: "100%",
        overflowX: "auto",
        background: tableClasses.dark.backgroundColor,
      }}
      elevation={0}
    >
      {dynamicCols && dynamicHeaders ? (
        <table style={{ ...tableClasses.table }}>
          <thead
            style={{
              backgroundColor: themeType.default.themeGray,
              position: "sticky",
              top: "-1px",
              zIndex: 1,
            }}>
            <tr>
              {dynamicHeaders?.slice(0, 2).map((head, i) => {
                return (
                  <td
                    key={i}
                    style={{
                      ...textClasses.boldText,
                      ...tableClasses.tableHeader,
                      textAlign: "center",
                    }}
                  >
                    {head}
                  </td>
                );
              })}
              {dynamicHeaders?.slice(2, dynamicHeaders.length).map((head, i) => {
                if (head !== null) {
                  return (
                    <td
                      key={i}
                      style={{
                        ...textClasses.boldText,
                        ...tableClasses.tableHeader,
                        textAlign: "center",
                      }}
                    >
                      {head}
                    </td>
                  );
                }
              })}
            </tr>
          </thead>
          <tbody>
            {dynamicRows.length > 0 ? (
              <>
                {dynamicRows.map((row, i) => (
                  <tr data-testid="tablerow" key={i} >
                    {row.slice(0, 2).map((col, j) => {
                      return (
                        <td
                          key={j}
                          data-testid="tablecell"
                          style={{
                            textAlign: "center",
                            ...textClasses.normalText,
                            ...tableClasses.dark,
                            ...tableClasses.tableBodyClass,
                            textAlign: "center",
                            // background : allDevices[i].device_status === "DISABLE" ? '1px solid #F0F0F0' :  allDevices[i].device_status === "REQUEST_FOR_REPAIRING" || allDevices[i].device_status === "UNDER_REPAIRING" || allDevices[i].device_status === "REPAIRED" ? '1px solid  #FF0000' :  '#fff'
                          }}
                        >
                          {col}
                        </td>
                      );
                    })}
                    {row.slice(2, row.length).map((col, j) => {
                      return (
                        <td
                          key={j}
                          style={{
                            textAlign: "center",
                            ...textClasses.normalText,
                            ...tableClasses.dark,
                            ...tableClasses.tableBodyClass,
                            textAlign: "center",
                            // color:'#fff',
                            // border : allDevices[i].device_status === "DISABLE" ? '1px solid #F0F0F0' :  allDevices[i].device_status === "REQUEST_FOR_REPAIRING" || allDevices[i].device_status === "UNDER_REPAIRING" || allDevices[i].device_status === "REPAIRED" ? '1px solid #FF0000' :  '#fff'
                          }}
                        >
                          {col}
                        </td>
                      );
                    })}
                  </tr>
                ))}
              </>
            ) : (
              <tr sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                <td
                  data-testid="nodata"
                  component="th"
                  scope="row"
                  style={{
                    ...textClasses.normalText,
                    textAlign: "left",
                    overflowWrap: "break-word",
                  }}
                ></td>
              </tr>
            )}
          </tbody>
        </table>
      ) : (
        <>
          {tableColumns && data && (
            <table style={{ ...tableClasses.table }}>
              <thead
                style={{
                  backgroundColor: themeType?.default?.themeGray,
                  position: "sticky",
                  top: "-1px",
                  zIndex: 1,
                }}
              >
                <tr className="roundedRow">
                  {tableColumns?.map((column, index) => {
                    return (
                      <td
                        key={index}
                        style={{
                          ...textClasses.boldText,
                          ...tableClasses.tableHeader,
                          textAlign: "center",
                          width: column?.width ?? "auto",
                        }}
                      >
                        {column?.header}
                      </td>
                    );
                  })}
                </tr>
              </thead>
              <tbody style={{ position: "relative" }}>
                {data?.length > 0 ? (
                  <>
                    {data?.map((entry, key) => (
                      <tr
                        onClick={() => onRowChange(entry)}
                        key={key}
                        style={{
                          borderBottom: "1px solid #0000002B",
                          background: key === tableactiveIndex ? "#F5F5F5" : null,
                        }}
                        data-testid="table-row"
                      >
                        {tableColumns?.map((column, index) => (
                          <td
                            key={index}
                            style={{
                              textAlign: "center",
                              padding: "8px 8px",
                              ...textClasses.normalText,
                              ...tableClasses.dark,
                              background: activeIndex === key ? "#F5F5F5" : null,
                              width: column?.width ?? "auto",
                            }}
                          >
                            {column?.header === "#" || column?.header === "Action"
                              ? column?.cell(entry, key)
                              : column?.cell(entry, key)}
                          </td>
                        ))}
                      </tr>
                    ))}
                  </>
                ) : (
                  <tr>
                    <td colSpan={tableColumns.length} style={{ textAlign: "center", padding: "20px 0" }}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "100%",
                        }}
                      >
                        No Data Found.
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          )}

        </>
      )}

      {pagination ? 
        <TablePagination
          component="div"
          count={totalRecords || 0}
          page={(pagination[pageFilterKey] || 1) - 1}
          onPageChange={(e, newPage) =>
            setPagination({ ...pagination, [pageFilterKey]: newPage + 1 })
          }
          rowsPerPage={pagination[limitFilterKey]}
          onRowsPerPageChange={(e) => {
            setPagination({ ...pagination, [pageFilterKey]: 1, [limitFilterKey]: e.target.value })
          }}
          sx={{
            ...tableClasses.tableHeader,
            ...tableClasses.tablePagination,
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "sticky",
            bottom: "0px",
            mt: "0em",
            "& .MuiTablePagination-selectLabel ": {
              margin: "0",
            },
            "& .MuiTablePagination-displayedRows": {
              margin: "0",
            },
          }}
        />
      :null}
    </Paper>
  );
};

export default DataTable;
