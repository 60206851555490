import {
    DRIVER_LISTING,
    ULIP_DATA_LIST,
    FETCH_START,
    FETCH_SUCCESS,
    FETCH_ERROR
  } from "../types";
  import axios, { geoAxios } from "../../services/Api";
  
  export const driverListing = (payload, cb) => {
    return async (dispatch) => {
      dispatch({ type : FETCH_START });
      // dispatch({ type : FLUSH_BOARD });
      await geoAxios
        .post("/listDrivers", { ...payload })
        .then(({ data }) => {
          if (!data.error) {
            callback(data);
          }
          function callback(data) {
            dispatch({ type: FETCH_SUCCESS });
            dispatch({
              type: DRIVER_LISTING,
              payload: { rows: data.data, count: data.count },
            });
            if (cb) {
              cb(data);
            }
          }
        })
        .catch(function (error) {
          dispatch({ type: FETCH_ERROR, payload: error.message });
          dispatch({ type: DRIVER_LISTING, payload: { rows: [], count: 0 } });
          console.log("Error****:", error.message);
          if (cb) {
            cb(error);
          }
        });
    };
  };

  export const getUlipData = (payload, cb) => {
    return async (dispatch) => {
      dispatch({ type : FETCH_START });
      // dispatch({ type : FLUSH_BOARD });
      await geoAxios
        .post("/getUlipData", { ...payload })
        .then(({ data }) => {
          if (!data.error) {
            callback(data);
          }
          function callback(data) {
            dispatch({ type: FETCH_SUCCESS });
            dispatch({
              type: ULIP_DATA_LIST,
              payload: { rows: data.data, count: data.count },
            });
            if (cb) {
              cb(data);
            }
          }
        })
        .catch(function (error) {
          dispatch({ type: FETCH_ERROR, payload: error.message });
          dispatch({ type: ULIP_DATA_LIST, payload: { rows: [], count: 0 } });
          console.log("Error****:", error.message);
          if (cb) {
            cb(error);
          }
        });
    };
  };


  // export const getUlipData = (payload, callback) => {
  //   return (dispatch) => {
  //     dispatch({ type: FETCH_START });
  //     geoAxios
  //       .post("/getUlipData", {...payload})
  //       .then(({ data }) => {
  //         if (data.success) {
  //           console.log('d',data);
  //           dispatch({ type: FETCH_SUCCESS, payload: data.success });
            
  //         } 
  //         else {
  //           dispatch({ type: FETCH_ERROR, payload: data.message });
  //         }
  
  //         if (callback) {
  //           callback({ success: data.success || false, message: data.message });
  //         }
  //       })
  //       .catch(function (error) {
  //         dispatch({
  //           type: FETCH_ERROR,
  //           payload: error?.response?.data?.message,
  //         });
  //         if (callback) {
  //           callback({ success: false, message: error?.response?.data?.message });
  //         }
  //         console.log("Error****:", error.message);
  //       });
  //   };
  // };