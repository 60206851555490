import { Box, Checkbox, Grid, Typography, Button, Tabs, IconButton, Tab, FormHelperText, MenuItem } from "@mui/material";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import { CustomInput } from "../../../../components/FormUI";
import { Controller, useForm } from "react-hook-form";
import TextField from '@mui/material/TextField';
import { selectStylesOverride, sendCustomEventAnalytics } from "../../../../utils/util";
import CustomSelect from "../../../../components/FormUI/CustomSelect";
import AddIcon from "@mui/icons-material/Add";
import { useJsApiLoader, GoogleMap } from "@react-google-maps/api";
import { GoogleMapsAPIKey } from "../../../../services/constants";
import { useEffect, useMemo, useState } from "react";
import { useCallback } from "react";
import { TimeField } from "@mui/x-date-pickers/TimeField";
import moment from "moment";
import { format, setHours, setMinutes } from "date-fns";
import { CustomTimeField } from "../../../../components/FormUI/CustomTimeField";
import DynamicFormRender from "../../../Components/DynamicFormRender";
import SourceImg from "../../../../static/images/routes/source_icon.svg";
import ViaPointImg from "../../../../static/images/routes/viapoint_icon.svg";
import DestinationImg from "../../../../static/images/routes/destination_icon.svg";
import IconWithArrow from "./IconWithArrow";
import { havePermission } from "../../../../utils/permissionCheck";
import { getAllGeofences, currentUserDetails, userDetails, getOrganisation, getAllUsers, flushUsersList, flushGeofenceList } from "../../../../redux/actions";
import { AddCircle, DeleteOutline } from "@mui/icons-material";
import CustomSubmitBtn from "../../../../components/FormUI/CustomSubmitBtn";

const NewRouteForm = ({
    addMore,
    batterPercentageOptions,
    control,
    dayOptions,
    errors,
    setValue,
    handleAddMore,
    handleCancel,
    isSuperAdmin,
    // noGeofenceMessage,
    schedules,
    selectStylesOverride,
    setSchedules,
    themeType,
    templateExistence,
    register,
    reset,
    getDirectionsData,
    isDarkThemeEnabledSelector,
    routeLegsArr,
    activePath,
    setActivePath,
    removePath,
    setSearchObject,
    sourceGeofenceList,
    handleSearchGeofence,
    geofenceList,
    searchObject,
    destinationGeofenceList,
    routePayload,
    setRoutePayload,
    viaPointsSearchObject,
    setViaPointsSearchObject,
    viaPointsDropdownGeofenceList,
    getDistanceFromDb,
    calcTime,
    convertHHMMToDate,
    calcSpeed,
    setPlotByGoogle,
    addNewPath,
    isEditRoute,
    handleSubmit,
    onSubmit,
    handleEdit,
    user,
    setUser,
    plotOnMap,
    createLoader,
    updateLoader,
    routeDetails,
    orgListing,
    userList,
    handleOrganisationSearch,
    handleUserSearch,
    setOrgListing,
    setUserList,
    clearMap
}) => {
    const { buttonClasses, inputClasses, textClasses } = themeType;
    const dispatch = useDispatch();
    const [scheduleCustomDay, setScheduleCustomDay] = useState({});
    const [scheduleDestinationCustomDay, setScheduleDestinationCustomDay] = useState({});
    const [tabValue, setTabValue] = useState(0);
    const userPermissions = useSelector((state) => state.auth?.currentUserDetail) || {};
    const [haltAtViaPoints, setHaltAtViaPoints] = useState([]);


    const addViaPointFunction = () => {
        let temp = { ...routePayload }
        temp.viaPoints.push(
            {
                "_id": "",
                "itemName": "",
                "speed": 40,
                "etaTime": "",
                "totalDistance": "",
                "centerCoordinates": ""
            }
        )
        setRoutePayload(temp)
    }
    const handleAddSchedule = useCallback(() => {
        setSchedules((prev) => {
            const newSchedule = {
                key: prev.length,
            };
            return [...prev, newSchedule];
        });
        setTabValue((prev) => prev + 1);
    }, [setSchedules, setTabValue]);

    function a11yProps(index) {
        return {
            id: `full-width-tab-${index}`,
            "aria-controls": `full-width-tabpanel-${index}`,
        };
    }

    const handleScheduling = useCallback((e) => {
        if (e.target.checked) {
            setSchedules((prev) => {
                const newSchedule = {
                    key: prev.length,
                };
                return [...prev, newSchedule];
            });
            setTabValue(0);
        }
        else {
            setSchedules([]);
        }
    },
        [setSchedules, setTabValue]
    );

    const handleOnChange = (newVal, item, index) => {
        let temp = { ...routePayload }
        if (item === 'name') {
            temp.routeName = newVal.target.value
        }
        else if (item === 'description') {
            temp.description = newVal.target.value
        }
        else if (item === 'battery') {
            if (newVal) {
                temp.batteryPercentage = newVal?.value
            }
            else {
                temp.batteryPercentage = ''
            }

        }
        else if (item === 'srcAddress') {
            if (newVal) {
                temp.routeSource._id = newVal?._id
                temp.routeSource.name = newVal?.name
                temp.routeSource.centerCoordinates = newVal?.centerCoordinates
                getDirectionsData('src', null);
                setPlotByGoogle(true);
                plotOnMap()
            }
            else {
                clearMap()
                temp.routeSource._id = ''
                temp.routeSource.name = ''
                temp.routeSource.centerCoordinates = ''
            }

        }
        else if (item === 'viaAddress') {
            if (newVal) {
                temp.viaPoints[index]._id = newVal?._id
                temp.viaPoints[index].itemName = newVal?.name
                temp.viaPoints[index].centerCoordinates = newVal?.centerCoordinates
                getDirectionsData('via', index);
                setPlotByGoogle(true);
                plotOnMap()
            }
            else {
                temp.viaPoints[index]._id = ''
                temp.viaPoints[index].itemName = ''
                temp.viaPoints[index].centerCoordinates = ''
                temp.viaPoints[index].totalDistance = ''
                temp.viaPoints[index].etaTime = ''
                temp.viaPoints[index].speed = 40
                setTimeout(() => {
                    setPlotByGoogle(true);
                    plotOnMap()
                }, 50)
            }
        }
        else if (item === 'destAddress') {
            if (newVal) {
                temp.routeDestination._id = newVal?._id
                temp.routeDestination.name = newVal?.name
                temp.routeDestination.centerCoordinates = newVal?.centerCoordinates
                getDirectionsData('dest', null);
                setPlotByGoogle(true);
                plotOnMap()
            }
            else {
                clearMap()
                temp.routeDestination._id = ''
                temp.routeDestination.name = ''
                temp.routeDestination.totalDistance = ''
                temp.routeDestination.centerCoordinates = ''
                temp.routeDestination.etaTime = ''
                temp.routeDestination.speed = 40
            }
        }
        setRoutePayload(temp)
    }

    const deleteViapoint = async (index) => {
        setPlotByGoogle(true);
        let temp = { ...routePayload }
        let src = routePayload?.routeSource
        let via = routePayload?.viaPoints
        let destination = routePayload?.routeDestination


        temp.viaPoints.splice(index, 1)
        setRoutePayload(temp)

        console.log('routePayload---',index , routePayload?.viaPoints?.length - 1)

        if (routePayload?.viaPoints?.length === 1) {
            if (via[0]?._id) {
                let sourceObj = {
                    _id: src?._id,
                    srcName: src?.name,
                    srcLATLNG: src && src?.centerCoordinates && src?.centerCoordinates[0] && src?.centerCoordinates[0][0] ? src?.centerCoordinates[0][0]?.join(",") : '',
                };
                let destObj = {
                    _id: via[0]?._id,
                    desName: via[0]?.itemName,
                    desLATLNG: via && via[0]?.centerCoordinates && via[0]?.centerCoordinates[0] && via[0]?.centerCoordinates[0][0] ? via[0]?.centerCoordinates[0][0]?.join(",") : '',
                };

                let payload = {
                    sourceObj: sourceObj,
                    destinationObj: destObj,
                    // userId: null,
                };
                let res = await getDistanceFromDb(payload)
                if (res) {
                    temp.viaPoints[0].totalDistance = res?.data?.distance || res;
                    let time = calcTime(res?.data?.distance || res, temp?.viaPoints[0]?.speed)
                    temp.viaPoints[0].etaTime = convertHHMMToDate(time)
                }
            }
            if (via[0]?._id && destination?._id) {
                let sourceObj = {
                    _id: via[0]?._id,
                    srcName: via[0]?.itemName,
                    srcLATLNG: via && via[0]?.centerCoordinates && via[0]?.centerCoordinates[0] && via[0]?.centerCoordinates[0][0] ? via[0]?.centerCoordinates[0][0]?.join(",") : '',
                };
                let destObj = {
                    _id: destination?._id,
                    desName: destination?.name,
                    desLATLNG: destination && destination?.centerCoordinates && destination?.centerCoordinates[0] && destination?.centerCoordinates[0][0] ? destination?.centerCoordinates[0][0]?.join(",") : '',
                };

                let payload2 = {
                    sourceObj: sourceObj,
                    destinationObj: destObj,
                    // userId: null,
                };
                let res = await getDistanceFromDb(payload2)
                if (res) {
                    temp.routeDestination.totalDistance = res?.data?.distance || res;
                    let time = calcTime(res?.data?.distance || res, temp?.routeDestination?.speed)
                    temp.routeDestination.etaTime = convertHHMMToDate(time)
                }
            }
        }
        else if (routePayload?.viaPoints?.length > 1) {
            if (index === 0) {
                let sourceObj = {
                    _id: src?._id,
                    srcName: src?.name,
                    srcLATLNG: src && src?.centerCoordinates && src?.centerCoordinates[0] && src?.centerCoordinates[0][0] ? src?.centerCoordinates[0][0]?.join(",") : '',
                };
                let destObj = {
                    _id: via[0]?._id,
                    desName: via[0]?.itemName,
                    desLATLNG: via && via[0]?.centerCoordinates && via[0]?.centerCoordinates[0] && via[0]?.centerCoordinates[0][0] ? via[0]?.centerCoordinates[0][0]?.join(",") : '',
                };

                let payload = {
                    sourceObj: sourceObj,
                    destinationObj: destObj,
                    // userId: null,
                };
                let res = await getDistanceFromDb(payload)
                if (res) {
                    temp.viaPoints[0].totalDistance = res?.data?.distance || res;
                    let time = calcTime(res?.data?.distance || res, temp?.viaPoints[0]?.speed)
                    temp.viaPoints[0].etaTime = convertHHMMToDate(time)
                }
            }
            else if (index === routePayload?.viaPoints?.length ) {
                let sourceObj = {
                    _id: via[index - 1]?._id,
                    srcName: via[index - 1]?.itemName,
                    srcLATLNG: via && via[index - 1]?.centerCoordinates && via[index - 1]?.centerCoordinates[0] && via[index - 1]?.centerCoordinates[0][0] ? via[index - 1]?.centerCoordinates[0][0]?.join(",") : '',
                };
                let destObj = {
                    _id: destination?._id,
                    desName: destination?.name,
                    desLATLNG: destination && destination?.centerCoordinates && destination?.centerCoordinates[0] && destination?.centerCoordinates[0][0] ? destination?.centerCoordinates[0][0]?.join(",") : '',
                };

                let payload = {
                    sourceObj: sourceObj,
                    destinationObj: destObj,
                    // userId: null,
                };
                let res = await getDistanceFromDb(payload)
                if (res) {
                    temp.routeDestination.totalDistance = res?.data?.distance || res;
                    let time = calcTime(res?.data?.distance || res, temp?.routeDestination?.speed)
                    temp.routeDestination.etaTime = convertHHMMToDate(time)
                }
            }
            else {
                console.log('index-------',index,src,via)
                let sourceObj = {
                    _id: via[index - 1]?._id,
                    srcName: via[index - 1]?.itemName,
                    srcLATLNG: via && via[index - 1]?.centerCoordinates && via[index - 1]?.centerCoordinates[0] && via[index - 1]?.centerCoordinates[0][0] ? via[index - 1]?.centerCoordinates[0][0]?.join(",") : '',
                };
                let destObj = {
                    _id: via[index]?._id,
                    desName: via[index]?.itemName,
                    desLATLNG: via && via[index]?.centerCoordinates && via[index]?.centerCoordinates[0] && via[index]?.centerCoordinates[0][0] ? via[index]?.centerCoordinates[0][0]?.join(",") : '',
                };

                let payload = {
                    sourceObj: sourceObj,
                    destinationObj: destObj,
                    // userId: null,
                };
                let res = await getDistanceFromDb(payload)
                if (res) {
                    temp.viaPoints[index].totalDistance = res?.data?.distance || res;

                    let time = calcTime(res?.data?.distance || res, temp?.viaPoints[index]?.speed)
                    temp.viaPoints[index].etaTime = convertHHMMToDate(time)
                }
            }
        }
        else {
            let sourceObj = {
                _id: src?._id,
                srcName: src?.name,
                srcLATLNG: src && src?.centerCoordinates && src?.centerCoordinates[0] && src?.centerCoordinates[0][0] ? src?.centerCoordinates[0][0]?.join(",") : '',
            };
            let destObj = {
                _id: destination?._id,
                desName: destination?.name,
                desLATLNG: destination && destination?.centerCoordinates && destination?.centerCoordinates[0] && destination?.centerCoordinates[0][0] ? destination?.centerCoordinates[0][0]?.join(",") : '',
            };

            let payload = {
                sourceObj: sourceObj,
                destinationObj: destObj,
                // userId: null,
            };
            let res = await getDistanceFromDb(payload)
            if (res) {
                temp.routeDestination.totalDistance = res?.data?.distance || res;
                let time = calcTime(res?.data?.distance || res, temp?.routeDestination?.speed)
                temp.routeDestination.etaTime = convertHHMMToDate(time)
            }
        }


        let tempSchedules = [...schedules];
        tempSchedules[0]?.scheduleVia?.splice(index, 1);
        setSchedules(tempSchedules);


        setTimeout(() => {
            plotOnMap()
        }, 20);

    }



    function calculateHalt(arrivalTime, departureTime, data) {
        // Parse the arrival and departure times into Date objects
        // console.log('run calculateHalt', data);

        const firstDate = () => {
            if (data.dayArrival === "sameDay") {
                const originalDate = new Date();
                const [hours, minutes] = data.STA.split(":").map(Number);
                const newDate = new Date(originalDate);
                // console.log('1st-hour-min', hours, minutes)
                newDate.setHours(hours, minutes, 0, 0);
                return newDate;
            } else if (data.dayArrival === "nextDay") {
                const originalDate = new Date();
                const nextDate = new Date(originalDate);
                nextDate.setDate(originalDate.getDate() + 1);
                const [hours, minutes] = data.STA.split(":").map(Number);
                nextDate.setHours(hours, minutes, 0, 0);
                return nextDate;
            } else {
                const originalDate = new Date();
                const nextDate = new Date(originalDate);
                nextDate.setDate(originalDate.getDate() + Number(data.dayArrival - 1));
                const [hours, minutes] = data.STA.split(":").map(Number);
                nextDate.setHours(hours, minutes, 0, 0);
                return nextDate;
            }
        };

        const secondDate = () => {
            if (data.dayDeparture === "sameDay") {
                if (data.dayArrival === "sameDay") {
                    const originalDate = new Date();
                    const [hours, minutes] = data.STD.split(":").map(Number);
                    // console.log('2st-hour-min', hours, minutes)
                    const newDate = new Date(originalDate);
                    newDate.setHours(hours, minutes, 0, 0);
                    return newDate;
                } else if (data.dayArrival === "nextDay") {
                    const originalDate = new Date();
                    const nextDate = new Date(originalDate);
                    nextDate.setDate(originalDate.getDate() + 1);
                    const [hours, minutes] = data.STD.split(":").map(Number);
                    nextDate.setHours(hours, minutes, 0, 0);
                    return nextDate;
                } else {
                    const originalDate = new Date();
                    const nextDate = new Date(originalDate);
                    nextDate.setDate(
                        originalDate.getDate() + Number(data.dayArrival - 1)
                    );
                    const [hours, minutes] = data.STD.split(":").map(Number);
                    nextDate.setHours(hours, minutes, 0, 0);
                    return nextDate;
                }
            } else if (data.dayDeparture === "nextDay") {
                if (data.dayArrival === "sameDay") {
                    const originalDate = new Date();
                    const nextDate = new Date(originalDate);
                    nextDate.setDate(originalDate.getDate() + 1);
                    const [hours, minutes] = data.STD.split(":").map(Number);
                    nextDate.setHours(hours, minutes, 0, 0);
                    return nextDate;
                } else if (data.dayArrival === "nextDay") {
                    const originalDate = new Date();
                    const nextDate = new Date(originalDate);
                    nextDate.setDate(originalDate.getDate() + 2);
                    const [hours, minutes] = data.STD.split(":").map(Number);
                    nextDate.setHours(hours, minutes, 0, 0);
                    return nextDate;
                } else {
                    const originalDate = new Date();
                    const nextDate = new Date(originalDate);
                    nextDate.setDate(
                        originalDate.getDate() + Number(data.dayArrival - 1) + 1
                    );
                    const [hours, minutes] = data.STD.split(":").map(Number);
                    nextDate.setHours(hours, minutes, 0, 0);
                    return nextDate;
                }
            } else {
                if (data.dayArrival === "sameDay") {
                    const originalDate = new Date();
                    const nextDate = new Date(originalDate);
                    nextDate.setDate(
                        originalDate.getDate() + Number(data.dayDeparture - 1)
                    );
                    const [hours, minutes] = data.STD.split(":").map(Number);
                    nextDate.setHours(hours, minutes, 0, 0);
                    return nextDate;
                } else if (data.dayArrival === "nextDay") {
                    const originalDate = new Date();
                    const nextDate = new Date(originalDate);
                    nextDate.setDate(
                        originalDate.getDate() + 2 + Number(data.dayDeparture - 1)
                    );
                    const [hours, minutes] = data.STD.split(":").map(Number);
                    nextDate.setHours(hours, minutes, 0, 0);
                    return nextDate;
                } else {
                    const originalDate = new Date();
                    const nextDate = new Date(originalDate);
                    nextDate.setDate(
                        originalDate.getDate() +
                        Number(data.dayArrival - 1) +
                        Number(data.dayDeparture - 1)
                    );
                    const [hours, minutes] = data.STD.split(":").map(Number);
                    nextDate.setHours(hours, minutes, 0, 0);
                    return nextDate;
                }
            }
        };

        // console.log('first date', firstDate());
        // console.log('second date', secondDate())

        const timeDifferenceInMilliseconds =
            secondDate().getTime() - firstDate().getTime();

        // // Convert the difference to hours
        const timeDifferenceInHours =
            timeDifferenceInMilliseconds / (1000 * 60 * 60);
        // console.log('diff', timeDifferenceInHours)
        return convertDecimalToTime(timeDifferenceInHours).toString();
    }
    const convertDecimalToTime = (decimalHours) => {
        // Extract the whole number part (hours) and the fractional part (minutes)
        var hours = Math.floor(decimalHours);
        var minutes = Math.round((decimalHours - hours) * 60);

        // Format the result
        var result = hours + ":";
        if (minutes >= 0) {
            result += minutes;
        }
        return result;
    };

    const getTimefromDistanceandspeed = async (e, index, item) => {
        let temp = { ...routePayload }
        let val = e.target.value;

        if (item === 'via') {
            temp.viaPoints[index].totalDistance = val
            let distance = routePayload?.viaPoints[index]?.totalDistance
            let speed = routePayload?.viaPoints[index]?.speed
            let time = await calcTime(distance, speed)
            temp.viaPoints[index].etaTime = convertHHMMToDate(time)
        }
        else {
            temp.routeDestination.totalDistance = val
            let distance = routePayload?.routeDestination?.totalDistance
            let speed = routePayload?.routeDestination?.speed

            let time = await calcTime(distance, speed)
            temp.routeDestination.etaTime = convertHHMMToDate(time)
        }
        setRoutePayload(temp)
    }

    const getTimefromDistance = async (e, index, item) => {
        let temp = { ...routePayload }
        let val = e.target.value;

        if (item === 'via') {
            temp.viaPoints[index].speed = val
            let distance = routePayload?.viaPoints[index]?.totalDistance
            let speed = routePayload?.viaPoints[index]?.speed
            let time = await calcTime(distance, speed)
            temp.viaPoints[index].etaTime = convertHHMMToDate(time)
        }
        else {
            temp.routeDestination.speed = val
            let distance = routePayload?.routeDestination?.totalDistance
            let speed = routePayload?.routeDestination?.speed
            let time = await calcTime(distance, speed)
            temp.routeDestination.etaTime = convertHHMMToDate(time)
        }
        setRoutePayload(temp)
    }

    const getSpeedfromDistanceandtime = async (value, index, item) => {
        let temp = { ...routePayload }
        if (item === 'via') {
            temp.viaPoints[index].etaTime = value
            let distance = routePayload?.viaPoints[index]?.totalDistance
            let time = routePayload?.viaPoints[index]?.etaTime
            let speed = await calcSpeed(distance, time)
            temp.viaPoints[index].speed = speed
        }
        else {
            temp.routeDestination.etaTime = value
            let distance = routePayload?.routeDestination?.totalDistance
            let time = routePayload?.routeDestination?.etaTime
            let speed = await calcSpeed(distance, time)
            temp.routeDestination.speed = speed
        }
        setRoutePayload(temp)
    }

    const handleChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const handleRemoveSchedule = useCallback(
        (key) => {
            const updatedSchedules = schedules?.filter((schedule) => {
                return schedule.key !== key;
            });
            setSchedules(updatedSchedules);
            setTabValue((prev) => prev - 1);
        },
        [setSchedules, schedules, setTabValue]
    );

    useEffect(() => {
        if (isEditRoute) {
            schedules?.map((schedule, index) => {
                let dayArrival;
                if (schedule?.dayArrival === "sameDay") {
                    dayArrival = "sameDay";
                } else if (schedule?.dayArrival === "nextDay") {
                    dayArrival = "nextDay";
                } else if (!!schedule?.dayArrival) {
                    dayArrival = "custom";
                    setValue(
                        `scheduleCustomArrivalDay${index}`,
                        schedule?.dayArrival
                    );
                }
                setValue(
                    `scheduleArrivalDay${index}`,
                    schedule?.dayArrival
                        ? (dayOptions?.find(
                            (option) => option?.value === schedule?.dayArrival
                        ) ?? dayOptions?.find((option) => option?.value === "custom"))
                        : null
                );

                const arrivalDay = {
                    [`scheduleArrivalDay${index}`]: dayArrival,
                };

                setScheduleDestinationCustomDay((prev) => ({ ...prev, ...arrivalDay }));

                if (schedule?.scheduleVia?.length) {
                    schedule?.scheduleVia?.forEach((scheduleVia, viaIndex) => {
                        let viaDayArrival, viaDayDeparture;
                        if (scheduleVia?.dayArrival === "sameDay") {
                            viaDayArrival = "sameDay";
                        } else if (scheduleVia?.dayArrival === "nextDay") {
                            viaDayArrival = "nextDay";
                        } else if (!!scheduleVia?.dayArrival) {
                            viaDayArrival = "custom";
                            setValue(
                                `scheduleViaPointCustomArrivalDay${index}_${viaIndex}`,
                                scheduleVia?.dayArrival
                            );
                        }
                        setValue(
                            `scheduleViaPointArrivalDay${index}_${viaIndex}`,
                            scheduleVia?.dayArrival
                                ? (dayOptions?.find(
                                    (option) => option?.value === scheduleVia?.dayArrival
                                ) ?? dayOptions?.find((option) => option?.value === "custom"))
                                : null
                        );
                        if (scheduleVia?.dayDeparture === "sameDay") {
                            viaDayDeparture = "sameDay";
                        } else if (scheduleVia?.dayDeparture === "nextDay") {
                            viaDayDeparture = "nextDay";
                        } else if (!!scheduleVia?.dayDeparture) {
                            viaDayDeparture = "custom";
                            setValue(
                                `scheduleViaPointCustomDepartureDay${index}_${viaIndex}`,
                                scheduleVia?.dayDeparture
                            );
                        }
                        setValue(
                            `scheduleViaPointDepartureDay${index}_${viaIndex}`,
                            scheduleVia?.dayDeparture
                                ? (dayOptions?.find(
                                    (option) => option?.value === scheduleVia?.dayDeparture
                                ) ?? dayOptions?.find((option) => option?.value === "custom"))
                                : null
                        );
                        setScheduleCustomDay((prev) => ({
                            ...prev,
                            [`scheduleViaPointArrivalDay${index}_${viaIndex}`]: viaDayArrival,
                            [`scheduleViaPointDepartureDay${index}_${viaIndex}`]:
                                viaDayDeparture,
                        }));
                    });
                }
            });
        }
    }, [
        schedules,
        setScheduleCustomDay,
        setScheduleDestinationCustomDay,
        setValue,
        dayOptions,
        isEditRoute,
    ]);

    return (
        <>
            <Grid>
                <Grid container spacing={2}>
                    {isSuperAdmin && (
                        <Grid item md={6}>
                            <Controller
                                name="organisation"
                                control={control}
                                render={({ field: { onChange, value, name } }) => {
                                    return (
                                        <CustomSelect
                                            selectStylesOverride={selectStylesOverride}
                                            label="Select Organization"
                                            value={user && user?.orgId || null}
                                            name={name}
                                            errors={errors}
                                            disabled={isEditRoute}
                                            getOptionLabel={(option) => {
                                                if (typeof option === "string") {
                                                    return (
                                                        orgListing?.find((_) => _?._id == user?.orgId)?.name || ""
                                                    );
                                                } else {
                                                    return option?.name || "";
                                                }
                                            }}
                                            labelKey="name"
                                            onChange={(e, newVal, reason) => {
                                                if (newVal) {
                                                    setUser((prev) => {
                                                        const updateUser = { ...prev };
                                                        updateUser["orgId"] = newVal ? newVal?._id : null;
                                                        updateUser["orgName"] = newVal ? newVal?.name : null;
                                                        updateUser["id"] = null;
                                                        updateUser["name"] = null;
                                                        return updateUser;
                                                    });
                                                    dispatch(getAllUsers({
                                                        selection: ["name", "addedBy", "assignGeofence"],
                                                        searchValue: "",
                                                        orgId: newVal?._id ? newVal?._id : "",
                                                    }, (res) => { setUserList(res) }))
                                                }
                                                else {
                                                    setOrgListing([...orgListing])
                                                    setUserList([])
                                                }

                                                if (reason === "clear") {
                                                    handleOrganisationSearch(true);
                                                    dispatch(flushUsersList());
                                                    dispatch(flushGeofenceList());
                                                    setUser((prev) => {
                                                        const updateUser = { ...prev };
                                                        updateUser["orgId"] = null;
                                                        updateUser["orgName"] = null;
                                                        updateUser["id"] = null;
                                                        updateUser["name"] = null;
                                                        return updateUser;
                                                    });
                                                }
                                            }}
                                            searchValue={searchObject?.organisation}
                                            onInputChange={(e) => {
                                                if (e?.type === "change") {
                                                    if (e?.target?.value === "") handleOrganisationSearch(true);
                                                    setSearchObject((prev) => ({
                                                        ...prev,
                                                        organisation: e?.target?.value?.trim(),
                                                    }));
                                                }
                                            }}
                                            onSearch={() => handleOrganisationSearch()}
                                            options={orgListing && orgListing}
                                        />
                                    );
                                }}
                            />
                        </Grid>
                    )}
                    {isSuperAdmin && (
                        <Grid item md={6}>
                            <Controller
                                name="user"
                                control={control}
                                render={({ field: { onChange, value, name } }) => {
                                    return (
                                        <CustomSelect
                                            selectStylesOverride={selectStylesOverride}
                                            label="Select User"
                                            disabled={isEditRoute}
                                            value={user && user?.id || null}
                                            name={name}
                                            errors={errors}
                                            labelKey="name"
                                            onChange={(e, newVal, reason) => {
                                                if (newVal) {
                                                    setUser((prev) => {
                                                        const updateUser = { ...prev };
                                                        updateUser["id"] = newVal ? newVal?._id : null;
                                                        updateUser["name"] = newVal ? newVal?.name : null;
                                                        return updateUser;
                                                    });

                                                    dispatch(getAllGeofences({
                                                        selection: ["name", "centerCoordinates"],
                                                        user: {
                                                            userId: newVal?._id,
                                                            addedBy: newVal?.addedBy?.id,
                                                            //   assignGeofence: res?.assignGeofence,
                                                        },
                                                    }))
                                                }
                                                if (reason === "clear") {
                                                    handleUserSearch(true);
                                                    setUser((prev) => {
                                                        const updateUser = { ...prev };
                                                        updateUser["id"] = null;
                                                        updateUser["name"] = null;
                                                        return updateUser;
                                                    });
                                                    dispatch(flushGeofenceList());
                                                }

                                            }}
                                            searchValue={searchObject?.user}
                                            onInputChange={(e) => {
                                                if (e?.type === "change") {
                                                    if (e?.target?.value === "") handleUserSearch(true);
                                                    setSearchObject((prev) => ({
                                                        ...prev,
                                                        user: e?.target?.value?.trim(),
                                                    }));
                                                }
                                            }}
                                            getOptionLabel={(option) => {
                                                if (typeof option === "string") {
                                                    return (
                                                        userList?.find((_) => _?._id == user?.id)?.name || ""
                                                    );
                                                } else {
                                                    return option?.name || "";
                                                }
                                            }}
                                            onSearch={() => handleUserSearch()}
                                            options={userList && userList}
                                        />
                                    );
                                }}
                            />
                        </Grid>
                    )}
                    <Grid item md={6}>
                        {/* <Controller
                            name="name"
                            control={control}
                            rules={{
                                required: {
                                    value: true,
                                    message: "Name is required",
                                },
                            }}
                            render={({ field: { onChange, value, name } }) => { */}
                        {/* return ( */}
                        <CustomInput
                            placeholder="Route Name"
                            selectStylesOverride={selectStylesOverride}
                            inputClasses={inputClasses}
                            // name={name}
                            value={routePayload?.routeName || ""}
                            label="Name"
                            required
                            onChange={(e) => handleOnChange(e, 'name', null)}
                            errors={errors}
                        />
                        {/* );
                            }}
                        /> */}
                    </Grid>
                    <Grid item md={6}>
                        {/* <Controller
                            name="description"
                            control={control}
                            render={({ field: { onChange, value, name } }) => {
                                return ( */}
                        <CustomInput
                            placeholder="Route description"
                            selectStylesOverride={selectStylesOverride}
                            inputClasses={inputClasses}
                            // name={name}
                            value={routePayload?.description || ""}
                            label="Route description"
                            onChange={(e) => handleOnChange(e, 'description', null)}
                            errors={errors}
                        />
                        {/* );
                            }}
                        /> */}
                    </Grid>
                    <Grid item md={6}>
                        {/* <Controller
                            name="battery"
                            control={control}
                            rules={{
                                required: {
                                    value: true,
                                    message: "Battery Percentage is required",
                                },
                            }}
                            render={({ field: { onChange, value, name } }) => {
                                return ( */}
                        <CustomSelect
                            selectStylesOverride={selectStylesOverride}
                            value={routePayload?.batteryPercentage || null}
                            // name={name}
                            required
                            label="Minimum Battery Percentage"
                            errors={errors}
                            getOptionLabel={(option) => {
                                return option + '%';
                            }}
                            filterSelectedOptions
                            options={batterPercentageOptions}
                            onChange={(e, newVal) => handleOnChange(newVal, 'battery', null)}
                        />
                        {/* );
                            }}
                        /> */}
                    </Grid>
                </Grid>

                <form>
                    <Grid
                        mt={0}
                        item
                        sm={12}
                        minHeight="115px"
                        xs={12}
                        style={{ overflowY: "auto", overflowX: "hidden" }}
                    >
                        {templateExistence && Object.keys(templateExistence).length ? (
                            <DynamicFormRender
                                formHooks={{
                                    register,
                                    control,
                                    errors,
                                    handleSubmit,
                                    reset,
                                    setValue,
                                }}
                                templateId={(templateExistence && templateExistence?._id) || ""}
                                widthProp={6}
                            />
                        ) : null}
                    </Grid>
                </form>

                {/* Route scheduling check  + via point add btn */}
                <Grid
                    container
                    display={"flex"}
                    justifyContent={"space-between"}
                    mt={0.5}
                >
                    <Box display={"flex"} alignItems={"center"}>
                        <Checkbox
                            checked={schedules?.length > 0 ? true : false}
                            onChange={(e) => handleScheduling(e)}
                            sx={{
                                "&, &.Mui-checked": { color: themeType.default.themeOrange },
                                marginBottom: "4px",
                            }}
                        />
                        <Typography
                            sx={{ ...textClasses.normalText, paddingBottom: "4px" }}
                        >
                            Enable Route Scheduling
                        </Typography>
                    </Box>
                    <Button
                        variant="contained"
                        sx={{
                            ...buttonClasses.small,
                            minHeight: "36px",
                            borderColor: themeType.default.themeOrange,
                            color: buttonClasses.lynkitOrangeEmpty,
                        }}
                        startIcon={<AddIcon />}
                        onClick={() => addViaPointFunction()}
                    >
                        Add Via Point
                    </Button>
                </Grid>

                {schedules.length > 0 && (
                    <Grid container justifyContent="end" mt={-1}>
                        <Grid item xs={11} container justifyContent="end">
                            <Grid
                                xs={12}
                                display={"flex"}
                                sx={{ maxWidth: { xs: 280, sm: 414 }, height: "24px" }}
                            >
                                <Tabs
                                    value={tabValue}
                                    onChange={handleChange}
                                    variant="scrollable"
                                    sx={{ minHeight: "16px" }}
                                    scrollButtons="auto"
                                    indicatorColor=""
                                    textColor="inherit"
                                >
                                    {schedules?.map((schedule, i) => {
                                        return (
                                            <Tab
                                                sx={{
                                                    bgcolor: "#E0E0E0",
                                                    minHeight: "24px",
                                                    borderRadius: "4px 4px 0 0",
                                                    padding: 0,
                                                    marginRight: "4px",
                                                    fontSize: "11px",
                                                    textTransform: "capitalize",
                                                    "&.Mui-selected": {
                                                        bgcolor: "#FF7200",
                                                        color: "#fff",
                                                    },
                                                }}
                                                label={
                                                    <span>
                                                        {`Schedule ${i + 1}`}
                                                        <IconButton
                                                            size="small"
                                                            sx={{ padding: 0 }}
                                                            onClick={() => {
                                                                handleRemoveSchedule(schedule?.key);
                                                            }}
                                                        >
                                                            <CloseIcon
                                                                sx={{
                                                                    width: "15px",
                                                                    position: "relative",
                                                                    left: "6px",
                                                                }}
                                                            />
                                                        </IconButton>
                                                    </span>
                                                }
                                                {...a11yProps(schedule?.key)}
                                            />
                                        );
                                    })}
                                </Tabs>
                                {schedules.length > 0 && (
                                    <Button
                                        variant="contained"
                                        size="small"
                                        data-testid="add-new-button"
                                        onClick={() => handleAddSchedule()}
                                        sx={{
                                            minWidth: "24px",
                                            height: "24px",
                                            padding: "4px",
                                            boxShadow: "none",
                                            bgcolor: "#fff",
                                            border: "1px solid green",
                                            "&:hover": {
                                                bgcolor: "#fff",
                                            },
                                        }}
                                    >
                                        <AddIcon fontSize="12px" sx={{ color: "green" }} />
                                    </Button>
                                )}
                            </Grid>

                        </Grid>
                    </Grid>
                )}

                <Box sx={{ minHeight: "34%", maxHeight: "auto", overflow: "auto" }}>
                    <Box
                        sx={{ borderColor: (theme) => theme.palette.divider }}
                        border={"0.25px solid"}
                        mt={schedules.length > 0 ? 0 : 1}
                    >
                        <Grid container sx={{ height: "100%" }}>
                            <Grid container item md={12}>
                                <Grid container item xs={12} mt={0}>
                                    <Grid item md={1}>
                                        <IconWithArrow name="source" src={SourceImg} />
                                    </Grid>
                                    <Grid
                                        item
                                        container
                                        md={10.5}
                                        mt={1}
                                        border={"0.1px solid"}
                                        sx={{ borderColor: (theme) => theme.palette.divider }}
                                    >
                                        <Grid item xs={schedules?.length > 0 ? 6 : 12} p={1}>
                                            <Typography
                                                sx={{
                                                    ...textClasses.normalText,
                                                    paddingBottom: "16px",
                                                }}
                                            >
                                                Source Address*
                                            </Typography>
                                            {/* <Controller
                                                name="source"
                                                control={control}
                                                rules={{
                                                    required: {
                                                        value: true,
                                                        message: "Source is required",
                                                    },
                                                }}
                                                render={({ field: { onChange, value, name } }) => {
                                                    return ( */}
                                            <CustomSelect
                                                noOptionsText={"No geofences found"}
                                                selectStylesOverride={selectStylesOverride}
                                                // name={name}
                                                required
                                                label="Select Geofence"
                                                errors={errors}
                                                getOptionLabel={(option) => {
                                                    return option?.name;
                                                }}
                                                value={routePayload?.routeSource || null}
                                                filterSelectedOptions
                                                filterOptions={(options) => options}
                                                labelKey="name"
                                                searchValue={searchObject?.source}
                                                onInputChange={(e) => {
                                                    if (e?.type === "change") {
                                                        setSearchObject((prev) => ({
                                                            ...prev,
                                                            source: e?.target?.value?.trim(),
                                                        }));
                                                        if (e.target.value === "") {
                                                            handleSearchGeofence(
                                                                "source",
                                                                null,
                                                                "reset"
                                                            );
                                                        }
                                                    }
                                                }}
                                                onSearch={() => handleSearchGeofence("source")}
                                                options={sourceGeofenceList?.length > 0 ? sourceGeofenceList : geofenceList}
                                                onChange={(e, newVal) => handleOnChange(newVal, 'srcAddress', null)}
                                            />
                                            {/* );
                                                }}
                                            /> */}
                                        </Grid>
                                        {/* --- SOURCE SCHEDULES --- */}
                                        {schedules.length > 0 && (
                                            <Grid
                                                item
                                                md={6}
                                                pb={3}
                                                pl={3}
                                                pr={3}
                                                borderLeft={"0.1px solid "}
                                                sx={{ borderColor: (theme) => theme.palette.divider }}
                                            >
                                                <Box pb={2}>
                                                    <Typography
                                                        sx={{
                                                            ...textClasses.normalText,
                                                            paddingBottom: "9px",
                                                            paddingTop: "3px",
                                                        }}
                                                    >
                                                        Schedule Name*
                                                    </Typography>
                                                    <Controller
                                                        name={`schedule${tabValue}`}
                                                        control={control}
                                                        rules={{
                                                            required: {
                                                                value: true,
                                                                message: "Name is required",
                                                            },
                                                        }}
                                                        render={({ field: { onChange, value, name } }) => {
                                                            return (
                                                                <CustomInput
                                                                    placeholder="Enter Schedule Name"
                                                                    selectStylesOverride={selectStylesOverride}
                                                                    inputClasses={inputClasses}
                                                                    name={name}
                                                                    value={schedules[tabValue]?.schedule || ""}
                                                                    label="Name"
                                                                    required
                                                                    onChange={(e) => {
                                                                        const updatedSchedules = [...schedules]; // Create a copy of the original array
                                                                        const scheduleIndex =
                                                                            updatedSchedules.findIndex(
                                                                                (schedule) => schedule.key === tabValue
                                                                            );

                                                                        if (scheduleIndex !== -1) {
                                                                            updatedSchedules[scheduleIndex].schedule =
                                                                                e.target.value;
                                                                        }
                                                                        onChange(e.target.value);
                                                                    }}
                                                                    errors={errors}
                                                                />
                                                            );
                                                        }}
                                                    />
                                                </Box>
                                                <Box>
                                                    <Typography
                                                        sx={{
                                                            ...textClasses.normalText,
                                                            paddingBottom: "3px",
                                                        }}
                                                    >
                                                        Schedule Time of Departure*
                                                    </Typography>
                                                    <Controller
                                                        name={`scheduleDeparture${tabValue}`}
                                                        control={control}
                                                        rules={{
                                                            required: {
                                                                value: true,
                                                                message: "Time is required",
                                                            },
                                                        }}
                                                        render={({ field: { onChange, value, name } }) => {
                                                            return (
                                                                <CustomTimeField
                                                                    label="Time"
                                                                    name={name}
                                                                    selectStylesOverride={selectStylesOverride}
                                                                    value={
                                                                        schedules[tabValue]?.STD
                                                                            ? convertHHMMToDate(
                                                                                schedules[tabValue]?.STD
                                                                            )
                                                                            : null
                                                                    }
                                                                    onChange={(time) => {
                                                                        if (
                                                                            new Date(time).toString() !==
                                                                            "Invalid Date"
                                                                        ) {
                                                                            const formattedTime =
                                                                                time && format(time, "HH:mm");
                                                                            const updatedSchedules = [...schedules]; // Create a copy of the original array
                                                                            const scheduleIndex =
                                                                                updatedSchedules.findIndex(
                                                                                    (schedule) =>
                                                                                        schedule.key === tabValue
                                                                                );

                                                                            if (scheduleIndex !== -1) {
                                                                                updatedSchedules[scheduleIndex]["STD"] =
                                                                                    formattedTime;
                                                                            }
                                                                        }
                                                                        onChange(time);
                                                                    }}
                                                                    errors={errors}
                                                                />
                                                            );
                                                        }}
                                                    />
                                                </Box>
                                            </Grid>
                                        )}
                                    </Grid>
                                </Grid>

                                {/* Via points section ------- */}
                                {routePayload?.viaPoints?.length > 0 &&
                                    routePayload?.viaPoints?.map((viaPoint, index) => {
                                        return (
                                            <div
                                                key={index}
                                                style={{ width: "96%" }}
                                            >
                                                <Grid xs={12} item container mt={1}>
                                                    <Grid item md={1}>
                                                        <IconWithArrow name="viapoint" src={ViaPointImg} />
                                                    </Grid>
                                                    <Grid item container md={11}>
                                                        <Grid
                                                            container
                                                            item
                                                            xs={12}
                                                            borderTop={"0.25px solid"}
                                                            borderBottom={"0.1px solid"}
                                                            borderRight={"0.1px solid"}
                                                            sx={{
                                                                position: "relative",
                                                                borderColor: (theme) => theme.palette.divider,
                                                            }}
                                                        >
                                                            <Grid className="viaNumber">{index + 1}</Grid>
                                                            <Grid
                                                                item
                                                                xs={schedules?.length > 0 ? 6 : 12}
                                                                p={2}
                                                                borderLeft={"0.25px solid"}
                                                                sx={{
                                                                    borderColor: (theme) => theme.palette.divider,
                                                                }}
                                                            >
                                                                <Grid
                                                                    item
                                                                    sx={{
                                                                        display: "flex",
                                                                        justifyContent: "space-between",
                                                                    }}
                                                                >
                                                                    <Typography
                                                                        sx={{
                                                                            ...textClasses.normalText,
                                                                            paddingBottom: "1px",
                                                                        }}
                                                                    >
                                                                        Viapoint Address*
                                                                    </Typography>
                                                                    <Button
                                                                        variant="outlined"
                                                                        size="small"
                                                                        // className='deleteredBtn'
                                                                        sx={{
                                                                            ...buttonClasses.lynkitRedEmpty,
                                                                            minHeight: "25px",
                                                                            position: "relative",
                                                                            bottom: "5px",
                                                                        }}
                                                                        onClick={() => deleteViapoint(index)}
                                                                    >
                                                                        Delete
                                                                    </Button>
                                                                </Grid>
                                                                <Grid item mt={0}>
                                                                    {/* <Controller
                                                                        name={`viapoint${index}`}
                                                                        control={control}
                                                                        rules={{
                                                                            required: {
                                                                                value: true,
                                                                                message: "Viapoint is required",
                                                                            },
                                                                        }}
                                                                        render={({
                                                                            field: { onChange, value, name },
                                                                        }) => {
                                                                            return ( */}
                                                                    <CustomSelect
                                                                        noOptionsText={"No geofences found"}
                                                                        selectStylesOverride={
                                                                            selectStylesOverride
                                                                        }
                                                                        // name={name}
                                                                        required
                                                                        label="Select Viapoint"
                                                                        errors={errors}
                                                                        getOptionLabel={(option) => { return (option?.name || option?.itemName || "") }}
                                                                        filterSelectedOptions
                                                                        filterOptions={(options) => options}
                                                                        labelKey="name"
                                                                        searchValue={viaPointsSearchObject?.find((viaPoint) => { return (viaPoint?.index === index) })?.searchValue}
                                                                        onInputChange={(e) => {
                                                                            if (e?.type === "change") {
                                                                                const temp = viaPointsSearchObject;
                                                                                const existingSearchObject = temp?.find((object) => object?.index === index);
                                                                                if (existingSearchObject) {
                                                                                    const updateExistingObject = temp.map((object) => {
                                                                                        if (object?.index === index) {
                                                                                            return {
                                                                                                ...object,
                                                                                                searchValue: e?.target?.value,
                                                                                            };
                                                                                        }
                                                                                    });
                                                                                    setViaPointsSearchObject(updateExistingObject);
                                                                                }
                                                                                else {
                                                                                    setViaPointsSearchObject((prev) => {
                                                                                        const vaiPointSearchObject = {
                                                                                            index: index,
                                                                                            searchValue: e?.target?.value,
                                                                                        };
                                                                                        return [
                                                                                            ...prev,
                                                                                            vaiPointSearchObject,
                                                                                        ];
                                                                                    }
                                                                                    );
                                                                                }

                                                                                if (e.target.value === "") {
                                                                                    handleSearchGeofence("viaPoint", index, "reset")
                                                                                }
                                                                            }
                                                                        }}
                                                                        onSearch={() => handleSearchGeofence("viaPoint", index)}
                                                                        value={routePayload?.viaPoints[index] || null}
                                                                        options={viaPointsDropdownGeofenceList?.find((geofenceList) => geofenceList?.index === index)?.list ?? geofenceList}
                                                                        onChange={(e, newVal) => handleOnChange(newVal, 'viaAddress', index)}
                                                                    />
                                                                    {/* );
                                                                        }}
                                                                    /> */}
                                                                    {schedules.length > 0 && (
                                                                        <Box pt={2}>
                                                                            <Typography
                                                                                sx={{
                                                                                    ...textClasses.normalText,
                                                                                    paddingBottom: "11px",
                                                                                }}
                                                                            >
                                                                                Distance :{" "}
                                                                                {viaPoint?.totalDistance ?? "-"}
                                                                            </Typography>
                                                                            <Typography
                                                                                sx={{
                                                                                    ...textClasses.normalText,
                                                                                    paddingBottom: "11px",
                                                                                }}
                                                                            >
                                                                                Average Speed : {viaPoint?.speed ?? "-"}
                                                                            </Typography>
                                                                            <Typography
                                                                                sx={{
                                                                                    ...textClasses.normalText,
                                                                                    paddingBottom: "4px",
                                                                                }}
                                                                            >
                                                                                Halt :{" "}
                                                                                {(schedules[tabValue] &&
                                                                                    schedules[tabValue]?.scheduleVia &&
                                                                                    schedules[tabValue]?.scheduleVia[
                                                                                        index
                                                                                    ]?.halt) ??
                                                                                    ""}
                                                                            </Typography>
                                                                        </Box>
                                                                    )}
                                                                </Grid>
                                                            </Grid>

                                                            {schedules.length === 0 && (
                                                                <Grid
                                                                    container
                                                                    item
                                                                    xs={4}
                                                                    gap={1}
                                                                    p={2}
                                                                    borderLeft={"0.1px solid"}
                                                                    sx={{
                                                                        borderColor: (theme) =>
                                                                            theme.palette.divider,
                                                                    }}
                                                                >
                                                                    <Grid
                                                                        item
                                                                        xs
                                                                        sx={{ padding: "0 !important" }}
                                                                    >
                                                                        <Controller
                                                                            name={`viapointDistance${index}`}
                                                                            control={control}
                                                                            render={({
                                                                                field: { onChange, value, name },
                                                                            }) => {
                                                                                return (
                                                                                    <CustomInput
                                                                                        placeholder="Distance"
                                                                                        selectStylesOverride={selectStylesOverride}
                                                                                        inputClasses={inputClasses}
                                                                                        name={name}
                                                                                        value={routePayload?.viaPoints[index]?.totalDistance || ""}
                                                                                        label="Distance"
                                                                                        disabled={
                                                                                            (userPermissions &&
                                                                                                havePermission(
                                                                                                    userPermissions,
                                                                                                    "trip",
                                                                                                    "flightDetails"
                                                                                                )) ||
                                                                                                (routeDetails &&
                                                                                                    Object.keys(routeDetails)
                                                                                                        .length)
                                                                                                ? false
                                                                                                : true
                                                                                        }
                                                                                        onChange={(e) => getTimefromDistanceandspeed(e, index, 'via')}
                                                                                    />
                                                                                );
                                                                            }}
                                                                        />
                                                                    </Grid>
                                                                    <Grid
                                                                        item
                                                                        xs
                                                                        sx={{ padding: "0 !important" }}
                                                                    >
                                                                        <Controller
                                                                            name={`viapointTime${index}`}
                                                                            control={control}
                                                                            render={({
                                                                                field: { onChange, value, name },
                                                                            }) => {
                                                                                return (
                                                                                    <CustomTimeField
                                                                                        label="Time"
                                                                                        size="small"
                                                                                        name={name}
                                                                                        errors={errors}
                                                                                        selectStylesOverride={selectStylesOverride}
                                                                                        value={routePayload?.viaPoints[index]?.etaTime || ""}
                                                                                        onChange={(e) => getSpeedfromDistanceandtime(e, index, 'via')}
                                                                                    />
                                                                                );
                                                                            }}
                                                                        />
                                                                    </Grid>
                                                                    <Grid
                                                                        item
                                                                        xs
                                                                        sx={{ padding: "0 !important" }}
                                                                    >
                                                                        <Controller
                                                                            name={`viapointSpeed${index}`}
                                                                            control={control}
                                                                            render={({ field: { onChange, value, name }, }) => {
                                                                                return (
                                                                                    <CustomInput
                                                                                        placeholder="Speed"
                                                                                        selectStylesOverride={
                                                                                            selectStylesOverride
                                                                                        }
                                                                                        inputClasses={inputClasses}
                                                                                        name={name}
                                                                                        value={routePayload?.viaPoints[index]?.speed || ""}
                                                                                        label="Speed"
                                                                                        onChange={(e) => getTimefromDistance(e, index, 'via')}
                                                                                    />
                                                                                );
                                                                            }}
                                                                        />
                                                                    </Grid>
                                                                </Grid>
                                                            )}
                                                            {schedules.length > 0 && (
                                                                <Grid
                                                                    container
                                                                    item
                                                                    xs
                                                                    borderLeft={"0.25px solid"}
                                                                    sx={{
                                                                        borderColor: (theme) =>
                                                                            theme.palette.divider,
                                                                    }}
                                                                >
                                                                    <Box
                                                                        width={"100%"}
                                                                        pl={3}
                                                                        pr={3}
                                                                        md={12}
                                                                        pt={2}
                                                                    >
                                                                        <Typography
                                                                            sx={{
                                                                                ...textClasses.normalText,
                                                                                paddingBottom: "15px",
                                                                            }}
                                                                            pb={4}
                                                                        >
                                                                            Schedule Time of Arrival*
                                                                        </Typography>

                                                                        <Grid
                                                                            item
                                                                            container
                                                                            md={12}
                                                                            mb={2}
                                                                            display={"flex"}
                                                                            sx={{ justifyContent: "space-between" }}
                                                                        >
                                                                            <Grid item md={5.8}>
                                                                                <Box pb={0}>
                                                                                    <Controller
                                                                                        name={`scheduleViaPointArrivalDay${tabValue}_${index}`}
                                                                                        control={control}
                                                                                        rules={{
                                                                                            required: {
                                                                                                value: true,
                                                                                                message: "Please add Day",
                                                                                            },
                                                                                        }}
                                                                                        // defaultValue={null}
                                                                                        render={({
                                                                                            field: { onChange, value, name },
                                                                                        }) => {
                                                                                            return (
                                                                                                <CustomSelect
                                                                                                    selectStylesOverride={
                                                                                                        selectStylesOverride
                                                                                                    }
                                                                                                    // defaultValue={
                                                                                                    //   dayOptions?.find(
                                                                                                    //     (option) =>
                                                                                                    //       option?.value ===
                                                                                                    //       (schedules[tabValue] &&
                                                                                                    //         schedules[tabValue][
                                                                                                    //         "scheduleVia"
                                                                                                    //         ] &&
                                                                                                    //         schedules[tabValue][
                                                                                                    //           "scheduleVia"
                                                                                                    //         ][index]?.dayArrival)
                                                                                                    //   ) || null
                                                                                                    // }
                                                                                                    // inputValue={(schedules[tabValue] && schedules[tabValue]?.scheduleVia && schedules[tabValue]?.scheduleVia[index]?.dayArrival ) === null ?  "" : schedules[tabValue] && schedules[tabValue]?.scheduleVia && schedules[tabValue]?.scheduleVia[index]?.dayArrival }
                                                                                                    // value={
                                                                                                    //   schedules[tabValue] &&
                                                                                                    //     schedules[tabValue]
                                                                                                    //       ?.scheduleVia &&
                                                                                                    //     schedules[tabValue]
                                                                                                    //       ?.scheduleVia[index]
                                                                                                    //       ?.dayArrival
                                                                                                    //     ? dayOptions.find(
                                                                                                    //       (option) =>
                                                                                                    //         option?.value ===
                                                                                                    //         schedules[tabValue]
                                                                                                    //           ?.scheduleVia[index]
                                                                                                    //           ?.dayArrival
                                                                                                    //     ) ??
                                                                                                    //     dayOptions.find(
                                                                                                    //       (option) =>
                                                                                                    //         option?.value ===
                                                                                                    //         "custom"
                                                                                                    //     )
                                                                                                    //     : null
                                                                                                    // }
                                                                                                    value={
                                                                                                        scheduleCustomDay[
                                                                                                            `scheduleViaPointArrivalDay${tabValue}_${index}`
                                                                                                        ] === "sameDay"
                                                                                                            ? {
                                                                                                                value: "sameDay",
                                                                                                                label: "Same Day",
                                                                                                            }
                                                                                                            : scheduleCustomDay[
                                                                                                                `scheduleViaPointArrivalDay${tabValue}_${index}`
                                                                                                            ] === "nextDay"
                                                                                                                ? {
                                                                                                                    value: "nextDay",
                                                                                                                    label: "Next Day",
                                                                                                                }
                                                                                                                : scheduleCustomDay[
                                                                                                                    `scheduleViaPointArrivalDay${tabValue}_${index}`
                                                                                                                ] === "custom"
                                                                                                                    ? {
                                                                                                                        value: "custom",
                                                                                                                        label: "Custom",
                                                                                                                    }
                                                                                                                    : null
                                                                                                    }
                                                                                                    onChange={(e, newVal) => {
                                                                                                        if (newVal) {
                                                                                                            let updatedSchedules = [
                                                                                                                ...schedules,
                                                                                                            ]; // Create a copy of the original array
                                                                                                            const scheduleIndex =
                                                                                                                updatedSchedules.findIndex(
                                                                                                                    (schedule) =>
                                                                                                                        schedule.key ===
                                                                                                                        tabValue
                                                                                                                );

                                                                                                            if (
                                                                                                                newVal?.value !==
                                                                                                                "custom"
                                                                                                            ) {
                                                                                                                if (
                                                                                                                    updatedSchedules[
                                                                                                                        scheduleIndex
                                                                                                                    ]?.scheduleVia?.[
                                                                                                                    index
                                                                                                                    ]
                                                                                                                ) {
                                                                                                                    updatedSchedules[
                                                                                                                        scheduleIndex
                                                                                                                    ].scheduleVia[
                                                                                                                        index
                                                                                                                    ].STA = null;
                                                                                                                    updatedSchedules[
                                                                                                                        scheduleIndex
                                                                                                                    ].scheduleVia[
                                                                                                                        index
                                                                                                                    ].halt = null;
                                                                                                                }
                                                                                                                setSchedules(
                                                                                                                    updatedSchedules
                                                                                                                );

                                                                                                                if (
                                                                                                                    scheduleIndex !== -1
                                                                                                                ) {
                                                                                                                    if (
                                                                                                                        !updatedSchedules[
                                                                                                                            scheduleIndex
                                                                                                                        ].scheduleVia
                                                                                                                    ) {
                                                                                                                        updatedSchedules[
                                                                                                                            scheduleIndex
                                                                                                                        ]["scheduleVia"] =
                                                                                                                            [];
                                                                                                                        updatedSchedules[
                                                                                                                            scheduleIndex
                                                                                                                        ]["scheduleVia"][
                                                                                                                            index
                                                                                                                        ] = {};
                                                                                                                    }
                                                                                                                    if (
                                                                                                                        !updatedSchedules[
                                                                                                                        scheduleIndex
                                                                                                                        ]["scheduleVia"][
                                                                                                                        index
                                                                                                                        ]
                                                                                                                    ) {
                                                                                                                        updatedSchedules[
                                                                                                                            scheduleIndex
                                                                                                                        ]["scheduleVia"][
                                                                                                                            index
                                                                                                                        ] = {};
                                                                                                                    }
                                                                                                                    updatedSchedules[
                                                                                                                        scheduleIndex
                                                                                                                    ]["scheduleVia"][
                                                                                                                        index
                                                                                                                    ]["dayArrival"] =
                                                                                                                        newVal?.value;
                                                                                                                }
                                                                                                            } else {
                                                                                                                if (
                                                                                                                    updatedSchedules[
                                                                                                                        scheduleIndex
                                                                                                                    ]?.scheduleVia?.[
                                                                                                                    index
                                                                                                                    ]
                                                                                                                ) {
                                                                                                                    updatedSchedules[
                                                                                                                        scheduleIndex
                                                                                                                    ].scheduleVia[index][
                                                                                                                        "dayArrival"
                                                                                                                    ] = "custom";
                                                                                                                    updatedSchedules[
                                                                                                                        scheduleIndex
                                                                                                                    ].scheduleVia[
                                                                                                                        index
                                                                                                                    ].STA = null;
                                                                                                                    updatedSchedules[
                                                                                                                        scheduleIndex
                                                                                                                    ].scheduleVia[
                                                                                                                        index
                                                                                                                    ].halt = null;
                                                                                                                    setValue(
                                                                                                                        `scheduleViaPointCustomArrivalDay${tabValue}_${index}`,
                                                                                                                        ""
                                                                                                                    );
                                                                                                                    setSchedules(
                                                                                                                        updatedSchedules
                                                                                                                    );
                                                                                                                }
                                                                                                            }
                                                                                                            const scheduleState = {
                                                                                                                [`scheduleViaPointArrivalDay${tabValue}_${index}`]:
                                                                                                                    newVal?.value,
                                                                                                            };
                                                                                                            setScheduleCustomDay(
                                                                                                                (prev) => ({
                                                                                                                    ...prev,
                                                                                                                    ...scheduleState,
                                                                                                                })
                                                                                                            );
                                                                                                            onChange(newVal);
                                                                                                        }
                                                                                                    }}
                                                                                                    name={name}
                                                                                                    required
                                                                                                    label="Schedule Day"
                                                                                                    errors={errors}
                                                                                                    getOptionLabel={(option) => {
                                                                                                        return option?.label;
                                                                                                    }}
                                                                                                    filterSelectedOptions
                                                                                                    options={dayOptions}
                                                                                                />
                                                                                            );
                                                                                        }}
                                                                                    />
                                                                                </Box>
                                                                            </Grid>

                                                                            {scheduleCustomDay[
                                                                                `scheduleViaPointArrivalDay${tabValue}_${index}`
                                                                            ] !== "custom" && (
                                                                                    <Grid item md={5.8}>
                                                                                        <Box pb={1}>
                                                                                            <Controller
                                                                                                name={`scheduleViaPointArrivalTime${tabValue}_${index}`}
                                                                                                control={control}
                                                                                                rules={{
                                                                                                    required: {
                                                                                                        value: true,
                                                                                                        message: "Time is required",
                                                                                                    },
                                                                                                }}
                                                                                                render={({
                                                                                                    field: {
                                                                                                        onChange,
                                                                                                        value,
                                                                                                        name,
                                                                                                    },
                                                                                                }) => {
                                                                                                    return (
                                                                                                        <CustomTimeField
                                                                                                            selectStylesOverride={
                                                                                                                selectStylesOverride
                                                                                                            }
                                                                                                            label="Time"
                                                                                                            size="small"
                                                                                                            name={name}
                                                                                                            errors={errors}
                                                                                                            value={
                                                                                                                schedules[tabValue] &&
                                                                                                                    schedules[tabValue]
                                                                                                                        ?.scheduleVia &&
                                                                                                                    schedules[tabValue]
                                                                                                                        ?.scheduleVia[index]
                                                                                                                        ?.STA
                                                                                                                    ? convertHHMMToDate(
                                                                                                                        schedules[tabValue]
                                                                                                                            ?.scheduleVia[
                                                                                                                            index
                                                                                                                        ]?.STA
                                                                                                                    )
                                                                                                                    : null
                                                                                                            }
                                                                                                            onChange={(time) => {
                                                                                                                if (
                                                                                                                    new Date(
                                                                                                                        time
                                                                                                                    ).toString() !==
                                                                                                                    "Invalid Date"
                                                                                                                ) {
                                                                                                                    const formattedTime =
                                                                                                                        format(time, "HH:mm");

                                                                                                                    const updatedSchedules =
                                                                                                                        [...schedules]; // Create a copy of the original array
                                                                                                                    const scheduleIndex =
                                                                                                                        updatedSchedules.findIndex(
                                                                                                                            (schedule) =>
                                                                                                                                schedule.key ===
                                                                                                                                tabValue
                                                                                                                        );

                                                                                                                    if (
                                                                                                                        scheduleIndex !== -1
                                                                                                                    ) {
                                                                                                                        if (
                                                                                                                            !updatedSchedules[
                                                                                                                                scheduleIndex
                                                                                                                            ].scheduleVia
                                                                                                                        ) {
                                                                                                                            updatedSchedules[
                                                                                                                                scheduleIndex
                                                                                                                            ]["scheduleVia"] =
                                                                                                                                [];
                                                                                                                            updatedSchedules[
                                                                                                                                scheduleIndex
                                                                                                                            ]["scheduleVia"][
                                                                                                                                index
                                                                                                                            ] = {};
                                                                                                                        }
                                                                                                                        if (
                                                                                                                            !updatedSchedules[
                                                                                                                            scheduleIndex
                                                                                                                            ]["scheduleVia"][
                                                                                                                            index
                                                                                                                            ]
                                                                                                                        ) {
                                                                                                                            updatedSchedules[
                                                                                                                                scheduleIndex
                                                                                                                            ]["scheduleVia"][
                                                                                                                                index
                                                                                                                            ] = {};
                                                                                                                        }
                                                                                                                        updatedSchedules[
                                                                                                                            scheduleIndex
                                                                                                                        ]["scheduleVia"][
                                                                                                                            index
                                                                                                                        ]["STA"] =
                                                                                                                            formattedTime;
                                                                                                                        // &&&&

                                                                                                                        const AT =
                                                                                                                            formattedTime;
                                                                                                                        const DT =
                                                                                                                            schedules?.[
                                                                                                                                scheduleIndex
                                                                                                                            ]?.scheduleVia[
                                                                                                                                index
                                                                                                                            ]?.STD;

                                                                                                                        const halt =
                                                                                                                            calculateHalt(
                                                                                                                                AT,
                                                                                                                                DT,
                                                                                                                                schedules?.[
                                                                                                                                    scheduleIndex
                                                                                                                                ]?.scheduleVia[
                                                                                                                                index
                                                                                                                                ]
                                                                                                                            );
                                                                                                                        updatedSchedules[
                                                                                                                            scheduleIndex
                                                                                                                        ]["scheduleVia"][
                                                                                                                            index
                                                                                                                        ]["halt"] = halt;
                                                                                                                        const indexForHalt =
                                                                                                                            haltAtViaPoints.findIndex(
                                                                                                                                (item) =>
                                                                                                                                    item.key ===
                                                                                                                                    index
                                                                                                                            );
                                                                                                                        if (
                                                                                                                            indexForHalt !== -1
                                                                                                                        ) {
                                                                                                                            // If an object with the key exists, update its halt value
                                                                                                                            setHaltAtViaPoints(
                                                                                                                                (prevData) => [
                                                                                                                                    ...prevData.slice(
                                                                                                                                        0,
                                                                                                                                        indexForHalt
                                                                                                                                    ),
                                                                                                                                    {
                                                                                                                                        key: index,
                                                                                                                                        halt: halt,
                                                                                                                                    },
                                                                                                                                    ...prevData.slice(
                                                                                                                                        index + 1
                                                                                                                                    ),
                                                                                                                                ]
                                                                                                                            );
                                                                                                                        } else {
                                                                                                                            setHaltAtViaPoints(
                                                                                                                                (prevData) => [
                                                                                                                                    ...prevData,
                                                                                                                                    {
                                                                                                                                        key: index,
                                                                                                                                        halt: halt,
                                                                                                                                    },
                                                                                                                                ]
                                                                                                                            );
                                                                                                                        }
                                                                                                                    }
                                                                                                                }
                                                                                                                onChange(time);
                                                                                                            }}
                                                                                                        />
                                                                                                    );
                                                                                                }}
                                                                                            />
                                                                                        </Box>
                                                                                    </Grid>
                                                                                )}
                                                                        </Grid>

                                                                        {scheduleCustomDay[
                                                                            `scheduleViaPointArrivalDay${tabValue}_${index}`
                                                                        ] === "custom" && (
                                                                                <Grid
                                                                                    item
                                                                                    container
                                                                                    md={12}
                                                                                    display={"flex"}
                                                                                    sx={{ justifyContent: "space-between" }}
                                                                                >
                                                                                    <Grid item md={5.8} pb={1}>
                                                                                        <Box pb={1}>
                                                                                            <Controller
                                                                                                name={`scheduleViaPointCustomArrivalDay${tabValue}_${index}`}
                                                                                                control={control}
                                                                                                rules={{
                                                                                                    required: {
                                                                                                        value: true,
                                                                                                        message: "Please add Day",
                                                                                                    },
                                                                                                }}
                                                                                                render={({
                                                                                                    field: {
                                                                                                        onChange,
                                                                                                        value,
                                                                                                        name,
                                                                                                    },
                                                                                                }) => {
                                                                                                    return (
                                                                                                        <CustomInput
                                                                                                            placeholder="Select Day"
                                                                                                            selectStylesOverride={
                                                                                                                selectStylesOverride
                                                                                                            }
                                                                                                            inputClasses={inputClasses}
                                                                                                            type="number"
                                                                                                            name={name}
                                                                                                            value={value || ""}
                                                                                                            // value={(schedules[tabValue]?.scheduleVia && schedules[tabValue]?.scheduleVia[index].length > 0 && Number(schedules[tabValue]?.scheduleVia[index]?.["dayArrival"]) ? Number(schedules[tabValue]?.scheduleVia[index]?.["dayArrival"]) : value ? value : "") || ""}
                                                                                                            label="Select Day"
                                                                                                            onChange={(e) => {
                                                                                                                // console.log('sleect day', schedules, tabValue, index)
                                                                                                                const updatedSchedules = [
                                                                                                                    ...schedules,
                                                                                                                ]; // Create a copy of the original array
                                                                                                                const scheduleIndex =
                                                                                                                    updatedSchedules.findIndex(
                                                                                                                        (schedule) =>
                                                                                                                            schedule.key ===
                                                                                                                            tabValue
                                                                                                                    );

                                                                                                                if (
                                                                                                                    updatedSchedules[
                                                                                                                        scheduleIndex
                                                                                                                    ]?.scheduleVia?.[index]
                                                                                                                ) {
                                                                                                                    updatedSchedules[
                                                                                                                        scheduleIndex
                                                                                                                    ].scheduleVia[
                                                                                                                        index
                                                                                                                    ].STA = null;
                                                                                                                    updatedSchedules[
                                                                                                                        scheduleIndex
                                                                                                                    ].scheduleVia[
                                                                                                                        index
                                                                                                                    ].halt = null;
                                                                                                                }
                                                                                                                setSchedules(
                                                                                                                    updatedSchedules
                                                                                                                );
                                                                                                                if (
                                                                                                                    scheduleIndex !== -1
                                                                                                                ) {
                                                                                                                    if (
                                                                                                                        !updatedSchedules[
                                                                                                                            scheduleIndex
                                                                                                                        ].scheduleVia
                                                                                                                    ) {
                                                                                                                        updatedSchedules[
                                                                                                                            scheduleIndex
                                                                                                                        ]["scheduleVia"] = [];
                                                                                                                        updatedSchedules[
                                                                                                                            scheduleIndex
                                                                                                                        ]["scheduleVia"][
                                                                                                                            index
                                                                                                                        ] = {};
                                                                                                                    }
                                                                                                                    if (
                                                                                                                        !updatedSchedules[
                                                                                                                        scheduleIndex
                                                                                                                        ]["scheduleVia"][
                                                                                                                        index
                                                                                                                        ]
                                                                                                                    ) {
                                                                                                                        updatedSchedules[
                                                                                                                            scheduleIndex
                                                                                                                        ]["scheduleVia"][
                                                                                                                            index
                                                                                                                        ] = {};
                                                                                                                    }
                                                                                                                    if (
                                                                                                                        e.target.value < 3
                                                                                                                    ) {
                                                                                                                        updatedSchedules[
                                                                                                                            scheduleIndex
                                                                                                                        ]["scheduleVia"][
                                                                                                                            index
                                                                                                                        ]["dayArrival"] = 3;
                                                                                                                    } else {
                                                                                                                        updatedSchedules[
                                                                                                                            scheduleIndex
                                                                                                                        ]["scheduleVia"][
                                                                                                                            index
                                                                                                                        ]["dayArrival"] =
                                                                                                                            e.target.value;
                                                                                                                    }
                                                                                                                }

                                                                                                                onChange(
                                                                                                                    e.target.value > 2
                                                                                                                        ? e.target.value
                                                                                                                        : ""
                                                                                                                );
                                                                                                            }}
                                                                                                            minValue={3}
                                                                                                        />
                                                                                                    );
                                                                                                }}
                                                                                            />
                                                                                            <FormHelperText
                                                                                                style={{ fontSize: "11px" }}
                                                                                            >
                                                                                                Day value should be more than{" "}
                                                                                                <b>2</b>.
                                                                                            </FormHelperText>
                                                                                        </Box>
                                                                                    </Grid>
                                                                                    <Grid item md={5.8} pb={1}>
                                                                                        <Controller
                                                                                            name={`scheduleViaPointArrivalTime${tabValue}_${index}`}
                                                                                            control={control}
                                                                                            rules={{
                                                                                                required: {
                                                                                                    value: true,
                                                                                                    message: "Time is required",
                                                                                                },
                                                                                            }}
                                                                                            render={({
                                                                                                field: { onChange, value, name },
                                                                                            }) => {
                                                                                                return (
                                                                                                    <CustomTimeField
                                                                                                        selectStylesOverride={
                                                                                                            selectStylesOverride
                                                                                                        }
                                                                                                        label="Time"
                                                                                                        size="small"
                                                                                                        name={name}
                                                                                                        errors={errors}
                                                                                                        value={
                                                                                                            schedules[tabValue] &&
                                                                                                                schedules[tabValue]
                                                                                                                    ?.scheduleVia &&
                                                                                                                schedules[tabValue]
                                                                                                                    ?.scheduleVia[index]?.STA
                                                                                                                ? convertHHMMToDate(
                                                                                                                    schedules[tabValue]
                                                                                                                        ?.scheduleVia[index]
                                                                                                                        ?.STA
                                                                                                                )
                                                                                                                : null
                                                                                                        }
                                                                                                        onChange={(time) => {
                                                                                                            if (
                                                                                                                new Date(
                                                                                                                    time
                                                                                                                ).toString() !==
                                                                                                                "Invalid Date"
                                                                                                            ) {
                                                                                                                const formattedTime =
                                                                                                                    format(time, "HH:mm");

                                                                                                                const updatedSchedules = [
                                                                                                                    ...schedules,
                                                                                                                ]; // Create a copy of the original array
                                                                                                                const scheduleIndex =
                                                                                                                    updatedSchedules.findIndex(
                                                                                                                        (schedule) =>
                                                                                                                            schedule.key ===
                                                                                                                            tabValue
                                                                                                                    );

                                                                                                                if (
                                                                                                                    scheduleIndex !== -1
                                                                                                                ) {
                                                                                                                    if (
                                                                                                                        !updatedSchedules[
                                                                                                                            scheduleIndex
                                                                                                                        ].scheduleVia
                                                                                                                    ) {
                                                                                                                        updatedSchedules[
                                                                                                                            scheduleIndex
                                                                                                                        ]["scheduleVia"] = [];
                                                                                                                        updatedSchedules[
                                                                                                                            scheduleIndex
                                                                                                                        ]["scheduleVia"][
                                                                                                                            index
                                                                                                                        ] = {};
                                                                                                                    }
                                                                                                                    if (
                                                                                                                        !updatedSchedules[
                                                                                                                        scheduleIndex
                                                                                                                        ]["scheduleVia"][
                                                                                                                        index
                                                                                                                        ]
                                                                                                                    ) {
                                                                                                                        updatedSchedules[
                                                                                                                            scheduleIndex
                                                                                                                        ]["scheduleVia"][
                                                                                                                            index
                                                                                                                        ] = {};
                                                                                                                    }
                                                                                                                    updatedSchedules[
                                                                                                                        scheduleIndex
                                                                                                                    ]["scheduleVia"][index][
                                                                                                                        "STA"
                                                                                                                    ] = formattedTime;

                                                                                                                    const AT =
                                                                                                                        formattedTime;
                                                                                                                    const DT =
                                                                                                                        schedules?.[
                                                                                                                            scheduleIndex
                                                                                                                        ]?.scheduleVia[index]
                                                                                                                            ?.STD;

                                                                                                                    const halt =
                                                                                                                        calculateHalt(
                                                                                                                            AT,
                                                                                                                            DT,
                                                                                                                            schedules?.[
                                                                                                                                scheduleIndex
                                                                                                                            ]?.scheduleVia[
                                                                                                                            index
                                                                                                                            ]
                                                                                                                        );
                                                                                                                    updatedSchedules[
                                                                                                                        scheduleIndex
                                                                                                                    ]["scheduleVia"][index][
                                                                                                                        "halt"
                                                                                                                    ] = halt;
                                                                                                                    const indexForHalt =
                                                                                                                        haltAtViaPoints.findIndex(
                                                                                                                            (item) =>
                                                                                                                                item.key === index
                                                                                                                        );
                                                                                                                    if (
                                                                                                                        indexForHalt !== -1
                                                                                                                    ) {
                                                                                                                        // If an object with the key exists, update its halt value
                                                                                                                        setHaltAtViaPoints(
                                                                                                                            (prevData) => [
                                                                                                                                ...prevData.slice(
                                                                                                                                    0,
                                                                                                                                    indexForHalt
                                                                                                                                ),
                                                                                                                                {
                                                                                                                                    key: index,
                                                                                                                                    halt: halt,
                                                                                                                                },
                                                                                                                                ...prevData.slice(
                                                                                                                                    index + 1
                                                                                                                                ),
                                                                                                                            ]
                                                                                                                        );
                                                                                                                    } else {
                                                                                                                        setHaltAtViaPoints(
                                                                                                                            (prevData) => [
                                                                                                                                ...prevData,
                                                                                                                                {
                                                                                                                                    key: index,
                                                                                                                                    halt: halt,
                                                                                                                                },
                                                                                                                            ]
                                                                                                                        );
                                                                                                                    }
                                                                                                                }
                                                                                                            }
                                                                                                            onChange(time);
                                                                                                        }}
                                                                                                    />
                                                                                                );
                                                                                            }}
                                                                                        />
                                                                                    </Grid>
                                                                                </Grid>
                                                                            )}

                                                                        <Typography
                                                                            sx={{ ...textClasses.normalText }}
                                                                            pb={2}
                                                                        >
                                                                            Schedule Time of Departure*
                                                                        </Typography>

                                                                        <Grid
                                                                            item
                                                                            container
                                                                            md={12}
                                                                            display={"flex"}
                                                                            sx={{ justifyContent: "space-between" }}
                                                                        >
                                                                            <Grid item md={5.8} pb={1}>
                                                                                <Box pb={1}>
                                                                                    <Controller
                                                                                        name={`scheduleViaPointDepartureDay${tabValue}_${index}`}
                                                                                        control={control}
                                                                                        rules={{
                                                                                            required: {
                                                                                                value: true,
                                                                                                message: "Please add Day",
                                                                                            },
                                                                                        }}
                                                                                        render={({
                                                                                            field: { onChange, value, name },
                                                                                        }) => {
                                                                                            return (
                                                                                                <CustomSelect
                                                                                                    selectStylesOverride={
                                                                                                        selectStylesOverride
                                                                                                    }
                                                                                                    // defaultValue={
                                                                                                    //   dayOptions?.find(
                                                                                                    //     (option) =>
                                                                                                    //       option?.value ===
                                                                                                    //       (schedules[tabValue] &&
                                                                                                    //         schedules[tabValue][
                                                                                                    //         "scheduleVia"
                                                                                                    //         ] &&
                                                                                                    //         schedules[tabValue][
                                                                                                    //           "scheduleVia"
                                                                                                    //         ][index]?.dayDeparture)
                                                                                                    //   ) || null
                                                                                                    // }
                                                                                                    // value={
                                                                                                    //   schedules[tabValue] &&
                                                                                                    //     schedules[tabValue]
                                                                                                    //       ?.scheduleVia &&
                                                                                                    //     schedules[tabValue]
                                                                                                    //       ?.scheduleVia[index]
                                                                                                    //       ?.dayDeparture
                                                                                                    //     ? dayOptions.find(
                                                                                                    //       (option) =>
                                                                                                    //         option?.value ===
                                                                                                    //         schedules[tabValue]
                                                                                                    //           ?.scheduleVia[index]
                                                                                                    //           ?.dayDeparture
                                                                                                    //     ) ??
                                                                                                    //     dayOptions.find(
                                                                                                    //       (option) =>
                                                                                                    //         option?.value ===
                                                                                                    //         "custom"
                                                                                                    //     )
                                                                                                    //     : null
                                                                                                    // }
                                                                                                    value={
                                                                                                        scheduleCustomDay[
                                                                                                            `scheduleViaPointDepartureDay${tabValue}_${index}`
                                                                                                        ] === "sameDay"
                                                                                                            ? {
                                                                                                                value: "sameDay",
                                                                                                                label: "Same Day",
                                                                                                            }
                                                                                                            : scheduleCustomDay[
                                                                                                                `scheduleViaPointDepartureDay${tabValue}_${index}`
                                                                                                            ] === "nextDay"
                                                                                                                ? {
                                                                                                                    value: "nextDay",
                                                                                                                    label: "Next Day",
                                                                                                                }
                                                                                                                : scheduleCustomDay[
                                                                                                                    `scheduleViaPointDepartureDay${tabValue}_${index}`
                                                                                                                ] === "custom"
                                                                                                                    ? {
                                                                                                                        value: "custom",
                                                                                                                        label: "Custom",
                                                                                                                    }
                                                                                                                    : null
                                                                                                    }
                                                                                                    onChange={(e, newVal) => {
                                                                                                        const updatedSchedules = [
                                                                                                            ...schedules,
                                                                                                        ]; // Create a copy of the original array
                                                                                                        const scheduleIndex =
                                                                                                            updatedSchedules.findIndex(
                                                                                                                (schedule) =>
                                                                                                                    schedule.key ===
                                                                                                                    tabValue
                                                                                                            );

                                                                                                        if (
                                                                                                            newVal?.value !== "custom"
                                                                                                        ) {
                                                                                                            if (
                                                                                                                updatedSchedules[
                                                                                                                    scheduleIndex
                                                                                                                ]?.scheduleVia?.[index]
                                                                                                            ) {
                                                                                                                updatedSchedules[
                                                                                                                    scheduleIndex
                                                                                                                ].scheduleVia[
                                                                                                                    index
                                                                                                                ].STD = null;
                                                                                                                updatedSchedules[
                                                                                                                    scheduleIndex
                                                                                                                ].scheduleVia[
                                                                                                                    index
                                                                                                                ].halt = null;
                                                                                                            }
                                                                                                            setSchedules(
                                                                                                                updatedSchedules
                                                                                                            );
                                                                                                            if (
                                                                                                                scheduleIndex !== -1
                                                                                                            ) {
                                                                                                                if (
                                                                                                                    !updatedSchedules[
                                                                                                                        scheduleIndex
                                                                                                                    ].scheduleVia
                                                                                                                ) {
                                                                                                                    updatedSchedules[
                                                                                                                        scheduleIndex
                                                                                                                    ]["scheduleVia"] = [];
                                                                                                                    updatedSchedules[
                                                                                                                        scheduleIndex
                                                                                                                    ]["scheduleVia"][
                                                                                                                        index
                                                                                                                    ] = {};
                                                                                                                }
                                                                                                                if (
                                                                                                                    !updatedSchedules[
                                                                                                                    scheduleIndex
                                                                                                                    ]["scheduleVia"][
                                                                                                                    index
                                                                                                                    ]
                                                                                                                ) {
                                                                                                                    updatedSchedules[
                                                                                                                        scheduleIndex
                                                                                                                    ]["scheduleVia"][
                                                                                                                        index
                                                                                                                    ] = {};
                                                                                                                }
                                                                                                                updatedSchedules[
                                                                                                                    scheduleIndex
                                                                                                                ]["scheduleVia"][index][
                                                                                                                    "dayDeparture"
                                                                                                                ] = newVal?.value;
                                                                                                            }
                                                                                                        } else {
                                                                                                            if (
                                                                                                                updatedSchedules[
                                                                                                                    scheduleIndex
                                                                                                                ]?.scheduleVia?.[index]
                                                                                                            ) {
                                                                                                                updatedSchedules[
                                                                                                                    scheduleIndex
                                                                                                                ].scheduleVia[index][
                                                                                                                    "dayDeparture"
                                                                                                                ] = "custom";
                                                                                                                updatedSchedules[
                                                                                                                    scheduleIndex
                                                                                                                ].scheduleVia[
                                                                                                                    index
                                                                                                                ].STD = null;
                                                                                                                updatedSchedules[
                                                                                                                    scheduleIndex
                                                                                                                ].scheduleVia[
                                                                                                                    index
                                                                                                                ].halt = null;
                                                                                                                setValue(
                                                                                                                    `scheduleViaPointCustomDepartureDay${tabValue}_${index}`,
                                                                                                                    ""
                                                                                                                );
                                                                                                                setSchedules(
                                                                                                                    updatedSchedules
                                                                                                                );
                                                                                                            }
                                                                                                        }

                                                                                                        const scheduleViaPoint = {
                                                                                                            [`scheduleViaPointDepartureDay${tabValue}_${index}`]:
                                                                                                                newVal?.value,
                                                                                                        };

                                                                                                        setScheduleCustomDay(
                                                                                                            (prev) => ({
                                                                                                                ...prev,
                                                                                                                ...scheduleViaPoint,
                                                                                                            })
                                                                                                        );

                                                                                                        onChange(newVal);
                                                                                                    }}
                                                                                                    name={name}
                                                                                                    required
                                                                                                    label="Schedule Day"
                                                                                                    errors={errors}
                                                                                                    getOptionLabel={(option) => {
                                                                                                        return option?.label;
                                                                                                    }}
                                                                                                    filterSelectedOptions
                                                                                                    options={dayOptions}
                                                                                                />
                                                                                            );
                                                                                        }}
                                                                                    />
                                                                                </Box>
                                                                            </Grid>
                                                                            {scheduleCustomDay[
                                                                                `scheduleViaPointDepartureDay${tabValue}_${index}`
                                                                            ] !== "custom" && (
                                                                                    <Grid item md={5.8}>
                                                                                        <Controller
                                                                                            name={`scheduleViaPointDepartureTime${tabValue}_${index}`}
                                                                                            control={control}
                                                                                            rules={{
                                                                                                required: {
                                                                                                    value: true,
                                                                                                    message: "Time is required",
                                                                                                },
                                                                                            }}
                                                                                            render={({
                                                                                                field: { onChange, value, name },
                                                                                            }) => {
                                                                                                return (
                                                                                                    <CustomTimeField
                                                                                                        label="Time"
                                                                                                        size="small"
                                                                                                        name={name}
                                                                                                        errors={errors}
                                                                                                        selectStylesOverride={
                                                                                                            selectStylesOverride
                                                                                                        }
                                                                                                        value={
                                                                                                            schedules[tabValue] &&
                                                                                                                schedules[tabValue]
                                                                                                                    ?.scheduleVia &&
                                                                                                                schedules[tabValue]
                                                                                                                    ?.scheduleVia[index]?.STD
                                                                                                                ? convertHHMMToDate(
                                                                                                                    schedules[tabValue]
                                                                                                                        ?.scheduleVia[index]
                                                                                                                        ?.STD
                                                                                                                )
                                                                                                                : null
                                                                                                        }
                                                                                                        onChange={(time) => {
                                                                                                            if (
                                                                                                                new Date(
                                                                                                                    time
                                                                                                                ).toString() !==
                                                                                                                "Invalid Date"
                                                                                                            ) {
                                                                                                                const formattedTime =
                                                                                                                    format(time, "HH:mm");
                                                                                                                const newDate = new Date(
                                                                                                                    time
                                                                                                                );
                                                                                                                // console.log('time2==', newDate, formattedTime, schedules)
                                                                                                                const updatedSchedules = [
                                                                                                                    ...schedules,
                                                                                                                ]; // Create a copy of the original array
                                                                                                                const scheduleIndex =
                                                                                                                    updatedSchedules.findIndex(
                                                                                                                        (schedule) =>
                                                                                                                            schedule.key ===
                                                                                                                            tabValue
                                                                                                                    );

                                                                                                                if (
                                                                                                                    scheduleIndex !== -1
                                                                                                                ) {
                                                                                                                    if (
                                                                                                                        !updatedSchedules[
                                                                                                                            scheduleIndex
                                                                                                                        ].scheduleVia
                                                                                                                    ) {
                                                                                                                        updatedSchedules[
                                                                                                                            scheduleIndex
                                                                                                                        ]["scheduleVia"] = [];
                                                                                                                        updatedSchedules[
                                                                                                                            scheduleIndex
                                                                                                                        ]["scheduleVia"][
                                                                                                                            index
                                                                                                                        ] = {};
                                                                                                                    }
                                                                                                                    if (
                                                                                                                        !updatedSchedules[
                                                                                                                        scheduleIndex
                                                                                                                        ]["scheduleVia"][
                                                                                                                        index
                                                                                                                        ]
                                                                                                                    ) {
                                                                                                                        updatedSchedules[
                                                                                                                            scheduleIndex
                                                                                                                        ]["scheduleVia"][
                                                                                                                            index
                                                                                                                        ] = {};
                                                                                                                    }
                                                                                                                    updatedSchedules[
                                                                                                                        scheduleIndex
                                                                                                                    ]["scheduleVia"][index][
                                                                                                                        "STD"
                                                                                                                    ] = formattedTime;
                                                                                                                }
                                                                                                                const AT =
                                                                                                                    schedules?.[
                                                                                                                        scheduleIndex
                                                                                                                    ]?.scheduleVia[index]
                                                                                                                        ?.STA;
                                                                                                                const DT = formattedTime;

                                                                                                                const halt =
                                                                                                                    calculateHalt(
                                                                                                                        AT,
                                                                                                                        DT,
                                                                                                                        schedules?.[
                                                                                                                            scheduleIndex
                                                                                                                        ]?.scheduleVia[index]
                                                                                                                    );
                                                                                                                updatedSchedules[
                                                                                                                    scheduleIndex
                                                                                                                ]["scheduleVia"][index][
                                                                                                                    "halt"
                                                                                                                ] = halt;
                                                                                                                const indexForHalt =
                                                                                                                    haltAtViaPoints.findIndex(
                                                                                                                        (item) =>
                                                                                                                            item.key === index
                                                                                                                    );
                                                                                                                if (indexForHalt !== -1) {
                                                                                                                    // If an object with the key exists, update its halt value
                                                                                                                    setHaltAtViaPoints(
                                                                                                                        (prevData) => [
                                                                                                                            ...prevData.slice(
                                                                                                                                0,
                                                                                                                                indexForHalt
                                                                                                                            ),
                                                                                                                            {
                                                                                                                                key: index,
                                                                                                                                halt: halt,
                                                                                                                            },
                                                                                                                            ...prevData.slice(
                                                                                                                                index + 1
                                                                                                                            ),
                                                                                                                        ]
                                                                                                                    );
                                                                                                                } else {
                                                                                                                    setHaltAtViaPoints(
                                                                                                                        (prevData) => [
                                                                                                                            ...prevData,
                                                                                                                            {
                                                                                                                                key: index,
                                                                                                                                halt: halt,
                                                                                                                            },
                                                                                                                        ]
                                                                                                                    );
                                                                                                                }
                                                                                                            }

                                                                                                            onChange(time);
                                                                                                        }}
                                                                                                    />
                                                                                                );
                                                                                            }}
                                                                                        />
                                                                                    </Grid>
                                                                                )}
                                                                        </Grid>

                                                                        {scheduleCustomDay[
                                                                            `scheduleViaPointDepartureDay${tabValue}_${index}`
                                                                        ] === "custom" && (
                                                                                <Grid
                                                                                    item
                                                                                    container
                                                                                    md={12}
                                                                                    display={"flex"}
                                                                                    sx={{ justifyContent: "space-between" }}
                                                                                >
                                                                                    <Grid item md={5.8} pb={1}>
                                                                                        <Box pb={1}>
                                                                                            <Controller
                                                                                                name={`scheduleViaPointCustomDepartureDay${tabValue}_${index}`}
                                                                                                control={control}
                                                                                                rules={{
                                                                                                    required: {
                                                                                                        value: true,
                                                                                                        message: "Please add Day",
                                                                                                    },
                                                                                                }}
                                                                                                render={({
                                                                                                    field: {
                                                                                                        onChange,
                                                                                                        value,
                                                                                                        name,
                                                                                                    },
                                                                                                }) => {
                                                                                                    return (
                                                                                                        <CustomInput
                                                                                                            placeholder="Select Day"
                                                                                                            selectStylesOverride={
                                                                                                                selectStylesOverride
                                                                                                            }
                                                                                                            inputClasses={inputClasses}
                                                                                                            type="number"
                                                                                                            name={name}
                                                                                                            value={value || ""}
                                                                                                            label={"Select Day"}
                                                                                                            onChange={(e) => {
                                                                                                                const updatedSchedules = [
                                                                                                                    ...schedules,
                                                                                                                ]; // Create a copy of the original array
                                                                                                                const scheduleIndex =
                                                                                                                    updatedSchedules.findIndex(
                                                                                                                        (schedule) =>
                                                                                                                            schedule.key ===
                                                                                                                            tabValue
                                                                                                                    );

                                                                                                                if (
                                                                                                                    updatedSchedules[
                                                                                                                        scheduleIndex
                                                                                                                    ]?.scheduleVia?.[index]
                                                                                                                ) {
                                                                                                                    updatedSchedules[
                                                                                                                        scheduleIndex
                                                                                                                    ].scheduleVia[
                                                                                                                        index
                                                                                                                    ].STD = null;
                                                                                                                    updatedSchedules[
                                                                                                                        scheduleIndex
                                                                                                                    ].scheduleVia[
                                                                                                                        index
                                                                                                                    ].halt = null;
                                                                                                                }
                                                                                                                setSchedules(
                                                                                                                    updatedSchedules
                                                                                                                );

                                                                                                                if (
                                                                                                                    scheduleIndex !== -1
                                                                                                                ) {
                                                                                                                    if (
                                                                                                                        !updatedSchedules[
                                                                                                                            scheduleIndex
                                                                                                                        ].scheduleVia
                                                                                                                    ) {
                                                                                                                        updatedSchedules[
                                                                                                                            scheduleIndex
                                                                                                                        ]["scheduleVia"] = [];
                                                                                                                        updatedSchedules[
                                                                                                                            scheduleIndex
                                                                                                                        ]["scheduleVia"][
                                                                                                                            index
                                                                                                                        ] = {};
                                                                                                                    }
                                                                                                                    if (
                                                                                                                        !updatedSchedules[
                                                                                                                        scheduleIndex
                                                                                                                        ]["scheduleVia"][
                                                                                                                        index
                                                                                                                        ]
                                                                                                                    ) {
                                                                                                                        updatedSchedules[
                                                                                                                            scheduleIndex
                                                                                                                        ]["scheduleVia"][
                                                                                                                            index
                                                                                                                        ] = {};
                                                                                                                    }
                                                                                                                    updatedSchedules[
                                                                                                                        scheduleIndex
                                                                                                                    ]["scheduleVia"][index][
                                                                                                                        "dayDeparture"
                                                                                                                    ] = e.target.value;
                                                                                                                }
                                                                                                                onChange(
                                                                                                                    e.target.value > 2
                                                                                                                        ? e.target.value
                                                                                                                        : ""
                                                                                                                );
                                                                                                            }}
                                                                                                            minValue={3}
                                                                                                        />
                                                                                                    );
                                                                                                }}
                                                                                            />
                                                                                            <FormHelperText
                                                                                                style={{ fontSize: "11px" }}
                                                                                            >
                                                                                                Day value should be more than{" "}
                                                                                                <b>2</b>.
                                                                                            </FormHelperText>
                                                                                        </Box>
                                                                                    </Grid>
                                                                                    <Grid item md={5.8} pb={1}>
                                                                                        <Controller
                                                                                            name={`scheduleViaPointDepartureTime${tabValue}_${index}`}
                                                                                            control={control}
                                                                                            rules={{
                                                                                                required: {
                                                                                                    value: true,
                                                                                                    message: "Time is required",
                                                                                                },
                                                                                            }}
                                                                                            render={({
                                                                                                field: { onChange, value, name },
                                                                                            }) => {
                                                                                                return (
                                                                                                    <CustomTimeField
                                                                                                        label="Time"
                                                                                                        size="small"
                                                                                                        name={name}
                                                                                                        errors={errors}
                                                                                                        selectStylesOverride={
                                                                                                            selectStylesOverride
                                                                                                        }
                                                                                                        value={
                                                                                                            schedules[tabValue] &&
                                                                                                                schedules[tabValue]
                                                                                                                    ?.scheduleVia &&
                                                                                                                schedules[tabValue]
                                                                                                                    ?.scheduleVia[index]?.STD
                                                                                                                ? convertHHMMToDate(
                                                                                                                    schedules[tabValue]
                                                                                                                        ?.scheduleVia[index]
                                                                                                                        ?.STD
                                                                                                                )
                                                                                                                : null
                                                                                                        }
                                                                                                        onChange={(time) => {
                                                                                                            if (
                                                                                                                new Date(
                                                                                                                    time
                                                                                                                ).toString() !==
                                                                                                                "Invalid Date"
                                                                                                            ) {
                                                                                                                const formattedTime =
                                                                                                                    format(time, "HH:mm");
                                                                                                                // console.log('time', formattedTime, schedules)
                                                                                                                const updatedSchedules = [
                                                                                                                    ...schedules,
                                                                                                                ]; // Create a copy of the original array
                                                                                                                const scheduleIndex =
                                                                                                                    updatedSchedules.findIndex(
                                                                                                                        (schedule) =>
                                                                                                                            schedule.key ===
                                                                                                                            tabValue
                                                                                                                    );

                                                                                                                if (
                                                                                                                    scheduleIndex !== -1
                                                                                                                ) {
                                                                                                                    if (
                                                                                                                        !updatedSchedules[
                                                                                                                            scheduleIndex
                                                                                                                        ].scheduleVia
                                                                                                                    ) {
                                                                                                                        updatedSchedules[
                                                                                                                            scheduleIndex
                                                                                                                        ]["scheduleVia"] = [];
                                                                                                                        updatedSchedules[
                                                                                                                            scheduleIndex
                                                                                                                        ]["scheduleVia"][
                                                                                                                            index
                                                                                                                        ] = {};
                                                                                                                    }
                                                                                                                    if (
                                                                                                                        !updatedSchedules[
                                                                                                                        scheduleIndex
                                                                                                                        ]["scheduleVia"][
                                                                                                                        index
                                                                                                                        ]
                                                                                                                    ) {
                                                                                                                        updatedSchedules[
                                                                                                                            scheduleIndex
                                                                                                                        ]["scheduleVia"][
                                                                                                                            index
                                                                                                                        ] = {};
                                                                                                                    }
                                                                                                                    updatedSchedules[
                                                                                                                        scheduleIndex
                                                                                                                    ]["scheduleVia"][index][
                                                                                                                        "STD"
                                                                                                                    ] = formattedTime;
                                                                                                                }
                                                                                                                const AT =
                                                                                                                    schedules?.[
                                                                                                                        scheduleIndex
                                                                                                                    ]?.scheduleVia[index]
                                                                                                                        ?.STA;
                                                                                                                const DT = formattedTime;

                                                                                                                const halt =
                                                                                                                    calculateHalt(
                                                                                                                        AT,
                                                                                                                        DT,
                                                                                                                        schedules?.[
                                                                                                                            scheduleIndex
                                                                                                                        ]?.scheduleVia[index]
                                                                                                                    );
                                                                                                                updatedSchedules[
                                                                                                                    scheduleIndex
                                                                                                                ]["scheduleVia"][index][
                                                                                                                    "halt"
                                                                                                                ] = halt;
                                                                                                                const indexForHalt =
                                                                                                                    haltAtViaPoints.findIndex(
                                                                                                                        (item) =>
                                                                                                                            item.key === index
                                                                                                                    );
                                                                                                                if (indexForHalt !== -1) {
                                                                                                                    // If an object with the key exists, update its halt value
                                                                                                                    setHaltAtViaPoints(
                                                                                                                        (prevData) => [
                                                                                                                            ...prevData.slice(
                                                                                                                                0,
                                                                                                                                indexForHalt
                                                                                                                            ),
                                                                                                                            {
                                                                                                                                key: index,
                                                                                                                                halt: halt,
                                                                                                                            },
                                                                                                                            ...prevData.slice(
                                                                                                                                index + 1
                                                                                                                            ),
                                                                                                                        ]
                                                                                                                    );
                                                                                                                } else {
                                                                                                                    setHaltAtViaPoints(
                                                                                                                        (prevData) => [
                                                                                                                            ...prevData,
                                                                                                                            {
                                                                                                                                key: index,
                                                                                                                                halt: halt,
                                                                                                                            },
                                                                                                                        ]
                                                                                                                    );
                                                                                                                }
                                                                                                            }

                                                                                                            onChange(time);
                                                                                                        }}
                                                                                                    />
                                                                                                );
                                                                                            }}
                                                                                        />
                                                                                    </Grid>
                                                                                </Grid>
                                                                            )}
                                                                    </Box>
                                                                </Grid>
                                                            )}
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </div>
                                        );
                                    })}

                                {/* Destination------- */}
                                <Grid container item xs={12} mt={1} mb={1}>
                                    <Grid item md={1}>
                                        <IconWithArrow name="destination" src={DestinationImg} />
                                    </Grid>
                                    <Grid
                                        item
                                        container
                                        md={10.5}
                                        border={"0.25px solid"}
                                        sx={{ borderColor: (theme) => theme.palette.divider }}
                                    >
                                        <Grid item xs={schedules?.length > 0 ? 6 : 12} p={2}>
                                            <Typography
                                                sx={{
                                                    ...textClasses.normalText,
                                                    paddingBottom: "16px",
                                                }}
                                            >
                                                Destination Address*
                                            </Typography>
                                            {/* <Controller
                                                name="destination"
                                                control={control}
                                                rules={{
                                                    required: {
                                                        value: true,
                                                        message: "Destination is required",
                                                    },
                                                }}
                                                render={({ field: { onChange, value, name } }) => {
                                                    return ( */}
                                            <CustomSelect
                                                noOptionsText={"No geofences found"}
                                                selectStylesOverride={selectStylesOverride}
                                                value={routePayload?.routeDestination || null}
                                                filterOptions={(options) => options}
                                                // name={name}
                                                required
                                                label="Select Geofence"
                                                errors={errors}
                                                getOptionLabel={(option) => { return option?.name; }}
                                                filterSelectedOptions
                                                labelKey="name"
                                                searchValue={searchObject?.destination}
                                                onInputChange={(e) => {
                                                    if (e?.type === "change") {
                                                        setSearchObject((prev) => ({
                                                            ...prev,
                                                            destination: e?.target?.value?.trim(),
                                                        }));
                                                        if (e.target.value === "") {
                                                            handleSearchGeofence(
                                                                "destination",
                                                                null,
                                                                "reset"
                                                            );
                                                        }
                                                    }
                                                }}
                                                onSearch={() => handleSearchGeofence("destination")}
                                                options={destinationGeofenceList?.length > 0 ? destinationGeofenceList : geofenceList}
                                                onChange={(e, newVal) => handleOnChange(newVal, 'destAddress', null)}
                                            />
                                            {/* );
                                                }}
                                            /> */}
                                            {schedules.length !== 0 && (
                                                <Box pt={1}>
                                                    <Typography
                                                        sx={{
                                                            ...textClasses.normalText,
                                                            paddingBottom: "11px",
                                                        }}
                                                    >
                                                        Distance : {routePayload?.routeDestination?.totalDistance}
                                                    </Typography>
                                                    <Typography
                                                        sx={{
                                                            ...textClasses.normalText,
                                                            paddingBottom: "4px",
                                                        }}
                                                    >
                                                        Average Speed : {routePayload?.routeDestination?.speed}
                                                    </Typography>
                                                </Box>
                                            )}
                                        </Grid>
                                        {schedules.length === 0 && (
                                            <Grid container item xs={12} gap={1} p={2}>
                                                <Grid item xs sx={{ paddingLeft: "0 !important" }}>
                                                    <Controller
                                                        name={`distance`}
                                                        control={control}
                                                        render={({ field: { onChange, value, name } }) => {
                                                            return (
                                                                <CustomInput
                                                                    placeholder="Distance"
                                                                    selectStylesOverride={selectStylesOverride}
                                                                    inputClasses={inputClasses}
                                                                    name={name}
                                                                    value={routePayload?.routeDestination?.totalDistance || ""}
                                                                    label="Distance"
                                                                    disabled={
                                                                        (userPermissions &&
                                                                            havePermission(
                                                                                userPermissions,
                                                                                "trip",
                                                                                "flightDetails"
                                                                            )) ||
                                                                            (routeDetails &&
                                                                                Object.keys(routeDetails).length)
                                                                            ? false
                                                                            : true
                                                                    }
                                                                    onChange={(e) => getTimefromDistanceandspeed(e, null, 'dest')}
                                                                />
                                                            );
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item xs sx={{ padding: "0 !important" }}>
                                                    <Controller
                                                        name={`time`}
                                                        control={control}
                                                        render={({ field: { onChange, value, name } }) => {
                                                            return (
                                                                <CustomTimeField
                                                                    label="Time"
                                                                    name={name}
                                                                    errors={errors}
                                                                    fullWidth
                                                                    selectStylesOverride={selectStylesOverride}
                                                                    value={routePayload?.routeDestination?.etaTime || ''}
                                                                    onChange={(e) => getSpeedfromDistanceandtime(e, null, 'dest')}
                                                                />
                                                            );
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item xs sx={{ padding: "0 !important" }}>
                                                    <Controller
                                                        name={`speed`}
                                                        control={control}
                                                        render={({ field: { onChange, value, name } }) => {
                                                            return (
                                                                <CustomInput
                                                                    placeholder="Speed"
                                                                    selectStylesOverride={selectStylesOverride}
                                                                    inputClasses={inputClasses}
                                                                    name={name}
                                                                    value={routePayload?.routeDestination?.speed || ""}
                                                                    label="Speed"
                                                                    onChange={(e) => getTimefromDistance(e, null, 'dest')}
                                                                />
                                                            );
                                                        }}
                                                    />
                                                </Grid>
                                            </Grid>
                                        )}

                                        {schedules.length > 0 && (
                                            <Grid
                                                container
                                                item
                                                xs
                                            // pl={3}
                                            // pr={3}
                                            >
                                                <Box
                                                    width={"100%"}
                                                    md={12}
                                                    pt={2}
                                                    pl={3}
                                                    pr={3}
                                                    borderLeft={"0.1px solid"}
                                                    sx={{ borderColor: (theme) => theme.palette.divider }}
                                                >
                                                    <Typography
                                                        sx={{
                                                            ...textClasses.normalText,
                                                            paddingBottom: "15px",
                                                        }}
                                                    >
                                                        Schedule Time of Arrival*
                                                    </Typography>

                                                    <Grid
                                                        item
                                                        container
                                                        md={12}
                                                        mb={2}
                                                        display={"flex"}
                                                        sx={{ justifyContent: "space-between" }}
                                                    >
                                                        <Grid item md={5.8}>
                                                            <Box>
                                                                <Controller
                                                                    name={`scheduleArrivalDay${tabValue}`}
                                                                    control={control}
                                                                    rules={{
                                                                        required: {
                                                                            value: true,
                                                                            message: "Please add Day",
                                                                        },
                                                                    }}
                                                                    render={({
                                                                        field: { onChange, value, name },
                                                                    }) => {
                                                                        return (
                                                                            <CustomSelect
                                                                                selectStylesOverride={
                                                                                    selectStylesOverride
                                                                                }
                                                                                // defaultValue={
                                                                                //   dayOptions.find(
                                                                                //     (option) =>
                                                                                //       option?.value ===
                                                                                //       schedules[tabValue]?.dayArrival
                                                                                //   ) || null
                                                                                // }
                                                                                value={
                                                                                    schedules[tabValue] &&
                                                                                        schedules[tabValue]?.dayArrival
                                                                                        ? (dayOptions.find(
                                                                                            (option) =>
                                                                                                option?.value ===
                                                                                                schedules[tabValue]
                                                                                                    ?.dayArrival
                                                                                        ) ??
                                                                                            dayOptions?.find(
                                                                                                (option) =>
                                                                                                    option?.value === "custom"
                                                                                            ))
                                                                                        : null
                                                                                }
                                                                                onChange={(e, newVal) => {
                                                                                    const updatedSchedules = [
                                                                                        ...schedules,
                                                                                    ]; // Create a copy of the original array
                                                                                    const scheduleIndex =
                                                                                        updatedSchedules.findIndex(
                                                                                            (schedule) =>
                                                                                                schedule.key === tabValue
                                                                                        );

                                                                                    if (newVal?.value !== "custom") {
                                                                                        if (
                                                                                            updatedSchedules[scheduleIndex]
                                                                                        ) {
                                                                                            updatedSchedules[
                                                                                                scheduleIndex
                                                                                            ].STA = null;
                                                                                        }
                                                                                        setSchedules(updatedSchedules);

                                                                                        if (scheduleIndex !== -1) {
                                                                                            updatedSchedules[scheduleIndex][
                                                                                                "dayArrival"
                                                                                            ] = newVal?.value;
                                                                                        }
                                                                                    } else {
                                                                                        if (
                                                                                            updatedSchedules[scheduleIndex]
                                                                                        ) {
                                                                                            updatedSchedules[
                                                                                                scheduleIndex
                                                                                            ].STA = null;
                                                                                            setSchedules(updatedSchedules);
                                                                                            setValue(
                                                                                                `scheduleCustomArrivalDay${tabValue}`,
                                                                                                ""
                                                                                            );
                                                                                        }
                                                                                    }

                                                                                    const scheduleArrivalDay = {
                                                                                        [`scheduleArrivalDay${tabValue}`]:
                                                                                            newVal?.value,
                                                                                    };
                                                                                    setScheduleDestinationCustomDay(
                                                                                        (prev) => ({
                                                                                            ...prev,
                                                                                            ...scheduleArrivalDay,
                                                                                        })
                                                                                    );
                                                                                    onChange(newVal);
                                                                                }}
                                                                                name={name}
                                                                                required
                                                                                label="Day"
                                                                                errors={errors}
                                                                                getOptionLabel={(option) => {
                                                                                    return option?.label;
                                                                                }}
                                                                                filterSelectedOptions
                                                                                options={dayOptions}
                                                                            />
                                                                        );
                                                                    }}
                                                                />
                                                            </Box>
                                                        </Grid>
                                                        {scheduleDestinationCustomDay[
                                                            `scheduleArrivalDay${tabValue}`
                                                        ] !== "custom" && (
                                                                <Grid item md={5.8}>
                                                                    <Controller
                                                                        name={`scheduleArrivalTime${tabValue}`}
                                                                        control={control}
                                                                        rules={{
                                                                            required: {
                                                                                value: true,
                                                                                message: "Time is required",
                                                                            },
                                                                        }}
                                                                        render={({
                                                                            field: { onChange, value, name },
                                                                        }) => {
                                                                            return (
                                                                                <CustomTimeField
                                                                                    label="Time"
                                                                                    size="small"
                                                                                    name={name}
                                                                                    errors={errors}
                                                                                    selectStylesOverride={
                                                                                        selectStylesOverride
                                                                                    }
                                                                                    value={
                                                                                        schedules[tabValue] &&
                                                                                            schedules[tabValue]?.STA
                                                                                            ? convertHHMMToDate(
                                                                                                schedules[tabValue]?.STA
                                                                                            )
                                                                                            : null
                                                                                    }
                                                                                    onChange={(time) => {
                                                                                        if (
                                                                                            new Date(time).toString() !==
                                                                                            "Invalid Date"
                                                                                        ) {
                                                                                            const formattedTime = format(
                                                                                                time,
                                                                                                "HH:mm"
                                                                                            );

                                                                                            const updatedSchedules = [
                                                                                                ...schedules,
                                                                                            ]; // Create a copy of the original array
                                                                                            const scheduleIndex =
                                                                                                updatedSchedules.findIndex(
                                                                                                    (schedule) =>
                                                                                                        schedule.key === tabValue
                                                                                                );

                                                                                            if (scheduleIndex !== -1) {
                                                                                                updatedSchedules[scheduleIndex][
                                                                                                    "STA"
                                                                                                ] = formattedTime;
                                                                                            }
                                                                                        }
                                                                                        onChange(time);
                                                                                    }}
                                                                                />
                                                                            );
                                                                        }}
                                                                    />
                                                                </Grid>
                                                            )}
                                                    </Grid>

                                                    {scheduleDestinationCustomDay[
                                                        `scheduleArrivalDay${tabValue}`
                                                    ] === "custom" && (
                                                            <Grid
                                                                item
                                                                container
                                                                md={12}
                                                                display={"flex"}
                                                                sx={{ justifyContent: "space-between" }}
                                                            >
                                                                <Grid item md={5.8} pb={1}>
                                                                    <Box pb={2}>
                                                                        <Controller
                                                                            name={`scheduleCustomArrivalDay${tabValue}`}
                                                                            control={control}
                                                                            rules={{
                                                                                required: {
                                                                                    value: true,
                                                                                    message: "Please add Day",
                                                                                },
                                                                            }}
                                                                            render={({
                                                                                field: { onChange, value, name },
                                                                            }) => {
                                                                                return (
                                                                                    <CustomInput
                                                                                        placeholder="Select Day"
                                                                                        selectStylesOverride={
                                                                                            selectStylesOverride
                                                                                        }
                                                                                        inputClasses={inputClasses}
                                                                                        name={name}
                                                                                        value={value || ""}
                                                                                        label="Select Day"
                                                                                        onChange={(e) => {
                                                                                            const updatedSchedules = [
                                                                                                ...schedules,
                                                                                            ]; // Create a copy of the original array
                                                                                            const scheduleIndex =
                                                                                                updatedSchedules.findIndex(
                                                                                                    (schedule) =>
                                                                                                        schedule.key === tabValue
                                                                                                );

                                                                                            if (
                                                                                                updatedSchedules[scheduleIndex]
                                                                                            ) {
                                                                                                updatedSchedules[
                                                                                                    scheduleIndex
                                                                                                ].STA = null;
                                                                                            }
                                                                                            setSchedules(updatedSchedules);

                                                                                            if (scheduleIndex !== -1) {
                                                                                                updatedSchedules[scheduleIndex][
                                                                                                    "dayArrival"
                                                                                                ] = e.target.value;
                                                                                            }
                                                                                            onChange(e.target.value);
                                                                                        }}
                                                                                    />
                                                                                );
                                                                            }}
                                                                        />
                                                                        <FormHelperText style={{ fontSize: "11px" }}>
                                                                            Day value should be more than <b>2</b>.
                                                                        </FormHelperText>
                                                                    </Box>
                                                                </Grid>
                                                                <Grid item md={5.8} pb={1}>
                                                                    <Controller
                                                                        name={`scheduleArrivalTime${tabValue}`}
                                                                        control={control}
                                                                        rules={{
                                                                            required: {
                                                                                value: true,
                                                                                message: "Time is required",
                                                                            },
                                                                        }}
                                                                        render={({
                                                                            field: { onChange, value, name },
                                                                        }) => {
                                                                            return (
                                                                                <CustomTimeField
                                                                                    label="Time"
                                                                                    size="small"
                                                                                    name={name}
                                                                                    errors={errors}
                                                                                    selectStylesOverride={
                                                                                        selectStylesOverride
                                                                                    }
                                                                                    value={
                                                                                        schedules[tabValue] &&
                                                                                            schedules[tabValue]?.STA
                                                                                            ? convertHHMMToDate(
                                                                                                schedules[tabValue]?.STA
                                                                                            )
                                                                                            : null
                                                                                    }
                                                                                    onChange={(time) => {
                                                                                        if (
                                                                                            new Date(time).toString() !==
                                                                                            "Invalid Date"
                                                                                        ) {
                                                                                            const formattedTime = format(
                                                                                                time,
                                                                                                "HH:mm"
                                                                                            );

                                                                                            const updatedSchedules = [
                                                                                                ...schedules,
                                                                                            ]; // Create a copy of the original array
                                                                                            const scheduleIndex =
                                                                                                updatedSchedules.findIndex(
                                                                                                    (schedule) =>
                                                                                                        schedule.key === tabValue
                                                                                                );

                                                                                            if (scheduleIndex !== -1) {
                                                                                                updatedSchedules[scheduleIndex][
                                                                                                    "STA"
                                                                                                ] = formattedTime;
                                                                                            }
                                                                                        }
                                                                                        onChange(time);
                                                                                    }}
                                                                                />
                                                                            );
                                                                        }}
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                        )}
                                                </Box>
                                            </Grid>
                                        )}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>

                    {routeLegsArr?.length ? (
                        <Box width="100%" my={2}>
                            <Typography sx={{ mb: 1 }} variant="body2">
                                Define ideal path to enable route deviation alerts...
                            </Typography>
                            <Box
                                width="100%"
                                height="100%"
                                display="flex"
                                flexDirection="column"
                                gap={1}
                                p={1}
                                sx={{
                                    border: "1px solid",
                                    borderColor: (theme) => theme.palette.divider,
                                    borderRadius: "12px",
                                }}
                            >
                                {routeLegsArr?.map((path, index) => {
                                    return (
                                        <Box display="flex" alignItems="center">
                                            <Box
                                                // height="100px"
                                                width="100%"
                                            >
                                                <Box
                                                    height="80px"
                                                    width="100%"
                                                    sx={{
                                                        border: "1px solid",
                                                        borderColor:
                                                            activePath === index
                                                                ? themeType.default.themeOrange
                                                                : (theme) => theme.palette.divider,
                                                        borderRadius: "12px",
                                                        bgcolor:
                                                            activePath === index
                                                                ? (theme) => theme.palette.divider
                                                                : "transparent",
                                                        padding: 2,
                                                        display: "flex",
                                                        justifyContent: "space-between",
                                                        alignItems: "center",
                                                        cursor: "pointer",
                                                    }}
                                                    onClick={() => setActivePath(index)}
                                                >
                                                    <Box>
                                                        <Typography sx={{ fontWeight: 700 }}>{`Path ${index + 1
                                                            }`}</Typography>
                                                    </Box>
                                                    <Box display="flex" alignItems="center" gap={1}>
                                                        {/* <Button
                                variant="text"
                                size="small"
                                sx={{
                                  textTransform: "capitalize",
                                  textDecoration: "underline",
                                  fontSize: "15px",
                                }}
                              >
                                Rename
                              </Button> */}
                                                        {/* <Button sx={{ ...buttonClasses.lynkitOrangeFill }}>
                                Edit
                              </Button> */}
                                                        {routeLegsArr?.length > 1 ? (
                                                            <>
                                                                {index === activePath && (
                                                                    <IconButton onClick={() => removePath(index)}>
                                                                        <DeleteOutline
                                                                            sx={{
                                                                                color: themeType.default.themeOrange,
                                                                            }}
                                                                        />
                                                                    </IconButton>
                                                                )}
                                                            </>
                                                        ) : null}
                                                    </Box>
                                                </Box>
                                            </Box>
                                            {index === routeLegsArr?.length - 1 &&
                                                routeLegsArr?.length < 3 && (
                                                    <Box>
                                                        <IconButton
                                                            onClick={addNewPath}
                                                        >
                                                            <AddCircle
                                                                sx={{ color: themeType.default.themeGreen }}
                                                            />
                                                        </IconButton>
                                                    </Box>
                                                )}
                                        </Box>
                                    );
                                })}
                            </Box>
                        </Box>
                    ) : null}
                </Box>

                <Grid
                    item
                    display="flex"
                    alignItems="center"
                    position={"relative"}
                    sx={{
                        width: "100%",
                        position: "sticky",
                        height: "55px",
                        bottom: "0px",
                        zIndex: "11",
                        background: isDarkThemeEnabledSelector ? "#383838" : "#fff",
                    }}
                >
                    <Box display={"flex"} gap={"8px"}>
                        {!isEditRoute && (
                            <Box key={0} display={"flex"} alignItems={"center"}>
                                <Checkbox
                                    checked={addMore}
                                    onChange={handleAddMore}
                                    sx={{
                                        "&, &.Mui-checked": {
                                            color: themeType.default.themeOrange,
                                        },
                                    }}
                                />
                                <Typography
                                    sx={{ ...textClasses.normalText, whiteSpace: "nowrap" }}
                                >
                                    Add More Route
                                </Typography>
                            </Box>
                        )}
                    </Box>

                    <Box
                        display={"flex"}
                        gap={"8px"}
                        justifyContent={"center"}
                        sx={{ width: isEditRoute ? "100%" : "80%" }}
                    >
                        <Button
                            variant="outlined"
                            sx={{
                                ...buttonClasses?.lynkitBlackFill,
                                minHeight: "32px",
                                minWidth: "110px",
                                padding: "0px",
                            }}
                            onClick={handleCancel}
                        >
                            Cancel
                        </Button>
                        {userPermissions &&
                            havePermission(userPermissions, "routes", "createRoutes") ? (
                            !isEditRoute ? (
                                <CustomSubmitBtn
                                    label="Create"
                                    variant="contained"
                                    data-testid="create"
                                    sx={{
                                        ...buttonClasses?.lynkitOrangeFill,
                                        minHeight: "32px",
                                        minWidth: "110px",
                                        "&.Mui-disabled": {
                                            backgroundColor: "#827878 !important",
                                            border: `1px solid #827878 !important`,
                                            color: "#EAEAEA !important",
                                        },
                                    }}
                                    type="submit"
                                    onClick={handleSubmit(onSubmit)}
                                    loading={createLoader}
                                    disabled={createLoader}
                                />
                            ) : null
                        ) : null}

                        {userPermissions &&
                            havePermission(userPermissions, "routes", "editRoutes") ? (
                            isEditRoute ? (
                                <CustomSubmitBtn
                                    label="Update"
                                    variant="contained"
                                    data-testid="update"
                                    sx={{
                                        ...buttonClasses?.lynkitOrangeFill,
                                        minHeight: "32px",
                                        minWidth: "110px",
                                        "&.Mui-disabled": {
                                            backgroundColor: "#827878 !important",
                                            border: `1px solid #827878 !important`,
                                            color: "#EAEAEA !important",
                                        },
                                    }}
                                    type="submit"
                                    onClick={handleSubmit(handleEdit)}
                                    loading={updateLoader}
                                    disabled={updateLoader}
                                />



                            ) : null
                        ) : null}
                    </Box>
                </Grid>
            </Grid>
        </>
    );
};

export default NewRouteForm;
