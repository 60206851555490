import { useState } from "react";
import { useSelector } from "react-redux";
import { Col } from "reactstrap";
// import { RefreshCcw } from "react-feather";
import { ReactComponent as SettingsIcon } from "../../../icons/setting2.svg";
import { ReactComponent as FullScreenIcon } from "../../../icons/zoom4.svg";
import { ReactComponent as HideMapIcon } from "../../../icons/HideMap2.svg";
import { ReactComponent as ExcelIcon } from "../../../icons/Xls_Icon.svg";
import Settings from "./Settings";
import XLSX from "sheetjs-style";
// import { downloadAsExcel } from "../../../../../util/util";
import { AiOutlineStar } from "react-icons/ai";
import { Button, Tooltip } from "@mui/material";
import {
  Description,
  Fullscreen,
  Map,
  Refresh,
  Settings as SettingIcon,
  StarBorder,
} from "@mui/icons-material";
import { Workbook } from "xlsx";

const PanelAction = ({
  isMap,
  mapToggle,
  fetchData,
  onViewUnderObserve,
  themeType,
  isDark,
  currentUserDetail,
  handleCreateBoxesForUser,
  openFullScreen,
}) => {
  const [isSettings, setIsSettings] = useState(false);
  const { monitoringData, deviceCount, alertCount, tripCount, monitoringLoading, monitoringCountLoading, alertCountLoading, deviceCountLoading } = useSelector(
    (content) => content.monitoring
  );

  const { buttonClasses } = themeType;

  const excelBtnLoading = monitoringLoading || monitoringCountLoading || alertCountLoading || deviceCountLoading ? true : false

  const handleSettingsToggle = () => setIsSettings(!isSettings);

  const goFull = () => {
    if (
      (document.fullScreenElement && document.fullScreenElement !== null) ||
      (!document.mozFullScreen && !document.webkitIsFullScreen)
    ) {
      if (document.documentElement.requestFullScreen) {
        document
          .getElementById("settingsDashboard__container")
          .requestFullScreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document
          .getElementById("settingsDashboard__container")
          .mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullScreen) {
        document
          .getElementById("settingsDashboard__container")
          .webkitRequestFullScreen(Element.ALLOW_KEYBOARD_INPUT);
      }
      document
        .getElementById("settingsDashboard__container")
        .classList.add("p-5");
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    }
  };

  const downloadDashboardData = () => {
    if (monitoringData && deviceCount && tripCount && alertCount) {
      const mergedCounts = {
        ...(deviceCount ? deviceCount : {}),
        ...(tripCount ? tripCount : {}),
        ...(alertCount ? alertCount : {}),
      };
    
      const workbook = XLSX.utils.book_new();
      const worksheet = XLSX.utils.aoa_to_sheet([["Name", "Value"]]); // Headers
      monitoringData.forEach((section) => {
        if(section?._id !== "Alerts"){
          section.data.forEach((item) => {
            if(item?.isVisible){
              const value = mergedCounts[item.type] || 0; // Use default 0 for missing types
              XLSX.utils.sheet_add_aoa(worksheet, [[item.name, value]], {
                origin: -1,
              }); // Add data to worksheet
            }
          });
        }else{
          section?.data?.map((item) => {
            item?.alertLevelArray?.forEach((alert) => {
              if(alert?.isVisible){
                const value = mergedCounts[alert.type] || 0; // Use default 0 for missing types
                XLSX.utils.sheet_add_aoa(worksheet, [[`${alert?.name} (L${alert?.level})`, value]], {
                  origin: -1,
                }); // Add data to worksheet
              }
            });
          })
        }
      });

      XLSX.utils.book_append_sheet(workbook, worksheet, "Monitoring Data");
      const wbout = XLSX.write(workbook, { bookType: "xlsx", type: "binary" });
      const blob = new Blob([s2ab(wbout)], {
        type: "application/octet-stream",
      });
      const filename = "Monitoring_Data.xlsx";
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        // For IE
        window.navigator.msSaveOrOpenBlob(blob, filename);
      } else {
        // For other browsers
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      }
    }
  };

  // Utility function to convert s to an array buffer
  const s2ab = (s) => {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
    return buf;
  };

  return (
    <>
      <Col
        md
        style={{ gap: "8px" }}
        className="d-flex align-items-center justify-content-end"
      >
        {currentUserDetail &&
          currentUserDetail?.adminSpecificPermissions &&
          monitoringData &&
          monitoringData?.length == 0 && (
            <Button
              sx={{ ...buttonClasses?.lynkitOrangeEmpty }}
              onClick={handleCreateBoxesForUser}
            >
              Create Boxes
            </Button>
          )}

        <Tooltip placement="bottom" arrow title="Fullscreen Mode">
          <span>
            <Button
              variant="contained"
              sx={{
                ...buttonClasses.lynkitOrangeFill,
                px: "8px",
                minWidth: "50px",
              }}
              onClick={openFullScreen}
            >
              <Fullscreen sx={{ fontSize: "1.2rem" }} />
            </Button>
          </span>
        </Tooltip>

        <Tooltip placement="bottom" arrow title="Toggle Map">
          <span>
            <Button
              variant="contained"
              sx={{
                ...buttonClasses.lynkitOrangeFill,
                px: "8px",
                minWidth: "50px",
              }}
              onClick={mapToggle}
            >
              {/* <HideMapIcon
                className="monitoringDashboard-tabs__icon"
                style={{ height: "1.5em", width: "1.5em" }}
              /> */}
              <Map sx={{ fontSize: "1.2rem" }} />
            </Button>
          </span>
        </Tooltip>

        {currentUserDetail && !currentUserDetail?.adminSpecificPermissions && (
          <Tooltip placement="bottom" arrow title="Settings">
            <span>
              <Button
                variant="contained"
                sx={{
                  ...buttonClasses.lynkitOrangeFill,
                  px: "8px",
                  minWidth: "50px",
                  maxHeight: "35px",
                  maxWidth: "35px",
                }}
                onClick={handleSettingsToggle}
              >
                <SettingIcon sx={{ fontSize: "1.2rem" }} />
              </Button>
            </span>
          </Tooltip>
        )}

        <Tooltip placement="bottom" arrow title="Refresh">
          <span>
            <Button
              variant="contained"
              sx={{
                ...buttonClasses.lynkitOrangeFill,
                px: "8px",
                minWidth: "50px",
                maxHeight: "35px",
                maxWidth: "35px",
              }}
              onClick={fetchData}
            >
              <Refresh sx={{ fontSize: "1.2rem" }} />
            </Button>
          </span>
        </Tooltip>

        <Tooltip placement="bottom" arrow title="Download monitoring data">
          <span>
            <Button
              variant="contained"
              sx={{
                ...buttonClasses.lynkitOrangeFill,
                px: "8px",
                minWidth: "50px",
                maxHeight: "35px",
                maxWidth: "35px",
              }}
              disabled={excelBtnLoading}
              onClick={downloadDashboardData}
            >
              <Description sx={{ fontSize: "1.2rem" }} />
            </Button>
          </span>
        </Tooltip>
        <Tooltip placement="bottom" arrow title="Devices under observation">
          <span>
            <Button
              variant="contained"
              sx={{
                ...buttonClasses.lynkitOrangeFill,
                px: "8px",
                minWidth: "50px",
                maxHeight: "35px",
                maxWidth: "35px",
              }}
              onClick={onViewUnderObserve}
            >
              <StarBorder sx={{ fontSize: "1.2rem" }} />
            </Button>
          </span>
        </Tooltip>
      </Col>

      {isSettings ? (
        <Settings
          isModal={isSettings}
          onToggle={handleSettingsToggle}
          themeType={themeType}
          isDark={isDark}
        />
      ) : null}
    </>
  );
};

export default PanelAction;
