import { FullscreenExit } from "@mui/icons-material";
import { AppBar, Dialog, IconButton, Slide, Toolbar, Typography } from "@mui/material";
import React from "react";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const CameraFullScreenMode = ({ open, setOpen }) => {
  return (
    <Dialog
      fullScreen
      open={open}
      onClose={() => setOpen(false)}
      TransitionComponent={Transition}
    >
      <AppBar>
        <Toolbar>
          <IconButton onClick={() => setOpen(false)}>
            <FullscreenExit />
          </IconButton>
          <Typography>Close</Typography>
        </Toolbar>
      </AppBar>
    </Dialog>
  );
};

export default CameraFullScreenMode;
