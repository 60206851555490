import {
  Button,
  Autocomplete,
  TextField,
  Grid,
  MenuItem,
  Paper,
  Menu,
  Checkbox,
  Tab,
  Tabs,
  Typography,
  Switch,
  Box,
  Tooltip,
  FormControl
} from "@mui/material";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import loaderGif from "../../../../static/images/drawer/loader.gif";
import { cloneDeep } from 'lodash';
import Table from "../components/Table";
import {
  Add,
  ExpandMore,
  Edit,
  DisabledByDefaultRounded,
  Delete,
  DoNotDisturbAltOutlined,
  DoneOutlined,
  Details,
  ConstructionOutlined,
} from "@mui/icons-material";
import { useEffect } from "react";
import { useCallback } from "react";
import {
  getAllSettings,
  updateSettings,
  listDynamicKeys,
  getImagesOfOrganisation,
  uploadImagesInOrganisation,
  clearOrganisationImages,
  clearSettings
} from "../../../../redux/actions/Settings";
import { selectStylesOverride } from "../../../../utils/util";
import { useMemo } from "react";
import { Controller, useForm } from "react-hook-form";
import EditTemplateModal from "../components/EditTemplateModal";
import { makeStyles } from "@mui/styles";
import CustomNumberInputField from "../../../../components/FormUI/CustomNumberInputField";
import { getShortText } from "../../../../services/functions";
import EmptyPage from "../../EmptyPage";
import { userDetails, updateUser, currentUserDetails, getAllOrganisations, getAllUsers } from "../../../../redux/actions";
import CustomSelect from "../../../../components/FormUI/CustomSelect";
import { havePermission } from "../../../../utils/permissionCheck";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import { CustomInput } from "../../../../components/FormUI";
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles({
  root: {
    width: "50px",
    height: "24px",
    padding: "0px",
  },
  switchBase: {
    padding: "2px",
    color: "#FF0101",
    "&.Mui-disabled": {
      color: "green",
    },
    "&.Mui-checked": {
      color: "green",
    },
    "&.Mui-checked + .MuiSwitch-track": {
      backgroundColor: "transparent",
    },
  },
  track: {
    border: "0.5px solid #D9D9D9",
    borderRadius: "20px",
    backgroundColor: "transparent",
    opacity: "1 !important",
    "&:after, &:before": {
      fontSize: "11px",
      position: "absolute",
      top: "6px",
      content: "''", // Add an empty content property by default
    },
    "&:after": {
      color: "green",
      top: "4.5px",
      content: "'Yes'",
      left: "5px",
    },
    "&:before": {
      color: "#FF0101",
      content: "'No'",
      right: "7px",
    },
  },
  checked: {
    transform: "translateX(26px) !important",
  },
});


const AlertSettings = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const {
    register,
    control,
    getValues,
    setValue,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  //selectors
  const [selectedImage, setSelectedImage] = useState([]);
  const userPermissions = useSelector((state) => state.auth?.currentUserDetail) || {};
  const alertSettingsData = useSelector(
    (state) => state.settingsReducer.settingsData
  );
  const dynamicKeys = useSelector(
    (state) => state.settingsReducer.dynamicKeysForTemplates
  );
  const organisationImages = useSelector(
    (state) => state.settingsReducer.organisationImages
  );
  const alertSettings = alertSettingsData?.settings;
  const isDarkThemeEnabledSelector = useSelector(
    (state) => state.theme.themeDark
  );

  const themeType = isDarkThemeEnabledSelector
    ? require("../../../../static/styles/darktheme")
    : require("../../../../static/styles/theme");
  const { buttonClasses, inputClasses, textClasses } = themeType;

  //states
  const [tabIndex, setTabIndex] = useState(0);
  const [details, setDetails] = useState({
    orgId: '',
    id: ''
  })
  const [selectedTabName, setSelectedTabName] = useState("");
  const [loader, setLoader] = useState(false);
  const [isEditMode, setEditMode] = useState(false);
  const [dataToSend, setDataToSend] = useState();
  const [alertType, setAlertType] = useState("withTrip");
  const [showModal, setShowModal] = useState(false);
  const [selectedAlert, setSelectedAlert] = useState();
  const [viewPermission, setViewPermission] = useState({
    alert: 'viewAlertPermission',
    threshold: 'viewAlertThresholdPermission',
    email: 'viewEmailTemplate',
    pushNotification: 'viewPushNotificationTemplate'
  })
  const [editPermission, setEditPermission] = useState('editAlertPermission')
  const superAdmin = useSelector((state) => state.auth.currentUserDetail)?.adminSpecificPermissions || {};
  const orgListing = useSelector((state) => state.Organisationlisting.OrganisationListing);
  const Users = useSelector((state) => state.userlist.allUsers);
  const currentUser = useSelector((state) => state.auth.currentUserDetail);
  const [searchFields, setSearchFields] = useState({
    org: null,
    users: null,
  });
  useEffect(() => {

    if (superAdmin && Object.keys(superAdmin).length && selectedTabName) {
      if (selectedTabName === 'permissions') {
        if (details?.orgId && details?.id) {
          let payload = {
            type: "alert",
            orgId: details?.orgId,
            userId: details?.id,
            subType: selectedTabName === 'threshold' ? "threshold" : selectedTabName,
            alertType: alertType,
          }
          setLoader(true);
          dispatch(getAllSettings(payload, (res, error) => {
            setLoader(false);
          }));
        }

      }
      else {
        if (details?.orgId) {
          let payload = {
            type: "alert",
            orgId: details?.orgId,
            subType: selectedTabName === 'threshold' ? "threshold" : selectedTabName,
            alertType: alertType,
          }
          setLoader(true);
          dispatch(getAllSettings(payload, (res, error) => {
            setLoader(false);
          }));
        }
      }
    }
  }, [dispatch, details, selectedTabName, alertType, superAdmin, userPermissions]);

  useEffect(() => {
    if (currentUser) {
      if (!currentUser?.adminSpecificPermissions && selectedTabName) {
        if (selectedTabName === 'permissions') {
          if (details?.id) {
            let payload = {
              type: "alert",
              orgId: userPermissions?.orgId,
              userId: details?.id,
              subType: selectedTabName === 'threshold' ? "threshold" : selectedTabName,
              alertType: alertType,
            }
            setLoader(true);
            dispatch(getAllSettings(payload, (res, error) => {
              setLoader(false);
            }));
          }
        }
        else {
          let payload = {
            type: "alert",
            orgId: userPermissions?.orgId,
            subType: selectedTabName === 'threshold' ? "threshold" : selectedTabName,
            alertType: alertType,
          }
          setLoader(true);
          dispatch(getAllSettings(payload, (res, error) => {
            setLoader(false);
          }));
        }
      } else {
      }
    }
  }, [currentUser, selectedTabName, alertType, details, userPermissions]);

  useEffect(() => {
    if (currentUser) {
      if (!currentUser?.adminSpecificPermissions) {
        if (selectedTabName === 'permissions') {
          let payload = {
            type: "alert",
            orgId: userPermissions?.orgId,
            userId: userPermissions?._id,
            subType: selectedTabName === 'threshold' ? "threshold" : selectedTabName,
            alertType: alertType,
          }
          setValue('userName', userPermissions)
          let temp = { details }
          temp.id = userPermissions?._id
          temp.orgId = userPermissions?.orgId
          setDetails(temp)
          setLoader(true);
          dispatch(getAllSettings(payload, (res, error) => {
            setLoader(false);
          }));
        }
      }
    }
  }, [currentUser, selectedTabName, alertType, userPermissions]);

  useEffect(() => {
    if (userPermissions && Object.keys(userPermissions).length) {
      if (havePermission(userPermissions, "alertSettings", 'viewAlertPermission')) {
        setSelectedTabName('permissions');
      }
      else if (havePermission(userPermissions, "alertSettings", 'viewAlertThresholdPermission')) {
        setSelectedTabName('threshold');
      }
      else if (havePermission(userPermissions, "alertSettings", 'viewEmailTemplate')) {
        setSelectedTabName('templates');
      }
      else if (havePermission(userPermissions, "alertSettings", 'viewPushNotificationTemplate')) {
        setSelectedTabName('pushNotifications');
      }
      else {
      }


    }
  }, [userPermissions])


  useEffect(() => {
    dispatch(clearSettings())
    if (currentUser) {
      if (currentUser?.adminSpecificPermissions) {
        dispatch(getAllOrganisations({ selection: ["name"] }));
      } else {
        dispatch(getAllUsers({ orgId: currentUser?.orgId, selection: ["name", "orgName"] }));
      }
    }
  }, [currentUser]);

  useEffect(() => {
    if (alertSettings) {
      setDataToSend(alertSettings);
    }
  }, [alertSettings]);

  const handleDynamicKeys = useCallback(() => {
    dispatch(listDynamicKeys());
  }, [dispatch]);

  const handleEmailAlert = useCallback(
    (newValue, key) => {
      let temp = dataToSend;
      const keyToChange = key;
      temp[keyToChange]["emailAlert"].value = newValue;
      setDataToSend(temp);
    },
    [dataToSend, selectedTabName]
  );

  const handleSmsAlert = useCallback(
    (newValue, key) => {
      let temp = dataToSend;
      const keyToChange = key;
      temp[keyToChange]["smsAlert"].value = newValue;
      setDataToSend(temp);
    },
    [dataToSend, selectedTabName]
  );

  const handlePushNotificationAlert = useCallback(
    (newValue, key) => {
      let temp = dataToSend;
      const keyToChange = key;
      temp[keyToChange]["pushNotificationAlert"].value = newValue;
      setDataToSend(temp);
    },
    [dataToSend, selectedTabName]
  );

  const handleMapUIAlert = useCallback(
    (newValue, key) => {
      let temp = dataToSend;
      const keyToChange = key;
      if (temp[keyToChange] && temp[keyToChange]["mapUIAlert"]) {
        temp[keyToChange]["mapUIAlert"].value = newValue;
      }
      setDataToSend(temp);
    },
    [dataToSend, selectedTabName]
  );

  // const handleLimitAlert = (newValue, key) => {
  //   let temp = cloneDeep(dataToSend); // Use deep copy
  //   const keyToChange = key;

  //   if (temp[keyToChange] && temp[keyToChange]["limitAlert"]) {
  //     temp[keyToChange]["limitAlert"].value = newValue;
  //     setDataToSend(temp);
  //   }
  // };

  const handleLimitAlert = (value, key) => {
    let temp = cloneDeep(dataToSend);

    if (temp[key] && temp[key]["limitAlert"]) {
      temp[key]["limitAlert"].value = value; // Store the input as it is
      setDataToSend(temp);
    }

    //=====================================
    // let temp = cloneDeep(dataToSend);
  
    // if (temp[key] && temp[key]["limitAlert"]) {
    //   let newValue = parseFloat(value);
    //   if (isNaN(newValue)) {
    //     newValue = 1; 
    //   }
  
    //   if ((key === 'movingUnlockedAlert' || key === 'movingWithoutTripAlert') && newValue > 140) {
    //     newValue = 140;
    //   } else if (key === "routeDeviationAlert" && newValue < 100) {
    //     newValue = 100;
    //   } else if (newValue < 1) {
    //     newValue = 1;
    //   }
  
    //   temp[key]["limitAlert"].value = newValue.toString();
    //   setDataToSend(temp);
    // }
  };

  const handleLimitAlertBlur = (value, key) => {
    let temp = cloneDeep(dataToSend);
  
    if (temp[key] && temp[key]["limitAlert"]) {
      let newValue = parseFloat(value);
      if (isNaN(newValue)) {
        newValue = 1; 
      }
  
      if ((key === 'movingUnlockedAlert' || key === 'movingWithoutTripAlert') && newValue > 140) {
        newValue = 140;
      } else if (key === "routeDeviationAlert" && newValue < 100) {
        newValue = 100;
      } else if (newValue < 1) {
        newValue = 1;
      }
  
      temp[key]["limitAlert"].value = newValue.toString();
      setDataToSend(temp);
    }
  };

  const handleLimitAlertNum = (e, item, key) => {
    let temp = cloneDeep(dataToSend);
  
    if (temp[key] && temp[key]["limitAlert"]) {
      let currentValue = parseFloat(temp[key]["limitAlert"].value);
      if (isNaN(currentValue)) {
        currentValue = 1; 
      }
  
      if (item === 'add') {
        if ((key === 'movingUnlockedAlert' || key === 'movingWithoutTripAlert') && currentValue < 140) {
          temp[key]["limitAlert"].value = (currentValue + 1).toString();
        } else if (key === 'routeDeviationAlert') {
          temp[key]["limitAlert"].value = currentValue < 100 ? "100" : (currentValue + 1).toString();
        } else if (key !== 'movingUnlockedAlert' && key !== 'movingWithoutTripAlert') {
          temp[key]["limitAlert"].value = (currentValue + 1).toString();
        }
      } else {
        if(key === 'routeDeviationAlert'){
          temp[key]["limitAlert"].value = currentValue > 100 ? (currentValue - 1).toString() : "100";
        }else if (currentValue > 1) {
          temp[key]["limitAlert"].value = (currentValue - 1).toString();
        }
      }
  
      setDataToSend(temp);
    }
  };

  const handleSearchUsers = (empty) => {
    if (empty === true) {
      dispatch(
        getAllUsers({
          selection: ["name", "orgName"],
          searchValue: "",
        })
      );
    } else {
      if (searchFields?.users || searchFields?.users == "")
        dispatch(
          getAllUsers({
            selection: ["name", "orgName"],
            searchValue: searchFields?.users,
          })
        );
    }
  };

  const handleSearchOrg = (empty) => {
    if (empty === true) {
      dispatch(getAllOrganisations({ selection: ["name"] }));
    } else {
      if (searchFields?.org || searchFields?.org == "")
        dispatch(
          getAllOrganisations({
            selection: ["name"],
            searchValue: searchFields.org,
          })
        );
    }
  };

  const handleOrganisationList = useCallback(() => {
    dispatch(getImagesOfOrganisation({ orgId: userPermissions?.orgId }, () => { }));
  }, [dispatch, userPermissions]);



  const handleOpenTemplateModal = (entry) => {
    setShowModal(true);
    setSelectedAlert(entry);
    dispatch(
      getImagesOfOrganisation({ orgId: userPermissions?.orgId }, (res) => {
        if (res && res?.length > 0) {
          if (entry?.lynktracLogo === "" && entry?.userLogo === "") {
            // finding organization logo in listing and selecting it initially.
            const temp = [];
            const defaultImg = res?.find(
              (_) => _?.isDefault && _?.isDefault === true
            );
            temp?.push(defaultImg);
            setSelectedImage(temp);
          } else {
            const temp = [];
            res?.map((_) => {
              if (
                _?.path === entry?.lynktracLogo ||
                _?.path === entry?.userLogo
              )
                temp?.push(_);
            });
            setSelectedImage(temp);
          }
        }
      })
    );
  };

  let tableColumns =
    selectedTabName === "permissions"
      ? [
        {
          key: "name",
          header: "Alert Name",
          isCenter: false,
          cell: (entry) => {
            return (
              <>
                <Typography
                  sx={{
                    ...textClasses.t13n,
                    textAlign: "start",
                    fontWeight: 800,
                  }}
                >
                  {entry?.label}
                </Typography>
                <Typography
                  sx={{
                    ...textClasses.t13n,
                    textAlign: "start",
                  }}
                >
                  {entry?.description}
                </Typography>
              </>
            );
          },
        },
        // {
        //   key: "limit",
        //   header: "Limit",
        //   isCenter: true,
        //   cell: (entry) => {
        //     return (
        //       <>
        //         <Typography
        //           sx={{
        //             ...textClasses.t13n,
        //             textAlign: "center",
        //             fontSize: "16px",
        //             fontWeight: 600,
        //           }}
        //         >
        //           {entry?.limitAlert?.isShow ? (
        //             isEditMode ? (
        //               <CustomNumberInputField
        //                 min={1}
        //                 aria-label="Quantity Input"
        //                 selectStylesOverride={selectStylesOverride(
        //                   isDarkThemeEnabledSelector
        //                 )}
        //                 defaultValue={entry?.limitAlert?.value}
        //                 onChange={(e) => {
        //                   handleLimitAlert(e.target.value, entry?.key);
        //                 }}
        //               />
        //             ) : (
        //               entry?.limitAlert?.value
        //             )
        //           ) : (
        //             "-"
        //           )}
        //         </Typography>
        //       </>
        //     );
        //   },
        // },
        {
          key: "emailAlert",
          header: "Email ALert",
          isCenter: true,
          cell: (entry) => {
            return (
              <Typography
                sx={{
                  ...textClasses.t13n,
                  textAlign: "center",
                  fontSize: "16px",
                  fontWeight: 600,
                  color:
                    entry?.emailAlert?.value == true
                      ? themeType?.default.themeGreen
                      : themeType?.default.themeRed,
                }}
              >
                {entry?.emailAlert?.isShow ? (
                  isEditMode ? (
                    <Controller
                      name={`switch_email_alert_${entry?.key}`}
                      control={control}
                      render={({ field: { onChange, value, name } }) => {
                        return (
                          <div>
                            <Switch
                              classes={{
                                root: classes.root,
                                switchBase: classes.switchBase,
                                thumb: classes.thumb,
                                track: classes.track,
                                checked: classes.checked,
                              }}
                              name={name}
                              defaultChecked={entry?.emailAlert?.value}
                              value={value}
                              onChange={(e) => {
                                handleEmailAlert(
                                  e.target.checked,
                                  entry?.key
                                );
                                onChange(e.target.checked);
                              }}
                            />
                          </div>
                        );
                      }}
                    />
                  ) : entry?.emailAlert?.value === true ? (
                    "Yes"
                  ) : (
                    "No"
                  )
                ) : (
                  "-"
                )}
              </Typography>
            );
          },
        },
        {
          key: "smsAlert",
          header: "SMS Alert",
          isCenter: true,
          cell: (entry) => {
            return (
              <Typography
                sx={{
                  ...textClasses.t13n,
                  textAlign: "center",
                  fontSize: "16px",
                  fontWeight: 600,
                  color: entry?.smsAlert?.isShow
                    ? entry?.smsAlert?.value == true
                      ? themeType?.default.themeGreen
                      : themeType?.default.themeRed
                    : "unset",
                }}
              >
                {entry?.smsAlert?.isShow ? (
                  isEditMode ? (
                    <Controller
                      name={`switch_sms_alert_${entry?.key}`}
                      control={control}
                      render={({ field: { onChange, value, name } }) => {
                        return (
                          <div>
                            <Switch
                              classes={{
                                root: classes.root,
                                switchBase: classes.switchBase,
                                thumb: classes.thumb,
                                track: classes.track,
                                checked: classes.checked,
                              }}
                              name={name}
                              defaultChecked={entry?.smsAlert?.value}
                              value={value}
                              onChange={(e) => {
                                handleSmsAlert(e.target.checked, entry?.key);
                                onChange(e.target.checked);
                              }}
                            />
                            {errors[name] && (
                              <span
                                role="alert"
                                style={{ color: "#FF0101", fontSize: 11 }}
                              >
                                {errors[name]?.message}
                              </span>
                            )}
                          </div>
                        );
                      }}
                    />
                  ) : entry?.smsAlert?.value === true ? (
                    "Yes"
                  ) : (
                    "No"
                  )
                ) : (
                  "-"
                )}
              </Typography>
            );
          },
        },
        {
          key: "pushNotification",
          header: "Push Notification",
          isCenter: true,
          cell: (entry) => {
            return (
              <Typography
                sx={{
                  ...textClasses.t13n,
                  textAlign: "center",
                  fontSize: "16px",
                  fontWeight: 600,
                  color: entry?.pushNotificationAlert?.isShow
                    ? entry?.pushNotificationAlert?.value == true
                      ? themeType?.default.themeGreen
                      : themeType?.default.themeRed
                    : "unset",
                }}
              >
                {entry?.pushNotificationAlert?.isShow ? (
                  isEditMode ? (
                    <Controller
                      name={`switch_pushNotification_alert_${entry?.key}`}
                      control={control}
                      render={({ field: { onChange, value, name } }) => {
                        return (
                          <div>
                            <Switch
                              classes={{
                                root: classes.root,
                                switchBase: classes.switchBase,
                                thumb: classes.thumb,
                                track: classes.track,
                                checked: classes.checked,
                              }}
                              name={name}
                              defaultChecked={
                                entry?.pushNotificationAlert?.value
                              }
                              value={value}
                              onChange={(e) => {
                                handlePushNotificationAlert(
                                  e.target.checked,
                                  entry?.key
                                );
                                onChange(e.target.checked);
                              }}
                            />
                            {errors[name] && (
                              <span
                                role="alert"
                                style={{ color: "#FF0101", fontSize: 11 }}
                              >
                                {errors[name]?.message}
                              </span>
                            )}
                          </div>
                        );
                      }}
                    />
                  ) : entry?.pushNotificationAlert?.value === true ? (
                    "Yes"
                  ) : (
                    "No"
                  )
                ) : (
                  "-"
                )}
              </Typography>
            );
          },
        },
        {
          key: "mapUiAlert",
          header: "Map UI Alert",
          isCenter: true,
          cell: (entry) => {
            return (
              <Typography
                sx={{
                  ...textClasses.t13n,
                  textAlign: "center",
                  fontSize: "16px",
                  fontWeight: 600,
                  color: entry?.mapUIAlert?.isShow
                    ? entry?.mapUIAlert?.value == true
                      ? themeType?.default.themeGreen
                      : themeType?.default.themeRed
                    : "unset",
                }}
              >
                {entry?.mapUIAlert?.isShow ? (
                  isEditMode ? (
                    <Controller
                      name={`switch_mapUI_alert_${entry?.key}`}
                      control={control}
                      render={({ field: { onChange, value, name } }) => {
                        return (
                          <div>
                            <Switch
                              classes={{
                                root: classes.root,
                                switchBase: classes.switchBase,
                                thumb: classes.thumb,
                                track: classes.track,
                                checked: classes.checked,
                              }}
                              name={name}
                              defaultChecked={entry?.mapUIAlert?.value}
                              value={value}
                              onChange={(e) => {
                                handleMapUIAlert(
                                  e.target.checked,
                                  entry?.key
                                );
                                onChange(e.target.checked);
                              }}
                            />
                            {errors[name] && (
                              <span
                                role="alert"
                                style={{ color: "#FF0101", fontSize: 11 }}
                              >
                                {errors[name]?.message}
                              </span>
                            )}
                          </div>
                        );
                      }}
                    />
                  ) : entry?.mapUIAlert?.value === true ? (
                    "Yes"
                  ) : (
                    "No"
                  )
                ) : (
                  "-"
                )}
              </Typography>
            );
          },
        },
      ] :
      selectedTabName === "threshold"
        ? [
          {
            key: "name",
            header: "Alert Name",
            isCenter: false,
            cell: (entry) => {
              return (
                <>
                  <Typography
                    sx={{
                      ...textClasses.t13n,
                      textAlign: "start",
                      fontWeight: 800,
                    }}
                  >
                    {entry?.label}
                  </Typography>
                  <Typography
                    sx={{
                      ...textClasses.t13n,
                      textAlign: "start",
                    }}
                  >
                    {entry?.description}
                  </Typography>
                </>
              );
            },
          },
          {
            key: "limit",
            header: "Limit",
            isCenter: true,
            cell: (entry) => {
              return (
                <>
                  <Typography
                    sx={{
                      ...textClasses.t13n,
                      textAlign: "center",
                      fontSize: "16px",
                      fontWeight: 600,
                    }}
                  >
                    {/* {entry?.limitAlert?.isShow ? (
                      isEditMode ? (
                        <CustomNumberInputField
                          min={1}
                          max={entry.key === "movingUnlockedAlert" || entry.key === "movingWithoutTripAlert" ? 140 : undefined}
                          aria-label="Quantity Input"
                          selectStylesOverride={selectStylesOverride(
                            isDarkThemeEnabledSelector
                          )}
                          defaultValue={entry?.limitAlert?.value}
                          onChange={(e) => {
                              handleLimitAlert(e.target.value, entry?.key);
                          }}
                        />
                      ) : (
                        entry?.limitAlert?.value
                      )
                    ) : (
                      "-"
                    )} */}

                    {entry?.limitAlert?.isShow ? (
                      isEditMode ? (
                        <Grid sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                         

                          <Button
                            variant="outlined"
                            size="small"
                            sx={{
                              minWidth: '30px',
                              padding: '3px 3px',
                              marginRight: '9px',
                              height: '29px',
                              marginTop: '1px'
                            }}
                            startIcon={<RemoveIcon sx={{ marginRight: '-4px', marginLeft: '6px' }} />}
                            onClick={(e) => { handleLimitAlertNum(e, 'sub', entry?.key) }}
                          >


                          </Button>
                          <FormControl sx={{ ...selectStylesOverride, width: "65px", height: '32px',display:'flex', textAlign: 'center' }}>
                            <CustomInput
                              required={false}
                              selectStylesOverride={selectStylesOverride(isDarkThemeEnabledSelector)}
                              inputClasses={inputClasses}
                              sx={{
                                ...inputClasses?.textfield,
                                ...selectStylesOverride,
                                height: "32px",
                                display: 'flex',
                                  justifyContent: 'center',
                                  textAlign: 'center',
                              }}
                              centreState={true}
                              value={dataToSend[entry?.key]['limitAlert']?.value}
                              onChange={(e)=>handleLimitAlert(e.target.value, entry?.key)}
                              onBlur={(e) => handleLimitAlertBlur(e.target.value, entry?.key)}
                            />
                          </FormControl>
                          <Button
                            variant="outlined"
                            size="small"
                            sx={{
                              minWidth: '30px',
                              padding: '3px 3px',
                              marginLeft: '9px',
                              height: '29px',
                              marginTop: '1px'
                            }}
                            startIcon={<AddIcon sx={{ marginRight: '-4px', marginLeft: '6px' }} />}
                            onClick={(e) => { handleLimitAlertNum(e, 'add', entry?.key) }}
                          >


                          </Button>
                          
                        </Grid>
                      ) : (
                        entry?.limitAlert?.value
                      )
                    ) : (
                      "-"
                    )}
                  </Typography>
                </>
              );
            },
          },

        ]
        : [
          {
            key: "name",
            header: "Alert Name",
            cell: (entry) => {
              return (
                <>
                  <Typography
                    sx={{
                      ...textClasses.t13n,
                      textAlign: "start",
                      fontWeight: 800,
                    }}
                  >
                    {entry?.label}
                  </Typography>
                  <Typography
                    sx={{
                      ...textClasses.t13n,
                      textAlign: "start",
                    }}
                  >
                    {entry?.description}
                  </Typography>
                </>
              );
            },
          },
          {
            key: "subject",
            header: "Subject",
            cell: (entry) => {
              return (
                <Typography
                  sx={{
                    ...textClasses.t13n,
                    textAlign: "start",
                  }}
                >
                  {entry?.subject}
                </Typography>
              );
            },
          },
          {
            key: "message",
            header: "Message Body",
            cell: (entry) => {
              var tempElement = document.createElement("div");
              tempElement.innerHTML = entry?.message;
              const text =
                tempElement?.textContent || tempElement?.innerText || "";
              return (
                <Tooltip title={text?.length > 15 ? text : ""} placement="top">
                  <Typography
                    sx={{
                      ...textClasses.t13n,
                      textAlign: "start",
                      textTransform: "capitalize",
                    }}
                  >
                    {getShortText(text, 15) ?? "-"}
                  </Typography>
                </Tooltip>
              );
            },
          },
          {
            key: "action",
            header: "Action",
            cell: (entry) => {
              return (
                <>
                  <Typography
                    sx={{
                      ...textClasses.t13n,
                      textAlign: "start",
                    }}
                  >
                    {userPermissions && havePermission(userPermissions, "alertSettings", editPermission) ?
                      <Button
                        variant="outlined"
                        size="small"
                        // startIcon={<Edit />}
                        sx={{
                          padding: 0,
                          minWidth: 0,
                          height: "24px",
                          width: "24px",
                          color: `${isDarkThemeEnabledSelector ? "#808080" : "#696969"
                            }`,
                          border: `1px solid ${isDarkThemeEnabledSelector ? "#808080" : "#696969"
                            }`,
                          "&:hover": {
                            color: `${isDarkThemeEnabledSelector ? "#808080" : "#696969"
                              }`,
                            border: `1px solid ${isDarkThemeEnabledSelector ? "#808080" : "#696969"
                              }`,
                          },
                        }}
                        onClick={() => handleOpenTemplateModal(entry)}
                      >

                        <Edit sx={{ width: "16px", height: "16px" }} />
                      </Button>
                      :
                      <Typography
                        sx={{
                          ...textClasses.t13n,
                          textAlign: "start",
                          paddingLeft: '14px'
                        }}
                      >
                        -
                      </Typography>
                    }
                  </Typography>
                </>
              );
            },
          },
        ];


  const tableData = useMemo(() => {
    return (
      alertSettings &&
      Object.keys(alertSettings)?.map((setting) => {
        let conditionData = alertSettings && alertSettings[setting];
        if (conditionData) {
          conditionData = { ...conditionData, key: setting };
        }
        return conditionData;
      })
    );
  }, [alertSettings, details.id, userPermissions]);


  const tableDataAlert = useMemo(() => {
    if (currentUser) {
      if (currentUser?.adminSpecificPermissions) {
        if (details.orgId && details?.id) {
          return (
            alertSettings &&
            Object.keys(alertSettings)?.map((setting) => {
              let conditionData = alertSettings && alertSettings[setting];
              if (conditionData) {
                conditionData = { ...conditionData, key: setting };
              }
              return conditionData;
            })
          )
        }
        else { return [] }
      }
      else {
        if (details?.id) {
          return (
            alertSettings &&
            Object.keys(alertSettings)?.map((setting) => {
              let conditionData = alertSettings && alertSettings[setting];
              if (conditionData) {
                conditionData = { ...conditionData, key: setting };
              }
              return conditionData;
            })
          )
        }
        else { return [] }
      }
    }

  }, [alertSettings, details.orgId, details?.id, currentUser])


  const handleTabChange = (event, value) => {
    setTabIndex(value);
  };

  const handleUpdate = (data) => {
    let payload = {
      type: "alert",
      orgId: details?.orgId,
      settings: dataToSend,
      label: alertSettingsData?.label,
      subType: selectedTabName,
      subTypeLabel: alertSettingsData?.subTypeLabel,
      alertType: alertType,
    };

    let getPayload = {
      type: "alert",
      orgId: details?.orgId,
      subType: selectedTabName,
      alertType: alertType,
    }
    if (selectedTabName === 'permissions') {
      payload.userId = details?.id;
      getPayload.userId = details?.id;
    }
    if (currentUser) {
      if (!currentUser?.adminSpecificPermissions) {
        payload.orgId = userPermissions?.orgId
      }
    }
    dispatch(
      updateSettings(payload, () => {
        dispatch(
          getAllSettings(getPayload, () => {
            setShowModal(false);
          }
          )
        );
        setEditMode(false);
      })
    );
  };

  const handleUpdateAlert = useCallback(
    (data) => {
      const payload = {
        type: "alert",
        orgId: details?.orgId,
        settings: data,
        label: alertSettingsData?.label,
        subType: selectedTabName,
        subTypeLabel: alertSettingsData?.subTypeLabel,
        alertType: alertType,
      };


      let getPayload = {
        type: "alert",
        orgId: details?.orgId,
        subType: selectedTabName === 'threshold' ? "threshold" : selectedTabName,
        alertType: alertType,
      }
      if (selectedTabName === 'permissions') {
        payload.userId = details?.id;
        getPayload.userId = details?.id;
      }
      if (currentUser) {
        if (!currentUser?.adminSpecificPermissions) {
          payload.orgId = userPermissions?.orgId
        }
      }
      dispatch(
        updateSettings(payload, () => {
          dispatch(
            getAllSettings(getPayload, () => {
              setShowModal(false);
            }
            )
          );
          setEditMode(false);
        })
      );
    },
    [
      details,
      alertSettingsData,
      selectedTabName,
      setShowModal,
      alertSettingsData,
      alertType,
    ]
  );

  const handleUploadImage = useCallback(
    (data) => {
      dispatch(
        uploadImagesInOrganisation(
          { orgId: userPermissions?.orgId, images: data },
          () => {
            handleOrganisationList();
          }
        )
      );
    },
    [userPermissions, dispatch, handleOrganisationList]
  );

  const handleClearUploadedImages = useCallback(() => {
    dispatch(clearOrganisationImages());
  }, [dispatch]);

  const _handleChange = (e, newVal, item) => {
    let temp = { ...details }
    if (item === 'org') {
      if (newVal) {
        temp.orgId = newVal?._id
        setDetails(temp)
        if (Object?.keys(superAdmin)?.length) {
          dispatch(getAllUsers({ orgId: newVal?._id, selection: ["name", "orgName"] }));
        }
      }
      else {
        temp.orgId = ""
        setDetails(temp)
        dispatch(clearSettings())
        setValue('userName', "")
      }
    }
    else if (item === 'user') {
      if (newVal) {
        temp.id = newVal?._id
        setDetails(temp)
        // setValue('userName', newVal?.name)
      }
      else {
        temp.id = ""
        setDetails(temp)
        dispatch(clearSettings())
      }
    }
  }


  return (
    <>
      <Grid item sm={12} xs={12}>
        <Typography role="span" sx={{ ...textClasses.cardTitle }}>
          Alerts Settings
        </Typography>
      </Grid>
      <Box
        sx={{
          borderBottom: 1,
          borderColor: "divider",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Tabs
          value={tabIndex}
          aria-label="disabled tabs example"
          onChange={handleTabChange}
          style={{ height: "20px" }}
          sx={{
            "& .MuiTabs-indicator": {
              backgroundColor: themeType.default.themeOrange,
            },
            "& .MuiTab-root.Mui-selected": {
              color: themeType.default.themeOrange,
            },
          }}
        >
          {userPermissions && havePermission(userPermissions, "alertSettings", viewPermission.alert) ?
            <Tab
              data-testid="allMember"
              label="Alert Permissions"
              {...a11yProps(0)}
              sx={{ fontSize: "14px", textTransform: "none" }}
              onClick={() => {
                setSelectedTabName("permissions");
                setEditPermission("editAlertPermission")
              }}
            />
            : null}

          {userPermissions && havePermission(userPermissions, "alertSettings", viewPermission.threshold) ?
            <Tab
              data-testid="alertTHreshhold"
              label="Alert threshold "
              {...a11yProps(0)}
              sx={{ fontSize: "14px", textTransform: "none" }}
              onClick={() => {
                setSelectedTabName("threshold");
                setEditPermission("editAlertThresholdPermission")
              }}
            />
            : null}
          {userPermissions && havePermission(userPermissions, "alertSettings", viewPermission.email) ?
            <Tab
              data-testid="allMember"
              label="Email Templates"
              {...a11yProps(1)}
              sx={{ fontSize: "14px", textTransform: "none" }}
              onClick={() => {
                setSelectedTabName("templates");
                setEditPermission("editEmailTemplate")
              }}
            />
            : null}
          {userPermissions && havePermission(userPermissions, "alertSettings", viewPermission.pushNotification) ?
            <Tab
              data-testid="allMember"
              label="Push Notifications Templates"
              {...a11yProps(2)}
              sx={{ fontSize: "14px", textTransform: "none" }}
              onClick={() => {
                setSelectedTabName("pushNotifications");
                setEditPermission("editPushNotificationTemplate")
              }}
            />
            : null}
        </Tabs>
      </Box>
      <Grid
        container
        mt={2}
        display="flex"
        gap={2}
        alignItems="center"
        sx={{ width: "100%", paddingTop: "0 !important" }}
      >
        <Grid
          item
          display="flex"
          gap={2}
          alignItems="center"
          sx={{ width: "40%", paddingTop: "0 !important" }}
        >
          {superAdmin && Object.keys(superAdmin).length ?
            <Grid
              item
              display="flex"
              gap={2}
              alignItems="center"
              sx={{ width: "100%", paddingTop: "0 !important" }}
            >

              <FormControl
                variant="outlined"
                size="small"
                fullWidth
                sx={{ ...selectStylesOverride(isDarkThemeEnabledSelector) }}
              >

                <CustomSelect
                  selectStylesOverride={{ ...selectStylesOverride, width: '96%' }}
                  key={`organisation`}
                  onChange={(e, newVal) => {
                    _handleChange(e, newVal, 'org')
                  }}
                  onSearch={handleSearchOrg}
                  onInputChange={(e) => {
                    if (e?.type === "change") {
                      if (e?.target?.value === "") handleSearchOrg(true);
                      setSearchFields((prev) => ({
                        ...prev,
                        org: e?.target?.value?.trim(),
                      }));
                    }
                  }}
                  searchValue={searchFields?.org && searchFields?.org !== ""
                    ? searchFields?.org
                    : null}
                  label="Organisation"
                  errors={errors}
                  getOptionLabel={(option) => { return option?.name }}
                  filterSelectedOptions
                  labelKey="name"
                  isOptionEqualToValue={(option, value) =>
                    option._id === value.id && option.name === value.name
                  }
                  options={orgListing && orgListing}
                />
              </FormControl>

            </Grid>
            : null}
          <Grid
            item
            display="flex"
            gap={2}
            alignItems="center"
            sx={{ width: Object.keys(superAdmin).length ? '100%' : '50%', paddingTop: "0 !important" }}
          >
            <FormControl
              variant="outlined"
              size="small"
              fullWidth
              sx={{ ...selectStylesOverride(isDarkThemeEnabledSelector), width: '96%' }}
            >
              <Controller
                name="userName"
                control={control}
                render={({ field: { onChange, value, name } }) => {
                  return (
                    <CustomSelect
                      selectStylesOverride={{ ...selectStylesOverride, width: '96%' }}
                      key={`id`}
                      defaultValue={value}
                      onChange={(e, newVal) => {
                        onChange(newVal?._id);
                        _handleChange(e, newVal, 'user')
                      }}
                      searchValue={searchFields?.users && searchFields?.users !== ""
                        ? searchFields?.users
                        : null}
                      onSearch={handleSearchUsers}
                      onInputChange={(e) => {
                        if (e?.type === "change") {
                          if (e?.target?.value === "") handleSearchUsers(true);
                          setSearchFields((prev) => ({
                            ...prev,
                            users: e?.target?.value?.trim(),
                          }));
                        }
                      }}
                      label="User"
                      name={name}
                      // disabled={!details?.orgId}
                      errors={errors}
                      value={value || null}
                      sx={{ visibility: selectedTabName === 'permissions' ? "unset" : 'hidden' }}
                      getOptionLabel={(option) => {
                        if (value === undefined) {
                          return "";
                        }
                        if (typeof option === "string") {
                          return (
                            Users?.find((_) => _?._id == value)?.name ||
                            ""
                          );
                        } else {
                          return option?.name || "";
                        }
                      }}
                      filterSelectedOptions
                      labelKey="name"
                      // isOptionEqualToValue={(option, value) =>
                      //   option._id === value.id && option.name === value.name
                      // }
                      // options={Users && Users}
                      options={Users?.length > 0 ? Users.map((ele) => ({ "name": `${ele.name}-${ele.orgName}`, "_id": ele._id })) : []}
                    />
                  );
                }}
              />
            </FormControl>
          </Grid>
        </Grid>





        <Grid
          item
          display="flex"
          gap={2}
          alignItems="center"
          sx={{ width: "58%", paddingTop: "0 !important" }}
        >

          <Button
            variant="contained"
            sx={{
              background: alertType === "withTrip" ? '#ff7200' : "rgb(130, 120, 120)",
              color: alertType === "withTrip" ? '#fff' : "#fff",
              minHeight: "36px",
              minWidth: "140px",
              '&:hover': { background: alertType === "withTrip" ? '#ff7200' : "#808080" },
            }}
            onClick={() => setAlertType("withTrip")}
          >
            Alert With Trip
          </Button>
          <Button
            variant="contained"
            sx={{
              background: alertType === "withoutTrip" ? '#ff7200' : "rgb(130, 120, 120)",
              color: alertType === "withoutTrip" ? '#fff' : "#fff",
              minHeight: "36px",
              minWidth: "140px",
              '&:hover': { background: alertType === "withoutTrip" ? '#ff7200' : "#808080" },
            }}
            onClick={() => setAlertType("withoutTrip")}
          >
            Alert Without Trip
          </Button>
        </Grid>

      </Grid>



      <Grid container spacing={2} mt={0}>
        {loader ? (
          <Grid
            container
            direction={"column"}
            justifyContent={"center"}
            alignItems="center"
            sx={{ p: 15 }}
          >
            <Grid item>
              <img src={loaderGif} />
            </Grid>
          </Grid>
        ) : (
          <>
            {(!tableData || (selectedTabName === "permissions" && (!tableDataAlert || tableDataAlert.length === 0)) || tableData.length === 0) ? (
              <EmptyPage body="No settings found" />
            ) : (
              <Grid container sx={{ mt: 2 }}>
                <Grid item sm={12} xs={12}>
                  <Paper
                    sx={{
                      height: selectedTabName === 'permissions' || selectedTabName === 'threshold' ? '60vh' : '67vh',
                      width: "98%",
                      margin: "auto",
                      overflow: "auto",
                      display: "block",
                    }}
                  >
                    {selectedTabName ?
                      <Table
                        tableColumns={tableColumns}
                        data={selectedTabName === "permissions" ? tableDataAlert : tableData}
                        themeType={themeType}
                        selectedTabName={selectedTabName}
                      />
                      : null}
                  </Paper>
                </Grid>
              </Grid>
            )}
          </>
        )}
      </Grid>
      {selectedTabName === "permissions" || selectedTabName === "threshold" ? (
        <Grid
          item
          mt={2}
          display="flex"
          justifyContent="center"
          gap={2}
          alignItems="center"
          sx={{ width: "100%", paddingTop: "0 !important" }}
        >
          {isEditMode ? (
            <>
              <Button
                variant="outlined"
                sx={{
                  ...buttonClasses?.lynkitBlackFill,
                  minHeight: "36px",
                  minWidth: "140px",
                }}
                onClick={() => setEditMode(false)}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                sx={{
                  ...buttonClasses?.lynkitOrangeFill,
                  minHeight: "36px",
                  minWidth: "140px",
                }}
                onClick={handleSubmit(handleUpdate)}
              >
                Save
              </Button>
            </>
          ) : null
          }

          {userPermissions && havePermission(userPermissions, "alertSettings", editPermission) ?
            !isEditMode ?
              ((selectedTabName === "permissions" ? tableDataAlert : tableData) &&
                ((selectedTabName === "permissions" ? tableDataAlert?.length : tableData?.length) > 0)) ? (
                <Button
                  variant="contained"
                  sx={{
                    ...buttonClasses?.lynkitOrangeFill,
                    minHeight: "36px",
                    minWidth: "140px",
                  }}
                  onClick={() => setEditMode(prev => !prev)}
                >
                  Edit
                </Button>
              ) : null : null : null}
        </Grid>
      ) : null}
      {showModal && (
        <EditTemplateModal
          selectedTabName={selectedTabName}
          open={showModal}
          hide={() => {
            setShowModal(false);
          }}
          selectedImage={selectedImage}
          setSelectedImage={setSelectedImage}
          organisationImages={organisationImages}
          selectStylesOverride={selectStylesOverride(
            isDarkThemeEnabledSelector
          )}
          selectedAlert={selectedAlert}
          dataToSend={dataToSend}
          setDataToSend={setDataToSend}
          setShowModal={setShowModal}
          onUpdateAlert={handleUpdateAlert}
          handleClearUploadedImages={handleClearUploadedImages}
          themeType={themeType}
          getDynamicKeys={handleDynamicKeys}
          dynamicKeys={dynamicKeys}
          getOrganisationImages={handleOrganisationList}
          onUploadImage={handleUploadImage}
        />
      )}
    </>
  );
};

export default AlertSettings;