import { FiberManualRecordTwoTone, Lock } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import React from "react";
import { convertIntoGivenTimezone, dateTimeFormater } from "../../../../services/functions";
import config from "../../../../services/config";
import { useDispatch, useSelector } from "react-redux";
import { fetchAddressFromCoords } from "../../../../redux/actions";

const EventSummaryTimeline = ({
  events = [],
  themeType,
  setEventSummaryData,
}) => {
  const dispatch = useDispatch();
  const { inputClasses, buttonClasses, textClasses } = themeType;
  const userDetails = useSelector((state) => state.auth.currentUserDetail);
  const iconPath = `https://lynktrac.io/lynktracV2/trip-icons`;

  const getAddressFromCoords = (item, i) => {
    let temp = events;
    const payload = {
      latitude: item?.lat,
      longitude: item?.lng,
      appType: "GOOGLE",
    };
    dispatch(
      fetchAddressFromCoords(payload, (res) => {
        if (res && res?.length > 0) {
          const { address } = res[0];
          if (temp[i]) {
            temp[i] = { ...(temp[i]), address: address };
          }
          // const updatedData = {
          //   ...tripEventsDetails,
          //   eventData: temp,
          // };
          setEventSummaryData([...temp]);
        }
      })
    );
  }

  // console.log('eee',events);

  return (
    <Box p={1} display="flex" flexDirection="column">
      {events?.map((_, i) => {
        const iconFile = _?.icon ? _?.icon?.split("/") : "";
        let iconImg =
          iconFile && Array.isArray(iconFile)
            ? iconFile[iconFile?.length - 1]
            : null;
        return (
          <Box sx={{ display: "flex", gap: "4px" }}>
            <Box display="flex" flexDirection="column" alignItems="center">
              <FiberManualRecordTwoTone sx={{ color: "#FF7200" }} />
              <div
                style={{ background: "#FF7200", width: "2px", height: "100%" }}
              />
            </Box>
            <Box pt="3px" pb="6px" display="flex" flexDirection="column">
              <Typography
                sx={{
                  ...textClasses.t13n,
                  fontWeight: 700,
                  textTransform: "uppercase",
                  color: themeType.default.themeOrange,
                }}
              >
                {_?.event ?? "-"}
              </Typography>
              <Typography sx={{ ...textClasses.normalText, fontSize: "11px" }}>
                {convertIntoGivenTimezone(_?.packetTime, userDetails.timeZone.offset) || "-"}
              </Typography>
              <Typography
                sx={{ fontSize: "12px", color: themeType.default.themeGreen }}
              >
                {/* <Lock fontSize="12px" /> Locked */}
                <img
                  src={iconImg ? `${iconPath}/${iconImg}` : ""}
                  height={12}
                  width={12}
                />{" "}
                {_?.lockStatus && _?.lockStatus}
              </Typography>
              {_?.address ? (
                <Typography
                  sx={{ ...textClasses.normalText, fontSize: "11px" }}
                >{_?.address}</Typography>
              ) : (
                <Typography
                  sx={{ ...textClasses.normalText, fontSize: "11px",cursor: "pointer", color: themeType.default.themeOrange }}
                  onClick={() => getAddressFromCoords(_, i)}
                >
                  {/* {_?.lat ? _?.lat?.toFixed(5) : "-"}
                  {" / "}
                  {_?.lng ? _?.lng?.toFixed(5) : "-"} */}
                  Get Address
                </Typography>
              )}
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};

export default EventSummaryTimeline;
