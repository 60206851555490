/* eslint-disable */
// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax
import React, { useState } from 'react'
import CameraEventsDrawer from './components/CameraEventsDrawer';
import {
  AppBar,
  Box,
  Button,
  Grid,
  IconButton,
  Paper,
  Toolbar,
  Typography,
  capitalize,
  styled,
  useTheme,
} from "@mui/material";
import config, { locationUrl } from "../../../services/config";
import { useDispatch, useSelector } from "react-redux";
import { selectStylesOverride } from "../../../utils/util";
import axios from 'axios'
import Iframe from 'react-iframe'
import history from "../../../services/history";
import { useParams } from "react-router-dom/cjs/react-router-dom";
import { getDeviceDetails, getCameraToken, getCameraAlerts, getCameraStatus, updateAlertsFlag } from "../../../redux/actions";
import speedLogo from "../../../assets/images/speed.png";
import dateTime from "../../../assets/images/dateandtime.png";
import locationLogo from "../../../assets/infoWindow/location.png";
import { convertIntoGivenTimezone } from "../../../services/functions";
import ThreeDotsLoader from '../../Components/ThreeDots/ThreeDotsLoader';
const CameraMap = () => {
  const { id } = useParams();
  let infoWindow = null;
  const dispatch = useDispatch();
  const isDarkThemeEnabledSelector = useSelector(
    (state) => state.theme.themeDark
  );
  const [mapLoader, setMapLoader] = useState(false)
  const [cameraLoader, setCameraLoader] = useState(false)
  const [alertsLoader, setAlertsLoader] = useState(false)
  const [deviceStatus, setDeviceStatus] = useState(null)
  const { currentUserDetail } = useSelector((_) => _?.auth);
  const [currentLocation, setCurrentLocation] = React.useState({});
  const currentTime = new Date();
  const endTime = new Date(currentTime.getTime() - 2 * 60 * 60 * 1000);
  const { deviceDetails, cameraTokenValue, cameraAlerts, alertListFlag } = useSelector(
    (_) => _?.deviceslist

  );

  const themeType = isDarkThemeEnabledSelector
    ? require("../../../static/styles/darktheme")
    : require("../../../static/styles/theme");

  const { buttonClasses } = themeType;
  const [mapLoaded, setMapLoaded] = useState(false);

  const formatDateTime = (date) => {
    return (
      date.getFullYear() +
      '-' +
      String(date.getMonth() + 1).padStart(2, '0') +
      '-' +
      String(date.getDate()).padStart(2, '0') +
      ' ' +
      String(date.getHours()).padStart(2, '0') +
      ':' +
      String(date.getMinutes()).padStart(2, '0') +
      ':' +
      String(date.getSeconds()).padStart(2, '0')
    );
  };

  React.useEffect(() => {
    let isMounted = true;
    loadGoogleMapsApi()
      .then((google) => {
        if (isMounted) {
          setMapLoaded(true);
        }
      })
      .catch((error) => {
        console.error("Error loading Google Maps API:", error);
      });

    return () => {
      isMounted = false;
    };
  }, []);

  const loadGoogleMapsApi = () => {
    return new Promise((resolve, reject) => {
      if (window.google) {
        resolve(window.google);
      } else {
        const script = document.createElement("script");
        script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyANnEwTq59T8s30-hXFPNOS5t9_OP47i2A&libraries=geometry,drawing,places`;
        script.onload = () => {
          resolve(window.google);
        };
        script.onerror = (error) => {
          reject(error);
        };
        document.body.appendChild(script);
      }
    });
  };

  React.useEffect(() => {
    if (id) {
      try {
        setCameraLoader(false)
        setMapLoader(false)
        setAlertsLoader(true)
        dispatch(getCameraToken({}, (res) => {
          setCameraLoader(true)
          localStorage.setItem('cameraToken', res?.data?.token)
        }))
        dispatch(getCameraStatus({ deviceId: id }, (res) => {
          if (res?.data?.data && res?.data?.data?.accessMode && res?.data?.data?.accessMode === 5) {
            setDeviceStatus(true)
          }
          else {
            setDeviceStatus(false)
          }
        }))
        dispatch(getDeviceDetails({ id }, () => {
          setMapLoader(true)
        }));

        dispatch(getCameraAlerts(
          {
            "startTime": formatDateTime(endTime),
            "endTime": formatDateTime(currentTime),
            // "startTime": "2024-03-19 00:00:00",
            // "endTime": "2024-03-19 23:59:59",
            "pageNum": "1",
            "pageCount": "20",
            "deviceId": id
          }, () => {
            setAlertsLoader(false)
            dispatch(updateAlertsFlag(false));
          }
        ))


      } catch (e) {
        console.log("error initial: ", e);
      }
    }
  }, [dispatch, id]);

  React.useEffect(() => {
    if (deviceDetails) {
      var pos = {
        lat: Number(deviceDetails?.latitude),
        lng: Number(deviceDetails?.longitude),
      };

      setCurrentLocation(pos);
    }
  }, [deviceDetails]);

  React.useEffect(() => {
    setTimeout(() => {
      if (typeof google === "undefined" || typeof google.maps === "undefined") {
        return null;
      }
      const mapElement = document.getElementById("viewallmap");

      if (!mapElement) {
        console.error("Element with id 'viewallmap' not found.");
        return null;
      }
      if (currentLocation) {
        const mapOptions = {
          zoom: 14,
          center: currentLocation,
          mapTypeId: "terrain",
          zoomControl: true,
          zoomControlOptions: {
            style: google.maps.ZoomControlStyle.SMALL,
            position: google.maps.ControlPosition.LEFT_BOTTOM,
          },
          // options: { styles: isDarkThemeEnabledSelector && styles },
        };

        const map = new google.maps.Map(mapElement, mapOptions);
        const marker = new window.google.maps.Marker({
          position: currentLocation,
          map: map,
          title: "Center Marker",
          optimized: false,
          // icon: gifIcon
        });

        marker?.addListener("click", (event) => {
          if (infoWindow) {
            infoWindow.close();
          }
          const payload = {
            latitude: currentLocation.lat,
            longitude: currentLocation.lng,
            appType: "GOOGLE",
          };
          axios.post(`${locationUrl}fetchAddress`, payload, {
            headers: {
              "Content-Type": "application/json",
              authorization: "oLjJEPvkqYloUOfU1aCyAvOBMQKKG9Am",
            },
          })
            .then((response) => {
              let address = response?.data?.data[0]?.address;
              trackInfoRenderer(address, event, map, marker);


            })
            .catch((error) => {
              console.error("Error:", error);
            });
        });
        // setMymap(map);
        return map;
      }
    }, 40);
  }, [currentLocation, alertsLoader]);

  const trackInfoRenderer = (address, event, map, marker) => {
    let statusColor = ''
    // console.log('deviceDetails', deviceDetails);
    if (deviceDetails.ignition_status == 'IDLE') {
      statusColor = "#f1c232"
    }
    else if (deviceDetails.ignition_status == 'MOVING') {
      statusColor = "#34a853"
    }
    else if (deviceDetails.ignition_status == 'OFFLINE') {
      statusColor = "#000"
    }
    else if (deviceDetails.ignition_status == 'IGNITION_OFF') {
      statusColor = "#b7b7b7"
    }
    else if (deviceDetails.ignition_status == 'STOP') {
      statusColor = "#b7b7b7"
    }
    else {
      statusColor = "#989898"
    }



    infoWindow = new google.maps.InfoWindow({
      content: `
      <div style="width:320px; min-width:100%; padding:5px "; class="t-menu-item">
      <div style="display:flex;">
        <div style="flex: 1; margin-right: 10px;">
          <div style="display:flex; text-align:center; justify-content:center; align-items:center; height:65px; border-radius: 5px; background-color:${statusColor}; box-shadow: 2px 9px 6px 0px rgba(81, 81, 87, 0.29)">
            <div style="width:100%; text-align:center; color:#fff; padding:15px;">
              <div style="font-weight:700; font-size:13px;">${deviceDetails?.ignition_status}</div>
              <div>Device Status</div>
            </div>
          </div>
        </div>
        <div style="flex: 1;">
          <div style="display:flex; text-align:center; justify-content:center; align-items:center; height:65px; border-radius: 5px; background-color: #6495ED; box-shadow: 2px 9px 6px 0px rgba(81, 81, 87, 0.29);">
            <div style="width:100%; text-align:center; color:#fff; padding:15px;">
              <div style="font-weight:700; font-size:13px;">${deviceDetails?.client_id}</div>
              <div>Device ID</div>
            </div>
          </div>
        </div>
      </div>
      <div style="display:flex; margin-top: 20px;">
        <div style="flex: 1; border-right: dotted; border-color: #32333561; text-align:center;">
          <img src="${speedLogo}" width="20" style="margin-bottom:5px;">
          <h3 style="font-weight:700; font-size:13px; color:#e72b2b;">Speed</h3>
          <span style="line-height:15px;">${deviceDetails.speed}</span>
        </div>
        <div style="flex: 1; text-align:center;">
          <img src="${dateTime}" width="20" style="margin-bottom:5px;">
          <h3 style="font-weight:700; font-size:13px; color:#084c5b;">Date & Time</h3>
          <span style="line-height:15px;">${convertIntoGivenTimezone(deviceDetails.packet_time * 1000, currentUserDetail?.timeZone?.offset) || "NA"}</span>
        </div>
      </div>
      <div style="display:flex; margin-top:20px;">
        <div style="flex: 1; text-align:center;">
          <img src="${locationLogo}" style="width:50%;">
        </div>
        <div style="flex: 3; font-size:13px; color:#475a6d; margin-top:10px; margin-bottom:10px;">
          <span style="line-height:15px;">${deviceDetails.geo_name}, ${address}</span>
        </div>
      </div>
    </div>`
    });
    infoWindow.open(map, marker);
  }

  React.useEffect(() => {
    const fetchDeviceDetails = () => {
      dispatch(getDeviceDetails({ id }, () => {
      }));
    };
    const fetchAlertsList = () => {
      // dispatch(getCameraAlerts(
      //   {
      //     "startTime": formatDateTime(endTime),
      //     "endTime": formatDateTime(currentTime),
      //     "pageNum": "1",
      //     "pageCount": "20",
      //     "deviceId": id
      //   }, () => {
      //     setAlertsLoader(false)
      //     dispatch(updateAlertsFlag(false));
      //   }
      // ))
    };


    fetchDeviceDetails();
    fetchAlertsList();

    const interval = setInterval(() => {fetchDeviceDetails(); fetchAlertsList(); }, 60000);

    return () => clearInterval(interval);
  }, [dispatch, id]);

  return (

    <>
      <Box width="100%" height="90vh" position="relative" display="flex">
        <Box
          width="100%"
          height="100%"
          display="flex"
          flexDirection="column"
          mr={0}
        >
          {/* Live camera */}
          <Box width="100%">
            <Paper sx={{ px: 2, py: 1, marginBottom: '8px', boxShadow: 'none', borderBottom: 1, borderColor: "divider" }}>
              <Box
                width="100%"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Box display="flex" alignItems="center" data-testid="page-title">
                  <Typography variant="h6">Live Camera</Typography>
                  {/* <IconButton sx={{ ml: 1 }} size="large" onClick={() => setFullScreenCamera(true)}>
                          <Fullscreen />
                        </IconButton> */}
                </Box>
                <Button variant="contained" sx={{ ...buttonClasses?.lynkitOrangeFill }} onClick={() => history.push(`/dashboard/camera/${id}`)}>
                  Playback
                </Button>
              </Box>
            </Paper>
          </Box>

          <Box width="100%" height="100%">
            <Grid sx={{ height: '100%', minHeight: '100%' }}>
              {!mapLoader && !cameraLoader ?
                <Grid data-testid="loader" item container direction="column" justifyContent="center" alignItems="center" xs={12} sx={{ p: 1 }}>
                  <ThreeDotsLoader />
                </Grid>
                :
                deviceStatus ?
                <Iframe data-testid="iframe-cam" className='iframe-camera'
                    url={`http://43.205.126.165:9966/vss/apiPage/RealVideo.html?token=${cameraTokenValue?.token}&deviceId=${deviceDetails?.client_id}&chs=1_2_3_4&panel=1&stream=1&buffer=0`}
                  /> 
                  :
                  <Grid data-testid="offline-device-screen" sx={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Typography>Device is offline.</Typography>
                  </Grid>
              }
            </Grid>
          </Box>
        </Box>
        <CameraEventsDrawer mapLoaded={mapLoaded} alertsLoader={alertsLoader} cameraAlerts={cameraAlerts} themeType={themeType} deviceDetails={deviceDetails} currentTime={currentTime} endTime={endTime} formatDateTime={formatDateTime} alertListFlag={alertListFlag} isDarkThemeEnabledSelector={isDarkThemeEnabledSelector} />
      </Box>
    </>

  );
};

export default CameraMap;

 





