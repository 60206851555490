import { DRAWER_OPEN, DRAWER_CLOSE } from "../types";

const INIT_STATE = {
    drawerOpen: false,

};
  
export default (state = INIT_STATE, action) => {
    switch (action.type) {
      case DRAWER_OPEN: {
        return {...state, drawerOpen: true};
      }
      case DRAWER_CLOSE: {
        return {...state, drawerOpen: false};
      }
      
      default:
        return state;
    }
}