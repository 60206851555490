import { FilterAlt } from "@mui/icons-material";
import { Box, Typography, useTheme } from "@mui/material";
import React from "react";

const DrawerFloaterLabel = ({
  show,
  setShow,
  text = "Filter",
  top = "3%",
  Icon,
  leftAlign = false,
}) => {
  const iconStyles = { fontSize: "20px", color: "#ffffff" };
  const theme = useTheme();
  return (
    <>
      {leftAlign ? (
        <>
          {!show && (
            <Box
              className="drawer-toggle-floating-btn--left"
              onClick={() => setShow(true)}
              sx={{
                top: top,
                left: "-10px",
                transition: theme.transitions.create("all", {
                  easing: theme.transitions.easing.sharp,
                  duration: theme.transitions.duration.leavingScreen,
                }),
                ":hover": {
                  transition: theme.transitions.create("all", {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.leavingScreen,
                  }),
                },
              }}
            >
              <Typography
                sx={{ color: "#fff", marginLeft: "5px", fontSize: "16px" }}
                className="hide-text"
              >
                {text}
                {" "}
              </Typography>

              {Icon ? Icon : <FilterAlt sx={iconStyles} />}
            </Box>
          )}
        </>
      ) : (
        <>
          {!show && (
            <Box
              className="drawer-toggle-floating-btn"
              onClick={() => setShow(true)}
              sx={{
                top: top,
                right: "-20px",
                transition: theme.transitions.create("all", {
                  easing: theme.transitions.easing.sharp,
                  duration: theme.transitions.duration.leavingScreen,
                }),
                ":hover": {
                  transition: theme.transitions.create("all", {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.leavingScreen,
                  }),
                },
              }}
            >
              {Icon ? Icon : <FilterAlt sx={iconStyles} />}
              <Typography
                sx={{ color: "#fff", marginLeft: "5px", fontSize: "16px" }}
                className="hide-text"
              >
                {text}
              </Typography>
            </Box>
          )}
        </>
      )}
    </>
  );
};

export default DrawerFloaterLabel;
