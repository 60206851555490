import { ORGANISATIONS_LIST, GET_ORGANISATION, ORGANISATION_FEATURES, ORGANISATIONS_COUNT, FLUSH_ORGANISATION } from "../types";
  
  const INIT_STATE = {
    OrganisationListing: [],
    selectedOrganisation: [],
    organisationFeatures: {},
    organisationsCount: {},
  };
 
  export default (state = INIT_STATE, action) => {
    switch (action.type) {
      case ORGANISATIONS_LIST: return {...state, OrganisationListing: action.payload.rows, totalRows: action.payload.count}
      case GET_ORGANISATION: return {...state, selectedOrganisation: [action.payload]}
      case ORGANISATIONS_COUNT: return { ...state, organisationsCount: action.payload}
      case ORGANISATION_FEATURES: return { ...state, organisationFeatures: action.payload }
      case FLUSH_ORGANISATION: return { ...state, selectedOrganisation: []}
      default:
        return state;
    }
  }

  export const flushSelectedOrganisation = () => ({
    type: FLUSH_ORGANISATION,
  });