/* eslint-disable */

import {
    Box,
    Card,
    CardContent,
    Checkbox,
    Grid,
    Typography,
    Button,
    Breadcrumbs 
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
    useJsApiLoader,
    GoogleMap,
    Marker,
    DirectionsRenderer,
    Polyline,
} from "@react-google-maps/api";
import { selectStylesOverride, sendCustomEventAnalytics } from "../../../../utils/util";
import { GoogleMapsAPIKey } from "../../../../services/constants";
import { useEffect, useMemo, useState } from "react";
import { parse, addMinutes, setMinutes, setHours } from "date-fns";
import _ from "lodash";
import SourceImg from "../../../../static/images/routes/source_icon.svg";
import DestinationImg from "../../../../static/images/routes/destination_icon.svg";
import ViaPointImg from "../../../../static/images/routes/viapoint_icon.svg";
import {
    getAllRoutes,
    getAllGeofences,
    createRoute,
    getAndSaveTotalDistanceRoute,
    getAllOrganizations,
    getAllUsers,
    getRouteDetails,
    getRouteSchedculeData,
    updateRouteDetails,
    clearRouteData,
    checkTemplateExistance,
    flushGeofenceList,
    flushRouteDetail,
    getOrganisation,
    userDetails,
    viewGeofenceForDetail
} from "../../../../redux/actions";
import { flushRoutesData } from "../../../../redux/reducers/Route";
import { useCallback } from "react";
import { useRef } from "react";
import { showToast } from "../../../../services/functions";
import { useForm, useWatch } from "react-hook-form";
import NewRouteForm from "./NewRouteForm";
import { Link, useParams, useHistory } from "react-router-dom";
import { format } from "date-fns";
import axios, { geoAxios } from "../../../../services/Api";
import { useQueryParams } from "../../../../utils/utilHooks/useQueryParams";
import debounce from "lodash.debounce";
import { havePermission } from "../../../../utils/permissionCheck";
import { AssignmentReturnedOutlined } from "@mui/icons-material";

const dayOptions = [
    { value: "sameDay", label: "Same Day" },
    { value: "nextDay", label: "Next Day" },
    { value: "custom", label: "Custom" },
];

const batterPercentageOptions = [
    { value: "20", label: "20%" },
    { value: "30", label: "30%" },
    { value: "40", label: "40%" },
    { value: "50", label: "50%" },
    { value: "60", label: "60%" },
    { value: "70", label: "70%" },
    { value: "80", label: "80%" },
    { value: "90", label: "90%" },
];

const AddRoute = () => {
    let query = useQueryParams();
    const dispatch = useDispatch();
    const history = useHistory();
    const { id } = useParams();
    const isEditRoute = !!id;

    /* --- LOCAL STATES --- */
    const [plotBygoogle, setPlotByGoogle] = useState(false)
    const [viaPointsDropdownGeofenceList, setViaPointsDropdownGeofenceList] = useState([]);
    const [addMore, setAddMore] = useState(false);
    const [noGeofenceMessage, setNoGeofenceMessage] = useState("");
    const [viaPoints, setViaPoints] = useState([]);
    const [user, setUser] = useState({});
    const [directions, setDirections] = useState(null);
    const [polyline, setPolyline] = useState(null);
    const [myMarkers, setMarkers] = useState([]);
    const [schedules, setSchedules] = useState([]);
    const [createLoader, setCreateLoader] = useState(false)
    const [updateLoader, setUpdateLoader] = useState(false)
    const [searchObject, setSearchObject] = useState({ source: "", destination: "", organisation: "", user: "" })
    const [centerCoordinates, setCenterCoordinates] = useState({ lat: "", lng: "" });
    const [myMap, setMymap] = useState(/** @type google.maps.Map */(null));
    const [routeLegsArr, setRouteLegsArr] = useState([]);
    const [activePath, setActivePath] = useState(0);
    const [isDraggingRoute, setIsDraggingRoute] = useState(false);
    const selectedRouteSchedules = useSelector((state) => state.route.selectedRouteSchedules);
    const [sourceGeofenceList, setSourceGeofenceList] = useState([])
    const [destinationGeofenceList, setDestinationGeofenceList] = useState([]);
    const [viaPointsSearchObject, setViaPointsSearchObject] = useState([]);
    // const [organisationList, setOrganisationList] = useState([])
    /* --- SELECTORS --- */
    const superAdmin = useSelector((state) => state.auth.currentUserDetail)?.adminSpecificPermissions;
    const isSuperAdmin = !!superAdmin;
    const [userList, setUserList] = useState([])
    const userDetailsRes = useSelector((state) => state.userlist.userDetail);
    const geofenceList = useSelector((state) => state.geofencelist.geofenceListing);
    const templateExistence = useSelector(state => state.templates.templateExistence);
    const routeDetails = useSelector((state) => state.route.routeDetails);
    const isDarkThemeEnabledSelector = useSelector((state) => state.theme.themeDark);
    const [routeLegs, setRouteLegs] = useState([])
    const [isGettingDirections, setIsGettingDirections] = useState(false);
    const currentUserDetailsObj = useSelector((state) => state.auth.currentUserDetail);
    const [orgListing, setOrgListing] = useState([])
    const [routePayload, setRoutePayload] = useState(
        {
            "routeSource": {
                "_id": "",
                "name": "",
                "centerCoordinates": ""
            },
            "routeDestination": {
                "_id": "",
                "name": "",
                "speed": 40,
                "etaTime": "",
                "totalDistance": "",
                "centerCoordinates": ""
            },
            "viaPoints": [],
            "routeName": "",
            "description": "",
            "batteryPercentage": "",
            "templateId": "",
            "dynamicData": {},
        }
    )
    useEffect(() => {
        if (isEditRoute) {
            dispatch(
                getRouteDetails({
                    id: id,
                    selection: [
                        "routeName",
                        "description",
                        "batteryPercentage",
                        "routeSource",
                        "routeDestination",
                        "viaPoints",
                        "scheduleData",
                        "isSchedulesExists",
                        "dynamicData",
                        "user",
                        "status",
                        "addedOn",
                        "addedBy",
                        "modifiedOn",
                        "modifiedBy",
                        "routeLegs",
                        "routeLegs1",
                        "routeLegs2",
                        "direction1",
                        "direction2",
                        "direction3",
                    ],
                }),
                () => { }
            );
        }
    }, [dispatch, isEditRoute]);

    useEffect(() => {
        if (isSuperAdmin) {
            dispatch(getAllOrganizations({ selection: ["name"] }, (res) => {
                setOrgListing(res)
            }));
        } else {
            dispatch(getAllGeofences({
                selection: [
                    "name",
                    "centerCoordinates",
                ],
            }));
        }
    }, [dispatch, isSuperAdmin]);

    const {
        control,
        formState: { errors, isSubmitting, isValid, dirtyFields },
        handleSubmit,
        reset,
        register,
        setError,
        setValue,
        getValues,
    } = useForm({
        mode: "onChange",
        criteriaMode: "all",
    });

    const directionsRendererRef = useRef(null);
    const mapRef = useRef(null);
    const { isLoaded } = useJsApiLoader({ googleMapsApiKey: GoogleMapsAPIKey, });
    const themeType = isDarkThemeEnabledSelector ? require("../../../../static/styles/darktheme") : require("../../../../static/styles/theme");
    const { cardClasses, textClasses } = themeType;
    const center = { lat: 28.7, lng: 77.1 };

    const handleSearchGeofence = useCallback((type, index, reset) => {
        let payload;
        if (type === "source" && !reset) {
            payload = {
                searchValue: searchObject?.source,
            }
        } else if (type === "destination" && !reset) {
            payload = {
                searchValue: searchObject?.destination,
            }
        }
        else if (type === "viaPoint" && index !== undefined && !reset) {
            payload = {
                searchValue: viaPointsSearchObject?.find((viaPoint) => viaPoint?.index === index)?.searchValue
            }
        }
        if (isSuperAdmin && user?.orgId && user?.id) {
            payload.filter = { orgId: user?.orgId };
            payload.user = { userId: user?.id };
        }

        geoAxios.post("/listGeofence", payload && { ...payload, selection: ['name', 'centerCoordinates'] }).then(({ data }) => {
            if (data.success) {
                if (type === "source") {
                    setSourceGeofenceList(data.data)

                } else if (type === "destination") {
                    setDestinationGeofenceList(data.data)
                } else if (type === "viaPoint" && index !== undefined) {
                    const existingDropdownList = viaPointsDropdownGeofenceList?.find((viaPointList) => {
                        return viaPointList?.index === index
                    })
                    if (existingDropdownList) {
                        const updatedViaPointsDropdowns = viaPointsDropdownGeofenceList?.map((viaPointList) => {
                            if (viaPointList?.index === index) {
                                return { ...viaPointList, list: data?.data }
                            } else return viaPointList
                        })
                        setViaPointsDropdownGeofenceList(updatedViaPointsDropdowns)
                    } else {
                        const viaPointDropdownList = {
                            index: index,
                            list: data?.data,
                        }
                        setViaPointsDropdownGeofenceList(prev => ([...prev, viaPointDropdownList]))
                    }
                }
            }
        })


    }, [searchObject, viaPointsSearchObject, viaPointsDropdownGeofenceList, setSourceGeofenceList, setViaPointsDropdownGeofenceList, setDestinationGeofenceList])

    const handleOrganisationSearch = useCallback(
        (empty) => {
            setOrgListing([])
            if (empty === true) {
                dispatch(getAllOrganizations({ selection: ["name"] }, (res) => {
                    setOrgListing(res)
                })
                );

            } else {
                if (searchObject?.organisation || searchObject?.organisation == "")
                    dispatch(getAllOrganizations({ selection: ["name"], searchValue: searchObject?.organisation }, (res) => {
                        setOrgListing(res)
                    })
                    );
            }
        },
        [searchObject]
    );

    const handleUserSearch = useCallback((empty) => {
        if (empty === true) {
            dispatch(
                getAllUsers({
                    selection: ["name", "addedBy", "assignGeofence"],
                    searchValue: "",
                    orgId: user?.orgId ? user?.orgId : "",
                }, (res) => { setUserList(res) })
            );
        } else {
            if (searchObject?.user || searchObject?.user == "")
                dispatch(
                    getAllUsers({
                        selection: ["name", "addedBy", "assignGeofence"],
                        searchValue: searchObject?.user,
                        orgId: user?.orgId ? user?.orgId : "",
                    }, (res) => { setUserList(res) })
                );
        }
    }, [searchObject])

    useEffect(() => {
        if (routeDetails && isEditRoute) {
            let temp = { ...routePayload }
            clearMarkers();
            setPlotByGoogle(true)
            const finalViaPoints = routeDetails?.viaPoints?.map(viaPoint => {
                const { etaTime, ...rest } = viaPoint;
                const formattedEtaTime = convertHHMMToDate(etaTime);
                return {
                    ...rest,
                    etaTime: formattedEtaTime
                };
            });

            temp.routeName = routeDetails?.routeName
            temp.description = routeDetails?.description
            temp.batteryPercentage = routeDetails?.batteryPercentage
            temp.routeSource = routeDetails?.routeSource
            temp.viaPoints = finalViaPoints
            temp.routeDestination = { ...routeDetails?.routeDestination, etaTime: convertHHMMToDate(routeDetails?.routeDestination?.etaTime) }

            if (!routeDetails?.direction1 || Object.keys(routeDetails?.direction1)?.length === 0) {
                // plotOnMap();
            }
            else {
                setRouteLegs(routeDetails?.routeLegs);
                setDirections(deserializeDirection(routeDetails?.direction1));
                let tempRouteLegsArr = [{
                    routeLegs: routeDetails?.routeLegs,
                    directions: deserializeDirection(routeDetails?.direction1),
                }];

                if (routeDetails?.routeLegs1 && routeDetails?.routeLegs1?.length) {
                    tempRouteLegsArr.push({
                        routeLegs: routeDetails?.routeLegs1,
                        directions: deserializeDirection(routeDetails?.direction2),
                    });
                }

                if (routeDetails?.routeLegs2 && routeDetails?.routeLegs2?.length) {
                    tempRouteLegsArr.push({
                        routeLegs: routeDetails?.routeLegs2,
                        directions: deserializeDirection(routeDetails?.direction3),
                    });
                }
                setRouteLegsArr([...tempRouteLegsArr]);
                plotMarkersWhileEditing(routeDetails?.routeLegs);
            }

            if (routeDetails?.dynamicData) {
                for (const key in routeDetails && routeDetails.dynamicData) {
                    setValue(key, routeDetails.dynamicData[key])
                }
            }
            setRoutePayload(temp)
            if (isSuperAdmin) {
                let orgCheck = orgListing?.find((_) => _?._id == routeDetails?.user?.orgId) || ""
                let userCheck = userList?.find((_) => _?._id == routeDetails?.user?.id) || ""
                if (!orgCheck) {
                    dispatch(getOrganisation({ id: routeDetails?.user?.orgId }, (res) => {
                        if (res) {
                            let tempOrgList = orgListing;
                            tempOrgList.push({ _id: res?._id, name: res?.name })
                            setOrgListing([...tempOrgList]);
                        }
                    }));
                }
                if (!userCheck) {
                    dispatch(userDetails({ id: routeDetails?.user?.id }, (res) => {
                        if (res) {
                            let tempUserList = userList;
                            tempUserList.push({ _id: res?._id, name: res?.name, orgName: res?.orgName, addedBy: res?.addedBy })
                            setUserList([...tempUserList]);
                        }
                    }));
                }
            }
        }
    }, [routeDetails, superAdmin]);

    const plotMarkersWhileEditing = (legs) => {
        if (myMap && legs?.length) {
            legs.forEach((leg, index) => {
                if (index === 0) {
                    makeMarker(leg.start_location, icons.start, "title")
                }
                if (index === legs.length - 1) {
                    makeMarker(leg.end_location, icons.end, 'title')
                } else {
                    makeMarker(leg.end_location, icons.via, 'title')
                }
            })
        }
    }

    useEffect(() => {
        if (selectedRouteSchedules && isEditRoute) {
            const newSchedules = selectedRouteSchedules?.map((schedule, index) => {
                const sendingSchedule = {
                    key: index,
                    schedule: schedule?.schedule,
                    STD: schedule?.routeSource?.STD,
                    dayArrival: schedule?.routeDestination?.dayArrival,
                    STA: schedule?.routeDestination?.STA,
                };
                setValue(`schedule${index}`, schedule?.schedule);
                setValue(
                    `scheduleArrivalDay${index}`,
                    dayOptions?.find(
                        (option) => option?.value === schedule?.routeDestination?.dayArrival
                    )
                );
                setValue(
                    `scheduleDeparture${index}`,
                    convertHHMMToDate(schedule?.routeSource?.STD)
                );
                setValue(
                    `scheduleArrivalTime${index}`,
                    convertHHMMToDate(schedule?.routeDestination?.STA)
                );
                const updatedViaPoints = schedule?.viaPoints?.map(
                    (viaPoint, viaPointIndex) => {
                        setValue(
                            `scheduleViaPointArrivalTime${index}_${viaPointIndex}`,
                            convertHHMMToDate(viaPoint?.STA)
                        );
                        setValue(
                            `scheduleViaPointDepartureTime${index}_${viaPointIndex}`,
                            convertHHMMToDate(viaPoint?.STD)
                        );
                        setValue(
                            `scheduleViaPointArrivalDay${index}_${viaPointIndex}`,
                            dayOptions?.find(
                                (option) => option?.value === viaPoint?.dayArrival
                            )
                        );
                        setValue(
                            `scheduleViaPointDepartureDay${index}_${viaPointIndex}`,
                            dayOptions?.find(
                                (option) => option?.value === viaPoint?.dayDeparture
                            )
                        );

                        const checkViaPoint = {
                            dayDeparture: viaPoint?.dayDeparture,
                            STD: viaPoint?.STD,
                            halt: viaPoint?.halt,
                            STA: viaPoint?.STA,
                            dayArrival: viaPoint?.dayArrival,
                        };
                        return checkViaPoint;
                    }
                );
                sendingSchedule["scheduleVia"] = updatedViaPoints;
                return sendingSchedule;
            });
            setSchedules(newSchedules);
        }
    }, [selectedRouteSchedules, isEditRoute, setSchedules]);

    useEffect(() => {
        if (routeDetails?._id && isEditRoute) {
            setUser(routeDetails?.user);
            dispatch(getRouteSchedculeData({ routeId: routeDetails?._id }), () => { });
            dispatch(getAllGeofences(
                {
                    selection: [
                        "name",
                        "centerCoordinates",
                    ],
                    user: {
                        userId: routeDetails?.user?.id,
                        addedBy: routeDetails?.addedBy?.id,
                    }
                }, (success, error) => {
                    if (success && success.count === 0) {
                        setNoGeofenceMessage(success?.message)
                    }

                }
            )
            );
        }
    }, [dispatch, routeDetails, isEditRoute]);

    const getDirectionsData = (item, index) => {
        let src = routePayload?.routeSource || {}
        let via = routePayload?.viaPoints || {};
        let destination = routePayload?.routeDestination || {}

        if (src?._id && destination?._id && !via?.length) {
            filterData(src, null, destination, null, item)
        }
        else if (via.length) {
            if (src?._id && via?.length && !destination?._id) {
                filterData(src, via, null, index, item)
            }
            else if (via?.length && destination?._id && !src?._id) {
                filterData(null, via, destination, index, item)
            }
            else {
                filterData(src, via, destination, index, item)
            }
        }
    }

    const getDistanceFromDb = async (payload) => {
        if (payload?.sourceObj?._id && payload?.destinationObj?._id) {
            return new Promise(async (resolve, reject) => {
                dispatch(getAndSaveTotalDistanceRoute(payload, async (res) => {
                    if (res?.success) {
                        if (res?.data?.distance) {
                            res.data.distance = res.data.distance.replace(/[^\d.]/g, '').trim();
                        }
                        resolve(res);
                    } else {
                        let sourceSelected = await getGeofenceDetail(payload?.sourceObj?._id);
                        let destinationSelected = await getGeofenceDetail(payload?.destinationObj?._id);
    
                        try {
                            const googleDirections = await googleDirectionsApi(sourceSelected, destinationSelected, payload);
                            resolve(googleDirections);
                        }
                        catch (error) {
                            console.error('Error:', error);
                            reject(new Error('Failed to fetch distance'));
                        }
                    }
                }));
            });
        }
    };
    
    // Helper function to get geofence details
    const getGeofenceDetail = async (id) => {
        const geofence = geofenceList?.find((geofence) => geofence._id === id);
    
        if (geofence) {
            return geofence;
        } else {
            const viewpayload = {
                id: id,
                selection: [
                    "name",
                    "centerCoordinates"
                ],
            };
    
            return new Promise((resolve, reject) => {
                dispatch(viewGeofenceForDetail(viewpayload, (res) => {
                    if (res?.data) {
                        resolve({ name: res?.data?.name, _id: res?.data?._id, centerCoordinates: res?.data?.centerCoordinates });
                    } else {
                        reject(new Error('Failed to fetch geofence details'));
                    }
                }));
            });
        }
    };
    

    const googleDirectionsApi = (source, destination, payload) => {
        return new Promise((resolve, reject) => {
            const directionsService = new google.maps.DirectionsService();

            const originCoords = {
                lat: source?.centerCoordinates && source?.centerCoordinates[0][0][1],
                lng: source?.centerCoordinates && source?.centerCoordinates[0][0][0],
            };
            const destinationCoords = {
                lat: destination?.centerCoordinates && destination?.centerCoordinates[0][0][1],
                lng: destination?.centerCoordinates && destination?.centerCoordinates[0][0][0],
            };

            directionsService.route(
                {
                    origin: originCoords,
                    destination: destinationCoords,
                    travelMode: google.maps.TravelMode.DRIVING,
                    unitSystem: google.maps.UnitSystem.METRIC,
                },
                (result, status) => {
                    if (status === "OK") {
                        let distance = result.routes[0].legs[0].distance?.text;
                        if (distance) {
                            distance = distance.replace(/[^\d.]/g, '');
                        }
                        payload["distance"] = distance;
                        dispatch(getAndSaveTotalDistanceRoute(payload, (status) => { }));
                        resolve(distance);
                    } else {
                        showToast("Please select geofence with valid latitude longitude", true);
                        // reject(new Error("Failed to get directions"));
                    }
                }
            );
        });
    };

    const filterData = async (src, via, destination, index, item) => {
        let temp = { ...routePayload }
        if (src?._id && destination?._id && !via) {
            let payload = {
                sourceObj: {
                    _id: src?._id,
                    srcName: src?.name,
                    srcLATLNG: src && src?.centerCoordinates && src?.centerCoordinates[0] && src?.centerCoordinates[0][0] ? src?.centerCoordinates[0][0]?.join(",") : '',
                },
                destinationObj: {
                    _id: destination?._id,
                    desName: destination?.name,
                    desLATLNG: destination && destination?.centerCoordinates && destination?.centerCoordinates[0] && destination?.centerCoordinates[0][0] ? destination?.centerCoordinates[0][0]?.join(",") : '',
                },
                // userId: userDetailsRes?._id,
            };
            let res = await getDistanceFromDb(payload)
            if (res) {
                temp.routeDestination.totalDistance = res?.data?.distance || res;
                let time = calcTime(res?.data?.distance || res, temp?.routeDestination?.speed)
                temp.routeDestination.etaTime = convertHHMMToDate(time)
            }
        }
        else if (src?._id && via?.length && !destination?._id) {
            if (via.length === 1) {
                let sourceObj;
                let destObj;
                if (src?._id) {
                    sourceObj = {
                        _id: src?._id,
                        srcName: src?.name,
                        srcLATLNG: src && src?.centerCoordinates && src?.centerCoordinates[0] && src?.centerCoordinates[0][0] ? src?.centerCoordinates[0][0]?.join(",") : '',
                    };
                    destObj = {
                        _id: via[0]?._id,
                        desName: via[0]?.itemName,
                        desLATLNG: via && via[0] && via[0]?.centerCoordinates && via[0]?.centerCoordinates[0] && via[0]?.centerCoordinates[0][0] ? via[0]?.centerCoordinates[0][0]?.join(",") : '',
                    }
                }
                let payload = {
                    sourceObj: sourceObj,
                    destinationObj: destObj,
                    // userId: userDetailsRes?._id,
                };
                let res = await getDistanceFromDb(payload)
                if (res) {
                    temp.viaPoints[0].totalDistance = res?.data?.distance || res;
                    let time = calcTime(res?.data?.distance || res, temp?.viaPoints[0]?.speed)
                    temp.viaPoints[0].etaTime = convertHHMMToDate(time)
                }
            }
            else if (index === via.length - 1) {
                let sourceObj = {
                    _id: via[index - 1]?._id,
                    srcName: via[index - 1]?.itemName,
                    srcLATLNG: via && via[index - 1] && via[index - 1]?.centerCoordinates && via[index - 1]?.centerCoordinates[0] && via[index - 1]?.centerCoordinates[0][0] ? via[index - 1]?.centerCoordinates[0][0]?.join(",") : '',
                };
                let destObj = {
                    _id: via[index]?._id,
                    desName: via[index]?.itemName,
                    desLATLNG: via && via[index] && via[index]?.centerCoordinates && via[index]?.centerCoordinates[0] && via[index]?.centerCoordinates[0][0] ? via[index]?.centerCoordinates[0][0]?.join(",") : '',
                };

                let payload = {
                    sourceObj: sourceObj,
                    destinationObj: destObj,
                    // userId: userDetailsRes?._id,
                };
                let res = await getDistanceFromDb(payload)
                if (res) {
                    temp.viaPoints[index].totalDistance = res?.data?.distance || res;
                    let time = calcTime(res?.data?.distance || res, temp?.viaPoints[index]?.speed)
                    temp.viaPoints[index].etaTime = convertHHMMToDate(time)
                }

            }
            else {
                if (via?.length > 1) {
                    if (index === 0) {
                        let sourceObj;
                        let destObj;
                        if (src?._id) {
                            sourceObj = {
                                _id: src?._id,
                                srcName: src?.name,
                                srcLATLNG: src && src?.centerCoordinates && src?.centerCoordinates[0] && src?.centerCoordinates[0][0] ? src?.centerCoordinates[0][0]?.join(",") : '',
                            };
                            destObj = {
                                _id: via[0]?._id,
                                desName: via[0]?.itemName,
                                desLATLNG: via && via[0] && via[0]?.centerCoordinates && via[0]?.centerCoordinates[0] && via[0]?.centerCoordinates[0][0] ? via[0]?.centerCoordinates[0][0]?.join(",") : '',
                            }
                        }
                        let payload = {
                            sourceObj: sourceObj,
                            destinationObj: destObj,
                            // userId: userDetailsRes?._id,
                        };
                        let res = await getDistanceFromDb(payload)
                        if (res) {
                            temp.viaPoints[0].totalDistance = res?.data?.distance || res;
                            let time = calcTime(res?.data?.distance || res, temp?.viaPoints[0]?.speed)
                            temp.viaPoints[0].etaTime = convertHHMMToDate(time)
                        }

                        if (via[index]?._id) {
                            let payload1 = {
                                sourceObj: {
                                    _id: via[index]?._id,
                                    srcName: via[index]?.itemName,
                                    srcLATLNG: via && via[index] && via[index]?.centerCoordinates && via[index]?.centerCoordinates[0] && via[index]?.centerCoordinates[0][0] ? via[index]?.centerCoordinates[0][0]?.join(",") : '',
                                },
                                destinationObj: {
                                    _id: via[index + 1]?._id,
                                    desName: via[index + 1]?.itemName,
                                    desLATLNG: via && via[index + 1] && via[index + 1]?.centerCoordinates && via[index + 1]?.centerCoordinates[0] && via[index + 1]?.centerCoordinates[0][0] ? via[index + 1]?.centerCoordinates[0][0]?.join(",") : '',
                                },
                                // userId: userDetailsRes?._id,
                            };
                            let res = await getDistanceFromDb(payload1)
                            if (res) {
                                temp.viaPoints[index + 1].totalDistance = res?.data?.distance || res;
                                let time = calcTime(res?.data?.distance || res, temp?.viaPoints[index + 1]?.speed)
                                temp.viaPoints[index + 1].etaTime = convertHHMMToDate(time)
                            }

                        }
                    }
                    else {
                        if (via[index - 1]?._id) {
                            let payload1 = {
                                sourceObj: {
                                    _id: via[index - 1]?._id,
                                    srcName: via[index - 1]?.itemName,
                                    srcLATLNG: via && via[index - 1] && via[index - 1]?.centerCoordinates && via[index - 1]?.centerCoordinates[0] && via[index - 1]?.centerCoordinates[0][0] ? via[index - 1]?.centerCoordinates[0][0]?.join(",") : '',
                                },
                                destinationObj: {
                                    _id: via[index]?._id,
                                    desName: via[index]?.itemName,
                                    desLATLNG: via && via[index] && via[index]?.centerCoordinates && via[index]?.centerCoordinates[0] && via[index]?.centerCoordinates[0][0] ? via[index]?.centerCoordinates[0][0]?.join(",") : '',
                                },
                                // userId: userDetailsRes?._id,
                            };
                            let res = await getDistanceFromDb(payload1)
                            if (res) {
                                temp.viaPoints[index].totalDistance = res?.data?.distance
                                let time = calcTime(res?.data?.distance || res, temp?.viaPoints[index]?.speed)
                                temp.viaPoints[index].etaTime = convertHHMMToDate(time)
                            }
                        }
                        if (via[index + 1]?._id) {
                            let payload2 = {
                                sourceObj: {
                                    _id: via[index]?._id,
                                    srcName: via[index]?.itemName,
                                    srcLATLNG: via && via[index] && via[index]?.centerCoordinates && via[index]?.centerCoordinates[0] && via[index]?.centerCoordinates[0][0] ? via[index]?.centerCoordinates[0][0]?.join(",") : '',
                                },
                                destinationObj: {
                                    _id: via[index + 1]?._id,
                                    desName: via[index + 1]?.itemName,
                                    desLATLNG: via && via[index + 1] && via[index + 1]?.centerCoordinates && via[index + 1]?.centerCoordinates[index + 1] && via[index + 1]?.centerCoordinates[0][0] ? via[index + 1]?.centerCoordinates[0][0]?.join(",") : '',
                                },
                                // userId: userDetailsRes?._id,
                            };
                            let res = await getDistanceFromDb(payload2)
                            if (res) {
                                temp.viaPoints[index + 1].totalDistance = res?.data?.distance || res;
                                let time = calcTime(res?.data?.distance || res, temp?.viaPoints[index + 1]?.speed)
                                temp.viaPoints[index + 1].etaTime = convertHHMMToDate(time)
                            }
                        }
                    }

                }
            }
        }
        else if (!src?._id && via?.length && destination?._id) {
            if (item === 'via') {
                if (via.length === 1) {
                    let sourceObj;
                    let destObj;
                    if (via[0]?._id) {
                        sourceObj = {
                            _id: via[0]?._id,
                            srcName: via[0]?.itemName,
                            srcLATLNG: via && via[0] && via[0]?.centerCoordinates && via[0]?.centerCoordinates[0] && via[0]?.centerCoordinates[0][0] ? via[0]?.centerCoordinates[0][0]?.join(",") : '',
                        };
                        destObj = {
                            _id: destination?._id,
                            desName: destination?.name,
                            desLATLNG: destination && destination && destination?.centerCoordinates && destination?.centerCoordinates[0] && destination?.centerCoordinates[0][0] ? destination?.centerCoordinates[0][0]?.join(",") : '',
                        }
                    }
                    let payload = {
                        sourceObj: sourceObj,
                        destinationObj: destObj,
                        // userId: userDetailsRes?._id,
                    };
                    let res = await getDistanceFromDb(payload)
                    if (res) {
                        temp.routeDestination.totalDistance = res?.data?.distance || res;
                        let time = calcTime(res?.data?.distance || res, temp?.routeDestination?.speed)
                        temp.routeDestination.etaTime = convertHHMMToDate(time)

                    }
                }
                else if (index === via.length - 1) {
                    if (via[index - 1]?._id) {
                        let sourceObj = {
                            _id: via[index - 1]?._id,
                            srcName: via[index - 1]?.itemName,
                            srcLATLNG: via && via[index - 1] && via[index - 1]?.centerCoordinates && via[index - 1]?.centerCoordinates[0] && via[index - 1]?.centerCoordinates[0][0] ? via[index - 1]?.centerCoordinates[0][0]?.join(",") : '',
                        };
                        let destObj = {
                            _id: via[index]?._id,
                            desName: via[index]?.itemName,
                            desLATLNG: via && via[index] && via[index]?.centerCoordinates && via[index]?.centerCoordinates[0] && via[index]?.centerCoordinates[0][0] ? via[index]?.centerCoordinates[0][0]?.join(",") : '',
                        };

                        let payload = {
                            sourceObj: sourceObj,
                            destinationObj: destObj,
                            // userId: userDetailsRes?._id,
                        };
                        let res = await getDistanceFromDb(payload)
                        if (res) {
                            temp.viaPoints[index].totalDistance = res?.data?.distance || res;
                            let time = calcTime(res?.data?.distance || res, temp?.viaPoints[index]?.speed)
                            temp.viaPoints[index].etaTime = convertHHMMToDate(time)
                        }
                    }
                    if (via[index]?._id) {
                        let sourceObj = {
                            _id: via[index]?._id,
                            srcName: via[index]?.itemName,
                            srcLATLNG: via && via[index] && via[index]?.centerCoordinates && via[index]?.centerCoordinates[0] && via[index]?.centerCoordinates[0][0] ? via[index]?.centerCoordinates[0][0]?.join(",") : '',
                        };
                        let destObj = {
                            _id: destination?._id,
                            desName: destination?.name,
                            desLATLNG: destination && destination?.centerCoordinates && destination?.centerCoordinates[0] && destination?.centerCoordinates[0][0] ? destination?.centerCoordinates[0][0]?.join(",") : '',
                        };

                        let payload = {
                            sourceObj: sourceObj,
                            destinationObj: destObj,
                            // userId: userDetailsRes?._id,
                        };
                        let res = await getDistanceFromDb(payload)
                        if (res) {
                            temp.routeDestination.totalDistance = res?.data?.distance || res;
                            let time = calcTime(res?.data?.distance || res, temp?.routeDestination?.speed)
                            temp.routeDestination.etaTime = convertHHMMToDate(time)
                        }
                    }
                }
                else {
                    if (via?.length > 1) {
                        if (index === 0) {
                            let sourceObj = {
                                _id: via[index]?._id,
                                srcName: via[index]?.itemName,
                                srcLATLNG: via && via[index] && via[index]?.centerCoordinates && via[index]?.centerCoordinates[0] && via[index]?.centerCoordinates[0][0] ? via[index]?.centerCoordinates[0][0]?.join(",") : '',
                            };
                            let destObj = {
                                _id: via[index + 1]?._id,
                                desName: via[index + 1]?.itemName,
                                desLATLNG: via && via[index + 1]?.centerCoordinates && via[index + 1]?.centerCoordinates[0] && via[index + 1]?.centerCoordinates[0][0] ? via[index + 1]?.centerCoordinates[0][0]?.join(",") : '',
                            };

                            let payload = {
                                sourceObj: sourceObj,
                                destinationObj: destObj,
                                // userId: userDetailsRes?._id,
                            };
                            let res = await getDistanceFromDb(payload)
                            if (res) {
                                temp.viaPoints[index + 1].totalDistance = res?.data?.distance || res;
                                let time = calcTime(res?.data?.distance || res, temp?.viaPoints[index + 1]?.speed)
                                temp.viaPoints[index + 1].etaTime = convertHHMMToDate(time)
                            }
                        }
                        else {
                            if (via[index - 1]?._id) {
                                let payload1 = {
                                    sourceObj: {
                                        _id: via[index - 1]?._id,
                                        srcName: via[index - 1]?.itemName,
                                        srcLATLNG: via && via[index - 1] && via[index - 1]?.centerCoordinates && via[index - 1]?.centerCoordinates[0] && via[index - 1]?.centerCoordinates[0][0] ? via[index - 1]?.centerCoordinates[0][0]?.join(",") : '',
                                    },
                                    destinationObj: {
                                        _id: via[index]?._id,
                                        desName: via[index]?.itemName,
                                        desLATLNG: via && via[index] && via[index]?.centerCoordinates && via[index]?.centerCoordinates[0] && via[index]?.centerCoordinates[0][0] ? via[index]?.centerCoordinates[0][0]?.join(",") : '',
                                    },
                                    // userId: userDetailsRes?._id,
                                };
                                let res = await getDistanceFromDb(payload1)
                                if (res) {
                                    temp.viaPoints[index].totalDistance = res?.data?.distance || res;
                                    let time = calcTime(res?.data?.distance || res, temp?.viaPoints[index]?.speed)
                                    temp.viaPoints[index].etaTime = convertHHMMToDate(time)
                                }
                            }
                            if (via[index + 1]?._id) {
                                let payload2 = {
                                    sourceObj: {
                                        _id: via[index]?._id,
                                        srcName: via[index]?.itemName,
                                        srcLATLNG: via && via[index] && via[index]?.centerCoordinates && via[index]?.centerCoordinates[0] && via[index]?.centerCoordinates[0][0] ? via[index]?.centerCoordinates[0][0]?.join(",") : '',
                                    },
                                    destinationObj: {
                                        _id: via[index + 1]?._id,
                                        desName: via[index + 1]?.itemName,
                                        desLATLNG: via && via[index + 1] && via[index + 1]?.centerCoordinates && via[index + 1]?.centerCoordinates[index + 1] && via[index + 1]?.centerCoordinates[0][0] ? via[index + 1]?.centerCoordinates[0][0]?.join(",") : '',
                                    },
                                    // userId: userDetailsRes?._id,
                                };
                                let res = await getDistanceFromDb(payload2)
                                if (res) {
                                    temp.viaPoints[index + 1].totalDistance = res?.data?.distance || res;
                                    let time = calcTime(res?.data?.distance || res, temp?.viaPoints[index + 1]?.speed)
                                    temp.viaPoints[index + 1].etaTime = convertHHMMToDate(time)
                                }
                            }
                        }
                    }
                }
            }
            else {
                if (via[via.length - 1]?._id) {
                    let sourceObj = {
                        _id: via[via.length - 1]?._id,
                        srcName: via[via.length - 1]?.itemName,
                        srcLATLNG: via && via[via.length - 1] && via[via.length - 1]?.centerCoordinates && via[via.length - 1]?.centerCoordinates[0] && via[via.length - 1]?.centerCoordinates[0][0] ? via[via.length - 1]?.centerCoordinates[0][0]?.join(",") : '',
                    };
                    let destObj = {
                        _id: destination?._id,
                        desName: destination?.name,
                        desLATLNG: destination && destination && destination?.centerCoordinates && destination?.centerCoordinates[0] && destination?.centerCoordinates[0][0] ? destination?.centerCoordinates[0][0]?.join(",") : '',
                    };

                    let payload = {
                        sourceObj: sourceObj,
                        destinationObj: destObj,
                        // userId: userDetailsRes?._id,
                    };
                    let res = await getDistanceFromDb(payload)
                    if (res) {
                        temp.routeDestination.totalDistance = res?.data?.distance || res;
                        let time = calcTime(res?.data?.distance || res, temp?.routeDestination?.speed)
                        temp.routeDestination.etaTime = convertHHMMToDate(time)
                    }
                }
            }
        }
        else {
            if (item === 'src') {
                let payload = {
                    sourceObj: {
                        _id: src?._id,
                        srcName: src?.name,
                        srcLATLNG: src && src?.centerCoordinates && src?.centerCoordinates[0] && src?.centerCoordinates[0][0] ? src?.centerCoordinates[0][0]?.join(",") : '',
                    },
                    destinationObj: {
                        _id: via[0]?._id,
                        desName: via[0]?.itemName,
                        desLATLNG: via && via[0]?.centerCoordinates && via[0]?.centerCoordinates[0] && via[0]?.centerCoordinates[0][0] ? via[0]?.centerCoordinates[0][0]?.join(",") : '',
                    },
                    // userId: userDetailsRes?._id,
                };
                let res = await getDistanceFromDb(payload)
                if (res) {
                    temp.viaPoints[0].totalDistance = res?.data?.distance || res;
                    let time = calcTime(res?.data?.distance || res, temp?.viaPoints[0]?.speed)
                    temp.viaPoints[0].etaTime = convertHHMMToDate(time)
                }
            }
            else if (item === 'dest') {
                let payload = {
                    sourceObj: {
                        _id: via[via.length - 1]?._id,
                        srcName: via[via.length - 1]?.itemName,
                        srcLATLNG: via && via[via.length - 1]?.centerCoordinates && via[via.length - 1]?.centerCoordinates[0] && via[via.length - 1]?.centerCoordinates[0][0] ? via[via.length - 1]?.centerCoordinates[0][0]?.join(",") : '',
                    },
                    destinationObj: {
                        _id: destination?._id,
                        desName: destination?.name,
                        desLATLNG: destination && destination?.centerCoordinates && destination?.centerCoordinates[0] && destination?.centerCoordinates[0][0] ? destination?.centerCoordinates[0][0]?.join(",") : '',
                    },
                    // userId: userDetailsRes?._id,
                };
                let res = await getDistanceFromDb(payload)
                if (res) {
                    temp.routeDestination.totalDistance = res?.data?.distance || res;
                    let time = calcTime(res?.data?.distance || res, temp?.routeDestination?.speed)
                    temp.routeDestination.etaTime = convertHHMMToDate(time)
                }
            }
            else {
                if (via.length === 1) {
                    if (src?._id && via[0]?._id) {
                        let payload = {
                            sourceObj: {
                                _id: src?._id,
                                srcName: src?.name,
                                srcLATLNG: src && src?.centerCoordinates && src?.centerCoordinates[0] && src?.centerCoordinates[0][0] ? src?.centerCoordinates[0][0]?.join(",") : '',
                            },
                            destinationObj: {
                                _id: via[0]?._id,
                                desName: via[0]?.itemName,
                                desLATLNG: via && via[0]?.centerCoordinates && via[0]?.centerCoordinates[0] && via[0]?.centerCoordinates[0][0] ? via[0]?.centerCoordinates[0][0]?.join(",") : '',
                            },
                            // userId: userDetailsRes?._id,
                        };
                        let res = await getDistanceFromDb(payload)
                        if (res) {
                            temp.viaPoints[0].totalDistance = res?.data?.distance || res;
                            let time = calcTime(res?.data?.distance || res, temp?.viaPoints[0]?.speed)
                            temp.viaPoints[0].etaTime = convertHHMMToDate(time)
                        }
                    }

                    if (via[0]?._id && destination?._id) {
                        let payload = {
                            sourceObj: {
                                _id: via[0]?._id,
                                desName: via[0]?.itemName,
                                srcLATLNG: via && via[0]?.centerCoordinates && via[0]?.centerCoordinates[0] && via[0]?.centerCoordinates[0][0] ? via[0]?.centerCoordinates[0][0]?.join(",") : '',
                            },
                            destinationObj: {
                                _id: destination?._id,
                                desName: destination?.name,
                                desLATLNG: destination && destination?.centerCoordinates && destination?.centerCoordinates[0] && destination?.centerCoordinates[0][0] ? destination?.centerCoordinates[0][0]?.join(",") : '',
                            },
                            // userId: userDetailsRes?._id,
                        };
                        let res = await getDistanceFromDb(payload)
                        if (res) {
                            temp.routeDestination.totalDistance = res?.data?.distance || res;
                            let time = calcTime(res?.data?.distance || res, temp?.routeDestination?.speed)
                            temp.routeDestination.etaTime = convertHHMMToDate(time)
                        }
                    }
                }
                else if (index === via.length - 1) {
                    if (via[index - 1]?._id && via[index]?._id) {
                        let payload = {
                            sourceObj: {
                                _id: via[index - 1]?._id,
                                srcName: via[index - 1]?.itemName,
                                srcLATLNG: via && via[index - 1]?.centerCoordinates && via[index - 1]?.centerCoordinates[0] && via[index - 1]?.centerCoordinates[0][0] ? via[index - 1]?.centerCoordinates[0][0]?.join(",") : '',
                            },
                            destinationObj: {
                                _id: via[index]?._id,
                                desName: via[index]?.itemName,
                                desLATLNG: via && via[index]?.centerCoordinates && via[index]?.centerCoordinates[0] && via[index]?.centerCoordinates[0][0] ? via[0]?.centerCoordinates[0][0]?.join(",") : '',
                            },
                            // userId: userDetailsRes?._id,
                        };
                        let res = await getDistanceFromDb(payload)
                        if (res) {
                            temp.viaPoints[index].totalDistance = res?.data?.distance || res;
                            let time = calcTime(res?.data?.distance || res, temp?.viaPoints[index]?.speed)
                            temp.viaPoints[index].etaTime = convertHHMMToDate(time)
                        }
                    }

                    if (via[index]?._id && destination?._id) {
                        let payload = {
                            sourceObj: {
                                _id: via[index]?._id,
                                srcName: via[index]?.itemName,
                                srcLATLNG: via && via[index]?.centerCoordinates && via[index]?.centerCoordinates[0] && via[index]?.centerCoordinates[0][0] ? via[index]?.centerCoordinates[0][0]?.join(",") : '',
                            },
                            destinationObj: {
                                _id: destination?._id,
                                desName: destination?.name,
                                desLATLNG: destination && destination?.centerCoordinates && destination?.centerCoordinates[0] && destination?.centerCoordinates[0][0] ? destination?.centerCoordinates[0][0]?.join(",") : '',
                            },
                            // userId: userDetailsRes?._id,
                        };
                        let res = await getDistanceFromDb(payload)
                        if (res) {
                            temp.routeDestination.totalDistance = res?.data?.distance || res;
                            let time = calcTime(res?.data?.distance || res, temp?.routeDestination?.speed)
                            temp.routeDestination.etaTime = convertHHMMToDate(time)
                        }
                    }
                }
                else {
                    if (index === 0) {
                        if (src?._id && via[0]?._id) {
                            let payload = {
                                sourceObj: {
                                    _id: src?._id,
                                    srcName: src?.name,
                                    srcLATLNG: src && src?.centerCoordinates && src?.centerCoordinates[0] && src?.centerCoordinates[0][0] ? src?.centerCoordinates[0][0]?.join(",") : '',
                                },
                                destinationObj: {
                                    _id: via[0]?._id,
                                    desName: via[0]?.itemName,
                                    desLATLNG: via && via[0]?.centerCoordinates && via[0]?.centerCoordinates[0] && via[0]?.centerCoordinates[0][0] ? via[0]?.centerCoordinates[0][0]?.join(",") : '',
                                },
                                // userId: userDetailsRes?._id,
                            };
                            let res = await getDistanceFromDb(payload)
                            if (res) {
                                temp.viaPoints[0].totalDistance = res?.data?.distance || res;
                                let time = calcTime(res?.data?.distance || res, temp?.viaPoints[0]?.speed)
                                temp.viaPoints[0].etaTime = convertHHMMToDate(time)
                            }
                        }

                        if (via[0]?._id && via[index + 1]?._id) {
                            let payload = {
                                sourceObj: {
                                    _id: via[0]?._id,
                                    srcName: via[0]?.itemName,
                                    srcLATLNG: via[0] && via[0]?.centerCoordinates && via[0]?.centerCoordinates[0] && via[0]?.centerCoordinates[0][0] ? via[0]?.centerCoordinates[0][0]?.join(",") : '',
                                },
                                destinationObj: {
                                    _id: via[index + 1]?._id,
                                    desName: via[index + 1]?.itemName,
                                    desLATLNG: via && via[index + 1]?.centerCoordinates && via[index + 1]?.centerCoordinates[0] && via[index + 1]?.centerCoordinates[0][index + 1] ? via[index + 1]?.centerCoordinates[0][0]?.join(",") : '',
                                },
                                // userId: userDetailsRes?._id,
                            };
                            let res = await getDistanceFromDb(payload)
                            if (res) {
                                temp.viaPoints[index + 1].totalDistance = res?.data?.distance || res;
                                let time = calcTime(res?.data?.distance || res, temp?.viaPoints[index + 1]?.speed)
                                temp.viaPoints[index + 1].etaTime = convertHHMMToDate(time)
                            }
                        }
                    }
                    else {
                        if (via[index - 1]?._id && via[index]?._id) {
                            let payload = {
                                sourceObj: {
                                    _id: via[index - 1]?._id,
                                    srcName: via[index - 1]?.itemName,
                                    srcLATLNG: via && via[index - 1]?.centerCoordinates && via[index - 1]?.centerCoordinates[0] && via[index - 1]?.centerCoordinates[0][0] ? via[index - 1]?.centerCoordinates[0][0]?.join(",") : '',
                                },
                                destinationObj: {
                                    _id: via[index]?._id,
                                    desName: via[index]?.itemName,
                                    desLATLNG: via && via[index]?.centerCoordinates && via[index]?.centerCoordinates[0] && via[index]?.centerCoordinates[0][0] ? via[0]?.centerCoordinates[0][0]?.join(",") : '',
                                },
                                // userId: userDetailsRes?._id,
                            };
                            let res = await getDistanceFromDb(payload)
                            if (res) {
                                temp.viaPoints[index].totalDistance = res?.data?.distance || res;
                                let time = calcTime(res?.data?.distance || res, temp?.viaPoints[index]?.speed)
                                temp.viaPoints[index].etaTime = convertHHMMToDate(time)
                            }
                        }

                        if (via[index]?._id && via[index + 1]?._id) {
                            let payload = {
                                sourceObj: {
                                    _id: via[index]?._id,
                                    srcName: via[index]?.itemName,
                                    srcLATLNG: via && via[index]?.centerCoordinates && via[index]?.centerCoordinates[0] && via[index]?.centerCoordinates[0][0] ? via[index]?.centerCoordinates[0][0]?.join(",") : '',
                                },
                                destinationObj: {
                                    _id: via[index + 1]?._id,
                                    desName: via[index + 1]?.itemName,
                                    desLATLNG: via && via[index + 1]?.centerCoordinates && via[index + 1]?.centerCoordinates[0] && via[index + 1]?.centerCoordinates[0][0] ? via[index + 1]?.centerCoordinates[0][0]?.join(",") : '',
                                },
                                // userId: userDetailsRes?._id,
                            };
                            let res = await getDistanceFromDb(payload)
                            if (res) {
                                temp.viaPoints[index + 1].totalDistance = res?.data?.distance || res;
                                let time = calcTime(res?.data?.distance || res, temp?.viaPoints[index + 1]?.speed)
                                temp.viaPoints[index + 1].etaTime = convertHHMMToDate(time)
                            }
                        }
                    }
                }
            }



        }
        setRoutePayload(temp)

    }

    const calcTime = useCallback((distance, speed) => {
        const timeHours = distance / speed;
        const hours = Math.floor(timeHours);
        const minutes = Math.round((timeHours - hours) * 60);

        // Format the time as "hh:mm"
        const formattedTime = `${String(hours).padStart(2, "0")}:${String(
            minutes
        ).padStart(2, "0")}`;
        return formattedTime;
    }, []);

    function convertHHMMToDate(hhmm = "") {
        if (typeof hhmm !== "string") {
            return null;
        }
        const [hours, minutes] = hhmm?.split(":").map(Number);
        const now = new Date();
        const resultDate = new Date(setMinutes(setHours(now, hours), minutes));
        return resultDate;
    }

    const calcSpeed = useCallback((distance, time) => {
        const date = new Date(time);

        const hours = date.getHours();
        const minutes = date.getMinutes();

        const timeInHours = hours + minutes / 60; // Convert minutes to a fraction of an hour
        const speed = distance / timeInHours;

        return Math.round(speed);
    }, []);

    useEffect(() => {
        let payload = { "category": "route" }
        reset();

        dispatch(flushGeofenceList());
        dispatch(checkTemplateExistance(payload));
        setIsDraggingRoute(false);
        setRouteLegs([]);
        setRouteLegsArr([]);
        setActivePath(0);
        if (navigator.geolocation) {
            navigator.geolocation?.getCurrentPosition(successCallback, errorCallback);
        }
        setIsGettingDirections(false);
    }, [])


    const successCallback = (position) => {
        const currentLocationCoords = { lat: position?.coords?.latitude, lng: position?.coords?.longitude }
        setCenterCoordinates(currentLocationCoords ?? center)


    };

    const errorCallback = (error) => {
        console.log(error);
    };

    const clearMarkers = () => {
        myMarkers.forEach(marker => marker.setMap(null));
        setMarkers([]);
    };

    const clearMap = () => {
        // myMarkers.forEach((marker) => {
        //   marker.setMap(null);
        // });
        if (polyline) {
            polyline.setMap(null);
        }

        clearMarkers();
        setPolyline(null);
        setRouteLegs([]);
        setRouteLegsArr([]);
    };

    const handleDirectionsChanged = () => {
        if (isDraggingRoute && directionsRendererRef.current) {
            const newDirections = directionsRendererRef.current.getDirections();
            const updatedDirections = newDirections;
            const normalizedDirections = updatedDirections;
            if (JSON.stringify(normalizedDirections.routes[0].legs) !== JSON.stringify(routeLegsArr[activePath]?.directions?.routes[0].legs)) {
                let tempRoutePaths = routeLegsArr;
                let activeRoutePath = tempRoutePaths[activePath];

                activeRoutePath.directions = normalizedDirections;
                activeRoutePath.routeLegs = normalizedDirections.routes[0].legs;
                tempRoutePaths[activePath] = activeRoutePath;
                setRouteLegsArr([...tempRoutePaths]);
            }
        }
    };

    const addNewPath = () => {
        let tempPathArr = routeLegsArr;
        tempPathArr.push({
            directions,
            routeLegs,
        });
        setRouteLegsArr([...tempPathArr]);
    }

    const removePath = (index) => {
        const tempRouteLegs = routeLegsArr.filter((_, i) => i !== index);
        setRouteLegsArr(tempRouteLegs);

        let newPathIndex;
        if (index >= tempRouteLegs.length) {
            newPathIndex = tempRouteLegs.length - 1;
        } else {
            newPathIndex = index;
        }

        setIsDraggingRoute(false);
        setTimeout(() => {
            setActivePath(newPathIndex);
        }, 500)
    };

    const plotOnMap = async () => {
        console.log('heyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyy')
        if (!plotBygoogle || !myMap) {
            return;
        }
        clearMap();
        let origin;
        let destination;
        const directionsService = myMap && new google.maps.DirectionsService();

        if (!routePayload?.routeSource?._id || !routePayload?.routeDestination?._id) {
            setDirections(null);
            setRouteLegsArr([]);
            return;
        }

        console.log('routePayload---',routePayload)

        if (routeDetails && isEditRoute && id) {
            let SourceRes = await getGeofenceDetail(routeDetails?.routeSource?._id);
            let destinationRes = await getGeofenceDetail(routeDetails?.routeDestination?._id);
            
            origin = {
                lat: SourceRes?.centerCoordinates?.[0]?.[0]?.[1],
                lng: SourceRes?.centerCoordinates?.[0]?.[0]?.[0],
            };

            destination = {
                lat: destinationRes?.centerCoordinates?.[0]?.[0]?.[1],
                lng: destinationRes?.centerCoordinates?.[0]?.[0]?.[0],
            };
        }
        else {
            origin = {
                lat: routePayload?.routeSource?.centerCoordinates?.[0]?.[0]?.[1],
                lng: routePayload?.routeSource?.centerCoordinates?.[0]?.[0]?.[0],
            };
            destination = {
                lat: routePayload?.routeDestination?.centerCoordinates?.[0]?.[0]?.[1],
                lng: routePayload?.routeDestination?.centerCoordinates?.[0]?.[0]?.[0],
            };
        }
        let viaPointsCoords = []

        if (routePayload?.viaPoints?.length > 0) {
            viaPointsCoords = await Promise.all(
                routePayload.viaPoints.map(async (viaPoint) => {
                    if (viaPoint?._id) {
                        const geofenceDetail = await getGeofenceDetail(viaPoint._id);
                        const geofenceCoordinates = geofenceDetail?.centerCoordinates;
                        if (geofenceCoordinates) {
                            return {
                                location: {
                                    lat: geofenceCoordinates[0][0][1],
                                    lng: geofenceCoordinates[0][0][0],
                                },
                            };
                        }
                    }
                    return undefined;
                })
            );
            viaPointsCoords = viaPointsCoords.filter(coordinate => coordinate !== undefined);
        }
        
      

        if (origin.lat && origin.lng && destination.lat && destination.lng) {
            setTimeout(() => {
                directionsService.route(
                    {
                        origin: origin,
                        destination: destination,
                        travelMode: google.maps.TravelMode.DRIVING,
                        unitSystem: google.maps.UnitSystem.METRIC,
                        waypoints: viaPointsCoords || [],
                    },
                    (result, status) => {
                        if (status === google.maps.DirectionsStatus.OK) {
                            let newResult = JSON.parse(JSON.stringify(result));
                            setDirections(newResult);
                            let newLegs = [];

                            newResult.routes[0].legs.forEach((leg, index) => {
                                newLegs.push(leg);
                                if (index === 0) {
                                    makeMarker(leg.start_location, icons.start, "title");
                                }
                                if (index === newResult.routes[0].legs.length - 1) {
                                    makeMarker(leg.end_location, icons.end, "title");
                                } else {
                                    makeMarker(leg.end_location, icons.via, "title");
                                }
                            });
                            setIsDraggingRoute(false);
                            setRouteLegs([...newLegs]);
                            setRouteLegsArr([{
                                directions: result,
                                routeLegs: [...newLegs]
                            }]);
                            setActivePath(0);
                        } else {
                            console.log("Directions API Error--", status);
                        }
                    }
                );
            }, 20)

        }
    }

    var icons = myMap && {
        start: new google.maps.MarkerImage(
            // URL
            SourceImg,
            // (width,height)
            new google.maps.Size(44, 32),
            // The origin point (x,y)
            new google.maps.Point(0, 0),
            // The anchor point (x,y)
            new google.maps.Point(22, 32)
        ),
        end: new google.maps.MarkerImage(
            // URL
            DestinationImg,
            // (width,height)
            new google.maps.Size(44, 32),
            // The origin point (x,y)
            new google.maps.Point(0, 0),
            // The anchor point (x,y)
            new google.maps.Point(22, 32)
        ),
        via: new google.maps.MarkerImage(
            // URL
            ViaPointImg,
            // (width,height)
            new google.maps.Size(44, 32),
            // The origin point (x,y)
            new google.maps.Point(0, 0),
            // The anchor point (x,y)
            new google.maps.Point(22, 32)
        ),
    };

    function makeMarker(position, icon, title) {
        let tempMarkers = myMarkers;
        const marker = new google.maps.Marker({
            position: position,
            map: myMap,
            icon: icon,
            title: ''
        });
        tempMarkers.push(marker)
        setMarkers(tempMarkers);

    }
    const handleCancel = useCallback(() => {
        sendCustomEventAnalytics("routes", "cancel-route", "Cancel Route");
        reset();
        history.push("/dashboard/routes");
    }, []);

    const dateForArrival = (data, previousDate) => {
        if (previousDate && Object.prototype.toString.call(previousDate) === '[object Date]' && !isNaN(previousDate.getTime())) {
            if (data.dayArrival === "sameDay") {
                const originalDate = new Date(previousDate);
                const [hours, minutes] = data.STA.split(':').map(Number);
                const newDate = new Date(originalDate);
                newDate.setHours(hours, minutes, 0, 0);
                return newDate;
            } else if (data.dayArrival === "nextDay") {
                const originalDate = new Date(previousDate);
                const nextDate = new Date(originalDate);
                nextDate.setDate(originalDate.getDate() + 1);
                const [hours, minutes] = data.STA.split(':').map(Number);
                nextDate.setHours(hours, minutes, 0, 0);
                return nextDate;
            } else {
                const originalDate = new Date(previousDate);
                const nextDate = new Date(originalDate);
                nextDate.setDate(originalDate.getDate() + Number(data.dayArrival - 1));
                const [hours, minutes] = data.STA.split(':').map(Number);
                nextDate.setHours(hours, minutes, 0, 0);
                return nextDate;
            }

        } else {
            if (data.dayArrival === "sameDay") {
                const originalDate = new Date();
                const [hours, minutes] = data.STA.split(':').map(Number);
                const newDate = new Date(originalDate);
                newDate.setHours(hours, minutes, 0, 0);
                return newDate;
            } else if (data.dayArrival === "nextDay") {
                const originalDate = new Date();
                const nextDate = new Date(originalDate);
                nextDate.setDate(originalDate.getDate() + 1);
                const [hours, minutes] = data.STA.split(':').map(Number);
                nextDate.setHours(hours, minutes, 0, 0);
                return nextDate;
            } else {
                const originalDate = new Date();
                const nextDate = new Date(originalDate);
                nextDate.setDate(originalDate.getDate() + Number(data.dayArrival - 1));
                const [hours, minutes] = data.STA.split(':').map(Number);
                nextDate.setHours(hours, minutes, 0, 0);
                return nextDate;
            }

        }

    }
    const dateForDeparture = (data, previousDate) => {
        if (previousDate && Object.prototype.toString.call(previousDate) === '[object Date]' && !isNaN(previousDate.getTime())) {
            if (data.dayDeparture === "sameDay") {
                const originalDate = new Date(previousDate);
                const [hours, minutes] = data.STD.split(':').map(Number);
                const newDate = new Date(originalDate);
                newDate.setHours(hours, minutes, 0, 0);
                return newDate;
            } else if (data.dayDeparture === "nextDay") {
                const originalDate = new Date(previousDate);
                const nextDate = new Date(originalDate);
                nextDate.setDate(originalDate.getDate() + 1);
                const [hours, minutes] = data.STD.split(':').map(Number);
                nextDate.setHours(hours, minutes, 0, 0);
                return nextDate;
            } else {
                const originalDate = new Date(previousDate);
                const nextDate = new Date(originalDate);
                nextDate.setDate(originalDate.getDate() + Number(data.dayDeparture - 1));
                const [hours, minutes] = data.STD.split(':').map(Number);
                nextDate.setHours(hours, minutes, 0, 0);
                return nextDate;
            }

        } else {
            if (data.dayDeparture === "sameDay") {
                if (data.dayArrival === "sameDay") {
                    const originalDate = new Date();
                    const [hours, minutes] = data.STD.split(':').map(Number);
                    const newDate = new Date(originalDate);
                    newDate.setHours(hours, minutes, 0, 0);
                    return newDate;
                } else if (data.dayArrival === "nextDay") {
                    const originalDate = new Date();
                    const nextDate = new Date(originalDate);
                    nextDate.setDate(originalDate.getDate() + 1);
                    const [hours, minutes] = data.STD.split(':').map(Number);
                    nextDate.setHours(hours, minutes, 0, 0);
                    return nextDate;
                } else {
                    const originalDate = new Date();
                    const nextDate = new Date(originalDate);
                    nextDate.setDate(originalDate.getDate() + Number(data.dayArrival - 1));
                    const [hours, minutes] = data.STD.split(':').map(Number);
                    nextDate.setHours(hours, minutes, 0, 0);
                    return nextDate;
                }
            } else if (data.dayDeparture === "nextDay") {
                if (data.dayArrival === "sameDay") {
                    const originalDate = new Date();
                    const nextDate = new Date(originalDate);
                    nextDate.setDate(originalDate.getDate() + 1);
                    const [hours, minutes] = data.STD.split(':').map(Number);
                    nextDate.setHours(hours, minutes, 0, 0);
                    return nextDate;
                } else if (data.dayArrival === "nextDay") {
                    const originalDate = new Date();
                    const nextDate = new Date(originalDate);
                    nextDate.setDate(originalDate.getDate() + 2);
                    const [hours, minutes] = data.STD.split(':').map(Number);
                    nextDate.setHours(hours, minutes, 0, 0);
                    return nextDate;
                } else {
                    const originalDate = new Date();
                    const nextDate = new Date(originalDate);
                    nextDate.setDate(originalDate.getDate() + Number(data.dayArrival - 1) + 1);
                    const [hours, minutes] = data.STD.split(':').map(Number);
                    nextDate.setHours(hours, minutes, 0, 0);
                    return nextDate;
                }
            } else {
                if (data.dayArrival === "sameDay") {
                    const originalDate = new Date();
                    const nextDate = new Date(originalDate);
                    nextDate.setDate(originalDate.getDate() + Number(data.dayDeparture - 1));
                    const [hours, minutes] = data.STD.split(':').map(Number);
                    nextDate.setHours(hours, minutes, 0, 0);
                    return nextDate;
                } else if (data.dayArrival === "nextDay") {
                    const originalDate = new Date();
                    const nextDate = new Date(originalDate);
                    nextDate.setDate(originalDate.getDate() + 2 + Number(data.dayDeparture - 1));
                    const [hours, minutes] = data.STD.split(':').map(Number);
                    nextDate.setHours(hours, minutes, 0, 0);
                    return nextDate;
                } else {
                    const originalDate = new Date();
                    const nextDate = new Date(originalDate);
                    nextDate.setDate(originalDate.getDate() + Number(data.dayArrival - 1) + Number(data.dayDeparture - 1));
                    const [hours, minutes] = data.STD.split(':').map(Number);
                    nextDate.setHours(hours, minutes, 0, 0);
                    return nextDate;
                }

            }

        }
    }

    const validatePayload = (formData, stateData) => {
        const finalData = [];
        stateData.length > 0 && stateData.forEach(item => {
            const newItem = { ...item };
            if (newItem.scheduleVia) {
                newItem.scheduleVia = newItem.scheduleVia.map(via => ({ ...via }));
            }
            finalData.push(newItem);
        });
        let success = false;
        if (finalData.length > 0) {
            stateData.length > 0 && stateData.forEach((ele, index) => {
                ele["scheduleVia"]?.length > 0 && ele["scheduleVia"].forEach((innerele, innerIndex) => {
                    let first;
                    let second;
                    let third;
                    if (innerIndex === 0) {
                        first = dateForArrival(innerele, "").getTime() - formData[`scheduleDeparture${index}`].getTime();
                        second = dateForDeparture(innerele, "").getTime() - dateForArrival(innerele, "").getTime();
                        finalData[index].STD = formData[`scheduleDeparture${index}`];
                        finalData[index]["scheduleVia"][innerIndex].STA = dateForArrival(innerele, "");
                        finalData[index]["scheduleVia"][innerIndex].STD = dateForDeparture(innerele, "");
                    } else {
                        first = dateForArrival(innerele, finalData[index]["scheduleVia"][innerIndex - 1].STD).getTime() - (finalData[index]["scheduleVia"][innerIndex - 1].STD).getTime();
                        finalData[index]["scheduleVia"][innerIndex].STA = dateForArrival(innerele, finalData[index]["scheduleVia"][innerIndex - 1].STD);
                        second = dateForDeparture(innerele, finalData[index]["scheduleVia"][innerIndex].STA).getTime() - finalData[index]["scheduleVia"][innerIndex].STA.getTime();
                        finalData[index]["scheduleVia"][innerIndex].STD = dateForDeparture(innerele, finalData[index]["scheduleVia"][innerIndex].STA);
                    }
                    if (innerIndex === (ele["scheduleVia"]?.length - 1)) {
                        third = dateForArrival(ele, finalData[index]["scheduleVia"][ele["scheduleVia"]?.length - 1].STD).getTime() - (finalData[index]["scheduleVia"][ele["scheduleVia"]?.length - 1].STD).getTime();
                        finalData[index].STA = dateForArrival(ele, finalData[index]["scheduleVia"][ele["scheduleVia"]?.length - 1].STD);
                    }
                    const firstDiffHours = first / (1000 * 60 * 60);
                    const secondDiffHours = second / (1000 * 60 * 60);
                    const thirdDiffHours = third / (1000 * 60 * 60);
                    if (firstDiffHours <= 0) {
                        setError(`scheduleViaPointArrivalTime${index}_${innerIndex}`, {
                            type: "manual",
                            message: "Time can't less than Departure Time",
                        });
                        success = true;
                    }
                    if (secondDiffHours <= 0) {
                        setError(`scheduleViaPointDepartureTime${index}_${innerIndex}`, {
                            type: "manual",
                            message: "Time can't less than Arrival Time",
                        });
                        success = true;
                    }
                    if (thirdDiffHours <= 0) {
                        setError(`scheduleArrivalTime${index}`, {
                            type: "manual",
                            message: "Time can't less than Departure Time",
                        });
                        success = true;
                    }
                })
            })
        }

        return success

    }

    function convertohhmmformat(date) {
        if (!(date instanceof Date)) {
            // Handle the case where date is not a valid Date object
            return null;
        }
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');
        return `${hours}:${minutes}`;
    }

    const onSubmit = useCallback((data) => {

        const validation = validatePayload(data, schedules)
        if (validation) {
            showToast("Some value are invalid", true);
            return;
        }
        const finalViaPoints = routePayload?.viaPoints?.map(viaPoint => {
            const { etaTime, speed, ...rest } = viaPoint;
            const formattedEtaTime = convertohhmmformat(new Date(etaTime));

            return {
                ...rest,
                etaTime: formattedEtaTime,
                speed: Number(speed)
            };
        });


        schedules.forEach((schedule) => {
            delete schedule.key
        })
        let destinationhhmmtime = convertohhmmformat(routePayload?.routeDestination?.etaTime)
        const payload = {
            routeSource: routePayload?.routeSource,
            routeDestination: { ...routePayload?.routeDestination, etaTime: destinationhhmmtime, speed: Number(routePayload?.routeDestination?.speed) },
            viaPoints: finalViaPoints,
            routeName: routePayload?.routeName,
            description: routePayload?.description,
            batteryPercentage: routePayload?.batteryPercentage,
            scheduleData: schedules,
            templateId: templateExistence && templateExistence?._id || '',
            routeLegs: routeLegsArr[0]?.routeLegs,
            direction1: serializeDirection(routeLegsArr[0]?.directions),
        };


        let updatedPayload = { ...payload };

        if (routeLegsArr?.length > 1) {
            routeLegsArr.map((_, i) => {
                if (i !== 0) {
                    updatedPayload[`routeLegs${i}`] = routeLegsArr[i]?.routeLegs;
                    updatedPayload[`direction${i + 1}`] = serializeDirection(routeLegsArr[i]?.directions);
                }
            })
        }

        if (isSuperAdmin) {
            updatedPayload["user"] = user;
        }

        handleSubmit((data) => {
            const dynamicData = Object.keys(data).reduce((acc, key) => {
                if (key.startsWith("dynamic")) {
                    acc[key] = data[key];
                }
                return acc;
            }, {});
            let finalPayload = { ...updatedPayload, dynamicData }
            sendCustomEventAnalytics("routes", "create-route", "Create Route");

            if (!finalPayload?.routeName) {
                showToast('Route Name is required.', true)
            }
            else if (!finalPayload?.batteryPercentage) {
                showToast('Battery Percentage is required.', true)
            }
            else if (!finalPayload?.routeSource?._id) {
                showToast('Source is required.', true)
            }
            else if (!finalPayload?.routeDestination?._id) {
                showToast('Destination is required.', true)
            }
            // else if (finalPayload?.viaPoints?.length) {
            //     const invalidViaPoint = finalPayload.viaPoints.some(viaPoint =>
            //         !viaPoint._id ||
            //         !viaPoint.itemName ||
            //         !viaPoint.totalDistance ||
            //         !viaPoint.speed
            //     );

            //     if (invalidViaPoint) {
            //         showToast('Via Points must have valid data.', true);
            //     }
            //     else {

            //     }
            // }
            // else {
            //     if (currentUserDetailsObj && havePermission(currentUserDetailsObj, "routes", "flightDetails")) {
            //         delete finalPayload?.routeLegs
            //         delete finalPayload?.direction1
            //     }
            //     console.log('finalPayload---',finalPayload)
            //     setCreateLoader(true)
            //     // dispatch(
            //     //     createRoute(finalPayload, (res) => {
            //     //         if (res.success) {
            //     //             setCreateLoader(false)
            //     //             reset();
            //     //             clearMap()
            //     //             setRoutePayload({
            //     //                 "routeSource": {
            //     //                     "_id": "",
            //     //                     "name": "",
            //     //                     "centerCoordinates": ""
            //     //                 },
            //     //                 "routeDestination": {
            //     //                     "_id": "",
            //     //                     "name": "",
            //     //                     "speed": 40,
            //     //                     "etaTime": "",
            //     //                     "totalDistance": "",
            //     //                     "centerCoordinates": ""
            //     //                 },
            //     //                 "viaPoints": [],
            //     //                 "routeName": "",
            //     //                 "description": "",
            //     //                 "batteryPercentage": "",
            //     //                 "templateId": "",
            //     //                 "dynamicData": {}
            //     //             })
            //     //             setSchedules([])
            //     //             setPlotByGoogle(false)
            //     //             dispatch(flushRouteDetail())
            //     //             if (!addMore) {
            //     //                 history.push("/dashboard/routes");
            //     //             }
            //     //             if (query.get("fromIframe")) {
            //     //                 window.parent.postMessage('formSubmitted', '*');
            //     //             }

            //     //             setTimeout(() => {
            //     //                 setAddMore(false);
            //     //             }, 1000)
            //     //         }
            //     //         else {
            //     //             setCreateLoader(false)
            //     //         }
            //     //     })
            //     // );
            // }

            else {
                const { routeDestination, viaPoints } = finalPayload;
            
                const isRouteDestinationValid = routeDestination?.totalDistance !== '';
                const areViaPointsValid = viaPoints?.length > 0
                    ? viaPoints.every(point => point.totalDistance !== '')
                    : true;
            
                if (isRouteDestinationValid && areViaPointsValid) {
                    if (currentUserDetailsObj && !havePermission(currentUserDetailsObj, "routes", "flightDetails")) {
                        delete finalPayload?.routeLegs;
                        delete finalPayload?.direction1;
                    }

                    setCreateLoader(true);
                    dispatch(
                        createRoute(finalPayload, (res) => {
                            if (res.success) {
                                setCreateLoader(false);
                                reset();
                                clearMap();
                                setRoutePayload({
                                    "routeSource": {
                                        "_id": "",
                                        "name": "",
                                        "centerCoordinates": ""
                                    },
                                    "routeDestination": {
                                        "_id": "",
                                        "name": "",
                                        "speed": 40,
                                        "etaTime": "",
                                        "totalDistance": "",
                                        "centerCoordinates": ""
                                    },
                                    "viaPoints": [],
                                    "routeName": "",
                                    "description": "",
                                    "batteryPercentage": "",
                                    "templateId": "",
                                    "dynamicData": {}
                                });
                                setSchedules([]);
                                setPlotByGoogle(false);
                                dispatch(flushRouteDetail());
                                if (!addMore) {
                                    history.push("/dashboard/routes");
                                }
                                if (query.get("fromIframe")) {
                                    window.parent.postMessage('formSubmitted', '*');
                                }
            
                                setTimeout(() => {
                                    setAddMore(false);
                                }, 1000);
                            } else {
                                setCreateLoader(false);
                            }
                        })
                    );
                } else {
                    showToast('Route Destination distance or via Points distance is missing.',true);
                }
            }

        }, (errors) => {
            setCreateLoader(false)
            let errorField = Object.keys(errors)[0];
            showToast(`${errors[errorField].message}`, true);
        })();
    },
        [
            dispatch,
            isSuperAdmin,
            user,
            schedules,
            addMore,
            reset,
            routeLegsArr,
            routePayload
        ]
    );
    const handleAddMore = useCallback(() => {
        setAddMore((prev) => !prev);
      }, [setAddMore]);

    const handleEdit = useCallback(
        (data) => {
            const validation = validatePayload(data, schedules)
            if (validation) {
                showToast("Some value are invalid", true);
                return;
            }

            const finalViaPoints = routePayload?.viaPoints?.map(viaPoint => {
                const { etaTime, speed, ...rest } = viaPoint;
                const formattedEtaTime = convertohhmmformat(new Date(etaTime));

                return {
                    ...rest,
                    etaTime: formattedEtaTime,
                    speed: Number(speed)
                };
            });

            let destinationhhmmtime = convertohhmmformat(routePayload?.routeDestination?.etaTime)
            const payload = {
                id: id,
                routeName: routePayload?.routeName,
                description: routePayload?.description,
                batteryPercentage: routePayload?.batteryPercentage,
                templateId: templateExistence && templateExistence?._id || '',
                routeSource: routePayload?.routeSource,
                routeDestination: { ...routePayload?.routeDestination, etaTime: destinationhhmmtime, speed: Number(routePayload?.routeDestination?.speed) },
                viaPoints: finalViaPoints,
                scheduleData: schedules,
                routeLegs: routeLegsArr[0]?.routeLegs,
                direction1: serializeDirection(routeLegsArr[0]?.directions),
            };

            let updatedPayload = { ...payload };

            if (routeLegsArr?.length > 1) {
                routeLegsArr.map((_, i) => {
                    if (i !== 0) {
                        updatedPayload[`routeLegs${i}`] = routeLegsArr[i]?.routeLegs;
                        updatedPayload[`direction${i + 1}`] = serializeDirection(routeLegsArr[i]?.directions);
                    }
                })
            }

            handleSubmit((data) => {
                const dynamicData = Object.keys(data).reduce((acc, key) => {
                    if (key.startsWith("dynamic")) {
                        acc[key] = data[key];
                    }
                    return acc;
                }, {});
                let finalPayload = { ...updatedPayload, dynamicData }
                sendCustomEventAnalytics("routes", "edit-route", "Edit Route");

                if (!finalPayload?.routeName) {
                    showToast('Route Name is required.', true)
                }
                else if (!finalPayload?.batteryPercentage) {
                    showToast('Battery Percentage is required.', true)
                }
                else if (!finalPayload?.routeSource?._id) {
                    showToast('Source is required.', true)
                }
                else if (!finalPayload?.routeDestination?._id) {
                    showToast('Destination is required.', true)
                }
                // else if (finalPayload?.viaPoints?.length) {
                //     const invalidViaPoint = finalPayload.viaPoints.some(viaPoint =>
                //         !viaPoint._id ||
                //         !viaPoint.itemName ||
                //         !viaPoint.totalDistance ||
                //         !viaPoint.speed 
                //     );

                //     if (invalidViaPoint) {
                //         showToast('Via Points must have valid data.', true);
                //     }
                //     else {

                //     }
                // }
                // else {
                //     if (currentUserDetailsObj && havePermission(currentUserDetailsObj, "routes", "flightDetails")) {
                //         delete finalPayload?.routeLegs
                //         delete finalPayload?.direction1
                //     }
                //     if (routeDetails?.routeName === finalPayload?.routeName) {
                //         delete finalPayload?.routeName
                //     }
                //     setUpdateLoader(true)
                //     dispatch(updateRouteDetails(finalPayload, (res) => {
                //         if (res?.success) {
                //             setUpdateLoader(false)
                //             reset();
                //             setSchedules([])
                //             setViaPoints([])
                //             history.push("/dashboard/routes");
                //         }
                //         else {
                //             setUpdateLoader(false)
                //         }

                //     })
                //     );
                // }


                else {
                    const { routeDestination, viaPoints } = finalPayload;
                
                    // Check if routeDestination totalDistance is not empty
                    const isRouteDestinationValid = routeDestination?.totalDistance !== '';
                
                    // Check if viaPoints array exists and all viaPoints have a totalDistance
                    const areViaPointsValid = viaPoints?.length > 0
                        ? viaPoints.every(point => point.totalDistance !== '')
                        : true;
                
                    if (isRouteDestinationValid && areViaPointsValid) {
                        if (currentUserDetailsObj && !havePermission(currentUserDetailsObj, "routes", "flightDetails")) {
                            delete finalPayload?.routeLegs;
                            delete finalPayload?.direction1;
                        }
                        if (routeDetails?.routeName === finalPayload?.routeName) {
                            delete finalPayload?.routeName;
                        }
                        setUpdateLoader(true);
                        dispatch(
                            updateRouteDetails(finalPayload, (res) => {
                                if (res?.success) {
                                    setUpdateLoader(false);
                                    reset();
                                    setSchedules([]);
                                    setViaPoints([]);
                                    history.push("/dashboard/routes");
                                } else {
                                    setUpdateLoader(false);
                                }
                            })
                        );
                    } else {
                        showToast('Route Destination distance or via Points distance is missing.',true);
                    }
                }
                



            }, (errors) => {
                setUpdateLoader(false)
                let errorField = Object.keys(errors)[0];
                showToast(`${errors[errorField].message}`, true);
            })();


        },
        [viaPoints, routePayload, isSuperAdmin, schedules, routeLegsArr]
    );

    const serializeDirection = (directions) => {
        return JSON.stringify(directions, (key, value) => {
            if (value instanceof window.google.maps.LatLng) {
                return { lat: value.lat(), lng: value.lng() };
            }
            return value;
        });
    }

    const deserializeDirection = (directions) => {
        let updatedDirections;

        try {
            updatedDirections = JSON.parse(directions, (key, value) => {
                if (value && value.lat !== undefined && value.lng !== undefined) {
                    return new google.maps.LatLng(value.lat, value.lng);
                }
                return value;
            });
        } catch (error) {
            console.error('Error parsing directions JSON:', error);
            return;
        }

        updatedDirections.routes[0].legs.forEach((leg) => {
            leg.start_location = new google.maps.LatLng(leg.start_location.lat, leg.start_location.lng);
            leg.end_location = new google.maps.LatLng(leg.end_location.lat, leg.end_location.lng);
            leg.steps.forEach((step) => {
                step.start_location = new google.maps.LatLng(step.start_location.lat, step.start_location.lng);
                step.end_location = new google.maps.LatLng(step.end_location.lat, step.end_location.lng);
            });
        });

        return updatedDirections;

        // return JSON.parse(directions, (key, value) => {
        //   if (value && value.lat !== undefined && value.lng !== undefined) {
        //     return new window.google.maps.LatLng(value.lat, value.lng);
        //   }
        //   return value;
        // });
    }

    return (
        <>
            <Grid container>
                <Grid item sm={12} xs={12}>
                    <Grid container>
                        <Grid item sm={6} xs={4}>
                            <Typography sx={{ ...textClasses.cardTitle }}>
                                {!isEditRoute ? "Add Route" : "Edit Route"}
                            </Typography>
                        </Grid>
                        <Grid
                            item={true}
                            sm={6}
                            xs={6}
                            sx={{
                                displey: "flex",
                                display: "flex",
                                justifyContent: "flex-end",
                                height: "36px",
                            }}
                        >
                        </Grid>
                    </Grid>
                </Grid>

                <Grid>
                    <Breadcrumbs sx={{ mb: 1 }}>
                        <Typography
                            sx={{
                                ...textClasses.normalText,
                                fontSize: "12px",
                                color: themeType.default.themeOrange,
                            }}
                        >
                            <Link
                                style={{
                                    color: themeType.default.themeOrange,
                                    textDecoration: "none",
                                }}
                                to="/dashboard/routes"
                            >
                                Routes
                            </Link>
                        </Typography>

                        <Typography sx={{ ...textClasses.normalText, fontSize: "12px" }}>
                            {!isEditRoute ? "Add" : "Edit"}
                        </Typography>
                    </Breadcrumbs>
                </Grid>

                <Grid container direction="row" alignItems={"stretch"} mt={0}>
                    <Grid item sm={8}>
                        <Card
                            sx={{
                                ...cardClasses.basic,
                                borderRadius: "8px",
                                position: "relative",
                                maxHeight: localStorage.getItem("iframeee") ? "86vh" : "77vh",
                                minHeight: localStorage.getItem("iframeee") ? "86vh" : "77vh",
                                overflowY: "auto",
                            }}
                        >
                            <CardContent
                                sx={{
                                    height: localStorage.getItem("iframeee") ? "86vh" : "77vh",
                                    display: "flex",
                                    flexDirection: "column",
                                }}
                            >
                                <NewRouteForm
                                    addMore={addMore}
                                    control={control}
                                    selectStylesOverride={selectStylesOverride(isDarkThemeEnabledSelector)}
                                    batterPercentageOptions={batterPercentageOptions}
                                    dayOptions={dayOptions}
                                    errors={errors}
                                    isSuperAdmin={isSuperAdmin}
                                    noGeofenceMessage={noGeofenceMessage}
                                    setValue={setValue}
                                    geofenceList={geofenceList}
                                    themeType={themeType}
                                    viaPoints={viaPoints}
                                    schedules={schedules}
                                    setSchedules={setSchedules}
                                    templateExistence={templateExistence}
                                    register={register}
                                    reset={reset}
                                    isDarkThemeEnabledSelector={isDarkThemeEnabledSelector}
                                    routeLegsArr={routeLegsArr}
                                    activePath={activePath}
                                    setActivePath={setActivePath}
                                    handleSearchGeofence={handleSearchGeofence}
                                    sourceGeofenceList={sourceGeofenceList}
                                    setSearchObject={setSearchObject}
                                    searchObject={searchObject}
                                    destinationGeofenceList={destinationGeofenceList}
                                    routePayload={routePayload}
                                    setRoutePayload={setRoutePayload}
                                    viaPointsSearchObject={viaPointsSearchObject}
                                    setViaPointsSearchObject={setViaPointsSearchObject}
                                    viaPointsDropdownGeofenceList={viaPointsDropdownGeofenceList}
                                    getDirectionsData={getDirectionsData}
                                    getDistanceFromDb={getDistanceFromDb}
                                    calcTime={calcTime}
                                    convertHHMMToDate={convertHHMMToDate}
                                    calcSpeed={calcSpeed}
                                    addNewPath={addNewPath}
                                    removePath={removePath}
                                    setPlotByGoogle={setPlotByGoogle}
                                    handleCancel={handleCancel}
                                    clearMarkers={clearMarkers}
                                    isEditRoute={isEditRoute}
                                    handleSubmit={handleSubmit}
                                    onSubmit={onSubmit}
                                    handleEdit={handleEdit}
                                    user={user}
                                    setUser={setUser}
                                    plotOnMap={plotOnMap}
                                    createLoader={createLoader}
                                    updateLoader={updateLoader}
                                    routeDetails={routeDetails}
                                    setOrgListing={setOrgListing}
                                    orgListing={orgListing}
                                    handleOrganisationSearch={handleOrganisationSearch}
                                    handleUserSearch={handleUserSearch}
                                    userList={userList}
                                    setUserList={setUserList}
                                    handleAddMore={handleAddMore}
                                    clearMap={clearMap}
                                />
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item sm={4}>
                        <Card
                            sx={{
                                ...cardClasses.basic,
                                borderRadius: "8px",
                                position: "relative",
                                height: localStorage.getItem("iframeee") ? "86vh" : "77vh",
                            }}
                        >
                            <CardContent
                                sx={{
                                    padding: 0,
                                    paddingBottom: 0,
                                    height: "100%",
                                    "&:last-child": {
                                        paddingBottom: 0,
                                    },
                                }}
                            >
                                {isLoaded &&
                                    window.google &&
                                    window.google.maps.Map ? (
                                    <GoogleMap
                                        center={centerCoordinates}
                                        zoom={7}
                                        onLoad={(map) => setMymap(map)}
                                        mapContainerStyle={{ minHeight: "82vh", height: "100%" }}
                                        ref={mapRef}
                                    >
                                        {activePath !== null && routeLegsArr[activePath] && (
                                            <DirectionsRenderer
                                                directions={routeLegsArr[activePath]?.directions}
                                                options={{ suppressMarkers: true, draggable: true }}
                                                onDirectionsChanged={() => {
                                                    setIsDraggingRoute(true);
                                                    handleDirectionsChanged();
                                                }}
                                                ref={(ref) => {
                                                    if (ref) {
                                                        directionsRendererRef.current = ref.state.directionsRenderer;
                                                    }
                                                }}
                                            />
                                        )}
                                    </GoogleMap>
                                ) : null}
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};
export default AddRoute;