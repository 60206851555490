// DARK-THEME-CSS
export const inputClasses = {
  labelField: {
    // backgroundColor: "#383838",
  },
  textField: {
    // boxShadow: "0px 4px 4px 0px #30303000040",
    borderRadius: "12px",
    borderColor: "1px solid #282828",
    fontSize: "16px",
    lineHeight: "22px",
    fontWeight: "400",
    "& label.Mui-focused": {
      color: "#505050",
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "#505050",
        borderWidth: 1,
      },
    },
  },
  shadowField: {
    // boxShadow: "0px 4px 4px 0px #30303000040",
    borderRadius: "12px",
    borderColor: "#505050",
    fontSize: "14px",
    lineHeight: "19px",
    fontWeight: "400",
    "& label.Mui-focused": {
      color: "#505050",
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "#505050",
        borderWidth: 1,
      },
    },
  },
  themeBoundary: {
    "& label.Mui-focused": {
      color: "#505050",
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "#505050",
        borderWidth: 1,
      },
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "#505050",
        borderWidth: 1,
      },
      "&:hover fieldset": {
        borderColor: "#505050",
        borderWidth: 1,
      },
    },
  },
  filterField: {
    boxShadow: "0px 1px 4px 0px #282828",
    borderRadius: "12px",
    fontSize: "14px",
    lineHeight: "19px",
    fontWeight: "400",
    backgroundColor: "#383838",
    color: "#808080",
    "& input::placeholder": {
      fontSize: "14px",
      color: "#fff",
    },
    "& label.Mui-focused": {
      color: "#fff",
      borderColor: "#505050",
      borderWidth: 1,
      // color: "red",
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "#505050",
        borderWidth: 1,
      },
      "&:hover fieldset": {
        borderColor: "#505050",
        borderWidth: 1,
        // color: "red",
      },
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#505050",
      borderWidth: 1,
      // color: "red",
    },
    "& .MuiFilledInput-underline:after": {
      borderBottomColor: "#505050",
      borderWidth: 1,
      // color: "red",
    },
    "&:hover > .MuiOutlinedInput-notchedOutline ": {
      borderColor: "#505050",
      borderWidth: 1,
      // color: "red",
    },
    "&:focused > .MuiOutlinedInput-notchedOutline ": {
      borderColor: "#505050",
      borderWidth: 1,
    },
    "& input::placeholder": {
      fontSize: "13px",
      color: "#B0B0B0",
    },
  },
  lightertextField: {
    // boxShadow: "0px 4px 4px 0px #30303000040",
    borderRadius: "12px",
    borderColor: "1px solid #282828",
    fontSize: "16px",
    lineHeight: "22px",
    fontWeight: "400",
    "& label.Mui-focused": {
      color: "#505050",
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "#505050",
        borderWidth: 1,
      },
    },
  },
};
export const textClasses = {
  t12n: {
    fontSize: "12px",
    fontWeight: "400",
    lineHeight: "16px",
    color: "#808080",
  },
  t13n: {
    fontSize: "13px",
    fontWeight: "400",
    lineHeight: "16px",
    color: "#808080",
  },
  t14n: {
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "16px",
    color: "#808080",
  },
  normalText: {
    fontSize: "13px",
    fontWeight: "400",
    lineHeight: "17px",
    color: "#808080",
  },
  boldText: {
    fontSize: "14px",
    fontWeight: "600",
    lineHeight: "20px",
    color: "#808080",
  },
  t20n: {
    fontSize: "20px",
    fontWeight: "400",
    lineHeight: "27px",
    color: "#808080",
  },
  cardTitle: {
    fontSize: "24px",
    lineHeight: "33px",
    fontWeight: "600",
    color: "#808080",
  },
  pageTitle: {
    fontSize: "32px",
    lineHeight: "44px",
    fontWeight: "600",
    color: "#fff",
  },
  themeColor: {
    color: "#808080",
  },
  pageTitleHeading: {
    fontSize: "16px",
    lineHeight: "25px",
    fontWeight: "800",
    color: "#fff",
    backgroundColor:"rgb(56, 56, 56)"
  },
  pageSubText: {
    fontSize: "12px",
    lineHeight: "16px",
    fontWeight: "400",
    color: "#808080",
  },
  labelHeadColor:{
    fontSize:'9.5px',
    color:'#FFFFFFB3',
    backgroundColor:'rgb(56, 56, 56)'
  },
  labelHeadBorder:{
    border:'0.3px solid #808080',
    borderRadius:'12px'
  }
};
export const cardClasses = {
  basic: {
    borderRadius: "15px",
    border: "1px solid #505050",
    boxShadow: "0px 1px 4px 0px #505050",
    backgroundColor: "#282828",
    color: "#fff",
  },
};
export const tableClasses = {
  table: {
    width: "100%",
    overflowX: "auto",
    whiteSpace: "nowrap",
    // border: "2px solid #303030",
    // borderLeft: "1px solid #303030",
    // borderRight: "1px solid #303030",
    transition: "background-color 250ms linear",
  },
  dark: {
    borderRadius: "0px",
    // borderBottom: "3px solid #383838",
    // backgroundColor: "#303030",
    padding: "13px 5px",
    // padding: "13px 5px 13px 14px",
    transition: "background-color 250ms linear",
  },
  sideBarandHeader: {
    borderRadius: "0px",
    // border: "1px solid #383838",
    // boxShadow: "0px 1px 1px 0px #303030",
    backgroundColor: "#383838",
  },
  tableHeader: {
    padding: "17px 10px 17px 10px",
    borderRadius: "0px",
    // backgroundColor: "#282828",
    // color: "#BEBEBE",
  },
  tablePagination: {
   
    backgroundColor: '#383838'
  },
  tableHeaderClass: {
    // backgroundColor: "#282828",
    padding: "8px 8px 8px 8px",
    // color: "#BEBEBE",
  },
  tableBodyClass: {
    padding: "6px 6px 6px 6px",
    borderRadius: "0px",
    // borderBottom: "3px solid #383838",
    // backgroundColor: "#303030",
    transition: "background-color 250ms linear",
  },
  myCTable: {
    minWidth: '400px',
    borderCollapse: 'collapse',
    tableLayout: 'fixed',
    marginRight: '25px'
  },
  myTH: { 
    border: '1px solid #383838', 
    padding: '8px', 
    textAlign: 'left',
    width: "40%", 
    wordWrap: 'break-word', 
    wordBreak: 'break-all',
    fontSize: '12px',
  },
  myTD: { 
    border: "1px solid #383838", 
    padding: "8px", 
    textAlign: "left",
    width: "60%", 
    wordWrap: "break-word", 
    wordBreak: "break-all",
    fontSize: '13px',
  }
  
};
export const baseButton = {
  border: "1px solid #D9D9D9",
  borderRadius: "12px",
  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
  fontWeight: "600",
  fontSize: "16px",
  lineHeight: "22px",
  minHeight: "48px",
  textTransform: "none",
};
export const buttonClasses = {
  small: {
    borderRadius: "8px",
    fontSize: "12px",
    fontWeight: "400",
    lineHeight: "16px",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#f36c00",
      color: "white",
      borderColor: "#f36c00",
    },
  },
  normal: {
    border: "1px solid #D9D9D9",
    borderRadius: "12px",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "22px",
    minHeight: "48px",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#f36c00",
      color: "white",
      borderColor: "#f36c00",
    },
  },
  orange: {
    ...baseButton,
    backgroundColor: "#FF7200",
    color: "white",
    "&:hover": {
      backgroundColor: "#f36c00",
      color: "white",
    },
  },
  smallOrange: {
    border: "1px solid #D9D9D9",
    borderRadius: "4px",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    fontWeight: "400",
    fontSize: "10px",
    lineHeight: "27px",
    maxHeight: "24px",
    maxWidth: "24px",
    textTransform: "none",
    backgroundColor: "#FF7200",
    color: "white",
    padding: "2px",
    "&:hover": {
      backgroundColor: "#f36c00",
      color: "white",
      borderColor: "#f36c00",
    },
  },
  outlined: {
    border: "1px solid #FF7200",
    color: "#FF7200",
    borderRadius: "12px",
    backgroundColor: "#FFFFFF",
    fontSize: "14px",
    lineHeight: "19px",
    fontWeight: "400",
    textTransform: "none",
    minHeight: "40px",
    "&:hover": {
      border: "1px solid #FF7200",
      backgroundColor: "white",
      boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    },
  },
  lynkitOrangeEmpty: {
    backgroundColor: "transparent",
    borderRadius: "8px",
    color: "#ff7200",
    border: `1px solid #FF7200`,
    textTransform: "none",
    height: "32px",
    "&:hover": {
      backgroundColor: "#f36c0036",
      border: `1px solid #FF7200`,
      color: "#ff8200",
    },
  },
  lynkitOrangeFill: {
    backgroundColor: "#FF7200",
    borderRadius: "8px",
    border: `1px solid #FF7200`,
    // border: `1px solid #fff`,
    textTransform: "none",
    color: "#fff",
    height: "32px",
    "&:hover": {
      backgroundColor: "#f36c0036",
      border: `1px solid #FF7200`,
      // color: "#FF7200",
      color: "#ffffff",
    },
    "&:disabled": {
      border: `1px solid #000`,
    },

    fontSize: "14px",
    lineHeight: "19px",
    fontWeight: "400",
    minHeight: "30px",
  },
  lynkitGreenEmpty: {
    backgroundColor: "#fff",
    borderRadius: "12px",
    color: "green",
    border: `1px solid green`,
    textTransform: "none",
    height: "32px",
    boxShadow:
      "rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px",
    "&:hover": {
      backgroundColor: "#fff",
      border: `1px solid green`,
      color: "green",
      boxShadow: "0 8px 6px -7px #3E621E",
    },
  },
  lynkitRedEmpty: {
    backgroundColor: "#fff",
    borderRadius: "12px",
    color: "red",
    border: `1px solid red`,
    textTransform: "none",
    height: "32px",
    boxShadow:
      "rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px",
    "&:hover": {
      backgroundColor: "#fff",
      border: `1px solid red`,
      color: "red",
      boxShadow: "0 8px 6px -7px #3E621E",
    },
  },
  lynkitRedFill: {
    backgroundColor: "#FF0101",
    borderRadius: "12px",
    border: `1px solid #FF0101`,
    textTransform: "none",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#FF0101",
      border: `1px solid #FF0101`,
    },
    "&:disabled": {
      border: "none",
    },
  },
  lynkitGreenFill: {
    backgroundColor: "#3E621E",
    borderRadius: "8px",
    border: `1px solid #3E621E`,
    textTransform: "none",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#3E621E",
      border: `1px solid #3E621E`,
    },
  },
  lynkitDisabled: {
    backgroundColor: "#827878",
    borderRadius: "8px",
    border: `1px solid #827878`,
    textTransform: "none",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#827878",
      border: `1px solid #827878`,
    },
  },
  lynkitDelete: {
    backgroundColor: "#FF0000",
    borderRadius: "8px",
    border: `1px solid #FF0000`,
    textTransform: "none",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#FF0000",
      border: `1px solid #FF0000`,
    },
  },
  lynkitBlackEmpty: {
    backgroundColor: "#fff",
    borderRadius: "8px",
    color: "#FF7200",
    border: `1px solid #FF7200`,
    height: "32px",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#fff",
      border: `1px solid #FF7200`,
      color: "#ff8200",
    },
  },
  lynkitBlackFill: {
    backgroundColor: "#ff7200",
    borderRadius: "8px",
    color: "#ffffff",
    border: `1px solid #ff7200`,
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#ff7200",
      border: `1px solid #ff7200`,
      color: "#fff",
    },
    minWidth: "60px",
  },
  lynkitGreenFill: {
    backgroundColor: "#3ac47d",
    borderRadius: "8px",
    color: "#ffffff",
    border: `1px solid #3ac47d`,
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#3ac47d",
      border: `1px solid #3ac47d`,
      color: "#fff",
    },
  },
};
export const driverModal = {
  modal: {
    whiteSpace: "nowrap",
    border: "2px solid #696969",
    borderRadius: "8px",
    // boxShadow: "0 8px 6px -2px #D3D3D3",
  },
};
export const dividerClass = {
  upper: {
    borderTop: "1px solid #404040",
  },
  lower: {
    borderBottom: "1px solid #404040",
  },
  all:{
    border:"1px solid #404040"
  }
};
export const borderRight = {
  borderRight: "1px solid #404040",
};
export const borderAll = {
  border: "1px solid #404040",
};
export const borderTop = {
  borderTop: "1px solid #404040",
};
export const borderBottom = {
  borderBottom: "1px solid #404040",
};
export default {
  themeOrange: "#FF7200",
  themeLightOrange: "#f3d7c0",
  themeOrangeShadow: "#c95c03",
  // themeBlack: "#303030",
  themeGray: "#383838",
  // themeGray: "#D9D9D9",
  themeGreen: "green",
  themeRed: "#FF0101",
  themeWhite: "rgb(56, 56, 56)",
  sideBarTheme: "rgb(56, 56, 56)",
};
