import React, { useState, useEffect } from "react";
import { Box, Modal, Backdrop } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Grid, Typography } from "@mui/material";
import PropTypes from "prop-types";
import { styled, useTheme } from "@mui/material/styles";
import MinimizeIcon from "@mui/icons-material/Minimize";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import zIndex from "@mui/material/styles/zIndex";
export default function CustomModel(props) {
  const [open, setOpen] = useState(false);
  const [minimize, setMinimize] = useState(false);
  const handleClose = (e) => {
    setOpen(!open);
    props.onClose(false);
    setMinimize(false);
  };
  useEffect(() => {
    setOpen(props.show);
  }, [props.show]);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    // transition: 'all 0.3s ease-out',
    minWidth: props?.minWidth || "50%",
    maxWidth: props?.maxWidth || "50%",
    maxHeight: props?.maxHeight || "90vh",
    bgcolor: "background.paper",
    borderRadius: "10px",
    // boxShadow: 24,
    // pt: props?.pt || 0,
    // pb: props?.pb || 3,
    // pl: props?.pl || 3,
    // pr: props?.pr || 3,
    outline: "none",
    boxShadow: `0px 5px 18px #ffffff17`,
  };

  const style2 = {
    position: "fixed",
    bgcolor: "background.paper",
    boxShadow: 24,
    overflow: "auto",
    border: "none",
    bottom: "0px",
    right: "0px",
    height: "38px",
    overflow: "none",
    width: "25%",
    borderTopLeftRadius: "14px",
    borderTopRightRadius: "14px",
    padding: "6px 8px 8px 8px",
    background: "#ff7200",
    boxShadow: "0px 0px 15px grey",
  };

  const headerStyle = {
    display: "flex",
    justifyContent: "space-between",
    position: "sticky",
    top: 0,
    zIndex: 100,
    background: "#Ff7200",
    borderTopLeftRadius: "10px",
    borderTopRightRadius: "10px",
    paddingTop: "6px",
    paddingBottom: "6px",
    paddingLeft: "14px",
    paddingRight: "14px",
  };

  const handleMiniMax = () => {
    setMinimize(!minimize);
  };
  useEffect(() => {
    if (!minimize && open) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "scroll";
    }
  }, [minimize]);
  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        BackdropComponent={Backdrop}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        BackdropProps={{
          style: { pointerEvents: "auto" },
          onClick: (event) => (props.autoClose ? "" : event.stopPropagation()),
        }}
        style={{
          display: !minimize ? "unset" : "none",
        }}
      >
        <Grid container sx={style}>
          <Grid item sm={12}>
            <Box>
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                sx={headerStyle}
              >
                <span
                  className={
                    !minimize
                      ? "fs-24 font-Nunito fw-1000 white-text"
                      : "fs-18 white-text"
                  }
                  style={{ textTransform: "capitalize" }}
                >
                  {" "}
                  {props.Title}{" "}
                </span>
                {props.viewMinimiseIcon ? null :
                <Grid sx={{ display: "flex", alignItems: "center" }}>
                  <span
                    style={{ cursor: "pointer" }}
                    onClick={() => handleMiniMax()}
                  >
                    <MinimizeIcon
                      sx={{
                        fontSize: "20px",
                        color: "#000",
                        marginBottom: "4px",
                        marginRight: "4px",
                        color: "#fff",
                      }}
                    />
                  </span>
                  {props?.viewCloseIcon && (
                    <span
                      className="curser"
                      style={{ cursor: "pointer" }}
                      onClick={() => handleClose()}
                    >
                      <CloseIcon sx={{ marginBottom: "4px", color: "#fff" }} />
                    </span>
                  )}
                </Grid>}
              </Typography>
            </Box>
            <Box
              sx={{
                maxWidth: "inherit",
                maxHeight: props.childrenMaxHeight || "70vh",
                paddingLeft: "20px",
                paddingRight: "20px",
              }}
            >
              {props.children}
            </Box>
          </Grid>
        </Grid>
      </Modal>

      {minimize ? (
        <Grid sx={style2}>
          <Grid>
            <Box>
              <Typography
                onClick={() => handleMiniMax()}
                id="modal-modal-title"
                variant="h6"
                component="h2"
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  position: "sticky",
                  bottom: 200,
                  zIndex: 100,
                  cursor: "pointer",
                }}
              >
                <span
                  className={
                    !minimize
                      ? "fs-24 font-Nunito fw-1000 white-text"
                      : "fs-18 white-text"
                  }
                  style={{ paddingLeft: "5px" }}
                >
                  {" "}
                  {/* {props.Title}{" "} */}
                  {props && props.Title.length > 25
                    ? props.Title.substring(0, 25) + "..."
                    : props.Title ?? "-"}
                </span>
                <Grid sx={{ display: "flex", alignItems: "center" }}>
                  <span
                    style={{ cursor: "pointer" }}
                    onClick={() => handleMiniMax()}
                  >
                    <ContentCopyIcon
                      sx={{
                        fontSize: "20px",
                        color: "#000",
                        marginBottom: "4px",
                        marginRight: "4px",
                        color: "#fff",
                      }}
                    />
                  </span>
                  {props?.viewCloseIcon && (
                    <span
                      className="curser"
                      style={{ cursor: "pointer" }}
                      onClick={() => handleClose()}
                    >
                      <CloseIcon sx={{ marginBottom: "4px", color: "#fff" }} />
                    </span>
                  )}
                </Grid>
              </Typography>
            </Box>
          </Grid>
        </Grid>
      ) : null}
    </>
  );
}
