 const Actions = {
    ADD : "ADD",
    DELETE: "DELETE",
    BLOCK: "BLOCK",
    ACTIVE: "ACTIVE",
    VIEW: "VIEW",
    EDIT: "EDIT",
}


export const getDisplayValue = ( action)=>{
    return action.charAt(0).toUpperCase() + action.slice(1).toLowerCase()
}

export default Actions