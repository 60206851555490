import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Autocomplete,
  TextField,
  Grid,
  MenuItem,
  Paper,
  Menu,
  Checkbox,
  Tab,
  Tabs,
  Typography,
  Box,
  FormControl,
  OutlinedInput,
  InputAdornment,
  InputLabel,
  Tooltip,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import history from "../../../services/history";
import loaderGif from "../../../static/images/drawer/loader.gif";
import EmptyPage from "../EmptyPage";
import {
  getAllRoutes,
  getAllUsers,
  getRouteDetails,
  getAllGeofences,
  getAllOrganizations,
  updateRouteStatus,
  getRoutesCount,
  getRoutesStatusCount,
  getBoards,
  deleteRoutes,
  updateRouteListFlag,
  getRouteSchedculeData,
  flushScheduleData,
  saveFilters,
  flushRouteDetail,
  getRouteAnalytics,
  getAllDeviceCategoryNames,
  getJourneyByRoute,
} from "../../../redux/actions";
import RoutesTable from "./components/RoutesTable";
import { main } from "./helper";
import {
  BarChart,
  FilterAlt,
  KeyboardArrowDown,
  Search,
} from "@mui/icons-material";
import DetailCard from "./components/DetailCard";
import {
  deleteEmptyKeys,
  selectStylesOverride,
  sendCustomEventAnalytics,
} from "../../../utils/util";
import FiltersWrapper from "../../Components/FiltersWrapper/FiltersWrapper";
import ConfirmModal from "./components/ConfirmModal";
import ViaPointModal from "./components/ViaPointModal";
import { flushAllBoards } from "../../../redux/reducers/Dashboard";
import { havePermission } from "../../../utils/permissionCheck";
import axios from "axios";
// import { loginURL } from "../../../services/Api";
import { loginURL } from "../../../services/config";
import { setRoutesTempFilter } from "../../../redux/reducers/Route";
import AnalyticsCard from "./components/AnalyticsCard";
import { formattedDate, getGMTDate, getSevenDaysBefore } from "./util";
import { convertIntoGivenTimezone } from "../../../services/functions";

let payload = {
  page: 1,
  limit: 10,
};

let payloadBoard = {
  module: "route",
  search: "",
};

const Routes = ({ previousRoute }) => {
  // local states ---------
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [loader, setLoader] = React.useState(false);
  const [boardLoader, setBoardLoader] = React.useState(false);
  const [bulkStatus, setBulkStatus] = React.useState(null); //to check if all checked items are active or blocked
  const [filter, setFilter] = React.useState({});
  const [selectedTab, setSelectedTab] = React.useState(0);
  const [finalFilter, setFinalFilter] = React.useState(null);
  const [tableIndex, setTableIndex] = React.useState(null);
  const [actionIndex, setActionIndex] = React.useState(null);
  const [viaPointEntry, setViaPointEntry] = React.useState(null);
  const [tableData, setTableData] = React.useState([]);
  const [actionStatus, setActionStatus] = React.useState(null);
  const [comment, setComment] = React.useState("");
  const [checkedData, setCheckedData] = React.useState([]);
  const [selectedTemplates, setSelectedTemplates] = React.useState([]);
  const [showConfirmModal, setShowConfirmModal] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [selectedRouteId, setSelectedRouteId] = React.useState(null);
  const [keysArray, setKeysArray] = React.useState(null);
  const [schedulesTabs, setSchedulesTabs] = React.useState([]); //schedule data for tabs [details]
  const [selectedSchedule, setSelectedSchedule] = React.useState(0); //schedule tab index [details]
  const [hasLoaded, setHasLoaded] = React.useState(false);
  const [dropdownObj, setDropdownObj] = useState({
    journeyPeriod: '',
    deviceType: ''
  })
  const [searchFields, setSearchFields] = React.useState({
    org: null,
    users: null,
    geofence: null,
  });
  const [routeForAnalytics, setRouteForAnalytics] = React.useState(null);

  // dispatcher --------
  const dispatch = useDispatch();

  // selectors ---------
  const {
    allRoutes,
    totalRoutes,
    routeDetails,
    routeStatusCounts,
    fetchRouteFlag,
  } = useSelector((state) => state.routes);
  const { currentUserDetail } = useSelector((state) => state.auth);
  const { allUsers, allOrganizations } = useSelector((state) => state.userlist);
  const { geofenceListing } = useSelector((state) => state.geofencelist);
  const { selectedRouteSchedules, filterFromStore, routeAnalyticsData, routeJourneyOptions } = useSelector(
    (state) => state.route
  );
  const { allDeviceCategoryNames } = useSelector((state) => state.deviceslist);
  const { allFilters } = useSelector((state) => state.filters);
  const userPermissions =
    useSelector((state) => state.auth?.currentUserDetail) || {};
  const userDetails = useSelector((state) => state.auth.currentUserDetail);
  const boardListing = useSelector(
    (state) => state.dynamicDashboard.boardsListing[0]
  );
  const isDarkThemeEnabledSelector = useSelector(
    (state) => state.theme.themeDark
  );
  const themeType = isDarkThemeEnabledSelector
    ? require("../../../static/styles/darktheme")
    : require("../../../static/styles/theme");

  const { buttonClasses, inputClasses, textClasses } = themeType;

  const showSavedFilters = React.useMemo(() => {
    if (previousRoute) {
      return previousRoute?.pathname?.includes("/edit-route") ? true : false;
    } else {
      return false;
    }
  }, [previousRoute]);

  // Handlers ------
  const handleFetchAllRoutes = () => {
    try {
      setLoader(true);
      if (filter) {
        dispatch(
          getAllRoutes(
            {
              ...filter,
              selection:
                keysArray && keysArray?.length > 0
                  ? keysArray.filter((item) => item !== "totalFinalDistance")
                  : [],
            },
            (res) => {
              if (res) {
                setBulkStatus(null);
                setCheckedData([]);
                setTableIndex(null);
                setActionIndex(null);
                setActionStatus(null);
                dispatch(updateRouteListFlag(false));
                // dispatch(getRoutesStatusCount({ ...filter }));
              }
              setLoader(false);
            }
          )
        );
      }
    } catch (e) {
      setLoader(false);
    }
  };

  const handleGetRouteDetails = () => {
    dispatch(flushScheduleData());
    dispatch(flushRouteDetail());
    // setSelectedTab(0);
    setSchedulesTabs([]);
    try {
      setLoader(true);
      if (selectedRouteId) {
        dispatch(
          getRouteDetails({
            id: selectedRouteId,  
            selection: [
              "routeName",
              "description",
              "batteryPercentage",
              "routeSource",
              "routeDestination",
              "viaPoints",
              "scheduleData",
              "isSchedulesExists",
              "dynamicData",
              "user",
              "status",
              "addedOn",
              "addedBy",
              "modifiedOn",
              "modifiedBy",
              "routeLegs",
              "routeLegs1",
              "routeLegs2",
            ],
          }, (res) => {
            setLoader(false);
            if (res && res?.isSchedulesExists === true) {
              dispatch(getRouteSchedculeData({ routeId: res?._id }));
            }
          })
        );
      }
    } catch (e) {
    } finally {
      setLoader(false);
    }
  };

  const handleSelectAll = (event) => {
    if (event?.target?.checked) {
      let temp = [];
      tableData?.map((_) => {
        temp?.push(_?._id);
      });
      setCheckedData([...temp]);
    } else {
      const temp = [];
      setCheckedData([...temp]);
    }
  };

  const handleCheckChange = (event, entry, index) => {
    if (event?.target?.checked) {
      setTableIndex(index);
      let temp = [...checkedData];
      temp?.push(entry?._id);
      setCheckedData([...temp]);
    } else {
      const temp = checkedData?.filter((_) => _ !== entry?._id);
      setCheckedData(temp);
    }
  };

  const handleOpenAction = (event, key) => {
    setAnchorEl(event.currentTarget);
    setActionIndex(key);
    setTableIndex(key);
  };

  const handleCloseAction = () => {
    setActionIndex(null);
    setTimeout(() => {
      setAnchorEl(null);
    }, 1);
  };

  /**
   * @description --- This will open confirmation modal for mutliple checked data
   */
  const handleOpenDeleteModal = () => {
    let tempChecked = [...checkedData];
    let templateArr = [];
    tempChecked?.map((id) => {
      let selectedRowData = tableData?.find((_) => _?._id === id);
      if (selectedRowData)
        templateArr?.push({
          name: selectedRowData?.routeName,
          id: id,
        });
    });
    setSelectedTemplates(templateArr);
    setShowConfirmModal(true);
  };

  /**
   * @description --- This will open confirmation modal for single items (through action menu)
   */
  const handleOpenModal = (action) => {
    let selectedRow = [];
    let template = [];
    setActionStatus(action);
    selectedRow?.push(tableData[tableIndex]?._id);
    template?.push({
      name: tableData[tableIndex]?.routeName,
      id: tableData[tableIndex]?._id,
    });

    setCheckedData(selectedRow);
    setSelectedTemplates(template);
    setShowConfirmModal(true);
  };

  const handleShowDetails = (entry) => {
    if (entry) {
      handleCloseAction();
      setSelectedRouteId(entry?._id);
      setRouteForAnalytics(null);
    }
  };

  const handleSearchUsers = (empty) => {
    if (empty === true) {
      dispatch(
        getAllUsers({
          selection: ["name", "orgName"],
          searchValue: "",
        })
      );
    } else {
      if (searchFields?.users || searchFields?.users == "")
        dispatch(
          getAllUsers({
            selection: ["name", "orgName"],
            searchValue: searchFields?.users,
          })
        );
    }
  };

  const handleSearchGeofence = (empty) => {
    if (empty === true) {
      dispatch(
        getAllGeofences({
          selection: ["name"],
          filter: {
            orgId: localStorage.getItem("orgId"),
          },
        })
      );
    } else {
      if (searchFields?.geofence || searchFields?.geofence == "")
        dispatch(
          getAllGeofences({
            selection: ["name"],
            filter: {
              orgId: localStorage.getItem("orgId"),
              name: searchFields?.geofence,
            },
          })
        );
    }
  };

  const handleSearchOrg = (empty) => {
    if (empty === true) {
      dispatch(getAllOrganizations({ selection: ["name"] }));
    } else {
      if (searchFields?.org || searchFields?.org == "")
        dispatch(
          getAllOrganizations({
            selection: ["name"],
            searchValue: searchFields.org,
          })
        );
    }
  };

  const handleApplyFilters = React.useCallback(() => {
    if (filter?.filter) {
      handleFetchAllRoutes();
      setFinalFilter(filter);
      // setRoutesTempFilter(filter);
      dispatch(getRoutesStatusCount({ ...filter }));
    }
  }, [filter?.filter]);

  const handleConfirmActionFn = React.useCallback(
    (remark) => {
      try {
        if (actionStatus === "delete") {
          const payload = {
            ids: checkedData,
            remark: remark ?? "",
          };
          sendCustomEventAnalytics("routes", "delete-route", "Delete Route");
          dispatch(
            deleteRoutes(payload, () => {
              handleCloseAction();
              setShowConfirmModal(false);
              handleFetchAllRoutes();
              dispatch(getRoutesStatusCount());
              setComment("");
            })
          );
        } else if (actionStatus === "enable" || actionStatus === "disable") {
          const payload = {
            ids: checkedData,
            status:
              actionStatus === "enable"
                ? "ACTIVE"
                : actionStatus?.toUpperCase(),
            remark: remark ?? "",
          };
          sendCustomEventAnalytics(
            "routes",
            `${actionStatus}-route`,
            `${actionStatus} route`
          );
          dispatch(
            updateRouteStatus(payload, () => {
              handleCloseAction();
              setShowConfirmModal(false);
              handleFetchAllRoutes();
              dispatch(getRoutesStatusCount());
              setComment("");
            })
          );
        }
      } catch (e) {
      } finally {
      }
    },
    [actionStatus]
  );
  // ({
  //   ...existing,
  //   filter: {
  //     ...filter,
  //     status: "ACTIVE",
  //   },
  // })
  const handleTabsChange = (_, value) => {
    setSelectedTab(value);
    if (allRoutes && tabs[value]?.key === "active") {
      setFilter((existing) => {
        return {
          ...existing,
          filter: {
            ...existing.filter,
            status: "ACTIVE",
          },
        };
      });
    } else if (allRoutes && tabs[value]?.key === "blocked") {
      setFilter((existing) => {
        return {
          ...existing,
          filter: {
            ...existing.filter,
            status: "DISABLE",
          },
        };
      });
      // setFilter((existing) => ({
      //   ...existing,
      //   filter: {
      //     status: "DISABLE",
      //   },
      // }));
    } else if (allRoutes && tabs[value]?.key === "all") {
      let temp = { ...filter };
      delete temp["filter"]["status"];
      setFilter(temp);
      handleFetchAllRoutes();
    }
  };

  useEffect(() => {
    if (filter?.filter?.status) {
      handleFetchAllRoutes();
    }
  }, [filter]);

  const handleFetchBoards = () => {
    setBoardLoader(true);
    dispatch(
      getBoards(payloadBoard, (keys) => {
        if (keys && keys?.length > 0) {
          setKeysArray([...keys, "routeLegs"]);
        } else {
          setKeysArray([]);
        }
        setBoardLoader(false);
      })
    );
  };

  // Tabs -----
  const tabs = [
    {
      id: 0,
      key: "all",
      name: `All Routes (${
        routeStatusCounts
          ? routeStatusCounts?.active + routeStatusCounts?.disabled
          : 0
      })`,
    },
    {
      id: 1,
      key: "active",
      name: `Active (${routeStatusCounts?.active ?? 0})`,
    },
    {
      id: 2,
      key: "blocked",
      name: `Blocked (${routeStatusCounts?.disabled ?? 0})`,
    },
  ];

  const dynamiHeader =
    boardListing?.boardData?.map((item, index) => item.columnName) || [];

  const tableHeader = [
    <Typography
      sx={{
        ...textClasses.t13n,
        textTransform: "capitalize",
        color: themeType.default.themeOrange,
      }}
    >
      <Checkbox
        size="small"
        sx={{
          marginBottom: "4px",
          padding: "0px",
          color: "#ff7200",
          "&.Mui-checked": {
            color: "#ff7200",
          },
        }}
        checked={checkedData?.length === tableData?.length}
        onChange={(event) => handleSelectAll(event)}
      />
    </Typography>,
    "#",
    ...dynamiHeader,
    "Actions",
  ];

  if(currentUserDetail && (currentUserDetail?.adminSpecificPermissions || havePermission(currentUserDetail, "routes", "RoutesAnalytics"))){
    tableHeader.splice(tableHeader?.length - 1, 0, "Analytics");
  }

  const tableRows = allRoutes?.map((item, index) => {
    let items = [];
    items.push(
      <Typography
        sx={{
          ...textClasses.t13n,
          textTransform: "capitalize",
          color: themeType.default.themeOrange,
        }}
      >
        <Checkbox
          size="small"
          sx={{
            marginBottom: "4px",
            padding: "0px",
            color: "#ff7200",
            "&.Mui-checked": {
              color: "#ff7200",
            },
          }}
          checked={checkedData?.includes(item._id) ? true : false}
          onChange={(event) => {
            handleCheckChange(event, item, index);
          }}
        />
      </Typography>
    );
    items.push(
      <Typography sx={{ ...textClasses.normalText, textAlign: "center" }}>
        {(filter?.page - 1) * filter?.limit + index + 1}
      </Typography>
    );
    let rowKeysArr = boardListing?.boardData || [];

    for (let obj of rowKeysArr) {
      let eachColStr = "";
      for (let ob of obj.group) {
        let tmpInnerStr = main(
          ob.fnc,
          ob.key,
          ob.label,
          item,
          index,
          setViaPointEntry,
          handleShowDetails,
          currentUserDetail
        );
        eachColStr += tmpInnerStr + "<br/>";
      }
      items.push(
        <Typography
          sx={{ ...textClasses.normalText, textAlign: "center" }}
          dangerouslySetInnerHTML={{ __html: eachColStr }}
        />
      );
    }

    if(currentUserDetail && (currentUserDetail?.adminSpecificPermissions || havePermission(currentUserDetail, "routes", "RoutesAnalytics"))){
      items.push(
        <div
          className="filled-theme-btn"
          data-testid="analytics-btn"
          onClick={() => {
            let selectedRoute = JSON.parse(JSON.stringify(allRoutes[index]));
            setRouteForAnalytics(selectedRoute);
            handleGetAnalyticsData(selectedRoute);
            setSelectedRouteId(null);
          }}
        >
          <BarChart />
        </div>
      );
    }

    items.push(
      <Typography
        sx={{
          ...textClasses.normalText,
          color: themeType.default.themeOrange,
          cursor: "pointer",
        }}
        onClick={(e) => handleOpenAction(e, index)}
      >
        Action <KeyboardArrowDown />
      </Typography>
    );
    return items;
  });

  const filterDropdowns = [
    {
      key: "byUser",
      name: "Added By",
      // options: allUsers ?? [],
      options:
        allUsers?.length > 0
          ? allUsers.map((ele) => ({
              name: `${ele.name}-${ele.orgName}`,
              _id: ele._id,
            }))
          : [],
      labelKey: "name",
      valueKey: "_id",
      value: filter?.filter?.user || null,
      searchValue:
        searchFields?.users && searchFields?.users !== ""
          ? searchFields?.users
          : null,
      onSearch: handleSearchUsers,
      onInputChange: (e) => {
        if (e?.type === "change") {
          if (e?.target?.value === "") handleSearchUsers(true);
          setSearchFields((prev) => ({
            ...prev,
            users: e?.target?.value?.trim(),
          }));
        }
      },
      onChange: (newVal) => {
        if (newVal) {
          setFilter((prev) => ({
            ...prev,
            filter: {
              ...prev?.filter,
              user: newVal?._id,
            },
          }));
        } else {
          let temp = { ...filter };
          delete temp["filter"]["user"];
          setFilter(temp);
        }
      },
    },
    {
      name: "Filter by Geofence",
      options: geofenceListing ?? [],
      labelKey: "name",
      valueKey: "_id",
      value: filter?.filter?.geofenceId || null,
      searchValue:
        searchFields?.geofence && searchFields?.geofence !== ""
          ? searchFields?.geofence
          : null,
      onSearch: handleSearchGeofence,
      onInputChange: (e) => {
        if (e?.type === "change") {
          if (e?.target?.value === "") handleSearchGeofence(true);
          setSearchFields((prev) => ({
            ...prev,
            geofence: e?.target?.value?.trim(),
          }));
        }
      },
      onChange: (newVal) => {
        if (newVal) {
          setFilter((prev) => ({
            ...prev,
            filter: {
              ...prev?.filter,
              geofenceId: newVal?._id,
            },
          }));
        } else {
          let temp = { ...filter };
          delete temp["filter"]["geofenceId"];
          setFilter(temp);
        }
      },
    },
  ];

  if (currentUserDetail?.adminSpecificPermissions) {
    filterDropdowns?.unshift({
      key: "orgName",
      name: "Organization Name",
      options: allOrganizations ?? [],
      labelKey: "name",
      valueKey: "_id",
      value: filter?.filter?.orgId || null,
      searchValue:
        searchFields?.org && searchFields?.org !== ""
          ? searchFields?.org
          : null,
      onSearch: handleSearchOrg,
      onInputChange: (e) => {
        if (e?.type === "change") {
          if (e?.target?.value === "") handleSearchOrg(true);
          setSearchFields((prev) => ({
            ...prev,
            org: e?.target?.value?.trim(),
          }));
        }
      },
      onChange: (newVal) => {
        if (newVal) {
          setFilter((prev) => ({
            ...prev,
            filter: {
              ...prev?.filter,
              orgId: newVal?._id,
            },
          }));
        } else {
          let temp = { ...filter };
          delete temp["filter"]["orgId"];
          setFilter(temp);
        }
      },
    });
  }

  const handleSaveFilters = () => {
    if (filter) {
      const { page, limit, selection, ...rest } = filter;
      // const filteredObject = deleteEmptyKeys(filter?.filter);
      const payload = {
        type: "routes",
        data: { ...rest },
        status: allFilters && allFilters?.routesFilter ? true : false,
      };

      dispatch(
        saveFilters(payload, (res) => {
          if (res && res?.success) {
          }
        })
      );
    }
  };

  const handleGetAnalyticsData = (route) => {
    if (route) {
      const date = getGMTDate();
      
      const fromDate = formattedDate(getSevenDaysBefore(date));
      const toDate = formattedDate(date);
      dispatch(getAllDeviceCategoryNames({selection: ["category", "label"]}));

      dispatch(getJourneyByRoute({
        routeId: route?._id
      }))
      dispatch(
        getRouteAnalytics({
          routeId: route?._id,
          startDate: fromDate,
          endDate: toDate,
          deviceType: dropdownObj.deviceType
        })
      );
    }
  };

  const debounceSearch = () => {
    handleFetchAllRoutes();
  };

  // Effects ------
  React.useEffect(() => {
    if (filterFromStore && previousRoute && showSavedFilters) {
      const tempFilters = filterFromStore;
      setFilter({ ...tempFilters });
      setFinalFilter({ ...tempFilters });
    } else if (allFilters && allFilters?.routesFilter) {
      const routesFilter =
        typeof allFilters?.routesFilter === "string"
          ? JSON.parse(allFilters?.routesFilter)
          : allFilters?.devicesFilter;
      setFilter({
        page: 1,
        limit: 10,
        ...routesFilter,
      });
      setFinalFilter({
        page: 1,
        limit: 10,
        ...routesFilter,
      });
    } else {
      setFilter({
        page: 1,
        limit: 10,
      });
      setFinalFilter({
        page: 1,
        limit: 10,
      });
    }

    if (!hasLoaded) {
      setSelectedRouteId(null);
      setRouteForAnalytics(null);
      dispatch(updateRouteListFlag(true));
      setComment("");
      handleFetchBoards();
      dispatch(
        getAllGeofences({
          selection: ["name"],
        })
      );
      if (currentUserDetail?.adminSpecificPermissions) {
        dispatch(
          getAllOrganizations({
            selection: ["name"],
          })
        );
      }
      dispatch(getRoutesStatusCount());
      dispatch(
        getAllUsers({
          selection: ["name", "orgName"],
        })
      );
      setHasLoaded(true);
    }
  }, [dispatch, allFilters, filterFromStore]);

  React.useEffect(() => {
    if (keysArray !== null) handleFetchAllRoutes();
  }, [keysArray]);

  React.useEffect(() => {
    if (allRoutes && allRoutes?.length > 0) setTableData(allRoutes);
  }, [allRoutes]);

  React.useEffect(() => {
    let timeoutId;

    if (filter && filter?.searchValue && filter?.searchValue?.length > 3) {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(debounceSearch, 700);
      return () => {
        clearTimeout(timeoutId);
      };
    }

    if (filter?.searchValue === "") {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(debounceSearch, 700);
      return () => {
        clearTimeout(timeoutId);
      };
    }

    if (filter && filter?.searchValue && filter?.searchValue?.length <= 3) {
      return;
    }
  }, [filter?.searchValue]);

  React.useEffect(() => {
    if ((filter?.limit || filter?.page) && fetchRouteFlag === false) {
      handleFetchAllRoutes("use Effect [filter?.limit, filter?.page]");
    }
  }, [filter?.limit, filter?.page]);

  React.useEffect(() => {
    if (checkedData?.length > 0) {
      let activeCount = 0;
      let disableCount = 0;

      for (const item of checkedData) {
        const sel = tableData?.find((_) => _?._id === item);
        if (sel?.status?.toLowerCase() === "active") {
          activeCount++;
        } else if (sel?.status?.toLowerCase() === "disable") {
          disableCount++;
        }
      }

      if (activeCount > 0 && disableCount === 0) {
        setBulkStatus("active");
      } else if (disableCount > 0 && activeCount === 0) {
        setBulkStatus("disable");
      } else {
        return setBulkStatus(null);
      }
    }
  }, [checkedData]);

  // const Openns = () => {
  //   let tt = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2NWM2MDViMGE4ZDY1YzQzN2FlMzIwMmIiLCJjcmVhdGVkRGF0ZSI6IjIwMjQtMDMtMTFUMDk6NTA6NTcuMTA5WiIsImlhdCI6MTcxMDE1MDY1NywiZXhwIjoxNzEwMzIzNDU3fQ.c0fLf_2K9Zta0sIJFQIPYjc3UWLqP-izZyCmMPZZP0o"
  //   axios.post(`${loginURL}/validateToken`, {}, {
  //     headers: {
  //       'Authorization': tt
  //     }
  //   })
  //     .then(response => {
  //       console.log('res', response)

  //     })
  //     .catch(error => {
  //       // Handle error
  //       console.error('Error:', error);
  //     });
  // }

  return (
    <FiltersWrapper
      handleDrawerToggle={() => setOpen(!open)}
      open={open}
      filters={filterDropdowns}
      isDarkThemeEnabledSelector={isDarkThemeEnabledSelector}
      selectStylesOverride={selectStylesOverride(isDarkThemeEnabledSelector)}
      themeType={themeType}
      handleApplyFilters={handleApplyFilters}
      saveFilters={handleSaveFilters}
      defaultFiltersExists={
        allFilters && allFilters?.routesFilter ? true : false
      }
      searchElement={
        <Grid container spacing={2} mt={0}>
          <Grid item md={12} ml={2} mr={2} style={{ width: "100%" }}>
            <FormControl
              variant="outlined"
              size="small"
              fullWidth
              sx={{
                ...selectStylesOverride(isDarkThemeEnabledSelector),
                borderRadius: "12px",
              }}
            >
              <InputLabel
                htmlFor="outlined-adornment-password"
                sx={{ fontSize: "12px" }}
              >
                Search by name
              </InputLabel>
              <OutlinedInput
                sx={{ ...inputClasses.filterField, height: "36px" }}
                name="name"
                value={filter?.searchValue || ""}
                onChange={(e) =>
                  setFilter({
                    ...filter,
                    searchValue: e.target.value
                      .trimStart()
                      .replace(/\s{2,}/g, " "),
                    page: 1,
                  })
                }
                // inputValue.replace(/ +/g, ' ').trim();
                placeholder="Search by name"
                label="Search by name"
                endAdornment={
                  <InputAdornment position="end">
                    <Search fontSize="small" />
                  </InputAdornment>
                }
              />
            </FormControl>
          </Grid>
        </Grid>
      }
      searchActions={{
        handleSearchUsers,
        searchFields,
        setSearchFields,
      }}
    >
      <Grid container>
        {/* ----- top row - page title and action button ----- */}
        <Grid
          item
          xs={12}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography
            data-testid="page-title"
            sx={{ ...textClasses.cardTitle }}
          >
            Routes
          </Typography>
          {/* <Button onClick={() => Openns()}>Clickkk</Button> */}
          {/* CTAs ----- */}
          <Grid
            xs
            item
            mt={1}
            display="flex"
            justifyContent="end"
            alignItems="flex-end"
            gap={1}
          >
            {(finalFilter?.filter &&
              Object.keys(finalFilter?.filter)?.length > 0) ||
            filter.searchValue ? (
              <Button variant="text">
                <Typography
                  sx={{
                    ...textClasses.boldText,
                    color: themeType.default.themeOrange,
                    textDecoration: "underline",
                    cursor: "pointer",
                    textTransform: "none",
                  }}
                  onClick={() => {
                    setFilter({ ...payload });
                    setFinalFilter(null);
                    setOpen(false);
                    setSearchFields({
                      org: null,
                      users: null,
                      geofence: null,
                    });
                    setSelectedTab(0);
                    dispatch(getAllRoutes({ ...payload }));
                    dispatch(getRoutesStatusCount({ ...payload }));
                  }}
                >
                  Clear All Filter(s)
                </Typography>
              </Button>
            ) : null}
            {/* Filters button ------- */}
            {!open && (
              <Button
                variant="contained"
                sx={{
                  ...buttonClasses?.lynkitOrangeEmpty,
                  minHeight: "36px",
                }}
                onClick={() => setOpen(!open)}
                startIcon={<FilterAlt />}
                data-testid="filtersBtn"
              >
                Filters
                {finalFilter?.filter &&
                  `(${Object.keys(finalFilter?.filter)?.length})`}
              </Button>
            )}
            {userPermissions &&
            havePermission(userPermissions, "routes", "createRoutes") ? (
              <Button
                variant="contained"
                sx={{
                  ...buttonClasses?.lynkitOrangeEmpty,
                  minHeight: "36px",
                }}
                data-testid="addRouteBtn"
                startIcon={<AddIcon />}
                onClick={() => {
                  sendCustomEventAnalytics("routes", "add-route", "Add Route");
                  history.push("/dashboard/routes/add-route");
                }}
              >
                Add Route
              </Button>
            ) : null}
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          sx={{
            borderBottom: 1,
            borderColor: "divider",
            mt: 0,
            mb: 1,
          }}
        >
          {/* tabs */}
          <Grid item xs={5}>
            <Tabs
              value={selectedTab}
              onChange={handleTabsChange}
              variant="scrollable"
              scrollButtons="auto"
              className="tabsClass"
              aria-label="scrollable auto tabs example"
              sx={{
                maxWidth: window.innerWidth - 108,
                "& .MuiTabs-indicator": {
                  backgroundColor: themeType.default.themeOrange,
                },
                "& .MuiTab-root.Mui-selected": {
                  color: themeType.default.themeOrange,
                },
              }}
            >
              {tabs?.map((item, i) => {
                return (
                  <Tab
                    key={i}
                    label={`${item.name}`}
                    sx={{
                      ...textClasses.normalText,
                      textTransform: "capitalize",
                    }}
                    variant="scrollable"
                    // scrollButtons
                    // allowScrollButtonsMobile
                  />
                );
              })}
            </Tabs>
          </Grid>
        </Grid>

        {/* ----- Action Buttons for bulk ------ */}
        {checkedData.length > 0 ? (
          <Box pl={"0"} mt={-1} mb={0.4} data-testid="edit">
            {checkedData.length < 2 ? (
              <>
                {userPermissions &&
                havePermission(userPermissions, "routes", "editRoutes") ? (
                  <Button
                    size="small"
                    data-testid="edit"
                    variant="contained"
                    className="UM_uploadfile-btn"
                    //disabled={checkedData.length > 1 ? true : false}
                    sx={{
                      fontSize: "12px",
                      borderRadius: "8px",
                      margin: "5px",
                      color: "black",
                      border: "1px solid black",
                      ...buttonClasses.lynkitOrangeFill,
                      mr: 1,
                      textTransform: "none",
                    }}
                    onClick={() => {
                      sendCustomEventAnalytics(
                        "routes",
                        "edit-route",
                        "Edit Route"
                      );
                      setSelectedRouteId(tableData[tableIndex]?._id);
                      history.push(
                        `/dashboard/routes/edit-route/${tableData[tableIndex]?._id}`
                      );
                    }}
                  >
                    {" "}
                    Edit{" "}
                  </Button>
                ) : null}
              </>
            ) : (
              ""
            )}

            {bulkStatus !== null && (
              <Button
                size="small"
                data-testid="delete"
                variant="contained"
                className="UM_delete-btn"
                onClick={() => {
                  setActionStatus(
                    bulkStatus == "active" ? "disable" : "enable"
                  );
                  handleOpenDeleteModal();
                }}
                sx={{
                  fontSize: "12px",
                  margin: "5px",
                  color: "black",
                  border: "1px solid black",
                  textTransform: "none",
                  ...buttonClasses[
                    bulkStatus == "active"
                      ? "lynkitDisabled"
                      : "lynkitGreenFill"
                  ],
                }}
              >
                {bulkStatus == "active" ? "Block" : "Active"}
              </Button>
            )}
            {userPermissions &&
            havePermission(userPermissions, "routes", "deleteRoutes") ? (
              <Button
                size="small"
                data-testid="delete"
                variant="contained"
                className="UM_delete-btn"
                onClick={() => {
                  setActionStatus("delete");
                  handleOpenDeleteModal();
                }}
                sx={{
                  fontSize: "12px",
                  borderRadius: "8px",
                  margin: "5px",
                  color: "black",
                  border: "1px solid black",
                  textTransform: "none",
                  ...buttonClasses.lynkitBlackFill,
                }}
              >
                {" "}
                Delete{" "}
              </Button>
            ) : null}
          </Box>
        ) : (
          ""
        )}
        {/* ----- Table and details section ----- */}
        {loader ? (
          <Grid
            container
            direction={"column"}
            justifyContent={"center"}
            alignItems="center"
            xs={12}
            sx={{ p: 15 }}
          >
            <Grid item>
              <img src={loaderGif} />
            </Grid>
          </Grid>
        ) : (
          <>
            {allRoutes && allRoutes?.length === 0 && !loader ? (
              <EmptyPage body="No Routes found" />
            ) : (
              <Grid container spacing={1}>
                <Grid item sm={selectedRouteId ? 9 : routeForAnalytics ? 8 : 12}>
                  <RoutesTable
                    header={tableHeader}
                    rows={tableRows}
                    // tableColumns={tableColumns}
                    data={tableData ?? []}
                    totalRecords={totalRoutes}
                    pagination={filter}
                    setPagination={setFilter}
                    pageFilterKey="page"
                    themeType={themeType}
                  />
                </Grid>
                {selectedRouteId && (
                  <Grid item sm={3} xs={6} sx={{ p: 1, pt: 0 }}>
                    <DetailCard
                      id={selectedRouteId}
                      details={routeDetails}
                      schedulesData={selectedRouteSchedules}
                      handleGetDetails={handleGetRouteDetails}
                      onClose={() => setSelectedRouteId(null)}
                      themeType={themeType}
                      selectedSchedule={selectedSchedule}
                      setSelectedSchedule={setSelectedSchedule}
                      schedulesTabs={schedulesTabs}
                      setSchedulesTabs={setSchedulesTabs}
                      userDetails={userDetails}
                    />
                  </Grid>
                )}
                {routeForAnalytics && (
                  <Grid item sm={4} xs={6} sx={{ p: 1, pt: 0 }}>
                    <AnalyticsCard
                      onClose={() => setRouteForAnalytics(null)}
                      selectedRoute={routeForAnalytics}
                      routeAnalyticsData={routeAnalyticsData}
                      allDeviceCategoryNames={allDeviceCategoryNames}
                      dropdownObj={dropdownObj}
                      setDropdownObj={setDropdownObj}
                      routeJourneyOptions={routeJourneyOptions}
                    />
                  </Grid>
                )}
              </Grid>
            )}
          </>
        )}

        {/* ----- Additional Components ----- */}
        {actionIndex != null && (
          <Menu
            data-testid="action-menu"
            id="basic-menu"
            anchorEl={anchorEl}
            open={actionIndex != null}
            onClose={handleCloseAction}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
            sx={{
              display:
                actionIndex != null ? "block !important" : "none !important",
            }}
          >
            {userPermissions &&
            havePermission(userPermissions, "routes", "editRoutes") ? (
              <MenuItem
                key={1}
                onClick={() => {
                  dispatch(setRoutesTempFilter(filter));
                  setSelectedRouteId(tableData[tableIndex]?._id);
                  sendCustomEventAnalytics(
                    "routes",
                    "edit-route",
                    "Edit Route"
                  );
                  history.push(
                    `/dashboard/routes/edit-route/${tableData[tableIndex]?._id}`
                  );
                }}
              >
                Edit
              </MenuItem>
            ) : null}

            <MenuItem
              key={3}
              onClick={() =>
                handleOpenModal(
                  tableData[tableIndex]?.status?.toLowerCase() == "active"
                    ? "disable"
                    : "enable"
                )
              }
            >
              {tableData[tableIndex]?.status?.toLowerCase() == "active"
                ? "Block"
                : "Active"}
            </MenuItem>
            {userPermissions &&
            havePermission(userPermissions, "routes", "deleteRoutes") ? (
              <MenuItem
                key={3}
                data-testid="delete-menu"
                onClick={() => handleOpenModal("delete")}
              >
                Delete
              </MenuItem>
            ) : null}
          </Menu>
        )}

        {showConfirmModal && (
          <ConfirmModal
            open={showConfirmModal}
            loading={loader}
            hide={() => {
              setShowConfirmModal(false);
              setActionStatus(null);
              handleCloseAction();
              setComment("");
            }}
            tableData={tableData}
            checkedObj={checkedData}
            action={actionStatus}
            setShowConfirmModal={() => {
              setShowConfirmModal(false);
            }}
            comment={comment}
            setComment={setComment}
            selectStylesOverride={selectStylesOverride(
              isDarkThemeEnabledSelector
            )}
            actionFn={handleConfirmActionFn}
            setSelectedTemplates={setSelectedTemplates}
            selectedTemplates={selectedTemplates}
            setCheckedObj={setCheckedData}
          />
        )}
        {viaPointEntry && (
          <ViaPointModal
            open={viaPointEntry ? true : false}
            hide={() => {
              setViaPointEntry(null);
            }}
            selectedEntry={viaPointEntry}
            themeType={themeType}
          />
        )}
      </Grid>
    </FiltersWrapper>
  );
};

export default Routes;
