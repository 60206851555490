import React, {useState} from 'react'
import { Typography, Collapse, Grid, IconButton } from '@mui/material'
import { ExpandMoreOutlined } from '@mui/icons-material'
import { styled } from '@mui/material/styles'
import GenericDraggableComponent from './GenericDraggableComponent'
import theme from '../../../static/styles/theme'

const ExpandMore = styled((props) => {
    const {expand, ...other} = props;
    return <IconButton {...other} />
})(({theme, expand}) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
}))

export default function GenericComponentsCollapse({index, title, components, type, componentType}) {
    const [expanded, setExpanded] = useState(index == 1 || index == 2 ? true : false);
    
    const handleExpandClick = () => {
        setExpanded(!expanded)
    }
    return (
        <>
            <Typography variant='string' sx={{color: theme.themeOrange, fontSize: "14px", fontWeight: "400"}}>
                {index}. {title}
                <ExpandMore
                    expand={expanded}
                    onClick={handleExpandClick}
                    aria-expanded={expanded}
                    aria-label="show more"
                >
                    <ExpandMoreOutlined sx={{color: theme.themeOrange}}/>
                </ExpandMore>
            </Typography>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
                <Grid container>
                    {
                        components.map((comp, i) => {
                            return (
                                <Grid key={i} container direction={"column"} item xs={12} sm={type == "list" ? 12 : 4} justifyContent="center" alignItems={"center"} sx={{pl: 1, pt: 1}}>
                                    <GenericDraggableComponent component={comp} index={i} orientation={type} componentType={componentType} />
                                </Grid>
                            )
                        })
                    }
                </Grid>
            </Collapse>
        </>
    )
}
