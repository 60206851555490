/* eslint-disable */
// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax
import { Box, IconButton, Typography } from "@mui/material";
import React from "react";
import loaderGif from "../../../static/images/drawer/loader.gif";
import CameraPlaybackDrawer from "./components/CameraPlaybackDrawer";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAddressFromCoords,
  getAllGeofencesForMap,
  getCameraAlerts,
  getCameraPlaybacks,
  getDeviceDetails,
  getHistoryTrack,
  getTrackData,
} from "../../../redux/actions";
import {
  capitalizeSentence,
  convertIntoGivenTimezone,
} from "../../../services/functions";
import speedLogo from "../../../assets/images/speed.png";
import dateTime from "../../../assets/images/dateandtime.png";
import locationLogo from "../../../assets/infoWindow/location.png";
import speed from "../../../assets/images/speed.png";
import geofenceImg from "../../../assets/images/map.png";
import distance from "../../../assets/images/distance.png";
import deviceeventImg from "../../../assets/images/deviceevent.png";
// import dateTime from "../../../assets/images/dateandtime.png";
import location from "../../../assets/infoWindow/location.png";
import truck from "../../../assets/infoWindow/truckBlack.png";
import battery from "../../../assets/infoWindow/low-battery.png";
import batteryConnected from "../../../assets/infoWindow/connected_icon_new.png";
import batteryDisconnected from "../../../assets/infoWindow/disconnected_icon_new.png";
import attached from "../../../assets/infoWindow/attached_icon_new.png";
import deattached from "../../../assets/infoWindow/deattached_icon_new.png";
import tag from "../../../assets/infoWindow/tag.png";
import lock from "../../../assets/infoWindow/lock.png";
import darkLock from "../../../assets/infoWindow/dark_lock.png";
import darkUnlock from "../../../assets/infoWindow/dark_unlock.png";
import gpsIcon from "../../../assets/img/mapicon_orange.png";
import unlock from "../../../assets/infoWindow/unlock.png";
import greenMarkerIcon from "../../../static/images/map/green_marker.svg";
import axios from "axios";
import { locationUrl } from "../../../services/config";
import { useParams } from "react-router";
import DataTable from "../../Components/DataTable/DataTable";
import { Download, Info, PlayArrow } from "@mui/icons-material";
import { alarmData, darkStyles } from "../../../services/constants";
import SlideshowIcon from "@mui/icons-material/Slideshow";
import EmptyPage from "../EmptyPage";
import Iframe from "react-iframe";
import EvidenceModal from "./components/EvidenceModal";

const CameraPlayback = () => {
  var infoWindows = [];
  var trackDetailInfoWindow = window.google
    ? new google.maps.InfoWindow()
    : null;

  const { id } = useParams();
  const dispatch = useDispatch();
  const iconPath = `https://lynktrac.io/lynktracV2/trip-icons`;

  /* Local States --------- */
  const [showMap, setShowMap] = React.useState(false);
  const [showCamera, setShowCamera] = React.useState(false);
  const [cameraPlayback, setCameraPlayback] = React.useState(false);
  const [eventPlayback, setEventPlayback] = React.useState(false);
  const [structuredEventList, setStructuredEventList] = React.useState([]);
  const [structuredCameraList, setStructuredCameraList] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [myMap, setMymap] = React.useState(null);
  const [iFrameUrl, setIFrameUrl] = React.useState(null);
  const [currentLocation, setCurrentLocation] = React.useState({});
  const [mapLoaded, setMapLoaded] = React.useState(false);
  const [deviceTrackData, setDeviceTrackData] = React.useState(null);
  const [openEventModal, setOpenEventModal] = React.useState(false);
  const [selectedEventData, setSelectedEventData] = React.useState(null);
  const [filters, setFilters] = React.useState({
    from: new Date(),
    to: new Date(),
    overstoppage: "",
    overspeed: "",
  });

  /* Selectors ------- */
  const isDarkThemeEnabledSelector = useSelector(
    (state) => state.theme.themeDark
  );
  const { socketInstance, connected } = useSelector((_) => _?.socket);
  const {
    deviceDetails,
    cameraAlerts: eventsListing,
    cameraPlaybacks,
  } = useSelector((_) => _?.deviceslist);
  const { geofenceListingForMap } = useSelector((_) => _?.geofencelist);
  const { currentUserDetail } = useSelector((_) => _?.auth);

  const themeType = isDarkThemeEnabledSelector
    ? require("../../../static/styles/darktheme")
    : require("../../../static/styles/theme");

  const { textClasses } = themeType;

  const filtersPayload = React.useMemo(() => {
    return {
      id: deviceDetails?.client_id,
      type: deviceDetails?.deviceType,
      overspeed: filters?.overspeed ?? "",
      overstop: filters?.overstoppage ?? "",
      userId: currentUserDetail?._id,
      userType:
        currentUserDetail?.userRole?.type || currentUserDetail?.userType,
      socketId: connected && socketInstance ? socketInstance?.id : "",
    };
  }, [deviceDetails, filters, currentUserDetail, socketInstance, connected]);

  const cameraTableColumns = [
    {
      header: "Channel",
      cell: (entry) => {
        return (
          <Typography
            sx={{
              ...textClasses.t13n,
              textAlign: "center",
              textTransform: "capitalize",
            }}
          >
            {entry?.channel ?? "-"}
          </Typography>
        );
      },
    },
    {
      header: "Start time",
      cell: (entry) => {
        return (
          <Typography
            sx={{
              ...textClasses.t13n,
              textAlign: "center",
              textTransform: "capitalize",
            }}
          >
            {entry?.start
              ? convertIntoGivenTimezone(
                  entry?.start,
                  currentUserDetail?.timeZone?.offset
                )
              : "-"}
          </Typography>
        );
      },
    },
    {
      header: "End Time",
      cell: (entry) => {
        return (
          <Typography
            sx={{
              ...textClasses.t13n,
              textAlign: "center",
              textTransform: "capitalize",
            }}
          >
            {entry?.stop
              ? convertIntoGivenTimezone(
                  entry?.stop,
                  currentUserDetail?.timeZone?.offset
                )
              : "-"}
          </Typography>
        );
      },
    },
    {
      header: "Duration(seconds)",
      cell: (entry) => {
        const startTime = new Date(entry?.start);
        const endTime = new Date(entry?.stop);

        // Calculate the duration in milliseconds by subtracting start time from end time
        const durationInMillis = endTime - startTime;

        // Convert milliseconds to seconds
        const durationInSeconds = durationInMillis / 1000;
        return (
          <Typography
            sx={{
              ...textClasses.t13n,
              textAlign: "center",
              textTransform: "capitalize",
            }}
          >
            {durationInSeconds ?? "-"}
          </Typography>
        );
      },
    },
    {
      header: "Actions",
      cell: (entry) => {
        return (
          <Typography
            data-testid="action-menu-btn"
            sx={{
              ...textClasses.normalText,
              color: themeType.default.themeOrange,
            }}
            // onClick={(e) => handleOpenAction(e, index)}
          >
            <Box display="flex" justifyContent="center">
              <IconButton
                size="small"
                onClick={() => {
                  const videoPath = entry?.downUrl?.split("=")[1];
                  const sourceUrl = `http://43.205.126.165:9967/vss/apiPage/ReplayAlarmServerVideo.html?token=${localStorage.getItem(
                    "cameraToken"
                  )}&deviceId=${
                    deviceDetails?.client_id
                  }&chs=1&fpath=${videoPath}`;
                  setIFrameUrl(sourceUrl);
                }}
              >
                <PlayArrow />
              </IconButton>
              <IconButton
                size="small"
                onClick={() => {
                  handleDownload(entry?.downUrl);
                }}
              >
                <Download />
              </IconButton>
            </Box>
          </Typography>
        );
      },
    },
  ];

  const eventsTableColumns = [
    {
      header: "#",
      cell: (i) => {
        return (
          <Typography
            sx={{
              ...textClasses.t13n,
              textAlign: "center",
              textTransform: "capitalize",
            }}
          >
            {i + 1}
          </Typography>
        );
      },
    },
    {
      header: "Alert Name",
      cell: (entry) => {
        return (
          <Typography
            sx={{
              ...textClasses.t13n,
              textAlign: "center",
              textTransform: "capitalize",
            }}
          >
            {entry?.alarmType
              ? alarmData?.find((_) => _?.code === entry?.alarmType)?.type
              : "-"}
          </Typography>
        );
      },
    },
    {
      header: "Alert Time",
      cell: (entry) => {
        return (
          <Typography
            sx={{
              ...textClasses.t13n,
              textAlign: "center",
              textTransform: "capitalize",
            }}
          >
            {entry?.alarmTime
              ? convertIntoGivenTimezone(
                  entry?.alarmTime,
                  currentUserDetail?.timeZone?.offset
                )
              : "-"}
          </Typography>
        );
      },
    },
    {
      header: "Location",
      cell: (entry, index) => {
        return (
          <Typography
            sx={{
              ...textClasses.t13n,
              textAlign: "center",
              textTransform: "capitalize",
              color: themeType?.default?.themeOrange,
              cursor: "pointer",
            }}
            onClick={() => {
              const location = entry?.alarmGps?.split(",");
              getAddressFromCoords(
                { lat: location[0], lng: location[1] },
                index
              );
            }}
          >
            {entry?.address ? entry?.address : entry?.alarmGps || "-"}
          </Typography>
        );
      },
    },
    {
      header: "Actions",
      cell: (entry) => {
        return (
          <Typography
            data-testid="action-menu-btn"
            sx={{
              ...textClasses.normalText,
              color: themeType.default.themeOrange,
            }}
            // onClick={(e) => handleOpenAction(e, index)}
          >
            <IconButton
              size="small"
              onClick={() => {
                setOpenEventModal(true);
                setSelectedEventData(entry);
              }}
            >
              <Info />
            </IconButton>
          </Typography>
        );
      },
    },
  ];

  /* Handlers -------- */
  const geofenceRenderer = (map, geoJsonData) => {
    const outputJson = {
      type: "FeatureCollection",
      features: geoJsonData.map((location) => ({
        type: "Feature",
        properties: location.features[0].properties,
        geometry: location.features[0].geometry,
      })),
    };

    const geoJsonLayer = new google.maps.Data();
    geoJsonLayer.addGeoJson(outputJson);

    geoJsonLayer.setStyle(function (feature) {
      return {
        fillColor: "#0059ff",
        strokeColor: "#0059ff",
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillOpacity: 0.5,
      };
    });

    geoJsonLayer.setMap(map);

    geoJsonLayer.addListener("click", function (event) {
      let props = {
        name: event.feature.getProperty("name"),
        status: event.feature.getProperty("status"),
        addedOn: convertIntoGivenTimezone(
          event.feature.getProperty("addedOn"),
          currentUserDetail.timeZone.offset
        ),
        addedBy: event.feature.getProperty("addedBy"),
        centerCoordinates: event.feature.getProperty("centerCoordinates"),
      };
      infoWindowRederer(map, props);
    });
  };

  const infoWindowRederer = (map, feature) => {
    const coordinates = feature.centerCoordinates[0];
    const infoWindowContent = `
      <div style="min-width:310px">
          <div> <span style="display: inline-block; width: 80px; color: #909090;">Name:</span> <span style="color: #ff7200; font-Size: 20px; font-Weight: 500; text-transform: capitalize">${feature?.name}</span></div><br>
          <div><span style="display: inline-block; width: 80px; color: #909090;" >Status:</span> <span style="color: #000; font-Weight: 500;"> ${feature?.status}</span><br><br>
          <div><span style="display: inline-block; width: 80px; color: #909090;">Added By:</span><span style="color: #000; font-Weight: 500;"> ${feature?.addedBy?.name}</span><br><br>
          <div><span style="display: inline-block; width: 80px; color: #909090;">Added On:</span><span style="color: #000; font-Weight: 500;"> ${feature?.addedOn}</span><br><br>
      </div>
      `;
    const infoWindow = createInfoWindow(infoWindowContent, {
      lat: Number(coordinates[0][1]),
      lng: Number(coordinates[0][0]),
    });
    map.panTo({
      lat: Number(coordinates[0][1]),
      lng: Number(coordinates[0][0]),
    });
    infoWindows.forEach((window) => window.close());
    infoWindow.open(map);
    infoWindows.push(infoWindow);
  };

  const createInfoWindow = (content, position) => {
    return new google.maps.InfoWindow({
      content: content,
      position: position,
    });
  };

  const trackInfoRenderer = (address, event, map, marker) => {
    // trackDetailInfoWindow?.close();
    // const {
    //   lockStatus,
    //   lock_status,
    //   batteryPercent,
    //   speedVal,
    //   distanceVal,
    //   time,
    //   vehicleStatus,
    //   address,
    //   eventName,
    //   vehicleNumber,
    //   gpsVal,
    //   geofenceNames,
    //   deviceeventStatus,
    // } = fieldData;

    // let lockStatusUpdated;

    // let status = deviceeventStatus ? deviceeventStatus : "-";

    let statusColor = "";
    console.log("deviceDetails", deviceDetails);
    if (deviceDetails.ignition_status == "IDLE") {
      statusColor = "#f1c232";
    } else if (deviceDetails.ignition_status == "MOVING") {
      statusColor = "#34a853";
    } else if (deviceDetails.ignition_status == "OFFLINE") {
      statusColor = "#000";
    } else if (deviceDetails.ignition_status == "IGNITION_OFF") {
      statusColor = "#b7b7b7";
    } else if (deviceDetails.ignition_status == "STOP") {
      statusColor = "#b7b7b7";
    } else {
      statusColor = "#989898";
    }

    let infoWindowContent = `
    <div style="width:420px; min-width:100%; padding:5px" class="t-menu-item">
    <div style="display:flex;">
      <div style="flex: 1; margin-right: 10px;">
        <div style="display:flex; text-align:center; justify-content:center; align-items:center; height:65px; border-radius: 5px; background-color:${statusColor}; box-shadow: 2px 9px 6px 0px rgba(81, 81, 87, 0.29)">
          <div style="width:100%; text-align:center; color:#fff; padding:15px;">
            <div style="font-weight:700; font-size:13px;">${
              deviceDetails?.ignition_status
            }</div>
            <div>Device Status</div>
          </div>
        </div>
      </div>
      <div style="flex: 1;">
        <div style="display:flex; text-align:center; justify-content:center; align-items:center; height:65px; border-radius: 5px; background-color: #6495ED; box-shadow: 2px 9px 6px 0px rgba(81, 81, 87, 0.29);">
          <div style="width:100%; text-align:center; color:#fff; padding:15px;">
            <div style="font-weight:700; font-size:13px;">${
              deviceDetails?.client_id
            }</div>
            <div>Device ID</div>
          </div>
        </div>
      </div>
    </div>
    <div style="display:flex; margin-top: 20px;">
      <div style="flex: 1; border-right: dotted; border-color: #32333561; text-align:center;">
        <img src="${speedLogo}" width="20" style="margin-bottom:5px;">
        <h3 style="font-weight:700; font-size:13px; color:#e72b2b;">Speed</h3>
        <span style="line-height:15px;">${deviceDetails.speed}</span>
      </div>
      <div style="flex: 1; text-align:center;">
        <img src="${dateTime}" width="20" style="margin-bottom:5px;">
        <h3 style="font-weight:700; font-size:13px; color:#084c5b;">Date & Time</h3>
        <span style="line-height:15px;">${
          convertIntoGivenTimezone(
            deviceDetails.packet_time * 1000,
            currentUserDetail?.timeZone?.offset
          ) || "NA"
        }</span>
      </div>
    </div>
    <div style="display:flex; margin-top:20px;">
      <div style="flex: 1; text-align:center;">
        <img src="${locationLogo}" style="width:50%;">
      </div>
      <div style="flex: 3; font-size:13px; color:#475a6d; margin-top:10px; margin-bottom:10px;">
        <span style="line-height:15px;">${
          deviceDetails.geo_name
        }, ${address}</span>
      </div>
    </div>
  </div>
      `;
    // ${geofenceNames ? `<span>${geofenceNames}</span>` : 'null'}
    trackDetailInfoWindow.setContent(infoWindowContent);
    trackDetailInfoWindow.setPosition(event.latLng);
    trackDetailInfoWindow.open(map);
  };

  const handleRenderTrackOnMap = (geoJsonData) => {
    if (geoJsonData && myMap) {
      // removePreviousTrack();
      const firstGpsSignal = geoJsonData?.features[0]?.properties?.gps;
      let trackData = [];
      myMap.data.addGeoJson(geoJsonData, {
        idPropertyName: "_id",
      });

      myMap.data.setStyle({
        strokeColor: "#ff7200", // Orange color
        strokeWeight: 2,
      });
      myMap.data.forEach((feature, index) => {
        if (feature.getGeometry().getType() === "LineString") {
          const coordinates = feature.getGeometry().getArray();
          const lineSymbol = {
            path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW, // Arrow symbol
            scale: 3, // Size of the arrow
            strokeColor: "#ff7200", // Arrow color
            fillColor: "#ff7200",
          };
          // Create Polylines for each segment
          for (let i = 0; i < coordinates.length - 1; i++) {
            const segmentPath = [coordinates[i], coordinates[i + 1]];

            // Create the Polyline with arrow icon
            const line = new google.maps.Polyline({
              path: segmentPath,
              icons: [
                {
                  icon: lineSymbol,
                  offset: "50%", // Adjust offset for the first arrow
                },
              ],
              strokeColor: "#ff7200",
              // Customize line style as needed
              map: myMap,
            });
          }
        } else if (feature.getGeometry().getType() === "Point") {
          const iconFile = feature.getProperty("img")
            ? feature.getProperty("img")?.split("/")
            : "";
          let iconImg =
            iconFile && Array.isArray(iconFile)
              ? iconFile[iconFile?.length - 1]
              : null;
          const imgSize =
            iconImg && (iconImg?.includes("start") || iconImg?.includes("end"))
              ? 25
              : 20;
          myMap.data.overrideStyle(feature, {
            icon: {
              url: iconImg ? `${iconPath}/${iconImg}` : greenMarkerIcon,
              scaledSize: new google.maps.Size(imgSize, imgSize),
            },
          });

          let objMap = {
            latitude: feature.getProperty("latitude"),
            longitude: feature.getProperty("longitude"),
            event: feature.getProperty("event"),
            event_alert: feature.getProperty("event_alert"),
            packet_time: feature.getProperty("packet_time"),
            lockStatus: feature.getProperty("lockStatus"),
            icon: feature.getProperty("img"),
          };
          handleEventSummaryData(objMap, true, trackData);
        }
      });

      myMap?.data?.addListener("click", (event) => {
        var feat = event.feature;
        let geofenceName = "";
        if (
          feat?.getProperty("geo_name") &&
          Array.isArray(feat?.getProperty("geo_name"))
        ) {
          geofenceName = feat
            ?.getProperty("geo_name")
            ?.map((_) => _)
            ?.join(", ");
        } else if (
          feat?.getProperty("geo_name") &&
          typeof feat?.getProperty("geo_name") === "string"
        ) {
          geofenceName = feat?.getProperty("geo_name");
        }
        const allData = {
          lockStatus: feat?.getProperty("lockStatus") || "-",
          lock_status: feat?.getProperty("lock_status") || "",
          batteryPercent: feat?.getProperty("battery_voltage")
            ? `${Number(feat?.getProperty("battery_voltage"))?.toFixed(2)}%`
            : "-",
          speedVal: feat?.getProperty("speed")
            ? /^\s*([-+]?\d+(\.\d*)?)([eE][+-]?\d+)\s*$/?.test(
                Number(feat?.getProperty("speed"))
              )
              ? `${(parseFloat(feat?.getProperty("speed")) * 10 ** 0).toFixed(
                  2
                )} Km/h`
              : `${feat?.getProperty("speed")} Km/h`
            : "-",
          vehicleStatus: feat?.getProperty("ignition_status") || "-",
          // distanceVal: ((feat?.getProperty("gps") - firstGpsSignal)/1000).toFixed(2) + ' KM' || "-",
          distanceVal: feat?.getProperty("distance") + " KM" || "-",
          time: feat?.getProperty("packet_time")
            ? convertIntoGivenTimezone(
                feat?.getProperty("packet_time"),
                currentUserDetail.timeZone.offset
              )
            : "-",
          eventName: feat?.getProperty("event") || "-",
          vehicleNumber: feat?.getProperty("vehicleNo") || "-",
          geofenceNames: geofenceName || "-",
          deviceeventStatus: feat?.getProperty("event") || "-",
        };

        const payload = {
          latitude: feat?.getProperty("latitude"),
          longitude: feat?.getProperty("longitude"),
          appType: "GOOGLE",
        };

        axios
          .post(`${locationUrl}fetchAddress`, payload, {
            headers: {
              "Content-Type": "application/json",
              authorization: "oLjJEPvkqYloUOfU1aCyAvOBMQKKG9Am",
            },
          })
          .then((response) => {
            let address = response?.data?.data[0]?.address;
            allData.address = response?.data?.data[0]?.address;

            trackInfoRenderer(address, event, myMap);

            // infowindow.setContent(infoWindowContent);
            // infowindow.setPosition(event.latLng);
            // trackDetailInfoWindows?.forEach((window) => window.close());
            // infowindow.open(myMap);
            // trackDetailInfoWindows?.push(infowindow);
          })
          .catch((error) => {
            console.error("Error:", error);
          });
      });
    }
  };

  const renderMap = () => {
    setTimeout(() => {
      if (typeof google === "undefined" || typeof google.maps === "undefined") {
        return null;
      }

      const mapElement = document.getElementById("viewallmap");

      if (!mapElement) {
        console.error("Element with id 'viewallmap' not found.");
        return null;
      }
      if (currentLocation) {
        const mapOptions = {
          zoom: 14,
          center: currentLocation,
          mapTypeId: "terrain",
          zoomControl: true,
          zoomControlOptions: {
            style: google.maps.ZoomControlStyle.SMALL,
            position: google.maps.ControlPosition.LEFT_BOTTOM,
          },
          // options: { styles: isDarkThemeEnabledSelector && styles },
        };

        const map = new google.maps.Map(mapElement, mapOptions);
        const marker = new window.google.maps.Marker({
          position: currentLocation,
          map: map,
          title: "Center Marker",
          optimized: false,
          // icon: gifIcon
        });

        marker?.addListener("click", (event) => {
          var feat = event.feature;
          let speedVal;

          if (deviceDetails?.speed) {
            if (deviceDetails["speed"].includes("e")) {
              speedVal = `${parseFloat(deviceDetails["speed"])} Km/h`;
            } else {
              speedVal = `${(Number(deviceDetails["speed"]) / 1000)?.toFixed(
                2
              )} Km/h`;
            }
          } else {
            speedVal = "-";
          }

          const allData = {
            lockStatus: deviceDetails?.lockStatus
              ? deviceDetails["lockStatus"]
              : "-",
            lock_status: deviceDetails?.lockStatus
              ? deviceDetails["lock_status"]
              : "",
            batteryPercent: deviceDetails?.battery_voltage
              ? `${Number(deviceDetails["battery_voltage"])?.toFixed(2)}%`
              : "-",
            speedVal: speedVal,
            vehicleStatus: deviceDetails?.ignition_status
              ? deviceDetails["ignition_status"]
              : "-",
            distanceVal: deviceDetails?.distance
              ? deviceDetails["distance"]
              : "-",
            time: deviceDetails?.packet_time
              ? convertIntoGivenTimezone(
                  new Date(Number(deviceDetails?.packet_time) * 1000),
                  currentUserDetail.timeZone.offset
                )
              : "-",
            eventName: deviceDetails?.event ?? "-",
            vehicleNumber: deviceDetails?.vehicleNo ?? "-",
            gpsVal: deviceDetails?.gps ?? "-",
            geofenceNames: deviceDetails?.geoName ?? "",
          };

          const payload = {
            latitude: deviceDetails?.latitude ? deviceDetails["latitude"] : "",
            longitude: deviceDetails?.longitude
              ? deviceDetails["longitude"]
              : "",
            appType: "GOOGLE",
          };

          axios
            .post(`${locationUrl}fetchAddress`, payload, {
              headers: {
                "Content-Type": "application/json",
                authorization: "oLjJEPvkqYloUOfU1aCyAvOBMQKKG9Am",
              },
            })
            .then((response) => {
              allData.address = response?.data?.data[0]?.address;
              trackInfoRenderer(
                response?.data?.data[0]?.address,
                event,
                map,
                marker
              );
            })
            .catch((error) => {
              console.error("Error:", error);
            });
        });
        setMymap(map);

        if (geofenceListingForMap && geofenceListingForMap?.length > 0) {
          geofenceRenderer(map, geofenceListingForMap);
          //   geofenceListingForMap?.forEach((featureCollection) => {
          //     featureCollection.features.forEach((feature) => {
          //       geofenceRenderer(map, feature);
          //     });
          //   });
        }
        return map;
      }
    }, 100);
  };

  const handleEventSummaryData = (data, flag = false, trackData) => {
    // let trackData = eventSummaryData;
    if (
      data.icon == "assets/img/trackingicons/end.png" ||
      data.icon == "assets/img/trackingicons/start.png"
    ) {
      if (
        deviceDetails?.deviceType == "elock" ||
        deviceDetails?.deviceType == "block" ||
        deviceDetails?.deviceType == "fixedLock"
      ) {
        if (data.event == "Sealed") {
          data.icon = "assets/img/new_lock.png";
        } else if (data.event == "Unsealed") {
          data.icon = "assets/img/new_auth.png";
        } else if (data.event == "Unauthorised Unsealing") {
          data.icon = "assets/img/new_unauth.png";
        } else if (data.event == "Authorised Unsealing") {
          data.icon = "assets/img/new_auth.png";
        } else if (data.event == "Device Tampered") {
          data.icon = "assets/img/new_tamp.png";
        } else if (data.event == "Motor Unlocked") {
          data.icon = "assets/img/new_motor.png";
        } else if (data.lockStatus == "Case Open") {
          data.icon = "assets/img/case_openNew.png";
        }
      }
    }

    if (flag) {
      if (data.event == "Sealed" && data.lockStatus == "locked") {
        trackData.push({
          lat: data.latitude,
          lng: data.longitude,
          packetTime: data.packet_time,
          event: data.event,
          icon: data.icon,
          lockStatus: "Locked",
        });
      } else if (data.event == "Locked" && data.event != "Sealed") {
        // icon = 'assets/img/new_lock.png'
        // size = new google.maps.Size(20, 20)
        // trackData.push({ lat: track[interval].latitude, lng: track[interval].longitude, packetTime: track[interval].packet_time, event: track[interval].event , icon:data.icon })
      } else if (
        data.lockStatus == "unlocked" &&
        data.event != "Unsealed" &&
        data.event != "Unauthorised Unsealing" &&
        data.event != "Authorised Unsealing" &&
        data.event != "Device Tampered"
      ) {
        // trackData.push({ lat: track[interval].latitude, lng: track[interval].longitude, packetTime: track[interval].packet_time, event: track[interval].event, icon:data.icon })
      } else if (data.event == "Unauthorised Unsealing") {
        trackData.push({
          lat: data.latitude,
          lng: data.longitude,
          packetTime: data.packet_time,
          event: data.event,
          icon: data.icon,
          lockStatus: "Unlocked",
        });
      } else if (data.event == "Authorised Unsealing") {
        trackData.push({
          lat: data.latitude,
          lng: data.longitude,
          packetTime: data.packet_time,
          event: data.event,
          icon: data.icon,
          lockStatus: "Locked",
        });
      } else if (data.event == "Device Tampered") {
        // tampGlobalflag = true;
        trackData.push({
          lat: data.latitude,
          lng: data.longitude,
          packetTime: data.packet_time,
          event: data.event,
          icon: data.icon,
          lockStatus: "Tampered",
        });
      } else if (data.event == "Unsealed") {
        trackData.push({
          lat: data.latitude,
          lng: data.longitude,
          packetTime: data.packet_time,
          event: data.event,
          icon: data.icon,
          lockStatus: "Unlocked",
        });
      } else if (data.lockStatus == "motorUnlocked") {
        if (data.event == "Motor Unlocked")
          trackData.push({
            lat: data.latitude,
            lng: data.longitude,
            packetTime: data.packet_time,
            event: data.event,
            lockStatus: "Motor Unlocked",
            icon: data.icon,
          });
      } else if (data.event == "Case Open") {
        trackData.push({
          lat: data.latitude,
          lng: data.longitude,
          packetTime: data.packet_time,
          event: data.event,
          lockStatus: "Motor Unlocked",
          icon: data.icon,
        });
      }

      if (
        data &&
        data.event_alert &&
        data.event_alert.length > 0 &&
        (data.event_alert.includes("connected") ||
          data.event_alert.includes("disconnected"))
      ) {
        trackData.push({
          lat: data.latitude,
          lng: data.longitude,
          packetTime: data.packet_time,
          event: data.event_alert.includes("connected")
            ? "External Battery Connected"
            : "External Battery Disconnected",
          lockStatus: data.lockStatus,
          icon: data.event_alert.includes("connected")
            ? "assets/img/Attached_green.png"
            : "assets/img/Detached_grey.png",
        });
      }
    } else {
      if (data.event == "Unauthorised Unsealing") {
        trackData.push({
          lat: data.latitude,
          lng: data.longitude,
          packetTime: data.packet_time,
          event: data.event,
          icon: data.icon,
          lockStatus: "Unlocked",
        });
      } else if (data.event == "Authorised Unsealing") {
        trackData.push({
          lat: data.latitude,
          lng: data.longitude,
          packetTime: data.packet_time,
          event: data.event,
          icon: data.icon,
          lockStatus: "Unlocked",
        });
      } else if (data.event == "Device Tampered") {
        trackData.push({
          lat: data.latitude,
          lng: data.longitude,
          packetTime: data.packet_time,
          event: data.event,
          icon: data.icon,
          lockStatus: "Tampered",
        });
      } else if (data.lockStatus == "motorUnlocked") {
        if (data.event == "Motor Unlocked")
          trackData.push({
            lat: data.latitude,
            lng: data.longitude,
            packetTime: data.packet_time,
            event: data.event,
            lockStatus: "Motor Unlocked",
            icon: data.icon,
          });
      } else if (data.event == "Case Open") {
        trackData.push({
          lat: data.latitude,
          lng: data.longitude,
          packetTime: data.packet_time,
          event: data.event,
          lockStatus: "Back Cover Open",
          icon: data.icon,
        });
      }

      if (
        data.event_alert &&
        data.event_alert.length > 0 &&
        (data.event_alert.includes("connected") ||
          data.event_alert.includes("disconnected"))
      ) {
        trackData.push({
          lat: data.latitude,
          lng: data.longitude,
          packetTime: data.packet_time,
          event: data.event_alert.includes("connected")
            ? "External Battery Connected"
            : "External Battery Disconnected",
          lockStatus: data.lockStatus,
          icon: data.event_alert.includes("connected")
            ? "assets/img/Attached_green.png"
            : "assets/img/Detached_grey.png",
        });
      }
    }
  };

  const handleHistoryTrack = () => {
    setLoading(true);
    setTimeout(() => {
      renderMap();
    }, 500);
    const payload = {
      fromDate: new Date(filters.from)?.getTime() ?? "",
      toDate: new Date(filters.to)?.getTime() ?? "",
      ...filtersPayload,
    };

    dispatch(
      getHistoryTrack(payload, (res) => {
        if (res) {
          setDeviceTrackData(null);
          setLoading(true);
        }
        setLoading(false);
      })
    );
  };

  const handleTimeBtnClicked = (hours) => {
    setLoading(true);
    setShowCamera(false);
    setShowMap(true);
    renderMap();
    const newToDateTime = new Date();
    const newFromDateTime = new Date(
      newToDateTime.getTime() - hours * 60 * 60 * 1000
    );

    setFilters((prev) => ({
      ...prev,
      from: newFromDateTime,
      to: newToDateTime,
    }));

    const payload = {
      fromDate: new Date(newFromDateTime)?.getTime() ?? "",
      toDate: new Date(newToDateTime)?.getTime() ?? "",
      ...filtersPayload,
    };
    dispatch(
      getHistoryTrack(payload, (res) => {
        if (res) {
          setDeviceTrackData(null);
          setLoading(true);
          // handleSocketForTrack();
        }
        setLoading(false);
      })
    );
  };

  const formatDateTime = (date) => {
    return (
      date.getFullYear() +
      '-' +
      String(date.getMonth() + 1).padStart(2, '0') +
      '-' +
      String(date.getDate()).padStart(2, '0') +
      ' ' +
      String(date.getHours()).padStart(2, '0') +
      ':' +
      String(date.getMinutes()).padStart(2, '0') +
      ':' +
      String(date.getSeconds()).padStart(2, '0')
    );
  };

  const handleCameraPlayback = () => {
    setLoading(true);
    setCameraPlayback(true);
    setEventPlayback(false);
    setIFrameUrl(null);
    setStructuredEventList([]);
    dispatch(
      getCameraPlaybacks(
        {
          deviceID: deviceDetails?.client_id,
          startTime: formatDateTime(filters?.from),
          endTime: formatDateTime(filters?.to),
        },
        (res) => {
          if (res) {
            setStructuredCameraList(res?.data?.files);
          }
          setLoading(false);
        }
      )
    );
  };

  const handleEventsPlayback = () => {
    setLoading(true);
    setCameraPlayback(false);
    setEventPlayback(true);
    setIFrameUrl(null);
    setStructuredCameraList([]);
    dispatch(
      getCameraAlerts(
        {
          startTime: formatDateTime(filters?.from),
          endTime: formatDateTime(filters?.to),
          pageNum: "1",
          pageCount: "20",
          deviceId: deviceDetails?.client_id,
        },
        (res) => {
          if (res) {
            setStructuredEventList(res?.data?.list);
          }
          setLoading(false);
        }
      )
    );
  };

  const loadGoogleMapsApi = () => {
    return new Promise((resolve, reject) => {
      if (window.google) {
        resolve(window.google);
      } else {
        const script = document.createElement("script");
        script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyANnEwTq59T8s30-hXFPNOS5t9_OP47i2A&libraries=geometry,drawing,places`;
        script.onload = () => {
          resolve(window.google);
        };
        script.onerror = (error) => {
          reject(error);
        };
        document.body.appendChild(script);
      }
    });
  };

  const handleDownload = (downloadURL) => {
    const downloadLink = document.createElement("a");
    downloadLink.href = downloadURL;
    downloadLink.setAttribute("download", "filename.mp4");
    downloadLink.style.display = "none";
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  const getAddressFromCoords = (item, i) => {
    let temp = structuredEventList;
    const payload = {
      latitude: item?.lat,
      longitude: item?.lng,
      appType: "GOOGLE",
    };
    dispatch(
      fetchAddressFromCoords(payload, (res) => {
        if (res && res?.length > 0) {
          const { address } = res[0];
          if (temp[i]) {
            temp[i] = { ...temp[i], address: address };
          }
          setStructuredEventList([...temp]);
        }
      })
    );
  };

  /* ---Use effects--- */
  React.useEffect(() => {
    let isMounted = true;
    loadGoogleMapsApi()
      .then((google) => {
        if (isMounted) {
          setMapLoaded(true);
        }
      })
      .catch((error) => {
        console.error("Error loading Google Maps API:", error);
      });

    return () => {
      isMounted = false;
    };
  }, []);

  React.useEffect(() => {
    if (id) {
      try {
        setLoading(true);
        setShowMap(false);
        setShowCamera(false);
        setDeviceTrackData(null);
        dispatch(getDeviceDetails({ id }));
        dispatch(
          getAllGeofencesForMap({
            filter:{
              orgId: currentUserDetail?.orgId,
            }
          }, () => {
            setLoading(false);
          })
        );
        setFilters({
          from: new Date(),
          to: new Date(),
          overstoppage: "",
          overspeed: "",
        });
      } catch (e) {
        console.log("error initial: ", e);
      }
    }
  }, [dispatch, id]);

  React.useEffect(() => {
    if (deviceDetails) {
      var pos = {
        lat: Number(deviceDetails?.latitude),
        lng: Number(deviceDetails?.longitude),
      };
      setCurrentLocation(pos);
    }
  }, [deviceDetails]);

  React.useEffect(() => {
    if (socketInstance && connected) {
      socketInstance?.on("data", (response) => {
        if (response) {
          if (response?.data) {
            dispatch(
              getTrackData({ file: response?.data?.file }, (res) => {
                if (res) {
                  setDeviceTrackData(res);
                }
                setLoading(false);
              })
            );
          }
        }
      });

      return () => {
        socketInstance.off("data");
      };
    }
  }, [socketInstance, connected]);

  React.useEffect(() => {
    if (deviceTrackData) {
      handleRenderTrackOnMap(deviceTrackData);
    }
  }, [deviceTrackData]);

  React.useEffect(() => {
    if (myMap) {
      const mapStyles = isDarkThemeEnabledSelector ? darkStyles : null;
      myMap.setOptions({ styles: mapStyles });
    }
  }, [isDarkThemeEnabledSelector, myMap]);

  return (
    <Box
      width="100%"
      height="90vh"
      maxHeight="900px"
      position="relative"
      display="flex"
    >
      {/* {loading ? (
      ) : (
      )} */}
      {loading && (
        <Box
          bgcolor="background.paper"
          style={{
            zIndex: 1000,
            height: "100%",
            width: "100%",
            position: "absolute",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          data-testid="loader"
        >
          <img src={loaderGif} />
        </Box>
      )}
      <>
        <Box width="100%" height="100%" mr={1.5}>
          {!showMap && !showCamera && (
            <Box width="100%" height="100%" borderRadius="12px" display="flex" data-testid="choose-option-screen">
              <Box m="auto">
                <EmptyPage body="Choose an option" />
              </Box>
            </Box>
          )}
          {!showCamera && (
            <Box
              width="100%"
              height="100%"
              // height={showMap && mapLoaded ? "100%" : "0px"}
              borderRadius="12px"
              data-testid="map-container"
              sx={{ visibility: showMap && mapLoaded ? "visible" : "hidden" }}
            >
              <div
                id="viewallmap"
                className="drawMap"
                style={{
                  width: "100%",
                  height: "100%",
                  borderRadius: "12px",
                  // display: showMap && mapLoaded ? "block" : "none",
                }}
              />
            </Box>
          )}

          {!showMap && showCamera && (
            <Box
              height="100%"
              width="100%"
              display="flex"
              flexDirection="column"
              gap={1}
            >
              {cameraPlayback && (
                <Box
                  height="50%"
                  width="100%"
                  borderRadius="12px"
                  sx={{ background: themeType.default.themeGray }}
                  data-testid="camera-section"
                >
                  {/* Camera area----- */}
                  {iFrameUrl ? (
                    <Iframe className="iframe-camera" url={iFrameUrl} />
                    // <></>
                  ) : (
                    <Box
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      height="100%"
                      width="100%"
                    >
                      <SlideshowIcon
                        sx={{ fontSize: "5rem ! important", color: "#ff7200" }}
                      />
                      <Typography>
                        Select file from the table and play.
                      </Typography>
                    </Box>
                  )}
                </Box>
              )}
              <Box
                width="100%"
                height={cameraPlayback ? "50%" : "auto"}
                bgcolor="ivory"
                sx={{ overflowY: "auto" }}
                borderRadius="12px"
                data-testid="datatable-section"
              >
                {/* Table area----- */}
                <DataTable
                  themeType={themeType}
                  tableColumns={
                    cameraPlayback ? cameraTableColumns : eventsTableColumns
                  }
                  data={
                    cameraPlayback
                      ? structuredCameraList
                      : eventPlayback && eventsListing
                      ? structuredEventList
                      : []
                  }
                />
              </Box>
            </Box>
          )}
        </Box>
        <CameraPlaybackDrawer
          themeType={themeType}
          isDark={isDarkThemeEnabledSelector}
          setShowMap={setShowMap}
          setShowCamera={setShowCamera}
          filters={filters}
          setFilters={setFilters}
          handleHistoryTrack={handleHistoryTrack}
          handleTimeBtnClicked={handleTimeBtnClicked}
          handleEventsPlayback={handleEventsPlayback}
          handleCameraPlayback={handleCameraPlayback}
        />
      </>
      {selectedEventData && (
        <EvidenceModal
          open={openEventModal}
          eventObject={selectedEventData}
          userDetails={currentUserDetail}
          deviceDetails={deviceDetails}
          hide={() => {
            setOpenEventModal(false);
          }}
        />
      )}
    </Box>
  );
};

export default CameraPlayback;
