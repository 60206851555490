import { FiberManualRecordTwoTone, Lock } from "@mui/icons-material";
import { Box, Typography, Grid } from "@mui/material";
import React, { useEffect } from "react";
import { fetchAddressFromCoords } from "../../../../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import ThreeDotsLoader from "../../../Components/ThreeDots/ThreeDotsLoader";
import { convertIntoGivenTimezone, capitalizeSentence } from "../../../../services/functions";
import { URLFORTRIPMAP } from "../../../../services/Api";
import { locationUrl } from "../../../../services/config";
import axios from 'axios'
import truck from "../../../../assets/infoWindow/truck.png"
import lock from "../../../../assets/infoWindow/lock.png"
import unlock from "../../../../assets/infoWindow/unlock.png"
import location from "../../../../assets/infoWindow/location.png"
import tag from "../../../../assets/infoWindow/tag.png"
import routeIcon from "../../../../assets/images/routeIcon.png"
import speed from "../../../../assets/images/speed.png"
import distance from "../../../../assets/images/distance.png"
import dateTime from "../../../../assets/images/dateandtime.png"
import battery from "../../../../assets/infoWindow/low-battery.png"

const EventSummaryTimeline = ({ themeType, tripEventsDetails, myMap, infoWindow, newInfoWindow, setInfoWindow, tripDetails, idParam, userDetails, geofenceWindow, fastTageWindow }) => {
  const { inputClasses, buttonClasses, textClasses } = themeType;
  const dispatch = useDispatch()
  const [eventDataState, setEventData] = React.useState(null);

  useEffect(() => {
    if (Object.keys(tripEventsDetails).length) {
      setEventData(tripEventsDetails)
    }
  }, [tripEventsDetails])

  const getAddressFromCoords = (item, i) => {
    let temp = [...tripEventsDetails?.eventData]
    const payload = {
      latitude: item?.lat,
      longitude: item?.lng,
      appType: "GOOGLE",
    };
    dispatch(
      fetchAddressFromCoords(payload, (res) => {
        if (res && res?.length > 0) {
          const { address } = res[0];
          if (temp[i]) {
            temp[i] = { ...(temp[i]), address: address };
          }
          const updatedData = {
            ...tripEventsDetails,
            eventData: temp,
          };
          setEventData(updatedData);
        }
      })
    );
  }

  const bgColor = (data) => {
    let lockStatus = data?.lockStatus || ''
    let color = '';
    if (lockStatus === 'locked') {
      color = 'green';
    }
    else if (lockStatus === 'unlocked') {
      color = '#D2042D';
    }
    else if (lockStatus === 'tampered') {
      color = '#D2042D';
    }
    else if (lockStatus === 'motorUnlocked') {
      color = '#FFAA1D';
    }

    return { color };
  }

  // const openInfoWindow = (item) => {
  //   let infoWindowContent;
  //   let markerObj = {
  //     "deviceId": "99875087937",
  //     "packet_id": "667c11bbfa5634587500c31e",
  //     "status": "",
  //     "eventAfterTampered": false,
  //     "icon": "Sealed",
  //     "gps": "48817.44",
  //     "offlineLimit": 3
  //   }
  //   axios.post(`${URLFORTRIPMAP}/getTripMapPacketData`, { ...markerObj }, {
  //     headers: {
  //       Authorization: JSON.parse(localStorage.getItem('token'))
  //     }
  //   })
  //     .then(response => {
  //       let data = response?.data?.trackData
  //       const lockStatus = data?.lockStatus || '-';
  //       const batteryPercent = data?.battery + '%' || '-';
  //       const tripStatus = data?.trip_status || '-';
  //       const speedVal = data?.speed.toFixed(2) + ' kmph' || '-';
  //       const distanceVal = data?.distance || '-';
  //       const time = convertIntoGivenTimezone(data?.packet_time, userDetails.timeZone.offset) || '-';
  //       const tripEventStatus = data?.event || null
  //       const activeDevice = data?.deviceType || "-"
  //       const color = data?.color === 'yellow' ? '#FFAA1D' : data?.color
  //       const backgroundColor = bgColor(data)
  //       const payload = {
  //         latitude: data?.latitude,
  //         longitude: data?.longitude,
  //         appType: "GOOGLE",
  //       };


  //       axios.post(`${locationUrl}fetchAddress`, payload, {
  //         headers: {
  //           "Content-Type": "application/json",
  //           authorization: "oLjJEPvkqYloUOfU1aCyAvOBMQKKG9Am",
  //         },
  //       })
  //         .then(response => {
  //           let address = response?.data?.data[0]?.address
  //           infoWindowContent = `<div class='infoDiv'>
  //       <div class='infoChild1'>
        
        
  //       ${tripEventStatus !== '-' ?
  //               `<div class='subChild' style="background-color: ${color}" >
  //             <img class='lockImg'  src=${truck} alt='' />
  //             <span class='deviceLabel'>Trip Event </span>
  //             <span class='devicevalue'>${tripEventStatus}</span>
  //         </div>`
  //               : ''
  //             }
  //           ${lockStatus !== '-' ?
  //               `<div class='subChild' style="background-color: ${backgroundColor?.color}">
  //         <img class='lockImg'  src=${lockStatus === 'motorUnlocked' ? unlock : lock} alt='' />
  //         <span class='deviceLabel'>Device Status </span>
  //         <span class='devicevalue'>${lockStatus === 'motorUnlocked' ? 'Motor Unlocked' : capitalizeSentence(lockStatus)}</span>
  //       </div>  `
  //               : ''
  //             }
  //       <div class='subChild bgblue'>
  //           <img class='lockImg'  src=${tag} alt='' />
  //           <span class='deviceLabel'> Device Id </span>
  //           <span class='devicevalue'> ${idParam}</span>
  //       </div>
  //     </div>
  //     <div class='infoChild2'>


  //     ${activeDevice !== "simTracking" ?
  //               `<div class='subChild2 '>
  //     <img class='imgCss'  src=${battery} alt='' />
  //     <span class='infoLabel label2'>Battery</span>
  //     <span class='infoValue infoValue2'>${batteryPercent}</span>
  //       </div>`
  //               : ''}


  //       <div class='subChild2 '>
  //         <img class='imgCss'  src=${routeIcon} alt='' />
  //         <span class='infoLabel label2'>Trip Status</span>
  //         <span class='infoValue infoValue2'>${tripStatus}</span>
  //       </div>

  //         <div class='subChild2'>
  //           <img class='imgCss'  src=${speed} alt='' />
  //           <span class='infoLabel label2'>Speed </span>
  //           <span class='infoValue infoValue2'>${speedVal}</span>
  //         </div>

  //       <div class='subChild2'>
  //           <img class='imgCss'  src=${distance} alt='' />
  //           <span class='infoLabel label2'>Distance </span>
  //           <span class='infoValue infoValue2'>${distanceVal}</span>
  //       </div>

  //       <div class='subChild2'>
  //         <img class='imgCss'  src=${dateTime} alt='' />
  //         <span class='infoLabel label2'>Date & Time</span>
  //         <span class='infoValue infoValue2'>${time}</span>
  //       </div>
  //     </div>

  //     <div class='infoChild3'>
  //       <div class='addressDiv'>
  //         <div class='addressImg'>
  //         <img class='imgCss' src=${location} alt='' />
  //         </div>
  //         <div class='addressBox'>
  //         <span class='infoLabel'>${address}</span>
  //         </div>
  //       </div>
        
  //     </div>
  //     </div>
  //   `
  //           if (geofenceWindow) {
  //             geofenceWindow.close();
  //           }

  //           if(infoWindow){
  //             infoWindow.close()
  //           }

  //           if (newInfoWindow) {
  //             newInfoWindow.close();
  //           }

  //           if (fastTageWindow) {
  //             fastTageWindow.close();
  //           }


  //           let position = {
  //             lat: Number(item.lat), lng: Number(item.lng)
  //           }

  //           myMap.panTo(position);

  //           newInfoWindow.setContent(infoWindowContent);
  //           newInfoWindow.setPosition(position);
  //           newInfoWindow.open(myMap);
  //           setInfoWindow(newInfoWindow);

  //         })
  //         .catch(error => {
  //           console.error('Error:', error);
  //         });
  //     }
  //     )
  //     .catch(error => {
  //       console.error('Error creating data:', error);
  //     });
  // }

  return (
    <>
      {/* {eventDataState !== null && eventDataState !== undefined && Object.keys(eventDataState).length ? */}
      <Box p={1} display="flex" flexDirection="column" height="100%">
        {eventDataState?.eventData?.map((item, i) => {
          return (
            <Box index={i} sx={{ display: "flex", gap: "4px", height: "100%" }}>
              <Box display="flex" flexDirection="column" alignItems="center">
                <FiberManualRecordTwoTone sx={{ color: "#FF7200" }} />
                <div
                  style={{ background: "#FF7200", width: "2px", height: "100%" }}
                />
              </Box>
              <Box
                pt="3px"
                pb="6px"
                display="flex"
                gap="3px"
                flexDirection="column"
              >
                <Typography
                  sx={{
                    ...textClasses.t13n,
                    fontWeight: 700,
                    color: themeType.default.themeOrange,
                    cursor: 'pointer'
                  }}
                  // onClick={() => openInfoWindow(item)}
                >
                  {item?.event || '-'}
                </Typography>
                {item?.geo_name ?
                  <Typography
                    sx={{ ...textClasses.normalText, fontSize: "13.5px" }}
                  >
                    {item?.geo_name === 'nil' ? 'Out of geofence' : item?.geo_name || '-'}
                  </Typography>
                  : null}
                <Typography sx={{ ...textClasses.normalText, fontSize: "11px" }}>
                  {convertIntoGivenTimezone(item?.date, userDetails.timeZone.offset) || '-'}

                </Typography>
                {!item?.address ? (
                  <Typography
                    sx={{
                      ...textClasses.normalText,
                      fontSize: "13.5px",
                      color: themeType.default.themeOrange,
                      cursor: "pointer"
                    }}
                    onClick={() => getAddressFromCoords(item, i)}
                  >
                    Get Address
                  </Typography>
                ) : (
                  <>
                    <Typography
                      sx={{
                        ...textClasses.normalText,
                        fontSize: "13.5px",
                        color: themeType.default.themeGreen,
                        cursor: "pointer"
                      }}
                    >
                      {item?.address || ''}
                    </Typography>
                  </>
                )}
              </Box>
            </Box>
          );
        })}
      </Box>
      {/* :
        <Typography>No Event Data.</Typography>
       
      } */}
    </>
  );
};

export default EventSummaryTimeline;
{/* <Grid item={true} data-testid='loader' container direction={"column"} justifyContent={"center"} alignItems="center" xs={12} sx={{ p: 1 }}>
          <ThreeDotsLoader />
        </Grid> */}