import {
  Button,
  Autocomplete,
  TextField,
  Grid,
  MenuItem,
  Paper,
  Menu,
  Typography,
  FormControl,
  OutlinedInput,
  InputAdornment,
  Link,
  Box,
  IconButton,
  Tooltip,
  InputLabel,
  Checkbox,
  Card,
  Skeleton,
} from "@mui/material";
import {
  Check,
  Close,
  Create,
  FilterAlt,
  KeyboardArrowDown,
  Search,
  Visibility,
} from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { Controller, useForm, useWatch } from "react-hook-form";
import {
  fetchAddressFromCoords,
  getAllDeviceCategoryNames,
  getAllDeviceModels,
  getAllDevices,
  getAllUsers,
  updateDeviceStatus,
  getBoards,
  updateDeviceFlag,
  getDeviceStatusGraph,
  getLockStatusGraph,
  getPasscodeOTP,
  showPasscode,
  verifyPasscodeOTP,
  generatePasscodeForFixedLock,
  getAllOrganizations,
  getAllAdmins,
  currentStatusReport,
  deviceWiseEvents,
  deviceEvents,
  saveFilters,
} from "../../../redux/actions";
import {
  clearConsole,
  deleteEmptyKeys,
  selectStylesOverride,
  sendCustomEventAnalytics,
} from "../../../utils/util";
import loaderGif from "../../../static/images/drawer/loader.gif";
import EmptyPage from "../EmptyPage";
import CustomSelect from "../../../components/FormUI/CustomSelect";
import DevicesTable from "./components/DevicesTable";
import { debounce } from "lodash";
import {
  flushAllDevices,
  setDevicesFilters,
} from "../../../redux/reducers/DevicesReducers";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import { main } from "./helper";
import { capitalizeSentence, getShortText } from "../../../services/functions";
import FiltersWrapper from "../../Components/FiltersWrapper/FiltersWrapper";
import DeviceAdditionalModal from "./DeviceAdditionalModal";
import { havePermission } from "../../../utils/permissionCheck";
// import DoughnutChart from "../../Components/DoughnutChart/DoughnutChart";
import DownloadIcon from "@mui/icons-material/Download";
import DonutChart from "../../Components/D3/DonutChart";
import { dateTimePickerSlotProps } from "../../../utils/util";
import { showToast } from "../../../services/functions";
import { DateTimePicker } from "@mui/x-date-pickers";
import DeviceShareModal from "./components/DeviceShareModal";
import { graphColors, reportTypes } from "../../../services/constants";
import FixedLockPasscodeModal from "./components/FixedLockPasscodeModal";
import OTPModal from "./components/OTPModal";
import { CustomInput } from "../../../components/FormUI";
import ThreeDotsLoader from "../../Components/ThreeDots/ThreeDotsLoader";
import history from "../../../services/history";
// import { flushAllBoards } from "../../../redux/reducers/Dashboard";
import ReactGA from "react-ga4";
import { devicesAxios } from "../../../services/Api";

let payload = {
  q: "",
  pageNumber: 1,
  limit: 10,
};
let payloadBoard = {
  module: "Device",
  search: "",
  page: 1,
  limit: 10,
};
const statusOptions = [
  { label: "Active", value: "ACTIVE" },
  { label: "Disable", value: "DISABLE" },
  { label: "In-Stock", value: "INSTOCK" },
  { label: "Request for Servicing/Repairing", value: "REQUEST_FOR_REPAIRING" },
  { label: "Under Servicing/Repairing", value: "UNDER_REPAIRING" },
  { label: "Repaired", value: "REPAIRED" },
];

const lockStatusFilterOptions = [
  { label: "Locked", value: "Locked" },
  { label: "Unlocked", value: "Unlocked" },
  { label: "Motor Unlocked", value: "Motor Unlocked" },
  { label: "Tampered", value: "Tampered" },
];

export default function Devices({ previousRoute }) {
  //dispatcher -----
  const dispatch = useDispatch();
  // const history = useHistory();
  const location = useLocation();

  const {
    control,
    reset,
    setValue,
    handleSubmit,
    formState: { errors, isSubmitting, isDirty },
  } = useForm();
  //local states -------
  const [anchorEl, setAnchorEl] = useState(null);
  const [isVisible, setIsVisible] = useState(true);
  const [actionIndex, setActionIndex] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [keysArray, setKeysArray] = useState(null);
  const [statusValue, setStatusValue] = useState(null);
  const [loader, setLoader] = useState(false);
  const [passcodeLoader, setPasscodeLoader] = useState(false);
  const [passcodeIndex, setPasscodeIndex] = useState(null); //to show loader at the relevant password btn
  const [graphLoader, setGraphLoader] = useState(false);
  const [boardLoader, setBoardLoader] = useState(false);
  const [reportLoader, setReportLoader] = useState(false);
  const [filter, setFilter] = useState({ ...payload });
  const [finalFilter, setFinalFilter] = useState(null);
  const [additionalInfoModal, setAdditionalInfoModal] = useState(false);
  const [tableIndex, setTableIndex] = React.useState(0);
  const [open, setOpen] = React.useState(false);
  const [reportType, setReportType] = useState("");
  const [shareDeviceModal, setShareDeviceModal] = useState(false);
  const [d3DougnutArray, setD3DougnutArray] = useState([]);
  const [showFixedLockPasscodeModal, setShowFixedLockPasscodeModal] =
    React.useState(false);
  const [modalLoading, setModalLoading] = React.useState(false);
  const [showPasscodeOTPModal, setShowPasscodeOTPModal] = React.useState(false);
  const [passcodeValue, setPasscodeValue] = React.useState(null); //same state for showing elock and fixed lock passcode values
  const [passcodeIdToVerify, setPasscodeIdToVerify] = React.useState(null); //elock passcode id got in send otp response
  const [statusActionIndex, setStatusActionIndex] = useState(null);
  const [deviceDetails, setDeviceDetails] = useState(null); // for view passcode
  const [reportModal, setReportModal] = React.useState(false);
  const [deviceShareObj, setDeviceShareObj] = useState({});
  const [dStatusFilterOptions, setDStatusFilterOptions] = useState([]);
  const [reportDeviceTypeOptions, setReportDeviceTypeOptions] = useState([]);
  const isDarkThemeEnabledSelector = useSelector(
    (state) => state.theme.themeDark
  );
  const boardListing = useSelector(
    (state) => state.dynamicDashboard.boardsListing[0]
  );
  const themeType = isDarkThemeEnabledSelector
    ? require("../../../static/styles/darktheme")
    : require("../../../static/styles/theme");

  const { buttonClasses, inputClasses, textClasses, dividerClass } = themeType;
  const [searchFields, setSearchFields] = React.useState({
    users: null,
    deviceModel: null,
    deviceType: null,
    reportDeviceType: null,
    org: null,
  });
  const [deviceId, SetDeviceId] = useState("");

  const [reportFields, setReportFields] = useState({
    deviceType: "",
    userId: "",
    email: [],
    fromDate: "",
    toDate: "",
  });

  //selectors -------
  const {
    allDevices,
    deviceListFlag,
    totalRecords,
    allDeviceCategoryNames,
    allDeviceModels,
    fetchedAddress,
    deviceStatusGraphData,
    adminLists,
    devicesFilters,
  } = useSelector((state) => state.deviceslist);

  const { allUsers, allOrganizations } = useSelector((state) => state.userlist);
  const { currentUserDetail } = useSelector((state) => state.auth);
  const { allFilters } = useSelector((state) => state.filters);
  const { message, error } = useSelector((state) => state.common);
  let dynamiHeader =
    boardListing?.boardData?.map((item, index) => item.columnName) || [];

  const showSavedFilters = React.useMemo(() => {
    if (previousRoute) {
      if (previousRoute?.state) {
        return previousRoute?.state?.from === "device-map-page" ||
          previousRoute?.state?.from === "create-device-page"
          ? true
          : false;
      } else {
        return previousRoute?.pathname?.includes("/devices/map/") ||
          previousRoute?.pathname?.includes("/edit-device") ||
          previousRoute?.pathname?.includes("camera")
          ? true
          : false;
      }
    } else {
      return false;
    }
  }, [previousRoute]);

  /** Handlers **/
  const handleUpdateDeviceStatus = () => {
    try {
      if (statusActionIndex !== null && statusValue) {
        setLoader(true);
        dispatch(
          updateDeviceStatus(
            {
              id: tableData[statusActionIndex]?.client_id,
              status: statusValue?.value,
            },
            (res) => {
              if (res?.success) {
                handleCloseStatusAction();
                dispatch(
                  getAllDevices({
                    ...filter,
                    selection:
                      keysArray && keysArray?.length > 0
                        ? [
                            ...keysArray,
                            "_id",
                            "orgName",
                            "formData",
                            "deviceType",
                            "driver_name"
                          ]
                        : [],
                  })
                );
              }
              setLoader(false);
            }
          )
        );
      }
    } catch (e) {
      setLoader(false);
    }
  };

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname,
      title: "device-listing",
    });
  }, []);

  const handleCloseStatusAction = () => {
    setStatusActionIndex(null);
    setStatusValue(null);
  };

  const handleOpenAction = (event, i) => {
    setAnchorEl(event.currentTarget);
    setActionIndex(i);
    setTableIndex(i);
  };

  const handleOpenStatusAction = (event, i) => {
    setStatusActionIndex(i);
    setStatusValue(
      statusOptions?.find((_) => _?.value === tableData[i]?.device_status)
    );
  };

  const handleCloseAction = () => {
    setActionIndex(null);
    setTimeout(() => {
      setAnchorEl(null);
    }, 1);
  };

  const handleDrawerToggle = () => {
    setOpen(!open);
    if(searchFields?.reportDeviceType){
      handleSearchReportDeviceType(true);
    }
  };

  const handleFetchAllDevices = () => {
    if (filter?.filter?.deviceType) {
      try {
        setLoader(true);
        setIsVisible(true);
        setTimeout(() => {
          dispatch(
            getAllDevices(
              {
                ...filter,
                selection:
                  keysArray && keysArray?.length > 0
                    ? [
                        ...keysArray,
                        "_id",
                        "orgName",
                        "formData",
                        "deviceType",
                        "deviceModel",
                         "driver_name"
                      ]
                    : [],
              },
              async (res) => {
                if (res) {
                  setLoader(false);
                  dispatch(updateDeviceFlag(true));
                  handleGetGraphData();
                  setIsVisible(true);
                }
              },
              () => {
                setLoader(false);
              }
            )
          );
        }, 30);
      } catch {
        setLoader(false);
      }
    }
  };

  const handleGetGraphData = async () => {
    // let tempGraph = [];
    let d3Data = [];
    if (
      filter?.filter?.deviceType?.length > 0 &&
      filter?.filter?.deviceType !== "bLock" &&
      filter?.filter?.deviceType !== "simTracking"
    ) {
      setGraphLoader(true);
      await dispatch(
        getDeviceStatusGraph(
          { ...filter?.filter },
          // { deviceType: filter?.filter?.deviceType },
          (res) => {
            if (res?.success) {
              const data = res?.data;
              let selectedObject;

              if (filter?.filter?.deviceType === "elock") {
                selectedObject = data?.lock;
              } else if (filter?.filter?.deviceType === "fixedELock") {
                // selectedObject = data?.lock;
                selectedObject = data?.fixedELock;
              } else if (filter?.filter?.deviceType === "fixedTracker") {
                selectedObject = data?.tracker;
              } else {
                selectedObject = data[filter?.filter?.deviceType];
              }

              let deviceStatusArr = [
                {
                  label: "Offline",
                  value: selectedObject?.offline,
                  color: graphColors?.black,
                },
                {
                  label: "Idle",
                  value: selectedObject?.idle,
                  color: graphColors?.yellow,
                },
                {
                  label: "Moving",
                  value: selectedObject?.running,
                  color: graphColors?.dullGreen,
                },
                { chartTitle: "Device Status" },
              ];

              if (filter?.filter?.deviceType === "fixedTracker") {
                deviceStatusArr.splice(3, 0, {
                  label: "Ignition Off",
                  value: selectedObject?.ignition,
                  color: graphColors?.grey,
                });
              }

              d3Data.push([...deviceStatusArr]);
            }
            setGraphLoader(false);
          }
        )
      );
    }
    if (
      filter?.filter?.deviceType?.length > 0 &&
      filter?.filter?.deviceType !== "simTracking"
    ) {
      setGraphLoader(true);
      await dispatch(
        getLockStatusGraph({ ...filter?.filter }, (res) => {
          if (res?.success) {
            const data = res?.data;
            let lockStatusArr = [];
            if (filter?.filter?.deviceType === "elock") {
              lockStatusArr = [
                {
                  label: "Tampered",
                  value: data?.tampered,
                  color: graphColors?.red,
                },
                {
                  label: "Locked",
                  value: data?.locked,
                  color: graphColors?.brightGreen,
                },
                {
                  label: "Unauthorized Unsealing",
                  value: data?.unauth,
                  color: graphColors?.orange,
                },
                {
                  label: "Unlocked",
                  value: data?.unlocked,
                  color: graphColors?.gray,
                },
                {
                  label: "Motor Unlocked",
                  value: data?.motorUnlocked,
                  color: graphColors?.yellow,
                },
              ];
            }
            if (filter?.filter?.deviceType === "fixedELock") {
              lockStatusArr = [
                {
                  label: "Locked",
                  value: data?.locked,
                  color: graphColors?.dullGreen,
                },
                {
                  label: "Partially Locked",
                  value: data?.partialLocked,
                  color: graphColors?.yellow,
                },
                {
                  label: "Unlocked",
                  value: data?.unlocked,
                  color: graphColors?.gray,
                },
                {
                  label: "Power Off",
                  value: data?.poweroff,
                  color: graphColors?.black,
                },
                {
                  label: "Tampered",
                  value: data?.tampered,
                  color: graphColors?.red,
                },
              ];
            }
            if (filter?.filter?.deviceType === "fixedTracker") {
              lockStatusArr = [
                {
                  label: "External Power Connected",
                  value: data?.locked,
                  color: graphColors?.dullGreen,
                },
                {
                  label: "External Power Disconnected",
                  value: data?.unlocked,
                  color: graphColors?.gray,
                },
                {
                  label: "Power Off",
                  value: data?.poweroff ?? 0,
                  color: graphColors?.black,
                },
              ];
            }
            lockStatusArr.push({
              chartTitle:
                filter?.filter?.deviceType === "fixedTracker"
                  ? "Connection Status"
                  : "Lock Status",
            });
            d3Data.push([...lockStatusArr]);
          }
          setGraphLoader(false);
        })
      );
    }
    setD3DougnutArray(d3Data);
  };

  const handleApplyFilters = () => {
    if (filter || filter?.q !== "") {
      handleFetchAllDevices();
      setFinalFilter(filter);
    }
  };

  const handleClearAllFilters = () => {
    try {
      const nFilter = { ...payload };
      nFilter.filter = {
        deviceType: currentUserDetail?.adminSpecificPermissions
          ? "elock"
          : allDeviceCategoryNames[0]
          ? allDeviceCategoryNames[0]?.category
          : "",
      };
      setFilter({ ...nFilter });
      setFinalFilter({ ...nFilter });
      //dispatch(getAllDevices({ ...nFilter }, () => setLoader(false)));
    } catch {
      //setLoader(false);
    }
  };

  const handleMakeDeviceTypeAPICall = async (payload, callback) => {
    try {
      const response = await devicesAxios.post(
        "/listDeviceCategoryType",
        payload
      );
      const data = response.data;
      if (data?.success) {
        callback(data?.data);
      } else {
        callback([]);
      }
    } catch (error) {
      callback([]);
    }
  };

  const handleSearchUsers = () => {
    if (searchFields?.users || searchFields?.users == "")
      dispatch(
        getAllUsers({
          searchValue: searchFields?.users,
          selection: ["name", "orgName"],
        })
      );
  };

  const handleSearchDeviceWiseAdmins = (empty) => {
    if (searchFields?.users || searchFields?.users == "")
      dispatch(
        getAllAdmins({
          searchValue: searchFields?.users,
        })
      );
  };

  const handleSearchDeviceModels = () => {
    if (
      (searchFields?.deviceModel || searchFields?.deviceModel == "") &&
      currentUserDetail?.adminSpecificPermissions
    )
      dispatch(
        getAllDeviceModels({
          filter: {
            category: filter?.filter?.deviceType,
            lynkDeviceModel: searchFields?.deviceModel,
          },
          selection: ["deviceModel", "lynkDeviceModel"],
        })
      );
  };

  const handleSearchOrg = (empty) => {
    if (empty === true) {
      dispatch(getAllOrganizations({ selection: ["name"] }));
    } else {
      if (searchFields?.org || searchFields?.org == "")
        dispatch(
          getAllOrganizations({
            selection: ["name"],
            searchValue: searchFields.org,
          })
        );
    }
  };

  const handleSearchDeviceType = () => {
    if (searchFields?.deviceType || searchFields?.deviceType == "")
      dispatch(
        getAllDeviceCategoryNames({
          searchValue: searchFields?.deviceType,
          selection: ["label", "category"]
        })
      );
  };

  const handleSearchReportDeviceType = (empty) => {
    if (empty === true) {
      handleMakeDeviceTypeAPICall(
        {selection: ["label", "category"]},
        (data) => {
          setReportDeviceTypeOptions(data);
        }
      );
    } else {
      if (
        searchFields?.reportDeviceType ||
        searchFields?.reportDeviceType == ""
      )
        handleMakeDeviceTypeAPICall(
          {
            searchValue: searchFields?.reportDeviceType,
            selection: ["label", "category"]
          },
          (data) => {
            setReportDeviceTypeOptions(data);
          }
        );
    }
  };

  const handlePasscodes = (device) => {
    if (device) {
      setPasscodeValue(null);
      setDeviceDetails(device);
      setTimeout(() => {
        if (filter?.filter?.deviceType === "elock") {
          handleViewPasscode(device);
        } else if (filter?.filter?.deviceType?.toLowerCase() === "fixedelock") {
          handleFixedLockPasscode(device);
        }
      }, 300);
    }
  };

  const handleViewPasscode = (deviceDetailsObj) => {
    if (
      havePermission(
        currentUserDetail,
        "unlockingOption",
        "otpForUnlockingPasscode"
      )
    ) {
      setModalLoading(true);
      dispatch(
        getPasscodeOTP({ deviceId: deviceDetailsObj?.client_id }, (res) => {
          if (res && res?.success) {
            setPasscodeIdToVerify(res?._id);
            setShowPasscodeOTPModal(true);
          }
          setModalLoading(false);
        })
      );
    } else {
      setPasscodeLoader(true);
      const payload = { deviceId: deviceDetailsObj?.client_id };
      dispatch(
        showPasscode(payload, (res) => {
          if (res && res?.success) {
            setPasscodeIdToVerify(null);
            setPasscodeValue({
              id: deviceDetailsObj?.client_id,
              passcode: res?.result,
            });
          }
          setPasscodeLoader(false);
        })
      );
    }
  };

  const handleFixedLockPasscode = () => {
    setShowFixedLockPasscodeModal(true);
  };

  const handleVerifyOtpForPasscode = (data) => {
    setModalLoading(true);
    const payload = {
      otp: data?.otp,
      _id: passcodeIdToVerify,
    };

    dispatch(
      verifyPasscodeOTP(payload, (res) => {
        if (res && res?.success) {
          setPasscodeIdToVerify(null);
          setLoader(true);
          dispatch(
            showPasscode({ deviceId: deviceDetails?.client_id }, (res) => {
              if (res && res?.success) {
                setPasscodeValue({
                  id: deviceDetails?.client_id,
                  passcode: res?.result,
                });
                setShowPasscodeOTPModal(false);
              }
              setLoader(false);
            })
          );
        }
        setModalLoading(false);
      })
    );
  };

  const handleGenerateFixedLockPasscode = (data, cb) => {
    setModalLoading(true);
    const payload = {
      randomNo: data?.randomNo,
      deviceId: deviceDetails?.client_id,
    };

    dispatch(
      generatePasscodeForFixedLock(payload, (res) => {
        if (res && res?.success) {
          setPasscodeValue({
            id: deviceDetails?.client_id,
            passcode: res?.message,
          });
          cb && cb();
        }
        setModalLoading(false);
      })
    );
  };

  const handleSaveFilters = () => {
    if (filter?.filter) {
      const filteredObject = deleteEmptyKeys(filter?.filter);
      const payload = {
        type: "devices",
        data: { ...filteredObject },
        status: allFilters && allFilters?.devicesFilter ? true : false,
      };

      dispatch(
        saveFilters(payload, (res) => {
          if (res && res?.success) {
          }
        })
      );
    }
  };

  const handleSaveFiltersForStore = () => {
    const tempFilters = filter;
    dispatch(setDevicesFilters({ ...tempFilters }));
  };

  const fetchModels = React.useCallback(
    debounce((name) => {
      dispatch(
        getAllDeviceModels({
          filter: {
            category: name,
          },
          selection: ["deviceModel", "lynkDeviceModel"],
        })
      )?.then((res) => {});
    }, 200),
    []
  );

  const filterDropdowns = [
    {
      key: "byUser",
      name: "Filter by User",
      // options: allUsers ?? []
      options: currentUserDetail?.adminSpecificPermissions
        ? allUsers?.length > 0
          ? allUsers.map((ele) => ({
              name: `${ele.name}-${ele.orgName}`,
              _id: ele._id,
            }))
          : []
        : adminLists?.userDeviceData
        ? adminLists.userDeviceData.map((ele) => ({
            orgName: `${ele._id}-${ele.orgName}`,
            userId: ele.userId,
          }))
        : [],
      labelKey: currentUserDetail?.adminSpecificPermissions
        ? "name"
        : "orgName",
      valueKey: currentUserDetail?.adminSpecificPermissions ? "_id" : "userId",
      reportModal: reportModal,
      value: filter?.filter?.user ?? null,
      searchValue:
        searchFields?.users && searchFields?.users !== ""
          ? searchFields?.users
          : null,
      onSearch: currentUserDetail?.adminSpecificPermissions
        ? handleSearchUsers
        : handleSearchDeviceWiseAdmins,
      onInputChange: (e) => {
        if (e?.type === "change") {
          setSearchFields((prev) => ({
            ...prev,
            users: e?.target?.value?.trim(),
          }));
        }
      },
      onChange: (newVal) => {
        if (newVal) {
          setFilter((prev) => ({
            ...prev,
            filter: {
              ...prev?.filter,
              user: currentUserDetail?.adminSpecificPermissions
                ? newVal?._id
                : newVal?.userId,
            },
          }));
        } else {
          let temp = { ...filter };
          delete temp["filter"]["user"];
          setFilter(temp);
        }
      },
    },
    {
      key: "byDeviceStatus",
      name: "Filter by Device Status",
      options: dStatusFilterOptions ?? [],
      reportModal: reportModal,
      value: filter?.filter?.deviceStatus ?? null,
      labelKey: "label",
      valueKey: "value",
      onChange: (newVal) => {
        if (newVal) {
          setFilter((prev) => ({
            ...prev,
            filter: {
              ...prev?.filter,
              deviceStatus: newVal?.value,
            },
          }));
        } else {
          let temp = { ...filter };
          delete temp["filter"]["deviceStatus"];
          setFilter(temp);
        }
      },
    },
    {
      key: "byStatus",
      name: "Filter by Status",
      options: currentUserDetail?.adminSpecificPermissions
        ? statusOptions
        : statusOptions.filter((option) => {
            return option.label === "Request for Servicing/Repairing";
          }) ?? [],
      reportModal: reportModal,
      value: filter?.filter?.status ?? null,
      labelKey: "label",
      valueKey: "value",
      onChange: (newVal) => {
        if (newVal) {
          setFilter((prev) => ({
            ...prev,
            filter: {
              ...prev?.filter,
              status: newVal?.value,
            },
          }));
        } else {
          let temp = { ...filter };
          delete temp["filter"]["status"];
          setFilter(temp);
        }
      },
    },
  ];

  if (currentUserDetail && currentUserDetail?.adminSpecificPermissions) {
    filterDropdowns?.unshift({
      key: "orgName",
      name: "Organization Name",
      options: allOrganizations ?? [],
      labelKey: "name",
      valueKey: "_id",
      value: filter?.filter?.orgId || null,
      searchValue:
        searchFields?.org && searchFields?.org !== ""
          ? searchFields?.org
          : null,
      onSearch: handleSearchOrg,
      onInputChange: (e) => {
        if (e?.type === "change") {
          if (e?.target?.value === "") handleSearchOrg(true);
          setSearchFields((prev) => ({
            ...prev,
            org: e?.target?.value?.trim(),
          }));
        }
      },
      onChange: (newVal) => {
        if (newVal) {
          setFilter((prev) => ({
            ...prev,
            filter: {
              ...prev?.filter,
              orgId: newVal?._id,
            },
          }));
          dispatch(
            getAllUsers({
              selection: ["name", "orgName"],
              orgId: newVal?._id,
            })
          );
        } else {
          let temp = { ...filter };
          delete temp["filter"]["orgId"];
          setFilter(temp);
        }
      },
    });
  }

  /* device type and associated device models */
  const mandatoryFilters = [
    {
      key: "byDeviceType",
      name: "Filter by Device Type",
      options: allDeviceCategoryNames || [],
      value: filter?.filter?.deviceType ?? null,
      labelKey: "label",
      valueKey: "category",
      disableClearable: true,
      searchValue:
        searchFields?.deviceType && searchFields?.deviceType !== ""
          ? searchFields?.deviceType
          : null,
      onSearch: handleSearchDeviceType,
      onInputChange: (e) => {
        if (e?.type === "change") {
          setSearchFields((prev) => ({
            ...prev,
            deviceType: e?.target?.value?.trim(),
          }));
        }
      },
      onChange: (newVal) => {
        if (newVal) {
          setFilter((prev) => ({
            ...prev,
            filter: {
              ...prev?.filter,
              deviceType: newVal?.category,
            },
          }));

          setReportFields((prev) => ({
            ...prev,
            deviceType: newVal?.category,
          }));
        } else {
          let temp = { ...filter };
          delete temp["filter"]["deviceType"];
          setFilter(temp);
          setReportFields((prev) => ({
            ...prev,
            deviceType: "",
          }));
        }
      },
    },
    {
      key: "byDeviceModel",
      name: "Filter by Device Model",
      options: allDeviceModels || [],
      disabled: !filter?.filter?.deviceType,
      value: filter?.filter?.deviceModel ?? null,
      labelKey: "lynkDeviceModel",
      valueKey: "deviceModel",
      searchValue:
        searchFields?.deviceModel && searchFields?.deviceModel !== ""
          ? searchFields?.deviceModel
          : null,
      onSearch: handleSearchDeviceModels,
      onInputChange: (e) => {
        if (e?.type === "change") {
          setSearchFields((prev) => ({
            ...prev,
            deviceModel: e?.target?.value?.trim(),
          }));
        }
      },
      onChange: (newVal) => {
        if (newVal) {
          setFilter((prev) => ({
            ...prev,
            filter: {
              ...prev?.filter,
              deviceModel: newVal?.deviceModel,
            },
          }));
        } else {
          let temp = { ...filter };
          delete temp["filter"]["deviceModel"];
          setFilter(temp);
        }
      },
    },
  ];

  const debounceSearch = () => {
    handleFetchAllDevices();
  };

  if (
    dynamiHeader &&
    dynamiHeader?.length > 0 &&
    filter?.filter &&
    filter?.filter?.deviceType &&
    filter?.filter?.deviceType === "fixedELock" &&
    !havePermission(currentUserDetail, "device", "viewDoorStatus")
  ) {
    const updatedDynamicHeader = boardListing?.boardData
      ?.filter((_) => !_?.group?.find((_) => _?.key === "ir_sensor"))
      ?.map((item, index) => item.columnName);
    dynamiHeader = updatedDynamicHeader;
  }

  /** Table headers and rows **/
  const tableHeader = [
    "#",
    ...dynamiHeader,
    currentUserDetail && currentUserDetail?.adminSpecificPermissions
      ? "Port"
      : null,
    "Actions",
  ];

  const tableRows = allDevices?.map((item, index) => {
    let items = [];
    items.push(
      <Typography sx={{ ...textClasses.normalText, textAlign: "center" }}>
        {(filter?.pageNumber - 1) * filter?.limit + index + 1}
      </Typography>
    );
    let rowKeysArr = boardListing?.boardData || [];

    if (
      filter?.filter &&
      filter?.filter?.deviceType &&
      filter?.filter?.deviceType === "fixedELock" &&
      !havePermission(currentUserDetail, "device", "viewDoorStatus")
    ) {
      if (
        rowKeysArr?.find((_) => _?.group?.find((_) => _?.key === "ir_sensor"))
      ) {
        const updatedKeysArray = rowKeysArr?.filter(
          (_) => !_?.group?.find((_) => _?.key === "ir_sensor")
        );
        rowKeysArr = updatedKeysArray;
      }
    }

    for (let obj of rowKeysArr) {
      let eachColStr = "";
      let colKey = "";

      for (let ob of obj.group) {
        colKey = ob.key;
        const deviceType =
          filter?.filter && filter?.filter?.deviceType
            ? filter?.filter?.deviceType
            : "";

        let tmpInnerStr = main(
          ob.fnc,
          ob.key,
          ob.label,
          item,
          index,
          setTableIndex,
          dispatch,
          fetchedAddress,
          tableIndex,
          setAdditionalInfoModal,
          SetDeviceId,
          history,
          deviceStatusGraphData,
          handlePasscodes,
          passcodeValue,
          setPasscodeValue,
          deviceType,
          currentUserDetail,
          passcodeLoader,
          passcodeIndex,
          setPasscodeIndex,
          handleSaveFiltersForStore
        );
        eachColStr += tmpInnerStr + "<br/>";
      }

      if (colKey === "device_status") {
        items.push(
          statusActionIndex === index ? (
            <Box display="flex" alignItems="center" minWidth="200px">
              <Box width="100%">
                <FormControl
                  variant="outlined"
                  size="small"
                  fullWidth
                  sx={selectStylesOverride(isDarkThemeEnabledSelector)}
                >
                  <Autocomplete
                    size="small"
                    options={
                      currentUserDetail?.adminSpecificPermissions
                        ? statusOptions
                        : statusOptions.filter((option) => {
                            return (
                              option.label === "Request for Servicing/Repairing"
                            );
                          })
                    }
                    renderInput={(params) => (
                      <TextField
                        sx={{ ...inputClasses.textField }}
                        {...params}
                      />
                    )}
                    onChange={(e, newVal) => {
                      setStatusValue(newVal);
                    }}
                    renderOption={(props, option) => (
                      <li {...props} style={{ fontSize: "13px" }}>
                        {option?.label}
                      </li>
                    )}
                    getOptionLabel={(option) => option?.label}
                    value={statusValue}
                  />
                </FormControl>
              </Box>
              <IconButton
                size="small"
                sx={{ color: themeType.default.themeOrange }}
                onClick={handleUpdateDeviceStatus}
              >
                <Check fontSize="inherit" />
              </IconButton>
              <IconButton
                size="small"
                sx={{ color: themeType.default.themeOrange }}
                onClick={handleCloseStatusAction}
              >
                <Close fontSize="inherit" />
              </IconButton>
            </Box>
          ) : (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography
                sx={{
                  ...textClasses.normalText,
                  cursor: "pointer",
                  borderRadius: "8px",
                  padding: "8px",
                  border:
                    item[colKey] === "DISABLE"
                      ? "0.5px solid #808080"
                      : item[colKey] === "REQUEST_FOR_REPAIRING" ||
                        item[colKey] === "UNDER_REPAIRING" ||
                        item[colKey] === "REPAIRED"
                      ? "0.5px solid  #FF0000"
                      : "#fff",
                  background:
                    item[colKey] === "DISABLE"
                      ? "#F0F0F0"
                      : item[colKey] === "REQUEST_FOR_REPAIRING" ||
                        item[colKey] === "UNDER_REPAIRING" ||
                        item[colKey] === "REPAIRED"
                      ? "#f6e8e7"
                      : "#fff",
                }}
              >
                {capitalizeSentence(
                  item[colKey] === "REQUEST_FOR_REPAIRING"
                    ? "Request For Repairing"
                    : item[colKey] === "UNDER_REPAIRING"
                    ? "Under Repairing"
                    : item[colKey]
                )}
              </Typography>
              <IconButton
                size="small"
                onClick={(e) => handleOpenStatusAction(e, index)}
              >
                <Create fontSize="inherit" />
              </IconButton>
            </Box>
          )
        );
      } else {
        items.push(
          <Typography
            sx={{ ...textClasses.normalText, textAlign: "center" }}
            dangerouslySetInnerHTML={{ __html: eachColStr }}
          />
        );
      }
    }

    if (currentUserDetail && currentUserDetail?.adminSpecificPermissions) {
      items.push(
        <Typography sx={{ ...textClasses.normalText, textAlign: "center" }}>
          {item["socket_port"] || "-"}
        </Typography>
      );
    }

    items.push(
      <Typography
        sx={{
          ...textClasses.normalText,
          color: themeType.default.themeOrange,
          cursor: "pointer",
        }}
        onClick={(e) => handleOpenAction(e, index)}
      >
        Action <KeyboardArrowDown />
      </Typography>
    );
    return items;
  });

  /** Effects **/
  useEffect(() => {
    setKeysArray(null);
    setPasscodeValue(null);
    setShowPasscodeOTPModal(false);
    setShowFixedLockPasscodeModal(false);
    setModalLoading(false);
    setStatusActionIndex(null);
    setStatusValue(null);
    dispatch(updateDeviceFlag(false));
    dispatch(
      getAllDeviceCategoryNames({ selection: ["label", "category"] }, (res) => {
        if (res && res?.success) {
          setReportDeviceTypeOptions(res?.data || []);
        }
      })
    );
  }, []);

  useEffect(() => {
    if (currentUserDetail) {
      setReportFields((prevState) => ({
        ...prevState,
        email: [currentUserDetail?.email],
      }));

      if (currentUserDetail?.adminSpecificPermissions) {
        dispatch(getAllOrganizations());
        // dispatch(getAllUsers({ selection: ["name", "orgName"] }));
      } else {
        dispatch(getAllAdmins());
      }
    }
  }, [currentUserDetail]);

  useEffect(() => {
    if (!deviceListFlag && allDeviceCategoryNames && currentUserDetail) {
      if (
        devicesFilters &&
        previousRoute &&
        showSavedFilters
        // (location.state?.from === "device-map-page" ||
        //   location.state?.from === "create-device-page")
      ) {
        const tempFilters = devicesFilters;
        setFilter({ ...tempFilters });
        setFinalFilter({ ...tempFilters });
      } else if (allFilters && allFilters?.devicesFilter) {
        const devicesFilter =
          typeof allFilters?.devicesFilter === "string"
            ? JSON.parse(allFilters?.devicesFilter)
            : allFilters?.devicesFilter;
        setFilter((prev) => ({
          ...prev,
          filter: devicesFilter,
        }));
        setFinalFilter((prev) => ({
          ...prev,
          filter: devicesFilter,
        }));

        if(devicesFilter?.deviceType){
          setReportFields((prev) => ({
            ...prev,
            deviceType: devicesFilter?.deviceType,
          }));
        }
      } else {
        setFilter((prev) => ({
          ...prev,
          filter: {
            deviceType: currentUserDetail?.adminSpecificPermissions
              ? "elock"
              : allDeviceCategoryNames[0]
              ? allDeviceCategoryNames[0]?.category
              : "",
          },
        }));
        setFinalFilter((prev) => ({
          ...prev,
          filter: {
            deviceType: currentUserDetail?.adminSpecificPermissions
              ? "elock"
              : allDeviceCategoryNames[0]
              ? allDeviceCategoryNames[0]?.category
              : "",
          },
        }));
        setReportFields((prev) => ({
          ...prev,
          deviceType: currentUserDetail?.adminSpecificPermissions
          ? "elock"
          : allDeviceCategoryNames[0]
          ? allDeviceCategoryNames[0]?.category
          : "",
        }));
      }
    }
  }, [
    allDeviceCategoryNames,
    currentUserDetail,
    allFilters,
    devicesFilters,
    location,
  ]);

  useEffect(() => {
    if (
      (filter?.filter?.deviceType || filter?.filter?.deviceType == "") &&
      allDeviceCategoryNames
    ) {
      if (filter?.filter?.deviceType === "fixedTracker") {
        setDStatusFilterOptions([
          { label: "Idle", value: "IDLE" },
          { label: "Moving", value: "MOTION" },
          { label: "Offline", value: "OFFLINE" },
          { label: "Ignition Off", value: "IGNITION_OFF" },
        ]);
      } else {
        setDStatusFilterOptions([
          { label: "Idle", value: "IDLE" },
          { label: "Moving", value: "MOTION" },
          { label: "Offline", value: "OFFLINE" },
        ]);
      }
      currentUserDetail?.adminSpecificPermissions &&
        fetchModels(filter?.filter?.deviceType);
      if (allDeviceCategoryNames?.length) {
        setBoardLoader(true);
        const module = allDeviceCategoryNames?.find(
          (_) => _?.category === filter?.filter?.deviceType
        )?.label;
        if (module && module?.length > 0) {
          dispatch(
            getBoards(
              {
                module: module,
              },
              (keys) => {
                if (keys && keys?.length > 0) {
                  let selectionKeys = keys;
                  if (
                    keys &&
                    keys?.length > 0 &&
                    keys?.includes("[latitude,longitude]")
                  ) {
                    const temp = selectionKeys?.filter(
                      (_) => _ !== "[latitude,longitude]"
                    );
                    const newKeys = [...temp, "latitude", "longitude"];
                    selectionKeys = newKeys;
                  }
                  if (
                    keys &&
                    keys?.length > 0 &&
                    !keys?.includes("ignition_status")
                  ) {
                    const newKeys = [...selectionKeys, "ignition_status"];
                    selectionKeys = newKeys;
                  }
                  setKeysArray(selectionKeys);
                } else {
                  setKeysArray([]);
                  // handleFetchAllDevices(null);
                }
                setBoardLoader(false);
              }
            )
          );
        }
      }

      return () => dispatch(flushAllDevices());
    }
  }, [filter?.filter?.deviceType, allDeviceCategoryNames]);

  useEffect(() => {
    if (keysArray !== null) {
      handleFetchAllDevices();
    }
  }, [keysArray]);

  useEffect(() => {
    let timeoutId;

    if (filter && filter?.q && filter?.q?.length > 3) {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(debounceSearch, 700);
      return () => {
        clearTimeout(timeoutId);
      };
    }

    if (filter?.q === "") {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(debounceSearch, 700);
      return () => {
        clearTimeout(timeoutId);
      };
    }

    if (filter && filter?.q && filter?.q?.length < 3) {
      return;
    }
  }, [filter?.q]);

  useEffect(() => {
    if (
      deviceListFlag &&
      (filter?.limit || filter?.pageNumber || filter?.filter?.deviceModel)
    ) {
      handleFetchAllDevices();
    }
  }, [filter?.limit, filter?.pageNumber, filter?.filter?.deviceModel]);

  useEffect(() => {
    if (allDevices) {
      setTableData(allDevices);
    }
  }, [allDevices]);

  const _handleChange = (e, newVal, item) => {
    if (item === "email") {
      const emailsString = e.target.value;
      let emailsArray = [];
      if (emailsString) {
        if (emailsString?.includes(",")) {
          emailsArray = emailsString.split(",").map((email) => email.trim());
        } else {
          emailsArray = [emailsString];
        }
      }

      // const emailsArray = emailsString.split(",").map((email) => email.trim());
      setReportFields((prevState) => ({
        ...prevState,
        email: emailsArray,
      }));
    }
  };

  const sendReport = () => {
    if (reportType === "currentStatus") {
      let payload = {
        deviceType: reportFields?.deviceType,
        userId: reportFields.userId,
        email: reportFields.email,
      };
      setReportLoader(true);
      dispatch(
        currentStatusReport(
          {
            ...payload,
            ...(currentUserDetail.adminSpecificPermissions
              ? { orgId: filter?.filter?.orgId }
              : {}),
          },
          () => {
            setReportLoader(false);
          }
        )
      );
    } else if (reportType === "lockUnlock") {
      let payload = {
        deviceType: reportFields?.deviceType,
        userId: reportFields.userId,
        email: reportFields.email,
        fromDate: reportFields.fromDate,
        toDate: reportFields.toDate,
      };
      setReportLoader(true);
      dispatch(
        deviceEvents(
          {
            ...payload,
            ...(currentUserDetail.adminSpecificPermissions
              ? { orgId: filter?.filter?.orgId }
              : {}),
          },
          () => {
            setReportLoader(false);
          }
        )
      );
    } else if (reportType === "tripEvent") {
      let payload = {
        deviceType: reportFields?.deviceType,
        userId: reportFields.userId,
        email: reportFields.email,
        fromDate: reportFields.fromDate,
        toDate: reportFields.toDate,
        deviceId: reportFields.deviceId,
      };

      setReportLoader(true);
      dispatch(
        deviceWiseEvents(
          {
            ...payload,
            ...(currentUserDetail.adminSpecificPermissions
              ? { orgId: filter?.filter?.orgId }
              : {}),
          },
          () => {
            setReportLoader(false);
          }
        )
      );
    }
  };

  useEffect(() => {
    if (message && reportLoader) {
      showToast(message, false);
      setReportLoader(false);
    }
    if (error && reportLoader) {
      setReportLoader(false);
      showToast(error, true);
    }
  }, [message, error]);

  const shareDevice = () => {
    let temp = allDevices[tableIndex];
    setShareDeviceModal(true);
    setDeviceShareObj(temp);
    setActionIndex(null);
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const scrollDiv = document.getElementById("scrollDiv");
      const scrollTable = document.getElementById("scrollTableDevice");

      if (scrollPosition > 0 && isVisible && scrollDiv && scrollTable) {
        setIsVisible(false);
        scrollDiv.style.top = `-${scrollDiv.offsetHeight}px`;
        scrollTable.style.marginTop = "10";
      } else if (
        scrollPosition === 0 &&
        !isVisible &&
        scrollDiv &&
        scrollTable
      ) {
        setIsVisible(true);
        scrollDiv.style.top = "10";
        scrollTable.style.marginTop = `${scrollDiv.offsetHeight}px`;
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isVisible]);

  return (
    <FiltersWrapper
      handleDrawerToggle={handleDrawerToggle}
      open={open}
      filters={filterDropdowns}
      isDarkThemeEnabledSelector={isDarkThemeEnabledSelector}
      selectStylesOverride={selectStylesOverride(isDarkThemeEnabledSelector)}
      themeType={themeType}
      handleApplyFilters={handleApplyFilters}
      reportModal={reportModal}
      saveFilters={handleSaveFilters}
      defaultFiltersExists={
        allFilters && allFilters?.devicesFilter ? true : false
      }
      searchActions={{
        handleSearchUsers,
        handleSearchDeviceModels,
        handleSearchDeviceType,
        searchFields,
        setSearchFields,
      }}
      searchElement={
        <Grid container spacing={2} mt={0}>
          {/* search input */}
          <Grid
            item
            md={12}
            ml={2}
            mr={2}
            style={{ width: "100%" }}
            data-testid="search-input-container"
          >
            <FormControl
              variant="outlined"
              size="small"
              fullWidth
              sx={selectStylesOverride(isDarkThemeEnabledSelector)}
            >
              <InputLabel sx={{ fontSize: "12px" }}>
                Search by device
              </InputLabel>
              <OutlinedInput
                sx={{ ...inputClasses.filterField, height: "36px" }}
                placeholder="Search by device"
                label="Search by device"
                value={filter?.q}
                endAdornment={
                  <InputAdornment position="end" onClick={handleApplyFilters}>
                    <IconButton size="small">
                      <Search fontSize="small" />
                    </IconButton>
                  </InputAdornment>
                }
                data-testid="search-input-filter"
                onChange={(e) => {
                  setFilter((v) => ({
                    ...v,
                    q: e.target.value.trimStart().replace(/\s{2,}/g, " "),
                    pageNumber: 1,
                    limit: 10,
                  }));
                }}
              />
            </FormControl>
          </Grid>
          {mandatoryFilters?.map((dropdownFilter) => (
            <Grid item md={12} ml={2} mr={2} style={{ width: "100%" }}>
              <FormControl
                variant="outlined"
                size="small"
                fullWidth
                sx={selectStylesOverride(isDarkThemeEnabledSelector)}
              >
                <Autocomplete
                  size="small"
                  options={dropdownFilter?.options ?? []}
                  disabled={dropdownFilter?.disabled}
                  disableClearable={
                    dropdownFilter?.disableClearable ? true : false
                  }
                  renderInput={(params) => (
                    <TextField
                      sx={{ ...inputClasses.filterField }}
                      {...params}
                      label={
                        <Typography
                          sx={
                            isDarkThemeEnabledSelector
                              ? {
                                  fontSize: "12px",
                                  color: "#808080",
                                  paddingTop: "1px",
                                }
                              : { fontSize: "12px", paddingTop: "1px" }
                          }
                        >
                          {dropdownFilter?.name}
                        </Typography>
                      }
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {dropdownFilter?.searchValue &&
                            dropdownFilter?.searchValue?.length > 0 ? (
                              <IconButton
                                size="small"
                                color=""
                                onClick={
                                  dropdownFilter?.onSearch &&
                                  dropdownFilter?.onSearch
                                }
                              >
                                <Search fontSize="12px" />
                              </IconButton>
                            ) : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                        disableUnderline: true,
                      }}
                    />
                  )}
                  filterOptions={
                    dropdownFilter?.searchValue ? (option) => option : undefined
                  }
                  renderOption={(props, option) => (
                    <li {...props} style={{ fontSize: "13px" }}>
                      {option[dropdownFilter?.labelKey ?? "label"]}
                    </li>
                  )}
                  clearOnBlur={dropdownFilter?.searchValue && false}
                  onChange={(e, newVal) =>
                    dropdownFilter?.onChange
                      ? dropdownFilter?.onChange(newVal)
                      : null
                  }
                  onInputChange={(e) =>
                    dropdownFilter?.onInputChange
                      ? dropdownFilter?.onInputChange(e)
                      : null
                  }
                  getOptionLabel={(option) => {
                    if (typeof option === "string") {
                      return dropdownFilter?.options?.length > 0
                        ? dropdownFilter?.options?.find(
                            (_) => _[dropdownFilter?.valueKey] === option
                          )
                          ? dropdownFilter?.options?.find(
                              (_) => _[dropdownFilter?.valueKey] === option
                            )[dropdownFilter?.labelKey]
                          : ""
                        : "";
                    } else {
                      return dropdownFilter?.value
                        ? option[dropdownFilter?.labelKey ?? "label"]
                        : "";
                    }
                  }}
                  value={dropdownFilter?.value || null}
                  // defaultValue={null}
                />
              </FormControl>
            </Grid>
          ))}
        </Grid>
      }
      reportActions={
        <Grid container>
          <Grid item md={12} ml={2} mr={2} style={{ width: "100%" }}>
            <FormControl sx={{ ...selectStylesOverride, display: "flex" }}>
              <CustomSelect
                selectStylesOverride={selectStylesOverride(
                  isDarkThemeEnabledSelector
                )}
                key={`reports`}
                label="Reports"
                sx={{ ...inputClasses.filterField }}
                // value={item?.route || null}
                onChange={(e, newVal) => {
                  if (newVal) {
                    setReportType(newVal.value);
                    dispatch(getAllUsers({ selection: ["name", "orgName"] }));
                    // dispatch(getAllAdmins());
                  } else {
                    setReportType("");
                  }
                }}
                errors={errors}
                getOptionLabel={(option) => {
                  return option?.label || "";
                }}
                labelKey="label"
                filterSelectedOptions
                options={reportTypes && reportTypes}
              />
            </FormControl>
          </Grid>

          <Grid item md={12} ml={2} mr={2} mt={2} style={{ width: "100%" }}>
            <FormControl sx={{ ...selectStylesOverride, display: "flex" }}>
              <CustomSelect
                selectStylesOverride={selectStylesOverride(
                  isDarkThemeEnabledSelector
                )}
                key={`deviceType`}
                label="Device Type"
                sx={{ ...inputClasses.filterField }}
                value={reportFields?.deviceType || null}
                searchValue={searchFields?.reportDeviceType || ""}
                onSearch={handleSearchReportDeviceType}
                onInputChange={(e) => {
                  if (e?.type === "change") {
                    if (e?.target?.value === "")
                      handleSearchReportDeviceType(true);
                    setSearchFields((prev) => ({
                      ...prev,
                      reportDeviceType: e?.target?.value?.trim(),
                    }));
                  }
                }}
                onChange={(e, newVal, reason) => {
                  if (reason === "clear") handleSearchReportDeviceType(true);
                  if (newVal) {
                    setReportFields((prev) => ({
                      ...prev,
                      deviceType: newVal?.category,
                    }));
                  } else {
                    setReportFields((prev) => ({
                      ...prev,
                      deviceType: "",
                    }));
                  }
                }}
                filterOptions={(options) => options}
                errors={errors}
                getOptionLabel={(option) => {
                  if (typeof option === "string") {
                    return reportDeviceTypeOptions?.find(
                      (_) => _?.category == reportFields?.deviceType
                    )?.label || "";
                  } else {
                    return option?.label || "";
                  }
                }}
                labelKey="label"
                options={reportDeviceTypeOptions || []}
              />
            </FormControl>
          </Grid>

          {reportType === "lockUnlock" || reportType === "tripEvent" ? (
            <>
              <Grid item md={12} ml={2} mt={2} mr={2} style={{ width: "100%" }}>
                <FormControl
                  sx={{
                    ...selectStylesOverride(isDarkThemeEnabledSelector),
                    width: "100%",
                  }}
                >
                  <DateTimePicker
                    label="From"
                    onChange={(date) => {
                      if (date) {
                        setReportFields((prev) => ({
                          ...prev,
                          fromDate: moment(date).format("DD-MM-YYYY hh:mm A"),
                        }));
                      } else {
                        let temp = { ...reportFields };
                        temp.fromDate = "";
                        setReportFields(temp);
                      }
                    }}
                    format="dd-MM-yyyy hh:mm a"
                    className="customDatePicker"
                    sx={{
                      width: "100%",
                      ...selectStylesOverride,
                    }}
                    slotProps={dateTimePickerSlotProps(
                      inputClasses,
                      isDarkThemeEnabledSelector
                    )}
                  />
                </FormControl>
              </Grid>

              <Grid item mt={2} md={12} ml={2} mr={2} style={{ width: "100%" }}>
                <FormControl
                  sx={{
                    ...selectStylesOverride(isDarkThemeEnabledSelector),
                    width: "100%",
                  }}
                >
                  <DateTimePicker
                    label="To"
                    // value={new Date(scheduleData?.routeSource?.STD)}
                    onChange={(date) => {
                      if (date) {
                        setReportFields((prev) => ({
                          ...prev,
                          toDate: moment(date).format("DD-MM-YYYY hh:mm A"),
                        }));
                      } else {
                        let temp = { ...reportFields };
                        temp.toDate = "";
                        setReportFields(temp);
                      }
                    }}
                    format="dd-MM-yyyy hh:mm a"
                    className="customDatePicker"
                    sx={{
                      width: "100%",
                      ...selectStylesOverride,
                    }}
                    slotProps={dateTimePickerSlotProps(
                      inputClasses,
                      isDarkThemeEnabledSelector
                    )}
                  />
                </FormControl>
              </Grid>
            </>
          ) : null}

          {/* <Grid item md={12} mt={2} ml={2} mr={2} style={{ width: '100%' }}>
            <Button
              variant="contained"
              sx={{
                ...buttonClasses?.lynkitOrangeFill,
                minHeight: "36px",
                width: '100%'
              }}
              onClick={() => dispatch(getAllAdmins())}
              disabled={reportType ? false : true}
            >
              Apply Filters
            </Button>
          </Grid> */}
          {reportType === "tripEvent" &&
          adminLists &&
          adminLists?.userDeviceData?.length ? (
            <Grid container mt={2}>
              <Grid item md={12} ml={2} mr={2} style={{ width: "100%" }}>
                <FormControl
                  variant="outlined"
                  size="small"
                  fullWidth
                  sx={selectStylesOverride(isDarkThemeEnabledSelector)}
                >
                  <OutlinedInput
                    data-testid="searchelement"
                    sx={{ ...inputClasses.filterField, height: "36px" }}
                    placeholder="Search by Device ID."
                    // value={filter?.regex || ""}
                    onChange={(e) =>
                      setReportFields({
                        ...reportFields,
                        deviceId: e.target.value,
                      })
                    }
                    endAdornment={
                      <InputAdornment position="end">
                        <Search fontSize="small" />
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </Grid>
            </Grid>
          ) : null}

          {adminLists && adminLists?.userDeviceData?.length && reportType ? (
            <Grid item md={12} ml={2} mt={2} mr={2} style={{ width: "100%" }}>
              <FormControl sx={{ ...selectStylesOverride, display: "flex" }}>
                <CustomSelect
                  selectStylesOverride={selectStylesOverride(
                    isDarkThemeEnabledSelector
                  )}
                  key={`users`}
                  label="Filter by User"
                  sx={{ ...inputClasses.filterField }}
                  onChange={(e, newVal) => {
                    let temp = { ...reportFields };
                    if (newVal) {
                      // temp.userId = newVal?.userId;
                      temp.userId = newVal?._id;
                      setReportFields(temp);
                    } else {
                      temp.userId = "";
                      setReportFields(temp);
                    }
                  }}
                  errors={errors}
                  getOptionLabel={(option) => {
                    return option?.name || "";
                  }}
                  labelKey="name"
                  filterSelectedOptions
                  searchValue={
                    searchFields?.users && searchFields?.users !== ""
                      ? searchFields?.users
                      : null
                  }
                  onSearch={handleSearchUsers}
                  onInputChange={(e) => {
                    if (e?.type === "change") {
                      setSearchFields((prev) => ({
                        ...prev,
                        users: e?.target?.value?.trim(),
                      }));
                    }
                  }}
                  // options={adminLists.userDeviceData && adminLists.userDeviceData}
                  options={
                    // adminLists?.userDeviceData
                    //   ? adminLists.userDeviceData.map((ele) => ({
                    //       orgName: `${ele._id}-${ele.orgName}`,
                    //       userId: ele.userId,
                    //     }))
                    //   : []
                    allUsers?.length > 0
                      ? allUsers.map((ele) => ({
                          name: `${ele.name}-${ele.orgName}`,
                          _id: ele._id,
                        }))
                      : []
                  }
                />
              </FormControl>
            </Grid>
          ) : null}

          {reportType ? (
            <>
              <Grid container item md={12} ml={2} mr={2} mt={2}>
                <FormControl sx={{ ...selectStylesOverride, width: "100%" }}>
                  <CustomInput
                    placeholder="Email Id"
                    label="Email Id(s)"
                    data-testid="email-ids"
                    required={true}
                    sx={{ ...inputClasses.filterField }}
                    selectStylesOverride={selectStylesOverride(
                      isDarkThemeEnabledSelector
                    )}
                    inputClasses={inputClasses}
                    errors={errors}
                    value={reportFields?.email}
                    onChange={(e, newVal) => {
                      _handleChange(e, newVal, "email");
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item md={12} ml={2} mr={2} mt={1}>
                <Typography sx={{ ...textClasses.t12n }}>
                  Add multiple email ids by comma <b>( , )</b> separated.
                </Typography>
              </Grid>
            </>
          ) : null}

          <Button
            sx={{
              ...buttonClasses.lynkitBlackFill,
              minHeight: "36px",
              margin: 2,
              width: "100%",
              mt: 2,
              mb: 0,
            }}
            // disabled={filter.hasOwnProperty('fromDate') && filter.hasOwnProperty('toDate') ? false : true}
            onClick={() => {
              sendReport();
            }}
          >
            Send
          </Button>
        </Grid>
      }
    >
      <Grid container>
        {/* <Grid xs={12}>
          <Card sx={{paddingLeft: 3}}>
            <DoughnutChart />
          </Card>
        </Grid> */}
        {/* Page title & Action button */}
        <Grid item={true} sm={12} xs={12}>
          <Grid container>
            <Grid item={true} sm={6} xs={4}>
              <Typography data-testid="title" sx={{ ...textClasses.cardTitle }}>
                Devices
              </Typography>
            </Grid>

            <Grid
              container
              item={true}
              sm={6}
              xs={6}
              sx={{ justifyContent: "flex-end" }}
            >
              <Grid
                item={true}
                sm={12}
                xs={6}
                sx={{
                  displey: "flex",
                  display: "flex",
                  justifyContent: "flex-end",
                  height: "36px",
                }}
              >
                {/* {finalFilter?.filter && Object.keys(finalFilter?.filter)?.length && (filter?.filter?.deviceType !== 'fixedELock') ? */}

                {filter?.filter?.deviceType !== "fixedELock" ||
                filter?.q !== "" ||
                filter?.filter["user"] ||
                filter?.filter["deviceStatus"] ||
                filter?.filter["status"] ? (
                  <Button variant="text" data-testid="clear-filters">
                    <Typography
                      sx={{
                        ...textClasses.boldText,
                        color: themeType.default.themeOrange,
                        textDecoration: "underline",
                        cursor: "pointer",
                        textTransform: "none",
                        marginRight: "7px",
                      }}
                      onClick={handleClearAllFilters}
                    >
                      Clear All Filter(s)
                    </Typography>
                  </Button>
                ) : null}

                <Grid>
                  {/* Filters button ------- */}
                  {!isVisible ? (
                    <Button
                      variant="contained"
                      sx={{
                        ...buttonClasses?.lynkitOrangeEmpty,
                        minHeight: "36px",
                        marginRight: "10px",
                      }}
                      onClick={() => setIsVisible(true)}
                    >
                      Show Charts
                    </Button>
                  ) : null}
                  {!open && (
                    <Button
                      variant="contained"
                      sx={{
                        ...buttonClasses?.lynkitOrangeEmpty,
                        minHeight: "36px",
                        marginRight: "10px",
                      }}
                      onClick={() => {
                        setReportModal(true);
                        setOpen(!open);
                      }}
                      startIcon={<DownloadIcon />}
                      data-testid="filter-btn"
                    >
                      Reports
                    </Button>
                  )}
                  {!open && (
                    <Button
                      variant="contained"
                      sx={{
                        ...buttonClasses?.lynkitOrangeEmpty,
                        minHeight: "36px",
                      }}
                      onClick={() => {
                        setReportModal(false);
                        setOpen(!open);
                      }}
                      startIcon={<FilterAlt />}
                      data-testid="filter-btn"
                    >
                      Filters
                      {finalFilter?.filter &&
                        `(${Object.keys(finalFilter?.filter)?.length})`}
                    </Button>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        {/* graph area ----- */}
        {graphLoader ? (
          <Box
            width="100%"
            justifyContent="center"
            alignItems="center"
            display="flex"
            gap={2}
            my={1}
          >
            <Box width={"30%"} height={200} display="flex">
              <Skeleton variant="circular" width="200px" height={"100%"} />
              <Box width="40%" mt={5} ml={2}>
                <Skeleton
                  variant="text"
                  width={"50%"}
                  sx={{ fontSize: "1rem" }}
                />
                <Skeleton
                  variant="text"
                  width={"50%"}
                  sx={{ fontSize: "1rem" }}
                />
                <Skeleton
                  variant="text"
                  width={"50%"}
                  sx={{ fontSize: "1rem" }}
                />
                <Skeleton
                  variant="text"
                  width={"50%"}
                  sx={{ fontSize: "1rem" }}
                />
                <Skeleton
                  variant="text"
                  width={"50%"}
                  sx={{ fontSize: "1rem" }}
                />
              </Box>
            </Box>
            <Box width={"30%"} height={200} display="flex">
              <Skeleton variant="circular" width="200px" height={"100%"} />
              <Box width="40%" mt={5} ml={2}>
                <Skeleton
                  variant="text"
                  width={"50%"}
                  sx={{ fontSize: "1rem" }}
                />
                <Skeleton
                  variant="text"
                  width={"50%"}
                  sx={{ fontSize: "1rem" }}
                />
                <Skeleton
                  variant="text"
                  width={"50%"}
                  sx={{ fontSize: "1rem" }}
                />
                <Skeleton
                  variant="text"
                  width={"50%"}
                  sx={{ fontSize: "1rem" }}
                />
                <Skeleton
                  variant="text"
                  width={"50%"}
                  sx={{ fontSize: "1rem" }}
                />
              </Box>
            </Box>
          </Box>
        ) : (
          <>
            {d3DougnutArray &&
              isVisible &&
              d3DougnutArray?.length > 0 &&
              ((filter?.filter && filter?.filter?.deviceType === "elock") ||
                filter?.filter?.deviceType === "fixedELock" ||
                filter?.filter?.deviceType === "fixedTracker") && (
                <Grid id="scrollDiv" container justifyContent="center" my={1}>
                  <DonutChart data={d3DougnutArray} />
                </Grid>
              )}
          </>
        )}

        {/* Filters */}

        {loader || boardLoader ? (
          <Grid
            container
            item={true}
            direction={"column"}
            justifyContent={"center"}
            alignItems="center"
            xs={12}
            sx={{ p: 15 }}
          >
            <Grid item>
              <img src={loaderGif} />
            </Grid>
          </Grid>
        ) : (
          <>
            {filter?.filter?.deviceType === "" ||
            (allDevices && allDevices?.length === 0) ? (
              <EmptyPage />
            ) : (
              <Grid id="scrollTableDevice" container sx={{ mt: 1.5 }}>
                <Grid item={true} sm={12} xs={12}>
                  <Paper
                    sx={{
                      height: "81vh",
                      overflow: "auto",
                      width: "100%",
                      display: "block",
                    }}
                  >
                    <DevicesTable
                      dataTestid="devices-list-table"
                      header={tableHeader}
                      rows={tableRows}
                      // tableColumns={tableColumns}
                      // data={tableData ?? []}
                      totalRecords={totalRecords}
                      pagination={filter}
                      setPagination={setFilter}
                      themeType={themeType}
                      allDevices={allDevices}
                    />
                  </Paper>
                </Grid>
              </Grid>
            )}
          </>
        )}

        {additionalInfoModal && (
          <DeviceAdditionalModal
            open={additionalInfoModal}
            hide={() => {
              setAdditionalInfoModal(false);
            }}
            deviceId={deviceId}
            refresh={handleFetchAllDevices}
          />
        )}
      </Grid>

      {actionIndex != null && (
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={actionIndex != null}
          onClose={handleCloseAction}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
          sx={{
            display:
              actionIndex != null ? "block !important" : "none !important",
          }}
        >
          {/* {(currentUserDetail &&
            havePermission(
              currentUserDetail,
              "device",
              "allowSharingDeviceTrackingLink"
            )) ||
          havePermission(
            currentUserDetail,
            "device",
            "allowSharingDeviceAlert"
          ) ? (
            <MenuItem key={2} onClick={() => shareDevice()}>
              Share
            </MenuItem>
          ) : null}  */}

          {currentUserDetail &&
          currentUserDetail?.adminSpecificPermissions &&
          havePermission(currentUserDetail, "device", "updateDevice") ? (
            <MenuItem
              key={1}
              disabled={
                currentUserDetail &&
                !currentUserDetail?.adminSpecificPermissions &&
                !havePermission(currentUserDetail, "device", "updateDevice")
              }
              onClick={() => {
                handleSaveFiltersForStore();
                history?.push(
                  `/dashboard/devices/edit-device/${tableData[tableIndex]?._id}`
                );
              }}
            >
              Edit
            </MenuItem>
          ) : null}
        </Menu>
      )}
      {shareDeviceModal && (
        <DeviceShareModal
          open={shareDeviceModal}
          hide={() => {
            setShareDeviceModal(false);
          }}
          deviceShareObj={deviceShareObj}
          currentUserDetail={currentUserDetail}
          setShareDeviceModal={setShareDeviceModal}
        />
      )}
      <OTPModal
        open={showPasscodeOTPModal}
        setOpen={setShowPasscodeOTPModal}
        themeType={themeType}
        loading={modalLoading}
        selectStylesOverride={selectStylesOverride(isDarkThemeEnabledSelector)}
        handleVerifyFn={handleVerifyOtpForPasscode}
      />
      <FixedLockPasscodeModal
        open={showFixedLockPasscodeModal}
        setOpen={setShowFixedLockPasscodeModal}
        themeType={themeType}
        loading={modalLoading}
        passcodeValue={passcodeValue ? passcodeValue?.passcode : null}
        setPasscodeValue={setPasscodeValue}
        selectStylesOverride={selectStylesOverride(isDarkThemeEnabledSelector)}
        handleVerifyFn={handleGenerateFixedLockPasscode}
      />
    </FiltersWrapper>
  );
}
