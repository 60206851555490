import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  USER_PERMISSIONS,
  SHOW_MESSAGE,
  ADD_PERMISSIONS_ROLE,
  UPDATE_PERMISSIONS_ROLE,
  USER_ROLES_COUNT,
} from "../types";
import axios from "../../services/Api";
import { showToast } from "../../services/functions";

export const getUserPermissions = (bodydata, callBackFunction) => {
  return async (dispatch) => {
    dispatch({ type: FETCH_START });
    await axios
      .post("/role/getUserPermission", bodydata)
      .then(({ data }) => {
        if (data.success) {
          dispatch({ type: FETCH_SUCCESS, payload: data.success });
          dispatch({ type: "GET_USER_PERMISSIONS", payload: data.data });
          dispatch({ type: SHOW_MESSAGE, payload: data.message });
        } else {
          dispatch({ type: FETCH_ERROR, payload: data.message });
          dispatch({ type: "GET_USER_PERMISSIONS", payload: {} });
        }
        if (callBackFunction) {
          callBackFunction({ success: data.success, message: data.message });
        }
      })
      .catch(function (error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
        dispatch({
          type: "GET_USER_PERMISSIONS",
          payload: { rows: [], count: 0 },
        });
        console.log("Error****:", error.message);
        if (callBackFunction) {
          callBackFunction({ message: error.message });
        }
      });
  };
};

export const createPermissionRole = (bodydata, callBackFunction) =>
{
    return async (dispatch) => {
        dispatch({type: FETCH_START});
        await axios.post('/addPermissionsRole', bodydata
        ).then(({data}) => {
          if (data.success) 
          {
            dispatch({type: FETCH_SUCCESS,payload: data.success});
            dispatch({ type: ADD_PERMISSIONS_ROLE, payload: data.data });
              dispatch({type: SHOW_MESSAGE, payload: data.message});
          } 

          if(callBackFunction){
            callBackFunction({success: data.success, message: data.message })
          }
        }).catch(function (error) {
          console.log("Error****:", error.message);
          showToast(error?.response?.data?.message, true);
        });
      }
};

export const updatePermissionRole = (bodydata, callBackFunction) => {
  return async (dispatch) => {
    dispatch({ type: FETCH_START });
    await axios
      .post("/updatePermissionsRole", bodydata)
      .then(({ data }) => {
        if (data.success) {
          dispatch({ type: FETCH_SUCCESS, payload: data.success });
          dispatch({ type: UPDATE_PERMISSIONS_ROLE, payload: data.data });
          dispatch({ type: SHOW_MESSAGE, payload: data.message });
        } else {
          dispatch({ type: FETCH_ERROR, payload: data.message });
          dispatch({ type: UPDATE_PERMISSIONS_ROLE, payload: {} });
        }
        if (callBackFunction) {
          callBackFunction({ success: data.success, message: data.message });
        }
      })
      .catch(function (error) {
        showToast(error?.response?.data?.message, true);
        console.log("Error****:", error.message);
      });
  };
};

export const deletePermissionRole = (bodydata, callBackFunction) => {
  return async (dispatch) => {
    dispatch({ type: FETCH_START });
    await axios
      .post("/deletePermissionsRoles", bodydata)
      .then(({ data }) => {
        if (data.success) {
          dispatch({ type: FETCH_SUCCESS, payload: data.success });
          dispatch({ type: SHOW_MESSAGE, payload: data.message });
        } else {
          dispatch({ type: FETCH_ERROR, payload: data.message });
        }
        if (callBackFunction) {
          callBackFunction({ success: data.success, message: data.message });
        }
      })
      .catch(function (error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
        console.log("Error****:", error.message);
        if (callBackFunction) {
          callBackFunction({ message: error.message });
        }
      });
  };
};

export const getPermissionRoles = (bodydata, callBackFunction) => {
  return async (dispatch) => {
    dispatch({ type: FETCH_START });
    await axios
      .post("/listPermissionsRoles", bodydata)
      .then(({ data }) => {
        if (data.success) {
          dispatch({ type: FETCH_SUCCESS, payload: data.success });
          dispatch({ type: USER_PERMISSIONS, payload: data.data });
          dispatch({ type: "USER_PERMISSIONS_COUNT", payload: data.count });
        }
        if (callBackFunction) {
          callBackFunction({ success: data.success, message: data.message });
        }
      })
      .catch(function (error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
        dispatch({ type: USER_PERMISSIONS, payload: [] });
        dispatch({ type: "USER_PERMISSIONS_COUNT", payload: 0 });
        console.log("Error****:", error.message);
        if (callBackFunction) {
          callBackFunction({ message: error.message });
        }
      });
  };
};

export const getUserRolesStatusWiseCount = (payload, cb) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    axios
      .post("/userRolesStatusWiseCount", { ...payload })
      .then(({ data }) => {
        if (data.success) {
          callback();
        } else {
          dispatch({ type: FETCH_ERROR, payload: data.message });
          dispatch({ type: USER_ROLES_COUNT, payload: {} });
          if (cb) {
            cb();
          }
        }
        function callback() {
          dispatch({ type: FETCH_SUCCESS });
          dispatch({ type: USER_ROLES_COUNT, payload: data.data });

          if (cb) {
            cb();
          }
        }
      })
      .catch(function (error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
        console.log("Error****:", error.message);
        if (cb) {
          cb();
        }
      });
  };
};
