import {
  GET_MAIN_ASSET_BY_DEVICE_ID,
  GET_MAIN_ASSET_DATA,
  GET_VENDOR_ASSET_DATA,
  SET_SELECTED_ITEM_ID,
} from "../types";

const INIT_STATE = {
  mainAsset: {
    data: [],
    isLoading: false,
    isError: false,
    count: 0,
    downloadData: [],
  },
  vendorAsset: { data: [], isLoading: false, isError: false, count: 0 },
  searchData: { data: {}, isLoading: false, isError: false },
  selectedItemId: "",
  mainLoading: false,
  vendorLoading: false,
  categoryTypeOptions: [],
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_MAIN_ASSET_BY_DEVICE_ID:
      return {
        ...state,
        searchData: {
          ...state.searchData,
          data: action?.payload,
        },
      };
    case GET_MAIN_ASSET_DATA:
      return {
        ...state,
        mainAsset: {
          ...state.mainAsset,
          downloadData: action?.isDownload ? action?.payload : [],
          data: !action?.isDownload ? action?.payload : [],
        },
      };
    case GET_VENDOR_ASSET_DATA:
      return {
        ...state,
        vendorAsset: {
          ...state.vendorAsset,
          data: action?.payload?.rows,
          count: action?.payload?.count,
        },
      };
    case "FLUSH_VENDOR_ASSET_DATA":
      return {
        ...state,
        vendorAsset: {
          ...state.vendorAsset,
          data: [],
          count: 0,
        },
      };
    case "MAIN_LOADING":
      return {
        ...state,
        mainLoading: action?.payload,
      };
    case SET_SELECTED_ITEM_ID:
      return {
        ...state,
        selectedItemId: action?.payload,
      };
    case "VENDOR_LOADING":
      return {
        ...state,
        vendorLoading: action?.payload,
      };
    case "CATEGORY_TYPES":
      return {
        ...state,
        categoryTypeOptions: action?.payload,
      };
    case "FLUSH_CATEGORY_TYPES":
      return {
        ...state,
        categoryTypeOptions: [],
      };
    default:
      return state;
  }
};

export const selectSelectedItemId = (state) =>
  state.assetManagement.selectedItemId;

export const selectMainAssetData = (state) =>
  state.assetManagement.mainAsset.data;

export const selectMainAssetDownloadData = (state) =>
  state.assetManagement.mainAsset.downloadData;

export const selectIsMainAssetLoading = (state) =>
  state.assetManagement.mainLoading;

export const selectVendorAssetData = (state) =>
  state.assetManagement.vendorAsset.data;

export const selectIsVendorAssetLoading = (state) =>
  state.assetManagement.vendorLoading;

export const selectVendorAssetCount = (state) =>
  state.assetManagement.vendorAsset.count;

export const selectSearchData = (state) =>
  state.assetManagement.mainAsset.data;

export const selectIsSearchDataLoading = (state) =>
  state.assetManagement.searchData.isLoading;
