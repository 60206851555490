import { Box, Button, IconButton, TextField } from "@mui/material";
import { selectStylesOverride } from "../../../../utils/util";
import React from "react";
import { useSelector } from "react-redux";

const EmailInput = ({state, setState, isDarkThemeEnabledSelector, themeType, isValid, isInList}) => {
  const { inputClasses } = themeType;

  const btnStyle = {
    border: "none",
    boxShadow: "none",
    color: themeType?.default?.themeOrange,
    height: "18px",
    textAlign: "center",
    marginLeft: "3px",
    backgroundColor: "transparent",
    fontSize: "14px"
  }

  const handleKeyDown = (evt) => {
    if (["Enter", "Tab", ","].includes(evt.key)) {
      evt.preventDefault();

      if (state?.value) {
        let value = state?.value?.trim();
        let itemsTemp = state?.items;

        if (value && isValid(value)) {
          itemsTemp?.push(state?.value);
          setState((prev) => ({
            ...prev,
            items: itemsTemp,
            value: "",
          }));
        }
      }
    }
  };

  const handleChange = (evt) => {
    setState((prev) => ({
      ...prev,
      value: evt?.target?.value,
      error: null,
    }));
  };

  const handleDelete = (item) => {
    setState((prev) => ({
      ...prev,
      items: state?.items?.filter((i) => i !== item),
    }));
  };

  const handlePaste = (evt) => {
    evt.preventDefault();

    var paste = evt?.clipboardData?.getData("text");
    var emails = paste?.match(/[\w\d\.-]+@[\w\d\.-]+\.[\w\d\.-]+/g);

    if (emails) {
      var toBeAdded = emails?.filter((email) => !isInList(email));

      setState((prev) => ({
        ...prev,
        items: [...state?.items, ...toBeAdded],
      }));
    }
  };


  return (
    <>
      {state?.items && state?.items?.length > 0 && (
        <Box mb={1} sx={{ display: "flex", alignItems: "center", gap: "6px", flexWrap: "wrap" }}>
          {state?.items?.map((item) => (
            <Box
              borderRadius="20px"
              bgcolor={themeType?.default?.themeGray}
              px={1} 
              key={item}
              sx={{fontSize: "14px"}}
            >
              {item}
              <button
                type="button"
                className="button"
                style={btnStyle}
                onClick={() => handleDelete(item)}
              >
                &times;
              </button>
            </Box>
          ))}
        </Box>
      )}

      <TextField
        size="small"
        role="textbox"
        value={state?.value}
        placeholder="Type or paste email addresses and press ENTER"
        onKeyDown={handleKeyDown}
        onChange={handleChange}
        onPaste={handlePaste}
        fullWidth
        sx={{
          ...inputClasses?.textField,
          ...selectStylesOverride(isDarkThemeEnabledSelector),
          height: "36px",
        }}
        inputProps={{
          style: {
            padding: "8px 11px",
          },
        }}
        error={state.error ? true : false}
      />
      {state.error && (
        <span role="alert" style={{ color: "#FF0101", fontSize: 11 }}>
          {state.error}
        </span>
      )}
    </>
  );
};

export default EmailInput;
