import React from "react";
import FiltersWrapper from "../../Components/FiltersWrapper/FiltersWrapper";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Breadcrumbs,
  Button,
  FormControl,
  Grid,
  InputAdornment,
  Menu,
  MenuItem,
  OutlinedInput,
  Paper,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { Link } from "react-router-dom/cjs/react-router-dom";
import { selectStylesOverride } from "../../../utils/util";
import loaderGif from "../../../static/images/drawer/loader.gif";
import DataTable from "../../Components/DataTable/DataTable";
import { Add, FilterAlt, KeyboardArrowDown, Search } from "@mui/icons-material";
import { havePermission } from "../../../utils/permissionCheck";
import history from "../../../services/history";
import {
  getAllRoutesForTrips,
  getAllTripSchedules,
  getTripScheduleCount,
  updateScheduleTrip,
} from "../../../redux/actions/Trips";
import { convertIntoGivenTimezone } from "../../../services/functions";
import UpdateTripScheduleStatus from "./components/UpdateTripScheduleStatus";
import { setTripScheduleListFlag } from "../../../redux/reducers/TripsReducer";
import { getAllOrganizations, getBoards } from "../../../redux/actions";
import EmptyPage from "../EmptyPage";
import { main } from "./tripSchedule.helper";

const TripSchedule = () => {
  const dispatch = useDispatch();

  /* ---- LOCAL STATES ---- */
  const [openFilters, setOpenFilters] = React.useState(false);
  const [filter, setFilter] = React.useState({
    page: 1,
    limit: 10,
    searchValue: "",
  });
  const [searchFields, setSearchFields] = React.useState({
    org: null,
    route: null,
  });
  const [selectedTab, setSelectedTab] = React.useState(0);
  const [comment, setComment] = React.useState(""); //for enable/disable/delete
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [actionIndex, setActionIndex] = React.useState(null);
  const [keysArray, setKeysArray] = React.useState(null);
  const [tableIndex, setTableIndex] = React.useState(null);
  const [actionStatus, setActionStatus] = React.useState(null); // for updating status
  const [selectedTemplates, setSelectedTemplates] = React.useState([]); // for updating status
  const [showDeleteModal, setShowDeleteModal] = React.useState(false); // for updating status

  /* ---- SELECTORS ---- */
  const { currentUserDetail } = useSelector((state) => state.auth);
  const boardListing = useSelector(
    (state) => state.dynamicDashboard.boardsListing[0]
  );
  const { allUsers, allOrganizations } = useSelector((state) => state.userlist);
  const {
    tripScheduleList,
    tripScheduleLoading,
    totalTripSchedule,
    routeListForTrips,
    tripScheduleCount,
    tripScheduleListFlag,
    updateTripScheduleLoading,
  } = useSelector((state) => state.tripsData);
  const isDarkThemeEnabledSelector = useSelector(
    (state) => state.theme.themeDark
  );

  const themeType = isDarkThemeEnabledSelector
    ? require("../../../static/styles/darktheme")
    : require("../../../static/styles/theme");

  const { textClasses, buttonClasses, inputClasses } = themeType;

  const routesListing = React.useMemo(() => {
    if (routeListForTrips) {
      return routeListForTrips?.routes || [];
    }
  }, [routeListForTrips]);

  /* ---- HANDLERS ---- */
  const handleTabsChange = (_, value) => {
    setSelectedTab(value);
    let tempFilters = filter;
    if (tripScheduleList && tabs[value]?.key === "active") {
      setFilter((prev) => ({
        ...prev,
        status: false,
      }));
      tempFilters.status = false;
    } else if (tripScheduleList && tabs[value]?.key === "disable") {
      setFilter((prev) => ({
        ...prev,
        status: true,
      }));
      tempFilters.status = true;
    } else if (tripScheduleList && tabs[value]?.key === "all") {
      let temp = filter;
      delete temp["status"];
      setFilter({ ...temp });
      delete tempFilters["status"];
    }

    let modifiedFilters = tempFilters;
    if (tempFilters?.route && tempFilters?.route?._id) {
      modifiedFilters.route = tempFilters?.route?._id;
    }
    dispatch(
      getAllTripSchedules({ ...modifiedFilters }, (res) => {
        if (res && res?.success) {
          setTripScheduleListFlag(true);
        }
      })
    );
  };

  const handleOpenAction = (event, key) => {
    setAnchorEl(event.currentTarget);
    setActionIndex(key);
    setTableIndex(key);
  };

  const handleCloseAction = () => {
    setActionIndex(null);
    setTimeout(() => {
      setAnchorEl(null);
    }, 1);
  };

  const handleFetchAllSchedules = () => {
    let modifiedFilters = filter;
    if (filter?.route && filter?.route?._id) {
      modifiedFilters.route = filter?.route;
    }
    const filters = {
      ...modifiedFilters,
      ...(filter?.route ? { route: [filter.route._id] } : {}),
      selection: keysArray ? keysArray : [],
    };

    dispatch(
      getAllTripSchedules(filters, (res) => {
        if (res && res?.success) {
          dispatch(setTripScheduleListFlag(true));
        }
      })
    );
  };

  const handleApplyFilters = () => {
    handleFetchAllSchedules();
  };

  const debounceSearch = () => {
    handleFetchAllSchedules();
  };

  const handleOpenModal = (action) => {
    // let selectedRow = [];
    let template = [];
    setActionStatus(action);
    // selectedRow?.push(tableData[tableIndex]?._id);
    template?.push({
      scheduleName: tripScheduleList[tableIndex]?.scheduleName,
      id: tripScheduleList[tableIndex]?._id,
      isActive:
        tripScheduleList[tableIndex]?.isDisabled === true ? false : true,
    });

    // setCheckedData(selectedRow);
    setSelectedTemplates(template);
    setShowDeleteModal(true);
  };

  const handleStatusUpdate = () => {
    if (tableIndex || tableIndex == 0) {
      const selectedSchedule = tripScheduleList[tableIndex];
      const payload = {
        type: actionStatus,
        comment: comment || "",
        scheduleId: selectedSchedule?._id,
        scheduleName: selectedSchedule?.scheduleName,
        schedule: selectedSchedule?.schedule,
        updateData: {
          data: selectedSchedule?.data,
        },
      };

      dispatch(
        updateScheduleTrip({ ...payload }, (res) => {
          if (res && res?.success) {
            handleFetchAllSchedules();
            dispatch(getTripScheduleCount());
            setShowDeleteModal(false);
            setSelectedTemplates([]);
          }
        })
      );
    }
  };

  const handleSearchOrg = (empty) => {
    if (empty === true) {
      dispatch(getAllOrganizations({ selection: ["name"] }));
    } else {
      if (searchFields?.org || searchFields?.org == "")
        dispatch(
          getAllOrganizations({
            selection: ["name"],
            searchValue: searchFields.org,
          })
        );
    }
  };

  const handleSearchRoute = (empty) => {
    if (empty === true) {
      dispatch(getAllRoutesForTrips({ regex: "", limit: 30 }));
    } else {
      if (searchFields?.route || searchFields?.route == "") {
        dispatch(
          getAllRoutesForTrips({ regex: searchFields?.route, limit: 30 })
        );
      }
    }
  };

  /* ---- USE-EFFECTS ---- */
  React.useEffect(() => {
    setSelectedTab(0);
    setKeysArray(null);
    dispatch(getAllRoutesForTrips({ limit: 30 }));
    dispatch(getTripScheduleCount());
    setFilter({
      page: 1,
      limit: 10,
      searchValue: "",
    });
    setSearchFields({
      org: null,
      route: null,
    });
    dispatch(setTripScheduleListFlag(false))
  }, []);

  React.useEffect(() => {
    if (currentUserDetail && currentUserDetail?.adminSpecificPermissions) {
      dispatch(getAllOrganizations());
    }
  }, [currentUserDetail]);

  React.useEffect(() => {
    if (tripScheduleListFlag == false) {
      // handleFetchAllSchedules();
      dispatch(getBoards({
        module: "Trip Schedule",
        page: 1,
        limit: 10,
      }, (keys) => {
        if (keys && keys?.length > 0) {
          setKeysArray([...keys]);
        } else {
          setKeysArray([]);
        }
        // setBoardLoader(false);
      }))
    }
  }, [tripScheduleListFlag]);

  React.useEffect(() => {
    if (keysArray !== null) handleFetchAllSchedules();
  }, [keysArray]);

  React.useEffect(() => {
    if ((filter?.page || filter?.limit) && tripScheduleListFlag) {
      handleFetchAllSchedules();
    }
  }, [filter?.page, filter?.limit, tripScheduleListFlag]);

  React.useEffect(() => {
    let timeoutId;

    if (filter && filter?.searchValue && filter?.searchValue?.length > 3) {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(debounceSearch, 700);
      return () => {
        clearTimeout(timeoutId);
      };
    }

    if (filter?.searchValue === "") {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(debounceSearch, 700);
      return () => {
        clearTimeout(timeoutId);
      };
    }

    if (filter && filter?.searchValue && filter?.searchValue?.length < 3) {
      return;
    }
  }, [filter?.searchValue]);

  /* ---- CONSTANT UTILS ---- */
  const tabs = [
    {
      id: 0,
      key: "all",
      name: `All (${tripScheduleCount ? tripScheduleCount?.countAll : 0})`,
    },
    {
      id: 1,
      key: "active",
      name: `Active (${tripScheduleCount?.countActive ?? 0})`,
    },
    {
      id: 2,
      key: "disable",
      name: `Blocked (${tripScheduleCount?.countDeactive ?? 0})`,
    },
  ];

  const filterDropdowns = [
    {
      key: "routeName",
      name: "Route",
      options: routesListing || [],
      labelKey: "routeName",
      valueKey: "routeName",
      value: filter?.route || null,
      onChange: (newVal) => {
        if (newVal === null) handleSearchRoute(true);
        setFilter((prevFormState) => ({
          ...prevFormState,
          route: newVal,
        }));
      },
      searchValue:
        searchFields?.route && searchFields?.route !== ""
          ? searchFields?.route
          : null,
      onSearch: handleSearchRoute,
      onInputChange: (e) => {
        if (e?.type === "change") {
          if (e?.target?.value === "") handleSearchRoute(true);
          setSearchFields((prev) => ({
            ...prev,
            route: e?.target?.value?.trim(),
          }));
        }
      },
    },
  ];

  if (currentUserDetail && currentUserDetail?.adminSpecificPermissions) {
    filterDropdowns?.push({
      key: "orgName",
      name: "Organization Name",
      options: allOrganizations ?? [],
      labelKey: "name",
      valueKey: "_id",
      value: filter?.orgId || null,
      searchValue:
        searchFields?.org && searchFields?.org !== ""
          ? searchFields?.org
          : null,
      onSearch: handleSearchOrg,
      onInputChange: (e) => {
        if (e?.type === "change") {
          if (e?.target?.value === "") handleSearchOrg(true);
          setSearchFields((prev) => ({
            ...prev,
            org: e?.target?.value?.trim(),
          }));
        }
      },
      onChange: (newVal) => {
        if (newVal) {
          setFilter((prev) => ({
            ...prev,
            orgId: newVal?._id,
          }));
          // dispatch(
          //   getAllUsers({ orgId: newVal?._id, selection: ["name", "orgName"] })
          // );
        } else {
          let temp = { ...filter };
          delete temp["orgId"];
          setFilter(temp);
          // dispatch(flushUsersList());
        }
      },
    });
  }

  const dynamiHeader =
    boardListing?.boardData?.map((item, index) => item.columnName) || [];

  const tableHeader = [
    "#",
    ...dynamiHeader,
    "Actions",
  ];

  const tableRows = tripScheduleList?.map((item, index) => {
    let items = [];
    items.push(
      <Typography sx={{ ...textClasses.normalText, textAlign: "center" }}>
        {(filter?.page - 1) * filter?.limit + index + 1}
      </Typography>
    );
    let rowKeysArr = boardListing?.boardData || [];

    for (let obj of rowKeysArr) {
      let eachColStr = "";
      for (let ob of obj.group) {
        let tmpInnerStr = main(
          ob.fnc,
          ob.key,
          ob.label,
          item,
          index,
          currentUserDetail,
        );
        eachColStr += tmpInnerStr + "<br/>";
      }
      items.push(
        <Typography
          sx={{ ...textClasses.normalText, textAlign: "center" }}
          dangerouslySetInnerHTML={{ __html: eachColStr }}
        />
      );
    }

    items.push(
      <Typography
        data-testid="action-menu-btn"
        sx={{
          ...textClasses.normalText,
          color: themeType.default.themeOrange,
          cursor: "pointer",
        }}
        onClick={(e) => handleOpenAction(e, index)}
      >
        Action <KeyboardArrowDown />
      </Typography>
    );
    return items;
  });

  return (
    <FiltersWrapper
      handleDrawerToggle={() => setOpenFilters(!openFilters)}
      open={openFilters}
      isDarkThemeEnabledSelector={isDarkThemeEnabledSelector}
      selectStylesOverride={selectStylesOverride(isDarkThemeEnabledSelector)}
      themeType={themeType}
      filters={filterDropdowns}
      handleApplyFilters={handleApplyFilters}
      searchElement={
        <Grid container spacing={2} mt={0}>
          <Grid item md={12} ml={2} mr={2} style={{ width: "100%" }}>
            <FormControl
              variant="outlined"
              size="small"
              fullWidth
              sx={selectStylesOverride(isDarkThemeEnabledSelector)}
            >
              <OutlinedInput
                data-testid="searchelement"
                sx={{ ...inputClasses.filterField, height: "36px" }}
                placeholder="Search by Schedule Name"
                value={filter?.searchValue || ""}
                onChange={(e) =>
                  setFilter({
                    ...filter,
                    searchValue: e.target.value
                      .trimStart()
                      .replace(/\s{2,}/g, " "),
                  })
                }
                endAdornment={
                  <InputAdornment position="end">
                    <Search fontSize="small" />
                  </InputAdornment>
                }
              />
            </FormControl>
          </Grid>
        </Grid>
      }
    >
      <Grid container>
        <Grid
          xs={12}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box display="flex" flexDirection="column">
            <Typography sx={{ ...textClasses.cardTitle }} data-testid="title">
              Trip Schedule
            </Typography>
            <Breadcrumbs data-testid="breadcrumbs">
              <Typography
                sx={{
                  ...textClasses.normalText,
                  fontSize: "12px",
                  color: themeType.default.themeOrange,
                }}
              >
                <Link
                  style={{
                    color: themeType.default.themeOrange,
                    textDecoration: "none",
                  }}
                  data-testid="link-to-go-back"
                  to={{ pathname: "/dashboard/trips" }}
                >
                  Trips
                </Link>
              </Typography>

              <Typography sx={{ ...textClasses.normalText, fontSize: "12px" }}>
                Trip Schedule
              </Typography>
            </Breadcrumbs>
          </Box>
          <Box display="flex" alignItems="center" gap={1}>
            <Button
              sx={{ ...buttonClasses?.lynkitOrangeEmpty, minHeight: "36px" }}
              variant="contained"
              onClick={() => setOpenFilters(true)}
              startIcon={<FilterAlt />}
            >
              Filter
            </Button>
            {havePermission(
              currentUserDetail,
              "trip",
              "createTripSchedule"
            ) && (
              <Button
                sx={{ ...buttonClasses?.lynkitOrangeEmpty, minHeight: "36px" }}
                variant="contained"
                startIcon={<Add />}
                onClick={() => {
                  history.push("/dashboard/trips/trip-schedule/create");
                }}
              >
                Add New
              </Button>
            )}
          </Box>
        </Grid>

        <Grid
          item
          xs={12}
          sx={{
            borderBottom: 1,
            borderColor: "divider",
            mt: 0,
          }}
        >
          <Tabs
            value={selectedTab}
            onChange={handleTabsChange}
            variant="scrollable"
            scrollButtons="auto"
            className="tabsClass"
            aria-label="scrollable auto tabs example"
            sx={{
              maxWidth: window.innerWidth - 108,
              "& .MuiTabs-indicator": {
                backgroundColor: themeType.default.themeOrange,
              },
              "& .MuiTab-root.Mui-selected": {
                color: themeType.default.themeOrange,
              },
            }}
          >
            {tabs?.map((item, i) => {
              return (
                <Tab
                  key={i}
                  label={`${item.name}`}
                  sx={{
                    ...textClasses.normalText,
                    textTransform: "capitalize",
                  }}
                  variant="scrollable"
                  scrollButtons
                  allowScrollButtonsMobile
                  data-testid="tab"
                />
              );
            })}
          </Tabs>
        </Grid>

        {tripScheduleLoading && (
          <Grid
            container
            direction={"column"}
            justifyContent={"center"}
            alignItems="center"
            item
            xs={12}
            sx={{ p: 15 }}
            data-testid="loader"
          >
            <Grid item>
              <img src={loaderGif} />
            </Grid>
          </Grid>
        )}

        {!tripScheduleLoading &&
          tripScheduleList &&
          tripScheduleList?.length === 0 && (
            <EmptyPage body="No Trip Schdule found" />
          )}

        {!tripScheduleLoading &&
          tripScheduleList &&
          tripScheduleList?.length > 0 && (
            <Grid id="scrollTable" xs={12} sx={{ mt: 2 }}>
              <Paper
                sx={{
                  height: "78vh",
                  overflow: "auto",
                  width: "100%",
                  display: "block",
                }}
              >
                <DataTable
                  // tableColumns={tableColumns}
                  // data={tripScheduleList || []}
                  dynamicCols={true}
                  dynamicHeaders={tableHeader || []}
                  dynamicRows={tableRows || []}
                  themeType={themeType}
                  pagination={filter}
                  setPagination={setFilter}
                  pageFilterKey="page"
                  totalRecords={totalTripSchedule || 0}
                />
              </Paper>
            </Grid>
          )}

        {/* ----------------------------------- */}
      </Grid>

      {actionIndex != null && (
        <Menu
          data-testid="action-menu"
          id="basic-menu"
          anchorEl={anchorEl}
          open={actionIndex != null}
          onClose={handleCloseAction}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
          sx={{
            display:
              actionIndex != null ? "block !important" : "none !important",
          }}
        >
          {havePermission(currentUserDetail, "trip", "updateTripSchedule") && tripScheduleList && tripScheduleList?.length && !tripScheduleList[tableIndex]?.isDisabled && (
            <MenuItem
              key={"edit"}
              onClick={() => {
                history.push({
                  pathname: "/dashboard/trips/trip-schedule/edit",
                  state: {
                    data: tripScheduleList[tableIndex] || {},
                  },
                });
                handleCloseAction();
              }}
            >
              Edit
            </MenuItem>
          )}
          {havePermission(currentUserDetail, "trip", "updateTripSchedule") && (
            <MenuItem
              key="active/block"
              onClick={() => {
                handleOpenModal(
                  tripScheduleList[tableIndex]?.isDisabled
                    ? "enable"
                    : "disable"
                );
                handleCloseAction();
              }}
            >
              {tripScheduleList[tableIndex]?.isDisabled ? "Enable" : "Block"}
            </MenuItem>
          )}
          {havePermission(currentUserDetail, "trip", "deleteTripSchedule") && (
            <MenuItem
              key="delete"
              data-testid="delete-menu"
              onClick={() => {
                handleOpenModal("delete");
                handleCloseAction();
              }}
            >
              Delete
            </MenuItem>
          )}
        </Menu>
      )}

      {showDeleteModal && (
        <UpdateTripScheduleStatus
          open={showDeleteModal}
          loading={updateTripScheduleLoading}
          hide={() => {
            setShowDeleteModal(false);
            setActionStatus(null);
            handleCloseAction();
          }}
          tableData={tripScheduleList}
          checkedObj={[]}
          action={actionStatus}
          refresh={handleFetchAllSchedules}
          setShowDeleteModal={() => {
            setShowDeleteModal(false);
          }}
          selectStylesOverride={selectStylesOverride(
            isDarkThemeEnabledSelector
          )}
          actionFn={handleStatusUpdate}
          setSelectedTemplates={setSelectedTemplates}
          selectedTemplates={selectedTemplates}
          setCheckedObj={() => {}}
          comment={comment}
          setComment={setComment}
        />
      )}
    </FiltersWrapper>
  );
};

export default TripSchedule;
