import { Close, SellOutlined } from "@mui/icons-material";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import React from "react";
import { convertIntoGivenTimezone } from "../../../../services/functions";
import pass_icon from "../../../../assets/img/checkIcon.svg";
import fail_icon from "../../../../assets/img/crossIcon.svg";
import { parseDeviceLabel } from "../../../../utils/util";

const DetailCard = ({ themeType, currentUserDetail, onClose, logData }) => {
  const { cardClasses, textClasses } = themeType;

  const cardFields = [
    {
      key: "deviceId",
      name: "Device ID",
    },
    {
      value:
        logData && logData?.deviceType
          ? parseDeviceLabel(logData?.deviceType)
          : "--",
      key: "deviceType",
      name: "Device Type",
    },
    {
      value: logData && logData?.vehicleNumber ? logData?.vehicleNumber : "--",
      key: "vehicleNumber",
      name: "Vehicle Number",
    },
    {
      value: logData ? logData?.deviceAdmin : "--",
      key: "deviceAdmin",
      name: "Device Admin",
    },
    {
      value: logData
        ? convertIntoGivenTimezone(
            logData?.dateTime,
            currentUserDetail?.timeZone?.offset
          )
        : "",
      key: "datetime",
      name: "Created On",
    },
    {
      value: logData
        ? `${logData?.addedBy?.name} - ${logData?.addedBy?.orgName}`
        : "--",
      key: "addedBy",
      name: "Created By",
    },
  ];

  return (
    <>
      <Card
        className="ppmodal"
        sx={{
          ...cardClasses.basic,
          borderRadius: "8px",
          border: `1px solid ${themeType.default.themeOrange}`,
          position: "relative",
          height: "77vh",
        }}
      >
        <CardHeader
          sx={{
            height: "46px",
            backgroundColor: themeType.default.themeOrange,
          }}
          title={
            <Typography
              data-testid="detail-title"
              sx={{
                ...textClasses.boldText,
                color: "white",
                fontSize: "16px",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              <SellOutlined fontSize="inherit" sx={{ mr: 1 }} />
              Health Report Details
            </Typography>
          }
          action={
            <IconButton size="small" sx={{ p: 0, mb: 1 }}>
              <Close sx={{ color: "white" }} onClick={onClose} />
            </IconButton>
          }
        />

        <CardContent
          sx={{ height: "100%", maxHeight: "70vh", overflowY: "scroll" }}
        >
          {logData &&
            Object.keys(logData)?.length > 0 &&
            cardFields?.map((field, idx) => {
              return (
                <Grid container sx={{ mt: 1 }}>
                  <Grid item sm={6} xs={4}>
                    <Typography
                      sx={{
                        ...textClasses.t12n,
                        color: "#827878",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {field?.name}
                    </Typography>
                  </Grid>
                  <Grid
                    container
                    item
                    sm={6}
                    xs={8}
                    direction="row"
                    alignItems={"center"}
                    sx={{
                      ...textClasses.t12n,
                      color: "#827878",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      textTransform: "capitalize",
                    }}
                  >
                    <Tooltip
                      placement="top"
                      title={
                        field?.value
                          ? field?.value?.length > 15
                            ? field?.value
                            : "-"
                          : logData[field?.key]
                          ? logData[field?.key]?.length > 15
                            ? logData[field?.key]
                            : "-"
                          : "-"
                      }
                    >
                      <Typography
                        sx={{
                          ...textClasses.t12n,
                          width: "100%",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {`:  ${
                          field?.value
                            ? field?.value
                            : field?.key === "isActive"
                            ? logData[field?.key] === true
                              ? "Active"
                              : "Disabled"
                            : logData[field?.key] ?? "--"
                        }`}
                      </Typography>
                    </Tooltip>
                  </Grid>
                </Grid>
              );
            })}

          {logData &&
            logData?.healthReport &&
            logData?.healthReport?.length > 0 && (
              <ReportTable themeType={themeType} data={logData?.healthReport} />
            )}
        </CardContent>
      </Card>
    </>
  );
};

const ReportTable = ({ themeType, data }) => {
  const unlockingData = data.filter(
    (item) => item.text.includes("Unlocking") || item.text.includes("unlocking")
  );
  const excludedUnlockingData = data.filter(
    (item) =>
      !item.text.includes("Unlocking") && !item.text.includes("unlocking")
  );
  const unlockingStatus = unlockingData.some((item) => item.status === "pass")
    ? "pass"
    : "fail";

  return (
    <Box borderRadius="10px" mt={3}>
      <Box
        className="d-flex-between"
        sx={{
          background: themeType.default.themeOrange,
          borderRadius: "8px 8px 0 0",
        }}
        py={0.5}
        px={1}
      >
        <Typography sx={{ color: "#fff", fontWeight: 600 }}>
          Category
        </Typography>
        <Typography sx={{ color: "#fff", fontWeight: 600 }}>Result</Typography>
      </Box>

      <Box
        py={0.5}
        px={1}
        border="1px solid"
        sx={{ borderColor: themeType.default.themeOrange }}
      >
        <Box className="d-flex-between">
          <Typography variant="body2">Unlocking</Typography>
          <Typography
            sx={{
              color:
                unlockingStatus === "pass"
                  ? themeType.default.themeGreen
                  : themeType.default.themeRed,
              fontWeight: 600,
            }}
          >
            {unlockingStatus === "pass" ? "Passed" : "Failed"}
          </Typography>
        </Box>
        {unlockingData.map((item, index) => (
          <Box className="d-flex-between" mx={2} py={0.3}>
            <Typography variant="subtitle2" sx={{ color: "gray", textTransform: "capitalize" }}>
              {item?.text}
            </Typography>
            <Typography>
              {item?.isAttempted === false &&
              item?.operationToPerform === "count" ? (
                "-"
              ) : (
                <img
                  src={item?.status === "pass" ? pass_icon : fail_icon}
                  height="25px"
                  width="25px"
                />
              )}
            </Typography>
          </Box>
        ))}
      </Box>
      {excludedUnlockingData &&
        excludedUnlockingData?.map((item, i) => (
          <Box
            className="d-flex-between"
            py={0.5}
            px={1}
            border="1px solid"
            sx={{
              borderColor: themeType.default.themeOrange,
              borderTop: "none",
            }}
          >
            <Typography variant="body2" sx={{textTransform: "capitalize"}}>
              {item?.text}{" "}
              {item?.type === "battery" ||
              item?.type === "noTampered" ||
              item?.type === "lastPacketTime"
                ? `- ${item?.value}`
                : ""}{" "}
              {item?.type === "noTampered" || item?.type === "lastPacketTime"
                ? "hr"
                : item?.type === "battery"
                ? "%"
                : ""}
            </Typography>
            <Typography
              sx={{
                color: item?.isAttempted === false &&
                item?.operationToPerform === "count" ? "unset" :
                  item?.status === "pass"
                    ? themeType.default.themeGreen
                    : themeType.default.themeRed,
                fontWeight: 600,
              }}
            >
              {item?.isAttempted === false &&
              item?.operationToPerform === "count"
                ? "-"
                : item?.status === "pass"
                ? "Passed"
                : "Failed"}
            </Typography>
          </Box>
        ))}
    </Box>
  );
};

export default DetailCard;
