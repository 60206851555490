import { Typography } from "@mui/material";
import { useSelector } from "react-redux";


export const TableColumns = ({ filter }) => {
    const isDarkThemeEnabledSelector = useSelector(
        (state) => state.theme.themeDark
    );
    const themeType = isDarkThemeEnabledSelector
        ? require("../../../../static/styles/darktheme")
        : require("../../../../static/styles/theme");
    const { textClasses } = themeType;
    let column = []

    column = [
        {
            key: "Sno",
            header: "#",
            cell: (key) => (
                <Typography
                    sx={{
                        ...textClasses.t13n,
                        textAlign: "center",
                    }}
                >
                    {(filter.page - 1) * filter.limit + key + 1}
                </Typography>
            ),
        },
        {
            key: "Port",
            header: "Port",
            cell: (entry) => (
                <Typography
                    sx={{
                        ...textClasses.t13n,
                        textAlign: "center",
                        color: themeType.default.themeOrange,
                        cursor: "pointer",
                    }}
                >
                    {entry?.port ?? "-"}
                </Typography>
            ),
        },
        {
            key: "Total Devices",
            header: "Total Devices",
            cell: (entry) => (
                <Typography sx={{ ...textClasses.t13n, textAlign: "center" }}>
                    {entry?.totalDevice ?? "-"}
                </Typography>
            ),
        },
        {
            key: "Offline Devices",
            header: "Offline Devices",
            cell: (entry) => (
                <Typography sx={{ ...textClasses.t13n, textAlign: "center" }}>
                    {entry?.offlineDevice ?? "-"}
                </Typography>
            ),
        },
        {
            key: "Active Devices",
            header: "Active Devices",
            cell: (entry) => (
                <Typography sx={{ ...textClasses.t13n, textAlign: "center" }}>
                    {entry?.onlineDevice ?? "-"}
                </Typography>
            ),
        },
    ];


    return column;
};