import React, { useEffect, useState } from "react";
import { styled, useTheme } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  Grid,
  Typography,
  Box,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { useSelector } from "react-redux";
import Navbar from "../Navbar";
import DrawerItemList from "./DrawerItems";
import DashboardIcon from "../../../static/images/drawer/dashboard.svg";
import DashboardActiveIcon from "../../../static/images/drawer/dashboard_active.svg";
import devices from "../../../static/images/drawer/devices.png";
import devicesActive from "../../../static/images/drawer/devicesActive.png";
import users from "../../../static/images/drawer/users.png";
import usersActive from "../../../static/images/drawer/usersActive.png";
import location from "../../../static/images/drawer/location.png";
import locationActive from "../../../static/images/drawer/locationActive.png";
import way from "../../../static/images/drawer/way.png";
import wayActive from "../../../static/images/drawer/wayActive.png";
import { useDispatch } from "react-redux";
import { closeDrawer, openDrawer, userDetails } from "../../../redux/actions";
import MiniLogo from "../../../static/images/drawer/mini_logo.svg";
import Logo from "../../../static/images/login/Lynktrac.png";
import board from "../../../static/images/drawer/layout.png";
import boardActive from "../../../static/images/drawer/layoutactive.png";
import truckIcon from "../../../assets/infoWindow/cargo.png"
import truckIconWhite from "../../../assets/infoWindow/cargowhite.png"
import template from "../../../static/images/drawer/templateactive (2).png";
import templateActive from "../../../static/images/drawer/templateactive (1).png";

import routes from "../../../static/images/drawer/routes (1).png";
import routesActive from "../../../static/images/drawer/routes (2).png";

import settings from "../../../static/images/drawer/settings (1).png";
import settingsActive from "../../../static/images/drawer/settings (2).png";

// import {ReactComponent as RouteIcon} from "../../../static/images/drawer/route.svg"
// import {ReactComponent as DeviceIcon} from "../../../static/images/drawer/Assets.svg"
// import {ReactComponent as AssetMgtIcon} from "../../../static/images/drawer/asset management.svg"
// import {ReactComponent as GeofenceIcon} from "../../../static/images/drawer/geofence.svg"
// import {ReactComponent as MonitoringIcon} from "../../../static/images/drawer/monitoring.svg"
// import {ReactComponent as SettingsIcon} from "../../../static/images/drawer/setting.svg"
// import {ReactComponent as TripIcon} from "../../../static/images/drawer/trip.svg"

import analytic from "../../../static/images/drawer/analytic.png";
import analyticActive from "../../../static/images/drawer/analyticActive.png";
import theme, { textClasses } from "../../../static/styles/theme";
import {
  havePermission,
  preparePermissions,
} from "../../../utils/permissionCheck";
import { Monitor, Window } from "@mui/icons-material";
import GeoIcon from "../../../static/images/sidebarIcons/GeoIcon";
import TripSvgIcon from "../../../static/images/sidebarIcons/TripSvgIcon";
import RouteIcon from "../../../static/images/sidebarIcons/RouteIcon";
import SettingsIcon from "../../../static/images/sidebarIcons/SettingsIcon";
import MonitoringIcon from "../../../static/images/sidebarIcons/MonitoringIcon";
import DeviceIcon from "../../../static/images/sidebarIcons/DeviceIcon";
import AssetMgtIcon from "../../../static/images/sidebarIcons/AssetMgtIcon";
import MapArea from "../../../static/images/sidebarIcons/MapArea";

import logs from "../../../static/images/drawer/logs.png";
import logsWhite from "../../../static/images/drawer/logsWhite.png";
import shift from "../../../static/images/drawer/shift.png";
import shiftWhite from "../../../static/images/drawer/shiftWhite.png";
import apilogs from "../../../static/images/drawer/apilogss.png";
import apilogswhite from "../../../static/images/drawer/apilogsWhite.png";
import health from "../../../static/images/drawer/health.png";
import healthwhite from "../../../static/images/drawer/healthwhite.png";
import healthReportWhite from "../../../static/images/drawer/health_report_filled_white.svg";
import healthReportBlack from "../../../static/images/drawer/health_report_filled_black.svg";
import driver from "../../../static/images/drawer/driver.png";
import driverwhite from "../../../static/images/drawer/driverwhite.png";
import catalogue from "../../../static/images/drawer/catalogue.png";
import cataloguewhite from "../../../static/images/drawer/cataloguewhite.png";
import DrawerFloaterLabel from "./DrawerFloaterLabel";
import MenuIcon from "@mui/icons-material/Menu";

const drawerWidth = 180;
const sideBarValue = localStorage.getItem('sidebar')

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(4)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(6)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));
export default function MiniDrawer(props) {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const userDetail = useSelector((state) => state.auth.currentUserDetail);
  const isDarkThemeEnabled = useSelector((state) => state.theme.themeDark);
  const currentUser = useSelector((state) => state.auth.currentUserDetail);
  const superAdmin = currentUser?.adminSpecificPermissions;
  const isSuperAdmin = !!superAdmin;

  const themeType = isDarkThemeEnabled
    ? require("../../../static/styles/darktheme")
    : require("../../../static/styles/theme");
  const {
    buttonClasses,
    cardClasses,
    inputClasses,
    textClasses,
    tableClasses,
  } = themeType;

  const dispatch = useDispatch();
  const sideBarValue = localStorage.getItem('sidebar')
  const [expandList, setExpandList] = useState(null);

  const handleExpandList = (type, title) => {
    setExpandList(type == 1 ? title : null);
  };

  const handleDrawerOpen = () => {
    setOpen(true);
    dispatch(openDrawer());
  };

  const handleDrawerClose = () => {
    setOpen(false);
    handleExpandList(0);
    dispatch(closeDrawer());
  };

  const handleResponsiveDrawerToggle = () => {
    if(mobileOpen === true){
      handleExpandList(0);
      dispatch(closeDrawer());
    }else{
      dispatch(openDrawer());
    }
    setMobileOpen(!mobileOpen);
  };

  // const isSuperAdmin = JSON.parse(localStorage.getItem("isSuperAdmin"));
  const devicesSubItems = isSuperAdmin
    ? [
        {
          title: "Device Models",
          url: "/dashboard/devices/device-models",
          module: "device",
          permissionKey: "viewDeviceCategory",
          onlySuperAdmin: true,
        },
        {
          title: "Add Device",
          url: "/dashboard/devices/add-device",
          module: "device",
          permissionKey: "addDevice",
        },
        {
          title: "Devices",
          url: "/dashboard/devices/all-devices",
          module: "device",
          permissionKey: "viewDevice",
        },
      ]
    : [
        {
          title: "Add Device",
          url: "/dashboard/devices/add-device",
          module: "device",
          permissionKey: "addDevice",
        },
        {
          title: "Devices",
          url: "/dashboard/devices/all-devices",
          module: "device",
          permissionKey: "viewDevice",
        },
      ];
  const UsersSubItems2 = [
    {
      title: "Organisation",
      url: "/dashboard/users/organisation",
      onlySuperAdmin: true,
      module: "organization",
      permissionKey: "viewOrganization",
    },
    {
      title: "User Role",
      url: "/dashboard/users/user-role",
      module: "userRole",
      permissionKey: "viewPermissionsRole",
    },
    {
      title: "Users",
      url: "/dashboard/users/my-team",
      module: "user",
      permissionKey: "viewUser",
    },
  ];
  const TripSubItems = [
    {
      title: "Create Trip",
      url: "/dashboard/trips/create",
      noSuperAdmin: true,
      module: "trip",
      permissionKey: "createTrip",
    },
    {
      title: "Bulk Upload",
      url: "/dashboard/trips/bulk-create",
      noSuperAdmin: true,
      module: "trip",
      permissionKey: "bulkUploadTrip",
    },
    {
      title: "Trip Schedule",
      url: "/dashboard/trips/trip-schedule",
      module: "trip",
      permissionKey: "viewTripSchedule",
    },
    {
      title: "Trips",
      url: "/dashboard/trips",
      module: "trip",
      permissionKey: "viewTrip",
    }   
  ];

  const SettingsSubItems = [
    {
      title: "Trip",
      url: "/dashboard/settings/trips",
      isSettingPermission: true,
      module: "tripSettings",
    },
    {
      title: "Alerts",
      url: "/dashboard/settings/alerts",
      isSettingPermission: true,
      module: "alertSettings",
    },
    {
      title: "Health Report",
      url: "/dashboard/settings/health-report",
      isSettingPermission: true,
      module: "healthSettings",
      permissionKey: "viewDeviceHealthPermission",
    },
    {
      title: "Account Linkage",
      url: "/dashboard/settings/account-linkage",
      isSettingPermission: false,
      module: "accountLinkage",
      permissionKey: "viewAccountLinkage",
    },
    // {
    //   title: "Profile",
    //   url: "/dashboard/settings/profile",
    // },
    // {
    //   title: "Apps",
    //   url: "/dashboard/settings/apps",
    // },
    // {
    //   title: "Api",
    //   url: "/dashboard/settings/api",
    // },
  ];

  const sidebarItems = [
    //   {
    //   title: "Dashboard",
    //   icon: isDarkThemeEnabled ? (
    //     <img src={analyticActive} />
    //   ) : (
    //     <img src={analytic} />
    //   ),
    //   activeIcon: <img src={analyticActive} />,
    //   url: "/dashboard",
    // },

    {
      title: "Monitoring",
      icon: (
        <MonitoringIcon
          height="17"
          width="17"
          fill={isDarkThemeEnabled ? "#ffffff" : "#000000"}
        />
      ),
      activeIcon: <MonitoringIcon height="17" width="17" fill="#ffffff" />,
      url: "/dashboard/monitoring",
      moduleKey: "monitoring",
      permissionKey: "viewMonitoring",
    },
    {
      title: "Central Map ",
      icon: isDarkThemeEnabled ? <img src={wayActive} /> : <img src={way} />,
      activeIcon: <img src={wayActive} />,
      url: "/dashboard/mapview",
      moduleKey: "mapDashboard",
      permissionKey: "viewMapDashboard",
    },
    {
      title: "Area Mapping",
      icon: (
        <MapArea
          height="17"
          width="17"
          fill={isDarkThemeEnabled ? "#ffffff" : "#000000"}
        />
      ),
      activeIcon: <MapArea height="17" width="17" fill="#ffffff" />,
      url: "/dashboard/area-mapping?accesskey=lozro2to_5214",
      moduleKey: "areaMapping",
      permissionKey: "viewAreaMapping",
    },
    {
      title: "Manage Board",
      icon: isDarkThemeEnabled ? (
        <img src={boardActive} />
      ) : (
        <img src={board} />
      ),
      activeIcon: <img src={boardActive} />,
      url: "/dashboard/board",
      moduleKey: "dynamicBoard",
      permissionKey: "viewBoard",
    },
    {
      title: "Manage Templates",
      icon: isDarkThemeEnabled ? (
        <img src={templateActive} />
      ) : (
        <img src={template} />
      ),
      activeIcon: <img src={templateActive} />,
      url: "/dashboard/manage-templates",
      moduleKey: "dynamicTemplates",
      permissionKey: "viewTemplate",
    },
    // {
    //   title: "Map",
    //   icon: isDarkThemeEnabled ? <img src={wayActive} /> : <img src={way} />,
    //   activeIcon: <img src={wayActive} />,
    //   url: null,
    //   moduleKey: "mapDashboard",
    //   permissionKey: "viewMapDashboard",
    // },
   
    {
      title: "Geofence",
      icon: (
        <GeoIcon
          height="17"
          width="17"
          fill={isDarkThemeEnabled ? "white" : "black"}
        />
      ),
      activeIcon: <GeoIcon height="17" width="17" fill="#ffffff" />,
      url: "/dashboard/geofence",
      permissionKey: "viewGeofence",
      moduleKey: "geofence",
    },
    {
      title: "Routes",
      icon: (
        <RouteIcon
          height="17"
          width="17"
          fill={isDarkThemeEnabled ? "white" : "black"}
        />
      ),
      activeIcon: <RouteIcon height="17" width="17" fill="#ffffff" />,
      url: "/dashboard/routes",
      moduleKey: "routes",
      permissionKey: "viewRoutes",
    },
    {
      title: "Manage Users",
      icon: isDarkThemeEnabled ? (
        <img src={usersActive} />
      ) : (
        <img src={users} />
      ),
      activeIcon: <img src={usersActive} />,
      url: "/dashboard/users/organisation",
      moduleKey: "user",
      subItems: UsersSubItems2,
    },
    {
      title: "Trips",
      icon: (
        <TripSvgIcon
          height="17"
          width="17"
          fill={isDarkThemeEnabled ? "#ffffff" : "#000000"}
        />
      ),
      activeIcon: <TripSvgIcon height="17" width="17" fill="#ffffff" />,
      url: "/dashboard/trips",
      moduleKey: "trip",
      subItems: TripSubItems,
    },
    {
      title: "Devices",
      icon: (
        <DeviceIcon
          height="17"
          width="17"
          fill={isDarkThemeEnabled ? "#ffffff" : "#000000"}
        />
      ),
      activeIcon: <DeviceIcon height="17" width="17" fill="#ffffff" />,
      url: "/dashboard/devices/device-models",
      moduleKey: "device",
      subItems: devicesSubItems,
    },
    {
      title: "Settings",
      icon: (
        <SettingsIcon
          height="17"
          width="17"
          fill={isDarkThemeEnabled ? "#ffffff" : "#000000"}
        />
      ),
      activeIcon: <SettingsIcon height="17" width="17" fill="#ffffff" />,
      moduleKey: "settings",
      url: "/dashboard/settings/trips",
      subItems: SettingsSubItems,
    },
    {
      title: "Asset Management",
      icon: (
        <AssetMgtIcon
          height="17"
          width="17"
          fill={isDarkThemeEnabled ? "#ffffff" : "#000000"}
        />
      ),
      activeIcon: <AssetMgtIcon height="17" width="17" fill="#ffffff" />,
      url: "/dashboard/asset-management",
      moduleKey: "assets",
      permissionKey: "viewAssetMgmt",
    },
    {
      title: "Health Report",
      icon: isDarkThemeEnabled ? (
        <img src={healthReportWhite} />
      ) : (
        <img src={healthReportBlack} />
      ),
      activeIcon: <img src={healthReportWhite} />,
      url: "/dashboard/health-report",
      moduleKey: "healthSettings",
      permissionKey: "generateHealthReport",
    },
    {
      title: "Health",
      icon: isDarkThemeEnabled ? (
        <img src={healthwhite} />
      ) : (
        <img src={health} />
      ),
      activeIcon: <img src={healthwhite} />,
      url: "/dashboard/health",
      moduleKey: "healthDashboard",
      permissionKey: "viewHealthDashboard",
    },
    {
      title: "Logs",
      icon: isDarkThemeEnabled ? <img src={logsWhite} /> : <img src={logs} />,
      activeIcon: <img src={logsWhite} />,
      url: "/dashboard/logs",
      moduleKey: "Logs",
      // permissionKey: "viewAlertLogs",
    },
    {
      title: "Shift",
      icon: isDarkThemeEnabled ? <img src={shiftWhite} /> : <img src={shift} />,
      activeIcon: <img src={shiftWhite} />,
      url: null,
      moduleKey: "shift",
      permissionKey: "viewShift",
    },
    {
      title: "API Logs",
      icon: isDarkThemeEnabled ? (
        <img src={apilogswhite} />
      ) : (
        <img src={apilogs} />
      ),
      activeIcon: <img src={apilogswhite} />,
      url: null,
      moduleKey: "apiLogs",
      permissionKey: "viewApiLogs",
    },
    {
      title: "Drivers",
      icon: isDarkThemeEnabled ? (
        <img src={driverwhite} />
      ) : (
        <img src={driver} />
      ),
      activeIcon: <img src={driverwhite} />,
      url: null,
      moduleKey: "driver",
      permissionKey: "driver_list",
    },
    {
      title: "Vehicle Master",
      icon: isDarkThemeEnabled ? (
        <img src={truckIconWhite} />
      ) : (
        <img src={truckIcon} />
      ),
      activeIcon: <img src={driverwhite} />,
      url: null,
      moduleKey: "vehicleMaster",
      permissionKey: "get_truck_list",
    },
    {
      title: "Resources",
      icon: isDarkThemeEnabled ? (
        <img src={cataloguewhite} />
      ) : (
        <img src={catalogue} />
      ),
      activeIcon: <img src={cataloguewhite} />,
      url: null,
      moduleKey: "resources",
      permissionKey: "viewResources",
    },
  ];

  let filteredSidebarItems = [];

  if (currentUser && Object.keys(currentUser)?.length > 0) {
    filteredSidebarItems = sidebarItems
      ?.map((item) => {
        if (!item.subItems) {
          if (item.moduleKey) {
            let hasPermission;
            if(item.moduleKey === "Logs"){
             hasPermission = (
              havePermission(currentUser, "unlockingLogs", "viewBtUnlockingLogs") ||
              havePermission(currentUser, "unlockingLogs", "viewPasscodeLogs") ||
              havePermission(currentUser, "unlockingLogs", "viewPasscodeUnlockingLogs") ||
              havePermission(currentUser, "unlockingLogs", "viewRfidUnlockingLogs") ||
              havePermission(currentUser, "unlockingLogs", "viewSmsLogs") ||
              havePermission(currentUser, "unlockingLogs", "viewSmsUnlockingLogs") ||
              havePermission(currentUser, "unlockingLogs", "viewWebUnlockingLogs") ||
              havePermission(currentUser, "alertLogs", "viewAlertLogs")
            )
            }else{
              hasPermission = havePermission(
                currentUser,
                item.moduleKey,
                item.permissionKey
              );
            }
            
            if (item.onlySuperAdmin) {
              if (hasPermission && currentUser?.adminSpecificPermissions) {
                return item;
              }
            } else if (hasPermission) {
              return item;
            }
          } else if (
            item.onlySuperAdmin &&
            currentUser?.adminSpecificPermissions
          ) {
            return item;
          } else if (!item.onlySuperAdmin) {
            return item;
          }
        } else {
          const filteredSubItems = item.subItems.filter((subItem) => {
            if (subItem?.isSettingPermission) {
              const mergedPermissions =
                Object.keys(currentUser)?.length > 0
                  ? preparePermissions(
                    currentUser?.userRole?.permissions,
                      currentUser?.permissions,
                    )
                  : null;
              if (
                mergedPermissions &&
                Object?.keys(mergedPermissions)?.length > 0
              ) {
                const finalCheck =
                  mergedPermissions[subItem?.module] &&
                  Object.keys(mergedPermissions[subItem?.module])?.some(
                    (key) => {
                      if (key?.startsWith("view")) {
                        return (
                          mergedPermissions[subItem?.module][key].value === true
                        );
                      }
                      return false;
                    }
                  );
                return finalCheck;
              }
            } else {
              const hasPermission = havePermission(
                currentUser,
                subItem.module,
                subItem.permissionKey
              );
              if(subItem?.noSuperAdmin){
                return !currentUser?.adminSpecificPermissions && hasPermission;
              } else if (subItem.onlySuperAdmin) {
                return currentUser?.adminSpecificPermissions && hasPermission;
              } else {
                return hasPermission;
              }
            }
          });

          if (filteredSubItems.length > 0) {
            return { ...item, subItems: filteredSubItems };
          }
        }

        return null;
      })
      .filter(Boolean);
  }

  const DrawerChildren = () => {
    return(
      <>
        <DrawerHeader sx={{display: {xs: "none", md: "flex"}}}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List
          sx={{
            "&.MuiList-root": {
              background: "transparent",
            },
            marginBottom: 8,
          }}
        >
          <DrawerItemList
            items={filteredSidebarItems}
            // items={sidebarItems}
            open={open || mobileOpen}
            expandList={expandList}
            handleExpandList={handleExpandList}
            setOpen={setOpen}
            {...props}
          />
        </List>
        <Box
          sx={{
            position: "fixed",
            bottom: "0",
            textAlign: "center",
            paddingBottom: "12px",
            paddingLeft: "6px",
            background: (theme) => theme.palette.background.paper,
          }}
        >
          {open ? (
            <Grid
              container
              item
              alignItems={"center"}
              justifyContent={"center"}
              textAlign={"center"}
              display={"flex"}
              flexDirection={"column"}
            >
              <Typography
                sx={{
                  ...textClasses.normalText,
                  fontSize: "12px",
                  color: "#827878",
                  display: "flex",
                  textAlign: "center",
                  justifyContent: "center",
                }}
              >
                Powered by{" "}
              </Typography>
              <img src={Logo} height="29" />
            </Grid>
          ) : (
            <Grid
              container
              item
              alignItems={"center"}
              justifyContent={"center"}
            >
              <img src={MiniLogo} />
            </Grid>
          )}
        </Box>
      </>
    )
  }

  return (
    <div className="main-sidebar-drawer">
      <CssBaseline />

      <Navbar
        open={open}
        handleDrawerOpen={handleDrawerOpen}
        userDetail={userDetail}
        handleResponsiveDrawerToggle={handleResponsiveDrawerToggle}
      />
      {sideBarValue || localStorage.getItem("iframeee") ? null : 
      <>
        {/* <DrawerFloaterLabel top="0%" show={mobileOpen} setShow={setMobileOpen} text="Menu" Icon={<MenuIcon sx={{color: '#ffffff', fontsize: "13px"}} />} leftAlign={true}/> */}
        <MuiDrawer
          // container={container}
          variant="temporary"
          open={mobileOpen}
          // onTransitionEnd={handleDrawerTransitionEnd}
          onClose={() => {setMobileOpen(false)}} 
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', md: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth + 20 },
          }}
        >
          {DrawerChildren()}
        </MuiDrawer>
        <Drawer
          variant="permanent"
          sx={{display: { xs: 'none', md: 'block' }}}
          open={open}
          onMouseEnter={handleDrawerOpen}
          onMouseLeave={handleDrawerClose}
        >
          {DrawerChildren()}
        </Drawer>
      </>
      }
    </div>
  );
}
