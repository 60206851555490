import { Paper, TablePagination } from "@mui/material";
import React from "react";
//import theme, { textClasses } from "../../../../static/styles/theme";

const RoutesTable = ({
  header,
  rows,
  tableColumns = [],
  data,
  totalRecords,
  pagination,
  setPagination,
  pageFilterKey = "pageNumber",
  onRowChange = () => { },
  themeType,
  minTableWidth = "100%",
}) => {
  const { textClasses, tableClasses, } = themeType;

  return (
    <Paper
      className="muitable"
      sx={{
        height: '74vh',
        overflow: 'auto',
        width: "100%",
        display: "block",
      }}
    >
      <table style={{ ...tableClasses.table }}>
        <thead style={{ backgroundColor: themeType.default.themeGray, position: 'sticky', top: 0, zIndex: 1 }}>
          <tr>
            {header.slice(0, 2).map((head, i) => {
              return (
                <td
                  key={i}
                  style={{
                    ...textClasses.boldText,
                    ...tableClasses.tableHeader,
                    textAlign: "center",
                  }}
                >
                  {head}
                </td>
              );
            })}
            {header.slice(2, header.length).map((head, i) => {
              return (
                <td
                  key={i}
                  style={{
                    ...textClasses.boldText,
                    ...tableClasses.tableHeader,
                    textAlign: "center",
                  }}
                >
                  {head}
                </td>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {rows.length > 0 ? (
            <>
              {rows.map((row, i) => (
                <tr data-testid="tablerow" key={i}>
                  {row.slice(0, 2).map((col, j) => {
                    return (
                      <td
                        key={j}
                        data-testid="tablecell"
                        style={{
                          textAlign: "center",
                          ...textClasses.normalText,
                          ...tableClasses.dark,
                          ...tableClasses.tableBodyClass,
                          textAlign: "center",
                        }}
                      >
                        {col}
                      </td>
                    );
                  })}
                  {row.slice(2, row.length).map((col, j) => {
                    return (
                      <td
                        key={j}
                        style={{
                          textAlign: "center",
                          ...textClasses.normalText,
                          ...tableClasses.dark,
                          ...tableClasses.tableBodyClass,
                          textAlign: "center",
                        }}
                      >
                        {col}
                      </td>
                    );
                  })}
                </tr>
              ))}
            </>
          ) : (
            <tr sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
              <td
                data-testid="nodata"
                component="th"
                scope="row"
                style={{
                  ...textClasses.normalText,
                  textAlign: "left",
                  overflowWrap: "break-word",
                }}
              ></td>
            </tr>
          )}
        </tbody>
      </table>
      {pagination && (
        <TablePagination
          component="div"
          count={totalRecords}
          page={pagination[pageFilterKey] - 1}
          onPageChange={(e, newPage) => {
            setPagination((v) => ({ ...v, [pageFilterKey]: newPage + 1 }));
          }}
          rowsPerPage={pagination?.limit}
          onRowsPerPageChange={(e) => {
            setPagination((v) => ({
              ...v,
              [pageFilterKey]: 1,
              limit: e.target.value,
            }));
          }}
          sx={{
          ...tableClasses.tableHeader,
          ...tableClasses.tablePagination,
          display: "flex",
          justifyContent: "center",
          position: 'sticky',
          bottom: '0px',
          alignItems: "center",
          mt: "0em",
          "& .MuiTablePagination-selectLabel ": {
            margin: "0",
          },
          "& .MuiTablePagination-displayedRows": {
            margin: "0",
          },
        }}
        />
      )}
    </Paper>
  );
};

export default RoutesTable;
