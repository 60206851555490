import { Box, Grid, Paper, Typography } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import DataTable from "../../../Components/DataTable/DataTable";
import { convertIntoGivenTimezone } from "../../../../services/functions";
import moment from "moment";

const FlightDetails = ({details}) => {
  const {currentUserDetail} = useSelector(_ => _.auth);
  const isDarkThemeEnabledSelector = useSelector(
    (state) => state.theme.themeDark
  );
  const themeType = isDarkThemeEnabledSelector
    ? require("../../../../static/styles/darktheme")
    : require("../../../../static/styles/theme");
  const { textClasses } = themeType;

  const stackedStyles = {display: "flex", flexDirection: "column", alignItems: "center"};

  const getTimezoneOffset = (timeZone) => {
    try {
        const offsetMinutes = moment().tz(timeZone).utcOffset();
        return offsetMinutes / 60;
    } catch (error) {
        console.error('Invalid time zone:', timeZone);
        return null;
    }
  }

  const tableColumn = [
    {
      header: "Flight Number",
      cell: (entry) => {
        return (
          <Typography
              sx={{
                ...textClasses.t13n,
                textAlign: "center",
                textTransform: "capitalize",
              }}
            >
              {entry?.flightId?.toUpperCase() ?? "-"}
            </Typography>
        );
      },
    },
    {
      header: "Boarding Location & Terminal",
      cell: (entry) => {
        return (
          <Box style={stackedStyles}>
            <Typography
              sx={{
                ...textClasses.t13n,
                textAlign: "center",
                textTransform: "capitalize",
              }}
            >
              {entry?.boardingLocation ?? "-"}
            </Typography>
            <Typography
              sx={{
                ...textClasses.t13n,
                textAlign: "center",
                textTransform: "capitalize",
              }}
            >
              {entry?.origin_code_iata ? `[${entry?.origin_code_iata}]` : "-"}
            </Typography>
          </Box>
        );
      },
    },
    {
      header: "Departure Date & Time",
      cell: (entry) => {
        let originTimezoneOffset = getTimezoneOffset(entry?.originTimeZone);
        return (
          <Typography
              sx={{
                ...textClasses.t13n,
                textAlign: "center",
                textTransform: "capitalize",
              }}
            >
              {entry?.boardingDateTime ? convertIntoGivenTimezone(entry?.boardingDateTime, originTimezoneOffset) : "-"}
            </Typography>
        );
      },
    },
    {
      header: "Actual Departure",
      cell: (entry) => {
        let originTimezoneOffset = getTimezoneOffset(entry?.originTimeZone);
        return (
          <Typography
              sx={{
                ...textClasses.t13n,
                textAlign: "center",
                textTransform: "capitalize",
              }}
            >
              {entry?.trackingFlightStartTime ? convertIntoGivenTimezone(entry?.trackingFlightStartTime, originTimezoneOffset) : "-"}
            </Typography>
        );
      },
    },
    {
      header: "Destination Location & Terminal",
      cell: (entry) => {
        return (
          <Box style={stackedStyles}>
            <Typography
                sx={{
                  ...textClasses.t13n,
                  textAlign: "center",
                  textTransform: "capitalize",
                }}
              >
                {entry?.destinationLocation ?? "-"}
            </Typography>
            <Typography
                sx={{
                  ...textClasses.t13n,
                  textAlign: "center",
                  textTransform: "capitalize",
                }}
              >
                {entry?.destination_code_iata ? `[${entry?.destination_code_iata}]` : "-"}
            </Typography>
          </Box>
        );
      },
    },
    {
      header: "Estimated In",
      cell: (entry) => {
        let arrivalTimezoneOffset = getTimezoneOffset(entry?.destinationTimeZone);
        return (
          <Typography
              sx={{
                ...textClasses.t13n,
                textAlign: "center",
                textTransform: "capitalize",
              }}
            >
              {entry?.estimated_in ? convertIntoGivenTimezone(entry?.estimated_in, arrivalTimezoneOffset) : "-"}
            </Typography>
        );
      },
    },
    {
      header: "Arrival Date & Time",
      cell: (entry) => {
        let arrivalTimezoneOffset = getTimezoneOffset(entry?.destinationTimeZone);
        return (
          <Typography
              sx={{
                ...textClasses.t13n,
                textAlign: "center",
                textTransform: "capitalize",
              }}
            >
              {entry?.arrivalDateTime ? convertIntoGivenTimezone(entry?.arrivalDateTime, arrivalTimezoneOffset) : "-"}
            </Typography>
        );
      },
    },
    {
      header: "Actual Arrival",
      cell: (entry) => {
        let arrivalTimezoneOffset = getTimezoneOffset(entry?.destinationTimeZone);
        return (
          <Typography
              sx={{
                ...textClasses.t13n,
                textAlign: "center",
                textTransform: "capitalize",
              }}
            >
              {entry?.trackingFlightEndTime ? convertIntoGivenTimezone(entry?.trackingFlightEndTime, arrivalTimezoneOffset) : "-"}
            </Typography>
        );
      },
    },
    {
      header: "Route Distance",
      cell: (entry) => {
          let distance = Object.keys(details)?.length ? calculateTotalDistance() : null
        return (
          <Typography
              sx={{
                ...textClasses.t13n,
                textAlign: "center",
                textTransform: "capitalize",
              }}
            >
              {distance} km
            </Typography>
        );
      },
    },
  ]

  function calculateTotalDistance() {
    const viaPoints = details?.route?.viaPoints || [];
    const routeDestinationDistance = parseFloat(details?.route?.routeDestination?.totalDistance) || 0;
    let totalDistance = routeDestinationDistance;
    
    for (let i = 0; i < viaPoints.length; i++) {
      totalDistance += parseFloat(viaPoints[i].totalDistance) || 0;
    }
  
    return totalDistance;
  }

  return (
    <>
      <Box
        width="96%"
        mx={"auto"}
        mt="3%"
        sx={{
          overflowX: "auto",
          display: "flex",
          maxHeight: "270px",
          overflowY: "auto",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1,
            width: "100%",
            margin: "auto",
            position: "relative",
            top: "10px",
          }}
        >
          {details && details?.flightDetails && details?.flightDetails?.length ? (
            <Paper
              sx={{
                height: "100%",
                width: "100%",
                overflowX: "auto",
                display: "block",
              }}
              data-testid="device-models-table"
            >
              <DataTable
                themeType={themeType}
                tableColumns={tableColumn}
                data={details?.flightDetails}
              />
            </Paper>
          ) : (
            <Box sx={{display: "flex", justifyContent: "center", alignItems: "center", mx: "auto"}}>
              <Typography variant="h6">No details found</Typography>
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
};

export default FlightDetails;
