import { Paper, TablePagination } from "@mui/material";
import React from "react";

const Table = ({
  testId,
  tableColumns = [],
  data,
  themeType,
  selectedTabName
}) => {

  const {
    textClasses,
    tableClasses,
  } = themeType; 


  const filteredData = data.filter(entry => {
    if (selectedTabName === 'threshold') {
      return entry?.limitAlert?.value !== 0;
    }
    return true;
  });

  return (
    <>
      <table style={{ ...tableClasses.table }}>
        <thead
          style={{
            backgroundColor: themeType.default.themeGray,
            position: "sticky",
            top: "-1px",
            zIndex: 1,
          }}>
          <tr className="roundedRow">
            {tableColumns?.map((column, index) => {
              return (
                <td
                  key={index}
                  style={{ ...textClasses.boldText, ...tableClasses.tableHeaderClass,...tableClasses.tableHeader, textAlign: column?.isCenter ? "center" : "start" }}
                >
                  {column?.header}
                </td>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {filteredData.length > 0 ? (
            <>
              {filteredData.map((entry, key) => (
                <tr key={key} style={{ borderBottom: "1px solid #0000002B" }}>
                  {tableColumns?.map((column, index) => (
                    <td
                      key={index}
                      style={{
                          textAlign: "center",
                          ...textClasses.normalText,
                          ...tableClasses.dark,
                          ...tableClasses.tableBodyClass,
                          textAlign: "center",
                          padding:'11px'
                        }}
                    >
                      {column?.header === "#" ? (
                        column?.cell(key)
                      ) : (
                        column?.cell(entry)
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            </>
          ) : (
            <tr sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
              <td
                component="th"
                scope="row"
                style={{
                  ...textClasses.normalText,
                  ...tableClasses.dark,
                  textAlign: "left",
                  overflowWrap: "break-word",
                  fontSize:'15px',
                  fontWeight:'600',
                  height:'70px'
                }}
              >
                Please Select Organisation..
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </>
  );
};

export default Table;
