import { Delete, Edit } from '@mui/icons-material'
// import { Typography, Grid, Card, CardHeader, CardContent, Button, TextField, Divider } from '@mui/material'
import { Breadcrumbs, Typography, Card, CardHeader, CardContent, Button, Divider, Grid, ButtonGroup, TextField, IconButton, Input, FormControl, FormLabel, FormGroup, InputLabel, Select, MenuItem, FormControlLabel, Checkbox, RadioGroup, Radio, CircularProgress, Dialog, DialogContent, Box } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import theme, { inputClasses, textClasses, buttonClasses } from '../../../static/styles/theme'
import { useParams, Link } from 'react-router-dom'
import { changeTemplateStatus, getTemplate, removeTemplates } from '../../../redux/actions'
import { getInputField, getFieldsLayout } from '../../Components/Utils'
import { getTemplateTypeName } from '../../../services/constants'
import history from '../../../services/history'
import { getCamelCaseText } from '../../../services/functions'
import loaderGif from "../../../static/images/drawer/loader.gif";
import { havePermission } from '../../../utils/permissionCheck'


const ConfirmDialog = ({ status, handleClose, selectedTemplates, refreshContent }) => {
    const dispatch = useDispatch();

    const isDraft = status && selectedTemplates.length > 0 && status == "Publish" && selectedTemplates[0].status == "drafted";

    const callBack = () => {
        handleClose();
        refreshContent();
    }
    const handleStatusChange = () => {

        let payload = {
            template_id: selectedTemplates.length == 1 ? selectedTemplates[0]._id : selectedTemplates.map(st => st._id)
        }
        if (status == "Publish") {
            payload["status"] = "published"
            dispatch(changeTemplateStatus(payload, callBack))
        }
        else if (status == "Disable") {
            payload["status"] = "disabled";
            dispatch(changeTemplateStatus(payload, callBack))
        }
        else if (status == "Delete") {
            dispatch(removeTemplates(payload, () => {
                handleClose();
                history.push("/dashboard/manage-templates");
            }))
        }

    }
    return (
        <Dialog
            open={status != null}
            onClose={handleClose}
            scroll={"paper"}
            PaperProps={{ sx: { width: "100%" } }}
            maxWidth={"sm"}
        >
            <DialogContent>
                <Grid container justifyContent={"center"} alignItems="center">
                    <Typography sx={{ ...textClasses.cardTitle }}>Are you Sure ?</Typography>
                </Grid>
                <Grid container justifyContent={"center"} alignItems="center">
                    <Typography sx={{ ...textClasses.cardTitle }}>{status} the selected template</Typography>
                </Grid>
                {/* <Grid container sx={{ mt: 1 }} spacing={1}>
                    {
                        selectedTemplates.slice(0, showAll ? size : 5).map((st, i) => {
                            return (
                                <Grid item sm={4} xs={6} key={i}>
                                    <Grid container direction={"row"} justifyContent="center" alignItems={"center"} sx={{ backgroundColor: theme.themeLightOrange, border: `1px solid ${theme.themeOrange}`, borderRadius: "12px", pl: 1, pr: 1, minHeight: "40px" }}>
                                        <Grid item sm={10} xs={8}>
                                            <Typography sx={{ ...textClasses.normalText, color: theme.themeOrange, textAlign: "start" }}>
                                                {(st.name && st.name.length > 15) ? st.name.substring(0, 15) + "..." : st.name}
                                            </Typography>

                                        </Grid>

                                    </Grid>
                                </Grid>
                            )
                        })
                    }
                    {
                        size > 5 &&
                        <>
                            <Grid container item sm={4} xs={6} justifyContent="center" alignItems={"center"}>
                                <Typography sx={{ ...textClasses.boldText, color: theme.themeOrange, textDecoration: "underline", "cursor": "pointer" }} onClick={() => setShowAll(!showAll)}>
                                    {
                                        showAll
                                            ?
                                            <>Collapse</>
                                            :
                                            <>View All ({size})</>
                                    }
                                </Typography>
                            </Grid>

                        </>
                    }
                </Grid> */}
                <Grid container sx={{ p: 1, mt: 3 }} justifyContent="center">
                    <Button variant='outlined' size='small' sx={{ ...buttonClasses.outlined, borderColor: theme.themeOrange, color: theme.themeOrange, minWidth: "150px" }} onClick={handleClose}>
                        No, Cancel
                    </Button>
                    {
                        isDraft &&
                        <Button variant='outlined' size='small' sx={{ ml: 1, ...buttonClasses.outlined, borderColor: theme.themeOrange, color: theme.themeOrange, minWidth: "150px" }} onClick={() => history.push(`/dashboard/templates/edit/${selectedTemplates[0]._id}`)}>
                            Edit & Publish
                        </Button>
                    }

                    <Button variant='contained' size='small' sx={{ ml: 1, ...buttonClasses.small, backgroundColor: theme.themeOrange, minWidth: "150px" }} disabled={selectedTemplates.length <= 0} onClick={handleStatusChange}>
                        Yes, {status}
                    </Button>
                </Grid>
            </DialogContent>
        </Dialog>
    )
}



const templateSection = (sectionIndex, sectionName, fields) => {
    let name;
    name = sectionName
    if (fields && fields.length > 0) {
        const layout = getFieldsLayout(fields, true);
        return (
            <div key={sectionIndex}>
                <Typography sx={{ mb: 1 }}>{sectionIndex}. {name}</Typography>
                <Grid container spacing={2}>
                    {
                        layout.single.map((field, i) => {
                            return (
                                <Grid container item xs={6} sm={4} justifyContent={"center"} alignItems="center" key={i}>
                                    {
                                        field
                                    }
                                </Grid>
                            )
                        })
                    }
                </Grid>
                <Grid container spacing={2} sx={{ mt: 1 }}>
                    {
                        layout.multi.map((field, i) => {
                            return (
                                <Grid container item xs={6} sm={4} justifyContent={"center"} alignItems="center" key={i}>
                                    {
                                        // getInputField(field)
                                        field
                                    }
                                </Grid>
                            )
                        })
                    }
                </Grid>
                <Divider sx={{ mt: 3, mb: 1 }} />
            </div>
        )
    }
}

export default function ViewTemplate() {
    const { templateId } = useParams();
    const template = useSelector(state => state.templates.viewTemplateObj)
    const userDetails = useSelector((state) => state.auth.currentUserDetail);
    // const selectedSubTemplate = useSelector(state => state.canvas.selectedSubTemplate)
    // console.log('selectedSubTemplate---',selectedSubTemplate);
    const [loader, setLoader] = useState(false);
    const [actionConfirmModal, setActionConfirmModal] = useState(null);
    const dispatch = useDispatch();

    const getSectionAttributes = (sectionType) => {
        if (template) {
            const sections = template.otherTemplate.filter(t => t.templateType == sectionType)
            if (sections.length >= 1) {
                return sections[0];
            }
        }
        return null
    }

    const filterTemplateList = () => {
        let filteredArr = template.attributes.filter(f => f.templateType === template.templateType) 
        return filteredArr
    }     
    
    const handleCloseAction = () => {
        setActionConfirmModal(null);
    }

    const refreshContent = () => {
        setLoader(true);
        setActionConfirmModal(null);
        dispatch(getTemplate({ template_id: templateId }, () => {
            setLoader(false);
        }));
    }

    useEffect(() => {
        refreshContent();
    }, [])

    return (
        <>
            <Grid>
                <ConfirmDialog status={actionConfirmModal} handleClose={handleCloseAction} selectedTemplates={template ? [template] : []} refreshContent={refreshContent} />
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Box>
                        <Typography sx={{ ...textClasses.cardTitle }}>Manage Templates</Typography>
                        <Breadcrumbs sx={{ mb: 1 }}>
                            <Typography sx={{ ...textClasses.normalText, fontSize: "12px", color: theme.themeOrange }}>
                                <Link style={{ color: theme.themeOrange, textDecoration: "none" }} to="/dashboard/templates">
                                    Manage Templates
                                </Link>
                            </Typography>
                            {/* // font weight */}
                            <Typography sx={{ ...textClasses.normalText, fontSize: "12px" }} >View Template - {getTemplateTypeName(template ? template.templateType : "")}</Typography>
                        </Breadcrumbs>
                    </Box>
                    {/* <Button sx={{...buttonClasses.lynkitBlackFill}} onClick={() => history.goBack()}>Back</Button> */}
                </Box>
                {
                    !loader && template ?
                        <Card sx={{ mt: 2, width: "100%", borderRadius: "10px", border: `1px solid ${theme.themeOrange}` }}>
                            <CardHeader
                                sx={
                                    {
                                        backgroundColor: theme.themeLightOrange,
                                        p: 1,
                                        borderBottom: `1px solid ${theme.themeOrange}`

                                    }
                                }
                                title={
                                    <Typography sx={{ color: theme.themeOrange, ...textClasses.cardTitle, fontSize: "20px" }}>
                                        {getTemplateTypeName(template ? template.templateType : "")} - {getCamelCaseText(template !== null ? template?.name : "")}
                                    </Typography>
                                }
                                action={
                                    <>
                                        {havePermission(userDetails, "dynamicTemplates", "deleteTemplate") && (
                                            <Button variant='outlined' color='inherit' size='small' sx={{ fontSize: "14px", mr: 1, mt: "5px", backgroundColor: "white", textTransform: "none" }} onClick={() => setActionConfirmModal("Delete")}>
                                                Delete
                                            </Button>
                                        )}
                                        {
                                            havePermission(userDetails, "dynamicTemplates", "disableTemplate") && template && template.status == "published" &&
                                            <Button variant="outlined" color="inherit" size="small" sx={{ fontSize: "14px", mr: 1, mt: "5px", backgroundColor: "white", textTransform: "none" }} onClick={() => setActionConfirmModal("Disable")}>
                                                Disable
                                            </Button>
                                        }
                                        {
                                            havePermission(userDetails, "dynamicTemplates", "editTemplate") && template && (template.status == "drafted" || template.status == "disabled") &&
                                            <Button variant="outlined" color="inherit" size="small" sx={{ fontSize: "14px", mr: 1, mt: "5px", backgroundColor: "white", textTransform: "none" }} onClick={() => setActionConfirmModal("Publish")}>
                                                Publish
                                            </Button>

                                        }
                                        {havePermission(userDetails, "dynamicTemplates", "editTemplate") && (
                                            <Button variant="outlined" color="inherit" size="small" sx={{ fontSize: "14px", mr: 1, mt: "5px", backgroundColor: "white", textTransform: "none", borderColor: theme.themeOrange, color: theme.themeOrange }} onClick={() => history.push(`/dashboard/templates/edit/${template._id}`)}>
                                                Edit
                                            </Button>
                                        )}
                                    </>

                                }
                            />
                            <CardContent>
                                {
                                    templateSection(1, "Details", filterTemplateList())
                                }
                            </CardContent>
                        </Card>
                        :
                        <Grid container direction={"column"} justifyContent={"center"} alignItems="center" sx={{ p: 5 }}>
                            <Grid item >
                            <img src={loaderGif} />
                            </Grid>
                        </Grid>

                }

            </Grid>
        </>
    )
}



