import React, { useState, useEffect, Fragment } from "react";
import Panel from "../Monitoring/Components/Panel";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  FormGroup,
} from "reactstrap";
// import FilterForm from "./Filter/index";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import Map from "./Map/index";
import {
  getLocation,
  saveGeofenceMonitoring,
} from "../../../redux/actions/Monitoring";
import "./style.css";
import moment from "moment";
import { useSelector } from "react-redux";
import { Box, Button, FormControl, Grid } from "@mui/material";
import CustomSelect from "../../../components/FormUI/CustomSelect";
import {
  dateTimePickerSlotProps,
  selectStylesOverride,
} from "../../../utils/util";
import { DateTimePicker } from "@mui/x-date-pickers";

var toastObj = {
  position: "bottom-center",
  autoClose: 5003,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  // progress: undefined,
  theme: "dark",
};
const initialDetailsForm = {
  name: "",
  description: "",
};
var xyz = [];
const Area_mapping = () => {
  const dispatch = useDispatch();

  const [isAreaCreated, setIsAreaCreated] = useState(false);
  const [array, setArray] = useState([]);
  const [alertForm, setAlertForm] = useState({
    type: "tampered",
    start: new Date(),
    end: new Date(),
    // start: moment().format("YYYY-MM-DDTHH:MM"),
    // end: moment().format("YYYY-MM-DDTHH:MM"),
  });
  const [detailsForm, setDetailsForm] = useState(initialDetailsForm);
  const [modal, setModal] = useState(false);

  const { monitoringLocation, mapviewAlerts, loading } = useSelector(
    (content) => content.monitoring
  );
  const isDarkThemeEnabledSelector = useSelector(
    (state) => state.theme.themeDark
  );
  const themeType = isDarkThemeEnabledSelector
    ? require("../../../static/styles/darktheme")
    : require("../../../static/styles/theme");

  const { buttonClasses, textClasses } = themeType;

  const onFilterFormChange = (data) => {
    const { name, value } = data;
    // console.log(name, value);
    setAlertForm((f) => ({ ...f, [name]: value }));
  };

  const onFilterFormSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    let payload = {...alertForm}
    payload.start =  moment(payload.start).format("YYYY-MM-DDTHH:MM")
    payload.end =  moment(payload.end).format("YYYY-MM-DDTHH:MM")
    dispatch(getLocation(payload));

    // console.log(alertForm);

    // dispatch(getMapviewAlerts(alertForm));
  };

  const createArea = () => {
    setIsAreaCreated(true);
  };

  const saveAllDetails = () => {
    // // console.log("all geo array == > ", array);
    // // console.log('start date ', form.start);
    // var date = new Date(form.start);
    // var months = date.toLocaleString('default', { month: 'long' })
    // // console.log('months==>', months);
    dispatch(
      saveGeofenceMonitoring({
        name: detailsForm.name,
        type: alertForm.type,
        description: detailsForm.description,
        geofence: {
          type: "FeatureCollection",
          features: array,
        },
      })
    );
    xyz = [];
    setArray(xyz);
    setIsAreaCreated(false);
    // setName("");
    // setDescription("");
    toggle();
  };

  const addGeofence = (geofence) => {
    setArray((a) => [...a, geofence]);
    toast.success("Created successfully", toastObj);
  };
  const removeGeofence = (id) => {
    setArray((a) => a.filter((feat) => feat.id !== id));
  };

  const clearDrawing = (data) => {
    if (data.map.current) {
      data.map.current.removeControls(data.drwaing);
    }
  };

  const toggle = () => setModal(!modal);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setDetailsForm((df) => ({ ...df, [name]: value }));
  };

  return (
    <>
      {/* <Loader showOn={loader} /> */}
      <Grid container id="settingsDashboard__container">
        <Grid item xs={12} mb={2}>
          <Panel>
            <Box display="flex" justifyContent="space-between">
              <FilterForm
                onSubmit={onFilterFormSubmit}
                form={alertForm}
                onChange={onFilterFormChange}
                themeType={themeType}
                selectStylesOverride={selectStylesOverride(
                  isDarkThemeEnabledSelector
                )}
                isDar={isDarkThemeEnabledSelector}
              />
              <Box
                display="flex"
                justifyContent="end"
                alignItems="center"
                gap={1}
              >
                <Button
                  variant="contained"
                  sx={{ ...buttonClasses.lynkitBlackFill, height: "32px" }}
                  onClick={createArea}
                  disabled={monitoringLocation.length < 1}
                >
                  Create Area
                </Button>
                <Button
                  variant="contained"
                  sx={{ ...buttonClasses.lynkitOrangeFill }}
                  disabled={array.length < 1}
                  onClick={toggle}
                >
                  Save
                </Button>
              </Box>
            </Box>
          </Panel>
        </Grid>
        <Grid item xs={12}>
          <div className="dashboard-main-wrapper">
            <Map
              addGeofence={addGeofence}
              removeGeofence={removeGeofence}
              clearDrawing={clearDrawing}
              alertType={alertForm.type}
              isAreaCreated={isAreaCreated}
            />
          </div>
        </Grid>

        {array.length ? (
          <div className="createdAreasListing">
            <h6 className="m-0 p-1">Area Listing</h6>
            <table>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Color</th>
                </tr>
              </thead>
              <tbody>
                {array.map((geofence, index) => {
                  const { description, color } = geofence.properties;
                  return (
                    <tr>
                      <td>{description}</td>
                      <td>
                        <div
                          style={{
                            backgroundColor: color,
                            height: "20px",
                            width: "20px",
                            border: "none",
                            borderRadius: "4px",
                          }}
                        ></div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        ) : null}
      </Grid>
      <Modal isOpen={modal} toggle={toggle} centered>
        <ModalHeader
          color="primary"
          className="bg-primary py-2"
          toggle={toggle}
        >
          Details
        </ModalHeader>
        <ModalBody>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <FormGroup className="mb-0">
              <Label>Name</Label>
              <Input
                type="text"
                className="form-control"
                name="name"
                onChange={handleChange}
                value={detailsForm.name}
              />
            </FormGroup>
            <FormGroup className="mb-0">
              <Label>description</Label>
              <Input
                type="textarea"
                className="form-control"
                name="description"
                rows="1"
                onChange={handleChange}
                value={detailsForm.description}
              />
            </FormGroup>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            disabled={detailsForm.name === ""}
            onClick={saveAllDetails}
          >
            Submit
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default Area_mapping;

const FilterForm = ({
  onSubmit,
  form,
  onChange,
  themeType,
  selectStylesOverride,
  isDark,
}) => {
  const { buttonClasses, inputClasses } = themeType;
  return (
    <>
      <Box>
        <form
          onSubmit={onSubmit}
          style={{ display: "flex", justifyContent: "flex-start", gap: "0.5rem" }}
        >
          <Box width="100%">
            <CustomSelect
              selectStylesOverride={selectStylesOverride}
              label="Select Alert Type"
              value={form.type}
              onChange={(e, newVal) => {
                onChange({ name: "type", value: newVal?.value });
              }}
              id="monitoring-dashboard__type-filter"
              options={[{ label: "Tampered", value: "tampered" }]}
              getOptionLabel={option => {
                if (typeof option === "string") {
                  return (
                    [{ label: "Tampered", value: "tampered" }]?.find((_) => _?.value == option)
                      ?.label || ""
                  );
                } else {
                  return option?.label || "";
                }
              }}
            />
          </Box>
          <Box width="100%">
            <DateTimePicker
              label="From"
              value={form.start || new Date()}
              onChange={(date) => onChange({ name: "start", value: date })}
              className="customDatePicker"
              sx={{
                ...selectStylesOverride,
              }}
              slotProps={dateTimePickerSlotProps(inputClasses, isDark)}
            />
          </Box>
          <Box width="100%">
            <DateTimePicker
              label="To"
              value={form.end || new Date()}
              onChange={(date) => onChange({ name: "end", value: date })}
              className="customDatePicker"
              sx={{
                ...selectStylesOverride,
              }}
              slotProps={dateTimePickerSlotProps(inputClasses, isDark)}
            />
          </Box>
          {/* <FormControl className="d-flex gap-half aItemsCenter mb-0 ">
            <Label for="exampleDatetime" className="mb-0 clr-darkgrey">
              From
            </Label>
            <Input
              id="exampleDatetime"
              name="start"
              placeholder="datetime placeholder"
              type="datetime-local"
              bsSize="sm"
              className=" border clr-primary "
              value={form.start}
              onChange={onChange}
            />
          </FormControl> */}
          {/* <FormControl className="d-flex gap-half aItemsCenter mb-0">
            <Label for="exampleDatetime" className="mb-0 clr-darkgrey ">
              To
            </Label>
            <Input
              id="exampleDatetime"
              name="end"
              placeholder="datetime placeholder"
              type="datetime-local"
              bsSize="sm"
              className="border clr-primary"
              value={form.end}
              onChange={onChange}
            />
          </FormControl> */}
          <Button
            sx={{ ...buttonClasses?.lynkitOrangeEmpty }}
            disabled={!form.type}
            onClick={onSubmit}
          >
            Apply
          </Button>
        </form>
      </Box>
    </>
  );
};

var temp = [
  {
    id: "d3a397ef73c508eb61377f05a91e10f1",
    type: "Feature",
    properties: {
      type: "highRisk",
      description: "n1",
      color: "#563d7c",
    },
    geometry: {
      coordinates: [
        [
          [77.23254931945854, 28.61524552763403],
          [77.2331291728184, 28.611450890424592],
          [77.23792614152649, 28.61344077825271],
          [77.23755714393405, 28.61621730291624],
          [77.23254931945854, 28.61524552763403],
        ],
      ],
      type: "Polygon",
    },
  },
  {
    id: "d3a397ef73c508eb61377f05a91e10f1",
    type: "Feature",
    properties: {
      type: "highRisk",
      description: "n2",
      color: "#563d7c",
    },
    geometry: {
      coordinates: [
        [
          [77.23254931945854, 28.61524552763403],
          [77.2331291728184, 28.611450890424592],
          [77.23792614152649, 28.61344077825271],
          [77.23755714393405, 28.61621730291624],
          [77.23254931945854, 28.61524552763403],
        ],
      ],
      type: "Polygon",
    },
  },
];
