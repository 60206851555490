import { Close } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
} from "@mui/material";
import React from "react";
import DevicesTable from "../components/DevicesTable";

const NetworkAdminsUpdate = ({
  open,
  handleClose,
  themeType,
  networkTableColumns,
  tableData,
  submitAction,
  allDevicePermissions,
  selectStylesOverride,
}) => {
  const { buttonClasses, inputClasses, textClasses, tableClasses } = themeType;

  const handleCloseModal = (event, reason) => {
    if (reason && reason == "backdropClick") return;
    handleClose();
  };

  return (
    <Dialog
      disableEscapeKeyDown
      open={open}
      onClose={handleCloseModal}
      maxWidth="xl"
      fullWidth
      sx={{
        "& .MuiPaper-root ": {
          background: tableClasses.dark.backgroundColor,
        },
      }}
    >
      <DialogTitle sx={{ m: 0, p: 2, ...textClasses?.cardTitle }}>
        Network Admin Permission(s)
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <Close />
      </IconButton>

      <DialogContent dividers sx={{ overflow: 'hidden'}}>
        <Grid container>
          <Grid item xs={12} sx={{maxHeight:'500px', overflow:'scroll'}}>
            <DevicesTable
              themeType={themeType}
              tableColumns={networkTableColumns}
              showAllColumns={true}
              data={tableData ?? []}
            />
          </Grid>
        
        </Grid>
      
      </DialogContent>
      <Grid
            item
            xs={12}
            display="flex"
            justifyContent="center"
            alignItems="center"
            gap="7px"
            mt={1}
            mb={1}
          >
            <Button
              variant="outlined"
              sx={{
                ...buttonClasses?.lynkitBlackFill,
                minHeight: "36px",
                minWidth: "140px",
              }}
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              sx={{
                ...buttonClasses?.lynkitOrangeFill,
                minHeight: "36px",
                minWidth: "140px",
              }}
              onClick={submitAction}
            >
              Update
            </Button>
          </Grid>
    </Dialog>
  );
};

export default NetworkAdminsUpdate;
