import React from 'react';
import ReactDOM from 'react-dom';
import App from './components';
import './static/css/global.css';
import './static/css/util.css';
import reportWebVitals from './reportWebVitals';
import "mapbox-gl/dist/mapbox-gl.css";
import { ErrorBoundary } from 'react-error-boundary';
import EmptyPage from './views/Modules/EmptyPage';

ReactDOM.render(
  <ErrorBoundary
    fallback={
      <div style={{  height:'100vh', display:'flex',justifyContent:'cebter', alignItems:'center' }} >
        <EmptyPage
          body="Something went wrong..."
        />
      </div>
    }>
    <App />
  </ErrorBoundary>,
  document.getElementById('root')
);

reportWebVitals();
