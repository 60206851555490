import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import { Controller, useForm, useWatch } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import maldives from "../../../src/assets/img/maldivesLock.webp";
import frame from "../../../src/assets/img/Framee.png";
import lynktracLogo from "../../../src/assets/img/logo/lynktrac_5.webp";
import ReCAPTCHA from "react-google-recaptcha";

import {
  Grid,
  Card,
  Typography,
  FormHelperText,
  Button,
  TextField,
  FormControl,
  Box,
  CardContent,
  CircularProgress,
} from "@mui/material";
import "../../static/css/login.css";
import Logo from "../../static/images/login/Lynk.webp";
import IconButton from "@mui/material/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import theme, {
  inputClasses,
  cardClasses,
  textClasses,
  buttonClasses,
} from "../../static/styles/theme";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import { valid_email } from "../../services/validation";
import { CustomInput } from "../../components/FormUI";
import { encryptUsingAES256, selectStylesOverride } from "../../utils/util";
import { showToast } from "../../services/functions";
import { getOTPforResetPassword, updatePassword } from "../../redux/actions";
import { ArrowBack, Close } from "@mui/icons-material";
import { io } from "socket.io-client";
import history from "../../services/history";
import CustomSubmitBtn from "../../components/FormUI/CustomSubmitBtn";

const Login = (props) => {
  const captchaRef = useRef(null);
  const {
    control,
    reset,
    register,
    handleSubmit,
    setValue,
    getValues,
    setError,
    formState: { errors },
  } = useForm();
  const [showPassword, setShowPassword] = React.useState(false);
  const [openResetPassForm, setOpenResetPassForm] = React.useState(false);
  const [captchaValue, setCaptchaValue] = useState(null);
  const [emailMobile, setEmailMobile] = React.useState(null);
  const [showPasswordVisibility, setShowPasswordVisibility] =
    React.useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);
  const [seconds, setSeconds] = useState(60); // Initial value for 1 minute
  const [timerActive, setTimerActive] = useState(true);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const dispatch = useDispatch();
  const isDarkThemeEnabledSelector = useSelector(
    (state) => state.theme.themeDark
  );

  const passwordEntered = useWatch({
    control: control,
    name: "newPassword",
  });

  const handleBackBtnClick = () => {
    reset();
    props.setOtpLoading(false);
    if (props.otpSuccess) {
      props.setOtpSuccess(false);
    } else {
      setOpenResetPassForm(false);
    }
  };

  const handleLogin = (data) => {
    props.setLoginLoading(true);
    if (data.email === "") {
      delete data.email;
    }
    if (data.mobile === "") {
      delete data.mobile;
    }
    const payload = {
      ...data,
      projectId: "LYNKTRAC",
      type: "web",
      captchaValue: captchaValue,
    };
    const encryptedData = encryptUsingAES256(payload);
    encryptedData
      ?.then((res) => {
        if (res?.isSuccess) {
          props._onLogin({ encryptedString: res?.data });
        }
      })
      ?.catch(() => props.setLoginLoading(false));
  };

  const handleSendOtp = (payload) => {
    if (payload) {
      try {
        props.setOtpLoading(true);
        dispatch(
          getOTPforResetPassword(
            payload,
            (res) => {
              if (res) {
                reset();
                setValue("email_mobile", undefined);
                setValue("otp", undefined);
                props.setOtpLoading(false);
                props.setOtpSuccess(true);
                setSeconds(60);
                setTimerActive(true);
              }
            },
            () => {
              props.setOtpLoading(false);
            }
          )
        );
      } catch (e) {
        props.setOtpLoading(false);
      }
    }
  };

  const handleGetOTP = (data) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const mobileRegex = /^[0-9]{10}$/;
    let payload = null;

    if (emailRegex.test(data?.email_mobile)) {
      payload = {
        email: data?.email_mobile,
        projectId: "LYNKTRAC",
      };
      setEmailMobile({
        isEmail: true,
        value: data?.email_mobile,
      });
    } else if (mobileRegex.test(data?.email_mobile)) {
      payload = {
        mobile: data?.email_mobile,
        projectId: "LYNKTRAC",
      };
      setEmailMobile({
        isEmail: false,
        value: data?.email_mobile,
      });
    } else {
      setError("email_mobile", {
        type: "manual",
        message: "Please enter a valid email or mobile number",
      });
      setEmailMobile(null);
      return;
    }

    handleSendOtp(payload);
  };

  const handleSubmitOTP = (data) => {
    if (data) {
      props.setOtpLoading(true);
      const payload = {
        otp: data?.otp,
        projectId: "LYNKTRAC",
        newPassword: data?.newPassword,
      };

      if (emailMobile && emailMobile?.isEmail) {
        payload.email = emailMobile?.value;
      } else if (emailMobile && !emailMobile?.isEmail) {
        payload.mobile = emailMobile?.value;
      }

      dispatch(
        updatePassword(
          payload,
          (res) => {
            if (res) {
              //redirect to login card and reset everything.
              reset();
              setOpenResetPassForm(false);
              props.setOtpSuccess(false);
              props.setOtpLoading(false);
            }
          },
          () => {
            props.setOtpLoading(false);
          }
        )
      );
    }
  };

  const handleResendClick = () => {
    const payload = {
      projectId: "LYNKTRAC",
    };
    reset();

    if (emailMobile && emailMobile?.isEmail) {
      payload.email = emailMobile?.value;
    } else if (emailMobile && !emailMobile?.isEmail) {
      payload.mobile = emailMobile?.value;
    }

    handleSendOtp(payload);
  };

  React.useEffect(() => {
    let timer;

    if (props.otpSuccess && timerActive && seconds > 0) {
      timer = setInterval(() => {
        setSeconds((prevSeconds) => prevSeconds - 1);
      }, 1000);
    }

    return () => clearInterval(timer);
  }, [props.otpSuccess, timerActive, seconds]);

  const _handleChange = (e) => {
    let value = e.target.value.replace(/\s/g, "");
    if (value.includes("@")) {
      setValue("email", value);
      setValue("mobile", "");
    } else {
      setValue("mobile", value);
      setValue("email", "");
    }
  };

  const onCaptchaChange = (value) => {
    setCaptchaValue(value);
  };

  return (
    <>
      <Grid container>
        <Grid item sm={12}>
          <img
            className="bg-image"
            style={{ width: "100vw", height: "100vh", objectFit: "cover" }}
            src={maldives}
            alt=""
          />
        </Grid>
        <Grid
          container
          item
          sm={6}
          xs={12}
          direction={"column"}
          sx={{
            height: { sm: "100vh" },
            position: "absolute",
            top: { xs: "10px", sm: "0%" },
          }}
          // sx={{ height: {sm: "100vh"} }}
        >
          <Grid
            sx={{
              position: "relative",
              top: "65%",
              width: "auto",
              left: { xs: "unset", sm: "5%", lg: "15%" },
            }}
          >
            <Box
              display="flex"
              flexDirection="column"
              sx={{ alignItems: { xs: "center", sm: "flex-start" } }}
            >
              <img
                style={{ width: "150px", height: "32px" }}
                src={lynktracLogo}
                alt=""
              />
              <Typography
                sx={{
                  color: "white",
                  fontSize: { xs: "15px", sm: "19px" },
                  fontWeight: "700",
                  paddingTop: "6px",
                  textAlign: "center",
                  display: { xs: "block", sm: "none" },
                }}
                className="typed"
              >
                Start your safest & secure journey now.
              </Typography>
              <Typography
                className="typed"
                sx={{
                  color: "white",
                  fontSize: { xs: "15px", sm: "19px" },
                  fontWeight: "700",
                  paddingTop: "6px",
                  textAlign: "left",
                  display: { xs: "none", sm: "block" },
                }}
              >
                Start your safest & secure journey now.
              </Typography>
            </Box>
          </Grid>
        </Grid>

        <Grid
          container
          item
          sm={6}
          xs={12}
          justifyContent="center"
          alignItems="center"
          direction={"column"}
          minWidth="250px"
          // sx={{ height: {sm: "100vh"} }}
          sx={{
            height: { sm: "100vh" },
            position: "absolute",
            top: { xs: "30%", sm: "unset" },
            left: { xs: "50%", sm: "50%" },
            right: { xs: "50%", sm: "unset" },
            transform: { xs: "translateX(-50%)", sm: "unset" },
          }}
        >
          {!openResetPassForm && !props.otpSuccess && (
            <Card
              sx={{
                ml: { xs: "10px", sm: 2, lg: 13 },
                mr: { xs: "10px", sm: 2, lg: 13 },
                maxWidth: "530px",
                ...cardClasses.basic,
              }}
              className="mui-custom-card"
            >
              <Grid
                container
                sx={{ p: 2 }}
                alignItems={"center"}
                justifyContent={"center"}
              >
                <Grid item sm={12} xs={12}>
                  <Typography
                    align="center"
                    sx={{
                      fontWeight: "600",
                      fontSize: "20px",
                      lineHeight: "27px",
                    }}
                  >
                    Get Started With
                  </Typography>
                </Grid>
                <Grid item sx={{ mt: 1 }}>
                  <img src={Logo} height="48px" />
                </Grid>
                <Grid item sm={12} xs={12} sx={{ mt: 1 }}>
                  <Typography
                    align="center"
                    visibility="hidden"
                    sx={{
                      fontWeight: "600",
                      fontSize: "20px",
                      lineHeight: "27px",
                    }}
                  >
                    Warehouse Management System
                  </Typography>
                </Grid>
              </Grid>

              <Grid
                sx={{ pl: 8, pr: 8, mt: 2 }}
                container
                direction={"row"}
                alignItems={"center"}
                justifyContent={"center"}
              >
                <FormControl
                  variant="outlined"
                  size="small"
                  fullWidth
                  sx={{
                    ...selectStylesOverride(isDarkThemeEnabledSelector),
                    borderRadius: "12px",
                  }}
                >
                  <TextField
                    variant="outlined"
                    size="small"
                    label="Email / Mobile Number"
                    fullWidth
                    placeholder="Enter Your Email or Mobile Number"
                    onChange={(e) => _handleChange(e)}
                  />
                </FormControl>
              </Grid>
              <Grid
                sx={{
                  pl: 8,
                  pr: 8,
                  mt: 2,
                  ...selectStylesOverride(isDarkThemeEnabledSelector),
                }}
                container
                direction={"row"}
                alignItems={"center"}
                justifyContent={"center"}
              >
                <FormControl
                  fullWidth
                  variant="outlined"
                  size="small"
                  // sx={inputClasses.textField}
                >
                  <InputLabel htmlFor="outlined-adornment-password">
                    Password
                  </InputLabel>
                  <OutlinedInput
                    error={errors.password ? true : false}
                    {...register("password", { required: true })}
                    id="outlined-adornment-password"
                    size="small"
                    type={showPassword ? "text" : "password"}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        e.preventDefault(); // Prevent form submission
                        handleLogin(getValues()); // Call the handleLogin function
                      }
                    }}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                    label="Password"
                    fullWidth
                    placeholder="Enter Password"
                  />
                  {errors.password && (
                    <FormHelperText
                      id="outlined-weight-helper-text"
                      style={{ color: "#d32f2f" }}
                    >
                      Please enter password
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid
                container
                item
                sx={{ pr: 8, mt: 1 }}
                justifyContent="right"
                alignItems="right"
              >
                <Typography
                  sx={{
                    mt: 0,
                    ...textClasses.boldText,
                    cursor: "pointer",
                    "&:hover": {
                      color: theme.themeOrange,
                    },
                  }}
                  onClick={(e) => {
                    e.preventDefault();
                    setOpenResetPassForm(true);
                  }}
                >
                  Forgot password
                </Typography>
              </Grid>

              {/* <Grid
                container
                item
                sx={{ pl: 8, mt: 1 }}
                justifyContent="left"
                alignItems="left"
              >
                <ReCAPTCHA
                  sitekey="6LdNCMgpAAAAALaAfWG6FDTvdz9MwW4cOiV4mN_4"  // for prod
                  onChange={onCaptchaChange}
                  ref={captchaRef}
                />
              </Grid> */}

              <Grid container item sx={{ pl: 8, pr: 8, mt: 2, mb: 2 }}>
                <CustomSubmitBtn
                  fullWidth
                  size="small"
                  sx={buttonClasses.orange}
                  onClick={handleSubmit(handleLogin)}
                  label="Login"
                  disabled={props.loginLoading}
                  loading={props.loginLoading}
                />
                <Box display="flex" alignItems="center" gap={1}>
                  {/* <Typography
                    sx={{ mt: 2, mb: 2, ...textClasses.boldText }}
                    align="left"
                    variant="caption"
                  >
                    Dont have an account ?{" "}
                  </Typography>
                  <Typography
                    sx={{ mt: 2, mb: 2, ...textClasses.boldText, color: "#FF7200", cursor: "pointer" }}
                    align="left"
                    variant="caption"
                    onClick={(e) => {
                      e?.preventDefault();
                      history?.push("/register");
                    }}
                  >
                    Sign Up.
                  </Typography> */}
                </Box>
              </Grid>
            </Card>
          )}
          {openResetPassForm && (
            <Card
              sx={{
                ml: { xs: "10px", sm: 2, lg: 13 },
                mr: { xs: "10px", sm: 2, lg: 13 },
                minWidth: "300px",
                height: "auto",
                width: "60%",
                ...cardClasses.basic,
              }}
              className="mui-custom-card"
            >
              <CardContent>
                <Box
                  mb={2}
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <IconButton
                    sx={{ marginRight: "auto" }}
                    onClick={handleBackBtnClick}
                  >
                    <ArrowBack />
                  </IconButton>
                  <Typography
                    sx={{
                      fontSize: "17px",
                      fontWeight: "700",
                      width: "100%",
                      textAlign: "center",
                    }}
                  >
                    Reset Password
                  </Typography>
                </Box>
                {!props.otpSuccess ? (
                  <Grid container my={3}>
                    <Grid item xs={12} mb={2}>
                      <Controller
                        name={"email_mobile"}
                        control={control}
                        rules={{
                          required: {
                            value: true,
                            message: "Please enter email / mobile number first",
                          },
                        }}
                        render={({ field: { onChange, value, name } }) => {
                          return (
                            <CustomInput
                              name={name}
                              errors={errors}
                              value={value || ""}
                              onChange={onChange}
                              placeholder="Enter your email / mobile number"
                              selectStylesOverride={selectStylesOverride(false)}
                            />
                          );
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Button
                        fullWidth
                        size="small"
                        disabled={props.otpLoading}
                        sx={buttonClasses.lynkitOrangeFill}
                        onClick={handleSubmit(handleGetOTP)}
                      >
                        {props.otpLoading ? (
                          <CircularProgress size={20} color="inherit" />
                        ) : (
                          "Get OTP"
                        )}
                      </Button>
                    </Grid>
                  </Grid>
                ) : (
                  <Grid container my={3}>
                    <Grid item xs={12} mb={2}>
                      <Controller
                        name={"otp"}
                        control={control}
                        rules={{
                          required: {
                            value: true,
                            message: "Please enter OTP",
                          },
                          pattern: {
                            value: /^\d{6}$/,
                            message: "Invalid OTP",
                          },
                        }}
                        render={({ field: { onChange, value, name } }) => {
                          return (
                            <CustomInput
                              inputProps={{ maxLength: 6 }}
                              name={name}
                              errors={errors}
                              value={value || ""}
                              onChange={onChange}
                              placeholder="Enter OTP"
                              selectStylesOverride={selectStylesOverride(false)}
                            />
                          );
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} mb={2}>
                      <Controller
                        name={"newPassword"}
                        control={control}
                        rules={{
                          required: {
                            value: true,
                            message: "Please enter password",
                          },
                          pattern: {
                            value:
                              /^(?=.*[A-Z])(?=.*[a-z])(?=.*[!@#$%^&*])(?=.*[0-9]).{8,}$/,
                            message:
                              "Password must be 8 digit long containing at least one digit (0-9) one uppercase letter (A-Z) one lowercase letter (a-z) and one special charater",
                          },
                        }}
                        render={({ field: { onChange, value, name } }) => {
                          return (
                            // <CustomInput
                            //   name={name}
                            //   errors={errors}
                            //   value={value || ""}
                            //   onChange={(e) => {
                            //     onChange(e?.target?.value);
                            //     setValue("newPassword", e?.target?.value);
                            //   }}
                            //   placeholder="Enter Password"
                            //   selectStylesOverride={selectStylesOverride(false)}
                            // />
                            <FormControl
                              variant="outlined"
                              size="small"
                              fullWidth
                              sx={{
                                ...selectStylesOverride(false),
                              }}
                            >
                              <InputLabel
                                htmlFor="outlined-adornment-password"
                                sx={{ fontSize: "0.8rem" }}
                              >
                                Enter Password
                              </InputLabel>
                              <OutlinedInput
                                error={errors[name] ? true : false}
                                id="outlined-adornment-password"
                                value={value || ""}
                                onChange={(e) => {
                                  onChange(e?.target?.value);
                                  setValue("newPassword", e?.target?.value);
                                }}
                                type={
                                  showPasswordVisibility ? "text" : "password"
                                }
                                endAdornment={
                                  <InputAdornment position="end">
                                    <IconButton
                                      aria-label="toggle password visibility"
                                      onClick={() =>
                                        setShowPasswordVisibility(
                                          !showPasswordVisibility
                                        )
                                      }
                                      onMouseDown={(e) => e.preventDefault()}
                                      edge="end"
                                    >
                                      {!showPasswordVisibility ? (
                                        <Visibility sx={{ fontSize: "1rem" }} />
                                      ) : (
                                        <VisibilityOff
                                          sx={{ fontSize: "1rem" }}
                                        />
                                      )}
                                    </IconButton>
                                  </InputAdornment>
                                }
                                label="Enter Password"
                                fullWidth
                                placeholder="Enter Password"
                              />
                              {errors[name] && (
                                <span
                                  role="alert"
                                  style={{ color: "#FF0101", fontSize: 11 }}
                                >
                                  {errors[name]?.message}
                                </span>
                              )}
                            </FormControl>
                          );
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} mb={3}>
                      <Controller
                        name={"confirmPassword"}
                        control={control}
                        rules={{
                          required: {
                            value: passwordEntered && true,
                            message: "Please confirm your password",
                          },
                          validate: (value) =>
                            passwordEntered === value ||
                            "Please enter same password",
                        }}
                        render={({ field: { onChange, value, name } }) => {
                          return (
                            // <CustomInput
                            //   name={name}
                            //   errors={errors}
                            //   value={value || ""}
                            //   onChange={onChange}
                            //   placeholder="Confirm Password"
                            //   selectStylesOverride={selectStylesOverride(false)}
                            // />
                            <FormControl
                              variant="outlined"
                              size="small"
                              fullWidth
                              sx={{
                                ...selectStylesOverride(false),
                              }}
                            >
                              <InputLabel
                                htmlFor="outlined-adornment-password"
                                sx={{ fontSize: "0.8rem" }}
                              >
                                Confirm Password
                              </InputLabel>
                              <OutlinedInput
                                error={errors[name] ? true : false}
                                id="outlined-adornment-password"
                                value={value || ""}
                                onChange={onChange}
                                type={showConfirmPassword ? "text" : "password"}
                                endAdornment={
                                  <InputAdornment position="end">
                                    <IconButton
                                      aria-label="toggle password visibility"
                                      onClick={() =>
                                        setShowConfirmPassword(
                                          !showConfirmPassword
                                        )
                                      }
                                      onMouseDown={(e) => e.preventDefault()}
                                      edge="end"
                                    >
                                      {!showConfirmPassword ? (
                                        <Visibility sx={{ fontSize: "1rem" }} />
                                      ) : (
                                        <VisibilityOff
                                          sx={{ fontSize: "1rem" }}
                                        />
                                      )}
                                    </IconButton>
                                  </InputAdornment>
                                }
                                label="Confirm Password"
                                fullWidth
                                placeholder="Confirm Password"
                              />
                              {errors[name] && (
                                <span
                                  role="alert"
                                  style={{ color: "#FF0101", fontSize: 11 }}
                                >
                                  {errors[name]?.message}
                                </span>
                              )}
                            </FormControl>
                          );
                        }}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      mb={1}
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                    >
                      {seconds === 0 ? (
                        <Button variant="text">
                          <Typography
                            sx={{
                              ...textClasses.boldText,
                              color: theme.themeOrange,
                              textDecoration: "underline",
                              cursor: "pointer",
                              textTransform: "none",
                            }}
                            onClick={handleResendClick}
                          >
                            Resend OTP
                          </Typography>
                        </Button>
                      ) : (
                        <Typography
                          sx={{
                            ...textClasses.boldText,
                            textTransform: "none",
                          }}
                        >
                          Resend OTP in {seconds} seconds
                        </Typography>
                      )}
                    </Grid>
                    <Grid item xs={12}>
                      <Button
                        fullWidth
                        size="small"
                        disabled={props.otpLoading}
                        sx={buttonClasses.lynkitOrangeFill}
                        onClick={handleSubmit(handleSubmitOTP)}
                      >
                        {props.otpLoading ? (
                          <CircularProgress size={20} color="inherit" />
                        ) : (
                          "Submit"
                        )}
                      </Button>
                    </Grid>
                  </Grid>
                )}
              </CardContent>
            </Card>
          )}
          <Grid
            container
            item
            alignItems={"center"}
            justifyContent={"center"}
            sx={{ mt: 3 }}
          >
            <Typography
              sx={{ mr: 1, ...textClasses.normalText, color: "#827878" }}
            >
              Powered by{" "}
            </Typography>
            <img src={Logo} height="29" />
          </Grid>
        </Grid>
      </Grid>

      <ToastContainer
        position="bottom-center"
        autoClose={false}
        closeOnClick={false}
      />
    </>
  );
};

export default Login;
