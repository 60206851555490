import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button, Grid, Typography } from "@mui/material";
import CustomModel from "../../Components/CustomModels/CustomModel1";
import { showToast } from "../../../services/functions";
import { ToastContainer } from "react-toastify";
import { Controller, useForm } from "react-hook-form";
import DynamicFormRender from "../../Components/DynamicFormRender";
import {
  checkTemplateExistance,
  getEditDeviceTemplate,
} from "../../../redux/actions";
import { addDeviceAdditionalDetails } from "../../../redux/actions";
import { CustomInput } from "../../../components/FormUI";
import { selectStylesOverride } from "../../../utils/util";

function DeviceAdditionalModal(props) {
  const dispatch = useDispatch();
  const { open, hide, deviceId, refresh } = props;
  const isDarkThemeEnabledSelector = useSelector(
    (state) => state.theme.themeDark
  );
  const themeType = isDarkThemeEnabledSelector
    ? require("../../../static/styles/theme")
    : require("../../../static/styles/theme");
  const { buttonClasses } = themeType;
  const [additionalInfo, setAdditionalInfo] = useState({});
  const templateExistence = useSelector(
    (state) => state.templates.templateExistence
  );
  const { deviceDetailsTemplate } = useSelector((state) => state.deviceslist);
  const {
    control,
    formState: { errors, isSubmitting, isValid, dirtyFields },
    handleSubmit,
    reset,
    register,
    setValue,
    getValues,
  } = useForm();

  const saveEntry = () => {
    handleSubmit(
      (data) => {
        let payload = {
          ...data,
          id: deviceId,
          template_id: templateExistence?._id,
        };
        
        dispatch(
          addDeviceAdditionalDetails(payload, () => {
            hide();
            refresh();
          })

        );
      },
      (errors) => {
        let errorField = Object.keys(errors)[0];
        showToast(`${errors[errorField].message}`, true);
      }
    )();
  };

  useEffect(() => {
    if (open && deviceId) {
      let payload = { category: "device" };
      dispatch(checkTemplateExistance(payload));
      dispatch(getEditDeviceTemplate({ id: deviceId }));
    }
  }, [open, deviceId]);

  useEffect(() => {
    setAdditionalInfo(deviceDetailsTemplate);
  }, [deviceDetailsTemplate]);

  useEffect(() => {
    reset();
    if (additionalInfo?.dynamicData) {
      for (const key in additionalInfo && additionalInfo.dynamicData) {
        setValue(key, additionalInfo.dynamicData[key]);
      }
    }
  }, [additionalInfo]);

  return (
    <>
      <CustomModel
        autoClose={false}
        show={open || false}
        onClose={() => hide()}
        minWidth={770}
        maxWidth={770}
        pb={3}
        pl={3}
        pr={3}
        maxHeight={"80vh"}
        childrenMaxHeight={"80vh"}
        Title="Devices Additional Information"
        viewCloseIcon={open}
      >
        <Grid container mb={2}>
          <Grid
            item
            sm={12}
            flexDirection={"column"}
            sx={{ width: "100%", height: "auto" }}
          >
            
              <form>
                <Grid
                  mt={2}
                  item
                  sm={12}
                  height="30vh"
                  xs={12}
                  style={{
                    overflowY: "auto",
                    border: "1px solid #E8E8E8",
                    overflowX: "hidden",
                    padding: "10px",
                    paddingBottom: "20px",
                  }}
                >
                  {templateExistence && Object.keys(templateExistence).length ? (
                    <DynamicFormRender
                      formHooks={{
                        register,
                        control,
                        errors,
                        handleSubmit,
                        reset,
                        setValue,
                      }}
                      templateId={
                        (templateExistence && templateExistence?._id) || ""
                      }
                    />
                    ) : (
                      <Grid
                        item
                        sm={12}
                        flexDirection={"column"}
                        sx={{
                          width: "100%",
                          height: "100px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Typography> No Template Found</Typography>
                      </Grid>
                    )}
                </Grid>
              </form>
          </Grid>
        </Grid>
        <Grid
          container
          item
          direction={"row"}
          sm={12}
          xs={12}
          sx={{ ml: 1, pb: 3 }}
        >
          <>
            <Button
              variant="outlined"
              sx={{
                size: "small",
                ...buttonClasses?.lynkitBlackFill,
                minHeight: "36px",
                minWidth: "130px",
                marginRight: "20px",
              }}
              onClick={hide}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              size="small"
              sx={{
                ...buttonClasses.lynkitOrangeFill,
                ml: 0,
                fontSize: ".7rem",
                border: "none",
                height: "37px",
                minWidth: "130px",
              }}
              onClick={() => saveEntry()}
            >
              Submit
            </Button>
          </>
        </Grid>
      </CustomModel>
      <ToastContainer
        position="bottom-center"
        autoClose={false}
        closeOnClick={false}
      />
    </>
  );
}

export default DeviceAdditionalModal;
