import { FETCH_START, FETCH_SUCCESS, FETCH_ERROR, SET_SELECTED_TEMPLATE, FETCH_WAREHOUSES, FETCH_FLOORS, FETCH_MAPPING } from "../types";
import axios,{templateAxios} from "../../services/Api";
import { showToast } from "../../services/functions";



export const getSelectedTemplate = (payload, cb) =>
{
    return (dispatch) =>
    {
        dispatch({type: FETCH_START});
        templateAxios.post('dynamicForm/getTemplateDetail',payload).then(({data}) => {
            if (data.success) {
                dispatch({ type: FETCH_SUCCESS });
                dispatch({ type: SET_SELECTED_TEMPLATE, payload: data.data})
            } 
            else {
                dispatch({ type: SET_SELECTED_TEMPLATE, payload: {}})
                dispatch({type: FETCH_ERROR, payload: data.message});
            }

            if(cb){
                cb(data?.data || {})
            }
        }).catch(function (error) {
            dispatch({type: FETCH_ERROR, payload: error.message});
            console.log("Error****:", error.message);
            if(cb){
                cb()
            }
        });
    }
};


export const getAllWarehouses = ({type}) =>
{
    return (dispatch) =>
    {
        dispatch({type: FETCH_START});
        axios.post('/company/getCompany', {
               type: type
        }).then(({data}) => {
            if (data.success) {
                callback();
            } 
            else {
                dispatch({type: FETCH_ERROR, payload: data.message});
            }
            function callback() {
                dispatch({ type: FETCH_SUCCESS });
                dispatch({ type: FETCH_WAREHOUSES, payload: data.data})
            }
        }).catch(function (error) {
            dispatch({type: FETCH_ERROR, payload: error.message});
            console.log("Error****:", error.message);
        });
    }
};

export const getWarehouseFloors = ({type, warehouseId}) =>
{
    return (dispatch) =>
    {
        dispatch({type: FETCH_START});
        axios.post('/company/getCompany', {
               type: type,
               warehouseId: warehouseId 
        }).then(({data}) => {
            if (data.success) {
                callback();
            } 
            else {
                dispatch({type: FETCH_ERROR, payload: data.message});
            }
            function callback() {
                dispatch({ type: FETCH_SUCCESS });
                dispatch({ type: FETCH_FLOORS, payload: data.data})
            }
        }).catch(function (error) {
            dispatch({type: FETCH_ERROR, payload: error.message});
            console.log("Error****:", error.message);
        });
    }
};

export const getFloorMapping = ({floorId}) =>
{
    return (dispatch) =>
    {
        dispatch({type: FETCH_START});
        axios.post('/areaMapping/getAreaMapping', {
            //    floorId: floorId,
            //    fetch_for: "mappingArea_Only",
               allAreaNameOnly: true,
               status: ["available", "partial"]
        }).then(({data}) => {
            if (data.success) {
                callback();
            } 
            else {
                dispatch({type: FETCH_ERROR, payload: data.message});
            }
            function callback() {
                dispatch({ type: FETCH_SUCCESS });
                dispatch({ type: FETCH_MAPPING, payload: data.data})
            }
        }).catch(function (error) {
            dispatch({type: FETCH_ERROR, payload: error.message});
            console.log("Error****:", error.message);
        });
    }
};

export const saveInwardEntry = (payload, callback) =>
{
    return (dispatch) =>
    {
        dispatch({type: FETCH_START});
        axios.post('/inventory/saveInventoryData', payload).then(({data}) => {
            if (data.success) {
                process(data)
            } 
            else {
                dispatch({type: FETCH_ERROR, payload: data.message});
                showToast(data.message, true)
                callback(null);
            }
            function process(data) {
                dispatch({ type: FETCH_SUCCESS });
                callback(data);
            }
        }).catch(function (error) {
            dispatch({type: FETCH_ERROR, payload: error.message});
            console.log("Error****:", error.message);
            callback(null)
        });
    }
};



