import { Box, CircularProgress, Skeleton } from "@mui/material";
import { useEffect, useState } from "react";
// import CountUp from "react-countup";
import { useDispatch, useSelector } from "react-redux";

const dummyAnalyticsData = [
  {
    _id: "onTime",
    orgId: "65c6054ca8d65c437ae31f5b",
    header: "Analytics",
    text: "",
    headerOrder: 2,
    value: 2,
    subText: "",
    borderColor: "#FF8C00",
    name: "On Time",
    order: 6,
    isVisible: true,
    type: "onTime",
  },
  {
    _id: "lateDeparture",
    orgId: "65c6054ca8d65c437ae31f5b",
    header: "Analytics",
    text: "",
    headerOrder: 2,
    value: 1,
    subText: "",
    borderColor: "#CD5C5C",
    name: "Late Departure",
    order: 6,
    isVisible: true,
    type: "lateDeparture",
  },
  {
    _id: "lateArrival",
    orgId: "65c6054ca8d65c437ae31f5b",
    header: "Analytics",
    text: "",
    headerOrder: 2,
    value: 1,
    subText: "",
    borderColor: "#CD5C5C",
    name: "Late Arrival",
    order: 6,
    isVisible: true,
    type: "lateArrival",
  },
  {
    _id: "earlyArrival",
    orgId: "65c6054ca8d65c437ae31f5b",
    header: "Analytics",
    text: "",
    headerOrder: 2,
    value: 3,
    subText: "",
    borderColor: "#2E8B57",
    name: "Early Arrival",
    order: 6,
    isVisible: true,
    type: "earlyArrival",
  },
];

const DataBox = ({
  sectionName,
  data,
  allCounts,
  onBoxClick,
  fullscreenView,
}) => {
  const { monitoringCountLoading, alertCountLoading, deviceCountLoading } =
    useSelector((content) => content.monitoring);

  // const boxesToRender = sectionName === "Analytics" ? dummyAnalyticsData : data

  return (
    <>
      {/* <Loader showOn={loader} /> */}
      {data?.map((box, index) => {
        let count =
          allCounts && allCounts[box?.type]
            ? allCounts[box?.type]?.toLocaleString("en-US")
            : 0;
        const loading =
          box?.header === "Alerts"
            ? alertCountLoading
            : monitoringCountLoading || deviceCountLoading;
        return box.isVisible ? (
          <div
            key={index}
            data-id={box._id}
            className={`sectionBody-box d-flex justify-content-between align-items-center pl-3 pr-3 pRel 
              ${box.value > 0 ? "cursor-pointer" : ""}
              ${fullscreenView ? "section-box--fullscreen" : ""}
            `}
            style={{
              border: `2px solid ${box.borderColor}`,
              cursor: count > 0 ? "pointer" : "default",
            }}
            onClick={() => {
              if (allCounts[box?.type] > 0) {
                onBoxClick(box);
              }
            }}
            aria-label="monitoring box"
          >
            <div className="media">
              <div className={`media-body ${fullscreenView ? "box-text--fullscreen" : ""}`}>
                <span style={{ fontWeight: 500 }}>{box.name} </span>
                <span className="media-subtext pAbs">{box.text}</span>
              </div>
            </div>
            <span
              className={`box__value pRel d-flex justify-content-end pe-1 ${fullscreenView ? "box-value--fullscreen" : ""}`}
              style={{
                color: `${box.borderColor}`,
              }}
            >
              {loading ? (
                <Box
                  height="100%"
                  width="100%"
                  display="flex"
                  justifyContent="end"
                >
                  {/* <Skeleton variant="rounded" /> */}
                  <CircularProgress
                    size={20}
                    sx={{
                      color: "#383838",
                    }}
                  />
                </Box>
              ) : (
                <span className="ms-1">
                  {allCounts && allCounts[box?.type]
                    ? allCounts[box?.type]?.toLocaleString("en-US")
                    : 0}
                </span>
              )}
            </span>
          </div>
        ) : null;
      })}
    </>
  );
};
export default DataBox;
