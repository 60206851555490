import React from 'react'

const AssetMgtIcon = ({height, width, fill}) => (
    <svg id="Layer_1" height={height} width={width} data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 265.83 250.62">
    <defs>
      <style>{`
        .cls-am-1 {
          fill: ${fill} !important;
        }
  
        .cls-am-1, .cls-am-2 {
          stroke-width: 0px;
        }
  
        .cls-am-2 {
          fill: ${fill} !important;
        }
      `}</style>
    </defs>
    <path class="cls-am-2" d="m0,124.93c.88.55,1.86.27,2.79.28,14.36.02,28.72.11,43.08-.02,8.72-.08,15.9,2.96,22.04,9.16,21.09,21.28,42.29,42.46,63.48,63.63,2.85,2.85,4.34,6.06,3.37,10.05-1.75,7.15-10.24,9.8-15.84,5-1.11-.96-2.11-2.05-3.15-3.1-16.33-16.36-32.69-32.71-48.96-49.13-1.38-1.39-2.04-1.36-3.32.03-2.57,2.81-5.29,5.48-8.06,8.1-1.09,1.03-1.02,1.6.02,2.64,17.41,17.37,34.75,34.81,52.16,52.18,5.09,5.07,11.34,7.33,18.51,7.32,34.09-.03,68.17-.02,102.26,0,6.56,0,10.86,4.31,10.54,10.45-.23,4.32-3.54,8.12-7.81,8.81-1.36.22-2.76.26-4.14.26-41.44.01-82.88,0-124.32.03-1.69,0-3.21-.32-4.75-.99-18.79-8.25-37.52-16.64-56.42-24.63-12.73-5.39-26.14-8.31-39.96-9.13-.5-.03-1.01.07-1.51.11v-91.04Z"/>
    <path class="cls-am-2" d="m241.06,60.9c-.86,4.81-1.61,9.25-2.46,13.66-1.81,9.36,2.94,17.61,11.96,20.59,3.85,1.27,7.7,2.52,11.6,3.63,1.2.34,1.67.95,1.91,2.08,2.35,10.91,2.3,21.82.08,32.75-.24,1.17-.87,1.71-1.95,2.09-4.08,1.4-8.13,2.87-12.18,4.34-8.98,3.26-13.53,11.58-11.45,20.86.87,3.88,1.63,7.79,2.6,11.64.31,1.22-.01,1.89-.87,2.66-8.28,7.43-17.67,12.92-28.24,16.41-1.22.4-1.89.08-2.72-.64-3.34-2.88-6.71-5.74-10.1-8.57-7.08-5.91-16.28-5.79-23.15.33-3.04,2.7-6.06,5.43-9,8.25-1.02.98-1.86,1.08-3.19.64-10.32-3.4-19.49-8.74-27.67-15.87-1.15-1-1.42-1.98-1.15-3.37.79-4.08,1.48-8.17,2.27-12.25,1.78-9.21-2.96-17.41-11.88-20.41-3.92-1.32-7.86-2.6-11.83-3.75-1.11-.32-1.52-.86-1.75-1.89-2.42-10.99-2.35-21.99-.11-33,.25-1.24,1.05-1.63,2.06-1.99,3.98-1.41,7.96-2.84,11.93-4.29,9.19-3.35,13.66-11.52,11.53-21.02-.85-3.8-1.56-7.63-2.53-11.39-.34-1.33,0-2.03.94-2.87,8.15-7.33,17.44-12.65,27.78-16.2,1.27-.44,2.15-.37,3.22.59,3.22,2.89,6.56,5.64,9.87,8.43,7.04,5.94,16.2,5.89,23.15-.18,3-2.62,5.95-5.3,8.81-8.07,1.12-1.08,2.02-1.41,3.6-.89,10.3,3.45,19.5,8.76,27.61,15.98.62.55,1.5.99,1.3,1.71Zm-53.12,90.6c18.84,0,34.2-15.39,34.11-34.2-.09-18.77-15.34-33.99-34.06-34-18.82,0-34.2,15.42-34.1,34.22.1,18.79,15.32,33.97,34.06,33.98Z"/>
    <path class="cls-am-1" d="m109.04,24.02c0,7.02-.06,14.03.04,21.05.03,1.74-.48,2.15-2.15,2.14-14.18-.06-28.35-.07-42.53,0-1.71,0-2.14-.46-2.13-2.16.07-14.2.07-28.41,0-42.61,0-1.73.48-2.15,2.15-2.14,14.18.06,28.35.07,42.53,0,1.71,0,2.16.45,2.14,2.16-.1,7.19-.04,14.38-.04,21.56Z"/>
    <path class="cls-am-1" d="m15.57,85.91c0-7.02.06-14.03-.04-21.05-.03-1.74.48-2.15,2.15-2.14,14.18.06,28.35.07,42.53,0,1.71,0,2.14.46,2.13,2.16-.07,14.2-.07,28.41,0,42.61,0,1.73-.48,2.15-2.15,2.14-14.18-.06-28.35-.07-42.53,0-1.71,0-2.16-.46-2.14-2.16.1-7.19.04-14.38.04-21.56Z"/>
    <path class="cls-am-1" d="m132.41,15.79c-2.55-.09-5.98,1.31-7.43-.36-1.25-1.44-.42-4.78-.35-7.28.07-2.55-1.12-5.96.51-7.4,1.43-1.26,4.76-.45,7.25-.39,2.56.06,5.98-1.12,7.43.5,1.26,1.41.42,4.78.35,7.28-.07,2.55,1.12,5.98-.51,7.4-1.42,1.23-4.76.26-7.25.26Z"/>
    <path class="cls-am-1" d="m31.16,39.5c0,2.07-.05,4.15.02,6.22.04,1.15-.39,1.48-1.49,1.46-4.23-.05-8.45-.05-12.68,0-1.14.01-1.47-.39-1.46-1.5.05-4.23.05-8.47,0-12.7-.02-1.15.39-1.47,1.49-1.46,4.23.05,8.45.05,12.68,0,1.14-.01,1.49.39,1.46,1.5-.07,2.16-.02,4.32-.02,6.48Z"/>
    <path class="cls-am-1" d="m93.32,70.78c-.08,2.47,1.22,5.76-.36,7.17-1.36,1.22-4.59.45-6.99.4-2.65-.05-6.19,1.14-7.69-.49-1.31-1.42-.44-4.93-.38-7.51.06-2.47-1.1-5.76.47-7.17,1.36-1.22,4.59-.45,6.99-.4,2.65.05,6.2-1.14,7.69.49,1.28,1.41.27,4.93.27,7.51Z"/>
    <path class="cls-am-1" d="m85.42,109.43c-2.47-.06-5.78,1.25-7.18-.35-1.21-1.38-.41-4.62-.34-7.03.08-2.64-1.16-6.17.52-7.65,1.47-1.3,4.92-.46,7.5-.4,2.47.06,5.78-1.11,7.18.49,1.21,1.38.41,4.62.34,7.03-.08,2.64,1.16,6.18-.52,7.65-1.46,1.28-4.92.27-7.49.27Z"/>
    <path class="cls-am-2" d="m187.97,135.89c-10.19,0-18.51-8.29-18.51-18.48,0-10.16,8.32-18.5,18.49-18.5,10.18,0,18.51,8.31,18.52,18.48,0,10.18-8.31,18.49-18.5,18.49Z"/>
  </svg> 
)

export default AssetMgtIcon