import React, { useState } from "react";
import {
  Button,
  InputLabel,
  Grid,
  CardContent,
  Breadcrumbs,
  Typography,
  FormControl,
  OutlinedInput,
  IconButton,
  InputAdornment,
  Card,
  Box,
  Checkbox,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { countriesList, selectStylesOverride } from "../../../../utils/util";
import { Link, useParams } from "react-router-dom";
import { useForm, Controller, useWatch } from "react-hook-form";
import { CustomInput } from "../../../../components/FormUI";
import CustomSelect from "../../../../components/FormUI/CustomSelect";
import {
  assignDeassignDevices,
  assignDeassignGeofences,
  assignDeassignRoutes,
  checkTemplateExistance,
  getAllDeviceCategoryNames,
  getAllRoutes,
  getBulkUsersDetails,
  getUserAssignedDevices,
  getUserAssignedGeofences,
  getUserAssignedRoutes,
  getAllOrganizations,
  getOrganisation,
  getPermissionRoles,
  updateUser,
  getAllUsers,
  userDetails,
  getAllGeofences,
  addUser,
  getAllDevices,
  getAllDevicePermissions,
  flushGeofenceList,
  flushRoutes,
  flushUsersList,
  flushUserDetails,
} from "../../../../redux/actions";
import { flushSelectedOrganisation } from "../../../../redux/reducers/OrganisationReducer";
import AdminSection from "./AdminSection";
import GeofenceSection from "./GeofenceSection";
import RouteSection from "./RouteSection";
import DeviceSection from "./DeviceSection";
import AddUserRightPanel from "./AddUserRightPanel";
import { useHistory } from "react-router-dom";
import _ from "lodash";
import { showToast } from "../../../../services/functions";
import { flushAllDevices } from "../../../../redux/reducers/DevicesReducers";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { flushAllRolePermissions } from "../../../../redux/reducers/UserPermission";
import { preparePermissions } from "../../../../utils/permissionCheck";
import CustomSubmitBtn from "../../../../components/FormUI/CustomSubmitBtn";
import Timezones from 'timezones-list';
import moment from 'moment-timezone';

const AddUser = () => {
  // dispatchers ---
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();
  const isEditUser = !!id;

  let filter = {
    page: 1,
    limit: 100,
  };
  let deviceFilter = { filter: {}, selection: ["_id", "client_id"] };
  // local states ---

  const [showPassword, setShowPassword] = React.useState(false);
  const [submitBtnLoading, setSubmitBtnLoading] = React.useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);
  const [adminRadio, setAdminRadio] = React.useState(null);
  const [routeRadio, setRouteRadio] = React.useState(null);
  const [deviceRadio, setDeviceRadio] = React.useState(null);
  const [geofenceRadio, setGeofenceRadio] = React.useState(null);
  const [showAll, setShowAll] = React.useState(false);
  const [showAllGeo, setShowAllGeo] = React.useState(false);
  const [showAllRoute, setShowAllRoute] = React.useState(false);
  const [showAllDevice, setShowAllDevice] = React.useState(false);
  const [selectedUser, setSelectedUser] = React.useState([]);
  const [selectedRoute, setSelectedRoute] = React.useState([]);
  const [selectedDevice, setSelectedDevice] = React.useState([]);
  const [selectedGeofence, setSelectedGeofence] = React.useState([]);
  const [timezonesList,setTimeZonesList] = useState([])
  const [initialSelectedUsers, setInitialSelectedUsers] = React.useState([]); // --- only for edit
  const [initialSelectedGeofence, setInitialSelectedGeofence] = React.useState(
    []
  ); // --- only for edit
  const [initialSelectedRoute, setInitialSelectedRoute] = React.useState([]); // --- only for edit
  const [initialSelectedDevice, setInitialSelectedDevice] = React.useState([]); // --- only for edit
  const [selectedDeviceCategory, setSelectedDeviceCategory] =
    React.useState("");
  const [userRoleId, setUserRoleId] = React.useState("");
  const [addMore, setAddMore] = React.useState(false);
  const [permissions, setPermissions] = React.useState(null);
  const [deviceSpecificPermissions, setDeviceSpecificPermissions] =
    React.useState([]);
  const [searchFields, setSearchFields] = React.useState({
    organization: null,
    role: null,
    users: null,
    geofence: null,
    routes: null,
    deviceType: null,
    devices: null,
  });

  // selectors ---
  const { currentUserDetail } = useSelector((state) => state.auth);
  const isDarkThemeEnabledSelector = useSelector(
    (state) => state.theme.themeDark
  );
  const organizations = useSelector((state) => state.userlist.allOrganizations);
  const Users = useSelector((state) => state.userlist.allUsers);
  const { userDetail } = useSelector((state) => state.userlist);
  const orgDetails = useSelector(
    (state) => state.Organisationlisting.selectedOrganisation
  );
  const allPermissionRoles = useSelector(
    (state) => state.userPermission.permissionRoles
  );
  const geofencesListing = useSelector(
    (state) => state.geofencelist.geofenceListing
  );
  const routesListing = useSelector((state) => state.routes.allRoutes);
  const deviceTypeList = useSelector(
    (state) => state.deviceslist.allDeviceCategoryNames
  );
  const devices = useSelector((state) => state.deviceslist.allDevices);
  const [timeZone, setTimeZone] = useState({
    offset: 5.5,
    label: 'Asia/Calcutta (GMT+05:30)'
  });
  const devicePermissions = useSelector(
    (state) => state.deviceslist.alldevicePermissions
  );

  // theme ---
  const themeType = isDarkThemeEnabledSelector
    ? require("../../../../static/styles/darktheme")
    : require("../../../../static/styles/theme");
  const { buttonClasses, cardClasses, inputClasses, textClasses } = themeType;

  let size = selectedUser?.length;
  let geoSize = selectedGeofence?.length;
  let routeSize = selectedRoute?.length;
  let deviceSize = selectedDevice?.length;

  const {
    control,
    formState: { errors, isSubmitting, isValid, dirtyFields },
    handleSubmit,
    reset,
    register,
    setValue,
    getValues,
  } = useForm();

  const userSelected = useWatch({
    control,
    name: "user",
  });
  const userRadio = useWatch({
    control,
    name: "radio-buttons-group",
  });
  const organizationSelected = useWatch({
    control,
    name: "organisation",
  });
  const passwordEntered = useWatch({
    control,
    name: "password",
  });
  const geofenceSelected = useWatch({
    control,
    name: "geofence",
  });
  const geoRadio = useWatch({
    control,
    name: "radio-buttons-group-geo",
  });
  const routesSelected = useWatch({
    control,
    name: "routes",
  });
  const routeRadioWatch = useWatch({
    control,
    name: "radio-buttons-group-route",
  });
  const deviceTypeSelected = useWatch({
    control,
    name: "deviceType",
  });
  const deviceSelected = useWatch({
    control,
    name: "device",
  });
  const deviceRadioWatch = useWatch({
    control,
    name: "radio-buttons-group-device",
  });

  React.useEffect(() => {
    const timeZones = moment.tz.names();

    const offsetTmzData = timeZones.map(timezone => {
      const offset = moment.tz(timezone).format('Z');
      return {
        label: `${timezone} (GMT${offset})`,
        utc: `${offset}`
      };
    });
    console.log('offsetTmz', offsetTmzData);
    setTimeZonesList(offsetTmzData)
  }, []); 

  const userRolesPermissionListing = React.useMemo(() => {
    return (
      allPermissionRoles &&
      allPermissionRoles?.find((_) => _._id === userRoleId)
    );
  }, [allPermissionRoles, userRoleId]);

  // const userRolesPermissionListing = React.useMemo(() => {
  //   return (
  //     orgDetails &&
  //     orgDetails[0] &&
  //     orgDetails[0].userRoles.find((_) => _.id === userRoleId)
  //   );
  // }, [orgDetails, userRoleId]);

  const userRolePermissions = React.useMemo(() => {
    return _.cloneDeep(userRolesPermissionListing?.permissions);
  }, [userRolesPermissionListing]);

  /** --- HANDLERS --- **/
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => event.preventDefault();

  const handleClickShowConfirmPassword = () =>
    setShowConfirmPassword((show) => !show);

  const handleCloseAction = React.useCallback(() => {
    reset();
    history.push("/dashboard/users");
  }, [history]);

  // helper---
  const excludeKeys = (sourceObj, exclusionObj, userPermissions) => {
    const result = JSON.parse(JSON.stringify(sourceObj));

    // if (userPermissions && Object.keys(userPermissions)?.length === 0) {
    //   return result;
    // } else {
    //   Object.keys(sourceObj).forEach((key) => {
    //     if (exclusionObj[key]) {
    //       const sourceSubKeys = Object.keys(sourceObj[key]);
    //       const exclusionSubKeys = Object.keys(exclusionObj[key]);

    //       sourceSubKeys.forEach((subKey) => {
    //         if (subKey !== "label" && exclusionSubKeys.includes(subKey)) {
    //           delete result[key][subKey];
    //         }
    //       });

    //       if (
    //         Object.keys(result[key]).length === 1 &&
    //         result[key].hasOwnProperty("label")
    //       ) {
    //         delete result[key];
    //       }
    //     }
    //   });
    //   return result;
    // }
  };

  const arePermissionsEqual = (resultPermissions, userPermissions) => {
    for (const permissionGroup in resultPermissions) {
      if (resultPermissions.hasOwnProperty(permissionGroup)) {
        const resultGroup = resultPermissions[permissionGroup];
        const userGroup = userPermissions[permissionGroup];

        if (!userGroup) {
          return false; // Missing group in userPermissions
        }

        for (const permissionName in resultGroup) {
          if (permissionName !== "label") {
            const resultPermission = resultGroup[permissionName];
            const userPermission = userGroup[permissionName];

            if (
              !userPermission ||
              resultPermission.value !== userPermission.value
            ) {
              return false; // Permission not found or values differ
            }
          }
        }

        for (const permissionName in userGroup) {
          if (permissionName !== "label" && !resultGroup[permissionName]) {
            return false; // Permission exists in userGroup but not in resultGroup
          }
        }
      }
    }

    // Check for any extra groups in userPermissions
    for (const permissionGroup in userPermissions) {
      if (!resultPermissions[permissionGroup]) {
        return false; // Extra group in userPermissions
      }
    }

    return true; // All groups and permissions match
  };

  const onSubmit = (data) => {
    setSubmitBtnLoading(true);
    const resultPermissions = permissions
    // const resultPermissions = excludeKeys(
    //   permissions,
    //   userRolesPermissionListing?.permissions
    // );

    let payload = {
      orgId: data?.organisation,
      name: data?.name,
      password: data?.password,
      mobile: data?.mobile,
      email: data?.email,
      timeZone: { text: timeZone?.label, offset: Number(timeZone?.offset) },
      details: {
        rfid: data?.rfidCard,
        department: data?.department,
        country: data?.country,
      },
      permissions: resultPermissions,
      userRole: {
        id: data.userRole,
        name:
          allPermissionRoles &&
          allPermissionRoles?.find((userRole) => userRole?._id === userRoleId)
            ?.name,
        type:
          allPermissionRoles &&
          allPermissionRoles?.find((userRole) => userRole?._id === userRoleId)
            ?.type,
      },
    };
    let finalPayload = payload;


    if (finalPayload?.timeZone?.offset === '' || finalPayload?.timeZone?.text === '') {
      showToast('Time zone is mandatory.',true)
      setSubmitBtnLoading(false)
    }
    else {
      dispatch(
        addUser(finalPayload, (userId, orgId, orgName, name) => {
          if (userId) {
            const updatePayload = {
              id: userId,
            };
            const dispatchers = [];
            //if user has selected "select" radio, only then we have to assign the user with that respective module API"
            if (userRadio && userRadio !== null) {
              updatePayload.reportingAdmin =
                userRadio?.toLowerCase() === "select"
                  ? "few"
                  : userRadio?.toLowerCase();
              if (userRadio?.toLowerCase() === "select") {
                let temp = selectedUser?.map((_) => _?.id);
                updatePayload.fewReportingAdminList = temp;
              }
            }
            if (geofenceRadio && geofenceRadio !== null) {
              updatePayload.assignGeofence =
                geofenceRadio?.toLowerCase() === "select"
                  ? "few"
                  : geofenceRadio?.toLowerCase();
              if (geofenceRadio?.toLowerCase() !== "all")
                dispatchers?.push(
                  dispatch(
                    assignDeassignGeofences({
                      geofences: selectedGeofence,
                      userId,
                      orgId,
                    })
                  )
                );
            }
            if (routeRadio && routeRadio !== null) {
              updatePayload.assignRoutes =
                routeRadio?.toLowerCase() === "select"
                  ? "few"
                  : routeRadio?.toLowerCase();
              if (routeRadio?.toLowerCase() !== "all")
                dispatchers?.push(
                  dispatch(
                    assignDeassignRoutes({
                      routes: selectedRoute,
                      userId,
                      orgId,
                    })
                  )
                );
            }
            if (deviceRadio && deviceRadio !== null) {
              updatePayload.assignDevice =
                deviceRadio?.toLowerCase() === "select"
                  ? "few"
                  : deviceRadio?.toLowerCase();
              const deviceAssignPayload = {
                userId,
                orgId,
                orgName,
                userName: name,
                type:
                  deviceRadio?.toLowerCase() === "select"
                    ? "few"
                    : deviceRadio?.toLowerCase(),
              };
              if (deviceRadio?.toLowerCase() !== "all") {
                deviceAssignPayload.devices = selectedDevice;
              } else if (deviceRadio?.toLowerCase() === "all") {
                delete deviceAssignPayload["type"];
                deviceAssignPayload["permissions"] = {
                  deviceSpecificPermission: deviceSpecificPermissions,
                };
              }
              dispatchers?.push(
                dispatch(assignDeassignDevices(deviceAssignPayload))
              );
            }

            Promise.all(dispatchers)
              ?.catch((e) => {
                showToast("Some Error occured");
                setSubmitBtnLoading(false);
              })
              ?.then(() => {
                reset();
                dispatch(
                  updateUser(updatePayload, () => {
                    !addMore && history.push("/dashboard/users");
                  }, () => { }, true)
                );
                setSubmitBtnLoading(false);
              });
          }
        }, () => { setSubmitBtnLoading(false) })
      );
    }
  };


  const handleEdit = (data) => {
    const resultPermissions = permissions;
    // const resultPermissions = excludeKeys(
    //   permissions,
    //   userRolesPermissionListing?.permissions,
    //   userDetail?.permissions
    // );

    setSubmitBtnLoading(true);

    const payload = {};
    Object.keys(dirtyFields)?.forEach((field) => {
      if (field === "rfid" || field === "department" || field === "country") {
        payload.details = {
          [field]: data[field],
        };
      } else if (field === "userRole") {
        const selectedRole = allPermissionRoles?.find(
          (_) => _?._id == data[field]
        );
        payload[field] = {
          id: selectedRole?._id,
          type: selectedRole?.type,
          name: selectedRole?.name,
        };
        // payload[field] = orgDetails[0]?.userRoles?.find(
        //   (_) => _?.id == data[field]
        // );
      } else {
        payload[field] = data[field];
      }
    });

    if (
      arePermissionsEqual(resultPermissions, userDetail?.permissions) === false
    ) {
      payload["permissions"] = resultPermissions;
    }

    payload["id"] = userDetail?._id;
    payload["timeZone"] = { text: timeZone?.label, offset: Number(timeZone?.offset) };
    const dispatchers = [];

    // ---> check if current radio !== userDetail.radio || initial !== selected
    const updatedAdminRadio =
      adminRadio && adminRadio === "select" ? "few" : adminRadio?.toLowerCase();
    const updatedGeoRadio =
      geofenceRadio && geofenceRadio === "select"
        ? "few"
        : geofenceRadio?.toLowerCase();
    const updatedRouteRadio =
      routeRadio && routeRadio === "select" ? "few" : routeRadio?.toLowerCase();
    const updatedDeviceRadio =
      deviceRadio && deviceRadio === "select"
        ? "few"
        : deviceRadio?.toLowerCase();

    if (
      adminRadio &&
      (updatedAdminRadio !== userDetail?.reportingAdmin ||
        !_?.isEqual(initialSelectedUsers, selectedUser))
    ) {
      payload.reportingAdmin =
        adminRadio === "select" ? "few" : adminRadio?.toLowerCase();
      if (adminRadio === "select") {
        let temp = selectedUser?.map((_) => _?.id);
        payload.fewReportingAdminList = temp;
      }
    }
    if (
      geofenceRadio &&
      (updatedGeoRadio !== userDetail?.assignGeofence ||
        !_?.isEqual(initialSelectedGeofence, selectedGeofence))
    ) {
      payload.assignGeofence =
        geofenceRadio === "select" ? "few" : geofenceRadio?.toLowerCase();
      if (geofenceRadio !== "All")
        dispatchers?.push(
          dispatch(
            assignDeassignGeofences({
              geofences: selectedGeofence,
              userId: userDetail?._id,
              orgId: userDetail?.orgId,
            })
          )
        );
    }
    if (
      routeRadio &&
      (updatedRouteRadio !== userDetail?.assignRoutes ||
        !_?.isEqual(initialSelectedRoute, selectedRoute))
    ) {
      payload.assignRoutes =
        routeRadio === "select" ? "few" : routeRadio?.toLowerCase();
      if (routeRadio !== "All")
        dispatchers?.push(
          dispatch(
            assignDeassignRoutes({
              routes: selectedRoute,
              userId: userDetail?._id,
              orgId: userDetail?.orgId,
            })
          )
        );
    }
    if (
      deviceRadio &&
      (updatedDeviceRadio !== userDetail?.assignDevice ||
        !_?.isEqual(initialSelectedDevice, selectedDevice))
    ) {
      payload.assignDevice =
        deviceRadio === "select" ? "few" : deviceRadio?.toLowerCase();
      const deviceAssignPayload = {
        userId: userDetail?._id,
        orgId: userDetail?.orgId,
        orgName: userDetail?.orgName,
        userName: userDetail?.name,
        type:
          deviceRadio?.toLowerCase() === "select"
            ? "few"
            : deviceRadio?.toLowerCase(),
      };
      if (deviceRadio !== "All") {
        deviceAssignPayload.devices = selectedDevice;
      } else if (deviceRadio === "All") {
        delete deviceAssignPayload["type"];
        deviceAssignPayload["permissions"] = {
          deviceSpecificPermission: deviceSpecificPermissions,
        };
        // deviceAssignPayload.permissions =
        //   selectedDevice && selectedDevice[0]?.permissions;
      }

      dispatchers?.push(dispatch(assignDeassignDevices(deviceAssignPayload)));
    }
    const { user, geofence, routes, deviceType, device, ...rest } = payload;
    const keysToDelete = [
      "radio-buttons-group-geo",
      "radio-buttons-group",
      "radio-buttons-group-route",
      "radio-buttons-group-device",
    ];
    let updatedPayload = deleteKeys(rest, keysToDelete);

    Promise.all(dispatchers)
      ?.catch((e) => {
        showToast("Some Error occured");
        setSubmitBtnLoading(false)
      })
      ?.then(() => {
        dispatch(
          updateUser(updatedPayload, () => {
            reset();
            handleCloseAction();
            setSubmitBtnLoading(false);
          }, () => { setSubmitBtnLoading(false); })
        );
      });
  };

  const _handleSelect = (e, value, item) => {
    if (item === "users") {
      setSelectedUser([...selectedUser, value]);
    } else if (item === "geofence") {
      setSelectedGeofence([...selectedGeofence, value]);
    } else if (item === "route") {
      setSelectedRoute([...selectedRoute, value]);
    } else if (item === "deviceCategory") {
      setSelectedDeviceCategory(value?.lynkDeviceModel);
      if (value) {
        dispatch(getAllDevices({ ...filter, deviceModel: value.deviceModel }));
        // dispatch(getAllDevices({ deviceModel: value.deviceModel, ...deviceFilter, }));
        dispatch(getAllDevicePermissions());
      }
    } else if (item === "device") {
      setSelectedDevice([...selectedDevice, value]);
    }
  };

  const deleteKeys = (obj, keys) => {
    keys.forEach((key) => {
      delete obj[key];
    });
    return obj;
  };

  const handleRemoveSelection = (index, item) => {
    if (item === "users") {
      let temp = [...selectedUser];
      temp.splice(index, 1);
      setSelectedUser(temp);
    } else if (item === "geofence") {
      let temp = [...selectedGeofence];
      temp.splice(index, 1);
      setSelectedGeofence(temp);
    } else if (item === "route") {
      let temp = [...selectedRoute];
      temp.splice(index, 1);
      setSelectedRoute(temp);
    } else if (item === "device") {
      let temp = [...selectedDevice];
      temp.splice(index, 1);
      setSelectedDevice(temp);
    }
  };

  const handleCheckboxChange = (e, key) => {
    setDeviceSpecificPermissions((prevData) => {
      if (prevData[key]) {
        return {
          ...prevData,
          [key]: { ...prevData[key], value: e?.target?.checked ? true : false },
        };
      } else {
        return prevData;
      }
    });
  };

  const handleAddDevice = (e) => {
    e?.preventDefault();
    e?.stopPropagation();
    if (deviceSelected && deviceRadio === "select") {
      const tempSelectedDevices = [...selectedDevice];
      tempSelectedDevices?.push({
        clientId: deviceSelected,
        permissions: {
          deviceSpecificPermission: deviceSpecificPermissions,
        },
      });
      setSelectedDevice(tempSelectedDevices);
      const { deviceSpecificPermission } = devicePermissions;
      setDeviceSpecificPermissions(deviceSpecificPermission);
      setValue("device", undefined);
      dispatch(flushAllDevices());
    } else if (deviceRadio === "All") {
      let arr = [];
      devices?.map((item) => {
        arr.push({
          clientId: item?.client_id,
          permissions: {
            deviceSpecificPermission: deviceSpecificPermissions,
          },
        });
      });
      setSelectedDevice(arr);
      const { deviceSpecificPermission } = devicePermissions;
      setDeviceSpecificPermissions(deviceSpecificPermission);
      setValue("device", undefined);
    }
  };

  const handleAddMore = React.useCallback(
    (e) => {
      if (e.target.checked) {
        setAddMore(true);
      } else {
        setAddMore(false);
      }
    },
    [setAddMore]
  );

  const handleCallModuleAPIsForEdit = (details) => {
    if (details && details?.orgId) {
      const paylaodFilter = {
        page: 1,
        limit: 100,
      };
      let deviceRequest = {
        ...deviceFilter,
        filter: {
          user: userDetail?._id,
        }
      }

      dispatch(
        getAllUsers(
          {
            ...paylaodFilter,
            userRoleType: "admin",
            selection: ["name"],
            orgId: details?.orgId,
          },
          (res) => {
            if (
              res &&
              res?.length > 0 &&
              details?.reportingAdmin &&
              details?.reportingAdmin === "all"
            ) {
              setValue("user", undefined);
              setSelectedUser([]);
              let arr =
                res &&
                res?.map((_) => ({
                  id: _?._id,
                  name: _?.name,
                }));
              setSelectedUser(arr);
              setInitialSelectedUsers(arr);
              setValue("user", arr);
            }
          }
        )
      );
      dispatch(
        getAllGeofences(
          {
            ...paylaodFilter,
            selection: ["name"],
            filter: { orgId: details?.orgId },
          },
          (res) => {
            if (
              res &&
              res?.data &&
              res?.data?.length > 0 &&
              details?.assignGeofence &&
              details?.assignGeofence === "all"
            ) {
              setValue("geofence", undefined);
              setSelectedGeofence([]);
              let arr =
                res?.data &&
                res?.data?.map((item) => ({
                  id: item._id,
                  name: item?.name,
                }));
              setValue("geofence", arr);
              setSelectedGeofence(arr);
              setInitialSelectedGeofence(arr);
            }
          }
        )
      );
      dispatch(
        getAllRoutes(
          {
            ...paylaodFilter,
            selection: ["routeName"],
            filter: { orgId: details?.orgId },
          },
          (res) => {
            if (
              res &&
              res?.length > 0 &&
              details?.assignRoutes &&
              details?.assignRoutes === "all"
            ) {
              setValue("routes", undefined);
              setSelectedRoute([]);
              let arr =
                res &&
                res?.map((item) => ({
                  id: item._id,
                  name: item?.routeName,
                }));
              setSelectedRoute(arr);
              setInitialSelectedRoute(arr);
              setValue("routes", arr);
            }
          }
        )
      );
      dispatch(getAllDeviceCategoryNames());
      dispatch(
        getAllDevices(
          {
            ...deviceRequest,
          },
          (res) => {
            if (
              res &&
              res?.length > 0 &&
              details?.assignDevice &&
              details?.assignDevice === "all"
            ) {
              let arr = [];
              res?.map((item) => {
                arr.push({
                  clientId: item?.client_id,
                  permissions: {
                    deviceSpecificPermission: {},
                  },
                });
              });
              setSelectedDevice(arr);
              setInitialSelectedDevice(arr);
            }
          }
        )
      );
    }
  };

  // >> API based search dropdown handlers <<
  const handleSearchDeviceType = (reset = false) => {
    if (searchFields?.deviceType || searchFields?.deviceType == "") {
      if (reset === true) {
        dispatch(getAllDeviceCategoryNames({ selection: ["category"] }));
      } else {
        dispatch(
          getAllDeviceCategoryNames({
            selection: ["category"],
            searchValue: searchFields?.deviceType,
          })
        );
      }
    }
  };

  const handleSearchDevice = (reset = false) => {
    if (searchFields?.devices || searchFields?.devices == "") {
      if (reset === true) {
        dispatch(
          getAllDevices({
            filter: {
              deviceType: deviceTypeSelected,
            },
          })
        );
      } else {
        dispatch(
          getAllDevices({
            q: searchFields?.devices,
          })
        );
      }
    }
  };

  const handleSearchGeofence = (reset = false) => {
    if (searchFields?.geofence || searchFields?.geofence == "") {
      if (reset === true) {
        dispatch(
          getAllGeofences({
            selection: ["name"],
            filter: { orgId: organizationSelected },
          })
        );
      } else {
        dispatch(
          getAllGeofences({
            selection: ["name"],
            searchValue: searchFields?.geofence,
            filter: {
              orgId: organizationSelected,
            },
          })
        );
      }
    }
  };

  const handleSearchRoute = (reset = false) => {
    if (searchFields?.routes || searchFields?.routes == "") {
      if (reset === true) {
        dispatch(
          getAllRoutes({
            selection: ["name"],
            filter: { orgId: organizationSelected },
          })
        );
      } else {
        dispatch(
          getAllRoutes({
            selection: ["name"],
            filter: {
              searchValue: searchFields?.routes,
              orgId: organizationSelected,
            },
          })
        );
      }
    }
  };

  const handleSearchOrg = (reset) => {
    if (searchFields?.organization || searchFields?.organization == "") {
      if (reset === true) {
        dispatch(getAllOrganizations());
      } else {
        dispatch(
          getAllOrganizations({
            searchValue: searchFields?.organization,
          })
        );
      }
    }
  };

  const handleSearchUser = (reset = false) => {
    if (searchFields?.users || searchFields?.users == "") {
      if (reset === true) {
        dispatch(
          getAllUsers({
            page: 1,
            limit: 100,
            selection: ["name"],
            userRoleType: "admin",
            orgId: organizationSelected,
          })
        );
      } else {
        dispatch(
          getAllUsers({
            orgId: organizationSelected,
            userRoleType: "admin",
            searchValue: searchFields?.users,
            selection: ["name"],
          })
        );
      }
    }
  };

  /** --- EFFECTS --- **/
  React.useEffect(() => {
    reset();
    let payload = { category: "user" };
    dispatch(checkTemplateExistance(payload));
    setSelectedDevice([]);
    setSelectedUser([]);
    setSelectedGeofence([]);
    setSelectedRoute([]);
    setDeviceRadio(null);
    setAdminRadio(null);
    setGeofenceRadio(null);
    setRouteRadio(null);
  }, []);

  React.useEffect(() => {
    // --> If current user is super admin, we will list all organizations, else we'll just take current user's org [ADD]
    if (!id && window.location.pathname?.includes("add")) {
      if (
        currentUserDetail &&
        Object.keys(currentUserDetail)?.length > 0 &&
        currentUserDetail?.adminSpecificPermissions
      ) {
        dispatch(
          getAllOrganizations({ ...filter, selection: ["name"] }, () => { })
        );
        // dispatch(getPermissionRoles(filter, () => {}));
      } else if (
        currentUserDetail &&
        Object.keys(currentUserDetail)?.length > 0 &&
        !currentUserDetail?.adminSpecificPermissions
      ) {
        setValue("organisation", currentUserDetail?.orgId);
        dispatch(getPermissionRoles({ orgId: currentUserDetail?.orgId }));
        // dispatch(getOrganisation({ id: currentUserDetail?.orgId })); // <<< comment
      }
    }
  }, [dispatch, currentUserDetail]);

  React.useEffect(() => {
    // --> As we select the organization, we'll fetch users, geos, routes and devices according to that org. [ADD]
    if (
      organizationSelected &&
      !id &&
      window.location.pathname?.includes("add")
    ) {
      const paylaodFilter = {
        page: 1,
        limit: 100,
      };
      let userPayload = {
        ...paylaodFilter,
        userRoleType: "admin",
        selection: ["name"],
        // orgId: organizationSelected,
      };
      let geofencePayload = {
        ...paylaodFilter,
        selection: ["name"],
        // filter: { orgId: organizationSelected },
      };
      let routePayload = {
        ...paylaodFilter,
        selection: ["routeName"],
        // filter: { orgId: organizationSelected },
      };

      if (currentUserDetail?.adminSpecificPermissions) {
        userPayload.orgId = organizationSelected;
        geofencePayload.filter = { orgId: organizationSelected };
        routePayload.filter = { orgId: organizationSelected };
      }

      dispatch(getAllUsers(userPayload, () => { }));
      dispatch(getAllGeofences(geofencePayload, (res) => { }));
      dispatch(getAllRoutes(routePayload, (res) => { }));
      dispatch(getAllDeviceCategoryNames());
      dispatch(
        getAllDevices(
          {
            ...deviceFilter,
          },
          (res) => { }
        )
      );
    }
    return () => {
      dispatch(flushGeofenceList());
      dispatch(flushUsersList());
      dispatch(flushAllDevices());
      dispatch(flushRoutes());
    };
  }, [organizationSelected]);

  // ---- Reporting admin assign effects ----
  React.useEffect(() => {
    if (userRadio === "select") {
      setValue("user", undefined);
      setSelectedUser([]);
      setAdminRadio(userRadio);
    } else if (userRadio === "All") {
      setValue("user", undefined);
      setSelectedUser([]);
      let arr =
        Users &&
        Users?.map((_) => ({
          id: _?._id,
          name: _?.name,
        }));
      setSelectedUser(arr);
      setValue("user", arr);
      setAdminRadio(userRadio);
    }
  }, [userRadio]);

  React.useEffect(() => {
    if (userSelected) {
      setSelectedUser(userSelected);
    }
  }, [userSelected]);

  // ---- Geofence assign effects ----
  React.useEffect(() => {
    if (geoRadio === "select") {
      setValue("geofence", undefined);
      setSelectedGeofence([]);
      setGeofenceRadio(geoRadio);
    } else if (geoRadio === "All") {
      setValue("geofence", undefined);
      setSelectedGeofence([]);
      let arr =
        geofencesListing &&
        geofencesListing?.map((item) => ({ id: item._id, name: item?.name }));
      setValue("geofence", arr);
      setSelectedGeofence(arr);
      setGeofenceRadio(geoRadio);
    } else if (geoRadio === "None") {
      setValue("geofence", undefined);
      setSelectedGeofence([]);
      setGeofenceRadio(geoRadio);
    }
  }, [geoRadio]);

  React.useEffect(() => {
    if (geofenceSelected) {
      setSelectedGeofence(geofenceSelected);
    }
  }, [geofenceSelected]);

  // ---- Routes assign effects ----
  React.useEffect(() => {
    if (routesSelected) {
      setSelectedRoute(routesSelected);
    }
  }, [routesSelected]);

  React.useEffect(() => {
    if (routeRadioWatch === "select") {
      setValue("routes", undefined);
      setSelectedRoute([]);
      setRouteRadio(routeRadioWatch);
    } else if (routeRadioWatch === "All") {
      setValue("routes", undefined);
      setSelectedRoute([]);
      let arr =
        routesListing &&
        routesListing?.map((item) => ({ id: item._id, name: item?.routeName }));
      setSelectedRoute(arr);
      setValue("routes", arr);
      setRouteRadio(routeRadioWatch);
    } else if (routeRadioWatch === "None") {
      setValue("routes", undefined);
      setSelectedRoute([]);
      setRouteRadio(routeRadioWatch);
    }
  }, [routeRadioWatch]);

  // ---- Devices assign effects ----
  React.useEffect(() => {
    if (deviceTypeSelected) {
      setSelectedDeviceCategory(deviceTypeSelected);
      dispatch(
        getAllDevices({
          ...deviceFilter,
          filter: { ...filter, deviceType: deviceTypeSelected },
        })
      );
      dispatch(getAllDevicePermissions());
    }
  }, [deviceTypeSelected]);

  React.useEffect(() => {
    if (devicePermissions && devicePermissions?.deviceSpecificPermission) {
      const { deviceSpecificPermission } = devicePermissions;
      setDeviceSpecificPermissions(deviceSpecificPermission);
    }
  }, [devicePermissions]);

  React.useEffect(() => {
    if (deviceRadioWatch === "select") {
      setSelectedDevice([]);
      setDeviceRadio(deviceRadioWatch);
    } else if (deviceRadioWatch === "All") {
      dispatch(getAllDevicePermissions());
      setSelectedDevice([]);
      setDeviceRadio(deviceRadioWatch);
    } else if (deviceRadioWatch === "None") {
      setSelectedDevice([]);
      setDeviceRadio(deviceRadioWatch);
    }
  }, [deviceRadioWatch]);

  React.useEffect(() => {
    if (
      userRolePermissions &&
      !id &&
      window.location.pathname?.includes("add")
    ) {
      setPermissions(userRolePermissions);
    }
  }, [userRolePermissions]);

  // --- edit case
  React.useEffect(() => {
    if (id && window.location.pathname?.includes("edit")) {
      dispatch(userDetails({ id }));
      setInitialSelectedDevice([]);
      setInitialSelectedGeofence([]);
      setInitialSelectedRoute([]);
      setInitialSelectedUsers([]);
    }

    return () => {
      dispatch(flushUserDetails());
    };
  }, [id]);

  React.useEffect(() => {
    // --> Setting all values in fields for edit
    if (
      userDetail &&
      Object.keys(userDetail)?.length > 0 &&
      window.location.pathname?.includes("edit")
    ) {
      if (userDetail?.orgId) {
        dispatch(getPermissionRoles({ orgId: userDetail?.orgId }));
        // dispatch(getOrganisation({ id: userDetail?.orgId })); // comment <<<<
      }
      let temp = { ...timeZone };
      temp.offset = userDetail?.timeZone?.offset;
      temp.label = userDetail?.timeZone?.text;
      setTimeZone(temp);
      setPermissions(userDetail?.permissions);
      setValue("organisation", userDetail?.orgId);
      setValue("userRole", userDetail?.userRole?.id);
      setUserRoleId(userDetail?.userRole?.id);
      setValue("name", userDetail?.name);
      setValue("email", userDetail?.email);
      setValue("mobile", userDetail?.mobile);
      setValue("rfid", userDetail?.details?.rfid);
      setValue("department", userDetail?.details?.department);
      setValue("country", userDetail?.details?.country);
      setTimeout(() => {
        setValue(
          "radio-buttons-group",
          userDetail?.reportingAdmin === "few"
            ? "select"
            : userDetail?.reportingAdmin === "all"
              ? "All"
              : userDetail?.reportingAdmin === "none"
                ? "None"
                : undefined
        );
        setAdminRadio(
          userDetail?.reportingAdmin === "few"
            ? "select"
            : userDetail?.reportingAdmin === "all"
              ? "All"
              : userDetail?.reportingAdmin === "none"
                ? "None"
                : null
        );
        if (userDetail?.reportingAdmin === "few") {
          if (userDetail?.fewReportingAdminList?.length > 0) {
            dispatch(
              getBulkUsersDetails(
                {
                  usersIds: userDetail?.fewReportingAdminList,
                  selection: ["name"],
                },
                (data) => {
                  let temp = data?.map((_) => ({
                    id: _?._id,
                    name: _?.name,
                  }));
                  setValue("user", temp);
                  setSelectedUser(temp);
                  setInitialSelectedUsers(temp);
                }
              )
            );
          }
        }
        setValue(
          "radio-buttons-group-geo",
          userDetail?.assignGeofence === "few"
            ? "select"
            : userDetail?.assignGeofence === "all"
              ? "All"
              : userDetail?.assignGeofence === "none"
                ? "None"
                : undefined
        );
        setGeofenceRadio(
          userDetail?.assignGeofence === "few"
            ? "select"
            : userDetail?.assignGeofence === "all"
              ? "All"
              : userDetail?.assignGeofence === "none"
                ? "None"
                : undefined
        );
        if (userDetail?.assignGeofence === "few") {
          dispatch(
            getUserAssignedGeofences(
              {
                userId: userDetail?._id,
                orgId: userDetail?.orgId,
              },
              (res) => {
                setSelectedGeofence(res);
                setInitialSelectedGeofence(res);
              }
            )
          );
        }
        setValue(
          "radio-buttons-group-route",
          userDetail?.assignRoutes === "few"
            ? "select"
            : userDetail?.assignRoutes === "all"
              ? "All"
              : userDetail?.assignRoutes === "none"
                ? "None"
                : undefined
        );
        setRouteRadio(
          userDetail?.assignRoutes === "few"
            ? "select"
            : userDetail?.assignRoutes === "all"
              ? "All"
              : userDetail?.assignRoutes === "none"
                ? "None"
                : undefined
        );
        if (userDetail?.assignRoutes === "few") {
          dispatch(
            getUserAssignedRoutes(
              {
                userId: userDetail?._id,
                orgId: userDetail?.orgId,
              },
              (res) => {
                setSelectedRoute(res);
                setInitialSelectedRoute(res);
              }
            )
          );
        }
        setValue(
          "radio-buttons-group-device",
          userDetail?.assignDevice === "few"
            ? "select"
            : userDetail?.assignDevice === "all"
              ? "All"
              : userDetail?.assignDevice === "none"
                ? "None"
                : undefined
        );
        setDeviceRadio(
          userDetail?.assignDevice === "few"
            ? "select"
            : userDetail?.assignDevice === "all"
              ? "All"
              : userDetail?.assignDevice === "none"
                ? "None"
                : undefined
        );
        if (userDetail?.assignDevice === "few") {
          dispatch(
            getUserAssignedDevices(
              {
                userId: userDetail?._id,
                orgId: userDetail?.orgId,
              },
              (res) => {
                const temp = [];
                if (res && res?.length) {
                  res?.map((_) => {
                    temp?.push({
                      clientId: _?.clientId,
                      permissions: _?.permissions,
                    });
                  });
                  setSelectedDevice(temp);
                  setInitialSelectedDevice(temp);
                }
              }
            )
          );
        }

        handleCallModuleAPIsForEdit(userDetail);
      }, 500);
    }

    return () => {
      dispatch(flushGeofenceList());
      dispatch(flushUsersList());
      dispatch(flushAllDevices());
      dispatch(flushRoutes());
    };
  }, [userDetail]);

  const _handleChange = (e, newVal) => {
    let temp = { ...timeZone };
    if (newVal) {
      temp.offset = extractNumbers(newVal?.utc)
      temp.label = newVal?.label;
      setTimeZone(temp);
    } else {
      temp.offset = "";
      temp.label = "";
      setTimeZone(temp);
    }
  };

  function extractNumbers(timeZone) {
    const regex = /^([+-]?\d+):?(\d+)?/;
    const match = timeZone.match(regex);
    if (match) {
      const hours = parseInt(match[1], 10);
      const minutes = match[2] ? parseInt(match[2], 10) / 60 : 0;
      const offset = hours + minutes * Math.sign(hours);
      return offset;
    }
    return null;
  }

  return (
    <Grid container height="88vh">
      {/* --- Page title --- */}
      <Grid item container xs={12} height="70px">
        <Grid item container xs={12}>
          <Grid item sm={6} xs={4}>
            <Typography sx={{ ...textClasses.cardTitle }} data-testid="title">
              {!id ? "Add User" : "Edit User"}
            </Typography>
          </Grid>

          <Grid
            item
            sm={6}
            xs={6}
            sx={{
              displey: "flex",
              display: "flex",
              justifyContent: "flex-end",
              height: "36px",
            }}
          >
            {/* <Button
              onClick={history.goBack}
              variant="outlined"
              data-testid="back"
              size="small"
              sx={{
                ...buttonClasses.small,
                color: buttonClasses.lynkitBlackFill,
              }}
            >
              Back
            </Button> */}
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Breadcrumbs sx={{ mb: 1 }}>
            <Typography
              sx={{
                ...textClasses.normalText,
                fontSize: "12px",
                color: themeType.default.themeOrange,
              }}
            >
              <Link
                style={{
                  color: themeType.default.themeOrange,
                  textDecoration: "none",
                }}
                to="/dashboard/users/my-team"
              >
                Users
              </Link>
            </Typography>

            <Typography sx={{ ...textClasses.normalText, fontSize: "12px" }}>
              add-user
            </Typography>
          </Breadcrumbs>
        </Grid>
      </Grid>

      {/* --- main section --- */}
      <Grid
        item
        container
        spacing={2}
        sx={{ mt: 1, height: "calc(100% - 70px)" }}
        direction="row"
        alignItems={"stretch"}
      >
        <Grid item sm={permissions ? 8 : 12} xs={10} height="100%">
          <>
            <Card
              sx={{
                ...cardClasses.basic,
                borderRadius: "8px",
                position: "relative",
                height: "100%",
                overflowY: "scroll",
              }}
            >
              <CardContent>
                <FormControl
                  variant="outlined"
                  size="small"
                  fullWidth
                  sx={{ ...selectStylesOverride(isDarkThemeEnabledSelector) }}
                >
                  <Grid container spacing={2}>
                    {currentUserDetail &&
                      currentUserDetail?.adminSpecificPermissions && (
                        <Grid item md={4}>
                          <Controller
                            name="organisation"
                            control={control}
                            rules={{
                              required: {
                                value: true,
                                message: "Organisation is required",
                              },
                            }}
                            render={({ field: { onChange, value, name } }) => {
                              return (
                                <CustomSelect
                                  selectStylesOverride={selectStylesOverride}
                                  value={value || null}
                                  data-testid="orgId"
                                  disabled={isEditUser}
                                  searchValue={searchFields?.organization}
                                  onSearch={handleSearchOrg}
                                  onInputChange={(e) => {
                                    if (e?.type === "change") {
                                      if (e?.target?.value?.trim() === "") {
                                        handleSearchOrg(true);
                                      } else {
                                        setSearchFields((prev) => ({
                                          ...prev,
                                          organization:
                                            e?.target?.value?.trim(),
                                        }));
                                      }
                                    }
                                  }}
                                  onChange={(e, newVal, reason) => {
                                    if (reason === "clear") {
                                      // dispatch(flushSelectedOrganisation()); // >>>> comment
                                      handleSearchOrg(true);
                                      dispatch(flushAllRolePermissions());
                                    }
                                    onChange(newVal?._id);
                                    setValue("organisation", newVal?._id);
                                    if (newVal?._id) {
                                      // dispatch(
                                      //   getOrganisation({ id: newVal?._id })
                                      // ); // >>>> comment
                                      dispatch(
                                        getPermissionRoles({
                                          orgId: newVal?._id,
                                        })
                                      );
                                    }
                                  }}
                                  name={name}
                                  required
                                  label="Organisation"
                                  errors={errors}
                                  getOptionLabel={(option) => {
                                    if (value === undefined) {
                                      return "";
                                    }
                                    if (typeof option === "string") {
                                      return (
                                        organizations?.find(
                                          (_) => _?._id == value
                                        )?.name || ""
                                      );
                                    } else {
                                      return option?.name || "";
                                    }
                                  }}
                                  filterSelectedOptions
                                  labelKey="name"
                                  options={organizations && organizations}
                                />
                              );
                            }}
                          />
                        </Grid>
                      )}
                    <Grid item md={4}>
                      <Controller
                        name="userRole"
                        control={control}
                        rules={{
                          required: {
                            value: true,
                            message: "User Role is required",
                          },
                        }}
                        render={({ field: { onChange, value, name } }) => {
                          return (
                            <CustomSelect
                              selectStylesOverride={selectStylesOverride}
                              value={value || null}
                              data-testid="userRole"
                              onChange={(e, newVal) => {
                                onChange(newVal?._id);
                                setValue("userRole", newVal?._id);
                                setUserRoleId(newVal?._id);
                              }}
                              name={name}
                              disabled={!organizationSelected}
                              label={"User Role*"}
                              errors={errors}
                              getOptionLabel={(option) => {
                                if (value === undefined) {
                                  return "";
                                }
                                if (typeof option === "string") {
                                  return (
                                    (allPermissionRoles &&
                                      allPermissionRoles?.find(
                                        (_) => _?._id == value
                                      )?.name) ||
                                    ""
                                    // (orgDetails[0] &&
                                    //   orgDetails[0]?.userRoles?.find(
                                    //     (_) => _?.id == value
                                    //   )?.name) ||
                                    // ""
                                  );
                                } else {
                                  return option?.name || "";
                                }
                              }}
                              labelKey="name"
                              options={allPermissionRoles && allPermissionRoles}
                            // options={
                            //   orgDetails &&
                            //   orgDetails[0] &&
                            //   orgDetails[0]?.userRoles
                            // }
                            />
                          );
                        }}
                      />
                    </Grid>
                    <Grid item md={4}>
                      <Controller
                        name="name"
                        control={control}
                        rules={{
                          required: {
                            value: true,
                            message: "Name is required",
                          },
                        }}
                        render={({ field: { onChange, value, name } }) => {
                          return (
                            <CustomInput
                              placeholder="Name"
                              selectStylesOverride={selectStylesOverride}
                              //inputClasses={inputClasses}
                              name={name}
                              value={value || ""}
                              label="Name"
                              required
                              onChange={onChange}
                              errors={errors}
                            />
                          );
                        }}
                      />
                    </Grid>
                    <Grid item md={4}>
                      <Controller
                        name="email"
                        control={control}
                        rules={{
                          required: {
                            value: true,
                            message: "Email is required",
                          },
                          pattern: {
                            value: /^[\w\.-]+@[a-zA-Z\d\.-]+\.[a-zA-Z]{2,}$/,
                            message: "Please enter a valid email",
                          },
                        }}
                        render={({ field: { onChange, value, name } }) => {
                          return (
                            <CustomInput
                              // type='email'
                              data-testid="email"
                              placeholder="Email"
                              selectStylesOverride={selectStylesOverride}
                              inputClasses={inputClasses}
                              name={name}
                              value={value || ""}
                              label="Email"
                              required
                              onChange={onChange}
                              errors={errors}
                            />
                          );
                        }}
                      />
                    </Grid>
                    <Grid item md={4}>
                      <Controller
                        name="country"
                        control={control}
                        rules={{
                          required: {
                            value: true,
                            message: "country is required",
                          },
                        }}
                        render={({ field: { onChange, value, name } }) => {
                          return (
                            <CustomSelect
                              selectStylesOverride={selectStylesOverride}
                              value={value || null}
                              data-testid="country"
                              onChange={(e, newVal) => {
                                onChange(newVal?.value);
                              }}
                              name={name}
                              label={"Country*"}
                              errors={errors}
                              getOptionLabel={(option) => {
                                if (typeof option === "string") {
                                  return (
                                    (countriesList &&
                                      countriesList?.find(
                                        (_) => _?.value == value
                                      )?.label) ||
                                    ""
                                  );
                                } else {
                                  return option?.label || "";
                                }
                              }}
                              // options={[{label: 'Batman', value: "Batman"},{label: 'superman', value: "superman"},{label: 'black panther', value: "black panther"},]}
                              options={countriesList && countriesList}
                            />
                          );
                        }}
                      />
                    </Grid>
                    <Grid item md={4}>
                      <Controller
                        name="mobile"
                        control={control}
                        rules={{
                          required: {
                            value: true,
                            message: "Mobile Number is required",
                          },
                          pattern: {
                            value: /^\d{7,15}$/,
                            message: "Please enter a valid mobile number",
                          },
                        }}
                        render={({ field: { onChange, value, name } }) => {
                          return (
                            <CustomInput
                              //type="number"
                              data-testid="mobile"
                              placeholder="Mobile Number"
                              selectStylesOverride={selectStylesOverride}
                              inputClasses={inputClasses}
                              name={name}
                              value={value || ""}
                              label="Mobile Number"
                              required
                              onChange={onChange}
                              errors={errors}
                            />
                          );
                        }}
                      />
                    </Grid>
                    <Grid item md={4}>
                      <Controller
                        name="rfid"
                        control={control}
                        rules={
                          {
                            // required: {
                            //     value: true,
                            //     message: "Mobile Number is required",
                            // },
                          }
                        }
                        render={({ field: { onChange, value, name } }) => {
                          return (
                            <CustomInput
                              // type='email'
                              data-testid="rfid"
                              placeholder="Assign RFID Card"
                              selectStylesOverride={selectStylesOverride}
                              inputClasses={inputClasses}
                              name={name}
                              value={value || ""}
                              label="Assign RFID Card"
                              // required
                              onChange={onChange}
                              errors={errors}
                            />
                          );
                        }}
                      />
                    </Grid>
                    <Grid item md={4}>
                      <Controller
                        name="department"
                        control={control}
                        rules={
                          {
                            // required: {
                            //     value: true,
                            //     message: "Mobile Number is required",
                            // },
                          }
                        }
                        render={({ field: { onChange, value, name } }) => {
                          return (
                            <CustomInput
                              // type='email'
                              data-testid="department"
                              placeholder="Department"
                              selectStylesOverride={selectStylesOverride}
                              inputClasses={inputClasses}
                              name={name}
                              value={value || ""}
                              label="Department"
                              // required
                              onChange={onChange}
                              errors={errors}
                            />
                          );
                        }}
                      />
                    </Grid>
                    <Grid item md={4}>
                      <Controller
                        name="timeZone"
                        control={control}
                        rules={{
                          required: {
                            value: false,
                            message: "Time zone is required",
                          },
                        }}
                        render={({ field: { onChange, value, name } }) => {
                          return (
                            <CustomSelect
                              data-testid="timeZone"
                              selectStylesOverride={selectStylesOverride}
                              value={timeZone || null}
                              key={value}
                              onChange={(e, newVal) => {
                                _handleChange(e, newVal);
                              }}
                              name={name}
                              required={false}
                              label="Time Zone"
                              errors={errors}
                              getOptionLabel={(option) => {
                                return option?.label || "";
                              }}
                              filterSelectedOptions
                              labelKey="label"
                              options={timezonesList && timezonesList}

                            />
                          );
                        }}
                      />
                    </Grid>
                    {!isEditUser && (
                      <Grid item md={4}>
                        <FormControl
                          variant="outlined"
                          size="small"
                          fullWidth
                          sx={{
                            ...selectStylesOverride(isDarkThemeEnabledSelector),
                          }}
                        >
                          <InputLabel
                            htmlFor="outlined-adornment-password"
                            sx={{ fontSize: "0.8rem" }}
                          >
                            One Time Password*
                          </InputLabel>
                          <OutlinedInput
                            data-testid="password"
                            error={errors.password ? true : false}
                            autoComplete="new-password"
                            {...register("password", {
                              required: {
                                value: true,
                                message: "Please enter a password",
                              },
                              pattern: {
                                value:
                                  /^(?!.*\s)(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*]).{8,}$/,
                                message:
                                  "Password must contain 8 characters, 1 uppercase, 1 lowercase, 1 number and 1 special case character",
                              },
                            })}
                            id="outlined-adornment-password"
                            type={!showPassword ? "text" : "password"}
                            endAdornment={
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowPassword}
                                  onMouseDown={handleMouseDownPassword}
                                  edge="end"
                                >
                                  {!showPassword ? (
                                    <Visibility sx={{ fontSize: "1rem" }} />
                                  ) : (
                                    <VisibilityOff sx={{ fontSize: "1rem" }} />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            }
                            label="One Time Password"
                            fullWidth
                            placeholder="Enter Password"
                          />
                          {errors?.password && (
                            <span
                              role="alert"
                              style={{ color: "#FF0101", fontSize: 11 }}
                            >
                              {errors?.password?.message}
                            </span>
                          )}
                        </FormControl>
                      </Grid>
                    )}
                    {!isEditUser && (
                      <Grid item md={4}>
                        <FormControl
                          variant="outlined"
                          size="small"
                          fullWidth
                          sx={{
                            ...selectStylesOverride(isDarkThemeEnabledSelector),
                          }}
                        >
                          <InputLabel
                            htmlFor="outlined-adornment-password"
                            sx={{ fontSize: "0.8rem" }}
                          >
                            Confirm Password*
                          </InputLabel>
                          <OutlinedInput
                            error={errors.confirmPassword ? true : false}
                            {...register("confirmPassword", {
                              required: {
                                value: passwordEntered && true,
                                message: "Please confirm your password",
                              },
                              validate: {
                                passwordMatch: (value) =>
                                  getValues("password") === value ||
                                  "Please enter same password",
                              },
                            })}
                            autoComplete="new-password"
                            id="outlined-adornment-password"
                            type={showConfirmPassword ? "text" : "password"}
                            endAdornment={
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowConfirmPassword}
                                  onMouseDown={handleMouseDownPassword}
                                  edge="end"
                                >
                                  {showPassword ? (
                                    <Visibility sx={{ fontSize: "1rem" }} />
                                  ) : (
                                    <VisibilityOff sx={{ fontSize: "1rem" }} />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            }
                            label="Confirm Password"
                            fullWidth
                            placeholder="Re-enter Password"
                          />
                          {errors?.confirmPassword?.message && (
                            <span
                              role="alert"
                              style={{ color: "#FF0101", fontSize: 11 }}
                            >
                              {errors?.confirmPassword?.message}
                            </span>
                          )}
                        </FormControl>
                      </Grid>
                    )}
                  </Grid>
                </FormControl>
              </CardContent>

              <AdminSection
                _handleSelect={_handleSelect}
                handleRemoveSelection={handleRemoveSelection}
                Users={Users}
                selectedUser={selectedUser}
                setSelectedUser={setSelectedUser}
                showAll={showAll}
                setShowAll={setShowAll}
                size={size}
                searchFields={searchFields}
                setSearchFields={setSearchFields}
                handleSearchUser={handleSearchUser}
                adminRadio={adminRadio}
                setAdminRadio={setAdminRadio}
                control={control}
                errors={errors}
                handleSubmit={handleSubmit}
                onSubmit={onSubmit}
                setValue={setValue}
              />

              <GeofenceSection
                _handleSelect={_handleSelect}
                handleRemoveSelection={handleRemoveSelection}
                geofencesListing={geofencesListing}
                selectedGeofence={selectedGeofence}
                showAllGeo={showAllGeo}
                setShowAllGeo={setShowAllGeo}
                geoSize={geoSize}
                searchFields={searchFields}
                setSearchFields={setSearchFields}
                handleSearchGeofence={handleSearchGeofence}
                geofenceRadio={geofenceRadio}
                setGeofenceRadio={setGeofenceRadio}
                control={control}
                errors={errors}
                handleSubmit={handleSubmit}
                onSubmit={onSubmit}
                setValue={setValue}
              />

              <RouteSection
                _handleSelect={_handleSelect}
                handleRemoveSelection={handleRemoveSelection}
                routesListing={routesListing}
                selectedRoute={selectedRoute}
                showAllRoute={showAllRoute}
                searchFields={searchFields}
                setSearchFields={setSearchFields}
                handleSearchRoute={handleSearchRoute}
                setShowAllRoute={setShowAllRoute}
                routeSize={routeSize}
                routeRadio={routeRadio}
                setRouteRadio={setRouteRadio}
                control={control}
                errors={errors}
                handleSubmit={handleSubmit}
                onSubmit={onSubmit}
                setValue={setValue}
              />

              <DeviceSection
                _handleSelect={_handleSelect}
                handleRemoveSelection={handleRemoveSelection}
                deviceSize={deviceSize}
                deviceRadio={deviceRadio}
                setDeviceRadio={setDeviceRadio}
                searchFields={searchFields}
                setSearchFields={setSearchFields}
                deviceTypeList={deviceTypeList}
                devices={devices}
                selectedDeviceCategory={selectedDeviceCategory}
                handleSearchDeviceType={handleSearchDeviceType}
                handleSearchDevice={handleSearchDevice}
                selectedDevice={selectedDevice}
                deviceSelected={deviceSelected} //device dropdown controller value
                showAllDevice={showAllDevice}
                setShowAllDevice={setShowAllDevice}
                handleAddDevice={handleAddDevice}
                permissions={deviceSpecificPermissions}
                handleCheckboxChange={handleCheckboxChange}
                control={control}
                errors={errors}
                handleSubmit={handleSubmit}
                onSubmit={onSubmit}
                setValue={setValue}
              />
              <Grid
                item
                display="flex"
                justifyContent="center"
                gap={2}
                mt={3}
                mb={2}
                alignItems="center"
                position={"relative"}
                sx={{ width: "100%", paddingTop: "0 !important" }}
              >
                {!isEditUser && (
                  <Box
                    key={0}
                    display={"flex"}
                    alignItems={"center"}
                    sx={{
                      position: "absolute",
                      left: "10px",
                      top: "50%",
                      transform: "translate(0,-50%)",
                    }}
                  >
                    <Checkbox
                      checked={addMore}
                      onChange={handleAddMore}
                      sx={{
                        "&, &.Mui-checked": {
                          color: themeType.default.themeOrange,
                        },
                        marginBottom: "4px",
                      }}
                    />
                    <Typography
                      sx={{ ...textClasses.normalText, paddingBottom: "4px" }}
                    >
                      Add More Users
                    </Typography>
                  </Box>
                )}
                <Box
                  display={"flex"}
                  justifyContent={"center"}
                  gap={"8px"}
                  width={"80%"}
                >
                  <Button
                    variant="outlined"
                    sx={{
                      ...buttonClasses?.lynkitBlackFill,
                      minHeight: "36px",
                      minWidth: "140px",
                    }}
                    onClick={handleCloseAction}
                    data-testid="cancel"
                  >
                    Cancel
                  </Button>
                  <CustomSubmitBtn
                    variant="contained"
                    data-testid="create"
                    sx={{
                      ...buttonClasses?.lynkitOrangeFill,
                      minHeight: "36px",
                      minWidth: "140px",
                    }}
                    disabled={submitBtnLoading}
                    loading={submitBtnLoading}
                    onClick={handleSubmit(isEditUser ? handleEdit : onSubmit)}
                    label={isEditUser ? "Update" : "Create"}
                  />
                </Box>
              </Grid>
            </Card>
          </>
        </Grid>
        {permissions && (
          <Grid item sm={4} xs={2} height="100%">
            <AddUserRightPanel
              userRolesPermissionListing={userRolesPermissionListing}
              permissions={permissions}
              orgDetails={orgDetails}
              permissionRoles={allPermissionRoles}
              onChangePermissions={setPermissions}
              currentUserDetail={currentUserDetail}
            />
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default AddUser;
