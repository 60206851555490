import React, { useState, useRef, useEffect } from "react";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import CloseIcon from "@mui/icons-material/Close";
import {
  convertIntoGivenTimezone,
  showToast,
} from "../../../../services/functions";
import { useDispatch, useSelector } from "react-redux";
import {
  getObj,
  getTripById,
  saveTripImage,
  tripDetails,
  uploadImagetoS3,
} from "../../../../redux/actions";
import noImageAvailable from "../../../../assets/images/no_img_available.png";
import { AddAPhoto, Close } from "@mui/icons-material";
import { convertFileToBase64 } from "../../../../utils/util";
import { fileTypes } from "../../../../services/constants";
import ThreeDotsLoader from "../../../Components/ThreeDots/ThreeDotsLoader";
import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  Tooltip,
} from "@mui/material";
import { locationUrl, lynctracBaseURL } from "../../../../services/config";
import axios from "axios";

const DocUpload = (props) => {
  const dispatch = useDispatch();
  const fileInputRef = useRef();

  const { index, details } = props;

  const [tripData, setTripData] = useState(null);
  const [tripImages, setTripImages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [locationIndex, setLocationIndex] = useState(false);
  const [locationLoading, setLocationLoading] = useState(false);
  const [imgPreview, setImgPreview] = useState(null);

  const { themeDark } = useSelector((_) => _?.theme);
  const { currentUserDetail } = useSelector((_) => _?.auth);

  const imgBaseURL = process.env.REACT_APP_ENV === "development" ? "https://testv2.lynktrac.io/"  : "https://lynktrac.io/";

  /* ----HANDLERS---- */
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    const base64 = convertFileToBase64(file);
    if (file && fileTypes?.includes(file.type)) {
      setLoading(true);
      base64
        ?.then((res) => {
          let payloadForUpload = { uploadObjects: [res] };
          dispatch(
            uploadImagetoS3({ ...payloadForUpload }, (res) => {
              if (res && res?.success) {
                setLoading(true);
                let payload = {
                  tripId: details?._id,
                  filePath: res?.data[0],
                  type: "web",
                  subtype: "browser", // subtype will have two possible value - live or browser
                };

                if ("geolocation" in navigator) {
                  navigator.geolocation.getCurrentPosition(
                    function (position) {
                      payload.latitude = position.coords.latitude?.toString();
                      payload.longitude = position.coords.longitude?.toString();

                      // Dispatch the action to save the trip image inside the success callback
                      dispatch(
                        saveTripImage(payload, (res) => {
                          if (res && res.success) {
                            handleFetchTripDetails(details?._id);
                          }
                          setLoading(false);
                        })
                      );
                    },
                    function (error) {
                      console.error(
                        "Error getting geolocation:",
                        error.message
                      );

                      // If geolocation fails, still dispatch the action but without latitude and longitude
                      dispatch(
                        saveTripImage(payload, (res) => {
                          if (res && res.success) {
                            handleFetchTripDetails(details?._id);
                          }
                          setLoading(false);
                        })
                      );
                    }
                  );
                } else {
                  // If geolocation is not supported, dispatch the action without latitude and longitude
                  payload.latitude = "";
                  payload.longitude = "";
                  console.error(
                    "Geolocation is not supported by this browser."
                  );
                  dispatch(
                    saveTripImage(payload, (res) => {
                      if (res && res.success) {
                        handleFetchTripDetails(details?._id);
                      }
                      setLoading(false);
                    })
                  );
                }
              } else {
                setLoading(false);
              }
            })
          );
        })
        ?.catch(() => {
          setLoading(false);
        });
    } else {
      showToast("Only png, jpg, doc, pdf are allowed to upload.", true);
    }
  };

  const slide = (index) => {
    const buttonRight = document.getElementById(`slideRight-${index}`);
    const buttonLeft = document.getElementById(`slideLeft-${index}`);

    buttonRight.onclick = function () {
      document.getElementById(`container-${index}`).scrollLeft += 150;
    };
    buttonLeft.onclick = function () {
      document.getElementById(`container-${index}`).scrollLeft -= 150;
    };
  };

  const handleFetchTripDetails = (tripId) => {
    if (tripId) {
      setLoading(true);
      dispatch(
        getTripById({ id: tripId }, (res) => {
          if (res && res?.success) {
            setTripData(res?.data);
            const tempTripDetails = res?.data;
            if (tempTripDetails?.images && tempTripDetails?.images?.length) {
              let tempAllImgs = [];
              tempTripDetails?.images?.map((image, index) => {
                tempAllImgs[index] = image;
                if (image?.filePath) {
                  const imgName =
                    image?.filePath?.split("/")[
                      image?.filePath?.split("/")?.length - 1
                    ];
                  tempAllImgs[
                    index
                  ].filepath = `${imgBaseURL}/api/v1/geo-route/getFile/${imgName}`;
                } else {
                  tempAllImgs[index].filepath = null;
                }
              });
              setTripImages([...tempAllImgs]);
            }
          } else {
            setTripData(null);
            setTripImages([]);
          }
          setLoading(false);
        })
      );
    }
  };

  const handleFetchLocation = (index) => {
    const lat = tripImages[index]?.latitude;
    const lng = tripImages[index]?.longitude;
    if (lat && lng) {
      setLocationIndex(index);
      setLocationLoading(true);
      const payload = {
        latitude: lat,
        longitude: lng,
        appType: "GOOGLE",
      };

      axios
        .post(`${locationUrl}fetchAddress`, payload, {
          headers: {
            "Content-Type": "application/json",
            authorization: "oLjJEPvkqYloUOfU1aCyAvOBMQKKG9Am",
          },
        })
        .then((response) => {
          let address = response?.data?.data[0]?.address;
          let tempTripImages = tripImages;
          tempTripImages[index].address = address;
          setTripImages([...tempTripImages]);
          setLocationLoading(false);
        })
        .catch((error) => {
          console.error("Error:", error);
          setLocationLoading(false);
        });
    }
  };

  useEffect(() => {
    if (details && details?._id) {
      handleFetchTripDetails(details?._id);
    }
  }, [details]);

  return (
    <>
      {loading ? (
        <Grid
          item={true}
          container
          data-testid="documents-loader"
          direction={"column"}
          justifyContent={"center"}
          alignItems="center"
          xs={12}
          sx={{ p: 1 }}
        >
          <ThreeDotsLoader />
        </Grid>
      ) : (
        <div style={{ overflowX: "hidden", display: "flex", marginTop: "2%" }} data-testid="documents-container" >
          <div className="arrowBox">
            <ArrowLeftIcon
              id={`slideLeft-${index}`}
              onClick={() => slide(index)}
              className="arrowClass"
            />
          </div>
          <div
            id={`container-${index}`}
            className="slidingContainer"
            style={{ height: "220px" }}
            mt={5}
          >
            <label class="label" data-testid="upload-img-button">
              <input
                type="file"
                required
                ref={fileInputRef}
                onChange={handleFileChange}
                style={{ display: "none" }}
              />
              <div
                className="trip_doc_upload_box"
                style={{ borderColor: themeDark ? "#403e3e" : "#cfcfcf" }}
              >
                <AddAPhoto style={{ height: "30px", width: "30px" }} />
                Upload Image
              </div>
            </label>
            {tripImages &&
              tripImages?.map((image, i) => {
                return (
                  <div
                    className="imgContainer"
                    style={{ borderColor: themeDark ? "#403e3e" : "#cfcfcf" }}
                    data-testid="img-data-box"
                  >
                    <div className="imgBox">
                      {/* <span className="crossBox">
                        <CloseIcon className="closeIClass" />
                      </span> */}
                      <img
                        className="imgCss--doc-upload"
                        style={{
                          maxWidth: "100%",
                          cursor: image?.filepath ? "pointer" : "not-allowed",
                        }}
                        // src={
                        //   "https://v1.lynktrac.io/auth/v1/resource/getFile/31543359.jpg"
                        // }
                        onClick={() =>
                          image?.filepath
                            ? setImgPreview(image?.filepath)
                            : setImgPreview(null)
                        }
                        src={
                          image?.filepath ? image?.filepath : noImageAvailable
                        }
                        alt=""
                      />
                    </div>
                    <div className="imgDetails">
                      <div className="imgDr">
                        <div className="imgDrLabel">Uploaded By : </div>
                        <Tooltip
                          arrow
                          placement="top"
                          title={
                            image?.addedBy?.name
                              ? `${image?.addedBy?.name} - ${image?.addedBy?.orgName}`
                              : "-"
                          }
                        >
                          <div className="imgDrValue">
                            {image?.addedBy?.name
                              ? `${image?.addedBy?.name} - ${image?.addedBy?.orgName}`
                              : "-"}
                          </div>
                        </Tooltip>
                      </div>
                      <div className="imgDr">
                        <div className="imgDrLabel">Date : </div>
                        <Tooltip
                          arrow
                          placement="top"
                          title={
                            image?.addedBy?.time
                              ? convertIntoGivenTimezone(
                                  image?.addedBy?.time,
                                  currentUserDetail?.timeZone?.offset
                                )
                              : "-"
                          }
                        >
                          <div className="imgDrValue">
                            {image?.addedBy?.time
                              ? convertIntoGivenTimezone(
                                  image?.addedBy?.time,
                                  currentUserDetail?.timeZone?.offset
                                )
                              : "-"}
                          </div>
                        </Tooltip>
                      </div>
                      <div className="imgDr">
                        <div className="imgDrLabel">Method : </div>
                        <div className="imgDrValue">{image?.type ?? "-"}</div>
                      </div>
                      <div className="imgDr">
                        <div className="imgDrLabel">Location : </div>
                        {locationIndex === i && locationLoading ? (
                          <Box ml={1}>
                            <CircularProgress
                              size={13}
                              style={{ color: "#ff7200" }}
                            />
                          </Box>
                        ) : (
                          <>
                            {image?.address ? (
                              <Tooltip
                                arrow
                                placement="top"
                                title={image?.address ?? "-"}
                              >
                                <div className="imgDrValue">
                                  {image?.address ?? "-"}
                                </div>
                              </Tooltip>
                            ) : (
                              <Tooltip
                                arrow
                                placement="top"
                                title={
                                  image?.latitude && image?.longitude
                                    ? `${image?.latitude}/${image?.longitude}`
                                    : "-"
                                }
                              >
                                <div
                                  className="imgDrValue"
                                  style={{
                                    cursor: "pointer",
                                    color: "#ff7200",
                                  }}
                                  onClick={() => handleFetchLocation(i)}
                                >
                                  {image?.latitude && image?.longitude
                                    ? `${Number(image?.latitude)?.toFixed(
                                        5
                                      )}/${Number(image?.longitude)?.toFixed(
                                        5
                                      )}`
                                    : "-"}
                                </div>
                              </Tooltip>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>

          <div className="arrowBox">
            <ArrowRightIcon
              id={`slideRight-${index}`}
              onClick={() => slide(index)}
              className="arrowClass"
            />
          </div>
        </div>
      )}

      <Dialog
        fullWidth
        maxWidth={"lg"}
        open={imgPreview ? true : false}
        onClose={() => setImgPreview(null)}
      >
        <Box display="flex" justifyContent="flex-end" py={1} px={3}>
          <IconButton onClick={() => setImgPreview(null)}>
            <Close />
          </IconButton>
        </Box>
        <DialogContent dividers>
          <Box height="100%" width="100%" maxHeight="830px">
            <img
              src={imgPreview}
              height="100%"
              width="100%"
              style={{ objectFit: "contain", maxHeight: "830px" }}
            />
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default DocUpload;
