import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button, Grid, Typography } from "@mui/material";
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import {
    checkTemplateExistance,
    getTemplate,
    tripDetails,
    emptyTripDetails
} from "../../../../redux/actions";
import ThreeDotsLoader from "../../../Components/ThreeDots/ThreeDotsLoader";

function AdditionalInfo(props) {
    const dispatch = useDispatch();
    const { additionalData, index } = props;
    const isDarkThemeEnabledSelector = useSelector((state) => state.theme.themeDark);
    const themeType = isDarkThemeEnabledSelector ? require("../../../../static/styles/theme") : require("../../../../static/styles/theme");
    const { buttonClasses, textClasses, tableClasses } = themeType;
    const templateExistence = useSelector((state) => state.templates.templateExistence);
    const completeFields = useSelector((state) => state.templates.templateExistence.attributes || []);
    const detailTrips = useSelector((state) => state.tripsData.tripDetails);
    const [loader, setLoader] = useState(false)


    useEffect(() => {
        if (Object.keys(additionalData).length) {
            dispatch(tripDetails({ id: additionalData?._id }))
        }
    }, [additionalData])

    useEffect(() => {
        dispatch(emptyTripDetails())
        setLoader(true)
        dispatch(checkTemplateExistance({ category: "trip" }, () => {
            setLoader(false);
        })
        );


    }, [dispatch])

    useEffect(() => {
        if (templateExistence && templateExistence?._id) {
            dispatch(getTemplate({ template_id: templateExistence && templateExistence?._id }))
        }
    }, [templateExistence])


    const chunkArray = (labelArray, valueArray, chunkSize) => {
        if (labelArray?.length && valueArray?.dynamicArray?.length) {
            labelArray.forEach(item => {
                const matchKey = Object.keys(valueArray?.dynamicArray[0]).find(key => key === item._id);
                if (matchKey) {
                    item.newVal = valueArray?.dynamicArray[0][matchKey];
                }
            });

            const chunkedArray = [];
            for (let i = 0; i < labelArray.length; i += chunkSize) {
                const chunk = labelArray.slice(i, i + chunkSize);
                if (chunk.length < chunkSize) {
                    const diff = chunkSize - chunk.length;
                    for (let j = 0; j < diff; j++) {
                        chunk.push({});
                    }
                }
                chunkedArray.push(chunk);
            }
             labelArray.push({
                _id: "route_source",
                label: "Route Source",
                newVal: valueArray?.route?.routeSource?.name || ""
            });
            labelArray.push({
                _id: "route_via_points",
                label: "Route Via Points",
                newVal: valueArray?.route?.viaPoints?.map(point => point.itemName).join(", ") || ""
            });
            labelArray.push({
                _id: "route_destination",
                label: "Route Destination",
                newVal: valueArray?.route?.routeDestination?.name || ""
            });
            
            labelArray.push({
                _id: "driver_details",
                label: "Vehicle Number",
                newVal: valueArray?.driverData?.vehicleNumber || '-'
            });
            labelArray.push({
                _id: "driver_details",
                label: "Driver Details",
                newVal: valueArray?.driverData?.driver?.map(driver => `${driver.driverName} - ${driver.mobileNo}`).join(", ") || ""
            });
            return chunkedArray;
        }
        return [];
    }

    const chunkedDataArray = completeFields?.length && detailTrips?.dynamicArray ? chunkArray(completeFields, detailTrips, 4) : [];


    const slide = (index) => {
        const buttonRight = document.getElementById(`slideRight-${index}`);
        const buttonLeft = document.getElementById(`slideLeft-${index}`);

        buttonRight.onclick = function () {
            document.getElementById(`container-${index}`).scrollLeft += 1000;
        };
        buttonLeft.onclick = function () {
            document.getElementById(`container-${index}`).scrollLeft -= 1000;
        };


    }
    const styleCssLabel = isDarkThemeEnabledSelector ?
        {
            color: "#808080",
            border: '1px solid #808080',
        }
        :
        {
            color: "#000",
            border: '1px solid #F0F0F0'
        }

    const styleCssValue = isDarkThemeEnabledSelector ?
        {
            color: "#fff",
            border: '1px solid #808080'
        }
        :
        {
            color: "#000",
            border: '1px solid #F0F0F0'
        }



    return (
        <>
            {loader ?
                <Grid item={true} container direction={"column"} justifyContent={"center"} alignItems="center" xs={12} sx={{ p: 1 }}>
                    <ThreeDotsLoader />
                </Grid>
                :
                chunkedDataArray && chunkedDataArray?.length ?
                <div style={{ overflowX: 'hidden',  display: 'flex', height:'190px', paddingTop:'20px'}} >
                    <div className="arrowBox" >
                        <ArrowLeftIcon id={`slideLeft-${index}`} onClick={() => slide(index)} className="arrowClass" />
                    </div>
                    <div id={`container-${index}`} className="slidingContainer"  >
                        {chunkedDataArray && chunkedDataArray?.map((chunk, index) => (
                            <table style={{ ...tableClasses.myCTable, minWidth:'33%', marginLeft:'0px' }} key={index}>
                                <tbody>
                                    {chunk && chunk.map((item, idx) => (
                                        <tr key={idx}>
                                            <th style={{ ...tableClasses.myTH, ...styleCssLabel, width:'40%' }}>{item?.label || '-'}</th>
                                            <td style={{ ...tableClasses.myTD, ...styleCssValue, width: '60%' }}>{item?.newVal || '-'}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        ))}
                    </div>
                    <div className="arrowBox" >
                        <ArrowRightIcon id={`slideRight-${index}`} onClick={() => slide(index)} className="arrowClass" />
                    </div>
                </div>
                :
                <div style={{marginTop: '2%', display:'flex',justifyContent:'center', alignItems:'center', height:'100px'}}>
                    No Trip Details..
                </div>
            }
        </>
    );
}

export default AdditionalInfo;