/* eslint-disable */
import React, { useState, useRef, useEffect } from "react";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Button, Grid, Typography, Box, Breadcrumbs } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import loaderGif from "../../static/images/drawer/loader.gif";
import axios from "axios";
import Fastag from "../../static/images/map/Fastag.svg";
import { convertIntoGivenTimezone, showToast } from "../../services/functions";
import toll from "../../assets/infoWindow/toll.png";
import dateTime from "../../assets/images/dateandtime.png";
import dateTimeBold from "../../assets/images/calendar_bold.png";
import truckIcon from "../../assets/infoWindow/truckIcon.svg";
import location from "../../assets/infoWindow/location.png";
import { locationUrl } from "../../services/config";
import { getUlipData } from "../../redux/actions";
const FastTagPlotting = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [mapLoaded, setMapLoaded] = useState(false);
  const [mapCenter, setMapCenter] = useState({ lat: 28.6139, lng: 77.209 });
  const [myMap, setMymap] = useState(null);
  const [infoWindow, setInfoWindow] = React.useState(null);
  const { currentUserDetail } = useSelector((state) => state.auth);
  const ulipData = useSelector((state) => state.driverReducer.ulipData);
  const [newInfoWindow, setNewInfoWindow] = React.useState(null);
  const isDarkThemeEnabledSelector = useSelector(
    (state) => state.theme.themeDark
  );
  const themeType = isDarkThemeEnabledSelector
    ? require("../../static/styles/darktheme")
    : require("../../static/styles/theme");
  const { buttonClasses, textClasses } = themeType;
  var map;

  const mapElement = document.getElementById("tripmap") || "";

  useEffect(() => {
    if (
      myMap &&
      mapLoaded &&
      Object.keys(ulipData).length &&
      !ulipData.hasOwnProperty("success")
    ) {
      if (ulipData?.features?.length >= 2) {
        let lastObject = ulipData?.features[ulipData?.features?.length - 2]
        let lastlat = lastObject?.geometry?.coordinates[1]
        let lastlng = lastObject?.geometry?.coordinates[0]
        myMap.panTo({
          lat: Number(lastlat),
          lng: Number(lastlng),
        });
      } else {
        let lastObject = ulipData?.features?.features[0];
        let lastlat = lastObject?.geometry?.coordinates[1];
        let lastlng = lastObject?.geometry?.coordinates[0];
        myMap.panTo({
          lat: Number(lastlat),
          lng: Number(lastlng),
        });
      }
      myMap?.data?.addGeoJson(ulipData);
      myMap.data.setStyle({
        strokeColor: "#ff7200",
        strokeWeight: 2,
      });
      
      // Create an array to store polylines with arrows for each segment
      const polylineWithArrows = [];

      // Loop through track points (features) except the last one
      for (let i = 0; i < ulipData.features.length - 1; i++) {
        const currentFeature = ulipData.features[i];
        const nextFeature = ulipData.features[i + 1];

        // Extract coordinates for current and next points
        const currentLat = currentFeature.geometry.coordinates[1];
        const currentLng = currentFeature.geometry.coordinates[0];
        const nextLat = nextFeature.geometry.coordinates[1];
        const nextLng = nextFeature.geometry.coordinates[0];

        // Calculate the direction angle between current and next points
        const angle = Math.atan2(nextLat - currentLat, nextLng - currentLng) * 180 / Math.PI;

        // Create a polyline for the current segment
        const polyline = new google.maps.Polyline({
          path: [
            { lat: currentLat, lng: currentLng },
            { lat: nextLat, lng: nextLng }
          ],
          strokeColor: "#ff7200",
          strokeWeight: 2,
          map: myMap
        });

        // Define the arrow symbol options
        const arrowSymbol = {
          path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
          strokeColor: "#ff7200",
          fillColor: "#ff7200",
          fillOpacity: 1,
          strokeWeight: 1,
          scale: 5 // Adjust scale for arrow size
        };

        // Set the icon for the polyline, rotating it based on the direction
        polyline.setOptions({
          icons: [{
            icon: arrowSymbol,
            offset: '100%', // Place arrow at the end of the line
            rotation: angle
          }]
        });

        // Add the polyline with arrow to the array
        polylineWithArrows.push(polyline);
      }

      myMap.data.forEach((feature, index) => {
        myMap.data.overrideStyle(feature, {
          icon: {
            url: Fastag,
            scaledSize: new google.maps.Size(25, 25),
          },
        });
      });


      myMap.data.addListener("click", async (event) => {
        let markerData = {
          readTime: event.feature.getProperty("readerReadTime")
            ? convertIntoGivenTimezone(
                event.feature.getProperty("readerReadTime"),
                currentUserDetail?.timeZone?.offset
              ) || "NA"
            : "",
          tollPlazaName: event.feature.getProperty("tollPlazaName"),
          vehicleRegNo: event.feature.getProperty("vehicleRegNo"),
          vehicleLatLng: event.feature.getProperty("tollPlazaGeocode"),
        };

        const payload = {
          latitude: markerData?.vehicleLatLng[1],
          longitude: markerData?.vehicleLatLng[0],
          // latitude: markerData?.vehicleLatLng[0],
          // longitude: markerData?.vehicleLatLng[1],
          appType: "GOOGLE",
        };
        axios
          .post(`${locationUrl}fetchAddress`, payload, {
            headers: {
              "Content-Type": "application/json",
              authorization: "oLjJEPvkqYloUOfU1aCyAvOBMQKKG9Am",
            },
          })
          .then((response) => {
            let address = response?.data?.data[0]?.address;
            let infoWindowContent = ` 
                <div class='infoDiv' style="width:390px;">
                <div class='infoChild2'>
                  <div class='subChild2' style="width:33%;">
                    <img class='imgCss'  src=${toll} alt='' />
                    <span class='infoLabel label2'>Toll Plaza</span>
                    <span class='infoValue infoValue2'>${markerData?.tollPlazaName}</span>
                  </div>
          
                    <div class='subChild2' style="width:33%;">
                      <img class='imgCss' style='height:34px; width:34px;'  src=${truckIcon} alt='' />
                      <span class='infoLabel label2'>Vehicle Number </span>
                      <span class='infoValue infoValue2'>${markerData?.vehicleRegNo}</span>
                    </div>
          
                  <div class='subChild2' style="width:335;">
                      <img class='imgCss'  src=${dateTimeBold} alt='' />
                      <span class='infoLabel label2'>Time </span>
                      <span class='infoValue infoValue2'>${markerData?.readTime}</span>
                  </div>
          
                 
                </div>
    
                <div class='infoChild3'>
                <div class='addressDiv'>
                  <div class='addressImg'>
                  <img class='imgCss' src=${location} alt='' />
                  </div>
                  <div class='addressBox'>
                  <span class='infoLabel'>${address}</span>
                  </div>
                </div>
                
              </div>
                </div>
                  `;
            let temp = newInfoWindow;

            if (infoWindow) {
              infoWindow.close();
            }

            temp.setContent(infoWindowContent);
            temp.setPosition(event.latLng);
            temp.open(myMap);
            setInfoWindow(temp);
            setNewInfoWindow(temp);
          })
          .catch((error) => {
            console.error("Error:", error);
          });
      });
    }
  }, [myMap, mapLoaded, ulipData]);

  useEffect(() => {
    if (!ulipData?.success) {
      showToast(ulipData?.message, true);
    }
  }, [ulipData]);

  const loadGoogleMapsApi = () => {
    return new Promise((resolve, reject) => {
      if (window.google) {
        resolve(window.google);
      } else {
        const script = document.createElement("script");
        script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyANnEwTq59T8s30-hXFPNOS5t9_OP47i2A&libraries=geometry,drawing,places`;
        script.onload = () => {
          resolve(window.google);
        };
        script.onerror = (error) => {
          reject(error);
        };
        document.body.appendChild(script);
      }
    });
  };

  useEffect(() => {
    let isMounted = true;
    loadGoogleMapsApi()
      .then((google) => {
        if (isMounted) {
          setMapLoaded(true);
          setNewInfoWindow(new google.maps.InfoWindow());
        }
      })
      .catch((error) => {
        console.error("Error loading Google Maps API:", error);
      });

    return () => {
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    if (mapLoaded && !myMap && mapElement) {
      if (typeof google === "undefined" || typeof google.maps === "undefined") {
        return null;
      }
      if (!mapElement) {
        return null;
      }
      if (!mapCenter.lat || !mapCenter.lng) {
        console.log("Latitude or longitude not provided.");
        return null;
      }

      const mapOptions = {
        zoom: 15,
        center: {
          lat: parseFloat(mapCenter?.lat),
          lng: parseFloat(mapCenter?.lng),
        },
        mapTypeId: "terrain",
        zoomControl: true,
        zoomControlOptions: {
          style: google.maps.ZoomControlStyle.SMALL,
          position: google.maps.ControlPosition.LEFT_BOTTOM,
        },
      };
      map = new google.maps.Map(mapElement, mapOptions);
      setMymap(map);
      return map;
    }
  }, [mapLoaded, mapElement, myMap]);

  return (
    <>
      <Grid container sx={{ height: "81vh", position: "relative" }}>
        {localStorage.getItem("sidebar") ? null : (
          <Grid item xs={12} height="75px">
            <Grid item sm={12} xs={12}>
              <Grid container>
                <Grid item sm={6} xs={4}>
                  <Typography sx={{ ...textClasses.cardTitle }}>
                    Vehicle Detail
                  </Typography>
                </Grid>
                <Grid
                  item
                  sm={6}
                  xs={6}
                  sx={{
                    displey: "flex",
                    display: "flex",
                    justifyContent: "flex-end",
                    height: "36px",
                  }}
                >
                  <Button
                    data-testid="back"
                    onClick={history.goBack}
                    variant="outlined"
                    size="small"
                    sx={{
                      ...buttonClasses.small,
                      color: buttonClasses.lynkitBlackFill,
                    }}
                  >
                    Back
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid>
              <Breadcrumbs sx={{ mb: 1 }}>
                <Typography
                  sx={{
                    ...textClasses.normalText,
                    fontSize: "12px",
                    color: themeType.default.themeOrange,
                  }}
                >
                  <Link
                    data-testid="geofence"
                    style={{
                      color: themeType.default.themeOrange,
                      textDecoration: "none",
                    }}
                    to="/dashboard/vehicle-master"
                  >
                    vehicle-master
                  </Link>
                </Typography>

                <Typography
                  data-testid="viewgeofence"
                  sx={{ ...textClasses.normalText, fontSize: "12px" }}
                >
                  detail
                </Typography>
              </Breadcrumbs>
            </Grid>
          </Grid>
        )}
        <Grid item sm={12}>
          <Box sx={{ display: "flex" }} position="relative">
            {!mapLoaded && !mapElement ? (
              <Grid
                container
                item
                direction={"column"}
                justifyContent={"center"}
                alignItems="center"
                xs={12}
                sx={{ p: 15 }}
              >
                <Grid item>
                  <img src={loaderGif} />
                </Grid>
              </Grid>
            ) : (
              <Box bgcolor="#DADADA" width="100%">
                <div
                  id="tripmap"
                  style={{
                    width: "100%",
                    height: localStorage.getItem("sidebar") ? "90vh" : "80vh",
                  }}
                ></div>
              </Box>
            )}
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default FastTagPlotting;
