import React, { useEffect, useState } from 'react'
import { Grid, Typography, Paper, Button, FormControl, MenuItem, Menu, TablePagination } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { convertIntoGivenTimezone } from '../../../services/functions'
import AddIcon from "@mui/icons-material/Add";
import history from '../../../services/history';
import { KeyboardArrowDown } from '@mui/icons-material'
import { deleteBoard, getBoards, boardStatus, getAllOrganisations } from '../../../redux/actions'
import DeleteModal from './DeleteModal';
import { selectStylesOverride } from "../../../utils/util";
import { showToast } from "../../../services/functions";
import EmptyPage from "../EmptyPage";
import loaderGif from "../../../static/images/drawer/loader.gif";
import { capitalizeSentence } from '../../../services/functions';
import { havePermission } from '../../../utils/permissionCheck';
import CustomSelect from '../../../components/FormUI/CustomSelect';
export default function DynamicBoard() {
    let payload = {
        "module": "",
        "search": "",
        "page": 1,
        "limit": 10
    }
    const { message, error } = useSelector((state) => state.common);
    const dispatch = useDispatch()
    const [dialogBox, setDialogBox] = useState(false);
    const [disableloader, setDisableLoader] = useState(false);
    const [tableIndex, setTableIndex] = useState("");
    const [deleteloader, setDeleteLoader] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [actionIndex, setActionIndex] = useState(null);
    const [comment, setComment] = useState("");
    const [selectedTemplates, setSelectedTemplates] = useState([]);
    const [filter, setFilter] = useState({ ...payload });
    const isDarkThemeEnabledSelector = useSelector(state => state.theme.themeDark);
    const boardData = useSelector(state => state.dynamicDashboard.boardsListing);
    const totalRows = useSelector((state) => state.dynamicDashboard.totalRecords);
    const userDetails = useSelector((state) => state.auth.currentUserDetail);
    const orgListing = useSelector((state) => state.Organisationlisting.OrganisationListing);
    const Users = useSelector((state) => state.userlist.allUsers);
    const [boardLoader, setBoardLoader] = useState(false)
    let devicesLists = ["E-Lock", "Sim Tracking", "Fixed Tracker(fuelSensor)", "Lora Lock", "Fixed Tracker", "Asset Tracker", "B-Lock", "Camera", "Fixed E-lock"]
    const themeType = isDarkThemeEnabledSelector ? require('../../../static/styles/darktheme') : require('../../../static/styles/theme');
    const {
        theme,
        buttonClasses,
        cardClasses,
        inputClasses,
        textClasses,
        tableClasses,
        dividerClass
    } = themeType;

    useEffect(() => {
        if (userDetails?.adminSpecificPermissions) {
            dispatch(getAllOrganisations({ selection: ["name"] }));
        }
    }, [dispatch,userDetails]);


    const [selectedValue, setSelectedValue] = useState({})
    const [actionStatus, setActionStatus] = useState("");
    const [isviewBoard, setIsViewBoard] = useState(false)

    const getOrganistaion = (id) => {
        if (userDetails?.adminSpecificPermissions) {
            const organization = orgListing.find(item => item._id === id);
            if (organization) {
                return organization?.name
            } else {
                return '-'
            }
        }
        else {
            return userDetails?.orgName
        }
    }

    const handleOpenAction = (event, i) => {
        setAnchorEl(event.currentTarget);
        setActionIndex(i);
        setTableIndex(i);
    }

    const tableHeader = ["#", "Board Name", "Organisation Name", "Module Name","Sub Module Name", "Created At", "Status", "Columns Name", "Actions"]
    // const tableRows = []

    const tableRows = boardData.map((row, index) => {
        let items = [];
        items.push(
            <Typography key={`item-${index}`} sx={{ ...textClasses.normalText, textAlign: "center" }}>
                {index + 1}
            </Typography>
        );
        items.push(

            <Typography key={`boardName-${index}`} data-testid='name' sx={{ ...textClasses.normalText, textAlign: "center", color: themeType.default.themeOrange, cursor: 'pointer' }} >
                {capitalizeSentence(row?.boardName) || "-"}
            </Typography>
        );

        items.push(
            <Typography key={`createdAt-${index}`} sx={{ ...textClasses.normalText, textAlign: "center", }}>
                {getOrganistaion(row?.orgId)}
            </Typography>
        );
        items.push(
            <Typography key={`module-${index}`} sx={{ ...textClasses.normalText, textAlign: "center", }}>
                {capitalizeSentence(devicesLists.includes(row?.module) ? 'Device' : row?.module) || "-"}
            </Typography>
        );
        items.push(
            <Typography key={`module-${index}`} sx={{ ...textClasses.normalText, textAlign: "center", }}>
                {capitalizeSentence(row?.subModule) || "-"}
            </Typography>
        );
        items.push(
            <Typography key={`createdAt-${index}`} sx={{ ...textClasses.normalText, textAlign: "center", }}>
                {convertIntoGivenTimezone(row?.createdAt, userDetails.timeZone.offset) || "-"}
            </Typography>
        );
        items.push(
            <>
                {
                    row.status === "ACTIVE" ?
                        <Typography key={`status-${index}`} sx={{ ...textClasses.normalText, textAlign: "center", color: "#008000" }}>
                            {capitalizeSentence(row.status)}
                        </Typography>

                        :
                        <Typography key={`status2-${index}`} sx={{ ...textClasses.normalText, textAlign: "center", color: "#008000" }}>
                            {capitalizeSentence(row.status)}
                        </Typography>
                }
            </>
        );

        items.push(
            <Typography key={`-${row._id}-${index}`} sx={{ ...textClasses.normalText, textAlign: "center", }}>
                {row.boardData.length > 0 &&
                    row.boardData.map((heading, index) => {
                        return <span className='verticalClass' key={`columnName2-${index}`}>{heading.columnName}</span>
                    })
                }
            </Typography>
        );
        items.push(
            <>
                {row?.visibility === "private" ?
                    <Typography key={`action-${index}`} sx={{ ...textClasses.normalText, color: themeType.default.themeOrange, cursor: "pointer" }} onClick={(e) => handleOpenAction(e, index)}>Action <KeyboardArrowDown /></Typography>
                    :
                    <Typography sx={{ color: themeType.default.themeGreen }}>Default</Typography>
                }
            </>
        );
        return items;
    })
    const editDetails = () => {
        setIsViewBoard(false);
        let data = boardData[tableIndex]
        setSelectedValue(data)
        history.push({
            pathname: `/dashboard/board/edit/${boardData[tableIndex]._id}`,
            state: { onChange: data, viewBoard: isviewBoard, case: 'edit' }
        })
    }

    const _moveTo = () => {
        history.push({
            pathname: '/dashboard/board/create'
        })
    }
    const handleCloseAction = () => {
        setActionIndex(null);
        setTimeout(() => {
            setAnchorEl(null);
        }, 1);

    }

    useEffect(() => {
        setBoardLoader(true);
        dispatch(
            getBoards(payload, () => {
                setBoardLoader(false);
            })
        );
    }, [])

    const handleDropdown = (item) => {
        if (item === 'delete') {
            setDialogBox(true);
            let data = boardData[tableIndex]
            setActionStatus('Delete')
            let selectedBoard = []
            selectedBoard?.push(
                {
                    name: data?.boardName,
                    id: data?._id
                }
            )
            setSelectedTemplates(selectedBoard);
        }
        else if (item === 'disable') {
            setDialogBox(true);
            let data = boardData[tableIndex]
            setActionStatus('Disable')
            let selectedBoard = []
            selectedBoard?.push(
                {
                    name: data?.boardName,
                    id: data?._id
                }
            )
            setSelectedTemplates(selectedBoard);

        }

    };

    const handleConfirmActionFn = () => {
        if (actionStatus === 'Delete') {
            setDeleteLoader(true)
            let deletePayload = { boardId: boardData[tableIndex]._id, comment: comment }
            dispatch(
                deleteBoard(deletePayload, () => {
                    setDeleteLoader(false)
                })
            );
        }
        else {
            setDisableLoader(true)
            let disablePayload = { boardId: boardData[tableIndex]._id, comment: comment }
            dispatch(
                boardStatus(disablePayload, () => {
                    setDisableLoader(false)
                })
            );
        }
    }

    useEffect(() => {
        if (message && disableloader) {
            showToast(message, false)
            dispatch(getBoards(payload))
            handleCloseAction();
            setActionIndex(null);
            setDisableLoader(false)
            setDialogBox(false);
        }
        if (error && disableloader) {
            showToast(error, true);
            setDisableLoader(false)
        }
    }, [message, error])

    useEffect(() => {
        if (message && deleteloader) {
            showToast(message, false)
            dispatch(getBoards(payload))
            handleCloseAction();
            setActionIndex(null);
            setDeleteLoader(false)
            setDialogBox(false);
        }
        if (error && deleteloader) {
            showToast(error, true);
            setDeleteLoader(false)
        }
    }, [message, error])


    useEffect(() => {
        if (filter?.page || filter?.limit) {
            let temp = { ...filter };
            //temp.filter.orgId = orgid;
            setBoardLoader(true);
            setTableIndex(null);
            dispatch(
                getBoards({ ...temp }, () => {
                    setBoardLoader(false);
                })
            );
        }
    }, [filter?.page, filter?.limit]);

    
    

    return (
        <>
            <Grid container >
                <Grid item sm={12} xs={12} >
                    <Grid container>
                        <Grid item sm={6} xs={4} >
                            <Typography sx={{ ...textClasses.cardTitle }} data-testid='title'>
                                Board
                            </Typography>
                        </Grid>
                        {havePermission(userDetails, "dynamicBoard", "addBoard") && (
                            <Grid container item sm={6} xs={6} sx={{ justifyContent: 'flex-end' }}>
                                <Button variant="outlined" size="small"
                                    sx={{
                                        ...buttonClasses.small,
                                        minHeight: "36px",
                                        borderColor: themeType.default.themeOrange,
                                        color: buttonClasses.lynkitOrangeEmpty,
                                    }}
                                    startIcon={<AddIcon />}
                                    onClick={() => _moveTo()}
                                >
                                    Create Board
                                </Button>
                            </Grid>
                        )}
                    </Grid>
                </Grid>
                {/* <Grid item sm={12} xs={12} mt={3}>
                    <Grid container>
                        <Grid item sm={2} xs={3} mr={1}>
                            <FormControl
                                variant="outlined"
                                size="small"
                                fullWidth
                                sx={{ ...selectStylesOverride(isDarkThemeEnabledSelector) }}
                            >
                                <CustomSelect
                                    selectStylesOverride={selectStylesOverride}
                                    // onChange={(e, newVal) => {
                                    //     handleFilter(e, newVal, "org");
                                    // }}
                                    sx={{
                                        ...inputClasses.filterField,
                                    }}
                                    label="Organisation"
                                    // errors={errors}
                                    getOptionLabel={(option) => {
                                        return option?.name;
                                    }}
                                    filterSelectedOptions
                                    labelKey="name"
                                    isOptionEqualToValue={(option, value) =>
                                        option._id === value.id && option.name === value.name
                                    }
                                    options={orgListing && orgListing}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item sm={2} xs={3} ml={1}>
                            <FormControl
                                variant="outlined"
                                size="small"
                                fullWidth
                                sx={{ ...selectStylesOverride(isDarkThemeEnabledSelector) }}
                            >
                                <CustomSelect
                                    selectStylesOverride={{ ...selectStylesOverride }}
                                    // onChange={(e, newVal) => {
                                    //     handleFilter(e, newVal, "users");
                                    // }}
                                    sx={{
                                        ...inputClasses.filterField,
                                    }}
                                    label="User"
                                    getOptionLabel={(option) => {
                                        return option?.name;
                                    }}
                                    filterSelectedOptions
                                    labelKey="name"
                                    isOptionEqualToValue={(option, value) =>
                                        option._id === value.id && option.name === value.name
                                    }
                                    options={Users && Users}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                </Grid> */}

                {boardLoader ? (
                    <Grid
                        container
                        item
                        direction={"column"}
                        justifyContent={"center"}
                        alignItems="center"
                        xs={12}
                        sx={{ p: 15 }}
                    >
                        <Grid item>
                            <img src={loaderGif} />
                        </Grid>
                    </Grid>
                ) : (
                    <>
                        {boardData && boardData.length === 0 ? (
                            <EmptyPage body="No Board found.." />
                        ) : (
                            <Grid container sx={{ mt: 2 }}>
                                <Grid item xs={12} sm={12}>
                                    <Paper
                                        sx={{
                                            height: "81vh",
                                            overflow: "auto",
                                            width: "100%",
                                            display: "block",
                                        }}>
                                        <Table
                                            header={tableHeader}
                                            rows={tableRows}
                                            totalRows={totalRows}
                                            filter={filter}
                                            setFilter={setFilter}
                                            themeType={themeType}
                                        />
                                    </Paper>
                                </Grid>


                            </Grid>
                        )}
                    </>
                )}
            </Grid>
            {
                actionIndex != null &&
                <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={actionIndex != null}
                    onClose={handleCloseAction}
                    MenuListProps={{
                        'aria-labelledby': 'basic-button',
                    }}
                    sx={{ display: actionIndex != null ? "block !important" : "none !important" }}
                >
                    {havePermission(userDetails, "dynamicBoard", "editBoard") && boardData && boardData[tableIndex]?.visibility === "private" ?
                        <MenuItem key={2} onClick={() => { editDetails('row'); handleCloseAction(); }}>Edit</MenuItem>
                        : null}
                    {havePermission(userDetails, "dynamicBoard", "disableBoard") && boardData && boardData[tableIndex]?.visibility === "private" ?
                        <MenuItem data-testid='disable' key={3} onClick={() => { handleDropdown('disable'); handleCloseAction(); }}>Disable</MenuItem>
                        : null}
                    {havePermission(userDetails, "dynamicBoard", "disableBoard") && boardData && boardData[tableIndex]?.visibility === "private" ?
                        <MenuItem data-testid='delete' key={4} onClick={() => { handleDropdown('delete'); handleCloseAction(); }}>Delete</MenuItem>
                        : ''}
                </Menu>

            }

            {dialogBox && (
                <DeleteModal
                    open={dialogBox}
                    hide={() => {
                        setDialogBox(false);
                        setActionStatus(null);
                        handleCloseAction();
                        setComment("");
                    }}
                    tableData={boardData}
                    action={actionStatus}

                    setShowConfirmModal={() => {
                        setDialogBox(false);
                    }}
                    comment={comment}
                    setComment={setComment}
                    selectStylesOverride={selectStylesOverride(
                        isDarkThemeEnabledSelector
                    )}
                    setSelectedTemplates={setSelectedTemplates}
                    selectedTemplates={selectedTemplates}
                    actionFn={handleConfirmActionFn}
                />
            )}



        </>
    )
}

export const Table = ({ header, rows, themeType, totalRows, filter, setFilter }) => {
    const {
        theme,
        buttonClasses,
        cardClasses,
        inputClasses,
        textClasses,
        tableClasses,
    } = themeType;
    return (
        <>
            <table style={{ ...tableClasses.table }}>
                <thead
                    style={{
                        backgroundColor: themeType.default.themeGray,
                        position: "sticky",
                        top: "-1px",
                        zIndex: 1,
                    }}>
                    <tr>
                        {
                            header.slice(0, 2).map((head, i) => {
                                return (
                                    <td key={`table-1-${i}`}
                                        style={{
                                            ...textClasses.boldText,
                                            ...tableClasses.tableHeader,
                                            textAlign: "center",
                                        }}>{head}</td>
                                )
                            })
                        }
                        {
                            header.slice(2, header.length).map((head, i) => {
                                return (
                                    <td key={`table-${i}`}
                                        style={{
                                            ...textClasses.boldText,
                                            ...tableClasses.tableHeader,
                                            textAlign: "center",
                                        }}>{head}</td>
                                )
                            })
                        }
                    </tr>
                </thead>
                <tbody>
                    {
                        rows.length > 0
                            ?
                            <>
                                {
                                    rows.map((row, i) => (
                                        <tr data-testid='tablerow' key={`row-0-${i}`}>
                                            {
                                                row.slice(0, 2).map((col, j) => {
                                                    return (
                                                        <td key={`row-1-${i}-${j}`} data-testid='tablecell'
                                                            style={{
                                                                textAlign: "center",
                                                                ...textClasses.normalText,
                                                                ...tableClasses.dark,
                                                                ...tableClasses.tableBodyClass,
                                                                textAlign: "center",
                                                            }}>
                                                            {col}
                                                        </td>
                                                    )
                                                })
                                            }
                                            {
                                                row.slice(2, row.length).map((col, j) => {
                                                    return (
                                                        <td key={`row-2-${j}`}
                                                            style={{
                                                                textAlign: "center",
                                                                ...textClasses.normalText,
                                                                ...tableClasses.dark,
                                                                ...tableClasses.tableBodyClass,
                                                                textAlign: "center",
                                                            }}>
                                                            {col}
                                                        </td>
                                                    )
                                                })
                                            }

                                        </tr>
                                    ))
                                }

                            </>
                            :
                            <tr
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <td data-testid='nodata' component="th" scope="row" style={{ ...textClasses.normalText, textAlign: "left", overflowWrap: "break-word" }}>

                                </td>

                            </tr>


                    }
                </tbody>

            </table>

            <TablePagination
                component="div"
                data-testid='pagination'
                sx={{
                    ...tableClasses.tableHeader,
                    ...tableClasses.tablePagination,
                    display: "flex",
                    justifyContent: "center",
                    position: "sticky",
                    bottom: "0px",
                    alignItems: "center",
                    mt: "0em",
                    "& .MuiTablePagination-selectLabel ": {
                        margin: "0",
                    },
                    "& .MuiTablePagination-displayedRows": {
                        margin: "0",
                    },
                }}
                count={totalRows || 0}
                page={(filter.page || 1) - 1}
                onPageChange={(e, newPage) => setFilter({ ...filter, "page": newPage + 1 })}
                rowsPerPage={filter.limit}
                onRowsPerPageChange={(e) => setFilter({ ...filter, "page": 1, "limit": e.target.value })}
            />

        </>


    )
}
