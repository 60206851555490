const initialState = {
    connected: false,
    socketInstance: null
  };
  
  const socketReducer = (state = initialState, action) => {
    switch (action.type) {
      case "SOCKET_CONNECTED":
        return {
          ...state,
          connected: true,
        };
      case "SOCKET_DISCONNECTED":
        return {
          ...state,
          connected: false,
        };
      case "SOCKET_INSTANCE":
        return {
          ...state,
          socketInstance: action?.payload,
        };
      default:
        return state;
    }
  };
  
  export default socketReducer;
  