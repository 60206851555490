import { FETCH_ROUTES, FETCH_ROUTE_FLAG, ROUTE_DETAILS, ROUTE_STATUS_COUNT } from "../types";

const INIT_STATE = {
  allRoutes: [],
  routeDetails: null,
  totalRoutes: 0,
  routeStatusCounts: null,
  fetchRouteFlag: true,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_ROUTES:
      return {
        ...state,
        allRoutes: action?.payload?.rows,
        totalRoutes: action?.payload?.count,
      };
    
    case "FLUSH_ROUTES":
      return {
        ...state,
        allRoutes: [],
        totalRoutes: 0,
      };
    
    case FETCH_ROUTE_FLAG: return { ...state, fetchRouteFlag : action?.payload}
    case ROUTE_DETAILS:
      return {
        ...state,
        routeDetails: action?.payload,
      };
    case ROUTE_STATUS_COUNT:
      return {
        ...state,
        routeStatusCounts: action?.payload,
      };
    default:
      return state;
  }
};
