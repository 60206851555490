import React from "react";
import FiltersWrapper from "../../Components/FiltersWrapper/FiltersWrapper";
import {
  Box,
  Button,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Paper,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import loaderGif from "../../../static/images/drawer/loader.gif";
import EmptyPage from "../EmptyPage";
import DataTable from "../../Components/DataTable/DataTable";
import { convertIntoGivenTimezone } from "../../../services/functions";
import {
  getAllDeviceCategoryNames,
  getAllUsers,
  getAllOrganizations,
  flushUsersList,
} from "../../../redux/actions";
import {
  dateTimePickerSlotProps,
  parseDeviceLabel,
  selectStylesOverride,
} from "../../../utils/util";
import { FilterAlt, Search } from "@mui/icons-material";
import { getHealthReportLogsList } from "../../../redux/actions/Health";
import DetailCard from "./components/DetailCard";
import { DateTimePicker } from "@mui/x-date-pickers";

const HealthReport = () => {
  const dispatch = useDispatch();

  /* ---- LOCAL STATES ---- */
  const [openFilterDrawer, setOpenFilterDrawer] = React.useState(false);
  const [selectedReport, setSelectedReport] = React.useState(null);
  const [searchFields, setSearchFields] = React.useState({
    deviceType: null,
    users: null,
    org: null,
  });
  const [filter, setFilter] = React.useState({
    page: 1,
    limit: 10,
    searchValue: "",
  });
  const [finalFilter, setFinalFilter] = React.useState(null);

  /* ---- SELECTORS ---- */
  const { allDeviceCategoryNames } = useSelector((state) => state.deviceslist);
  const { allUsers, allOrganizations } = useSelector((state) => state.userlist);
  const isDarkThemeEnabledSelector = useSelector(
    (state) => state.theme.themeDark
  );
  const { healthReportLogs, healthLogsLoading, totalHealthLogsCount } =
    useSelector((state) => state.health);
  const { currentUserDetail } = useSelector((state) => state.auth);

  const themeType = isDarkThemeEnabledSelector
    ? require("../../../static/styles/darktheme")
    : require("../../../static/styles/theme");
  const { textClasses, inputClasses, buttonClasses } = themeType;

  /* ---- HANDLERS ---- */
  const debounceSearch = () => {
    handleFetchHealthLogs();
  };

  const modifyFilterKeys = (filterObj) => {
    const modifiedFilter = filterObj;
    if (modifiedFilter.hasOwnProperty("page")) {
      modifiedFilter.pageCount = modifiedFilter.page;
      delete modifiedFilter.page;
    }

    if (modifiedFilter.hasOwnProperty("limit")) {
      modifiedFilter.pageLimit = modifiedFilter.limit;
      delete modifiedFilter.limit;
    }

    if (modifiedFilter.hasOwnProperty("fromDate")) {
      modifiedFilter.fromDate = new Date(modifiedFilter.fromDate)?.getTime();
    }

    if (modifiedFilter.hasOwnProperty("toDate")) {
      modifiedFilter.toDate = new Date(modifiedFilter.toDate)?.getTime();
    }

    return modifiedFilter;
  };

  const handleFetchHealthLogs = (fromApplyFilters) => {
    let modifiedFilters = modifyFilterKeys({ ...filter });
    // if(fromApplyFilters){
    //   modifiedFilters.pageCount = 1;
    //   modifiedFilters.pageLimit = 10;
    // }
    dispatch(getHealthReportLogsList({ ...modifiedFilters }));
  };

  const handleApplyFilters = () => {
    if (filter || filter?.q !== "") {
      setSelectedReport(null);
      handleFetchHealthLogs(true);
      const { page, limit, ...rest } = filter;
      setFinalFilter({ ...rest });
    }
  };

  const handleSearchDeviceType = (empty) => {
    if (empty === true) {
      dispatch(getAllDeviceCategoryNames());
    } else {
      if (searchFields?.deviceType || searchFields?.deviceType == "")
        dispatch(
          getAllDeviceCategoryNames({
            searchValue: searchFields?.deviceType,
          })
        );
    }
  };

  const handleSearchUsers = (empty) => {
    if (empty === true) {
      dispatch(
        getAllUsers({
          selection: ["name", "orgName"],
          searchValue: "",
        })
      );
    } else {
      if (searchFields?.users || searchFields?.users == "")
        dispatch(
          getAllUsers({
            selection: ["name", "orgName"],
            searchValue: searchFields?.users,
          })
        );
    }
  };

  const handleSearchOrg = (empty) => {
    if (empty === true) {
      dispatch(getAllOrganizations({ selection: ["name"] }));
    } else {
      if (searchFields?.org || searchFields?.org == "")
        dispatch(
          getAllOrganizations({
            selection: ["name"],
            searchValue: searchFields.org,
          })
        );
    }
  };

  const handleClearAllFilters = () => {
    setFilter({ page: 1, limit: 10 });
    setFinalFilter(null);
    setSelectedReport(null);
    setOpenFilterDrawer(false);
    setSearchFields({
      org: null,
      users: null,
      geofence: null,
    });

    let modifiedFilters = modifyFilterKeys({ page: 1, limit: 10 });
    dispatch(getHealthReportLogsList({ ...modifiedFilters }));
  };

  /* ---- EFFECTS ---- */
  React.useEffect(() => {
    setFilter({
      page: 1,
      limit: 10,
    });
    setSelectedReport(null);
    dispatch(flushUsersList());
    dispatch(getAllDeviceCategoryNames());
    setTimeout(() => {
      handleFetchHealthLogs();
    }, 500);
  }, []);

  React.useEffect(() => {
    if (currentUserDetail) {
      if (currentUserDetail?.adminSpecificPermissions) {
        dispatch(getAllOrganizations({ selection: ["name"] }));
      } else {
        dispatch(getAllUsers({ selection: ["name", "orgName"] }));
      }
    }
  }, [currentUserDetail]);

  React.useEffect(() => {
    let timeoutId;

    if (filter && filter?.searchValue && filter?.searchValue?.length > 3) {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(debounceSearch, 700);
      return () => {
        clearTimeout(timeoutId);
      };
    }

    if (filter?.searchValue === "") {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(debounceSearch, 700);
      return () => {
        clearTimeout(timeoutId);
      };
    }

    if (filter && filter?.searchValue && filter?.searchValue?.length < 3) {
      return;
    }
  }, [filter?.searchValue]);

  React.useEffect(() => {
    if (filter?.page || filter?.limit) {
      handleFetchHealthLogs();
    }
  }, [filter?.page, filter?.limit]);

  /* ---- CONSTANT UTILS ---- */
  const tableColumns = [
    {
      header: "#",
      cell: (entry, i) => {
        return (
          <Typography
            sx={{
              ...textClasses.t13n,
              textAlign: "center",
              textTransform: "capitalize",
            }}
          >
            {/* {i + 1} */}
            {(filter?.page - 1) * filter?.limit + i + 1}
          </Typography>
        );
      },
    },
    {
      header: "Device ID",
      cell: (entry) => {
        return (
          <Typography
            sx={{
              ...textClasses.t13n,
              textAlign: "center",
              textTransform: "capitalize",
              cursor: "pointer",
              color: themeType.default.themeOrange,
            }}
            onClick={() => setSelectedReport(entry)}
          >
            {entry?.deviceId ?? "-"}
          </Typography>
        );
      },
    },
    {
      header: "Device Type",
      cell: (entry) => {
        return (
          <Typography
            sx={{
              ...textClasses.t13n,
              textAlign: "center",
              textTransform: "capitalize",
            }}
          >
            {entry?.deviceType ? parseDeviceLabel(entry?.deviceType) : "-"}
          </Typography>
        );
      },
    },
    {
      header: "Vehicle Number",
      cell: (entry) => {
        return (
          <Typography
            sx={{
              ...textClasses.t13n,
              textAlign: "center",
              textTransform: "capitalize",
            }}
          >
            {entry?.vehicleNumber && entry?.vehicleNumber !== ""
              ? entry?.vehicleNumber
              : "-"}
          </Typography>
        );
      },
    },
    {
      header: "Created On",
      cell: (entry) => {
        return (
          <Typography
            sx={{
              ...textClasses.t13n,
              textAlign: "center",
              textTransform: "capitalize",
            }}
          >
            {entry?.dateTime
              ? convertIntoGivenTimezone(
                  entry?.dateTime,
                  currentUserDetail.timeZone.offset
                )
              : "-"}
          </Typography>
        );
      },
    },
    {
      header: "Created By",
      cell: (entry) => {
        return (
          <Typography
            sx={{
              ...textClasses.t13n,
              textAlign: "center",
              textTransform: "capitalize",
            }}
          >
            {entry?.generatedBy ?? "-"}
          </Typography>
        );
      },
    },
  ];

  const filterDropdowns = [
    {
      key: "byDeviceType",
      name: "Filter by Device Type",
      options: allDeviceCategoryNames || [],
      value: filter?.deviceType ?? null,
      labelKey: "label",
      valueKey: "category",
      // disableClearable: true,
      searchValue:
        searchFields?.deviceType && searchFields?.deviceType !== ""
          ? searchFields?.deviceType
          : null,
      onSearch: handleSearchDeviceType,
      onInputChange: (e) => {
        if (e?.type === "change") {
          if (e.target.value == "") handleSearchDeviceType(true);
          setSearchFields((prev) => ({
            ...prev,
            deviceType: e?.target?.value?.trim(),
          }));
        }
      },
      onChange: (newVal) => {
        if (newVal) {
          setFilter((prev) => ({
            ...prev,
            deviceType: newVal?.category,
          }));
        } else {
          let temp = { ...filter };
          delete temp["deviceType"];
          setFilter(temp);
        }
      },
    },
    {
      key: "_id",
      name: "User",
      options:
        allUsers?.length > 0
          ? allUsers.map((ele) => ({
              name: `${ele.name}-${ele.orgName}`,
              _id: ele._id,
            }))
          : [],
      labelKey: "name",
      valueKey: "_id",
      value: filter?.userId || null,
      searchValue:
        searchFields?.users && searchFields?.users !== ""
          ? searchFields?.users
          : null,
      onSearch: handleSearchUsers,
      onInputChange: (e) => {
        if (e?.type === "change") {
          if (e?.target?.value === "") handleSearchUsers(true);
          setSearchFields((prev) => ({
            ...prev,
            users: e?.target?.value?.trim(),
          }));
        }
      },
      onChange: (newVal) => {
        if (newVal) {
          setFilter((prev) => ({
            ...prev,
            userId: newVal?._id,
          }));
        } else {
          let temp = { ...filter };
          delete temp["userId"];
          setFilter(temp);
        }
      },
    },
  ];

  if (currentUserDetail?.adminSpecificPermissions) {
    filterDropdowns?.splice(1, 0, {
      key: "orgName",
      name: "Organization Name",
      options: allOrganizations ?? [],
      labelKey: "name",
      valueKey: "_id",
      value: filter?.orgId || null,
      searchValue:
        searchFields?.org && searchFields?.org !== ""
          ? searchFields?.org
          : null,
      onSearch: handleSearchOrg,
      onInputChange: (e) => {
        if (e?.type === "change") {
          if (e?.target?.value === "") handleSearchOrg(true);
          setSearchFields((prev) => ({
            ...prev,
            org: e?.target?.value?.trim(),
          }));
        }
      },
      onChange: (newVal) => {
        if (newVal) {
          setFilter((prev) => ({
            ...prev,
            orgId: newVal?._id,
          }));
          dispatch(
            getAllUsers({ orgId: newVal?._id, selection: ["name", "orgName"] })
          );
        } else {
          let temp = { ...filter };
          delete temp["orgId"];
          setFilter(temp);
          dispatch(flushUsersList());
        }
      },
    });
  }

  return (
    <FiltersWrapper
      open={openFilterDrawer}
      handleDrawerToggle={() => setOpenFilterDrawer(!openFilterDrawer)}
      isDarkThemeEnabledSelector={isDarkThemeEnabledSelector}
      themeType={themeType}
      handleApplyFilters={handleApplyFilters}
      filters={filterDropdowns}
      searchElement={
        <Grid container spacing={2} mt={0}>
          <Grid
            item
            md={12}
            ml={2}
            mr={2}
            style={{ width: "100%" }}
            data-testid="search-input-container"
          >
            <FormControl
              variant="outlined"
              size="small"
              fullWidth
              sx={selectStylesOverride(isDarkThemeEnabledSelector)}
            >
              <InputLabel sx={{ fontSize: "12px" }}>Search</InputLabel>
              <OutlinedInput
                sx={{ ...inputClasses.filterField, height: "36px" }}
                placeholder="Search by device Id, vehicle number"
                label="Search"
                value={filter?.searchValue}
                endAdornment={
                  <InputAdornment position="end" onClick={handleApplyFilters}>
                    <IconButton size="small">
                      <Search fontSize="small" />
                    </IconButton>
                  </InputAdornment>
                }
                data-testid="search-input-filter"
                onChange={(e) => {
                  setFilter((v) => ({
                    ...v,
                    searchValue: e.target.value
                      .trimStart()
                      .replace(/\s{2,}/g, " "),
                    page: 1,
                    limit: 10,
                  }));
                }}
              />
            </FormControl>
          </Grid>
        </Grid>
      }
      additionalFilter={
        <Box mx={2} mb={2} display="flex" flexDirection="column" gap={2}>
          <DateTimePicker
            value={filter?.fromDate ? filter?.fromDate : null}
            // maxDate={new Date()}
            onChange={(date) =>
              setFilter((prev) => ({ ...prev, fromDate: date }))
            }
            className="customDatePicker"
            sx={{
              width: "100%",
              marginBottom: 2,
              ...selectStylesOverride(isDarkThemeEnabledSelector),
            }}
            label="From"
            onAccept={(newDate) => {
              if(newDate === null){
                let temp = filter;
                delete filter["fromDate"];
                setFilter({...temp});
              }
            }}
            slotProps={{
              ...dateTimePickerSlotProps(
                inputClasses,
                isDarkThemeEnabledSelector
              ),
              actionBar: { actions: ["clear", "accept"] },
            }}
          />
          <DateTimePicker
            value={filter?.toDate ? filter?.toDate : null}
            // maxDate={new Date()}
            onChange={(date) =>
              setFilter((prev) => ({ ...prev, toDate: date }))
            }
            className="customDatePicker"
            sx={{
              width: "100%",
              marginBottom: 2,
              ...selectStylesOverride(isDarkThemeEnabledSelector),
            }}
            label="To"
            onAccept={(newDate) => {
              if(newDate === null){
                let temp = filter;
                delete filter["toDate"];
                setFilter({...temp});
              }
            }}
            slotProps={{
              ...dateTimePickerSlotProps(
                inputClasses,
                isDarkThemeEnabledSelector
              ),
              actionBar: { actions: ["clear", "accept"] },
            }}
          />
        </Box>
      }
    >
      <Grid container>
        {/* Title and action buttons */}
        <Grid container item xs={12}>
          <Grid item={true} sm={6} xs={4}>
            <Typography data-testid="title" sx={{ ...textClasses.cardTitle }}>
              Health Report
            </Typography>
          </Grid>
          <Grid
            container
            item={true}
            sm={6}
            xs={6}
            sx={{ justifyContent: "flex-end" }}
          >
            {finalFilter && Object.keys(finalFilter)?.length > 0 && (
              <Button variant="text">
                <Typography
                  sx={{
                    ...textClasses.boldText,
                    color: themeType.default.themeOrange,
                    textDecoration: "underline",
                    cursor: "pointer",
                    textTransform: "none",
                  }}
                  onClick={handleClearAllFilters}
                >
                  Clear All Filter(s)
                </Typography>
              </Button>
            )}
            {!openFilterDrawer && (
              <Button
                variant="contained"
                sx={{
                  ...buttonClasses?.lynkitOrangeEmpty,
                  minHeight: "36px",
                }}
                onClick={() => {
                  setOpenFilterDrawer(!openFilterDrawer);
                }}
                startIcon={<FilterAlt />}
                data-testid="filter-btn"
              >
                Filters
                {finalFilter && `(${Object.keys(finalFilter)?.length})`}
              </Button>
            )}
          </Grid>
        </Grid>

        {/* Main section */}
        <Grid item xs={12}>
          {healthLogsLoading ? (
            <Grid
              container
              item={true}
              direction={"column"}
              justifyContent={"center"}
              alignItems="center"
              xs={12}
              sx={{ p: 15 }}
              data-testid="page-loader"
            >
              <Grid item>
                <img src={loaderGif} />
              </Grid>
            </Grid>
          ) : (
            <>
              {!healthReportLogs || healthReportLogs?.length === 0 ? (
                <EmptyPage />
              ) : (
                <Grid
                  container
                  sx={{ mt: 1 }}
                  spacing={1}
                  minHeight={selectedReport ? "77vh" : "unset"}
                  maxHeight="77vh"
                >
                  <Grid item sm={selectedReport ? 8 : 12}>
                    <Paper
                      sx={{
                        height: "77vh",
                        width: "100%",
                        overflowX: "auto",
                        display: "block",
                      }}
                    >
                      <DataTable
                        themeType={themeType}
                        tableColumns={tableColumns}
                        data={healthReportLogs || []}
                        pagination={filter}
                        setPagination={setFilter}
                        pageFilterKey="page"
                        totalRecords={totalHealthLogsCount || 0}
                      />
                    </Paper>
                  </Grid>
                  {selectedReport && (
                    <Grid
                      item
                      sm={4}
                      data-testid="detail-section"
                      height="100%"
                    >
                      <DetailCard
                        logData={selectedReport}
                        onClose={() => setSelectedReport(null)}
                        themeType={themeType}
                        currentUserDetail={currentUserDetail}
                      />
                    </Grid>
                  )}
                </Grid>
              )}
            </>
          )}
        </Grid>
      </Grid>
    </FiltersWrapper>
  );
};

export default HealthReport;
