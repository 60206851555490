import {
  ALERT_COUNT_LOADING,
  DETAIL_LOADING,
  EDIT_MONITORING_DATA,
  GENERATE_ALERT_COMMENT,
  GET_ADMINS_FROM_ACCESSKEY,
  GET_ADMINS_MONITORING,
  GET_ALERTS_DATA,
  GET_ALERT_COMMENTS,
  GET_ASSETS_OBSERVATION,
  GET_GEOFENCE_MONITORING,
  GET_LOCATION,
  GET_MAPVIEW_ALERTS,
  GET_MONITORING_DATA,
  MONITORING_COUNT_LOADING,
  MONITORING_LOADING,
  SAVE_GEOFENCE_MONITORING,
  SET_ADMIN_VALUE,
} from "../types";

const INIT_STATE = {
  monitoringData: [],
  monitoringLocation: [],
  zoneList: [],
  // mapviewAlerts:[],
  error: null,
  alerts: [],
  comments: [],
  admins: [],
  userDetail: null,
  message: "",
  loading: false,
  adminDetail: null,
  alertData: { alerts: [], comments: [], isLoading: false },
  coords: [],
  tripCount: null,
  deviceCount: null,
  alertCount: null,
  monitoringAnalyticsDetails: [],
  monitoringAlertDetails: [],
  devicesUnderObservation: [],
  monitoringAlertDetailsCount: 0,
  activeUserId: null, //for superadmin
  monitoringLoading: false,
  monitoringCountLoading: false, //trip data count loading
  deviceCountLoading: false,
  alertCountLoading: false,
  detailLoading: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_ADMIN_VALUE:
      return {
        ...state,
        adminDetail: action?.payload,
      };
    case GET_MONITORING_DATA:
      return {
        ...state,
        monitoringData: action?.payload,
      };
    case "FLUSH_MONITORING_DATA":
      return {
        ...state,
        monitoringData: [],
      };
    case GET_LOCATION:
      return {
        ...state,
        monitoringLocation: action?.payload,
      };
    case GET_GEOFENCE_MONITORING:
      return {
        ...state,
        zoneList: action?.payload,
      };
    case GET_MAPVIEW_ALERTS:
      return {
        ...state,
        mapviewAlerts: action?.payload,
      };
    case GET_ALERTS_DATA:
      return {
        ...state,
        alertData: {
          alert: action?.payload,
        },
      };
    case GET_ALERT_COMMENTS:
      return {
        ...state,
        alertData: {
          comments: action?.payload,
        },
      };
    case GET_ADMINS_MONITORING:
      return {
        ...state,
        admins: action?.payload,
      };
    case GET_ADMINS_FROM_ACCESSKEY:
      return {
        ...state,
        userDetail: action?.payload,
      };
    case GET_ASSETS_OBSERVATION:
      return {
        ...state,
        // alertData: {
        //   alerts: action?.payload,
        // },
        devicesUnderObservation: action?.payload,
      };
    case MONITORING_LOADING:
      return {
        ...state,
        monitoringLoading: action?.payload
      };
    case MONITORING_COUNT_LOADING:
      return {
        ...state,
        monitoringCountLoading: action?.payload
      };
    case "DEVICE_COUNT_LOADING":
      return {
        ...state,
        deviceCountLoading: action?.payload
      };
    case ALERT_COUNT_LOADING:
      return {
        ...state,
        alertCountLoading: action?.payload
      };
    case DETAIL_LOADING:
      return {
        ...state,
        detailLoading: action?.payload
      };
    case "MONITORING_TRIP_COUNT":
      return {
        ...state,
        tripCount: action?.payload
      };
    case "MONITORING_DEVICE_COUNT":
      return {
        ...state,
        deviceCount: action?.payload
      };
    case "MONITORING_ALERT_COUNT":
      return {
        ...state,
        alertCount: action?.payload
      };
    case "MONITORING_ANALYTICS_DETAILS":
      return {
        ...state,
        monitoringAnalyticsDetails: action?.payload
      };
    case "MONITORING_ALERT_DETAILS":
      return {
        ...state,
        monitoringAlertDetails: action?.payload,
        monitoringAlertDetailsCount: action?.count,
      };
    case "SET_USER_ID":
      return {
        ...state,
        activeUserId: action?.payload
      };
    default:
      return state;
  }
};

export const selectMonitoringData = (state) => state.monitoring.monitoringData;
export const selectMonitoringMapViewAlerts = (state) =>
  state.monitoring.mapviewAlerts;
export const selectIsMonitoringLoading = (state) => state.monitoring.loading;
export const selectMonitoringLocationData = (state) =>
  state.monitoring.monitoringLocation;
export const selectUserDetail = (state) => state.monitoring.userDetail;
export const selectAlertData = (state) => state.monitoring.alertData;
export const selectPlotCoords = (state) => state.monitoring.coords;

