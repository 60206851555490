import {
  Box,
  Breadcrumbs,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Divider,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import {
  dateTimePickerSlotProps,
  selectStylesOverride,
} from "../../../utils/util";
import { useDispatch, useSelector } from "react-redux";
import CustomSelect from "../../../components/FormUI/CustomSelect";
import { borderRadius } from "@mui/system";
import { Controller, useForm, useWatch } from "react-hook-form";
import schedule from "../../../assets/marker/schedule.png";
import { format, setHours, setMinutes } from "date-fns";
import loaderGif from "../../../static/images/drawer/loader.gif";
import MoreTimeIcon from "@mui/icons-material/MoreTime";

import {
  checkTemplateExistance,
  createScheduleTrip,
  driverListing,
  flushScheduleData,
  getAllGeofences,
  getAllRoutes,
  getRouteSchedculeData,
  updateScheduleTrip,
} from "../../../redux/actions";
import { getTemplateFieldsTripSchedule } from "../../Components/Utils";
import { CustomInput } from "../../../components/FormUI";
import {
  Add,
  AddCircleOutline,
  Check,
  Close,
  Delete,
  DeleteOutline,
  RemoveCircleOutline,
  Schedule,
} from "@mui/icons-material";
import DataTable from "../../Components/DataTable/DataTable";
import IframeModal from "../../Components/IframeModal/IframeModal";
import CustomSubmitBtn from "../../../components/FormUI/CustomSubmitBtn";
import { CustomTimeField } from "../../../components/FormUI/CustomTimeField";
import { showToast } from "../../../services/functions";
import moment from "moment";
import { DateTimePicker } from "@mui/x-date-pickers";
import { staticOptionDriver } from "../../../services/constants";
import history from "../../../services/history";
import { useLocation } from "react-router";
import { Link } from "react-router-dom/cjs/react-router-dom";
import _ from "lodash";

let editDataLoading = false;
let payload = {
  limit: 50,
  page: 1,
};
let driverPayload = {
  limit: 1000,
  page_no: 1,
  type: "listing",
  isThirdParty: false,
};
const initialScheduleData = [
  { day: "Monday", dayIndex: 1, isChecked: false },
  { day: "Tuesday", dayIndex: 2, isChecked: false },
  { day: "Wednesday", dayIndex: 3, isChecked: false },
  { day: "Thursday", dayIndex: 4, isChecked: false },
  { day: "Friday", dayIndex: 5, isChecked: false },
  { day: "Saturday", dayIndex: 6, isChecked: false },
  { day: "Sunday", dayIndex: 7, isChecked: false },
];
const dayOptions = [
  { value: "sameDay", label: "Same Day" },
  { value: "nextDay", label: "Next Day" },
  { value: "custom", label: "Custom" },
];

const AddTripSchedule = () => {
  /* ---- INITIALISE DISPATCHER ---- */
  const dispatch = useDispatch();
  const location = useLocation();

  /* ---- LOCAL STATES ---- */
  const [tripScheduleData, setTripScheduleData] =
    React.useState(initialScheduleData);
  const [allWeekDays, setAllWeekDays] = React.useState(false);
  const [showTimeField, setShowTimeField] = React.useState(false);
  const [commonTimefieldVal, setCommonTimefieldVal] = React.useState(null);
  const [showScheduleDetails, setShowScheduleDetails] = React.useState(false);
  const [scheduleData, setScheduleData] = React.useState(null);
  const [templateArray, setTemplateArray] = React.useState([]);
  const [driverDetails, setDriverDetails] = React.useState([
    {
      _id: "",
      mobileNo: "",
      licenseNo: "",
      driverName: "",
    },
  ]);
  const [searchFields, setSearchFields] = React.useState({
    route: null,
  });
  const [driverInnerIndex, setDriverInnerIndex] = React.useState(null);
  const [driverModalIndex, setDriverModalIndex] = React.useState(null);
  const [isEditMode, setIsEditMode] = React.useState(false);
  const [customScheduleTime, setCustomScheduleTime] = React.useState({
    viaPoints: {
      dayArrivals: [],
      dayDepartures: [],
    },
    destination: false,
  });
  const [scheduleDetails, setScheduleDetails] = React.useState(null); // for edit
  const [routeViapoints, setRouteViapoints] = React.useState([]);
  const [routeScheduleState, setRouteScheduleState] = React.useState({
    data: [],
  });
  const [tripData, setTripData] = React.useState({ data: [{}] });
  const [modalFields, setModalFields] = React.useState({
    driverName: "",
    mobileNo: "",
  });
  const [scheduleValidation, setScheduleValidation] = React.useState(null);
  const [initialStartDate, setInitialStartDate] = React.useState(undefined);
  const [initialEndDate, setInitialEndDate] = React.useState(undefined);

  /* ---- SELECTORS ---- */
  const { templateExistence } = useSelector((state) => state.templates);
  const { allRoutes } = useSelector((state) => state.routes);
  const { currentUserDetail } = useSelector((state) => state.auth);
  const { selectedRouteSchedules } = useSelector((state) => state.route);
  const { geofenceListing } = useSelector((state) => state.geofencelist);
  const { createTripScheduleLoading, updateTripScheduleLoading } = useSelector(
    (state) => state.tripsData
  );
  const { tripDetails } = useSelector((state) => state.tripsData);
  const { driverList } = useSelector((state) => state.driverReducer);
  const isDarkThemeEnabledSelector = useSelector(
    (state) => state.theme.themeDark
  );
  const themeType = isDarkThemeEnabledSelector
    ? require("../../../static/styles/darktheme")
    : require("../../../static/styles/theme");

  const { textClasses, buttonClasses, inputClasses, driverModal } = themeType;

  const {
    formState: { errors, dirtyFields },
    control,
    handleSubmit,
    setValue,
    reset,
  } = useForm();

  const routeWatch = useWatch({
    control: control,
    name: "routeName",
  });

  const scheduleStartDateWatch = useWatch({
    control: control,
    name: "scheduleStart",
  });

  const scheduleEndDateWatch = useWatch({
    control: control,
    name: "scheduleEnd",
  });

  const scheduleStartMinDateTime = React.useMemo(() => {
    return new Date();
  }, []);

  const scheduleStartMaxDateTime = React.useMemo(() => {
    return scheduleEndDateWatch ? scheduleEndDateWatch : null;
  }, [scheduleEndDateWatch]);

  const scheduleEndMinDateTime = React.useMemo(() => {
    return scheduleStartDateWatch ? scheduleStartDateWatch : new Date();
  }, [scheduleStartDateWatch]);

  /* ---- HANDLERS ---- */
  const getDriversList = (index) => {
    let arr = [
      staticOptionDriver,
      ...(driverList && driverList?.length > 0 ? driverList : []),
    ];
    let licenseNumbers = [];
    // const data = tripData.data[index];
    driverDetails?.forEach((driver) => {
      licenseNumbers?.push(driver.licenseNo);
    });
    let modifiedList = arr?.filter((driver) => {
      return !licenseNumbers?.includes(driver?.license_no);
    });
    const modifiedData = modifiedList.map((item) => ({
      _id: item._id,
      driverName: item?.name,
      licenseNo: item?.license_no,
      mobileNo: item?.mobile,
      sso_id: item?.formData?.sso_id,
    }));
    return modifiedData;
  };

  const handleTemplateChange = async (event, fieldId) => {
    let i = 0;
    const targetField = templateExistence?.attributes?.filter(
      (f) => f._id == fieldId
    )[0];

    let oldItems = [...templateArray];
    let oldObject = { ...oldItems[i] };
    if (targetField.type == "checkbox") {
      let oldValues = oldObject[fieldId] ? oldObject[fieldId] : [];
      if (event.checked) {
        oldValues.push(event.value);
      } else {
        let pos = oldValues.indexOf(event.value);
        oldValues.splice(pos, 1);
      }
      oldObject[fieldId] = [...oldValues];
    } else {
      oldObject[fieldId] = event.target.value;
      // temp.data[i].dynamicArray[0][fieldId] = event.target.value;
    }
    oldItems[i] = { ...oldObject };
    setTemplateArray([...oldItems]);
  };

  const getItemRow = (i = 0) => {
    const others = templateExistence?.attributes?.map((of) => {
      let component = { ...of };
      let width = "100% !important";
      return (
        <Grid item md={4}>
          {getTemplateFieldsTripSchedule(
            component,
            templateArray,
            handleTemplateChange,
            width,
            isDarkThemeEnabledSelector
          )}
        </Grid>
      );
    });
    return [...others];
  };

  const addDriver = () => {
    setDriverDetails((prev) => [
      ...prev,
      {
        _id: "",
        mobileNo: "",
        licenseNo: "",
        driverName: "",
      },
    ]);
  };

  const removeDriver = (driverIndex) => {
    let temp = driverDetails;
    temp?.splice(driverIndex, 1);
    setDriverDetails([...temp]);
  };

  const convertISOToDate = (isoString) => {
    if (typeof isoString === "string") {
      const dateObject = new Date(isoString);
      if (!isNaN(dateObject)) {
        // Use UTC methods to preserve original time
        const year = dateObject.getUTCFullYear();
        const month = dateObject.getUTCMonth() + 1; // Months are 0-indexed
        const day = dateObject.getUTCDate();
        const hours = dateObject.getUTCHours();
        const minutes = dateObject.getUTCMinutes();
        const seconds = dateObject.getUTCSeconds();
        const milliseconds = dateObject.getUTCMilliseconds();
  
        // Create a new Date object using UTC values
        const utcDate = new Date(year, month - 1, day, hours, minutes, seconds, milliseconds);
        return utcDate;
      } else {
        console.log("Invalid ISO date string.");
      }
    } else {
      console.log("Input must be a string.");
    }
  };

  const convertTimeISTtoUTC = (timeString, isToUTC = true) => {
    if (timeString) {
      // Check if the time string is in hh:mm format
      // const timeRegex = /^([0-1][0-9]|2[0-3]):([0-5][0-9])$/;
      // if (!timeRegex.test(timeString)) {
      //   throw new Error("Invalid time format. Please use hh:mm");
      // }

      // Handle potential undefined userOffset (assuming it's from userDetails)
      const userOffset = currentUserDetail?.timeZone?.offset || 0; // Use 0 as default if not available

      const hours = parseInt(timeString.split(":")[0]);
      const minutes = parseInt(timeString.split(":")[1]);

      const localTime = new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate(),
        hours,
        minutes
      );

      let adjustedTime;
      if (isToUTC) {
        adjustedTime = new Date(
          localTime.getTime() - userOffset * 60 * 60 * 1000
        ); // Subtract offset for UTC
      } else {
        adjustedTime = new Date(
          localTime.getTime() + userOffset * 60 * 60 * 1000
        ); // Add offset for IST
      }

      // Ensure leading zeros for both hours and minutes
      const adjustedHours = adjustedTime.getHours().toString().padStart(2, "0");
      const adjustedMinutes = adjustedTime
        .getMinutes()
        .toString()
        .padStart(2, "0");

      // Adjust for overflow (hours) and format back to "hh:mm"
      const formattedTime = `${adjustedHours}:${adjustedMinutes}`;

      return formattedTime;
    }
  };

  const convertDateISTtoUTC = (dateObject, isToUTC = true) => {
    if (dateObject instanceof Date && !isNaN(dateObject)) {
      const userOffset = currentUserDetail?.timeZone?.offset || 0;

      let adjustedTime;
      if (isToUTC) {
        // Subtract offset for UTC
        adjustedTime = new Date(
          dateObject.getTime() - userOffset * 60 * 60 * 1000
        );
      } else {
        // Add offset for local time
        adjustedTime = new Date(
          dateObject.getTime() + userOffset * 60 * 60 * 1000
        );
      }

      return adjustedTime;
    } else {
      throw new Error(
        "Invalid Date object. Please provide a valid Date object."
      );
    }
  };

  const convertDateToISO = (dateObject) => {
    if(dateObject){
      const year = dateObject.getFullYear();
      const month = dateObject.getMonth() + 1; // Months are 0-indexed
      const day = dateObject.getDate();
      const hours = dateObject.getHours();
      const minutes = dateObject.getMinutes();
      const seconds = dateObject.getSeconds();

      // Format the date string in ISO format with custom "Z" for no timezone
      const isoDateString = `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}T${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}Z`; // Use "Z" for UTC

      return isoDateString;
    }
  };

  const handleCreateSchedule = (data) => {
    if (data) {
      if (!data.routeName) {
        showToast("Please select Route.", true);
        return;
      }
      if (!data.tripScheduleName) {
        showToast("Trip schedule name is mandatory.", true);
        return;
      }
      if(data?.scheduleStart && data?.scheduleEnd){
        if(compareDates(data?.scheduleStart, data?.scheduleEnd) !== -1){
          showToast("Schedule start date should be greater than end date", true);
          return;
        }
      }
      
      let scheduleWithGMT = tripScheduleData
        ?.filter((_) => _?.isChecked === true)
        ?.map((_) => {
          let timeValues = _?.value
            ?.filter((_) => _ !== null)
            ?.map((_) => convertTimeISTtoUTC(_));
          return { day: _?.dayIndex, dayName: _?.day, time: timeValues };
        });

      const hasEmptyArray = scheduleWithGMT?.some(
        (obj) => obj?.time?.length === 0
      );

      if (hasEmptyArray) {
        showToast("Please provide time of schedule", true);
        return;
      }

      const tempPayload = {
        data: [
          {
            tripId: "",
            routeNotAvailable: false,
            route: {
              id: data?.routeName?._id,
              routeName: data?.routeName?.routeName,
            },
            routeSchedule: scheduleData || {},
            dynamicArray: templateArray || [],
            location: false,
            sameTripId: false,
            sameRoute: false,
            driverData: {
              vehicleNumber: data?.vehicleNumber,
              driver: driverDetails || [],
              images: [],
            },
            devices: [
              {
                deviceId: "",
                address: {},
                primary: false,
                remark: "",
              },
            ],
            image: [],
          },
        ],
        schedule: [...scheduleWithGMT],
        templateId: templateExistence?._id,
        scheduleName: data?.tripScheduleName,
      };

      if (data?.scheduleStart) {
        let startDateToUTC = convertDateISTtoUTC(data?.scheduleStart);
        let startDateISO = convertDateToISO(startDateToUTC);
        tempPayload.scheduleStart = startDateISO;
      }
      if (data?.scheduleEnd) {
        let endDateToUTC = convertDateISTtoUTC(data?.scheduleEnd);
        let endDateISO = convertDateToISO(endDateToUTC);
        tempPayload.scheduleEnd = endDateISO;
      }

      dispatch(
        createScheduleTrip({ ...tempPayload }, (res) => {
          if (res && res?.success) {
            history.push("/dashboard/trips/trip-schedule");
          }
        })
      );
    }
  };

  const handleUpdateSchedule = (data) => {
    if (data) {
      if (!data.routeName) {
        showToast("Please select Route.", true);
        return;
      }
      if (!data.tripScheduleName) {
        showToast("Trip schedule name is mandatory.", true);
        return;
      }
      if(data?.scheduleStart && data?.scheduleEnd){
        if(compareDates(data?.scheduleStart, data?.scheduleEnd) !== -1){
          showToast("Schedule start date should be greater than end date", true);
          return;
        }
      }

      let schedule = tripScheduleData
        ?.filter((_) => _?.isChecked === true)
        ?.map((_) => {
          let timeValues = _?.value
            ?.filter((_) => _ !== null)
            ?.map((_) => convertTimeISTtoUTC(_));
          return { day: _?.dayIndex, dayName: _?.day, time: timeValues };
        });

      const hasEmptyArray = schedule?.some((obj) => obj?.time?.length === 0);

      if (hasEmptyArray) {
        showToast("Please provide time of schedule", true);
        return;
      }

      const tempPayload = {
        type: "update",
        comment: "",
        scheduleId: scheduleDetails?._id,
        updateData: {
          data: [
            {
              tripId: "",
              routeNotAvailable: false,
              route: {
                id: data?.routeName?._id,
                routeName: data?.routeName?.routeName,
              },
              routeSchedule: scheduleData || {},
              dynamicArray: templateArray || [],
              location: false,
              sameTripId: false,
              sameRoute: false,
              driverData: {
                vehicleNumber: data?.vehicleNumber,
                driver: driverDetails || [],
                images: [],
              },
              devices: [
                {
                  deviceId: "",
                  address: {},
                  primary: false,
                  remark: "",
                },
              ],
              image: [],
            },
          ],
          templateId: templateExistence?._id,
        },
      };

      if (!!dirtyFields?.tripScheduleName) {
        tempPayload.updateData.scheduleName = data?.tripScheduleName;
      }

      // if (!!dirtyFields?.scheduleStart && data?.scheduleStart) {
      //   let startDateToUTC = convertDateISTtoUTC(data?.scheduleStart);
      //   let startDateISO = convertDateToISO(startDateToUTC);
      //   tempPayload.updateData.scheduleStart = startDateISO;
      // }

      if (data?.scheduleStart !== undefined) {
        if (!!dirtyFields?.scheduleStart || data.scheduleStart === null) {
          if (data?.scheduleStart) {
            let startDateToUTC = convertDateISTtoUTC(data?.scheduleStart);
            let startDateISO = convertDateToISO(startDateToUTC);
            tempPayload.updateData.scheduleStart = startDateISO;
          } else {
            tempPayload.updateData.scheduleStart = null;
          }
        } else {
          let startDateToUTC = convertDateISTtoUTC(initialStartDate);
          let startDateISO = convertDateToISO(startDateToUTC);
          tempPayload.updateData.scheduleStart = startDateISO;
        }
      }

      // if (!!dirtyFields?.scheduleEnd && data?.scheduleEnd) {
      //   let endDateToUTC = convertDateISTtoUTC(data?.scheduleEnd);
      //   let endDateISO = convertDateToISO(endDateToUTC);
      //   tempPayload.updateData.scheduleEnd = endDateISO;
      // }
      if (data?.scheduleEnd !== undefined) {
        if (!!dirtyFields?.scheduleEnd || data.scheduleEnd === null) {
          if (data?.scheduleEnd) {
            let endDateToUTC = convertDateISTtoUTC(data?.scheduleEnd);
            let endDateISO = convertDateToISO(endDateToUTC);
            tempPayload.updateData.scheduleEnd = endDateISO;
          } else {
            tempPayload.updateData.scheduleEnd = null;
          }
        } else {
          let endDateToUTC = convertDateISTtoUTC(initialEndDate);
          let endDateISO = convertDateToISO(endDateToUTC);
          tempPayload.updateData.scheduleEnd = endDateISO;
        }
      }

      if (!_?.isEqual(scheduleDetails?.schedule, schedule)) {
        tempPayload.updateData.schedule = [...schedule];
      }

      if (Object.keys(tempPayload.updateData.data[0])?.length === 0) {
        delete tempPayload["updateData"]["data"];
      }

      dispatch(
        updateScheduleTrip({ ...tempPayload }, (res) => {
          if (res && res?.success) {
            history.push("/dashboard/trips/trip-schedule");
          }
        })
      );
    }
  };

  const convertHHMMToDate = (hhmm = "") => {
    if (typeof hhmm !== "string") {
      // Handle the case where hhmm is not a string, maybe return null or throw an error
      return null;
    }
    const [hours, minutes] = hhmm?.split(":").map(Number);
    const now = new Date();
    const resultDate = new Date(setMinutes(setHours(now, hours), minutes));
    return resultDate;
  };

  const checkAllChecked = () => {
    let tempData = tripScheduleData;
    if (tempData?.every((_) => _?.isChecked === true)) {
      return true;
    } else {
      return false;
    }
  };

  const handleSelectedSchedule = (selectedSchedule) => {
    setScheduleData(selectedSchedule);
    setShowScheduleDetails(true);

    let tempCustomScheduleTime = {
      viaPoints: {
        dayArrivals: [],
        dayDepartures: [],
      },
      destination: false,
    };
    if (selectedSchedule?.routeDestination) {
      const scheduleDestination = selectedSchedule?.routeDestination;
      if (
        scheduleDestination?.dayArrival &&
        scheduleDestination?.dayArrival !== "nextDay" &&
        scheduleDestination?.dayArrival !== "sameDay"
      ) {
        tempCustomScheduleTime.destination = true;
      }
    }
    if (
      selectedSchedule?.viaPoints &&
      selectedSchedule?.viaPoints?.length > 0
    ) {
      const scheduleViaPoints = selectedSchedule?.viaPoints;
      let tempArrivals = tempCustomScheduleTime?.viaPoints?.dayArrivals;
      let tempDepartures = tempCustomScheduleTime?.viaPoints?.dayDepartures;
      scheduleViaPoints?.map((_, idx) => {
        if (
          _?.dayArrival &&
          _?.dayArrival !== "nextDay" &&
          _?.dayArrival !== "sameDay"
        ) {
          tempArrivals?.push(idx);
        }
        if (
          _?.dayDeparture &&
          _?.dayDeparture !== "nextDay" &&
          _?.dayDeparture !== "sameDay"
        ) {
          tempDepartures?.push(idx);
        }
      });
      tempCustomScheduleTime.viaPoints.dayArrivals = tempArrivals?.filter(
        (value, index, self) => self.indexOf(value) === index
      );
      tempCustomScheduleTime.viaPoints.dayDepartures = tempDepartures?.filter(
        (value, index, self) => self.indexOf(value) === index
      );
    }
    setCustomScheduleTime({ ...tempCustomScheduleTime });
  };

  const addDriverFunction = () => {
    let tempDriverDetails = driverDetails;
    tempDriverDetails[driverInnerIndex].driverName = modalFields.driverName;
    tempDriverDetails[driverInnerIndex].mobileNo = modalFields.mobileNo;
    setDriverDetails([...tempDriverDetails]);
    setDriverModalIndex(null);
  };

  const removeTimeValue = (index, i) => {
    let temp = [...tripScheduleData];
    temp[index].value.splice(i, 1);

    if (temp[index]?.value.length == 0) {
      temp[index].isChecked = false;
    }
    setTripScheduleData(temp);
  };

  const handleSearchRoute = (empty) => {
    if (empty) {
      dispatch(
        getAllRoutes({
          filter: {
            status: "ACTIVE",
          },
          selection: [
            "routeName",
            "scheduleData",
            "viaPoints",
            "isSchedulesExists",
            "routeSource",
            "routeDestination",
          ],
        })
      );
    } else {
      dispatch(
        getAllRoutes({
          filter: {
            status: "ACTIVE",
          },
          searchValue: searchFields?.route,
          selection: [
            "routeName",
            "scheduleData",
            "viaPoints",
            "isSchedulesExists",
            "routeSource",
            "routeDestination",
          ],
        })
      );
    }
  };

  /**
   * 
   * @param {*} date1 
   * @param {*} date2 
   * @returns -1 if date1 is less than date2 | 1 if date1 is greater than date2 | 0 if both dates are equal
   */
  const compareDates = (date1, date2) => {
    if(date1 && date2){
      // Ensure both arguments are Date objects
      if (!(date1 instanceof Date) || !(date2 instanceof Date)) {
        throw new Error('Both arguments must be Date objects');
      }
    
      const time1 = date1.getTime();
      const time2 = date2.getTime();
    
      // Compare the timestamps
      if (time1 < time2) {
        return -1; // date1 is earlier than date2
      } else if (time1 > time2) {
        return 1; // date1 is later than date2
      } else {
        return 0; // dates are equal
      }
    }
  }

  /* ---- USE EFFECTS ---- */
  React.useEffect(() => {
    setDriverModalIndex(null);
    setShowTimeField(false);
    setIsEditMode(false);
    setShowScheduleDetails(false);
    setScheduleDetails(null);
    setCommonTimefieldVal(null);
    setTemplateArray([]);
    setModalFields({
      driverName: "",
      mobileNo: "",
    });
    setTripScheduleData([
      { day: "Monday", dayIndex: 1, isChecked: false, value: [] },
      { day: "Tuesday", dayIndex: 2, isChecked: false, value: [] },
      { day: "Wednesday", dayIndex: 3, isChecked: false, value: [] },
      { day: "Thursday", dayIndex: 4, isChecked: false, value: [] },
      { day: "Friday", dayIndex: 5, isChecked: false, value: [] },
      { day: "Saturday", dayIndex: 6, isChecked: false, value: [] },
      { day: "Sunday", dayIndex: 7, isChecked: false, value: [] },
    ]);
    setDriverDetails([
      {
        _id: "",
        mobileNo: "",
        licenseNo: "",
        driverName: "",
      },
    ]);
    setSearchFields({
      route: null,
    });
    setCustomScheduleTime({
      viaPoints: {
        dayArrivals: [],
        dayDepartures: [],
      },
      destination: false,
    });
    setInitialStartDate(undefined);
    setInitialEndDate(undefined);
    reset();
  }, []);

  React.useEffect(() => {
    if (
      location?.pathname?.includes("/trip-schedule/edit") &&
      location?.state &&
      location?.state?.data &&
      Object.keys(location?.state?.data)?.length > 0
    ) {
      setIsEditMode(true);
      setScheduleDetails(location?.state?.data);
    } else {
      setIsEditMode(false);
      setScheduleDetails(null);
    }
  }, [location]);

  React.useEffect(() => {
    dispatch(checkTemplateExistance({ category: "trip" }));
    dispatch(
      getAllRoutes({
        filter: {
          status: "ACTIVE",
        },
        selection: [
          "routeName",
          "scheduleData",
          "viaPoints",
          "isSchedulesExists",
          "routeSource",
          "routeDestination",
        ],
      })
    );
    dispatch(getAllGeofences({ ...payload, selection: ["name"] }));
    dispatch(driverListing(driverPayload));
    // setEditImgs([]);
    // setImageBase([]);
  }, [dispatch]);

  React.useEffect(() => {
    if (
      templateExistence &&
      templateExistence?.attributes &&
      templateExistence?.attributes?.length > 0
    ) {
      const filteredData = {};
      templateExistence?.attributes.forEach((item) => {
        filteredData[item._id] = item.value || "";
      });
      setTemplateArray([filteredData]);
    }
  }, [templateExistence]);

  React.useEffect(() => {
    // storing selected route schedules in an array state
    if (selectedRouteSchedules && selectedRouteSchedules.length) {
      let tempSchedules = selectedRouteSchedules;
      setRouteScheduleState([...tempSchedules]);
    } else {
      setRouteScheduleState([]);
    }
  }, [selectedRouteSchedules]);

  React.useEffect(() => {
    if (isEditMode && scheduleDetails) {
      let scheduleTripDetails = scheduleDetails?.data[0];
      let existingSchedules = tripScheduleData;
      setTimeout(() => {
        setTemplateArray(scheduleTripDetails?.dynamicArray);
      }, 500);

      setValue("tripScheduleName", scheduleDetails?.scheduleName);
      setValue("routeName", {
        _id: scheduleTripDetails?.route?.id,
        routeName: scheduleTripDetails?.route?.routeName,
      });
      setValue("vehicleNumber", scheduleTripDetails?.driverData?.vehicleNumber);

      if (scheduleDetails?.scheduleStart) {
        let scheduleStartDateISO = convertISOToDate(
          scheduleDetails?.scheduleStart
        );
        let scheduleStartDateToLocalTime = convertDateISTtoUTC(
          scheduleStartDateISO,
          false
        );
        setValue("scheduleStart", scheduleStartDateToLocalTime);
        setInitialStartDate(scheduleStartDateToLocalTime);
      }

      if (scheduleDetails?.scheduleEnd) {
        let scheduleEndDateISO = convertISOToDate(scheduleDetails?.scheduleEnd);
        let scheduleEndDateToLocalTime = convertDateISTtoUTC(
          scheduleEndDateISO,
          false
        );
        setValue("scheduleEnd", scheduleEndDateToLocalTime);
        setInitialEndDate(scheduleEndDateToLocalTime);
      }

      if (scheduleTripDetails?.route?.viaPoints) {
        setRouteViapoints(
          JSON.parse(JSON.stringify(scheduleTripDetails?.route?.viaPoints))
        );
      }

      if (scheduleTripDetails?.routeSchedule) {
        setTimeout(() => {
          // let tempRouteSchedule = _?.cloneDeep(scheduleTripDetails?.routeSchedule);
          let tempRouteSchedule = JSON.parse(
            JSON.stringify(scheduleTripDetails?.routeSchedule)
          );
          setScheduleData(tempRouteSchedule);
          setShowScheduleDetails(true);

          let tempCustomScheduleTime = customScheduleTime;
          if (scheduleTripDetails?.routeSchedule?.routeDestination) {
            const scheduleDestination =
              scheduleTripDetails?.routeSchedule?.routeDestination;
            if (
              scheduleDestination?.dayArrival &&
              scheduleDestination?.dayArrival !== "nextDay" &&
              scheduleDestination?.dayArrival !== "sameDay"
            ) {
              tempCustomScheduleTime.destination = true;
            }
          }
          if (
            scheduleTripDetails?.routeSchedule?.viaPoints &&
            scheduleTripDetails?.routeSchedule?.viaPoints?.length > 0
          ) {
            const scheduleViaPoints =
              scheduleTripDetails?.routeSchedule?.viaPoints;
            let tempArrivals = tempCustomScheduleTime?.viaPoints?.dayArrivals;
            let tempDepartures =
              tempCustomScheduleTime?.viaPoints?.dayDepartures;
            scheduleViaPoints?.map((_, idx) => {
              if (
                _?.dayArrival &&
                _?.dayArrival !== "nextDay" &&
                _?.dayArrival !== "sameDay"
              ) {
                tempArrivals?.push(idx);
              }
              if (
                _?.dayDeparture &&
                _?.dayDeparture !== "nextDay" &&
                _?.dayDeparture !== "sameDay"
              ) {
                tempDepartures?.push(idx);
              }
            });
            tempCustomScheduleTime.viaPoints.dayArrivals = tempArrivals?.filter(
              (value, index, self) => self.indexOf(value) === index
            );
            tempCustomScheduleTime.viaPoints.dayDepartures =
              tempDepartures?.filter(
                (value, index, self) => self.indexOf(value) === index
              );
          }
          setCustomScheduleTime({ ...tempCustomScheduleTime });
        }, 20);
      }

      if (
        scheduleTripDetails?.driverData?.driver &&
        scheduleTripDetails?.driverData?.driver?.length > 0
      ) {
        setDriverDetails(
          JSON.parse(JSON.stringify(scheduleTripDetails?.driverData?.driver))
        );
      }

      if (scheduleDetails?.schedule && scheduleDetails?.schedule?.length > 0) {
        scheduleDetails?.schedule?.map((_) => {
          let idx = existingSchedules?.findIndex(
            (sdl) => sdl?.dayIndex === _?.day
          );

          if (idx > -1) {
            existingSchedules[idx].isChecked = true;
            existingSchedules[idx].value = _?.time?.map((timeVal) =>
              convertTimeISTtoUTC(timeVal, false)
            );
          }
        });
        setTripScheduleData([...existingSchedules]);
      }
    }
  }, [scheduleDetails, isEditMode]);

  /* ---- CONSTANT UTILS ---- */
  const tableColumns = [
    {
      header: (
        <Typography
          sx={{
            ...textClasses.t13n,
            textTransform: "capitalize",
            color: themeType.default.themeOrange,
          }}
        >
          <Checkbox
            size="small"
            data-testid="header-checkbox"
            sx={{
              marginBottom: "4px",
              padding: "0px",
              color: "#ff7200",
              "&.Mui-checked": {
                color: "#ff7200",
              },
            }}
            checked={checkAllChecked()}
            onChange={(event) => {
              let tempScheduleData = tripScheduleData;
              if (event.target.checked) {
                tempScheduleData?.map((schedule) => {
                  let scheduleVal = schedule.value || [];
                  schedule.value = [null, ...scheduleVal];
                  schedule.isChecked = true;
                });
                setAllWeekDays(true);
                setTripScheduleData([...tempScheduleData]);
              } else {
                // tempScheduleData?.map((schedule) => {
                //   // let scheduleVal = schedule.value || [];
                //   // if(scheduleVal && scheduleVal?.length > 0) scheduleVal?.shift();
                //   // schedule.value = [...scheduleVal];
                //   schedule.isChecked = false;
                // });
                setTripScheduleData([
                  { day: "Monday", dayIndex: 1, isChecked: false, value: [] },
                  { day: "Tuesday", dayIndex: 2, isChecked: false, value: [] },
                  {
                    day: "Wednesday",
                    dayIndex: 3,
                    isChecked: false,
                    value: [],
                  },
                  { day: "Thursday", dayIndex: 4, isChecked: false, value: [] },
                  { day: "Friday", dayIndex: 5, isChecked: false, value: [] },
                  { day: "Saturday", dayIndex: 6, isChecked: false, value: [] },
                  { day: "Sunday", dayIndex: 7, isChecked: false, value: [] },
                ]);
                setAllWeekDays(false);
              }
            }}
          />
        </Typography>
      ),
      selector: "checkbox",
      cell: (entry, index) => {
        return (
          <Typography
            sx={{
              ...textClasses.t13n,
              textTransform: "capitalize",
              color: themeType.default.themeOrange,
            }}
          >
            <Checkbox
              size="small"
              data-testid="checkbox"
              sx={{
                marginBottom: "4px",
                padding: "0px",
                color: "#ff7200",
                "&.Mui-checked": {
                  color: "#ff7200",
                },
              }}
              checked={entry?.isChecked ? true : false}
              onChange={(event) => {
                let tempData = tripScheduleData;
                if (event.target?.checked) {
                  let existingScheduleValue = tempData[index].value ?? [];
                  tempData[index].value = [...existingScheduleValue, null];

                  tempData[index].isChecked = true;
                  setTripScheduleData([...tempData]);
                } else {
                  tempData[index].value = [];
                  tempData[index].isChecked = false;
                  setTripScheduleData([...tempData]);
                }
              }}
            />
          </Typography>
        );
      },
      width: "80px",
    },
    {
      header: "Day",
      cell: (entry) => (
        <Typography
          sx={{
            ...textClasses.t13n,
            textAlign: "center",
            textTransform: "capitalize",
            cursor: "pointer",
          }}
        >
          {entry?.day ?? "-"}
        </Typography>
      ),
      width: "110px",
    },
    {
      header: (
        <Box display="flex" alignItems="center" gap={2}>
          <Typography>Trip Creation Time (24 hours)</Typography>
          {!allWeekDays && (
            <IconButton
              size="small"
              onClick={() => {
                setShowTimeField(true);
              }}
            >
              <Schedule />
            </IconButton>
          )}
          {showTimeField && (
            <Box display="flex" alignItems="center" gap={1}>
              <CustomTimeField
                placeholder={"hh:mm"}
                selectStylesOverride={selectStylesOverride(
                  isDarkThemeEnabledSelector
                )}
                value={
                  commonTimefieldVal
                    ? convertHHMMToDate(commonTimefieldVal)
                    : null
                }
                onChange={(time) => {
                  if (new Date(time).toString() !== "Invalid Date") {
                    const formattedTime = time && format(time, "HH:mm");
                    setCommonTimefieldVal(formattedTime);
                  }
                }}
                maxwidth="80px"
              />
              <IconButton
                onClick={() => {
                  let tempScheduleData = tripScheduleData;
                  tempScheduleData?.map((schedule) => {
                    schedule.value = [commonTimefieldVal, ...schedule.value];
                    schedule.isChecked = true;
                  });
                  setTripScheduleData([...tempScheduleData]);
                  setAllWeekDays(true);
                  setShowTimeField(false);
                  setCommonTimefieldVal(null);
                }}
              >
                <Check />
              </IconButton>
            </Box>
          )}
        </Box>
      ),
      cell: (entry, index) => (
        <Box
          display="flex"
          justifyContent="flex-start"
          alignItems="center"
          gap={0.8}
        >
          <IconButton
            size="small"
            onClick={() => {
              let tempScheduleData = tripScheduleData;
              let existingScheduleValue = tempScheduleData[index].value ?? [];
              tempScheduleData[index].value = [null, ...existingScheduleValue];
              tempScheduleData[index].isChecked = true;
              setTripScheduleData([...tempScheduleData]);
            }}
          >
            {entry?.value && entry?.value?.length > 0 ? (
              <MoreTimeIcon sx={{ color: "green" }} />
            ) : (
              <Schedule />
            )}
          </IconButton>
          {entry?.value &&
            entry?.value?.length > 0 &&
            entry?.value?.map((value, i) => {
              return (
                <Box
                  display="flex"
                  alignItems="center"
                  pr={0.6}
                  sx={{
                    borderRight:
                      entry?.value?.length > 1 && i !== entry?.value?.length - 1
                        ? "1px solid"
                        : "none",
                    borderColor: (theme) => theme.palette.divider,
                  }}
                >
                  <CustomTimeField
                    // label="Time"
                    placeholder={"hh:mm"}
                    // name={name}
                    selectStylesOverride={selectStylesOverride(
                      isDarkThemeEnabledSelector
                    )}
                    // value={value || null}
                    value={value ? convertHHMMToDate(value) : null}
                    onChange={(time) => {
                      if (new Date(time).toString() !== "Invalid Date") {
                        const formattedTime = time && format(time, "HH:mm");
                        let tempData = tripScheduleData;
                        tempData[index].value[i] = formattedTime;
                        setTripScheduleData([...tempData]);
                      }
                      // onChange(time);
                    }}
                    errors={errors}
                    maxwidth="80px"
                  />
                  <IconButton
                    size="small"
                    sx={{ color: (theme) => theme.palette.divider }}
                    onClick={() => removeTimeValue(index, i)}
                  >
                    <Close />
                  </IconButton>
                </Box>
              );
            })}
        </Box>
      ),
    },
  ];

  return (
    <Grid container>
      {/* Title section ---- */}
      <Grid xs={12}>
        <Box display="flex" flexDirection="column">
          <Typography sx={{ ...textClasses.cardTitle }} data-testid="title">
            Trip Schedule
          </Typography>
          <Breadcrumbs data-testid="breadcrumbs">
            <Typography
              sx={{
                ...textClasses.normalText,
                fontSize: "12px",
                color: themeType.default.themeOrange,
              }}
            >
              <Link
                style={{
                  color: themeType.default.themeOrange,
                  textDecoration: "none",
                }}
                data-testid="link-to-go-back"
                to={{ pathname: "/dashboard/trips" }}
              >
                Trips
              </Link>
            </Typography>

            <Typography
              sx={{
                ...textClasses.normalText,
                fontSize: "12px",
                color: themeType.default.themeOrange,
              }}
            >
              <Link
                style={{
                  color: themeType.default.themeOrange,
                  textDecoration: "none",
                }}
                data-testid="link-to-go-back"
                to={{ pathname: "/dashboard/trips/trip-schedule" }}
              >
                Trip Schedule
              </Link>
            </Typography>

            <Typography sx={{ ...textClasses.normalText, fontSize: "12px" }}>
              {isEditMode ? "Edit Schedule" : "Add Schedule"}
            </Typography>
          </Breadcrumbs>
        </Box>
      </Grid>
      {/* Form section ---- */}
      {editDataLoading ? (
        <Grid
          container
          direction={"column"}
          justifyContent={"center"}
          alignItems="center"
          item
          xs={12}
          sx={{ p: 15 }}
          data-testid="loader"
        >
          <Grid item>
            <img src={loaderGif} />
          </Grid>
        </Grid>
      ) : (
        <form>
          <Grid container>
            <Grid
              item
              container
              xs={showScheduleDetails ? 9 : 12}
              sx={{
                borderRadius: "12px",
                border: "1px solid",
                borderColor: (theme) => theme.palette.divider,
                overflowY: "auto",
                maxHeight: "77vh",
                position: "relative",
              }}
              rowGap={1.5}
            >
              <Grid
                xs={12}
                pb={1}
                m={1}
                item
                borderBottom="1px solid"
                sx={{ borderColor: (theme) => theme.palette.divider }}
              >
                <Box display="flex" alignItems="center" gap={1}>
                  <Typography>Method :</Typography>
                  <CustomSelect
                    sx={{ minWidth: "300px" }}
                    label=""
                    value={{
                      label: "Trip creation with selected time",
                      value: "Trip creation with selected time",
                    }}
                    options={[
                      {
                        label: "Trip creation with selected time",
                        value: "Trip creation with selected time",
                      },
                    ]}
                  />
                </Box>
              </Grid>
              {/* Trip Details */}
              <Grid item m={1} xs={12} sx={{ padding: "0px !important" }}>
                <Box
                  py={0.5}
                  px={1}
                  sx={{
                    background: (theme) => theme.palette.divider,
                    borderRadius: "12px 12px 0 0",
                  }}
                >
                  <Typography>1. Trip Details</Typography>
                </Box>
                <Box
                  p={1}
                  sx={{
                    border: "1px solid",
                    borderRadius: "0px 0px 12px 12px",
                    borderColor: (theme) => theme.palette.divider,
                  }}
                >
                  <Grid container spacing={0.5} pt={0.4}>
                    {/* Mandatory fields (trip id, route) */}
                    <Grid item container xs={12} spacing={2}>
                      <Grid item md={3}>
                        <Controller
                          name="tripId"
                          control={control}
                          render={({ field: { onChange, value, name } }) => (
                            <CustomSelect
                              label="Trip ID* (auto generated)"
                              name={name}
                              value={value}
                              disabled={true}
                              selectStylesOverride={selectStylesOverride(
                                isDarkThemeEnabledSelector
                              )}
                              inputClasses={inputClasses}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item md={3}>
                        <Controller
                          name="routeName"
                          control={control}
                          render={({ field: { onChange, value, name } }) => (
                            <CustomSelect
                              label="Route Name*"
                              name={name}
                              selectStylesOverride={selectStylesOverride(
                                isDarkThemeEnabledSelector
                              )}
                              inputClasses={inputClasses}
                              value={value || null}
                              onChange={(e, newVal, reason) => {
                                if (reason === "clear") handleSearchRoute(true);
                                let dummy = [...routeViapoints];
                                let modalData = { ...modalFields };

                                if (newVal) {
                                  // setScheduleIndex(index);
                                  if (dummy) {
                                    dummy = newVal?.viaPoints || [];
                                  } else {
                                    dummy.push(newVal?.viaPoints || []);
                                  }
                                  setRouteViapoints(dummy);

                                  if (newVal?.isSchedulesExists) {
                                    dispatch(
                                      getRouteSchedculeData({
                                        routeId: newVal?._id,
                                      })
                                    );
                                    setScheduleData(null);
                                  } else {
                                    dispatch(flushScheduleData());
                                    setTimeout(() => {
                                      handleSelectedSchedule(newVal);
                                    }, 500);
                                  }
                                } else {
                                  // temp.data[index].route.id = "";
                                  // temp.data[index].route.routeName = "";
                                  // temp.data[index].routeSchedule = {};
                                  // setTripData(temp);
                                  setRouteScheduleState({ data: [] });
                                }

                                onChange(newVal);
                              }}
                              searchValue={searchFields?.route}
                              onInputChange={(e) => {
                                if (e?.type === "change") {
                                  setSearchFields((prev) => ({
                                    ...prev,
                                    route: e?.target?.value?.trim(),
                                  }));
                                  if (e.target.value === "") {
                                    handleSearchRoute(true);
                                  }
                                }
                              }}
                              onSearch={() => handleSearchRoute()}
                              getOptionLabel={(option) => {
                                return option?.routeName;
                              }}
                              labelKey="routeName"
                              options={allRoutes || []}
                            />
                          )}
                        />
                        <IframeModal
                          label="Add Route"
                          iFrameUrl="/dashboard/routes/add-route?fromIframe=true"
                          submitAction={() => {
                            dispatch(
                              getAllRoutes({
                                filter: {
                                  status: "ACTIVE",
                                },
                                selection: [
                                  "routeName",
                                  "scheduleData",
                                  "viaPoints",
                                  "isSchedulesExists",
                                  "routeSource",
                                  "routeDestination",
                                ],
                              })
                            );
                          }}
                        />
                      </Grid>
                      {routeWatch &&
                        routeWatch?.isSchedulesExists &&
                        routeScheduleState?.length > 0 && (
                          <Grid item md={3}>
                            <Controller
                              control={control}
                              name="routeSchedule"
                              render={({
                                field: { onChange, value, name },
                              }) => (
                                <CustomSelect
                                  selectStylesOverride={selectStylesOverride(
                                    isDarkThemeEnabledSelector
                                  )}
                                  label="Route Schedule"
                                  value={value || null}
                                  onChange={(e, newVal) => {
                                    onChange(newVal);
                                    setTimeout(() => {
                                      handleSelectedSchedule(newVal);
                                    }, 50);
                                  }}
                                  name={name}
                                  errors={errors}
                                  // disabled={
                                  //   item?.routeNotAvailable ||
                                  //   detailTrips?.routeSchedule
                                  //     ? true
                                  //     : false
                                  // }
                                  getOptionLabel={(option) => {
                                    if (typeof option === "string") {
                                      return selectedRouteSchedules?.find(
                                        (_) => _?.schedule == value
                                      )?.schedule;
                                    } else {
                                      return option?.schedule;
                                    }
                                  }}
                                  labelKey="schedule"
                                  isOptionEqualToValue={(option, value) =>
                                    option._id === value._id &&
                                    option.schedule === value.schedule
                                  }
                                  options={routeScheduleState || []}
                                />
                              )}
                            />
                          </Grid>
                        )}
                    </Grid>

                    {/* Template fields */}
                    <Grid item container xs={12} spacing={1.5}>
                      {templateExistence &&
                      templateExistence?.attributes &&
                      templateExistence?.attributes?.length > 0
                        ? getItemRow()
                        : null}
                    </Grid>

                    <Grid
                      xs={12}
                      my={2}
                      height="1px"
                      sx={{ background: (theme) => theme.palette.divider }}
                    ></Grid>

                    <Grid item container xs={12}>
                      <Grid md={4}>
                        <Controller
                          defaultValue=""
                          name="vehicleNumber"
                          control={control}
                          render={({ field: { onChange, value, name } }) => {
                            return (
                              <CustomInput
                                placeholder={"Vehicle Number"}
                                label="Vehicle Number"
                                required={false}
                                selectStylesOverride={selectStylesOverride(
                                  isDarkThemeEnabledSelector
                                )}
                                inputClasses={inputClasses}
                                name={name}
                                errors={errors}
                                value={value || ""}
                                onChange={onChange}
                              />
                            );
                          }}
                        />
                      </Grid>
                    </Grid>
                    <Grid item container xs={12} position="relative">
                      <Grid
                        key={`driverModal`}
                        className={
                          driverModalIndex !== null &&
                          driverModalIndex !== undefined
                            ? "driverModal"
                            : "driverModal close"
                        }
                        sx={{ ...driverModal.modal }}
                        mt={1.7}
                      >
                        <Grid
                          item
                          md={12}
                          sx={{ background: "#ff7200" }}
                          pt={1}
                          pb={0.5}
                          display={"flex"}
                          justifyContent="space-between"
                        >
                          <Typography
                            sx={{
                              ...textClasses.boldText,
                              fontSize: "19px",
                              color: "#fff",
                            }}
                            pl={2}
                          >
                            Driver Details
                          </Typography>
                          <Close
                            sx={{
                              color: "#fff",
                              paddingBottom: "2px",
                              cursor: "pointer",
                              paddingRight: "4px",
                            }}
                            onClick={() => setDriverModalIndex(null)}
                          />
                        </Grid>
                        <Grid item md={12} mt={1.5} pl={1} pr={1}>
                          <FormControl
                            sx={{ ...selectStylesOverride, width: "100%" }}
                          >
                            <Controller
                              defaultValue=""
                              name="driverName"
                              control={control}
                              key={`driverName`}
                              id="driverName"
                              render={({
                                field: { onChange, value, name },
                              }) => {
                                return (
                                  <CustomInput
                                    placeholder="Driver Name"
                                    label="Driver Name"
                                    required={false}
                                    selectStylesOverride={selectStylesOverride(
                                      isDarkThemeEnabledSelector
                                    )}
                                    inputClasses={inputClasses}
                                    name={name}
                                    errors={errors}
                                    value={modalFields.driverName}
                                    onChange={(e) => {
                                      let modalData = { ...modalFields };
                                      modalData.driverName = e.target.value;
                                      setModalFields(modalData);
                                    }}
                                  />
                                );
                              }}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item md={12} mt={1.5} pl={1} pr={1}>
                          <FormControl
                            sx={{ ...selectStylesOverride, width: "100%" }}
                          >
                            <Controller
                              defaultValue=""
                              name="driverNumber"
                              control={control}
                              key={`driverNum`}
                              id="driverNumber"
                              render={({
                                field: { onChange, value, name },
                              }) => {
                                return (
                                  <CustomInput
                                    placeholder="Driver Number"
                                    label="Driver Number"
                                    required={false}
                                    selectStylesOverride={selectStylesOverride(
                                      isDarkThemeEnabledSelector
                                    )}
                                    inputClasses={inputClasses}
                                    name={name}
                                    errors={errors}
                                    value={modalFields.mobileNo}
                                    onChange={(e) => {
                                      let modalData = { ...modalFields };
                                      modalData.mobileNo = e.target.value;
                                      setModalFields(modalData);
                                    }}
                                  />
                                );
                              }}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item md={12} mt={1.5} pl={1} pr={1} mb={1.5}>
                          <Button
                            variant="contained"
                            sx={{
                              ...buttonClasses?.lynkitOrangeFill,
                              minHeight: "28px",
                              minWidth: "70px",
                            }}
                            onClick={() => {
                              addDriverFunction();
                              // sendCustomEventAnalytics(
                              //   "trips",
                              //   "Add Driver",
                              //   "Add Driver"
                              // );
                            }}
                          >
                            Add
                          </Button>
                        </Grid>
                      </Grid>
                      <Grid md={4}>
                        {driverDetails?.map((driver, driverIndex) => {
                          return (
                            <Grid key={driverIndex} item className="driverDrop">
                              <FormControl
                                sx={{
                                  ...selectStylesOverride,
                                  width: "90%",
                                  display: "flex",
                                }}
                              >
                                <Controller
                                  name={`driver-${driverIndex}`}
                                  control={control}
                                  render={({
                                    field: { onChange, value, name },
                                  }) => {
                                    return (
                                      <CustomSelect
                                        //disabled={true}
                                        selectStylesOverride={selectStylesOverride(
                                          isDarkThemeEnabledSelector
                                        )}
                                        label="Select Driver"
                                        key={`driver-${driverIndex}`}
                                        value={driver}
                                        onChange={(e, newVal) => {
                                          if (newVal !== null) {
                                            if (
                                              newVal.driverName === "Add Driver"
                                            ) {
                                              setModalFields({
                                                driverName: "",
                                                mobileNo: "",
                                              });
                                              setDriverInnerIndex(driverIndex);
                                              let tempDriverDetails =
                                                driverDetails;
                                              tempDriverDetails[driverIndex] = {
                                                _id: "",
                                                mobileNo: "",
                                                licenseNo: "",
                                                driverName: "",
                                              };
                                              setDriverDetails([
                                                ...tempDriverDetails,
                                              ]);
                                              setDriverModalIndex(driverIndex);
                                            } else {
                                              setDriverModalIndex(null);
                                              setDriverInnerIndex(null);
                                              let tempDriverDetails =
                                                driverDetails;
                                              tempDriverDetails[driverIndex] = {
                                                _id: newVal?._id,
                                                mobileNo: newVal?.mobileNo,
                                                licenseNo: newVal?.licenseNo,
                                                driverName: newVal?.driverName,
                                                sso_id: newVal?.sso_id,
                                              };
                                              setDriverDetails([
                                                ...tempDriverDetails,
                                              ]);
                                            }
                                          } else {
                                            setDriverModalIndex(null);
                                            let tempDriverDetails =
                                              driverDetails;
                                            tempDriverDetails[driverIndex] = {
                                              _id: "",
                                              mobileNo: "",
                                              licenseNo: "",
                                              driverName: "",
                                            };
                                            setDriverDetails([
                                              ...tempDriverDetails,
                                            ]);
                                          }
                                        }}
                                        name={name}
                                        errors={errors}
                                        getOptionLabel={(option) => {
                                          return option?.driverName || "";
                                        }}
                                        filterSelectedOptions
                                        renderOptions={(
                                          props,
                                          option,
                                          { selected }
                                        ) => {
                                          return (
                                            <>
                                              <MenuItem
                                                value={value || ""}
                                                selected={selected}
                                                {...props}
                                              >
                                                <Typography
                                                  sx={{
                                                    fontSize: ".8rem",
                                                    overflow: "hidden",
                                                    whiteSpace: "nowrap",
                                                    textOverflow: "ellipsis",
                                                  }}
                                                >
                                                  {option?.driverName || ""}
                                                </Typography>
                                              </MenuItem>
                                            </>
                                          );
                                        }}
                                        isOptionEqualToValue={(option, value) =>
                                          option._id === value._id &&
                                          option.driverName === value.driverName
                                        }
                                        options={getDriversList()}
                                      />
                                    );
                                  }}
                                />
                              </FormControl>
                              {driverDetails?.length === 1 ? (
                                ""
                              ) : (
                                <RemoveCircleOutline
                                  onClick={() => removeDriver(driverIndex)}
                                  data-testid="remove-driver-btn"
                                  className="removeIcon"
                                />
                              )}
                              {driverDetails?.length - 1 === driverIndex ? (
                                <AddCircleOutline
                                  className="addIcon"
                                  data-testid="add-driver-btn"
                                  onClick={() => addDriver()}
                                />
                              ) : (
                                ""
                              )}
                            </Grid>
                          );
                        })}
                      </Grid>
                    </Grid>

                    {/* Note */}
                    <Grid xs={12} p={1}>
                      <Typography variant="caption">
                        * Note : Device mapping with trip needs to be done
                        during the trip schedule time.
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
              {/* Trip Schedule */}
              <Grid item m={1} xs={12} sx={{ padding: "0px !important" }}>
                <Box
                  py={0.5}
                  px={1}
                  sx={{
                    background: (theme) => theme.palette.divider,
                    borderRadius: "12px 12px 0 0",
                  }}
                >
                  <Typography>1. Trip Schedule</Typography>
                </Box>
                <Box
                  p={1}
                  sx={{
                    border: "1px solid",
                    borderRadius: "0px 0px 12px 12px",
                    borderColor: (theme) => theme.palette.divider,
                  }}
                >
                  <Grid container py={0.5}>
                    <Grid container item xs={12} columnSpacing={1} mb={0.5}>
                      {/* Trip schedule name */}
                      <Grid item sm={4} lg={3}>
                        <Controller
                          name="tripScheduleName"
                          control={control}
                          // rules={{
                          //   required: {
                          //     value: true,
                          //     message: "Please enter trip schedule name",
                          //   },
                          // }}
                          render={({ field: { onChange, value, name } }) => (
                            <CustomInput
                              label="Trip Schedule Name"
                              required
                              name={name}
                              value={value || ""}
                              onChange={onChange}
                              selectStylesOverride={selectStylesOverride(
                                isDarkThemeEnabledSelector
                              )}
                              inputClasses={inputClasses}
                              errors={errors}
                            />
                          )}
                        />
                      </Grid>
                      {/* Trip Schedule start date */}
                      <Grid item sm={4} lg={3}>
                        <Controller
                          name="scheduleStart"
                          control={control}
                          // rules={{
                          //   validate: {
                          //     minDateTime: (value) =>
                          //       isEditMode && value === initialStartDate
                          //       ? true
                          //       : !value || compareDates(value, scheduleStartMinDateTime) === 1 || `Schedule start must be after ${scheduleStartMinDateTime.toLocaleString()}`,
                          //     maxDateTime: (value) =>
                          //       isEditMode && value === initialStartDate
                          //       ? true
                          //       : !value || compareDates(value, scheduleStartMaxDateTime) !== 1 || `Schedule start must be before ${scheduleStartMaxDateTime.toLocaleString()}`,
                          //   },
                          // }}
                          render={({ field: { onChange, name, value } }) => (
                            <Box>
                              <DateTimePicker
                                name={name}
                                label="Schedule Start"
                                value={value || null}
                                onChange={(date) => {
                                  onChange(date);
                                }}
                                className="customDatePicker"
                                format="dd-MM-yyyy hh:mm a"
                                sx={{
                                  width: "100%",
                                  ...selectStylesOverride(
                                    isDarkThemeEnabledSelector
                                  ),
                                }}
                                slotProps={{
                                  ...dateTimePickerSlotProps(
                                    inputClasses,
                                    isDarkThemeEnabledSelector
                                  ),
                                  actionBar: { actions: ["clear", "accept"] },
                                }}
                                minDate={new Date()}
                                maxDate={scheduleEndDateWatch || null}
                              />
                              {errors?.scheduleStart && (
                                <span role="alert" style={{ color: "#FF0101", fontSize: 11 }}>
                                  {errors?.scheduleStart?.message}
                                </span>
                              )}
                            </Box>
                          )}
                        />
                      </Grid>
                      {/* Trip Schedule end date */}
                      <Grid item sm={4} lg={3}>
                        <Controller
                          name="scheduleEnd"
                          control={control}
                          // rules={{
                          //   required: false,
                          //   validate: {
                          //     minDateTime: (value) =>
                          //       isEditMode && value === initialEndDate
                          //       ? true
                          //       : !value || compareDates(value, scheduleEndMinDateTime) === 1 || `Schedule end must be after ${scheduleEndMinDateTime.toLocaleString()}`,
                          //     },
                          // }}
                          render={({ field: { onChange, name, value } }) => (
                            <Box>
                              <DateTimePicker
                                name={name}
                                label="Schedule End"
                                value={value || null}
                                onChange={(date) => {
                                  onChange(date);
                                }}
                                className="customDatePicker"
                                format="dd-MM-yyyy hh:mm a"
                                sx={{
                                  width: "100%",
                                  ...selectStylesOverride(
                                    isDarkThemeEnabledSelector
                                  ),
                                }}
                                minDate={scheduleStartDateWatch ? scheduleStartDateWatch : new Date()}
                                slotProps={{
                                  ...dateTimePickerSlotProps(
                                    inputClasses,
                                    isDarkThemeEnabledSelector
                                  ),
                                  actionBar: { actions: ["clear", "accept"] },
                                }}
                              />
                              {errors?.scheduleEnd && (
                                <span role="alert" style={{ color: "#FF0101", fontSize: 11 }}>
                                  {errors?.scheduleEnd?.message}
                                </span>
                              )}
                            </Box>
                          )}
                        />
                      </Grid>
                    </Grid>
                    {/* Select date and time slots */}
                    <Grid xs={12}>
                      <Typography variant="caption">
                        Select the days for trip schedule with time slots.
                        Multiple time slots can be added for particular day.
                      </Typography>
                      <Box mt={0.5}>
                        <DataTable
                          data={tripScheduleData}
                          tableColumns={tableColumns}
                          themeType={themeType}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
              {/* Button Section */}
              <Grid
                item
                m={1}
                xs={12}
                sx={{
                  zIndex: 100,
                  padding: "0px !important",
                  position: "sticky",
                  bottom: "0px",
                  left: "0px",
                }}
              >
                <Box
                  sx={{
                    // border: "1px solid",
                    // borderRadius: "12px",
                    // borderColor: (theme) => theme.palette.divider,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: 2,
                    bgcolor: "background.paper",
                  }}
                  p={1}
                >
                  <Button
                    sx={{
                      ...buttonClasses.lynkitBlackFill,
                      minHeight: "36px",
                      minWidth: "150px",
                    }}
                    onClick={() => {
                      history.push("/dashboard/trips/trip-schedule");
                    }}
                  >
                    Cancel
                  </Button>
                  <CustomSubmitBtn
                    label={isEditMode ? "Update" : "Create"}
                    variant="contained"
                    sx={{
                      ...buttonClasses?.lynkitOrangeFill,
                      minHeight: "36px",
                      minWidth: "150px",
                    }}
                    onClick={handleSubmit(
                      isEditMode ? handleUpdateSchedule : handleCreateSchedule
                    )}
                    disabled={
                      createTripScheduleLoading || updateTripScheduleLoading
                    }
                    loading={
                      createTripScheduleLoading || updateTripScheduleLoading
                    }
                  />
                </Box>
              </Grid>
            </Grid>

            {/* ==== SCHEDULE DETAILS ==== */}
            {showScheduleDetails && (
              <Grid xs={3} sx={{ maxHeight: "77vh" }}>
                <DetailCard
                  themeType={themeType}
                  scheduleData={scheduleData}
                  setScheduleData={setScheduleData}
                  handleSelectedSchedule={handleSelectedSchedule}
                  isDarkThemeEnabledSelector={isDarkThemeEnabledSelector}
                  control={control}
                  errors={errors}
                  dateTimePickerSlotProps={dateTimePickerSlotProps(
                    inputClasses,
                    isDarkThemeEnabledSelector
                  )}
                  showScheduleDetails={showScheduleDetails}
                  setShowScheduleDetails={setShowScheduleDetails}
                  GeofencesListing={geofenceListing || []}
                  tripData={tripData}
                  setTripData={setTripData}
                  scheduleIndex={0}
                  routeViapoints={routeViapoints}
                  setRouteViapoints={setRouteViapoints}
                  detailTrips={tripDetails || {}}
                  customScheduleTime={customScheduleTime}
                  setCustomScheduleTime={setCustomScheduleTime}
                  scheduleValidation={scheduleValidation}
                  setScheduleValidation={setScheduleValidation}
                />
              </Grid>
            )}
          </Grid>
        </form>
      )}
    </Grid>
  );
};

/* ========== SCHEDULE DETAILS ========== */

export const DetailCard = ({
  themeType,
  handleSelectedRow,
  isDarkThemeEnabledSelector,
  control,
  errors,
  dateTimePickerSlotProps,
  showScheduleDetails,
  setShowScheduleDetails,
  GeofencesListing,
  scheduleData,
  setScheduleData,
  tripData,
  setTripData,
  scheduleIndex,
  detailTrips,
  customScheduleTime,
  setCustomScheduleTime,
  scheduleValidation,
  setScheduleValidation,
}) => {
  const {
    theme,
    textClasses,
    cardClasses,
    inputClasses,
    tableClasses,
    buttonClasses,
  } = themeType;

  const checkAndUpdateDates = (dateField, value, item, dayAdjustment) => {
    const currentDate = new Date();
    if (dayAdjustment === "nextDay") {
      currentDate.setDate(currentDate.getDate() + 1);
    } else if (dayAdjustment && !isNaN(parseInt(dayAdjustment))) {
      currentDate.setDate(currentDate.getDate() + parseInt(dayAdjustment));
    }

    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    const day = String(currentDate.getDate()).padStart(2, "0");
    const date = `${year}-${month}-${day}`;

    if (value) {
      let dates = value.split(" ");

      if (dates.length >= 2) {
        return `${value}`;
      } else {
        return `${date} ${value}`;
      }
    }
  };

  // Helper function to convert HH:mm to minutes
  const convertToMinutes = (time) => {
    if (!time || typeof time !== "string") return null;
    const [hours, minutes] = time.split(":").map(Number);
    if (isNaN(hours) || isNaN(minutes)) return null;
    return hours * 60 + minutes;
  };

  // Helper function to calculate the travel time
  const calculateTravelTime = (
    startTime,
    endTime,
    startDayOffset,
    endDayOffset
  ) => {
    if (startTime === null || endTime === null) return "Invalid time";

    const totalMinutes =
      endTime + endDayOffset * 24 * 60 - (startTime + startDayOffset * 24 * 60);
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    return `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}`;
  };

  // Validation and travel time calculation
  const validateAndCalculateTravelTime = (schedule) => {
    const { routeSource, viaPoints, routeDestination } = schedule;
    const sourceTime = convertToMinutes(routeSource.STD);
    if (sourceTime === null) {
      setScheduleValidation("Invalid source time");
      return;
    }

    let previousTime = sourceTime;
    let previousDayOffset = 0;

    for (let i = 0; i < viaPoints.length; i++) {
      const viaPoint = viaPoints[i];
      const viaPointArrivalTime = convertToMinutes(viaPoint.STA);

      if (viaPointArrivalTime === null) {
        setScheduleValidation(
          `Invalid arrival time at via point ${i + 1} - ${viaPoint.itemName}`
        );
        return;
      }

      const viaPointDayArrivalOffset =
        viaPoint.dayArrival === "nextDay"
          ? 1
          : viaPoint.dayArrival === "sameDay"
          ? 0
          : parseInt(viaPoint.dayArrival, 10);

      if (isNaN(viaPointDayArrivalOffset)) {
        setScheduleValidation(
          `Invalid arrival day value at via point ${i + 1} - ${
            viaPoint.itemName
          }`
        );
        return;
      }

      if (
        viaPointArrivalTime + viaPointDayArrivalOffset * 24 * 60 <
        previousTime + previousDayOffset * 24 * 60
      ) {
        setScheduleValidation(
          `Invalid schedule at via point ${i + 1} - ${viaPoint.itemName}`
        );
        return;
      }

      viaPoint.travelTime = calculateTravelTime(
        previousTime,
        viaPointArrivalTime,
        previousDayOffset,
        viaPointDayArrivalOffset
      );

      const viaPointDepartureTime = convertToMinutes(viaPoint.STD);
      const viaPointDayDepartureOffset =
        viaPoint.dayDeparture === "nextDay"
          ? 1
          : viaPoint.dayDeparture === "sameDay"
          ? 0
          : parseInt(viaPoint.dayDeparture, 10);

      if (
        viaPointDepartureTime !== null &&
        !isNaN(viaPointDayDepartureOffset)
      ) {
        previousTime = viaPointDepartureTime;
        previousDayOffset = viaPointDayDepartureOffset;
      } else {
        previousTime = viaPointArrivalTime;
        previousDayOffset = viaPointDayArrivalOffset;
      }
    }

    const destinationArrivalTime = convertToMinutes(routeDestination.STA);
    if (destinationArrivalTime === null) {
      setScheduleValidation("Invalid destination time");
      return;
    }

    const destinationDayArrivalOffset =
      routeDestination.dayArrival === "nextDay"
        ? 1
        : routeDestination.dayArrival === "sameDay"
        ? 0
        : parseInt(routeDestination.dayArrival, 10);

    if (isNaN(destinationDayArrivalOffset)) {
      setScheduleValidation("Invalid destination day value");
      return;
    }

    if (
      destinationArrivalTime + destinationDayArrivalOffset * 24 * 60 <
      previousTime + previousDayOffset * 24 * 60
    ) {
      setScheduleValidation(
        `Invalid schedule at destination ${routeDestination.name}`
      );
      return;
    }

    routeDestination.travelTime = calculateTravelTime(
      previousTime,
      destinationArrivalTime,
      previousDayOffset,
      destinationDayArrivalOffset
    );

    setScheduleData(schedule);
    setScheduleValidation(null);
  };

  const _handleDetailCardChange = (e, newVal, item, index) => {
    let temp = { ...scheduleData };
    let dummy = { ...tripData };
    if (item === "sourceAddress") {
      if (newVal) {
        temp.routeSource.name = newVal?.name;
        temp.routeSource._id = newVal?._id;
        // setScheduleData(temp);

        const newRouteSource = {
          ...dummy.data[scheduleIndex].routeSchedule.routeSource,
          name: newVal?.name,
          _id: newVal?._id,
        };
        dummy.data[scheduleIndex].routeSchedule.routeSource = newRouteSource;
        setTripData(dummy);
      } else {
        temp.routeSource.name = "";
        temp.routeSource._id = "";
        dummy.data[scheduleIndex].routeSchedule.routeSource = {};
        // setScheduleData(temp);
      }
    } else if (item === "sourceSTA") {
      temp.routeSource.STD = newVal;
      // setScheduleData(temp);
      // const newRouteSourceSTD = {
      //   ...dummy.data[scheduleIndex].routeSchedule.routeSource,
      //   STD: newVal,
      // };

      // dummy.data[scheduleIndex].routeSchedule.routeSource = newRouteSourceSTD;
      // setTripData(dummy);

      //====================================
      // temp.routeSource.STD = moment(newVal).format("YYYY-MM-DD HH:mm");
      // var date1String = temp?.routeSource?.STD;
      // var date2String = temp?.viaPoints[0]?.STA;

      // setScheduleData(temp);
      // changeScheduleEntries(
      //   moment(newVal).format("YYYY-MM-DD HH:mm"),
      //   "src",
      //   0
      // );
      // travelTimeLogic(date1String, date2String, 0, date2String, "sta", "src");

      // const newRouteSourceSTD = {
      //   ...dummy.data[scheduleIndex].routeSchedule.routeSource,
      //   STD: moment(newVal).format("YYYY-MM-DD HH:mm"),
      // };

      // dummy.data[scheduleIndex].routeSchedule.routeSource = newRouteSourceSTD;
      // setTripData(dummy);
    } else if (item === "STA") {
      temp.viaPoints[index].STA = newVal;
      // setScheduleData(temp);

      //==========================================================
      // if (index === 0) {
      //   var date1String = temp?.routeSource?.STD;
      //   var date2String = newVal;
      //   travelTimeLogic(date1String, date2String, index, newVal, "sta");

      //   changeScheduleEntries(
      //     moment(newVal).format("YYYY-MM-DD HH:mm"),
      //     "via",
      //     index
      //   );

      //   let newHaltTime = calculateHaltTime(
      //     temp.viaPoints[index].STD,
      //     temp.viaPoints[index].STA
      //   );
      //   temp.viaPoints[index].halt = newHaltTime;
      //   setScheduleData(temp);
      // } else {
      //   var date1String = temp?.viaPoints[index - 1]?.STD;
      //   var date2String = newVal;
      //   travelTimeLogic(date1String, date2String, index, newVal, "sta");
      //   changeScheduleEntries(
      //     moment(newVal).format("YYYY-MM-DD HH:mm"),
      //     "via",
      //     index
      //   );

      //   let newHaltTime = calculateHaltTime(
      //     temp.viaPoints[index].STD,
      //     temp.viaPoints[index].STA
      //   );
      //   temp.viaPoints[index].halt = newHaltTime;
      //   setScheduleData(temp);
      // }
    } else if (item === "STD") {
      temp.viaPoints[index].STD = newVal;
      setScheduleData(temp);

      //=======================================================
      // temp.viaPoints[index].STD = moment(newVal).format("YYYY-MM-DD HH:mm");
      // let newHaltTime = calculateHaltTime(
      //   temp.viaPoints[index].STD,
      //   temp.viaPoints[index].STA
      // );
      // temp.viaPoints[index].halt = newHaltTime;

      // changeScheduleEntries(
      //   moment(newVal).format("YYYY-MM-DD HH:mm"),
      //   "via",
      //   index
      // );

      // setScheduleData(temp);
      // const copy = dummy.data[scheduleIndex].routeSchedule;
      // if (!copy.viaPoints) {
      //   copy.viaPoints = [];
      // }
      // while (copy.viaPoints.length <= index) {
      //   copy.viaPoints.push({});
      // }
      // const viaAddress = {
      //   ...copy.viaPoints[index],
      //   STD: moment(newVal).format("YYYY-MM-DD HH:mm"),
      // };
      // copy.viaPoints[index] = viaAddress;
      // setTripData(copy);
    } else if (item === "sourceDestination") {
      if (newVal) {
        temp.routeDestination.name = newVal?.name;
        temp.routeDestination._id = newVal?._id;
        // setScheduleData(temp);

        const newRouteDestination = {
          ...dummy.data[scheduleIndex].routeSchedule.routeDestination,
          name: newVal.name,
          _id: newVal._id,
        };
        dummy.data[scheduleIndex].routeSchedule.routeDestination =
          newRouteDestination;
        setTripData(dummy);
      } else {
        temp.routeDestination.name = "";
        temp.routeDestination._id = "";
        // setScheduleData(temp);

        dummy.data[scheduleIndex].routeSchedule.routeDestination = {};
        // setScheduleData(temp);
      }
    } else if (item === "travelTime") {
      temp.viaPoints[index].travelTime = format(newVal, "HH:mm");
      setScheduleData(temp);

      const travelTime = dummy.data[scheduleIndex].routeSchedule;

      if (!travelTime.viaPoints) {
        travelTime.viaPoints = [];
      }

      while (travelTime.viaPoints.length <= index) {
        travelTime.viaPoints.push({});
      }

      const viaAddress = {
        ...travelTime.viaPoints[index],
        travelTime: format(newVal, "HH:mm"),
      };

      travelTime.viaPoints[index] = viaAddress;
      setTripData(travelTime);
    } else if (item === "viapt") {
      temp.viaPoints[index].itemName = newVal?.name;
      temp.viaPoints[index]._id = newVal?._id;
      // setScheduleData(temp);

      const routeSchedule = dummy.data[scheduleIndex].routeSchedule;

      if (!routeSchedule.viaPoints) {
        routeSchedule.viaPoints = [];
      }

      while (routeSchedule.viaPoints.length <= index) {
        routeSchedule.viaPoints.push({});
      }

      const viaAddress = {
        ...routeSchedule.viaPoints[index],
        itemName: newVal?.name,
        _id: newVal?._id,
      };

      routeSchedule.viaPoints[index] = viaAddress;
      setTripData(routeSchedule);
    } else if (item === "dayArrival") {
      if (newVal) {
        temp.viaPoints[index].dayArrival = newVal?.value;
        // setScheduleData(temp);

        //-----------------------
        // const routeSchedule = dummy.data[scheduleIndex].routeSchedule;
        // if (!routeSchedule.viaPoints) {
        //   routeSchedule.viaPoints = [];
        // }
        // while (routeSchedule.viaPoints.length <= index) {
        //   routeSchedule.viaPoints.push({});
        // }
        // const viaAddress = {
        //   ...routeSchedule.viaPoints[index],
        //   dayArrival: newVal?.value,
        // };
        // routeSchedule.viaPoints[index] = viaAddress;
        // setTripData(routeSchedule);
        if (newVal?.value === "custom") {
          let tempCustomScheduleTime = customScheduleTime;
          if (tempCustomScheduleTime?.viaPoints?.dayArrivals) {
            let viapointArrivals =
              tempCustomScheduleTime.viaPoints?.dayArrivals;
            viapointArrivals.push(index);
            let viaArrivalsWithoutDuplicates = viapointArrivals?.filter(
              (value, index, self) => self.indexOf(value) === index
            );
            tempCustomScheduleTime.viaPoints.dayArrivals = [
              ...viaArrivalsWithoutDuplicates,
            ];
          } else {
            tempCustomScheduleTime.viaPoints.dayArrivals = [index];
          }
          setCustomScheduleTime({ ...tempCustomScheduleTime });
        } else {
          let tempCustomScheduleTime = customScheduleTime;
          if (tempCustomScheduleTime?.viaPoints?.dayArrivals) {
            let viapointArrivals =
              tempCustomScheduleTime.viaPoints?.dayArrivals;
            let filteredVP = viapointArrivals.filter((_) => _ !== index);
            tempCustomScheduleTime.viaPoints.dayArrivals = [...filteredVP];
          }
        }
      } else {
      }
    } else if (item === "dayArrivalCustomCount") {
      temp.viaPoints[index].dayArrival = newVal;
      // setScheduleData(temp);
    } else if (item === "dayDeparture") {
      if (newVal) {
        temp.viaPoints[index].dayDeparture = newVal?.value;
        // setScheduleData(temp);

        //-----------------
        // const routeSchedule = dummy.data[scheduleIndex].routeSchedule;
        // if (routeSchedule && !routeSchedule.viaPoints) {
        //   routeSchedule.viaPoints = [];
        // }
        // while (routeSchedule.viaPoints.length <= index) {
        //   routeSchedule.viaPoints.push({});
        // }
        // const viaAddress = {
        //   ...routeSchedule.viaPoints[index],
        //   dayDeparture: newVal?.value,
        // };
        // routeSchedule.viaPoints[index] = viaAddress;
        // setTripData(routeSchedule);
        if (newVal?.value === "custom") {
          let tempCustomScheduleTime = customScheduleTime;
          if (tempCustomScheduleTime?.viaPoints?.dayDepartures) {
            let viapointDepartures =
              tempCustomScheduleTime.viaPoints?.dayDepartures;
            viapointDepartures.push(index);
            let viaArrivalsWithoutDuplicates = viapointDepartures?.filter(
              (value, index, self) => self.indexOf(value) === index
            );
            tempCustomScheduleTime.viaPoints.dayDepartures = [
              ...viaArrivalsWithoutDuplicates,
            ];
          } else {
            tempCustomScheduleTime.viaPoints.dayDepartures = [index];
          }
          setCustomScheduleTime({ ...tempCustomScheduleTime });
        } else {
          let tempCustomScheduleTime = customScheduleTime;
          if (tempCustomScheduleTime?.viaPoints?.dayDepartures) {
            let viapointDepartures =
              tempCustomScheduleTime.viaPoints?.dayDepartures;
            let filteredVP = viapointDepartures?.filter((_) => _ !== index);
            tempCustomScheduleTime.viaPoints.dayDepartures = [...filteredVP];
          }
        }
      } else {
      }
    } else if (item === "dayDepartureCustomCount") {
      temp.viaPoints[index].dayDeparture = newVal;
      // setScheduleData(temp);
    } else if (item === "destinationDayArrival") {
      if (newVal) {
        temp.routeDestination.dayArrival = newVal?.value;
        // setScheduleData(temp);

        //-------------------
        // const newRouteDestinationSTA = {
        //   ...dummy?.data[scheduleIndex]?.routeSchedule?.routeDestination,
        //   STA: newVal?.value,
        // };
        // dummy.data[scheduleIndex].routeSchedule.routeDestination =
        //   newRouteDestinationSTA;
        // setTripData(dummy);
        if (newVal?.value === "custom") {
          let tempCustomScheduleTime = customScheduleTime;
          tempCustomScheduleTime.destination = true;
          setCustomScheduleTime({ ...tempCustomScheduleTime });
        } else {
          let tempCustomScheduleTime = customScheduleTime;
          tempCustomScheduleTime.destination = false;
          setCustomScheduleTime({ ...tempCustomScheduleTime });
        }
      }
    } else if (item === "destinationArrivalCustomCount") {
      temp.routeDestination.dayArrival = newVal;
      // setScheduleData(temp);
    } else if (item === "destinationTravelTime") {
      temp.routeDestination.travelTime = format(newVal, "HH:mm");
      setScheduleData(temp);

      const destinationAvgSpeed = {
        ...dummy.data[scheduleIndex].routeSchedule.routeDestination,
        travelTime: format(newVal, "HH:mm"),
      };

      dummy.data[scheduleIndex].routeSchedule.routeDestination =
        destinationAvgSpeed;
      setTripData(dummy);
    } else if (item === "rdSTA") {
      temp.routeDestination.STA = newVal;
      // // setScheduleData(temp);
      // const newRouteDestinationSTA = {
      //   ...dummy?.data[scheduleIndex]?.routeSchedule?.routeDestination,
      //   STA: newVal,
      // };
      // dummy.data[scheduleIndex].routeSchedule.routeDestination =
      //   newRouteDestinationSTA;
      // setTripData(dummy);

      // ===================================================
      // if (temp.viaPoints.length) {
      //   var date1String = temp?.viaPoints[temp.viaPoints.length - 1]?.STD;
      //   var date2String = newVal;
      //   travelTimeLogic(date1String, date2String, index, newVal, "rdsta");
      // } else {
      //   var date1String = temp?.routeSource?.STD;
      //   var date2String = newVal;
      //   travelTimeLogic(date1String, date2String, index, newVal, "rdsta");
      // }

      // const newRouteDestinationSTD = {
      //   ...dummy?.data[scheduleIndex]?.routeSchedule?.routeDestination,
      //   STA: moment(newVal).format("YYYY-MM-DD HH:mm"),
      // };
      // dummy.data[scheduleIndex].routeSchedule.routeDestination =
      //   newRouteDestinationSTD;
      // setTripData(dummy);
    } else if (item === "avgSpeed") {
      temp.viaPoints[index] = {
        ...temp?.viaPoints[index],
        averageSpeed: e.target.value,
      };
      setScheduleData(temp);
      const copy = dummy?.data[scheduleIndex]?.routeSchedule;

      if (!copy.viaPoints) {
        copy.viaPoints = [];
      }

      while (copy.viaPoints.length <= index) {
        copy.viaPoints.push({});
      }

      const viaAddress = {
        ...copy?.viaPoints[index],
        averageSpeed: e.target.value,
      };

      copy.viaPoints[index] = viaAddress;
      setTripData(copy);
    } else if (item === "destinationaverageSpeed") {
      temp.routeDestination.averageSpeed = e.target.value;
      setScheduleData(temp);

      const destSpeed = {
        ...dummy?.data[scheduleIndex]?.routeSchedule?.routeDestination,
        averageSpeed: e.target.value,
      };

      dummy.data[scheduleIndex].routeSchedule.routeDestination = destSpeed;
      setTripData(dummy);
    }
    setScheduleData(temp);
    setTripData(dummy);
    validateAndCalculateTravelTime(temp);
  };

  const addViapoint = () => {
    let temp = { ...scheduleData };
    temp.viaPoints.push({
      itemName: "",
      _id: "",
      travelTime: "00:00",
      STA: "",
      STD: "",
      // "halt": "",
      averageSpeed: "",
    });
    setScheduleData(temp);
  };

  const deleteViapoint = (index) => {
    let temp = scheduleData;
    let tempViapoint = temp.viaPoints[index];
    let tempCustomScheduleTime = customScheduleTime;

    if (
      tempViapoint?.dayArrival &&
      tempViapoint?.dayArrival !== "nextDay" &&
      tempViapoint?.dayArrival !== "sameDay"
    ) {
      let filteredArrivals =
        tempCustomScheduleTime?.viaPoints?.dayArrivals?.filter(
          (_) => _ !== index
        );
      tempCustomScheduleTime.viaPoints.dayArrivals = [...filteredArrivals];
    }
    if (
      tempViapoint?.dayDeparture &&
      tempViapoint?.dayDeparture !== "nextDay" &&
      tempViapoint?.dayDeparture !== "sameDay"
    ) {
      let filteredDepartures =
        tempCustomScheduleTime?.viaPoints?.dayDepartures?.filter(
          (_) => _ !== index
        );
      tempCustomScheduleTime.viaPoints.dayDepartures = [...filteredDepartures];
    }

    setCustomScheduleTime({ ...tempCustomScheduleTime });
    temp.viaPoints.splice(index, 1);
    setScheduleData({ ...temp });
  };

  const convertHHMMToDate = (hhmm = "") => {
    const [hours, minutes] = hhmm?.split(":").map(Number);
    const now = new Date();
    const resultDate = setMinutes(setHours(now, hours), minutes);
    return resultDate;
  };

  React.useEffect(() => {
    // TODO: remove tripData dependency
    let temp = { ...tripData };
    if (
      scheduleData &&
      Object?.keys(scheduleData)?.length &&
      scheduleData?.routeSource?.STD &&
      scheduleData?.routeDestination?.STA
    ) {
      if (
        temp.data[scheduleIndex].routeSchedule &&
        temp.data[scheduleIndex].routeSchedule.routeSource &&
        temp.data[scheduleIndex].routeSchedule.routeSource.STD
      )
        temp.data[scheduleIndex].routeSchedule.routeSource.STD =
          checkAndUpdateDates(
            null,
            temp?.data[scheduleIndex]?.routeSchedule?.routeSource?.STD,
            "source"
          );
      if (
        temp.data[scheduleIndex].routeSchedule &&
        temp.data[scheduleIndex].routeSchedule.routeDestination &&
        temp.data[scheduleIndex].routeSchedule.routeDestination.STA
      )
        temp.data[scheduleIndex].routeSchedule.routeDestination.STA =
          checkAndUpdateDates(
            null,
            temp?.data[scheduleIndex]?.routeSchedule?.routeDestination?.STA,
            "destination",
            temp?.data[scheduleIndex]?.routeSchedule?.routeDestination
              ?.dayArrival
          );

      for (let i = 0; i < scheduleData?.viaPoints?.length; i++) {
        if (
          temp.data[scheduleIndex].routeSchedule &&
          temp.data[scheduleIndex].routeSchedule.viaPoints[i].STA !== "" &&
          temp.data[scheduleIndex].routeSchedule.viaPoints[i].STD !== ""
        ) {
          temp.data[scheduleIndex].routeSchedule.viaPoints[i].STA =
            checkAndUpdateDates(
              null,
              temp?.data[scheduleIndex]?.routeSchedule?.viaPoints[i]?.STA,
              "viapoints",
              temp?.data[scheduleIndex]?.routeSchedule?.viaPoints[i]?.dayArrival
            );
          temp.data[scheduleIndex].routeSchedule.viaPoints[i].STD =
            checkAndUpdateDates(
              null,
              temp?.data[scheduleIndex]?.routeSchedule?.viaPoints[i]?.STD,
              "viapoints",
              temp?.data[scheduleIndex]?.routeSchedule?.viaPoints[i]
                ?.dayDeparture
            );
        }
      }
    }
    setTripData(temp);

    //=========================================
    // let temp = { ...tripData };
    // if (
    //   scheduleData &&
    //   Object?.keys(scheduleData)?.length &&
    //   scheduleData?.routeSource?.STD &&
    //   scheduleData?.routeDestination?.STA
    // ) {
    //   if (
    //     temp.data[scheduleIndex].routeSchedule &&
    //     temp.data[scheduleIndex].routeSchedule.routeSource &&
    //     temp.data[scheduleIndex].routeSchedule.routeSource.STD
    //   )
    //     temp.data[scheduleIndex].routeSchedule.routeSource.STD =
    //       checkAndUpdateDates(
    //         null,
    //         temp?.data[scheduleIndex]?.routeSchedule?.routeSource?.STD,
    //         "source"
    //       );
    //   if (
    //     temp.data[scheduleIndex].routeSchedule &&
    //     temp.data[scheduleIndex].routeSchedule.routeDestination &&
    //     temp.data[scheduleIndex].routeSchedule.routeDestination.STA
    //   )
    //     temp.data[scheduleIndex].routeSchedule.routeDestination.STA =
    //       checkAndUpdateDates(
    //         null,
    //         temp?.data[scheduleIndex]?.routeSchedule?.routeDestination?.STA,
    //         "destination",
    //         temp?.data[scheduleIndex]?.routeSchedule?.routeDestination
    //           ?.dayArrival
    //       );

    //   for (let i = 0; i < scheduleData?.viaPoints?.length; i++) {
    //     if (
    //       temp.data[scheduleIndex].routeSchedule &&
    //       temp.data[scheduleIndex].routeSchedule.viaPoints[i].STA !== "" &&
    //       temp.data[scheduleIndex].routeSchedule.viaPoints[i].STD !== ""
    //     ) {
    //       temp.data[scheduleIndex].routeSchedule.viaPoints[i].STA =
    //         checkAndUpdateDates(
    //           null,
    //           temp?.data[scheduleIndex]?.routeSchedule?.viaPoints[i]?.STA,
    //           "viapoints",
    //           temp?.data[scheduleIndex]?.routeSchedule?.viaPoints[i]?.dayArrival
    //         );
    //       temp.data[scheduleIndex].routeSchedule.viaPoints[i].STD =
    //         checkAndUpdateDates(
    //           null,
    //           temp?.data[scheduleIndex]?.routeSchedule?.viaPoints[i]?.STD,
    //           "viapoints",
    //           temp?.data[scheduleIndex]?.routeSchedule?.viaPoints[i]
    //             ?.dayDeparture
    //         );
    //     }
    //   }
    // }
    // setTripData(temp);
  }, [scheduleData]);

  return (
    <>
      <Card
        className="ppmodal"
        data-testid="modal"
        sx={{
          ...cardClasses.basic,
          borderRadius: "8px",
          border: `1px solid ${themeType.default.themeOrange}`,
          position: "absolute",
          marginRight: "20px",
          borderTop: "2px solid #E8E8E8",
          maxHeight: "77vh",
        }}
      >
        <Grid item container>
          <CardHeader
            sx={{
              width: "100%",
              height: "46px",
              backgroundColor: themeType.default.themeOrange,
            }}
            title={
              <Typography
                sx={{
                  ...textClasses.boldText,
                  color: "white",
                  fontSize: "16px",
                }}
              >
                <img
                  style={{ width: "22px", height: "22px" }}
                  src={schedule}
                  alt=""
                />
                <span style={{ position: "relative", left: "10px" }}>
                  Route Schedule
                </span>
              </Typography>
            }
            action={
              <IconButton
                size="small"
                sx={{ p: 0, mb: 1 }}
                onClick={() => setShowScheduleDetails(false)}
              >
                {detailTrips && Object.keys(detailTrips).length ? null : (
                  <Close sx={{ color: "white" }} />
                )}
              </IconButton>
            }
          />

          <CardContent
            sx={{
              height: "calc(77vh - 50px)",
              overflow: "auto",
              position: "relative",
              padding: "0px !important",
            }}
          >
            {/* {scheduleValidation && (
              <Box px={2} py={0.5} sx={{width:"100%", position: "sticky", top: "0px", left: "0px", bgcolor: theme => theme.palette.background.default, zIndex: 100}}>
                <Typography variant="body2" sx={{color: themeType.default.themeRed}}>
                  {scheduleValidation}
                </Typography>
              </Box>
            )} */}
            <Box sx={{ p: 2 }}>
              {/* ------- SOURCE ------- */}
              <Box pb={3} sx={{ borderBottom: "1.5px dashed #ff7200" }}>
                <Grid container>
                  <Grid item md={12}>
                    <Typography
                      sx={{ ...textClasses.boldText, fontSize: "20px" }}
                    >
                      Source
                    </Typography>
                  </Grid>
                  <Grid item md={12} mt={2}>
                    <FormControl
                      sx={{
                        ...selectStylesOverride,
                        width: "100%",
                        display: "flex",
                      }}
                    >
                      <Controller
                        name="sourceAddress"
                        control={control}
                        render={({ field: { onChange, value, name } }) => {
                          return (
                            <CustomSelect
                              selectStylesOverride={selectStylesOverride(
                                isDarkThemeEnabledSelector
                              )}
                              label="Source Address"
                              value={scheduleData?.routeSource || null}
                              onChange={(e, newVal) => {
                                _handleDetailCardChange(
                                  e,
                                  newVal,
                                  "sourceAddress"
                                );
                              }}
                              name={name}
                              errors={errors}
                              getOptionLabel={(option) => {
                                if (typeof option === "string") {
                                  return (
                                    GeofencesListing?.find(
                                      (_) => _?.name == value
                                    )?.name || ""
                                  );
                                } else {
                                  return option?.name || "";
                                }
                              }}
                              labelKey="name"
                              isOptionEqualToValue={(option, value) =>
                                option._id === value._id &&
                                option.name === value.name
                              }
                              options={GeofencesListing && GeofencesListing}
                            />
                          );
                        }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item md={12} mt={2}>
                    <FormControl
                      sx={{
                        ...selectStylesOverride(isDarkThemeEnabledSelector),
                        width: "100%",
                      }}
                    >
                      <CustomTimeField
                        label="Schedule Time of Departure"
                        placeholder={"hh:mm"}
                        // name={name}
                        selectStylesOverride={selectStylesOverride(
                          isDarkThemeEnabledSelector
                        )}
                        // value={value || null}
                        value={
                          scheduleData?.routeSource?.STD
                            ? convertHHMMToDate(scheduleData?.routeSource?.STD)
                            : null
                        }
                        onChange={(time) => {
                          if (new Date(time).toString() !== "Invalid Date") {
                            const formattedTime = time && format(time, "HH:mm");
                            _handleDetailCardChange(
                              null,
                              formattedTime,
                              "sourceSTA"
                            );
                          }
                          // onChange(time);
                        }}
                        errors={errors}
                        maxwidth="80px"
                      />
                      {/* <DateTimePicker
                        label="Time"
                        value={new Date(scheduleData?.routeSource?.STD)}
                        onChange={(date) =>
                          _handleDetailCardChange(null, date, "sourceSTA")
                        }
                        className="customDatePicker"
                        format="dd-MM-yyyy hh:mm a"
                        sx={{
                          width: "100%",
                          ...selectStylesOverride,
                        }}
                        slotProps={dateTimePickerSlotProps}
                      /> */}
                    </FormControl>
                  </Grid>
                </Grid>
              </Box>
              {/* ------- VIA POINTS -------- */}
              <Box mt={2}>
                {scheduleData?.viaPoints?.length ? (
                  scheduleData?.viaPoints.map((item, index) => {
                    return (
                      <Grid
                        key={`via-${index}`}
                        mb={2}
                        pb={2}
                        container
                        sx={{ borderBottom: "1.5px dashed #ff7200" }}
                      >
                        <Grid
                          item
                          md={12}
                          display={"flex"}
                          sx={{
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <Typography
                            sx={{ ...textClasses.boldText, fontSize: "20px" }}
                          >
                            Via Point - {index + 1}
                          </Typography>
                          <Grid item>
                            {scheduleData?.viaPoints?.length - 1 === index ? (
                              <IconButton
                                size="medium"
                                color="success"
                                onClick={() => addViapoint()}
                              >
                                <AddCircleOutline
                                  sx={{ color: themeType.default.themeGreen }}
                                />
                              </IconButton>
                            ) : null}
                            <IconButton
                              size="medium"
                              color="error"
                              onClick={() => deleteViapoint(index)}
                            >
                              <DeleteOutline
                                sx={{ color: themeType.default.themeRed }}
                              />
                            </IconButton>
                          </Grid>
                        </Grid>
                        <Grid item md={12} mt={2}>
                          <FormControl
                            sx={{
                              ...selectStylesOverride,
                              width: "100%",
                              display: "flex",
                            }}
                          >
                            <Controller
                              name={`viaPointAddress-${index}`}
                              control={control}
                              render={({
                                field: { onChange, value, name },
                              }) => {
                                return (
                                  <CustomSelect
                                    selectStylesOverride={selectStylesOverride(
                                      isDarkThemeEnabledSelector
                                    )}
                                    label="Via Point Address"
                                    value={item || null}
                                    onChange={(e, newVal) => {
                                      _handleDetailCardChange(
                                        e,
                                        newVal,
                                        "viapt",
                                        index
                                      );
                                    }}
                                    name={name}
                                    errors={errors}
                                    getOptionLabel={(option) => {
                                      const originalOption =
                                        GeofencesListing.find(
                                          (item) => item._id === option._id
                                        );
                                      return originalOption
                                        ? originalOption.name
                                        : "";
                                    }}
                                    labelKey="name"
                                    isOptionEqualToValue={(option, value) =>
                                      option._id === value._id &&
                                      option.name === value.name
                                    }
                                    options={
                                      GeofencesListing && GeofencesListing
                                    }
                                  />
                                );
                              }}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item md={12} gap={1} mt={2} display={"flex"}>
                          <Grid item md={6}>
                            <Grid
                              sx={{
                                height: "36px",
                                alignItems: "center",
                                display: "flex",
                                paddingLeft: "8px",
                                position: "relative",
                                ...textClasses.labelHeadBorder,
                              }}
                            >
                              <Typography
                                sx={{
                                  position: "absolute",
                                  top: "-7px",
                                  left: "12px",
                                  ...textClasses.labelHeadColor,
                                }}
                              >
                                Travel Time
                              </Typography>
                              <Typography
                                sx={{
                                  color: "#808080",
                                  fontSize: "0.8rem",
                                  paddingLeft: "7px",
                                  paddingTop: "2PX",
                                }}
                              >
                                {item.travelTime}
                              </Typography>
                            </Grid>
                          </Grid>

                          <Grid item md={6}>
                            <FormControl
                              sx={{ ...selectStylesOverride, width: "100%" }}
                            >
                              <Controller
                                name={`averageSpeed-${index}`}
                                control={control}
                                render={({
                                  field: { onChange, value, name },
                                }) => {
                                  return (
                                    <CustomInput
                                      placeholder="Average Speed"
                                      label="Average Speed"
                                      value={item?.speed ? item?.speed : 40}
                                      required={false}
                                      disabled
                                      selectStylesOverride={selectStylesOverride(
                                        isDarkThemeEnabledSelector
                                      )}
                                      inputClasses={inputClasses}
                                      name={name}
                                      errors={errors}
                                      onChange={(e, newVal) => {
                                        _handleDetailCardChange(
                                          e,
                                          newVal,
                                          "avgSpeed",
                                          index
                                        );
                                      }}
                                    />
                                  );
                                }}
                              />
                            </FormControl>
                          </Grid>
                        </Grid>
                        {/* >>>>>> STA <<<<<<< */}
                        <Grid
                          item
                          container
                          md={12}
                          mt={1}
                          rowSpacing={1}
                          columnSpacing={1}
                        >
                          <Grid item xs={12}>
                            <Typography variant="body2">
                              Schedule Time of Arrival
                            </Typography>
                          </Grid>
                          <Grid item md={6}>
                            <FormControl
                              sx={{
                                ...selectStylesOverride(
                                  isDarkThemeEnabledSelector
                                ),
                                width: "100%",
                              }}
                            >
                              <CustomSelect
                                options={dayOptions}
                                label="Day"
                                value={item?.dayArrival || null}
                                selectStylesOverride={selectStylesOverride(
                                  isDarkThemeEnabledSelector
                                )}
                                labelKey="label"
                                getOptionLabel={(option) => {
                                  if (option && typeof option === "string") {
                                    if (
                                      option === "sameDay" ||
                                      option === "nextDay"
                                    ) {
                                      return dayOptions?.find(
                                        (_) => _?.value === option
                                      )?.label;
                                    } else {
                                      return dayOptions?.find(
                                        (_) => _?.value === "custom"
                                      )?.label;
                                    }
                                  } else {
                                    return option?.label || "";
                                  }
                                }}
                                onChange={(e, newVal) => {
                                  _handleDetailCardChange(
                                    null,
                                    newVal,
                                    "dayArrival",
                                    index
                                  );
                                }}
                              />
                            </FormControl>
                          </Grid>
                          {customScheduleTime?.viaPoints?.dayArrivals?.length &&
                          customScheduleTime?.viaPoints?.dayArrivals?.includes(
                            index
                          ) ? (
                            <Grid item md={6}></Grid>
                          ) : null}
                          {customScheduleTime?.viaPoints?.dayArrivals?.length &&
                          customScheduleTime?.viaPoints?.dayArrivals?.includes(
                            index
                          ) ? (
                            <Grid item md={6}>
                              <FormControl
                                sx={{
                                  ...selectStylesOverride(
                                    isDarkThemeEnabledSelector
                                  ),
                                  width: "100%",
                                }}
                              >
                                <CustomInput
                                  label="No. of days"
                                  value={
                                    item?.dayArrival
                                      ? item?.dayArrival === "custom"
                                        ? ""
                                        : item?.dayArrival
                                      : ""
                                  }
                                  selectStylesOverride={selectStylesOverride(
                                    isDarkThemeEnabledSelector
                                  )}
                                  inputClasses={inputClasses}
                                  onChange={(e) => {
                                    _handleDetailCardChange(
                                      null,
                                      e?.target?.value,
                                      "dayArrivalCustomCount",
                                      index
                                    );
                                  }}
                                />
                              </FormControl>
                            </Grid>
                          ) : null}
                          <Grid item md={6}>
                            <FormControl
                              sx={{
                                ...selectStylesOverride(
                                  isDarkThemeEnabledSelector
                                ),
                                width: "100%",
                              }}
                            >
                              <CustomTimeField
                                label="Time"
                                placeholder={"hh:mm"}
                                selectStylesOverride={selectStylesOverride(
                                  isDarkThemeEnabledSelector
                                )}
                                value={
                                  item?.STA
                                    ? convertHHMMToDate(item?.STA)
                                    : null
                                }
                                onChange={(time) => {
                                  if (
                                    new Date(time).toString() !== "Invalid Date"
                                  ) {
                                    const formattedTime =
                                      time && format(time, "HH:mm");
                                    _handleDetailCardChange(
                                      null,
                                      formattedTime,
                                      "STA",
                                      index
                                    );
                                  }
                                  // onChange(time);
                                }}
                              />
                            </FormControl>
                          </Grid>
                        </Grid>
                        {/* >>>>>> STD <<<<<<< */}
                        <Grid
                          item
                          container
                          md={12}
                          mt={1}
                          rowSpacing={1}
                          columnSpacing={1}
                        >
                          <Grid item xs={12}>
                            <Typography variant="body2">
                              Schedule Time of Departure
                            </Typography>
                          </Grid>
                          <Grid item md={6}>
                            <FormControl
                              sx={{
                                ...selectStylesOverride(
                                  isDarkThemeEnabledSelector
                                ),
                                width: "100%",
                              }}
                            >
                              <CustomSelect
                                options={dayOptions}
                                label="Day"
                                value={item?.dayDeparture || null}
                                selectStylesOverride={selectStylesOverride(
                                  isDarkThemeEnabledSelector
                                )}
                                labelKey="label"
                                getOptionLabel={(option) => {
                                  if (option && typeof option === "string") {
                                    if (
                                      option === "sameDay" ||
                                      option === "nextDay"
                                    ) {
                                      return dayOptions?.find(
                                        (_) => _?.value === option
                                      )?.label;
                                    } else {
                                      return dayOptions?.find(
                                        (_) => _?.value === "custom"
                                      )?.label;
                                    }
                                  } else {
                                    return option?.label || "";
                                  }
                                }}
                                onChange={(e, newVal) => {
                                  _handleDetailCardChange(
                                    null,
                                    newVal,
                                    "dayDeparture",
                                    index
                                  );
                                }}
                              />
                            </FormControl>
                          </Grid>
                          {customScheduleTime?.viaPoints?.dayDepartures
                            ?.length &&
                          customScheduleTime?.viaPoints?.dayDepartures?.includes(
                            index
                          ) ? (
                            <Grid item md={6}></Grid>
                          ) : null}
                          {customScheduleTime?.viaPoints?.dayDepartures
                            ?.length &&
                          customScheduleTime?.viaPoints?.dayDepartures?.includes(
                            index
                          ) ? (
                            <Grid item md={6}>
                              <FormControl
                                sx={{
                                  ...selectStylesOverride(
                                    isDarkThemeEnabledSelector
                                  ),
                                  width: "100%",
                                }}
                              >
                                <CustomInput
                                  label="No. of days"
                                  value={
                                    item?.dayDeparture
                                      ? item?.dayDeparture === "custom"
                                        ? ""
                                        : item?.dayDeparture
                                      : ""
                                  }
                                  selectStylesOverride={selectStylesOverride(
                                    isDarkThemeEnabledSelector
                                  )}
                                  inputClasses={inputClasses}
                                  onChange={(e) => {
                                    _handleDetailCardChange(
                                      null,
                                      e?.target?.value,
                                      "dayDepartureCustomCount",
                                      index
                                    );
                                  }}
                                />
                              </FormControl>
                            </Grid>
                          ) : null}
                          <Grid item md={6}>
                            <FormControl
                              sx={{
                                ...selectStylesOverride(
                                  isDarkThemeEnabledSelector
                                ),
                                width: "100%",
                              }}
                            >
                              <CustomTimeField
                                label="Time"
                                placeholder={"hh:mm"}
                                selectStylesOverride={selectStylesOverride(
                                  isDarkThemeEnabledSelector
                                )}
                                value={
                                  item?.STD
                                    ? convertHHMMToDate(item?.STD)
                                    : null
                                }
                                onChange={(time) => {
                                  if (
                                    new Date(time).toString() !== "Invalid Date"
                                  ) {
                                    const formattedTime =
                                      time && format(time, "HH:mm");
                                    _handleDetailCardChange(
                                      null,
                                      formattedTime,
                                      "STD",
                                      index
                                    );
                                  }
                                }}
                              />
                            </FormControl>
                          </Grid>
                        </Grid>

                        {/* <Grid item md={12} mt={2}>
                          <FormControl
                            sx={{
                              ...selectStylesOverride(isDarkThemeEnabledSelector),
                              width: "100%",
                            }}
                          >
                            <DateTimePicker
                              name={`ScheduleTimeOfArrival-${index}`}
                              label="Schedule Time Of Arrival"
                              value={new Date(item?.STA)}
                              onChange={(date) =>
                                _handleDetailCardChange(null, date, "STA", index)
                              }
                              className="customDatePicker"
                              format="dd-MM-yyyy hh:mm a"
                              sx={{
                                width: "100%",
                                ...selectStylesOverride,
                              }}
                              slotProps={dateTimePickerSlotProps}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item md={12} mt={2}>
                          <FormControl
                            sx={{
                              ...selectStylesOverride(isDarkThemeEnabledSelector),
                              width: "100%",
                            }}
                          >
                            <DateTimePicker
                              name={`ScheduleTimeDepart-${index}`}
                              label="Schedule Time Of Departure"
                              value={new Date(item?.STD)}
                              onChange={(date) =>
                                _handleDetailCardChange(null, date, "STD", index)
                              }
                              className="customDatePicker"
                              format="dd-MM-yyyy hh:mm a"
                              sx={{
                                width: "100%",
                                ...selectStylesOverride,
                              }}
                              slotProps={dateTimePickerSlotProps}
                            />
                          </FormControl>
                        </Grid> */}
                        {item?.halt ? (
                          <Grid item md={12} mt={0} mb={2}>
                            <Typography
                              sx={{ fontSize: "14px", paddingLeft: "8px" }}
                            >
                              Halt Duration : {item.halt}
                            </Typography>
                          </Grid>
                        ) : null}
                      </Grid>
                    );
                  })
                ) : (
                  <Grid
                    item
                    md={12}
                    display={"flex"}
                    pt={0}
                    mb={2}
                    sx={{
                      justifyContent: "space-between",
                      borderBottom: "1.5px dashed #ff7200",
                    }}
                  >
                    <Typography
                      sx={{ ...textClasses.boldText, fontSize: "20px" }}
                    >
                      Via Points - 0
                    </Typography>
                    <Grid item>
                      <IconButton
                        size="medium"
                        onClick={() => addViapoint()}
                        color="success"
                      >
                        <AddCircleOutline
                          sx={{ color: themeType.default.themeGreen }}
                        />
                      </IconButton>
                    </Grid>
                  </Grid>
                )}
              </Box>
              {/* ------- DESTINATION ------- */}
              <Box pb={3}>
                <Grid container>
                  <Grid item md={12}>
                    <Typography
                      sx={{ ...textClasses.boldText, fontSize: "20px" }}
                    >
                      Destination
                    </Typography>
                  </Grid>
                  <Grid item md={12} mt={2}>
                    <FormControl
                      sx={{
                        ...selectStylesOverride,
                        width: "100%",
                        display: "flex",
                      }}
                    >
                      <Controller
                        name="destinationAddress"
                        control={control}
                        render={({ field: { onChange, value, name } }) => {
                          return (
                            <CustomSelect
                              selectStylesOverride={selectStylesOverride(
                                isDarkThemeEnabledSelector
                              )}
                              label="Destination Address"
                              value={scheduleData?.routeDestination || null}
                              onChange={(e, newVal) => {
                                // onChange(newVal?.name);
                                _handleDetailCardChange(
                                  e,
                                  newVal,
                                  "sourceDestination"
                                );
                              }}
                              name={name}
                              errors={errors}
                              getOptionLabel={(option) => {
                                if (typeof option === "string") {
                                  return (
                                    GeofencesListing?.find(
                                      (_) => _?.name == value
                                    )?.name || ""
                                  );
                                } else {
                                  return option?.name || "";
                                }
                              }}
                              labelKey="name"
                              isOptionEqualToValue={(option, value) =>
                                option._id === value._id &&
                                option.name === value.name
                              }
                              options={GeofencesListing && GeofencesListing}
                            />
                          );
                        }}
                      />
                    </FormControl>
                  </Grid>

                  <Grid item md={12} gap={1} mt={2} display={"flex"}>
                    <Grid item md={6}>
                      <Grid
                        sx={{
                          height: "36px",
                          alignItems: "center",
                          display: "flex",
                          paddingLeft: "8px",
                          position: "relative",
                          ...textClasses.labelHeadBorder,
                        }}
                      >
                        <Typography
                          sx={{
                            position: "absolute",
                            top: "-7px",
                            left: "12px",
                            ...textClasses.labelHeadColor,
                          }}
                        >
                          Travel Time
                        </Typography>
                        <Typography
                          sx={{
                            color: "#808080",
                            fontSize: "0.8rem",
                            paddingLeft: "7px",
                            paddingTop: "2PX",
                          }}
                        >
                          {scheduleData?.routeDestination?.travelTime}
                        </Typography>
                      </Grid>
                    </Grid>

                    <Grid item md={6}>
                      <FormControl
                        sx={{ ...selectStylesOverride, width: "100%" }}
                      >
                        <Controller
                          defaultValue=""
                          name="destinationaverageSpeed"
                          control={control}
                          render={({ field: { onChange, value, name } }) => {
                            return (
                              <CustomInput
                                placeholder="Average Speed"
                                label="Average Speed"
                                required={false}
                                disabled
                                selectStylesOverride={selectStylesOverride(
                                  isDarkThemeEnabledSelector
                                )}
                                inputClasses={inputClasses}
                                name={name}
                                errors={errors}
                                value={
                                  scheduleData?.routeDestination?.speed || "40"
                                }
                                onChange={(e, newVal) => {
                                  _handleDetailCardChange(
                                    e,
                                    newVal,
                                    "destinationaverageSpeed"
                                  );
                                }}
                              />
                            );
                          }}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    container
                    xs={12}
                    rowSpacing={1}
                    columnSpacing={1}
                    mt={1}
                  >
                    <Grid xs={12} item>
                      <Typography variant="body2">
                        Schedule Time of Arrival
                      </Typography>
                    </Grid>
                    <Grid item md={6}>
                      <FormControl
                        sx={{
                          ...selectStylesOverride(isDarkThemeEnabledSelector),
                          width: "100%",
                        }}
                      >
                        <CustomSelect
                          options={dayOptions}
                          label="Day"
                          value={
                            scheduleData?.routeDestination?.dayArrival || null
                          }
                          selectStylesOverride={selectStylesOverride(
                            isDarkThemeEnabledSelector
                          )}
                          labelKey="label"
                          getOptionLabel={(option) => {
                            if (option && typeof option === "string") {
                              if (
                                option === "sameDay" ||
                                option === "nextDay"
                              ) {
                                return dayOptions?.find(
                                  (_) => _?.value === option
                                )?.label;
                              } else {
                                return dayOptions?.find(
                                  (_) => _?.value === "custom"
                                )?.label;
                              }
                            } else {
                              return option?.label || "";
                            }
                          }}
                          onChange={(e, newVal) => {
                            _handleDetailCardChange(
                              null,
                              newVal,
                              "destinationDayArrival"
                            );
                          }}
                        />
                      </FormControl>
                    </Grid>
                    {customScheduleTime?.destination ? (
                      <Grid item md={6}></Grid>
                    ) : null}
                    {customScheduleTime?.destination ? (
                      <Grid item md={6}>
                        <FormControl
                          sx={{
                            ...selectStylesOverride(isDarkThemeEnabledSelector),
                            width: "100%",
                          }}
                        >
                          <CustomInput
                            label="No. of days"
                            value={
                              scheduleData?.routeDestination?.dayArrival
                                ? scheduleData?.routeDestination?.dayArrival ===
                                  "custom"
                                  ? ""
                                  : scheduleData?.routeDestination?.dayArrival
                                : ""
                            }
                            selectStylesOverride={selectStylesOverride(
                              isDarkThemeEnabledSelector
                            )}
                            inputClasses={inputClasses}
                            onChange={(e) => {
                              _handleDetailCardChange(
                                null,
                                e?.target?.value,
                                "destinationArrivalCustomCount"
                              );
                            }}
                          />
                        </FormControl>
                      </Grid>
                    ) : null}
                    <Grid item md={6}>
                      <FormControl
                        sx={{
                          ...selectStylesOverride(isDarkThemeEnabledSelector),
                          width: "100%",
                        }}
                      >
                        <CustomTimeField
                          label="Time"
                          placeholder={"hh:mm"}
                          // name={name}
                          selectStylesOverride={selectStylesOverride(
                            isDarkThemeEnabledSelector
                          )}
                          // value={value || null}
                          value={
                            scheduleData?.routeDestination?.STA
                              ? convertHHMMToDate(
                                  scheduleData?.routeDestination?.STA
                                )
                              : null
                          }
                          onChange={(time) => {
                            if (new Date(time).toString() !== "Invalid Date") {
                              const formattedTime =
                                time && format(time, "HH:mm");
                              _handleDetailCardChange(
                                null,
                                formattedTime,
                                "rdSTA"
                              );
                            }
                            // onChange(time);
                          }}
                        />
                        {/* <DateTimePicker
                          label="Schedule Time Of Arrival"
                          value={new Date(scheduleData?.routeDestination?.STA)}
                          onChange={(date) =>
                            _handleDetailCardChange(null, date, "rdSTA")
                          }
                          className="customDatePicker"
                          format="dd-MM-yyyy hh:mm a"
                          sx={{
                            width: "100%",
                            ...selectStylesOverride,
                          }}
                          slotProps={dateTimePickerSlotProps}
                        /> */}
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </CardContent>
        </Grid>
      </Card>
    </>
  );
};

export default AddTripSchedule;
