import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Typography,
} from "@mui/material";
import React from "react";
import { selectStylesOverride } from "../../../../utils/util";
import { Controller, useForm, useWatch } from "react-hook-form";
import { getAllDeviceModels } from "../../../../redux/actions";
import CustomSelect from "../../../../components/FormUI/CustomSelect";
import { debounce } from "lodash";
import { CustomInput } from "../../../../components/FormUI";
import AdminsSection from "./AdminsSection";

const SAFormData = ({ otherFormProps, adminSectionProps }) => {
  const {
    errors,
    control,
    getValues,
    loading,
    selectStylesOverride,
    setValue,
    searchFields,
    setSearchFields,
  } = adminSectionProps;
  const {
    isEdit,
    dispatch,
    deviceTypeEntered,
    deviceModelInfo,
    themeType,
    handleSubmit,
    handleCreateDevice,
    handleUpdateDevice,
    handleCancelButton,
    allPorts,
    addMoreCheck,
    setAddMoreCheck,
    allDeviceModels,
    operatorOptions,
    allDeviceCategoryNames,
    handleSearchDeviceModels,
    handleSearchDeviceType,
    handleSearchPortGroup,
  } = otherFormProps;

  const { buttonClasses, inputClasses, textClasses, dividerClass } = themeType;

  React.useEffect(() => {
    if (deviceTypeEntered && deviceTypeEntered !== "") {
      verify(deviceTypeEntered);
    }
  }, [deviceTypeEntered]);

  const verify = React.useCallback(
    debounce((name) => {
      dispatch(
        getAllDeviceModels({
          filter: {
            category: name,
          },
          selection: ["info", "deviceModel", "lynkDeviceModel"],
        })
      );
    }, 200),
    []
  );

  return (
    <>
      <Grid item container spacing={2}>
        {/* Device Type */}
        <Grid item md={3}>
          <Controller
            name="deviceType"
            control={control}
            rules={{
              required: {
                value: true,
                message: "Device Type is required",
              },
            }}
            render={({ field: { onChange, value, name } }) => {
              return (
                <CustomSelect
                  data-testid="device-type-select"
                  selectStylesOverride={selectStylesOverride}
                  value={value || null}
                  defaultValue={null}
                  filterOptions={(options) => options}
                  disabled={isEdit}
                  searchValue={searchFields?.deviceType}
                  onSearch={handleSearchDeviceType}
                  onInputChange={(e) => {
                    if (e?.type === "change") {
                      setSearchFields((prev) => ({
                        ...prev,
                        deviceType: e?.target?.value?.trim(),
                      }));
                    }
                  }}
                  onChange={(e, newVal) => {
                    onChange(newVal?.category);
                    setValue("deviceType", newVal?.category);
                    setValue("deviceModel", undefined);
                    setValue("deviceModelInfo", undefined);
                  }}
                  name={name}
                  label="Device Type"
                  required
                  errors={errors}
                  getOptionLabel={(option) => {
                    if (typeof option === "string") {
                      return (
                        allDeviceCategoryNames?.find(
                          (_) => _?.category == value
                        )?.label || ""
                      );
                    } else {
                      return option?.label || "";
                    }
                  }}
                  labelKey="label"
                  options={allDeviceCategoryNames && allDeviceCategoryNames}
                />
              );
            }}
          />
        </Grid>
        {/* Device model */}
        <Grid item md={3}>
          <Controller
            name="deviceModel"
            control={control}
            rules={{
              required: {
                value: true,
                message: "Device Model is required",
              },
            }}
            render={({ field: { onChange, value, name } }) => {
              return (
                <CustomSelect
                  data-testid="device-model-select"
                  selectStylesOverride={selectStylesOverride}
                  value={value || null}
                  defaultValue={null}
                  disabled={isEdit || !deviceTypeEntered}
                  onChange={(e, newVal) => {
                    onChange(newVal?.deviceModel);
                    //selectedDevice ? newVal : newVal?.deviceModel
                    let onlyTruePermissions =
                      newVal?.info?.length > 0 &&
                      newVal?.info?.filter((item) => item?.visibility == true);
                    setValue("deviceModelInfo", onlyTruePermissions);
                  }}
                  filterOptions={(options) => options}
                  searchValue={searchFields?.deviceModel}
                  onSearch={handleSearchDeviceModels}
                  onInputChange={(e) => {
                    if (e?.type === "change") {
                      setSearchFields((prev) => ({
                        ...prev,
                        deviceModel: e?.target?.value?.trim(),
                      }));
                    }
                  }}
                  name={name}
                  label="Device Model"
                  required
                  errors={errors}
                  getOptionLabel={(option) => {
                    if (typeof option === "string") {
                      return (
                        allDeviceModels?.find((_) => _?.deviceModel == value)
                          ?.lynkDeviceModel || ""
                      );
                    } else {
                      return option?.lynkDeviceModel || "";
                    }
                  }}
                  labelKey="lynkDeviceModel"
                  options={allDeviceModels && allDeviceModels}
                />
              );
            }}
          />
        </Grid>
        {/* Port Group */}
        <Grid item md={3}>
          <Controller
            name="groupType"
            control={control}
            rules={{
              required: {
                value: true,
                message: "Post Group Type is required",
              },
            }}
            render={({ field: { onChange, value, name } }) => {
              return (
                <CustomSelect
                  selectStylesOverride={selectStylesOverride}
                  data-testid="port-group-select"
                  value={value || null}
                  defaultValue={undefined}
                  onChange={(e, newVal) => {
                    onChange(newVal?.value);
                  }}
                  searchValue={searchFields?.portGroup}
                  onSearch={handleSearchPortGroup}
                  onInputChange={(e) => {
                    if (e?.type === "change") {
                      setSearchFields((prev) => ({
                        ...prev,
                        portGroup: e?.target?.value?.trim(),
                      }));
                    }
                  }}
                  name={name}
                  filterOptions={(options) => options}
                  required
                  label="Port Group Type"
                  errors={errors}
                  getOptionLabel={(option) => {
                    if (typeof option === "string") {
                      return (
                        allPorts?.find((_) => _?.value == value)?.label || ""
                      );
                    } else {
                      return option?.label || "";
                    }
                  }}
                  options={allPorts && allPorts}
                />
              );
            }}
          />
        </Grid>
        {/* Device Id */}
        <Grid item md={3}>
          <Controller
            name="deviceId"
            control={control}
            rules={{
              required: {
                value: true,
                message: "Device Id is required",
              },
              pattern: {
                value: /^\d{9,15}$/,
                message: "Enter a valid device Id",
              },
            }}
            render={({ field: { onChange, value, name } }) => {
              return (
                <CustomInput
                  required
                  label="Device Id"
                  placeholder="Device Id"
                  selectStylesOverride={selectStylesOverride}
                  data-testid="device-id-text"
                  inputClasses={inputClasses}
                  disabled={isEdit}
                  name={name}
                  errors={errors}
                  value={value || ""}
                  onChange={onChange}
                />
              );
            }}
          />
        </Grid>
        {/* IMEI */}
        <Grid item md={3}>
          <Controller
            defaultValue={null}
            name="imei"
            rules={{
              required: {
                value: true,
                message: "IMEI is required",
              },
              pattern: {
                value: /^\d{9,15}$/,
                message: "Enter a valid IMEI",
              },
            }}
            control={control}
            render={({ field: { onChange, value, name } }) => {
              return (
                <CustomInput
                  errors={errors}
                  required
                  placeholder="IMEI"
                  selectStylesOverride={selectStylesOverride}
                  inputClasses={inputClasses}
                  disabled={isEdit}
                  name={name}
                  data-testid="imei-text"
                  value={value || ""}
                  onChange={onChange}
                />
              );
            }}
          />
        </Grid>
        {/* Client Id. */}
        <Grid item md={3}>
          <Controller
            defaultValue={null}
            name="clientId"
            rules={{
              required: {
                value: true,
                message: "Client Id is required",
              },
              pattern: {
                value: /^[a-zA-Z0-9]+$/,
                message: "Enter a valid client Id",
              },
            }}
            control={control}
            render={({ field: { onChange, value, name } }) => {
              return (
                <CustomInput
                  errors={errors}
                  placeholder="Client Id"
                  required
                  selectStylesOverride={selectStylesOverride}
                  data-testid="client-id-text"
                  inputClasses={inputClasses}
                  name={name}
                  value={value || ""}
                  onChange={onChange}
                />
              );
            }}
          />
        </Grid>
        {/* Operator No. 1 */}
        <Grid item md={3}>
          <Controller
            name="sim1type"
            control={control}
            rules={{
              required: {
                value: true,
                message: "Sim 1 Type is required",
              },
            }}
            render={({ field: { onChange, value, name } }) => {
              return (
                <CustomSelect
                  selectStylesOverride={selectStylesOverride}
                  value={value || null}
                  onChange={(e, newVal) => {
                    onChange(newVal?.value);
                  }}
                  name={name}
                  label="Sim 1 Type"
                  required
                  data-testid="operator1-select"
                  errors={errors}
                  getOptionLabel={(option) => {
                    if (value) {
                      if (typeof option === "string") {
                        return (
                          operatorOptions?.find((_) => _?.value == value)
                            ?.label || ""
                        );
                      } else {
                        return option?.label || "";
                      }
                    } else {
                      return "";
                    }
                  }}
                  options={operatorOptions}
                />
              );
            }}
          />
        </Grid>
        {/* Sim no. 1 */}
        <Grid item md={3}>
          <Controller
            defaultValue=""
            name="sim1no"
            rules={{
              required: {
                value: true,
                message: "Sim 1 Number is required",
              },
              minLength: {
                value: 10,
                message: "Sim 1 number must be atleast 10 characters",
              },
              maxLength: {
                value: 13,
                message: "Sim 1 number must be maximum 13 characters",
              },
            }}
            control={control}
            render={({ field: { onChange, value, name } }) => {
              return (
                <CustomInput
                  placeholder="Sim 1 Number"
                  selectStylesOverride={selectStylesOverride}
                  inputClasses={inputClasses}
                  name={name}
                  required
                  data-testid="sim1-text"
                  errors={errors}
                  value={value || ""}
                  onChange={onChange}
                />
              );
            }}
          />
        </Grid>
      </Grid>

      {/* 
        dynamic row ------- [info will be an array of objects which will be containing objects having a key called dependents]
        objects of "info" will be based on check marks that were selected while creating device model.
        And if some check is selected, we will render the "dependents" of that checked object.
      */}
      {deviceModelInfo && deviceModelInfo?.length > 0 && (
        <Grid item container spacing={2} my={1}>
          {deviceModelInfo?.map((item) => {
            return (
              item?.visibility &&
              item?.dependent?.length > 0 && (
                <>
                  {item?.dependent?.map((subItem, key) => {
                    return (
                      <Grid item md={3} key={key}>
                        <Controller
                          name={subItem?.key}
                          control={control}
                          rules={{
                            required: {
                              value:
                                getValues(subItem?.key) === false
                                  ? false
                                  : subItem?.required
                                  ? true
                                  : false,
                              message:
                                subItem?.required ||
                                getValues(subItem?.key) !== false
                                  ? `${subItem?.label} is required`
                                  : "",
                            },
                          }}
                          render={({ field: { onChange, value, name } }) => {
                            if (subItem?.type === "input") {
                              return (
                                <CustomInput
                                  placeholder={subItem?.placeholder}
                                  selectStylesOverride={selectStylesOverride}
                                  inputClasses={inputClasses}
                                  required={subItem?.required ? true : false}
                                  name={name}
                                  errors={errors}
                                  value={value || ""}
                                  onChange={onChange}
                                />
                              );
                            } else if (subItem?.type === "select") {
                              return (
                                <CustomSelect
                                  selectStylesOverride={selectStylesOverride}
                                  value={
                                    value !== undefined && value == false
                                      ? value?.toString()
                                      : value || null
                                  }
                                  onChange={(e, newVal) => {
                                    onChange(newVal?.value);
                                  }}
                                  name={name}
                                  required={subItem?.required ? true : false}
                                  label={subItem?.label}
                                  errors={errors}
                                  getOptionLabel={(option) => {
                                    if (typeof option === "boolean") {
                                      return subItem?.values?.find(
                                        (_) =>
                                          _?.value?.toString()?.toLowerCase() ==
                                          value?.toString()?.toLowerCase()
                                      )?.label || "";
                                    }
                                    if (typeof option === "string") {
                                      return subItem?.values?.find(
                                        (_) =>
                                          _?.value?.toString()?.toLowerCase() ==
                                          value?.toString()?.toLowerCase()
                                      )?.label || "";
                                    } else {
                                      return option?.label || "";
                                    }
                                  }}
                                  options={subItem?.values && subItem?.values}
                                />
                              );
                            } else if (subItem?.type === "checkbox") {
                              return (
                                <FormControlLabel
                                  sx={{
                                    color: "rgb(128, 128, 128)",
                                    "& .MuiFormControlLabel-label": {
                                      fontSize: "13px",
                                    },
                                  }}
                                  label={subItem?.label}
                                  control={
                                    <Checkbox
                                      sx={{
                                        color: "#ff7200",
                                        "&.Mui-checked": {
                                          color: "#ff7200",
                                        },
                                      }}
                                      checked={value === true ? true : false}
                                      onChange={(e) => {
                                        if (e?.target?.checked) {
                                          onChange(true);
                                          setValue(subItem?.key, true);
                                        } else {
                                          onChange(false);
                                          setValue(subItem?.key, false);
                                        }
                                      }}
                                      name={name}
                                    />
                                  }
                                />
                              );
                            } else {
                              return <></>;
                            }
                          }}
                        />
                      </Grid>
                    );
                  })}
                </>
              )
            );
          })}
        </Grid>
      )}

      <AdminsSection isEdit={isEdit} allProps={adminSectionProps} />

      <Grid
        item
        xs={12}
        display="flex"
        justifyContent="center"
        alignItems="center"
        position="relative"
        gap="7px"
      >
        {!isEdit && (
          <Box
            key={0}
            display={"flex"}
            alignItems={"center"}
            sx={{
              position: "absolute",
              left: "10px",
              top: "50%",
              transform: "translate(0,-50%)",
            }}
          >
            <Checkbox
              checked={addMoreCheck}
              onChange={(e) => {
                if (e?.target?.checked) setAddMoreCheck(true);
                else setAddMoreCheck(false);
              }}
              sx={{
                "&, &.Mui-checked": {
                  color: themeType.default.themeOrange,
                },
                marginBottom: "4px",
              }}
            />
            <Typography
              sx={{ ...textClasses.normalText, paddingBottom: "4px" }}
            >
              Add More Device
            </Typography>
          </Box>
        )}
        <Button
          variant="outlined"
          sx={{
            ...buttonClasses?.lynkitBlackFill,
            minHeight: "36px",
            minWidth: "140px",
          }}
          onClick={handleCancelButton}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          sx={{
            ...buttonClasses?.lynkitOrangeFill,
            minHeight: "36px",
            minWidth: "140px",
          }}
          onClick={
            isEdit
              ? handleSubmit(handleUpdateDevice)
              : handleSubmit(handleCreateDevice)
            // : handleSubmit(handleCreateDevice, (errors) => {
            //   if(errors){
            //     window.scrollTo({ top: 0, behavior: 'smooth' });
            //   }
            // })
          }
        >
          {isEdit ? "Update" : "Create"}
        </Button>
      </Grid>
    </>
  );
};

export default SAFormData;
