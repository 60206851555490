import React, { useState } from "react";
import { useDispatch } from "react-redux";
import {
  Button,
  Typography,
  IconButton,
  Dialog,
  DialogContent,
  Grid,
  FormControl,
  OutlinedInput,
  InputLabel,
} from "@mui/material";
import theme, {
  buttonClasses,
  textClasses,
} from "../../../../static/styles/theme";
import { Cancel } from "@mui/icons-material";
import Actions, { getDisplayValue } from "../../../../utils/constants";
import { useCallback } from "react";

const DisableDeleteOrganizationModal = (props) => {
  const {
    refresh,
    action,
    testId,
    onSubmit,
    type,
    selectedTemplates,
    setSelectedTemplates,
    setEditBlock,
    setCheckedObj,
    status,
    pageLimits,
    pageNumber,
    selectStylesOverride,
  } = props;

  const size = selectedTemplates.length;
  const [showAll, setShowAll] = useState(false);
  const [comment, setComment] = useState("");

  const handleRemoveSelection = (templateId) => {
    let oldTemplates = [...selectedTemplates];
    const i = oldTemplates.map((o) => o.id).indexOf(templateId);
    if (i >= 0) {
      oldTemplates.splice(i, 1);
      setSelectedTemplates(oldTemplates);
      setCheckedObj(
        oldTemplates.map((item) => {
          return item.id;
        })
      );
    }
    if (oldTemplates.length === 0) {
      setCheckedObj([]);
      setSelectedTemplates([]);
      setEditBlock(false);
    }
  };

  const handleCloseConfirmModal = useCallback(() => {
    setEditBlock(false);
    setComment("");
  }, [setEditBlock, setComment]);

  const handleAction = (index) => {
    onSubmit(comment);
  };

  return (
    <Dialog
      data-testid={testId}
      open={action != null && selectedTemplates.length !== 0}
      onClose={handleCloseConfirmModal}
      scroll={"paper"}
      PaperProps={{ sx: { width: "100%" } }}
      maxWidth={"sm"}
    >
      <DialogContent>
        <Grid container justifyContent={"center"} alignItems="center">
          <Typography sx={{ ...textClasses.cardTitle }}>
            Are you Sure ?
          </Typography>
        </Grid>
        <Grid container justifyContent={"center"} alignItems="center">
          <Typography sx={{ ...textClasses.cardTitle }}>
            {getDisplayValue(action)} the selected{" "}
            {type === "User" ? "User(s)" : "Organisation(s)"}
          </Typography>
        </Grid>
        <Grid container sx={{ mt: 1 }} spacing={1}>
          {selectedTemplates.slice(0, showAll ? size : 5).map((st, i) => {
            return (
              <Grid item sm={4} xs={6} key={i}>
                <Grid
                  container
                  direction={"row"}
                  justifyContent="center"
                  alignItems={"center"}
                  sx={{
                    backgroundColor: theme.themeLightOrange,
                    border: `1px solid ${theme.themeOrange}`,
                    borderRadius: "12px",
                    pl: 1,
                    pr: 1,
                    minHeight: "40px",
                  }}
                >
                  <Grid item sm={10} xs={8}>
                    <Typography
                      sx={{
                        ...textClasses.normalText,
                        color: theme.themeOrange,
                        textAlign: "start",
                      }}
                    >
                      {st.name && st.name.length > 15
                        ? st.name.substring(0, 15) + "..."
                        : st.name}
                    </Typography>
                  </Grid>
                  {handleRemoveSelection && (
                    <Grid item sm={2} xs={4}>
                      <IconButton
                        sx={{ alignContent: "right" }}
                        onClick={() => handleRemoveSelection(st.id)}
                      >
                        <Cancel sx={{ color: theme.themeOrange }} />
                      </IconButton>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            );
          })}
          {size > 5 && (
            <>
              <Grid
                container
                item
                sm={4}
                xs={6}
                justifyContent="center"
                alignItems={"center"}
              >
                <Typography
                  sx={{
                    ...textClasses.boldText,
                    color: theme.themeOrange,
                    textDecoration: "underline",
                    cursor: "pointer",
                  }}
                  onClick={() => setShowAll(!showAll)}
                >
                  {showAll ? <>Collapse</> : <>View All ({size})</>}
                </Typography>
              </Grid>
            </>
          )}
        </Grid>
        {(action === Actions.BLOCK || action === Actions.ACTIVE) && (
          <Grid>
            <FormControl
              size="small"
              fullWidth
              sx={{ ...selectStylesOverride, mt: 2 }}
            >
              <InputLabel
                htmlFor="outlined-adornment-password"
                sx={{ fontSize: "12px" }}
              >
                Write Comment, if any
              </InputLabel>
              <OutlinedInput
                data-testid="inputElem"
                value={comment}
                onChange={(e) => {
                  setComment(e.target.value);
                  // setIsFeildTouched(true)
                }}
                // disabled={action === "view" ? true : false}
                // error={errorMessage.name ? true : false}
                // required
                type="text"
                sx={{
                  ...selectStylesOverride,
                  fontSize: "12px",
                  boxShadow: 1,
                  "& input::placeholder": {
                    fontSize: "14px",
                    fontWeight: "400",
                  },
                  borderRadius: "10px",
                }}
                placeholder="Write Comment, if any"
                label="Write Comment, if any"
              />
            </FormControl>
          </Grid>
        )}
        <Grid container sx={{ p: 1, mt: 3 }} justifyContent="center">
          <Button
            variant="outlined"
            sx={{
              ...buttonClasses.lynkitBlackFill,
              minWidth: "150px",
            }}
            onClick={handleCloseConfirmModal}
          >
            No, Cancel
          </Button>

          {/* {(action === "Activate") &&   <Button variant='contained' size='small' sx={{ ml: 1, ...buttonClasses.small, backgroundColor: theme.themeOrange, minWidth: "150px" }}
                    disabled={selectedTemplates.length <= 0}
                    onClick={handleUnBlockUser}
                >
                    Activate
                </Button>
                }
                {(action === "Block") &&  <Button variant='contained' size='small' sx={{ ml: 1, ...buttonClasses.small, backgroundColor: theme.themeOrange, minWidth: "150px" }}
                    disabled={selectedTemplates.length <= 0}
                    onClick={handleBlockUser}
                >
                    Block
                </Button>
                } */}

          {/* {action === Actions.DELETE && ( */}
          <Button
            variant="contained"
            size="small"
            sx={{
              ml: 1,
              ...buttonClasses?.lynkitOrangeFill,
              minWidth: "150px",
            }}
            disabled={selectedTemplates.length <= 0}
            onClick={handleAction}
          >
            Submit
          </Button>
          {/* )} */}
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default DisableDeleteOrganizationModal;
