import {
  Grid,
  Typography,
  Card,
  CardHeader,
  IconButton,
  CardContent,
  Box,
  Button,
  Tooltip,
} from "@mui/material";
import { Close, SellOutlined } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useMemo, useState } from "react";
import usersActive from "../../../../static/images/drawer/usersActive.png";
import {
  getAllDevices,
  getAllGeofences,
  getAllRoutes,
  getUserAssignedDevices,
  getUserAssignedGeofences,
  getUserAssignedRoutes,
  userDetails,
} from "../../../../redux/actions";
import {
  convertIntoGivenTimezone,
  getShortText,
} from "../../../../services/functions";
import s from "../../Devices/components/components.module.css";

const initialAssignedTableData = [
  // {
  //   name: "Geofences",
  //   type: "",
  //   count: 0,
  // },
  // {
  //   name: "Routes",
  //   type: "",
  //   count: 0,
  // },
  // {
  //   name: "Devices",
  //   type: "",
  //   count: 0,
  // },
];

const DetailCard = ({ onClose, themeType, selectedUser, userTimeZone }) => {
  // dispatcher --------
  const dispatch = useDispatch();
  const [toggleLoginTable, setToggleLoginTable] = useState(false);
  const [showModuleTable, setShowModuleTable] = useState({
    show: false,
    module: "",
  });
  const [multiAssignedTableData, setMultiAssignedTableData] = useState([]);
  const [assignedGeo, setAssignedGeo] = useState([]);
  const [assignedDevices, setAssignedDevices] = useState([]);
  const [assignedRoutes, setAssignedRoutes] = useState([]);

  // selectors ------
  const selectedUserData = useSelector((state) => state.userlist.userDetail);

  // theme --------
  const { cardClasses, textClasses } = themeType;

  const cardFields = [
    {
      key: "name",
      name: "Name",
    },
    {
      key: "email",
      name: "Email",
    },
    {
      key: "mobile",
      name: "Mobile No.",
    },
    {
      key: "userRole",
      name: "User Role",
    },
    {
      key: "orgName",
      name: "Organization",
    },
    {
      key: "isApproved",
      name: "Status",
    },
    // {
    //   key: "fewReportingAdminList",
    //   name: "Reporting Admins",
    // },
    {
      key: "addedBy",
      name: "Added By",
    },
    {
      key: "addedOn",
      name: "Added On",
    },
    {
      key: "modifiedBy",
      name: "Last Updated By",
    },
    {
      key: "modifiedOn",
      name: "Last Updated On",
    },
  ];

  const loginTableColumns = [
    {
      header: "Type",
      cell: (entry) => {
        return (
          <Typography
            sx={{
              ...textClasses.t13n,
              textAlign: "center",
              textTransform: "capitalize",
            }}
          >
            {entry?.type ?? "-"}
          </Typography>
        );
      },
    },
    {
      header: "Time",
      cell: (entry) => {
        return (
          <Typography
            sx={{
              ...textClasses.t13n,
              textAlign: "center",
              textTransform: "capitalize",
            }}
          >
            {entry?.time
              ? convertIntoGivenTimezone(
                  entry?.time,
                  userTimeZone.timeZone.offset
                )
              : "-"}
          </Typography>
        );
      },
    },
    {
      header: "Info",
      cell: (entry) => {
        return (
          <Typography
            sx={{
              ...textClasses.t13n,
              textAlign: "center",
              textTransform: "capitalize",
            }}
          >
            {entry?.info ?? "-"}
          </Typography>
        );
      },
    },
  ];

  const multiAssignedTableColumns = [
    {
      header: "Assigned",
      cell: (entry) => {
        return (
          <Typography
            sx={{
              ...textClasses.t13n,
              textAlign: "center",
              textTransform: "capitalize",
            }}
          >
            {entry?.name ?? "-"}
          </Typography>
        );
      },
    },
    {
      header: "Type",
      cell: (entry) => {
        return (
          <Typography
            sx={{
              ...textClasses.t13n,
              textAlign: "center",
              textTransform: "capitalize",
            }}
          >
            {entry?.type ?? "-"}
          </Typography>
        );
      },
    },
    {
      header: "Count",
      cell: (entry) => {
        return (
          <Typography
            onClick={
              !entry?.count
                ? null
                : () =>
                    setShowModuleTable((prev) => ({
                      show: entry?.name === prev?.module ? !prev.show : true,
                      module: entry?.name,
                    }))
            }
            sx={{
              ...textClasses.t13n,
              textAlign: "center",
              textTransform: "capitalize",
              cursor: !entry?.count ? "unset" : "pointer",
              textDecoration: !entry?.count ? "unset" : "underline",
            }}
          >
            {entry?.count ?? "-"}
          </Typography>
        );
      },
    },
  ];

  const moduleTableCols = [
    {
      header: `${showModuleTable?.module}`,
      cell: (entry) => {
        return (
          <Typography
            sx={{
              ...textClasses.t13n,
              textAlign: "left",
              textTransform: "capitalize",
            }}
          >
            {entry?.name
              ? entry?.name
              : entry?.routeName
              ? entry?.routeName
              : entry?.client_id
              ? entry?.client_id
              : entry?.clientId
              ? entry?.clientId
              : "-"}
          </Typography>
        );
      },
    },
  ];

  const assignedData = useMemo(() => {
    const tableData = [];
    if (selectedUserData && selectedUserData?.assignGeofence) {
      let temp = { name: "Geofences" };
      if (selectedUserData?.assignGeofence === "few") {
        temp.type = "Selective";
        dispatch(
          getUserAssignedGeofences(
            {
              userId: selectedUserData?._id,
              orgId: selectedUserData?.orgId,
            },
            (res) => {
              if (res && res?.length > 0) {
                setAssignedGeo(res);
                temp.count = res?.length;
              }
            }
          )
        );
      } else if (selectedUserData?.assignGeofence === "all") {
        temp.type = "All";
        dispatch(
          getAllGeofences({ selection: ["name"] }, (res) => {
            if (res && res?.data?.length > 0) {
              setAssignedGeo(res?.data);
              temp.count = res?.data?.length;
            }
          })
        );
      } else if (selectedUserData?.assignGeofence === "none") {
        setAssignedGeo([]);
        temp.type = "None";
        temp.count = 0;
      }
      tableData.push(temp);
    }

    if (selectedUserData && selectedUserData?.assignRoutes) {
      let temp = { name: "Routes" };
      if (selectedUserData?.assignRoutes === "few") {
        temp.type = "Selective";
        dispatch(
          getUserAssignedRoutes(
            {
              userId: selectedUserData?._id,
              orgId: selectedUserData?.orgId,
            },
            (res) => {
              if (res && res?.length > 0) {
                setAssignedRoutes(res);
                temp.count = res?.length;
              }
            }
          )
        );
      } else if (selectedUserData?.assignRoutes === "all") {
        temp.type = "All";
        dispatch(
          getAllRoutes({ selection: ["routeName"] }, (res) => {
            if (res && res?.length > 0) {
              setAssignedRoutes(res);
              temp.count = res?.length;
            }
          })
        );
      } else if (selectedUser?.assignRoutes === "none") {
        setAssignedRoutes([]);
        temp.type = "None";
        temp.count = 0;
      }
      tableData.push(temp);
    }

    if (selectedUserData && selectedUserData?.assignDevice) {
      let temp = { name: "Devices" };
      if (selectedUserData?.assignDevice === "few") {
        temp.type = "Selective";
        dispatch(
          getUserAssignedDevices(
            {
              userId: selectedUserData?._id,
              orgId: selectedUserData?.orgId,
            },
            (res) => {
              if (res && res?.length > 0) {
                setAssignedDevices(res);
                temp.count = res?.length;
              }
            }
          )
        );
      } else if (selectedUserData?.assignDevice === "all") {
        temp.type = "All";
        dispatch(
          getAllDevices({ filter:{user: selectedUserData?._id}, selection: ["_id", "client_id"] }, (res) => {
            if (res && res?.length > 0) {
              setAssignedDevices(res);
              temp.count = res?.length;
            }
          })
        );
      } else if (selectedUser?.assignDevice === "none") {
        setAssignedDevices([]);
        temp.type = "None";
        temp.count = 0;
      }
      tableData.push(temp);
    }
    setMultiAssignedTableData(...tableData);
    return tableData;
  }, [selectedUserData, dispatch]);

  // Effects --------
  useEffect(() => {
    if (selectedUser && selectedUser._id) {
      dispatch(userDetails({ id: selectedUser._id }));
      setAssignedGeo([]);
      setAssignedDevices([]);
      setAssignedRoutes([]);
      setShowModuleTable({
        show: false,
        module: "",
      });
      setToggleLoginTable(false);
    }
  }, [dispatch, selectedUser]);

  const getDisplayValue = (field) => {
    if (field?.key === "addedBy" || field?.key === "modifiedBy") {
      return (
        <Tooltip
          placement="top"
          title={
            selectedUser[field?.key]?.name &&
            selectedUser[field?.key]?.name?.length > 15
              ? selectedUser[field?.key]?.name
              : ""
          }
        >
          <span
            style={{
              width: "100%",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {selectedUser[field?.key]?.name ?? "-"}
          </span>
        </Tooltip>
      );
    } else if (field?.key === "modifiedOn" || field?.key === "addedOn") {
      return (
        <Tooltip
          placement="top"
          title={
            convertIntoGivenTimezone(
              selectedUserData[field?.key],
              userTimeZone.timeZone.offset || ""
            ) &&
            convertIntoGivenTimezone(
              selectedUserData[field?.key],
              userTimeZone.timeZone.offset || ""
            )?.length > 15
              ? convertIntoGivenTimezone(
                  selectedUserData[field?.key],
                  userTimeZone.timeZone.offset || ""
                )
              : ""
          }
        >
          <span
            style={{
              width: "100%",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {convertIntoGivenTimezone(
              selectedUserData[field?.key],
              userTimeZone.timeZone.offset || ""
            )}
          </span>
        </Tooltip>
      );
    } else if (field?.key === "isApproved") {
      return (
        <Box display="flex" gap={0.5} maxWidth="100%">
          <span
            style={{
              color:
                selectedUserData[field?.key] === true
                  ? themeType.default.themeGreen
                  : themeType.default.themeRed,
            }}
          >
            {selectedUserData[field?.key] === true ? "Active" : "Blocked"}
          </span>
          {selectedUserData?.remark && "("}
          <Tooltip placement="top" title={selectedUserData?.remark ?? ""}>
            <span
              style={{
                width: "100%",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {selectedUserData?.remark ? `${selectedUserData?.remark}` : ""}
            </span>
          </Tooltip>
          {selectedUserData?.remark && ")"}
        </Box>
      );
    } else if (field?.key === "userRole") {
      return (
        <Tooltip
          placement="top"
          title={
            selectedUserData?.userRole?.name &&
            selectedUserData?.userRole?.name?.length > 20
              ? selectedUserData?.userRole?.name
              : ""
          }
        >
          <span
            style={{
              width: "100%",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {selectedUserData?.userRole?.name ?? "-"}
          </span>
        </Tooltip>
      );
    } else {
      return (
        <Tooltip
          placement="top"
          title={
            selectedUserData[field?.key] &&
            selectedUserData[field?.key]?.length > 15
              ? selectedUserData[field?.key]
              : ""
          }
        >
          <span
            style={{
              width: "100%",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {selectedUserData[field?.key] ?? "-"}
          </span>
        </Tooltip>
      );
    }
  };

  return (
    <>
      <Card
        className="ppmodal"
        data-testid="genericdetailedtable"
        sx={{
          ...cardClasses.basic,
          borderRadius: "8px",
          border: `1px solid ${themeType.default.themeOrange}`,
          position: "relative",
          height: "66vh",
          overflow: "auto",
        }}
      >
        <CardHeader
          sx={{
            height: "46px",
            backgroundColor: themeType.default.themeOrange,
          }}
          title={
            <Typography
              sx={{
                ...textClasses.boldText,
                color: "white",
                fontSize: "16px",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              <img
                style={{ width: "26px", height: "24px", marginRight: "12px" }}
                src={usersActive}
                alt=""
              />
              User Details
            </Typography>
          }
          action={
            <IconButton size="small" sx={{ p: 0, mb: 1 }}>
              <Close sx={{ color: "white" }} onClick={onClose} />
            </IconButton>
          }
        />
        <CardContent
          sx={{
            height: "calc(100% - 50px)",
            overflowY: "scroll",
            marginBottom: 5,
            width: "100%",
            overflowX: "hidden",
          }}
        >
          <Grid container sx={{ mt: 0.5 }}>
            {selectedUserData &&
              cardFields?.map((field) => {
                return (
                  <>
                    <Grid item sm={6} xs={4} my={0.3}>
                      <Typography
                        sx={{
                          ...textClasses.t12n,
                          color: "#827878",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {field?.name}
                      </Typography>
                    </Grid>
                    <Grid
                      container
                      item
                      sm={6}
                      xs={8}
                      my={0.3}
                      direction="row"
                      alignItems={"center"}
                      sx={{
                        ...textClasses.t12n,
                        color: "#827878",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        textTransform:
                          field?.key === "email" ? "unset" : "capitalize",
                      }}
                    >
                      {getDisplayValue(field)}
                    </Grid>
                  </>
                );
              })}
            <Grid item sm={6} xs={4} my={0.3}>
              <Typography
                sx={{
                  ...textClasses.t12n,
                  color: "#827878",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                Active Logins
              </Typography>
            </Grid>
            <Grid
              container
              item
              sm={6}
              xs={8}
              my={0.3}
              direction="row"
              alignItems={"center"}
              sx={{
                ...textClasses.t12n,
                color: "#827878",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                textTransform: "capitalize",
              }}
            >
              {getDisplayValue("activeLogins")}
              {getDisplayValue("activeLogins") !== "-" && (
                <Button
                  onClick={() => setToggleLoginTable(!toggleLoginTable)}
                  sx={{ fontSize: "12px" }}
                >
                  {toggleLoginTable ? "Hide" : "view"}
                </Button>
              )}
            </Grid>
          </Grid>
          {toggleLoginTable && (
            <TableSection
              tableColumns={loginTableColumns}
              data={[1, 2]}
              themeType={themeType}
            />
          )}

          <TableSection
            tableColumns={multiAssignedTableColumns}
            data={assignedData}
            // data={multiAssignedTableData}
            themeType={themeType}
          />

          {showModuleTable?.show && (
            <TableSection
              themeType={themeType}
              tableColumns={moduleTableCols}
              data={
                showModuleTable?.module === "Geofences"
                  ? assignedGeo
                  : showModuleTable?.module === "Routes"
                  ? assignedRoutes
                  : showModuleTable?.module === "Devices"
                  ? assignedDevices
                  : []
              }
            />
          )}
        </CardContent>
      </Card>
    </>
  );
};

const TableSection = ({ tableColumns, data, themeType }) => {
  const { tableClasses, textClasses } = themeType;
  return (
    <Box mt={2} width="100%">
      <table
        className={s["details-table"]}
        style={{
          width: "100%",
          ...textClasses?.t12n,
        }}
      >
        <thead>
          <tr
            style={{
              background: themeType?.default?.themeOrange,
              borderTopLeftRadius: "5px",
              borderTopRightRadius: "5px",
            }}
          >
            {tableColumns?.map((column, index) => {
              return (
                <td style={{ padding: "5px" }} key={index}>
                  <Typography
                    sx={{
                      ...textClasses.t12n,
                      color: "#fff",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {column?.header}
                  </Typography>
                </td>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {data?.length > 0 ? (
            <>
              {data?.map((entry, key) => (
                <tr key={key} style={{ borderBottom: "1px solid #0000002B" }}>
                  {tableColumns?.map((column, index) => (
                    <td
                      key={index}
                      style={{ padding: "5px", textAlign: "left" }}
                    >
                      {column?.header === "#"
                        ? column?.cell(key)
                        : column?.cell(entry)}
                    </td>
                  ))}
                </tr>
              ))}
            </>
          ) : (
            <tr sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
              <td component="th" scope="row" style={{ padding: "5px" }}>
                No Data Found
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </Box>
  );
};

export default DetailCard;
