export const FETCH_START = "fetch_start";
export const FETCH_SUCCESS = "fetch_success";
export const FETCH_ERROR = "fetch_error";
export const SHOW_MESSAGE = "SHOW_MESSAGE";
export const HIDE_MESSAGE = "HIDE_MESSAGE";
export const ON_SHOW_LOADER = "ON_SHOW_LOADER";
export const ON_HIDE_LOADER = "ON_HIDE_LOADER";
export const THEME_DARK = "THEME_DARK";
export const THEME_LIGHT = "THEME_LIGHT";
export const DRAWER_OPEN = "DRAWER_OPEN";
export const DRAWER_CLOSE = "DRAWER_CLOSE";

//DEVICES
export const DEVICES_LIST = "DEVICES_LIST";
export const DEVICE_MARKERS = "DEVICE_MARKERS";
export const ULIP_DATA_LIST = "ULIP_DATA_LIST";
export const FLUSH_DEVICES_LIST = "FLUSH_DEVICES_LIST";
export const DEVICE_MODELS = "DEVICE_MODELS";
export const FLUSH_DEVICE_MODELS = "FLUSH_DEVICE_MODELS";
export const ADD_DEVICE_MODEL = "ADD_DEVICE_MODEL";
export const LIST_DEVICE_CATEGORY_NAMES = "LIST_DEVICE_CATEGORY_NAMES";
export const WEB_COMMAND_NAMES = "WEB_COMMAND_NAMES";
export const SMS_COMMAND_NAMES = "SMS_COMMAND_NAMES";
export const GET_COMMANDS = "GET_COMMANDS"
export const LIST_ALERT_SETTINGS = "LIST_ALERT_SETTINGS";
export const DEVICE_MODEL_DETAILS = "DEVICE_MODEL_DETAILS";
export const DEVICE_MODEL_SETTINGS = "DEVICE_MODEL_SETTINGS";
export const GET_PORTS = "GET_PORTS";
export const DEVICE_TEMPLATE = "DEVICE_TEMPLATE";
export const EDIT_DEVICE = "EDIT_DEVICE";
export const SET_FLAG = "SET_FLAG"; //Add device
export const FETCH_DEVICES_FLAG = "FETCH_DEVICES_FLAG";
export const DEVICE_STATUS = "DEVICE_STATUS";
export const FETCH_ADDRESS = "FETCH_ADDRESS";
export const HISTORY_DATA = "HISTORY_DATA";
export const DEVICE_MODEL_STATUS_COUNT = "DEVICE_MODEL_STATUS_COUNT";
export const CLEAR_DEVICE_TEMPLATE =
  "DEVICE_MODEL_STCLEAR_DEVICE_TEMPLATEATUS_COUNT";
export const ADMINS_LIST = "ADMINS_LIST";
export const CAMERA_TOKEN = "CAMERA_TOKEN";
export const CAMERA_ALERTS = "CAMERA_ALERTS";
export const CAMERA_PLAYBACKS = "CAMERA_PLAYBACKS";
export const CAMERA_STATUS = "CAMERA_STATUS";
export const FETCH_ALERTS_FLAG = "FETCH_ALERTS_FLAG";
export const EMPTY_LOGS_REPORT = "EMPTY_LOGS_REPORT";
export const EMPTY_TRIP_SCHEDULE_LIST = "EMPTY_TRIP_SCHEDULE_LIST";
export const BSJ_CAMERA_ALERTS = "BSJ_CAMERA_ALERTS";
export const EMPTY_BSJ_ALERTS = "EMPTY_BSJ_ALERTS";
export const EMPTY_DEVICE_DETAILS = "EMPTY_DEVICE_DETAILS";
export const BSJ_CAMERA_VIDEO_LIST = "BSJ_CAMERA_VIDEO_LIST";
export const EMPTY_BSJ_VIDEO_LIST = "EMPTY_BSJ_VIDEO_LIST";

//USERS

export const FLUSH_ROUTE_DETAILS = "FLUSH_ROUTE_DETAILS";
export const USERS_COUNT = "USERS_COUNT";
export const GEOFENCES_LIST = "GEOFENCES_LIST";
export const GEOFENCES_LIST_MAP = "GEOFENCES_LIST_MAP";
export const VIEW_GEOFENCE = "VIEW_GEOFENCE";
export const VIEW_GEOFENCE_DETAIL = "VIEW_GEOFENCE_DETAIL";
export const GEO_STATUS_COUNT = "GEO_STATUS_COUNT";
export const USERS_LIST = "USERS_LIST";
export const USER_ROLES_COUNT = "USER_ROLES_COUNT";
export const USER_DETAIL = "USER_DETAIL";
export const CURRENT_USER_DETAILS = "CURRENT_USER_DETAILS";
export const ORGANISATIONS_LIST = "ORGANISATIONS_LIST";
export const GET_ORGANISATION = "GET_ORGANISATION";
export const ORGANISATION_FEATURES = "ORGANISATION_FEATURES";
export const ORGANISATIONS_COUNT = "ORGANISATIONS_COUNT";
export const FLUSH_ORGANISATION = "FLUSH_ORGANISATION";
export const FETCH_GEO_FLAG = "FETCH_GEO_FLAG";
export const VALIDATE_TOKEN = "VALIDATE_TOKEN";
export const USER_PERMISSIONS = "USER_PERMISSIONS";
export const USER_PERMISSIONS_ROLES = "USER_PERMISSIONS_ROLES";
export const CHECK_LOGIN = "CHECK_LOGIN";
export const ADD_PERMISSIONS_ROLE = "ADD_PERMISSIONS_ROLE";
export const UPDATE_PERMISSIONS_ROLE = "UPDATE_PERMISSIONS_ROLE";

// ASSET MANAGEMENT ----------
export const GET_MAIN_ASSET_BY_DEVICE_ID = "GET_MAIN_ASSET_BY_DEVICE_ID";
export const GET_MAIN_ASSET_DATA = "GET_MAIN_ASSET_DATA";
export const GET_VENDOR_ASSET_DATA = "GET_VENDOR_ASSET_DATA";
export const SET_ADDRESS_FROM_COORDS = "SET_ADDRESS_FROM_COORDS";
export const SET_SELECTED_ITEM_ID = "SET_SELECTED_ITEM_ID";

// MONITORING ----------
export const MONITORING_LOADING = "MONITORING_LOADING";
export const MONITORING_COUNT_LOADING = "MONITORING_COUNT_LOADING";
export const ALERT_COUNT_LOADING = "ALERT_COUNT_LOADING";
export const DETAIL_LOADING = "DETAIL_LOADING";
export const GET_MONITORING_DATA = "GET_MONITORING_DATA";
export const SET_ADMIN_VALUE = "SET_ADMIN_VALUE";
export const EDIT_MONITORING_DATA = "EDIT_MONITORING_DATA";
export const GET_MAPVIEW_ALERTS = "GET_MAPVIEW_ALERTS";
export const GET_ALERTS_DATA = "GET_ALERTS_DATA";
export const GENERATE_ALERT_COMMENT = "GENERATE_ALERT_COMMENT";
export const GET_ALERT_COMMENTS = "GET_ALERT_COMMENTS";
export const RESOLVE_ALERT_COMMENTS = "RESOLVE_ALERT_COMMENTS";
export const GET_LOCATION = "GET_LOCATION";
export const SAVE_GEOFENCE_MONITORING = "SAVE_GEOFENCE_MONITORING";
export const GET_GEOFENCE_MONITORING = "GET_GEOFENCE_MONITORING";
export const GET_ADMINS_MONITORING = "GET_ADMINS_MONITORING";
export const GET_ADMINS_FROM_ACCESSKEY = "GET_ADMINS_FROM_ACCESSKEY";
export const ADD_DEVICE_TO_OBSERVATION = "ADD_DEVICE_TO_OBSERVATION";
export const REMOVE_DEVICE_FROM_OBSERVATION = "REMOVE_DEVICE_FROM_OBSERVATION";
export const GET_ASSETS_OBSERVATION = "GET_ASSETS_OBSERVATION";

// ROUTES------
export const FETCH_ROUTES = "FETCH_ROUTES";
export const ROUTE_DETAILS = "ROUTE_DETAILS";
export const ROUTE_STATUS_COUNT = "ROUTE_STATUS_COUNT";
export const SCHEDULE_DATA = "SCHEDULE_DATA";
export const CLEAR_ROUTE_DATA = "CLEAR_ROUTE_DATA";
export const FETCH_ROUTE_FLAG = "FETCH_ROUTE_FLAG";
export const EMPTY_ROUTE_SCHEDULE = "EMPTY_ROUTE_SCHEDULE";

// SETTINGS-----
export const FETCH_SETTINGS = "FETCH_SETTINGS";
export const FETCH_DYNAMIC_KEYS_FOR_TEMPLATES =
  "FETCH_DYNAMIC_KEYS_FOR_TEMPLATES";
export const ORGANISATION_IMAGES = "ORGANISATION_IMAGES";
export const CLEAR_UPLOADED_IMAGES = "CLEAR_UPLOADED_IMAGES";

//template functions
export const FETCH_TEMPLATES = "FETCH_TEMPLATES";
export const FETCH_STD_COMPS = "FETCH_STANDARD_COMPS";
export const FETCH_PRV_COMPS = "FETCH_PREVIOUS_COMPS";
export const SAVE_TEMPLATE = "SAVE_TEMPLATE";
export const FETCH_TEMPLATES_ERR = "FETCH_TEMPLATES_ERR";
export const FETCH_TEMPLATE = "FETCH_TEMPLATE";
export const DISABLE_TEMPLATES = "DISABLE_TEMPLATES";
export const PUBLISH_TEMPLATES = "PUBLISH_TEMPLATES";
export const DELETE_TEMPLATES = "DELETE_TEMPLATES";

// Create Template Action types

export const FETCH_ALL_TEMPLATE_TYPES = "FETCH_ALL_TEMPLATE_TYPES";
export const INIT_CANVAS = "INIT_CANVAS";
export const REPLACE_EDIT_CANVAS = "REPLACE_EDIT_CANVAS";
export const ADD_SECTION = "ADD_SECTION";
export const REMOVE_SECTION = "REMOVE_SECTION";
export const ADD_FIELD = "ADD_FIELD";
export const REMOVE_FIELD = "REMOVE_FIELD";
export const SET_EDIT_FIELD = "SET_EDIT_FIELD";
export const REMOVE_EDIT_FIELD = "REMOVE_EDIT_FIELD";
export const EDIT_FIELD = "EDIT_FIELD";
export const SELECT_TEMPLATE = "SELECT_TEMPLATE";
export const SELECT_SUB_TEMPLATE = "SELECT_SUB_TEMPLATE";

export const TEMPLATED_CREATEDBY = "TEMPLATED_CREATEDBY";
export const FETCH_TEMPLATES_TYPE = "FETCH_TEMPLATES_TYPE";
export const TEMPLATE_STATUS = "TEMPLATE_STATUS";
export const FETCH_STATUS_TYPE = "FETCH_STATUS_TYPE";
export const FETCH_USERS = "FETCH_USERS";
export const FETCH_STATUS_COUNT = "FETCH_STATUS_COUNT";
export const FETCH_REGEX_LIST = "FETCH_REGEX_LIST";
export const TEMPLATE_EXISTENCE = "TEMPLATE_EXISTENCE";
export const ADD_USER = "ADD_USER";

//template actions
export const TEMPLATE_EDIT = "TEMPLATE_EDIT";
export const TEMPLATE_REMOVE = "TEMPLATE_REMOVE";
export const GET_DEVICE_DETAILS = "GET_DEVICE_DETAILS";
export const LIST_DEVICE_PERMISSION = "LIST_DEVICE_PERMISSION";
export const DEVICE_PERMISSIONS = "DEVICE_PERMISSIONS";
export const ORGANIZATIONS_LIST = "ORGANIZATIONS_LIST";

//template table
export const GET_TEMPLATE_FETCH = "GET_TEMPLATE_FETCH";
export const GET_TEMPLATE_SUCC = "GET_TEMPLATE_SUCC";
export const GET_TEMPLATE_ERR = "GET_TEMPLATE_ERR";

export const GET_ITEM_MASTER = "GET_ITEM_MASTER";
export const FETCH_ITEMS_DETAIL = "FETCH_ITEMS_DETAIL";

// For ASN
export const FETCH_SUPPLIER_MASTER_TEMPLATES =
  "FETCH_SUPPLIER_MASTER_TEMPLATES";
export const FETCH_CUSTOMER_MASTER_TEMPLATES =
  "FETCH_CUSTOMER_MASTER_TEMPLATES";
export const FETCH_ITEM_MASTER_TEMPLATES = "FETCH_ITEM_MASTER_TEMPLATES";

export const SELECT_SUPPLIER_MASTER = "SELECT_SUPPLIER_MASTER";
export const SELECT_CUSTOMER_MASTER = "SELECT_CUSTOMER_MASTER";
export const SELECT_ITEM_MASTER = "SELECT_ITEM_MASTER";

// Inward Data Entry
export const SET_SELECTED_TEMPLATE = "SET_SELECTED_TEMPLATE";

//data entry
export const DATA_ENTRY_FILTER = "DATA_ENTRY_FILTER";
export const DATA_ENTRY_COMPANY = "DATA_ENTRY_COMPANY";
export const DATA_ENTRY_FLOOR = "DATA_ENTRY_FLOOR";
export const FETCH_WAREHOUSES = "FETCH_WAREHOUSES";
export const FETCH_FLOORS = "FETCH_FLOORS";
export const FETCH_MAPPING = "FETCH_MAPPING";

//routes

export const ROUTE_DISTANCE = "ROUTE_DISTANCE";
export const FLUSH_ROUTES_DATA = "FLUSH_ROUTES_DATA";
//dashboard
export const GET_BOARD = "GET_BOARD";
export const FLUSH_BOARD = "FLUSH_BOARD";
export const MODULE_NAMES = "MODULE_NAMES";
export const MODULE_LISTING = "MODULE_LISTING";
export const SAVE_BOARD = "SAVE_BOARD";
export const EDIT_BOARD = "EDIT_BOARD";
export const DELETE_BOARD = "DELETE_BOARD";
export const BOARD_STATUS = "BOARD_STATUS";
export const EMPTY_LISTS = "EMPTY_LISTS";
//trips
export const TRIP_LIST = "TRIP_LIST";
export const TRIP_MARKERS = "TRIP_MARKERS";
export const TRIP_LIST_COUNT = "TRIP_LIST_COUNT";
export const AVAILABLE_DEVICE_TYPE = "AVAILABLE_DEVICE_TYPE"; 
export const AVAILABLE_MESSAGE = "AVAILABLE_MESSAGE"; 
export const TRIP_DETAILS = "TRIP_DETAILS"; 
export const IMAGE_PATH = "IMAGE_PATH";
export const GET_IMAGE_PATH = "GET_IMAGE_PATH";
export const EMPTY_IMAGE_PATH = "EMPTY_IMAGE_PATH";
export const EMPTY_IMAGE = "EMPTY_IMAGE";
export const EMPTY_TRIP_DETAILS = "EMPTY_TRIP_DETAILS";
export const GET_TRIP_DATA_BY_TRIP_ID = "GET_TRIP_DATA_BY_TRIP_ID";
export const TRIP_GEOFENCE_WITH_ROUTE = "TRIP_GEOFENCE_WITH_ROUTE";
export const TRIP_GEOFENCE_WITHOUT_ROUTE = "TRIP_GEOFENCE_WITHOUT_ROUTE";
export const EMPTY_TRIP_ROUTE = "EMPTY_TRIP_ROUTE";
export const GET_TRIP_EVENTS = "GET_TRIP_EVENTS";
export const MAP_TRACK = "MAP_TRACK";
export const EMPTY_TRIP_DATA = "EMPTY_TRIP_DATA";
export const MARKER_DATA = "MARKER_DATA";
export const EMPTY_MARKER_EVENT = "EMPTY_MARKER_EVENT";
export const TRIP_STATUS_CHART = "TRIP_STATUS_CHART";
export const TRIP_LOCK_STATUS_CHART = "TRIP_LOCK_STATUS_CHART";
export const TRIP_ASSET_STATUS_CHART = "TRIP_ASSET_STATUS_CHART";
export const TRIP_TRACK_LIST = "TRIP_TRACK_LIST";
export const EMPTY_TRIP_TRACK_LISTING = "EMPTY_TRIP_TRACK_LISTING";
export const TRIP_ALERT_LIST = "TRIP_ALERT_LIST";
export const EMPTY_TRIP_ALERT_LISTING = "EMPTY_TRIP_ALERT_LISTING";
export const REPORT_KEYS = "REPORT_KEYS";
export const EMPTY_REPORT_KEYS = "EMPTY_REPORT_KEYS";
export const TRIP_SUMMARY = "TRIP_SUMMARY";
export const FLUSH_GEOFENCE_DETAILS = "FLUSH_GEOFENCE_DETAILS";
export const EMPTY_TRIP_LIST = "EMPTY_TRIP_LIST";
export const TRIP_PERFORMANCE_DATA = "TRIP_PERFORMANCE_DATA";
export const TRIP_LIST_FOR_ROUTE = "TRIP_LIST_FOR_ROUTE";
export const CLEAR_SETTINGS = "CLEAR_SETTINGS";
export const EMPTY_TRIP_SUMMARY = "EMPTY_TRIP_SUMMARY";
export const CLOSURE_MESSAGE = "CLOSURE_MESSAGE";
export const DEVICE_TRACK_LIST = "DEVICE_TRACK_LIST";
export const EMPTY_DEVICE_TRACK_LISTING = "EMPTY_DEVICE_TRACK_LISTING";
export const DEVICE_ALERT_LIST = "DEVICE_ALERT_LIST";
export const EMPTY_DEVICE_ALERT_LISTING = "EMPTY_DEVICE_ALERT_LISTING";
export const FLIGHTDETAILS = "FLIGHTDETAILS"

// TRIP SCHEDULE
export const TRIP_SCHEDULE_LIST_LOADING = "TRIP_SCHEDULE_LIST_LOADING";
export const TRIP_SCHEDULE_LIST = "TRIP_SCHEDULE_LIST";


// export const TRIP_LIST_COUNT = "TRIP_LIST_COUNT";

// Logs

export const LOGS_LIST = "LOGS_LIST";
export const LOGS_DOC = "LOGS_DOC";
export const ALERT_LIST_For_AdminAndSubuser = "ALERT_LIST_For_AdminAndSubuser";

// Health
export const SIMPLE_TRACK_STATUS = "SIMPLE_TRACK_STATUS";
export const FULL_TRACK_STATUS = "FULL_TRACK_STATUS";
export const PORT_WISE_STATUS = "PORT_WISE_STATUS";
export const TRACK_STATUS_KEYS = "TRACK_STATUS_KEYS";




//Lynkit.io
export const DRIVER_LISTING = "DRIVER_LISTING";

// -------FILTERS
export const SAVE_FILTERS = "SAVE_FILTERS";
export const GET_FILTERS = "GET_FILTERS";