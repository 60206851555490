import { BarChart, Close, Compare } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  Typography,
} from "@mui/material";
import CustomSelect from "../../../../components/FormUI/CustomSelect";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectStylesOverride } from "../../../../utils/util";
import SlimTable from "./helperComponents/SlimTable";
import { format, subDays } from "date-fns";
import { formattedDate, getGMTDate, getSevenDaysBefore } from "../util";
import { getRouteAnalytics, getTripId } from "../../../../redux/actions";
import history from "../../../../services/history";

// const routeAnalyticsData = {
//   categories: {
//     completed_trips: {
//       label: "Trips Completed",
//       total: "79",
//     },
//     unauthorised_unsealing_count: {
//       label: "Unauthorised Unsealing",
//       total: "0",
//     }
//   },
//   stats: {
//     average: {
//       label: "Average Value",
//       distance: "55",
//       speed: "40",
//       time: "2 Hrs",
//     },
//     highest: {
//       label: "Highest Value",
//       distance: "55",
//       speed: "40",
//       time: "2 Hrs",
//     },
//     lowest: {
//       label: "Lowest Value",
//       distance: "55",
//       speed: "40",
//       time: "2 Hrs",
//     },
//   },
//   journeyAverage: {
//     average_locking_time: {
//       label: "Average time from locking to departure",
//       total: "31 hr 30 min",
//     }
//   }
// }

const AnalyticsCard = ({
  onClose,
  selectedRoute,
  allDeviceCategoryNames,
  dropdownObj,
  setDropdownObj,
  routeAnalyticsData,
  routeJourneyOptions,
}) => {
  const dispatch = useDispatch();
  const fromDate = formattedDate(getSevenDaysBefore(getGMTDate()));
  const toDate = formattedDate(getGMTDate());

  const isDarkThemeEnabledSelector = useSelector(
    (state) => state.theme.themeDark
  );
  const themeType = isDarkThemeEnabledSelector
    ? require("../../../../static/styles/darktheme")
    : require("../../../../static/styles/theme");

  const { buttonClasses, cardClasses, textClasses } = themeType;

  const categoriesData = React.useMemo(() => {
    if (routeAnalyticsData && routeAnalyticsData?.categories) {
      console.log(routeAnalyticsData);
      let tempCols = [];
      Object.keys(routeAnalyticsData?.categories)?.map((category) => {
        tempCols?.push({
          category: routeAnalyticsData?.categories[category]?.label,
          total: routeAnalyticsData?.categories[category]?.total,
        });
      });
      return tempCols;
    } else {
      return [];
    }
  }, [routeAnalyticsData]);

  const statsData = React.useMemo(() => {
    if (routeAnalyticsData && routeAnalyticsData?.stats) {
      let tempCols = [];
      Object.keys(routeAnalyticsData?.stats)?.map((item) => {
        tempCols?.push({
          name: routeAnalyticsData?.stats[item]?.label,
          distance: routeAnalyticsData?.stats[item]?.distance,
          speed: routeAnalyticsData?.stats[item]?.speed,
          time: routeAnalyticsData?.stats[item]?.time,
          distance_tripId:
            routeAnalyticsData?.stats[item]?.distance_tripId ?? null,
          time_tripId: routeAnalyticsData?.stats[item]?.time_tripId ?? null,
          speed_tripId: routeAnalyticsData?.stats[item]?.speed_tripId ?? null,
        });
      });
      return tempCols;
    } else {
      return [];
    }
  }, [routeAnalyticsData]);

  const journeyData = React.useMemo(() => {
    if (routeAnalyticsData && routeAnalyticsData?.journeyAverage) {
      let tempCols = [];
      Object.keys(routeAnalyticsData?.journeyAverage)?.map((item) => {
        tempCols?.push({
          name: routeAnalyticsData?.journeyAverage[item]?.label,
          total: routeAnalyticsData?.journeyAverage[item]?.total,
          via: routeAnalyticsData?.journeyAverage[item]?.viaPoint || "0",
        });
      });
      return tempCols;
    } else {
      return [];
    }
  }, [routeAnalyticsData]);

  const journeyOptions = React.useMemo(() => {
    if (routeJourneyOptions && routeJourneyOptions?.length > 0) {
      let tempOptions = [];
      routeJourneyOptions?.map((op, idx) => {
        tempOptions?.push({
          label: op,
          value: op,
        });
      });
      return tempOptions;
    } else {
      return [];
    }
  }, [routeJourneyOptions]);

  const categoriesTable = [
    {
      header: "Category",
      cell: (entry, index) => {
        return entry?.category ?? "-";
      },
    },
    {
      header: "Total",
      cell: (entry, index) => {
        return entry?.total ?? "-";
      },
    },
  ];

  const statsTable = [
    {
      header: "",
      cell: (entry, index) => {
        return entry?.name ?? "-";
      },
    },
    {
      header: <Box className="d-flex-row" justifyContent="center">Distance</Box>,
      cell: (entry, index) => {
        return (
          <Box
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: 1.5,
              flexDirection: "column",
            }}
          >
            <Box>{entry?.distance ?? "-"}</Box>
            {entry?.distance_tripId &&
              entry?.distance_tripId?.length &&
              entry?.distance_tripId !== "_" && (
                <Box className="d-flex-row">
                  {`(`}
                  <Typography
                    onClick={() => {
                      dispatch(getTripId({tripId: entry?.distance_tripId}, (res) => {
                        if(res && res?.success){
                          if(res?.data?._id && res?.data?.detailsDeviceID?.deviceID){
                            history.push(`/dashboard/trips/trip-map/${res?.data._id}?id=${res?.data?.detailsDeviceID?.deviceID}`)
                          }
                        }
                      }))
                    }}
                    sx={{
                      color: themeType.default.themeOrange,
                      cursor: "pointer",
                      ...textClasses?.t12n,
                    }}
                  >
                    {entry?.distance_tripId}
                  </Typography>
                  {`)`}
                </Box>
              )}
          </Box>
        );
      },
    },
    {
      header: <Box className="d-flex-row" justifyContent="center">Speed</Box>,
      cell: (entry, index) => {
        return (
          <Box
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: 1.5,
              flexDirection: "column",
            }}
          >
            <Box>{entry?.speed ?? "-"}</Box>
            {entry?.speed_tripId &&
              entry?.speed_tripId?.length &&
              entry?.speed_tripId !== "_" && (
                <Box className="d-flex-row">
                  {`(`}
                  <Typography
                    onClick={() => {
                      dispatch(getTripId({tripId: entry?.speed_tripId}, (res) => {
                        if(res && res?.success){
                          if(res?.data?._id && res?.data?.detailsDeviceID?.deviceID){
                            history.push(`/dashboard/trips/trip-map/${res?.data._id}?id=${res?.data?.detailsDeviceID?.deviceID}`)
                          }
                        }
                      }));
                    }}
                    sx={{
                      color: themeType.default.themeOrange,
                      cursor: "pointer",
                      ...textClasses?.t12n,
                    }}
                  >
                    {entry?.speed_tripId}
                  </Typography>
                  {`)`}
                </Box>
              )}
          </Box>
        );
      },
    },
    {
      header: <Box className="d-flex-row" justifyContent="center">Time</Box>,
      cell: (entry, index) => {
        // return entry?.time ?? "-";
        return (
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              gap: 1.5,
            }}
          >
            <Box>{entry?.time ?? "-"}</Box>
            {entry?.time_tripId &&
              entry?.time_tripId?.length &&
              entry?.time_tripId !== "_" && (
                <Box className="d-flex-row">
                  {`(`}
                  <Typography
                    onClick={() => {
                      dispatch(getTripId({tripId: entry?.time_tripId}, (res) => {
                        if(res && res?.success){
                          if(res?.data?._id && res?.data?.detailsDeviceID?.deviceID){
                            history.push(`/dashboard/trips/trip-map/${res?.data._id}?id=${res?.data?.detailsDeviceID?.deviceID}`)
                          }
                        }
                      }));
                    }}
                    sx={{
                      color: themeType.default.themeOrange,
                      cursor: "pointer",
                      ...textClasses?.t12n,
                    }}
                  >
                    {entry?.time_tripId}
                  </Typography>
                  {`)`}
                </Box>
              )}
          </Box>
        );
      },
    },
  ];

  const journeyTable = [
    {
      header: "",
      cell: (entry, index) => {
        return entry?.name ?? "-";
      },
    },
    {
      header: "Total",
      cell: (entry, index) => {
        return entry?.total ?? "-";
      },
    },
  ];

  const fetchAnalyticsWithFilters = (topFilters) => {
    dispatch(
      getRouteAnalytics({
        routeId: selectedRoute?._id,
        startDate: fromDate,
        endDate: toDate,
        ...topFilters,
      })
    );
  };

  return (
    <Card
      className="ppmodal"
      data-testid="genericdetailedtable"
      sx={{
        ...cardClasses.basic,
        borderRadius: "8px",
        border: `1px solid ${themeType.default.themeOrange}`,
        position: "relative",
        height: "74vh",
        overflow: "auto",
      }}
    >
      <CardHeader
        sx={{
          height: "45px",
          backgroundColor: themeType.default.themeOrange,
        }}
        title={
          <Typography
            sx={{
              ...textClasses.boldText,
              color: "white",
              fontSize: "16px",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            <BarChart height="14px" width="16px" fill="#ffffff" /> Analytics
          </Typography>
        }
        action={
          <IconButton size="small" sx={{ p: 0, mb: 1 }}>
            <Close sx={{ color: "white" }} onClick={onClose} />
          </IconButton>
        }
      />

      <CardContent sx={{ height: "calc(100% - 50px)", overflowY: "scroll" }}>
        <Box display="flex" alignItems="center" gap={1}>
          {/* <CustomSelect
            selectStylesOverride={selectStylesOverride(
              isDarkThemeEnabledSelector
            )}
            onChange={(e, newVal) => {
              let temp = { ...dropdownObj };
              if (newVal) {
                temp.journeyPeriod = newVal?.category;
                setDropdownObj(temp);
              } else {
                temp.journeyPeriod = "";
                setDropdownObj(temp);
              }
              // TODO: add key for journey
              fetchAnalyticsWithFilters({deviceType: dropdownObj?.deviceType});
            }}
            value={dropdownObj?.journeyPeriod || null}
            label="Select Journey"
            getOptionLabel={(option) => {
              if (typeof option === "string") {
                return journeyOptions?.find(
                  (_) => _?.category == dropdownObj?.journeyPeriod
                )?.label || "";
              } else {
                return option?.label || "";
              }
            }}
            sx={{ minWidth: "140px" }}
            labelKey="label"
            options={journeyOptions || []}
          /> */}

          <CustomSelect
            selectStylesOverride={selectStylesOverride(
              isDarkThemeEnabledSelector
            )}
            onChange={(e, newVal) => {
              let temp = { ...dropdownObj };
              if (newVal) {
                temp.deviceType = newVal?.category;
                setDropdownObj(temp);
              } else {
                temp.deviceType = "";
                setDropdownObj(temp);
              }

              fetchAnalyticsWithFilters({ deviceType: temp?.deviceType });
            }}
            value={dropdownObj?.deviceType || null}
            label="Device Type"
            getOptionLabel={(option) => {
              if (typeof option === "string") {
                return (
                  allDeviceCategoryNames?.find(
                    (_) => _?.category == dropdownObj?.deviceType
                  )?.label || ""
                );
              } else {
                return option?.label || "";
              }
            }}
            labelKey="label"
            sx={{ minWidth: "140px" }}
            options={allDeviceCategoryNames && allDeviceCategoryNames}
          />

          <Button
            sx={{ ...buttonClasses.lynkitOrangeFill, visibility: "hidden" }}
            startIcon={<Compare />}
          >
            Compare
          </Button>
        </Box>

        <Box sx={{ display: "flex", alignItems: "start", gap: 1.5 }} mt={2}>
          <Typography variant="body2" sx={{ fontWeight: 700 }}>
            Route:
          </Typography>
          <Typography variant="body2">
            {selectedRoute?.routeName ?? "-"}
          </Typography>
        </Box>

        <Box
          sx={{ display: "flex", alignItems: "center", gap: 1.5 }}
          mt={1}
          mb={2}
        >
          <Typography variant="body2" sx={{ fontWeight: 700 }}>
            Statistics between:
          </Typography>
          <Typography variant="body2">{`${fromDate} - ${toDate}`}</Typography>
        </Box>

        <Box mb={2} sx={{ overflowX: "auto" }} data-testid="table-section">
          <SlimTable
            tableColumns={categoriesTable || []}
            data={categoriesData || []}
            themeType={themeType}
          />
        </Box>

        <Box mb={2} sx={{ overflowX: "auto" }} data-testid="table-section">
          <SlimTable
            tableColumns={statsTable || []}
            data={statsData || []}
            themeType={themeType}
          />
        </Box>

        <Box mb={2} sx={{ overflowX: "auto" }} data-testid="table-section">
          <SlimTable
            tableColumns={journeyTable || []}
            data={journeyData || []}
            themeType={themeType}
          />
        </Box>
      </CardContent>
    </Card>
  );
};

export default AnalyticsCard;
