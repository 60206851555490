import { USERS_LIST, ORGANIZATIONS_LIST, USER_DETAIL, USERS_COUNT } from "../types";
  
  const INIT_STATE = {
    allUsers: [],
    allOrganizations: [],
    userDetail:{},
    usersCount: {},
    };
  
  export default (state = INIT_STATE, action) => {
    switch (action.type) {
      case USERS_LIST: return {...state, allUsers: action.payload.rows, totalRows: action.payload.count}
      case "FLUSH_USERS_LIST": return {...state, allUsers: [], totalRows: 0}
      case USERS_COUNT: return { ...state, usersCount: action.payload}
      case ORGANIZATIONS_LIST: return {...state, allOrganizations: action.payload.rows, totalRows: action.payload.count}
      case USER_DETAIL: return {...state, userDetail: action.payload.rows}
      case "FLUSH_USERS_DETAILS": return {...state, userDetail: {}}
      default:
        return state;
    }
  }
  //chnage