import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const VerticalBarChart = ({
  title,
  legendPos = "top",
  labels,
  datasets,
  isLegend = false,
  isDark,
}) => {
  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: isLegend,
        position: legendPos,
        labels: {
          color: isDark ? "rgba(255, 255, 255, 0.7)" : "#000",
        },
      },
      title: {
        display: true,
        text: title,
        color: isDark ? "rgba(255, 255, 255, 0.7)" : "#000",
        font: {
          size: 16,
        },
      },
    },
    maintainAspectRatio: false,
    scales: {
      y: {
        ticks: {
          color: isDark ? "rgba(255, 255, 255, 0.7)" : "#000",
          beginAtZero: true,
        },
      },
      x: {
        ticks: {
          color: isDark ? "rgba(255, 255, 255, 0.7)" : "#000",
          beginAtZero: true,
        },
      },
    },
  };
  return <Bar options={options} data={{ labels, datasets }} />;
};

export default VerticalBarChart;
