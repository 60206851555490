import React, { useEffect } from 'react'
import { Typography, Grid } from '@mui/material'
import theme from "../../../static/styles/theme"

import GenericComponentsCollapse from '../../Components/Generic/GenericComponentsCollapse'
import { useDispatch, useSelector } from 'react-redux'
import { getAllTemplates } from '../../../redux/actions'
import { preDefineElement } from './componentList'

export default function StandardComponents({stdComponents}) {

    const dispatch = useDispatch();
    const templates = useSelector(state => state.templates.allTemplates);
    const freeTemplates = templates.filter(t => t.templateType == "freeTemplate")

    useEffect(() => {
        dispatch(getAllTemplates({type: "wms"}));
    }, [])


    return (
        <Grid container direction="column">
            <Typography variant='h6' sx={{color: theme.themeOrange}}>Standard Form Component</Typography>
            <Typography variant='caption'>
                Drag a field type to one of the sections on the left to create a custom field for this issue type.
            </Typography>
            
            {
                stdComponents && stdComponents.filter(section => ["Section Breaker", "Grid"].indexOf(section._id) < 0).map((section, i) => {
                    return (
                        <GenericComponentsCollapse
                            key={i}
                            index={i+1}
                            title={section._id}
                            components={section.elements}                            
                        />
                    )
                })
            }
            
        </Grid>
    )
}

