/* eslint-disable */
// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax
import { Google } from "@mui/icons-material";
import { Box, Button, IconButton, Typography, Grid, Tabs, Tab, FormControl } from "@mui/material";
import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import CloseIcon from '@mui/icons-material/Close';
import s from "../../Devices/components/components.module.css";
import start from "../../../../assets/infoWindow/start1.png"
import via from "../../../../assets/infoWindow/Via.png"
import end from "../../../../assets/infoWindow/End.png"
import editicon from "../../../../assets/infoWindow/editicon.svg"

import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline';
import { CustomInput } from "../../../../components/FormUI";
import { selectStylesOverride } from "../../../../utils/util";
import ThreeDotsLoader from "../../../Components/ThreeDots/ThreeDotsLoader";
import { useDebounce } from "../../../../utils/hooks";
import {
  getGeofenceDetails,
  flushGeofenceDetails,
  updateGeofenceDetails,
  getTripDataByTripId
} from "../../../../redux/actions";
import CustomSubmitBtn from "../../../../components/FormUI/CustomSubmitBtn";
import { showToast } from "../../../../services/functions";
import pin from "../../../../../src/assets/img/trackingicons/pin.png";

const EditGeofenceDrawer = ({ open, themeType, history, geoEditDrawer, setGeoEditDrawer, geofencePlotArray, myMap, tripParam, idParam, setTripDetailsLoader, geofenceRenderer, setGeofencePlotArray }) => {
  const searchInputRef = useRef(null);
  const { inputClasses, textClasses, buttonClasses } = themeType;
  const [tabIndex, setTabIndex] = useState(0)
  const [geoDetailLoader, setGeoDetailLoader] = useState(false)
  const [geofenceLayer, setGeofenceLayer] = useState(null);
  const [initialGeofenceState, setInitialGeofenceState] = useState(null);
  const isDarkThemeEnabledSelector = useSelector((state) => state.theme.themeDark);
  const [drawnOverlays, setDrawnOverlays] = useState([]);
  const currentUser = useSelector((state) => state.auth.currentUserDetail);
  const [currentEditablePolygon, setCurrentEditablePolygon] = useState(null);
  const [currentEditableCircle, setCurrentEditableCircle] = useState(null);
  const [updateLoader, setUpdateLoader] = useState(false)
  const [btnToggle, setBtnToggle] = useState(null)
  const [tab, setTab] = useState(false)
  const [geofenceListCopy, setGeoCopy] = useState([]);
  const [geoIndex, setGeoIndex] = useState(null)
  // var drawingManager;
  const [drawingManager, setDrawingManager] = useState(null);
  const [addressFetcher, setAddressFetcher] = useState(false)
  const [originalPolygonCoordinates, setOriginalPolygonCoordinates] = useState(null);
  const [originalCircleCenter, setOriginalCircleCenter] = useState(null);
  const [originalCircleRadius, setOriginalCircleRadius] = useState(null);
  const [markersArray, setMarkersArray] = useState([]);
  const [openAccordion, setOpenAccordion] = useState(null);
  const [geofencePayload, setGeofencePayload] = useState({
    name: '',
    address: '',
    latitude: '',
    longitude: '',
    radius: '',
    id: '',
    polygonType: '',
    geofenceCoordinates: {},
    userDetail: {}
  })
  const dispatch = useDispatch();

  useEffect(() => {
    if (geofenceListCopy.length === 0) {
      let filterArray = geofencePlotArray.filter(item => item?.features[0]?.properties.status !== "EDIT");
      setGeoCopy(JSON.parse(JSON.stringify(filterArray)));
    }
  }, [geofencePlotArray]);
  
  const getTabStyles = (tIndex) => {
    return {
      backgroundColor:
        tabIndex == tIndex ? themeType.default.themeOrange : "transparent",
      color: tabIndex == tIndex ? "white" : themeType.default.themeOrange,
      border: "1px solid",
      // boxShadow: "0px 2px 6px #E8E8E8",
      borderColor: themeType.default.themeOrange,
      p: 0,
      pt: "6px",
      pb: "6px",
      fontSize: "14px",
      lineHeight: "19px",
      fontWeight: "400",
      textTransform: "none",
    };
  };

  const getRouteType = (item) => {
    let oldiconPath = item?.properties?.icon
    const iconPath = `https://lynktrac.io/lynktracV2/trip-icons`;

    const iconFile = oldiconPath ? oldiconPath?.split("/") : "";
    let iconImg = iconFile && Array.isArray(iconFile) ? iconFile[iconFile?.length - 1] : null;
    return `${iconPath}/${iconImg}`

  }

  useEffect(() => {
    if (geofencePayload?.id) {
      const interval = setInterval(() => {
        if (searchInputRef.current) {
          const autocomplete = new window.google.maps.places.Autocomplete(searchInputRef.current);

          google.maps.event.addListener(autocomplete, 'place_changed', () => {
            onChangeAddress(autocomplete);
          });

          clearInterval(interval);
        }
      }, 600);

      return () => clearInterval(interval);
    }


  }, [geofencePayload.id]);

  function onChangeAddress(autocomplete) {
    var place = autocomplete.getPlace();
    if (place.geometry && place.geometry.location) {
      var selectedLocation = place.geometry.location;
      setGeofencePayload(prevState => ({
        ...prevState,
        latitude: selectedLocation.lat(),
        longitude: selectedLocation.lng()
      }));
      setAddressFetcher(true)
      addMarker(selectedLocation.lat(), selectedLocation.lng());
    }
  }

  const addMarker = (lat, lng) => {
    if (myMap) {
      myMap.setCenter({ lat: Number(lat), lng: Number(lng) });
      myMap.panTo({ lat: Number(lat), lng: Number(lng) });
      myMap.setZoom(12);
      if (markersArray) {
        for (let i = 0; i < markersArray.length; i++) {
          markersArray[i].setMap(null);
        }
        markersArray.length = 0;
      }

      let marker = new google.maps.Marker({
        map: myMap,
        position: { lat: Number(lat), lng: Number(lng) },
        icon: pin,
      });
      markersArray.push(marker);
    }
  };

  const getGeoDetails = (item, index) => {
    setOpenAccordion(openAccordion === index ? null : index);
    setBtnToggle(null)
    setGeoIndex(index)
    if (geofenceLayer) {
      resetGeofenceToInitialState();
    }
    dispatch(flushGeofenceDetails())
    setGeoDetailLoader(true)
    let obj = {
      id: item?.features[0]?.properties?._id,
      selection: [
        "name",
        "description",
        "geofence",
        "centerCoordinates",
        "radius",
        "polygonType",
        "status",
        "addedOn",
        "addedBy",
        "address",
        "latitude",
        "longitude",
        "user",
      ],
    };
    dispatch(getGeofenceDetails(obj, (res) => {
      if (res?.success) {
        let temp = { ...geofencePayload }
        temp.name = res?.data?.name
        temp.address = res?.data?.name
        temp.latitude = res?.data?.latitude
        temp.longitude = res?.data?.longitude
        temp.radius = res?.data?.radius || 10
        temp.id = res?.data?._id
        temp.polygonType = res?.data?.polygonType
        temp.geofenceCoordinates = res?.data?.geofence
        temp.userDetail = res?.data?.user
        setGeoDetailLoader(false)
        setGeofencePayload(temp)
        setTabIndex(res?.data?.polygonType === 'Polygon' ? 0 : 1)
        getTabStyles(res?.data?.polygonType === 'Polygon' ? 0 : 1)
        setTab(false)
        makeGeofenceEditable(res);


        myMap.panTo({
          lat: Number(item?.properties?.centerCoordinates[0][0][1]),
          lng: Number(item?.properties?.centerCoordinates[0][0][0]),
        });
      }
      else {
        setGeoDetailLoader(false)
        // setGeofencePayload(
        //   {
        //     name: '',
        //     address: '',
        //     latitude: '',
        //     longitude: '',
        //     radius: '',
        //     id: '',
        //     polygonType: '',
        //     geofenceCoordinates: {},
        //     userDetail: {}
        //   })
      }
    }));
  }

  const toggleTab = (val) => {
    setTabIndex(val)
  }

  const updateGeofence = () => {
    setUpdateLoader(true)
    let finalPayload = {
      "id": geofencePayload?.id,
      "geofence": geofencePayload?.geofenceCoordinates,
      "polygonType": geofencePayload?.polygonType,
      "radius": Number(geofencePayload?.radius) || 10,
      "address": geofencePayload?.address,
      "name": geofencePayload?.name,
    }

    if (geofencePayload.polygonType !== "Polygon") {
      finalPayload.latitude = geofencePayload.geofenceCoordinates.coordinates[0][0][1].toString();
      finalPayload.longitude = geofencePayload.geofenceCoordinates.coordinates[0][0][0].toString();
    }


    if (currentUser?.adminSpecificPermissions) {
      finalPayload.user = geofencePayload?.userDetail;
    }

    dispatch(updateGeofenceDetails(finalPayload, (res) => {
      if (res && res?.success) {
        setUpdateLoader(false)
        setTripDetailsLoader(true)
        setGeoIndex(null)
        setOpenAccordion(null)
        let body = {
          trip_id: tripParam,
          deviceId: idParam
        }
        dispatch(getTripDataByTripId(body, (res) => {
          setTripDetailsLoader(false)
        }));

        setGeofencePayload({
          name: '',
          address: '',
          latitude: '',
          longitude: '',
          radius: '',
          id: '',
          polygonType: '',
          geofenceCoordinates: {},
          userDetail: {}
        })


        if (drawingManager) {
          drawingManager.setOptions({
            drawingMode: null,
          });
          setDrawingManager(null)
        }

        resetGeofenceToInitialState();
        if (currentEditablePolygon) {
          currentEditablePolygon.setMap(null);
        }
        if (currentEditableCircle) {
          currentEditableCircle.setMap(null);
        }
        setCurrentEditablePolygon(null);
        setOriginalPolygonCoordinates(null);
        setCurrentEditableCircle(null);
        setOriginalCircleCenter(null);
        setOriginalCircleRadius(null);
        showToast(res?.message, false)

        if (markersArray) {
          for (let i = 0; i < markersArray.length; i++) {
            markersArray[i].setMap(null);
          }
          markersArray.length = 0;
        }
        setGeoCopy([]);
      }
      else {
        setUpdateLoader(false)
        showToast(res?.message, true)
      }
    })
    );



  }

  const resetGeofenceToInitialState = () => {
    if (geofenceLayer) {
      geofenceLayer.setMap(null);
    }

    if (initialGeofenceState) {
      const geoJsonLayer = new google.maps.Data();
      geoJsonLayer.addGeoJson({
        type: "FeatureCollection",
        features: [initialGeofenceState]
      });

      geoJsonLayer.setStyle({
        fillColor: null,
        strokeColor: null,
        strokeOpacity: 0,
        strokeWeight: 0,
        fillOpacity: 0,
      });

      geoJsonLayer.setMap(myMap);
      setGeofenceLayer(geoJsonLayer);
    }
  };

  function debounce(func, delay) {
    let timeoutId;
    return function (...args) {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        func.apply(this, args);
      }, delay);
    };
  }

  const makeGeofenceEditable = (res) => {
    if (currentEditablePolygon) {
      currentEditablePolygon.setMap(null);
    }
    if (currentEditableCircle) {
      currentEditableCircle.setMap(null);
    }
    setCurrentEditablePolygon(null);
    setOriginalPolygonCoordinates(null);
    setCurrentEditableCircle(null);
    setOriginalCircleCenter(null);
    setOriginalCircleRadius(null);

    if (res?.data?.polygonType === 'Polygon') {
      let originalCoordinates = res?.data?.geofence?.coordinates;
      const convertedCoordinates = originalCoordinates[0].map((point) => ({
        lat: point[1],
        lng: point[0],
      }));

      setOriginalPolygonCoordinates(convertedCoordinates);

      const newPolygon = new google.maps.Polygon({
        paths: convertedCoordinates,
        strokeColor: "#00008B",
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: "#6082B6",
        fillOpacity: 0.45,
        editable: true,
        draggable: true,
        map: myMap,
      });

      setCurrentEditablePolygon(newPolygon);

      const debouncedDragEnd = debounce(() => {
        const bounds = new google.maps.LatLngBounds();
        const coordinates = [];
        newPolygon.getPath().getArray().forEach((coord) => {
          coordinates.push([coord.lng(), coord.lat()]);
          bounds.extend(coord);
        });
        if (coordinates.length > 0) {
          coordinates.push(coordinates[0]);
        }
        const formattedCoordinates = [coordinates];
        setGeofencePayload(prevState => ({
          ...prevState,
          geofenceCoordinates: {
            coordinates: formattedCoordinates,
            type: 'Polygon'
          }
        }));

      }, 100);

      window.google.maps.event.addListener(newPolygon, "dragend", () => {
        debouncedDragEnd();
      });

      const debouncedSetAt = debounce(() => {
        const bounds = new google.maps.LatLngBounds();
        const coordinates = [];
        newPolygon.getPath().getArray().forEach((coord) => {
          coordinates.push([coord.lng(), coord.lat()]);
          bounds.extend(coord);
        });

        if (coordinates.length > 0) {
          coordinates.push(coordinates[0]);
        }
        const formattedCoordinates = [coordinates];
        setGeofencePayload(prevState => ({
          ...prevState,
          geofenceCoordinates: {
            coordinates: formattedCoordinates,
            type: 'Polygon'
          }
        }));


      }, 100);

      window.google.maps.event.addListener(newPolygon.getPath(), "set_at", () => {
        debouncedSetAt();
      }
      );

    }
    else {
      let lastCenterCoordinates = null;
      const centerForCircle = {
        lat: Number(res?.data?.latitude),
        lng: Number(res?.data?.longitude),
      };

      setOriginalCircleCenter(centerForCircle);
      setOriginalCircleRadius(Number(res?.data?.radius));

      const newCircle = new google.maps.Circle({
        strokeColor: "#00008B",
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: "#6082B6",
        fillOpacity: 0.45,
        editable: true,
        draggable: true,
        center: centerForCircle,
        radius: Number(res?.data?.radius),
        map: myMap,
      });




      setCurrentEditableCircle(newCircle);
      google.maps.event.addListener(newCircle, "radius_changed", () => {
        const updatedRadius = newCircle.getRadius();
        if (updatedRadius < 10 || updatedRadius > 2000) {
          showToast("Radius should be between 10m - 2000m.", true);

          // Set radius back to a valid range
          const correctedRadius = Math.max(10, Math.min(2000, updatedRadius));
          newCircle.setRadius(correctedRadius);

          setGeofencePayload(prevState => ({
            ...prevState,
            radius: Math.ceil(correctedRadius)
          }));
        } else {
          setGeofencePayload(prevState => ({
            ...prevState,
            radius: Math.ceil(updatedRadius)
          }));
        }
      });

      //   setGeofencePayload(prevState => ({
      //     ...prevState,
      //     radius: Math.ceil(updatedRadius)
      //   }));
      // });

      google.maps.event.addListener(newCircle, "center_changed", () => {
        const updatedCenter = newCircle.getCenter();
        lastCenterCoordinates = [updatedCenter.lng(), updatedCenter.lat()];
        const formattedCoordinates = [lastCenterCoordinates];
        setGeofencePayload(prevState => ({
          ...prevState,
          geofenceCoordinates: {
            coordinates: [formattedCoordinates],
            type: 'Circular'
          }
        }));
      });
    }
  };

  const debouncedLati = useDebounce(geofencePayload?.latitude, 1000);
  const debouncedLongi = useDebounce(geofencePayload?.longitude, 1000);


  useEffect(() => {
    if (debouncedLati && debouncedLongi && addressFetcher) {
      setGeofencePayload(prevState => ({
        ...prevState,
        address: 'fetching..'
      }));
      const latlng = new google.maps.LatLng(debouncedLati, debouncedLongi);
      const geocoder = new google.maps.Geocoder();
      geocoder.geocode({ location: latlng }, function (results, status) {
        if (status === google.maps.GeocoderStatus.OK) {
          if (results[0]) {
            setGeofencePayload(prevState => ({
              ...prevState,
              address: results[0].formatted_address
            }));
          } else {
            showToast("No address found for these coordinates.", true);
          }
        } else {
          // showToast("Geocoding failed due to: " + status, true);
        }
      });
    } else {
      // console.log("Please enter both latitude and longitude.")
    }
  }, [debouncedLati, debouncedLongi, addressFetcher]);

  const clearGeofence = () => {
    if (geoIndex !== null) {
      resetGeofenceToInitialState();
      if (currentEditablePolygon) {
        currentEditablePolygon.setMap(null);
      }
      if (currentEditableCircle) {
        currentEditableCircle.setMap(null);
      }
      if (markersArray) {
        for (let i = 0; i < markersArray.length; i++) {
          markersArray[i].setMap(null);
        }
        markersArray.length = 0;
      }
      setCurrentEditablePolygon(null);
      setOriginalPolygonCoordinates(null);
      setCurrentEditableCircle(null);
      setOriginalCircleCenter(null);
      setOriginalCircleRadius(null);

      let temp = [...geofencePlotArray]
      temp.splice(geoIndex, 1)
      geofenceRenderer(temp)
      setGeofencePlotArray(temp)
      setGeoIndex(null)
      setTabIndex(0)
      setTab(true)
      setBtnToggle('create')
      let copy = { ...geofencePayload }

      setGeofencePayload({
        name: copy?.name,
        address: '',
        latitude: '',
        longitude: '',
        radius: '',
        id: copy?.id,
        polygonType: 'Polygon',
        geofenceCoordinates: {},
        userDetail: copy?.userDetail
      })
      setTimeout(() => {
        startDrawing()
      }, 500);
    }
  }

  const startDrawing = () => {
    if (drawnOverlays.length > 0) {
      for (var i = 0; i < drawnOverlays.length; i++) {
        drawnOverlays[i].setMap(null);
      }
      setDrawnOverlays([]);
    }

    const newDrawingManager = new google.maps.drawing.DrawingManager({
      drawingMode: google.maps.drawing.OverlayType.POLYGON,
      drawingControl: false,
      drawingControlOptions: {
        position: google.maps.ControlPosition.TOP_CENTER,
        drawingModes: [google.maps.drawing.OverlayType.POLYGON],
      },
    });

    newDrawingManager.setMap(myMap);
    setDrawingManager(newDrawingManager);

    const toggleCircleButton = document.getElementById("circleTab");
    const togglePolygonButton = document.getElementById("polygonTab");
    const clearBtn = document.getElementById("clearGeofenceFromMap");
    const cancelBtn = document.getElementById("cancelBtn");

    if (toggleCircleButton) {
      toggleCircleButton.addEventListener("click", () => {
        newDrawingManager.setOptions({
          drawingMode: google.maps.drawing.OverlayType.CIRCLE,
        });
        for (var i = 0; i < drawnOverlays.length; i++) {
          drawnOverlays[i].setMap(null);
        }
        setDrawnOverlays([]);
        setGeofencePayload(prevState => ({
          ...prevState,
          polygonType: 'Circular',
          geofenceCoordinates: {}
        }));
      });
    }
    if (togglePolygonButton) {
      togglePolygonButton.addEventListener("click", () => {
        newDrawingManager.setOptions({
          drawingMode: google.maps.drawing.OverlayType.POLYGON,
        });
        for (var i = 0; i < drawnOverlays.length; i++) {
          drawnOverlays[i].setMap(null);
        }
        setGeofencePayload(prevState => ({
          ...prevState,
          polygonType: 'Polygon',
          geofenceCoordinates: {}
        }));
        setDrawnOverlays([]);
      });
    }

    if (clearBtn) {
      clearBtn.addEventListener("click", () => {
        for (var i = 0; i < drawnOverlays.length; i++) {
          drawnOverlays[i].setMap(null);
        }
        if (markersArray) {
          for (let i = 0; i < markersArray.length; i++) {
            markersArray[i].setMap(null);
          }
          markersArray.length = 0;
        }
        let copy = { ...geofencePayload }
        setGeofencePayload({
          name: '',
          address: '',
          latitude: '',
          longitude: '',
          radius: '',
          id: copy?.id,
          polygonType: 'Polygon',
          geofenceCoordinates: {},
          userDetail: copy?.userDetail
        })
        setDrawnOverlays([]);
      });
    }
    if (cancelBtn) {
      cancelBtn.addEventListener("click", () => {
        for (var i = 0; i < drawnOverlays.length; i++) {
          drawnOverlays[i].setMap(null);
        }
        setDrawnOverlays([]);
        if (markersArray) {
          for (let i = 0; i < markersArray.length; i++) {
            markersArray[i].setMap(null);
          }
          markersArray.length = 0;
        }
        newDrawingManager.setOptions({
          drawingMode: null,
        });
        setGeofencePayload(prevState => ({
          ...prevState,
          polygonType: '',
          geofenceCoordinates: {}
        }));
      });
    }

    google.maps.event.addListener(newDrawingManager, "overlaycomplete", (event) => {
      if (event.type === google.maps.drawing.OverlayType.POLYGON) {
        for (var i = 0; i < drawnOverlays.length; i++) {
          drawnOverlays[i].setMap(null);
        }
        setAddressFetcher(true)
        setDrawnOverlays([]);
        const polygon = event.overlay;
        polygon.setOptions({
          strokeColor: "#00008B",
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: "#6082B6",
          fillOpacity: 0.45,
        });
        const paths = polygon.getPaths();
        const coordinates = [];
        const bounds = new google.maps.LatLngBounds();

        paths.forEach((path) => {
          path.getArray().forEach((coord) => {
            coordinates.push([coord.lng(), coord.lat()]);
            bounds.extend(coord);
          });
        });

        const center = bounds.getCenter();
        if (coordinates.length > 0) {
          coordinates.push(coordinates[0]);
        }
        const formattedCoordinates = [coordinates];
        drawnOverlays.push(polygon);
        setDrawnOverlays(drawnOverlays);
        setGeofencePayload(prevState => ({
          ...prevState,
          latitude: center.lat(),
          longitude: center.lng(),
          geofenceCoordinates: {
            coordinates: formattedCoordinates,
            type: 'Polygon'
          }
        }));
      } else if (event.type === google.maps.drawing.OverlayType.CIRCLE) {
        for (var i = 0; i < drawnOverlays.length; i++) {
          drawnOverlays[i].setMap(null);
        }
        setAddressFetcher(true)
        setDrawnOverlays([]);
        const circle = event.overlay;
        circle.setOptions({
          strokeColor: "#00008B",
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: "#6082B6",
          fillOpacity: 0.45,
        });
        const center = circle.getCenter();
        const radius = circle.getRadius();
        const coordinates = [];

        if (radius < 10 || radius > 2000) {
          showToast("Radius should be between 10m - 2000m.", true);
          circle.setMap(null);
        } else {
          coordinates.push([center.lng(), center.lat()]);
          drawnOverlays.push(circle);
          setDrawnOverlays(drawnOverlays);
          const formattedCoordinates = [coordinates];
          setGeofencePayload(prevState => ({
            ...prevState,
            latitude: center.lat(),
            longitude: center.lng(),
            radius: Math.ceil(radius),
            geofenceCoordinates: {
              coordinates: formattedCoordinates,
              type: 'Circular'
            }
          }));
        }
      }
    });
  };

  const closeAccordian = () => {
    setGeoIndex(null)
    if (currentEditablePolygon) {
      currentEditablePolygon.setMap(null);
    }
    if (currentEditableCircle) {
      currentEditableCircle.setMap(null);
    }
    setCurrentEditablePolygon(null);
    setOriginalPolygonCoordinates(null);
    setCurrentEditableCircle(null);
    setOriginalCircleCenter(null);
    setOriginalCircleRadius(null);

    setOpenAccordion(null)
    dispatch(flushGeofenceDetails())
    setGeofencePayload({
      name: '',
      address: '',
      latitude: '',
      longitude: '',
      radius: '',
      id: '',
      polygonType: '',
      geofenceCoordinates: {},
      userDetail: {}
    })
  }

  return (

    <Box
      sx={{
        height: "100%",
        width: open ? 430 : 0,
        opacity: open ? 1 : 0,
        transition: "transform 0.6s ease-in-out, opacity 0.6s ease-in-out",
        overflowY: "scroll"
      }}
      data-testid="trip-map-side-panel"
    >
      {open && (
        <>
          <Box
            mb={2}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              position: "sticky",
              width: "100%",
              top: "-1px",
              background: (theme) => theme.palette.background.paper,
              zIndex: 300,
              borderBottom: "1px solid #D9D9D9",
              paddingY: "15.3px",
            }}
          >
            <Typography
              sx={{
                fontSize: "17px",
                fontWeight: "700",
                width: "100%",
                marginLeft: "10px"
              }}
              data-testid='editGeofence'
            >
              Edit Geofence in Trip
            </Typography>
            <CloseIcon sx={{ width: '20px', cursor: 'pointer', marginRight: '10px', color: '#696969' }} onClick={() => setGeoEditDrawer(false)} />
          </Box>

          <Box>
            <div
              style={{
                width: "95%",
                height: '480px',
                // maxHeight: geofencePayload?.id === '' ? '300px' : '170px',
                // overflowY: 'auto',
                marginLeft: '10px',
                marginRight: '10px',
              }}>
              {geofenceListCopy && geofenceListCopy?.length ?
                <table
                  style={{
                    width: "100%",
                    height: openAccordion && !geoDetailLoader ? '500px' : null,
                    ...textClasses?.t12n,
                    borderLeft: '1px solid  rgba(0, 0, 0, 0.17)',
                    borderRight: '1px solid  rgba(0, 0, 0, 0.17)',
                  }}
                >
                  <thead style={{ position: 'sticky', top: 0, zIndex: 1 }}>

                    <tr
                      style={{
                        background: themeType?.default?.themeOrange,
                        borderTopLeftRadius: "5px",
                        borderTopRightRadius: "5px",
                      }}
                    >
                      <td style={{ padding: "10px" }}>
                        <Typography
                          sx={{
                            ...textClasses.t12n,
                            color: "#000",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",

                          }}
                        >
                          Name
                        </Typography>
                      </td>
                      <td style={{ padding: "10px" }}>
                        <Typography
                          sx={{
                            ...textClasses.t12n,
                            color: "#000",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          Geo Type
                        </Typography>
                      </td>
                      <td style={{ padding: "10px" }}>
                        <Typography
                          sx={{
                            ...textClasses.t12n,
                            color: "#000",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            textAlign: "center"
                          }}
                        >
                          Action
                        </Typography>
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    {geofenceListCopy && geofenceListCopy?.map((item, index) =>
                    (
                      <>
                        <tr style={{ border: 'none', borderBottom: isDarkThemeEnabledSelector ? '1px solid  rgb(243, 215, 192)' : '1px solid #0000002B' }}>
                          <td style={{ padding: "3px", textAlign: "left", paddingLeft: '6px' }}>{item?.features[0]?.properties?.name || '-'}</td>
                          <td style={{ padding: "3px", textAlign: "center", display: 'flex', alignItems: 'center' }}>
                            <>
                              <img style={{ width: '14px', height: '14px', marginRight: '7px', position: 'relative', top: '3px' }} src={getRouteType(item) || '-'} alt='' />
                              <Typography sx={{ fontSize: '12px', position: 'relative', top: '3px' }}>{item?.features[0].properties?.title || '-'}</Typography>
                            </>
                          </td>
                          <td style={{ padding: "3px", textAlign: "center" }}>
                            <IconButton sx={{ fontSize: '12px', }} disabled={geoIndex === index ? true : false} onClick={() => { geoIndex === index ? null : getGeoDetails(item, index) }}>
                              <img src={editicon} />
                            </IconButton>
                          </td>
                        </tr>
                        {openAccordion === index && (
                          <tr>
                            <td colSpan="3" style={{ textAlign: "left" }}>
                              <div className={`accordion-content ${openAccordion === index ? 'open' : ''}`}>

                                {geoDetailLoader ?
                                  <div className="spinner" style={{ margin: '20px auto 20px' }}>
                                    <div className="bounce1"></div>
                                    <div className="bounce2"></div>
                                    <div className="bounce3"></div>
                                  </div>
                                  :
                                  geofencePayload && geofencePayload.id ?
                                    <>
                                      <Box
                                        width="95%"
                                        sx={{
                                          marginLeft: '5px',
                                          marginRight: '5px',
                                          height: '30px',
                                          marginTop: '7px'
                                        }}
                                      >
                                        <Tabs
                                          value={tabIndex}
                                          variant="fullWidth"
                                          sx={{
                                            "& .MuiTabs-indicator": { display: "none" },
                                            "& .MuiTab-root.Mui-selected": { color: "#FFFFFF" },
                                            minHeight: '30px',
                                            height: '30px'
                                          }}
                                          onChange={(e, val) => { toggleTab(val) }}
                                        >
                                          <Tab
                                            label="Polygon"
                                            disabled={tab ? null : tabIndex === 0 ? false : true}
                                            id='polygonTab'
                                            sx={{
                                              ...getTabStyles(0),
                                              height: '30px',
                                              minHeight: '30px',
                                            }}
                                          />
                                          <Tab
                                            label="Circular"
                                            id='circleTab'
                                            disabled={tab ? null : tabIndex === 1 ? false : true}
                                            sx={{
                                              ...getTabStyles(1),
                                              marginLeft: "0px",
                                              height: '30px',
                                              minHeight: '30px'
                                            }}
                                          />
                                        </Tabs>
                                      </Box>

                                      <Grid>
                                        <Grid item={true} md={12} pl={2} pr={2} pt={2}>
                                          <FormControl
                                            variant="outlined"
                                            size="small"
                                            fullWidth
                                            sx={{
                                              ...selectStylesOverride(isDarkThemeEnabledSelector),
                                            }}
                                          >
                                            <CustomInput
                                              placeholder="Name"
                                              required
                                              name='name'
                                              selectStylesOverride={selectStylesOverride}
                                              inputClasses={inputClasses}
                                              value={geofencePayload?.name || ""}
                                              onChange={(e) => {
                                                let temp = { ...geofencePayload }
                                                temp.name = e.target.value
                                                setGeofencePayload(temp)
                                              }}
                                            />
                                          </FormControl>
                                        </Grid>

                                        <Grid
                                          item={true}
                                          md={12}
                                          pl={2}
                                          pr={2}
                                          pt={2}
                                          position="relative"
                                        >
                                          <input
                                            type="text"
                                            placeholder="Search Places"
                                            ref={searchInputRef}
                                            data-testid="locations"
                                            name='address'
                                            className="mapInput"
                                            style={{
                                              background: "transparent",
                                              borderColor: isDarkThemeEnabledSelector
                                                ? "rgb(81, 81, 81)"
                                                : "#c6c6c6",
                                            }}
                                            id="searchTextFieldTrips"
                                            value={geofencePayload?.address || ''}
                                            onChange={(e) => {
                                              let temp = { ...geofencePayload }
                                              temp.address = e.target.value
                                              setGeofencePayload(temp)
                                            }}
                                          />
                                          <Box
                                            className='googleBox' style={{ background: isDarkThemeEnabledSelector ? '#000' : '#fff' }}
                                          >
                                            <Google
                                              fontSize="15px"
                                              sx={{
                                                color: isDarkThemeEnabledSelector ? "#fff" : "#696969",
                                              }}
                                            />
                                          </Box>
                                        </Grid>

                                        <Grid item={true} md={12} pl={2} pr={2} pt={2}>
                                          <FormControl
                                            variant="outlined"
                                            size="small"
                                            fullWidth
                                            sx={{ ...selectStylesOverride(isDarkThemeEnabledSelector) }}
                                          >

                                            <CustomInput
                                              placeholder="Latitude"
                                              required
                                              id="latitude"
                                              name='latitude'
                                              selectStylesOverride={selectStylesOverride}
                                              inputClasses={inputClasses}
                                              type={"number"}
                                              value={geofencePayload?.latitude || ""}
                                              onChange={(e) => {
                                                let temp = { ...geofencePayload }
                                                temp.latitude = e.target.value
                                                setGeofencePayload(temp)
                                                setAddressFetcher(true)
                                              }}
                                            />

                                          </FormControl>
                                        </Grid>

                                        <Grid item={true} md={12} pl={2} pr={2} pt={2}>
                                          <FormControl
                                            variant="outlined"
                                            size="small"
                                            fullWidth
                                            sx={{ ...selectStylesOverride(isDarkThemeEnabledSelector) }}
                                          >
                                            <CustomInput
                                              placeholder="Longitude"
                                              required={true}
                                              id="longitude"
                                              name='longitude'
                                              selectStylesOverride={selectStylesOverride}
                                              inputClasses={inputClasses}
                                              type={"number"}
                                              value={geofencePayload?.longitude || ""}
                                              onChange={(e) => {
                                                let temp = { ...geofencePayload }
                                                temp.longitude = e.target.value
                                                setGeofencePayload(temp)
                                                setAddressFetcher(true)
                                              }}
                                            />
                                          </FormControl>
                                        </Grid>
                                        {geofencePayload?.polygonType === 'Circular' ?
                                          <Grid item={true} md={12} pl={2} pr={2} pt={2}>
                                            <FormControl
                                              variant="outlined"
                                              size="small"
                                              fullWidth
                                              sx={{ ...selectStylesOverride(isDarkThemeEnabledSelector) }}
                                            >
                                              <CustomInput
                                                placeholder="Radius"
                                                name='radius'
                                                selectStylesOverride={selectStylesOverride}
                                                inputClasses={inputClasses}
                                                type={"number"}
                                                value={geofencePayload?.radius || ""}
                                                onChange={(e) => {
                                                  let temp = { ...geofencePayload }
                                                  temp.radius = e.target.value
                                                  setGeofencePayload(temp)
                                                }}
                                              />
                                            </FormControl>
                                          </Grid>
                                          : null}
                                      </Grid>
                                      <Grid item={true} display="flex" gap={1} mt={1} mb={1} ml={0} alignItems="center">
                                        <Box sx={{ m: 1, position: "relative", justifyContent: 'center', alignItems: 'center', display: 'flex', width: '100%' }}>

                                          {btnToggle === 'create' ?
                                            <CustomSubmitBtn
                                              label="Create"
                                              variant="contained"
                                              sx={{
                                                ...buttonClasses?.lynkitOrangeFill,
                                                minHeight: "28px",
                                                height: '28px',
                                                minWidth: "80px",
                                                borderRadius: "5px",
                                                marginRight: 1,
                                                padding: '0px',
                                                "&.Mui-disabled": {
                                                  backgroundColor: "#827878 !important",
                                                  border: `1px solid #827878 !important`,
                                                  color: "#EAEAEA !important",
                                                },
                                              }}
                                              type="submit"
                                              onClick={() => updateGeofence()}
                                              loading={updateLoader}
                                              disabled={updateLoader}
                                            />
                                            :
                                            <CustomSubmitBtn
                                              label="Update"
                                              variant="contained"
                                              sx={{
                                                ...buttonClasses?.lynkitOrangeFill,
                                                minHeight: "28px",
                                                height: '28px',
                                                minWidth: "80px",
                                                marginRight: 1,
                                                borderRadius: "5px",
                                                padding: '0px',
                                                "&.Mui-disabled": {
                                                  backgroundColor: "#827878 !important",
                                                  border: `1px solid #827878 !important`,
                                                  color: "#EAEAEA !important",
                                                },
                                              }}
                                              type="submit"
                                              onClick={() => updateGeofence()}
                                              loading={updateLoader}
                                              disabled={updateLoader}
                                            />
                                          }
                                          {btnToggle === 'create' ?

                                            <Button
                                              variant="outlined"
                                              size="small"
                                              id='clearGeofenceFromMap'
                                              sx={{
                                                ...buttonClasses.outlined,
                                                color: themeType.themeOrange,
                                                minWidth: "80px",
                                                minHeight: "28px",
                                                borderRadius: "5px",
                                                color: "#ff7700",
                                                marginRight: 1,
                                              }}
                                            >
                                              Clear Map
                                            </Button>
                                            :
                                            <Button
                                              variant="outlined"
                                              size="small"
                                              onClick={() => clearGeofence()}
                                              sx={{
                                                ...buttonClasses.outlined,
                                                color: themeType.themeOrange,
                                                minWidth: "80px",
                                                minHeight: "28px",
                                                borderRadius: "5px",
                                                color: "#ff7700",
                                                marginRight: 1,
                                              }}
                                            >
                                              Clear Map
                                            </Button>
                                          }
                                          <Button
                                            variant="outlined"
                                            id='cancelBtn'
                                            sx={{
                                              ...buttonClasses?.lynkitBlackFill,
                                              minWidth: "80px",
                                              minHeight: "28px",
                                              borderRadius: "5px",
                                              padding: '0px',
                                            }}
                                            onClick={() => closeAccordian()}
                                          >
                                            Cancel
                                          </Button>
                                        </Box>
                                      </Grid>
                                    </>
                                    : null}
                              </div>
                            </td>
                          </tr>
                        )}
                      </>
                    )

                    )}





                  </tbody>
                </table>
                :
                <span>No data found..</span>
              }
            </div>
          </Box>





        </>
      )}
    </Box>

  );
};

export default EditGeofenceDrawer;
