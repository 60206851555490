import {
  Button,
  Autocomplete,
  TextField,
  Grid,
  MenuItem,
  Paper,
  Menu,
  Checkbox,
  Tab,
  Tabs,
  Typography,
  Switch,
  Box,
  FormControl,
  FormControlLabel,
} from "@mui/material";
import { havePermission } from "../../../../utils/permissionCheck";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import loaderGif from "../../../../static/images/drawer/loader.gif";
import Table from "../components/Table";
import { useEffect } from "react";
import { useCallback } from "react";
import { selectStylesOverride } from "../../../../utils/util";
import {
  getAllSettings,
  updateSettings,
  clearSettings,
} from "../../../../redux/actions/Settings";
import { useMemo } from "react";
import { Controller, useForm } from "react-hook-form";
import { makeStyles } from "@mui/styles";
import EmptyPage from "../../EmptyPage";
import {
  currentUserDetails,
  getAllOrganisations,
  getAllUsers,
} from "../../../../redux/actions";
import CustomSelect from "../../../../components/FormUI/CustomSelect";
import IframeModal from "../../../Components/IframeModal/IframeModal";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles({
  root: {
    width: "50px",
    height: "24px",
    padding: "0px",
  },
  switchBase: {
    padding: "2px",
    color: "red",
    // "&.Mui-disabled": {
    //   color: "green",
    // },
    "&.Mui-checked": {
      color: "green",
    },
    "&.Mui-checked + .MuiSwitch-track": {
      backgroundColor: "transparent",
    },
  },
  track: {
    border: "0.5px solid #D9D9D9",
    borderRadius: "20px",
    backgroundColor: "transparent",
    opacity: "1 !important",
    "&:after, &:before": {
      fontSize: "11px",
      position: "absolute",
      top: "6px",
      content: "''", // Add an empty content property by default
    },
    "&:after": {
      color: "green",
      content: "'Yes'",
      left: "4px",
    },
    "&:before": {
      color: "red",
      content: "'No'",
      right: "7px",
    },
  },
  checked: {
    transform: "translateX(26px) !important",
  },
});

const HealthSettings = () => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const {
    register,
    control,
    getValues,
    setValue,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  /* LOCAL STATES */
  const [tabIndex, setTabIndex] = useState(0);
  const [isEditMode, setEditMode] = useState(false);
  const [allUserSelected, setAllUserSelected] = useState(false);
  const [orgUserSelected, setOrgUserSelected] = useState(false);
  const [dataToSend, setDataToSend] = useState(null);
  const [loader, setLoader] = useState(false);
  const [searchFields, setSearchFields] = useState({ org: null });
  const [errorMessage, setErrorMessage] = useState("");
  const [editPermission, setEditPermission] = useState("editChkAvlPermission");
  const [selectedTabName, setSelectedTabName] = useState("elock");
  const currentUser = useSelector((state) => state.auth.currentUserDetail);
  const [details, setDetails] = useState({
    orgId: "",
    id: "",
  });

  /* SELECTORS */
  const userPermissions =
    useSelector((state) => state.auth?.currentUserDetail) || {};
  const orgListing = useSelector(
    (state) => state.Organisationlisting.OrganisationListing
  );
  const superAdmin =
    useSelector((state) => state.auth.currentUserDetail)
      ?.adminSpecificPermissions || {};
  const Users = useSelector((state) => state.userlist.allUsers);
  const isDarkThemeEnabledSelector = useSelector(
    (state) => state.theme.themeDark
  );
  const { settingsData } = useSelector((state) => state.settingsReducer);

  const themeType = isDarkThemeEnabledSelector
    ? require("../../../../static/styles/darktheme")
    : require("../../../../static/styles/theme");
  const { buttonClasses, inputClasses, textClasses } = themeType;

  let tableColumns = [
    {
      key: "Sno",
      header: "#",
      cell: (key) => {
        return (
          <Typography
            sx={{
              ...textClasses.t13n,
              textAlign: "start",
            }}
          >
            {key + 1}
          </Typography>
        );
      },
    },
    {
      key: "conditions",
      header: "Checkpoints",
      cell: (entry) => {
        return (
          <Typography
            sx={{
              // ...textClasses.t13n,
              textAlign: "start",
            }}
          >
            {ConditionLabel(entry)}
          </Typography>
        );
      },
    },
    {
      key: "status",
      header: "Status",
      cell: (entry) => {
        return (
          <Typography
            sx={{
              ...textClasses.t13n,
              textAlign: "start",
              fontSize: "16px",
              fontWeight: 600,
              textTransform: "capitalize",
              color: entry?.isShow
                ? entry?.status == "yes"
                  ? themeType?.default.themeGreen
                  : themeType?.default.themeRed
                : (theme) => theme.palette.text,
            }}
          >
            {entry?.isShow ? (
              isEditMode ? (
                <Controller
                  name={`switch_${entry?.key}`}
                  control={control}
                  render={({ field: { onChange, value, name } }) => {
                    return (
                      <div>
                        <Switch
                          classes={{
                            root: classes.root,
                            switchBase: classes.switchBase,
                            thumb: classes.thumb,
                            track: classes.track,
                            checked: classes.checked,
                          }}
                          data-testid="checkbox"
                          // disabled={!entry?.isUpdateStatus}
                          size="medium"
                          name={name}
                          defaultChecked={
                            entry?.status === "yes" ? true : false
                          }
                          value={
                            typeof value === "string"
                              ? value === "yes"
                                ? true
                                : false
                              : value
                          }
                          onChange={(e) => {
                            updateStatus(e.target.checked, entry?.key);
                            onChange(e.target.checked == true ? "yes" : "no");
                          }}
                        />
                        {errors[name] && (
                          <span
                            role="alert"
                            style={{ color: "#FF0101", fontSize: 11 }}
                          >
                            {errors[name]?.message}
                          </span>
                        )}
                      </div>
                    );
                  }}
                />
              ) : (
                entry?.status
              )
            ) : (
              "-"
            )}
          </Typography>
        );
      },
    },
  ];

  const tableData = useMemo(() => {
    const healthSettings =
      (settingsData && settingsData?.healthDevicePermissions) || {};
    if (currentUser) {
      if (currentUser?.adminSpecificPermissions) {
        if (details.orgId && details?.id && selectedTabName) {
          const data =
            healthSettings &&
            healthSettings[selectedTabName] &&
            Object.keys(healthSettings[selectedTabName])
              ?.filter((condition) => condition !== "label")
              ?.map((condition, index) => {
                let conditionData =
                  healthSettings &&
                  healthSettings[selectedTabName] &&
                  healthSettings[selectedTabName][condition];
                if (conditionData) {
                  conditionData = { ...conditionData, key: condition };
                }
                return conditionData;
              });
          return data;
        } else {
          return [];
        }
      } else {
        if (details?.id) {
          const data =
            healthSettings &&
            healthSettings[selectedTabName] &&
            Object.keys(healthSettings[selectedTabName])
              ?.filter((condition) => condition !== "label")
              ?.map((condition, index) => {
                let conditionData =
                  healthSettings &&
                  healthSettings[selectedTabName] &&
                  healthSettings[selectedTabName][condition];
                if (conditionData) {
                  conditionData = { ...conditionData, key: condition };
                }
                return conditionData;
              });
          return data;
        } else {
          return [];
        }
      }
    }
  }, [settingsData, details.orgId, details?.id, currentUser, selectedTabName]);

  const updateValue = useCallback(
    (value, key) => {
      let temp = dataToSend;
      const keyToChange = key;
      temp = {
        ...temp,
        [selectedTabName]: {
          ...temp[selectedTabName],
          [keyToChange]: {
            ...temp[selectedTabName][keyToChange],
            value: Number(value),
          },
        },
      };
      setDataToSend(temp);
    },
    [dataToSend, selectedTabName]
  );

  const updateStatus = (newValue, key) => {
    let temp = dataToSend;
    const keyToChange = key;
    temp[selectedTabName][keyToChange].status = newValue == true ? "yes" : "no";
    setDataToSend(temp);
  };

  const ConditionLabel = (entry) => {
    var stringSplitted = entry?.label?.split("${value}");
    const contentArray = stringSplitted?.map((part, index) => {
      if (index < stringSplitted.length - 1) {
        return (
          <React.Fragment key={index}>
            <div style={{ alignSelf: "center" }}>{part}</div>
            <div>&nbsp;</div>
            <Controller
              name={entry?.key}
              control={control}
              required
              rules={{
                required: {
                  value: true,
                  message: "Field value is required",
                },
                // validate: (value) => {
                //   return value > 0 || "Value must be greater than 0";
                // },
              }}
              errors={errors}
              render={({ field: { onChange, value, name } }) => {
                return (
                  <div style={{ lineHeight: 0 }}>
                    <TextField
                      data-testid="textField"
                      sx={{
                        ...selectStylesOverride(isDarkThemeEnabledSelector),
                      }}
                      id={`outlined-basic-${index}`}
                      variant="outlined"
                      name={name}
                      type={entry?.type}
                      defaultValue={value}
                      value={value}
                      InputProps={{
                        style: {
                          minWidth: "50px",
                          maxWidth: "75px",
                          height: "30px",
                        },
                      }}
                      onChange={(e) => {
                        if (e.target.value) {
                          updateValue(e.target.value, entry?.key);
                        }
                        onChange(e.target.value);
                      }}
                    />
                    {errors[name] && (
                      <span
                        role="alert"
                        style={{ color: "#FF0101", fontSize: 11 }}
                      >
                        {errors[name]?.message}
                      </span>
                    )}
                  </div>
                );
              }}
            />
            <div>&nbsp;</div>
          </React.Fragment>
        );
      } else {
        return <div style={{ alignSelf: "center" }}>{part}</div>;
      }
    });

    const updatedLabel = isEditMode
      ? contentArray
      : entry?.label?.replace("${value}", entry?.value);

    return (
      <Typography
        sx={{
          ...textClasses.normalText,
          textAlign: "start",
          overflow: "hidden",
          textOverflow: "ellipsis",
          // fontWeight: 600,
          // fontSize: "16px",
          display: "flex",
          flexDirection: "row",
          // lineHeight: 0,
        }}
      >
        {updatedLabel}
      </Typography>
    );
  };

  const handleUpdate = useCallback(
    (data) => {
      const payload = {
        type: "health",
        orgId: details.orgId,
        settings: dataToSend,
        allUser: allUserSelected,
        orgUser: orgUserSelected,
      };
      if (superAdmin) payload.userId = details.id;
      dispatch(
        updateSettings({...payload}, () => {
          let payload = {
            type: "health",
            orgId: details?.orgId,
          };
          if (superAdmin) payload.userId = details?.id;
          dispatch(getAllSettings(payload));
          setEditMode(false);
        })
      );
    },
    [
      dispatch,
      superAdmin,
      currentUser,
      dataToSend,
      allUserSelected,
      orgUserSelected,
      setEditMode,
      getAllSettings,
      updateSettings,
    ]
  );

  const _handleChange = (e, newVal, item) => {
    let temp = { ...details };
    if (item === "org") {
      if (newVal) {
        temp.orgId = newVal?._id;
        setDetails(temp);
        if (Object?.keys(superAdmin)?.length) {
          dispatch(
            getAllUsers({ orgId: newVal?._id, selection: ["name", "orgName"] })
          );
        }
      } else {
        temp.orgId = "";
        setDetails(temp);
        dispatch(clearSettings());
        setValue("userName", "");
      }
    } else if (item === "user") {
      if (newVal) {
        temp.id = newVal?._id;
        setDetails(temp);
        // setValue('userName', newVal?.name)
      } else {
        temp.id = "";
        setDetails(temp);
        dispatch(clearSettings());
      }
    }
  };

  const handleSearchOrg = (empty) => {
    if (empty === true) {
      dispatch(getAllOrganisations({ selection: ["name"] }));
    } else {
      if (searchFields?.org || searchFields?.org == "")
        dispatch(
          getAllOrganisations({
            selection: ["name"],
            searchValue: searchFields.org,
          })
        );
    }
  };

  const handleSearchUsers = (empty) => {
    if (empty === true) {
      dispatch(
        getAllUsers({
          selection: ["name", "orgName"],
          searchValue: "",
        })
      );
    } else {
      if (searchFields?.users || searchFields?.users == "")
        dispatch(
          getAllUsers({
            selection: ["name", "orgName"],
            searchValue: searchFields?.users,
          })
        );
    }
  };

  /* EFFECTS */
  useEffect(() => {
    if (details?.orgId && details?.id) {
      let payload = {
        type: "health",
        orgId: details?.orgId,
        userId: details?.id,
      };
      setLoader(true);
      dispatch(
        getAllSettings(payload, (res) => {
          if (res && !res?.success) {
            setErrorMessage(res?.message);
          }
          setLoader(false);
        })
      );
      setAllUserSelected(false);
      setOrgUserSelected(false);
    } else if (details?.orgId) {
      let payload = {
        type: "health",
        orgId: details?.orgId,
      };
      setLoader(true);
      dispatch(
        getAllSettings(payload, (res) => {
          if (res && !res?.success) {
            setErrorMessage(res?.message);
          }
          setLoader(false);
        })
      );
      setAllUserSelected(false);
      setOrgUserSelected(false);
    }
  }, [dispatch, details?.orgId, details?.id]);

  useEffect(() => {
    dispatch(clearSettings());
    if (currentUser) {
      if (currentUser?.adminSpecificPermissions) {
        setErrorMessage("Please select organisation");
        setDetails({
          orgId: "",
          id: "",
        });
        dispatch(getAllOrganisations({ selection: ["name"] }));
      } else {
        dispatch(
          getAllUsers({
            orgId: currentUser?.orgId,
            selection: ["name", "orgName"],
          })
        );

        let payload = {
          type: "health",
          orgId: currentUser?.orgId,
          userId: currentUser?._id,
        };
        setValue("userName", currentUser);
        let temp = { details };
        temp.id = currentUser?._id;
        temp.orgId = currentUser?.orgId;
        setDetails(temp);
        // setLoader(true);
        // dispatch(
        //   getAllSettings(payload, (res) => {
        //     if (res && !res?.success) {
        //       setErrorMessage(res?.message);
        //     }
        //     setLoader(false);
        //   })
        // );
      }
    }
  }, [currentUser]);

  useEffect(() => {
    if (settingsData && settingsData?.healthDevicePermissions) {
      setDataToSend(settingsData?.healthDevicePermissions);
    }
  }, [settingsData]);

  useEffect(() => {
    if (tableData) {
      tableData.forEach((data) => {
        setValue(`switch_${data?.key}`, data?.status);
        if (data?.value) {
          setValue(data?.key, data?.value);
        }
      });
    }
  }, [tableData]);

  return (
    <>
      <Grid item sm={12} xs={12}>
        <Typography
          data-testid="page-title"
          role="span"
          sx={{ ...textClasses.cardTitle }}
        >
          Health Report Settings
        </Typography>
      </Grid>
      {settingsData && settingsData?.healthDevicePermissions && (
        <Box
          sx={{
            borderBottom: 1,
            borderColor: "divider",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Tabs
            value={tabIndex}
            aria-label="disabled tabs example"
            style={{ height: "20px" }}
            sx={{
              "& .MuiTabs-indicator": {
                backgroundColor: themeType.default.themeOrange,
              },
              "& .MuiTab-root.Mui-selected": {
                color: themeType.default.themeOrange,
              },
            }}
          >
            {settingsData &&
              settingsData?.healthDevicePermissions &&
              Object.keys(settingsData.healthDevicePermissions).map(
                (permissionKey, permissionindex) => (
                  <Tab
                    key={0 + permissionKey}
                    data-testid="tab-item"
                    label={
                      settingsData.healthDevicePermissions[permissionKey].label
                    }
                    {...a11yProps(0)}
                    sx={{ fontSize: "14px", textTransform: "none" }}
                    onClick={() => {
                      setSelectedTabName(permissionKey);
                      setTabIndex(permissionindex);
                    }}
                  />
                )
              )}
          </Tabs>
        </Box>
      )}
      <Grid
        container
        mt={2}
        display="flex"
        gap={2}
        alignItems="center"
        sx={{ width: "100%", paddingTop: "0 !important" }}
      >
        <Grid
          item
          display="flex"
          gap={2}
          alignItems="center"
          sx={{ width: "40%", paddingTop: "0 !important" }}
        >
          {superAdmin && Object.keys(superAdmin).length ? (
            <Grid
              item
              display="flex"
              gap={2}
              alignItems="center"
              sx={{ width: "100%", paddingTop: "0 !important" }}
            >
              <FormControl
                variant="outlined"
                size="small"
                fullWidth
                sx={{ ...selectStylesOverride(isDarkThemeEnabledSelector) }}
              >
                <CustomSelect
                  selectStylesOverride={{
                    ...selectStylesOverride,
                    width: "96%",
                  }}
                  key={`organisation`}
                  onChange={(e, newVal, reason) => {
                    if (reason === "clear")
                      setErrorMessage("Please select organisation");
                    _handleChange(e, newVal, "org");
                  }}
                  onSearch={handleSearchOrg}
                  onInputChange={(e) => {
                    if (e?.type === "change") {
                      if (e?.target?.value === "") handleSearchOrg(true);
                      setSearchFields((prev) => ({
                        ...prev,
                        org: e?.target?.value?.trim(),
                      }));
                    }
                  }}
                  searchValue={
                    searchFields?.org && searchFields?.org !== ""
                      ? searchFields?.org
                      : null
                  }
                  label="Organisation"
                  errors={errors}
                  getOptionLabel={(option) => {
                    return option?.name;
                  }}
                  filterSelectedOptions
                  labelKey="name"
                  isOptionEqualToValue={(option, value) =>
                    option._id === value.id && option.name === value.name
                  }
                  options={orgListing && orgListing}
                />
              </FormControl>
            </Grid>
          ) : null}
          <Grid
            item
            display="flex"
            gap={2}
            alignItems="center"
            sx={{
              width: Object.keys(superAdmin).length ? "100%" : "50%",
              paddingTop: "0 !important",
            }}
          >
            <FormControl
              variant="outlined"
              size="small"
              fullWidth
              sx={{
                ...selectStylesOverride(isDarkThemeEnabledSelector),
                width: "96%",
              }}
            >
              <Controller
                name="userName"
                control={control}
                render={({ field: { onChange, value, name } }) => {
                  return (
                    <CustomSelect
                      selectStylesOverride={{
                        ...selectStylesOverride,
                        width: "96%",
                      }}
                      key={`id`}
                      defaultValue={value}
                      onChange={(e, newVal) => {
                        onChange(newVal?._id);
                        _handleChange(e, newVal, "user");
                      }}
                      searchValue={
                        searchFields?.users && searchFields?.users !== ""
                          ? searchFields?.users
                          : null
                      }
                      onSearch={handleSearchUsers}
                      onInputChange={(e) => {
                        if (e?.type === "change") {
                          if (e?.target?.value === "") handleSearchUsers(true);
                          setSearchFields((prev) => ({
                            ...prev,
                            users: e?.target?.value?.trim(),
                          }));
                        }
                      }}
                      label="User"
                      name={name}
                      errors={errors}
                      value={value || null}
                      getOptionLabel={(option) => {
                        if (value === undefined) {
                          return "";
                        }
                        if (typeof option === "string") {
                          return (
                            Users?.find((_) => _?._id == value)?.name || ""
                          );
                        } else {
                          return option?.name || "";
                        }
                      }}
                      filterSelectedOptions
                      labelKey="name"
                      options={
                        Users?.length > 0
                          ? Users.map((ele) => ({
                              name: `${ele.name}-${ele.orgName}`,
                              _id: ele._id,
                            }))
                          : []
                      }
                    />
                  );
                }}
              />
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
      <Grid container spacing={2} mt={0}>
        {loader ? (
          <Grid
            container
            direction={"column"}
            justifyContent={"center"}
            alignItems="center"
            sx={{ p: 15 }}
          >
            <Grid item>
              <img src={loaderGif} />
            </Grid>
          </Grid>
        ) : (
          <>
            {tableData && tableData?.length === 0 && tableData === undefined ? (
              <EmptyPage body="No data found" />
            ) : (
              <Grid container sx={{ mt: 2 }}>
                <Grid item sm={12} xs={12}>
                  {!tableData || tableData.length === 0 ? (
                    <EmptyPage
                      // body={
                      //   currentUser?.adminSpecificPermissions
                      //     ? "Please select Organsiation "
                      //     : ""
                      // }
                      body={errorMessage}
                    />
                  ) : (
                    <Paper
                      sx={{
                        height: "100%",
                        width: "98%",
                        margin: "auto",
                        overflowX: "auto",
                        display: "block",
                      }}
                    >
                      <Table
                        tableColumns={tableColumns}
                        data={tableData ?? []}
                        themeType={themeType}
                      />
                    </Paper>
                  )}
                </Grid>
              </Grid>
            )}
          </>
        )}
      </Grid>
      {isEditMode && tableData && tableData?.length > 0 && (
        <Grid mt={0.5} xs={12} mb={1}>
          <Grid item xs={12} display="flex" alignItems="center">
            <FormControlLabel
              sx={{ color: "rgb(128, 128, 128)" }}
              control={
                <Checkbox
                  sx={{
                    color: "#ff7200",
                    "&.Mui-checked": {
                      color: "#ff7200",
                    },
                  }}
                  onClick={(e) => {
                    setOrgUserSelected(e.target.checked);
                    setAllUserSelected(false);
                  }}
                  checked={orgUserSelected}
                />
              }
            />
            <Typography sx={{ ...textClasses.normalText }}>
              Apply same setting to all users within the same organisation.
            </Typography>
          </Grid>
          <Grid item xs={12} display="flex" alignItems="center">
            <FormControlLabel
              sx={{ color: "rgb(128, 128, 128)" }}
              control={
                <Checkbox
                  sx={{
                    color: "#ff7200",
                    "&.Mui-checked": {
                      color: "#ff7200",
                    },
                  }}
                  onClick={(e) => {
                    setAllUserSelected(e.target.checked);
                    setOrgUserSelected(false);
                  }}
                  checked={allUserSelected}
                />
              }
            />
            <Box display="flex" alignItems="center">
              <Typography component="span" sx={{ ...textClasses.normalText }}>
                Apply same setting to all those users visible to you in{" "}
              </Typography>
              <IframeModal
                label="user management"
                iFrameUrl="/dashboard/users/my-team"
              />
            </Box>
          </Grid>
        </Grid>
      )}

      {isEditMode ? (
        <Grid
          item
          mt={2}
          display="flex"
          justifyContent="center"
          gap={2}
          alignItems="center"
          sx={{ width: "100%", paddingTop: "0 !important" }}
        >
          <Button
            variant="outlined"
            sx={{
              ...buttonClasses?.lynkitBlackFill,
              minHeight: "36px",
              minWidth: "140px",
            }}
            onClick={() => setEditMode(false)}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            sx={{
              ...buttonClasses?.lynkitOrangeFill,
              minHeight: "36px",
              minWidth: "140px",
            }}
            onClick={handleSubmit(handleUpdate)}
          >
            Save
          </Button>
        </Grid>
      ) : null}
      {tableData &&
        tableData?.length > 0 &&
        currentUser &&
        havePermission(
          currentUser,
          "healthSettings",
          "editDeviceHealthPermission"
        ) && (
          <>
            {!isEditMode ? (
              <Grid
                item
                mt={2}
                display="flex"
                justifyContent="center"
                gap={2}
                alignItems="center"
                sx={{ width: "100%", paddingTop: "0 !important" }}
              >
                <Button
                  variant="contained"
                  sx={{
                    ...buttonClasses?.lynkitOrangeFill,
                    minHeight: "36px",
                    minWidth: "140px",
                  }}
                  onClick={() => {
                    setEditMode((prev) => !prev);
                    setAllUserSelected(false);
                    setOrgUserSelected(false);
                  }}
                >
                  Edit
                </Button>
              </Grid>
            ) : null}
          </>
        )}
    </>
  );
};

export default HealthSettings;
