import { KeyboardArrowDown } from "@mui/icons-material";
import { Box } from "@mui/material";
import React from "react";

const IconWithArrow = ({ src, name = "" }) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      p="5px"
      height="100%"
    >
      <Box>
        <img
          src={src}
          height="100%"
          width="100%"
          style={{ objectFit: "cover" }}
        />
      </Box>
      {name?.toLowerCase() !== "destination" && (
        <Box
          height="100%"
          position="relative"
          border="1px dashed #000000"
          mt="2px"
        >
          <Box
            sx={{
              position: "absolute",
              bottom: "-10px",
              left: "50%",
              transform: "translateX(-50%)",
            }}
          >
            <KeyboardArrowDown fontSize="2rem" />
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default IconWithArrow;
