import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Link, useParams } from "react-router-dom";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useDispatch, useSelector } from "react-redux";
import {
  Grid,
  Typography,
  Select,
  MenuItem,
  Box,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  CardMedia,
  Avatar,
  Divider,
  Paper,
  Tabs,
  Tab,
  Breadcrumbs,
  CircularProgress,
  Button,
} from "@mui/material";

import theme, { textClasses } from "../../../static/styles/theme";
import SelectTemplate from "./SelectTemplate";
import ComponentCanvas from "./ComponentCanvas";
import StandardComponents from "./StandardComponents";
import PreviousUsedComponents from "./PreviousUsedComponents";

import {
  editTemplate,
  getPreviousComonents,
  getSelectedTemplate,
  getStandardComonents,
  initCanvas,
  replaceEditCanvas,
  saveTemplate,
  selectTemplate,
  getTemplateTypes,
  selectMasterTemplate,
} from "../../../redux/actions";
import { templateTypes } from "../../../services/constants";
import { cleanCanvas } from "../../../redux/reducers/Canvas";
import history from "../../../services/history";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 2 }}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function EditTemplate() {
  const { templateId } = useParams();
  const dispatch = useDispatch();
  const template = useSelector((state) => state.inward.selectedTemplate);
  const usedComponents = useSelector((state) => state.templates.usedComponents);
  const stdComponents = useSelector(
    (state) => state.templates.standardComponents
  );
  const components = useSelector((state) => state.canvas.components);
  const allTemplateTypes = useSelector(
    (state) => state.templates.templateTypes
  );
  const isDarkThemeEnabledSelector = useSelector(
    (state) => state.theme.themeDark
  );
  const themeType = isDarkThemeEnabledSelector
    ? require("../../../static/styles/darktheme")
    : require("../../../static/styles/theme");

  const {buttonClasses} = themeType;

  const initEditCanvas = (template) => {
    let newComponents = cleanCanvas();
    if (template) {
      // Get Shipment Components
      let newMandatory = [];
      let newOthers = [];
      let mandatoryIds = [];
      mandatoryIds = newComponents.attributes.mandatory.map((c) => c._id);
      for (let i = 0; i < template.attributes.length; i++) {
        const element = template.attributes[i];

        if (mandatoryIds.indexOf(element._id) >= 0) {
          newMandatory.push({ ...element });
        } else {
          newOthers.push({
            ...element,
            removable: !template.frequency || template.frequency == 0,
          });
        }
      }
      newComponents.attributes.mandatory = newMandatory;
      newComponents.attributes.other = newOthers;
    }
    return newComponents;
  };

  const selectedTemplate = useSelector(
    (state) => state.canvas.selectedTemplate
  );
  const selectedSubTemplate = useSelector(
    (state) => state.canvas.selectedSubTemplate
  );
  const counterElements = useSelector(
    (state) => state.templates.countElements
  );


  const [tabIndex, setTabIndex] = useState(0);
  const [sectionStep, setSectionStep] = useState(2);
  const [selectModal, setSelectModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleOpenModal = () => setSelectModal(true);
  const handleCloseModal = () => setSelectModal(false);

  const handleTemplateSelect = (templateType, subTemplateType) => {
    dispatch(selectTemplate(templateType, subTemplateType));
    setSectionStep(sectionStep + 1);
  };

  const handleTabChange = (event, value) => {
    setTabIndex(value);
  };

  const handleTemplateChange = (event) => {};

  const getSectionStep = () => {
    if (sectionStep == 1) {
      return (
        <SelectTemplate
          showModal={selectModal}
          handleTemplateSelect={handleTemplateSelect}
          handleOpenModal={handleOpenModal}
          handleCloseModal={handleCloseModal}
        />
      );
    } else if (sectionStep == 2) {
      return (
        <ComponentCanvas
          components={components}
          setComponents={null}
          handleSaveTemplate={handleEditTemplate}
          template={template}
        />
      );
    }
    return <div>Coming Soon..</div>;
  };

  const getTabStyles = (tIndex) => {
    return {
      backgroundColor:
        tabIndex == tIndex ? themeType.default.themeOrange : "white",
      color: tabIndex == tIndex ? "white" : themeType.default.themeOrange,
      border: "1px solid",
      boxShadow: "0px 2px 6px gray",
      borderColor: themeType.default.themeOrange,
      pt: 2,
      pb: 2,
      fontSize: "11px",
      lineHeight: "19px",
      fontWeight: "400",
      textTransform: "none",
    };
  };

  const handleEditTemplate = (data, callback) => {
    const payload = {
      name: data.name,
      templateType: selectedSubTemplate,
      templateCategory: selectedTemplate,
      status: data.status,
      description: data.description,
      template_id: templateId,
      countElements:counterElements,
      orgId: data?.orgId,
      attributes: [
        ...components.attributes.mandatory.filter(
          (f) => f.templateType == selectedSubTemplate
        ),
        ...components.attributes.other.map((c) => {
          let newC = { ...c };
          delete newC["removable"];
          return newC;
        }),
      ],
    };
    dispatch(editTemplate(payload, callback));
  };

  useEffect(() => {
    dispatch(initCanvas());
    dispatch(getStandardComonents({ type: "wms" }));
    // dispatch(
    //     getPreviousComonents({type: "wms"})
    // )
    dispatch(getTemplateTypes());
    dispatch(selectTemplate("-", "-"));
  }, []);

  useEffect(() => {
    if (templateId && templateId !== "") {
      setLoading(true);
      dispatch(
        getSelectedTemplate({ template_id: templateId }, (data) => {
          dispatch(
            replaceEditCanvas(
              data && data?.templateCategory,
              data && data?.templateType,
              initEditCanvas(data)
            )
          );
          setLoading(false);
        })
      );
    }
  }, [templateId]);

  return (
    <>
      <DndProvider backend={HTML5Backend}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box>
            <Typography sx={{ ...textClasses.cardTitle }}>
              Edit Template - {template && template.name}
            </Typography>
            <Breadcrumbs sx={{ mb: 1 }}>
              <Typography
                sx={{
                  ...textClasses.normalText,
                  fontSize: "12px",
                  color: themeType.default.themeOrange,
                }}
              >
                <Link
                  style={{
                    color: themeType.default.themeOrange,
                    textDecoration: "none",
                  }}
                  to="/dashboard/manage-templates"
                >
                  Manage Templates
                </Link>
              </Typography>

              <Typography sx={{ ...textClasses.normalText, fontSize: "12px" }}>
                Edit Template
              </Typography>
            </Breadcrumbs>
          </Box>
          {/* <Button sx={{...buttonClasses.lynkitBlackFill}} onClick={() => history.goBack()}>Back</Button> */}
        </Box>
        <Grid container spacing={2}>
          <Grid item sm={8} xs={10}>
            <Card sx={{ borderRadius: "12px" }}>
              <CardHeader
                sx={{
                  pt: 1,
                  pl: 2,
                  pb: 1,
                  borderRadius: "8px 8px 0px 0px",
                  backgroundColor: themeType.default.themeOrange,
                  color: "white",
                  boxShadow: "0px 2px 6px gray",
                }}
                action={
                  sectionStep > 1 && (
                    <Select
                      disabled
                      sx={{
                        backgroundColor: themeType.default.themeGray,
                        color: themeType.default.themeOrange,
                        minWidth: "200px",
                      }}
                      size="small"
                      labelId="demo-select-small-label"
                      id="demo-select-small"
                      value={selectedSubTemplate}
                      onChange={handleTemplateChange}
                    >
                      {allTemplateTypes.map((item, i) => {
                        if (item.allTemplateTypes.length > 1) {
                          let menus = item.allTemplateTypes.map((s, j) => {
                            return (
                              <MenuItem
                                value={s.templateTypekey}
                                key={`${i}-${j}`}
                              >
                                {item?.templateCategoryLabel} -{" "}
                                {s?.templateTypeLabel}
                              </MenuItem>
                            );
                          });
                          return menus;
                        } else {
                          return (
                            <MenuItem
                              value={item.allTemplateTypes[0].templateTypekey}
                              key={i}
                            >
                              {item.allTemplateTypes[0].templateTypeLabel}
                            </MenuItem>
                          );
                        }
                      })}
                    </Select>
                  )
                }
                title={<Typography variant="h6">Drop Section</Typography>}
              />
              <CardContent sx={{ pt: 2 }}>
                <Grid container sx={{ mb: 1 }}>
                  <Grid
                    container
                    item
                    sm={2}
                    direction="column"
                    alignItems="center"
                    justifyContent={"center"}
                  >
                    <Avatar
                      sx={{
                        width: 32,
                        height: 32,
                        backgroundColor:
                          sectionStep == 1
                            ? themeType.default.themeOrange
                            : null,
                      }}
                    >
                      1
                    </Avatar>
                    <Typography
                      variant="caption"
                      sx={{ mt: 1, fontSize: "12px" }}
                    >
                      select template type
                    </Typography>
                  </Grid>
                  <Grid item sm={3} justifyContent="center">
                    <Divider sx={{ mt: 2 }} />
                  </Grid>
                  <Grid
                    container
                    item
                    sm={2}
                    direction="column"
                    alignItems="center"
                    justifyContent={"center"}
                  >
                    <Avatar
                      sx={{
                        width: 32,
                        height: 32,
                        backgroundColor:
                          sectionStep == 2
                            ? themeType.default.themeOrange
                            : null,
                      }}
                    >
                      2
                    </Avatar>
                    <Typography
                      variant="caption"
                      sx={{ mt: 1, fontSize: "12px" }}
                    >
                      drag and drop comp
                    </Typography>
                  </Grid>
                  <Grid item sm={3}>
                    <Divider sx={{ mt: 2 }} />
                  </Grid>
                  <Grid
                    container
                    item
                    sm={2}
                    direction="column"
                    alignItems="center"
                    justifyContent={"center"}
                  >
                    <Avatar
                      sx={{
                        width: 32,
                        height: 32,
                        backgroundColor:
                          sectionStep == 3
                            ? themeType.default.themeOrange
                            : null,
                      }}
                    >
                      3
                    </Avatar>
                    <Typography
                      variant="caption"
                      sx={{ mt: 1, fontSize: "12px" }}
                    >
                      preview & submit form
                    </Typography>
                  </Grid>
                </Grid>

                {loading ? (
                  <Grid
                    container
                    justifyContent={"center"}
                    alignItems="center"
                    sx={{ mt: 3 }}
                  >
                    <CircularProgress />
                  </Grid>
                ) : (
                  getSectionStep()
                )}
              </CardContent>
            </Card>
          </Grid>
          <Grid item sm={4} xs={2}>
            <Paper
              sx={{
                position: "fixed",
                zIndex: 100,
                mr: 2,
                overflowY: "scroll",
                maxHeight: "500px",
                borderRadius: "8px",
              }}
            >
              <Tabs
                value={tabIndex}
                aria-label="disabled tabs example"
                variant="fullWidth"
                onChange={handleTabChange}
                sx={{
                  "& .MuiTabs-indicator": { display: "none" },
                  "& .MuiTab-root.Mui-selected": { color: "#FFFFFF" },
                }}
              >
                {/* <Tab label="Previously Used Components"  {...a11yProps(0)} sx={{...getTabStyles(0), borderTopLeftRadius: "8px"}} /> */}
                <Tab
                  label="Standard Form Components"
                  {...a11yProps(0)}
                  sx={{
                    ...getTabStyles(0),
                    borderTopRightRadius: "8px",
                    fontSize: "22px",
                  }}
                />
              </Tabs>
              {/* <TabPanel value={tabIndex} index={0} style={{backgroundColor: "#EEEEEE"}} >
                                <PreviousUsedComponents usedComponents={usedComponents} />
                            </TabPanel> */}
              <TabPanel
                value={tabIndex}
                index={0}
                style={{ backgroundColor: themeType.default.themeGray }}
              >
                <StandardComponents stdComponents={stdComponents} />
              </TabPanel>
            </Paper>
          </Grid>
        </Grid>
      </DndProvider>
    </>
  );
}
