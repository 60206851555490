import {
  Delete,
  Done,
  DoneOutline,
  InfoOutlined,
  Search,
  Visibility,
} from "@mui/icons-material";
import {
  Box,
  Breadcrumbs,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  ListItemText,
  ListSubheader,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { ExcelRenderer } from "react-excel-renderer";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  checkBulkTripData,
  getAllHeadersForBulkAddTrips,
  getAllRoutes,
  getAllGeofences,
  getRouteSchedculeData,
  flushScheduleData,
  createBulkTrip,
  getAllFilteredGeofences,
  getAllFilteredRoutes,
} from "../../../redux/actions";
import { Link } from "react-router-dom";
import CustomSelect from "../../../components/FormUI/CustomSelect";
import {
  clearConsole,
  dateTimePickerSlotProps,
  selectStylesOverride,
  sendCustomEventAnalytics,
} from "../../../utils/util";
import { Controller, useForm, useWatch } from "react-hook-form";
import ImgContainer from "../../Components/Generic/ImgContainer";
import noData from "../../../static/images/components/no_data_img_new.svg";
import { getShortText, showToast } from "../../../services/functions";
import { flushBulkHeaders } from "../../../redux/reducers/TripsReducer";
import { format, isValid, parse } from "date-fns";
import history from "../../../services/history";
import { DateTimePicker } from "@mui/x-date-pickers";
import { CustomInput } from "../../../components/FormUI";
import loaderGif from "../../../static/images/drawer/loader.gif";

const tripTypeOptions = [
  { label: "Trip with Route", value: "withRoute" },
  { label: "Trip with Geofence", value: "withoutRoute" },
];
const fileTypeOptions = [
  { label: "Standard", value: "standard" },
  { label: "Custom", value: "custom" },
];

const BulkCreateTrip = () => {
  const tripHeadersDummy = [
    "Trip Schedule Date",
    "Trip ID",
    "Vehicle number",
    "Route Name",
    "Route Schedule",
    "Driver Name",
    "Trip Name",
    "Number",
    "Long Text",
    "Dropdown",
    "Checkbox",
    "Radio",
  ];
  /* Dispatchers */
  const dispatch = useDispatch();
  const fileInputRef = React.useRef();

  /* Local States */
  const [selectedFile, setSelectedFile] = React.useState(null);
  const [showTablePreview, setShowTablePreview] = React.useState(false);
  const [rowData, setRowData] = React.useState([]);
  const [mappedRowData, setMappedRowData] = React.useState([]);
  const [headers, setHeaders] = React.useState([]);
  const [selectedIndices, setSelectedIndices] = React.useState([]);
  const [formWithError, setFormWithError] = React.useState(null);
  const [shellState, setShellState] = React.useState("");
  const [searchField, setSearchField] = React.useState({
    index: null,
    value: null,
  });
  const [geofenceSearchField, setGeofenceSearchField] = React.useState({
    rowIndex: null,
    cellIndex: null,
    value: null,
  });
  const [filteredViapoints, setFilteredViapoints] = React.useState({
    index: null,
    options: [],
  });
  const [filteredGeofences, setFilteredGeofences] = React.useState({
    rowIndex: null,
    cellIndex: null,
    options: [],
  });
  const [filteredRoutes, setFilteredRoutes] = React.useState({
    index: null,
    options: [],
  });
  const [fileErrorMsg, setFileErrorMsg] = React.useState(
    "Hey, please select the file and preview it."
  );
  const [routeScheduleIndex, setrouteScheduleIndex] = React.useState(null);
  const [loading, setLoading] = React.useState({
    table: false,
    main: false,
    validating: false,
    dropdownLoading: false,
  });

  /* Selectors */
  const { bulkTripHeaders } = useSelector((state) => state.tripsData);
  const { allRoutes } = useSelector((state) => state.routes);
  const { selectedRouteSchedules } = useSelector((state) => state.route);
  const { currentUserDetail } = useSelector((state) => state.auth);
  const { geofenceListing } = useSelector((state) => state.geofencelist);
  const isDarkThemeEnabledSelector = useSelector(
    (state) => state.theme.themeDark
  );

  const themeType = isDarkThemeEnabledSelector
    ? require("../../../static/styles/darktheme")
    : require("../../../static/styles/theme");

  const {
    buttonClasses,
    tableClasses,
    textClasses,
    dividerClass,
    inputClasses,
  } = themeType;

  const {
    formState: { errors },
    handleSubmit,
    control,
    setValue,
    reset,
  } = useForm();

  const selectedTripType = useWatch({
    control: control,
    name: "tripType",
  });

  const selectedFileType = useWatch({
    control: control,
    name: "fileType",
  });

  /* Handlers */
  // clearConsole();

  const isValidFile = (file) => {
    if (file) {
      const acceptedTypes = [
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "application/vnd.ms-excel",
      ];
      const maxFileSize = 2 * 1024 * 1024; // 2 MB

      if (!acceptedTypes?.includes(file?.type)) {
        return false;
      }

      if (file?.size > maxFileSize) {
        return false;
      }

      return true;
    }
  };

  const handleUpload = (fileData) => {
    const file = fileData;

    if (file) {
      ExcelRenderer(file, (err, resp) => {
        if (err) {
          console.log(err);
        } else {
          let tempHeaders = [];
          resp.rows[0]?.map((_, i) => {
            tempHeaders?.push({ label: _, value: _, index: i });
          });

          const filledArr1 = resp.rows
            ?.slice(1)
            ?.map((subArray) => {
              const filledSubArray = [];
              for (let i = 0; i < subArray.length; i++) {
                if (typeof subArray[i] === "undefined") {
                  filledSubArray.push(null);
                } else {
                  filledSubArray.push(subArray[i]);
                }
              }
              return filledSubArray;
            })
            ?.filter((_) => _?.length > 0);

          if (filledArr1?.length > 100) {
            showToast("Please upload a file with maximum 100 entries.", true);
            return;
          } else {
            setHeaders(tempHeaders);
            setRowData(filledArr1);
            setMappedRowData([]);
            setFormWithError(null);
            setSelectedIndices(
              bulkTripHeaders
                ? new Array(bulkTripHeaders.length).fill(null)
                : []
            );
            setFilteredViapoints({
              index: null,
              options: [],
            });
            setFilteredRoutes({
              index: null,
              options: [],
            });
            setSearchField({
              index: null,
              value: null,
            });
            setFilteredGeofences({
              rowIndex: null,
              cellIndex: null,
              options: [],
            });
            setGeofenceSearchField({
              rowIndex: null,
              cellIndex: null,
              value: null,
            });
            if (selectedTripType) {
              setShowTablePreview(true);
            }
          }
        }
      });
    }
  };

  const handleDeleteFile = () => {
    setSelectedFile(null);
    setHeaders([]);
    setRowData([]);
    setMappedRowData([]);
    setFilteredRoutes({
      index: null,
      options: [],
    });
    setFilteredViapoints({
      index: null,
      options: [],
    });
    setFilteredRoutes({
      index: null,
      options: [],
    });
    setSearchField({
      index: null,
      value: null,
    });
    setFilteredGeofences({
      rowIndex: null,
      cellIndex: null,
      options: [],
    });
    setGeofenceSearchField({
      rowIndex: null,
      cellIndex: null,
      value: null,
    });
    setShellState(Math.random().toString(36));
    setSelectedIndices(
      bulkTripHeaders ? new Array(bulkTripHeaders.length).fill(null) : []
    );
    showTablePreview && setShowTablePreview(false);
  };

  // const handlePreviewTable = (data) => {
  //   // e.preventDefault();
  //   if (!selectedTripType) {
  //     showToast("Select Trip Type", true);
  //   } else {
  //     handleValidation(data, () => {setShowTablePreview(true);});
  //   }
  // };

  const updateTableData = (selectedIndices, currentIndex) => {
    const updatedData = [];
    const tempFormErrorData = formWithError;
    for (const row of rowData) {
      const rowIndex = rowData?.findIndex((_) => _ == row);
      const newRow = [];
      for (let i = 0; i < bulkTripHeaders.length; i++) {
        const selectedIndex = selectedIndices[i];
        newRow.push(
          selectedIndex !== null
            ? currentIndex === i
              ? row[selectedIndex]
              : mappedRowData[rowIndex]
              ? mappedRowData[rowIndex][i] || null
              : row[selectedIndex]
            : mappedRowData[rowIndex]
            ? mappedRowData[rowIndex][i]
            : null
        );
        if (
          currentIndex === i &&
          tempFormErrorData &&
          tempFormErrorData?.length > 0
        ) {
          const headerName = bulkTripHeaders[i]?.header;
          if (
            tempFormErrorData[rowIndex]?.error &&
            tempFormErrorData[rowIndex]?.error[headerName]
          ) {
            delete tempFormErrorData[rowIndex]["error"][headerName];
          }
        }
      }
      updatedData.push(newRow);
    }

    setMappedRowData(updatedData);
  };

  const handleDropdownChange = (headerIndex, selectedOption) => {
    const newSelectedIndices = [...selectedIndices];
    newSelectedIndices[headerIndex] = selectedOption;
    setSelectedIndices(newSelectedIndices);
    updateTableData(newSelectedIndices, headerIndex);
  };

  const handleValidation = (data, callbackFn) => {
    try {
      if (data) {
        setFormWithError(null);
        setLoading((prev) => ({
          ...prev,
          validating: true,
        }));
        const payloadArrayData = [];
        // Preparing payload ********
        if (mappedRowData && mappedRowData?.length) {
          mappedRowData?.map((row, rIndex) => {
            let singleRow = {};
            bulkTripHeaders?.map((header, index) => {
              if (header?.header == "Viapoints") {
                singleRow[header?.header] =
                  row[index] === null || row[index] === undefined
                    ? ""
                    : Array.isArray(row[index])
                    ? row[index]?.join(",")
                    : row[index];
              } else if (
                header?.header == "Viapoint STA" ||
                header?.header == "Viapoint STD"
              ) {
                let finalVal;

                if (row[index] === null || row[index] === undefined) {
                  finalVal = "";
                } else if (Array.isArray(row[index])) {
                  const formattedDatesString = row[index]
                    ?.map((date) =>
                      date ? format(date, "yyyy-MM-dd'T'HH:mm:ss'Z'") : ""
                    )
                    ?.join(",");
                  finalVal = formattedDatesString;
                } else {
                  if (!isNaN(row[index])) {
                    const parsedDate = new Date(
                      (row[index] - 25569) * 86400 * 1000
                    );
                    const formattedDate = format(
                      parsedDate,
                      "yyyy-MM-dd'T'HH:mm:ss'Z'"
                    );
                    finalVal = formattedDate;
                  } else {
                    finalVal = row[index];
                  }
                }
                singleRow[header?.header] = finalVal;
              } else if (
                header?.header == "Source STD" ||
                header?.header == "Destination STA" ||
                header?.header == "Trip Schedule Date"
              ) {
                let finalVal;

                if (row[index] === null || row[index] === undefined) {
                  finalVal = "";
                } else if (row[index] instanceof Date) {
                  const formattedDate = format(
                    row[index],
                    "yyyy-MM-dd'T'HH:mm:ss'Z'"
                  );
                  finalVal = formattedDate;
                } else {
                  const parsedDate = isValidDate(row[index]?.toString());

                  if (parsedDate) {
                    const formattedDate = format(
                      parsedDate,
                      "yyyy-MM-dd'T'HH:mm:ss'Z'"
                    );
                    finalVal = formattedDate;
                  } else {
                    finalVal = row[index] || "";
                  }
                }
                singleRow[header?.header] = finalVal;
              } else {
                singleRow[header?.header] =
                  row[index] === null || row[index] === undefined
                    ? ""
                    : row[index]?.toString();
              }
            });
            payloadArrayData?.push(singleRow);
          });

          // validating ***********
          let hasInvalidDates = false;
          let hasInvalidScheduleDates = false;
          payloadArrayData?.forEach((row, rowIndex) => {
            if ("Trip Schedule Date" in row) {
              const tripScheduleDate = row["Trip Schedule Date"];
              if (
                tripScheduleDate !== undefined &&
                tripScheduleDate !== null &&
                tripScheduleDate !== ""
              ) {
                const parsedDate = isValidDate(tripScheduleDate);
                if (!parsedDate) {
                  hasInvalidDates = true;
                  payloadArrayData[rowIndex] = {
                    ...payloadArrayData[rowIndex],
                    error: {
                      "Trip Schedule Date": "Trip Schedule Date is not valid",
                    },
                  };
                }
              }
            }
            if ("Source STD" in row) {
              const tripScheduleDate = row["Source STD"];

              if (
                tripScheduleDate !== undefined &&
                tripScheduleDate !== null &&
                tripScheduleDate !== ""
              ) {
                const parsedDate = isValidDate(tripScheduleDate);
                if (!parsedDate) {
                  hasInvalidDates = true;
                  payloadArrayData[rowIndex] = {
                    ...payloadArrayData[rowIndex],
                    error: {
                      "Source STD": "Source STD is not valid",
                    },
                  };
                }
              }
            }
            if ("Destination STA" in row) {
              const tripScheduleDate = row["Destination STA"];
              if (
                tripScheduleDate !== undefined &&
                tripScheduleDate !== null &&
                tripScheduleDate !== ""
              ) {
                const parsedDate = isValidDate(tripScheduleDate);
                if (!parsedDate) {
                  hasInvalidDates = true;
                  payloadArrayData[rowIndex] = {
                    ...payloadArrayData[rowIndex],
                    error: {
                      "Destination STA": "Destination STA is not valid",
                    },
                  };
                }
              }
            }
          });

          if (!hasInvalidDates && !hasInvalidScheduleDates) {
            // All dates are valid, proceed with API call
            const payload = {
              tripType: data?.tripType?.value,
              fileType: "custom",
              data: payloadArrayData,
            };

            dispatch(
              checkBulkTripData(payload, (res) => {
                if (res && res?.success) {
                  setFormWithError(null);
                } else if (res && !res?.success && res?.data) {
                  let tempData = [];
                  let tempMapped = mappedRowData;
                  if (res?.data?.length > 0) {
                    res?.data?.map((row, i) => {
                      let tempRow = row;
                      if (row?.error) {
                        if (row?.error["Viapoints"]) {
                          const viaIndex = bulkTripHeaders?.findIndex(
                            (_) => _?.header == "Viapoints"
                          );
                          const staIndex = bulkTripHeaders?.findIndex(
                            (_) => _?.header == "Viapoint STA"
                          );
                          const stdIndex = bulkTripHeaders?.findIndex(
                            (_) => _?.header == "Viapoint STD"
                          );
                          tempMapped[i][viaIndex] = null;
                          tempMapped[i][staIndex] = [];
                          tempMapped[i][stdIndex] = [];
                          tempRow.error = {
                            ...tempRow.error,
                            "Viapoint STA": "Viapoint STA not valid",
                            "Viapoint STD": "Viapoint STD not valid",
                          };
                        }
                        if (row?.error["Source Geofence"]) {
                          tempRow.error = {
                            ...tempRow.error,
                            "Source STD": "Source STD not valid",
                          };
                        }
                        if (row?.error["Destination"]) {
                          tempRow.error = {
                            ...tempRow.error,
                            "Destination STA": "Destination STA not valid",
                          };
                        }
                      }
                      tempData?.push(tempRow);
                    });
                  }
                  setFormWithError([...tempData]);
                  setMappedRowData([...tempMapped]);
                  if (selectedTripType?.value === "withRoute") {
                    dispatch(
                      getAllRoutes({
                        selection: ["routeName", "isSchedulesExists"],
                      })
                    );
                    dispatch(getRouteSchedculeData());
                  } else if (selectedTripType?.value === "withoutRoute") {
                    dispatch(getAllGeofences({ selection: ["name"] }));
                  }
                }
                setLoading((prev) => ({
                  ...prev,
                  validating: false,
                }));
              })
            );
          } else {
            // Set form errors with updated payloadArrayData
            setFormWithError([...payloadArrayData]);
            setLoading((prev) => ({
              ...prev,
              validating: false,
            }));
          }
        } else {
          callbackFn();
          setLoading((prev) => ({
            ...prev,
            validating: false,
          }));
        }
      }
    } catch (e) {
      setLoading((prev) => ({
        ...prev,
        validating: false,
      }));
      console.log("error while validating: ", e);
    }

    callbackFn();
  };

  const handleCreateTrips = (data) => {
    try {
      setLoading((prev) => ({
        ...prev,
        main: true,
      }));
      const payloadArrayData = [];
      mappedRowData?.map((row, rIndex) => {
        let singleRow = {};
        bulkTripHeaders?.map((header, index) => {
          if (header?.header == "Viapoints") {
            singleRow[header?.key] =
              row[index] === null || row[index] === undefined
                ? ""
                : Array.isArray(row[index])
                ? row[index]?.join(",")
                : row[index];
          } else if (
            header?.header == "Viapoint STA" ||
            header?.header == "Viapoint STD"
          ) {
            let finalVal;

            if (row[index] === null || row[index] === undefined) {
              finalVal = "";
            } else if (Array.isArray(row[index])) {
              const formattedDatesString = row[index]
                ?.map((date) =>
                  date ? format(date, "yyyy-MM-dd'T'HH:mm:ss'Z'") : ""
                )
                ?.join(",");
              finalVal = formattedDatesString;
            } else {
              if (!isNaN(row[index])) {
                const parsedDate = new Date(
                  (row[index] - 25569) * 86400 * 1000
                );
                const formattedDate = format(
                  parsedDate,
                  "yyyy-MM-dd'T'HH:mm:ss'Z'"
                );
                finalVal = formattedDate;
              } else {
                finalVal = row[index];
              }
            }
            singleRow[header?.key] = finalVal;
          } else if (
            header?.header == "Source STD" ||
            header?.header == "Destination STA" ||
            header?.header == "Trip Schedule Date"
          ) {
            let finalVal;

            if (row[index] === null || row[index] === undefined) {
              finalVal = "";
            } else if (row[index] instanceof Date) {
              const formattedDate = format(
                row[index],
                "yyyy-MM-dd'T'HH:mm:ss'Z'"
              );
              finalVal = formattedDate;
            } else {
              const parsedDate = isValidDate(row[index]?.toString());

              if (parsedDate) {
                const formattedDate = format(
                  parsedDate,
                  "yyyy-MM-dd'T'HH:mm:ss'Z'"
                );
                finalVal = formattedDate;
              } else {
                finalVal = row[index] || "";
              }
            }
            singleRow[header?.key] = finalVal;
          }
          // else if (header?.header == "Trip Schedule Date") {
          //   let finalVal;

          //   if (row[index] === null || row[index] === undefined) {
          //     finalVal = "";
          //   } else {
          //     if (!isNaN(row[index])) {
          //       const parsedDate = new Date(
          //         (row[index] - 25569) * 86400 * 1000
          //       );
          //       const formattedDate = format(
          //         parsedDate,
          //         "yyyy-MM-dd'T'HH:mm:ss'Z'"
          //       );
          //       finalVal = formattedDate;
          //     } else {
          //       finalVal = row[index];
          //     }
          //   }
          //   singleRow[header?.key] = finalVal;
          // }
          else {
            singleRow[header?.key] =
              row[index] === null || row[index] === undefined
                ? ""
                : row[index]?.toString();
          }
        });
        payloadArrayData?.push(singleRow);
      });

      const payload = {
        tripType: data?.tripType,
        fileType: data?.fileType,
        data: payloadArrayData,
      };
      setLoading((prev) => ({
        ...prev,
        bulkTrip: true,
      }));

      dispatch(
        createBulkTrip(payload, (res) => {
          sendCustomEventAnalytics("trips", "Trip Bulk Upload", "Trip Bulk Upload")
          if (res && res?.success) {
            history.push("/dashboard/trips");
          }
          setLoading((prev) => ({
            ...prev,
            main: false,
          }));
        })
      );
    } catch (e) {
      setLoading((prev) => ({
        ...prev,
        bulkTrip: false,
      }));
      console.log("error in creating trip: ", e);
    }
  };

  const handleSubmitBulkTrips = (data) => {
    try {
      if (data) {
        setFormWithError(null);
        setLoading((prev) => ({
          ...prev,
          main: true,
        }));
        const payloadArrayData = [];
        // Preparing payload ********
        mappedRowData?.map((row, rIndex) => {
          let singleRow = {};
          bulkTripHeaders?.map((header, index) => {
            if (header?.header == "Viapoints") {
              singleRow[header?.header] =
                row[index] === null || row[index] === undefined
                  ? ""
                  : Array.isArray(row[index])
                  ? row[index]?.join(",")
                  : row[index];
            } else if (
              header?.header == "Viapoint STA" ||
              header?.header == "Viapoint STD"
            ) {
              let finalVal;

              if (row[index] === null || row[index] === undefined) {
                finalVal = "";
              } else if (Array.isArray(row[index])) {
                const formattedDatesString = row[index]
                  ?.map((date) =>
                    date ? format(date, "yyyy-MM-dd'T'HH:mm:ss'Z'") : ""
                  )
                  ?.join(",");
                finalVal = formattedDatesString;
              } else {
                if (!isNaN(row[index])) {
                  const parsedDate = new Date(
                    (row[index] - 25569) * 86400 * 1000
                  );
                  const formattedDate = format(
                    parsedDate,
                    "yyyy-MM-dd'T'HH:mm:ss'Z'"
                  );
                  finalVal = formattedDate;
                } else {
                  finalVal = row[index];
                  // const formattedDatesString = row[index]?.split(",")?.length > 0 ? row[index]?.split(",")?.map((date) => {
                  //   if(date && isValidDate(date)){
                  //     return format(isValidDate(date), "yyyy-MM-dd'T'HH:mm:ss'Z'")
                  //   }else{
                  //     return ""
                  //   }
                  // }) : row[index];

                  // finalVal = formattedDatesString;
                }
              }
              singleRow[header?.header] = finalVal;
            } else if (
              header?.header == "Source STD" ||
              header?.header == "Destination STA" ||
              header?.header == "Trip Schedule Date"
            ) {
              let finalVal;

              if (row[index] === null || row[index] === undefined) {
                finalVal = "";
              } else if (row[index] instanceof Date) {
                const formattedDate = format(
                  row[index],
                  "yyyy-MM-dd'T'HH:mm:ss'Z'"
                );
                finalVal = formattedDate;
              } else {
                const parsedDate = isValidDate(row[index]?.toString());

                if (parsedDate) {
                  const formattedDate = format(
                    parsedDate,
                    "yyyy-MM-dd'T'HH:mm:ss'Z'"
                  );
                  finalVal = formattedDate;
                } else {
                  finalVal = row[index] || "";
                }
              }
              singleRow[header?.header] = finalVal;
            }
            // else if (header?.header == "Trip Schedule Date") {
            //   // let finalVal;
            //   // if (row[index] === null || row[index] === undefined) {
            //   //   finalVal = "";
            //   // } else {
            //   //   if (!isNaN(row[index])) {
            //   //     const parsedDate = new Date(
            //   //       (row[index] - 25569) * 86400 * 1000
            //   //     );
            //   //     const formattedDate = format(
            //   //       parsedDate,
            //   //       "yyyy-MM-dd'T'HH:mm:ss'Z'"
            //   //     );
            //   //     finalVal = formattedDate;
            //   //   } else {
            //   //     finalVal = row[index];
            //   //   }
            //   // }
            //   // singleRow[header?.header] = finalVal;
            //   let finalVal;

            //   if (row[index] === null || row[index] === undefined) {
            //     finalVal = "";
            //   } else {
            //     const parsedDate = isValidDate(row[index]?.toString());

            //     if (parsedDate) {
            //       const formattedDate = format(
            //         parsedDate,
            //         "yyyy-MM-dd'T'HH:mm:ss'Z'"
            //       );
            //       finalVal = formattedDate;
            //     } else {
            //       finalVal = row[index] || "";
            //     }
            //   }
            //   singleRow[header?.header] = finalVal;
            // }
            else {
              singleRow[header?.header] =
                row[index] === null || row[index] === undefined
                  ? ""
                  : row[index]?.toString();
            }
          });
          payloadArrayData?.push(singleRow);
        });

        // validating ***********
        let hasInvalidDates = false;
        let hasInvalidScheduleDates = false;
        payloadArrayData?.forEach((row, rowIndex) => {
          if ("Trip Schedule Date" in row) {
            const tripScheduleDate = row["Trip Schedule Date"];
            if (
              tripScheduleDate !== undefined &&
              tripScheduleDate !== null &&
              tripScheduleDate !== ""
            ) {
              const parsedDate = isValidDate(tripScheduleDate);
              if (!parsedDate) {
                hasInvalidDates = true;
                payloadArrayData[rowIndex] = {
                  ...payloadArrayData[rowIndex],
                  error: {
                    "Trip Schedule Date": "Trip Schedule Date is not valid",
                  },
                };
              }
            }
          }
          if ("Source STD" in row) {
            const tripScheduleDate = row["Source STD"];

            if (
              tripScheduleDate !== undefined &&
              tripScheduleDate !== null &&
              tripScheduleDate !== ""
            ) {
              const parsedDate = isValidDate(tripScheduleDate);
              if (!parsedDate) {
                hasInvalidDates = true;
                payloadArrayData[rowIndex] = {
                  ...payloadArrayData[rowIndex],
                  error: {
                    "Source STD": "Source STD is not valid",
                  },
                };
              }
            }
          }
          if ("Destination STA" in row) {
            const tripScheduleDate = row["Destination STA"];
            if (
              tripScheduleDate !== undefined &&
              tripScheduleDate !== null &&
              tripScheduleDate !== ""
            ) {
              const parsedDate = isValidDate(tripScheduleDate);
              if (!parsedDate) {
                hasInvalidDates = true;
                payloadArrayData[rowIndex] = {
                  ...payloadArrayData[rowIndex],
                  error: {
                    "Destination STA": "Destination STA is not valid",
                  },
                };
              }
            }
          }
          if ("Viapoint STA" in row) {
            const viapoints = row["Viapoints"];
            const viapointDates = row["Viapoint STA"]?.toString();
            if (
              viapoints &&
              viapointDates !== undefined &&
              viapointDates !== null &&
              viapointDates !== ""
            ) {
              const viapointsArray = viapoints?.split(",");
              const viaStaArray = viapointDates?.split(",");

              if (viapointsArray?.length === viaStaArray?.length) {
                // console.log("Lengths match!");
              } else {
                hasInvalidScheduleDates = true;
                // array of viapoints + array of STA
                let tempMapped = mappedRowData;
                const viaIndex = bulkTripHeaders?.findIndex(
                  (_) => _?.header == "Viapoints"
                );
                const staIndex = bulkTripHeaders?.findIndex(
                  (_) => _?.header == "Viapoint STA"
                );
                // tempMapped[rowIndex][viaIndex] = [...viapointsArray];
                tempMapped[rowIndex][staIndex] = viapointsArray?.map((_) => "");
                setMappedRowData([...tempMapped]);
                payloadArrayData[rowIndex] = {
                  ...payloadArrayData[rowIndex],
                  error: {
                    "Viapoint STA":
                      "Viapoint STA should be in sync with viapoints",
                  },
                };
              }
            }
          }
          if ("Viapoint STD" in row) {
            const viapoints = row["Viapoints"];
            const viapointDates = row["Viapoint STD"];
            if (
              viapoints &&
              viapointDates !== undefined &&
              viapointDates !== null &&
              viapointDates !== ""
            ) {
              const viapointsArray = viapoints?.split(",");
              const viaStdArray = viapointDates?.split(",");

              if (viapointsArray?.length === viaStdArray?.length) {
                // console.log("Lengths match!");
              } else {
                hasInvalidScheduleDates = true;
                // array of viapoints + array of STA
                let tempMapped = mappedRowData;
                const viaIndex = bulkTripHeaders?.findIndex(
                  (_) => _?.header == "Viapoints"
                );
                const stdIndex = bulkTripHeaders?.findIndex(
                  (_) => _?.header == "Viapoint STD"
                );
                // tempMapped[rowIndex][viaIndex] = [...viapointsArray];
                tempMapped[rowIndex][stdIndex] = viapointsArray?.map((_) => "");
                setMappedRowData([...tempMapped]);
                payloadArrayData[rowIndex] = {
                  ...payloadArrayData[rowIndex],
                  error: {
                    "Viapoint STD":
                      "Viapoint STD should be in sync with viapoints",
                  },
                };
              }
            }
          }
        });

        if (!hasInvalidDates && !hasInvalidScheduleDates) {
          // All dates are valid, proceed with API call
          const payload = {
            tripType: data?.tripType,
            fileType: data?.fileType,
            data: payloadArrayData,
          };

          dispatch(
            checkBulkTripData(payload, (res) => {
              if (res && res?.success) {
                setFormWithError(null);
                handleCreateTrips(data);
              } else if (res && !res?.success && res?.data) {
                let tempData = [];
                let tempMapped = mappedRowData;
                if (res?.data?.length > 0) {
                  res?.data?.map((row, i) => {
                    let tempRow = row;
                    if (row?.error) {
                      if (row?.error["Viapoints"]) {
                        const viaIndex = bulkTripHeaders?.findIndex(
                          (_) => _?.header == "Viapoints"
                        );
                        const staIndex = bulkTripHeaders?.findIndex(
                          (_) => _?.header == "Viapoint STA"
                        );
                        const stdIndex = bulkTripHeaders?.findIndex(
                          (_) => _?.header == "Viapoint STD"
                        );
                        tempMapped[i][viaIndex] = null;
                        tempMapped[i][staIndex] = [];
                        tempMapped[i][stdIndex] = [];
                        tempRow.error = {
                          ...tempRow.error,
                          "Viapoint STA": "Viapoint STA not valid",
                          "Viapoint STD": "Viapoint STD not valid",
                        };
                      }
                      if (row?.error["Source Geofence"]) {
                        tempRow.error = {
                          ...tempRow.error,
                          "Source STD": "Source STD not valid",
                        };
                      }
                      if (row?.error["Destination"]) {
                        tempRow.error = {
                          ...tempRow.error,
                          "Destination STA": "Destination STA not valid",
                        };
                      }
                    }
                    tempData?.push(tempRow);
                  });
                }
                setFormWithError([...tempData]);
                setMappedRowData([...tempMapped]);
                if (selectedTripType === "withRoute") {
                  dispatch(
                    getAllRoutes({
                      selection: ["routeName", "isSchedulesExists"],
                    })
                  );
                  dispatch(getRouteSchedculeData());
                } else if (selectedTripType === "withoutRoute") {
                  dispatch(getAllGeofences({ selection: ["name"] }));
                }
              }
              setLoading((prev) => ({
                ...prev,
                main: false,
              }));
            })
          );
        } else {
          // Set form errors with updated payloadArrayData
          setFormWithError([...payloadArrayData]);
          setLoading((prev) => ({
            ...prev,
            main: false,
          }));
        }
      }
    } catch (e) {
      setLoading((prev) => ({
        ...prev,
        main: false,
      }));
      console.log("error while validating: ", e);
    }
  };

  const checkDate = (cellVal, dateFormat = "DD/MM/YYYY") => {
    let parsedDate;
    // isValidDate(cellVal) || !isNaN(new Date(cellVal));
    parsedDate = isValidDate(cellVal)
      ? isValidDate(cellVal)
      : !isNaN(new Date(cellVal))
      ? new Date(cellVal)
      : null;

    if (parsedDate && currentUserDetail) {
      const formattedDate = format(parsedDate, "yyyy-MM-dd hh:mm:ss a");
      // const formattedDate = convertIntoGivenTimezone(
      //   parsedDate,
      //   currentUserDetail?.timeZone?.offset,
      //   dateFormat
      // );
      return <>{formattedDate}</>;
    } else {
      return <>{cellVal ? <>{cellVal}</> : ""}</>;
    }
  };

  const checkScheduleDate = (cellVal, rowIndex, cellIndex) => {
    let scheduleArray;
    if (cellVal) {
      if (typeof cellVal === "string") {
        scheduleArray = cellVal?.split(",");
      } else if (Array?.isArray(cellVal)) {
        scheduleArray = cellVal;
      }
    } else {
      scheduleArray = [];
    }
    // const staArray = cellVal && typeof cellVal === "string" ? cellVal?.split(',') : [];
    const allDates = scheduleArray?.map((date) => {
      if (!date) {
        return null;
      } else {
        return isValidDate(date) || new Date(date);
      }
    });
    const areAllValidDates = allDates?.every(
      (_) => _ !== null || _ !== undefined
    );
    return (
      <>
        {allDates &&
          allDates?.map((val, i) => {
            if (val) {
              const formattedDate = val
                ? // ? convertIntoGivenTimezone(
                  //     val,
                  //     currentUserDetail?.timeZone?.offset,
                  //     "DD/MM/YYYY hh:mm:ss A"
                  //   )
                  format(val, "yyyy-MM-dd hh:mm:ss a")
                : "";
              return `${formattedDate}, `;
            } else {
              return scheduleArray[i] ? `${scheduleArray[i]}, ` : "";
            }
          })}
      </>
    );
    // if (areAllValidDates) {
    //   const formattedDate = allDates
    //     ?.map((date) => format(date, "yyyy-MM-dd:HH:mm:ss"))
    //     ?.join(", ");
    //   return <>{formattedDate}</>;
    // } else {
    //   return <>{cellVal ? <>{cellVal}</> : ""}</>;
    // }
  };

  function excelSerialToJSDate(serial) {
    const utcDays = Math.floor(serial - 25569);
    const utcValue = utcDays * 86400; // seconds since epoch

    const date = new Date(utcValue * 1000); // convert to milliseconds
    const hours = Math.floor((serial % 1) * 24);
    const minutes = Math.floor(((serial % 1) * 24 * 60) % 60);
    const seconds = Math.floor(((serial % 1) * 24 * 60 * 60) % 60);

    date.setHours(hours, minutes, seconds, 0);
    return date;
  }

  const isValidDate = (dateString, rowIndex, cellIndex) => {
    const dateFormats = [
      "yyyy/MM/dd",
      "MM/dd/yyyy",
      "M/d/yyyy",
      "d/M/yyyy",
      "dd/MM/yyyy", // Year/Month/Day
      "yyyy-MM-dd",
      "M-d-yyyy",
      "MM-dd-yyyy",
      "d-M-yyyy",
      "dd-MM-yyyy", // Year-Month-Day
      "yyyy.MM.dd",
      "M.d.yyyy",
      "MM.dd.yyyy",
      "d.M.yyyy",
      "dd.MM.yyyy", // Year.Month.Day
      "yyyy-MM-dd'T'HH:mm:ss'Z'",
      "yyyy-MM-dd hh:mm:ss a",
      "d-M-yyyy hh:mm:ss a",
      "dd-MM-yyyy hh:mm:ss a",
      "MM-dd-yyyy hh:mm:ss a",
      "MM/dd/yyyy hh:mm:ss a",
      "MM/dd/yyyy  hh:mm:ss a",
      "dd/MM/yyyy hh:mm:ss a",
      "dd/MM/yyyy  hh:mm:ss a",
    ];
    if (dateString && !isNaN(dateString)) {
      const dateVal = excelSerialToJSDate(dateString);
      if (isValid(dateVal)) {
        return dateVal;
      }

      return null;
    } else {
      for (const format of dateFormats) {
        const parsedDate = parse(dateString, format, new Date());

        if (isValid(parsedDate)) {
          return parsedDate;
        }
      }

      return null;
    }
  };

  function updateArrays(viapoints, sta, std) {
    // Create a dictionary to map locations to their indices in sta and std
    const locationIndexMap = new Map(
      viapoints.map((location, index) => [location, index])
    );

    // Update sta and std based on selected locations
    const updatedSta = [];
    const updatedStd = [];
    for (const location of viapoints) {
      const index = locationIndexMap.get(location);
      if (index !== undefined) {
        updatedSta.push(Array?.isArray(sta) ? sta[index] || "" : ""); // Correctly use the index from the map
        updatedStd.push(Array?.isArray(std) ? std[index] || "" : "");
      } else {
        updatedSta.push("");
        updatedStd.push("");
      }
    }

    return [updatedSta, updatedStd];
  }

  const handleRenderSelect = (rowIndex, cellIndex, tableHeader, cellValue) => {
    const wordsToCheck = [
      "Route Name",
      "Route Schedule",
      "Viapoints",
      "Source Geofence",
      "Destination",
      "Trip Schedule Date",
      "Viapoint STA",
      "Viapoint STD",
      "GR Number",
      "Destination STA",
      "Source STD",
    ];

    const sourceIndex =
      bulkTripHeaders &&
      bulkTripHeaders?.findIndex((_) => _?.header == "Source Geofence");
    const destinationIndex =
      bulkTripHeaders &&
      bulkTripHeaders?.findIndex((_) => _?.header == "Destination");
    const viaIndex =
      bulkTripHeaders &&
      bulkTripHeaders?.findIndex((_) => _?.header == "Viapoints");

    if (tableHeader.match(new RegExp(wordsToCheck[0], "i"))) {
      const labelKey = "routeName";
      const val = mappedRowData[rowIndex][cellIndex];
      const routeOptions =
        filteredRoutes?.index === rowIndex
          ? filteredRoutes?.options
          : allRoutes || [];
      return (
        <Tooltip
          placement="top"
          arrow
          title={val && val?.length > 10 ? val : ""}
        >
          <Box>
            <CustomSelect
              options={routeOptions}
              value={mappedRowData[rowIndex][cellIndex] || null}
              label={tableHeader}
              labelKey={labelKey}
              onChange={(e, newVal) => {
                const tempMapped = mappedRowData;
                tempMapped[rowIndex][cellIndex] = newVal?.routeName;
                setMappedRowData([...tempMapped]);
                if (newVal?.isSchedulesExists) {
                  setLoading((prev) => ({ ...prev, dropdownLoading: true })); //doubtful
                  dispatch(
                    getRouteSchedculeData({ routeId: newVal?._id }, (res) => {
                      if (res && res?.success) {
                        setrouteScheduleIndex(rowIndex);
                      }
                      setLoading((prev) => ({
                        ...prev,
                        dropdownLoading: false,
                      }));
                    })
                  );
                }
              }}
              onSearch={() => {
                handleSearchRoute(rowIndex);
              }}
              onInputChange={(e) => {
                if (e?.type === "change") {
                  if (e?.target?.value === "")
                    handleSearchRoute(rowIndex, true);
                  setSearchField((prev) => ({
                    ...prev,
                    index: rowIndex,
                    value: e?.target?.value?.trim(),
                  }));
                }
              }}
              searchValue={
                searchField?.index === rowIndex
                  ? searchField?.value && searchField?.value !== ""
                    ? searchField?.value
                    : null
                  : ""
              }
              selectStylesOverride={selectStylesOverride(
                isDarkThemeEnabledSelector
              )}
              extraClasses={{ minWidth: "210px" }}
              getOptionLabel={(option) => {
                if (typeof option === "string") {
                  return routeOptions?.length > 0
                    ? routeOptions?.find((_) => _?.routeName === option)
                        ?.routeName || ""
                    : "";
                } else {
                  return cellValue ? option[labelKey] : "";
                }
              }}
            />
          </Box>
        </Tooltip>
      );
    } else if (tableHeader.match(new RegExp(wordsToCheck[1], "i"))) {
      const labelKey = "schedule";
      let routeId = formWithError[rowIndex]?.routeId ?? null;
      const val = mappedRowData[rowIndex][cellIndex];
      return (
        <Tooltip
          placement="top"
          arrow
          title={val && val?.length > 10 ? val : ""}
        >
          <Box>
            <CustomSelect
              options={
                routeScheduleIndex === rowIndex
                  ? selectedRouteSchedules || []
                  : []
              }
              value={mappedRowData[rowIndex][cellIndex] || null}
              label={tableHeader}
              labelKey="schedule"
              selectStylesOverride={selectStylesOverride(
                isDarkThemeEnabledSelector
              )}
              loading={
                routeScheduleIndex === rowIndex
                  ? loading?.dropdownLoading
                  : false
              }
              onFocus={() => {
                if (routeId) {
                  setrouteScheduleIndex(null);
                  setLoading((prev) => ({ ...prev, dropdownLoading: true }));
                  setTimeout(() => {
                    dispatch(
                      getRouteSchedculeData({ routeId: routeId }, (res) => {
                        if (res && res?.success) {
                          setrouteScheduleIndex(rowIndex);
                        }
                        setLoading((prev) => ({
                          ...prev,
                          dropdownLoading: false,
                        }));
                      })
                    );
                  }, [400]);
                }
              }}
              onChange={(e, newVal) => {
                const tempMapped = mappedRowData;
                tempMapped[rowIndex][cellIndex] = newVal?.schedule;
                setMappedRowData([...tempMapped]);
              }}
              extraClasses={{ minWidth: "210px" }}
            />
          </Box>
        </Tooltip>
      );
    } else if (tableHeader.match(new RegExp(wordsToCheck[2], "i"))) {
      const labelKey = "name";
      const options =
        filteredViapoints?.index === rowIndex
          ? filteredViapoints?.options
          : geofenceListing || [];
      const val =
        mappedRowData[rowIndex][cellIndex] &&
        Array.isArray(mappedRowData[rowIndex][cellIndex]) &&
        mappedRowData[rowIndex][cellIndex]?.length > 2
          ? mappedRowData[rowIndex][cellIndex]?.join(", ")
          : mappedRowData[rowIndex][cellIndex] || null;
      return (
        <Box
          display="flex"
          minWidth="340px"
          maxWidth="400px"
          py={0.5}
          columnGap={1}
        >
          <Box display="flex" alignItems="center">
            <Box>
              <FormControl variant="outlined" size="small" fullWidth>
                <InputLabel
                  sx={{ fontSize: "13px" }}
                >{`${tableHeader}`}</InputLabel>
                <Select
                  sx={{
                    ...inputClasses.filterField,
                    height: "36px",
                    width: "100%",
                    minWidth: "110px",
                    maxWidth: "110px",
                    ...selectStylesOverride,
                  }}
                  MenuProps={{ autoFocus: false, sx: { maxHeight: "450px" } }}
                  multiple
                  value={
                    mappedRowData[rowIndex][cellIndex] &&
                    Array.isArray(mappedRowData[rowIndex][cellIndex])
                      ? mappedRowData[rowIndex][cellIndex]
                      : []
                  }
                  renderValue={(selected) => {
                    if (selected && Array.isArray(selected)) {
                      return selected
                        ?.map(
                          (geoName) =>
                            options.find((option) => option?.name === geoName)
                              ?.name
                        )
                        ?.join(", ");
                    }
                  }}
                  onChange={(data) => {
                    const {
                      target: { value },
                    } = data;
                    const tempMapped = mappedRowData;
                    tempMapped[rowIndex][cellIndex] = value;
                    let sta = tempMapped[rowIndex][cellIndex + 1];
                    let std = tempMapped[rowIndex][cellIndex + 2];
                    const [updatedSta, updatedStd] = updateArrays(
                      value,
                      sta,
                      std
                    );
                    tempMapped[rowIndex][cellIndex + 1] = [...updatedSta];
                    tempMapped[rowIndex][cellIndex + 2] = [...updatedStd];
                    setMappedRowData([...tempMapped]);
                  }}
                  onClose={() => {
                    // setSearchField((prev) => ({
                    //   ...prev,
                    //   index: null,
                    //   value: null
                    // }))
                  }}
                  input={<OutlinedInput label={`Select ${tableHeader}`} />}
                >
                  <ListSubheader>
                    <Box
                      display="flex"
                      alignItems="center"
                      gap="3px"
                      sx={{ paddingTop: 0.5 }}
                    >
                      <TextField
                        size="small"
                        autoFocus
                        placeholder="Type to search..."
                        fullWidth
                        value={
                          searchField?.index === rowIndex
                            ? searchField?.value
                            : ""
                        }
                        onChange={(e) => {
                          if (e?.type === "change") {
                            if (e?.target?.value === "")
                              handleSearchViapoints(rowIndex, true);
                            setSearchField((prev) => ({
                              ...prev,
                              index: rowIndex,
                              value: e?.target?.value?.trim(),
                            }));
                          }
                        }}
                        onKeyDown={(e) => {
                          if (e.key !== "Escape") {
                            // prevents autoselecting item while typing (default Select behaviour)
                            e.stopPropagation();
                          }
                        }}
                      />
                      <IconButton
                        size="small"
                        onClick={() => {
                          handleSearchViapoints(rowIndex);
                        }}
                      >
                        <Search />
                      </IconButton>
                    </Box>
                  </ListSubheader>
                  {options.map((option) => (
                    <MenuItem
                      sx={{
                        width: "270px",
                        padding: "0px",
                        overflow: "hidden",
                        border: "1px solid #F8F8F8",
                      }}
                      key={option?._id}
                      disabled={
                        option?.name == mappedRowData[rowIndex][sourceIndex] ||
                        option?.name ==
                          mappedRowData[rowIndex][destinationIndex]
                      }
                      value={option?.name}
                    >
                      <Checkbox
                        size="small"
                        checked={
                          mappedRowData[rowIndex][cellIndex]?.indexOf(
                            option?.name
                          ) > -1
                        }
                      />
                      <ListItemText
                        primary={
                          <Typography
                            variant="body2"
                            style={{ fontSize: "12px" }}
                          >
                            {option?.name}
                          </Typography>
                        }
                      />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Box>
          {/* -------- */}
          {mappedRowData[rowIndex][cellIndex] &&
            mappedRowData[rowIndex][cellIndex]?.length > 0 && (
              <Box
                width="100%"
                display="flex"
                flexDirection="column"
                borderLeft={`1px solid ${themeType.default.themeGray}`}
                pl={1}
              >
                {Array.isArray(mappedRowData[rowIndex][cellIndex]) &&
                  mappedRowData[rowIndex][cellIndex]?.map((viapoint, i) => {
                    return (
                      <Box
                        height="46px"
                        py={1}
                        borderBottom={
                          i == mappedRowData[rowIndex][cellIndex]?.length - 1
                            ? ""
                            : `1px solid ${themeType.default.themeGray}`
                        }
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Tooltip
                          placement="top"
                          arrow
                          title={
                            viapoint && viapoint?.length > 45 ? viapoint : ""
                          }
                        >
                          <Typography
                            sx={{ ...textClasses.normalText }}
                            key={i}
                          >
                            {viapoint && viapoint?.length > 45
                              ? getShortText(viapoint)
                              : viapoint || ""}
                          </Typography>
                        </Tooltip>
                      </Box>
                    );
                  })}
              </Box>
            )}
        </Box>
      );
    } else if (
      tableHeader.match(new RegExp(wordsToCheck[3], "i")) ||
      tableHeader === wordsToCheck[4]
    ) {
      const labelKey = "name";
      const val = mappedRowData[rowIndex][cellIndex];
      return (
        <Tooltip
          placement="top"
          arrow
          title={val && val?.length > 10 ? val : ""}
        >
          <Box>
            <CustomSelect
              options={
                filteredGeofences?.rowIndex === rowIndex &&
                filteredGeofences?.cellIndex === cellIndex
                  ? filteredGeofences?.options
                  : geofenceListing || []
              }
              label={tableHeader}
              value={mappedRowData[rowIndex][cellIndex] || null}
              labelKey="name"
              onChange={(e, newVal, reason) => {
                if (
                  reason === "clear" &&
                  filteredGeofences?.rowIndex === rowIndex &&
                  filteredGeofences?.cellIndex === cellIndex
                ) {
                  handleSearchGeofences(rowIndex, cellIndex, true);
                }
                const tempMapped = mappedRowData;
                tempMapped[rowIndex][cellIndex] = newVal?.name;
                setMappedRowData([...tempMapped]);
              }}
              onSearch={() => {
                handleSearchGeofences(rowIndex, cellIndex);
              }}
              onInputChange={(e) => {
                if (e?.type === "change") {
                  if (e?.target?.value === "")
                    handleSearchGeofences(rowIndex, cellIndex, true);
                  setGeofenceSearchField((prev) => ({
                    ...prev,
                    rowIndex: rowIndex,
                    cellIndex: cellIndex,
                    value: e?.target?.value?.trim(),
                  }));
                }
              }}
              searchValue={
                geofenceSearchField?.rowIndex === rowIndex &&
                geofenceSearchField?.cellIndex === cellIndex
                  ? geofenceSearchField?.value &&
                    geofenceSearchField?.value !== ""
                    ? geofenceSearchField?.value
                    : null
                  : ""
              }
              getOptionLabel={(option) => {
                if (typeof option === "string") {
                  return geofenceListing?.length > 0
                    ? geofenceListing?.find((_) => _?.name === option)?.name ||
                        ""
                    : "";
                } else {
                  return cellValue ? option[labelKey] || "" : "";
                }
              }}
              selectStylesOverride={selectStylesOverride(
                isDarkThemeEnabledSelector
              )}
              extraClasses={{ minWidth: "210px" }}
              getOptionDisabled={(option) => {
                let sourceDestination =
                  option?.name ===
                  mappedRowData[rowIndex][
                    tableHeader === wordsToCheck[4]
                      ? sourceIndex
                      : destinationIndex
                  ];
                let viapoints = false;

                if (
                  mappedRowData[rowIndex][viaIndex] &&
                  Array.isArray(mappedRowData[rowIndex][viaIndex])
                ) {
                  if (
                    mappedRowData[rowIndex][viaIndex]?.includes(option?.name)
                  ) {
                    viapoints = true;
                  }
                } else if (
                  mappedRowData[rowIndex][viaIndex] &&
                  typeof mappedRowData[rowIndex][viaIndex] == "string"
                ) {
                  if (
                    mappedRowData[rowIndex][viaIndex]?.split(",")?.length > 0 &&
                    mappedRowData[rowIndex][viaIndex]
                      ?.split(",")
                      ?.includes(option?.name)
                  ) {
                    viapoints = true;
                  }
                }
                return sourceDestination || viapoints;
              }}
            />
          </Box>
        </Tooltip>
      );
    } else if (tableHeader.match(new RegExp(wordsToCheck[5], "i"))) {
      return (
        // <DatePicker
        //   label={tableHeader}
        //   defaultValue={new Date()}
        //   value={
        //     mappedRowData[rowIndex][cellIndex]
        //       ? new Date(mappedRowData[rowIndex][cellIndex])
        //       : null
        //   }
        //   className="customDatePicker"
        //   fullWidth
        //   onChange={(newValue) => {
        //     const formattedDate = format(newValue, "yyyy-MM-dd'T'HH:mm:ss'Z'");
        //     const tempMapped = mappedRowData;
        //     tempMapped[rowIndex][cellIndex] = formattedDate;
        //     setMappedRowData([...tempMapped]);
        //   }}
        //   format="dd/MM/yyyy"
        //   sx={{
        //     ...inputClasses.filterField,
        //     fontSize: "8px",
        //     minWidth: "210px",
        //     width: "100%",
        //   }}
        //   slotProps={{
        //     textField: { size: "small" },
        //     actionBar: {
        //       actions: ["clear"],
        //     },
        //   }}
        // />
        <DateTimePicker
          label={tableHeader}
          value={
            mappedRowData[rowIndex][cellIndex]
              ? new Date(mappedRowData[rowIndex][cellIndex])
              : null
          }
          onChange={(date) => {
            const formattedDate = date;
            // const formattedDate = format(date, "yyyy-MM-dd'T'HH:mm:ss'Z'");
            const tempMapped = mappedRowData;
            tempMapped[rowIndex][cellIndex] = formattedDate;
            setMappedRowData([...tempMapped]);
          }}
          format="dd-MM-yyyy HH:mm:ss"
          className="customDatePicker"
          sx={{
            width: "100%",
            ...selectStylesOverride,
          }}
          slotProps={dateTimePickerSlotProps(
            inputClasses,
            isDarkThemeEnabledSelector,
            { minWidth: "210px" }
          )}
        />
      );
    } else if (tableHeader.match(new RegExp(wordsToCheck[6], "i"))) {
      return (
        <>
          {mappedRowData[rowIndex][cellIndex] &&
          Array.isArray(mappedRowData[rowIndex][cellIndex]) &&
          mappedRowData[rowIndex][cellIndex]?.length > 0 ? (
            <Box display="flex" flexDirection="column" gap={2} py={0.5}>
              {mappedRowData[rowIndex][cellIndex]?.map((row, i) => {
                return (
                  <DateTimePicker
                    label={tableHeader}
                    value={
                      mappedRowData[rowIndex][cellIndex][i]
                        ? new Date(mappedRowData[rowIndex][cellIndex][i])
                        : null
                    }
                    onChange={(date) => {
                      const tempMapped = mappedRowData;
                      tempMapped[rowIndex][cellIndex][i] = date;
                      setMappedRowData([...tempMapped]);
                    }}
                    className="customDatePicker"
                    sx={{
                      width: "100%",
                      ...selectStylesOverride,
                    }}
                    slotProps={dateTimePickerSlotProps(
                      inputClasses,
                      isDarkThemeEnabledSelector,
                      { minWidth: "210px" }
                    )}
                  />
                );
              })}
            </Box>
          ) : null}
        </>
      );
    } else if (tableHeader.match(new RegExp(wordsToCheck[7], "i"))) {
      return (
        <>
          {mappedRowData[rowIndex][cellIndex] &&
          Array.isArray(mappedRowData[rowIndex][cellIndex]) &&
          mappedRowData[rowIndex][cellIndex]?.length > 0 ? (
            <Box display="flex" flexDirection="column" gap={2} py={0.5}>
              {mappedRowData[rowIndex][cellIndex]?.map((row, i) => {
                return (
                  <DateTimePicker
                    label={tableHeader}
                    value={
                      mappedRowData[rowIndex][cellIndex][i]
                        ? new Date(mappedRowData[rowIndex][cellIndex][i])
                        : null
                    }
                    onChange={(date) => {
                      const tempMapped = mappedRowData;
                      tempMapped[rowIndex][cellIndex][i] = date;
                      setMappedRowData([...tempMapped]);
                    }}
                    className="customDatePicker"
                    sx={{
                      width: "100%",
                      ...selectStylesOverride,
                    }}
                    slotProps={dateTimePickerSlotProps(
                      inputClasses,
                      isDarkThemeEnabledSelector,
                      { minWidth: "210px" }
                    )}
                  />
                );
              })}
            </Box>
          ) : null}
        </>
      );
    } else if (
      tableHeader.match(new RegExp("Destination STA")) ||
      tableHeader.match(new RegExp("Source STD"))
    ) {
      return (
        <DateTimePicker
          label={tableHeader}
          value={
            mappedRowData[rowIndex][cellIndex]
              ? new Date(mappedRowData[rowIndex][cellIndex])
              : null
          }
          onChange={(date) => {
            const formattedDate = date;
            // const formattedDate = format(date, "yyyy-MM-dd'T'HH:mm:ss'Z'");
            const tempMapped = mappedRowData;
            tempMapped[rowIndex][cellIndex] = formattedDate;
            setMappedRowData([...tempMapped]);
          }}
          format="dd-MM-yyyy HH:mm:ss"
          className="customDatePicker"
          sx={{
            width: "100%",
            ...selectStylesOverride,
          }}
          slotProps={dateTimePickerSlotProps(
            inputClasses,
            isDarkThemeEnabledSelector,
            { minWidth: "210px" }
          )}
        />
      );
    } else if (tableHeader.match(new RegExp("GR Number"))) {
      return (
        <CustomInput
          label={tableHeader}
          value={mappedRowData[rowIndex][cellIndex] || ""}
          placeholder={tableHeader}
          sx={{ ...inputClasses.filterField }}
          selectStylesOverride={selectStylesOverride(
            isDarkThemeEnabledSelector
          )}
          inputClasses={inputClasses}
          onChange={(e) => {
            const tempMapped = mappedRowData;
            tempMapped[rowIndex][cellIndex] = e?.target?.value;
            setMappedRowData([...tempMapped]);
          }}
        />
      );
    } else {
      return (
        <CustomInput
          label={tableHeader}
          value={mappedRowData[rowIndex][cellIndex] || ""}
          placeholder={tableHeader}
          sx={{ ...inputClasses.filterField }}
          selectStylesOverride={selectStylesOverride(
            isDarkThemeEnabledSelector
          )}
          inputClasses={inputClasses}
          onChange={(e) => {
            const tempMapped = mappedRowData;
            tempMapped[rowIndex][cellIndex] = e?.target?.value;
            setMappedRowData([...tempMapped]);
          }}
        />
      );
    }
  };

  const handleSearchViapoints = (rowIndex, empty) => {
    if (empty === true) {
      dispatch(
        getAllFilteredGeofences(
          {
            selection: ["name"],
            searchValue: "",
          },
          (res) => {
            if (res && res?.success) {
              setFilteredViapoints((prev) => ({
                ...prev,
                index: rowIndex,
                options: res?.data,
              }));
            }
          }
        )
      );
    } else {
      if (searchField?.value || searchField?.value == "")
        dispatch(
          getAllFilteredGeofences(
            {
              selection: ["name"],
              searchValue: searchField?.value,
            },
            (res) => {
              if (res && res?.success) {
                setFilteredViapoints((prev) => ({
                  ...prev,
                  index: rowIndex,
                  options: res?.data,
                }));
              }
            }
          )
        );
    }
  };

  const handleSearchGeofences = (rowIndex, cellIndex, empty) => {
    if (empty === true) {
      dispatch(
        getAllFilteredGeofences(
          {
            selection: ["name"],
            searchValue: "",
          },
          (res) => {
            if (res && res?.success) {
              setFilteredGeofences((prev) => ({
                ...prev,
                rowIndex: rowIndex,
                cellIndex: cellIndex,
                options: res?.data,
              }));
            }
          }
        )
      );
    } else {
      if (geofenceSearchField?.value || geofenceSearchField?.value == "")
        dispatch(
          getAllFilteredGeofences(
            {
              selection: ["name"],
              searchValue: geofenceSearchField?.value,
            },
            (res) => {
              if (res && res?.success) {
                setFilteredGeofences((prev) => ({
                  ...prev,
                  cellIndex: cellIndex,
                  rowIndex: rowIndex,
                  options: res?.data,
                }));
              }
            }
          )
        );
    }
  };

  const handleSearchRoute = (rowIndex, empty) => {
    if (empty === true) {
      dispatch(
        getAllFilteredRoutes(
          {
            selection: ["routeName"],
            searchValue: "",
          },
          (res) => {
            if (res && res?.success) {
              setFilteredRoutes((prev) => ({
                ...prev,
                index: rowIndex,
                options: res?.data,
              }));
            }
          }
        )
      );
    } else {
      if (searchField?.value || searchField?.value == "")
        dispatch(
          getAllFilteredRoutes(
            {
              selection: ["routeName"],
              searchValue: searchField?.value,
            },
            (res) => {
              if (res && res?.success) {
                setFilteredRoutes((prev) => ({
                  ...prev,
                  index: rowIndex,
                  options: res?.data,
                }));
              }
            }
          )
        );
    }
  };

  /* Effects */
  React.useEffect(() => {
    setSelectedFile(null);
    setShellState(Math.random().toString(36));
    setMappedRowData([]);
    setSelectedIndices([]);
    setShowTablePreview(false);
    setRowData([]);
    setHeaders([]);
    dispatch(flushBulkHeaders());
    dispatch(flushScheduleData());
    setFormWithError(null);
    reset();
    setLoading({
      table: false,
      main: false,
      dropdownLoading: false,
    });
    setFilteredViapoints({
      index: null,
      options: [],
    });
    setFilteredRoutes({
      index: null,
      options: [],
    });
    setSearchField({
      index: null,
      value: null,
    });
    setFilteredGeofences({
      rowIndex: null,
      cellIndex: null,
      options: [],
    });
    setGeofenceSearchField({
      rowIndex: null,
      cellIndex: null,
      value: null,
    });
  }, []);

  React.useEffect(() => {
    if (bulkTripHeaders && bulkTripHeaders?.length > 0) {
      setSelectedIndices(new Array(bulkTripHeaders.length).fill(null));
    }
  }, [bulkTripHeaders]);

  return ( 
    <Box height="100%" display="flex" flexDirection="column" gap={1}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box>
          <Typography sx={{ ...textClasses.cardTitle }} data-testid="title">
            Trip Upload
          </Typography>
          <Breadcrumbs sx={{ mb: 1 }}>
            <Typography
              sx={{
                ...textClasses.normalText,
                fontSize: "12px",
                color: themeType.default.themeOrange,
              }}
            >
              <Link
                style={{
                  color: themeType.default.themeOrange,
                  textDecoration: "none",
                }}
                to="/dashboard/trips"
              >
                Trips
              </Link>
            </Typography>

            <Typography sx={{ ...textClasses.normalText, fontSize: "12px" }}>
              Trip Upload
            </Typography>
          </Breadcrumbs>
        </Box>
      </Box>
      <Box
        height="85vh"
        my={0}
        p={1}
        borderRadius="10px"
        border="1px solid #E0E0E0"
        position="relative"
      >
        <Grid item xs={12} mb={1} display="flex" alignItems="center" gap={2}>
          <Box width="100%" maxWidth="227px">
            <Controller
              name="tripType"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: "Trip Type is required",
                },
              }}
              render={({ field: { onChange, value, name } }) => {
                return (
                  <CustomSelect
                    selectStylesOverride={selectStylesOverride(
                      isDarkThemeEnabledSelector
                    )}
                    value={value}
                    onChange={(e, newVal) => {
                      setValue("fileType", undefined);
                      onChange(newVal?.value);
                      setValue("tripType", newVal?.value);
                      setMappedRowData([]);
                      setFormWithError(null);
                      setSelectedIndices(
                        bulkTripHeaders
                          ? new Array(bulkTripHeaders.length).fill(null)
                          : []
                      );
                      setFilteredViapoints({
                        index: null,
                        options: [],
                      });
                      setFilteredRoutes({
                        index: null,
                        options: [],
                      });
                      setSearchField({
                        index: null,
                        value: null,
                      });
                      setFilteredGeofences({
                        rowIndex: null,
                        cellIndex: null,
                        options: [],
                      });
                      setGeofenceSearchField({
                        rowIndex: null,
                        cellIndex: null,
                        value: null,
                      });
                      setShowTablePreview(false);
                      if (newVal) {
                        setLoading((prev) => ({
                          ...prev,
                          table: true,
                        }));
                        if (newVal) {
                          if (newVal?.value === "withRoute") {
                            dispatch(
                              getAllHeadersForBulkAddTrips(
                                {
                                  tripType: newVal?.value,
                                },
                                () => {
                                  if (newVal?.value) {
                                    setShowTablePreview(true);
                                  }
                                  setLoading((prev) => ({
                                    ...prev,
                                    table: false,
                                  }));
                                }
                              )
                            );
                          }
                        }
                      }
                    }}
                    name={name}
                    label="Trip Type"
                    errors={errors}
                    getOptionLabel={(option) => {
                      if (typeof option === "string") {
                        return tripTypeOptions?.find((_) => _?.value == value)
                          ?.label;
                      } else {
                        return option?.label;
                      }
                    }}
                    labelKey="label"
                    options={tripTypeOptions}
                  />
                );
              }}
            />
          </Box>
          {selectedTripType && selectedTripType === "withoutRoute" && (
            <Box width="100%" maxWidth="227px">
              <Controller
                name="fileType"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: "File Type is required",
                  },
                }}
                render={({ field: { onChange, value, name } }) => {
                  return (
                    <CustomSelect
                      selectStylesOverride={selectStylesOverride(
                        isDarkThemeEnabledSelector
                      )}
                      value={value}
                      onChange={(e, newVal) => {
                        if (newVal) {
                          setLoading((prev) => ({
                            ...prev,
                            table: true,
                          }));
                          if (newVal) {
                            dispatch(
                              getAllHeadersForBulkAddTrips(
                                {
                                  tripType: selectedTripType,
                                  fileType: newVal?.value,
                                },
                                () => {
                                  if (newVal?.value && !showTablePreview) {
                                    setShowTablePreview(true);
                                  }
                                  setLoading((prev) => ({
                                    ...prev,
                                    table: false,
                                  }));
                                }
                              )
                            );
                          }
                        }
                        onChange(newVal?.value);
                        setMappedRowData([]);
                        setFormWithError(null);
                        setSelectedIndices(
                          bulkTripHeaders
                            ? new Array(bulkTripHeaders.length).fill(null)
                            : []
                        );
                        setFilteredViapoints({
                          index: null,
                          options: [],
                        });
                        setFilteredRoutes({
                          index: null,
                          options: [],
                        });
                        setSearchField({
                          index: null,
                          value: null,
                        });
                        setFilteredGeofences({
                          rowIndex: null,
                          cellIndex: null,
                          options: [],
                        });
                        setGeofenceSearchField({
                          rowIndex: null,
                          cellIndex: null,
                          value: null,
                        });
                      }}
                      name={name}
                      label="File Type"
                      errors={errors}
                      getOptionLabel={(option) => {
                        if (typeof option === "string") {
                          return fileTypeOptions?.find((_) => _?.value == value)
                            ?.label;
                        } else {
                          return option?.label;
                        }
                      }}
                      labelKey="label"
                      options={fileTypeOptions}
                    />
                  );
                }}
              />
            </Box>
          )}
        </Grid>
        {selectedFileType && selectedFileType === "standard" && (
          <Box display="flex" alignItems="flex-start" gap={0.5} mb={1}>
            <InfoOutlined
              style={{
                height: "18px",
                width: "18px",
                marginTop: "3px",
                color: "#ff7200",
              }}
            />
            <Typography variant="caption">
              In standard file format, single trip is divided into multiple rows
              entries based on via points data. Each row should have common trip
              id. source and destination geofence will be based on movement. If
              trip ids are different, then each row will be considered as
              seperate trip. Please contact support team for any query.
            </Typography>
          </Box>
        )}
        {selectedFileType && selectedFileType === "custom" && (
          <Box display="flex" alignItems="center" gap={0.5} mb={1}>
            <InfoOutlined style={{ height: "18px", width: "18px" }} />
            <Typography variant="caption">
              You've selected custom file format
            </Typography>
          </Box>
        )}
        <Grid xs={12} item>
          <Grid item display="flex" alignItems="center" gap={2}>
            <FormControl
              spacing={1}
              sx={{ marginRight: "5px", marginTop: "2px" }}
            >
              <input
                type="file"
                name="upload"
                id="upload"
                className="RF_fileupload-input"
                onChange={(e) => {
                  const uploadedFile = e?.target?.files[0];
                  if (isValidFile(uploadedFile)) {
                    setSelectedFile(uploadedFile);
                    handleUpload(uploadedFile);
                  } else {
                    // Display an error message to the user
                    showToast(
                      "Please select a valid Excel file (.xlsx or .xls).",
                      true
                    );
                  }
                }}
                ref={fileInputRef}
                key={shellState}
              />
            </FormControl>
            <Button
              variant="outlined"
              spacing={0}
              className="RF_outline-btn"
              sx={{ marginRight: "5px", borderRadius: "8px" }}
              disabled={selectedFile == null}
              onClick={handleDeleteFile}
            >
              <Delete />
            </Button>
            {/* <Button
              variant="outlined"
              sx={{
                ...buttonClasses?.lynkitOrangeFill,
                minHeight: "36px",
                minWidth: "140px",
                marginLeft: "10px",
              }}
              startIcon={!loading?.table && <Visibility />}
              disabled={selectedFile === null || loading?.table}
              onClick={handlePreviewTable}
            >
              {loading?.table && (
                <CircularProgress
                  size={20}
                  sx={{
                    color: "#383838",
                  }}
                />
              )}
              {loading?.table ? "Loading..." : "Preview"}
            </Button> */}
          </Grid>
        </Grid>

        {!selectedFile || !showTablePreview ? (
          <Box mt={3}>
            <ImgContainer
              src={noData}
              title="Select a file first"
              body={"Hey, please select a file."}
            />
          </Box>
        ) : (
          <>
            {loading?.main || loading?.validating ? (
              <Grid
                container
                direction={"column"}
                justifyContent={"center"}
                alignItems="center"
                item
                xs={12}
                sx={{ p: 15 }}
              >
                <Grid item>
                  <img src={loaderGif} />
                </Grid>
              </Grid>
            ) : (
              <>
                {/* preview table area ----- */}
                {showTablePreview &&
                  headers?.length > 0 &&
                  rowData?.length > 0 &&
                  bulkTripHeaders &&
                  bulkTripHeaders?.length > 0 && (
                    <Grid
                      xs={12}
                      my={3}
                      sx={{
                        maxHeight: 600,
                        overflowY: "scroll",
                      }}
                    >
                      <Paper
                        className="muitable"
                        sx={{
                          maxWidth: "100%",
                          overflowX: "auto",
                          maxHeight: "60vh",
                          background: tableClasses.dark.backgroundColor,
                        }}
                      >
                        <table
                          style={{
                            width: "100%",
                            maxHeight: "60vh",
                            ...tableClasses.table,
                          }}
                        >
                          <thead
                            style={{
                              backgroundColor: themeType?.default?.themeGray,
                              position: "sticky",
                              top: "-1px",
                              zIndex: 3,
                            }}
                          >
                            <tr>
                              {bulkTripHeaders?.map((header, index) => (
                                <th
                                  key={index}
                                  style={{
                                    ...textClasses.boldText,
                                    padding: "6px 7.5px",
                                    borderRight: "1px solid #0000002B",
                                  }}
                                >
                                  {`${header?.header}${
                                    header?.isMandatory ? "*" : ""
                                  }`}
                                </th>
                              ))}
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              style={{
                                borderBottom: "1px solid #0000002B",
                                position: "sticky",
                                top: "32.4px",
                                zIndex: 3,
                              }}
                            >
                              {bulkTripHeaders?.map((header, i) => (
                                <td
                                  key={i}
                                  style={{
                                    padding: "6px 7.5px",
                                    background: isDarkThemeEnabledSelector
                                      ? "rgb(23, 23, 23)"
                                      : "#ffe6d1",
                                    borderRight: "1px solid #0000002B",
                                    ...textClasses.normalText,
                                  }}
                                >
                                  <CustomSelect
                                    options={headers}
                                    label="Select Key"
                                    labelKey="label"
                                    onChange={(e, newVal) => {
                                      handleDropdownChange(i, newVal?.index);
                                    }}
                                    selectStylesOverride={selectStylesOverride(
                                      isDarkThemeEnabledSelector
                                    )}
                                    extraClasses={{ minWidth: "130px" }}
                                    // onChange={(e, newVal) => handleKeySelection(i, newVal)}
                                  />
                                </td>
                              ))}
                            </tr>
                            {mappedRowData &&
                              mappedRowData?.length > 0 &&
                              mappedRowData?.map((row, rowIndex) => (
                                <tr
                                  key={rowIndex}
                                  style={{
                                    borderBottom: "1px solid #0000002B",
                                    height: "1px",
                                  }}
                                >
                                  {row.map((cell, cellIndex) => (
                                    <>
                                      {formWithError &&
                                      formWithError?.length > 0 &&
                                      formWithError[rowIndex]?.error &&
                                      formWithError[rowIndex]?.error[
                                        bulkTripHeaders[cellIndex]?.header
                                      ] ? (
                                        <td
                                          key={cellIndex}
                                          style={{
                                            padding: "8px 8px",
                                            borderRight: "1px solid #0000002B",
                                            maxWidth: "400px",
                                            height: "100%",
                                          }}
                                        >
                                          <Box height="100%" width="100%">
                                            {handleRenderSelect(
                                              rowIndex,
                                              cellIndex,
                                              bulkTripHeaders[cellIndex]
                                                ?.header,
                                              cell === null ||
                                                cell === undefined
                                                ? " "
                                                : cell?.toString()
                                            )}
                                          </Box>
                                        </td>
                                      ) : (
                                        <td
                                          key={cellIndex}
                                          style={{
                                            padding: "8px 8px",
                                            borderRight: "1px solid #0000002B",
                                            maxWidth: "250px",
                                          }}
                                        >
                                          <Typography
                                            sx={{
                                              whiteSpace: "pre-wrap",
                                              wordWrap: "break-word",
                                              ...textClasses.normalText,
                                            }}
                                          >
                                            <>
                                              {bulkTripHeaders[cellIndex]
                                                ?.header ===
                                              "Trip Schedule Date" ? (
                                                <>
                                                  {cell === null ||
                                                  cell === undefined
                                                    ? " "
                                                    : checkDate(
                                                        cell?.toString(),
                                                        "DD/MM/YYYY hh:mm:ss A"
                                                      )}
                                                </>
                                              ) : bulkTripHeaders[cellIndex]
                                                  ?.header === "Source STD" ||
                                                bulkTripHeaders[cellIndex]
                                                  ?.header ===
                                                  "Destination STA" ? (
                                                <>
                                                  {cell === null ||
                                                  cell === undefined
                                                    ? " "
                                                    : checkDate(
                                                        cell?.toString(),
                                                        "DD/MM/YYYY hh:mm:ss A"
                                                      )}
                                                </>
                                              ) : bulkTripHeaders[cellIndex]
                                                  ?.header === "Viapoint STA" ||
                                                bulkTripHeaders[cellIndex]
                                                  ?.header ===
                                                  "Viapoint STD" ? (
                                                <>
                                                  {cell === null ||
                                                  cell === undefined
                                                    ? " "
                                                    : checkScheduleDate(
                                                        cell?.toString(),
                                                        rowIndex,
                                                        cellIndex
                                                      )}
                                                </>
                                              ) : (
                                                <>
                                                  {cell === null ||
                                                  cell === undefined
                                                    ? " "
                                                    : cell?.toString()}
                                                </>
                                              )}
                                            </>
                                          </Typography>
                                        </td>
                                      )}
                                    </>
                                  ))}
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      </Paper>
                    </Grid>
                  )}
              </>
            )}
          </>
        )}

        <Box
          position="absolute"
          bottom="0"
          mt={1}
          pb={1}
          width="100%"
          display="flex"
          alignItems="center"
          justifyContent="center"
          gap={2}
        >
          <Button
            variant="contained"
            sx={{
              ...buttonClasses?.lynkitBlackFill,
              height: "33px",
              minWidth: "140px",
            }}
          >
            Cancel
          </Button>
          {/* {mappedRowData?.length > 0 && (
            <Button
              variant="contained"
              sx={{
                ...buttonClasses?.lynkitOrangeFill,
                minWidth: "140px",
                color: "#000000",
                background: "#ffbf00",
                borderColor: "#ffbf00",
                "&:hover": { background: "#ffbf00", borderColor: "#ffbf00" },
              }}
              startIcon={(!loading?.table && !loading?.validating) && (mappedRowData?.length ? <Done /> : <Visibility />)}
              disabled={selectedFile === null || loading?.table || loading?.validating}
              onClick={handleSubmit(handlePreviewTable)}
            >
              {loading?.table ||
                loading?.validating && (
                  <CircularProgress
                    size={20}
                    sx={{
                      color: "#383838",
                    }}
                  />
                )}
              {loading?.table || loading?.validating ? "Loading..." : mappedRowData?.length ? "Validate" : "Preview"}
            </Button>
          )} */}
          <Button
            variant="contained"
            sx={{ ...buttonClasses?.lynkitOrangeFill, minWidth: "140px" }}
            onClick={handleSubmit(handleSubmitBulkTrips)}
            disabled={
              loading?.main ||
              loading?.validating ||
              mappedRowData?.length === 0
            }
          >
            {loading?.main && (
              <CircularProgress
                size={20}
                sx={{
                  color: "#383838",
                }}
              />
            )}
            {loading?.main ? "Loading..." : "Submit"}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default BulkCreateTrip;
