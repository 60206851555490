import React from "react";
import "./board_styles.scss";
import { useDroppable } from "@dnd-kit/core";
import { Button, Input } from "reactstrap";

import { DndContext, rectIntersection } from "@dnd-kit/core";
import Draggable from "./Draggable";
import { arrayMove, SortableContext, sortableKeyboardCoordinates, rectSortingStrategy, verticalListSortingStrategy, } from "@dnd-kit/sortable";

function Droppable(props) {
  const {
    column,
    parent,
    time,
    columns,
    deleteColumn,
    i,
    setColumnName,
    group,
    onChange,
    deletable
  } = props;

  const { isOver, setNodeRef ,attributes, listeners,} = useDroppable({ id: column.id });

  // const style = { border: parent && parent == column.id ? "1px solid #f57c0057" : undefined };

  const handleSortEnd = (newItems) => {
    // const newColumns = columns.map((col) =>
    //   col.id === props.id ? { ...col, items: newItems } : col
    // );
    // onChange(newColumns);
  };

  return (
    <div
      ref={setNodeRef}
      // style={style}
      className="m-2 colmn"
      key={column.id}
      id={column.id}
    >
      <div className="m-1 d-flex">
        <div className="dnd_border">
          <Input type="text" name="columnName" placeholder="Enter Column Name"
            value={column.columnName || ""} onChange={(e) => setColumnName(e, i)}
            className="colInp" />
        </div>
        {columns.length > 1 && (
          <Button color="danger"  className="my-2 btn-sm px-2 mx-1"
            onClick={() => deleteColumn(column.id)}
          >
            <i className="fa fa-trash"></i>
          </Button>
        )}
      </div>
      <div className="m-1" >
        <div className="dnd_border" style={{ minWidth: "264px", minHeight: "320px", maxWidth: "264px" }} >
            {group && group.map((item, index) => (
                <div className="d-flex flex-column scrol" key={getRndInteger(1, time)}>
                  <Draggable deletable={deletable} key={item.key + '_in'} id={item.key + '_in'} parent={column.id} index={index} {...props}>
                    {item.label} 
                  </Draggable> 
                </div>
              ))}
        </div>
      </div>
    </div>
  );
}

export default Droppable;

function getRndInteger(min, max) {
  return Math.floor(Math.random() * (max - min) ) + min;
}