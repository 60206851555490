export const havePermission = (userDetail = {}, module, permissionKey) => {
  const mergedPermissions =
    Object.keys(userDetail)?.length > 0
      ? preparePermissions(
          userDetail?.userRole?.permissions,
          userDetail?.permissions
        )
      : null; 

  if (
    mergedPermissions &&
    Object.keys(mergedPermissions)?.length > 0 &&
    mergedPermissions[module] &&
    mergedPermissions[module][permissionKey] &&
    mergedPermissions[module][permissionKey].value
  ) {
    return true;
  } else {
    return false;
  }
};

export const checkSettingsPermissions = (userDetail = {}, module) => {
  const mergedPermissions =
    Object.keys(userDetail)?.length > 0
      ? preparePermissions(
          userDetail?.userRole?.permissions,
          userDetail?.permissions
        )
      : null;

  if (mergedPermissions && Object?.keys(mergedPermissions)?.length > 0) {
    const finalCheck =
      mergedPermissions[module] &&
      Object.keys(mergedPermissions[module])?.some((key) => {
        if (key?.startsWith("view")) {
          return mergedPermissions[module][key]?.value === true;
        }
        return false;
      });
    return finalCheck;
  }
};

/**
 * @description Merge same group permissions from role permissions object and individual permissions
 * @param {*} rolePermissions Individual permissions*
 * @param {*} userProjectIndividualPermissions Role permissions*
 * @returns
 */
export const preparePermissions = (
  rolePermissions = {},
  individualPermissions = {}
) => {
  const mergedPermissions = {};

  // Combine unique categories from both objects
  const allCategories = new Set([
    ...Object.keys(rolePermissions),
    ...Object.keys(individualPermissions),
  ]);

  // Traverse all categories
  for (const category of allCategories) {
    mergedPermissions[category] = {};

    // Handle missing categories in either object
    const roleCategoryData = rolePermissions[category] || {};
    const individualCategoryData = individualPermissions[category] || {};

    mergedPermissions[category]["label"] = roleCategoryData?.label || individualCategoryData?.label;

    // Iterate over possible permissions in both objects
    for (const permission of Object.keys(roleCategoryData).concat(
      Object.keys(individualCategoryData)
    )) {
      const roleValue = roleCategoryData[permission]?.value;
      const individualValue = individualCategoryData[permission]?.value;

      // Include the permission if it's true in either object or both
      if (roleValue || individualValue) {
        mergedPermissions[category][permission] = {
          label:
            roleCategoryData[permission]?.label ||
            individualCategoryData[permission]?.label,
          value: true,
        };
      }
    }
  }
  
  return mergedPermissions;
  // let userProjectPermissionsSet = {};

  // const isUserProjectIndividualPermissionsExists =
  //   !!userProjectIndividualPermissions &&
  //   Object.keys(userProjectIndividualPermissions).length > 0;

  // if (isUserProjectIndividualPermissionsExists) {
  //   Object.keys(userProjectIndividualPermissions).forEach(
  //     (groupedPermissionKey) => {
  //       const isKeyExistsInUserPermissionSet =
  //         !!userProjectPermissionsSet[groupedPermissionKey] &&
  //         Object.keys(userProjectPermissionsSet[groupedPermissionKey]).length >
  //           0;
  //       if (isKeyExistsInUserPermissionSet) {
  //         /** Add individual permissions in the existing user permission set */
  //         userProjectPermissionsSet[groupedPermissionKey] = {
  //           ...userProjectPermissionsSet[groupedPermissionKey],
  //           ...userProjectIndividualPermissions[groupedPermissionKey],
  //         };
  //       } else {
  //         /** Add individual permissions in the new user permission set */
  //         userProjectPermissionsSet = {
  //           ...userProjectPermissionsSet,
  //           [groupedPermissionKey]:
  //             userProjectIndividualPermissions[groupedPermissionKey],
  //         };
  //       }
  //     }
  //   );
  // }

  // const isRolePermissionsExists =
  //   !!rolePermissions && Object.keys(rolePermissions).length > 0;

  // if (isRolePermissionsExists) {
  //   Object.keys(rolePermissions).forEach((groupedPermissionKey) => {
  //     const isKeyExistsInUserPermissionSet =
  //       !!userProjectPermissionsSet[groupedPermissionKey] &&
  //       Object.keys(userProjectPermissionsSet[groupedPermissionKey]).length > 0;
  //     if (isKeyExistsInUserPermissionSet) {
  //       /** Add roles permissions in the existing user permission set */
  //       userProjectPermissionsSet[groupedPermissionKey] = {
  //         ...userProjectPermissionsSet[groupedPermissionKey],
  //         ...rolePermissions[groupedPermissionKey],
  //       };
  //     } else {
  //       /** Add roles permissions in the new user permission set */
  //       userProjectPermissionsSet = {
  //         ...userProjectPermissionsSet,
  //         [groupedPermissionKey]: rolePermissions[groupedPermissionKey],
  //       };
  //     }
  //   });
  // }

  // return userProjectPermissionsSet;
};
