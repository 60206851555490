import React from 'react'

const DeviceIcon = ({height, width, fill}) => (
    <svg id="Layer_1" data-name="Layer 1" height={height} width={width} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 267.75 262.51">
  <defs>
    <style>{`
      .cls-device-1 {
        stroke-width: 0px;
        fill: ${fill} !important;
      }
    `}</style>
  </defs>
  <rect class="cls-device-1" x="80.53" y="102.04" width="79.94" height="79.94" rx="9.23" ry="9.23"/>
  <rect class="cls-device-1" x="132.5" y="224.15" width="32.1" height="38.36"/>
  <rect class="cls-device-1" x="74.74" y="224.15" width="32.1" height="38.36"/>
  <rect class="cls-device-1" x="81.11" y="21.51" width="32.1" height="38.36"/>
  <path class="cls-device-1" d="m178.38,126.54v48.58c0,13.68-11.09,24.77-24.77,24.77h-66.22c-13.68,0-24.77-11.09-24.77-24.77v-66.22c0-13.68,11.09-24.77,24.77-24.77h50.72v-24.25h-73.66c-14.4,0-26.08,11.68-26.08,26.08v112.11c0,14.4,11.68,26.08,26.08,26.08h112.11c14.4,0,26.08-11.68,26.08-26.08v-71.52h-24.25Z"/>
  <rect class="cls-device-1" x="3.13" y="100.87" width="32.1" height="38.36" transform="translate(-100.87 139.23) rotate(-90)"/>
  <rect class="cls-device-1" x="205.79" y="154.76" width="32.1" height="38.36" transform="translate(47.9 395.78) rotate(-90)"/>
  <rect class="cls-device-1" x="3.13" y="154.76" width="32.1" height="38.36" transform="translate(-154.76 193.12) rotate(-90)"/>
  <path class="cls-device-1" d="m228.38,101.54c-1.98-36.17-33.03-63.99-69.2-62.03l1.42,26.26c21.7-1.2,40.35,15.5,41.54,37.21l26.24-1.44Z"/>
  <path class="cls-device-1" d="m162.76,105.15l26.24-1.44c-.79-14.5-13.18-25.6-27.68-24.81l1.44,26.24Z"/>
  <path class="cls-device-1" d="m267.75,99.4C264.58,41.53,214.9-2.99,157.01.16l1.44,26.24c43.42-2.37,80.67,31.02,83.06,74.43l26.24-1.44Z"/>
</svg>
)

export default DeviceIcon