import React, { useEffect, useState } from 'react'
import {
    Modal, Typography, Grid, Button, Card, TextField, FormControlLabel, Checkbox, Select, MenuItem, FormControl, InputLabel, IconButton,
    Table, TableBody, TableCell, FormLabel, TableContainer, TableHead, TableRow, Paper, Dialog, DialogContent
} from '@mui/material'
import { useDispatch, useSelector } from 'react-redux';
import { removeEditField, saveField } from '../../../redux/actions';
import { useForm } from 'react-hook-form';
import theme, { inputClasses, textClasses } from '../../../static/styles/theme';
import { Add, Remove } from '@mui/icons-material';
import { showToast } from '../../../services/functions';
import { selectStylesOverride } from '../../../utils/util';
import CustomModel from '../../Components/CustomModels/CustomModel1';

export default function EditFieldModal({ editField, allRegex }) {

    const isDarkThemeEnabledSelector = useSelector((state) => state.theme.themeDark);
    const themeType = isDarkThemeEnabledSelector ? require("../../../static/styles/theme") : require("../../../static/styles/theme");
    const { buttonClasses } = themeType;
    const components = useSelector(state => state.canvas.components);
    const selectedSubTemplate = useSelector(state => state.canvas.selectedSubTemplate);
    const dispatch = useDispatch();

    const { register, handleSubmit, setValue } = useForm();
    const fieldsSection = editField ? components[editField.sectionId][editField.type] : null;
    let field = null;
    if (fieldsSection) {
        if (editField.sectionId == "attributes" && editField.type == "mandatory") {
            field = fieldsSection.filter(f => f.templateType == selectedSubTemplate)[editField.index];
        }
        else {
            field = fieldsSection.filter(f => f.templateType == selectedSubTemplate)[editField.index];
        }
    }
    // const field = fieldsSection && editField.sectionId .filter(f => f.templateType == selectedSubTemplate)[editField.index] : null;
    const [required, setRequired] = useState(false);
    const [regex, setRegex] = useState("-")
    const [options, setOptions] = useState([]);

    const handleOptionChange = (e, type, i) => {
        let oldOptions = [...options];
        oldOptions[i][type] = e.target.value;
        setOptions(oldOptions);
    }

    const handleOptionAdd = () => {
        const newOption = {
            label: `Option ${options.length + 1}`,
            value: `option ${options.length + 1}`,
        }
        let oldOptions = [...options, newOption];
        setOptions(oldOptions);
    }

    const handleOptionDelete = (i) => {
        let oldOptions = [...options];
        oldOptions.splice(i, 1)
        setOptions(oldOptions);
    }

    const handleRequiredChange = (e) => {
        setRequired(!required);
    }

    const handleCloseModal = () => {
        dispatch(removeEditField())
    }

    const validateOptions = (options) => {
        let status = true;
        let message = "";
        if (!options || options.length <= 1) {
            status = false;
            message = "Alteast 2 options are needed"
        }
        for (let i = 0; i < options.length; i++) {
            const e = options[i];
            if (!e.label || e.label == "" || !e.value || e.value == "") {
                status = false;
                message = "Label & Value of option cannot be blank"
                break;
            }

        }
        return { status, message }
    }

    const handleSave = (data) => {
        let editData = {
            ...data,
            required: required,
        };
        let output = { status: true }
        if (["text", "number", "textarea", "input"].indexOf(field.type) >= 0) {
            editData['regex'] = regex || ""
        }
        if (["select", "checkbox", "radio"].indexOf(field.type) >= 0) {
            editData['values'] = options
            //status, message
            output = validateOptions(options);
        }
        if (!output || !output.status) {
            showToast(output.message || "", true);
        }
        else {
            let newField = { ...field, ...editData };
            // Find index
            let customIndex = editField.index;
            // if(editField.sectionId == "attributes" && editField.type == "mandatory"){
            //     console.log('innnnnnnnnnnnnnnnnnnnn');
            //     let tempI = 0;
            //     const baseFields = components[editField.sectionId][editField.type];
            //     for (let i = 0; i < baseFields.length; i++) {
            //         const f = baseFields[i];
            //         if(f.templateType == selectedSubTemplate){
            //             if(tempI == editField.index){
            //                 customIndex = i;
            //                 break;
            //             }
            //             else{
            //                 tempI++;
            //             }
            //         }

            //     }
            // }
            dispatch(saveField(editField.sectionId, editField.type, customIndex, newField))
            handleCloseModal();
        }

    }

    const handleRegexChange = (e) => {
        setRegex(e.target.value);
    }

    useEffect(() => {
        if (editField) {
            setValue("label", field.label);
            setValue("placeholder", field.placeholder);
            setRequired(field.required ? field.required : false);
            setRegex(field.regex ? field.regex : "");
            if (["select", "checkbox", "radio"].indexOf(field.type) >= 0) {
                setOptions(field.values);
            }
        }
    }, [editField])

    return (
        <>
            {
                field &&
                <CustomModel
                    autoClose={false}
                    show={editField || false}
                    onClose={handleCloseModal}
                    minWidth={860}
                    maxWidth={860}
                    pb={3}
                    pl={3}
                    pr={3}
                    maxHeight={"90vh"}
                    childrenMaxHeight={"90vh"}
                    Title={field.label + " Properties"}
                    viewCloseIcon={editField}
                >


                    <Grid Container>
                        <Grid container justifyContent={"end"} alignItems="end" mt={1}>
                            <FormControlLabel checked={required} control={<Checkbox sx={{ color: theme.themeOrange, "&, &.Mui-checked": { color: theme.themeOrange } }} onChange={handleRequiredChange} size='small' />} label="Mandatory" />
                            {/* <FormControlLabel control={<Checkbox sx={{color: theme.themeOrange, "&, &.Mui-checked": {color: theme.themeOrange}}} size='small' />} label="Hide label"/> */}
                        </Grid>
                        <Grid container sx={{ mt: 1 }} spacing={1}>

                            <Grid item sm={6} xs={12}>
                                <TextField variant='outlined' fullWidth label="Column heading" size='small' sx={{ ...inputClasses.themeBoundary }} required {...register("label", { required: true })} InputLabelProps={{sx: { fontSize: '13px', paddingTop: '2px' }}}/>
                            </Grid>
                            <Grid item sm={6} xs={12}>
                                <TextField variant='outlined' fullWidth label="Placeholder" size='small' sx={{ ...inputClasses.themeBoundary }} required {...register("placeholder", { required: true })} InputLabelProps={{sx: { fontSize: '13px', paddingTop: '2px' }}} />
                            </Grid>
                        </Grid>
                        {
                            ["select", "checkbox", "radio"].indexOf(field.type) >= 0 &&
                            <Grid sx={{ mt: 1 , overflow:'auto', height:'400px' }}>
                                <FormLabel sx={{ ...textClasses.normalText, mt: 2 }}>Options</FormLabel>
                                <TableContainer component={Paper}>
                                    <Table aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Sno</TableCell>
                                                <TableCell align="center">Label</TableCell>
                                                <TableCell align="center">Value</TableCell>
                                                <TableCell align="center">Action</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {
                                                options.map((op, i) => {
                                                    return (
                                                        <TableRow
                                                            key={i}
                                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}

                                                        >
                                                            <TableCell>{i + 1}</TableCell>
                                                            <TableCell>
                                                                <TextField type={"text"} variant="outlined" size='small' sx={{ ...inputClasses.themeBoundary }} fullWidth required value={op.label} onChange={(e) => handleOptionChange(e, "label", i)} />
                                                            </TableCell>
                                                            <TableCell>
                                                                <TextField type={"text"} variant="outlined" size='small' sx={{ ...inputClasses.themeBoundary }} fullWidth required value={op.value} onChange={(e) => handleOptionChange(e, "value", i)} />
                                                            </TableCell>
                                                            <TableCell align='center'>
                                                                <IconButton size='small' sx={{ background: "red" }} onClick={() => handleOptionDelete(i)}>
                                                                    <Remove fontSize='inherit' sx={{ color: "white" }} />
                                                                </IconButton>
                                                            </TableCell>
                                                        </TableRow>
                                                    )
                                                })
                                            }
                                            <TableRow
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}

                                            >
                                                <TableCell>
                                                    <IconButton size='small' sx={{ background: theme.themeOrange }} onClick={handleOptionAdd}>
                                                        <Add fontSize='inherit' sx={{ color: "white" }} />
                                                    </IconButton>
                                                </TableCell>

                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>



                            </Grid>
                        }
                        {/* {
                            ["text", "number", "textarea", "input"].indexOf(field.type) >= 0 &&
                            <Grid container sx={{ mt: 1 }} spacing={1}>
                                <Grid item sm={6} xs={12}>
                                    <FormControl fullWidth size='small'
                                        variant='outlined'
                                        sx={{ ...selectStylesOverride(isDarkThemeEnabledSelector) }}>
                                        <InputLabel sx={{ color: '#585858', fontSize: '14px' }}>Validation</InputLabel>
                                        <Select
                                            label={"Validation"}
                                            value={regex}
                                            onChange={handleRegexChange}
                                        >
                                            {
                                                allRegex.map((r, i) => <MenuItem value={r.regex} key={i}>{r.name}</MenuItem>)
                                            }
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        } */}

                        <Grid container justifyContent="start" alignItems="start" sx={{ mt: 2, mb: 3 }}>

                            <Button variant='outlined' size='small' sx={{ ml: 1, ...buttonClasses.small, minWidth: "110px", height: '34px', borderColor: themeType.default.themeOrange, color: themeType.default.themeOrange }} onClick={handleCloseModal} >
                                Cancel
                            </Button>
                            <Button variant='contained' size='small' sx={{ ml: 1, ...buttonClasses.small, minWidth: "110px", height: '34px', backgroundColor: themeType.default.themeOrange }} onClick={handleSubmit(handleSave)}>
                                Save Changes
                            </Button>
                        </Grid>

                    </Grid>



                </CustomModel>


            }
        </>



        // <>
        //     {
        //         field &&
        //         <Modal
        //             open={editField != null}
        //             onClose={handleCloseModal}
        //         >
        //             <Card sx={style}>
        //                 <Typography variant='h6'>{field.label} Properties</Typography>
        //                 <Grid container justifyContent={"end"} alignItems="end">
        //                     <FormControlLabel checked={required} control={<Checkbox sx={{ color: theme.themeOrange, "&, &.Mui-checked": { color: theme.themeOrange } }} onChange={handleRequiredChange} size='small' />} label="Mandatory" />
        //                     <FormControlLabel control={<Checkbox sx={{ color: theme.themeOrange, "&, &.Mui-checked": { color: theme.themeOrange } }} size='small' />} label="Hide label" />
        //                 </Grid>
        //                 <Grid container sx={{ mt: 1 }} spacing={1}>

        //                     <Grid item sm={6} xs={12}>
        //                         <TextField variant='outlined' fullWidth label="Column heading" size='small' sx={{ ...inputClasses.themeBoundary }} focused required {...register("label", { required: true })} />
        //                     </Grid>
        //                     <Grid item sm={6} xs={12}>
        //                         <TextField variant='outlined' fullWidth label="Placeholder" size='small' sx={{ ...inputClasses.themeBoundary }} focused required {...register("placeholder", { required: true })} />
        //                     </Grid>
        //                 </Grid>
        //                 {
        //                     ["select", "checkbox", "radio"].indexOf(field.type) >= 0 &&
        //                     <Grid sx={{ mt: 1 }}>
        //                         <FormLabel sx={{ ...textClasses.normalText, mt: 2 }}>Options</FormLabel>
        //                         <TableContainer component={Paper}>
        //                             <Table aria-label="simple table">
        //                                 <TableHead>
        //                                 <TableRow>
        //                                     <TableCell>Sno</TableCell>
        //                                     <TableCell align="center">Label</TableCell>
        //                                     <TableCell align="center">Value</TableCell>
        //                                     <TableCell align="center">Action</TableCell>
        //                                 </TableRow>
        //                                 </TableHead>
        //                                 <TableBody>
        //                                     {
        //                                         options.map((op, i) => {
        //                                             return(
        //                                                 <TableRow
        //                                                     key={i}
        //                                                     sx={{ '&:last-child td, &:last-child th': { border: 0 } }}

        //                                                 >
        //                                                     <TableCell>{i + 1}</TableCell>
        //                                                     <TableCell>
        //                                                         <TextField type={"text"} variant="outlined" size='small' sx={{ ...inputClasses.themeBoundary }} fullWidth required value={op.label} onChange={(e) => handleOptionChange(e, "label", i)} />
        //                                                     </TableCell>
        //                                                     <TableCell>
        //                                                         <TextField type={"text"} variant="outlined" size='small' sx={{ ...inputClasses.themeBoundary }} fullWidth required value={op.value} onChange={(e) => handleOptionChange(e, "value", i)} />
        //                                                     </TableCell>
        //                                                     <TableCell align='center'>
        //                                                         <IconButton size='small' sx={{ background: "red" }} onClick={() => handleOptionDelete(i)}>
        //                                                             <Remove fontSize='inherit' sx={{ color: "white" }} />
        //                                                         </IconButton>
        //                                                     </TableCell>
        //                                                 </TableRow>
        //                                                 )
        //                                         })
        //                                     }
        //                                     <TableRow
        //                                         sx={{ '&:last-child td, &:last-child th': { border: 0 } }}

        //                                     >
        //                                         <TableCell>
        //                                             <IconButton size='small' sx={{ background: theme.themeOrange }} onClick={handleOptionAdd}>
        //                                                 <Add fontSize='inherit' sx={{ color: "white" }} />
        //                                             </IconButton>
        //                                         </TableCell>

        //                                     </TableRow>
        //                                 </TableBody>
        //                             </Table>
        //                         </TableContainer>



        //                     </Grid>
        //                 }
        //                 {
        //                     ["text", "number", "textarea", "input"].indexOf(field.type) >= 0 &&
        //                     <Grid container sx={{ mt: 1 }} spacing={1}>
        //                         <Grid item sm={6} xs={12}>
        //                             <FormControl fullWidth size='small'>
        //                                 <InputLabel>Validation</InputLabel>
        //                                 <Select
        //                                     label={"Validation"}
        //                                     value={regex}
        //                                     onChange={handleRegexChange}
        //                                 >
        //                                     <MenuItem value={"-"}>Select a validation</MenuItem>
        //                                     {
        //                                         allRegex.map((r, i) => <MenuItem value={r.regex} key={i}>{r.name}</MenuItem>)
        //                                     }
        //                                 </Select>
        //                             </FormControl>
        //                         </Grid>
        //                     </Grid >
        //                 }

        //                 <Grid container justifyContent="start" alignItems="start" sx={{mt: 2}}>
        //                     <Button variant='outlined' size='small' sx={{borderColor: theme.themeOrange, color: theme.themeOrange, textTransform: "none", "&:hover": {borderColor: theme.themeOrange}}} onClick={handleCloseModal}>
        //                         Cancel
        //                     </Button>
        //                     <Button variant='contained' size='small' className='mui-orange-btn' sx={{ml: 2, textTransform: "none",}} onClick={handleSubmit(handleSave)}>
        //                         Save Changes
        //                     </Button>
        //                 </Grid>
        //             </Card>
        //         </Modal>
        //     }
        // </>

    )
}
