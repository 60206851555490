/* eslint-disable */
// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax
import React, { useState } from "react";
import axios from "axios";
import { Grid } from "@mui/material";
import { Box, Typography } from "@mui/material";
import { useQueryParams } from "../../../utils/utilHooks/useQueryParams";
import { useDispatch, useSelector } from "react-redux";
import { getSharedTripTrackingData , getSharedDeviceTrackingData} from "../../../redux/actions";
import { darkStyles } from "../../../services/constants";
import { URLFORTRIPMAP } from "../../../services/Api";
import Logo from "../../../static/images/login/Lynk.webp";
import loaderGif from "../../../static/images/drawer/loader.gif";
import {
  capitalizeSentence,
  convertIntoGivenTimezone,
} from "../../../services/functions";
import routeIcon from "../../../assets/images/routeIcon.png";
import speed from "../../../assets/images/speed.png";
import distance from "../../../assets/images/distance.png";
import dateTime from "../../../assets/images/dateandtime.png";
import location from "../../../assets/infoWindow/location.png";
import truck from "../../../assets/infoWindow/truck.png";
import battery from "../../../assets/infoWindow/low-battery.png";
import charging from "../../../assets/infoWindow/charging.png";
import tag from "../../../assets/infoWindow/tag.png";
import lock from "../../../assets/infoWindow/lock.png";
import unlock from "../../../assets/infoWindow/unlock.png";
import { locationUrl } from "../../../services/config";
import { ToastContainer } from "react-toastify";

const SharingTrackMap = () => {
  const dispatch = useDispatch();
  let query = useQueryParams();
  let urlIcon = "https://lynktrac.io/lynktracV2/";

  //LOCAL STATES --------
  const [myMap, setMyMap] = React.useState(null);
  const [tripDetails, setTripDetails] = React.useState(null);
  const [shareLoader, setShareLoader] = useState(false);
  const [infoWindow, setInfoWindow] = React.useState(null);
  const [newInfoWindow, setNewInfoWindow] = React.useState(null);

  //SELECTORS ----------
  const { currentUserDetail } = useSelector((state) => state.auth);
  const isDarkTheme = useSelector((state) => state.theme.themeDark);
  const themeType = isDarkTheme
    ? require("../../../static/styles/darktheme")
    : require("../../../static/styles/theme");

  //HANDLERS ----------
  const bgColor = (data) => {
    let lockStatus = data?.lockStatus || "";
    let color = "";
    if (lockStatus === "locked") {
      color = "green";
    } else if (lockStatus === "unlocked") {
      color = "#D2042D";
    } else if (lockStatus === "tampered") {
      color = "#D2042D";
    } else if (lockStatus === "motorUnlocked") {
      color = "#FFAA1D";
    }

    return { color };
  };

  const renderTrackOnMap = (geoJsonObject) => {
    if (geoJsonObject && myMap) {
      if (geoJsonObject?.features?.length >= 2) {
        let lastObject = geoJsonObject?.features[geoJsonObject?.features?.length - 2]
        let lastlat = lastObject?.geometry?.coordinates[1]
        let lastlng = lastObject?.geometry?.coordinates[0]
        myMap.panTo({
          lat: Number(lastlat),
          lng: Number(lastlng),
        });
      }
      else {
        let lastObject = geoJsonObject?.features[0]
        let lastlat = lastObject?.geometry?.coordinates[1]
        let lastlng = lastObject?.geometry?.coordinates[0]
        myMap.panTo({
          lat: Number(lastlat),
          lng: Number(lastlng),
        });
      }

      myMap.data.addGeoJson(geoJsonObject, {
        idPropertyName: "_id",
      });
      myMap.data.setStyle({
        strokeColor: "#ff7200", // Orange color
        strokeWeight: 2,
      });

      myMap.data.forEach((feature, index) => {
        if (feature.getGeometry().getType() === "LineString") {
          const coordinates = feature.getGeometry().getArray();
          const lineSymbol = {
            path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
            scale: 3,
            strokeColor: "#ff7200",
            fillColor: "#ff7200",
          };

          for (let i = 0; i < coordinates.length - 1; i++) {
            let color = "#ff7200";
            const segmentPath = [coordinates[i], coordinates[i + 1]];
            if (feature.getProperty("isColorful")) {
              color = feature.getProperty("color");
            }
            const line = new google.maps.Polyline({
              path: segmentPath,
              icons: [
                {
                  icon: lineSymbol,
                  offset: "50%",
                },
              ],
              strokeColor: color,
              map: myMap,
            });
          }
        }
        else if (feature.getGeometry().getType() === "Point") {
          if (feature.getProperty("icon")) {
            let iconSize = feature.getProperty("size")
              ? feature.getProperty("size")
              : 20;
            let iconImage;
            const featureIcon = feature.getProperty("icon");
            const trackingIconsCheck = "/img/trackingicons";

            if (featureIcon === "Sealed") {
              iconImage = `${urlIcon}trip-icons/start.png`;
            } else if (featureIcon === "Begin_Transit_Journey") {
              iconImage = `${urlIcon}trip-icons/maps.png`;
            } else if (
              featureIcon &&
              featureIcon.includes(trackingIconsCheck)
            ) {
              const splitArray = featureIcon.split(trackingIconsCheck);
              const iconName = splitArray[1] ? splitArray[1] : splitArray[0];
              iconImage = `${urlIcon}trip-icons${iconName}`;
            } else {
              const splitArray = featureIcon ? featureIcon.split("/img") : [];
              const iconName = splitArray[1] ? splitArray[1] : splitArray[0];
              iconImage = `${urlIcon}trip-icons${iconName}`;
            }

            myMap.data.overrideStyle(feature, {
              icon: {
                url: iconImage,
                scaledSize: new google.maps.Size(iconSize, iconSize),
              },
            });
          }
        }
      });

      myMap.data.addListener("click", async (event) => {
        let markerObj = {
          deviceId: event.feature.getProperty("asset_id"),
          packet_id: event.feature.getProperty("packet_id"),
          status: geoJsonObject?.tripDetails?.status,
          eventAfterTampered:
            event.feature.getProperty("icon") ===
              "assets/img/trackingicons/maps_red.png"
              ? true
              : false,
          icon: event.feature.getProperty("icon"),
          gps: geoJsonObject?.tripDetails?.sealingDetails?.gps || '',
          offlineLimit: geoJsonObject?.tripDetails?.limitThreshold?.offlineLimit || '',
        };
        await axios
          .post(
            `${URLFORTRIPMAP}/noAuth/getTripMapPacketData`,
            { ...markerObj },
            {
              headers: {
                Authorization: JSON.parse(localStorage.getItem("token")),
              },
            }
          )
          .then((response) => {
            let data = response?.data?.trackData;
            const lockStatus = data?.lockStatus || "-";
            const batteryPercent = data?.battery + "%" || "-";
            const tripStatus = data?.trip_status || "-";
            const speedVal = data?.speed.toFixed(2) + " kmph" || "-";
            const distanceVal = data?.distance || "-";
            const time =
              convertIntoGivenTimezone(
                data?.time,
                currentUserDetail?.timeZone?.offset
              ) || "-";
            const tripEventStatus = data?.event || null;
            const color = data?.color === "yellow" ? "#FFAA1D" : data?.color;
            const activeDevice = geoJsonObject?.tripDetails?.devices.find(item => item.primary === true);
            const backgroundColor = bgColor(data);
            const payload = {
              latitude: data?.latitude,
              longitude: data?.longitude,
              appType: "GOOGLE",
            };

            axios
              .post(`${locationUrl}fetchAddress`, payload, {
                headers: {
                  "Content-Type": "application/json",
                  authorization: "oLjJEPvkqYloUOfU1aCyAvOBMQKKG9Am",
                },
              })
              .then((response) => {
                // let address = response?.data?.data[0]?.address;
                let address = response && response.data && response.data.data && response.data.data.length > 0 ? response.data.data[0].address : null;
                let infoWindowContent = `<div class='infoDiv'>
              <div class='infoChild1'>
              
              ${tripEventStatus !== "-"
                    ? `<div class='subChild' style="background-color: ${color}" >
                    <img class='lockImg'  src=${truck} alt='' />
                    <span class='deviceLabel'>Trip Event </span>
                    <span class='devicevalue'>${tripEventStatus}</span>
                </div>`
                    : ""
                  }
                  ${lockStatus !== "-"
                    ? `<div class='subChild' style="background-color: ${backgroundColor?.color
                    }">
                <img class='lockImg'  src=${lockStatus === "motorUnlocked" ? unlock : lock
                    } alt='' />
                <span class='deviceLabel'>Lock Status </span>
                <span class='devicevalue'>${lockStatus === "motorUnlocked"
                      ? "Motor Unlocked"
                      : capitalizeSentence(lockStatus)
                    }</span>
              </div>  `
                    : ""
                  }

                  <div class='subChild bgblue'>
                  <img class='lockImg'  src=${tag} alt='' />
                  <span class='deviceLabel'> Device Id </span>
                  <span class='devicevalue'> ${activeDevice?.deviceId}</span>
              </div>

                 
            </div>
            <div class='infoChild2'>

            ${activeDevice?.assetType !== "simTracking" ?
            `<div class='subChild2 '>
            <img class='imgCss'  src=${battery} alt='' />
            <span class='infoLabel label2'>Battery</span>
            <span class='infoValue infoValue2'>${batteryPercent}</span>
              </div>`
              :''}

      
              <div class='subChild2 '>
                <img class='imgCss'  src=${routeIcon} alt='' />
                <span class='infoLabel label2'>Trip Status</span>
                <span class='infoValue infoValue2'>${tripStatus}</span>
              </div>
      
                <div class='subChild2'>
                  <img class='imgCss'  src=${speed} alt='' />
                  <span class='infoLabel label2'>Speed </span>
                  <span class='infoValue infoValue2'>${speedVal}</span>
                </div>
      
              <div class='subChild2'>
                  <img class='imgCss'  src=${distance} alt='' />
                  <span class='infoLabel label2'>Distance </span>
                  <span class='infoValue infoValue2'>${distanceVal}</span>
              </div>
      
              <div class='subChild2'>
                <img class='imgCss'  src=${dateTime} alt='' />
                <span class='infoLabel label2'>Date & Time</span>
                <span class='infoValue infoValue2'>${time}</span>
              </div>
            </div>
      
            <div class='infoChild3'>
              <div class='addressDiv'>
                <div class='addressImg'>
                <img class='imgCss' src=${location} alt='' />
                </div>
                <div class='addressBox'>
                <span class='infoLabel'>${address}</span>
                </div>
              </div>
              
            </div>
            </div>
          `;
                let temp = newInfoWindow;

                if (infoWindow) {
                  infoWindow.close();
                }

                temp.setContent(infoWindowContent);
                temp.setPosition(event.latLng);
                temp.open(myMap);
                setInfoWindow(temp);
                setNewInfoWindow(temp);
              })
              .catch((error) => {
                console.error("Error:", error);
              });
          })
          .catch((error) => {
            console.error("Error creating data:", error);
          });
      });
    }
  };

  //USE EFFECTS ----------
  React.useEffect(() => {
    setTripDetails(null);
    setTimeout(() => {
      if (typeof google === "undefined" || typeof google.maps === "undefined") {
        return null;
      }

      const mapElement = document.getElementById("viewallmap");

      if (!mapElement) {
        console.error("Element with id 'viewallmap' not found.");
        return null;
      }
      const mapOptions = {
        zoom: 12,
        center: {
          lat: Number(28.525553),
          lng: Number(77.275874),
        },
        mapTypeId: "terrain",
        zoomControl: true,
        zoomControlOptions: {
          style: google.maps.ZoomControlStyle.SMALL,
          position: google.maps.ControlPosition.LEFT_BOTTOM,
        },
      };

      const map = new google.maps.Map(mapElement, mapOptions);
      setMyMap(map);

      return map;
      // if (currentLocation) {
      // }
    }, 40);
  }, []);

  React.useEffect(() => {
    if (myMap) {
      const mapStyles = isDarkTheme ? darkStyles : null;
      myMap.setOptions({ styles: mapStyles });
    }
  }, [isDarkTheme, myMap]);

  React.useEffect(() => {
    try {
      if (
        query &&
        query.get("encKey") &&
        query.get("dateTime") &&
        query.get("page") &&
        myMap
      ) {
        setShareLoader(true);
        const payload = {
          encKey: query.get("encKey")?.replace(/ /g, "+"),
          dateTime: query.get("dateTime"),
        };
        if (query.get("page") === "trip") {
          dispatch(getSharedTripTrackingData({ ...payload }, (res) => {
              if (res && res?.success) {
                setTimeout(() => {
                  setShareLoader(false);
                }, 1000)
                const temp = {
                  type: res?.type,
                  features: res?.features,
                  tripDetails: res?.trip
                };
                renderTrackOnMap(temp);
                setTripDetails({
                  tripId: res?.trip?.tripId ?? "-",
                  deviceId:
                    res?.trip?.devices && res?.trip?.devices?.length > 0
                      ? res?.trip?.devices?.map((_) => _?.deviceId)?.join(", ")
                      : "-",
                  vehicleNo: res?.trip?.vehicleNo ?? "-",
                  validUpto: res?.validUpto,
                });
              } else {
                setTripDetails(null);
                setTimeout(() => {
                  setShareLoader(false);
                }, 1000)
              }
            })
          );
        }
        else if(query.get("page") === "device"){
          const payload = {
            id1: query.get("encKey")?.replace(/ /g, "+"),
            id2: query.get("dateTime"),
          };
          dispatch(getSharedDeviceTrackingData({ ...payload }, (res) => {
            if (res && res?.success) {
              setTimeout(() => {
                setShareLoader(false);
              }, 1000)
              const temp = {
                type: res?.type,
                features: res?.features,
                tripDetails: res?.trip
              };
              renderTrackOnMap(temp);
              setTripDetails({
                deviceId:
                  res?.trip?.devices && res?.trip?.devices?.length > 0
                    ? res?.trip?.devices?.map((_) => _?.deviceId)?.join(", ")
                    : "-",
                vehicleNo: res?.trip?.vehicleNo ?? "-",
                validUpto: res?.validUpto,
              });
            } 
            else {
              setTripDetails(null);
              setTimeout(() => {
                setShareLoader(false);
              }, 1000)
            }
          })
        );
        }
        else{
          setShareLoader(false);
        }
      }
    } catch (e) {
      setTimeout(() => {
        setShareLoader(false);
      }, 1000)
      console.log("error: ", e);
    }

    return () => {
      setTripDetails(null);
    };
  }, [query, myMap]);

  const loadGoogleMapsApi = () => {
    return new Promise((resolve, reject) => {
      if (window.google) {
        setNewInfoWindow(new google.maps.InfoWindow())
        resolve(window.google);
      } else {
        const script = document.createElement("script");
        script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyANnEwTq59T8s30-hXFPNOS5t9_OP47i2A&libraries=geometry,drawing,places`;
        script.onload = () => {
          setNewInfoWindow(new google.maps.InfoWindow())
          resolve(window.google);
        };
        script.onerror = (error) => {
          reject(error);
        };
        document.body.appendChild(script);
      }
    });
  };

  // In your React component
  React.useEffect(() => {
    let isMounted = true;
    loadGoogleMapsApi()
      .then((google) => {
        if (isMounted) {
          setNewInfoWindow(new google.maps.InfoWindow())
        }
      })
      .catch((error) => {
        console.error("Error loading Google Maps API:", error);
      });

    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <>
      <Box height="10vh" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} position="relative">
        <img style={{ width: '220px' }} src={Logo} alt='' />
      </Box>
      <Box height="90vh" position="relative">
        {shareLoader && (
          <Box
            height="100%"
            width="100%"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <img src={loaderGif} height="150px" width="150px" />
          </Box>
        )}
        <div
          id="viewallmap"
          className="drawMap"
          style={{ width: "100%", height: "100%" }}
        />
        {query &&
          query.get("page") &&
          query.get("page") === "trip" || query.get("page") === "device" && !shareLoader &&
          tripDetails && (
            <Box
              p={2}
              sx={{
                minWidth: '300px',
                maxWidth: "300px",
                position: "absolute",
                right: "10px",
                top: "10px",
                background: "#ff7200",
                boxShadow: `3px 3px 5px ${isDarkTheme ? "#2b2b2b" : "#807575"}`,
                borderRadius: "13px",
                display: "flex",
                flexDirection: "column",
                gap: "5px",
              }}
            >
            {query.get("page") !== "device" ?
              <Box display="flex" alignItems="center" gap={1.5}>
                <Typography sx={{ fontWeight: 700, fontSize: '14px', width: '40%' }}>Trip Id</Typography>
                <Typography sx={{ fontSize: '14px', width: '60%' }} fontWeight={500}>: {tripDetails?.tripId}</Typography>
              </Box>
              :null}
              <Box display="flex" alignItems="center" gap={1.5}>
                <Typography sx={{ fontWeight: 700, fontSize: '14px', width: '40%' }}>Device Id</Typography>
                <Typography sx={{ fontSize: '14px', width: '60%' }} fontWeight={500}>
                  : {tripDetails?.deviceId}
                </Typography>
              </Box>
              <Box display="flex" alignItems="center" gap={1.5}>
                <Typography sx={{ fontWeight: 700, fontSize: '14px', width: '40%' }}>Vehicle No.</Typography>
                <Typography sx={{ fontSize: '14px', width: '60%' }} fontWeight={500}>
                  : {tripDetails?.vehicleNo || '-'}
                </Typography>
              </Box>
              <Box display="flex" alignItems="center" gap={1.5}>
                <Typography sx={{ fontWeight: 700, fontSize: '14px', width: '40%' }}>Valid Upto</Typography>
                <Typography sx={{ fontSize: '14px', width: '60%' }} fontWeight={500}>
                  :{" "}

                  {tripDetails?.validUpto &&
                    typeof tripDetails?.validUpto === "object"
                    ? `Arrival at ${tripDetails?.validUpto?.itemName || "-"} `
                    : tripDetails?.validUpto || "-"}


                </Typography>
              </Box>
            </Box>
          )}
        <ToastContainer
          position="bottom-center"
          autoClose={false}
          closeOnClick={false}
        />
      </Box>
    </>
  );
};

export default SharingTrackMap;
