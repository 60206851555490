const { subDays, format } = require("date-fns");

export const getSevenDaysBefore = (today = new Date()) => {
  const sevenDaysBefore = subDays(today, 7);
  return sevenDaysBefore;
};

export const formattedDate = (date) => {
  return format(date, 'dd-MM-yyyy');
};

export function getGMTDate(date = new Date()) {
  // Get the current time in milliseconds since 1970/01/01
  const localTime = date.getTime();
  
  // Get the time zone offset in minutes and convert it to milliseconds
  const timeZoneOffset = date.getTimezoneOffset() * 60000;
  
  // Adjust the local time by adding the time zone offset
  const gmtTime = new Date(localTime + timeZoneOffset);
  
  return gmtTime;
}
