import { IMAGE_PATH, EMPTY_IMAGE, GET_IMAGE_PATH, EMPTY_IMAGE_PATH } from "../types";
  
  const INIT_STATE = {
    imagePaths : "",
    imagePathfromBase: ''
  };
  
  export default (state = INIT_STATE, action) => {
    switch (action.type) {
      case EMPTY_IMAGE: 
      return { 
       
        imagePaths: ""
      };
      case IMAGE_PATH:
        return {
          imagePaths: action.payload.rows
        };
        case GET_IMAGE_PATH:
        return {
          imagePathfromBase: action.payload.rows
        };
        case EMPTY_IMAGE_PATH:
          return {
            imagePathfromBase: ''
          };
        default:
        return state;
    }
  };
  