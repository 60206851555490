// import { Card, CardBody } from "reactstrap";
import DataBox from "./Sub-Components/DataBox";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { getMonitoringData } from "../../../../../redux/actions/Monitoring";
import { Card, CardContent } from "@mui/material";
import loaderGif from "../../../../../static/images/drawer/loader.gif";

const AlertsLevels = ({ data, mergedCounts, onBoxClick, themeType, fullscreenView = false }) => {
  return (
    <div className="pt-1">
      {data?.data && data?.data?.length
        ? data?.data?.map((section, i) => {
            return (
              <Card key={i} sx={{mb: 2}}>
                <CardContent
                  sx={{ padding: 0 }}
                  className="p-0"
                  aria-label="monitoring section"
                >
                  <div
                    className="monitorDashboard__section-header"
                    aria-label="monitoring section heading"
                    style={{ background: themeType?.default.themeGray }}
                  >
                    <span>{section._id}</span>
                  </div>

                  <div className={`monitorDashboard__section-body  p-3 ${fullscreenView ? "monitoring_section_body--fullscreen" : ""}`}>
                    <DataBox
                      sectionName={section?._id}
                      data={section.alertLevelArray}
                      allCounts={mergedCounts}
                      onBoxClick={onBoxClick}
                      fullscreenView={fullscreenView}
                    />
                  </div>
                </CardContent>
              </Card>
            );
          })
        : null}
    </div>
  );
};

const DashboardSection = ({ onBoxClick, handleSideClose, themeType, fullscreenView = false }) => {
  const {
    monitoringData,
    deviceCount,
    alertCount,
    tripCount,
    monitoringLoading,
  } = useSelector((state) => state.monitoring);
  const mergedCounts = {
    ...(deviceCount ? deviceCount : {}),
    ...(tripCount ? tripCount : {}),
    ...(alertCount ? alertCount : {}),
  };

  return (
    <>
      {monitoringLoading ? (
        <div
          className="pt-3"
          style={{
            height: "500px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img data-testId="loaderImg" src={loaderGif} />
        </div>
      ) : (
        <div className={`pt-3 ${fullscreenView ? "section-wrapper--fullscreen" : ""}`}>
          {monitoringData &&
            monitoringData?.map((section, index) => {
              return (
                <Card
                  key={index}
                  className={`${
                    index === monitoringData.length - 1 ? "" : !fullscreenView ? "mb-4" : ""
                  }`}
                >
                  <CardContent
                    sx={{ padding: 0 }}
                    className="p-0"
                    aria-label="monitoring section"
                  >
                    <div
                      className="monitorDashboard__section-header"
                      aria-label="monitoring section heading"
                      style={{ background: themeType?.default.themeGray }}
                    >
                      <span>{section._id}</span>
                    </div>

                    <div className={`monitorDashboard__section-body  p-3 ${fullscreenView ? "monitoring_section_body--fullscreen" : ""}`}>
                      {section?._id !== "Alerts" ? (
                        <DataBox
                          sectionName={section?._id}
                          data={section.data}
                          allCounts={mergedCounts}
                          onBoxClick={onBoxClick}
                          fullscreenView={fullscreenView}
                        />
                      ) : (
                        <AlertsLevels
                          data={section}
                          mergedCounts={mergedCounts}
                          onBoxClick={onBoxClick}
                          themeType={themeType}
                          fullscreenView={fullscreenView}
                        />
                      )}
                    </div>
                  </CardContent>
                </Card>
              );
            })}
        </div>
      )}
    </>
  );
};
export default DashboardSection;
