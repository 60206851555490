import { Delete, DeleteOutline, Edit, SwapVert } from '@mui/icons-material';
import {
    Box, Typography, Paper, Grid,
    Card, CardHeader, CardContent, Button, TextField, ButtonGroup, IconButton,
    Modal, FormControl, FormControlLabel, FormLabel
} from '@mui/material';
// import { type } from '@testing-library/user-event/dist/type';
import React, { useEffect, useRef } from 'react'
import { useState } from 'react';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import history from '../../../services/history';
import theme, { buttonClasses, textClasses } from '../../../static/styles/theme';
import GenericInputField from '../../Components/Generic/GenericInputField';
import GenericInputSection from '../../Components/Generic/GenericInputSection';
import EditFieldModal from './EditFieldModal';
import { getSectionHelperText, getTemplateTypeName } from '../../../services/constants';
import { getRegexList, initCanvas } from '../../../redux/actions';
import { cleanCanvas } from '../../../redux/reducers/Canvas';
import { capitalizeSentence } from '../../../services/functions';
import { selectStylesOverride } from '../../../utils/util';
import CustomModel from '../../Components/CustomModels/CustomModel1';
import { CustomInput } from '../../../components/FormUI';
import { getAllOrganisations } from "../../../redux/actions";
import CustomSelect from "../../../components/FormUI/CustomSelect";

// const style = {
//     position: 'absolute',
//     top: '50%',
//     left: '50%',
//     transform: 'translate(-50%, -50%)',
//     width: "40%",
//     bgcolor: 'background.paper',
//     border: '1px solid #000',
//     boxShadow: 24,
//     p: 3,
// };

const PublishDraftModal = ({ status, showModal, handleCloseModal, selectedSubTemplate, register, setValue, handleSubmit, handlePublish, getTemplateTypeName, template, isDarkThemeEnabledSelector, themeType, control, superAdmin, orgListing, CustomSelect }) => {
    useEffect(() => {
        if (template) {
            setValue("name", template.name);
            setValue("description", template.description);
            setValue("orgId", template.orgId);
        }
    }, [template])
    return (
        <CustomModel
            autoClose={false}
            show={showModal || false}
            onClose={handleCloseModal}
            minWidth={640}
            maxWidth={640}
            pb={3}
            pl={3}
            pr={3}
            maxHeight={"80vh"}
            childrenMaxHeight={"80vh"}
            Title="Publish Template"
            viewCloseIcon={showModal}
        >
            <Grid container mb={2}>
                {/* <Typography sx={{ ...textClasses.cardTitle }}>{status && status.substring(0, 1).toUpperCase() + status.substring(1, status.length)} {getTemplateTypeName(selectedSubTemplate)} Template</Typography> */}
                <Typography sx={{ ...textClasses.normalText, color: "#455A64", mt: 2, mb: 1 }}>
                    To save template fill the below details
                </Typography>
                <Grid item
                    sm={12}
                    flexDirection={"column"}
                    sx={{ width: "100%", height: "auto" }}>

                    <Grid item sx={{ mt: 2 }}>
                        <Controller
                            defaultValue=""
                            name="name"
                            control={control}
                            render={({ field: { onChange, value, name } }) => {
                                return (
                                    <CustomInput
                                        placeholder="Template name"
                                        label="Template name"
                                        selectStylesOverride={selectStylesOverride(isDarkThemeEnabledSelector)}
                                        inputClasses={themeType.inputClasses}
                                        name={name}
                                        // errors={errors}
                                        value={value}
                                        required
                                        onChange={onChange}
                                    />
                                );
                            }}
                        />
                    </Grid>
                    {superAdmin && Object.keys(superAdmin).length ?
                        <Grid item sx={{ mt: 2 }}>
                            <FormControl
                                variant="outlined"
                                size="small"
                                fullWidth
                                sx={{ ...selectStylesOverride(isDarkThemeEnabledSelector) }}
                            >
                                <Controller
                                    name="orgId"
                                    control={control}
                                    render={({ field: { onChange, value, name } }) => {
                                        return (
                                            <CustomSelect
                                                selectStylesOverride={selectStylesOverride}
                                                value={value || null}
                                                name={name}
                                                onChange={(e, newVal) => {
                                                    onChange(newVal?._id);
                                                }}
                                                inputClasses={themeType.inputClasses}
                                                label="Organisation"
                                                getOptionLabel={(option) => {
                                                    if (value === undefined) {
                                                        return "";
                                                    }
                                                    if (typeof option === "string") {
                                                        return (
                                                            orgListing?.find((_) => _?._id == value)?.name ||
                                                            ""
                                                        );
                                                    } else {
                                                        return option?.name || "";
                                                    }
                                                }}
                                                filterSelectedOptions
                                                labelKey="name"
                                                options={orgListing && orgListing}
                                            />


                                        );
                                    }}
                                />
                            </FormControl>
                        </Grid>
                        : null}
                    <Grid item sx={{ mt: 2, mb: 6 }}>
                        <Controller
                            defaultValue=""
                            name="description"
                            control={control}
                            render={({ field: { onChange, value, name } }) => {
                                return (
                                    <CustomInput
                                        placeholder="Description"
                                        label="Description"
                                        selectStylesOverride={selectStylesOverride(isDarkThemeEnabledSelector)}
                                        inputClasses={themeType.inputClasses}
                                        name={name}
                                        // errors={errors}
                                        value={value}
                                        multiline
                                        rows={3}
                                        onChange={onChange}
                                    />
                                );
                            }}
                        />
                    </Grid>


                </Grid>
                <Grid container spacing={5}>
                    <Grid container item sm={6} xs={6} justifyContent="center" alignItems="center">
                        <Button variant='outlined' fullWidth size='small' sx={{ ...buttonClasses.outlined, color: themeType.default.themeOrange }} onClick={handleCloseModal}>
                            Cancel
                        </Button>
                    </Grid>
                    <Grid container item sm={6} xs={6} justifyContent="center" alignItems="center">
                        <Button variant='contained' fullWidth size='small' sx={{ ...buttonClasses.lynkitOrangeFill, minHeight: "40px", backgroundColor: themeType.default.themeOrange }} onClick={handleSubmit(handlePublish)}>
                            Publish
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </CustomModel>
    )
}

const useSpiType = (template) => {
    const [spiType, setSpiType] = useState(null);

    useEffect(() => {
        if (template) {
            let type;
            if (!template.otherTemplate || template.otherTemplate.length == 0) {
                type = "shipmentOnly";
            }
            else if (template.otherTemplate.length == 2) {
                type = "SPI";
            }
            else if (template.otherTemplate.length == 1 && template.otherTemplate[0].templateType == "packageDetailTemplate") {
                type = "shipmentPackageOnly";
            }
            else {
                type = "shipmentItemOnly";
            }

            setSpiType(type);

        }
    }, [template])

    return spiType;
}

export default function ComponentCanvas({ components, handleSaveTemplate, template }) {
    const editField = useSelector(state => state.canvas.editField)
    const selectedTemplate = useSelector(state => state.canvas.selectedTemplate)
    const selectedSubTemplate = useSelector(state => state.canvas.selectedSubTemplate)
    const allRegex = useSelector(state => state.templates.allRegex)
    const [showModal, setShowModal] = useState(false);
    const [status, setStatus] = useState(null);
    const superAdmin = useSelector((state) => state.auth.currentUserDetail)?.adminSpecificPermissions || {};
    const orgListing = useSelector((state) => state.Organisationlisting.OrganisationListing);
    const isDarkThemeEnabledSelector = useSelector(state => state.theme.themeDark);
    const themeType = isDarkThemeEnabledSelector ? require('../../../static/styles/darktheme') : require('../../../static/styles/theme');
    const {
        theme,
        buttonClasses,
        cardClasses,
        inputClasses,
        textClasses,
        tableClasses,
        dividerClass
    } = themeType;
    const isCanvasDefault = (() => {
        const defaultCanvas = cleanCanvas();
        return _.isEqual(defaultCanvas, components);
    })();

    const isCanvasEmpty = (() => {
        let attributes = [...components.attributes.mandatory.filter(f => f.templateType == selectedSubTemplate), ...components.attributes.other];
        return attributes.length == 0;
    })();

    const spiType = useSpiType(template);
    const dispatch = useDispatch();
    useEffect(() => {
        if (Object?.keys(superAdmin)?.length) {
            dispatch(getAllOrganisations({ selection: ["name"] }));
        }
    }, [dispatch, superAdmin]);

    const handleOpenModal = (status) => {
        setShowModal(true);
        setStatus(status)
    }
    const handleCloseModal = () => {
        setShowModal(false);
        setStatus(null);
    }

    const { register, handleSubmit, setValue, control } = useForm();


    const handlePublish = (data) => {
        handleSaveTemplate({ ...data, status: status }, () => {
            handleCloseModal();
            history.push("/dashboard/manage-templates")
        });
    }


    const handleClear = () => {
        dispatch(initCanvas());
    }

    useEffect(() => {
        dispatch(getRegexList());
    }, [])

    return (
        <Grid container alignItems={"center"} direction="column" justifyContent="center" sx={{ p: 0, mt: 2 }}>
            <Grid container item sm={12} xs={12}>
                {components.attributes.mandatory.length ?

                    (template && ["geofence", "route", "trip"].indexOf(template.templateType) >= 0) || (["geofence", "route", "trip"].indexOf(selectedSubTemplate) >= 0) ?
                        <>
                            <GenericInputSection
                                components={components}
                                sectionId={"attributes"}
                                bifurcattion={"attributes"}
                                sectionName={`${capitalizeSentence(selectedSubTemplate)} - (Mandatory Section)`}
                                helperText={getSectionHelperText(`${selectedSubTemplate}`)}
                            />
                        </>
                        :
                        null
                    :
                    null}




            </Grid>
            <Grid container item sm={12} xs={12}>

                {(template && ["device", "geofence", "route", "trip"].indexOf(template.templateType) >= 0) || (["device", "geofence", "route", "trip"].indexOf(selectedSubTemplate) >= 0) ?
                    <>
                        <GenericInputSection
                            selectedSubTemplate={selectedSubTemplate}
                            components={components}
                            sectionId={"attributes"}
                            bifurcattion={"others"}
                            sectionName={`${capitalizeSentence(selectedSubTemplate)} - (Dynamic Section)`}
                            sx={{ mt: 3 }}
                            removable={true}
                        // helperText={getSectionHelperText(selectedSubTemplate)}
                        />
                    </>
                    : ''

                }
            </Grid>
            <Grid container sx={{ mt: 2, height: '34px' }} >
                {
                    !template &&
                    <Button variant='outlined' size='small' sx={{ ml: 1, ...buttonClasses.small, minWidth: "110px", height: '34px', borderColor: themeType.default.themeOrange, color: themeType.default.themeOrange }} onClick={handleClear} disabled={isCanvasDefault}>
                        Clear All
                    </Button>

                }


                <Button variant='contained' size='small' sx={{ ml: 1, ...buttonClasses.small, minWidth: "110px", height: '34px', backgroundColor: themeType.default.themeOrange }} onClick={() => handleOpenModal("published")} disabled={isCanvasEmpty}>
                    Publish
                </Button>
            </Grid>
            <PublishDraftModal
                status={status}
                showModal={showModal}
                handleCloseModal={handleCloseModal}
                selectedSubTemplate={selectedSubTemplate}
                register={register}
                setValue={setValue}
                handleSubmit={handleSubmit}
                handlePublish={handlePublish}
                getTemplateTypeName={getTemplateTypeName}
                template={template}
                isDarkThemeEnabledSelector={isDarkThemeEnabledSelector}
                themeType={themeType}
                control={control}
                superAdmin={superAdmin}
                orgListing={orgListing}
                CustomSelect={CustomSelect}
            />
            <EditFieldModal editField={editField} allRegex={allRegex} />
        </Grid>
    )
}
