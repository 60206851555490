import { geoAxios } from "../../services/Api";
import { showToast } from "../../services/functions";
import { GET_FILTERS } from "../types";

/**
 * @description Action creator to get all filters
 * @param {*} cb callback function
 * @returns
 */
export const getAllFilters = (payload = {}, cb = () => {}) => {
  return async (dispatch) => {
    try {
      const response = await geoAxios.post("/filter/getFilter", payload);
      const data = response.data;
      if (data?.success) {
        dispatch({ type: GET_FILTERS, payload: data?.data });
      } else {
        // showToast(data?.message, true);
        dispatch({ type: GET_FILTERS, payload: {} });
      }
      cb && cb(data);
    } catch (error) {
      // showToast(error?.response?.data?.message, true);
      dispatch({ type: GET_FILTERS, payload: {} });
      console.log("Error****:", error.message);
      cb && cb(error?.response?.data);
    }
  };
};

/**
 * @description Action creator to save filters
 * @param {*} payload {type: "", data: {}}
 * @param {*} cb callback function
 * @returns
 */
export const saveFilters = (payload, cb = () => {}) => {
  return async (dispatch) => {
    try {
      const response = await geoAxios.post("/filter/saveFilter", payload);
      const data = response.data;
      if (data?.success) {
        showToast(data?.message);
        dispatch(getAllFilters())
      } else {
        showToast(data?.message, true);
      }
      cb && cb(data);
    } catch (error) {
      showToast(error?.response?.data?.message, true);
      console.log("Error****:", error.message);
      cb && cb(error?.response?.data);
    }
  };
};
