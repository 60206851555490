import { ArrowForwardIos, LockOpen, Visibility, Close, ArrowBack } from "@mui/icons-material";
import { Box, Button, IconButton, Typography, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import EventSummaryTimeline from "./EventSummaryTimeline";
import { useDispatch, useSelector } from "react-redux";
import { convertIntoGivenTimezone, getCamelCaseText } from "../../../../services/functions";
import ThreeDotsLoader from "../../../Components/ThreeDots/ThreeDotsLoader";
import { havePermission } from "../../../../utils/permissionCheck";
import { getLockUnlockOTP, lockUnlockDevice, verifyLockUnlockOTP, getPasscodeOTP, verifyPasscodeOTP, showPasscode, generatePasscodeForFixedLock } from "../../../../redux/actions";
import OTPModal from "../../Devices/components/OTPModal";
import FixedLockPasscodeModal from "../../Devices/components/FixedLockPasscodeModal"
import { selectStylesOverride, sendCustomEventAnalytics } from "../../../../utils/util";
import moment from 'moment'
import start from "../../../../assets/infoWindow/start1.png"
import via from "../../../../assets/infoWindow/Via.png"
import end from "../../../../assets/infoWindow/End.png"

import e from "cors";
const TripMapDrawer = ({ open, themeType, history, details, tripEventsDetails, setIdParam, tripDetails, setTripParam, setMymap, idParam, myMap, geoEditDrawer, infoWindow, newInfoWindow, setInfoWindow, userDetails, tripDetailsLoader, setGeoEditDrawer, geofencePlotArray, geofenceWindow, fastTageWindow }) => {
  const { textClasses, buttonClasses } = themeType;
  const isDarkThemeEnabledSelector = useSelector((state) => state.theme.themeDark);
  const dispatch = useDispatch();
  const [currentDeviceId, setCurrentDeviceId] = useState('')
  const { currentUserDetail } = useSelector((_) => _?.auth);
  const [lockType, setLockType] = useState(null);
  const [modalLoading, setModalLoading] = useState(false);
  const [unlockId, setUnlockId] = useState("");
  const [loading, setLoading] = useState(false);
  const [passcodeIdToVerify, setPasscodeIdToVerify] = React.useState(null);
  const [passcodeValue, setPasscodeValue] = useState(null);
  const [showFixedLockPasscodeModal, setShowFixedLockPasscodeModal] = useState(false);
  const [showPasscodeOTPModal, setShowPasscodeOTPModal] = useState(false);
  const [showOTPForLockUnlock, setShowOTPForLockUnlock] = useState(false);

  const tripNewStatus = React.useMemo(() => {
    if (details?.tripData) {
      return details?.tripData?.newStatus ? details?.tripData?.newStatus : details?.tripData?.status
    }else{
      return "";
    }
  }, [details]);

  // TODO: Hide viapoint name [early-delay] if trip status is mapped, via, reached.
  const getTargetCurrentTime = () => {
    if (details?.tripData?.etaType) {
      if (!isNaN(details?.tripData?.etaType)) {
        const targetETA = convertIntoGivenTimezone(details?.tripData?.route?.viaPoints[Number(details?.tripData?.etaType)]?.targetETA, currentUserDetail?.timeZone?.offset, "DD/MM/YYYY hh:mm A") || "-";
        const STA = details?.tripData?.route?.viaPoints[Number(details?.tripData?.etaType)]?.STA
        let currentETA = '';

        const currentViapoint = details?.tripData?.route?.viaPoints[Number(details?.tripData?.etaType)];
        if (details?.tripData?.viaData?.length) {
          const viaWithSameName = details?.tripData?.viaData?.filter(_ => _?.name === currentViapoint?.itemName && _?.arrivalTime);
          if (viaWithSameName?.length) {
            currentETA = convertIntoGivenTimezone(viaWithSameName[0]?.arrivalTime, currentUserDetail?.timeZone?.offset, "DD/MM/YYYY hh:mm A") || "-";
          } else {
            currentETA = convertIntoGivenTimezone(details?.tripData?.route?.viaPoints[Number(details?.tripData?.etaType)]?.currentETA, currentUserDetail?.timeZone?.offset, "DD/MM/YYYY hh:mm A") || "-";
          }
        }
        else {
          currentETA = convertIntoGivenTimezone(details?.tripData?.route?.viaPoints[Number(details?.tripData?.etaType)]?.currentETA, currentUserDetail?.timeZone?.offset, "DD/MM/YYYY hh:mm A") || "-";
        }

        if (!details?.tripData?.route?.viaPoints[Number(details?.tripData?.etaType)]?.targetETA) {
          return { STA, currentETA };
        }
        else {
          return { targetETA, currentETA };
        }



      }
      else if (details?.tripData?.etaType === "destination") {
        const destinationObj = details?.tripData?.route?.routeDestination;
        const targetETA = convertIntoGivenTimezone(destinationObj?.targetETA, currentUserDetail?.timeZone?.offset, "DD/MM/YYYY hh:mm A") || '-';
        const currentETA = convertIntoGivenTimezone(tripNewStatus === "reached" ? details?.tripData?.arrivalTime : destinationObj?.currentETA, currentUserDetail?.timeZone?.offset, "DD/MM/YYYY hh:mm A") || "-";
        const STA = destinationObj?.STA

        if (!destinationObj?.targetETA) {
          return { STA, currentETA };
        }
        else {
          return { targetETA, currentETA };
        }

      } else if (details?.tripData?.etaType === "awaiting") {
        return { targetETA: "Awaiting Departure", currentETA: "Awaiting Departure" };
      } else {
        return { targetETA: "-", currentETA: "-" };
      }
    }
  }

  const getTargetCurrentEta = () => {
    const name =
      details?.tripData?.etaType === "destination"
        ? details?.tripData?.route?.routeDestination?.name || null
        : details?.tripData?.route?.viaPoints[
          Number(details?.tripData?.etaType)
        ]?.itemName || null;

    if (!isNaN(details?.tripData?.etaType)) {
      return {
        targetETA: details?.tripData?.route?.viaPoints[Number(details?.tripData?.etaType)]?.targetETA !== null ? getTargetCurrentTime()?.targetETA : getTargetCurrentTime()?.STA,
        currentETA: getTargetCurrentTime()?.currentETA,
        name
      }
    }
    else if (details?.tripData?.etaType === "destination") {
      return {
        targetETA: details?.tripData?.route?.routeDestination?.targetETA === null ? getTargetCurrentTime()?.STA : getTargetCurrentTime()?.targetETA,
        currentETA: getTargetCurrentTime()?.currentETA,
        name
      }
    }
    else {
      return {
        targetETA: getTargetCurrentTime()?.targetETA,
        currentETA: getTargetCurrentTime()?.currentETA,
        name
      }
    }
  };

  const detailsList = [
    {
      label: "Trip ID",
      key: "Trip ID",
      value: details && details?.tripData && details?.tripData?.tripDetails ? details?.tripData?.tripDetails?.tripId : '-',
    },
    {
      label: "Vehicle No.",
      key: "vehicle_number",
      value: details && details?.tripData && details?.tripData?.vehicleDetails ? details?.tripData?.vehicleDetails?.vehicleNumber : '-',
    },
    {
      label: "Distance from destination",
      key: "distance",
      value: tripNewStatus === "reached" ? "0 km" : tripEventsDetails && tripEventsDetails.distanceFromDestination ? tripEventsDetails?.distanceFromDestination + ' km' : '-'
    },
    {
      label: "Overstoppage",
      key: "overstoppage",
      value: tripDetails && tripDetails?.tripData && tripDetails?.tripData?.limitThreshold?.overstopLimit ? tripDetails?.tripData?.limitThreshold?.overstopLimit + ' mins' : '-'
    },
    {
      label: "Overspeed",
      key: "overspeed",
      value: tripDetails && tripDetails?.tripData && tripDetails?.tripData?.limitThreshold?.speedLimit ? tripDetails?.tripData?.limitThreshold?.speedLimit + ' kmph' : '-'
    },
    {
      label: "Device ID",
      key: "deviceId",
      value: details && details?.tripData && details?.tripData?.devices ? details?.tripData?.devices.map(item => ({
        deviceName: item.deviceId,
        deviceType: item.deviceType === 'fixedELock' ? 'Fixed E-Lock' : item.deviceType === 'elock' ? 'E-Lock' : item.deviceType === 'assetTracker' ? 'Asset Tracker' : item.deviceType === 'fixedTracker' ? 'Fixed Tracker' : item.deviceType === 'simTracking' ? 'Sim Tracking' : getCamelCaseText(item.deviceType)
      })) : []
    },
  ];

  if (details?.tripData?.flightDetails?.length && details?.tripData?.flightDetails[0]?.flightId) {
    detailsList.push({
      label: "Flight Number",
      key: "flightId",
      value: details && details?.tripData && details.tripData.flightDetails?.length && details.tripData.flightDetails[0]?.flightId ? details.tripData.flightDetails[0]?.flightId?.toUpperCase() : '-',
    });
  }

  const getETAValue = () => {
    if (details) {
      let targetETA = "";
      let currentETA = "";
      let tripStatus = tripNewStatus;


      if (details?.tripData?.route?.scheduleId) {
        switch (tripStatus) {
          case "mapped":
            targetETA = "Awaiting Departure";
            currentETA = "Awaiting Departure";
            break;
          case "in_transit":
            targetETA = details?.tripData?.etaType ? getTargetCurrentEta()?.targetETA : "-";
            currentETA = details?.tripData?.etaType
              ? getTargetCurrentEta()?.currentETA
              : "-";
            break;
          case "via":
            targetETA = "Inside Viapoint";
            currentETA = "Inside Viapoint";
            break;
          case "reached":
            targetETA = "Inside Destination";
            currentETA = "Inside Destination";
            break;
          default:
            targetETA = "-";
            currentETA = "-";
        }

        return { targetETA, currentETA };
      } else {
        switch (tripStatus) {
          case "mapped":
            targetETA = "Awaiting Departure";
            currentETA = "Awaiting Departure";
            break;
          case "in_transit":
            targetETA = details?.tripData?.etaType
              ? getTargetCurrentEta()?.targetETA
              : "-";
            currentETA = details?.tripData?.etaType
              ? getTargetCurrentEta()?.currentETA
              : "-";
            break;
          case "via":
            targetETA = "Inside Viapoint";
            currentETA = "Inside Viapoint";
            break;
          case "reached":
            targetETA = "Inside Destination";
            currentETA = "Inside Destination";
            break;
          default:
            targetETA = "-";
            currentETA = "-";
        }

        return { targetETA, currentETA };
      }
    }
  };

  const getLastTwoItems = () => { 
    const lastTwoItems = [];

    if (details && tripNewStatus !== "completed" && havePermission(currentUserDetail, "trip", "enableETA")) {
      lastTwoItems.push({
        label: "Target ETA",
        key: "targetEta",
        value:
          // details?.tripData?.etaType ? getTargetCurrentEta()?.targetETA : "-",
          getETAValue()?.targetETA,
      });

      lastTwoItems.push({
        label: "Current ETA",
        key: "currentEta",
        value:
          // details?.tripData?.etaType ? getTargetCurrentEta()?.currentETA : "-",
          getETAValue()?.currentETA,
      });
    }

    return lastTwoItems;
  };

  const finalDetailsList = [...detailsList, ...getLastTwoItems()];

  const handleLockUnlockDevice = (type) => {
    if (havePermission(currentUserDetail, "unlockingOption", "otpForUnlockingWeb")) {
      setModalLoading(true);
      const payload = {
        deviceId: currentDeviceId,
        page: "device",
        source: "web",
        otp: "",
        type: type ?? "",
      };
      dispatch(
        getLockUnlockOTP(payload, (res) => {
          if (res && res?.success) {
            setUnlockId(res?._id);
            setShowOTPForLockUnlock(true);
          }
          setModalLoading(false);
        })
      );
    } else {
      setLoading(true);
      const payload = {
        deviceId: currentDeviceId,
        page: "device",
        source: "web",
        type: type ?? "",
      };
      dispatch(
        lockUnlockDevice(payload, (res) => {
          if (res?.success) {
            setLockType(null);
          }
          setLoading(false);
        })
      );
    }
  };

  const handleVerifyOtpForLockUnlock = (data) => {
    setModalLoading(true);
    const payload = {
      deviceId: currentDeviceId,
      page: "device",
      source: "web",
      otp: data?.otp,
      type: lockType ?? "",
      _id: unlockId,
    };
    dispatch(
      verifyLockUnlockOTP(payload, (res) => {
        if (res && res?.success) {
          setLoading(true);
          dispatch(
            lockUnlockDevice(payload, (res) => {
              if (res?.success) {
                setLockType(null);
              }
              setLoading(false);
            })
          );
          setShowOTPForLockUnlock(false);
        }
        setModalLoading(false);
      })
    );
  };

  const changeDevice = (deviceID) => {
    setTripParam(null)
    setIdParam(null)
    setMymap(null)
    setCurrentDeviceId(deviceID)
    history.replace(`/dashboard/trips/trip-map/${tripDetails.tripData?._id}?id=${deviceID}`)

  }

  const handleFixedLockPasscode = () => {
    setShowFixedLockPasscodeModal(true);
  };

  const handleVerifyOtpForPasscode = (data) => {
    setModalLoading(true);
    const payload = {
      otp: data?.otp,
      _id: passcodeIdToVerify,
    };

    dispatch(
      verifyPasscodeOTP(payload, (res) => {
        if (res && res?.success) {
          setPasscodeIdToVerify(null);
          setLoading(true);
          dispatch(
            showPasscode({ deviceId: currentDeviceId }, (res) => {
              if (res && res?.success) {
                setPasscodeValue(res?.result);
                setShowPasscodeOTPModal(false);
              }
              setLoading(false);
            })
          );
        }
        setModalLoading(false);
      })
    );
  };

  const handleGenerateFixedLockPasscode = (data, cb) => {
    setModalLoading(true);
    const payload = {
      randomNo: data?.randomNo,
      deviceId: currentDeviceId,
    };

    dispatch(
      generatePasscodeForFixedLock(payload, (res) => {
        if (res && res?.success) {
          setPasscodeValue(res?.message);
          cb && cb();
        }
        setModalLoading(false);
      })
    );
  };

  const handleViewPasscode = () => {
    if (havePermission(currentUserDetail, "unlockingOption", "otpForUnlockingPasscode")) {
      setModalLoading(true);
      dispatch(
        getPasscodeOTP({ deviceId: currentDeviceId }, (res) => {
          if (res && res?.success) {
            setPasscodeIdToVerify(res?._id);
            setShowPasscodeOTPModal(true);
          }
          setModalLoading(false);
        })
      );
    } else {
      setLoading(true);
      const payload = { deviceId: currentDeviceId };
      dispatch(
        showPasscode(payload, (res) => {
          if (res && res?.success) {
            setPasscodeIdToVerify(null);
            setPasscodeValue(res?.result);
          }
          setLoading(false);
        })
      );
    }
  };

  useEffect(() => {
    if (idParam) {
      setCurrentDeviceId(idParam)
    }
  }, [idParam])

  const getDelay = (target, current) => {
    if (target === 'Awaiting Departure' || current === 'Awaiting Departure') {
      return null;
    }
    
    if (!target && !current ) {
      return null;
    } 
    else {
      const targetTime = moment(target, 'DD/MM/YYYY hh:mm:ss A');
      const currentTime = moment(current, 'DD/MM/YYYY hh:mm:ss A');
  
      const timeDifference = currentTime.diff(targetTime);
      const reversed = timeDifference < 0;
      const duration = moment.duration(Math.abs(timeDifference));
  
      const years = duration.years();
      const months = duration.months();
      const days = duration.days();
      const hours = duration.hours();
      const minutes = duration.minutes();
  
      let formattedDifference = "";
      if (years > 0) {
        formattedDifference += `${years} year${years > 1 ? 's' : ''}, `;
      }
      if (months > 0 || years > 0) {
        formattedDifference += `${months} month${months > 1 ? 's' : ''}, `;
      }
      if (days > 0 || months > 0 || years > 0) {
        formattedDifference += `${days} day${days > 1 ? 's' : ''}, `;
      }
      if (hours > 0 || days > 0 || months > 0 || years > 0) {
        formattedDifference += `${hours} hr${hours > 1 ? 's' : ''}, `;
      }
      if (minutes > 0 || hours > 0 || days > 0 || months > 0 || years > 0) {
        formattedDifference += `${minutes} min${minutes > 1 ? 's' : ''}, `;
      }
  
      if (formattedDifference.includes('NaN min') || formattedDifference === undefined) {
        return null;
      }
  
      if (formattedDifference.endsWith(", ")) {
        formattedDifference = formattedDifference.slice(0, -2);
      }
  
      if (reversed) {
        return {
          time: `( Early by ${formattedDifference} )`,
          color: 'green'
        };
      } else if (years === 0 && months === 0 && days === 0 && hours === 0 && minutes === 0) {
        return {
          time: '( On Time )',
          color: 'green'
        };
      } else {
        return {
          time: `( Delayed by ${formattedDifference} )`,
          color: 'red'
        };
      }
    }
  };

  

  return (
    <Box
      sx={{
        height: "100%",
        width: open ? 430 : 0,
        opacity: open ? 1 : 0,
        transition: "all 0.6s ease-in-out",
        overflowY: "scroll",
      }}
      data-testid="trip-map-side-panel"
    >
      {open && (
        <>
          {/* ----- Header (title & back icon) ------ */}
          <Box
            mb={2}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              position: "sticky",
              width: "100%",
              top: "-1px",
              background: (theme) => theme.palette.background.paper,
              zIndex: 300,
              borderBottom: "1px solid #D9D9D9",
              paddingY: "15.3px",
            }}
          >
            <Typography
              sx={{
                fontSize: "17px",
                fontWeight: "700",
                width: "100%",
                textAlign: "center",
                marginRight: "10px"
              }}
            >
              Trip Details
            </Typography>
          </Box>
          {tripDetailsLoader ? (
            <Grid item={true} data-testid='loader' container direction={"column"} justifyContent={"center"} alignItems="center" xs={12} sx={{ p: 1 }}>
              <ThreeDotsLoader />
            </Grid>
          )
            :
            <>
              {tripDetails && Object.keys(tripDetails).length && !tripDetailsLoader ? (
                <Box display="flex" flexDirection="column" gap="8px" px={2} sx={{height: "calc(100% - 80px)"}}>
                  {finalDetailsList?.map((detail, i) => {
                    return (
                      <Box
                        display="flex"
                        alignItems="flex-start"
                        justifyContent="space-between"
                        key={i}
                        sx={{ borderBottom: "1px solid #F5F5F5" }}
                      >
                        <Typography sx={{ ...textClasses.normalText }}>
                          {detail?.label}
                        </Typography>
                        {detail.key === "deviceId" ? (
                          <Grid
                            sx={{ display: "flex", flexDirection: "column" }}
                          >
                            {detail?.value?.map((item, index) => (
                              <Typography
                                key={index}
                                sx={{
                                  ...textClasses.normalText,
                                  color:
                                    idParam === item.deviceName
                                      ? "green"
                                      : "#ff7200",
                                  paddingTop: "2px",
                                  paddingBottom: "2px",
                                  cursor:
                                    idParam === item.deviceName
                                      ? "unset"
                                      : "pointer",
                                  "&:hover": {
                                    color: "grey",
                                  },
                                }}
                                onClick={
                                  idParam === item.deviceName
                                    ? null
                                    : () => changeDevice(item.deviceName)
                                }
                              >
                                {item?.deviceName || "-"} ({" "}
                                {item?.deviceType || "-"} )
                              </Typography>
                            ))}
                          </Grid>
                        ) : (
                          <Typography
                            sx={{
                              ...textClasses.normalText,
                              color:
                                detail?.key === "currentEta" ||
                                  detail?.key === "targetEta" || detail?.key === 'flightId'
                                  ? "green"
                                  : "#ff7200",
                              fontSize: "14px",
                            }}
                          >
                            <>
                              <Typography
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-end",
                                  fontSize: "14px",
                                }}
                              >
                                {/* {detail?.key === 'targetEta' || detail?.key === 'currentEta' ?
                                  detail?.value !== undefined && detail?.value !== '-' && detail?.value !== null ? moment(detail?.value, 'DD-MM-YYYY hh:mm:ss a').format('DD-MM-YYYY hh:mm:ss a') || '-' : '-'
                                  :
                                  detail?.value
                                } */}

                                {detail?.value}
                              </Typography>

                              {tripNewStatus !== "mapped" &&
                                tripNewStatus !== "via" &&
                                tripNewStatus !== "reached" && (
                                  <>
                                    {!getTargetCurrentEta()?.targetETA ||
                                      getTargetCurrentEta()?.name === "-" ||
                                      getTargetCurrentEta()?.name ===
                                      null ? null : detail?.key ===
                                        "targetEta" ? (
                                      <Typography
                                        data-testid="target-eta"
                                        style={{
                                          display: "flex",
                                          justifyContent: "right",
                                          fontSize: "13px",
                                        }}
                                      >
                                        ( {getTargetCurrentEta()?.name} )
                                      </Typography>
                                    ) : null}

                                    {!getTargetCurrentEta()?.currentETA ||
                                      getTargetCurrentEta()?.currentETA ===
                                      "-" ? null : detail?.key ===
                                        "currentEta" ? (
                                      <p
                                        data-testid="current-eta"
                                        style={{
                                          color: "red",
                                          display: "flex",
                                          justifyContent: "right",
                                          fontSize: "13px",
                                          color: getDelay(
                                            getTargetCurrentEta()?.targetETA,
                                            getTargetCurrentEta()?.currentETA
                                          )?.color,
                                        }}
                                      >
                                        {
                                          getDelay(
                                            getTargetCurrentEta()?.targetETA,
                                            getTargetCurrentEta()?.currentETA
                                          )?.time
                                        }
                                      </p>
                                    ) : null}
                                  </>
                                )}
                            </>
                          </Typography>
                        )}
                      </Box>
                    );
                  })}
                  <Grid sx={{ display: 'flex', justifyContent: 'space-between' }}>

                    {tripDetails?.tripData?.cameraId && tripDetails?.tripData?.status !== 'completed' && (
                      <Button
                        sx={{
                          ...buttonClasses.lynkitOrangeEmpty,
                          boxShadow: "none",
                          marginTop: 0,
                          flex: '1 1 46%',
                          marginRight: '10px'
                        }}
                        onClick={() => {
                          history.push({
                            pathname: `/dashboard/trips/camera/${tripDetails?.tripData?.cameraId}`,
                            state: { tripDetails }
                          });
                        }}

                      >
                        Live Camera
                      </Button>
                    )}
                    {tripDetails?.tripData?.cameraId && tripDetails?.tripData?.status === 'completed' && (
                      <Button
                        sx={{
                          ...buttonClasses.lynkitOrangeEmpty,
                          boxShadow: "none",
                          marginTop: 0,
                          flex: '1 1 46%',
                          marginRight: '10px'
                        }}
                        onClick={() => {
                          // CvNetVideo.Stop();
                          history.push({
                            pathname: `/dashboard/camera-playback/${tripDetails?.tripData?.cameraId}`,
                            state: tripDetails
                          });
                        }}

                      >
                        Playback
                      </Button>
                    )}

                    {details?.tripData?.devices?.find(item => item.deviceId === currentDeviceId)?.deviceType === "fixedTracker"  ? null :
                      <Button
                        sx={{
                          ...buttonClasses.lynkitOrangeEmpty,
                          boxShadow: "none",
                          marginTop: 0,
                          flex: '1 1 46%',
                        }}
                        onClick={() => {
                          sendCustomEventAnalytics("trips", "Trip Summary", "Trip Summary");
                          history.push({
                            pathname: `/dashboard/trips/trip-summary/${tripDetails.tripData?._id}`,
                            state: { currentDeviceId }
                          });
                        }}

                      >
                        View Summary
                      </Button>
                    }
                  </Grid>
                  {/* {currentUserDetail && havePermission(currentUserDetail, "geofence", "editGeofence") && details?.tripData?.status !== 'completed' ? (
                    geofencePlotArray && geofencePlotArray?.length ? */}
                      <Grid sx={{ display: 'flex' }}>
                        <Button
                          sx={{
                            ...buttonClasses.lynkitOrangeEmpty,
                            boxShadow: "none",
                            marginTop: 0,
                            flex: '1 1 46%',
                          }}
                          data-testid='editGeofence'
                          onClick={() => { setGeoEditDrawer(true) }}

                        >
                          Edit Geofence
                        </Button>
                      </Grid>
                      {/* : null
                  ) : null} */}


                  {details?.tripData?.devices?.find(item => item.deviceId === currentDeviceId && (item.deviceType === "assetTracker" || item.deviceType === "simTracking")) ? null :
                    details?.redisObjData?.geo_type === "nil" || details?.tripData?.devices?.find(item => item.status === "completed") ? null :
                      <Grid sx={{ display: 'flex' }}>
                        {havePermission(currentUserDetail, "unlockingOption", "sendUnlockCommand") && details?.tripData?.devices?.find(item => item.deviceId === currentDeviceId)?.deviceType === "fixedELock" ? null : (
                          <Grid
                            item
                            xs={12}
                            lg
                            sx={{ marginRight: '10px' }}
                          >
                            <Button
                              variant="contained"
                              fullWidth
                              sx={{
                                ...buttonClasses.lynkitOrangeEmpty,
                                px: "3px !important",
                                boxShadow: "none",
                                "& .MuiButton-startIcon": { marginRight: "4px" },
                              }}
                              startIcon={
                                <LockOpen sx={{ fontSize: "15px !important" }} />
                              }
                              onClick={() => {
                                setLockType("SET_UNLOCK");
                                handleLockUnlockDevice("SET_UNLOCK");
                              }}
                            >
                              Unlock
                            </Button>
                          </Grid>
                        )}

                        {details?.tripData?.devices?.find(item => item.deviceId === currentDeviceId)?.deviceType === "fixedELock" &&
                          havePermission(
                            currentUserDetail,
                            "unlockingOption",
                            "viewPasscode"
                          ) ? (
                          <Grid
                            item
                            xs={12}
                            lg
                          >
                            <Button
                              variant="contained"
                              fullWidth
                              sx={{
                                ...buttonClasses.lynkitOrangeEmpty,
                                boxShadow: "none",
                                px: "3px !important",
                                "& .MuiButton-startIcon": { marginRight: "4px" },
                              }}
                              onClick={handleFixedLockPasscode}
                              startIcon={
                                <Visibility sx={{ fontSize: "15px !important" }} />
                              }
                            >
                              Passcode
                            </Button>
                          </Grid>
                        ) : (
                          <>
                            {havePermission(
                              currentUserDetail,
                              "unlockingOption",
                              "viewPasscode"
                            ) && (
                                <Grid
                                  item
                                  xs={12}
                                  lg
                                >
                                  {passcodeValue ? (
                                    <Box
                                      display="flex"
                                      alignItems="center"
                                      justifyContent="center"
                                      gap={0.5}
                                      sx={{ border: ' 0.5px dashed #ff7200', borderRadius: '12px', height: '33px' }}
                                    >
                                      <Typography
                                        sx={{
                                          ...textClasses?.normalText,
                                          color: themeType?.default?.themeOrange,
                                          fontWeight: 600,
                                        }}
                                      >
                                        {passcodeValue}
                                      </Typography>
                                      <IconButton onClick={() => setPasscodeValue(null)}>
                                        <Close
                                          sx={{ color: themeType?.default?.themeOrange }}
                                        />
                                      </IconButton>
                                    </Box>
                                  ) : (
                                    details?.tripData?.detailsDeviceID?.assetType === 'fixedELock' ? null :
                                      <Button
                                        variant="contained"
                                        fullWidth
                                        sx={{
                                          ...buttonClasses.lynkitOrangeEmpty,
                                          boxShadow: "none",
                                          px: "3px !important",
                                          "& .MuiButton-startIcon": {
                                            marginRight: "4px",
                                          },
                                        }}
                                        onClick={handleViewPasscode}
                                        startIcon={
                                          <Visibility
                                            sx={{ fontSize: "15px !important" }}
                                          />
                                        }
                                      >
                                        Passcode
                                      </Button>
                                  )}
                                </Grid>
                              )}
                          </>
                        )}


                      </Grid>
                  }
                  <Grid container >
                    <Grid item xs={4} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                      <img src={start} alt='' style={{ width: '14px', height: '14px' }} />
                      <Typography sx={{ fontSize: '12px' }}> Source</Typography>
                    </Grid>
                    <Grid item xs={4} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                      <img src={via} alt='' style={{ width: '14px', height: '14px' }} />
                      <Typography sx={{ fontSize: '12px' }}> Viapoint</Typography>
                    </Grid>
                    <Grid item xs={4} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                      <img src={end} alt='' style={{ width: '14px', height: '14px' }} />
                      <Typography sx={{ fontSize: '12px' }}> Destination</Typography>
                    </Grid>

                  </Grid>
                  {details && details?.tripData && details?.tripData?.route?.geoOpsID?.length ? 
                  <Grid container sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Typography sx={{ fontSize: '12px', fontWeight: '700' }}>Note :  </Typography>
                    <Typography sx={{ fontSize: '12px', paddingLeft: '2px' }}> Black Geofence is Edited Geofence. </Typography>
                  </Grid>
                  :null}

                  {tripEventsDetails !== null && tripEventsDetails !== undefined && Object.keys(tripEventsDetails).length ?
                    <Box
                      mt={0}
                      borderRadius="12px"
                      border="1px solid #D9D9D9"
                      width="100%"
                      // height="100%"
                      // maxHeight="60%"
                      maxHeight="300px"
                      overflow='auto'
                    >

                      <EventSummaryTimeline
                        themeType={themeType}
                        tripEventsDetails={tripEventsDetails}
                        myMap={myMap}
                        tripDetails={tripDetails}
                        idParam={idParam}
                        geofenceWindow={geofenceWindow}
                        infoWindow={infoWindow}
                        newInfoWindow={newInfoWindow}
                        setInfoWindow={setInfoWindow}
                        userDetails={userDetails}
                        fastTageWindow={fastTageWindow}
                      />
                    </Box>
                    :
                    <Typography sx={{ paddingTop: '20px' }}>No Event Data.</Typography>
                  }
                </Box>


              )
                :
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <Typography >No trip data found.</Typography>

                </div>
              }
            </>
          }


          <OTPModal
            open={showOTPForLockUnlock}
            setOpen={setShowOTPForLockUnlock}
            themeType={themeType}
            loading={modalLoading}
            selectStylesOverride={selectStylesOverride(isDarkThemeEnabledSelector)}
            handleVerifyFn={handleVerifyOtpForLockUnlock}
          />

          <OTPModal
            open={showPasscodeOTPModal}
            setOpen={setShowPasscodeOTPModal}
            themeType={themeType}
            loading={modalLoading}
            selectStylesOverride={selectStylesOverride(isDarkThemeEnabledSelector)}
            handleVerifyFn={handleVerifyOtpForPasscode}
          />

          <FixedLockPasscodeModal
            open={showFixedLockPasscodeModal}
            setOpen={setShowFixedLockPasscodeModal}
            themeType={themeType}
            loading={modalLoading}
            passcodeValue={passcodeValue}
            setPasscodeValue={setPasscodeValue}
            selectStylesOverride={selectStylesOverride(isDarkThemeEnabledSelector)}
            handleVerifyFn={handleGenerateFixedLockPasscode}
          />
        </>
      )}
    </Box>

  );
};

export default TripMapDrawer;
