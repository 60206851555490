import {
  FETCH_SUCCESS,
  FETCH_START,
  FETCH_ERROR,
  FETCH_SETTINGS,
  FETCH_DYNAMIC_KEYS_FOR_TEMPLATES,
  ORGANISATION_IMAGES,
  CLEAR_UPLOADED_IMAGES,
  CLEAR_SETTINGS
} from "../types";
import axios, { geoAxios } from "../../services/Api";
import { showToast } from "../../services/functions";

/**
 * @description -- to fetch all settings
 */
export const getAllSettings = (payload, cb) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    axios
      .post("/getAllSettings", payload)
      .then(({ data }) => {
        if (data.success) {
          callback();
        }
        else{
          dispatch({ type: FETCH_SUCCESS });
          dispatch({
            type: FETCH_SETTINGS,
            payload: [],
          });
          if (cb) {
            cb(data);
          }
        }
        function callback() {
          dispatch({ type: FETCH_SUCCESS });
          dispatch({
            type: FETCH_SETTINGS,
            payload: data?.data[0],
          });
          if (cb) {
            cb(data);
          }
        }
      })
      .catch(function (error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
        dispatch({ type: FETCH_SETTINGS, payload: null });
        cb && cb({success: false, message: error?.response?.data?.message});
        console.log("Error****:", error.message);
      });
  };
};

export const updateSettings = (payload, cb) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    axios
      .post("/saveSettings", payload)
      .then(({ data }) => {
        if (data.success) {
          callback();
        }
        function callback() {
          dispatch({ type: FETCH_SUCCESS });
          // showToast(data?.message);
          if (cb) {
            cb();
          }
        }
      })
      .catch(function (error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
        console.log("Error****:", error.message);
      });
  };
};

export const listDynamicKeys = (payload, cb) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    axios
      .post("/listDynamicKeysForTemplate", payload)
      .then(({ data }) => {
        if (data.success) {
          callback();
        }
        function callback() {
          dispatch({
            type: FETCH_DYNAMIC_KEYS_FOR_TEMPLATES,
            payload: data?.data,
          });
          if (cb) {
            cb();
          }
        }
      })
      .catch(function (error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
        console.log("Error****:", error.message);
      });
  };
};

export const getImagesOfOrganisation = (payload, cb) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    axios
      .post("/getImagesOfOrganization", payload)
      .then(({ data }) => {
        if (data.success) {
          callback();
        }
        function callback() {
          dispatch({
            type: ORGANISATION_IMAGES,
            payload: data?.data,
          });
          if (cb) {
            cb(data?.data);
          }
        }
      })
      .catch(function (error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
        console.log("Error****:", error.message);
      });
  };
};

export const uploadImagesInOrganisation = (payload, cb) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    axios
      .post("/uploadImagesInOrganization", payload)
      .then(({ data }) => {
        if (data.success) {
          callback();
        }
        function callback() {
          if (cb) {
            cb();
          }
        }
      })
      .catch(function (error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
        console.log("Error****:", error.message);
      });
  };
};

export const clearOrganisationImages = (payload) => {
  return (dispatch) => {
    dispatch({ type: CLEAR_UPLOADED_IMAGES, payload: payload });
  };
};

/* ACCOUNT LINKAGE */
export const getAccountLinkageList = (payload, cb) => {
  return (dispatch) => {
    dispatch({ type: "ACCOUNT_LINKAGE_LIST_LOADING", payload: true });
    axios.post("/getAccountLinkageDetails", payload)?.then(({data})=>{
      if(data?.success){
        dispatch({ type: "ACCOUNT_LINKAGE_LIST", payload: data?.data?.accountData });
        dispatch({ type: "ACCOUNT_LINKAGE_COUNT", payload: data?.data?.count });
      }else{
        // showToast(data?.message, true);
        dispatch({ type: "ACCOUNT_LINKAGE_LIST", payload: [] });
        dispatch({ type: "ACCOUNT_LINKAGE_COUNT", payload: 0 });
      }
      cb && cb(data);
      dispatch({ type: "ACCOUNT_LINKAGE_LIST_LOADING", payload: false });
    })?.catch((error) => {
      dispatch({ type: "ACCOUNT_LINKAGE_LIST_LOADING", payload: false });
      dispatch({ type: "ACCOUNT_LINKAGE_LIST", payload: [] });
      dispatch({ type: "ACCOUNT_LINKAGE_COUNT", payload: 0 });
      cb && cb(error?.response?.data);
    });
  }
}

export const getUserAccountDetails = (payload, cb) => {
  return (dispatch) => {
    dispatch({ type: "ACCOUNT_LINKAGE_LIST_LOADING", payload: true });
    axios.post("/getUserDetailsOnTheBasisOfAccountId", payload)?.then(({data})=>{
      if(data?.success){
        dispatch({ type: "USER_ACCOUNT_DETAILS", payload: data?.data });
      }else{
        showToast(data?.message, true);
        dispatch({ type: "USER_ACCOUNT_DETAILS", payload: null });
      }
      cb && cb(data);
      dispatch({ type: "ACCOUNT_LINKAGE_LIST_LOADING", payload: false });
    })?.catch((error) => {
      dispatch({ type: "ACCOUNT_LINKAGE_LIST_LOADING", payload: false });
      dispatch({ type: "USER_ACCOUNT_DETAILS", payload: null });
      showToast(error?.response?.data?.message, true);
      cb && cb(error?.response?.data);
    });
  }
}

export const addAccountLinkageDetails = (payload, cb) => {
  return (dispatch) => {
    dispatch({ type: "ACCOUNT_LINKAGE_LIST_LOADING", payload: true });
    axios.post("/addAccountLinkageDetails", payload)?.then(({data})=>{
      if(!data?.success){
        showToast(data?.message, true);
      }else{
        showToast(data?.message)
      }
      cb && cb(data);
      dispatch({ type: "ACCOUNT_LINKAGE_LIST_LOADING", payload: false });
    })?.catch((error) => {
      dispatch({ type: "ACCOUNT_LINKAGE_LIST_LOADING", payload: false });
      showToast(error?.response?.data?.message, true);
      cb && cb(error?.response?.data);
    });
  }
}

export const updateAccountLinkageDetails = (payload, cb) => {
  return (dispatch) => {
    dispatch({ type: "ACCOUNT_LINKAGE_LIST_LOADING", payload: true });
    axios.post("/updateAccountLinkageDetails", payload)?.then(({data})=>{
      if(!data?.success){
        showToast(data?.message, true);
      }else{
        showToast(data?.message)
      }
      cb && cb(data);
      dispatch({ type: "ACCOUNT_LINKAGE_LIST_LOADING", payload: false });
    })?.catch((error) => {
      dispatch({ type: "ACCOUNT_LINKAGE_LIST_LOADING", payload: false });
      showToast(error?.response?.data?.message, true);
      cb && cb(error?.response?.data);
    });
  }
}

export const deleteAccountLinkageDetails = (payload, cb) => {
  return (dispatch) => {
    dispatch({ type: "ACCOUNT_LINKAGE_LIST_LOADING", payload: true });
    axios.post("/deleteAccountLinkageDetails", payload)?.then(({data})=>{
      if(!data?.success){
        showToast(data?.message, true);
      }else{
        showToast(data?.message)
      }
      cb && cb(data);
      dispatch({ type: "ACCOUNT_LINKAGE_LIST_LOADING", payload: false });
    })?.catch((error) => {
      dispatch({ type: "ACCOUNT_LINKAGE_LIST_LOADING", payload: false });
      showToast(error?.response?.data?.message, true);
      cb && cb(error?.response?.data);
    });
  }
}

/**
 * @description -- to get routes that are assigned to a user [will be used in "update account linkage"]
 * @param {*} payload -- takes userId & orgId
 * @param {*} cb
 * @returns
 */
export const getUserAssignedRoutesAccountLinkage = (payload, cb) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    geoAxios
      .post("/getUserAssignedRoutesAccountLinkage", payload)
      .then(({ data }) => {
        if (data.success) {
          callback();
        } else {
          dispatch({ type: FETCH_ERROR, payload: data.message });
        }
        function callback() {
          dispatch({ type: FETCH_SUCCESS });
          if (cb) {
            cb(data?.data);
          }
        }
      })
      .catch(function (error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
        showToast(error?.response?.data?.message, true);
        console.log("Error****:", error.message);
      });
  };
};

/**
 * @description -- to get geofences that are assigned to a user [will be used in "update account linkage"]
 * @param {*} payload -- takes userId & orgId
 * @param {*} cb
 * @returns
 */
export const getUserAssignedGeofencesAccountLinkage = (payload, cb) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    geoAxios
      .post("/getUserAssignedGeofencesAccountLinkage", payload)
      .then(({ data }) => {
        if (data.success) {
          callback();
        } else {
          dispatch({ type: FETCH_ERROR, payload: data.message });
        }
        function callback() {
          dispatch({ type: FETCH_SUCCESS });
          if (cb) {
            cb(data?.data);
          }
        }
      })
      .catch(function (error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
        showToast(error?.response?.data?.message, true);
        console.log("Error****:", error.message);
      });
  };
};


//----------------
export const clearSettings = (payload) => {
  return (dispatch) => {
    dispatch({ type: CLEAR_SETTINGS, payload: payload });
  };
};
