import React, { useEffect } from "react";
import Login from "../../views/Landing/Login";
import history from "../../services/history";
import { useDispatch, useSelector } from "react-redux";
import { createTheme, ThemeProvider } from "@mui/material";
import { getOTPforResetPassword, userLogin } from "../../redux/actions/Auth";
import ReactGA from 'react-ga4';

const loginFont = createTheme({
  typography: {
    fontFamily: ["Nunito", "serif"].join(","),
  },
});

const TRACKING_ID = process.env.REACT_APP_ENV === "development" ? 'G-YMDF21LE3R' : "G-FM3SW9XJ24" //--- correct one

ReactGA.initialize(TRACKING_ID);

export default function App() {
  const dispatch = useDispatch();
  const [loginLoading, setLoginLoading] = React.useState(false);
  const [otpLoading, setOtpLoading] = React.useState(false);
  const [otpSuccess, setOtpSuccess] = React.useState(false);
  let token = localStorage.getItem("token");
  let version=''
  let versionMismatch=false

  const _onLogin = (data) => {
    dispatch(userLogin(data, () => {
      setLoginLoading(false);
    }));
  };

  return (
    <ThemeProvider theme={loginFont}>
      <div>
        {/* <Navbar logo={logo}/> */}
        <Login
          _onLogin={_onLogin}
          setOtpLoading={setOtpLoading}
          otpLoading={otpLoading}
          otpSuccess={otpSuccess}
          setOtpSuccess={setOtpSuccess}
          setLoginLoading={setLoginLoading}
          loginLoading={loginLoading}
        />
        {/* <Footer/> */}
      </div>
    </ThemeProvider>
  );
}
