import {
  GEOFENCES_LIST,
  VIEW_GEOFENCE,
  VIEW_GEOFENCE_DETAIL,
  GEOFENCES_LIST_MAP,
  SHOW_MESSAGE,
  FETCH_START,
  FETCH_SUCCESS,
  FETCH_ERROR,
  GEO_STATUS_COUNT,
  FLUSH_BOARD,
  FETCH_GEO_FLAG,
  FLUSH_GEOFENCE_DETAILS
} from "../types";
import axios, { geoAxios } from "../../services/Api";
import { showToast } from "../../services/functions";
import history from "../../services/history";


export const getAllGeofences = (payload, cb) => {
  return async (dispatch) => {
    dispatch({ type: FETCH_START });
    await geoAxios
      .post("/listGeofence", { ...payload })
      .then(({ data }) => {
        if (data.success) {
          callback(data);
        } else {
          cb && cb({ success: false, message: data?.message });
          dispatch({
            type: GEOFENCES_LIST,
            payload: { rows: [], count: 0 },
          });
        }
        function callback(data) {
          dispatch({ type: FETCH_SUCCESS });
          dispatch({
            type: GEOFENCES_LIST,
            payload: { rows: data.data, count: data.count },
          });
          if (cb) {
            cb(data);
          }
        }
      })
      .catch(function (error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
        dispatch({ type: GEOFENCES_LIST, payload: { rows: [], count: 0 } });
        console.log("Error****:", error.message);
        if (cb) {
          cb({ success: false, message: error?.response?.data?.message });
          // cb(error);
        }
      });
  };
}; 
export const getAllGeofencesForMap = (payload, cb) => {
  return async (dispatch) => {
    dispatch({ type: FETCH_START });
    await geoAxios
      .post("/listGeofence/map", { ...payload })
      .then(({ data }) => {
        if (data.success) {
          callback(data);
        } else {
          cb && cb({ success: false, message: data?.message });
        }
        function callback(data) {
          dispatch({ type: FETCH_SUCCESS });
          dispatch({
            type: GEOFENCES_LIST_MAP,
            payload: { rows: data.data, count: data.count },
          });
          if (cb) {
            cb(data);
          }
        }
      })
      .catch(function (error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
        dispatch({ type: GEOFENCES_LIST_MAP, payload: { rows: [], count: 0 } });
        console.log("Error****:", error.message);
        if (cb) {
          cb({ success: false, message: error?.response?.data?.message });
          // cb(error);
        }
      });
  };
};





export const createGeofence = (payload, callback) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    geoAxios
      .post("/createGeofence", payload)
      .then(({ data }) => {
        if (data.success) {
          dispatch({ type: FETCH_SUCCESS, payload: data.success, data: data.data });
          dispatch({ type: SHOW_MESSAGE, payload: data.message, data: data.data });
        } else {
          dispatch({ type: FETCH_ERROR, payload: data.message });
        }

        if (callback) {
          callback({ success: data.success || false, message: data.message, data : data.data });
        }
      })
      .catch(function (error) {
        dispatch({
          type: FETCH_ERROR,
          payload: error?.response?.data?.message,
        });
        if (callback) {
          callback({ success: false, message: error?.response?.data?.message });
        }
        console.log("Error****:", error.message);
      });
  };
};

export const bulkUploadGeofence = (payload, callback) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    geoAxios
      .post("/bulkGeofencesUploadFromExcel", payload)
      .then(({ data }) => {
        if (data.success) {
          dispatch({ type: FETCH_SUCCESS, payload: data.success });
          dispatch({ type: SHOW_MESSAGE, payload: data.message });
        }else{
          showToast(data?.message, true)
        }

        if (callback) {
          callback({ success: data.success || false, message: data.message });
        }
      })
      .catch(function (error) {
        dispatch({
          type: FETCH_ERROR,
          payload: error?.response?.data?.message,
        });
        showToast(error?.response?.data?.message, true);
        if (callback) {
          callback({ success: false, message: error?.response?.data?.message });
        }
        console.log("Error****:", error.message);
      });
  };
};
// export const updateGeofence = (bodydata,callBackFunction) =>
// {
//     return async (dispatch) => {
//         dispatch({type: FETCH_START});
//         await geoAxios.post('/getGeofenceDetails', bodydata).then(({data}) => {
//           if (data.success)
//           {
//             dispatch({type: FETCH_SUCCESS,payload: data.success});
//             dispatch({type: SHOW_MESSAGE, payload: data.message});
//             // console.log('manageteam0', data)
//             dispatch({ type: "VIEW_GEOFENCE",  payload: {rows: data.data} });
//           }
//           else
//           {
//             dispatch({type: FETCH_ERROR, payload: data.message});
//             dispatch({ type: "VIEW_GEOFENCE", payload: {} });
//           }
//           if(callBackFunction){
//             callBackFunction({success: data.success, message: data.message })
//           }
//         }).catch(function (error) {
//           dispatch({type: FETCH_ERROR, payload: error.message});
//           console.log("Error****:", error.message);
//           if(callBackFunction){
//             callBackFunction({message: error.message })
//           }
//         });
//       }
// };
export const viewGeofenceForDetail = (bodydata, callBackFunction) => {
  return async (dispatch) => {
    dispatch({ type: FETCH_START });
    await geoAxios
      .post("/getGeofenceDetails", bodydata)
      .then(({ data }) => {
        if (data.success) {
          dispatch({ type: FETCH_SUCCESS, payload: data.success });
          dispatch({ type: SHOW_MESSAGE, payload: data.message });
          // console.log('manageteam0', data)
          dispatch({
            type: "VIEW_GEOFENCE_DETAIL",
            payload: { rows: data.data },
          });
        } else {
          dispatch({ type: FETCH_ERROR, payload: data.message });
          dispatch({ type: "VIEW_GEOFENCE_DETAIL", payload: {} });
          if (callBackFunction) {
            callBackFunction({ message: data.message });
          }
        }
        if (callBackFunction) {
          callBackFunction({ success: data.success, message: data.message, data: data.data  });
        }
      })
      .catch(function (error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
        console.log("Error****:", error.message);
        if (callBackFunction) {
          callBackFunction({ message: error.message });
        }
      });
  };
};

export const deleteGeofence = (bodydata, callBackFunction) => {
  return async (dispatch) => {
    dispatch({ type: FETCH_START });

    await geoAxios
      .post(`/deleteGeofenceDetails`, bodydata)
      .then(({ data }) => {
        if (data.success) {
          dispatch({ type: FETCH_SUCCESS, payload: data.success });
          dispatch({ type: SHOW_MESSAGE, payload: data.message });
        } else {
          dispatch({ type: FETCH_ERROR, payload: data.message });
        }
        if (callBackFunction) {
          callBackFunction({ success: data.success, message: data.message });
        }
      })
      .catch(function (error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
        showToast(error?.response?.data?.message, true);
        console.log("Error****:", error.message);
        if (callBackFunction) {
          callBackFunction(null);
        }
      });
  };
};

/**
 * @description -- to assign or unassign a geofence [will be used in "add/update user"]
 */
export const assignDeassignGeofences = (payload, cb) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    geoAxios
      .post("/assignDeassignGeofences", payload)
      .then(({ data }) => {
        if (data.success) {
          callback();
        } else {
          dispatch({ type: FETCH_ERROR, payload: data.message });
        }
        function callback() {
          dispatch({ type: FETCH_SUCCESS });
          if (cb) {
            cb();
          }
        }
      })
      .catch(function (error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
        showToast(error?.response?.data?.message, true);
        console.log("Error****:", error.message);
      });
  };
};

/**
 * @description -- to get geofences that are assigned to a user [will be used in "add/update user"]
 * @param {*} payload -- takes userId & orgId
 * @param {*} cb
 * @returns
 */
export const getUserAssignedGeofences = (payload, cb) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    geoAxios
      .post("/getUserAssignedGeofences", payload)
      .then(({ data }) => {
        if (data?.success) {
          callback();
        } else {
          dispatch({ type: FETCH_ERROR, payload: data.message });
        }
        function callback() {
          dispatch({ type: FETCH_SUCCESS });
          if (cb) {
            cb(data?.data);
          }
        }
      })
      .catch(function (error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
        showToast(error?.response?.data?.message, true);
        console.log("Error****:", error.message);
      });
  };
};

/**
 * @description -- To get total, active and blocked counts for geofences
 */
export const getGeofenceStatusCount = () => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    geoAxios
      .post("/geofencesStatusWiseCount")
      .then(({ data }) => {
        if (data.success) {
          callback();
        } else {
          dispatch({ type: FETCH_ERROR, payload: data.message });
        }
        function callback() {
          dispatch({ type: FETCH_SUCCESS });
          dispatch({ type: GEO_STATUS_COUNT, payload: data?.data });
        }
      })
      .catch(function (error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
        console.log("Error****:", error.message);
      });
  };
};

export const geofenceStatus = (payload, cb) => {
  return async (dispatch) => {
    try {
      dispatch({ type: FETCH_START });

      const response = await geoAxios.post("/updateGeoStatus", payload);
      const data = response.data;

      if (data?.success) {
        dispatch({ type: FETCH_SUCCESS });
        showToast(data.message);
        if (cb) {
          cb();
        }
      } else {
        dispatch({ type: FETCH_ERROR, payload: data.message });
      }
    } catch (error) {
      dispatch({ type: FETCH_ERROR, payload: error.message });
      showToast(error?.response?.data?.message, true);
      console.log("Error****:", error.message);
    }
  };
};

// export const getGeofenceDetails = (payload, cb) => {
//   return (dispatch) => {
//     dispatch({ type: FETCH_START });
//     geoAxios
//       .post("/getGeofenceDetails", { ...payload })
//       .then(({ data }) => {
//         console.log('resssss------------',data)
//         if (data.success) {
//           callback();
//         }
//         function callback() {
//           dispatch({ type: FETCH_SUCCESS });
//           dispatch({ type: "VIEW_GEOFENCE", payload: { rows: data.data } });
//           // showToast(data?.message)
//           if (cb) {
//             cb();
//           }
//         }
//       })
//       .catch(function (error) {
//         showToast(error.response?.data?.message, true);
//         console.log("Error****:", error.message);
//       });
//   };
// };

export const getGeofenceDetails = (payload, callback) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    geoAxios
      .post("/getGeofenceDetails", {...payload})
      .then(({ data }) => {
        if (data.success) {
          dispatch({ type: FETCH_SUCCESS, payload: data.success });
          dispatch({ type: SHOW_MESSAGE, payload: data.message });
          dispatch({ type: "VIEW_GEOFENCE", payload: { rows: data.data } });
        } 
        else {
          dispatch({ type: FETCH_ERROR, payload: data.message });
        }

        if (callback) {
          callback({ success: data.success || false, message: data.message , data: data.data  });
          dispatch({ type: "VIEW_GEOFENCE", payload: { rows: data.data } });
        }
      })
      .catch(function (error) {
        dispatch({
          type: FETCH_ERROR,
          payload: error?.response?.data?.message,
        });
        if (callback) {
          callback({ success: false, message: error?.response?.data?.message });
        }
        console.log("Error****:", error.message);
      });
  };
};
















export const updateGeofenceDetails = (payload, callback) => {
  return (dispatch) => {
    // console.log('apiKeyword', apiKeyword);
    dispatch({ type: FETCH_START });
    geoAxios
      .post("/updateGeofenceDetails", payload)
      .then(({ data }) => {
        if (data.success) {
          dispatch({ type: FETCH_SUCCESS, payload: data.success, data: data.data });
          dispatch({ type: SHOW_MESSAGE, payload: data.message, data: data.data });
        } else {
          dispatch({ type: FETCH_ERROR, payload: data.message });
        }

        if (callback) {
          callback({ success: data.success || false, message: data.message, data: data.data });
        }
      })
      .catch(function (error) {
        dispatch({
          type: FETCH_ERROR,
          payload: error?.response?.data?.message,
        });
        if (callback) {
          callback({ success: false, message: error?.response?.data?.message });
        }
        console.log("Error****:", error.message);
      });
  };
};

export const updateGeoListFlag = (payload) => {
  return (dispatch) => {
    dispatch({ type: FETCH_GEO_FLAG, payload: payload });
  };
};

export const flushGeofenceList = () => {
  return (dispatch) => {
    dispatch({ type: "FLUSH_GEOFENCE_LIST" });
  };
};

export const flushGeofenceMapList = () => {
  return (dispatch) => {
    dispatch({ type: "FLUSH_GEOFENCE_MAP_LIST" });
  };
};

export const flushGeofenceDetails = () => {
  return (dispatch) => {
    dispatch({ type: FLUSH_GEOFENCE_DETAILS });
  };
};