import {
  Grid,
  Typography,
  Card,
  CardHeader,
  IconButton,
  CardContent,
  Box,
  Tooltip,
} from "@mui/material";
import { Close, SellOutlined } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import {
  currentUserDetails,
  getDeviceModelDetails,
  getListDeviceStatus,
  getStatDeviceStatus,
} from "../../../../redux/actions";
import s from "./components.module.css";
import { convertIntoGivenTimezone } from "../../../../services/functions";

const DetailCard = ({ id, onClose, themeType }) => {
  // dispatcher --------
  const dispatch = useDispatch();
  const { currentUserDetail } = useSelector((state) => state.auth);
  // selectors ------
  const { deviceModelDetails, deviceStatusCounts } = useSelector(
    (state) => state.deviceslist
  );

  // theme --------
  const { cardClasses, textClasses } = themeType;

  const cardFields = [
    {
      key: "lynkDeviceModel",
      name: "Device Model",
    },
    {
      key: "deviceModel",
      name: "OEM Device Model",
    },
    {
      key: "manufactureName",
      name: "OEM",
    },
    {
      key: "category",
      name: "Device Type",
    },
    {
      key: "isActive",
      name: "Status",
    },
    {
      value: deviceModelDetails ? deviceModelDetails?.addedBy?.name : "",
      key: "addedBy",
      name: "Added By",
    },
    {
      value: deviceModelDetails
        ? convertIntoGivenTimezone(
            deviceModelDetails?.addedOn,
            currentUserDetail.timeZone.offset
          )
        : "",
      key: "addedOn",
      name: "Added On",
    },
    {
      key: "lastUpdatedBy",
      name: "Last Updated By",
    },
    {
      key: "lastUpdatedOn",
      name: "Last Updated On",
    },
  ];

  const tableFields = [
    {
      key: "totalDevices",
      name: "Total Devices",
    },
    {
      key: "activeCount",
      name: "Active",
    },
    {
      key: "disableCount",
      name: "Disable",
    },
    {
      key: "buffer",
      name: "Buffer",
    },
    {
      key: "reqForRepairCount",
      name: "Request for Repairing",
    },
    {
      key: "underRepairingCount",
      name: "Under Repairing",
    },
    {
      key: "repairedCount",
      name: "Repaired",
    },
  ];

  // Effects --------
  useEffect(() => {
    if (id) {
      dispatch(getDeviceModelDetails({ id }));
      dispatch(getStatDeviceStatus({ id }));
    }
  }, [dispatch, id]);

  return (
    <>
      <Card
        className="ppmodal"
        data-testid="genericdetailedtable"
        sx={{
          ...cardClasses.basic,
          borderRadius: "8px",
          border: `1px solid ${themeType.default.themeOrange}`,
          position: "relative",
          height: "100%",
        }}
      >
        <CardHeader
          sx={{
            height: "46px",
            backgroundColor: themeType.default.themeOrange,
          }}
          title={
            <Typography
              data-testid="detail-title"
              sx={{
                ...textClasses.boldText,
                color: "white",
                fontSize: "16px",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              <SellOutlined fontSize="inherit" sx={{ mr: 1 }} />
              Device Model Details
            </Typography>
          }
          action={
            <IconButton size="small" sx={{ p: 0, mb: 1 }}>
              <Close sx={{ color: "white" }} onClick={onClose} />
            </IconButton>
          }
        />

        <CardContent
          sx={{ height: "100%", maxHeight: "60vh", overflowY: "scroll" }}
        >
          {deviceModelDetails &&
            cardFields?.map((field) => {
              return (
                <Grid container sx={{ mt: 1 }}>
                  <Grid item sm={6} xs={4}>
                    <Typography
                      sx={{
                        ...textClasses.t12n,
                        color: "#827878",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {field?.name}
                    </Typography>
                  </Grid>
                  <Grid
                    container
                    item
                    sm={6}
                    xs={8}
                    direction="row"
                    alignItems={"center"}
                    sx={{
                      ...textClasses.t12n,
                      color: "#827878",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      textTransform: "capitalize",
                    }}
                  >
                    <Tooltip
                      placement="top"
                      title={
                        field?.value
                          ? field?.value?.length > 15
                            ? field?.value
                            : "-"
                          : deviceModelDetails[field?.key]
                          ? deviceModelDetails[field?.key]?.length > 15
                            ? deviceModelDetails[field?.key]
                            : "-"
                          : "-"
                      }
                    >
                      <Typography
                        sx={{
                          ...textClasses.t12n,
                          width: "100%",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {`:  ${
                          field?.value
                            ? field?.value
                            : field?.key === "isActive"
                            ? deviceModelDetails[field?.key] === true
                              ? "Active"
                              : "Disabled"
                            : deviceModelDetails[field?.key] ?? "--"
                        }`}
                      </Typography>
                    </Tooltip>
                  </Grid>
                </Grid>
              );
            })}

          <Box mt={2} width="100%">
            <table
              className={s["details-table"]}
              style={{
                width: "100%",
                ...textClasses?.t12n,
              }}
            >
              <thead>
                <tr
                  style={{
                    background: themeType?.default?.themeOrange,
                    borderTopLeftRadius: "5px",
                    borderTopRightRadius: "5px",
                  }}
                >
                  <td style={{ padding: "5px" }}>
                    <Typography
                      sx={{
                        ...textClasses.t12n,
                        color: "#fff",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      Category
                    </Typography>
                  </td>
                  <td style={{ padding: "5px" }}>
                    <Typography
                      sx={{
                        ...textClasses.t12n,
                        color: "#fff",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      Count
                    </Typography>
                  </td>
                </tr>
              </thead>
              <tbody>
                {deviceStatusCounts &&
                  tableFields?.map((row, index) => {
                    return (
                      <tr key={index}>
                        <td style={{ padding: "5px" }}>
                          <Typography
                            sx={{
                              ...textClasses.t12n,
                              color: "#827878",
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}
                          >
                            {row?.name}
                          </Typography>
                        </td>
                        <td style={{ padding: "5px" }}>
                          <Typography
                            sx={{
                              ...textClasses.t12n,
                              color: "#827878",
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}
                          >
                            {deviceStatusCounts[row?.key] || 0}
                          </Typography>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </Box>
          {/* permissions --- */}
          <Box mt={2} width="100%">
            <table
              className={s["details-table"]}
              style={{
                width: "100%",
                ...textClasses?.t12n,
              }}
            >
              <thead>
                <tr
                  style={{
                    background: themeType?.default?.themeOrange,
                    borderTopLeftRadius: "5px",
                    borderTopRightRadius: "5px",
                  }}
                >
                  <td style={{ padding: "5px" }}>
                    <Typography
                      sx={{
                        ...textClasses.t12n,
                        color: "#fff",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      Dynamic Keys
                    </Typography>
                  </td>
                </tr>
              </thead>
              <tbody>
                {deviceModelDetails &&
                  deviceModelDetails?.info?.map((obj, index) => {
                    return (
                      <tr key={index}>
                        <td style={{ padding: "5px" }}>
                          <Typography
                            sx={{
                              ...textClasses.t12n,
                              color: "#827878",
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}
                          >
                            {obj?.label}
                          </Typography>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </Box>
        </CardContent>
      </Card>
    </>
  );
};

export default DetailCard;
