import {
  Button,
  Typography,
  IconButton,
  Grid,
  Box,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import { useState, useEffect, useCallback, useMemo } from "react";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { CustomInput } from "../../../../components/FormUI";
import CustomSelect from "../../../../components/FormUI/CustomSelect";
import { useDispatch, useSelector } from "react-redux";
import { Cancel } from "@mui/icons-material";
import {
  getAllOrganisations,
  addOrganisation,
  editOrganisation,
  getOrganisation,
  getOrganisationFeatures,
  createPermissionRole,
} from "../../../../redux/actions";
import Actions from "../../../../utils/constants";
import { showToast } from "../../../../services/functions";
import CustomModel from "../../../Components/CustomModels/CustomModel1";
import { templateAxios } from "../../../../services/Api";

const defaultOrganisationData = {
  name: "",
  permissions: {},
  aesKey: "",
  representativeName: "",
  representativeEmail: "",
  representativeMobile: "",
  representativeDesignation: "",
  parentCompany: null,
  remark: "",
  addressDetails: {
    address: "",
    state: "",
    city: "",
    pincode: "",
  },
};

const AddOrganisationModal = ({
  action,
  handleClose,
  hide,
  open,
  refresh,
  selectedOrg,
  selectStylesOverride,
  show,
  themeType,
}) => {
  const dispatch = useDispatch();
  const organizations = useSelector((state) => state.userlist.allOrganizations);
  const [selectedFeatures, setSelectedFeatures] = useState([]);
  const [radioValue, setRadioValue] = useState("All");
  const [showAll, setShowAll] = useState(false);
  const { message, error } = useSelector((state) => state.common);
  const [addOrganisationStatus, setAddOrganisationStatus] = useState(false);
  const projectDetails = useSelector(
    (state) => state.Organisationlisting.organisationFeatures
  );
  const permissions = useMemo(() => {
    return projectDetails?.rows?.permissions ?? {};
  }, [projectDetails]);

  const selectedValue = useSelector(
    (state) => state.Organisationlisting.selectedOrganisation
  );

  // const mediaQuery = window.matchMedia("(max-width: 1400px)");


  useEffect(() => {
    if (show) {
      dispatch(getOrganisationFeatures());
    }
  }, [dispatch, show]);

  const features =
    permissions &&
    Object.keys(permissions)
      ?.filter((permission) => permission !== "label")
      .map((feature) => {
        return {
          label: permissions[feature].label,
          value: feature,
        };
      });

  useEffect(() => {
    if (action === Actions.EDIT && selectedOrg) {
      dispatch(getOrganisation({ id: selectedOrg }));
    }
  }, [dispatch, action, selectedOrg]);

  const {
    control,
    formState: { errors, dirtyFields },
    handleSubmit,
    reset,
    setValue,
    getValues,
  } = useForm();

  const initallySelectedFeatures = useMemo(() => {
    const keys = selectedValue[0] && Object.keys(selectedValue[0].permissions);
    const alreadySelectedFeatures = keys?.map((feature) => {
      return {
        label: selectedValue[0].permissions[feature].label,
        value: feature,
      };
    });
    return alreadySelectedFeatures;
  }, [selectedValue]);

  useEffect(() => {
    if (action === Actions.EDIT && selectedValue[0]) {
      const keys =
        selectedValue[0] && Object.keys(selectedValue[0].permissions);
      const alreadySelectedFeatures = keys?.map((feature) => {
        return {
          label: selectedValue[0].permissions[feature].label,
          value: feature,
        };
      });
      setSelectedFeatures(alreadySelectedFeatures);
      const present = features.every((item) =>
        alreadySelectedFeatures.some((feature) => feature.value === item.value)
      );
      if (present) {
        setRadioValue("All");
      } else {
        setRadioValue("select");
      }
      setValue("name", selectedValue[0].name);
      setValue(
        "representativeName",
        selectedValue[0]?.representativeName ?? ""
      );
      setValue(
        "representativeEmail",
        selectedValue[0]?.representativeEmail ?? ""
      );
      setValue(
        "representativeMobile",
        selectedValue[0]?.representativeMobile ?? ""
      );
      setValue(
        "representativeDesignation",
        selectedValue[0]?.representativeDesignation ?? ""
      );
      setValue(
        "pan",
        selectedValue[0]?.taxDetails?.pan ?? ""
      );
    }
  }, [action, selectedValue, setSelectedFeatures, setRadioValue, setValue]);

  const selectedOrgData = useMemo(() => {
    if (selectedValue[0]) {
      return {
        id: selectedValue[0]._id,
        name: selectedValue[0]?.name,
        permissions: selectedValue[0]?.permissions,
        aesKey: selectedValue[0]?.aesKey,
        representativeName: selectedValue[0]?.representativeName,
        representativeEmail: selectedValue[0]?.representativeEmail,
        representativeMobile: selectedValue[0]?.representativeMobile,
        representativeDesignation: selectedValue[0]?.representativeDesignation,
        remark: selectedValue[0]?.remark,
        parentCompany: selectedValue[0]?.parentCompany,
        addressDetails: {
          address: selectedValue[0]?.addressDetails?.address,
          state: selectedValue[0]?.addressDetails?.state,
          city: selectedValue[0]?.addressDetails?.city,
          pincode: selectedValue[0]?.addressDetails?.pincode,
        },
        taxDetails: {
          pan: selectedValue[0]?.taxDetails?.pan,
        },
      };
    } else return;
  }, [selectedValue]);

  const [addOrganisationData, setAddOrganisationData] = useState(
    defaultOrganisationData
  );

  const size = selectedFeatures.length;

  useEffect(() => {
    if (show) {
      dispatch(getAllOrganisations());
    }
  }, [dispatch, show]);

  const handleCloseAction = useCallback(() => {
    handleClose();
    setRadioValue("All");
    setSelectedFeatures([]);
    reset();
    refresh();
    setAddOrganisationData(defaultOrganisationData);
    setShowAll(false);
  }, [
    handleClose,
    refresh,
    reset,
    setAddOrganisationData,
    setRadioValue,
    setSelectedFeatures,
    setShowAll,
  ]);

  useEffect(() => {
    const temp = { ...permissions };
    if (radioValue === "All") {
      Object.keys(temp)?.forEach((feature) => {
        Object.keys(temp[feature])
          .filter((key) => key !== "label")
          .forEach((permission) => {
            temp[feature][permission].value = true;
          });
      });
      setAddOrganisationData((prev) => ({
        ...prev,
        permissions: temp,
      }));
    } else if (radioValue === "select") {
      const features = selectedFeatures.map((feature) => feature.value);
      Object.keys(temp).forEach((feature) => {
        if (features.includes(feature)) {
          Object.keys(temp[feature])
            .filter((key) => key !== "label")
            .forEach((permission) => {
              temp[feature][permission].value = true;
            });
        } else {
          delete temp[feature];
        }
        setAddOrganisationData((prev) => ({
          ...prev,
          permissions: temp,
        }));
      });
    }
  }, [permissions, radioValue, selectedFeatures, setAddOrganisationData]);

  const handleRemoveSelection = useCallback(
    (st) => {
      const updatedFeatures = selectedFeatures.filter((feature) => {
        return feature.value !== st.value;
      });
      setSelectedFeatures(updatedFeatures);
    },
    [setSelectedFeatures, selectedFeatures]
  );

  const getViewOnlyPermissions = useCallback(
    (permissions) => {
      const permissionsForViewOnly = Object.keys(permissions)?.reduce(
        (acc, permission) => {
          const permissionObject = {};
          const subPermissionsObject = {};
          Object.keys(addOrganisationData?.permissions[permission])?.forEach(
            (subPermission, index) => {
              if (subPermission === "label") {
                subPermissionsObject[subPermission] =
                  addOrganisationData?.permissions[permission][subPermission];
              }
              if (
                subPermission.startsWith("details") ||
                subPermission.startsWith("view") || subPermission.startsWith("isWebLogin") || subPermission.startsWith("isMobileLogin")
              ) {
                subPermissionsObject[subPermission] =
                  addOrganisationData?.permissions[permission][subPermission];
              }
            }
          );
          permissionObject[permission] = subPermissionsObject;
          acc = { ...acc, ...permissionObject };
          return acc;
        },
        {}
      );

      return permissionsForViewOnly;
    },
    [addOrganisationData]
  );

  const handleCreatePermissionRoles = useCallback(
    (organisationId) => {
      const permissionsForAdmin = addOrganisationData?.permissions;
      const payloadForAdmin = {
        name: "Administrative",
        orgId: organisationId,
        permissions: permissionsForAdmin,
        type: "admin",
      };

      const viewOnlypermissions = getViewOnlyPermissions(
        addOrganisationData?.permissions
      );

      const payloadForUser = {
        name: "Viewonly",
        type: "user",
        orgId: organisationId,
        permissions: viewOnlypermissions,
      };

      const dispatchers = [
        dispatch(createPermissionRole(payloadForAdmin)),
        dispatch(createPermissionRole(payloadForUser)),
      ];

      Promise.all(dispatchers)
        ?.then(() => {
          console.log("all success");
        })
        ?.catch((e) => {
          console.log("all failed");
        });
    },
    [addOrganisationData, dispatch, getViewOnlyPermissions]
  );

  const handleCreateOrganisation = useCallback(
    (data) => {
      if (addOrganisationData?.addressDetails?.pincode === "") {
        delete addOrganisationData?.addressDetails?.pincode;
      }
      if (addOrganisationData?.parentCompany === null) {
        delete addOrganisationData?.parentCompany;
      }

      setAddOrganisationStatus(true);
      dispatch(
        addOrganisation(addOrganisationData, (successData) => {
          const organisationId = successData?.data?.organizationId;
          handleCreatePermissionRoles(organisationId);

          setAddOrganisationStatus(false);
          handleCloseAction();
        })
      );
    },
    [
      addOrganisationData,
      dispatch,
      handleCloseAction,
      handleCreatePermissionRoles,
      setAddOrganisationStatus,
    ]
  );

  const handleUpdateOrganisation = useCallback(() => {
    console.log("in updateeeee");
    let tempData = {};
    Object.keys(dirtyFields)?.forEach((key) => {
      if (
        key === "address" ||
        key === "state" ||
        key === "city" ||
        key === "pincode"
      ) {
        if (!tempData["addressDetails"]) {
          tempData["addressDetails"] = {};
        }
        return (tempData["addressDetails"][key] =
          addOrganisationData?.addressDetails[key]);
      } else if (key === "pan") {
        tempData = {
          ...tempData,
          taxDetails: {
            pan: addOrganisationData?.taxDetails?.pan
          }
        }
      } else {
        return (tempData[key] = addOrganisationData[key]);
      }
    });

    if (initallySelectedFeatures.length !== selectedFeatures.length) {
      tempData["permissions"] = addOrganisationData?.permissions;
    }

    tempData["id"] = selectedValue[0]?._id;
    if (tempData?.parentCompany === null) {
      delete tempData?.parentCompany;
    }
    setAddOrganisationStatus(true);
    dispatch(
      editOrganisation(tempData, () => {
        setAddOrganisationStatus(false);
        handleCloseAction();
      })
    );
  }, [
    addOrganisationData,
    dirtyFields,
    dispatch,
    handleCloseAction,
    initallySelectedFeatures,
    selectedFeatures,
    selectedValue,
    setAddOrganisationStatus,
  ]);

  useEffect(() => {
    if (message && addOrganisationStatus) {
      showToast(message, false);
    }
  }, [message, addOrganisationStatus]);

  const { buttonClasses, inputClasses, textClasses } = themeType;

  useEffect(() => {
    if (action === Actions.EDIT) {
      setAddOrganisationData(selectedOrgData);
    }
  }, [setAddOrganisationData, action, selectedOrgData]);



  return (
    <CustomModel
      autoClose={false}
      show={open || false}
      onClose={() => hide()}
      minWidth={880}
      maxWidth={880}
      pb={3}
      pl={3}
      pr={3}
      maxHeight={"100vh"}
      childrenMaxHeight={"100vh"}
      Title={
        action === Actions.ADD ? "Add Organisation" : "Update Organisation"
      }
      viewCloseIcon={open}
    >
      <Grid container mb={2} data-testid="add-device-model-form-container" >
        <Grid
          md={12}
          sx={{maxHeight:'540px', overflow:'auto'}}
          // sx={{
          //   maxHeight: mediaQuery?.matches ? "500px" : undefined,
          //   overflowY: mediaQuery?.matches ? "scroll" : undefined,
          // }}
        >
          {/* first row */}
          <Grid item container spacing={2} mb={0} mt={2} md={12}>
            <Grid item md={4}>
              <Controller
                name="name"
                defaultValue={selectedValue[0]?.name}
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: "Organisation name is required",
                  },
                }}
                render={({ field: { onChange, value, name } }) => {
                  return (
                    <CustomInput
                    data-testid='orgName'
                      placeholder="Organisation name*"
                      selectStylesOverride={selectStylesOverride}
                      inputClasses={inputClasses}
                      name={name}
                      value={addOrganisationData?.name || ""}
                      onChange={(e) => {
                        setAddOrganisationData({
                          ...addOrganisationData,
                          name: e.target.value,
                        });
                        onChange({
                          ...addOrganisationData,
                          name: e.target.value,
                        });
                      }}
                      errors={errors}
                    />
                  );
                }}
              />
            </Grid>
            <Grid item md={4}>
              <Controller
                name="address"
                defaultValue={addOrganisationData?.addressDetails?.address}
                control={control}
                render={({ field: { onChange, value, name } }) => {
                  return (
                    <CustomInput
                      placeholder="Address"
                      data-testid='address'
                      selectStylesOverride={selectStylesOverride}
                      inputClasses={inputClasses}
                      name={name}
                      value={addOrganisationData?.addressDetails?.address || ""}
                      onChange={(e) => {
                        setAddOrganisationData({
                          ...addOrganisationData,
                          addressDetails: {
                            ...addOrganisationData.addressDetails,
                            address: e.target.value,
                          },
                        });
                        onChange({
                          ...addOrganisationData,
                          addressDetails: {
                            ...addOrganisationData.addressDetails,
                            address: e.target.value,
                          },
                        });
                      }}
                    />
                  );
                }}
              />
            </Grid>
            <Grid item md={4}>
              <Controller
                name="state"
                defaultValue={addOrganisationData?.addressDetails?.state ?? ""}
                control={control}
                render={({ field: { onChange, value, name } }) => {
                  return (
                    <CustomInput
                      placeholder="State"
                      data-testid='state'
                      selectStylesOverride={selectStylesOverride}
                      inputClasses={inputClasses}
                      name={name}
                      value={addOrganisationData?.addressDetails?.state || ""}
                      //   value={value}
                      onChange={(e) => {
                        setAddOrganisationData({
                          ...addOrganisationData,
                          addressDetails: {
                            ...addOrganisationData.addressDetails,
                            state: e.target.value,
                          },
                        });
                        onChange({
                          ...addOrganisationData,
                          addressDetails: {
                            ...addOrganisationData.addressDetails,
                            state: e.target.value,
                          },
                        });
                      }}
                      //   errors={errors}
                    />
                  );
                }}
              />
            </Grid>
          </Grid>

          {/* second row */}
          <Grid item container spacing={2} mt={0}>
            <Grid item md={4}>
              <Controller
                name="city"
                data-testid='city'
                control={control}
                defaultValue={addOrganisationData?.addressDetails?.city}
                render={({ field: { onChange, value, name } }) => {
                  return (
                    <CustomInput
                      placeholder="City"
                      selectStylesOverride={selectStylesOverride}
                      inputClasses={inputClasses}
                      name={name}
                      value={addOrganisationData?.addressDetails?.city || ""}
                      onChange={(e) => {
                        setAddOrganisationData({
                          ...addOrganisationData,
                          addressDetails: {
                            ...addOrganisationData.addressDetails,
                            city: e.target.value,
                          },
                        });
                        onChange({
                          ...addOrganisationData,
                          addressDetails: {
                            ...addOrganisationData.addressDetails,
                            city: e.target.value,
                          },
                        });
                      }}
                    />
                  );
                }}
              />
            </Grid>
            <Grid item md={4}>
              <Controller
                name="pincode"
                control={control}
                defaultValue={
                  addOrganisationData?.addressDetails?.pincode ?? ""
                }
                render={({ field: { onChange, value, name } }) => {
                  return (
                    <CustomInput
                    data-testid='pin'
                      placeholder="Pin Code"
                      selectStylesOverride={selectStylesOverride}
                      inputClasses={inputClasses}
                      name={name}
                      value={addOrganisationData?.addressDetails?.pincode || ""}
                      onChange={(e) => {
                        setAddOrganisationData({
                          ...addOrganisationData,
                          addressDetails: {
                            ...addOrganisationData.addressDetails,
                            pincode: e.target.value,
                          },
                        });
                        onChange({
                          ...addOrganisationData,
                          addressDetails: {
                            ...addOrganisationData.addressDetails,
                            pincode: e.target.value,
                          },
                        });
                      }}
                    />
                  );
                }}
              />
            </Grid>
            <Grid item md={4}>
              <Controller
                name="aesKey"
                control={control}
                defaultValue={addOrganisationData?.aesKey}
                rules={{
                  minLength: {
                    value: 16,
                    message: "Please enter atleast 16 digits",
                  },
                  pattern: {
                    value: /^[0-9]+$/,
                    message: "Please enter digits",
                  },
                }}
                render={({ field: { onChange, value, name } }) => {
                  return (
                    <CustomInput
                    data-testid='aes'
                      placeholder="AES Key"
                      selectStylesOverride={selectStylesOverride}
                      value={addOrganisationData?.aesKey || ""}
                      inputClasses={inputClasses}
                      name={name}
                      onChange={(e) => {
                        setAddOrganisationData({
                          ...addOrganisationData,
                          aesKey: e.target.value,
                        });
                        onChange(e.target.value);
                      }}
                      //   value={value}
                      //   onChange={onChange}
                      errors={errors}
                    />
                  );
                }}
              />
            </Grid>
            <Grid item md={4}>
              <Controller
                name="parentCompany"
                control={control}
                defaultValue={addOrganisationData?.parentCompany?.name ?? null}
                render={({ field: { onChange, value, name } }) => {
                  return (
                    <CustomSelect
                    data-testid='parent'
                      value={addOrganisationData?.parentCompany ?? null}
                      selectStylesOverride={selectStylesOverride}
                      onChange={(e, newVal) => {
                        const updatedOrgData = {
                          ...addOrganisationData,
                          parentCompany:
                            newVal !== null
                              ? { id: newVal?._id, name: newVal?.name }
                              : null,
                        };
                        setAddOrganisationData(updatedOrgData);
                        onChange(updatedOrgData);
                      }}
                      name={name}
                      label="Parent Company"
                      //   errors={errors}
                      options={organizations && organizations}
                      labelKey="name"
                      getOptionLabel={(option) => {
                        return option?.name;
                      }}
                    />
                  );
                }}
              />
            </Grid>
            <Grid item md={4}>
              <Controller
                name="pan"
                control={control}
                defaultValue={addOrganisationData?.taxDetails?.pan}
                rules={{
                  required: {
                    value: true,
                    message: "Pan number is required",
                  },
                  pattern: {
                    value: /^([A-Z]){5}([0-9]){4}([A-Z]){1}?$/,
                    message: "Please enter a valid pan number",
                  },
                }}
                render={({ field: { onChange, value, name } }) => {
                  return (
                    <CustomInput
                    data-testid='pan'
                      placeholder="Pan Number"
                      required
                      selectStylesOverride={selectStylesOverride}
                      value={addOrganisationData?.taxDetails?.pan || ""}
                      inputClasses={inputClasses}
                      name={name}
                      onChange={(e) => {
                        setAddOrganisationData({
                          ...addOrganisationData,
                          taxDetails: {
                            ...addOrganisationData?.taxDetails,
                            pan: e.target.value?.toUpperCase(),
                          },
                        });
                        onChange(e.target.value?.toUpperCase());
                      }}
                      //   value={value}
                      //   onChange={onChange}
                      errors={errors}
                    />
                  );
                }}
              />
            </Grid>
          </Grid>
          <Box sx={{ display: "flex", gap: "1rem" }} mt={2}>
            <Typography
              sx={{ fontSize: "14px", fontWeight: "400", alignSelf: "center" }}
            >
              Primary Point Of Contact
            </Typography>
          </Box>

          <Grid
            container
            borderRadius={2}
            border="1px solid #E0E0E0"
            my={2}
            p={2}
            sx={{ width: "100%" }}
          >
            <Grid item container spacing={2} mb={0} md={12}>
              <Grid item md={4}>
                <Controller
                  name="representativeName"
                  defaultValue={addOrganisationData?.representativeName}
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: "Name is required",
                    },
                  }}
                  render={({ field: { onChange, value, name } }) => {
                    return (
                      <CustomInput
                        placeholder="Name*"
                        data-testid='contact'
                        selectStylesOverride={selectStylesOverride}
                        inputClasses={inputClasses}
                        name={name}
                        value={addOrganisationData?.representativeName || ""}
                        onChange={(e) => {
                          setAddOrganisationData({
                            ...addOrganisationData,
                            representativeName: e.target.value,
                          });
                          onChange({
                            ...addOrganisationData,
                            representativeName: e.target.value,
                          });
                        }}
                        errors={errors}
                      />
                    );
                  }}
                />
              </Grid>
              <Grid item md={4}>
                <Controller
                  name="representativeEmail"
                  defaultValue={addOrganisationData?.representativeEmail}
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: "Email is required",
                    },
                    pattern: {
                      value: /^\S+@\S+\.\S+$/,
                      message: "Please enter a valid email",
                    },
                  }}
                  render={({ field: { onChange, value, name } }) => {
                    return (
                      <CustomInput
                      data-testid='email'
                        placeholder="Email*"
                        selectStylesOverride={selectStylesOverride}
                        inputClasses={inputClasses}
                        name={name}
                        value={addOrganisationData?.representativeEmail || ""}
                        onChange={(e) => {
                          setAddOrganisationData({
                            ...addOrganisationData,
                            representativeEmail: e.target.value,
                          });
                          onChange(e.target.value);
                        }}
                        errors={errors}
                      />
                    );
                  }}
                />
              </Grid>
              <Grid item md={4}>
                <Controller
                  name="representativeMobile"
                  //defaultValue={addOrganisationData?.representativeMobile}
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: "Mobile is required",
                    },
                    pattern: {
                      value: /^\d{7,15}$/,
                      message: "Please enter a valid number",
                    },
                  }}
                  render={({ field: { onChange, value, name } }) => {
                    return (
                      <CustomInput
                      data-testid='mobile'
                        placeholder="Mobile No*"
                        selectStylesOverride={selectStylesOverride}
                        inputClasses={inputClasses}
                        //value={addOrganisationData?.representativeMobile || ""}
                        value={value || ""}
                        name={name}
                        onChange={(e) => {
                          setAddOrganisationData({
                            ...addOrganisationData,
                            representativeMobile: e.target.value,
                          });
                          onChange(e.target.value);
                          // onChange({
                          //   ...addOrganisationData,
                          //   representativeMobile: e.target.value,
                          // });
                        }}
                        errors={errors}
                      />
                    );
                  }}
                />
              </Grid>
              <Grid item md={4}>
                <Controller
                  name="representativeDesignation"
                  defaultValue={addOrganisationData?.representativeDesignation}
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: "Designation is required",
                    },
                  }}
                  render={({ field: { onChange, value, name } }) => {
                    return (
                      <CustomInput
                        placeholder="Designation*"
                        data-testid='designation'
                        selectStylesOverride={selectStylesOverride}
                        inputClasses={inputClasses}
                        name={name}
                        value={
                          addOrganisationData?.representativeDesignation || ""
                        }
                        onChange={(e) => {
                          setAddOrganisationData({
                            ...addOrganisationData,
                            representativeDesignation: e.target.value,
                          });
                          onChange({
                            ...addOrganisationData,
                            representativeDesignation: e.target.value,
                          });
                        }}
                        //   value={value}
                        //   onChange={onChange}
                        errors={errors}
                      />
                    );
                  }}
                />
              </Grid>
              <Grid item md>
                <Controller
                  name="remark"
                  defaultValue={addOrganisationData?.remark}
                  control={control}
                  render={({ field: { onChange, value, name } }) => {
                    return (
                      <CustomInput
                        placeholder="Additional info, if any"
                        data-testid='remark'
                        selectStylesOverride={selectStylesOverride}
                        inputClasses={inputClasses}
                        name={name}
                        onChange={(e) => {
                          setAddOrganisationData({
                            ...addOrganisationData,
                            remark: e.target.value,
                          });
                          onChange({
                            ...addOrganisationData,
                            remark: e.target.value,
                          });
                        }}
                        value={addOrganisationData?.remark || ""}
                        //   onChange={onChange}
                      />
                    );
                  }}
                />
              </Grid>
            </Grid>
          </Grid>

          {/* <Box sx={{display: "flex", gap: "1rem"}} >
          <Typography sx={{ fontSize: "14px", fontWeight: "400", alignSelf: "center" }}>
             Device  Assign Permissions
            </Typography>
          </Box> */}
          {/*          
          <Grid
            container
            borderRadius={2}
            border="1px solid #E0E0E0"
            my={2}
            p={2}
            sx={{ width: "100%" }}
          >
             {checkboxes && checkboxes?.map((check, i) => {
              return (
                <Grid item md={4} key={i}>
                  <FormControlLabel
                    sx={{ color: "rgb(128, 128, 128)" }}
                    label={check?.label}
                    control={
                      <Checkbox
                        sx={{
                          color: "#ff7200",
                          "&.Mui-checked": {
                            color: "#ff7200",
                          },
                        }}
                        checked={check?.visibility}
                        onChange={(e) => {
                        //   handleCheckboxChange(e, check);
                        }}
                        name={check?.label}
                      />
                    }
                  />
                </Grid>
              );
            })}
           
          </Grid> */}

          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Typography sx={{ fontSize: "14px", fontWeight: "400" }}>
              Feature Module Permissions
            </Typography>
            <Typography sx={{ fontSize: "12px", fontWeight: "400" }}>
              Users under the organization can be able to access the selected
              features.
            </Typography>
          </Box>

          <Grid
            container
            borderRadius={2}
            border="1px solid #E0E0E0"
            my={2}
            p={2}
            sx={{
              width: "100%",
            }}
          >
            <Grid item container spacing={2} mb={0} md={12}>
              <Grid item md={4}>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  name="radio-buttons-group"
                  value={radioValue}
                  onChange={(e) => {
                    setRadioValue(e.target.value);
                  }}
                >
                  <FormControlLabel
                    sx={{
                      color: "rgb(128, 128, 128)",
                      "& .MuiFormControlLabel-label": { fontSize: "13px" },
                    }}
                    value="All"
                    label="All"
                    control={
                      <Radio
                        size="small"
                        data-testid='all'
                        sx={{
                          "&, &.Mui-checked": {
                            color: themeType.default.themeOrange,
                          },
                        }}
                      />
                    }
                  />
                  <FormControlLabel
                    sx={{
                      color: "rgb(128, 128, 128)",
                      "& .MuiFormControlLabel-label": { fontSize: "13px" },
                    }}
                    value="select"
                    label="Select Modules"
                    control={
                      <Radio
                        size="small"
                        data-testid='select'
                        sx={{
                          "&, &.Mui-checked": {
                            color: themeType.default.themeOrange,
                          },
                        }}
                      />
                    }
                  />
                </RadioGroup>
              </Grid>
              {radioValue === "select" && (
                <Grid item md={4} mt={"auto"}>
                  <Controller
                    defaultValue={selectedFeatures}
                    name="permissions"
                    control={control}
                    render={({ field: { onChange, value, name } }) => {
                      return (
                        <CustomSelect
                          value={selectedFeatures}
                          selectStylesOverride={selectStylesOverride}
                          disableClearable={true}
                          onChange={(e, newVal) => {
                            if (
                              selectedFeatures.length &&
                              action === Actions.EDIT
                            ) {
                              const uniqueNewValues = newVal.filter(
                                (newFeature) =>
                                  !selectedFeatures.some(
                                    (selectedFeature) =>
                                      selectedFeature.value === newFeature.value
                                  )
                              );
                              setSelectedFeatures([
                                ...selectedFeatures,
                                ...uniqueNewValues,
                              ]);
                              onChange([
                                ...selectedFeatures,
                                ...uniqueNewValues,
                              ]);
                            } else {
                              setSelectedFeatures(newVal);
                              onChange(newVal);
                            }
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              label="Select Features"
                              placeholder=""
                            />
                          )}
                          renderTags={() => null}
                          multiple
                          name={name}
                          label="Select Features"
                          options={
                            (features &&
                              features?.filter(
                                (feature) =>
                                  !selectedFeatures
                                    .map((f) => f.value)
                                    .includes(feature.value)
                              )) ||
                            []
                          }
                          labelKey="label"
                          getOptionLabel={(option) => {
                            return option?.label;
                          }}
                        />
                      );
                    }}
                  />
                </Grid>
              )}
            </Grid>
            <Grid container sx={{ mt: 1 }} spacing={1}>
              {radioValue === "select" &&
                selectedFeatures.slice(0, showAll ? size : 5).map((st, i) => {
                  return (
                    <Grid item sm={4} xs={6} key={i}>
                      <Grid
                        container
                        direction={"row"}
                        justifyContent="center"
                        alignItems={"center"}
                        sx={{
                          backgroundColor: themeType.default.themeLightOrange,
                          border: `1px solid ${themeType.default.themeOrange}`,
                          borderRadius: "12px",
                          pl: 1,
                          pr: 1,
                          minHeight: "40px",
                        }}
                      >
                        <Grid item sm={10} xs={8}>
                          <Typography
                            sx={{
                              ...textClasses.normalText,
                              color: themeType.default.themeOrange,
                              textAlign: "start",
                            }}
                          >
                            {st.label && st.label.length > 15
                              ? st.label.substring(0, 15) + "..."
                              : st.label}
                          </Typography>
                        </Grid>
                        {handleRemoveSelection && (
                          <Grid item sm={2} xs={4}>
                            <IconButton
                              sx={{ alignContent: "right" }}
                              onClick={() => handleRemoveSelection(st)}
                            >
                              <Cancel
                                sx={{ color: themeType.default.themeOrange }}
                              />
                            </IconButton>
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                  );
                })}
              {radioValue === "select" && size > 5 && (
                <>
                  <Grid
                    container
                    item
                    sm={4}
                    xs={6}
                    justifyContent="center"
                    alignItems={"center"}
                  >
                    <Typography
                      sx={{
                        ...textClasses.boldText,
                        color: themeType.default.themeOrange,
                        textDecoration: "underline",
                        cursor: "pointer",
                      }}
                      onClick={() => setShowAll(!showAll)}
                    >
                      {showAll ? <>Collapse</> : <>View All ({size})</>}
                    </Typography>
                  </Grid>
                </>
              )}
            </Grid>
          </Grid>
        </Grid>

        {/* action buttons */}
        <Grid
          item
          display="flex"
          justifyContent="center"
          gap={2}
          data-testid='cancel'
          alignItems="center"
          sx={{ width: "100%", paddingTop: "20px !important" }}
        >
          <Button
            variant="outlined"
            sx={{
              ...buttonClasses?.lynkitBlackFill,
              minHeight: "36px",
              minWidth: "140px",
            }}
            onClick={() => {
              handleClose();
              reset();
            }}
          >
            Cancel
          </Button>
          <Button
            //   disabled={isSubmitting || !isValid}
            variant="contained"
            data-testid='create'
            sx={{
              ...buttonClasses?.lynkitOrangeFill,
              minHeight: "36px",
              minWidth: "140px",
            }}
            onClick={
              handleSubmit(
                action === Actions.EDIT
                  ? handleUpdateOrganisation
                  : handleCreateOrganisation
              )

              // selectedModel ? handleUpdateModel : handleCreateModel
            }
          >
            {action === Actions.ADD ? "Create" : "Update"}
          </Button>
        </Grid>
      </Grid>
    </CustomModel>
  );
};

export default AddOrganisationModal;
