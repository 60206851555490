import React,{useRef} from "react";
import { Link } from "react-router-dom";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import StarBorder from "@mui/icons-material/StarBorder";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { useState } from "react";
import { Button, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import history from "../../../services/history";
export default function DrawerItemList({
  items,
  open,
  expandList,
  handleExpandList,
}) {
  const secondaryLinks = ["Map", "API Logs", "Logs", "Health", "Shift", "Drivers", "Resouces","Vehicle Master"]
  const currentUrl = window.location.pathname;
  const urlList = {
    users: [
      "/dashboard/users/organisation",
      "/dashboard/users/my-team",
      "/dashboard/users/user-role",
    ],
    devices: [
      "/dashboard/devices/device-models",
      "/dashboard/devices/add-device",
      "dashboard/devices/all-devices",
    ],
    settings: ["/dashboard/settings/trips", "/dashboard/settings/alerts"],
    trips: ["/dashboard/trips/create", "/dashboard/trips/bulk-create", "/dashboard/trips"]
  };
  const isDarkThemeEnabledSelector = useSelector(
    (state) => state.theme.themeDark
  );

  const activeSubMenu = (itemLength, url) => {
    if (!open) {
      if (itemLength == 0) {
        return currentUrl === url;
      } else {
        let urlParam = currentUrl.split("/")[2];
        let subRoute = urlList[urlParam];
        return (subRoute || []).includes(url);
      }
    } else {
      if (!itemLength) {
        return currentUrl === url;
      } else {
        return null;
      }
    }
  };
  const listItemButton = (item) => {
    return (
      <ListItemButton
        dense
        sx={{
          // "&.MuiListItem-root": {
          //   backgroundColor: "red",
          // },
          backgroundColor: isDarkThemeEnabledSelector
            ? "#121212"
            : "transparent",
          minHeight: 30,
          justifyContent: open ? "initial" : "center",
          "&:hover": {
            backgroundColor: isDarkThemeEnabledSelector ? "#121212" : "unset",
          },
        }}
      >
        <ListItemIcon
          sx={{
            height: "17px",
            minWidth: 0,
            maxWidth: "17px",
            mr: open ? 2 : "auto",
            justifyContent: "center",
          }}
        >
          {currentUrl == item.url ? item.activeIcon : item.icon}
        </ListItemIcon>
        <ListItemText
          sx={{ opacity: open ? 1 : 0 }}
          style={{
            color:
              !item.subItems && currentUrl == item.url
                ? "white"
                : isDarkThemeEnabledSelector
                  ? "white"
                  : "black",
          }}
        >
          <Typography variant="caption">{item.title}</Typography>
          {item.subItems && item.title == expandList && (
            <ExpandLess onClick={() => handleExpandList(0)} />
          )}
          {item.subItems && item.title != expandList && (
            <ExpandMore onClick={() => handleExpandList(1, item.title)} />
          )}
        </ListItemText>
      </ListItemButton>
    );
  };

  const collapseList = (item) => {
    return (
      <Collapse
        // sx={{ background: "red" }}
        in={item.title == expandList}
        timeout="auto"
        unmountOnExit
      >
        <List component="div" disablePadding dense>
          {item.subItems &&
            item.subItems.map((subItem, j) => {
              return (
                <Link
                  key={j}
                  to={subItem.url}
                  style={{ textDecoration: "none", backgroundColor: "red" }}
                >
                  <ListItemButton
                    key={subItem.title}
                    sx={{
                      pl: 1,
                      backgroundColor:
                        currentUrl == subItem.url
                          ? "#FF7200"
                          : isDarkThemeEnabledSelector
                            ? "#121212"
                            : "white",
                      color:
                        currentUrl == subItem.url
                          ? "white"
                          : isDarkThemeEnabledSelector
                            ? "white"
                            : "black",

                      "&:hover": {
                        backgroundColor:
                          currentUrl == subItem.url
                            ? "#FF7200"
                            : isDarkThemeEnabledSelector
                              ? "#121212"
                              : "white",
                        color:
                          currentUrl == subItem.url
                            ? "white"
                            : isDarkThemeEnabledSelector
                              ? "white"
                              : "black",
                      },
                    }}
                  >
                    <ListItemIcon></ListItemIcon>

                    <ListItemText
                      style={{
                        color: isDarkThemeEnabledSelector ? "white" : "black",
                      }}
                    >
                      <Typography variant="caption">{subItem.title}</Typography>
                    </ListItemText>
                  </ListItemButton>
                </Link>
              );
            })}
        </List>
      </Collapse>
    );
  };

  const redirectTo = (title) => {
    let localStorageToken = localStorage.getItem('token') ? localStorage.getItem('token').replace(/^"(.*)"$/, '$1') : null;
    let lynktracURL = process.env.REACT_APP_ENV === "development" ? `https://www.beta.lynktrac.io/` : `https://www.lynktrac.io/`;
    let lynkitIoURL = process.env.REACT_APP_ENV === "development" ? `https://beta.lynkit.io/login?auth=lynktrac&timestamp=${new Date().getTime()}&action=%2Fdriver` : `https://www.lynkit.io/login?auth=lynktrac&timestamp=${new Date().getTime()}&action=%2Fdriver`;
    // https: www.beta.lynktrac.io
    // https: preprod.lynktrac.ioip
    // https: lynktrac.io
    if (title === 'Health') {
      // window.open(lynktracURL, '_blank')
      // window.addEventListener("message", receivedMessage)
      // function receivedMessage(evt) {
      //   evt.source.postMessage({ token: localStorageToken, module: "health" }, evt.origin)
      // }
    }
    else if (title === 'Logs') {
      // window.open(lynktracURL, '_blank')
      // window.addEventListener("message", receivedMessage)
      // function receivedMessage(evt) {
      //   evt.source.postMessage({ token: localStorageToken, module: "logs" }, evt.origin)
      // }
    }
    else if (title === 'Shift') {
      window.open(lynktracURL, '_blank')
      window.addEventListener("message", receivedMessage)
      function receivedMessage(evt) {
        evt.source.postMessage({ token: localStorageToken, module: "shift" }, evt.origin)
      }
    }
    else if (title === 'API Logs') {
      window.open(lynktracURL, '_blank')
      window.addEventListener("message", receivedMessage)
      function receivedMessage(evt) {
        evt.source.postMessage({ token: localStorageToken, module: "apiLogs" }, evt.origin)
      }
    }
    else if (title === 'Drivers') {
      history.push('/dashboard/driver-master')
      // window.open(lynkitIoURL, '_blank')
      // window.addEventListener("message", receivedMessage)
      // function receivedMessage(evt) {
      //   evt.source.postMessage({ phrase: localStorageToken }, evt.origin)
      // }
    }
    else if (title === 'Map') {
      window.open(lynktracURL, '_blank')
      window.addEventListener("message", receivedMessage)
      function receivedMessage(evt) {
        evt.source.postMessage({ token: localStorageToken, module: "map" }, evt.origin)
      }
    }
    else if (title === 'Vehicle Master') {
      history.push('/dashboard/vehicle-master')
    }


  }


  return (
    <>
      {items.map((item, i) => {
        const itemStyle = {
          display: "block",
          backgroundColor: activeSubMenu(item?.subItems?.length || 0, item.url)
            ? "#FF7200"
            : "white",
          color: !item.subItems && currentUrl == item.url ? "white" : "black",
        };

        return (
          <div key={item.title}>
            {item.subItems ? (
              <ListItem
                dense
                key={item.title}
                disablePadding
                sx={itemStyle}
                onMouseEnter={() => handleExpandList(1, item.title)}
                onMouseLeave={() => handleExpandList(0)}
              >
                {listItemButton(item)}
                {collapseList(item)}
              </ListItem>
            ) : (
              <ListItem
                dense
                key={item.title}
                className="asas"
                disablePadding
                sx={itemStyle}
                component={item?.url ? Link : null}
                to={item.url}
                onClick={() => secondaryLinks?.includes(item.title) ? redirectTo(item?.title) : null}
              >
                {listItemButton(item)}
              </ListItem>
            )}
          </div>
        );
      })}
    </>
  );
}
