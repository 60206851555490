import react from "react";
import {
  Paper,
  Checkbox,
  CardContent,
  Typography,
  Card,
  CardTitle,
  Grid,
  Button,
  Tabs,
  Tab,
  Divider,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// IMPORT userEmpty from './'
import Box from "@mui/material/Box";
import userEmpty from "../../../../static/images/UserImages/userEmpty.png";
import { useCallback } from "react";
import { useMemo } from "react";
import _ from "lodash";
import { getShortText } from "../../../../services/functions";
import { preparePermissions } from "../../../../utils/permissionCheck";

const AddUserRightPanel = ({
  orgDetails,
  permissionRoles,
  permissions,
  userRolesPermissionListing,
  sidePermission,
  onChangePermissions,
  currentUserDetail,
}) => {
  const [tabIndex, setTabIndex] = React.useState(0);
  const dispatch = useDispatch();
  const organisationPermsissionsList = useMemo(() => {
    // this will be all permissions listing
    return currentUserDetail && currentUserDetail?.permissions
      ? preparePermissions(
          currentUserDetail?.userRole?.permissions || {},
          currentUserDetail?.permissions
        )
      : {};
  }, [currentUserDetail]);

  // const organisationPermsissionsList = useMemo(() => {
  //   return orgDetails && orgDetails[0] ? orgDetails[0]?.permissions : {};
  // }, [orgDetails]);

  const isDarkThemeEnabledSelector = useSelector(
    (state) => state.theme.themeDark
  );
  const themeType = isDarkThemeEnabledSelector
    ? require("../../../../static/styles/darktheme")
    : require("../../../../static/styles/theme");
  const { cardClasses, inputClasses, textClasses } = themeType;

  const areAllChecked = useMemo(() => {
    return Object.keys(organisationPermsissionsList)?.every((category) => {
      return Object.keys(organisationPermsissionsList[category])?.every(
        (permission) =>
          permissions &&
          permissions[category] &&
          permissions[category]?.hasOwnProperty(permission)
      );
    });
  }, [organisationPermsissionsList, permissions]);

  const _handleCheckbox = (e, category, subPermissionKey, item) => {
      if (item === "single") {
        onChangePermissions((prevPermissionsSet) => {
          const temp = JSON.parse(JSON.stringify(prevPermissionsSet));

          if (e.target.checked) {
            // Create the category if it doesn't exist
            if (!temp.hasOwnProperty(category)) {
              temp[category] = {
                label: organisationPermsissionsList[category]?.label,
              };
            }

            // Update the subPermissionKey
            temp[category][subPermissionKey] = {
              label:
                organisationPermsissionsList[category][subPermissionKey]?.label,
              value: true,
            };
          } else {
            // Uncheck the subPermissionKey
            if (
              temp.hasOwnProperty(category) &&
              temp[category].hasOwnProperty(subPermissionKey)
            ) {
              temp[category][subPermissionKey] = {
                label:
                  organisationPermsissionsList[category][subPermissionKey]?.label,
                value: false,
              };
              // delete temp[category][subPermissionKey];
            }

            // If category is empty, remove it
            if (
              Object.keys(temp[category]).length === 1 &&
              Object.keys(temp[category])[0] === "label"
            ) {
              delete temp[category];
            }
          }
          return temp;
        });
      } else if (item === "category" && category) {
        onChangePermissions((prevPermissions) => {
          const temp = { ...prevPermissions };
          if (e.target.checked) {
            if (!temp.hasOwnProperty(category)) {
              temp[category] = {
                label: organisationPermsissionsList[category]?.label,
              };
            }

            Object.keys(organisationPermsissionsList[category])
              .filter((permission) => permission !== "label")
              .forEach((key) => {
                {
                  temp[category][key] = {
                    label: organisationPermsissionsList[category][key].label,
                    value: true,
                  };
                }
              });
          } else {
            delete temp[category];
          }

          return temp;
        });
      } else if (item === "all") {
        onChangePermissions((prev) => {
          let temp = { ...organisationPermsissionsList };

          if (e.target.checked) {
            Object.keys(temp).forEach((categoryKey) => {
              Object.keys(temp[categoryKey]).forEach((subKey) => {
                if (subKey !== "label") {
                  temp[categoryKey][subKey].value = true;
                }
              });
            });
            return temp;
          } else {
            return userRolesPermissionListing?.permissions;
          }
        });
      }
    };

  const getTabStyles = (tIndex) => {
    return {
      backgroundColor:
        tabIndex == tIndex ? themeType.default.themeOrange : "transparent",
      color: tabIndex == tIndex ? "white" : themeType.default.themeOrange,
      border: "1px solid",
      boxShadow: "0px 2px 6px gray",
      borderColor: themeType.default.themeOrange,
      p: 0,
      pt: "10px",
      pb: "10px",
      fontSize: "14px",
      lineHeight: "19px",
      fontWeight: "400",
      textTransform: "none",
    };
  };

  return (
    <>
      {permissions && (
        <Paper
          sx={{
            borderRadius: "8px",
            height: "100%",
          }}
        >
          <Tabs
            value={tabIndex}
            aria-label="disabled tabs example"
            variant="fullWidth"
            sx={{
              "& .MuiTabs-indicator": { display: "none" },
              "& .MuiTab-root.Mui-selected": { color: "#FFFFFF" },
            }}
            onChange={(e, val) => setTabIndex(val)}
          >
            <Tab
              label="Role Permissions"
              sx={{
                ...getTabStyles(0),
                borderTopLeftRadius: "8px",
              }}
            />
            <Tab
              label="All Permissions"
              sx={{
                ...getTabStyles(1),
                marginLeft: "1px",
                borderTopRightRadius: "8px",
              }}
            />
          </Tabs>

          <Box
            sx={{
              height: "calc(100% - 50px)",
              overflowY: "scroll",
              pt: "8px",
            }}
          >
            {tabIndex === 0 ? (
              <Box px={2}>
                {userRolesPermissionListing &&
                userRolesPermissionListing?.permissions
                  ? Object.keys(userRolesPermissionListing?.permissions).map(
                      (category) => {
                        const categoryData =
                          userRolesPermissionListing?.permissions[category];
                        const filteredPermissions = Object.keys(categoryData)
                          ?.filter((permission) => permission !== "label")
                          ?.filter(
                            (permission) =>
                              categoryData[permission]?.value !== false
                          );

                        if (filteredPermissions?.length === 0) {
                          return null;
                        } else {
                          return (
                            <Grid container key={category}>
                              <Grid>
                                <Box>
                                  <Typography
                                    sx={{
                                      ...textClasses.normalText,
                                      fontWeight: "800",
                                    }}
                                  >
                                    {categoryData.label}
                                  </Typography>
                                </Box>
                              </Grid>
                              {filteredPermissions?.map((subPermissionKey) => {
                                if (
                                  categoryData[subPermissionKey] !== "label"
                                ) {
                                  const subPermissionData =
                                    categoryData[subPermissionKey];
                                  return (
                                    <Box
                                      display={"flex"}
                                      alignItems={"center"}
                                      key={subPermissionKey}
                                      width={"100%"}
                                    >
                                      <Checkbox
                                        checked={
                                          subPermissionData.value === true
                                        }
                                        disabled={
                                          subPermissionData.value === true
                                        }
                                        sx={{
                                          "&.Mui-disabled": {
                                            "&, &.Mui-checked": {
                                              color: "gray", // Set the color to gray for the disabled state
                                            },
                                          },
                                          "&, &.Mui-checked": {
                                            color:
                                              themeType.default.themeOrange,
                                          },
                                          marginBottom: "4px",
                                        }}
                                      />
                                      <Typography
                                        sx={{
                                          ...textClasses.normalText,
                                          paddingBottom: "4px",
                                        }}
                                      >
                                        {subPermissionData.label}
                                      </Typography>
                                    </Box>
                                  );
                                }
                                return null;
                              })}
                            </Grid>
                          );
                        }
                      }
                    )
                  : ""}
              </Box>
            ) : (
              <Box>
                <Box>
                  <Grid>
                    <Checkbox
                      checked={areAllChecked}
                      sx={{
                        "&, &.Mui-checked": {
                          color: themeType.default.themeOrange,
                        },
                        marginBottom: "4px",
                      }}
                      onChange={(e) => _handleCheckbox(e, null, null, "all")}
                    />
                    <span style={{ fontWeight: "900" }}>Select All</span>
                  </Grid>
                </Box>
                <Divider sx={{ mb: 0.5 }} />

                {organisationPermsissionsList && organisationPermsissionsList
                  ? Object.keys(organisationPermsissionsList)
                      .filter((key) => key !== "label")
                      .map((category) => {
                        const categoryData =
                          organisationPermsissionsList[category];
                        const filteredPermissions = Object.keys(categoryData)
                          ?.filter((permission) => permission !== "label")
                          ?.filter(
                            (permission) =>
                              categoryData[permission]?.isVisible ===
                                undefined ||
                              (!currentUserDetail?.adminSpecificPermissions &&
                                categoryData[permission]?.isVisible !==
                                  false) ||
                              currentUserDetail?.adminSpecificPermissions
                          );

                        if (filteredPermissions.length === 0) {
                          return null; // Skip rendering if all sub-permissions are invisible
                        } else {
                          // Check if all the permissions of this category are role permissions
                          const userRolePermissionsCategories =
                            userRolesPermissionListing?.permissions &&
                            Object.keys(
                              userRolesPermissionListing?.permissions
                            );
                          const userRoleCategoryPermissions =
                            userRolesPermissionListing?.permissions &&
                            userRolesPermissionListing?.permissions[category] &&
                            Object.keys(
                              userRolesPermissionListing?.permissions[category]
                            ).filter((permission) => permission !== "label");
                          const organisationCategoryPermissions = Object.keys(
                            filteredPermissions
                          ).filter((permission) => permission !== "label");
                          const isCategoryDisabled =
                            userRolePermissionsCategories?.includes(category) &&
                            userRoleCategoryPermissions?.length ===
                              organisationCategoryPermissions?.length &&
                            organisationCategoryPermissions?.every(
                              (permission) =>
                                userRoleCategoryPermissions?.includes(
                                  permission
                                )
                            );

                          return (
                            <Grid container key={category}>
                              <Grid>
                                <Box
                                  width={"100%"}
                                  pl={"0px"}
                                  display="flex"
                                  alignItems="center"
                                >
                                  <Checkbox
                                    data-testid="categoryCheckbox"
                                    disabled={isCategoryDisabled}
                                    checked={
                                      permissions && permissions[category]
                                        ? Object.keys(
                                            organisationPermsissionsList[
                                              category
                                            ]
                                          )
                                            .filter(
                                              (permission) =>
                                                permission !== "label"
                                            )
                                            .every((value) => {
                                              return Object.keys(
                                                permissions[category]
                                              ).includes(value)
                                                ? true
                                                : false;
                                            })
                                        : false
                                    }
                                    onChange={(e) =>
                                      _handleCheckbox(
                                        e,
                                        category,
                                        null,
                                        "category"
                                      )
                                    }
                                    sx={{
                                      "&.Mui-disabled": {
                                        "&, &.Mui-checked": {
                                          color: "gray", // Set the color to gray for the disabled state
                                        },
                                      },
                                      "&, &.Mui-checked": {
                                        color: themeType.default.themeOrange,
                                      },
                                      marginBottom: "4px",
                                    }}
                                  />
                                  <Typography
                                    sx={{
                                      ...textClasses.normalText,
                                      fontWeight: "800",
                                    }}
                                  >
                                    {categoryData.label}
                                  </Typography>
                                  {/* <span>
                                    {" "}
                                    {getShortText(categoryData.label, 30)}{" "}
                                  </span> */}
                                </Box>
                              </Grid>
                              {filteredPermissions?.map((subPermissionKey) => {
                                if (
                                  categoryData[subPermissionKey] !== "label"
                                ) {
                                  const subPermissionData =
                                    categoryData[subPermissionKey];
                                  return (
                                    <Box
                                      display={"flex"}
                                      alignItems={"center"}
                                      sx={{ pl: "10px" }}
                                      key={subPermissionKey}
                                      width={"100%"}
                                    >
                                      <Checkbox
                                        checked={
                                          (permissions &&
                                            permissions[category]?.[
                                              subPermissionKey
                                            ]?.value === true) ||
                                          (userRolesPermissionListing?.permissions &&
                                            userRolesPermissionListing
                                              ?.permissions[category]?.[
                                              subPermissionKey
                                            ]?.value === true)
                                        }
                                        disabled={
                                          userRolesPermissionListing?.permissions &&
                                          userRolesPermissionListing
                                            ?.permissions[category]?.[
                                            subPermissionKey
                                          ]?.value === true
                                        }
                                        sx={{
                                          "&.Mui-disabled": {
                                            "&, &.Mui-checked": {
                                              color: "gray", // Set the color to gray for the disabled state
                                            },
                                          },
                                          "&, &.Mui-checked": {
                                            color:
                                              themeType.default.themeOrange,
                                          },
                                          marginBottom: "4px",
                                        }}
                                        onChange={(e) =>
                                          _handleCheckbox(
                                            e,
                                            category,
                                            subPermissionKey,
                                            "single"
                                          )
                                        }
                                      />
                                      <Typography
                                        sx={{
                                          ...textClasses.normalText,
                                          paddingBottom: "4px",
                                        }}
                                      >
                                        {subPermissionData.label}
                                      </Typography>
                                    </Box>
                                  );
                                }
                                return null;
                              })}
                            </Grid>
                          );
                        }
                      })
                  : ""}
              </Box>
            )}
          </Box>
        </Paper>
      )}
    </>
  );
};

export default AddUserRightPanel;
