import {
  FETCH_WAREHOUSES, FETCH_FLOORS, FETCH_MAPPING, 
  SET_SELECTED_TEMPLATE

} from "../types";

const INIT_STATE = {
  allWarehouses: [],
  allFloors: [],
  allMapping: [],

  selectedTemplate: null,
  packages: []
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    // case CLEAR_ENTRY: return {...state, selectedTemplate: null, packages: []}
    case SET_SELECTED_TEMPLATE: return {...state, selectedTemplate: action.payload}
    case FETCH_WAREHOUSES: return {...state, allWarehouses: action.payload};
    case FETCH_FLOORS: return {...state, allFloors: action.payload}
    case FETCH_MAPPING: return {...state, allMapping: action.payload}

    default:
      return state;
  }
}