import { Search } from "@mui/icons-material";
import {
  Autocomplete,
  Box,
  Checkbox,
  Divider,
  FormControlLabel,
  IconButton,
  InputAdornment,
  MenuItem,
  Paper,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React from "react";

const CustomSelect = ({
  name,
  label,
  options = [],
  value,
  extraClasses,
  required = false,
  onChange,
  disabled,
  searchValue = null,
  onSearch = () => {},
  errors = {},
  selectStylesOverride,
  getOptionLabel,
  renderOptions,
  labelKey = "label",
  helperText,
  onFocus,
  ...props
}) => {
  return (
    <Box display="flex" flexDirection="column" sx={{ height: "max-content" }}>
      <Autocomplete
        {...props}
        role="combobox"
        value={value}
        key={value}
        size="small"
        options={options}
        //sx={{ height: "1.1rem" }}
        onChange={onChange}
        disabled={disabled}
        filterOptions={searchValue ? (options) => options : undefined}
        clearOnBlur={searchValue && false}
        getOptionLabel={getOptionLabel}
        error={errors[name]}
        renderInput={(params) => (
          <TextField
            sx={{
              fontSize: "12px",
              ...selectStylesOverride,
              ...extraClasses,
            }}
            {...params}
            error={errors[name] ? true : false}
            helperText={helperText}
            onFocus={onFocus ? onFocus : null}
            label={
              label ? (
                <Typography sx={{ fontSize: "12px" }}>
                  {label}
                  {required && "*"}
                </Typography>
              ) : null
            }
            onKeyDown={(ev) => {
              if (ev.key === 'Enter') {
                // Do code here
                ev.preventDefault();
                onSearch && onSearch();
              }
            }}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {searchValue && searchValue?.length > 0 ? (
                    <IconButton
                      size="small"
                      color=""
                      onClick={onSearch && onSearch}
                    >
                      <Search fontSize="12px" />
                    </IconButton>
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
              //disableUnderline: true,
            }}
          />
        )}
        renderOption={renderOptions ? renderOptions : (props, option, { selected }) => {
          return (
            <MenuItem value={value || ""} selected={selected} {...props}>
              <Typography
                sx={{
                  fontSize: ".8rem",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                }}
              >
                {option[labelKey] || ""}
              </Typography>
            </MenuItem>
          );
        }}
        // value={{
        //   value: value,
        //   label: options?.find((dl) => dl._id == value)?.name || "",
        // }}
        //disableCloseOnSelect={multiple ? true : false}
      />
      {errors[name] && (
        <span role="alert" style={{ color: "#FF0101", fontSize: 11, marginTop : "2px" }}>
          {errors[name]?.message}
        </span>
      )}
    </Box>
  );
};

export default CustomSelect;
