import {
  DEVICES_LIST,
  DEVICE_MODELS,
  DEVICE_MODEL_DETAILS,
  DEVICE_MODEL_SETTINGS,
  FLUSH_DEVICES_LIST,
  FLUSH_DEVICE_MODELS,
  GET_DEVICE_DETAILS,
  GET_PORTS,
  LIST_ALERT_SETTINGS,
  LIST_DEVICE_CATEGORY_NAMES,
  LIST_DEVICE_PERMISSION,
  DEVICE_PERMISSIONS,
  SET_FLAG,
  DEVICE_TEMPLATE,
  DEVICE_MODEL_STATUS_COUNT,
  DEVICE_STATUS,
  FETCH_ADDRESS,
  CLEAR_DEVICE_TEMPLATE,
  FETCH_DEVICES_FLAG,
  ADMINS_LIST,
  CAMERA_TOKEN,
  CAMERA_ALERTS,
  CAMERA_PLAYBACKS,
  FETCH_ALERTS_FLAG,
  DEVICE_TRACK_LIST,
  EMPTY_DEVICE_TRACK_LISTING,
  DEVICE_ALERT_LIST,
  EMPTY_DEVICE_ALERT_LISTING,
  EMPTY_DEVICE_DETAILS,
  DEVICE_MARKERS,
  WEB_COMMAND_NAMES,
  SMS_COMMAND_NAMES,
  GET_COMMANDS
} from "../types";

const INIT_STATE = {
  // devices ---
  allDevices: [],
  totalRecords: 0,
  deviceDetails: null,
  deviceDetailsTemplate: null,
  devicePermissions: [],
  deviceStatusCounts: null,
  // device models ---
  deviceModelSettings: [],
  allDeviceModels: [],
  deviceModelDetails: null,
  totalDeviceModels: 0,
  deviceModelStatusCounts: null,
  // others ---
  allDeviceCategoryNames: [],
  allAlerts: [],
  alldevicePermissions: [],
  allPorts: [],
  editFlag: false,
  deviceListFlag: false,
  fetchedAddress: null,
  deviceStatusGraphData: null,
  deviceLockStatusGraphData: null,
  deviceMovementReportData: null,
  allUserCommands: [],
  userCommandsLoading: false,
  adminLists: [],
  cameraTokenValue: null,
  cameraAlerts: [],
  cameraPlaybacks: [],
  alertListFlag: true,
  devicesFilters: null,
  deviceTrackListing: [],
  deviceAlertListing: [],
  deviceMarkers: [],
  commandLists: [],
  allCommands: [],
  deviceDetailsLoading: false,
  allocateDeviceLoading: false,
};

/** TODO: Keep only 1 device permission list */

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case DEVICES_LIST:
      return {
        ...state,
        allDevices: action?.payload?.rows,
        totalRecords: action?.payload?.count,
      };
    case ADMINS_LIST:
      return {
        ...state,
        adminLists: action?.payload,
      };
    case FLUSH_DEVICES_LIST:
      return { ...state, allDevices: [], totalRecords: 0 };
    case DEVICE_PERMISSIONS:
      return { ...state, devicePermissions: action.payload?.rows };
    case FLUSH_DEVICE_MODELS:
      return { ...state, allDeviceModels: [], totalDeviceModels: 0 };
    case SET_FLAG:
      return { ...state, editFlag: action?.payload };
    case FETCH_DEVICES_FLAG:
      return { ...state, deviceListFlag: action?.payload };
    case DEVICE_MARKERS:
      return { ...state, deviceMarkers: action.payload.rows };
    case DEVICE_MODELS:
      return {
        ...state,
        allDeviceModels: action?.payload?.rows,
        totalDeviceModels: action?.payload?.count,
      };
    case DEVICE_MODEL_SETTINGS:
      return {
        ...state,
        deviceModelSettings: action?.payload,
      };
    case LIST_DEVICE_CATEGORY_NAMES:
      return {
        ...state,
        allDeviceCategoryNames: action?.payload,
      };
    case LIST_ALERT_SETTINGS:
      return {
        ...state,
        allAlerts: action?.payload,
      };
    case LIST_DEVICE_PERMISSION:
      return {
        ...state,
        alldevicePermissions: action?.payload,
      };
    case GET_PORTS:
      return {
        ...state,
        allPorts: action?.payload?.map((item) => ({
          label: item,
          value: item,
        })),
      };
    case DEVICE_MODEL_DETAILS:
      return {
        ...state,
        deviceModelDetails: action?.payload,
      };
    case GET_DEVICE_DETAILS:
      return {
        ...state,
        deviceDetails: action?.payload,
      };
    case "FLUSH_DEVICE_DETAILS":
      return {
        ...state,
        deviceDetails: null,
      };
    case WEB_COMMAND_NAMES:
      return {
        ...state,
        commandLists: action?.payload,
      };
    case SMS_COMMAND_NAMES:
      return {
        ...state,
        commandLists: action?.payload,
      };
    case GET_COMMANDS:
      return {
        ...state,
        allCommands: action?.payload,
      };
    case EMPTY_DEVICE_DETAILS:
      return {
        ...state,
        deviceDetails: {},
      };
    case DEVICE_TEMPLATE:
      return {
        ...state,
        deviceDetailsTemplate: action?.payload,
      };
    case DEVICE_STATUS:
      return {
        ...state,
        deviceStatusCounts: action?.payload,
      };
    case DEVICE_MODEL_STATUS_COUNT:
      return {
        ...state,
        deviceModelStatusCounts: action?.payload,
      };
    case FETCH_ADDRESS:
      return {
        ...state,
        fetchedAddress: action?.payload[0],
      };
    case CLEAR_DEVICE_TEMPLATE:
      return {
        ...state,
        deviceDetailsTemplate: null,
      };
    case "DEVICE_LOCK_STATUS_GRAPH":
      return {
        ...state,
        deviceLockStatusGraphData: action?.payload,
      };
    case "DEVICE_STATUS_GRAPH":
      return {
        ...state,
        deviceStatusGraphData: action?.payload,
      };
    case "DEVICE_MOVEMENT_REPORT":
      return {
        ...state,
        deviceMovementReportData: action?.payload,
      };
    case "FLUSH_DEVICE_MOVEMENT_REPORT":
      return {
        ...state,
        deviceMovementReportData: null,
      };
    case "GET_USER_COMMANDS":
      return {
        ...state,
        allUserCommands: action?.payload,
      };
    case "USER_COMMANDS_LOADING":
      return {
        ...state,
        userCommandsLoading: action?.payload,
      };
    case "FLUSH_USER_COMMANDS":
      return {
        ...state,
        allUserCommands: [],
      };
    case CAMERA_TOKEN:
      return {
        ...state,
        cameraTokenValue: action?.payload,
      };
    case CAMERA_ALERTS:
      return {
        ...state,
        cameraAlerts: action?.payload,
      };
    case CAMERA_PLAYBACKS:
      return {
        ...state,
        cameraPlaybacks: action?.payload,
      };
    case "DEVICE_DETAILS_LOADING":
      return {
        ...state,
        deviceDetailsLoading: action?.payload,
      };
    case "ALLOCATE_DEVICE_LOADING":
      return {
        ...state,
        allocateDeviceLoading: action?.payload,
      };
    case DEVICE_TRACK_LIST:
      return {
        ...state,
        deviceTrackListing: action.payload.rows,
      };
    case EMPTY_DEVICE_TRACK_LISTING:
      return {
        ...state,
        deviceTrackListing: [],
      };
    case DEVICE_ALERT_LIST:
      return {
        ...state,
        deviceAlertListing: action.payload.rows,
      };
    case EMPTY_DEVICE_ALERT_LISTING:
      return {
        ...state,
        deviceAlertListing: [],
      };


    case "DEVICES_FILTERS":
      return {
        ...state,
        devicesFilters: action?.payload,
      };
    case FETCH_ALERTS_FLAG:
      return { ...state, alertListFlag: action.payload };

    default:
      return state;
  }
};

export const flushAllDevices = () => ({
  type: FLUSH_DEVICES_LIST,
});

export const flushDeviceDetails = () => ({
  type: "FLUSH_DEVICE_DETAILS",
});

export const flushAllDeviceModels = () => ({
  type: FLUSH_DEVICE_MODELS,
});

export const flushDeviceMovementReport = () => ({
  type: "FLUSH_DEVICE_MOVEMENT_REPORT",
});

export const flushUserCommands = () => ({
  type: "FLUSH_USER_COMMANDS",
});

export const setDevicesFilters = (payload) => ({
  type: "DEVICES_FILTERS", payload: payload
});
