import { Cancel, Close } from "@mui/icons-material";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import React from "react";
import { Controller, useForm, useWatch } from "react-hook-form";
import CustomSelect from "../../../../components/FormUI/CustomSelect";
import { CustomInput } from "../../../../components/FormUI";
import { useDispatch, useSelector } from "react-redux";
import {
  addNewDeviceModel,
  getAllDeviceCategoryNames,
  getAllDeviceModelSettings,
  updateDeviceModel,
} from "../../../../redux/actions";
import { showToast } from "../../../../services/functions";
import CustomMultiSelect from "../../../../components/FormUI/CustomMultiSelect";
import CustomModel from "../../../Components/CustomModels/CustomModel1";
import { sendCustomEventAnalytics } from "../../../../utils/util";

const AddDeviceModel = ({
  open,
  hide,
  handleClose,
  themeType,
  selectedModel = null,
  searchFields,
  setSearchFields,
  selectStylesOverride,
  refresh = () => {},
}) => {
  const { buttonClasses, inputClasses, textClasses, tableClasses } = themeType;

  //dispatcher ----
  const dispatch = useDispatch();

  //selector ----
  const { allAlerts, allDeviceCategoryNames, deviceModelSettings } =
    useSelector((state) => state.deviceslist);


  //local states ----
  const [checkboxes, setCheckboxes] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [showAll, setShowAll] = React.useState(false);
  const {
    control,
    getValues,
    formState: { errors, isSubmitting, isValid },
    handleSubmit,
    reset,
    setValue,
  } = useForm({ mode: "onchange" });

  const alertsSelected = useWatch({
    control: control,
    name: "alerts",
  });

  //handlers -------
  const handleCreateModel = (data) => {
    const allTrueChecks = checkboxes?.filter((_) => _?.visibility === true);
    if (allTrueChecks?.length === 0) {
      showToast("Please select a permission", true);
      return;
    }
    sendCustomEventAnalytics("device-model", "create-device-model", "create device model")
    try {
      setLoading(true);
      const info = checkboxes?.map((item) => {
        return {
          id: item?._id,
          key: item?.key,
          visibility: item?.visibility,
        };
      });

      const alertIds = data?.alerts?.map((_) => _?._id);

      const finalPayload = {
        ...data,
        alerts: alertIds,
        info: info,
      };

      dispatch(
        addNewDeviceModel(finalPayload, (res) => {
          if (res) {
            refresh && refresh();
            handleClose();
          }
          setLoading(false);
        })
      );
    } catch (e) {
      setLoading(false);
    }
  };

  const handleUpdateModel = (data) => {
    const allTrueChecks = checkboxes?.filter((_) => _?.visibility === true);
    if (allTrueChecks?.length === 0) {
      showToast("Please select a permission", true);
      return;
    }
    sendCustomEventAnalytics("device-model", "update-device-model", "update device model")
    try {
      setLoading(true);
      const info = checkboxes?.map((item) => {
        return {
          id: item?._id,
          key: item?.key,
          visibility: item?.visibility,
        };
      });

      let tempAlerts = [];
      data?.alerts?.map((alert) => {
        tempAlerts?.push(alert?._id);
      });

      const finalPayload = {
        id: selectedModel?._id,
        category: data?.category?.category,
        manufactureName: data?.manufactureName,
        deviceModel: data?.deviceModel,
        lynkDeviceModel: data?.lynkDeviceModel,
        isActive: selectedModel?.isActive,
        alerts: tempAlerts,
        info: info,
      };

      dispatch(
        updateDeviceModel(finalPayload, false, (res) => {
          if (res) {
            refresh && refresh();
            handleClose();
          }
          setLoading(false);
        })
      );
    } catch (e) {
      setLoading(false);
    }
  };

  const handleSearchDeviceType = () => {
    if (searchFields?.deviceType || searchFields?.deviceType == "")
      dispatch(
        getAllDeviceCategoryNames(
          {
            searchValue: searchFields?.deviceType,
          },
          (res) => {
            if(res && !res.success)
              setSearchFields((prev) => ({
                ...prev,
                deviceType: null,
              }));
          }
        )
      );
  };

  const handleCheckboxChange = (e, check) => {
    if (e?.target?.checked) {
      let temp = checkboxes?.map((item) => {
        if (item?._id === check?._id) {
          return {
            ...item,
            visibility: true,
          };
        } else {
          return item;
        }
      });

      setCheckboxes(temp);
    } else {
      let temp = checkboxes?.map((item) => {
        if (item?._id === check?._id) {
          return {
            ...item,
            visibility: false,
          };
        } else {
          return item;
        }
      });

      setCheckboxes(temp);
    }
  };

  const handleRemoveSelection = React.useCallback(
    (st) => {
      const updatedAlerts = alertsSelected?.filter((alert) => {
        return alert?._id !== st?._id;
      });
      setValue("alerts", updatedAlerts);
    },
    [alertsSelected]
  );

  const handleCloseModal = (event, reason) => {
    if (reason && reason == "backdropClick") return;
    handleClose();
  };

  const handleToggleSelectAllAlerts = (e, onChange) => {
    console.log("checked: ", e?.target?.checked);
    if (allAlerts?.length > 0) {
      if (e?.target?.checked) {
        setValue("alerts", allAlerts);
        onChange(allAlerts);
      } else {
        setValue("alerts", []);
        onChange([]);
      }
    }
  };

  /** ---Effects ----- */
  
  // React.useEffect(() => {
  //   reset();
  //   dispatch(getAllDeviceModelSettings());
  // }, []);

  React.useEffect(() => {
    if (open) {
      console.log("inside effect");
      reset();
      dispatch(getAllDeviceModelSettings());
    }
  }, [open]);

  React.useEffect(() => {
    if (open && deviceModelSettings && deviceModelSettings?.length > 0) {
      if (
        selectedModel &&
        allDeviceCategoryNames &&
        allDeviceCategoryNames?.length > 0 &&
        allAlerts &&
        allAlerts?.length > 0
      ) {
        setTimeout(() => {
          Object?.keys(selectedModel)?.map((key) => {
            setValue(key, selectedModel[key], { shouldValidate: true });
            if (key === "category")
              setValue(
                key,
                allDeviceCategoryNames?.find(
                  (_) => _?.category === selectedModel[key]
                ), { shouldValidate: true }
              );

            if (key === "alerts") {
              const tempAlerts = selectedModel[key]?.map((_) => {
                return allAlerts?.find((alertObj) => alertObj?._id == _);
              });
              const filtered = tempAlerts?.filter((_) => _ !== undefined);
              setValue(key, filtered, { shouldValidate: true });
            }

            if (key === "info") {
              console.log('asas',selectedModel[key])
              setCheckboxes(selectedModel[key]);
            }
          });

        }, 300);
      } else {
        let tempData = deviceModelSettings?.map((setting) => {
          return {
            ...setting,
            visibility: false,
          };
        });
        setCheckboxes(tempData)
      }
    }
  }, [
    selectedModel,
    allDeviceCategoryNames,
    allAlerts,
    open,
    setValue,
    deviceModelSettings,
  ]);

  return (
    <CustomModel
      autoClose={false}
      show={open || false}
      onClose={() => hide()}
      minWidth={770}
      maxWidth={770}
      pb={3}
      pl={3}
      pr={3}
      maxHeight={"81vh"}
      childrenMaxHeight={"81vh"}
      Title={selectedModel ? "Update Device Model" : "Add Device Model"}
      viewCloseIcon={open}
    >
      <DialogContent dividers sx={{ maxHeight: "70.5vh", overflowY: "scroll" }}>
        <Grid
          container
          rowSpacing={2}
          data-testid="add-device-model-form-container"
        >
          {/* first row */}
          <Grid item container spacing={2} mb={0} mt={0} md={12}>
            <Grid item md={4} data-testid="device-type-control">
              <Controller
                name="category"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: "Device Type is required",
                  },
                }}
                render={({ field: { onChange, value, name } }) => {
                  return (
                    <CustomSelect
                      selectStylesOverride={selectStylesOverride}
                      value={value || null}
                      onChange={(e, newVal) => {
                        onChange(selectedModel ? newVal : newVal?.category);
                      }}
                      searchValue={searchFields?.deviceType}
                      onSearch={handleSearchDeviceType}
                      onInputChange={(e) => {
                        if (e?.type === "change") {
                          setSearchFields((prev) => ({
                            ...prev,
                            deviceType: e?.target?.value?.trim(),
                          }));
                        }
                      }}
                      name={name}
                      required
                      label="Device Type"
                      errors={errors}
                      getOptionLabel={(option) => {
                        if (typeof option === "string") {
                          return (
                            (allDeviceCategoryNames &&
                              allDeviceCategoryNames?.find(
                                (_) => _?.category === option
                              )?.label) ||
                            ""
                          );
                        } else {
                          return option?.label || "";
                        }
                      }}
                      labelKey="label"
                      options={allDeviceCategoryNames && allDeviceCategoryNames}
                    />
                  );
                }}
              />
            </Grid>
            <Grid item md={4} data-testid="device-model-control">
              <Controller
                name="lynkDeviceModel"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: "Device Model is required",
                  },
                }}
                render={({ field: { onChange, value, name } }) => {
                  return (
                    <CustomInput
                      placeholder="Device Model"
                      selectStylesOverride={selectStylesOverride}
                      inputClasses={inputClasses}
                      name={name}
                      value={value || ""}
                      required
                      onChange={onChange}
                      errors={errors}
                    />
                  );
                }}
              />
            </Grid>
            <Grid item md={4} data-testid="oem-model-text">
              <Controller
                name="deviceModel"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: "OEM Model is required",
                  },
                }}
                render={({ field: { onChange, value, name } }) => {
                  return (
                    <CustomInput
                      required
                      placeholder="OEM Model"
                      selectStylesOverride={selectStylesOverride}
                      inputClasses={inputClasses}
                      name={name}
                      value={value || ""}
                      onChange={onChange}
                      errors={errors}
                    />
                  );
                }}
              />
            </Grid>
          </Grid>

          {/* second row */}
          <Grid item container spacing={2} data-testid="oem-text">
            <Grid item md={4}>
              <Controller
                name="manufactureName"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: "OEM Name is required",
                  },
                }}
                render={({ field: { onChange, value, name } }) => {
                  return (
                    <CustomInput
                      placeholder="OEM Name"
                      selectStylesOverride={selectStylesOverride}
                      inputClasses={inputClasses}
                      name={name}
                      required
                      value={value || ""}
                      onChange={onChange}
                      errors={errors}
                    />
                  );
                }}
              />
            </Grid>
            <Grid item md data-testid="alerts-multi">
              <Controller
                name="alerts"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: "Alerts are required",
                  },
                }}
                render={({ field: { onChange, value, name } }) => {
                  return (
                    <CustomMultiSelect
                      value={value || []}
                      required
                      selectStylesOverride={selectStylesOverride}
                      onChange={(e, newVal) => {
                        let idSet = newVal?.map((_) =>
                          typeof _ !== "string" ? _?._id : _
                        );
                        onChange(newVal);
                      }}
                      name={name}
                      label="Select Alerts"
                      errors={errors}
                      options={
                        allAlerts &&
                        allAlerts?.filter((_) =>
                          alertsSelected && alertsSelected?.length > 0
                            ? !alertsSelected
                                ?.map((existing) => existing?._id)
                                ?.includes(_?._id)
                            : allAlerts
                        )
                      }
                      allSelected={
                        alertsSelected &&
                        alertsSelected?.length === allAlerts?.length
                      }
                      handleToggleSelectAll={(e) =>
                        handleToggleSelectAllAlerts(e, onChange)
                      }
                      labelKey="name"
                      disableCloseOnSelect={true}
                      renderTags={() => null}
                      getOptionLabel={(option) => {
                        if (typeof option === "string") {
                          return (
                            alertsSelected &&
                            alertsSelected?.find((_) => _?._id === option)?.name
                          );
                        } else {
                          return option?.name;
                        }
                      }}
                    />
                  );
                }}
              />
            </Grid>
          </Grid>
          <Grid container my={0} spacing={2}>
            {allAlerts &&
              alertsSelected &&
              alertsSelected
                ?.slice(0, showAll ? alertsSelected?.length : 5)
                ?.map((st, i) => {
                  return (
                    <Grid item sm={4} xs={6} key={i}>
                      <Grid
                        container
                        direction={"row"}
                        justifyContent="center"
                        alignItems={"center"}
                        sx={{
                          backgroundColor: themeType.default.themeLightOrange,
                          border: `1px solid ${themeType.default.themeOrange}`,
                          borderRadius: "12px",
                          pl: 1,
                          pr: 1,
                          minHeight: "35px",
                        }}
                      >
                        <Grid item sm={10} xs={8}>
                          <Typography
                            sx={{
                              ...textClasses.normalText,
                              color: themeType.default.themeOrange,
                              textAlign: "start",
                            }}
                          >
                            {st.name && st.name.length > 15
                              ? st.name.substring(0, 15) + "..."
                              : st.name}
                          </Typography>
                        </Grid>
                        {handleRemoveSelection && (
                          <Grid
                            item
                            sm={2}
                            xs={4}
                            display="flex"
                            justifyContent="end"
                          >
                            <IconButton
                              sx={{ alignContent: "right", padding: "5px" }}
                              onClick={() => handleRemoveSelection(st)}
                            >
                              <Cancel
                                sx={{ color: themeType.default.themeOrange }}
                              />
                            </IconButton>
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                  );
                })}
            {alertsSelected && alertsSelected?.length > 5 && (
              <>
                <Grid
                  container
                  item
                  sm={4}
                  xs={6}
                  justifyContent="start"
                  alignItems={"center"}
                >
                  <Typography
                    sx={{
                      ...textClasses.boldText,
                      color: themeType.default.themeOrange,
                      textDecoration: "underline",
                      cursor: "pointer",
                    }}
                    onClick={() => setShowAll(!showAll)}
                  >
                    {showAll ? (
                      <>Collapse</>
                    ) : (
                      <>View All ({alertsSelected?.length})</>
                    )}
                  </Typography>
                </Grid>
              </>
            )}
          </Grid>

          {/* checkboxes section */}
          <Grid
            container
            borderRadius={1}
            border="1px solid #E0E0E0"
            my={2}
            p={2}
            sx={{ width: "100%" }}
            data-testid="checkbox-section"
          >
            {checkboxes?.map((check, i) => {
              return (
                <Grid item md={4} key={i}>
                  <FormControlLabel
                    sx={{
                      color: "rgb(128, 128, 128)",
                      "& .MuiFormControlLabel-label": { fontSize: "13px" },
                    }}
                    label={check?.label}
                    control={
                      <Checkbox
                        sx={{
                          color: "#ff7200",
                          "&.Mui-checked": {
                            color: "#ff7200",
                          },
                        }}
                        checked={check?.visibility}
                        onChange={(e) => {
                          handleCheckboxChange(e, check);
                        }}
                        name={check?.label}
                      />
                    }
                  />
                </Grid>
              );
            })}
          </Grid>

          {/* action buttons */}
          <Grid
            item
            display="flex"
            justifyContent="center"
            gap={2}
            alignItems="center"
            sx={{ width: "100%", paddingTop: "0 !important" }}
          >
            <Button
              variant="outlined"
              sx={{
                ...buttonClasses?.lynkitBlackFill,
                minHeight: "36px",
                minWidth: "150px",
              }}
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Box sx={{ m: 1, position: "relative" }}>
              <Button
                //disabled={true}
                disabled={isSubmitting || !isValid || loading}
                variant="contained"
                sx={{
                  ...buttonClasses?.lynkitOrangeFill,
                  minHeight: "36px",
                  minWidth: "150px",
                }}
                onClick={handleSubmit(
                  selectedModel ? handleUpdateModel : handleCreateModel
                )}
              >
                {loading ? "Loading" : `${selectedModel ? "Update" : "Create"}`}
                {/* {`${selectedModel ? "Update" : "Create"}`} */}
              </Button>
              {loading && (
                <CircularProgress
                  size={24}
                  sx={{
                    color: "#383838",
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    marginTop: "-12px",
                    marginLeft: "-12px",
                  }}
                />
              )}
            </Box>
          </Grid>
        </Grid>
      </DialogContent>
    </CustomModel>
  );
};

export default AddDeviceModel;
