import { Search } from "@mui/icons-material";
import {
  Box,
  Button,
  FormControl,
  Grid,
  OutlinedInput,
  Paper,
  Typography,
} from "@mui/material";
import React from "react";
import searchImg from "../../../../static/images/components/search-img.svg";
import noData from "../../../../static/images/components/no-data-img.svg";
import "./addDeviceComponents.css";
import { showToast } from "../../../../services/functions";
import DevicesTable from "../components/DevicesTable";
import ImgContainer from "../../../Components/Generic/ImgContainer";
import { useDispatch } from "react-redux";
import { flushDeviceDetails } from "../../../../redux/reducers/DevicesReducers";
import CustomSubmitBtn from "../../../../components/FormUI/CustomSubmitBtn";

const SUForm = ({
  searchInput,
  setSearchInput,
  selectStylesOverride,
  deviceData,
  handleFetchDeviceDetails,
  handleAllocateDeviceToUser,
  themeType,
  allocateDeviceLoading,
  searchValue,
  setSearchValue,
}) => {
  const { buttonClasses, inputClasses, textClasses, dividerClass } = themeType;

  const handleGetDetails = (e) => {
    e.preventDefault();
    setSearchValue(searchInput);
    const payload = {
      id: searchInput,
      selection: ["asset_id", "client_id", "deviceType", "deviceModel", "isActive"]
    };
    handleFetchDeviceDetails(payload);
  };

  const tableColumns = [
    {
      header: "Device Id",
      cell: (entry) => {
        return (
          <Typography
            sx={{
              ...textClasses.t13n,
              textAlign: "center",
              textTransform: "capitalize",
              color: themeType?.default?.themeOrange,
              cursor: "pointer",
            }}
          >
            {entry?.client_id ?? "-"}
          </Typography>
        );
      },
    },
    {
      header: "Device Type",
      cell: (entry) => {
        return (
          <Typography
            sx={{
              ...textClasses.t13n,
              textAlign: "center",
              textTransform: "capitalize",
              cursor: "pointer",
            }}
          >
            {entry?.deviceType ?? "-"}
          </Typography>
        );
      },
    },
    {
      header: "Device Model",
      cell: (entry) => {
        return (
          <Typography
            sx={{
              ...textClasses.t13n,
              textAlign: "center",
              textTransform: "capitalize",
              color: themeType?.default?.themeOrange,
              cursor: "pointer",
            }}
          >
            {entry?.deviceModel ?? "-"}
          </Typography>
        );
      },
    },
    {
      header: "Sim",
      cell: (entry) => {
        return (
          <Typography
            sx={{
              ...textClasses.t13n,
              textAlign: "center",
              textTransform: "capitalize",
              color:
                entry?.isActive == true
                  ? themeType?.default.themeGreen
                  : themeType?.default.themeRed,
            }}
          >
            {entry?.isActive == true ? "Active" : "Disabled"}
          </Typography>
        );
      },
    },
    {
      header: "Status",
      cell: (entry) => {
        return (
          <Typography
            sx={{
              ...textClasses.t13n,
              textAlign: "center",
              textTransform: "capitalize",
              color:
                entry?.isActive == true
                  ? themeType?.default.themeGreen
                  : themeType?.default.themeRed,
            }}
          >
            {entry?.isActive == true ? "Active" : "In-active"}
          </Typography>
        );
      },
    },
  ];

  return (
    <Grid container data-testid="user-add-form">
      <Grid
        xs={12}
        item
        container
        display="flex"
        justifyContent="center"
        alignItems="center"
        gap="15px"
      >
        <Grid
          xs={5}
          item
          display="flex"
          justifyContent="center"
          alignItems="center"
          gap="15px"
        >
          <FormControl
            variant="outlined"
            size="small"
            fullWidth
            sx={selectStylesOverride}
          >
            <OutlinedInput
              sx={{ ...inputClasses.filterField, height: "36px" }}
              placeholder="Search by device"
              value={searchInput}
              onChange={(e) => {
                console.log("e.target.value: ", e.target.value);
                setSearchInput(e.target.value);
              }}
            />
          </FormControl>
          <Button
            variant="outlined"
            spacing={0}
            data-testid="search-device-btn"
            className="RF_outline-btn"
            sx={{
              marginRight: "5px",
              borderRadius: "8px",
              borderColor: "#D9D9D9",
            }}
            onClick={handleGetDetails}
          >
            <Search fontSize="small" />
          </Button>
        </Grid>
      </Grid>

      {/* image and table section ---- */}
      <Grid xs={12}>
        {deviceData === null && searchValue?.length < 3 && (
          <ImgContainer
            src={searchImg}
            title="Search the device id first..."
            body="Hey, please search the device ID/ IMEI number engraved on the device, to add in your account."
          />
        )}
        {deviceData === null && searchValue?.length > 3 && (
          <ImgContainer
            src={noData}
            title="Sorry ! No Data found.."
            body={
              "Hey, please re-check the device id and try again. \nContact to support team at 011-4117 9940 or support@lynktrac.io"
            }
          />
        )}
        {deviceData?.length > 0 && searchValue?.length > 3 && (
          <Grid mt="20px" container spacing={2}>
            <Grid xs={12} item>
              <DevicesTable
                themeType={themeType}
                showAllColumns={true}
                tableColumns={tableColumns}
                data={deviceData ?? []}
              />
            </Grid>

            <Grid
              item
              xs={12}
              display="flex"
              justifyContent="center"
              alignItems="center"
              gap="7px"
              mt={2}
            >
              <Button
                variant="outlined"
                sx={{
                  ...buttonClasses?.lynkitBlackFill,
                  minHeight: "36px",
                  minWidth: "140px",
                }}
              >
                Cancel
              </Button>
              <CustomSubmitBtn
                variant="contained"
                sx={{
                  ...buttonClasses?.lynkitOrangeFill,
                  minHeight: "36px",
                  minWidth: "140px",
                }}
                onClick={handleAllocateDeviceToUser}
                label="Add Device"
                disabled={allocateDeviceLoading}
                loading={allocateDeviceLoading}
              />
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default SUForm;
