import {
  GET_BOARD,
  EMPTY_LISTS,
  MODULE_LISTING,
  MODULE_NAMES,
  SHOW_MESSAGE,
  FETCH_START,
  FETCH_SUCCESS,
  BOARD_STATUS,
  FETCH_ERROR,
  SAVE_BOARD,
  FLUSH_BOARD,
  EDIT_BOARD,
  DELETE_BOARD,
} from "../types";
import axios, { templateAxios } from "../../services/Api";
import { showToast } from "../../services/functions";

export const getBoards = (payload, cb) => {
  return async (dispatch) => {
    dispatch({ type: FETCH_START });
    dispatch({ type: FLUSH_BOARD });
    await templateAxios
      .post("/board/getBoard", { ...payload })
      .then(({ data }) => {
        if (data.success) {
          callback();
        } else {
          dispatch({ type: FETCH_ERROR });
          dispatch({ type: GET_BOARD, payload: { rows: [], count: 0 } });
          if (cb) {
            cb();
          }
        }
        function callback() {
          dispatch({ type: FETCH_SUCCESS });
          dispatch({
            type: GET_BOARD,
            payload: { rows: data.data, count: data.count },
          });
          if (cb) {
            if (data?.data && data?.data?.length > 0) {
              let temp = [];
              data?.data[0]?.boardData.forEach((data) => {
                data.group.forEach((item) => {
                  if (item.key.includes(".")) {
                    const splitKeys = item.key.split(".");
                    const firstPart = splitKeys[0];
                    if (!temp.includes(firstPart)) {
                      temp.push(firstPart);
                    }
                  } else if (!temp.includes(item.key)) {
                    temp.push(item.key);
                  }
                });
              });
              cb(temp);
            } else {
              cb(null);
            }
          }
        }
      })
      .catch(function (error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
        dispatch({ type: GET_BOARD, payload: { rows: [], count: 0 } });
        console.log("Error****1111111111111:", error.message);
        if (cb) {
          cb(null);
        }
      });
  };
};
export const getModuleNames = (payload, cb) => {
  return async (dispatch) => {
    dispatch({ type: FETCH_START });
    await templateAxios
      .post("/board/listModule", { ...payload })
      .then(({ data }) => {
        if (data.success) {
          callback();
        } else {
          dispatch({ type: FETCH_ERROR, payload: data.message });
          dispatch({ type: MODULE_NAMES, payload: { rows: [], count: 0 } });
          if (cb) {
            cb();
          }
        }
        function callback() {
          dispatch({ type: FETCH_SUCCESS });
          dispatch({
            type: MODULE_NAMES,
            payload: { rows: data.data, count: data.count },
          });
          if (cb) {
            cb();
          }
        }
      })
      .catch(function (error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
        dispatch({ type: MODULE_NAMES, payload: { rows: [], count: 0 } });
        console.log("Error****:", error.message);
        if (cb) {
          cb();
        }
      });
  };
};
export const getModuleListing = (payload, cb) => {
  return async (dispatch) => {
    dispatch({ type: EMPTY_LISTS });
    dispatch({ type: FETCH_START });
    await templateAxios
      .post("/board/getKeysModuleWise", { ...payload })
      .then(({ data }) => {
        if (data.success) {
          callback();
        } else {
          dispatch({ type: FETCH_ERROR, payload: data.message });
          dispatch({ type: MODULE_LISTING, payload: { rows: [], count: 0 } });
          if (cb) {
            cb();
          }
        }
        function callback() {
          dispatch({ type: FETCH_SUCCESS });
          dispatch({
            type: MODULE_LISTING,
            payload: { rows: data.data, count: data.count },
          });
          if (cb) {
            cb();
          }
        }
      })
      .catch(function (error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
        dispatch({ type: MODULE_LISTING, payload: { rows: [], count: 0 } });
        console.log("Error****:", error.message);
        if (cb) {
          cb();
        }
      });
  };
};
export const saveBoard = (bodydata, callBackFunction) => {
  return async (dispatch) => {
    dispatch({ type: FETCH_START });
    await templateAxios
      .post("/board/createBoard", bodydata)
      .then(({ data }) => {
        if (data.success) {
          dispatch({ type: FETCH_SUCCESS, payload: data.success });
          dispatch({ type: SHOW_MESSAGE, payload: data.message });
          // console.log('manageteam0', data)
        } else {
          dispatch({ type: FETCH_ERROR, payload: data.message });
          dispatch({ type: SAVE_BOARD, payload: {} });
        }
        if (callBackFunction) {
          callBackFunction({ success: data.success, message: data.message });
        }
      })
      .catch(function (error) {
        // dispatch({type: FETCH_ERROR, payload: error.message});
        // console.log("Error****:", error.response.data.message);
        showToast(error?.response?.data?.message, true);
      });
  };
};
export const editBoard = (bodydata, callBackFunction) => {
  return async (dispatch) => {
    dispatch({ type: FETCH_START });
    await templateAxios
      .post("/board/editBoard", bodydata)
      .then(({ data }) => {
        if (data.success) {
          dispatch({ type: FETCH_SUCCESS, payload: data.success });
          dispatch({ type: SHOW_MESSAGE, payload: data.message });
          // console.log('manageteam0', data)
        } else {
          dispatch({ type: FETCH_ERROR, payload: data.message });
          dispatch({ type: EDIT_BOARD, payload: {} });
        }
        if (callBackFunction) {
          callBackFunction({ success: data.success, message: data.message });
        }
      })
      .catch(function (error) {
        // dispatch({type: FETCH_ERROR, payload: error.message});
        // console.log("Error****:", error.response.data.message);
        showToast(error?.response?.data?.message, true);
      });
  };
};

export const deleteBoard = (bodydata, callBackFunction) => {
  return async (dispatch) => {
    dispatch({ type: FETCH_START });
    await templateAxios
      .post("/board/deleteBoard", bodydata)
      .then(({ data }) => {
        if (data.success) {
          dispatch({ type: FETCH_SUCCESS, payload: data.success });
          dispatch({ type: SHOW_MESSAGE, payload: data.message });
          // console.log('manageteam0', data)
        } else {
          dispatch({ type: FETCH_ERROR, payload: data.message });
          dispatch({ type: DELETE_BOARD, payload: {} });
        }
        if (callBackFunction) {
          callBackFunction({ success: data.success, message: data.message });
        }
      })
      .catch(function (error) {
        // dispatch({type: FETCH_ERROR, payload: error.message});
        // console.log("Error****:", error.response.data.message);
        showToast(error?.response?.data?.message, true);
      });
  };
};

export const boardStatus = (bodydata, callBackFunction) => {
  return async (dispatch) => {
    dispatch({ type: FETCH_START });
    await templateAxios
      .post("/board/disableBoard", bodydata)
      .then(({ data }) => {
        if (data.success) {
          dispatch({ type: FETCH_SUCCESS, payload: data.success });
          dispatch({ type: SHOW_MESSAGE, payload: data.message });
          // console.log('manageteam0', data)
        } else {
          dispatch({ type: FETCH_ERROR, payload: data.message });
          dispatch({ type: BOARD_STATUS, payload: {} });
        }
        if (callBackFunction) {
          callBackFunction({ success: data.success, message: data.message });
        }
      })
      .catch(function (error) {
        // dispatch({type: FETCH_ERROR, payload: error.message});
        // console.log("Error****:", error.response.data.message);
        showToast(error?.response?.data?.message, true);
      });
  };
};
