import React from "react";
import CustomModel from "../../../Components/CustomModels/CustomModel1";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  MenuItem,
  Typography,
  capitalize,
} from "@mui/material";
import { Controller, useForm, useWatch } from "react-hook-form";
import CustomSelect from "../../../../components/FormUI/CustomSelect";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllDeviceCategoryNames,
  getUserCommands,
  sendCommands,
  sendOTPForSmsCommand,
} from "../../../../redux/actions";
import { CustomInput } from "../../../../components/FormUI";
import { flushUserCommands } from "../../../../redux/reducers/DevicesReducers";

const operatorOptions = [
  { label: "Vodafone/Idea", value: "Vodafone/Idea" },
  { label: "BSNL", value: "BSNL" },
  { label: "Sensorise", value: "Sensorise" },
  { label: "Jio", value: "Jio" },
  { label: "Airtel", value: "Airtel" },
];

const DeviceCommandModal = ({
  open,
  setOpen,
  deviceDetails,
  themeType,
  selectStylesOverride,
}) => {
  const dispatch = useDispatch();
  let simOptions = [];

  const [loading, setLoading] = React.useState(false);
  const [showOtpField, setShowOtpField] = React.useState(false);

  const { allUserCommands } = useSelector((state) => state?.deviceslist);

  const { buttonClasses, inputClasses } = themeType;

  if (deviceDetails) {
    const formData =
      deviceDetails?.formData && JSON.parse(deviceDetails?.formData);
    let temp = [];
    temp.push({
      label: `${deviceDetails?.sim1type} - ${deviceDetails?.sim1no}`,
      value: {
        simType: deviceDetails?.sim1type,
        simNo: deviceDetails?.sim1no,
      },
    });
    if (formData && formData?.sim2type && formData?.sim2no) {
      temp.push({
        label: `${capitalize(formData?.sim2type)} - ${formData?.sim2no}`,
        value: {
          simType: formData?.sim2type,
          simNo: formData?.sim2no,
        },
      });
    }
    simOptions = [...temp];
  }

  const {
    control,
    reset,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const cmdTypeWatch = useWatch({
    control: control,
    name: "cmdType",
  });

  const hide = () => {
    setOpen(false);
  };

  const handleSendBtn = (data, fromOtp) => {
    if (data) {
      setLoading(true);
      let payload = {
        commandId: cmdTypeWatch?.commandId,
        deviceId: deviceDetails?.client_id,
        attributes: {},
        message: cmdTypeWatch?.command?.message || "",
        client_id: deviceDetails?.client_id,
        page: "device",
        source: "UI",
      };

      if (data?.smsType) {
        payload.simType = data?.smsType?.value?.simType;
        payload.simNo = data?.smsType?.value?.simNo;
      }

      if (fromOtp === true) {
        payload.otp = data?.otp;
      }

      if (cmdTypeWatch?.command?.attributes?.length > 0) {
        const { cmdType, otp, ...rest } = data;
        payload.attributes = {
          ...rest,
        };
      }

      dispatch(
        sendCommands(payload, (res) => {
          if (res?.success) {
            reset();
            setOpen(false);
          }
          setLoading(false);
        })
      );
    }
  };

  const handleOtpBtn = () => {
    setLoading(true);
    dispatch(
      sendOTPForSmsCommand({ deviceId: deviceDetails?.client_id }, (res) => {
        if (res && res?.success) {
          setShowOtpField(true);
        }
        setLoading(false);
      })
    );
  };

  React.useEffect(() => {
    if (open) {
      setLoading(true);
      setShowOtpField(false);
      reset();
      dispatch(
        getUserCommands(
          {
            deviceType: deviceDetails?.deviceType,
            deviceModel: deviceDetails?.deviceModel,
          },
          (res) => {
            setLoading(false);
          }
        )
      );

      return () => dispatch(flushUserCommands());
    }
  }, [open]);

  return (
    <CustomModel
      autoClose={false}
      show={open || false}
      onClose={() => hide()}
      minWidth={450}
      maxWidth={450}
      pb={3}
      pl={3}
      pr={3}
      maxHeight={"81vh"}
      childrenMaxHeight={"81vh"}
      Title="Send Command"
      viewCloseIcon={open}
    >
      <Grid container my={2} rowGap={2}>
        <Grid item xs={12}>
          <Controller
            name="cmdType"
            control={control}
            rules={{
              required: {
                value: true,
                message: "Command Type is required",
              },
            }}
            render={({ field: { onChange, value, name } }) => {
              return (
                <CustomSelect
                  selectStylesOverride={selectStylesOverride}
                  value={value || null}
                  onChange={(e, newVal) => {
                    onChange(newVal);
                    setShowOtpField(false);
                    setValue("otp", undefined);
                  }}
                  name={name}
                  required
                  label="Select Command Type"
                  errors={errors}
                  renderOptions={(props, option, { selected }) => {
                    return (
                      <MenuItem
                        value={value || ""}
                        selected={selected}
                        {...props}
                      >
                        <Typography
                          sx={{
                            fontSize: ".8rem",
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {option?.command?.commandName || ""}
                        </Typography>
                      </MenuItem>
                    );
                  }}
                  getOptionLabel={(option) => {
                    return option?.command?.commandName || "";
                  }}
                  options={allUserCommands ? allUserCommands : []}
                />
              );
            }}
          />
        </Grid>
        {cmdTypeWatch &&
          cmdTypeWatch?.command &&
          cmdTypeWatch?.command?.commandType === "sms" && (
            <Grid item xs={12}>
              <Controller
                name="smsType"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: "Sim Operator is required",
                  },
                }}
                render={({ field: { onChange, value, name } }) => {
                  return (
                    <CustomSelect
                      selectStylesOverride={selectStylesOverride}
                      value={value || null}
                      onChange={(e, newVal) => {
                        console.log("sim onchange: ", newVal);
                        onChange(newVal);
                      }}
                      name={name}
                      label="Select Sim Operator"
                      required
                      errors={errors}
                      getOptionLabel={(option) => {
                        if (value) {
                          if (typeof option === "string") {
                            return (
                              simOptions?.find((_) => _ == value)?.label || ""
                            );
                          } else {
                            return option?.label || "";
                          }
                        } else {
                          return "";
                        }
                      }}
                      options={simOptions}
                    />
                  );
                }}
              />
            </Grid>
          )}

        {cmdTypeWatch &&
          cmdTypeWatch?.command &&
          cmdTypeWatch?.command?.attributes &&
          cmdTypeWatch?.command?.attributes?.length > 0 &&
          cmdTypeWatch?.command?.attributes?.map((att, i) => {
            let rules = {};
            if (att?.dataType === "string" || att?.dataType === "number") {
              rules.pattern = {
                value: att?.dataType === "string" ? /^[A-Za-z]+$/ : /^[0-9]+(\.[0-9]+)*$/,
                message:
                  att?.dataType === "string"
                    ? "Enter only alphabets"
                    : "Enter only numeric value",
              };
            }
            if (att?.minChar !== "") {
              rules.minLength = {
                value: Number(att?.minChar),
                message: `Value must have atleast ${att?.minChar} characters.`,
              };
            }
            if (att?.maxChar !== "") {
              rules.maxLength = {
                value: Number(att?.maxChar),
                message: `Value must have max ${att?.maxChar} characters.`,
              };
            }
            rules.required = {
              value: true,
              message: `${att?.text} is required.`
            }
            return (
              <Grid item xs={12} key={i}>
                <Controller
                  name={att?.keyName}
                  control={control}
                  rules={rules}
                  render={({ field: { onChange, value, name } }) => {
                    return (
                      <CustomInput
                        selectStylesOverride={selectStylesOverride}
                        inputClasses={inputClasses}
                        value={value || null}
                        onChange={onChange}
                        name={name}
                        label={att?.text}
                        required
                        errors={errors}
                      />
                    );
                  }}
                />
              </Grid>
            );
          })}
        <Grid
          item
          xs={12}
          // mt={1}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          {cmdTypeWatch && cmdTypeWatch?.otpNeeded === "yes" ? (
            <>
              {showOtpField ? (
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  width="100%"
                  gap={1}
                  mt={1}
                >
                  <Controller
                    name="otp"
                    control={control}
                    rules={{
                      required: {
                        value: true,
                        message: "OTP is required",
                      },
                      pattern: {
                        value: /^\d+$/,
                        message: "Enter a valid number",
                      },
                      maxLength: {
                        value: 6,
                        message: "OTP should be 6 digits long",
                      },
                      minLength: {
                        value: 6,
                        message: "OTP should be 6 digits long",
                      },
                    }}
                    render={({ field: { onChange, value, name } }) => {
                      return (
                        <CustomInput
                          label="OTP"
                          name={name}
                          errors={errors}
                          placeholder="Enter OTP"
                          selectStylesOverride={selectStylesOverride}
                          inputClasses={inputClasses}
                          value={value}
                          onChange={(e) => {
                            onChange(e);
                          }}
                        />
                      );
                    }}
                  />
                  <Button
                    onClick={handleSubmit((data) => {
                      handleSendBtn(data, true);
                    })}
                    disabled={loading}
                    variant="contained"
                    sx={{ ...buttonClasses?.lynkitOrangeFill, mt: 1 }}
                  >
                    {loading && (
                      <CircularProgress
                        size={20}
                        sx={{
                          color: "#383838",
                        }}
                      />
                    )}
                    {loading ? "Loading..." : "Send"}
                  </Button>
                </Box>
              ) : (
                <Button
                  onClick={(e) => {
                    e?.preventDefault();
                    handleOtpBtn();
                  }}
                  disabled={loading}
                  variant="contained"
                  sx={{ ...buttonClasses?.lynkitBlackFill, height: "33px" }}
                >
                  {loading && (
                    <CircularProgress
                      size={20}
                      sx={{
                        color: "#383838",
                      }}
                    />
                  )}
                  {loading ? "Loading..." : "Get OTP"}
                </Button>
              )}
            </>
          ) : (
            <Button
              onClick={handleSubmit(handleSendBtn)}
              disabled={loading}
              variant="contained"
              sx={{
                ...buttonClasses?.lynkitOrangeFill,
                display: "flex",
                alignItems: "center",
                gap: 1,
              }}
            >
              {loading && (
                <CircularProgress
                  size={20}
                  sx={{
                    color: "#383838",
                  }}
                />
              )}
              {loading ? "Loading..." : "Send"}
            </Button>
          )}
        </Grid>
      </Grid>
    </CustomModel>
  );
};

export default DeviceCommandModal;
