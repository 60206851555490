import { useState, useEffect } from "react";

export const useFilterPanel = (date = new Date()) => {
  const [fromDate, setFromDate] = useState(date);
  const [isFilterPanelOpen, setIsFilterPanelOpen] = useState(false);
  const [filterOpen, setFilterOpen] = useState(false);

  const [filterPanelData, setFilterPanelData] = useState([]);

  const handleFilterPanelClose = () => {
    setIsFilterPanelOpen(false);
  };

  const setFilterData = (newData) => {
    setFilterPanelData(newData);
  };

  const handleFilter = (data) => {
    setFilterOpen(data);
  };

  const setFilterDate = (newData) => {
    setFromDate(newData);
  };

  const handleFilterPanelOpen = () => {
    setFromDate(new Date());
    setIsFilterPanelOpen(true);
  };

  // useEffect(() => {
  // 	setFromDate(date);
  // }, [date]);

  return {
    isFilterPanelOpen,
    filterPanelData,
    handleFilterPanelClose,
    handleFilterPanelOpen,
    setFilterData,
    setFilterDate,
    fromDate,
    handleFilter,
    filterOpen,
  };
};
