import {
  Button,
  Autocomplete,
  TextField,
  Grid,
  MenuItem,
  Paper,
  Menu,
  Checkbox,
  Tab,
  Tabs,
  Typography,
  Switch,
  Box,
  FormControl
} from "@mui/material";
import { havePermission } from "../../../../utils/permissionCheck";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import loaderGif from "../../../../static/images/drawer/loader.gif";
import Table from "../components/Table";
import { useEffect } from "react";
import { useCallback } from "react";
import { selectStylesOverride } from "../../../../utils/util";
import {
  getAllSettings,
  updateSettings,
  clearSettings
} from "../../../../redux/actions/Settings";
import { useMemo } from "react";
import { Controller, useForm } from "react-hook-form";
import { makeStyles } from "@mui/styles";
import EmptyPage from "../../EmptyPage";
import { getAllOrganisations } from "../../../../redux/actions";
import CustomSelect from "../../../../components/FormUI/CustomSelect";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles({
  root: {
    width: "50px",
    height: "24px",
    padding: "0px",
  },
  switchBase: {
    padding: "2px",
    color: "red",
    // "&.Mui-disabled": {
    //   color: "green",
    // },
    "&.Mui-checked": {
      color: "green",
    },
    "&.Mui-checked + .MuiSwitch-track": {
      backgroundColor: "transparent",
    },
  },
  track: {
    border: "0.5px solid #D9D9D9",
    borderRadius: "20px",
    backgroundColor: "transparent",
    opacity: "1 !important",
    "&:after, &:before": {
      fontSize: "11px",
      position: "absolute",
      top: "6px",
      content: "''", // Add an empty content property by default
    },
    "&:after": {
      color: "green",
      content: "'Yes'",
      left: "4px",
    },
    "&:before": {
      color: "red",
      content: "'No'",
      right: "7px",
    },
  },
  checked: {
    transform: "translateX(26px) !important",
  },
});

const TripSettings = () => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const {
    register,
    control,
    getValues,
    setValue,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  //selectors
  const userDetail = useSelector((state) => state.auth.currentUserDetail);
  const tripSettingsData = useSelector((state) => state.settingsReducer.settingsData);
  const tripSettings = tripSettingsData?.settings;



  const isDarkThemeEnabledSelector = useSelector((state) => state.theme.themeDark);

  //states
  const [tabIndex, setTabIndex] = useState(0);
  const [isEditMode, setEditMode] = useState(false);
  const [dataToSend, setDataToSend] = useState();
  const [loader, setLoader] = useState(false);
  const [searchFields, setSearchFields] = useState({
    org: null,
  });

  const [viewPermission, setViewPermission] = useState({
    check: 'viewChkAvlPermission',
    trip: 'viewTripCreatePermission',
    geofence: 'viewGeofencePermission',
    closure: 'viewTripClosurePermission',
    eta: 'viewEtaPermission'
  })
  const [editPermission, setEditPermission] = useState('editChkAvlPermission')

  const [selectedTabName, setSelectedTabName] = useState();
  const userPermissions = useSelector((state) => state.auth?.currentUserDetail) || {};
  const orgListing = useSelector((state) => state.Organisationlisting.OrganisationListing);

  useEffect(() => {
    if (userDetail && Object.keys(userDetail).length) {
      if (havePermission(userPermissions, "tripSettings", 'viewChkAvlPermission')) {
        setSelectedTabName('checkAvailability');
      }
      else if (havePermission(userPermissions, "tripSettings", 'viewGeofencePermission')) {
        setSelectedTabName('geofenceInOut');
      }
      else if (havePermission(userPermissions, "tripSettings", 'viewTripClosurePermission')) {
        setSelectedTabName('tripClosure');
      }
      else if (havePermission(userPermissions, "tripSettings", 'viewTripCreatePermission')) {
        setSelectedTabName('tripCreation');
      }

      else if (havePermission(userPermissions, "tripSettings", 'viewEtaPermission')) {
        setSelectedTabName('etaPermission');
      }


      else {
      }
    }
  }, [userDetail]);

  const themeType = isDarkThemeEnabledSelector
    ? require("../../../../static/styles/darktheme")
    : require("../../../../static/styles/theme");
  const { buttonClasses, inputClasses, textClasses } = themeType;

  useEffect(() => {
    if (tripSettings) {
      setDataToSend(tripSettings);
    }
  }, [tripSettings]);

  const updateValue = useCallback(
    (value, key) => {
      let temp = dataToSend;
      const keyToChange = key;
      temp = {
        ...temp,
        [selectedTabName]: {
          ...temp[selectedTabName],
          [keyToChange]: {
            ...temp[selectedTabName][keyToChange],
            value: Number(value),
          },
        },
      };
      setDataToSend(temp);
    },
    [dataToSend, selectedTabName]
  );

  const updateStatus = (newValue, key) => {
    let temp = dataToSend;
    const keyToChange = key;
    temp[selectedTabName][keyToChange].status = newValue;
    setDataToSend(temp);
  };

  const ConditionLabel = (entry) => {
    var stringSplitted = entry?.label?.split("${value}");
    const contentArray = stringSplitted?.map((part, index) => {
      if (index < stringSplitted.length - 1) {
        return (
          <React.Fragment key={index}>
            <div style={{ alignSelf: "center" }}>{part}</div>
            <div>&nbsp;</div>
            <Controller
              name={entry?.key}
              control={control}
              required
              rules={{
                required: {
                  value: true,
                  message: "Field value is required",
                },
                validate: (value) => {
                  return value > 0 || "Value must be greater than 0";
                },
              }}
              errors={errors}
              render={({ field: { onChange, value, name } }) => {
                return (
                  <div style={{ lineHeight: 0 }}>
                    <TextField
                      data-testid="textField"
                      sx={{
                        ...selectStylesOverride(isDarkThemeEnabledSelector),
                      }}
                      id={`outlined-basic-${index}`}
                      variant="outlined"
                      name={name}
                      type="number"
                      defaultValue={value}
                      value={value}
                      InputProps={{ style: { width: "50px", height: "30px" } }}
                      onChange={(e) => {
                        if (e.target.value) {
                          updateValue(e.target.value, entry?.key);
                        }
                        onChange(e.target.value);
                      }}
                    />
                    {errors[name] && (
                      <span
                        role="alert"
                        style={{ color: "#FF0101", fontSize: 11 }}
                      >
                        {errors[name]?.message}
                      </span>
                    )}
                  </div>
                );
              }}
            />
            <div>&nbsp;</div>
          </React.Fragment>
        );
      } else {
        return <div style={{ alignSelf: "center" }}>{part}</div>;
      }
    });

    const updatedLabel = isEditMode
      ? contentArray
      : entry?.label?.replace("${value}", entry?.value);

    return (
      <Typography
        sx={{
          ...textClasses.normalText,
          textAlign: "start",
          overflow: "hidden",
          textOverflow: "ellipsis",
          // fontWeight: 600,
          // fontSize: "16px",
          display: "flex",
          flexDirection: "row",
          // lineHeight: 0,
        }}
      >
        {updatedLabel}
      </Typography>
    );
  };

  let tableColumns = [
    {
      key: "Sno",
      header: "#",
      cell: (key) => {
        return (
          <Typography
            sx={{
              ...textClasses.t13n,
              textAlign: "start",
            }}
          >
            {key + 1}
          </Typography>
        );
      },
    },
    {
      key: "conditions",
      header: "Conditions Check",
      cell: (entry) => {
        return (
          <Typography
            sx={{
              // ...textClasses.t13n,
              textAlign: "start",
            }}
          >
            {ConditionLabel(entry)}
          </Typography>
        );
      },
    },
    {
      key: "status",
      header: "Status",
      cell: (entry) => {
        return (
          <Typography
            sx={{
              ...textClasses.t13n,
              textAlign: "start",
              fontSize: "16px",
              fontWeight: 600,
              color:
                entry?.status == true
                  ? themeType?.default.themeGreen
                  : themeType?.default.themeRed,
            }}
          >
            {entry?.isShow &&
              (isEditMode ? (
                <Controller
                  name={`switch_${entry?.key}`}
                  control={control}
                  render={({ field: { onChange, value, name } }) => {
                    return (
                      <div>
                        <Switch
                          classes={{
                            root: classes.root,
                            switchBase: classes.switchBase,
                            thumb: classes.thumb,
                            track: classes.track,
                            checked: classes.checked,
                          }}
                          data-testid="checkbox"
                          disabled={!entry?.isUpdateStatus}
                          size="medium"
                          name={name}
                          defaultChecked={entry?.status}
                          value={value}
                          onChange={(e) => {
                            updateStatus(e.target.checked, entry?.key);
                            onChange(e.target.checked);
                          }}
                        />
                        {errors[name] && (
                          <span
                            role="alert"
                            style={{ color: "#FF0101", fontSize: 11 }}
                          >
                            {errors[name]?.message}
                          </span>
                        )}
                      </div>
                    );
                  }}
                />
              ) : entry?.status == true ? (
                "Yes"
              ) : (
                "No"
              ))}
          </Typography>
        );
      },
    },
  ];
  const tableData = useMemo(() => {
    if (selectedTabName) {
      const data = tripSettings && tripSettings[selectedTabName] && Object.keys(tripSettings[selectedTabName])?.filter((condition) => condition !== "label")
        ?.map((condition, index) => {
          let conditionData = tripSettings && tripSettings[selectedTabName] && tripSettings[selectedTabName][condition];
          if (conditionData) {
            conditionData = { ...conditionData, key: condition };
          }
          return conditionData;
        });
      return data;
    }
  }, [tripSettings, selectedTabName, userPermissions]);


  useEffect(() => {
    if (tableData) {
      tableData.forEach((data) => {
        setValue(`switch_${data?.key}`, data?.status);
        if (data?.value) {
          setValue(data?.key, data?.value);
        }
      });
    }
  }, [tableData]);

  const handleTabChange = (event, value) => {
    setTabIndex(value);
  };

  const handleUpdate = useCallback(
    (data) => {
      const payload = {
        type: "trip",
        orgId: searchFields.org,
        settings: dataToSend,
        label: tripSettingsData?.label,
      };
      dispatch(
        updateSettings(payload, () => {
          dispatch(getAllSettings({ type: "trip", orgId: searchFields?.org }));
          setEditMode(false);
        })
      );
    },
    [
      dispatch,
      userDetail,
      dataToSend,
      tripSettingsData,
      setEditMode,
      getAllSettings,
      updateSettings,
    ]
  );
  const _handleChange = (e, newVal, item) => {
    let temp = { ...searchFields }
    if (item === 'org') {
      if (newVal) {
        temp.org = newVal?._id
        setSearchFields(temp)
        setLoader(true)
        dispatch(getAllSettings({ type: "trip", orgId: newVal?._id }, (res, error) => {
          setLoader(false);
        }));
      }
      else {
        temp.org = ""
        setSearchFields(temp)
      }
    }

  }

  const handleSearchOrg = (empty) => {
    if (empty === true) {
      dispatch(getAllOrganisations({ selection: ["name"] }));
    }
    else {
      if (searchFields?.org || searchFields?.org == "")
        dispatch(
          getAllOrganisations({
            selection: ["name"],
            searchValue: searchFields.org,
          })
        );
    }
  };
  console.log('userPermissions', userPermissions)
  useEffect(() => {
    dispatch(clearSettings())
    if (userPermissions) {
      if (userPermissions?.adminSpecificPermissions) {
        dispatch(getAllOrganisations({ selection: ["name"] }));
      }
      else {
        let temp = { ...searchFields }
        setLoader(true)
        dispatch(getAllSettings({ type: "trip", orgId: userDetail?.orgId }, (res, error) => {
          setLoader(false);
        }));
        temp.org = userDetail?.orgId
        setSearchFields(temp)
      }
    }
  }, [userPermissions]);

  return (
    <>
      <Grid item sm={12} xs={12}>
        <Typography role="span" sx={{ ...textClasses.cardTitle }}>
          Trip Settings
        </Typography>
      </Grid>
      <Box
        sx={{
          borderBottom: 1,
          borderColor: "divider",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Tabs
          value={tabIndex}
          aria-label="disabled tabs example"
          onChange={handleTabChange}
          style={{ height: "20px" }}
          sx={{
            "& .MuiTabs-indicator": {
              backgroundColor: themeType.default.themeOrange,
            },
            "& .MuiTab-root.Mui-selected": {
              color: themeType.default.themeOrange,
            },
          }}
        >
          {userPermissions && havePermission(userPermissions, "tripSettings", viewPermission.check) ?
            <Tab
              data-testid="allMember"
              label="Check Availability"
              {...a11yProps(0)}
              sx={{ fontSize: "14px", textTransform: "none" }}
              onClick={() => {
                setSelectedTabName('checkAvailability');
                setEditPermission("editChkAvlPermission")
              }}
            />
            : null}

          {userPermissions && havePermission(userPermissions, "tripSettings", viewPermission.trip) ?
            <Tab
              data-testid="tripCreation"
              label="Trip Creation "
              {...a11yProps(0)}
              sx={{ fontSize: "14px", textTransform: "none" }}
              onClick={() => {
                setSelectedTabName('tripCreation');
                setEditPermission("editTripCreatePermission")
              }}
            />
            : null}
          {userPermissions && havePermission(userPermissions, "tripSettings", viewPermission.geofence) ?
            <Tab
              data-testid="geofence"
              label="Geofence IN/OUT"
              {...a11yProps(1)}
              sx={{ fontSize: "14px", textTransform: "none" }}
              onClick={() => {
                setSelectedTabName("geofenceInOut");
                setEditPermission("editGeofencePermission")
              }}
            />
            : null}
          {userPermissions && havePermission(userPermissions, "tripSettings", viewPermission.closure) ?
            <Tab
              data-testid="closure"
              label="Trip Closure"
              {...a11yProps(2)}
              sx={{ fontSize: "14px", textTransform: "none" }}
              onClick={() => {
                setSelectedTabName("tripClosure");
                setEditPermission("editTripClosurePermission")
              }}
            />
            : null}
          <Tab
            data-testid="closure"
            label="Trip Visibility"
            {...a11yProps(2)}
            sx={{ fontSize: "14px", textTransform: "none" }}
            onClick={() => {
              setSelectedTabName("tripVisibility");
            }}
          />

          {userPermissions && havePermission(userPermissions, "tripSettings", viewPermission.eta) ?
            <Tab
              data-testid="closure"
              label="Trip ETA"
              {...a11yProps(2)}
              sx={{ fontSize: "14px", textTransform: "none" }}
              onClick={() => {
                setSelectedTabName("etaPermission");
                setEditPermission("editEtaPermission")
              }}
            />
            : null}


        </Tabs>
      </Box>

      {userPermissions && userPermissions?.adminSpecificPermissions ?
        <Grid
          item
          display="flex"
          gap={2}
          alignItems="center"
          sx={{ width: "100%", paddingTop: "0 !important", marginTop: '14px' }}
        >

          <FormControl
            variant="outlined"
            size="small"
            fullWidth
            sx={{ ...selectStylesOverride(isDarkThemeEnabledSelector) }}
          >

            <CustomSelect
              selectStylesOverride={{ ...selectStylesOverride, width: '23%' }}
              key={`organisation`}
              onChange={(e, newVal, reason) => {
                if (reason === "clear") {
                  setSearchFields({
                    org: null
                  })
                  dispatch(clearSettings())
                } else {
                  _handleChange(e, newVal, 'org')
                }
              }}
              onSearch={handleSearchOrg}
              onInputChange={(e) => {
                if (e?.type === "change") {
                  if (e?.target?.value === "") handleSearchOrg(true);
                  setSearchFields((prev) => ({
                    ...prev,
                    org: e?.target?.value?.trim(),
                  }));
                }
              }}
              searchValue={searchFields?.org && searchFields?.org !== ""
                ? searchFields?.org
                : null}
              label="Organisation"
              errors={errors}
              getOptionLabel={(option) => { return option?.name }}
              filterSelectedOptions
              labelKey="name"
              isOptionEqualToValue={(option, value) =>
                option._id === value.id && option.name === value.name
              }
              options={orgListing && orgListing}
            />
          </FormControl>

        </Grid>
        : null}

















      <Grid container spacing={2} mt={0}>
        {loader ? (
          <Grid
            container
            direction={"column"}
            justifyContent={"center"}
            alignItems="center"
            sx={{ p: 15 }}
          >
            <Grid item>
              <img src={loaderGif} />
            </Grid>
          </Grid>
        ) : (
          <>
            {tableData && tableData?.length === 0 && tableData === undefined ? (
              <EmptyPage body="No data found" />
            ) : (
              <Grid container sx={{ mt: 2 }}>
                <Grid item sm={12} xs={12}>
                  {!searchFields?.org ? (
                    <EmptyPage body={userPermissions?.adminSpecificPermissions ? "Please select Organsiation " : ""} />
                  ) : (

                    <Paper
                      sx={{
                        height: "100%",
                        width: "98%",
                        margin: "auto",
                        overflowX: "auto",
                        display: "block",
                      }}
                    >
                      <Table
                        tableColumns={tableColumns}
                        data={tableData ?? []}
                        themeType={themeType}
                      />
                    </Paper>
                  )}
                </Grid>
              </Grid>
            )}
          </>
        )}
      </Grid>
      {isEditMode ? (
        <Grid
          item
          mt={2}
          display="flex"
          justifyContent="center"
          gap={2}
          alignItems="center"
          sx={{ width: "100%", paddingTop: "0 !important" }}
        >
          <Button
            variant="outlined"
            sx={{
              ...buttonClasses?.lynkitBlackFill,
              minHeight: "36px",
              minWidth: "140px",
            }}
            onClick={() => setEditMode(false)}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            sx={{
              ...buttonClasses?.lynkitOrangeFill,
              minHeight: "36px",
              minWidth: "140px",
            }}
            onClick={handleSubmit(handleUpdate)}
          >
            Save
          </Button>
        </Grid>
      ) : null}

      {searchFields?.org && tableData && tableData?.length > 0 && (
        <>
          {userPermissions && havePermission(userPermissions, "tripSettings", editPermission) ?
            !isEditMode ?
              <Grid
                item
                mt={2}
                display="flex"
                justifyContent="center"
                gap={2}
                alignItems="center"
                sx={{ width: "100%", paddingTop: "0 !important" }}
              >
                <Button
                  variant="contained"
                  sx={{
                    ...buttonClasses?.lynkitOrangeFill,
                    minHeight: "36px",
                    minWidth: "140px",
                  }}
                  onClick={() => setEditMode((prev) => !prev)}
                >
                  Edit
                </Button>
              </Grid>
              : null : null
          }
        </>
      )}
    </>
  );
};

export default TripSettings;
