import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button, Grid, Typography, FormControl, InputLabel, OutlinedInput, IconButton, TextField } from "@mui/material";
import CustomModel from "../../../Components/CustomModels/CustomModel1";
import ThreeDotsLoader from "../../../Components/ThreeDots/ThreeDotsLoader";
import { ToastContainer } from "react-toastify";
import { capitalizeSentence } from "../../../../services/functions";
import theme, {
    buttonClasses,
    textClasses,
} from "../../../../static/styles/theme";
import moment from 'moment'
import { DateTimePicker } from "@mui/x-date-pickers";
import CustomSubmitBtn from "../../../../components/FormUI/CustomSubmitBtn";

function TripClosureModal(props) {
    const { open, hide, selectStylesOverride, closureDetails, handleSubmitFn, closureState, setClosureState, action, handleDelete, loading } = props;
    const isDarkThemeEnabledSelector = useSelector((state) => state.theme.themeDark);
    const themeType = isDarkThemeEnabledSelector ? require("../../../../static/styles/theme") : require("../../../../static/styles/theme");
    const { buttonClasses, inputClasses, textClasses, tableClasses } = themeType;


    const dateTimePickerSlotProps = {
        textField: { size: "small", ...inputClasses.textfield },
        day: {
            sx: {
                "& .Mui-selected:hover": {
                    background: "#FF7200",
                },
            },
        },
        popper: {
            sx: {
                "& .MuiDateCalendar-root": {
                    maxHeight: "300px",
                },
                "& .MuiMultiSectionDigitalClock-root": {
                    maxHeight: "300px",
                },
            },
        },
        digitalClockSectionItem: {
            sx: {
                fontSize: "12px",
            },
        },
    };
    const handleChange = (e) => {
        let temp = { ...closureState }
        temp.remark = e.target.value
        setClosureState(temp)
    }

    const handleDate = (date) => {
        const dateStamp = moment(date).toDate();
        const timestamp = moment(dateStamp).valueOf();
        let temp = { ...closureState }
        temp.endTime = timestamp
        setClosureState(temp)
    }

    return (
        <>
            <CustomModel
                autoClose={false}
                show={open || false}
                onClose={() => hide()}
                minWidth={650}
                maxWidth={650}
                pb={3}
                pl={3}
                pr={3}
                maxHeight={"80vh"}
                childrenMaxHeight={"80vh"}
                Title={action === 'Close' ? "Trip Closure" : 'Delete'}
                viewCloseIcon={open}
            >
                <Grid container mb={0}>
                    <Grid
                        item
                        sm={12}
                        flexDirection={"column"}
                        sx={{ width: "100%", height: "auto" }}
                    >
                        <Grid mt={2} item sm={12} maxHeight="60vh" xs={12}
                            style={{
                                // overflowY: "auto",
                                overflow: 'auto',
                                overflowX: "hidden",
                                paddingBottom: "15px",
                            }}
                        >

                            <Grid container justifyContent={"center"} alignItems="center">
                                <Typography sx={{ ...textClasses.cardTitle }}>
                                    Are you Sure ?
                                </Typography>
                            </Grid>
                            <Grid container justifyContent={"center"} alignItems="center">
                                <Typography sx={{ ...textClasses.cardTitle }}>
                                    {action} the Selected Trip ?
                                </Typography>
                            </Grid>

                            <Grid item sm={4} xs={6} mt={1}>
                                <Grid
                                    container
                                    direction={"row"}
                                    justifyContent="center"
                                    alignItems={"center"}
                                    sx={{
                                        backgroundColor: theme.themeLightOrange,
                                        border: `1px solid ${theme.themeOrange}`,
                                        borderRadius: "12px",
                                        pl: 1,
                                        pr: 1,
                                        minHeight: "40px",
                                    }}
                                >
                                    <Grid item sm={10} xs={8}>
                                        <Typography
                                            sx={{
                                                ...textClasses.normalText,
                                                color: theme.themeOrange,
                                                textAlign: "start",
                                            }}
                                        >
                                            {closureDetails?.tripId}
                                        </Typography>
                                    </Grid>

                                </Grid>
                            </Grid>


                            <Grid>
                                <FormControl
                                    size="small"
                                    fullWidth
                                    sx={{ ...selectStylesOverride(isDarkThemeEnabledSelector), mt: 2 }}
                                >
                                    <InputLabel
                                        htmlFor="outlined-adornment-password"
                                        sx={{ fontSize: "12px" }}
                                    >
                                        Write Comment, if any
                                    </InputLabel>
                                    <OutlinedInput
                                        value={closureState?.remark}
                                        onChange={(e) => {
                                            handleChange(e, 'remark')
                                        }}
                                        type="text"
                                        sx={{
                                            ...selectStylesOverride,
                                            fontSize: "12px",
                                            boxShadow: 1,
                                            "& input::placeholder": { fontSize: "14px", fontWeight: "400" },
                                            borderRadius: "10px",
                                            boxShadow:'0px 0px 10px #fff'
                                        }}
                                        placeholder="Write Comment, if any"
                                        label="Write Comment, if any"
                                    />
                                </FormControl>
                            </Grid>

                            {action === 'Close' ?
                                <Grid mt={2}>

                                    <FormControl sx={{ ...selectStylesOverride(isDarkThemeEnabledSelector), width: '100%' }}>
                                        <DateTimePicker
                                            label="End Time"
                                            // minDate={new Date(closureDetails?.detailsDeviceID?.time)}
                                            onChange={(date) => handleDate(date)}
                                            className="customDatePicker"
                                            sx={{
                                                width: "100%",
                                                ...selectStylesOverride
                                            }}
                                            slotProps={dateTimePickerSlotProps}
                                            renderInput={(params) => (
        <TextField
            {...params}
            InputLabelProps={{ shrink: true }}
            inputProps={{
                min: new Date(closureDetails?.detailsDeviceID?.time).toISOString().split("T")[0] + 'T00:00',
                // 'T00:00' sets the minimum time to midnight, adjust this value as needed
            }}
        />
    )}
                                        />
                                    </FormControl>
                                </Grid>
                                : null}
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container sx={{ p: 1, mt: 0, mb: 1 }} justifyContent="center">
                    <Button
                        variant="outlined"
                        sx={{
                            ...buttonClasses.lynkitBlackFill,
                            minWidth: "150px",
                        }}
                        onClick={hide}
                    >
                        No, Cancel
                    </Button>

                    <CustomSubmitBtn
                        variant="contained"
                        sx={{
                            ml: 1,
                            ...buttonClasses.lynkitOrangeFill,
                            textTransform: "capitalize",
                            minWidth: "150px",
                        }}
                        disabled={loading}
                        loading={loading}
                        label="Submit"
                        onClick={action === 'Close' ? handleSubmitFn : handleDelete}
                    />
                </Grid>

            </CustomModel>
            <ToastContainer
                position="bottom-center"
                autoClose={false}
                closeOnClick={false}
            />
        </>
    );
}

export default TripClosureModal;
