import React from 'react';

const TripSvgIcon = ({ height, width, fill }) => (
  <svg id="Layer_1" height={height} width={width} data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 264.45 179.55">
  <defs>
    <style>{`
      .cls-trip-1 {
        fill: ${fill} !important;
      }

      .cls-trip-1, .cls-trip-2 {
        stroke-width: 0px;
        fill: ${fill} !important;
      }

      .cls-trip-3 {
        stroke-dasharray: 0 0 23.2 23.2;
      }

      .cls-trip-3, .cls-trip-4, .cls-trip-5 {
        fill: none;
        stroke: ${fill} !important;
        stroke-linecap: round;
        stroke-linejoin: round;
        stroke-width: 10px;
      }

      .cls-trip-4 {
        stroke-dasharray: 0 0 22.58 22.58;
      }
    `}
    </style>
  </defs>
  <g>
    <path class="cls-trip-1" d="m183.75,51.88c25.39,0,50.79.05,76.18-.07,2.74-.01,3.39.66,3.37,3.38-.13,19.93-.12,39.85,0,59.78.02,2.61-.74,3.16-3.24,3.16-50.91-.08-101.82-.08-152.73,0-2.58,0-3.2-.7-3.18-3.22.11-19.93.13-39.85-.02-59.78-.02-2.83.82-3.32,3.44-3.31,25.39.1,50.79.06,76.18.06Zm-66.45,33.17c0,6.91.02,13.82-.01,20.72,0,2.04.03,4.27,2.72,4.16,2.35-.1,2.46-2.18,2.45-4.11-.03-13.94-.03-27.87,0-41.81,0-1.97-.22-3.97-2.59-3.99-2.42-.03-2.6,1.99-2.59,3.95.04,7.03.02,14.06.01,21.09Zm23.39-.03c0-7.03-.02-14.06.01-21.09,0-1.98-.26-3.95-2.65-3.92-2.42.04-2.52,2.08-2.52,4.02.03,13.94.03,27.87,0,41.81,0,1.92.05,4.02,2.44,4.09,2.64.07,2.74-2.13,2.73-4.19-.03-6.91-.01-13.82-.01-20.72Zm18.24-.03c0-7.03-.02-14.06.01-21.09.01-1.98-.27-3.92-2.67-3.89-2.15.02-2.5,1.8-2.5,3.68.03,14.18.03,28.36,0,42.54,0,1.89.36,3.67,2.48,3.71,2.37.05,2.71-1.85,2.7-3.86-.04-7.03-.02-14.06-.02-21.09Zm13.1-.35c0,7.03.02,14.06-.01,21.09-.01,2.05.03,4.27,2.69,4.21,2.36-.06,2.48-2.13,2.48-4.07-.03-13.94-.03-27.87,0-41.81,0-1.95-.15-3.98-2.53-4.03-2.4-.06-2.65,1.92-2.64,3.9.04,6.91.02,13.82.02,20.72Zm23.38.32c0-6.91-.02-13.82.01-20.72,0-2.04-.02-4.27-2.69-4.21-2.39.05-2.47,2.14-2.47,4.07.03,13.94.03,27.87,0,41.81,0,1.96.16,3.98,2.52,4.04,2.37.06,2.67-1.9,2.65-3.9-.05-7.03-.02-14.06-.02-21.09Zm18.24-.19c0-6.79-.02-13.57,0-20.36,0-2,.13-4.33-2.52-4.38-2.66-.06-2.64,2.23-2.64,4.26.02,13.82.02,27.63,0,41.45,0,2.05.05,4.27,2.7,4.2,2.36-.06,2.48-2.13,2.47-4.08-.04-7.03-.02-14.06-.02-21.09Zm13.11-.13c0,7.03.02,14.06-.01,21.09,0,2.05.05,4.26,2.7,4.2,2.37-.05,2.47-2.13,2.47-4.07-.03-13.94-.03-27.87,0-41.81,0-1.95-.12-3.98-2.52-4.03-2.39-.05-2.66,1.92-2.65,3.9.04,6.91.02,13.82.02,20.72Zm23.38.77c0-7.27-.03-14.54.02-21.81.01-1.92-.47-3.61-2.6-3.59-2.12.02-2.58,1.73-2.57,3.64.03,14.18.03,28.36,0,42.54,0,1.88.34,3.67,2.45,3.76,2.39.1,2.74-1.79,2.73-3.8-.04-6.91-.02-13.82-.02-20.72Z"/>
    <path class="cls-trip-1" d="m66.74,79.14c3.03-4.8,6.38-7.46,12.12-6.79,5.27.62,10.7.4,16.02.04,3.71-.25,4.31,1.08,4.26,4.45-.2,13.61-.08,27.22-.08,40.83,0,5.41.16,5.57,5.49,5.57,48,0,95.99,0,143.99,0q5.2,0,5.2,5.39c0,4.01-.1,8.02.04,12.03.07,1.97-.38,2.99-2.55,2.68-.83-.12-1.7-.07-2.55-.01-4.08.28-7.11.2-8.81-5.11-2.21-6.91-9.43-10.31-17.02-9.84-6.75.42-12.8,5.16-15.07,11.91-.58,1.72-.75,3.21-3.4,3.18-2.63-.03-3.08-1.35-3.74-3.25-2.51-7.32-8.97-11.89-16.58-11.89-7.58,0-14.22,4.55-16.45,11.96-.84,2.8-2.19,3.08-4.59,3.07-22.97-.07-45.93-.08-68.9.01-2.38,0-3.52-.49-4.3-2.97-2.38-7.56-9-12.14-16.76-12.08-7.57.06-14.04,4.66-16.36,12.11-.78,2.5-1.97,2.96-4.31,2.93-8.53-.11-8.53-.03-8.53-8.44,0-6.68.11-13.37-.06-20.05-.06-2.41.65-3.1,3.05-3.07,10.21.12,20.41.06,30.62.05,5.01,0,5.27-.28,5.28-5.43,0-7.66-.07-15.31.03-22.96.04-3.08-1.13-4.47-4.29-4.37-3.83.13-7.67.03-11.78.03Z"/>
    <path class="cls-trip-1" d="m60.84,106.71c-4.61,0-9.21.02-13.82-.02-1.04,0-2.59.52-2.97-.61-.42-1.26-.68-2.91.47-4.13,5.32-5.64,10.67-11.26,16.01-16.88.77-.81,1.76-.67,2.72-.67,3.88,0,7.76.1,11.63-.04,1.99-.07,2.77.4,2.71,2.57-.15,5.69-.18,11.4,0,17.09.08,2.4-.84,2.8-2.94,2.74-4.6-.14-9.21-.04-13.82-.04Z"/>
    <path class="cls-trip-1" d="m184.17,158.36c-6.91.02-12.51-5.49-12.56-12.37-.05-6.84,5.56-12.48,12.44-12.51,6.88-.03,12.54,5.55,12.58,12.38.04,6.82-5.59,12.47-12.46,12.49Z"/>
    <path class="cls-trip-1" d="m73.44,158.36c-6.93.07-12.54-5.37-12.66-12.27-.12-6.84,5.46-12.54,12.33-12.61,6.88-.07,12.56,5.43,12.69,12.28.13,6.78-5.52,12.53-12.36,12.6Z"/>
    <path class="cls-trip-1" d="m224.53,133.48c6.85.05,12.46,5.74,12.38,12.56-.08,6.84-5.74,12.36-12.63,12.31-6.93-.05-12.34-5.58-12.29-12.57.05-6.96,5.55-12.36,12.53-12.31Z"/>
  </g>
  <path class="cls-trip-2" d="m107.77,23.31C107.35,10.72,95.88-.2,83.39,0c-12.76.2-23.57,11.33-23.57,24.26-.12,2.86.59,5.58,1.56,8.21,4.43,11.96,11.52,22.4,18.84,32.7,1.88,2.64,5.27,2.53,7.15-.15,6.78-9.66,13.28-19.51,17.87-30.45,1.5-3.59,2.66-7.33,2.53-11.27Zm-24.51,12.26c-5.88-.02-10.63-4.86-10.59-10.79.04-5.92,4.82-10.59,10.79-10.53,5.79.06,10.51,4.82,10.53,10.6.02,5.82-4.9,10.73-10.72,10.71Z"/>
  <g>
    <line class="cls-trip-5" x1="49.94" y1="38.23" x2="37.44" y2="38.23"/>
    <polyline class="cls-trip-5" points="17.5 38.23 5 38.23 6.87 50.59"/>
    <line class="cls-trip-4" x1="10.25" y1="72.91" x2="22.07" y2="151.03"/>
    <polyline class="cls-trip-5" points="23.76 162.2 25.63 174.55 38.13 174.55"/>
    <line class="cls-trip-3" x1="61.33" y1="174.54" x2="235.35" y2="174.48"/>
    <line class="cls-trip-5" x1="246.95" y1="174.47" x2="259.45" y2="174.47"/>
  </g>
</svg>
);

export default TripSvgIcon;