import { combineReducers } from "redux";
import { routerReducer } from "react-router-redux";

import Common from "./Common";
import UI from "./UI";
import themeType from "./themeReducer";
import usersList from "./UsersReducer";
import LogsList from "./LogsReducer";
import HealthReducer from "./HealthReducer";
import devicesList from "./DevicesReducers";
import routesReducers from "./RoutesReducers";
import OrganisationListing from "./OrganisationReducer";
import GeofenceListing from "./GeofenceReducer";
import userPermission from "./UserPermission";
import auth from "./Auth";
import templates from "./Templates";
import ManageTeamReducer from "./ManageTeam";
import Canvas from "./Canvas";
import InwardEntry from "./InwardEntry";
import Route from "./Route";
import dynamicDashboard from "./Dashboard";
import Settings from "./Settings";
import tripsData from "./TripsReducer";
import assetManagement from "./AssetMgt";
import Monitoring from "./Monitoring";
import imageReducer from "./ImageRed";
import driverReducer from "./LynkitIoReducer";
import getTripDataByTripId from "./TripMapReducers"
import socketReducer from "./socket";
import FiltersReducer from "./Filters";


const reducers = combineReducers({
  auth: auth,
  common: Common,
  deviceslist: devicesList,
  theme: themeType,
  ui: UI,
  userlist: usersList,
  logsList : LogsList,
  health : HealthReducer,
  deviceslist: devicesList,
  routes: routesReducers,
  Organisationlisting: OrganisationListing,
  geofencelist: GeofenceListing,
  userPermission: userPermission,
  canvas: Canvas,
  route: Route,
  inward: InwardEntry,
  users: ManageTeamReducer,
  settingsReducer: Settings,
  templates: templates,
  dynamicDashboard: dynamicDashboard,
  tripsData : tripsData,
  assetManagement: assetManagement,
  monitoring: Monitoring,
  imageList: imageReducer,
  driverReducer:driverReducer,
  tripMap :getTripDataByTripId,
  socket: socketReducer,
  filters: FiltersReducer, 
});

export default reducers;
