import {
  Button,
  FormControl,
  Grid,
  InputAdornment,
  Menu,
  MenuItem,
  OutlinedInput,
  Paper,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import loaderGif from "../../../../static/images/drawer/loader.gif";
import DataTable from "../../../Components/DataTable/DataTable";
import EmptyPage from "../../EmptyPage";
import { Add, KeyboardArrowDown, Search } from "@mui/icons-material";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import {
  deleteAccountLinkageDetails,
  getAccountLinkageList,
} from "../../../../redux/actions";
import {
  flushAccountLinkageList,
  setAccountLinkageFlag,
} from "../../../../redux/reducers/Settings";
import UpdateAccountLinkageStatus from "./components/UpdateAccountLinkageStatus";
import { selectStylesOverride } from "../../../../utils/util";
import { havePermission } from "../../../../utils/permissionCheck";

const AccountLinkage = () => {
  const dispatch = useDispatch();

  const [selectedTab, setSelectedTab] = React.useState(0);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [tableIndex, setTableIndex] = React.useState(null);
  const [actionIndex, setActionIndex] = React.useState(null);
  const [selectedTemplates, setSelectedTemplates] = React.useState([]);
  const [showDeleteModal, setShowDeleteModal] = React.useState(false);
  const [filter, setFilter] = React.useState({
    pageCount: 1,
    pageLimit: 10,
    searchValue: "",
  });

  const history = useHistory();

  const {
    accountLinkageLoading,
    accountLinkageListing,
    accountLinkageCount,
    accountLinkageFlag,
  } = useSelector((state) => state.settingsReducer);
  const { currentUserDetail } = useSelector(state => state.auth);
  const isDarkThemeEnabledSelector = useSelector(
    (state) => state.theme.themeDark
  );
  const themeType = isDarkThemeEnabledSelector
    ? require("../../../../static/styles/darktheme")
    : require("../../../../static/styles/theme");

  const { buttonClasses, textClasses, inputClasses } = themeType;

  const tabs = [
    {
      id: 0,
      key: "all",
      name: "All",
    },
    {
      id: 1,
      key: "sharing",
      name: "Sharing",
    },
    {
      id: 2,
      key: "receiving",
      name: "Receiving",
    },
  ];

  const tableColumns = [
    {
      header: "#",
      cell: (entry, i) => {
        return (
          <Typography
            sx={{
              ...textClasses.t13n,
              textAlign: "center",
              textTransform: "capitalize",
            }}
          >
            {/* {i + 1} */}
            {(filter?.pageCount - 1) * filter?.pageLimit + i + 1}
          </Typography>
        );
      },
    },
    {
      header: "Shared To",
      cell: (entry) => {
        return (
          <Typography
            sx={{
              ...textClasses.t13n,
              textAlign: "center",
              textTransform: "capitalize",
            }}
          >
            {entry?.userName ? `${entry?.userName ?? "-"} - ${entry?.orgName ?? "-"}` : "-"}
          </Typography>
        );
      },
    },
    {
      header: "Shared By",
      cell: (entry) => {
        return (
          <Typography
            sx={{
              ...textClasses.t13n,
              textAlign: "center",
              textTransform: "capitalize",
            }}
          >
            {entry?.addedBy ? `${entry?.addedBy?.name ?? "-"} - ${entry?.addedBy?.orgName ?? "-"}` : "-"}
          </Typography>
        );
      },
    },
    {
      header: "Account ID",
      cell: (entry) => {
        return (
          <Typography
            sx={{
              ...textClasses.t13n,
              textAlign: "center",
              textTransform: "uppercase",
            }}
          >
            {entry?.accountId ?? "-"}
          </Typography>
        );
      },
    },
    {
      header: "Type",
      cell: (entry) => {
        return (
          <Typography
            sx={{
              ...textClasses.t13n,
              textAlign: "center",
              textTransform: "capitalize",
            }}
          >
            {entry?.type ?? "-"}
          </Typography>
        );
      },
    },
    {
      header: "Action",
      cell: (row, index) => {
        const entry = accountLinkageListing ? accountLinkageListing[index] : null;
        return (
          <Typography
            data-testid="action-menu-btn"
            sx={{
              ...textClasses.normalText,
              color: themeType.default.themeOrange,
              cursor: entry?.type && entry?.type?.toLowerCase() === "receiving" ? "not-allowed" : "pointer",
            }}
            onClick={entry?.type ? entry?.type?.toLowerCase() === "receiving" ? null : (e) => handleOpenAction(e, index) : null}
          >
            Action <KeyboardArrowDown />
          </Typography>
        );
      },
    },
  ];

  /* ------ HANDLERS ------ */
  const handleOpenAction = (event, key) => {
    setAnchorEl(event.currentTarget);
    setActionIndex(key);
    setTableIndex(key);
  };

  const handleCloseAction = () => {
    setActionIndex(null);
    setTimeout(() => {
      setAnchorEl(null);
    }, 1);
  };

  const handleTabsChange = (_, value) => {
    setSelectedTab(value);

    let tempFilters = JSON.parse(JSON.stringify(filter));
    if (accountLinkageListing && tabs[value]?.key === "all") {
      tempFilters.searchValue = "";
    } else {
      tempFilters.searchValue = tabs[value]?.key;
    }

    let modifiedFilters = tempFilters;
    
    dispatch(
      getAccountLinkageList({ ...modifiedFilters }, (res) => {
        if (res && res?.success) {
          dispatch(setAccountLinkageFlag(true));
        }
      })
    );
  };

  const handleFetchAccountLinkage = () => {
    dispatch(
      getAccountLinkageList(filter, (res) => {
        if (res && res?.success) {
          dispatch(setAccountLinkageFlag(true));
        }
      })
    );
  };

  const debounceSearch = () => {
    handleFetchAccountLinkage();
  };

  const handleOpenModal = (action) => {
    let template = [];
    template?.push({
      accountName: accountLinkageListing[tableIndex]?.userName,
      id: accountLinkageListing[tableIndex]?._id,
    });

    setSelectedTemplates(template);
    setShowDeleteModal(true);
  };

  const handleStatusUpdate = () => {
    if (tableIndex || tableIndex == 0) {
      const selectedAccount = accountLinkageListing[tableIndex];
      const payload = {
        id: [selectedAccount?._id],
      };

      dispatch(
        deleteAccountLinkageDetails({ ...payload }, (res) => {
          if (res && res?.success) {
            handleFetchAccountLinkage();
            setShowDeleteModal(false);
            setSelectedTemplates([]);
          }
        })
      );
    }
  };

  /* ------ EFFECTS ------ */
  React.useEffect(() => {
    dispatch(setAccountLinkageFlag(false));
    setTableIndex(null);
    setSelectedTemplates([]);
    setSelectedTab(0);
    setFilter({
      pageCount: 1,
      pageLimit: 10,
      searchValue: "",
    });

    return () => {
      dispatch(setAccountLinkageFlag(null));
      dispatch(flushAccountLinkageList());
    };
  }, []);

  React.useEffect(() => {
    if (accountLinkageFlag === false) {
      handleFetchAccountLinkage();
    }
  }, [accountLinkageFlag]);

  React.useEffect(() => {
    if ((filter.pageCount || filter.pageLimit) && accountLinkageFlag === true) {
      handleFetchAccountLinkage();
    }
  }, [filter.pageCount, filter.pageLimit]);

  React.useEffect(() => {
    let timeoutId;

    if (filter && filter?.searchValue && filter?.searchValue?.length > 2) {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(debounceSearch, 700);
      return () => {
        clearTimeout(timeoutId);
      };
    }

    if (filter?.searchValue === "") {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(debounceSearch, 700);
      return () => {
        clearTimeout(timeoutId);
      };
    }

    if (filter && filter?.searchValue && filter?.searchValue?.length < 2) {
      return;
    }
  }, [filter?.searchValue]);

  return (
    <>
      <Grid container>
        {/* top row - page title and action button */}
        <Grid
          item
          xs={12}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography sx={{ ...textClasses.cardTitle }} data-testid="page-title">
            Account Linkage
          </Typography>
          {currentUserDetail && havePermission(currentUserDetail, "accountLinkage", "createAccountLinkage") && (
            <Button
              variant="outlined"
              size="small"
              data-testid="add-new-button"
              onClick={() =>
                history.push("/dashboard/settings/account-linkage/add")
              }
              sx={{
                ...buttonClasses.small,
                minHeight: "36px",
                borderColor: themeType.default.themeOrange,
                color: buttonClasses.lynkitOrangeEmpty,
              }}
              startIcon={<Add />}
            >
              Add New
            </Button>
          )}
        </Grid>

        {/* Tabs ---- */}
        <Grid
          item
          xs={12}
          sx={{
            mt: 0,
            mb: 1,
          }}
        >
          <Tabs
            value={selectedTab}
            onChange={handleTabsChange}
            variant="scrollable"
            scrollButtons="auto"
            className="tabsClass"
            aria-label="scrollable auto tabs example"
            sx={{
              borderBottom: 1,
              borderColor: "divider",
              maxWidth: window.innerWidth - 108,
              "& .MuiTabs-indicator": {
                backgroundColor: themeType.default.themeOrange,
              },
              "& .MuiTab-root.Mui-selected": {
                color: themeType.default.themeOrange,
              },
            }}
          >
            {tabs?.map((item, i) => {
              return (
                <Tab
                  key={i}
                  label={`${item.name}`}
                  sx={{
                    ...textClasses.normalText,
                    textTransform: "capitalize",
                  }}
                  variant="scrollable"
                  scrollButtons
                  allowScrollButtonsMobile
                />
              );
            })}
          </Tabs>

          <Grid container spacing={2} mt={0}>
            <Grid item md={12} style={{ width: "100%" }}>
              <FormControl
                variant="outlined"
                size="small"
                sx={selectStylesOverride(isDarkThemeEnabledSelector)}
              >
                <OutlinedInput
                  data-testid="searchelement"
                  sx={{ ...inputClasses.filterField, height: "36px", minWidth: "200px", width: "24vw" }}
                  placeholder="Search by name, organisation or account Id"
                  value={filter?.searchValue || ""}
                  onChange={(e) =>
                    setFilter({
                      ...filter,
                      searchValue: e.target.value
                        .trimStart()
                        .replace(/\s{2,}/g, " "),
                    })
                  }
                  endAdornment={
                    <InputAdornment position="end">
                      <Search fontSize="small" />
                    </InputAdornment>
                  }
                />
              </FormControl>
            </Grid>
          </Grid>

          {/* Table and details section ----- */}
          {accountLinkageLoading ? (
            <Grid
              container
              direction={"column"}
              justifyContent={"center"}
              alignItems="center"
              sx={{ p: 15 }}
            >
              <Grid item>
                <img src={loaderGif} />
              </Grid>
            </Grid>
          ) : (
            <>
              {accountLinkageListing && accountLinkageListing?.length === 0 ? (
                <EmptyPage body="No accounts found" />
              ) : (
                <Grid item sm={12} mt={3}>
                  <Paper
                    sx={{
                      height: "100%",
                      width: "100%",
                      overflowX: "auto",
                      display: "block",
                    }}
                    data-testid="device-models-table"
                  >
                    <DataTable
                      tableColumns={tableColumns}
                      data={accountLinkageListing ?? []}
                      totalRecords={accountLinkageCount || 0}
                      pagination={filter}
                      setPagination={setFilter}
                      pageFilterKey="pageCount"
                      limitFilterKey="pageLimit"
                      themeType={themeType}
                    />
                  </Paper>
                </Grid>
              )}
            </>
          )}
        </Grid>
      </Grid>

      {actionIndex != null && (
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={actionIndex != null}
          onClose={handleCloseAction}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
          sx={{
            display:
              actionIndex != null ? "block !important" : "none !important",
          }}
        >
          {currentUserDetail && havePermission(currentUserDetail, "accountLinkage", "editAccountLinkage") && (
            <MenuItem
              key={"edit"}
              onClick={() => {
                history.push({
                  pathname: "/dashboard/settings/account-linkage/edit",
                  state: {
                    data: accountLinkageListing[tableIndex] || {},
                  },
                });
                handleCloseAction();
              }}
            >
              Edit
            </MenuItem>
          )}

          {currentUserDetail && havePermission(currentUserDetail, "accountLinkage", "deleteAccountLinkage") && (
            <MenuItem
              key="delete"
              data-testid="delete-menu"
              onClick={() => {
                handleOpenModal("delete");
                handleCloseAction();
              }}
            >
              Delete
            </MenuItem>
          )}
        </Menu>
      )}

      {showDeleteModal && (
        <UpdateAccountLinkageStatus
          open={showDeleteModal}
          loading={false}
          hide={() => {
            setShowDeleteModal(false);
            handleCloseAction();
          }}
          tableData={accountLinkageListing || []}
          checkedObj={[]}
          action={"delete"}
          // refresh={handleFetchAllSchedules}
          setShowDeleteModal={() => {
            setShowDeleteModal(false);
          }}
          selectStylesOverride={selectStylesOverride(
            isDarkThemeEnabledSelector
          )}
          actionFn={handleStatusUpdate}
          setSelectedTemplates={setSelectedTemplates}
          selectedTemplates={selectedTemplates}
          setCheckedObj={() => {}}
          comment={""}
          setComment={() => {}}
        />
      )}
    </>
  );
};

export default AccountLinkage;
