import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button, Grid, Typography, FormControl, IconButton, FormControlLabel, Checkbox } from "@mui/material";
import CustomModel from "../../../Components/CustomModels/CustomModel1";
import ThreeDotsLoader from "../../../Components/ThreeDots/ThreeDotsLoader";
import { Controller, useForm, useWatch } from "react-hook-form";
import { Cancel } from "@mui/icons-material";
import { showToast } from "../../../../services/functions";
import { ToastContainer } from "react-toastify";
import EmptyPage from "../../EmptyPage";
import CustomSelect from "../../../../components/FormUI/CustomSelect";
import { CustomInput } from '../../../../components/FormUI';
import { selectStylesOverride, sendCustomEventAnalytics } from "../../../../utils/util";
import { downloadTripReport, saveDefaultReportKeys, getTripReportKeys, downloadTripSummaryReport } from "../../../../redux/actions";
import moment from 'moment';
import CustomSubmitBtn from "../../../../components/FormUI/CustomSubmitBtn";

function TripReport(props) {
    const dispatch = useDispatch();
    const { message, error } = useSelector((state) => state.common);
    const { open, hide, finalFilter, reportKeys, reportKeysLoader, filter, setReportsModal, type, tripDeviceId, eventsCheck } = props;
    const isDarkThemeEnabledSelector = useSelector((state) => state.theme.themeDark);
    const themeType = isDarkThemeEnabledSelector ? require("../../../../static/styles/theme") : require("../../../../static/styles/theme");
    const { buttonClasses, inputClasses, textClasses, tableClasses } = themeType;
    const userPermissions = useSelector((state) => state.auth?.currentUserDetail) || {};
    const [reportKeysArray, setReportKeys] = useState([])
    const [checkboxes, seCheckBoxes] = useState([])
    const [defaultCheck, setDefaultCheck] = useState(false)
    const [reportLoader, setReportLoader] = useState(false);
    const [keysLoader, setSaveKeysLoader] = useState(false);
    const [emailBox, setEmailBox] = useState(false)

    const [reportState, setReportState] = useState({
        fromDate: "",
        toDate: "",
        projection: [],
        tripReportEmail: [],
    })
    const {
        control,
        formState: { errors, isSubmitting, isValid, dirtyFields },
        handleSubmit,
        getValues,
        setValue
    } = useForm();
    const defaultKeys = useSelector((state) => state.tripsData.reportKeys);

    useEffect(() => {
        if (userPermissions?.email) {
            setReportState(prevState => ({
                ...prevState,
                tripReportEmail: [userPermissions?.email]
            }));
        }
    }, [userPermissions])

    useEffect(() => {
        if (reportKeys && reportKeys?.allKeyHeader?.length) {
            const filteredArray = reportKeys?.allKeyHeader.filter(item => item.mapping_path !== null);
            setReportKeys(filteredArray);
        }
    }, [reportKeys])


    const handleCheckbox = (e, item, obj) => {
        let temp = [...checkboxes]
        if (item === 'all') {
            checkboxes.length = 0
            seCheckBoxes([])
            if (e.target.checked) {
                const dummy = [];
                for (let i = 0; i < reportKeysArray?.length; i++) {
                    dummy.push({
                        id: reportKeysArray[i]?.mapping_path,
                        itemName: reportKeysArray[i]?.field_name
                    })
                }
                seCheckBoxes(dummy)
            }
            else {
                checkboxes.length = 0
                seCheckBoxes([])
            }

        }
        else if (item === 'default') {
            if (e.target.checked) {
                setDefaultCheck(true)
                let notPresentEntries = defaultKeys?.defaultKeyHeader.filter(defaultObj => {
                    let presentInMainArray = checkboxes.some(mainObj =>
                        mainObj.id === defaultObj.mapping_path
                    );
                    return !presentInMainArray;
                }).map(obj => {
                    return {
                        id: obj.mapping_path,
                        itemName: obj.field_name
                    };
                });

                seCheckBoxes(prevCheckboxes => [...prevCheckboxes, ...notPresentEntries]);
            }
            else {
                setDefaultCheck(false)
                const temp = checkboxes.filter(mainItem => {
                    return !defaultKeys?.defaultKeyHeader.some(defaultItem => {
                        return mainItem.id === defaultItem.mapping_path || mainItem.id === defaultItem.field_name;
                    });
                });
                seCheckBoxes(temp)
            }
        }
        else if (item === 'single') {
            const exists = temp.some(item => item.id === obj.mapping_path);

            if (exists) {
                temp = temp.filter(item => item.id !== obj.mapping_path);
            }

            if (!exists) {
                temp.push({
                    "id": obj.mapping_path,
                    "itemName": obj.field_name
                });
            }
            seCheckBoxes(temp)


        }
    }

    useEffect(() => {
    }, [checkboxes])

    const submitReport = () => {
        let temp = { ...reportState }
        let payload = {
            route: filter?.route,
            status: filter?.status,
            assetType: filter?.assetType,
            projection: checkboxes,
            elockFilter: filter?.elockFilter,
            regex: filter?.regex,
            fromDate: new Date(filter?.fromDate).getTime(),
            toDate: new Date(filter?.toDate).getTime(),
            tripReportEmail: temp.tripReportEmail
        }
        if (userPermissions && userPermissions.adminSpecificPermissions) {
            // if (filter.userId) {
            payload.orgId = filter?.orgId
            payload.userId = filter?.userId
            // }
        }
        if (userPermissions && !userPermissions.adminSpecificPermissions) {
            if (filter.userId) {
                payload.userId = filter?.userId
            }
        }
        setReportState(temp)
        setReportLoader(true)
        dispatch(
            downloadTripReport(payload, () => {
                setReportLoader(false);
            })
        );
    }

    const saveDefaultKeys = () => {
        const transformedData = checkboxes?.map(item => {
            return {
                mapping_path: item.id,
                field_name: item.itemName
            };
        });

        setSaveKeysLoader(true)
        dispatch(saveDefaultReportKeys({
            reportType: type === "tripSummary" ? "trip summary" : "trip",
            headers: transformedData
        }, () => {
            setSaveKeysLoader(false)
        }))
    }

    const _handleChange = (e, newVal, item) => {
        if (item === "email") {
            const emailsString = e.target.value;
            const emailsArray = emailsString.split(',').map(email => email.trim());

            setReportState(prevState => ({
                ...prevState,
                tripReportEmail: emailsArray
            }));
        }
    }

    useEffect(() => {
        if (message && reportLoader) {
            showToast(message, false);
            setReportLoader(false);
            setReportsModal(false)
            setReportState({
                fromDate: "",
                toDate: "",
                projection: [],
                tripReportEmail: [],
            })
        }
        if (error && reportLoader) {
            setReportLoader(false);
            showToast(error, true);
        }
    }, [message, error]);

    useEffect(() => {
        if (message && keysLoader) {
            showToast(message, false);
            seCheckBoxes([]);
            setDefaultCheck(false)
            let payload = {
                "reportType": type === "tripSummary" ? "trip summary" : "trip",
                "keyType": "all"
            }
            dispatch(
                getTripReportKeys({ ...payload }, () => {
                })
            );
            setSaveKeysLoader(false);
        }
        if (error && keysLoader) {
            setSaveKeysLoader(false);
            showToast(error, true);
        }
    }, [message, error]);

    const submitSummaryReport = () => {
        let payload = {
            "trip_id": tripDeviceId?.tripId,
            "deviceId":  tripDeviceId?.deviceId,
            "dataType": eventsCheck ? 'few' : 'all',
            "projection": checkboxes,
            "tripReportEmail": reportState.tripReportEmail
        }
        setReportLoader(true)
        dispatch(downloadTripSummaryReport(payload,(res)=>{
            if(res.success){
                setReportLoader(false)
                setReportsModal(false)
                showToast(res?.message,false)
            }
            else{
                setReportLoader(false)
                showToast(res?.message,true)
            }
        }))
    }



    return (
        <>
            <CustomModel
                autoClose={false}
                show={open || false}
                onClose={() => hide()}
                minWidth={820}
                maxWidth={820}
                pb={3}
                pl={3}
                pr={3}
                maxHeight={"90vh"}
                overflow={'auto'}
                childrenMaxHeight={"90vh"}
                Title={type === "tripSummary" ? "Trip Summary Report" : "Trip Report"}
                viewCloseIcon={open}
            >
                <Grid container mb={2}>
                    <Grid
                        item
                        sm={12}
                        flexDirection={"column"}
                        sx={{ width: "100%" }}
                    >
                        <Grid mt={2} item sm={12} maxHeight="47vh" xs={12}
                            style={{
                                // overflowY: "auto",
                                overflow: 'auto',
                                overflowX: "hidden",
                                padding: "10px",
                                paddingBottom: "10px",
                            }}
                        >
                            <Grid Container display={'flex'} >
                                <Grid item md={3} sx={{ display: 'flex', alignItems: 'center' }}>
                                    <Typography sx={{
                                        fontSize: "17px",
                                        fontWeight: "700",
                                        width: "100%",
                                        ...textClasses.themeColor,
                                    }}>
                                        Trip Report Keys
                                    </Typography>
                                </Grid>
                                <Grid item md={9} display={'flex'} justifyContent={'flex-end'}  >
                                    <Grid item md={3} >
                                        <FormControlLabel
                                            sx={{
                                                color: "rgb(128, 128, 128)",
                                                "& .MuiFormControlLabel-label": { fontSize: "13px" },
                                            }}
                                            label={'Select All'}
                                            control={
                                                <Checkbox
                                                    sx={{
                                                        color: "#ff7200",
                                                        "&.Mui-checked": {
                                                            color: "#ff7200",
                                                        },
                                                    }}
                                                    checked={reportKeysArray && reportKeysArray?.length === checkboxes?.length}
                                                    onChange={(e) => {
                                                        handleCheckbox(e, 'all');
                                                    }}
                                                    name={'Select All'}
                                                />
                                            }
                                        />
                                    </Grid>

                                    <Grid item md={3} >
                                        <FormControlLabel
                                            sx={{
                                                color: "rgb(128, 128, 128)",
                                                "& .MuiFormControlLabel-label": { fontSize: "13px" },
                                            }}
                                            label={'Default Keys'}
                                            control={
                                                <Checkbox
                                                    sx={{
                                                        color: "#ff7200",
                                                        "&.Mui-checked": {
                                                            color: "#ff7200",
                                                        },
                                                    }}
                                                    disabled={reportKeysArray && reportKeysArray?.length === checkboxes?.length || defaultKeys && defaultKeys?.defaultKeyHeader?.length === 0}
                                                    checked={defaultCheck}
                                                    onChange={(e) => {
                                                        handleCheckbox(e, 'default');
                                                    }}
                                                    name={'Default Keys'}
                                                />
                                            }
                                        />
                                        {defaultKeys && !defaultKeys?.defaultKeyHeader?.length ?
                                            <Grid sx={{
                                                fontSize: "12px",
                                                width: "100%",
                                                color: 'red',
                                                position: 'relative',
                                                bottom: '8px',
                                                ...textClasses.themeColor,
                                            }}>
                                                No Default Keys.
                                            </Grid>
                                            : null}
                                    </Grid>

                                </Grid>
                            </Grid>


                            {reportKeysLoader ? (
                                <Grid
                                    container
                                    item
                                    direction={"column"}
                                    justifyContent={"center"}
                                    alignItems="center"
                                    xs={12}
                                    sx={{ p: 15 }}
                                >
                                    <Grid item={true} container direction={"column"} justifyContent={"center"} alignItems="center" xs={12} sx={{ p: 1 }}>
                                        <ThreeDotsLoader />
                                    </Grid>

                                </Grid>
                            ) : (
                                <>
                                    {reportKeysArray && reportKeysArray?.length === 0 ? (
                                        <EmptyPage body="No Keys found.." />
                                    ) : (
                                        <Grid
                                            container
                                            borderRadius={1}
                                            border={"1px solid #E8E8E8"}
                                            my={1}
                                            p={2}
                                        // sx={{ width: "100%", height: '340px', overflow: 'auto' }}
                                        >
                                            {reportKeysArray && reportKeysArray?.map((item, index) => {
                                                return (
                                                    <Grid key={index} item md={4} >
                                                        <FormControlLabel
                                                            sx={{
                                                                color: "rgb(128, 128, 128)",
                                                                "& .MuiFormControlLabel-label": { fontSize: "13px" },
                                                            }}
                                                            label={item?.field_name}
                                                            control={
                                                                <Checkbox
                                                                    sx={{
                                                                        color: "#ff7200",
                                                                        "&.Mui-checked": {
                                                                            color: "#ff7200",
                                                                        },
                                                                    }}
                                                                    checked={checkboxes.some(checkbox => checkbox.id === item.mapping_path)}
                                                                    onChange={(e) => {
                                                                        handleCheckbox(e, 'single', item, index);
                                                                    }}
                                                                    name={item?.field_name}
                                                                />
                                                            }
                                                        />
                                                    </Grid>
                                                )
                                            })}


                                        </Grid>
                                    )}
                                </>
                            )}








                        </Grid>
                    </Grid>
                </Grid>
                <Grid
                    container
                    item
                    direction={"row"}
                    sm={12}
                    xs={12}
                    sx={{ ml: 1, pb: 3 }}
                >
                    <Grid item md={12} mt={0}>
                        <FormControl sx={{ ...selectStylesOverride, width: '98%' }}>
                            <Controller
                                name="email"
                                control={control}
                                id={`email`}
                                render={({ field: { onChange, value, name } }) => {
                                    return (
                                        <CustomInput
                                            placeholder="Email Id"
                                            label="Email Id(s)"
                                            required={true}
                                            selectStylesOverride={selectStylesOverride(isDarkThemeEnabledSelector)}
                                            inputClasses={inputClasses}
                                            name={name}
                                            errors={errors}
                                            value={reportState?.tripReportEmail}
                                            onChange={(e, newVal) => {
                                                _handleChange(e, newVal, 'email')
                                            }}
                                        />
                                    );
                                }}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item md={12} mt={0.5} mb={1} >
                        <Typography sx={{ ...textClasses.t12n }}>Add multiple email ids by comma <b>( , )</b> separated.</Typography>
                    </Grid>

                    <>
                        <Button
                            variant="contained"
                            size="small"
                            sx={{
                                ...buttonClasses.lynkitBlackFill,
                                minHeight: "36px",
                                marginRight: '12px',
                                minWidth: "110px",
                            }}
                            onClick={() => setReportsModal(false)}
                        >
                            Cancel
                        </Button>



                        <CustomSubmitBtn
                            variant="contained"
                            sx={{
                                ...buttonClasses.lynkitOrangeFill,
                                ml: 0,
                                fontSize: ".7rem",
                                border: "none",
                                height: "37px",
                                minWidth: "110px",
                                marginRight: "17px",
                                "&.Mui-disabled": {
                                    backgroundColor: "#827878 !important",
                                    border: `1px solid #827878 !important`,
                                    color: "#EAEAEA !important",
                                },
                            }}
                            disabled={reportLoader}
                            loading={reportLoader}
                            label="Submit"
                            onClick={() => { type === "tripSummary" ? submitSummaryReport() : submitReport(); sendCustomEventAnalytics("trips", "Trip Report", "Trip Report") }}
                        />



                        <Button
                            variant="contained"
                            size="small"
                            sx={{
                                ...buttonClasses.small,
                                minHeight: "36px",
                                borderColor: themeType.default.themeOrange,
                                color: buttonClasses.lynkitOrangeEmpty,
                                minWidth: "110px",
                            }}
                            onClick={() => { saveDefaultKeys(); sendCustomEventAnalytics("trips", "save trip default keys", "Trip default keys") }}
                        >
                            Save Default Keys
                        </Button>



                    </>
                </Grid>
            </CustomModel>
            <ToastContainer
                position="bottom-center"
                autoClose={false}
                closeOnClick={false}
            />
        </>
    );
}

export default TripReport;
