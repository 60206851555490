import React from 'react';
import { useDraggable } from '@dnd-kit/core';
import { CSS } from "@dnd-kit/utilities";
// import { BackgroundColor, Color } from '../../../util/constant';
import { Button, Row, Col, Input,  } from "reactstrap";
function Draggable (props) 
{
    const {deleteDraggable, deletable} = props;
    const { attributes, listeners, setNodeRef, transform } = useDraggable({
        id: props.id,
        data: {
            // title,
            index: props.index,
            parent : props.parent,
          },
    });

    const style = {
        transform:  CSS.Translate.toString(transform),
        borderStyle: "dashed",
        // borderWidth: "thin",
        // borderColor: "gray",
        padding: "8px",
        margin: "6px",
        // boxShadow: "#262932 0px 5px 10px 1px",
        backgroundColor: "#fff",
        color: "#ff7200",
        textAlign: "center",
        borderRadius: "5px",
        // width: "100%",
        justifyContent: "space-between",
        height: 40,
        outline:'none',
    };

    const childrenStyle = {
        // ... (existing styles)
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      };

    const iconStyle={position: "absolute", right: "26px", top: "20px", cursor: "pointer"}


    return (
        <div ref={setNodeRef} style={{width: "100%", position: "relative", paddingLeft:'10px', paddingRight:'10px'}}>
            <button className='dnd_border btn btn-outline-primary d-flex cursor_move hclass'  style={style} {...listeners} {...attributes}>
                <div style={childrenStyle}> {props.children} </div>
                
            </button>
            {deletable ? <i style={iconStyle} onClick={(e)=>deleteDraggable(e,{index:props.index,parentIndex: props.i, key: props.id, parent:props.parent})} className="fa fa-trash"></i> : ""}
        </div>
    );
}

export default Draggable ;
