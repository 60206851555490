import { Box, Button, Grid, IconButton, Typography } from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers";
import {
  dateTimePickerSlotProps,
  selectStylesOverride,
} from "../../../../utils/util";
import React from "react";
import { ArrowBack } from "@mui/icons-material";
import history from "../../../../services/history";

const CameraPlaybackDrawer = ({
  themeType,
  isDark,
  setShowMap,
  setShowCamera,
  handleHistoryTrack,
  handleTimeBtnClicked,
  handleEventsPlayback,
  handleCameraPlayback,
  filters,
  setFilters,
}) => {
  const { buttonClasses, inputClasses } = themeType;

  return (
    <Box
      position="relative"
      sx={{
        width: 500,
        opacity: 1,
        transition: "all 0.6s ease-in-out",
        border: `1px solid`,
        borderColor: (theme) => theme.palette.divider,
        borderRadius: "10px",
      }}
      data-testid="controls-container"
    >
      {/* -----header----- */}
      <Box
        mb={1}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          position: "sticky",
          width: "100%",
          top: "-1px",
          background: (theme) => theme.palette.background.paper,
          zIndex: 300,
          borderBottom: `1px solid`,
          borderColor: (theme) => theme.palette.divider,
          borderRadius: "10px 10px 0px 0px",
        }}
      >
        {/* <Box display="flex" alignItems='center' justifyContent="center">

        </Box> */}
        <Button startIcon={<ArrowBack />} sx={{width: "50%"}} onClick={() => history?.goBack()}>
          Go Back
        </Button>
        <Typography
          sx={{
            fontSize: "17px",
            fontWeight: "700",
            width: "100%",
            textAlign: "center",
            // ...textClasses.themeColor,
          }}
        >
          Apply Filter Detail
        </Typography>
      </Box>

      {/* ----------------- */}
      <Grid container px={1}>
        <Grid
          item
          xs={12}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          gap="6px"
          sx={{ paddingTop: "0px !important" }}
          mb={2}
        >
          <Button
            variant="contained"
            fullWidth
            onClick={() => handleTimeBtnClicked(6)}
            sx={{ ...buttonClasses.lynkitOrangeFill }}
            // disabled={!socketInstance || !socketInstance?.id}
          >
            6 hrs
          </Button>
          <Button
            variant="contained"
            fullWidth
            onClick={() => handleTimeBtnClicked(12)}
            sx={{ ...buttonClasses.lynkitOrangeFill }}
            // disabled={!socketInstance || !socketInstance?.id}
          >
            12 hrs
          </Button>
          <Button
            variant="contained"
            fullWidth
            onClick={() => handleTimeBtnClicked(24)}
            sx={{ ...buttonClasses.lynkitOrangeFill }}
            // disabled={!socketInstance || !socketInstance?.id}
          >
            24 hrs
          </Button>
        </Grid>
        <Grid xs={12} mb={2}>
          <DateTimePicker
            label="From"
            value={filters?.from || new Date()}
            onChange={(date) => setFilters((prev) => ({ ...prev, from: date }))}
            className="customDatePicker"
            sx={{
              width: "100%",
              ...selectStylesOverride(isDark),
            }}
            slotProps={dateTimePickerSlotProps(inputClasses, isDark)}
          />
        </Grid>
        <Grid xs={12} mb={2}>
          <DateTimePicker
            label="To"
            value={filters?.to || new Date()}
            onChange={(date) => setFilters((prev) => ({ ...prev, to: date }))}
            className="customDatePicker"
            sx={{
              width: "100%",
              ...selectStylesOverride(isDark),
            }}
            slotProps={dateTimePickerSlotProps(inputClasses, isDark)}
          />
        </Grid>
        <Grid xs={12} mb={2}>
          <Button
            sx={{ ...buttonClasses?.lynkitOrangeFill }}
            fullWidth
            variant="contained"
            onClick={() => {
              setShowCamera(false);
              setShowMap(true);
              handleHistoryTrack();
            }}
          >
            History Track
          </Button>
        </Grid>
        <Grid xs={12} mb={2}>
          <Button
            sx={{ ...buttonClasses?.lynkitOrangeFill }}
            fullWidth
            variant="contained"
            onClick={() => {
              setShowMap(false);
              setShowCamera(true);
              handleCameraPlayback();
            }}
          >
            Camera Playback
          </Button>
        </Grid>
        <Grid xs={12} mb={2}>
          <Button
            sx={{ ...buttonClasses?.lynkitOrangeFill }}
            fullWidth
            variant="contained"
            onClick={() => {
              setShowMap(false);
              setShowCamera(true);
              handleEventsPlayback();
            }}
          >
            Events Playback
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default CameraPlaybackDrawer;
