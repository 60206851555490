import React , { useState , useEffect} from 'react';
import Button from '@mui/material/Button';
import{ DialogActions,Typography,Dialog,DialogContent,DialogContentText, DialogTitle} from '@mui/material';

export default function AlertDialog({viewDialog,children,title,subTitle,body,width}) {
    const [open, setOpen] = useState(false);

    useEffect(()=>{
        setOpen(viewDialog)
    },[viewDialog])
    

    return (
        <div>
            <Dialog
                open={open}
                // fullWidth={false}
                maxWidth={width ? "lg" : "xs"}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title" sx={{fontWeight:'1000', fontSize:'23px',textAlign:"center"}}>
                    {title}<br/> {subTitle}
                </DialogTitle>
               
                <DialogContent>
                    <DialogContentText id="alert-dialog-description" sx={{fontSize:'16px', color:'#000', fontWeight:'500'}}>
                       {body}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                   {children}
                </DialogActions>
            </Dialog>
        </div>
    );
}