// material ui
import {
    Button,
    Autocomplete,
    TextField,
    Grid,
    MenuItem,
    Paper,
    Menu,
    CardContent,
    DemoItem,
    Typography,
    FormControl,
    OutlinedInput,
    IconButton,
    InputAdornment,
    FormControlLabel,
    Card,
    InputLabel,
    TablePagination,
    CardHeader,
    Checkbox,
    Tooltip,
    Box,
    Tabs,
    Tab,
} from "@mui/material";
import {
    Close,
    ControlPoint,
    KeyboardArrowDown,
    Label,
    QrCodeScanner,
    QuestionMark,
    Search,
    Sell,
    SellOutlined,
    FilterAlt,
} from "@mui/icons-material";
import { dateTimePickerSlotProps } from "../../../utils/util";
import { DateTimePicker } from "@mui/x-date-pickers";
import dayjs from 'dayjs';
// react
import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as XLSX from 'xlsx';
// action
import { getportWiseStatus, getSimpleTrackData, getFullTrackData, getTrackStatusKeys } from "../../../redux/actions/Health";
import { getAllOrganisations } from "../../../redux/actions";
import { getAllUsers } from "../../../redux/actions";
import EmptyPage from "../EmptyPage";
import loaderGif from "../../../static/images/drawer/loader.gif";
import ThreeDotsLoader from "../../Components/ThreeDots/ThreeDotsLoader";
import FiltersWrapper from '../../Components/FiltersWrapper/FiltersWrapper';
import { selectStylesOverride } from "../../../utils/util";
import CustomSelect from "../../../components/FormUI/CustomSelect";
import { BarChart } from "../../Components/BarChart/BarChart";
import HealthTable from "./components/HealthTable";
import { TableColumns } from "./components/TableColumns";
import CustomModel from "../../Components/CustomModels/CustomModel1";
import { showToast } from "../../../services/functions";
import Loader from "../Logs/components/Loader";


let payload = {
    page: 1,
    limit: 10,
};

export default function HealthLogs() {
    const dispatch = useDispatch();
    // selectors ---
    const { OrganisationListing } = useSelector((state) => state.Organisationlisting);
    const usersListing = useSelector((state) => state.userlist.allUsers);
    const userDetail = useSelector((state) => state.auth.currentUserDetail);
    const superAdmin = userDetail?.adminSpecificPermissions || {};
    const portWiseStatus = useSelector((state) => state.health.portWiseStatus);
    // const simpleTrackStatus = useSelector((state) => state.health.simpleTrackStatus);
    // const fullTrackStatus = useSelector((state) => state.health.fullTrackStatus);
    // const trackStatusKeys = useSelector((state) => state.health.fullTrackStatus);
    // console.log('Users-redux', useSelector((state) => state))
    // console.log('portWiseStatus', trackStatusKeys)

    // theme declare ---
    const isDarkThemeEnabledSelector = useSelector(
        (state) => state.theme.themeDark
    );
    const themeType = isDarkThemeEnabledSelector
        ? require("../../../static/styles/darktheme")
        : require("../../../static/styles/theme");
    const {
        theme,
        buttonClasses,
        cardClasses,
        inputClasses,
        textClasses,
        tableClasses,
        dividerClass,
    } = themeType;

    // state declare ---
    const [open, setOpen] = useState(false);
    const [loader, setLoader] = useState(false);
    const [reportKeysLoader, setReportKeysLoader] = useState(false);
    const [reportKeysArray, setReportKeysArray] = useState([])
    const [reportDefaultKeysArray, setReportDefaultKeysArray] = useState([])
    const [selectedKeys, setSelectedKeys] = useState([])
    // const [selectedAllKeys, setSelectedAllKeys] = useState(false)
    const [errorsObj, setErrorsObj] = useState({});
    const [filter, setFilter] = useState({ ...payload });
    const [downloadStart, setDownloadStart] = useState(false);
    const [downloadSimpleStart, setDownloadSimpleStart] = useState(false);
    const [openTrack, setOpenTrack] = useState(false);
    const [barData, setBarData] = useState({});
    const [searchFields, setSearchFields] = useState({
        org: null,
        userId: null,
    });

    let tableColumns = TableColumns({ filter })

    const getDownloadSimpleTrack = () => {
        // console.log('filter', filter)
        if (!filter?.toDate && !filter?.fromDate && !filter?.deviceID) {
            return setErrorsObj({
                ...errorsObj, "toDate": {
                    type: "custom",
                    message: "To Date is required",
                }, "fromDate": {
                    type: "custom",
                    message: "From Date is required",
                }, "deviceID": {
                    type: "custom",
                    message: "Device ID is required",
                }
            })
        }
        if (!filter?.fromDate && !filter?.deviceID) {
            return setErrorsObj({
                ...errorsObj, "toDate": "", "fromDate": {
                    type: "custom",
                    message: "From Date is required",
                }, "deviceID": {
                    type: "custom",
                    message: "Device ID is required",
                }
            })
        }
        if (!filter?.toDate && !filter?.deviceID) {
            return setErrorsObj({
                ...errorsObj, "toDate": {
                    type: "custom",
                    message: "To Date is required",
                }, "fromDate": "", "deviceID": {
                    type: "custom",
                    message: "Device ID is required",
                }
            })
        }
        if (!filter?.toDate && !filter?.fromDate) {
            return setErrorsObj({
                ...errorsObj, "toDate": {
                    type: "custom",
                    message: "To Date is required",
                }, "fromDate": {
                    type: "custom",
                    message: "From Date is required",
                }, "deviceID": ""
            })
        }
        if (!filter?.deviceID) {
            return setErrorsObj({
                ...errorsObj, "deviceID": {
                    type: "custom",
                    message: "Device ID is required",
                }
            })
        }
        if (!filter?.toDate) {
            return setErrorsObj({
                ...errorsObj, "toDate": {
                    type: "custom",
                    message: "To Date is required",
                }, "fromDate": "", "deviceID": ""
            })
        }
        if (!filter?.fromDate) {
            return setErrorsObj({
                ...errorsObj, "fromDate": {
                    type: "custom",
                    message: "From Date is required",
                }, "toDate": "", "deviceID": ""
            })
        }
        setOpenTrack(true)
        setReportKeysLoader(true);
        dispatch(
            getTrackStatusKeys({
                "reportType": "health"
            }, (res) => {
                // console.log('response', res)
                setReportKeysArray(res.allKeyHeader);
                setReportDefaultKeysArray(res.defaultKeyHeader)
                setReportKeysLoader(false);
            })
        );
    }

    const getDownloadFullTrack = () => {
        // console.log('filter', filter)
        dispatch(
            getTrackStatusKeys({
                "reportType": "health"
            }, (res) => {
                // console.log('response', res)
                setReportKeysArray(res.allKeyHeader);
                setReportDefaultKeysArray(res.defaultKeyHeader)
                setReportKeysLoader(false);
                setDownloadStart(true)
            })
        );
    }

    const handleDropDown = (e, newVal, key) => {
        if (key === "Org") {
            // console.log('selectedorg', newVal)
            if (Object.keys(superAdmin).length && newVal?._id) {
                dispatch(getAllUsers({ orgId: newVal?._id, selection: ["name"] }));
                setFilter((existing) => ({
                    ...existing,
                    orgId: newVal?._id
                }));
            }
        }

        if (key === "User") {
            // console.log('selecteduser', newVal);
            setLoader(true)
            dispatch(
                getportWiseStatus({
                    "user": "",
                    "userCode": "",
                    "userId": newVal?._id,
                    "orgId": filter?.orgId,
                }, () => {
                    setLoader(false);
                })
            );
            setFilter((existing) => ({
                ...existing,
                userId: newVal?._id
            }));
        }

    }

    const handleCheckbox = (e, key, item, index) => {
        // console.log('checkbox', key, item, index);

        if (key === "single") {
            setSelectedKeys((prevSelectedKeys) => {
                const isItemInSelectedKeys = prevSelectedKeys.some(
                    (ele) => ele.field_name === item.field_name
                );

                if (isItemInSelectedKeys) {
                    return prevSelectedKeys.filter(
                        (ele) => ele.field_name !== item.field_name
                    );
                } else {
                    return [...prevSelectedKeys, item];
                }
            });
        }

        if (key === "selectall") {
            setSelectedKeys((prevSelectedKeys) =>
                prevSelectedKeys.length === reportKeysArray.length
                    ? []
                    : [...reportKeysArray]
            );
        }

        if (key === "default") {
            setSelectedKeys([...reportDefaultKeysArray]);
        }
    };

    const submitReport = () => {
        // console.log('submitReport', selectedKeys)
        if (selectedKeys.length === 0) return;
        setDownloadSimpleStart(true)
        dispatch(
            getSimpleTrackData({
                "deviceID": filter?.deviceID,
                "fromDate": filter?.fromDate,
                "toDate": filter?.toDate,
                "projection": selectedKeys,

            }, (res) => {
                setLoader(false);
                if (!res.success) {
                    showToast(res.message, true)
                }
                setDownloadSimpleStart(false);
                if (res?.trackData?.length > 0) {
                    // const tableData = res?.trackData.map((ele, index) => {
                    //     let temp = ele;
                    //     delete temp._id;
                    //     return temp
                    // });
                    // console.log("table data - submit report: ", tableData);
                    // const ws = XLSX.utils.json_to_sheet(tableData);
                    // const wb = XLSX.utils.book_new();
                    // XLSX.utils.book_append_sheet(wb, ws, 'Sheet 1');
                    // XLSX.writeFile(wb, `simpleTrackStatus.xlsx`);

                    const tableData = res?.trackData.map((ele, index) => {
                        let temp = { ...ele }; // Create a copy of the object
                        delete temp._id;
                        
                        // Check if the object has the "packet_time" key
                        if ('packet_time' in temp) {
                            const packetTime = new Date(temp.packet_time);
                            const ISTTime = new Date(packetTime.getTime() + (5.5 * 60 * 60 * 1000)); // Add 5 hours and 30 minutes to packet time
                            temp.IST = ISTTime.toISOString(); // Add IST time to the object
                        }
                        return temp;
                    });
                    
                    const ws = XLSX.utils.json_to_sheet(tableData);
                    const wb = XLSX.utils.book_new();
                    XLSX.utils.book_append_sheet(wb, ws, 'Sheet 1');
                    XLSX.writeFile(wb, `simpleTrackStatus.xlsx`);
                }
                setOpenTrack(false);
                setSelectedKeys([]);
            })
        );
    }

    const handleSearchOrg = (empty) => {
        if (empty === true) {
        dispatch(getAllOrganisations({ selection: ["name"] }));
        } else {
        if (searchFields?.org || searchFields?.org == "")
            dispatch(
            getAllOrganisations({
                selection: ["name"],
                searchValue: searchFields.org,
            })
            );
        }
    };

    const handleSearchUsers = (empty) => {
        if (empty === true) {
          dispatch(
            getAllUsers({
              selection: ["name", "email"],
              searchValue: "",
              orgId: filter?.orgId ? filter?.orgId : "",
            })
          );
        } else {
          if (searchFields?.userId || searchFields?.userId == "")
            dispatch(
              getAllUsers({
                selection: ["name", "email"],
                searchValue: searchFields?.userId,
                orgId: filter?.orgId ? filter?.orgId : "",
              })
            );
        }
    };

    useEffect(() => {
        setLoader(true)
        dispatch(
            getportWiseStatus({
                "user": "",
                "userCode": "",
                // "userId": newVal?._id,
                // "orgId": filter?.orgId,
            }, () => {
                setLoader(false);
            })
        );
    }, [])

    useEffect(() => {
        if (!downloadStart) return;
        if (!filter?.toDate && !filter?.fromDate && !filter?.deviceID) {
            return (setDownloadStart(false),
                setErrorsObj({
                    ...errorsObj, "toDate": {
                        type: "custom",
                        message: "To Date is required",
                    }, "fromDate": {
                        type: "custom",
                        message: "From Date is required",
                    }, "deviceID": {
                        type: "custom",
                        message: "Device ID is required",
                    }
                }))
        }
        if (!filter?.fromDate && !filter?.deviceID) {
            return (setDownloadStart(false), setErrorsObj({
                ...errorsObj, "toDate": "", "fromDate": {
                    type: "custom",
                    message: "From Date is required",
                }, "deviceID": {
                    type: "custom",
                    message: "Device ID is required",
                }
            }))
        }
        if (!filter?.toDate && !filter?.deviceID) {
            return (setDownloadStart(false), setErrorsObj({
                ...errorsObj, "toDate": {
                    type: "custom",
                    message: "To Date is required",
                }, "fromDate": "", "deviceID": {
                    type: "custom",
                    message: "Device ID is required",
                }
            }))
        }
        if (!filter?.toDate && !filter?.fromDate) {
            return (setDownloadStart(false), setErrorsObj({
                ...errorsObj, "toDate": {
                    type: "custom",
                    message: "To Date is required",
                }, "fromDate": {
                    type: "custom",
                    message: "From Date is required",
                }, "deviceID": ""
            }))
        }
        if (!filter?.deviceID) {
            return (setDownloadStart(false), setErrorsObj({
                ...errorsObj, "deviceID": {
                    type: "custom",
                    message: "Device ID is required",
                }
            }))
        }

        if (!filter?.toDate) {
            return (setDownloadStart(false), setErrorsObj({
                ...errorsObj, "toDate": {
                    type: "custom",
                    message: "To Date is required",
                }, "fromDate": "", "deviceID": ""
            }))
        }
        if (!filter?.fromDate) {
            return (setDownloadStart(false), setErrorsObj({
                ...errorsObj, "fromDate": {
                    type: "custom",
                    message: "From Date is required",
                }, "toDate": "", "deviceID": ""
            }))
        }
        dispatch(
            getSimpleTrackData({
                "deviceID": filter?.deviceID,
                "fromDate": filter?.fromDate,
                "toDate": filter?.toDate,
                "projection": reportKeysArray

            }, (res) => {
                // console.log('response', res);
                if (!res.success) {
                    showToast(res.message, true)
                }
                if (res?.trackData?.length > 0) {
                    const tableData = res?.trackData.map((ele, outerindex) => {
                        const temp = {...ele};
                        Object.keys(temp).forEach((key, index)=> {
                            if(Array.isArray(temp[key])){
                                const obj = temp[key];
                                temp[key] = JSON.stringify(obj)
                            }
                            if(typeof temp[key] === 'object' && temp[key] !== null && !Array.isArray(temp[key])){
                                const obj = temp[key];
                                temp[key] = JSON.stringify(obj)
                            }
                        });

                        // Check if the object has the "packet_time" key
                        if ('packet_time' in temp) {
                            const packetTime = new Date(temp.packet_time);
                            const ISTTime = new Date(packetTime.getTime() + (5.5 * 60 * 60 * 1000)); // Add 5 hours and 30 minutes to packet time
                            temp.IST = ISTTime.toISOString(); // Add IST time to the object
                        }
                        return temp
                    });
                    const ws = XLSX.utils.json_to_sheet(tableData);
                    const wb = XLSX.utils.book_new();
                    XLSX.utils.book_append_sheet(wb, ws, 'Sheet 1');
                    XLSX.writeFile(wb, `fullTrackStatus.xlsx`);
                }
                setDownloadStart(false);
            })
        );

    }, [downloadStart])

    useEffect(() => {
        if (portWiseStatus?.data?.length > 0) {
            // const ports = [7702, 7723, 7725, 7745, 5590];
            // const TotalDevices = [20, 15, 35, 25, 10];
            // const OfflineDevices = [15, 25, 15, 45, 20];
            // const ActiveDevices = [50, 35, 25, 37, 34];
            const ports = portWiseStatus.data.map((ele) => ele.port);
            const TotalDevices = portWiseStatus.data.map((ele) => ele.totalDevice);
            const OfflineDevices = portWiseStatus.data.map((ele) => ele.offlineDevice);
            const ActiveDevices = portWiseStatus.data.map((ele) => ele.onlineDevice);
            const dataset = [
                {
                    "label": "Total Devices",
                    "data": TotalDevices,
                    backgroundColor: ['#FF7200'],
                    borderWidth: ports?.length <= 3 ? 1 : undefined,
                    barPercentage: ports?.length <= 3 ? 0.5 : undefined,
                },
                {
                    "label": "Offline Devices",
                    "data": OfflineDevices,
                    backgroundColor: ['#FCD202'],
                    borderWidth: ports?.length <= 3 ? 1 : undefined,
                    barPercentage: ports?.length <= 3 ? 0.5 : undefined,
                },
                {
                    "label": "Active Devices",
                    "data": ActiveDevices,
                    backgroundColor: ['#228b22'],
                    borderWidth: ports?.length <= 3 ? 1 : undefined,
                    barPercentage: ports?.length <= 3 ? 0.5 : undefined,
                }
            ]
            setBarData({
                labels: ports,
                datasets: dataset
            })
        }
    }, [portWiseStatus])

    useEffect(() => {
        if (userDetail) {
            if (userDetail?.adminSpecificPermissions) {
                dispatch(getAllOrganisations({ selection: ["name"] }));
            }
        }
    }, [userDetail])

    console.log("filter: ", filter);

    return (
        <>
            <Grid container>
                <Grid item sm={12} xs={12}>
                    <Grid container>
                        <Grid item sm={6} xs={4}>
                            <Typography sx={{ ...textClasses.cardTitle }} data-testid="title">
                                Health
                            </Typography>

                        </Grid>
                        {/* <Grid
                            container
                            item
                            sm={6}
                            xs={6}
                            sx={{ justifyContent: "flex-end" }}
                        >
                            {!open && (
                                <Button
                                    variant="contained"
                                    sx={{
                                        ...buttonClasses?.lynkitOrangeEmpty,
                                        minHeight: "36px",
                                    }}
                                    onClick={() => setOpen(!open)}
                                    startIcon={<FilterAlt />}
                                    data-testid="filtersBtn"
                                >
                                    Filters
                                </Button>
                            )}

                        </Grid> */}
                    </Grid>
                    <Grid container marginTop={"10px"} borderRadius={'10px'} padding={"15px"} style={{ ...dividerClass.all }}>
                        <Grid item sm={12} xs={12}>
                            <Grid container>
                                <Grid item sm={6} xs={4}>
                                    <Typography sx={{ ...textClasses.t14n }} data-testid="title">
                                        TRACK DOWNLOAD
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item sm={12} xs={12} marginTop={"20px"}>
                            <Grid container>
                                <Grid item sm={6} xs={4} paddingRight={"10px"}>
                                    <FormControl
                                        variant="outlined"
                                        size="small"
                                        fullWidth
                                        sx={{
                                            ...selectStylesOverride(isDarkThemeEnabledSelector),
                                            borderRadius: "12px",
                                        }}
                                    >
                                        <InputLabel
                                            htmlFor="outlined-adornment-password"
                                            sx={{ fontSize: "12px" }}
                                        >
                                            Device ID.
                                        </InputLabel>
                                        <OutlinedInput
                                            sx={{ ...inputClasses.filterField, height: "36px" }}
                                            name="DeviceID"
                                            data-testid="searchelement"
                                            value={filter?.deviceID || ""}
                                            onChange={(e) =>
                                                setFilter({
                                                    ...filter,
                                                    deviceID: e.target.value
                                                        .trimStart()
                                                        .replace(/\s{2,}/g, " "),
                                                })
                                            }
                                            // inputValue.replace(/ +/g, ' ').trim();
                                            error={errorsObj["deviceID"] ? true : false}
                                            placeholder="Search Device ID. e.g. 70803739123"
                                            label="Search by name."
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <Search fontSize="small" />
                                                </InputAdornment>
                                            }
                                        />
                                        {errorsObj["deviceID"] && (
                                            <span role="alert" style={{ color: "#FF0101", fontSize: 11, marginTop: "5px" }}>
                                                {errorsObj["deviceID"]?.message}
                                            </span>
                                        )}
                                    </FormControl>
                                </Grid>
                                <Grid item sm={6} xs={4}>
                                    <Grid container>
                                        <Grid item sm={6} xs={4} paddingRight={"5px"} >
                                            <FormControl sx={{
                                                ...selectStylesOverride(isDarkThemeEnabledSelector),
                                                width: '100%',
                                            }}>
                                                <DateTimePicker
                                                    label="From"
                                                    onChange={(date) => {
                                                        if (date) {
                                                            setFilter((prev) => ({ ...prev, fromDate: date }))
                                                        }
                                                        else {
                                                            let temp = { ...filter };
                                                            temp.fromDate = "";
                                                            setFilter(temp);
                                                        }
                                                    }}
                                                    className="customDatePicker"
                                                    sx={{
                                                        ...inputClasses.filterField,
                                                        fontSize: "8px",
                                                        width: "100%",
                                                    }}
                                                    slotProps={{
                                                        textField: { size: "small" },
                                                        // actionBar: {
                                                        //     actions: ["clear"],
                                                        //     actions: ["ok"],
                                                        // },
                                                    }}
                                                // minDate={new Date("2024-01-08T18:30:00.000Z")}
                                                // minDate={filter?.toDate ? new Date(Substractfifteendays(filter?.toDate)) : ""}
                                                // disableFuture
                                                />
                                            </FormControl>
                                            {errorsObj["fromDate"] && (
                                                <span role="alert" style={{ color: "#FF0101", fontSize: 11, marginTop: "5px" }}>
                                                    {errorsObj["fromDate"]?.message}
                                                </span>
                                            )}
                                        </Grid>
                                        <Grid item sm={6} xs={4} paddingLeft={"5px"}>
                                            <FormControl sx={{ ...selectStylesOverride(isDarkThemeEnabledSelector), width: '100%' }}>
                                                <DateTimePicker
                                                    label="To"
                                                    onChange={(date) => {
                                                        if (date) {
                                                            setFilter((prev) => ({ ...prev, toDate: date }))
                                                        }
                                                        else {
                                                            let temp = { ...filter };
                                                            temp.toDate = "";
                                                            setFilter(temp);
                                                        }
                                                    }}
                                                    className="customDatePicker"
                                                    sx={{
                                                        ...inputClasses.filterField,
                                                        fontSize: "8px",
                                                        width: "100%",
                                                    }}
                                                    slotProps={{
                                                        textField: { size: "small" },
                                                        // actionBar: {
                                                        //     actions: ["clear"],
                                                        //     actions: ["ok"],
                                                        // },
                                                    }}
                                                // minDate={new Date("2024-01-08T18:30:00.000Z")}
                                                // minDate={filter?.toDate ? new Date(Substractfifteendays(filter?.toDate)) : ""}
                                                // disableFuture
                                                />
                                            </FormControl>
                                            {errorsObj["toDate"] && (
                                                <span role="alert" style={{ color: "#FF0101", fontSize: 11, marginTop: "5px" }}>
                                                    {errorsObj["toDate"]?.message}
                                                </span>
                                            )}
                                        </Grid>
                                    </Grid>
                                </Grid>

                            </Grid>

                        </Grid>
                        <Grid item sm={12} xs={12} style={{ display: "flex", justifyContent: "flex-end" }}>
                            <Grid item sm={6} xs={4}>
                                <Grid container>
                                    <Grid item sm={6} xs={4} paddingRight={"5px"}>
                                        <FormControl sx={{ ...selectStylesOverride(isDarkThemeEnabledSelector), width: '100%' }}>
                                            <Button
                                                sx={{
                                                    ...buttonClasses.lynkitBlackFill,
                                                    minHeight: "36px",
                                                    // margin: 2,
                                                    width: '100%',
                                                    mt: 2,
                                                    mb: 0,
                                                }}
                                                // disabled={filter.hasOwnProperty('fromDate') && filter.hasOwnProperty('toDate') ? false : true}
                                                onClick={() => { getDownloadSimpleTrack() }}
                                            >
                                                Download Simple Track
                                            </Button>
                                        </FormControl>
                                    </Grid>
                                    <Grid item sm={6} xs={4} paddingLeft={"5px"}>
                                        <FormControl sx={{ ...selectStylesOverride(isDarkThemeEnabledSelector), width: '100%' }}>
                                            <Button
                                                sx={{
                                                    ...buttonClasses.lynkitBlackFill,
                                                    minHeight: "36px",
                                                    // margin: 2,
                                                    width: '100%',
                                                    mt: 2,
                                                    mb: 0,
                                                }}
                                                disabled={downloadStart}
                                                onClick={() => { getDownloadFullTrack() }}
                                            >
                                                Download Full Track &nbsp;{downloadStart ? <Loader /> : ""}
                                            </Button>
                                        </FormControl>
                                    </Grid>

                                </Grid>
                            </Grid>

                        </Grid>
                    </Grid>
                    <Grid container marginTop={"10px"} padding={"15px"}>
                        <Grid item sm={12} xs={12}>
                            <Grid container alignItems={"center"}>
                                <Grid item sm={6} xs={4}>
                                    <Typography sx={{ ...textClasses.t14n }} data-testid="title">
                                        {"Port Wise Device Count".toUpperCase()}
                                    </Typography>
                                </Grid>
                                <Grid item sm={6} xs={4}>
                                    <Grid container>
                                        <Grid item sm={6} xs={4} paddingRight={"5px"}>
                                            <FormControl
                                                variant="outlined"
                                                size="small"
                                                fullWidth
                                                sx={{ ...selectStylesOverride(isDarkThemeEnabledSelector) }}
                                            >
                                                <CustomSelect
                                                    selectStylesOverride={selectStylesOverride(isDarkThemeEnabledSelector)}
                                                    onChange={(e, newVal, reason) => {
                                                        if(reason === "clear"){
                                                            handleSearchOrg(true);
                                                            let tempFilter = filter;
                                                            delete tempFilter.orgId;
                                                            delete tempFilter.userId;
                                                            setFilter(tempFilter);
                                                        }
                                                        handleDropDown(e, newVal, "Org");
                                                    }}
                                                    value={filter?.orgId || null}
                                                    sx={{
                                                        ...inputClasses.filterField,
                                                    }}
                                                    name={"SearchLogs"}
                                                    label="Select Organisation"
                                                    // errors={errorsObj}
                                                    // getOptionLabel={(option) => {
                                                    //     return option?.name;
                                                    // }}
                                                    getOptionLabel={(option) => {
                                                        if (filter?.orgId === undefined) {
                                                          return "";
                                                        }
                                                        if (typeof option === "string") {
                                                          return (
                                                            OrganisationListing?.find((_) => _?._id == filter?.orgId)?.name ||
                                                            ""
                                                          );
                                                        } else {
                                                          return option?.name || "";
                                                        }
                                                      }}
                                                    searchValue={
                                                        searchFields?.org && searchFields?.org !== ""
                                                          ? searchFields?.org
                                                          : null
                                                    }
                                                    onSearch={handleSearchOrg}
                                                    onInputChange={(e) => {
                                                        if (e?.type === "change") {
                                                            if (e?.target?.value === "") handleSearchOrg(true);
                                                            setSearchFields((prev) => ({
                                                            ...prev,
                                                            org: e?.target?.value?.trim(),
                                                            }));
                                                        }
                                                    }}
                                                    filterSelectedOptions
                                                    labelKey="name"
                                                    isOptionEqualToValue={(option, value) =>
                                                        option.name === value.name && option._id === value._id
                                                    }
                                                    options={OrganisationListing || []}
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item sm={6} xs={4} paddingLeft={"5px"}>
                                            <FormControl
                                                variant="outlined"
                                                size="small"
                                                fullWidth
                                                sx={{ ...selectStylesOverride(isDarkThemeEnabledSelector) }}
                                            >
                                                <CustomSelect
                                                    selectStylesOverride={selectStylesOverride}
                                                    onChange={(e, newVal, reason) => {
                                                        if(reason === "clear"){
                                                            handleSearchUsers(true);
                                                            let tempFilter = filter;
                                                            delete tempFilter.userId;
                                                            setFilter(tempFilter);
                                                        }
                                                        handleDropDown(e, newVal, "User");
                                                    }}
                                                    sx={{
                                                        ...inputClasses.filterField,
                                                    }}
                                                    value={filter?.userId || null}
                                                    disabled={!filter?.orgId}
                                                    name={"SearchUser"}
                                                    label="Select User"
                                                    // errors={errorsObj}
                                                    // getOptionLabel={(option) => {
                                                    //     return option?.name;
                                                    // }}
                                                    getOptionLabel={(option) => {
                                                        if (filter?.userId === undefined) {
                                                          return "";
                                                        }
                                                        if (typeof option === "string") {
                                                          return (
                                                            usersListing?.find((_) => _?._id == filter?.userId)?.name ||
                                                            ""
                                                          );
                                                        } else {
                                                          return option?.name || "";
                                                        }
                                                    }}
                                                    searchValue={
                                                        searchFields?.userId && searchFields?.userId !== ""
                                                          ? searchFields?.userId
                                                          : null
                                                    }
                                                    onSearch={handleSearchUsers}
                                                    onInputChange={(e) => {
                                                    if (e?.type === "change") {
                                                        if (e?.target?.value === "") handleSearchUsers(true);
                                                        setSearchFields((prev) => ({
                                                        ...prev,
                                                        userId: e?.target?.value?.trim(),
                                                        }));
                                                    }
                                                    }}
                                                    filterSelectedOptions
                                                    labelKey="name"
                                                    isOptionEqualToValue={(option, value) =>
                                                        option.name === value.name && option._id === value._id
                                                    }
                                                    options={usersListing}
                                                />
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </Grid>

                            </Grid>
                        </Grid>
                        {loader ? (
                            <Grid
                                container
                                direction={"column"}
                                justifyContent={"center"}
                                alignItems="center"
                                item
                                xs={12}
                                sx={{ p: 15 }}
                            >
                                <Grid item>
                                    <img data-testid="loaderImg" src={loaderGif} />
                                </Grid>
                            </Grid>
                        ) :
                            (portWiseStatus?.data?.length > 0 ?
                                <>
                                    <Grid item sm={12} xs={12} marginTop={"20px"}>
                                        <Grid container justifyContent={"center"}>
                                            <Grid item sm={10} xs={10}>
                                                {barData.hasOwnProperty('labels') && barData.hasOwnProperty('datasets')
                                                    ? <BarChart chartData={barData} heading="Port Wise Division" /> :
                                                    "no data"}

                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item sm={12} xs={12} marginTop={"20px"}>
                                        <Grid container justifyContent={"center"}>
                                            <Grid item sm={12} xs={12}>
                                                <HealthTable
                                                    tableColumns={tableColumns}
                                                    data={portWiseStatus?.data ?? []}
                                                    totalRecords={1}
                                                    pagination={filter}
                                                    setPagination={setFilter}
                                                    themeType={themeType}
                                                // handlePaginationChange={handlePaginationChange}
                                                />
                                            </Grid>
                                        </Grid>

                                    </Grid>
                                </> :
                                portWiseStatus?.data === 0 ?
                                    <EmptyPage body={'No matching records found.'} />
                                    :
                                    <EmptyPage body={'Please select Organisation and User to view data. No matching records found.'} />

                            )}


                    </Grid>

                </Grid>
            </Grid>
            <CustomModel
                autoClose={false}
                show={openTrack || false}
                onClose={() => {
                    setOpenTrack(false);
                    setSelectedKeys([]);
                }}
                minWidth={820}
                maxWidth={820}
                pb={3}
                pl={3}
                pr={3}
                maxHeight={"90vh"}
                childrenMaxHeight={"90vh"}
                Title="TRACK DOWNLOAD"
                viewCloseIcon={openTrack}
            >
                <Grid container mb={2}>
                    <Grid
                        item
                        sm={12}
                        flexDirection={"column"}
                        sx={{ width: "100%", height: "auto" }}
                    >
                        <Grid mt={2} item sm={12} maxHeight="60vh" xs={12}
                            style={{
                                // overflowY: "auto",
                                overflow: 'auto',
                                overflowX: "hidden",
                                padding: "10px",
                                paddingBottom: "20px",
                            }}
                        >
                            <Grid Container display={'flex'} >
                                <Grid item md={3} sx={{ display: 'flex', alignItems: 'center' }}>
                                    <Typography sx={{
                                        fontSize: "17px",
                                        fontWeight: "700",
                                        width: "100%",
                                        ...textClasses.themeColor,
                                    }}>Track Status Keys
                                    </Typography>
                                </Grid>
                                <Grid item md={9} display={'flex'} justifyContent={'flex-end'}  >
                                    <Grid item md={3} >
                                        <FormControlLabel
                                            sx={{
                                                color: "rgb(128, 128, 128)",
                                                "& .MuiFormControlLabel-label": { fontSize: "13px" },
                                            }}
                                            label={'Select All'}
                                            control={
                                                <Checkbox
                                                    sx={{
                                                        color: "#ff7200",
                                                        "&.Mui-checked": {
                                                            color: "#ff7200",
                                                        },
                                                    }}
                                                    checked={reportKeysArray && reportKeysArray?.length === selectedKeys?.length}
                                                    onChange={(e) => {
                                                        handleCheckbox(e, 'selectall');
                                                    }}
                                                    name={'Select All'}
                                                />
                                            }
                                        />
                                    </Grid>

                                    <Grid item md={3} >
                                        <FormControlLabel
                                            sx={{
                                                color: "rgb(128, 128, 128)",
                                                "& .MuiFormControlLabel-label": { fontSize: "13px" },
                                            }}
                                            label={'Default Keys'}
                                            control={
                                                <Checkbox
                                                    sx={{
                                                        color: "#ff7200",
                                                        "&.Mui-checked": {
                                                            color: "#ff7200",
                                                        },
                                                    }}
                                                    // disabled={reportKeysArray && reportKeysArray?.length === checkboxes?.length || defaultKeys && defaultKeys?.defaultKeyHeader?.length === 0}
                                                    // checked={defaultCheck}
                                                    onChange={(e) => {
                                                        handleCheckbox(e, 'default');
                                                    }}
                                                    name={'Default Keys'}
                                                />
                                            }
                                        />
                                        {reportDefaultKeysArray && !reportDefaultKeysArray?.length ?
                                            <Grid sx={{
                                                fontSize: "12px",
                                                width: "100%",
                                                color: 'red',
                                                position: 'relative',
                                                bottom: '8px',
                                                ...textClasses.themeColor,
                                            }}>
                                                No Default Keys.
                                            </Grid>
                                            : null}
                                    </Grid>

                                </Grid>
                            </Grid>


                            {reportKeysLoader ? (
                                <Grid
                                    container
                                    item
                                    direction={"column"}
                                    justifyContent={"center"}
                                    alignItems="center"
                                    xs={12}
                                    sx={{ p: 15 }}
                                >
                                    <Grid item={true} container direction={"column"} justifyContent={"center"} alignItems="center" xs={12} sx={{ p: 1 }}>
                                        <ThreeDotsLoader />
                                    </Grid>

                                </Grid>
                            ) : (
                                <>
                                    {reportKeysArray && reportKeysArray?.length === 0 ? (
                                        <EmptyPage body="No Keys found.." />
                                    ) : (
                                        <Grid
                                            container
                                            borderRadius={1}
                                            border={"1px solid #E8E8E8"}
                                            my={1}
                                            p={2}
                                        // sx={{ width: "100%", height: '340px', overflow: 'auto' }}
                                        >
                                            {reportKeysArray && reportKeysArray?.map((item, index) => {
                                                return (
                                                    <Grid key={index} item md={4} >
                                                        <FormControlLabel
                                                            sx={{
                                                                color: "rgb(128, 128, 128)",
                                                                "& .MuiFormControlLabel-label": { fontSize: "13px" },
                                                            }}
                                                            label={item?.field_name}
                                                            control={
                                                                <Checkbox
                                                                    sx={{
                                                                        color: "#ff7200",
                                                                        "&.Mui-checked": {
                                                                            color: "#ff7200",
                                                                        },
                                                                    }}
                                                                    checked={selectedKeys.some(ele => ele.field_name === item?.field_name)}
                                                                    onChange={(e) => {
                                                                        handleCheckbox(e, 'single', item, index);
                                                                    }}
                                                                    name={item?.field_name}
                                                                />
                                                            }
                                                        />
                                                    </Grid>
                                                )
                                            })}


                                        </Grid>
                                    )}
                                </>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
                <Grid
                    container
                    item
                    direction={"row"}
                    sm={12}
                    xs={12}
                    sx={{ ml: 1, pb: 3 }}
                >
                    <>
                        <Button
                            variant="contained"
                            size="small"
                            sx={{
                                ...buttonClasses.lynkitBlackFill,
                                minHeight: "36px",
                                marginRight: '12px',
                                minWidth: "110px",
                            }}
                            onClick={() => {
                                setOpenTrack(false);
                                setSelectedKeys([]);
                            }}
                        >
                            Cancel
                        </Button>

                        <Button
                            variant="contained"
                            size="small"
                            sx={{
                                ...buttonClasses.lynkitOrangeFill,
                                ml: 0,
                                fontSize: ".7rem",
                                border: "none",
                                height: "37px",
                                minWidth: "110px",
                                marginRight: "17px",
                            }}
                            disabled={downloadSimpleStart}
                            onClick={() => submitReport()}
                        >
                            Submit &nbsp;{downloadSimpleStart ? <Loader /> : ""}
                        </Button>

                        {/* <Button
                            variant="contained"
                            size="small"
                            sx={{
                                ...buttonClasses.small,
                                minHeight: "36px",
                                borderColor: themeType.default.themeOrange,
                                color: buttonClasses.lynkitOrangeEmpty,
                                minWidth: "110px",
                            }}
                            onClick={() => saveDefaultKeys()}
                        >
                            Save Default Keys
                        </Button> */}



                    </>
                </Grid>

            </CustomModel>
        </>
    )
}
