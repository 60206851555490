import { CHECK_LOGIN, CURRENT_USER_DETAILS } from "../types";

const intialState = {
  userId: "",
  currentUserDetail: {},
  generateOtpLoading: false,
  verifyOtpLoading: false,
  registerUserLoading: false,
};

const AuthReducer = (state = intialState, { type, payload }) => {
  switch (type) {
    case CHECK_LOGIN:
      return { ...state, userId: payload };
    case "FLUSH_USER_ID":
      return { ...state, userId: "" };
    case CURRENT_USER_DETAILS:
      return { ...state, currentUserDetail: payload.rows };
    case "FLUSH_CURRENT_USER":
      return { ...state, currentUserDetail: {} };
    case "GENERATE_OTP_LOADING":
      return { ...state, generateOtpLoading: payload };
    case "VERIFY_OTP_LOADING":
      return { ...state, verifyOtpLoading: payload };
    case "REGISTER_USER_LOADING":
      return { ...state, registerUserLoading: payload };
    default:
      return state;
  }
};

export default AuthReducer;
