import { DRIVER_LISTING, ULIP_DATA_LIST } from "../types";

const INIT_STATE = {
    driverList: [],
    ulipData: []
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case DRIVER_LISTING:
            return {
                ...state,
                driverList: action?.payload?.rows,
            };
        case ULIP_DATA_LIST:
            return {
                ...state,
                ulipData: action?.payload?.rows,
            };

        default:
            return state;
    }
};
//chnage
