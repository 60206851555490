import { THEME_DARK , THEME_LIGHT } from "../types";

export const darkTheme = () => {
    return (dispatch) => {
        dispatch({ type : THEME_DARK });
        localStorage.setItem("theme", "light")
    }
}

export const lightTheme = () => {
    return (dispatch) => {
        dispatch({ type : THEME_LIGHT });
        localStorage.setItem("theme", "dark")
    }
}

export const updateTheme = (payload) => {
    return (dispatch) => {
      dispatch({ type: "UPDATE_THEME", payload: payload });
    };
  };