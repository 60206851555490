import { Box, FormControl } from "@mui/material";
import { TimeField } from "@mui/x-date-pickers";
import React from "react";

export const CustomTimeField = ({
  name,
  required = false,
  value,
  onChange,
  errors = {},
  placeholder,
  disabled,
  label,
  inputClasses,
  type,
  helperText,
  selectStylesOverride,
  maxwidth = "unset",
}) => {
  return (
    <Box display="flex" flexDirection="column">
      <FormControl sx={{ ...selectStylesOverride, width: "100%" }}>
        {/* <InputLabel sx={{ fontSize: "12px" }}>{label}{required && "*"}</InputLabel> */}
        <TimeField
          size="small"
          value={value}
          onChange={onChange}
          helperText={helperText}
          label={label}
          sx={{
            ...inputClasses?.textfield,
            ...selectStylesOverride,
            // "&Mui-focused": {
            //   transform: "translate(14px, -3px) scale(0.75)",
            // },
            height: "36px",
          }}
          inputProps={{
            style: {
              minWidth: "50px",
              maxWidth: maxwidth
            },
          }}
          InputLabelProps={{
            sx: { fontSize: "13px", paddingTop: "1px" }, // Adjust the font size here
          }}
          ampm={false}
          type={type}
          disabled={disabled}
          error={errors[name] ? true : false}
          placeholder={placeholder}
        />
      </FormControl>
      {errors[name] && (
        <span role="alert" style={{ color: "#FF0101", fontSize: 10 }}>
          {errors[name]?.message}
        </span>
      )}
    </Box>
  );
};
