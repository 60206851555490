import {
  GEOFENCES_LIST,
  VIEW_GEOFENCE,
  VIEW_GEOFENCE_DETAIL,
  GEOFENCES_LIST_MAP,
  GEO_STATUS_COUNT,
  FETCH_GEO_FLAG,
  FLUSH_GEOFENCE_DETAILS
} from "../types";

const INIT_STATE = {
  geofenceListing: [],
  geofenceDetails: [],
  viewGeofenceForDetail: [],
  geofenceListingForMap: [],
  geofenceStatusCount: null,
  geoListFlag: true,
  filtersFromStore: null,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GEOFENCES_LIST:
      return {
        ...state,
        geofenceListing: action.payload.rows,
        totalRows: action.payload.count,
        geofenceDetails: [],
      };
    case "FLUSH_GEOFENCE_LIST":
      return {
        ...state,
        geofenceListing: [],
        totalRows: 0,
        geofenceDetails: [],
      };
    case VIEW_GEOFENCE:
      return {
        ...state,
        geofenceDetails: action.payload.rows,
        geofenceListing: [],
      };
    case VIEW_GEOFENCE_DETAIL:
      return { ...state, viewGeofenceForDetail: action.payload.rows };
    case GEOFENCES_LIST_MAP:
      return {
        ...state,
        geofenceListingForMap: action.payload.rows,
        totalRows: action.payload.count,
      };
    case "FLUSH_GEOFENCE_MAP_LIST":
      return {
        ...state,
        geofenceListingForMap: [],
        totalRows: 0,
      };
    case GEO_STATUS_COUNT:
      return { ...state, geofenceStatusCount: action.payload };
      case FLUSH_GEOFENCE_DETAILS:
        return { ...state, geofenceDetails: []};
    case FETCH_GEO_FLAG:
      return { ...state, geoListFlag: action.payload };
    case "GEO_TEMP_FILTER":
      return { ...state, filtersFromStore: action.payload };
    default:
      return state;
  }
};


export const setGeoTempFilter = (data) => ({
  type: "GEO_TEMP_FILTER", payload: data
});
