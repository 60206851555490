import {
  Box,
  Breadcrumbs,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Typography,
  capitalize,
} from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { CustomInput } from "../../../../components/FormUI";
import { useForm, useWatch } from "react-hook-form";
import GeofenceSection from "../../Users/components/GeofenceSection";
import {
  addAccountLinkageDetails,
  assignDeassignRoutes,
  getAllDeviceCategoryNames,
  getAllDevicePermissions,
  getAllDevices,
  getAllGeofences,
  getAllRoutes,
  getUserAccountDetails,
  getUserAssignedGeofences,
  getUserAssignedGeofencesAccountLinkage,
  getUserAssignedRoutes,
  getUserAssignedRoutesAccountLinkage,
  updateAccountLinkageDetails,
} from "../../../../redux/actions";
import DeviceSection from "../../Users/components/DeviceSection";
import RouteSection from "../../Users/components/RouteSection";
import { flushAllDevices } from "../../../../redux/reducers/DevicesReducers";
import { selectStylesOverride } from "../../../../utils/util";
import { flushUserAccountDetails } from "../../../../redux/reducers/Settings";
import { Link } from "react-router-dom/cjs/react-router-dom";
import { geoAxios } from "../../../../services/Api";
import { showToast } from "../../../../services/functions";
import history from "../../../../services/history";
import { useLocation, useParams } from "react-router";
import _ from "lodash";

const AddAccount = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const [selectedDeviceCategory, setSelectedDeviceCategory] =
    React.useState(null);
  const [accountIdEntered, setAccountIdEntered] = React.useState("");
  const [geofenceRadio, setGeofenceRadio] = React.useState(null);
  const [userAccountDetailsState, setUserAccountDetailsState] =
    React.useState(null);
  const [routeRadio, setRouteRadio] = React.useState(null);
  const [deviceRadio, setDeviceRadio] = React.useState(null);
  const [selectedGeofence, setSelectedGeofence] = React.useState([]);
  const [showAllGeo, setShowAllGeo] = React.useState(false);
  const [selectedRoute, setSelectedRoute] = React.useState([]);
  const [showAllRoute, setShowAllRoute] = React.useState(false);
  const [selectedDevice, setSelectedDevice] = React.useState([]);
  const [showAllDevice, setShowAllDevice] = React.useState(false);
  const [orgUsersShare, setOrgUsersShare] = React.useState(false);
  const [roleUsersShare, setRoleUsersShare] = React.useState(false);
  const [deviceSpecificPermissions, setDeviceSpecificPermissions] =
    React.useState([]);
  const [initialSelectedGeofence, setInitialSelectedGeofence] = React.useState(
    []
  ); // --- only for edit
  const [initialSelectedDevice, setInitialSelectedDevice] = React.useState([]); // --- only for edit
  const [initialSelectedRoute, setInitialSelectedRoute] = React.useState([]); // --- only for edit
  const [searchFields, setSearchFields] = React.useState({
    geofence: null,
    routes: null,
    deviceType: null,
    devices: null,
  });

  let geoSize = selectedGeofence?.length;
  let routeSize = selectedRoute?.length;
  let deviceSize = selectedDevice?.length;
  let deviceFilter = { filter: {} };

  const { currentUserDetail } = useSelector((state) => state.auth);
  const geofencesListing = useSelector(
    (state) => state.geofencelist.geofenceListing
  );
  const routesListing = useSelector((state) => state.routes.allRoutes);
  const deviceTypeList = useSelector(
    (state) => state.deviceslist.allDeviceCategoryNames
  );
  const devicePermissions = useSelector(
    (state) => state.deviceslist.alldevicePermissions
  );
  const devices = useSelector((state) => state.deviceslist.allDevices);
  const { userAccountDetails } = useSelector((state) => state.settingsReducer);
  const isDarkThemeEnabledSelector = useSelector(
    (state) => state.theme.themeDark
  );
  const themeType = isDarkThemeEnabledSelector
    ? require("../../../../static/styles/darktheme")
    : require("../../../../static/styles/theme");

  const { buttonClasses, textClasses } = themeType;

  const {
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm();

  const geofenceSelected = useWatch({
    control,
    name: "geofence",
  });
  const geoRadioWatch = useWatch({
    control,
    name: "radio-buttons-group-geo",
  });

  const deviceTypeSelected = useWatch({
    control,
    name: "deviceType",
  });
  const deviceSelected = useWatch({
    control,
    name: "device",
  });
  const deviceRadioWatch = useWatch({
    control,
    name: "radio-buttons-group-device",
  });

  const routesSelected = useWatch({
    control,
    name: "routes",
  });
  const routeRadioWatch = useWatch({
    control,
    name: "radio-buttons-group-route",
  });

  // -------handlers --------
  const handleRemoveSelection = (index, item) => {
    if (item === "geofence") {
      let temp = [...selectedGeofence];
      temp.splice(index, 1);
      setValue("geofence", temp);
      // setSelectedGeofence(temp);
    } else if (item === "route") {
      let temp = [...selectedRoute];
      temp.splice(index, 1);
      setValue("routes", temp);
      // setSelectedRoute(temp);
    } else if (item === "device") {
      let temp = [...selectedDevice];
      temp.splice(index, 1);
      setValue("device", temp);
      // setSelectedDevice(temp);
    }
  };

  const handleSearchDeviceType = (reset = false) => {
    if (searchFields?.deviceType || searchFields?.deviceType == "") {
      if (reset === true) {
        dispatch(getAllDeviceCategoryNames({ selection: ["category"] }));
      } else {
        dispatch(
          getAllDeviceCategoryNames({
            selection: ["category"],
            searchValue: searchFields?.deviceType,
          })
        );
      }
    }
  };

  const handleSearchDevice = (reset = false) => {
    if (searchFields?.devices || searchFields?.devices == "") {
      if (reset === true) {
        dispatch(
          getAllDevices({
            filter: {
              deviceType: deviceTypeSelected,
            },
          })
        );
      } else {
        dispatch(
          getAllDevices({
            q: searchFields?.devices,
          })
        );
      }
    }
  };

  const handleSearchGeofence = (reset = false) => {
    if (
      (currentUserDetail && searchFields?.geofence) ||
      searchFields?.geofence == ""
    ) {
      if (reset === true) {
        dispatch(
          getAllGeofences({
            selection: ["name"],
            filter: { orgId: currentUserDetail?.orgId },
          })
        );
      } else {
        dispatch(
          getAllGeofences({
            selection: ["name"],
            searchValue: searchFields?.geofence,
            filter: {
              orgId: currentUserDetail?.orgId,
            },
          })
        );
      }
    }
  };

  const handleSearchRoute = (reset = false) => {
    if (
      (currentUserDetail && searchFields?.routes) ||
      searchFields?.routes == ""
    ) {
      if (reset === true) {
        dispatch(
          getAllRoutes({
            selection: ["routeName"],
            filter: { orgId: currentUserDetail?.orgId },
          })
        );
      } else {
        dispatch(
          getAllRoutes({
            selection: ["routeName"],
            searchValue: searchFields?.routes,
            filter: {
              orgId: currentUserDetail?.orgId,
            },
          })
        );
      }
    }
  };

  const handleAddDevice = (e) => {
    e?.preventDefault();
    e?.stopPropagation();
    if (deviceSelected) {
      const tempSelectedDevices = [...selectedDevice];
      tempSelectedDevices?.push({
        id: deviceSelected,
        permissions: {
          deviceSpecificPermission: deviceSpecificPermissions,
        },
      });
      setSelectedDevice(tempSelectedDevices);
      const { deviceSpecificPermission } = devicePermissions;
      setDeviceSpecificPermissions(deviceSpecificPermission);
      setValue("device", undefined);
      dispatch(flushAllDevices());
    }
  };

  const handleCheckboxChange = (e, key) => {
    setDeviceSpecificPermissions((prevData) => {
      if (prevData[key]) {
        return {
          ...prevData,
          [key]: { ...prevData[key], value: e?.target?.value ? true : false },
        };
      } else {
        return prevData;
      }
    });
  };

  const makeAPICall = async (url, payload) => {
    // TODO: handle deviceAxios for sharing devices
    return geoAxios
      .post(url, payload)
      .then((response) => {
        if (response?.data?.success === false) {
          throw new Error(`API call failed: ${response?.data?.message}`);
        }
        return response?.data;
      })
      .catch((error) => {
        throw new Error(`API call failed: ${error?.response?.data?.message}`);
      });
  };

  const handleVerifyBtn = () => {
    if (accountIdEntered && accountIdEntered !== "") {
      dispatch(
        getUserAccountDetails(
          {
            accountId: accountIdEntered,
            // accountId: "hmd9fq",
          },
          (res) => {}
        )
      );
    }
  };

  const handleAddLinkage = () => {
    if (userAccountDetailsState) {
      let payload = {
        userId: userAccountDetailsState?.userId,
        userName: userAccountDetailsState?.name,
        orgId: userAccountDetailsState?.orgId,
        orgName: userAccountDetailsState?.orgName,
        accountId: userAccountDetailsState?.userCode,
        isAssignOrganization: orgUsersShare,
        isAssignUserRole: roleUsersShare,
        userRole: userAccountDetailsState?.userRole,
      };

      const dispatchers = [];

      if (geofenceRadio && geofenceRadio !== null) {
        payload.assignGeofence =
          geofenceRadio?.toLowerCase() === "select"
            ? "few"
            : geofenceRadio?.toLowerCase();
        if (geofenceRadio === "select") {
          payload.geofencesAssigned = selectedGeofence;
        }
      }
      if (routeRadio && routeRadio !== null) {
        payload.assignRoutes =
          routeRadio?.toLowerCase() === "select"
            ? "few"
            : routeRadio?.toLowerCase();
        if (routeRadio === "select") {
          payload.routesAssigned = selectedRoute;
        }
      }

      dispatch(
        addAccountLinkageDetails({ ...payload }, (res) => {
          if (res && res?.success) {
            history.push("/dashboard/settings/account-linkage");
          }
        })
      );

      //==============================================
      // Promise.allSettled(dispatchers)
      //   ?.then((results) => {
      //     const allFulfilled = results.every(
      //       (result) => result.status === "fulfilled"
      //     );

      //     if (allFulfilled) {
      //       console.log(
      //         "All API calls succeeded:",
      //         results.map((result) => result.value)
      //       );
      //       // operation
      //       dispatch(
      //         addAccountLinkageDetails({ ...payload }, (res) => {
      //           if (res && res?.success) {
      //             history.push("/dashboard/settings/account-linkage");
      //           }
      //         })
      //       );
      //     } else {
      //       console.error("One or more API calls failed:", results);
      //       // Show error message to the user
      //       const errorMessages = results
      //         .filter((result) => result.status === "rejected")
      //         .map((result) => result.reason.message);
      //       showToast(errorMessages.join("; "), true);
      //     }
      //   })
      //   .catch((error) => {
      //     console.error("Unexpected error:", error);
      //     showToast("An unexpected error occurred.", true);
      //   });
    }
  };

  const handleUpdateLinkage = () => {
    if (userAccountDetailsState) {
      let updatePayload = {
        _id: userAccountDetailsState?._id,
        userRole: userAccountDetailsState?.userRole,
        isAssignOrganization: orgUsersShare,
        isAssignUserRole: roleUsersShare,
      };

      const dispatchers = [];

      const updatedGeoRadio =
        geofenceRadio && geofenceRadio === "select"
          ? "few"
          : geofenceRadio?.toLowerCase();

      const updatedRouteRadio =
        routeRadio && routeRadio === "select"
          ? "few"
          : routeRadio?.toLowerCase();

      if (
        geofenceRadio &&
        (updatedGeoRadio !== userAccountDetailsState?.assignGeofence ||
          !_?.isEqual(initialSelectedGeofence, selectedGeofence))
      ) {
        updatePayload.assignGeofence =
          geofenceRadio === "select" ? "few" : geofenceRadio?.toLowerCase();

        if (geofenceRadio === "select") {
          updatePayload.geofencesAssigned = selectedGeofence;
        }
      }

      if (
        routeRadio &&
        (updatedRouteRadio !== userAccountDetailsState?.assignRoutes ||
          !_?.isEqual(initialSelectedRoute, selectedRoute))
      ) {
        updatePayload.assignRoutes =
          routeRadio === "select" ? "few" : routeRadio?.toLowerCase();
        if (routeRadio === "select") {
          updatePayload.routesAssigned = selectedRoute;
        }
      }

      dispatch(
        updateAccountLinkageDetails({ ...updatePayload }, (res) => {
          if (res && res?.success) {
            history.push("/dashboard/settings/account-linkage");
          }
        })
      );

      //====================================================
      // Promise.allSettled(dispatchers)
      //   ?.then((results) => {
      //     const allFulfilled = results.every(
      //       (result) => result.status === "fulfilled"
      //     );

      //     if (allFulfilled) {
      //       dispatch(
      //         updateAccountLinkageDetails({ ...updatePayload }, (res) => {
      //           if (res && res?.success) {
      //             history.push("/dashboard/settings/account-linkage");
      //           }
      //         })
      //       );
      //     } else {
      //       console.error("One or more API calls failed:", results);
      //       // Show error message to the user
      //       const errorMessages = results
      //         .filter((result) => result.status === "rejected")
      //         .map((result) => result.reason.message);
      //       showToast(errorMessages.join("; "), true);
      //     }
      //   })
      //   .catch((error) => {
      //     console.error("Unexpected error:", error);
      //     showToast("An unexpected error occurred.", true);
      //   });
    }
  };

  const makeAPICallForEdit = (url, payload, type, callback) => {
    if (type !== "device") {
      geoAxios
        .post(url, payload)
        .then((response) => {
          if (response?.data?.success === false) {
            console.log(`API call failed: ${response?.data?.message}`);
          }
          callback && callback(response?.data);
        })
        .catch((error) => {
          callback && callback(error?.response?.data);
          console.log(`API call failed: ${error?.response?.data?.message}`);
        });
    }
  };

  const handleCallModuleAPIsForEdit = (details) => {
    if (details && details?.orgId) {
      const paylaodFilter = {
        page: 1,
        limit: 100,
      };
      // let deviceRequest = {
      //   ...deviceFilter,
      //   filter: {
      //     user: userDetail?._id,
      //   }
      // }

      // if (details?.assignGeofence && details?.assignGeofence === "all") {
      //   makeAPICallForEdit(
      //     "/listGeofence",
      //     {
      //       ...paylaodFilter,
      //       selection: ["name"],
      //       filter: { orgId: details?.orgId },
      //     },
      //     "geo",
      //     (res) => {
      //       if (res && res?.data && res?.data?.length > 0) {
      //         setValue("geofence", undefined);
      //         setSelectedGeofence([]);
      //         let arr =
      //           res?.data &&
      //           res?.data?.map((item) => ({
      //             id: item._id,
      //             name: item?.name,
      //           }));
      //         setValue("geofence", arr);
      //         setSelectedGeofence(arr);
      //         setInitialSelectedGeofence(arr);
      //       }
      //     }
      //   );
      // }

      // if (details?.assignRoutes && details?.assignRoutes === "all") {
      //   makeAPICallForEdit(
      //     "/listRoutes",
      //     {
      //       ...paylaodFilter,
      //       selection: ["name"],
      //       filter: { orgId: details?.orgId },
      //     },
      //     "geo",
      //     (res) => {
      //       if (
      //         res &&
      //         res?.length > 0 &&
      //         details?.assignRoutes &&
      //         details?.assignRoutes === "all"
      //       ) {
      //         setValue("routes", undefined);
      //         setSelectedRoute([]);
      //         let arr =
      //           res &&
      //           res?.map((item) => ({
      //             id: item._id,
      //             name: item?.routeName,
      //           }));
      //         setSelectedRoute(arr);
      //         setInitialSelectedRoute(arr);
      //         setValue("routes", arr);
      //       }
      //     }
      //   );
      // }
      //=====================================================

      // dispatch(
      //   getAllRoutes(
      //     {
      //       ...paylaodFilter,
      //       selection: ["routeName"],
      //       filter: { orgId: details?.orgId },
      //     },
      //     (res) => {
      //       if (
      //         res &&
      //         res?.length > 0 &&
      //         details?.assignRoutes &&
      //         details?.assignRoutes === "all"
      //       ) {
      //         setValue("routes", undefined);
      //         setSelectedRoute([]);
      //         let arr =
      //           res &&
      //           res?.map((item) => ({
      //             id: item._id,
      //             name: item?.routeName,
      //           }));
      //         setSelectedRoute(arr);
      //         setInitialSelectedRoute(arr);
      //         setValue("routes", arr);
      //       }
      //     }
      //   )
      // );
      // dispatch(getAllDeviceCategoryNames());
      // dispatch(
      //   getAllDevices(
      //     {
      //       ...deviceRequest,
      //     },
      //     (res) => {
      //       if (
      //         res &&
      //         res?.length > 0 &&
      //         details?.assignDevice &&
      //         details?.assignDevice === "all"
      //       ) {
      //         let arr = [];
      //         res?.map((item) => {
      //           arr.push({
      //             clientId: item?.client_id,
      //             permissions: {
      //               deviceSpecificPermission: {},
      //             },
      //           });
      //         });
      //         setSelectedDevice(arr);
      //         setInitialSelectedDevice(arr);
      //       }
      //     }
      //   )
      // );
    }
  };

  // -----effects ---------
  React.useEffect(() => {
    setAccountIdEntered("");
    setGeofenceRadio(null);
    setSelectedGeofence([]);
    setRouteRadio(null);
    setSelectedRoute([]);
    setRoleUsersShare(false);
    setOrgUsersShare(false);

    return () => {
      dispatch(flushUserAccountDetails());
      setUserAccountDetailsState(null);
    };
  }, []);

  React.useEffect(() => {
    if (
      location &&
      location?.pathname?.includes("/account-linkage/edit") &&
      location?.state &&
      location?.state?.data &&
      Object.keys(location?.state?.data)?.length > 0
    ) {
      let userDetail = location?.state?.data;
      setUserAccountDetailsState(userDetail);

      if (userDetail?.accountId) {
        userDetail.userCode = userDetail?.accountId;
      }
      if (userDetail?.userName) {
        userDetail.name = userDetail?.userName;
      }
      setInitialSelectedGeofence([]);
      setAccountIdEntered(userDetail.userCode);
      setTimeout(() => {
        setValue(
          "radio-buttons-group-geo",
          userDetail?.assignGeofence === "few"
            ? "select"
            : userDetail?.assignGeofence === "all"
            ? "All"
            : userDetail?.assignGeofence === "none"
            ? "None"
            : undefined
        );
        setGeofenceRadio(
          userDetail?.assignGeofence === "few"
            ? "select"
            : userDetail?.assignGeofence === "all"
            ? "All"
            : userDetail?.assignGeofence === "none"
            ? "None"
            : undefined
        );
        if (userDetail?.assignGeofence === "few" || userDetail?.assignGeofence === "all") {
          dispatch(
            getUserAssignedGeofencesAccountLinkage(
              {
                // userId: userDetail?.userId,
                // orgId: userDetail?.addedBy?.orgId,
                _id: userDetail?._id
              },
              (res) => {
                setValue("geofence", res);
                // setSelectedGeofence(res);
                setInitialSelectedGeofence(res);
              }
            )
          );
        }

        setValue(
          "radio-buttons-group-route",
          userDetail?.assignRoutes === "few"
            ? "select"
            : userDetail?.assignRoutes === "all"
            ? "All"
            : userDetail?.assignRoutes === "none"
            ? "None"
            : undefined
        );
        setRouteRadio(
          userDetail?.assignRoutes === "few"
            ? "select"
            : userDetail?.assignRoutes === "all"
            ? "All"
            : userDetail?.assignRoutes === "none"
            ? "None"
            : undefined
        );
        if (userDetail?.assignRoutes === "few" || userDetail?.assignRoutes === "all") {
          dispatch(
            getUserAssignedRoutesAccountLinkage(
              {
                // userId: userDetail?.userId,
                // orgId: userDetail?.addedBy?.orgId,
                _id: userDetail?._id
              },
              (res) => {
                setValue("routes", res);
                // setSelectedRoute(res);
                setInitialSelectedRoute(res);
              }
            )
          );
        }

        handleCallModuleAPIsForEdit(userDetail);
      }, 500);
    }
  }, [location]);

  React.useEffect(() => {
    if (geofenceSelected) {
      setSelectedGeofence(geofenceSelected);
    }
  }, [geofenceSelected]);

  React.useEffect(() => {
    if (routesSelected) {
      setSelectedRoute(routesSelected);
    }
  }, [routesSelected]);

  React.useEffect(() => {
    if (deviceRadioWatch === "select") {
      setSelectedDevice([]);
      setDeviceRadio(deviceRadioWatch);
    } else if (deviceRadioWatch === "None") {
      setSelectedDevice([]);
      setDeviceRadio(deviceRadioWatch);
    }
  }, [deviceRadioWatch]);

  React.useEffect(() => {
    if (geoRadioWatch === "select") {
      setValue("geofence", undefined);
      setSelectedGeofence([]);
      setGeofenceRadio(geoRadioWatch);
    } else if (geoRadioWatch === "All") {
      setValue("geofence", undefined);
      setSelectedGeofence([]);
      let arr =
        geofencesListing &&
        geofencesListing?.map((item) => ({ id: item._id, name: item?.name }));
      setValue("geofence", arr);
      setSelectedGeofence(arr);
      setGeofenceRadio(geoRadioWatch);
    } else if (geoRadioWatch === "None") {
      setValue("geofence", undefined);
      setSelectedGeofence([]);
      setGeofenceRadio(geoRadioWatch);
    }
  }, [geoRadioWatch]);

  React.useEffect(() => {
    if (routeRadioWatch === "select") {
      setValue("routes", undefined);
      setSelectedRoute([]);
      setRouteRadio(routeRadioWatch);
    } else if (routeRadioWatch === "All") {
      setValue("routes", undefined);
      setSelectedRoute([]);
      let arr =
        routesListing &&
        routesListing?.map((item) => ({ id: item._id, name: item?.routeName }));
      setSelectedRoute(arr);
      setValue("routes", arr);
      setRouteRadio(routeRadioWatch);
    } else if (routeRadioWatch === "None") {
      setValue("routes", undefined);
      setSelectedRoute([]);
      setRouteRadio(routeRadioWatch);
    }
  }, [routeRadioWatch]);

  React.useEffect(() => {
    if (deviceTypeSelected) {
      setSelectedDeviceCategory(deviceTypeSelected);
      dispatch(
        getAllDevices({
          ...deviceFilter,
          filter: { deviceType: deviceTypeSelected },
          selection: ["client_id"],
        })
      );
      dispatch(getAllDevicePermissions());
    }
  }, [deviceTypeSelected]);

  React.useEffect(() => {
    if (currentUserDetail && currentUserDetail?.orgId) {
      const organizationSelected = currentUserDetail?.orgId;
      const paylaodFilter = {
        page: 1,
        limit: 100,
      };
      dispatch(
        getAllGeofences(
          {
            ...paylaodFilter,
            selection: ["name"],
            filter: { orgId: organizationSelected },
          },
          () => {}
        )
      );
      dispatch(getAllDeviceCategoryNames({ selection: ["category"] }));
      dispatch(
        getAllRoutes(
          {
            ...paylaodFilter,
            selection: ["routeName"],
            filter: { orgId: organizationSelected },
          },
          () => {}
        )
      );
    }
  }, [currentUserDetail]);

  React.useEffect(() => {
    if (devicePermissions && devicePermissions?.deviceSpecificPermission) {
      const { deviceSpecificPermission } = devicePermissions;
      setDeviceSpecificPermissions(deviceSpecificPermission);
    }
  }, [devicePermissions]);

  React.useEffect(() => {
    if (userAccountDetails && Object.keys(userAccountDetails)?.length > 0) {
      setUserAccountDetailsState(userAccountDetails);
    }
  }, [userAccountDetails]);

  return (
    <>
      <Grid container>
        {/* top row - page title*/}
        <Grid item xs={12} display="flex" flexDirection="column">
          <Typography
            sx={{ ...textClasses.cardTitle }}
            data-testid="page-title"
          >
            Link Account
          </Typography>
          <Breadcrumbs data-testid="breadcrumbs">
            <Typography
              sx={{
                ...textClasses.normalText,
                fontSize: "12px",
                color: themeType.default.themeOrange,
              }}
            >
              <Link
                style={{
                  color: themeType.default.themeOrange,
                  textDecoration: "none",
                }}
                data-testid="link-to-go-back"
                to={{ pathname: "/dashboard/settings/account-linkage" }}
              >
                Account Linkage
              </Link>
            </Typography>

            <Typography sx={{ ...textClasses.normalText, fontSize: "12px" }}>
              Link Account
            </Typography>
          </Breadcrumbs>
        </Grid>

        <Grid
          item
          container
          xs={12}
          p={2}
          my={2}
          borderRadius="10px"
          border="1px solid #E0E0E0"
        >
          <Grid item xs={12}>
            <Typography sx={{ ...textClasses.normalText }}>
              Firstly, need to enter and verify the account ID of user and then
              proceed for account linkage.{" "}
            </Typography>
          </Grid>
          <Grid item xs={12} display="flex" gap={3} mt={2}>
            <CustomInput
              label="Account ID"
              placeholder="Enter account ID"
              maxWidth="300px"
              selectStylesOverride={selectStylesOverride(
                isDarkThemeEnabledSelector
              )}
              onChange={(e) => {
                setAccountIdEntered(e?.target?.value);
              }}
              value={accountIdEntered || ""}
              disabled={window.location.pathname?.includes(
                "/account-linkage/edit"
              )}
            />
            <Button
              sx={{ ...buttonClasses.lynkitOrangeFill, width: "max-content" }}
              onClick={handleVerifyBtn}
              disabled={window.location.pathname?.includes(
                "/account-linkage/edit"
              )}
            >
              Verify User
            </Button>
            {!window.location.pathname?.includes("/account-linkage/edit") &&
              userAccountDetailsState &&
              Object.keys(userAccountDetailsState)?.length > 0 && (
                <Button
                  sx={{
                    textDecoration: "underline",
                    textTransform: "capitalize",
                  }}
                  onClick={() => {
                    setAccountIdEntered("");
                    dispatch(flushUserAccountDetails());
                    setUserAccountDetailsState(null);
                    setGeofenceRadio(false);
                    setSelectedGeofence([]);
                    setShowAllGeo(false);
                  }}
                >
                  Reset
                </Button>
              )}
          </Grid>

          {userAccountDetailsState &&
            Object.keys(userAccountDetailsState)?.length > 0 && (
              <Box width="100%" position="relative" data-testid="details-area">
                {/* --------- User Details ------- */}
                <Grid
                  xs={12}
                  display="flex"
                  alignItems="center"
                  gap={3}
                  flexWrap="wrap"
                  mt={2}
                >
                  <Box className="d-flex-row" gap={1}>
                    <Typography variant="body2" sx={{ fontWeight: 700 }}>
                      Name:
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{ textTransform: "capitalize" }}
                    >
                      {userAccountDetailsState?.name ?? "-"}
                    </Typography>
                  </Box>
                  <Box className="d-flex-row" gap={1}>
                    <Typography variant="body2" sx={{ fontWeight: 700 }}>
                      User Role:
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{ textTransform: "capitalize" }}
                    >
                      {userAccountDetailsState?.userRole?.name ?? "-"}
                    </Typography>
                  </Box>
                  <Box className="d-flex-row" gap={1}>
                    <Typography variant="body2" sx={{ fontWeight: 700 }}>
                      Organization Name:
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{ textTransform: "capitalize" }}
                    >
                      {userAccountDetailsState?.orgName ?? "-"}
                    </Typography>
                  </Box>
                </Grid>

                <Grid mt={2} item xs={12}>
                  <GeofenceSection
                    handleRemoveSelection={handleRemoveSelection}
                    geofencesListing={geofencesListing}
                    selectedGeofence={selectedGeofence}
                    showAllGeo={showAllGeo}
                    setShowAllGeo={setShowAllGeo}
                    geoSize={geoSize}
                    searchFields={searchFields}
                    setSearchFields={setSearchFields}
                    handleSearchGeofence={handleSearchGeofence}
                    geofenceRadio={geofenceRadio}
                    setGeofenceRadio={setGeofenceRadio}
                    control={control}
                    errors={errors}
                    handleSubmit={handleSubmit}
                    onSubmit={() => {}}
                    setValue={setValue}
                    title="Share Geofence(s)"
                    defaultExpanded={true}
                  />

                  <RouteSection
                    handleRemoveSelection={handleRemoveSelection}
                    routesListing={routesListing}
                    selectedRoute={selectedRoute}
                    showAllRoute={showAllRoute}
                    searchFields={searchFields}
                    setSearchFields={setSearchFields}
                    handleSearchRoute={handleSearchRoute}
                    setShowAllRoute={setShowAllRoute}
                    routeSize={routeSize}
                    routeRadio={routeRadio}
                    setRouteRadio={setRouteRadio}
                    control={control}
                    errors={errors}
                    handleSubmit={handleSubmit}
                    onSubmit={() => {}}
                    setValue={setValue}
                    title="Share Route(s)"
                  />

                  {/* <DeviceSection
                    handleRemoveSelection={handleRemoveSelection}
                    deviceSize={deviceSize}
                    deviceRadio={deviceRadio}
                    setDeviceRadio={setDeviceRadio}
                    searchFields={searchFields}
                    setSearchFields={setSearchFields}
                    deviceTypeList={deviceTypeList}
                    devices={devices}
                    selectedDeviceCategory={selectedDeviceCategory}
                    setSelectedDeviceCategory={setSelectedDeviceCategory}
                    handleSearchDeviceType={handleSearchDeviceType}
                    handleSearchDevice={handleSearchDevice}
                    selectedDevice={selectedDevice}
                    deviceSelected={deviceSelected} //device dropdown controller value
                    showAllDevice={showAllDevice}
                    setShowAllDevice={setShowAllDevice}
                    handleAddDevice={handleAddDevice}
                    permissions={deviceSpecificPermissions}
                    handleCheckboxChange={handleCheckboxChange}
                    control={control}
                    errors={errors}
                    handleSubmit={handleSubmit}
                    onSubmit={() => {}}
                    setValue={setValue}
                    title="Share Device(s)"
                  /> */}
                </Grid>
                <Grid item xs={12} display="flex" alignItems="center" mt={1}>
                  <FormControlLabel
                    sx={{ color: "rgb(128, 128, 128)" }}
                    control={
                      <Checkbox
                        sx={{
                          color: "#ff7200",
                          "&.Mui-checked": {
                            color: "#ff7200",
                          },
                        }}
                        onClick={(e) => {
                          setOrgUsersShare(e.target.checked);
                        }}
                        checked={orgUsersShare}
                      />
                    }
                  />
                  <Typography sx={{ ...textClasses.normalText }}>
                    {`Share and visible to all users of `}
                    <b>{capitalize(userAccountDetailsState?.orgName ?? "-")}</b>
                  </Typography>
                </Grid>
                <Grid item xs={12} display="flex" alignItems="center">
                  <FormControlLabel
                    sx={{ color: "rgb(128, 128, 128)" }}
                    control={
                      <Checkbox
                        sx={{
                          color: "#ff7200",
                          "&.Mui-checked": {
                            color: "#ff7200",
                          },
                        }}
                        onClick={(e) => {
                          setRoleUsersShare(e.target.checked);
                        }}
                        checked={roleUsersShare}
                      />
                    }
                  />
                  <Typography sx={{ ...textClasses.normalText }}>
                    {`Share and visible with all users of user role `}
                    <b>
                      {capitalize(
                        userAccountDetailsState?.userRole?.name ?? "-"
                      )}
                    </b>{" "}
                    {`of`}{" "}
                    <b>{capitalize(userAccountDetailsState?.orgName ?? "-")}</b>
                  </Typography>
                </Grid>
              </Box>
            )}
        </Grid>
        {userAccountDetailsState &&
          Object.keys(userAccountDetailsState)?.length > 0 && (
            <Grid
              xs={12}
              sx={{
                position: "sticky",
                bottom: 0,
                left: 0,
                bgcolor: "background.paper",
              }}
              className="d-flex-row"
              justifyContent="center"
              gap={2}
            >
              <Button
                sx={{
                  ...buttonClasses.lynkitBlackFill,
                  minWidth: "110px",
                  height: "33px",
                }}
                onClick={() => {
                  history.push("/dashboard/settings/account-linkage");
                }}
              >
                Cancel
              </Button>
              <Button
                sx={{ ...buttonClasses.lynkitOrangeFill, minWidth: "110px" }}
                onClick={
                  window.location.pathname?.includes("/account-linkage/edit")
                    ? handleUpdateLinkage
                    : handleAddLinkage
                }
              >
                {window.location.pathname?.includes("/account-linkage/edit")
                  ? "Update"
                  : "Share"}
              </Button>
            </Grid>
          )}
      </Grid>
    </>
  );
};

export default AddAccount;
