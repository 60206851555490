import {
  capitalizeSentence, 
  convertIntoGivenTimezone
} from "../../../services/functions";
import { sendCustomEventAnalytics } from "../../../utils/util";

export function main(str, pKey, pLabel = "", objFamily, index, setSelectedRow,setSelectedData,userDetails) {
  switch (str) {
    case "fnGeoCreatedOn":
      return fnGeoCreatedOn(pKey, pLabel, objFamily, {},userDetails);
    case "fnGeoName":
      return fnGeoName(str, pKey, pLabel, objFamily, index, setSelectedRow,setSelectedData);
    case "fnGeoStatus":
      return fnGeoStatus(str, pKey, pLabel, objFamily, {});
    case "fnGeoDescription":
      return fnGeoDescription(str, pKey, pLabel, objFamily, {});
    case "fnGeoAddress":
      return fnGeoAddress(str, pKey, pLabel, objFamily, {});

    default:
      try { 
        let valueStr = getNestedValues(objFamily, pKey);
        return `${valueStr}`;
      } catch (error) {
        return pLabel && pLabel != "" ? `${pLabel} : - ` : "-";
      }
  }
}

const fnGeoCreatedOn = (pKey, pLabel = "", objFamily, { }, userDetails) => {
  if (objFamily["addedOn"] && userDetails && userDetails.timeZone && userDetails.timeZone.offset) {
    return convertIntoGivenTimezone(objFamily["addedOn"], userDetails.timeZone.offset);
  } 
  else {
    return '-'
  }
};
const fnGeoName = (
  str,
  pKey,
  pLabel = "",
  objFamily,
  index,
  setSelectedRow,
  setSelectedData
) => {

  if (objFamily["name"]) {
    const name = `${objFamily?.name}`;
    const elemId = `name-${index}`;
    let spanHTML;

    if (name.length > 14) {
      let shortText = name.substring(0, 14) + "...";
      spanHTML = `<div class="tooltip-container">
      <span id=${elemId} style="color: #ff7700; cursor: pointer; font-size:13px;">${shortText}</span>
      <span class="tooltip-content">${objFamily?.name}</span>
    </div>`;
    } else {
      spanHTML = `<span id=${elemId} style="color: #ff7700; cursor: pointer; font-size:13px;">${name}</span>`;
    }
    setTimeout(() => {
      const spanElement = document.getElementById(elemId);
      if (spanElement) {
        spanElement.onclick = () => {
          setSelectedData(objFamily);
        };
      }
    }, 50)
    return spanHTML;
  }
}















const fnGeoStatus = (str, pKey, pLabel = "", objFamily, index) => {
  if (objFamily["status"]) {
    const status = objFamily["status"];
    if (status === "ACTIVE") {
      return `<span style="color: #008000; font-weight: 400">${capitalizeSentence(
        status
      )}</span>`;
    } else if (status === "DISABLE") {
      return `<span style="color: red; font-weight: 400">In-Active</span>`;
    }
    else if (status === "INACTIVE") {
      return `<span style="color: red; font-weight: 400">In-Active</span>`;
    }
  }
};
const fnGeoDescription = (str, pKey, pLabel = "", objFamily) => {
  if (objFamily["description"]) {
    const description = `${objFamily["description"]}`;

    if (description.length > 14) {
      let shortText = description.substring(0, 14) + "...";
      return `<div class="tooltip-container">
                    <span style="cursor: pointer; font-size:13px;">${shortText}</span>
                    <span class="tooltip-content">${objFamily["description"]}</span>
                </div>`;
    } else {
      return `<span style="cursor: pointer; font-size:13px;">${objFamily["description"]}</span>`;
    }
  }
  return "-";
};
const fnGeoAddress = (str, pKey, pLabel = "", objFamily) => {
  if (objFamily["address"]) {
    const address = `${objFamily["address"]}`;

    if (address.length > 14) {
      let shortText = address.substring(0, 14) + "...";
      return `<div class="tooltip-container">
                    <span style="cursor: pointer; font-size:13px;">${shortText}</span>
                    <span class="tooltip-content">${objFamily["address"]}</span>
                </div>`;
    } else {
      return `<span style="cursor: pointer; font-size:13px;">${objFamily["address"]}</span>`;
    }
  }
  return "-";
};
function formatDate(date) {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const month = months[date.getMonth()];
  const day = date.getDate();
  const year = date.getFullYear();
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const seconds = date.getSeconds();
  const ampm = hours >= 12 ? "PM" : "AM";

  // Convert to 12-hour format
  const formattedHours = hours % 12 || 12;

  const formattedDate = `${month} ${day}, ${year}, ${formattedHours}:${minutes}:${seconds} ${ampm}`;
  return formattedDate;
}

function processTimeZone(
  timeToConvert,
  timeZone,
  format = "MMM DD, YYYY, hh:mm:ss A"
) {
  const date = new Date(timeToConvert);
  const formattedDate = formatDate(date);
  return formattedDate;
}

function getNestedValues(objFamily, keyPath) {
  const keys = keyPath.split(".");
  let value = objFamily;
  for (const key of keys) {
    if (value && value.hasOwnProperty(key)) {
      value = value[key] || "-";
    } else {
      return "-";
    }
  }
  return value;
}
