import {
  USER_PERMISSIONS,
  USER_PERMISSIONS_ROLES,
  USER_ROLES_COUNT,
} from "../types";

const intialState = {
  permissionRoles: [],
  userPermissionRoles: {},
  userRolesCount: {},
  permissionRoleCount: 0,
};

const userPermissionReducer = (state = intialState, { type, payload }) => {
  switch (type) {
    case USER_PERMISSIONS:
      return { ...state, permissionRoles: payload };
    case "USER_PERMISSIONS_COUNT":
      return { ...state, permissionRoleCount: payload };
    case "FLUSH_PERMISSION_ROLES":
      return { ...state, permissionRoles: [] };
    case USER_ROLES_COUNT:
      return { ...state, userRolesCount: payload };
    default:
      return state;
  }
};

export const flushAllRolePermissions = () => ({
  type: "FLUSH_PERMISSION_ROLES",
});

export default userPermissionReducer;
