import { Box, CircularProgress } from "@mui/material";
import React, { forwardRef } from "react";
import styles from "./style.module.css";
import { useSelector } from "react-redux";
import { DetailPanel } from "../DetailPanel";
import "../../styles.scss";
import { SideDetails } from "./SideDetails";

const FullScreenView = forwardRef(
  (
    {
      openFullScreen,
      closeFullScreen,
      monitoringData,
      themeType,
      handleSideClose,
      handleBoxClick,
    },
    ref
  ) => {
    const [isPanelOpen, setIsPanelOpen] = React.useState(false);
    const [boxSelected, setBoxSelected] = React.useState(null);

    const {
      deviceCount,
      alertCount,
      tripCount,
      monitoringCountLoading,
      alertCountLoading,
      deviceCountLoading,
    } = useSelector((state) => state.monitoring);

    const mergedCounts = {
      ...(deviceCount ? deviceCount : {}),
      ...(tripCount ? tripCount : {}),
      ...(alertCount ? alertCount : {}),
    };

    const sections = React.useMemo(() => {
      let result = [];
      if (monitoringData && monitoringData?.length) {
        monitoringData?.map((data, i) => {
          if (data?._id !== "Alerts") {
            result.push({
              id: data?._id,
              count: data?.data?.length,
              list: data?.data,
            });
          } else {
            result.push({
              id: data?._id,
              count: data?.data?.length,
              subSections: data?.data?.map((_, i) => {
                return {
                  id: _?._id,
                  count: _?.alertLevelArray?.length,
                  list: _?.alertLevelArray,
                };
              }),
            });
          }
        });
      } else {
        result = [];
      }
      return result;
    }, [monitoringData]);

    const totalTiles = sections.reduce((total, section) => {
      if (section.subSections) {
        return (
          total +
          section.subSections.reduce(
            (subTotal, subSection) => subTotal + subSection.count,
            0
          )
        );
      }
      return total + section.count;
    }, 0);

    const getSectionHeight = (tileCount) => {
      return (tileCount / totalTiles) * 100 + "vh";
    };

    const handleTileClick = (box) => {
      console.log("box: ", box);
      setIsPanelOpen(true);
      setBoxSelected(box);
    };

    const handleClosePanel = () => {
      setIsPanelOpen(false);
      setBoxSelected(null);
    };

    React.useEffect(() => {
      openFullScreen();
    }, [openFullScreen]);

    console.log("boxSelected: ", boxSelected);

    return (
      <Box
        ref={ref}
        className={styles["main-container"]}
        sx={{ backgroundColor: "background.paper" }}
      >
        {sections.map((section, index) => {
          !section.subSections &&
            console.log("section height: ", getSectionHeight(section.count));
          return (
            <section
              key={index}
              className={`${styles["mon-section"]}`}
              style={{
                height: !section.subSections && getSectionHeight(section.count),
              }}
            >
              <Box
                className={styles.section_header}
                sx={{ background: (theme) => theme.palette.divider }}
              >
                {section?.id}
              </Box>
              <div
                className={`${styles.section_body} ${section.subSections ? styles.section_no_sub : ""}`}
              >
                {section.subSections
                  ? section.subSections.map((subSection, subIndex) => (
                      <div
                        key={subIndex}
                        className={styles["sub-section"]}
                        style={{ height: getSectionHeight(subSection.count) }}
                      >
                        <Box
                          className={styles.section_header}
                          sx={{ background: (theme) => theme.palette.divider }}
                        >
                          {subSection?.id}
                        </Box>
                        <div className={styles.sub_section__body}>
                          {subSection?.list?.map((_, idx) => {
                            let count =
                              mergedCounts && mergedCounts[_?.type]
                                ? mergedCounts[_?.type]?.toLocaleString("en-US")
                                : 0;
                            const loading =
                              section?.id === "Alerts"
                                ? alertCountLoading
                                : monitoringCountLoading || deviceCountLoading;
                            return (
                              <div
                                key={idx}
                                className={`${styles.tile} ${styles[subSection.id]}`}
                                onClick={() => handleTileClick(_)}
                                style={{
                                  background: "transparent",
                                  border: `2px solid ${_?.borderColor}`,
                                  cursor: count > 0 ? "pointer" : "default",
                                }}
                              >
                                <span>{_?.name}</span>
                                {loading ? (
                                  <Box
                                    height="100%"
                                    width="100%"
                                    display="flex"
                                    justifyContent="end"
                                  >
                                    <CircularProgress
                                      size={12}
                                      sx={{
                                        color: "#383838",
                                      }}
                                    />
                                  </Box>
                                ) : (
                                  <span style={{color: _?.borderColor, fontWeight: 600}} className="ms-1">{count}</span>
                                )}
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    ))
                  : section?.list?.map((_, idx) => {
                      let count =
                        mergedCounts && mergedCounts[_?.type]
                          ? mergedCounts[_?.type]?.toLocaleString("en-US")
                          : 0;
                      const loading =
                        section?.id === "Alerts"
                          ? alertCountLoading
                          : monitoringCountLoading || deviceCountLoading;
                      return (
                        <div
                          key={idx}
                          className={`${styles.tile} ${styles[section.id]}`}
                          onClick={() => handleTileClick(_)}
                          style={{
                            background: "transparent",
                            border: `2px solid ${_?.borderColor}`,
                            cursor: count > 0 ? "pointer" : "default",
                          }}
                        >
                          <span>{_?.name}</span>
                          {loading ? (
                            <Box
                              height="100%"
                              width="100%"
                              display="flex"
                              justifyContent="end"
                            >
                              <CircularProgress
                                size={12}
                                sx={{
                                  color: "#383838",
                                }}
                              />
                            </Box>
                          ) : (
                            <span style={{color: _?.borderColor, fontWeight: 600}} className="ms-1">{count}</span>
                          )}
                        </div>
                      );
                    })}
              </div>
            </section>
          );
        })}

        {boxSelected ? (
          <Box
            className={`${styles["side-panel"]} ${isPanelOpen ? styles.open : ""}`}
            sx={{ background: (theme) => theme.palette.background.paper }}
          >
            {/* <button onClick={handleClosePanel}>Close</button>
              <div className={styles["panel-content"]}>
                <h2>Panel Content</h2>
                <p>This is the side panel content.</p>
              </div> */}
            <SideDetails
              sideToOpen={boxSelected ? boxSelected?._id : null}
              onClose={handleClosePanel}
              data={boxSelected}
              themeType={themeType}
              setLatLng={() => {}}
            />
          </Box>
        ) : null}
      </Box>
    );
  }
);

export default FullScreenView;
