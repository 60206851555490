/* eslint-disable */
// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax
import { Box, IconButton, Typography, Grid, Tooltip } from "@mui/material";
import React, { useState, useEffect } from "react";
import loaderGif from "../../../static/images/drawer/loader.gif";
import BsjCameraPlaybackDrawer from "./components/BsjCameraPlaybackDrawer";
import { useDispatch, useSelector } from "react-redux";
import { bsjCameraConstants } from "../../../services/constants";
import SlideshowIcon from "@mui/icons-material/Slideshow";
import { fetchAddressFromCoords, getBsjCameraAlerts, getBsjCameraVideoLists } from "../../../redux/actions";
import { useParams } from "react-router";
import DataTable from "./components/DataTable";
import { Download, Info, PlayArrow } from "@mui/icons-material";
import EvidenceModal from "./components/EvidenceModal";
import { DateToUnixLong, convertToGMTTime, convertTimestamp, calculateTimeDifference, convertfiltersDateFormat } from "../../../services/constants";
import { convertIntoGivenTimezone, showToast } from "../../../services/functions";
import PauseIcon from '@mui/icons-material/Pause';

const BsjCameraPlayback = (props) => {
    const { id } = useParams();
    let propsShared = props?.location?.state?.tripData
    var uc1;
    const dispatch = useDispatch();
    const [cameraPlayback, setCameraPlayback] = React.useState(false);
    const [eventPlayback, setEventPlayback] = React.useState(false);
    const [tripData, setTripData] = useState({})
    const [fiveMinbtnLoader, set5MinBtnLoader] = useState(false)
    const [tenMinbtnLoader, set10MinBtnLoader] = useState(false)
    const [thirtyMinbtnLoader, set30MinBtnLoader] = useState(false)
    const [timeIntervalLoader, setTimeIntervalLoader] = useState(false)
    const [showVideo, setShowVideo] = useState(false)
    const [eventObject, setEventObject] = useState({})
    const [evidenceModal, setEvidenceModal] = useState(false)
    const [queryList, setQueryList] = useState([])
    const [tableactiveIndex, setActiveIndex] = useState(null)
    const [queryLoader, setQueryLoader] = useState(false)
    const [alertsLoader, setAlertsLoader] = useState(false)
    const [iconName, setIconName] = useState('start')
    const [cameraAlertListing, setCameraAlertListing] = useState([])
    const bsjCount = useSelector((state) => state.tripsData.totalRows);
    const [filters, setFilters] = React.useState({
        from: null,
        to: null,
    });
    let payload = {
        page: 1,
        limit: 10,
        filter: {},
    };
    const [paginationFilter, setPaginationFilter] = useState({ ...payload });

    const isDarkThemeEnabledSelector = useSelector((state) => state.theme.themeDark);
    const { deviceDetails, cameraAlerts: eventsListing } = useSelector((_) => _?.deviceslist);
    const { currentUserDetail } = useSelector((_) => _?.auth);
    const themeType = isDarkThemeEnabledSelector ? require("../../../static/styles/darktheme") : require("../../../static/styles/theme");
    const { textClasses } = themeType;

    useEffect(() => {
        if (propsShared) {
            setTripData(propsShared);
            let tripStartTime = propsShared?.detailsDeviceID ? convertToISTFormatByPriyanshu(propsShared?.detailsDeviceID?.time) : null;
            let tripEndTime = propsShared?.endTime ? convertToISTFormatByPriyanshu(propsShared?.endTime) : null;
            setFilters((prev) => ({
                ...prev,
                from: tripStartTime,
                to: tripEndTime,
            }))
        }
    }, [propsShared])

    useEffect(() => {
        const handleDomMutation = (mutationsList) => {
            for (const mutation of mutationsList) {
                if (mutation.type === 'childList') {
                    const addedNodes = Array.from(mutation.addedNodes);
                    addedNodes.forEach(node => {
                        if (node.nodeType === Node.ELEMENT_NODE && node.classList.contains('video-container')) {
                            applyStyles(node, 2);
                        }
                    });
                }
            }
        };

        const observer = new MutationObserver(handleDomMutation);
        observer.observe(document.documentElement, { childList: true, subtree: true });
        return () => {
            observer.disconnect();
        };
    }, []);

    const applyStyles = (container, cameraCount) => {
        const childDivs = Array.from(container.querySelectorAll('.video-container > div'));
        const firstChildDiv = childDivs[0];

        const checkVideoTag = (childDiv) => {
            if (childDiv) {
                const videoTag = childDiv.querySelector('video');
                if (videoTag) {
                    const handleSrcChange = () => {
                        const videoSrc = videoTag.getAttribute('src');
                        if (videoSrc) {
                            setShowVideo(true)
                            // console.log(`Video tag found in div  with src: ${videoSrc}`);
                        } else {
                            // console.log(`Video tag found in div  but no src attribute`);
                        }
                    };

                    handleSrcChange();

                    const srcObserver = new MutationObserver(() => {
                        handleSrcChange();
                    });
                    srcObserver.observe(videoTag, { attributes: true, attributeFilter: ['src'] });
                } else {
                    console.log(`No video tag found in div `);
                }
            }
        };

        checkVideoTag(firstChildDiv);

        if (childDivs.length > 0) {
            if (cameraCount === 2) {
                firstChildDiv.style.setProperty('width', '100%', 'important');
                firstChildDiv.style.setProperty('height', '100%', 'important');
            }
            else {
                {
                    firstChildDiv.style.setProperty('width', '100%', 'important');
                    firstChildDiv.style.setProperty('height', '100%', 'important');
                }
            }
        }
    };

    const getAddressFromCoords = (item, i) => {
        let temp = cameraAlertListing;
        const payload = {
            latitude: item?.lat,
            longitude: item?.lng,
            appType: "GOOGLE",
        };
        dispatch(
            fetchAddressFromCoords(payload, (res) => {
                if (res && res?.length > 0) {
                    const { address } = res[0];
                    if (temp[i]) {
                        temp[i] = { ...temp[i], address: address };
                    }
                    setCameraAlertListing([...temp]);
                }
            })
        );
    };

    const cameraTableColumns = [
        {
            header: "Channel",
            cell: (entry) => {
                return (
                    <Typography
                        sx={{
                            ...textClasses.t13n,
                            textAlign: "center",
                            textTransform: "capitalize",
                        }}
                    >
                        {entry?.Channel ?? "-"}
                    </Typography>
                );
            },
        },
        {
            header: "Start time",
            cell: (entry) => {
                return (
                    <Typography
                        sx={{
                            ...textClasses.t13n,
                            textAlign: "center",
                            textTransform: "capitalize",
                        }}
                    >
                        {convertTimestamp(entry?.StartTime) || "-"}
                    </Typography>
                );
            },
        },
        {
            header: "End Time",
            cell: (entry) => {
                return (
                    <Typography
                        sx={{
                            ...textClasses.t13n,
                            textAlign: "center",
                            textTransform: "capitalize",
                        }}
                    >
                        {convertTimestamp(entry?.EndTime) || "-"}
                    </Typography>
                );
            },
        },
        {
            header: "Duration(seconds)",
            cell: (entry) => {
                return (
                    <Typography
                        sx={{
                            ...textClasses.t13n,
                            textAlign: "center",
                            textTransform: "capitalize",
                        }}
                    >
                        {calculateTimeDifference(entry?.StartTime, entry?.EndTime) ?? "-"}
                    </Typography>
                );
            },
        },
        {
            header: "Actions",
            cell: (entry, key) => {
                return (
                    <Typography
                        data-testid="action-menu-btn"
                        sx={{
                            ...textClasses.normalText,
                            color: themeType.default.themeOrange,
                        }}
                    // onClick={(e) => handleOpenAction(e, index)}
                    >
                        <Box display="flex" justifyContent="center">
                            {key === tableactiveIndex && iconName === 'pause' ? (
                                <IconButton size="small">
                                    <PauseIcon
                                        size="small"
                                        sx={{ cursor: 'pointer', color: '#ff7200 !important' }}
                                        onClick={() => { CvNetVideo.Pause(); setIconName('resume'); }}
                                    />
                                </IconButton>
                            ) : key === tableactiveIndex && iconName === 'resume' ? (
                                <IconButton
                                    size="small"
                                    onClick={() => { CvNetVideo.Resume(); setIconName('pause'); }}
                                >
                                    <PlayArrow sx={{ cursor: 'pointer', color: '#ff7200 !important' }} />
                                </IconButton>
                            ) : (
                                <IconButton
                                    size="small"
                                    onClick={() => {
                                        // setVideoLoader(false)
                                        startPlayback(entry?.Channel, entry?.MediaType, entry?.StreamType, entry?.StorageType, entry?.StartTime, entry?.EndTime);
                                        setActiveIndex(key);
                                        setIconName('pause');
                                    }}
                                >
                                    <PlayArrow />
                                </IconButton>
                            )}
                        </Box>
                    </Typography>
                );
            },
        },
    ];

    const eventsTableColumns = [
        {
            header: "#",
            cell: (entry, key) => {
                return (
                    <Typography
                        sx={{
                            ...textClasses.t13n,
                            textAlign: "center",
                            textTransform: "capitalize",
                        }}
                    >
                        {(paginationFilter?.page - 1) * paginationFilter?.limit + key + 1}
                    </Typography>
                );
            },
        },
        {
            header: "Alert Name",
            cell: (entry) => {
                return (
                    <Typography
                        sx={{
                            ...textClasses.t13n,
                            textAlign: "center",
                            textTransform: "capitalize",
                        }}
                    >
                        {entry?.alertType?.toLowerCase().replace(/_/g, ' ').replace(/\b\w/g, (char) => char.toUpperCase())}
                    </Typography>
                );
            },
        },
        {
            header: "Alert Time",
            cell: (entry) => {
                return (
                    <Typography
                        sx={{
                            ...textClasses.t13n,
                            textAlign: "center",
                            textTransform: "capitalize",
                        }}
                    >
                        {convertIntoGivenTimezone(entry?.createdAt, currentUserDetail?.timeZone.offset) || "-"}
                    </Typography>
                );
            },
        },
        {
            header: "Location",
            cell: (entry, key) => {
                return (
                    <Tooltip
                        placement="top"
                        arrow
                        title={entry?.address && entry?.address?.length > 15 ? entry?.address : ""}
                    >
                        <Typography
                            sx={{
                                ...textClasses.t13n,
                                textAlign: "center",
                                textTransform: "capitalize",
                                color: themeType?.default?.themeOrange,
                                cursor: "pointer",
                            }}
                            onClick={() => {
                                getAddressFromCoords({ lat: entry?.latitude, lng: entry?.longitude }, key);
                            }}
                        >

                            {entry && entry.address && entry.address.length > 14
                                ? entry.address.substring(0, 14) + "..."
                                : entry.address ?? ""}
                            {entry?.latitude || "-"} , {entry?.longitude || "-"}

                        </Typography>
                    </Tooltip>
                );
            },
        },
        {
            header: "Actions",
            cell: (entry) => {
                return (
                    <Typography
                        data-testid="action-menu-btn"
                        sx={{
                            ...textClasses.normalText,
                            color: themeType.default.themeOrange,
                        }}
                    // onClick={(e) => handleOpenAction(e, index)}
                    >
                        <IconButton
                            size="small"
                            onClick={() => modalToggle(entry)}
                        >
                            <Info />
                        </IconButton>
                    </Typography>
                );
            },
        },
    ];

    const modalToggle = (item) => {
        setEventObject(item)
        if (evidenceModal) {
            setEvidenceModal(false)
        }
        else {
            setEvidenceModal(true)
        }
    }

    const handleCameraPlayback = () => {
        CvNetVideo.Pause()
        setEventPlayback(false)
        setCameraAlertListing([])
        let tripStartTime = convertToISTFormatByPriyanshu(tripData?.detailsDeviceID?.time, "start");
        let tripEndTime = convertToISTFormatByPriyanshu(tripData?.endTime, "end");
        let filterFrom = new Date(filters.from);
        let filterTo = new Date(filters.to);
        let time = 'anyRange'

        setTimeIntervalLoader(true)
        setQueryLoader(true)

        if (tripData?.status === 'completed') {
            if (filterFrom >= tripStartTime && filterTo <= tripEndTime) {
                CvNetVideo?.QueryVideoFileList(
                    id,
                    bsjCameraConstants.getChannel, //channel
                    DateToUnixLong(convertfiltersDateFormat(filters?.from)), //startTime
                    DateToUnixLong(convertfiltersDateFormat(filters?.to)), //endTime

                    // DateToUnixLong('5-31-2024 15:15:00'),
                    // DateToUnixLong('5-31-2024 17:18:00'),
                    bsjCameraConstants.numAlarm, //(numAlarm)
                    bsjCameraConstants.cmbMideaType, //Audio and video, Audio, Video, audio or video(cmbMideaType)
                    bsjCameraConstants.cmbStreamType_back, //all stram, sub stream, main stream
                    bsjCameraConstants.cmbStorageType, //Main storage or disaster recovery storage, main memory,Disaster recovery storage
                    (data) => QueryVideoFileListCallback(data, time),
                    0,
                    {
                        clusterHost: bsjCameraConstants.server,
                        clusterPort: bsjCameraConstants.port,
                        protocol: bsjCameraConstants.protocol,
                    },
                    bsjCameraConstants.cmbDataSource, //cmbDataSource
                );
            }
            else {
                setCameraAlertListing([])
                setQueryLoader(false)
                setTimeIntervalLoader(false)
                showToast('Filter times are not within the trip range time.', true)
            }
        }
        else {
            if (filterFrom >= tripStartTime && filterTo >= tripStartTime) {
                CvNetVideo?.QueryVideoFileList(
                    id,
                    bsjCameraConstants.getChannel, //channel
                    DateToUnixLong(convertfiltersDateFormat(filters?.from)), //startTime
                    DateToUnixLong(convertfiltersDateFormat(filters?.to)), //endTime
                    // DateToUnixLong('5-31-2024 15:15:00'),
                    // DateToUnixLong('5-31-2024 17:18:00'),
                    bsjCameraConstants.numAlarm, //(numAlarm)
                    bsjCameraConstants.cmbMideaType, //Audio and video, Audio, Video, audio or video(cmbMideaType)
                    bsjCameraConstants.cmbStreamType_back, //all stram, sub stream, main stream
                    bsjCameraConstants.cmbStorageType, //Main storage or disaster recovery storage, main memory,Disaster recovery storage
                    (data) => QueryVideoFileListCallback(data, time),
                    0,
                    {
                        clusterHost: bsjCameraConstants.server,
                        clusterPort: bsjCameraConstants.port,
                        protocol: bsjCameraConstants.protocol,
                    },
                    bsjCameraConstants.cmbDataSource, //cmbDataSource
                );
            } else {
                setCameraAlertListing([])
                setTimeIntervalLoader(false)
                setQueryLoader(false)
                showToast('Filter times should be greater than trip start time', true)
            }
        }
    }

    function convertToISTFormat(unixTimestamp) {
        if (!unixTimestamp) {
            return null; // Handle missing timestamp
        }

        const date = new Date(unixTimestamp);

        try {
            const timezoneOffset = currentUserDetail?.timeZone.offset || 0; // Handle missing offset
            const formattedDate = convertIntoGivenTimezone(date, timezoneOffset);


            const dateParts = formattedDate.split("/");
            const year = parseInt(dateParts[2], 10);
            const month = parseInt(dateParts[1], 10) - 1; // Months are 0-indexed
            const day = parseInt(dateParts[0], 10);

            const timeParts = formattedDate.split(" ")[1].split(":");
            const hours = parseInt(timeParts[0], 10);
            const minutes = parseInt(timeParts[1], 10);
            const seconds = parseInt(timeParts[2], 10);

            const desiredTime = new Date(
                year,
                month,
                day,
                hours,
                minutes,
                seconds,
                0
            );

            return desiredTime;
        } catch (error) {
            console.error("Error parsing date string:", error);
            return null; // Handle parsing errors
        }
    }

    function convertToISTFormatByPriyanshu(unixTimestamp) {
        if (!unixTimestamp) {
            return null; // Handle missing timestamp
        }

        const date = new Date(unixTimestamp);

        try {
            const timezoneOffset = currentUserDetail?.timeZone.offset || 0; // Handle missing offset
            const formattedDate = convertIntoGivenTimezone(date, timezoneOffset);
            const parsedDate = parseDateString(formattedDate);
            return parsedDate;

        } catch (error) {
            console.error("Error parsing date string:", error);
            return null; // Handle parsing errors
        }
    }

    const parseDateString = (dateString) => {
        const [date, time, period] = dateString.split(' ');
        const [day, month, year] = date.split('/').map(Number);
        let [hours, minutes, seconds] = time.split(':').map(Number);

        if (period === 'PM' && hours !== 12) {
            hours += 12;
        } else if (period === 'AM' && hours === 12) {
            hours = 0;
        }

        return new Date(year, month - 1, day, hours, minutes, seconds);
    };


    const handleEventsPlayback = () => {
        // setVideoLoader(true)
        setQueryList([])
        setCameraPlayback(false)
        setEventPlayback(true);
        setAlertsLoader(true)
        dispatch(getBsjCameraAlerts(
            {
                "startDate": new Date(tripData?.detailsDeviceID?.time),
                "endDate": tripData?.endTime ? new Date(tripData?.endTime) : new Date(),
                "deviceId": tripData?.cameraId,
                "tripId": tripData?._id || null,
                "page": "1",
                "limit": "10",
            }, (res) => {
                if (res?.success) {
                    setAlertsLoader(false)
                    setCameraAlertListing(res?.data)
                }
                else {
                    setAlertsLoader(false)
                    setCameraAlertListing([])
                }

            }
        ))
    };

    React.useEffect(() => {
        if (id) {
            try {
                setQueryList([])
                setCameraAlertListing([])
                // setFilters({
                //     from: null,
                //     to: null,
                // });
            } catch (e) {
                console.log("error initial: ", e);
            }
        }
    }, [dispatch, id]);

    useEffect(() => {
        function init() {
            uc1 = CvNetVideo.Init(document.querySelector("#player1"), 1,
                {
                    callback: function () {
                        [].forEach.call(document.querySelectorAll("button"), function (btn) {
                            btn.disabled = false;
                        });
                    },
                    selectedEvent: function (e) {
                        var selected = e;
                    },
                    events: {
                        onCapture: onCapture,
                        timeoutWarning: timeoutWarning,
                        timeoutCanceled: timeoutCanceled,
                        timeoutClose: timeoutClose,
                        onStop: onVideoStop,
                        onServerNotice: onServerNotice,
                        onEndByServer: onEndByServer,
                        onHlsPlay: onHlsPlay,
                        onChangeH265Player: onChangeH265Player
                    }
                });
        }
        if (CvNetVideo) {
            init();
        }
    }, [CvNetVideo])

    function onCapture(base64png, id, ucVideo) {

    }
    function timeoutWarning(leftMsec, time) {

    }
    function timeoutCanceled() {

    }
    function timeoutClose(time) {

    }
    function onVideoStop(id, ucVideo) {

    }
    function onServerNotice(type, id, ucVideo) {

    }
    function onEndByServer(msg, id, ucVideo) {

    }
    function onHlsPlay(id, ucVideo, hlshttp, hlshttps, rtmpurl, cid, flvhttp, flvhttps) {

    }
    function onChangeH265Player(playermode) {

    }

    const handleTimeBtnClicked = (time) => {
        setCameraAlertListing([])
        setCameraPlayback(true)
        setEventPlayback(false);
        const currentTime = new Date();
        const formattedCurrentTime = formatTime(currentTime);
        let formattedEndTime = ''
        setQueryLoader(true)
        CvNetVideo.Pause()

        if (time === 5) {
            set5MinBtnLoader(true)
            const timeBefore5Minutes = new Date(currentTime.getTime() - 5 * 60000);
            const formattedTimeBefore5Minutes = formatTime(timeBefore5Minutes);
            formattedEndTime = formattedTimeBefore5Minutes
        }
        else if (time === 10) {
            set10MinBtnLoader(10)
            const timeBefore5Minutes = new Date(currentTime.getTime() - 10 * 60000);
            const formattedTimeBefore5Minutes = formatTime(timeBefore5Minutes);
            formattedEndTime = formattedTimeBefore5Minutes
        }
        else if (time === 30) {
            set30MinBtnLoader(30)
            const timeBefore5Minutes = new Date(currentTime.getTime() - 30 * 60000);
            const formattedTimeBefore5Minutes = formatTime(timeBefore5Minutes);
            formattedEndTime = formattedTimeBefore5Minutes
        }
        else {

        }

        CvNetVideo?.QueryVideoFileList(
            id,
            bsjCameraConstants.getChannel, //channel
            DateToUnixLong(formattedCurrentTime), //startTime
            DateToUnixLong(formattedEndTime), //endTime
            // DateToUnixLong('5-31-2024 10:15:00'),
            // DateToUnixLong('5-31-2024 17:18:00'),
            bsjCameraConstants.numAlarm, //(numAlarm)
            bsjCameraConstants.cmbMideaType, //Audio and video, Audio, Video, audio or video(cmbMideaType)
            bsjCameraConstants.cmbStreamType_back, //all stram, sub stream, main stream
            bsjCameraConstants.cmbStorageType, //Main storage or disaster recovery storage, main memory,Disaster recovery storage
            (data) => QueryVideoFileListCallback(data, time),
            0,
            {
                clusterHost: bsjCameraConstants.server,
                clusterPort: bsjCameraConstants.port,
                protocol: bsjCameraConstants.protocol,
            },
            bsjCameraConstants.cmbDataSource, //cmbDataSource
        );
    }

    function QueryVideoFileListCallback(data, time) {
        console.log('data', data)
        if (time === 5) {
            set5MinBtnLoader(false)
        }
        else if (time === 10) {
            set10MinBtnLoader(false)
        }
        else if (time === 30) {
            set30MinBtnLoader(false)
        }
        else {
            setTimeIntervalLoader(false)
        }

        if (data?.FileList?.length) {
            setQueryList(data?.FileList)
            setQueryLoader(false)
            setTimeIntervalLoader(false)
        }
        else {
            setQueryLoader(false)
            setTimeIntervalLoader(false)
            setQueryList([])
            showToast('No data found.', true)
        }
    }


    function formatTime(date) {
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based in JS
        const day = String(date.getDate()).padStart(2, '0');
        const year = date.getFullYear();
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');

        return `${month}-${day}-${year} ${hours}:${minutes}:${seconds}`;
    }

    function startPlayback(channel, cvMediaType, streamType, storageType, startTime, endTime) {
        // setVideoLoader(false)
        CvNetVideo.PlaybackVideo(
            id,
            channel,
            cvMediaType,
            streamType,
            storageType,
            bsjCameraConstants.cmbPlaybackMode, //cmbPlaybackMode
            bsjCameraConstants.cmbMultiple, //cmbMultiple
            convertToGMTTime(startTime),
            convertToGMTTime(endTime),
            0,
            {
                clusterHost: bsjCameraConstants.server,
                clusterPort: bsjCameraConstants.port,
                protocol: bsjCameraConstants.protocol
            },
            function (res) {
                // if ('is_playing' in res) {
                //     console.log('trueeee')
                //     setVideoScreen(true)
                // }
                // else {
                //     setVideoScreen(false)
                //     showToast('Some Error Occured.', true)
                // }
            },
            bsjCameraConstants.cmbPlayermode, //cmbPlayermode
            bsjCameraConstants.cmbDataSource, //cmbDataSource


        );
    }



    return (
        <Box
            width="100%"
            height="90vh"
            maxHeight="900px"
            position="relative"
            display="flex"
        >
            <>
                <Box width="100%" height="100%" mr={1.5}>
                    <Box
                        height="100%"
                        width="100%"
                        display="flex"
                        flexDirection="column"
                        gap={1}
                    >
                        <Box
                            height="60%"
                            width="100%"
                            borderRadius="12px"
                            sx={{ background: themeType.default.themeGray, display: eventPlayback ? 'none' : null }}
                            data-testid="camera-section"
                        >
                            {!showVideo && (
                                <Box
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                    height="100%"
                                    width="100%"
                                >
                                    <SlideshowIcon
                                        sx={{ fontSize: "5rem ! important", color: "#ff7200" }}
                                    />
                                    <Typography>
                                        Select file from the table and play.
                                    </Typography>
                                </Box>
                            )}


                            <Grid item xs={12} sx={{ height: '100%', visibility: showVideo ? null : 'hidden' }}>
                                <div id="player1" style={{ width: '100%', height: '100%' }}>
                                </div>
                            </Grid>

                        </Box>
                        <Box
                            width="100%"
                            height={eventPlayback ? '100%' : '38%'}
                            sx={{ overflowY: "auto" }}
                            borderRadius="12px"
                            data-testid="datatable-section"
                        >
                            <DataTable
                                themeType={themeType}
                                tableColumns={queryList.length ? cameraTableColumns : eventsTableColumns}
                                data={queryList.length ? queryList : eventPlayback && eventsListing ? cameraAlertListing : []}
                                tableactiveIndex={tableactiveIndex}
                                queryLoader={queryLoader}
                                alertsLoader={alertsLoader}
                                tripData={tripData}
                                eventPlayback={eventPlayback}
                                cameraPlayback={cameraPlayback}
                                eventsCheck={true}
                                bsjCount={bsjCount}
                                paginationFilter={paginationFilter}
                                setPaginationFilter={setPaginationFilter}
                                cameraAlertListing={cameraAlertListing}
                                setCameraAlertListing={setCameraAlertListing}
                                id={id}
                            />
                        </Box>
                    </Box>
                </Box>
                <BsjCameraPlaybackDrawer
                    themeType={themeType}
                    tripData={tripData}
                    fiveMinbtnLoader={fiveMinbtnLoader}
                    tenMinbtnLoader={tenMinbtnLoader}
                    thirtyMinbtnLoader={thirtyMinbtnLoader}
                    timeIntervalLoader={timeIntervalLoader}
                    isDark={isDarkThemeEnabledSelector}
                    alertsLoader={alertsLoader}
                    filters={filters}
                    setFilters={setFilters}
                    currentUserDetail={currentUserDetail}
                    handleTimeBtnClicked={handleTimeBtnClicked}
                    handleEventsPlayback={handleEventsPlayback}
                    handleCameraPlayback={handleCameraPlayback}
                />
            </>


            {evidenceModal && (
                <EvidenceModal
                    open={evidenceModal}
                    eventObject={eventObject}
                    userDetails={currentUserDetail}
                    deviceDetails={deviceDetails}
                    hide={() => {
                        setEvidenceModal(false);
                    }}
                />
            )}
        </Box>
    );
};

export default BsjCameraPlayback;
