import { commonAxios,geoAxios, findGetParameter } from "../../services/Api";
import { showToast } from "../../services/functions";
import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  GET_MAIN_ASSET_BY_DEVICE_ID,
  GET_MAIN_ASSET_DATA,
  GET_VENDOR_ASSET_DATA,
  SET_SELECTED_ITEM_ID,
} from "../types";
import axios from 'axios';
import protobuf from "protobufjs";
import assetMgtProtoFile from "../../utils/protocolBuffer/assetManagementMain.proto";
import assetMgtVendorProtoFile from "../../utils/protocolBuffer/assetManagementVendor.proto";

const isSearchDataValid = (data) => {
  return data.filter((_) => _.count).length;
};

const transformSearchData = (data) => {
  return data
    .filter((_) => _.count)
    .reduce(
      (result, item) => {
        result.data = item.data;
        result.label = `Device ID: ${item.data[0].deviceId}`;
        result.categories.push(item.label);
        return result;
      },
      { data: [], label: "", categories: [], count: 1 }
    );
};

export const getMainAssetByDeviceId = ({ deviceId, time }, cb) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    const body = {
      deviceId,
      time,
      deviceType: "all",
      // accessKey: findGetParameter("accesskey"),
    };
    commonAxios
      .post("auth/v2/assetManagement/getAssetsMainCount", body)
      .then(({ data }) => {
        if (isSearchDataValid(data?.data)) {
          callback();
        } else {
          dispatch({ type: FETCH_ERROR, payload: data?.message });
          dispatch({ type: GET_MAIN_ASSET_BY_DEVICE_ID, payload: [] });
        }
        function callback() {
          dispatch({ type: FETCH_SUCCESS });
          dispatch({
            type: GET_MAIN_ASSET_BY_DEVICE_ID,
            payload: transformSearchData(data?.data),
          });
          if (cb) {
            cb(data?.data);
          }
        }
      })
      .catch(function (error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
        cb && cb();
        console.log("Error****:", error.message);
      });
  };
};

export const getMainAssetData = (requestPayload, cb) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    dispatch({ type: "MAIN_LOADING", payload: true });
    const body = {
      // deviceType: "all",
      // accessKey: findGetParameter("accesskey"),
      // accessKey: "ll4r7tw5_1001",
      ...requestPayload,
    };
    geoAxios
      .post("assetManagement/getAssetsMainCount", body)
      .then((allData) => {
        const { data } = allData;
        if (data?.success) {
          callback();
        } else {
          dispatch({ type: FETCH_ERROR, payload: data?.message });
          dispatch({ type: GET_MAIN_ASSET_DATA, payload: [] });
        }
        async function callback() {
          dispatch({ type: FETCH_SUCCESS });
          if(body?.type !== "download"){
            const root = await protobuf.load(assetMgtProtoFile);
            const Message = root.lookupType('DeviceList');
            const parsedData = Message.decode(data?.data?.data);
            dispatch({
              type: GET_MAIN_ASSET_DATA,
              payload: parsedData?.devices,
              isDownload: false,
              // isDownload: data?.type === "download" ? true : false,
            });
          }else{
            if(data?.data?.length === 0){
              showToast(data?.message, true);
            }else{
              showToast(data?.message);
            }
          }
          if (cb) {
            cb(data?.data);
          }
        }
        dispatch({ type: "MAIN_LOADING", payload: false });
      })
      .catch(function (error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
        cb && cb();
        showToast(error?.response?.data?.message, true);
        console.log("Error****:", error.message);
        dispatch({ type: "MAIN_LOADING", payload: false });
      });
  };
};

export const getCategorytypeOptions = (payload, cb) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    dispatch({ type: "VENDOR_LOADING", payload: true });
    geoAxios
      .post("assetManagement/getType", payload)
      .then(({ data }) => {
        if (data?.success) {
          callback();
        } else {
          dispatch({ type: FETCH_ERROR, payload: data?.message });
          dispatch({
            type: "CATEGORY_TYPES",
            payload: [],
          });
        }
        function callback() {
          dispatch({ type: FETCH_SUCCESS });
          if(data?.data && data?.data?.length > 0){
            const prepared = data?.data?.map(type => {
              return {label: type, value: type};
            })
            dispatch({
              type: "CATEGORY_TYPES",
              payload: prepared,
            });
          }
          if (cb) {
            cb(data?.data);
          }
        }
        dispatch({ type: "VENDOR_LOADING", payload: false });
      })
      .catch(function (error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
        cb && cb();
        console.log("Error****:", error.message);
        dispatch({ type: "VENDOR_LOADING", payload: false });
      });
  };
};

export const getVendorAssetData = (data, cb) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    dispatch({ type: "VENDOR_LOADING", payload: true });
    const body = {
      deviceType: "all",
      // accessKey: findGetParameter("accesskey"),
      ...data,
    };
    geoAxios
      .post("assetManagement/getVendorAssetCount", body)
      .then(({ data }) => {
        if (data?.success) {
          callback();
        } else {
          dispatch({ type: FETCH_ERROR, payload: data?.message });
          dispatch({
            type: GET_VENDOR_ASSET_DATA,
            payload: { rows: [], count: 0 },
          });
        }
        async function callback() {
          dispatch({ type: FETCH_SUCCESS });
          const root = await protobuf.load(assetMgtVendorProtoFile);
          const Message = root.lookupType('LynktracData');
          const parsedData = Message.decode(data?.data?.data);
          dispatch({
            type: GET_VENDOR_ASSET_DATA,
            payload: { rows: parsedData?.userdata, count: data?.count },
          });
          if (cb) {
            cb(parsedData?.devices);
          }
        }
        dispatch({ type: "VENDOR_LOADING", payload: false });
      })
      .catch(function (error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
        cb && cb();
        console.log("Error****:", error.message);
        dispatch({ type: "VENDOR_LOADING", payload: false });
      });
  };
};

export const setSelectedItemId = (data) => ({
  type: SET_SELECTED_ITEM_ID,
  payload: data,
});

export const flushAllCategoryData = () => ({
  type: "FLUSH_VENDOR_ASSET_DATA",
});

export const flushAllCategoryTypes = () => ({
  type: "FLUSH_CATEGORY_TYPES",
});
