/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom/cjs/react-router-dom";
import { Grid, Typography, Paper, Box } from "@mui/material";
import EmptyPage from '../EmptyPage';
import { bsjCameraConstants } from '../../../services/constants';

const BsjCamera = ({ zoom, setShowAudio, channelsCount ,deviceDetails }) => {
    const { id } = useParams();
    const [deviceNumber, setDeviceNumber] = useState('')
    const [cameraOffline, setCameraOffline] = useState(null)
    var uc1;

    useEffect(() => {
        if (id) {
            setDeviceNumber(id)
        }
    }, [id])

    useEffect(() => {
        function init() {
            uc1 = CvNetVideo.Init(document.querySelector("#player"), channelsCount.length,
                {
                    callback: function () {
                        [].forEach.call(document.querySelectorAll("button"), function (btn) {
                            btn.disabled = false;
                        });
                    },
                    selectedEvent: function (e) {
                        var selected = e;
                    },
                    events: {
                        onCapture: onCapture,
                        timeoutWarning: timeoutWarning,
                        timeoutCanceled: timeoutCanceled,
                        timeoutClose: timeoutClose,
                        onStop: onVideoStop,
                        onServerNotice: onServerNotice,
                        onEndByServer: onEndByServer,
                        onHlsPlay: onHlsPlay,
                        onChangeH265Player: onChangeH265Player
                    }
                });
                setTimeout(() => {
                    for (let i = 1; i <= channelsCount.length; i++) {
                    Connect(channelsCount[i-1],i)
                }
            }, 1000)
        }
        if (CvNetVideo && deviceNumber && channelsCount && deviceDetails && Object.keys(deviceDetails).length) {
            setTimeout(()=>{
                init();
            },50)
        }
    }, [CvNetVideo, deviceNumber, channelsCount, deviceDetails])

    function Connect(i,index) {
        var mideaType;
        if (bsjCameraConstants.mediaType == 1) {
            mideaType = 203;
        }
        else if (bsjCameraConstants.mediaType == 0) {
            mideaType = true;
        }
        else {
            mideaType = false;
        }
        CvNetVideo?.StartRealTimeVideo(deviceNumber, Number(i), parseInt(bsjCameraConstants.streamType), mideaType, index,
            {
                clusterHost: bsjCameraConstants.server,
                clusterPort: bsjCameraConstants.port,
                seekMode: bsjCameraConstants.frameTracking,
                protocol: bsjCameraConstants.protocol
            },
            null,
            parseInt(0)
        );
        // CvNetVideo.SetOsdColor("#ff7200", id);
        // CvNetVideo.SetOsdText(id, 'Lynkit');
        // CvNetVideo.SetOsdVisible(true, 0);
    }


    function onCapture(base64png, id, ucVideo) {

    }
    function timeoutWarning(leftMsec, time) {

    }
    function timeoutCanceled() {

    }
    function timeoutClose(time) {

    }
    function onVideoStop(id, ucVideo) {

    }
    function onServerNotice(type, id, ucVideo) {
        // console.log("event:" + type + " videoid:" + id);
    }
    function onEndByServer(msg, id, ucVideo) {
        setCameraOffline(true)
    }
    function onHlsPlay(id, ucVideo, hlshttp, hlshttps, rtmpurl, cid, flvhttp, flvhttps) {

    }
    function onChangeH265Player(playermode) {

    }

    useEffect(() => {
        const handleDomMutation = (mutationsList) => {
            for (const mutation of mutationsList) {
                if (mutation.type === 'childList') {
                    const addedNodes = Array.from(mutation.addedNodes);
                    addedNodes.forEach(node => {
                        if (node.nodeType === Node.ELEMENT_NODE && node.classList.contains('video-container')) {

                            setTimeout(()=>{
                                applyStyles(node, channelsCount.length);
                            },100)
                        }
                    });
                }
            }
        };

        const observer = new MutationObserver(handleDomMutation);
        observer.observe(document.documentElement, { childList: true, subtree: true });
        return () => {
            observer.disconnect();
        };
    }, []);

    useEffect(() => {
        const containers = document.querySelectorAll('.video-container');
        containers.forEach(container => applyStyles(container, channelsCount.length));
    }, [channelsCount]);
    


    const applyStyles = (container, cameraCount) => {
        const childDivs = Array.from(container.querySelectorAll('.video-container > div'));
        const firstChildDiv = childDivs[0];
        const secondChildDiv = childDivs[1];
        const thirdChildDiv = childDivs[2];
        const fourthChildDiv = childDivs[3];

        const checkVideoTag = (childDiv, index) => {
            if (childDiv) {
                const videoTag = childDiv.querySelector('video');
                if (videoTag) {
                    const handleSrcChange = () => {
                        const videoSrc = videoTag.getAttribute('src');
                        if (videoSrc) {
                            setShowAudio(true);
                            videoTag.muted = true;
                        } else {
                        }
                    };

                    handleSrcChange();

                    const srcObserver = new MutationObserver(() => {
                        handleSrcChange();
                    });
                    srcObserver.observe(videoTag, { attributes: true, attributeFilter: ['src'] });
                } else {
                }
            }
        };

        childDivs.forEach((childDiv, index) => {
            checkVideoTag(childDiv, index);
        });
        if (childDivs.length > 0) {
            if (cameraCount === 1) {
                firstChildDiv.style.setProperty('width', '100%', 'important');
                firstChildDiv.style.setProperty('height', '100%', 'important');

                secondChildDiv.style.setProperty('display', 'none', 'important');
                thirdChildDiv.style.setProperty('display', 'none', 'important');
                fourthChildDiv.style.setProperty('display', 'none', 'important');
            }
            else if (cameraCount === 2) {
                firstChildDiv.style.setProperty('width', '50%', 'important');
                firstChildDiv.style.setProperty('height', '100%', 'important');

                secondChildDiv.style.setProperty('width', '50%', 'important');
                secondChildDiv.style.setProperty('height', '100%', 'important');
                secondChildDiv.style.setProperty('border-left', '1px solid #fff', 'important');

                secondChildDiv.style.setProperty('position', 'absolute', 'important');
                secondChildDiv.style.setProperty('left', '50%', 'important');
                secondChildDiv.style.setProperty('top', '0px', 'important');

                thirdChildDiv.style.setProperty('display', 'none', 'important');
                fourthChildDiv.style.setProperty('display', 'none', 'important');
            }
            else if (cameraCount === 3) {
                firstChildDiv.style.setProperty('width', '50%', 'important');
                firstChildDiv.style.setProperty('height', '50%', 'important');
                firstChildDiv.style.setProperty('border-bottom', '1px solid #fff', 'important');

                secondChildDiv.style.setProperty('width', '50%', 'important');
                secondChildDiv.style.setProperty('height', '50%', 'important');
                secondChildDiv.style.setProperty('position', 'absolute', 'important');
                secondChildDiv.style.setProperty('left', '50%', 'important');
                secondChildDiv.style.setProperty('top', '0px', 'important');
                secondChildDiv.style.setProperty('border-left', '1px solid #fff', 'important');
                secondChildDiv.style.setProperty('border-bottom', '1px solid #fff', 'important');

                thirdChildDiv.style.setProperty('width', '100%', 'important');
                thirdChildDiv.style.setProperty('height', '50%', 'important');
                thirdChildDiv.style.setProperty('top', '50%', 'important');

                // fourthChildDiv.style.setProperty('width', '00%', 'important');
                // fourthChildDiv.style.setProperty('height', '00%', 'important');
                // fourthChildDiv.style.setProperty('display', 'none', 'important');

                // fourthChildDiv.style.setProperty('left', '50%', 'important');
                // fourthChildDiv.style.setProperty('top', '50%', 'important');
                // fourthChildDiv.style.setProperty('border-left', '1px solid #fff', 'important');

                fourthChildDiv.style.setProperty('display', 'none', 'important');

            } else if (cameraCount === 4) {
                firstChildDiv.style.setProperty('width', '50%', 'important');
                firstChildDiv.style.setProperty('height', '50%', 'important');
                firstChildDiv.style.setProperty('border-bottom', '1px solid #fff', 'important');

                secondChildDiv.style.setProperty('width', '50%', 'important');
                secondChildDiv.style.setProperty('height', '50%', 'important');
                secondChildDiv.style.setProperty('position', 'absolute', 'important');
                secondChildDiv.style.setProperty('left', '50%', 'important');
                secondChildDiv.style.setProperty('top', '0px', 'important');
                secondChildDiv.style.setProperty('border-left', '1px solid #fff', 'important');
                secondChildDiv.style.setProperty('border-bottom', '1px solid #fff', 'important');

                thirdChildDiv.style.setProperty('width', '50%', 'important');
                thirdChildDiv.style.setProperty('height', '50%', 'important');
                thirdChildDiv.style.setProperty('top', '50%', 'important');

                fourthChildDiv.style.setProperty('width', '50%', 'important');
                fourthChildDiv.style.setProperty('height', '50%', 'important');
                fourthChildDiv.style.setProperty('position', 'absolute', 'important');
                fourthChildDiv.style.setProperty('left', '50%', 'important');
                fourthChildDiv.style.setProperty('top', '50%', 'important');
                fourthChildDiv.style.setProperty('border-left', '1px solid #fff', 'important');
            }
            else {
                firstChildDiv.style.setProperty('width', '50%', 'important');
                firstChildDiv.style.setProperty('height', '50%', 'important');
                firstChildDiv.style.setProperty('border-bottom', '1px solid #fff', 'important');

                secondChildDiv.style.setProperty('width', '50%', 'important');
                secondChildDiv.style.setProperty('height', '50%', 'important');
                secondChildDiv.style.setProperty('position', 'absolute', 'important');
                secondChildDiv.style.setProperty('left', '50%', 'important');
                secondChildDiv.style.setProperty('top', '0px', 'important');
                secondChildDiv.style.setProperty('border-left', '1px solid #fff', 'important');
                secondChildDiv.style.setProperty('border-bottom', '1px solid #fff', 'important');

                thirdChildDiv.style.setProperty('width', '50%', 'important');
                thirdChildDiv.style.setProperty('height', '50%', 'important');
                thirdChildDiv.style.setProperty('top', '50%', 'important');

                fourthChildDiv.style.setProperty('width', '50%', 'important');
                fourthChildDiv.style.setProperty('height', '50%', 'important');
                fourthChildDiv.style.setProperty('position', 'absolute', 'important');
                fourthChildDiv.style.setProperty('left', '50%', 'important');
                fourthChildDiv.style.setProperty('top', '50%', 'important');
                fourthChildDiv.style.setProperty('border-left', '1px solid #fff', 'important');
            }
        }

    };

    return (
        <>
            <Grid container sx={{ width: '100%', height: '81vh' }}>
                {cameraOffline ?
                    <Grid
                        container
                        item={true}
                        direction={"column"}
                        justifyContent={"center"}
                        alignItems="center"
                        xs={12}
                        sx={{ p: 15 }}
                    >
                        <Grid item>
                            <Typography>Camera is not online.</Typography>
                        </Grid>
                    </Grid>
                    :
                    <Grid item xs={12}>
                        <div id="player" style={{ width: '99.5%', height: zoom ? '100vh' : '98%' }}>
                        </div>
                    </Grid>
                }
            </Grid>
        </>
    );
};

export default BsjCamera;