import { Box, Grid, Typography } from "@mui/material";
import React from "react";

const ImgContainer = ({ src, title = "", body = "", height }) => {
  return (
    <Grid container height={height} data-testid="empty-screen">
      <Grid item container xs={12}>
        <Box
          width="100%"
          mt={4}
          display="flex"
          flexDirection="column"
          alignItems="center"
          gap={2}
        >
          <Box className="img-container--outer">
            <img src={src} />
          </Box>
          <Typography variant="h5" textAlign="center">
            {title}
          </Typography>
          <Typography
            variant="body2"
            sx={{ whiteSpace: "pre-line", textAlign: "center" }}
          >
            {body}
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
};

export default ImgContainer;
