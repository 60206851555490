import {
    FETCH_ERROR,
    FETCH_START,
    FETCH_SUCCESS,
    SHOW_MESSAGE,
    FETCH_TEMPLATE,
    FETCH_TEMPLATES,
    FETCH_STD_COMPS,
    FETCH_PRV_COMPS,
    SAVE_TEMPLATE,
    FETCH_TEMPLATES_ERR,
    FETCH_USERS,
    FETCH_STATUS_COUNT,
    FETCH_REGEX_LIST,
    FETCH_ALL_TEMPLATE_TYPES,
    TEMPLATE_EXISTENCE

} from "../types";
import axios, { templateAxios } from "../../services/Api";
import { showToast } from "../../services/functions";
import history from "../../services/history";

export const getTemplateTypes = () => {
    return (dispatch) => {
        dispatch({ type: FETCH_START });
        templateAxios.post('dynamicForm/getTemplateTypes', {
            fetchSingle: false
        }
        ).then(({ data }) => {
            if (data.success) {
                callback();
            }
            else {
                dispatch({ type: FETCH_ERROR, payload: data.message });
                dispatch({ type: FETCH_ALL_TEMPLATE_TYPES, payload: [] })

            }

            function callback() {
                dispatch({ type: FETCH_SUCCESS });
                dispatch({ type: FETCH_ALL_TEMPLATE_TYPES, payload: data.data })
            }
        }).catch(function (error) {
            dispatch({ type: FETCH_ERROR, payload: error.message });
            console.log("Error****:", error.message);
        });
    }
};

export const getTemplate = (payload, cb) => {
    return (dispatch) => {
        dispatch({ type: FETCH_START });
        templateAxios.post('dynamicForm/getTemplateDetail', payload).then(({ data }) => {
            if (data.success) {
                dispatch({ type: FETCH_SUCCESS });
                dispatch({ type: FETCH_TEMPLATE, payload: data.data })
            }
            else {
                dispatch({ type: FETCH_ERROR, payload: data.message });
                dispatch({ type: FETCH_TEMPLATE, payload: {} })
            }

            if (cb) {
                cb(data?.data || {})
            }

        }).catch(function (error) {
            dispatch({ type: FETCH_ERROR, payload: error.message });
            console.log("Error****:", error.message);
            if (cb) {
                cb()
            }
        });
    }
};

export const getAllTemplates = (body, cb) => {
    return (dispatch) => {
        dispatch({ type: FETCH_START });
        templateAxios.post('dynamicForm/getTemplateList', body).then(({ data }) => {
            if (data.success) {
                dispatch({ type: FETCH_SUCCESS });
                dispatch({ type: FETCH_TEMPLATES, payload: { row: data.data, count: data.count } })
            }
            else {
                dispatch({ type: FETCH_TEMPLATES_ERR, payload: data.message });
                dispatch({ type: FETCH_TEMPLATES, payload: { row: [], count: 0 } })
            }

            if (cb) {
                cb(data?.data || []);
            }

        }).catch(function (error) {
            dispatch({ type: FETCH_ERROR, payload: error.message });
            console.log("Error****:", error.message);
            if (cb) {
                cb();
            }
        });
    }
};

export const getStandardComonents = ({ type }) => {
    return (dispatch) => {
        dispatch({ type: FETCH_START });
        templateAxios.post('dynamicForm/getStandardComponentList', {
            type: type,
        }
        ).then(({ data }) => {
            if (data.success) {
                callback();
            }
            else {
                dispatch({ type: FETCH_ERROR, payload: data.message });
            }

            function callback() {
                dispatch({ type: FETCH_SUCCESS });
                dispatch({ type: FETCH_STD_COMPS, payload: data.data })
            }
        }).catch(function (error) {
            dispatch({ type: FETCH_ERROR, payload: error.message });
            console.log("Error****:", error.message);
        });
    }
};

export const getPreviousComonents = ({ type }) => {
    return (dispatch) => {
        dispatch({ type: FETCH_START });
        templateAxios.post('dynamicForm/getUsedComponentList', {
            type: type,
        }
        ).then(({ data }) => {
            if (data.success) {
                callback();
            }
            else {
                dispatch({ type: FETCH_ERROR, payload: data.message });
            }

            function callback() {
                dispatch({ type: FETCH_SUCCESS });
                dispatch({ type: FETCH_PRV_COMPS, payload: data.data })
            }
        }).catch(function (error) {
            dispatch({ type: FETCH_ERROR, payload: error.message });
            console.log("Error****:", error.message);
        });
    }
};

export const saveTemplate = (body, cb) => {
    return (dispatch) => {
        dispatch({ type: FETCH_START });
        templateAxios.post('dynamicForm/saveTemplateDetailV2', {
            ...body
        }
        ).then(({ data }) => {
            if (data.success) {
                callback(data);
            }
            else {
                dispatch({ type: FETCH_ERROR, payload: data.message });
                showToast(data.message, true);
            }

            function callback(data) {
                dispatch({ type: FETCH_SUCCESS });
                showToast("Template created successfully", false)
                dispatch(getAllTemplates())
                cb(data.data);

            }
        }).catch(function (error) {
            dispatch({ type: FETCH_ERROR, payload: error.message });
            showToast(error.message, true);
            console.log("Error****:", error.message);
        });
    }
};

export const editTemplate = (body, cb) => {
    return (dispatch) => {
        dispatch({ type: FETCH_START });
        templateAxios.post('dynamicForm/editTemplateDetails', {
            ...body
        }
        ).then(({ data }) => {
            if (data.success) {
                callback();
            }
            else {
                dispatch({ type: FETCH_ERROR, payload: data.message });
                showToast(data.message, true);
            }

            function callback() {
                dispatch({ type: FETCH_SUCCESS });
                showToast("Template edited successfully", false)
                dispatch(getAllTemplates())
                history.push("/dashboard/manage-templates");
                cb();

            }
        }).catch(function (error) {
            dispatch({ type: FETCH_ERROR, payload: error.message });
            showToast(error.message, true);
            console.log("Error****:", error.message);
        });
    }
};

// export const getTemplate = ({templateId}) =>
// {
//     return (dispatch) =>
//     {
//         dispatch({type: FETCH_START});
//         axios.post('dynamicForm/getTemplateDetail', {
//             template_id: templateId
//         }
//         ).then(({data}) => {
//         if (data.success) 
//         {
//             callback();
//         } 
//         else 
//         {
//             dispatch({type: FETCH_ERROR, payload: data.message});
//         }

//         function callback() 
//         {
//             dispatch({ type: FETCH_SUCCESS });
//             dispatch({ type: FETCH_TEMPLATE, payload: data.data})
//         }
//         }).catch(function (error) {
//             dispatch({type: FETCH_ERROR, payload: error.message});
//             console.log("Error****:", error.message);
//         });
//     }
// };

// export const getTemplateTypes = () =>
// {
//     return (dispatch) =>
//     {
//         dispatch({type: FETCH_START});
//         axios.post('dynamicForm/getTemplateDetail', {
//             template_id: templateId
//         }
//         ).then(({data}) => {
//         if (data.success) 
//         {
//             callback();
//         } 
//         else 
//         {
//             dispatch({type: FETCH_ERROR, payload: data.message});
//         }

//         function callback() 
//         {
//             dispatch({ type: FETCH_SUCCESS });
//             dispatch({ type: FETCH_TEMPLATE, payload: data.data})
//         }
//         }).catch(function (error) {
//             dispatch({type: FETCH_ERROR, payload: error.message});
//             console.log("Error****:", error.message);
//         });
//     }
// };


export const getStatusCount = (payload) => {
    return (dispatch) => {
        dispatch({ type: FETCH_START });
        templateAxios.post('dynamicForm/getTemplateStatusCount', {
            ...payload
        }
        ).then(({ data }) => {
            if (data.success) {
                callback();
            }
            else {
                dispatch({ type: FETCH_ERROR, payload: data.message });
            }

            function callback() {
                dispatch({ type: FETCH_SUCCESS });
                dispatch({ type: FETCH_STATUS_COUNT, payload: data.data })
            }
        }).catch(function (error) {
            dispatch({ type: FETCH_ERROR, payload: error.message });
            console.log("Error****:", error.message);
        });
    }
};

export const getRegexList = () => {
    return (dispatch) => {
        dispatch({ type: FETCH_START });
        templateAxios.post('dynamicForm/getRegexList', {
            type: "wms"
        }
        ).then(({ data }) => {
            if (data.success) {
                callback();
            }
            else {
                dispatch({ type: FETCH_ERROR, payload: data.message });
            }

            function callback() {
                dispatch({ type: FETCH_SUCCESS });
                dispatch({ type: FETCH_REGEX_LIST, payload: data.data })
            }
        }).catch(function (error) {
            dispatch({ type: FETCH_ERROR, payload: error.message });
            console.log("Error****:", error.message);
        });
    }
};

export const changeTemplateStatus = (body, cb) => {
    return (dispatch) => {
        dispatch({ type: FETCH_START });
        templateAxios.post('dynamicForm/changeTemplateStatus', {
            ...body
        }
        ).then(({ data }) => {
            if (data.success) {
                callback();
            }
            else {
                dispatch({ type: FETCH_ERROR, payload: data.message });
                showToast(data.message, true);
            }

            function callback() {
                dispatch({ type: FETCH_SUCCESS });
                showToast("Template status changed successfully", false)
                // dispatch(getAllTemplates())
                if (cb) {
                    cb();
                }


            }
        }).catch(function (error) {
            dispatch({ type: FETCH_ERROR, payload: error.message });
            showToast(error.message, true);
            console.log("Error****:", error.message);
        });
    }
};

export const removeTemplates = (body, cb) => {
    return (dispatch) => {
        dispatch({ type: FETCH_START });
        templateAxios.post('dynamicForm/deleteTemplate', {
            ...body
        }
        ).then(({ data }) => {
            if (data.success) {
                callback();
            }
            else {
                dispatch({ type: FETCH_ERROR, payload: data.message });
                showToast(data.message, true);
            }

            function callback() {
                dispatch({ type: FETCH_SUCCESS });
                showToast("Template deleted successfully", false)
                // dispatch(getAllTemplates())
                if (cb) {
                    cb();
                }
                cb();

            }
        }).catch(function (error) {
            dispatch({ type: FETCH_ERROR, payload: error.message });
            showToast(error.message, true);
            console.log("Error****:", error.message);
        });
    }
};
export const checkTemplateExistance = (payload, callback) => {
    return (dispatch) => {
      dispatch({ type: FETCH_START });
      templateAxios.post("dynamicForm/chkTemplate", {...payload})
        .then(({ data }) => {
          if (data.success) {
            dispatch({ type: FETCH_SUCCESS });
            dispatch({ type: TEMPLATE_EXISTENCE, payload: data.data})
            
          } 
          else {
            dispatch({ type: FETCH_ERROR });
          }
  
          if (callback) {
            callback({ success: data.success || false });
          }
        })
        .catch(function (error) {
          dispatch({
            type: FETCH_ERROR,
            payload: error?.response?.data?.message,
          });
          if (callback) {
            callback({ success: false });
          }
          console.log("Error****:", error.message);
        });
    };
  };