import {
    SHOW_MESSAGE,
    FETCH_START,
    FETCH_SUCCESS,
    FETCH_ERROR,
    IMAGE_PATH,
    GET_IMAGE_PATH,
    EMPTY_IMAGE_PATH,
    EMPTY_IMAGE
  } from "../types";
  import axios from "../../services/Api";
  import { showToast } from "../../services/functions";
  import history from "../../services/history";
  
  export const uploadImagetoS3 = (payload, callback) => {
    return (dispatch) => {
      dispatch({ type: FETCH_START });
      dispatch({ type: EMPTY_IMAGE });
      axios
        .post("/uploadObj", payload)
        .then(({ data }) => {
          if (data.success) {
            dispatch({ type: FETCH_SUCCESS, payload: data.success });
            // dispatch({ type: SHOW_MESSAGE, payload: data.message });
            dispatch({
              type: IMAGE_PATH,
              payload: { rows: data.data, count: data.count },
            });
          } else {
            dispatch({ type: FETCH_ERROR, payload: data.message });
            showToast(data.message, true);
          }
  
          if (callback) {
            callback({ success: data.success || false, message: data.message, data: data?.data || undefined });
          }
        })
        .catch(function (error) {
          dispatch({
            type: FETCH_ERROR,
            payload: error?.response?.data?.message,
          });
          showToast(error?.response?.data?.message, true);
          if (callback) {
            callback({ success: false, message: error?.response?.data?.message });
          }
          console.log("Error****:", error.message);
        });
    };
  };

  export const emptyImage = (payload, callback) => {
    return (dispatch) => {
      dispatch({ type: EMPTY_IMAGE });
    }}
  
  export const getObj = (payload, callback) => { 
    return (dispatch) => {
      dispatch({ type: FETCH_START });
      dispatch({ type: EMPTY_IMAGE_PATH });
      axios
        .post("/getObj", payload)
        .then(({ data }) => {
          if (data.success) {
            dispatch({ type: FETCH_SUCCESS, payload: data.success });
            dispatch({ type: SHOW_MESSAGE, payload: data.message });
            dispatch({
              type: GET_IMAGE_PATH,
              payload: { rows: data.data, count: data.count },
            });
          } else {
            dispatch({ type: FETCH_ERROR, payload: data.message });
          }
  
          if (callback) {
            callback(data);
          }
        })
        .catch(function (error) {
          dispatch({
            type: FETCH_ERROR,
            payload: error?.response?.data?.message,
          });
          if (callback) {
            callback(error?.response?.data);
          }
          showToast(error?.response?.data?.message, true)
          console.log("Error****:", error.message);
        });
    };
  };
  export const emptyObj = (payload, callback) => {
    return (dispatch) => {
      dispatch({ type: EMPTY_IMAGE_PATH });
    }}