import {
  Grid,
  Typography,
  Card,
  CardHeader,
  IconButton,
  CardContent,
  Box,
  Tooltip,
} from "@mui/material";
import { Close, SellOutlined } from "@mui/icons-material";
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import s from "../../Devices/components/components.module.css";
import { getOrganisation } from "../../../../redux/actions";
import { format } from "date-fns";
import { convertIntoGivenTimezone } from "../../../../services/functions";

const OrganisationDetailCard = ({ selectedOrg, onClose, userDetails }) => {
  const selectedOrganisation = useSelector(
    (state) => state.Organisationlisting.selectedOrganisation[0]
  );

  const dispatch = useDispatch();
  const isDarkThemeEnabledSelector = useSelector(
    (state) => state.theme.themeDark
  );
  const themeType = isDarkThemeEnabledSelector
    ? require("../../../../static/styles/darktheme")
    : require("../../../../static/styles/theme");
  const {
    buttonClasses,
    cardClasses,
    inputClasses,
    textClasses,
    tableClasses,
    dividerClass,
  } = themeType;

  useEffect(() => {
    if (selectedOrg && selectedOrg._id) {
      dispatch(getOrganisation({ id: selectedOrg._id }));
    }
  }, [dispatch, selectedOrg]);

  const initialOrgData = [
    {
      key: "Organisation Name",
      value: "-",
    },
    { key: "Address", value: "-" },
    { key: "State", value: "-" },
    { key: "City", value: "-" },
    { key: "AES key", value: "-" },
    { key: "Parent Company", value: "-" },
    { key: "Status", value: "false" },
    { key: "Added By", value: "-" },
    { key: "Added On", value: "-" },
    { key: "Last updated by", value: "-" },
    { key: "Last updated on", value: "-" },
  ];

  const [orgData, setOrgData] = useState(initialOrgData);

  useEffect(() => {
    if (selectedOrganisation) {
      setOrgData([
        {
          key: "Organisation Name",
          value: (
            <Tooltip placement="top" title={selectedOrganisation?.name ?? ""}>
              <Typography
                data-testid="nameDetailed"
                sx={{
                  ...textClasses.t12n,
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {selectedOrganisation?.name ?? "-"}
              </Typography>
            </Tooltip>
          ),
        },
        {
          key: "Address",
          value: (
            <Tooltip
              placement="top"
              title={selectedOrganisation?.addressDetails?.address ?? ""}
            >
              <Typography
                data-testid="nameDetailed"
                sx={{
                  ...textClasses.t12n,
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {selectedOrganisation?.addressDetails?.address
                  ? selectedOrganisation?.addressDetails?.address === ""
                    ? "-"
                    : selectedOrganisation?.addressDetails?.address
                  : "-"}
              </Typography>
            </Tooltip>
          ),
        },
        {
          key: "State",
          value:
            selectedOrganisation?.addressDetails?.state !== ""
              ? selectedOrganisation?.addressDetails?.state
              : "-",
        },
        {
          key: "City",
          value:
            selectedOrganisation?.addressDetails?.city !== ""
              ? selectedOrganisation?.addressDetails?.city
              : "-",
        },
        {
          key: "AES key",
          value:
            selectedOrganisation?.aesKey !== ""
              ? selectedOrganisation?.aesKey
              : "-",
        },
        {
          key: "Parent Company",
          value: (
            <Tooltip
              placement="top"
              title={selectedOrganisation?.parentCompany?.name ?? ""}
            >
              <Typography
                data-testid="nameDetailed"
                sx={{
                  ...textClasses.t12n,
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {selectedOrganisation?.parentCompany?.name !== ""
                  ? selectedOrganisation?.parentCompany?.name
                  : "-"}
              </Typography>
            </Tooltip>
          ),
        },
        {
          key: "Status",
          value: (
            <Box display="flex" gap={0.5} maxWidth="100%">
              <span
                style={{
                  color:
                    selectedOrganisation?.isApproved === true
                      ? themeType.default.themeGreen
                      : themeType.default.themeRed,
                }}
              >
                {selectedOrganisation?.isApproved === true
                  ? "Active"
                  : "Blocked"}
              </span>
              {selectedOrganisation?.remark && "("}
              <Tooltip
                placement="top"
                title={selectedOrganisation?.remark ?? ""}
              >
                <span
                  style={{
                    width: "100%",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {selectedOrganisation?.remark
                    ? `${selectedOrganisation?.remark}`
                    : ""}
                </span>
              </Tooltip>
              {selectedOrganisation?.remark && ")"}
            </Box>
          ),
          // value: selectedOrganisation?.isApproved ? "Active" : "Blocked",
        },
        {
          key: "Added By",
          value: (
            <Tooltip
              placement="top"
              title={selectedOrganisation?.addedBy?.name ?? ""}
            >
              <Typography
                data-testid="nameDetailed"
                sx={{
                  ...textClasses.t12n,
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {selectedOrganisation?.addedBy?.name !== ""
                  ? selectedOrganisation?.addedBy?.name
                  : "-"}
              </Typography>
            </Tooltip>
          ),
        },
        {
          key: "Added On",
          value: selectedOrganisation?.modifiedOn
            ? convertIntoGivenTimezone(
                selectedOrganisation?.modifiedOn,
                userDetails.timeZone.offset
              )
            : "-",
        },
        {
          key: "Last updated by",
          value: (
            <Tooltip
              placement="top"
              title={selectedOrganisation?.modifiedBy?.name ?? ""}
            >
              <Typography
                data-testid="nameDetailed"
                sx={{
                  ...textClasses.t12n,
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {selectedOrganisation?.modifiedBy?.name !== ""
                  ? selectedOrganisation?.modifiedBy?.name
                  : "-"}
              </Typography>
            </Tooltip>
          ),
        },
        // {
        //   key: "Last updated on",
        //   value: selectedOrganisation?.modifiedOn
        //     ? convertIntoGivenTimezone(selectedOrganisation?.modifiedOn, userDetails.timeZone.offset)
        //     : "-",
        // },
      ]);
    }
  }, [setOrgData, selectedOrganisation]);

  return (
    <>
      <Card
        className="ppmodal"
        data-testid="genericdetailedtable"
        sx={{
          ...cardClasses.basic,
          borderRadius: "8px",
          border: `1px solid ${themeType.default.themeOrange}`,
          position: "relative",
          height: "66vh",
          overflow: "auto",
        }}
      >
        <CardHeader
          sx={{
            height: "46px",
            backgroundColor: themeType.default.themeOrange,
          }}
          title={
            <Typography
              sx={{
                ...textClasses.t12n,
                color: "white",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                fontWeight: "400",
                fontSize: "20px",
                lineHeight: "24px",
              }}
            >
              Organisation Details
            </Typography>
          }
          action={
            <IconButton size="small" sx={{ p: 0, mb: 1 }}>
              <Close sx={{ color: "white" }} onClick={onClose} />
            </IconButton>
          }
        />

        <CardContent
          sx={{
            height: "550px",
            overflowY: "scroll",
            width: "100%",
            overflowX: "hidden",
          }}
        >
          {orgData.map((object) => {
            return (
              <Grid container sx={{ mt: 1 }}>
                <Grid item sm={6} xs={4}>
                  <Typography
                    sx={{
                      ...textClasses.t12n,
                      color: "#827878",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {/* {"-"} */}
                    {object.key}
                  </Typography>
                </Grid>
                <Grid
                  container
                  item
                  sm={6}
                  xs={8}
                  direction="row"
                  alignItems={"center"}
                  sx={{
                    ...textClasses.t12n,
                    color: "#827878",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  <Box sx={{ display: "flex", width: "100%" }}>
                    <Typography
                      sx={{
                        ...textClasses.t12n,
                        color: "#827878",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                      mr={1}
                    >
                      {":"}
                    </Typography>
                    <Typography
                      sx={{
                        ...textClasses.t12n,
                        color: "#827878",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        textAlign: "center",
                      }}
                    >
                      {object.value}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            );
          })}

          <Box mt={2} width="100%" sx={{overflow:'auto'}}>
            <table
              className={s["details-table"]}
              style={{
                width: "100%",
                ...textClasses?.t12n,
              }}
            >
              <thead>
                <tr
                  style={{
                    background: themeType?.default?.themeOrange,
                    borderTopLeftRadius: "5px",
                    borderTopRightRadius: "5px",
                  }}
                >
                  <td style={{ padding: "5px" }}>
                    <Typography
                      sx={{
                        ...textClasses.t12n,
                        color: "#fff",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      Primary Point of Contact
                    </Typography>
                  </td>
                  <td style={{ padding: "5px" }}>
                    <Typography
                      sx={{
                        ...textClasses.t12n,
                        color: "#fff",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    ></Typography>
                  </td>
                  <td style={{ padding: "5px" }}>
                    <Typography
                      sx={{
                        ...textClasses.t12n,
                        color: "#fff",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    ></Typography>
                  </td>
                  {/* <td style={{ padding: "5px" }}>
                    <Typography
                      sx={{
                        ...textClasses.t12n,
                        color: "#fff",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      Count
                    </Typography>
                  </td> */}
                </tr>
              </thead>
              <tbody>
                {/* {tableFields?.map((row, index) => {
                  return ( */}
                <tr key={1}>
                  <td style={{ padding: "5px" }}>
                    <Typography
                      sx={{
                        ...textClasses.t12n,
                        color: "#827878",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {selectedOrganisation?.representativeName ?? "-"}
                    </Typography>
                  </td>
                  <td style={{ padding: "5px" }}>
                    <Typography
                      sx={{
                        ...textClasses.t12n,
                        color: "#827878",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      <Typography
                        sx={{
                          ...textClasses.t12n,
                          color: "#827878",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {selectedOrganisation?.representativeEmail}
                      </Typography>
                      <Typography
                        sx={{
                          ...textClasses.t12n,
                          color: "#827878",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {selectedOrganisation?.representativeMobile ?? "-"}
                      </Typography>
                    </Typography>
                  </td>
                  <td style={{ padding: "5px" }}>
                    <Typography
                      sx={{
                        ...textClasses.t12n,
                        color: "#827878",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      <Typography
                        sx={{
                          ...textClasses.t12n,
                          color: "#827878",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {selectedOrganisation?.representativeDesignation ?? "-"}
                      </Typography>
                      <Typography
                        sx={{
                          ...textClasses.t12n,
                          color: "#827878",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {selectedOrganisation?.remark ?? "-"}
                      </Typography>
                    </Typography>
                  </td>
                </tr>
                {/* );
                })} */}
              </tbody>
            </table>
          </Box>
          <Box mt={2} width="100%">
            <table
              className={s["details-table"]}
              style={{
                width: "100%",
                ...textClasses?.t12n,
              }}
            >
              <thead>
                <tr
                  style={{
                    background: themeType?.default?.themeOrange,
                    borderTopLeftRadius: "5px",
                    borderTopRightRadius: "5px",
                  }}
                >
                  <td style={{ padding: "5px" }}>
                    <Typography
                      sx={{
                        ...textClasses.t12n,
                        color: "#fff",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      Users
                    </Typography>
                  </td>
                  <td style={{ padding: "5px" }}>
                    <Typography
                      sx={{
                        ...textClasses.t12n,
                        color: "#fff",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    ></Typography>
                  </td>
                  <td style={{ padding: "5px" }}>
                    <Typography
                      sx={{
                        ...textClasses.t12n,
                        color: "#fff",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    ></Typography>
                  </td>
                  <td style={{ padding: "5px" }}>
                    <Typography
                      sx={{
                        ...textClasses.t12n,
                        color: "#fff",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    ></Typography>
                  </td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td style={{ padding: "5px" }}>
                    <Typography
                      sx={{
                        ...textClasses.t12n,
                        color: "#827878",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      #
                    </Typography>
                  </td>
                  <td style={{ padding: "5px" }}>
                    <Typography
                      sx={{
                        ...textClasses.t12n,
                        color: "#827878",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      Role
                    </Typography>
                  </td>
                  <td style={{ padding: "5px" }}>
                    <Typography
                      sx={{
                        ...textClasses.t12n,
                        color: "#827878",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      Active
                    </Typography>
                  </td>
                  <td style={{ padding: "5px" }}>
                    <Typography
                      sx={{
                        ...textClasses.t12n,
                        color: "#827878",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      Block
                    </Typography>
                  </td>
                </tr>
                {selectedOrganisation?.userRoles &&
                  selectedOrganisation?.userRoles?.map((role, i) => {
                    return (
                      <tr key={i}>
                        <td style={{ padding: "5px" }}>
                          <Typography
                            sx={{
                              ...textClasses.t12n,
                              color: "#827878",
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}
                          >
                            {i + 1}
                          </Typography>
                        </td>
                        <td style={{ padding: "5px" }}>
                          <Typography
                            sx={{
                              ...textClasses.t12n,
                              color: "#827878",
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}
                          >
                            {role?.name ?? "-"}
                          </Typography>
                        </td>
                        <td style={{ padding: "5px" }}>
                          <Typography
                            sx={{
                              ...textClasses.t12n,
                              color: "#827878",
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}
                          >
                            {"-"}
                          </Typography>
                        </td>
                        <td style={{ padding: "5px" }}>
                          <Typography
                            sx={{
                              ...textClasses.t12n,
                              color: "#827878",
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}
                          >
                            {"-"}
                          </Typography>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </Box>
        </CardContent>
      </Card>
    </>
  );
};

export default OrganisationDetailCard;
