import React from "react";
import CustomModel from "../../../Components/CustomModels/CustomModel1";
import { Button, CircularProgress, Grid } from "@mui/material";
import EmailInput from "./EmailInput";
import { useSelector } from "react-redux";

const EnterEmailModal = ({ open, hide, loading = false, onSubmitFn }) => {
  const [state, setState] = React.useState({
    items: [],
    value: "",
    error: null,
  });

  const isDarkThemeEnabledSelector = useSelector(
    (state) => state.theme.themeDark
  );
  const themeType = isDarkThemeEnabledSelector
    ? require("../../../../static/styles/darktheme")
    : require("../../../../static/styles/theme");

  const { buttonClasses } = themeType;

  const isValid = (email) => {
    let error = null;

    if (isInList(email)) {
      error = `${email} has already been added.`;
    }

    if (!isEmail(email)) {
      error = `${email} is not a valid email address.`;
    }

    if (error) {
      setState((prev) => ({ ...prev, error }));

      return false;
    }

    return true;
  };

  const isInList = (email) => {
    if (state?.items && state?.items?.length > 0)
      return state?.items?.includes(email);
  };

  const isEmail = (email) => {
    return /[\w\d\.-]+@[\w\d\.-]+\.[\w\d\.-]+/.test(email);
  };

  const handleSubmitFn = () => {
    if (state?.value) {
      let value = state?.value?.trim();
      let itemsTemp = state?.items;

      if (value && isValid(value)) {
        itemsTemp?.push(state?.value);
        setState((prev) => ({
          ...prev,
          items: itemsTemp,
          value: "",
        }));
        onSubmitFn(itemsTemp ?? []);
      }
    } else {
      onSubmitFn(state?.items ?? []);
    }
  };

  const handleCheckCurrentValue = () => {};

  React.useEffect(() => {
    if (open) {
      setState({
        items: [],
        value: "",
        error: null,
      });
    }
  }, [open]);

  return (
    <CustomModel
      autoClose={false}
      show={open || false}
      onClose={() => hide()}
      minWidth={600}
      maxWidth={600}
      pb={3}
      pl={3}
      pr={3}
      maxHeight={"81vh"}
      childrenMaxHeight={"81vh"}
      Title={"Enter e-mail Ids"}
      viewCloseIcon={open}
    >
      <Grid container spacing={2} mt={0.5} py={1} mb={2}>
        <Grid item xs={12}>
          <EmailInput
            state={state}
            setState={setState}
            themeType={themeType}
            isDarkThemeEnabledSelector={isDarkThemeEnabledSelector}
            isValid={isValid}
            isInList={isInList}
          />
        </Grid>
        <Grid
          item
          xs={12}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Button
            variant="contained"
            sx={{ ...buttonClasses?.lynkitBlackFill }}
            onClick={handleSubmitFn}
            disabled={loading}
          >
            {loading && (
              <CircularProgress
                size={20}
                sx={{
                  color: "#383838",
                }}
              />
            )}
            {loading ? "Loading..." : "Submit"}
          </Button>
        </Grid>
      </Grid>
    </CustomModel>
  );
};

export default EnterEmailModal;
