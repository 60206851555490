import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Box, Typography, Dialog, DialogContent, Grid, IconButton } from '@mui/material'
import CustomModel from '../../Components/CustomModels/CustomModel1';
import { showToast } from '../../../services/functions';
import { getAllGeofences, deleteGeofence } from '../../../redux/actions';
import theme, { buttonClasses, textClasses } from '../../../static/styles/theme';
import { Cancel } from '@mui/icons-material';
import { sendCustomEventAnalytics } from '../../../utils/util';


function DeleteModal(props) {
    const dispatch = useDispatch();
    const { hide, action, sharedArray,  setDeleteStatus, setSharedArray } = props
    const { message, error } = useSelector((state) => state.common);
    const [showAll, setShowAll] = useState(false);
    const [callStatusChange, setCallStatusChange] = useState(false);
    const size = sharedArray.length;

    let payload = {
        "page": 1,
        "limit": 10,
        "filter": { "orgId": localStorage.getItem('orgId') },
        "selection": ["name", "description", "geofence", "centerCoordinates", "radius", "polygonType", "accessID", "status", "orgId", "addedOn", "addedBy", "modifiedOn", "modifiedBy"]
    }
   
    useEffect(() => {
        if (message && callStatusChange) {
            showToast(message, false)
            setCallStatusChange(false)
            dispatch(getAllGeofences(payload, () => {
            }))
            hide();
        }

        if (error && callStatusChange) {
            showToast(error, true)
            setCallStatusChange(false)
        }


    }, [message, error,])

    const handleRemoveSelection = (templateId) => {
        console.log(sharedArray);
        let dummy = [...sharedArray];
        if(templateId){
            dummy.splice(templateId,1)
            setSharedArray(dummy)
        }
    }

    const handleCloseConfirmModal = () => {
        // setCheckedArray([])
        setSharedArray([])
        setDeleteStatus(false)
    }
    const handleDeleteItem = () => {
        setCallStatusChange(true)
        let bodyData = {
            id : sharedArray.length == 1 ? sharedArray[0]._id : sharedArray.map(st => st._id)
        }
        sendCustomEventAnalytics("geofence", "Delete Geofence", "Delete Geofence")
        dispatch(deleteGeofence(bodyData, () => {
            setCallStatusChange(false)
            setSharedArray([])
        }))
    }


    return (
        <>
            <Dialog
                open={action != null && sharedArray.length !== 0}
                onClose={handleCloseConfirmModal}
                scroll={"paper"}
                PaperProps={{ sx: { width: "100%" } }}
                maxWidth={"sm"}
            >
                <DialogContent>
                    <Grid container justifyContent={"center"} alignItems="center">
                        <Typography sx={{ ...textClasses.cardTitle }}>Are you Sure ?</Typography>
                    </Grid>
                    <Grid container justifyContent={"center"} alignItems="center">
                        <Typography sx={{ ...textClasses.cardTitle }}>{action} the selected Geofence(s)</Typography>
                    </Grid>
                    <Grid container sx={{ mt: 1 }} spacing={1}>
                        {
                            sharedArray.slice(0, showAll ? size : 5).map((st, i) => {
                                return (
                                    <Grid item sm={4} xs={6} key={i}>
                                        <Grid container direction={"row"} justifyContent="center" alignItems={"center"} sx={{ backgroundColor: theme.themeLightOrange, border: `1px solid ${theme.themeOrange}`, borderRadius: "12px", pl: 1, pr: 1, minHeight: "40px" }}>
                                            <Grid item sm={10} xs={8}>
                                                <Typography sx={{ ...textClasses.normalText, color: theme.themeOrange, textAlign: "start" }}>
                                                    {(st.name && st.name.length > 15) ? st.name.substring(0, 15) + "..." : st.name}
                                                </Typography>

                                            </Grid>
                                            {
                                                handleRemoveSelection &&
                                                <Grid item sm={2} xs={4}>
                                                    <IconButton sx={{ alignContent: "right" }} onClick={() => handleRemoveSelection(st._id)}>
                                                        <Cancel sx={{ color: theme.themeOrange }} />
                                                    </IconButton>
                                                </Grid>
                                            }

                                        </Grid>
                                    </Grid>
                                )
                            })
                        }
                        {
                            size > 5 &&
                            <>
                                <Grid container item sm={4} xs={6} justifyContent="center" alignItems={"center"}>
                                    <Typography sx={{ ...textClasses.boldText, color: theme.themeOrange, textDecoration: "underline", "cursor": "pointer" }} onClick={() => setShowAll(!showAll)}>
                                        {
                                            showAll
                                                ?
                                                <>Collapse</>
                                                :
                                                <>View All ({size})</>
                                        }
                                    </Typography>
                                </Grid>

                            </>
                        }
                    </Grid>
                    <Grid container sx={{ p: 1, mt: 3 }} justifyContent="center">
                        <Button variant='outlined' size='small' sx={{ ...buttonClasses.outlined, borderColor: theme.themeOrange, color: theme.themeOrange, minWidth: "150px" }} onClick={handleCloseConfirmModal} >
                            No, Cancel
                        </Button>


                        <Button variant='contained' size='small' sx={{ ml: 1, ...buttonClasses.small, backgroundColor: theme.themeOrange, minWidth: "150px" }}
                            disabled={sharedArray.length <= 0}
                            onClick={handleDeleteItem}
                        >
                            Yes, {action}
                        </Button>
                    </Grid>
                </DialogContent>
            </Dialog>
        </>
    )
}

export default DeleteModal;
