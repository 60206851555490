
const activeUrl =  process.env.REACT_APP_ENV === "development" ? "https://testv2.lynktrac.io/"  : "/";
const activeLynkitUrl =  process.env.REACT_APP_ENV === "development" ? "https://www.beta.lynkit.io/api/"  : "/";
// const activeUrl = "/sds";



module.exports = {
  footerText: "Copyright Company Name © 2023.",
  socketURL: "/",
  templateURL: `${activeUrl}api/v1/common`,
  loginURL: `${activeUrl}api/v1/user`,
  commonBaseURL: `${activeUrl}api/v1/`,
  baseURL: `${activeUrl}api/v1/common`,
  devicesBaseURL: `${activeUrl}api/v1/device`, //Device
  deviceSampleFileUrl: `https://3.7.37.177:8777/public/`,
  locationUrl: `https://www.address.lynkit.in/v1/`,
  lynkitUrl: `${activeLynkitUrl}`,
  deviceTemplateBaseURL: `${activeUrl}api/v1/device`,
  geoURL: `${activeUrl}api/v1/geo-route`,
  reportURL: `${activeUrl}api/v1/report`,
  lynkitIoURL: ` https://beta.lynkit.io/api`,
  mapBoxToken: "pk.eyJ1IjoieW9naWU2NCIsImEiOiJjbHFkb2M4OHMwYm05MnFtZXhrZ3lraGw3In0.WzwvOmkRqFGugMUP74Kh-w",
  lynctracBaseURL: `${activeUrl}`
};

// module.exports = {
//   footerText: "Copyright Company Name © 2023.",
//   socketURL: "/",
//   templateURL: `https://testv2.lynktrac.io/api/v1/common`,
//   loginURL: `https://testv2.lynktrac.io/api/v1/user`,
//   commonBaseURL: `https://testv2.lynktrac.io/api/v1/`,
//   baseURL: `https://testv2.lynktrac.io/api/v1/common`,
//   devicesBaseURL: `https://testv2.lynktrac.io/api/v1/device`, //Device
//   deviceSampleFileUrl: `https://3.7.37.177:8777/public/`,
//   locationUrl: `https://www.address.lynkit.in/v1/`,
//   deviceTemplateBaseURL: `https://testv2.lynktrac.io/api/v1/device`,
//   geoURL: `https://testv2.lynktrac.io/api/v1/geo-route`,
//   lynkitIoURL: ` https://beta.lynkit.io/api`,
//   mapBoxToken: "pk.eyJ1IjoieW9naWU2NCIsImEiOiJjbHFkb2M4OHMwYm05MnFtZXhrZ3lraGw3In0.WzwvOmkRqFGugMUP74Kh-w",
//   lynctracBaseURL: `https://testv2.lynktrac.io`
// };



// ******************For Testing***********************
// module.exports = {
//   footerText: "Copyright Company Name © 2023.",
//   socketURL: "/",
//   templateURL: `https://test3.lynktrac.io/api/v1/common`,
//   loginURL: `https://test3.lynktrac.io/api/v1/user`,
//   commonBaseURL: `https://test3.lynktrac.io/api/v1/`,
//   baseURL: `https://test3.lynktrac.io/api/v1/common`,
//   devicesBaseURL: `https://test3.lynktrac.io/api/v1/device`, //Device
//   deviceSampleFileUrl: `https://3.7.37.177:8777/public/`,
//   locationUrl: `https://www.address.lynkit.in/v1/`,
//   deviceTemplateBaseURL: `https://test3.lynktrac.io/api/v1/device`,
//   geoURL: `https://test3.lynktrac.io/api/v1/geo-route`,
//   lynkitIoURL: ` https://beta.lynkit.io/api`,
//   mapBoxToken: "pk.eyJ1IjoieW9naWU2NCIsImEiOiJjbHFkb2M4OHMwYm05MnFtZXhrZ3lraGw3In0.WzwvOmkRqFGugMUP74Kh-w",
//   lynctracBaseURL: `https://test3.lynktrac.io/`
// };


