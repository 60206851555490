/* eslint-disable */
// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax
import {
  Button,
  Autocomplete,
  TextField,
  Grid,
  MenuItem,
  Paper,
  Menu,
  CardContent,
  Typography,
  FormControl,
  OutlinedInput,
  IconButton,
  InputAdornment,
  Card,
  InputLabel,
  TablePagination,
  CardHeader,
  Checkbox,
  Box,
  Tabs,
  Tab,
  Tooltip,
} from "@mui/material";
import {
  Close,
  ControlPoint,
  KeyboardArrowDown,
  Label,
  QrCodeScanner,
  QuestionMark,
  Search,
  Sell,
  SellOutlined,
  FilterAlt,
} from "@mui/icons-material";
import GeoIcon from "../../../static/images/sidebarIcons/GeoIcon";
import React, { useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import { useDispatch, useSelector } from "react-redux";
import FiltersWrapper from "../../Components/FiltersWrapper/FiltersWrapper";
import {
  getAllGeofences,
  viewGeofenceForDetail,
  geofenceStatus,
  getBoards,
  deleteGeofence,
  getGeofenceStatusCount,
  updateGeoListFlag,
  getAllOrganisations,
  getAllUsers,
  userDetails,
  flushGeofenceDetails,
  flushUsersList,
  saveFilters,
} from "../../../redux/actions";
import { deleteEmptyKeys, selectStylesOverride, sendCustomEventAnalytics } from "../../../utils/util";
import EmptyPage from "../EmptyPage";
import loaderGif from "../../../static/images/drawer/loader.gif";
import history from "../../../services/history";
import {
  capitalizeSentence,
  convertIntoGivenTimezone,
} from "../../../services/functions";
import DeleteModal from "./DeleteModal";
import { useParams } from "react-router-dom";
import { statusFields } from "../../../services/constants";
// import ExternalGeofence from "./ExternalGeofence"
import { useForm, Controller } from "react-hook-form";
import { useDebounce } from "../../../utils/hooks";
import ConfirmModal from "./ConfirmModal";
import { main } from "./helper";
import CustomSelect from "../../../components/FormUI/CustomSelect";
import { havePermission } from "../../../utils/permissionCheck";
import { setGeoTempFilter } from "../../../redux/reducers/GeofenceReducer";

export default function Geofence({ previousRoute }) {
  const dispatch = useDispatch();
  const {
    control,

    formState: { errors, dirtyFields },
  } = useForm();
  // selectors ---
  const orgid = useSelector((state) => state.auth.currentUserDetail)?.orgId;
  const userDetails = useSelector((state) => state.auth.currentUserDetail);

  const GeofencesListing = useSelector(
    (state) => state.geofencelist.geofenceListing
  );
  const { allFilters } = useSelector((state) => state.filters);
  const geoFlag = useSelector((state) => state.geofencelist.geoListFlag);
  const viewGeofenceDetail = useSelector(
    (state) => state.geofencelist.viewGeofenceForDetail
  );
  const filterFromStore = useSelector(state => state.geofencelist.filtersFromStore);
  const isDarkThemeEnabledSelector = useSelector(
    (state) => state.theme.themeDark
  );
  const themeType = isDarkThemeEnabledSelector
    ? require("../../../static/styles/darktheme")
    : require("../../../static/styles/theme");
  const {
    theme,
    buttonClasses,
    cardClasses,
    inputClasses,
    textClasses,
    tableClasses,
    dividerClass,
  } = themeType;

  let payload = {
    page: 1,
    limit: 10,
    searchValue: "",
    filter: {},
  };

  let payloadBoard = {
    module: "geofence",
    search: "",
    page: 1,
    limit: 10,
  };

  // local states ------
  // const [geoFilter, setGeoFilter] = useState({name: })
  const userPermissions =
    useSelector((state) => state.auth?.currentUserDetail) || {};
  const [condition, setCondition] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);
  const [actionIndex, setActionIndex] = useState(null);
  const [open, setOpen] = useState(false);
  const [tableIndex, setTableIndex] = useState(null);
  const [actionStatus, setActionStatus] = useState("");
  const [myMap, setMyMap] = useState(null);
  const [comment, setComment] = useState("");
  const [searchFields, setSearchFields] = useState({
    org: null,
    userId: null,
  });
  const [tableData, setTableData] = useState([]);
  const [deleteStatus, setDeleteStatus] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [checkedData, setCheckedData] = useState([]); // array of selected row's _id
  const [selectedTemplates, setSelectedTemplates] = useState([]);
  const [bulkStatus, setBulkStatus] = React.useState(null); //to check if all checked items are active or blocked or mixed
  const [selectedRow, setSelectedRow] = useState(null);
  const [selectedData, setSelectedData] = useState(null);
  const [hasLoaded, setHasLoaded] = useState(false);
  const totalRows = useSelector((state) => state.geofencelist.totalRows);
  const statusCounts = useSelector(
    (state) => state.geofencelist.geofenceStatusCount
  );
  const [loader, setLoader] = useState(true);
  const [sharedArray, setSharedArray] = useState([]);
  const [keysArray, setKeysArray] = useState(null);
  const [filter, setFilter] = useState();
  const [cardLoader, setCardLoader] = useState(true);
  const [selectedTab, setSelectedTab] = React.useState(0);
  const debouncedId = useDebounce(filter?.searchValue, 1000);
  const debouncedStatus = useDebounce(filter?.filter?.status, 400);
  const boardListing = useSelector(
    (state) => state.dynamicDashboard.boardsListing[0]
  );
  const currentUser = useSelector((state) => state.auth.currentUserDetail);
  const isSuperAdmin = currentUser?.adminSpecificPermissions || false;

  const orgListing = useSelector(
    (state) => state.Organisationlisting.OrganisationListing
  );
  const Users = useSelector((state) => state.userlist.allUsers);

  const showSavedFilters = React.useMemo(() => {
    if (previousRoute) {
      return previousRoute?.pathname?.includes("/geomap/edit-geo") ? true : false;
    } else {
      return false;
    }
  }, [previousRoute]);

  useEffect(() => {
    if (condition && orgid) {
      // setLoader(true);
      // dispatch(
      //   getAllGeofences(payload, () => {
      //     setLoader(false);
      //   })
      // );
      dispatch(getGeofenceStatusCount());
      setCondition(false);
    }
  }, [orgid]);

  useEffect(() => {
    if (currentUser) {
      if (currentUser && currentUser["adminSpecificPermissions"]) {
        dispatch(getAllOrganisations({ selection: ["name"] }));
      }
    }
  }, [currentUser]);

  useEffect(() => {
    if (currentUser && !currentUser["adminSpecificPermissions"]) {
      dispatch(getAllUsers({ orgId: currentUser._id, selection: ["name", "email", "orgName"] }));
    }
  }, [currentUser]);

  useEffect(() => {
    if (filterFromStore && previousRoute && showSavedFilters) {
      const tempFilters = filterFromStore;
      setFilter({ ...tempFilters });
    } else if (allFilters && allFilters?.geofenceFilter) {
      const geoFilter =
        typeof allFilters?.geoFilter === "string"
          ? JSON.parse(allFilters?.geofenceFilter)
          : allFilters?.geofenceFilters;
      setFilter((prev) => ({
        ...prev,
        page: 1,
        limit: 10,
        searchValue: "",
        filter: geoFilter,
      }));
    } else {
      setFilter({ ...payload });
    }
    if (!hasLoaded) {
      setKeysArray(null);
      dispatch(updateGeoListFlag(true));
      handleFetchBoards();
      dispatch(flushUsersList());
      setHasLoaded(true);
    }
  }, [dispatch, allFilters]);

  useEffect(() => {
    if (keysArray && keysArray !== null)
      handleFetchAllGeofences("keysArray effect");
  }, [keysArray]);

  const handleOpenAction = (event, i) => {
    setAnchorEl(event.currentTarget);
    setActionIndex(i);
    setTableIndex(i);
  };
  const handleCloseAction = () => {
    setActionIndex(null);
    setTimeout(() => {
      setAnchorEl(null);
    }, 1);
  };
  const handleSelectedRow = (row) => {
    setSelectedRow(row);
  };
  const handleFetchBoards = () => {
    dispatch(
      getBoards(payloadBoard, (keys) => {
        if (keys && keys?.length > 0) {
          setKeysArray(keys);
        } else {
          setKeysArray([]);
        }
      })
    );
  };
  const handleFetchAllGeofences = (text) => {
    setLoader(true);
    dispatch(
      getAllGeofences(
        {
          ...filter,
          selection: keysArray && keysArray?.length > 0 ? keysArray : [],
        },
        () => {
          setLoader(false);
          dispatch(updateGeoListFlag(false));
        }
      )
    );
  };

  useEffect(() => {
    if (selectedData) {
      let viewPayload = {
        id: selectedData?._id,
        selection: [
          "name",
          "description",
          "geofence",
          "centerCoordinates",
          "radius",
          "polygonType",
          "accessID",
          "status",
          // "orgId",
          "addedOn",
          "addedBy",
          "modifiedOn",
          "modifiedBy",
          "dynamicData",
          "address",
          "latitude",
          "longitude",
          "user",
        ],
      };

      setCardLoader(true);
      dispatch(
        viewGeofenceForDetail(viewPayload, () => {
          setCardLoader(false);
        })
      );
    }
  }, [selectedData]);

  const dynamiHeader =
    boardListing?.boardData?.map((item, index) => item.columnName) || [];

  const tableHeader = [
    <Typography
      sx={{
        ...textClasses.t13n,
        textTransform: "capitalize",
        color: themeType.default.themeOrange,
      }}
    >
      <Checkbox
        data-testid="checkbox"
        size="small"
        sx={{
          marginBottom: "4px",
          padding: "0px",
          color: "#ff7200",
          "&.Mui-checked": {
            color: "#ff7200",
          },
        }}
        checked={checkedData?.length === tableData?.length}
        onChange={(event) => handleSelectAll(event)}
      />
    </Typography>,
    "#",
    ...dynamiHeader,
    "Actions",
  ];

  const tableRows = GeofencesListing.map((item, index) => {
    let items = [];
    items.push(
      <Typography
        sx={{
          ...textClasses.t13n,
          textTransform: "capitalize",
          color: themeType.default.themeOrange,
        }}
      >
        <Checkbox
          size="small"
          sx={{
            marginBottom: "4px",
            padding: "0px",
            color: "#ff7200",
            "&.Mui-checked": {
              color: "#ff7200",
            },
          }}
          checked={checkedData?.includes(item._id) ? true : false}
          onChange={(event) => {
            handleCheckChange(event, item, index);
            setTableIndex(index);
          }}
        />
      </Typography>
    );
    items.push(
      <Typography sx={{ ...textClasses.normalText, textAlign: "center" }}>
        {(filter?.page - 1) * filter?.limit + index + 1}
      </Typography>
    );
    let rowKeysArr = boardListing?.boardData || [];
    for (let obj of rowKeysArr) {
      let eachColStr = "";
      for (let ob of obj.group) {
        let tmpInnerStr = main(
          ob.fnc,
          ob.key,
          ob.label,
          item,
          index,
          setSelectedRow,
          setSelectedData,
          userDetails
        );
        eachColStr += tmpInnerStr + "<br/>";
      }
      items.push(
        <Typography
          sx={{ ...textClasses.normalText, textAlign: "center" }}
          dangerouslySetInnerHTML={{ __html: eachColStr }}
        />
      );
    }

    items.push(
      <Typography
        sx={{
          ...textClasses.normalText,
          color: themeType.default.themeOrange,
          cursor: "pointer",
        }}
        onClick={(e) => handleOpenAction(e, index)}
      >
        Action <KeyboardArrowDown />
      </Typography>
    );
    return items;
  });

  const tabs = [
    {
      id: 0,
      key: "all",
      name: `All Geofences (${statusCounts ? statusCounts?.total : 0})`,
    },
    {
      id: 1,
      key: "active",
      name: `Active (${statusCounts?.active ?? 0})`,
    },
    {
      id: 2,
      key: "blocked",
      name: `In-Active (${statusCounts?.disabled ?? 0})`,
    },
  ];

  const handleTabsChange = (_, value) => {
    setSelectedTab(value);
    if (GeofencesListing && tabs[value]?.key === "active") {
      setFilter((existing) => ({
        ...existing,
        filter: {
          ...existing?.filter,
          status: "ACTIVE",
        },
      }));
    } else if (GeofencesListing && tabs[value]?.key === "blocked") {
      setFilter((existing) => ({
        ...existing,
        filter: {
          ...existing?.filter,
          status: "DISABLE",
        },
      }));
    } else if (GeofencesListing && tabs[value]?.key === "all") {
      let temp = { ...filter }
      delete temp?.filter
      setFilter(temp)

      // setFilter((existing) => ({
      //   ...existing,
      //   filter: {
      //     ...existing?.filter
      //   },
      // }));
    }
  };

  const viewEditGeofence = (item) => {
    if (item === "single") {
      dispatch(setGeoTempFilter(filter));
      history.push({
        pathname: `/dashboard/geomap/edit-geo/${tableData[tableIndex]._id}`,
        state: { param: "singleViewEdit", id: tableData[tableIndex]._id },
      });
    } else if (item === "all") {
      history.push({
        pathname: "/dashboard/geofence/view-all-geofence",
        state: { param: "all", data: GeofencesListing },
      });
    }
  };

  useEffect(() => {
    if (GeofencesListing) {
      setTableData(GeofencesListing);
    }
  }, [GeofencesListing]);

  const handleAction = (action) => {
    let sharedData = tableData[tableIndex];
    if (action === "Delete") {
      handleCloseAction();
      setDeleteStatus(true);
      setActionStatus(action);
      setSharedArray([...sharedArray, sharedData]);
    } else {
      // setActionStatus(action);
      // setExternalStatus(true)
    }
  };

  const handleSelectAll = (event) => {
    if (event?.target?.checked) {
      let temp = [];
      tableData?.map((_) => {
        temp?.push(_?._id);
      });
      setCheckedData([...temp]);
    } else {
      const temp = [];
      setCheckedData([...temp]);
    }
  };

  const handleCheckChange = (event, entry, index) => {
    if (event?.target?.checked) {
      setTableIndex(index);
      let temp = [...checkedData];
      temp?.push(entry?._id);
      setCheckedData([...temp]);
    } else {
      const temp = checkedData?.filter((_) => _ !== entry?._id);
      setCheckedData(temp);
    }
  };

  const handleSaveFilters = () => {
    if (filter?.filter) {
      const filteredObject = deleteEmptyKeys(filter?.filter);
      const payload = {
        type: "geofences",
        data: { ...filteredObject },
        status: allFilters && allFilters?.geofenceFilter ? true : false
      };

      dispatch(
        saveFilters(payload, (res) => {
          if (res && res?.success) {
          }
        })
      );
    }
  };

  /**
   * @description --- This will open confirmation modal for mutliple checked data
   */
  const handleOpenConfirmModal = () => {
    let tempChecked = [...checkedData];
    let templateArr = [];
    tempChecked?.map((id) => {
      let selectedRowData = tableData?.find((_) => _?._id === id);
      if (selectedRowData)
        templateArr?.push({
          name: selectedRowData?.name,
          id: id,
        });
    });
    setSelectedTemplates(templateArr);
    setShowConfirmModal(true);
  };

  /**
   * @description --- This will open confirmation modal for single items (through action menu)
   */
  const handleOpenModalForSingle = (action) => {
    let selectedRow = [];
    let template = [];
    setActionStatus(action);
    selectedRow?.push(tableData[tableIndex]?._id);
    template?.push({
      name: tableData[tableIndex]?.name,
      id: tableData[tableIndex]?._id,
    });

    setCheckedData(selectedRow);
    setSelectedTemplates(template);
    setShowConfirmModal(true);
  };

  const handleConfirmActionFn = (remark) => {
    // Here we can handle the action according to "actionStatus". ---------
    try {
      if (actionStatus?.toLowerCase() === "delete") {
        sendCustomEventAnalytics("geofence", "delete", "delete")
        let bodyData = {
          ids: checkedData,
          remark: remark ?? "",
        };
        dispatch(
          deleteGeofence(bodyData, () => {
            setComment("");
            handleCloseAction();
            setBulkStatus(null);
            setCheckedData([]);
            setTableIndex(null);
            setShowConfirmModal(false);
            // dispatch(getAllGeofences({ ...filter }));
            handleFetchAllGeofences();
            dispatch(getGeofenceStatusCount());
          })
        );
      }
      else if (
        actionStatus?.toLowerCase() === "enable" ||
        actionStatus?.toLowerCase() === "block"
      ) {
        let payload = {
          status:
            actionStatus?.toLowerCase() === "enable" ? "ACTIVE" : "DISABLE",
          ids: checkedData,
          remark: remark ?? "",
        };
        sendCustomEventAnalytics("geofence", "Active/Disable Geofence", "Active/Disable Geofence")
        dispatch(
          geofenceStatus(payload, () => {
            setComment("");
            handleCloseAction();
            setBulkStatus(null);
            setCheckedData([]);
            setTableIndex(null);
            setShowConfirmModal(false);
            // dispatch(getAllGeofences({ ...filter }));
            handleFetchAllGeofences();
            dispatch(getGeofenceStatusCount());
          })
        );
      }
    } catch (e) {
    } finally {
    }
  };

  const handleSearchUsers = (empty) => {
    if (empty === true) {
      dispatch(
        getAllUsers({
          selection: ["name", "email", "orgName"],
          searchValue: "",
          orgId: filter?.filter?.orgId ? filter?.filter?.orgId : "",
        })
      );
    } else {
      if (searchFields?.userId || searchFields?.userId == "")
        dispatch(
          getAllUsers({
            selection: ["name", "email", "orgName"],
            searchValue: searchFields?.userId,
            orgId: filter?.filter?.orgId ? filter?.filter?.orgId : "",
          })
        );
    }
  };

  const handleSearchOrg = (empty) => {
    if (empty === true) {
      dispatch(getAllOrganisations({ selection: ["name"] }));
    } else {
      if (searchFields?.org || searchFields?.org == "")
        dispatch(
          getAllOrganisations({
            selection: ["name"],
            searchValue: searchFields.org,
          })
        );
    }
  };



  useEffect(() => {
    if (!condition) {
      try {
        //setLoader(true);
        if (filter?.searchValue.length >= 3 && geoFlag === false) {
          // dispatch(
          //   getAllGeofences({ ...filter }, () => {
          //     setLoader(false);
          //   })
          // );
          handleFetchAllGeofences("name filter effect 1");
        }
        if (filter?.searchValue.length === 0 && geoFlag === false) {
          // dispatch(getAllGeofences({ ...filter }));
          handleFetchAllGeofences("name filter effect 2");
        }
      } catch {
        setLoader(false);
      } finally {
        setLoader(false);
      }
    }
  }, [debouncedId, debouncedStatus]);

  useEffect(() => {
    if ((filter?.page || filter?.limit) && geoFlag === false) {
      let temp = { ...filter };
      //temp.filter.orgId = orgid;
      // setLoader(true);
      setCheckedData([]);
      setTableIndex(null);
      // dispatch(
      //   getAllGeofences({ ...temp }, () => {
      //     setLoader(false);
      //   })
      // );
      handleFetchAllGeofences("filter page, limit effect");
    }
  }, [filter?.page, filter?.limit]);

  useEffect(() => {
    if (checkedData?.length > 0) {
      let activeCount = 0;
      let disableCount = 0;

      for (const item of checkedData) {
        const sel = tableData?.find((_) => _?._id === item);
        if (sel?.status?.toLowerCase() === "active") {
          activeCount++;
        } else if (sel?.status?.toLowerCase() === "disable") {
          disableCount++;
        }
      }

      if (activeCount > 0 && disableCount === 0) {
        setBulkStatus("active");
      } else if (disableCount > 0 && activeCount === 0) {
        setBulkStatus("inactive");
      } else {
        return setBulkStatus(null);
      }
    }
  }, [checkedData]);

  const updateStatus = (item) => {
    if (item === "active") {
      let payload = {
        status: "ACTIVE",
        id: GeofencesListing[tableIndex]?._id,
      };
      dispatch(
        geofenceStatus(payload, () => {
          dispatch(getAllGeofences({ ...filter }));
        })
      );
    } else {
      let payload = {
        status: "INACTIVE",
        id: GeofencesListing[tableIndex]?._id,
      };
      dispatch(geofenceStatus(payload), () => {
        dispatch(getAllGeofences({ ...filter }));
      });
    }
  };

  const handleFilter = (e, newVal, item) => {
    if (item === "org") {
      if (newVal) {
        setFilter((existing) => ({
          ...existing,
          filter: {
            ...existing?.filter,
            orgId: newVal?._id,
          },
        }));
        if (currentUser && currentUser?.adminSpecificPermissions) {
          dispatch(getAllUsers({ orgId: newVal?._id, selection: ["name", "email", "orgName"] }));
        }
      }
    } else if (item === "users") {
      if (newVal) {
        setFilter((existing) => ({
          ...existing,
          filter: {
            ...existing?.filter,
            addedBy: newVal?._id,
          },
        }));
      }
    }
  };

  const handleApplyFilters = () => {
    dispatch(
      getAllGeofences(
        {
          ...filter,
          selection: keysArray && keysArray?.length > 0 ? keysArray : [],
        },
        () => {
          setLoader(false);
          dispatch(updateGeoListFlag(false));
        }
      )
    );
  };

  return (
    <FiltersWrapper
      handleDrawerToggle={() => setOpen(!open)}
      open={open}
      // filters={filterDropdowns}
      isDarkThemeEnabledSelector={isDarkThemeEnabledSelector}
      selectStylesOverride={selectStylesOverride(isDarkThemeEnabledSelector)}
      themeType={themeType}
      handleApplyFilters={handleApplyFilters}
      saveFilters={handleSaveFilters}
      defaultFiltersExists={allFilters && allFilters?.geofenceFilter ? true : false}
      searchElement={
        <Grid container spacing={2} mt={0}>
          <Grid item md={12} ml={2} mr={2} style={{ width: "100%" }}>
            <FormControl
              variant="outlined"
              size="small"
              fullWidth
              sx={{
                ...selectStylesOverride(isDarkThemeEnabledSelector),
                borderRadius: "12px",
              }}
            >
              <InputLabel
                htmlFor="outlined-adornment-password"
                sx={{ fontSize: "12px" }}
              >
                Search by name.
              </InputLabel>
              <OutlinedInput
                sx={{ ...inputClasses.filterField, height: "36px" }}
                name="name"
                data-testid="searchelement"
                value={filter?.searchValue || ""}
                onChange={(e) =>
                  setFilter({
                    ...filter,
                    searchValue: e.target.value
                      .trimStart()
                      .replace(/\s{2,}/g, " "),
                    page: 1,
                  })
                }
                // inputValue.replace(/ +/g, ' ').trim();
                placeholder="Search by name."
                label="Search by name."
                endAdornment={
                  <InputAdornment position="end">
                    <Search fontSize="small" />
                  </InputAdornment>
                }
              />
            </FormControl>
          </Grid>
        </Grid>
      }
      additionalFilter={
        <Grid container>
          {currentUser &&
            currentUser?.adminSpecificPermissions &&
            Object.keys(currentUser?.adminSpecificPermissions).length ? (
            <>
              <Grid item md={12} ml={2} mr={2} mb={2} style={{ width: "100%" }}>
                <FormControl
                  variant="outlined"
                  size="small"
                  fullWidth
                  sx={{ ...selectStylesOverride(isDarkThemeEnabledSelector) }}
                >
                  <CustomSelect
                    selectStylesOverride={selectStylesOverride}
                    onChange={(e, newVal, reason) => {
                      if (reason === "clear") {
                        handleSearchOrg(true);
                        dispatch(flushUsersList());
                      }
                      handleFilter(e, newVal, "org");
                    }}
                    searchValue={
                      searchFields?.org && searchFields?.org !== ""
                        ? searchFields?.org
                        : null
                    }
                    onSearch={handleSearchOrg}
                    onInputChange={(e) => {
                      if (e?.type === "change") {
                        if (e?.target?.value === "") handleSearchOrg(true);
                        setSearchFields((prev) => ({
                          ...prev,
                          org: e?.target?.value?.trim(),
                        }));
                      }
                    }}
                    sx={{
                      ...inputClasses.filterField,
                    }}
                    label="Organisation"
                    errors={errors}
                    getOptionLabel={(option) => {
                      return option?.name;
                    }}
                    filterSelectedOptions
                    labelKey="name"
                    isOptionEqualToValue={(option, value) =>
                      option._id === value.id && option.name === value.name
                    }
                    options={orgListing && orgListing}
                  />
                </FormControl>
              </Grid>
              <Grid item md={12} ml={2} mr={2} mb={2} style={{ width: "100%" }}>
                <FormControl
                  variant="outlined"
                  size="small"
                  fullWidth
                  sx={{ ...selectStylesOverride(isDarkThemeEnabledSelector) }}
                >
                  <CustomSelect
                    selectStylesOverride={{ ...selectStylesOverride }}
                    onChange={(e, newVal, reason) => {
                      if (reason === "clear") handleSearchUsers(true);
                      handleFilter(e, newVal, "users");
                    }}
                    // disabled={!filter?.filter?.addedBy}
                    sx={{
                      ...inputClasses.filterField,
                    }}
                    label="User"
                    getOptionLabel={(option) => {
                      return option?.name;
                    }}
                    searchValue={
                      searchFields?.userId && searchFields?.userId !== ""
                        ? searchFields?.userId
                        : null
                    }
                    onSearch={handleSearchUsers}
                    onInputChange={(e) => {
                      if (e?.type === "change") {
                        if (e?.target?.value === "") handleSearchUsers(true);
                        setSearchFields((prev) => ({
                          ...prev,
                          userId: e?.target?.value?.trim(),
                        }));
                      }
                    }}
                    filterSelectedOptions
                    labelKey="name"
                    renderOptions={(props, option, { selected }) => {
                      return (
                        <MenuItem
                          value={filter?.filter?.addedBy || ""}
                          selected={selected}
                          {...props}
                        >
                          <Typography
                            sx={{
                              fontSize: ".8rem",
                              overflow: "hidden",
                              whiteSpace: "nowrap",
                              textOverflow: "ellipsis",
                            }}
                          >
                            {`${option?.name} - ${option?.orgName}` || ""}
                          </Typography>
                        </MenuItem>
                      );
                    }}
                    isOptionEqualToValue={(option, value) =>
                      option._id === value.id && option.name === value.name
                    }
                    options={Users && Users}
                  />
                </FormControl>
              </Grid>
            </>
          ) : null}
        </Grid>
      }
    >
      <Grid container>
        <Grid item sm={12} xs={12}>
          <Grid container>
            <Grid item sm={6} xs={4}>
              <Typography sx={{ ...textClasses.cardTitle }} data-testid="title">
                Geofence
              </Typography>
            </Grid>

            <Grid
              container
              item
              sm={6}
              xs={6}
              sx={{ justifyContent: "flex-end" }}
            >
              {filter?.searchValue !== "" ? (
                <Grid
                  container
                  item
                  sm
                  xs={6}
                  sx={{ justifyContent: "flex-end" }}
                >
                  <Grid
                    item
                    sm={6}
                    xs={6}
                    sx={{
                      displey: "flex",
                      display: "flex",
                      justifyContent: "flex-end",
                      height: "36px",
                      display: "flex",
                      position: "relative",
                      marginRight: "5px",
                    }}
                  >
                    <Button variant="text">
                      <Typography
                        sx={{
                          ...textClasses.boldText,
                          color: themeType.default.themeOrange,
                          textDecoration: "underline",
                          cursor: "pointer",
                          textTransform: "none",
                        }}
                        data-testid="clearFilter"
                        onClick={() => {
                          setFilter({ ...payload });
                          // dispatch(getAllGeofences(payload, () => {}));
                          handleFetchAllGeofences();
                        }}
                      >
                        Clear All Filter(s)
                      </Typography>
                    </Button>
                  </Grid>
                </Grid>
              ) : null}

              {!open && (
                <Button
                  variant="contained"
                  sx={{
                    ...buttonClasses?.lynkitOrangeEmpty,
                    minHeight: "36px",
                  }}
                  onClick={() => setOpen(!open)}
                  startIcon={<FilterAlt />}
                  data-testid="filtersBtn"
                >
                  Filters
                  {/* {finalFilter?.filter &&
                    `(${Object.keys(finalFilter?.filter)?.length})`} */}
                </Button>
              )}
              {havePermission(
                userPermissions,
                "geofence",
                "createGeofence"
              ) && (
                  <Button
                    data-testid="creategeofence"
                    variant="outlined"
                    size="small"
                    sx={{
                      ...buttonClasses.small,
                      minHeight: "36px",
                      borderColor: themeType.default.themeOrange,
                      color: buttonClasses.lynkitOrangeEmpty,
                      marginLeft: "14px",
                    }}
                    onClick={() => {
                      sendCustomEventAnalytics("geofence", "create-geofence", "Create Geofence")
                      history.push("/dashboard/geomap/create-geo");
                    }}
                    startIcon={<AddIcon />}
                  >
                    Create Geofence
                  </Button>
                )}
              <Button
                data-testid="viewall"
                variant="outlined"
                size="small"
                // disabled={tableData.length ? false : true}
                sx={{
                  ...buttonClasses.small,
                  borderColor: themeType.default.themeOrange,
                  color: buttonClasses.lynkitOrangeFill,
                  marginLeft: "14px",
                  minHeight: "36px",
                }}
                onClick={() => viewEditGeofence("all")}
              >
                View All
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          sx={{
            borderBottom: 1,
            borderColor: "divider",
            mt: 0,
            mb: 1,
          }}
        >
          <Grid container>
            <Grid item xs={5}>
              <Tabs
                value={selectedTab}
                onChange={handleTabsChange}
                variant="scrollable"
                // scrollButtons="auto"
                className="tabsClass"
                aria-label="scrollable auto tabs example"
                sx={{
                  maxWidth: window.innerWidth - 108,
                  "& .MuiTabs-indicator": {
                    backgroundColor: themeType.default.themeOrange,
                  },
                  "& .MuiTab-root.Mui-selected": {
                    color: themeType.default.themeOrange,
                  },
                }}
              >
                {tabs?.map((item, i) => {
                  return (
                    <Tab
                      key={i}
                      label={`${item.name}`}
                      sx={{
                        ...textClasses.normalText,
                        textTransform: "capitalize",
                      }}
                      variant="scrollable"
                    // scrollButtons
                    // allowScrollButtonsMobile
                    />
                  );
                })}
              </Tabs>
            </Grid>
          </Grid>
        </Grid>

        {/* Tabs */}
        <Grid>
          {checkedData.length > 0 ? (
            <Box pl={"0"} mt={-1} mb={0.4} data-testid="edit">
              {checkedData.length < 2 ? (
                <>
                  {userPermissions &&
                    havePermission(
                      userPermissions,
                      "geofence",
                      "editGeofence"
                    ) ? (
                    <Button
                      size="small"
                      data-testid="edit"
                      variant="contained"
                      className="UM_uploadfile-btn"
                      //disabled={checkedData.length > 1 ? true : false}
                      sx={{
                        fontSize: "12px",
                        borderRadius: "8px",
                        margin: "5px",
                        color: "black",
                        border: "1px solid black",
                        ...buttonClasses.lynkitOrangeFill,
                        mr: 1,
                        textTransform: "none",
                      }}
                      onClick={() => viewEditGeofence("single")}
                    // onClick={() => {
                    //   setSelectedModel(tableData[tableIndex]);
                    //   setShowAddModal(true);
                    // }}
                    // sx={{ fontSize: "12px", borderRadius: "8px", margin: "5px", color: "black", border: "1px solid black", ...buttonClasses.lynkitOrangeFill, mr: 1, textTransform: "none" }}
                    >
                      Edit
                    </Button>
                  ) : null}
                  {/* <Button
                    size="small"
                    data-testid="view"
                    variant="contained"
                    className="UM_uploadfile-btn"
                    //disabled={checkedData.length > 1 ? true : false}
                    onClick={() => {
                      handleSelectedRow(tableData[tableIndex]);
                    }}
                    sx={{
                      fontSize: "12px",
                      borderRadius: "8px",
                      margin: "5px",
                      color: "black",
                      border: "1px solid black",
                      ...buttonClasses.lynkitOrangeFill,
                      mr: 1,
                      textTransform: "none",
                    }}
                  >
                    {" "}
                    View{" "}
                  </Button> */}

                  {/* TODO: ---- Comment the below code once bulk block, active & delete functionality is required */}
                  {/* {bulkStatus !== null && (
                  <Button
                    size="small"
                    data-testid="delete"
                    variant="contained"
                    className="UM_delete-btn"
                    onClick={() => {
                      setActionStatus(
                        bulkStatus == "active" ? "block" : "enable"
                      );
                      handleOpenConfirmModal();
                    }}
                    sx={{
                      fontSize: "12px",
                      margin: "5px",
                      color: "black",
                      border: "1px solid black",
                      textTransform: "none",
                      ...buttonClasses[
                        bulkStatus == "active"
                          ? "lynkitDisabled"
                          : "lynkitGreenFill"
                      ],
                    }}
                  >
                    {bulkStatus == "active" ? "Block" : "Active"}
                  </Button>
                )}

                <Button
                  size="small"
                  data-testid="delete"
                  variant="contained"
                  className="UM_delete-btn"
                  onClick={() => {
                    setActionStatus("delete");
                    handleOpenConfirmModal();
                  }}
                  sx={{
                    fontSize: "12px",
                    borderRadius: "8px",
                    margin: "5px",
                    color: "black",
                    border: "1px solid black",
                    textTransform: "none",
                    ...buttonClasses.lynkitBlackFill,
                  }}
                >
                  {" "}
                  Delete{" "}
                </Button> */}
                </>
              ) : (
                ""
              )}

              {/* TODO: ---- Uncomment the below code once bulk block, active & delete functionality is required */}
              {bulkStatus !== null && (
                <Button
                  size="small"
                  data-testid="delete"
                  variant="contained"
                  className="UM_delete-btn"
                  onClick={() => {
                    setActionStatus(
                      bulkStatus == "active" ? "block" : "enable"
                    );
                    handleOpenConfirmModal();
                  }}
                  sx={{
                    fontSize: "12px",
                    margin: "5px",
                    color: "black",
                    border: "1px solid black",
                    textTransform: "none",
                    ...buttonClasses[
                    bulkStatus == "active"
                      ? "lynkitDisabled"
                      : "lynkitGreenFill"
                    ],
                  }}
                >
                  {bulkStatus == "active" ? "Block" : "Active"}
                </Button>
              )}
              {userPermissions &&
                havePermission(userPermissions, "geofence", "deleteGeofence") ? (
                <Button
                  size="small"
                  data-testid="delete"
                  variant="contained"
                  className="UM_delete-btn"
                  onClick={() => {
                    setActionStatus("delete");
                    handleOpenConfirmModal();
                  }}
                  sx={{
                    fontSize: "12px",
                    borderRadius: "8px",
                    margin: "5px",
                    color: "black",
                    border: "1px solid black",
                    textTransform: "none",
                    ...buttonClasses.lynkitBlackFill,
                  }}
                >
                  {" "}
                  Delete{" "}
                </Button>
              ) : null}
            </Box>
          ) : (
            ""
          )}
        </Grid>

        {loader ? (
          <Grid
            container
            item
            direction={"column"}
            justifyContent={"center"}
            alignItems="center"
            xs={12}
            sx={{ p: 15 }}
          >
            <Grid item>
              <img data-testid="loaderImg" src={loaderGif} />
            </Grid>
          </Grid>
        ) : (
          <>
            {GeofencesListing && GeofencesListing.length === 0 ? (
              <EmptyPage
                data-testid="noGeofenceText"
                body="No geofence found.."
              />
            ) : (
              <Grid container>
                <Grid item xs={12} sm={selectedData ? 9 : 12}>
                  <Paper
                    sx={{
                      height: "74vh",
                      overflow: "auto",
                      width: "100%",
                      display: "block",
                    }}
                  >
                    <Table
                      header={tableHeader}
                      rows={tableRows}
                      totalRows={totalRows}
                      filter={filter}
                      setFilter={setFilter}
                      themeType={themeType}
                    />
                  </Paper>
                </Grid>
                {selectedData && (
                  <Grid item sm={3} xs={6} sx={{ p: 1, pt: 0 }}>
                    <DetailCard
                      cardLoader={cardLoader}
                      header={tableHeader || []}
                      themeType={themeType}
                      data={viewGeofenceDetail}
                      setMyMap={setMyMap}
                      handleSelectedRow={handleSelectedRow}
                      setSelectedData={setSelectedData}
                      userDetails={userDetails}
                    />
                  </Grid>
                )}
              </Grid>
            )}
          </>
        )}
      </Grid>

      {showConfirmModal && (
        <ConfirmModal
          open={showConfirmModal}
          loading={loader}
          hide={() => {
            setShowConfirmModal(false);
            setActionStatus(null);
            handleCloseAction();
            setComment("");
          }}
          tableData={tableData}
          checkedObj={checkedData}
          action={actionStatus}
          setShowConfirmModal={() => {
            setShowConfirmModal(false);
          }}
          comment={comment}
          setComment={setComment}
          selectStylesOverride={selectStylesOverride(
            isDarkThemeEnabledSelector
          )}
          actionFn={handleConfirmActionFn}
          setSelectedTemplates={setSelectedTemplates}
          selectedTemplates={selectedTemplates}
          setCheckedObj={setCheckedData}
        />
      )}

      {actionIndex != null && (
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={actionIndex != null}
          onClose={handleCloseAction}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
          sx={{
            display:
              actionIndex != null ? "block !important" : "none !important",
          }}
        >
          {userPermissions &&
            havePermission(userPermissions, "geofence", "editGeofence") ? (
            <MenuItem
              data-testid="edit"
              key={1}
              onClick={() => {
                viewEditGeofence("single")
              }}
            >
              Edit
            </MenuItem>
          ) : null}

          <MenuItem
            key={2}
            onClick={() => {
              // updateStatus(
              //   tableData[actionIndex].status === "INACTIVE"
              //     ? "active"
              //     : "block"
              // )
              handleOpenModalForSingle(
                tableData[tableIndex]?.status?.toLowerCase() == "active"
                  ? "block"
                  : "enable"
              );
              setActionIndex(null);
            }}
          >
            {tableData[actionIndex]?.status === "ACTIVE"
              ? "In-Active"
              : "Active"}
          </MenuItem>

          {userPermissions &&
            havePermission(userPermissions, "geofence", "deleteGeofence") ? (
            <MenuItem
              data-testid="delete"
              key={4}
              onClick={() => {
                //handleAction("Delete");
                handleOpenModalForSingle("delete");
                setActionIndex(null);
              }}
            >
              Delete
            </MenuItem>
          ) : null}
        </Menu>
      )}
      {deleteStatus && (
        <DeleteModal
          open={deleteStatus}
          hide={() => {
            setDeleteStatus(false);
          }}
          action={actionStatus}
          setDeleteStatus={setDeleteStatus}
          sharedArray={sharedArray}
          setSharedArray={setSharedArray}
        />
      )}
    </FiltersWrapper>
  );
}

export const Table = ({
  header,
  rows,
  totalRows,
  filter,
  setFilter,
  themeType,
}) => {
  const {
    theme,
    buttonClasses,
    cardClasses,
    inputClasses,
    textClasses,
    tableClasses,
  } = themeType;
  return (
    <>
      <table style={{ ...tableClasses.table }}>
        <thead
          style={{
            backgroundColor: themeType.default.themeGray,
            position: "sticky",
            top: "-1px",
            zIndex: 1,
          }}
        >
          <tr>
            {header.slice(0, 2).map((head, i) => {
              return (
                <td
                  key={i}
                  style={{
                    ...textClasses.boldText,
                    ...tableClasses.tableHeader,
                    textAlign: "center",
                  }}
                >
                  {head}
                </td>
              );
            })}
            {header.slice(2, header.length).map((head, i) => {
              return (
                <td
                  key={i}
                  style={{
                    ...textClasses.boldText,
                    ...tableClasses.tableHeader,
                    textAlign: "center",
                  }}
                >
                  {head}
                </td>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {rows.length > 0 ? (
            <>
              {rows.map((row, i) => (
                <tr data-testid="tablerow" key={i}>
                  {row.slice(0, 2).map((col, j) => {
                    return (
                      <td
                        key={j}
                        data-testid="tablecell"
                        style={{
                          textAlign: "center",
                          ...textClasses.normalText,
                          ...tableClasses.dark,
                          ...tableClasses.tableBodyClass,
                          textAlign: "center",
                          padding: "11px",
                        }}
                      >
                        {col}
                      </td>
                    );
                  })}
                  {row.slice(2, row.length).map((col, j) => {
                    return (
                      <td
                        key={j}
                        style={{
                          textAlign: "center",
                          ...textClasses.normalText,
                          ...tableClasses.dark,
                          ...tableClasses.tableBodyClass,
                          textAlign: "center",
                        }}
                      >
                        {col}
                      </td>
                    );
                  })}
                </tr>
              ))}
            </>
          ) : (
            <tr sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
              <td
                data-testid="nodata"
                component="th"
                scope="row"
                style={{
                  ...textClasses.normalText,
                  textAlign: "left",
                  overflowWrap: "break-word",
                }}
              ></td>
            </tr>
          )}
        </tbody>
      </table>
      <TablePagination
        component="div"
        data-testid="pagination"
        sx={{
          ...tableClasses.tableHeader,
          ...tableClasses.tablePagination,
          display: "flex",
          justifyContent: "center",
          position: "sticky",
          bottom: "0px",
          alignItems: "center",
          mt: "0em",
          "& .MuiTablePagination-selectLabel ": {
            margin: "0",
          },
          "& .MuiTablePagination-displayedRows": {
            margin: "0",
          },
        }}
        count={totalRows || 0}
        page={(filter.page || 1) - 1}
        onPageChange={(e, newPage) =>
          setFilter({ ...filter, page: newPage + 1 })
        }
        rowsPerPage={filter.limit}
        onRowsPerPageChange={(e) =>
          setFilter({ ...filter, page: 1, limit: e.target.value })
        }
      />
    </>
  );
};

export const DetailCard = ({
  data,
  cardLoader,
  themeType,
  handleSelectedRow,
  setMyMap,
  myMap,
  setSelectedData,
  userDetails,
}) => {
  const {
    theme,
    buttonClasses,
    cardClasses,
    inputClasses,
    textClasses,
    tableClasses,
  } = themeType;

  const DetailRow = ({ title, children }) => {
    return (
      <Grid container sx={{ mt: 1 }}>
        <Grid item sm={4.5} xs={4.5}>
          <Typography sx={{ ...textClasses.t12n, color: "#827878" }}>
            {title}
          </Typography>
        </Grid>
        <Grid
          container
          item
          sm={7.5}
          xs={7.5}
          direction="row"
          alignItems={"center"}
        >
          {title === "Status" ? (
            children.props.children === "Disable" ? (
              <span
                style={{ color: "red", fontSize: "14px", fontWeight: "400" }}
              >
                In-Active
              </span>
            ) : (
              <span
                style={{
                  color: "#008000",
                  fontSize: "14px",
                  fontWeight: "400",
                }}
              >
                Active
              </span>
            )
          ) : (
            children
          )}
        </Grid>
      </Grid>
    );
  };

  useEffect(() => {
    // if(data){
    setTimeout(() => {
      if (typeof google === "undefined" || typeof google.maps === "undefined") {
        return null;
      }
      const mapElement = document.getElementById("mapGeoDimensions");
      if (!mapElement) {
        console.error("Element with id 'viewallmap' not found.");
        return null;
      }
      let lat =
        data &&
          data.centerCoordinates &&
          data.centerCoordinates[0] &&
          data.centerCoordinates[0][0] &&
          data.centerCoordinates[0][0] &&
          data.centerCoordinates[0][0][1]
          ? data.centerCoordinates[0][0][1]
          : "";
      let lng =
        data &&
          data.centerCoordinates &&
          data.centerCoordinates[0] &&
          data.centerCoordinates[0][0] &&
          data.centerCoordinates[0][0] &&
          data.centerCoordinates[0][0][0]
          ? data.centerCoordinates[0][0][0]
          : "";

      const mapOptions = {
        zoom: 15,
        center: { lat: Number(lat), lng: Number(lng) },
        mapTypeId: "terrain",
        zoomControl: true,
        zoomControlOptions: {
          style: google.maps.ZoomControlStyle.SMALL,
          position: google.maps.ControlPosition.LEFT_BOTTOM,
        },
        streetViewControl: false,
        fullscreenControl: true,
        mapTypeControl: true,
        scaleControl: false,
        zoomControl: true,
      };
      const map = new google.maps.Map(mapElement, mapOptions);
      setMyMap(map);
      plotGeofence(map);
      return map;
    }, 100);
    // }
  }, [data]);

  const plotGeofence = (map) => {
    let originalCoordinates = data?.geofence?.coordinates || [];
    let geoType = data?.polygonType;
    const convertedCoordinates =
      originalCoordinates &&
      originalCoordinates[0] &&
      originalCoordinates[0].map((point) => ({
        lat: point[1],
        lng: point[0],
      }));
    const centerForCircle = {
      lat: Number(data?.latitude),
      lng: Number(data?.longitude),
    };
    const bounds = new google.maps.LatLngBounds();
    if (geoType === "Polygon") {
      const polygon = new google.maps.Polygon({
        paths: convertedCoordinates,
        strokeColor: "#00008B",
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: "#6082B6",
        fillOpacity: 0.45,
      });
      polygon.setMap(map);
      convertedCoordinates.forEach(coord => {
        bounds.extend(new google.maps.LatLng(coord.lat, coord.lng));
      });

      
    } else if (geoType === "Circular") {
      const polygon = new google.maps.Circle({
        strokeColor: "#00008B",
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: "#6082B6",
        fillOpacity: 0.45,
        center: centerForCircle,
        radius: Number(data?.radius),
      });
      polygon.setMap(map);

      bounds.extend(new google.maps.LatLng(centerForCircle.lat - (Number(data?.radius) / 111320), centerForCircle.lng));
    bounds.extend(new google.maps.LatLng(centerForCircle.lat + (Number(data?.radius) / 111320), centerForCircle.lng));
    bounds.extend(new google.maps.LatLng(centerForCircle.lat, centerForCircle.lng - (Number(data?.radius) / 111320)));
    bounds.extend(new google.maps.LatLng(centerForCircle.lat, centerForCircle.lng + (Number(data?.radius) / 111320)));

    }
    map.fitBounds(bounds);
  };

  console.log('data', data)


  return (
    <>
      {cardLoader ? (
        <Grid
          item
          container
          direction={"column"}
          justifyContent={"center"}
          alignItems="center"
          xs={12}
          sx={{ p: 15 }}
        >
          <Grid item>
            <img src={loaderGif} />
          </Grid>
        </Grid>
      ) : (
        <Card
          className="ppmodal"
          data-testid="modal"
          sx={{
            ...cardClasses.basic,
            borderRadius: "8px",
            border: `1px solid ${themeType.default.themeOrange}`,
            position: "relative",
            height: "73vh",
            overflow: "auto",
          }}
        >
          <CardHeader
            sx={{
              height: "45px",
              backgroundColor: themeType.default.themeOrange,
            }}
            title={
              <Typography
                sx={{
                  ...textClasses.boldText,
                  color: "white",
                  fontSize: "16px",
                }}
              >
                <GeoIcon height="22" width="22" fill={"white"} />
                <span style={{ position: "relative", left: "10px" }}>
                  {data.name.substring(0, 20) + "..."}
                </span>
              </Typography>
            }
            action={
              <IconButton
                size="small"
                sx={{ p: 0, mb: 1 }}
                onClick={() => setSelectedData(null)}
              >
                <Close sx={{ color: "white" }} />
              </IconButton>
            }
          />
          <CardContent>
            <div id="mapGeoDimensions" style={{ height: "310px" }}></div>
          </CardContent>

          <CardContent>
            <DetailRow title={"Geo Name"}>
              <Tooltip
                placement="top"
                title={data?.name && data?.name?.length > 15 ? data?.name : ""}
              >
                <Typography
                  data-testid="nameDetailed"
                  sx={{
                    ...textClasses.t12n,
                    color: "#ff7200",
                    fontWeight: "800",
                    width: "100%",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {data?.name || "-"}
                </Typography>
              </Tooltip>
            </DetailRow>
            <DetailRow title={"Address"}>
              <Typography sx={{ ...textClasses.t12n }}>
                {data?.address || "-"}
              </Typography>
            </DetailRow>
            <DetailRow title={"Polygon Type"}>
              <Typography sx={{ ...textClasses.t12n }}>
                {data?.polygonType || "-"}
              </Typography>
            </DetailRow>
            <DetailRow title={"Status"}>
              <Typography
                sx={{ ...textClasses.t12n, fontWeight: " 800" }}
                style={{ color: data.status === "ACTIVE" ? "green" : "red" }}
              >
                {capitalizeSentence(data?.status || "-")}
              </Typography>
            </DetailRow>
            <DetailRow title={"Added By"}>
              <Typography
                sx={{
                  ...textClasses.t12n,
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {capitalizeSentence(data?.addedBy?.name || "-")}
              </Typography>
            </DetailRow>
            <DetailRow title={"Added On"}>
              <Typography
                sx={{
                  ...textClasses.t12n,
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {data?.addedOn
                  ? convertIntoGivenTimezone(
                    data?.addedOn,
                    userDetails.timeZone.offset
                  )
                  : "-"}
              </Typography>
            </DetailRow>

            <DetailRow title={"Last Updated By"}>
              <Typography
                sx={{
                  ...textClasses.t12n,
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {capitalizeSentence(data?.modifiedBy?.name || "-")}
              </Typography>
            </DetailRow>
            <DetailRow title={"Last Updated On"}>
              <Typography
                sx={{
                  ...textClasses.t12n,
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {data?.modifiedOn
                  ? convertIntoGivenTimezone(
                    data?.modifiedOn,
                    userDetails.timeZone.offset
                  )
                  : "-"}
              </Typography>
            </DetailRow>

            {data && data?.dynamicData ? Object.keys(data?.dynamicData)?.length ?
              Object.entries(data?.dynamicData).map(([key, value]) => (
                <DetailRow title={key}>
                  <Typography
                    sx={{
                      ...textClasses.t12n,
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {value || "-"}
                  </Typography>
                </DetailRow>
              )) : null : null}


          </CardContent>
        </Card>
      )}
    </>
  );
};
