import { GET_TRIP_DATA_BY_TRIP_ID, TRIP_GEOFENCE_WITH_ROUTE, TRIP_GEOFENCE_WITHOUT_ROUTE, GET_TRIP_EVENTS, EMPTY_TRIP_DATA, EMPTY_MARKER_EVENT, EMPTY_TRIP_ROUTE, MAP_TRACK, MARKER_DATA } from "../types";

const INIT_STATE = {
    tripDataById: {},
    tripGeofenceWithRoute: {},
    tripGeofenceWithoutRoute: {},
    tripEvents: {},
    mapTrack: {},
    markerData: {}
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_TRIP_DATA_BY_TRIP_ID:
            return {
                ...state,
                tripDataById: action.payload.rows,
            };
        case TRIP_GEOFENCE_WITH_ROUTE:
            return {
                ...state,
                tripGeofenceWithRoute: action.payload.rows,
                tripGeofenceWithoutRoute: {}
            };
        case TRIP_GEOFENCE_WITHOUT_ROUTE:
            return {
                ...state,
                tripGeofenceWithoutRoute: action.payload.rows,
                tripGeofenceWithRoute: {}
            };
        case MAP_TRACK:
            return {
                ...state,
                mapTrack: action.payload.rows,
            };
        case EMPTY_TRIP_ROUTE:
            return {
                ...state,
                tripGeofenceWithRoute: {},
                tripGeofenceWithoutRoute: {}
            };
        case EMPTY_TRIP_DATA:
            return {
                ...state,
                tripDataById: {}
            };
        case EMPTY_MARKER_EVENT:
            return {
                ...state,
                markerData: {}
            };

        case GET_TRIP_EVENTS:
            return {
                ...state,
                tripEvents: action.payload.rows,
            };
        case MARKER_DATA:
            return {
                ...state,
                markerData: action.payload.rows,
            };

        default:
            return state;
    }
};
