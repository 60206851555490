// import {applyMiddleware, compose, createStore} from "redux";
// import reducers from "../reducers/index";
// import {routerMiddleware} from "react-router-redux";
// import thunk from 'redux-thunk';
// import { createBrowserHistory } from "history";

// const history = createBrowserHistory()

// const routeMiddleware = routerMiddleware(history);

// const middlewares = [thunk, routeMiddleware];

// // const middlewares = [thunk];

// const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
// // const composeEnhancers = compose;

// export default function configureStore(initialState) {
//   const store = createStore(
//     reducers,
//     initialState,
//     composeEnhancers(applyMiddleware(...middlewares))
//   );
//   // const store = createStore(
//   //   reducers,
//   //   initialState,
//   //   applyMiddleware(...middlewares)
//   // );

//   if (module.hot) {
//     // Enable Webpack hot module replacement for reducers
//     module.hot.accept('../reducers/index', () => {
//       const nextRootReducer = require('../reducers/index');
//       store.replaceReducer(nextRootReducer);
//     });
//   }
//   return store;
// }

// export {history};


import { applyMiddleware, compose, createStore } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // or another storage engine
import reducers from '../reducers/index';
import {routerMiddleware} from "react-router-redux";
import thunk from 'redux-thunk';
import { createBrowserHistory } from 'history';

const history = createBrowserHistory();

const routeMiddleware = routerMiddleware(history);

const middlewares = [thunk, routeMiddleware];

// Persist Configuration
const persistConfig = {
  key: 'root',
  storage, // Specify storage
  whitelist: ['auth'], // Specify which part of state to persist
};

const persistedAuthReducer = persistReducer(
  persistConfig,
  reducers
);
let composeEnhancers = compose;
// const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
// TODO: ---- Conditionally enable Redux DevTools in development
if (process.env.REACT_APP_ENV === 'development') {
  composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
}

export default function configureStore(initialState) {
  const store = createStore(
    persistedAuthReducer,
    initialState,
    composeEnhancers(applyMiddleware(...middlewares))
  );

  const persistor = persistStore(store); // Create persistor

  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept('../reducers/index', () => {
      const nextRootReducer = require('../reducers/index').default;
      store.replaceReducer(nextRootReducer);
    });
  }

  return { store, persistor }; // Return both store and persistor
}

export { history };