import React from "react";
import { Redirect, Route } from "react-router-dom/cjs/react-router-dom";

const ProtectedRoute = ({
  component,
  // component: Component,
  allowedRoles,
  havePermission,
  userRole,
  ...rest
}) => {
  // Check if the user role is allowed to access the route
  const isAuthorized = userRole && userRole?.length > 0 ? allowedRoles?.includes(userRole) : true;

  return (
    <Route
      {...rest}
      render={() => (isAuthorized && havePermission ? component : <Redirect to={localStorage.getItem('sidebar') ? null : "/dashboard/monitoring"} />)}
    />
  );
};

export default ProtectedRoute;
