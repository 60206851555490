import { memo, useCallback, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment/moment";
// import Loader from "../../layout/loader";
import VerticalBarChart from "./VerticalBarChart";
// import { grey, primary } from "../../data/colors";
// import { ReactComponent as AssetManagementIcon } from "../../assets/images/icons/asset_management.svg";
// import { ReactComponent as DownloadIcon } from "../../assets/images/icons/download.svg";
// import Pagination from "../util/pagination/Pagination";
import { FilterPanel } from "./FilterPanel";
import XLSX from "sheetjs-style";
import { DetailPanel } from "./DetailPanel";
import { Panel } from "./Panel";
import loaderGif from "../../../static/images/drawer/loader.gif";
import { useFilterPanel } from "./useFilterPanel";
import { Grid, Typography } from "@mui/material";
import {
  flushAllCategoryData,
  flushAllCategoryTypes,
  getCategorytypeOptions,
  getMainAssetByDeviceId,
  getMainAssetData,
  getVendorAssetData,
  setSelectedItemId,
} from "../../../redux/actions/AssetMgt";
import {
  selectIsMainAssetLoading,
  selectIsSearchDataLoading,
  selectIsVendorAssetLoading,
  selectMainAssetData,
  selectMainAssetDownloadData,
  selectSearchData,
  selectSelectedItemId,
  selectVendorAssetCount,
  selectVendorAssetData,
} from "../../../redux/reducers/AssetMgt";
import "./style.scss";
import { getAllOrganizations, getAllUsers } from "../../../redux/actions";
import ThreeDotsLoader from "../../Components/ThreeDots/ThreeDotsLoader";
import CustomSelect from "../../../components/FormUI/CustomSelect";
import { selectStylesOverride } from "../../../utils/util";

const AssetManagement = () => {
  const dispatch = useDispatch();

  const [clear, setClear] = useState(false);
  const [selectedCategoryType, setSelectedCategoryType] = useState("");
  const [searchTemp, setSearchTemp] = useState("");
  const [searchDeviceValue, setSearchDeviceValue] = useState("");
  const [mainFilterDate, setMainFilterDate] = useState(new Date());
  const [fromDate, setFromDate] = useState(new Date());
  const [filterObj, setFilterObj] = useState(null);
  const [searchFields, setSearchFields] = useState({
    users: null,
    org: null,
  });

  const { currentUserDetail } = useSelector((state) => state.auth);
  const { categoryTypeOptions } = useSelector((state) => state.assetManagement);
  const isDarkThemeEnabledSelector = useSelector(
    (state) => state.theme.themeDark
  );
  const themeType = isDarkThemeEnabledSelector
    ? require("../../../static/styles/darktheme")
    : require("../../../static/styles/theme");

  const { allUsers, allOrganizations } = useSelector((state) => state.userlist);
  const themeBorderColor = isDarkThemeEnabledSelector
    ? "rgba(255, 255, 255, 0.22)"
    : "#D9D9D9";

  const {
    isDetailPanelOpen,
    detailPanelData,
    handleDetailPanelClose,
    handleDetailPanelOpen,
    setData: setDetailPanelData,
  } = useDetailPanel();

  const {
    isFilterPanelOpen,
    filterPanelData,
    handleFilterPanelClose,
    handleFilterPanelOpen,
    setFilterData,
    handleFilter,
    filterOpen,
    // setFilterDate, fromDate
  } = useFilterPanel();

  // const categoryTypeOptions = ["Geofence", "Routes"];
  const { mainAssetData, isMainAssetDataLoading, mainAssetDownloadData } =
    useMainAssetCount();
  const { vendorAssetData, isVendorAssetDataLoading, fetchVendorAssetData } =
    useVendorAsset(
      categoryTypeOptions,
      selectedCategoryType,
      setSelectedCategoryType,
      mainFilterDate,
      filterObj
    );
  const { searchData, isSearchLoading, handleSearchSubmit } = useSearch(
    mainFilterDate,
    filterObj
  );

  const handleShowDetails = useCallback(
    ({ innerIndex, outerIndex = -1, name, param }) => {
      const selectedId = `${name}-${outerIndex}-${innerIndex}`;
      dispatch(setSelectedItemId(selectedId));

      let nextDetail = getNextDetail(innerIndex, outerIndex, param);
      if (!nextDetail?.dataSet?.length) {
        handleDetailPanelClose();
        return;
      }

      setDetailPanelData(nextDetail);
      handleFilterPanelClose();
      handleDetailPanelOpen();
    },
    [mainAssetData, vendorAssetData]
  );

  function getNextDetail(innerIndex, outerIndex = -1, param) {
    if (param === "data") {
      return outerIndex >= 0
        ? {
            dataSet: vendorAssetData[outerIndex]?.vendorData[innerIndex]?.data,
            label: vendorAssetData[outerIndex]?.vendorData[innerIndex]?.label,
            count: vendorAssetData[outerIndex]?.vendorData[innerIndex]?.count,
          }
        : {
            dataSet: mainAssetData[innerIndex].data,
            label: mainAssetData[innerIndex].label,
          };
    } else {
      return outerIndex >= 0
        ? {
            dataSet: vendorAssetData[outerIndex]?.vendorData[innerIndex]?.data,
            label: vendorAssetData[outerIndex]?.vendorData[innerIndex]?.label,
            count: vendorAssetData[outerIndex]?.vendorData[innerIndex]?.count,
          }
        : {
            dataSet: mainAssetData[innerIndex].dataSelf,
            label: mainAssetData[innerIndex].label,
          };
    }
  }

  const handleFilterSearchSubmit = (data) => {
    handleDetailPanelOpen();
    handleFilterPanelClose();
    if (data !== "") {
      handleSearchSubmit(data);
    }
  };

  useEffect(() => {
    setFilterObj(null);
    dispatch(flushAllCategoryData());
    setSelectedCategoryType("");
    setMainFilterDate(new Date());
    handleFilterPanelClose();
    handleDetailPanelClose();
    setSearchTemp("");
    setSearchDeviceValue("");
  }, []);

  useEffect(() => {
    if (currentUserDetail && currentUserDetail?.adminSpecificPermissions) {
      dispatch(getAllOrganizations({ selection: ["name"] }));
      dispatch(flushAllCategoryTypes());
    } else {
      dispatch(getCategorytypeOptions());
    }
  }, [currentUserDetail]);

  useEffect(() => {
    if (searchDeviceValue) {
      if (!Object.keys(searchData).length) return;
      if (!clear && searchData) {
        handleDetailPanelOpen();
        handleFilterPanelClose();
        setClear(true);
      }
      setDetailPanelData(searchData);
    }
  }, [searchData, searchDeviceValue]);

  const onFilterClick = () => {
    if (isFilterPanelOpen) {
      handleFilterPanelClose();
    } else {
      handleDetailPanelClose();
      setFilterData({ label: "Filter" });
      handleFilterPanelOpen();
    }
  };

  const setFilterDate = (date) => {
    setFromDate(date);
  };

  const onClearInput = () => {
    setClear(true);
    handleDetailPanelClose();
    handleFilterPanelClose();
    setSearchTemp("");
    setSearchDeviceValue("");
    dispatch(
      getMainAssetData({
        deviceType: "all",
        orgId: filterObj?.orgId ?? "",
        userId: filterObj?.userId ?? "",
      })
    );
  };

  const onClear = () => {
    handleDetailPanelClose();
    handleFilterPanelClose();

    setFilterObj(null);
    setFromDate(new Date());
    setSelectedCategoryType("");
    setMainFilterDate(new Date());
    handleFilter(false);
    dispatch(
      getMainAssetData({
        deviceType: "all",
      })
    );
    dispatch(flushAllCategoryData());
    if (currentUserDetail && currentUserDetail?.adminSpecificPermissions) {
      dispatch(flushAllCategoryTypes());
    }
  };

  const handleSearchUsers = (empty) => {
    if (empty === true) {
      dispatch(
        getAllUsers({
          selection: ["name"],
          searchValue: "",
          orgId: filterObj?.orgId ? filterObj?.orgId : "",
        })
      );
    } else {
      if (searchFields?.users || searchFields?.users == "")
        dispatch(
          getAllUsers({
            selection: ["name"],
            searchValue: searchFields?.users,
            orgId: filterObj?.orgId ? filterObj?.orgId : "",
          })
        );
    }
  };

  const handleSearchOrg = (empty) => {
    if (empty === true) {
      dispatch(getAllOrganizations({ selection: ["name"] }));
    } else {
      if (searchFields?.org || searchFields?.org == "")
        dispatch(
          getAllOrganizations({
            selection: ["name"],
            searchValue: searchFields.org,
          })
        );
    }
  };

  const Dashboard = ({ children }) => {
    return (
      <div
        className={`asset_management_dashboard ${
          isDetailPanelOpen || isFilterPanelOpen ? "open" : "clos"
        }`}
        style={{
          paddingRight: isDetailPanelOpen || isFilterPanelOpen ? "1em" : "0px",
        }}
      >
        {children}
      </div>
    );
  };
  const showHide = () => {
    // asset_table
  };

  const onApplyFilter = () => {
    handleFilter(true);
    //send filterObj in request payload
    dispatch(
      getMainAssetData({
        deviceType: "all",
        time: moment(mainFilterDate).format("YYYY-MM-DD HH:mm"),
        orgId: filterObj?.orgId ?? "",
        userId: filterObj?.userId ?? "",
      })
    );
    // dispatch(
    //   getVendorAssetData({
    //     page: 1,
    //     limit: 10,
    //     time: moment(mainFilterDate).format("YYYY-MM-DD HH:mm"),
    //   })
    // );
    dispatch(
      getCategorytypeOptions({
        time: moment(mainFilterDate).format("YYYY-MM-DD HH:mm"),
        orgId: filterObj?.orgId ?? "",
        userId: filterObj?.userId ?? "",
      })
    );
  };

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Header themeType={themeType}>
            <Panel
              onClearInput={onClearInput}
              fromDate={fromDate}
              isFilterPanelOpen={isFilterPanelOpen}
              onSubmit={handleFilterSearchSubmit}
              onFilterClick={onFilterClick}
              onClear={onClear}
              filterOpen={filterOpen}
              themeType={themeType}
              isDark={isDarkThemeEnabledSelector}
              searchDeviceValue={searchDeviceValue}
              setSearchDeviceValue={setSearchDeviceValue}
              searchTemp={searchTemp}
              setSearchTemp={setSearchTemp}
            />
          </Header>
          <h6 style={{}}>
            {" "}
            {filterOpen && mainFilterDate
              ? " Date Filter : " +
                moment(mainFilterDate).format("DD-MM-YYYY  hh:mm A")
              : ""}
          </h6>
        </Grid>
        <Grid item xs={12}>
          {isMainAssetDataLoading || isVendorAssetDataLoading ? (
            <Grid
              container
              item={true}
              direction={"column"}
              justifyContent={"center"}
              alignItems="center"
              xs={12}
              sx={{ p: 15 }}
            >
              <Grid item>
                <img src={loaderGif} />
              </Grid>
            </Grid>
          ) : (
            <div
              className={`main_content ${isFilterPanelOpen ? "open " : ""} ${
                isDetailPanelOpen ? "open_categorywise" : ""
              }`}
            >
              <Dashboard>
                <MainAssetData
                  data={mainAssetData}
                  onDetail={handleShowDetails}
                  themeBorderColor={themeBorderColor}
                  isDark={isDarkThemeEnabledSelector}
                  themeType={themeType}
                  loading={isMainAssetDataLoading}
                />
                {isVendorAssetDataLoading || isMainAssetDataLoading ? (
                  <ThreeDotsLoader />
                ) : (
                  <>
                    {categoryTypeOptions && categoryTypeOptions?.length > 0 && (
                      <CategoriesData
                        data={vendorAssetData}
                        onDetail={handleShowDetails}
                        onPageChange={fetchVendorAssetData}
                        themeBorderColor={themeBorderColor}
                        isDark={isDarkThemeEnabledSelector}
                        themeType={themeType}
                        dispatch={dispatch}
                        categoryOptions={categoryTypeOptions}
                        selectedCategoryType={selectedCategoryType}
                        setSelectedCategoryType={setSelectedCategoryType}
                      />
                    )}
                  </>
                )}
              </Dashboard>
              <DetailPanel
                isOpen={isDetailPanelOpen}
                onClose={handleDetailPanelClose}
                data={detailPanelData}
                themeBorderColor={themeBorderColor}
                isDark={isDarkThemeEnabledSelector}
                themeType={themeType}
                filterObj={filterObj}
                currentUserDetail={currentUserDetail}
                searchDeviceValue={searchDeviceValue}
                setSearchDeviceValue={setSearchDeviceValue}
                mainFilterDate={mainFilterDate}
              />
              <FilterPanel
                isOpen={isFilterPanelOpen}
                onClose={handleFilterPanelClose}
                data={filterPanelData}
                mainFilterDate={mainFilterDate}
                setMainFilterDate={setMainFilterDate}
                setFilterDate={setFilterDate}
                fromDate={fromDate}
                handleFilter={handleFilter}
                filterOpen={filterOpen}
                themeBorderColor={themeBorderColor}
                isDark={isDarkThemeEnabledSelector}
                themeType={themeType}
                filterObj={filterObj}
                setFilterObj={setFilterObj}
                applyFiltersFn={onApplyFilter}
                currentUser={currentUserDetail}
                orgOptions={allOrganizations}
                userOptions={allUsers}
                dispatch={dispatch}
                handleSearchOrg={handleSearchOrg}
                handleSearchUsers={handleSearchUsers}
                searchFields={searchFields}
                setSearchFields={setSearchFields}
              />
            </div>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default AssetManagement;
// export var globleFromDate = ''

const Header = ({ children, themeType }) => {
  const { textClasses } = themeType;
  return (
    <div className="header">
      <div className="title">
        {/* <AssetManagementIcon
          style={{
            height: "28px",
            width: "28px",
            // fill: primary
            fill: "blue",
          }}
        /> */}
        <Typography data-testid="assset" sx={{ ...textClasses.cardTitle }}>
          Asset Management
        </Typography>
      </div>
      {children}
    </div>
  );
};

const onSubmit = (dateTime) => {
  //
  // setAlertType(alertForm.alertType);
  // if (alertForm.zoneName && alertForm.zoneName != "") {
  //   // showLoader(true);
  //   dispatch(
  //     getGeofenceMonitoring({
  //       type: "",
  //       searchType: "name",
  //       searchValue: alertForm.zoneName,
  //     })
  //   );
  // } else {
  // showLoader(true);
  //   dispatch(getMapviewAlerts(alertForm));
  // }
};

const deviceDetail = {
  deviceId: {
    title: "Device ID",
    Icon: "",
    key: "deviceId",
    className: "fa fa-info",
  },
  adminName: {
    title: "Admin",
    Icon: "",
    key: "adminName",
    className: "fa fa-",
  },
  vehicleNo: {
    title: "Vehicle",
    Icon: "",
    key: "vehicleNo",
    className: "fa fa-truck",
  },
  packetTime: {
    title: "Packet Time",
    Icon: "",
    key: "packetTime",
    className: "fa fa-clock",
  },
  batteryPercentage: {
    title: "Battery",
    Icon: "",
    key: "",
    className: "fa fa-",
  },
  lockStatus: {
    title: "Status",
    Icon: "",
    key: "lockStatus",
    className: "fa fa-",
  },
  geofenceName: {
    title: "Geofence",
    Icon: "",
    key: "geofence",
    className: "fa fa-location-arrow",
  },
  routeName: {
    title: "Address",
    Icon: "",
    key: "address",
    className: "fa fa-map-marker",
  },
  // {
  // 	title: 'Lat/Lng',
  // 	Icon: '',
  // 	key : '' ,
  // 	className: 'fa fa-'
  // },
};

const MainAssetData = ({
  data,
  onDetail,
  themeBorderColor,
  isDark,
  themeType,
  loading,
}) => {
  return (
    <div className="main_asset_wrap">
      <DeviceTable
        data={data}
        onDetail={onDetail}
        isDark={isDark}
        themeBorderColor={themeBorderColor}
        themeType={themeType}
        loading={loading}
      />
      <DeviceChart
        data={data}
        themeBorderColor={themeBorderColor}
        isDark={isDark}
        themeType={themeType}
        loading={loading}
      />
    </div>
  );
};

export const DeviceTable = memo(
  ({ data = [], onDetail, themeBorderColor, themeType, loading, isDark }) => {
    const selectedId = useSelector(selectSelectedItemId);
    const { textClasses } = themeType;

    return (
      <div
        className="asset_table"
        data-testid="asset_table"
        style={{
          height: "300px",
          borderColor: loading ? "transparent" : themeBorderColor,
        }}
      >
        {loading ? (
          <ThreeDotsLoader />
        ) : (
          <>
            {data.length ? (
              <table className="table ">
                <thead
                  style={{
                    position: "sticky",
                    top: "-1px",
                    zIndex: 1,
                  }}
                >
                  <tr>
                    <th
                      style={{
                        ...textClasses.pageTitleHeading,
                        textAlign: "center",
                      }}
                      data-testid="type"
                    >
                      Type
                    </th>
                    <th
                      style={{
                        ...textClasses.pageTitleHeading,
                        textAlign: "center",
                      }}
                      data-testid="total"
                    >
                      Total{" "}
                    </th>
                    <th
                      style={{
                        ...textClasses.pageTitleHeading,
                        textAlign: "center",
                      }}
                      data-testid="selfowned"
                    >
                      Self Owned
                    </th>
                  </tr>
                </thead>
                <tbody style={{ borderTop: "unset" }}>
                  {data &&
                    data?.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td
                            style={{
                              ...textClasses.t12n,
                              textAlign: "center",
                            }}
                          >
                            {item.label}
                          </td>
                          <td>
                            <div
                              className={`asset_count ${
                                selectedId === `asset_count--1-${index}`
                                  ? "item_selected"
                                  : ""
                              }`}
                              style={{
                                background:
                                  selectedId === `asset_count--1-${index}`
                                    ? "transparent"
                                    : themeType.default.themeOrange,
                                textAlign: "center",
                                verticalAlign: "center",
                                ...textClasses.normalText,
                                color:
                                  selectedId === `asset_count--1-${index}`
                                    ? isDark
                                      ? "#fff"
                                      : themeType.default.themeOrange
                                    : "#fff",
                                width: "90%",
                                minHeight: "28px",
                                border: `1px solid ${themeType.default.themeOrange}`,
                                whiteSpace: "pre-line",
                                wordWrap: "break-word",
                              }}
                              onClick={() =>
                                onDetail({
                                  innerIndex: index,
                                  name: "asset_count",
                                  param: "data",
                                })
                              }
                              data-testid="asset_count"
                            >
                              {Number(item?.count)?.toLocaleString("en-US") ||
                                "0"}
                            </div>
                          </td>
                          <td>
                            <div
                              className={`self_asset_count ${
                                selectedId === `self_asset_count--1-${index}`
                                  ? "item_selected"
                                  : ""
                              }`}
                              style={{
                                background:
                                  selectedId === `self_asset_count--1-${index}`
                                    ? "transparent"
                                    : "#827878",
                                textAlign: "center",
                                verticalAlign: "center",
                                ...textClasses.normalText,
                                color:
                                  selectedId === `self_asset_count--1-${index}`
                                    ? isDark
                                      ? "#fff"
                                      : "#827878"
                                    : "#fff",
                                width: "90%",
                                minHeight: "28px",
                                border: "1px solid #827878",
                                whiteSpace: "pre-line",
                                wordWrap: "break-word",
                              }}
                              onClick={() =>
                                onDetail({
                                  innerIndex: index,
                                  name: "self_asset_count",
                                  param: "dataself",
                                })
                              }
                              data-testid="self_owned_asset"
                            >
                              {Number(item?.countSelf)?.toLocaleString(
                                "en-US"
                              ) || "0"}
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            ) : (
              <span style={{ paddingLeft: "0rem", paddingTop: "20px" }}>
                No Data Available
              </span>
            )}
          </>
        )}
      </div>
    );
  }
);

export const DeviceChart = memo(
  ({ data = [], themeBorderColor, isDark, themeType, loading }) => {
    const labels = data ? data?.map((d) => d?.label) : [];
    const count = data ? data?.map((d) => d?.count) : 0;
    const selfcount = data ? data?.map((d) => d?.countSelf) : 0;

    const datasets = [
      {
        label: "Total",
        data: count,
        // backgroundColor: "rgba(245, 124, 0,0.8)",
        backgroundColor: themeType.default.themeOrange,
        barPercentage: 0.5,
        barThickness: 17,
      },
      {
        label: "Self Owned",
        data: selfcount,
        backgroundColor: "#827878",
        // backgroundColor: "#696969",
        barPercentage: 0.5,
        barThickness: 17,
      },
    ];
    return (
      <>
        {loading ? (
          <ThreeDotsLoader />
        ) : (
          <>
            {count?.length && selfcount?.length ? (
              <div
                className="asset_chart"
                style={{ height: "300px", borderColor: themeBorderColor }}
                data-testid="VerticalBarChart"
              >
                <VerticalBarChart
                  title="Device Chart"
                  labels={labels}
                  datasets={datasets}
                  data-testid="VerticalBarChart"
                  isDark={isDark}
                />
              </div>
            ) : null}
          </>
        )}
      </>
    );
  }
);

const CategoriesData = ({
  data,
  onDetail,
  onPageChange,
  themeBorderColor,
  isDark,
  themeType,
  dispatch,
  categoryOptions,
  selectedCategoryType,
  setSelectedCategoryType,
}) => {
  const { textClasses, inputClasses } = themeType;
  const vendorCount = useSelector(selectVendorAssetCount);
  const [currPage, setCurrPage] = useState(1);
  const handlePageChange = (newPage) => {
    if (newPage === currPage) return;
    setCurrPage(newPage);
    onPageChange(newPage);
  };
  return (
    <div className="categories" data-testid="detail_panel_wrap">
      <div
        className="categories__header"
        style={{ border: "none", gap: "16px" }}
      >
        <Typography data-testid="categories" sx={{ ...textClasses.cardTitle }}>
          Categories
        </Typography>
        {categoryOptions && categoryOptions?.length > 1 && (
          <CustomSelect
            label="Select type"
            selectStylesOverride={selectStylesOverride(isDark)}
            sx={{ ...inputClasses.filterField, minWidth: "200px" }}
            options={categoryOptions}
            value={selectedCategoryType || null}
            onChange={(e, newVal, reason) => {
              if (reason === "clear") {
                dispatch(flushAllCategoryData());
                setSelectedCategoryType("");
              } else {
                setSelectedCategoryType(newVal?.value);
                onPageChange(1, newVal?.value);
              }
            }}
            getOptionLabel={(option) => {
              if (typeof option === "string") {
                return (
                  categoryOptions?.find((_) => _?.value == selectedCategoryType)
                    ?.label || ""
                );
              } else {
                return option?.label || "";
              }
            }}
          />
        )}
      </div>
      <Categories
        data={data}
        onDetail={onDetail}
        themeBorderColor={themeBorderColor}
        themeType={themeType}
        isDark={isDark}
        selectedCategoryType={selectedCategoryType}
      />
      <div className="pagination_wrap">
        {/* <Pagination
          currentPage={currPage}
          totalCount={vendorCount}
          pageSize={10}
          onPageChange={handlePageChange}
        /> */}
      </div>
    </div>
  );
};

const Categories = ({
  data,
  onDetail,
  themeBorderColor,
  themeType,
  selectedCategoryType,
  isDark,
}) => {
  return (
    <div className="categories__cards" data-testid="categories__cards">
      {data?.length ? (
        data.map((category, index) => {
          return (
            <CategoryCard
              data={category}
              onDetail={onDetail}
              outerIndex={index}
              key={index}
              themeBorderColor={themeBorderColor}
              themeType={themeType}
              isDark={isDark}
            />
          );
        })
      ) : (
        <span style={{ paddingLeft: "0.7rem" }}>
          {selectedCategoryType ? "No Data Available" : ""}
        </span>
      )}
    </div>
  );
};

const CategoryCard = ({
  data,
  onDetail,
  outerIndex,
  themeBorderColor,
  themeType,
  isDark,
}) => {
  const { textClasses } = themeType;
  const selectedId = useSelector(selectSelectedItemId);

  return (
    <div
      className="categoryCard"
      data-testid="category__card"
      style={{ borderColor: themeBorderColor }}
    >
      <div
        className="categoryCard__title"
        style={{ ...textClasses.pageTitleHeading }}
      >
        {data?.userName}
      </div>
      <div className="categoryCard__body">
        {data?.vendorData.map((_, index) => (
          <div className="categoryCard__row" key={index}>
            <div
              className="categoryCard__col"
              style={{ ...textClasses.pageSubText }}
            >
              {_.label}
            </div>
            <div
              className={`categoryCard__col ${
                selectedId === `categoryCard__col-${outerIndex}-${index}`
                  ? "item_selected"
                  : ""
              }`}
              style={{
                background:
                  selectedId === `categoryCard__col-${outerIndex}-${index}`
                    ? "transparent"
                    : themeType.default.themeOrange,
                color:
                  selectedId === `categoryCard__col-${outerIndex}-${index}`
                    ? isDark
                      ? "#fff"
                      : themeType.default.themeOrange
                    : "#fff",
              }}
              onClick={() => {
                onDetail({
                  innerIndex: index,
                  outerIndex,
                  name: "categoryCard__col",
                });
              }}
              data-testid="category__card__count"
            >
              {Number(_.count)?.toLocaleString("en-US") || 0}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

//hooks
export const useMainAssetCount = () => {
  const mainAssetData = useSelector(selectMainAssetData);
  const mainAssetDownloadData = useSelector(selectMainAssetDownloadData);
  const isMainAssetDataLoading = useSelector(selectIsMainAssetLoading);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      getMainAssetData({
        deviceType: "all",
      })
    );
  }, []);

  return {
    // mainAssetData: mockData.mainAssetData,
    mainAssetData,
    mainAssetDownloadData,
    isMainAssetDataLoading,
  };
};

export const useVendorAsset = (
  categoryTypeOptions,
  selectedCategoryType,
  setSelectedCategoryType,
  mainFilterDate,
  filterObj
) => {
  const vendorAssetData = useSelector(selectVendorAssetData);
  const isVendorAssetDataLoading = useSelector(selectIsVendorAssetLoading);
  const dispatch = useDispatch();
  function fetchVendorAssetData(pageNo, type = "") {
    dispatch(
      getVendorAssetData({
        page: pageNo,
        limit: 10,
        userType: type || selectedCategoryType,
        deviceType: "all",
        time: moment(mainFilterDate).format("YYYY-MM-DD HH:mm"),
        orgId: filterObj?.orgId ?? "",
        userId: filterObj?.userId ?? "",
      })
    );
  }
  // useEffect(() => {
  //   fetchVendorAssetData(1);
  // }, []);

  useEffect(() => {
    if (categoryTypeOptions && categoryTypeOptions?.length > 0) {
      if (categoryTypeOptions?.length === 1) {
        setSelectedCategoryType(categoryTypeOptions[0]?.value);
        fetchVendorAssetData(1, categoryTypeOptions[0]?.value);
      }
    }
  }, [categoryTypeOptions]);

  return {
    // vendorAssetData: mockData.vendorAssetData,
    vendorAssetData,
    isVendorAssetDataLoading,
    fetchVendorAssetData,
  };
};

const useDetailPanel = () => {
  const dispatch = useDispatch();
  const [isDetailPanelOpen, setIsDetailPanelOpen] = useState(false);
  const [detailPanelData, setDetailPanelData] = useState([]);
  const handleDetailPanelClose = () => {
    setIsDetailPanelOpen(false);
    dispatch(setSelectedItemId(""));
  };
  const setData = (newData) => {
    setDetailPanelData(newData);
  };
  const handleDetailPanelOpen = () => {
    setIsDetailPanelOpen(true);
  };
  return {
    isDetailPanelOpen,
    detailPanelData,
    handleDetailPanelClose,
    handleDetailPanelOpen,
    setData,
  };
};

const useSearch = (date, filterObj) => {
  const dispatch = useDispatch();
  const searchData = useSelector(selectSearchData);
  const isSearchLoading = useSelector(selectIsSearchDataLoading);
  const filterPanel = useFilterPanel();
  // const [searchValue, setSearchValue] = useState('')
  const handleSearchSubmit = (search) => {
    searchByDeviceId(search ? search?.toUpperCase() : "");
  };

  function searchByDeviceId(id) {
    // console.log(date);
    dispatch(
      getMainAssetData({
        deviceType: "all",
        deviceId: id,
        time: date ? moment(date).format("YYYY-MM-DD HH:mm") : "",
        orgId: filterObj?.orgId ?? "",
        userId: filterObj?.userId ?? "",
      })
    );
  }

  return {
    handleSearchSubmit,
    searchData,
    isSearchLoading,
  };
};

const useSubmit = () => {
  // const dispatch = useDispatch();
  // const datefilter = (e) => {
  // 	console.log("eeee", e);
  // }
  // const [form, setForm] = useState({
  // 	alertType: "",
  // 	zoneName: "",
  // 	fromDate: moment().format("YYYY-MM-DDTHH:MM"),
  // 	toDate: moment().format("YYYY-MM-DDTHH:MM"),
  // });
  // return {
  // 	handleSubmitDateFilter
  // }
};

//utils
function getRandomClr() {
  return `#${Math.floor(Math.random() * 16777215).toString(16)}`;
}

export function capitalize(str) {
  if (!str) return;
  return str[0].toUpperCase().concat(str.slice(1));
}

function convertTime(dateTime) {
  const secs = dateTime;
  const output = new Date(secs * 1000);
  return output;
}

const GenericTable = ({ headings, data = [] }) => {
  return (
    // <Table bordered responsive size="sm" className="table">
    <table className="table">
      <thead>
        <tr>
          {headings.map((heading, index) => {
            return <th key={index}>{heading}</th>;
          })}
        </tr>
      </thead>
      <tbody>
        {data.map((row, rIndex) => {
          return (
            <tr key={rIndex}>
              {row.map((col, cIndex) => {
                return <td key={cIndex}>{col}</td>;
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};
