import React from "react";
import s from "./components.module.css";
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";

const SlimTable = ({tableColumns=[], data=[], themeType}) => {

  const {textClasses, tableClasses} = themeType;

  return (
    <TableContainer component={Paper} sx={{border: "1px solid", borderColor: themeType.default.themeOrange,}}>
      <Table
        // className={s["details-table"]}
        style={{
          width: "100%",
          ...textClasses?.t12n,
        }}
      >
        <TableHead>
          <TableRow
            sx={{
              // background: theme => theme.palette.action.disabled,
              background: themeType.default.themeOrange,
            }}
          >
            {tableColumns?.map((column, i) => {
              return(
                <TableCell style={{ padding: "5px"}} key={i}>
                  <Typography
                    sx={{
                      ...textClasses.t12n,
                      // color: theme => theme.palette.getContrastText,
                      color: "#ffffff",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {column?.header}
                  </Typography>
                </TableCell>
              )
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.length > 0 ? (
            <>
              {data?.map((row, idx) => (
                <TableRow key={idx} 
                  sx={{
                    '& td, & th': {
                      borderBottom: '1px solid #FF7200', // Change border width and color here
                    },
                  }}
                >
                  {tableColumns?.map((col, index) => (
                    <TableCell style={{ padding: "5px" }} key={index}>
                      <Typography
                        sx={{
                          ...textClasses.t12n,
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          color: 'unset'
                        }}
                      >
                        {col?.cell(row, index)}
                      </Typography>
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </>
          ) : (
            <TableRow>
              <TableCell
                component="th"
                scope="row"
                style={{
                  ...textClasses.normalText,
                  ...tableClasses.dark,
                  textAlign: "left",
                  overflowWrap: "break-word",
                }}
              >
                No Data Found
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default SlimTable;
