import { Visibility } from "@mui/icons-material";
import {
  Box,
  FormControl,
  FormControlLabel,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";

export const CustomInput = ({
  name,
  required = false,
  value,
  onChange,
  errors = {},
  placeholder,
  label="",
  disabled,
  inputProps,
  InputProps={},
  inputClasses,
  multiline,
  rows,
  defaultValue,
  type,
  width = "100%",
  maxWidth,
  minValue,
  maxValue,
  centreState,
  hasError = false,
  helperText = "",
  selectStylesOverride,
  extraStyles = null,
  ...props
}) => {
  return (
    <Box display="flex" flexDirection="column" width={width} maxWidth={maxWidth}>
      <FormControl sx={{ ...selectStylesOverride, width: "100%" }}>
        {/* <InputLabel sx={{ fontSize: "12px" }}>{label}{required && "*"}</InputLabel> */}
        <TextField
          size="small"
          role="textbox"
          value={value}
          onChange={onChange}
          multiline={multiline}
          rows={multiline ? 1 : rows}
          helperText={helperText}
          defaultValue={defaultValue}
          label={
            label || placeholder ? (
              <Typography sx={{ fontSize: "12px" }}>
                {label ? label : placeholder}
                {required && "*"}
              </Typography>
            ) : null
          }
          sx={{
            ...inputClasses?.textfield,
            ...selectStylesOverride,
            // "&Mui-focused": {
            //   transform: "translate(14px, -3px) scale(0.75)",
            // },
            height: "36px",
          }}
          inputProps={{
            ...inputProps,
            style: {
              padding: "8.5px 11px",
              textAlign: centreState ? 'center' : 'inherit',
              ...extraStyles
            },
            min : minValue,
            max : maxValue,
            step: 1,
          }}
          InputProps={InputProps}
          type={type}
          disabled={disabled}
          error={hasError || errors[name] ? true : false}
          placeholder={placeholder}
          {...props}
        />
      </FormControl>
      {errors[name] && (
        <span role="alert" style={{ color: "#FF0101", fontSize: 11 }}>
          {errors[name]?.message}
        </span>
      )}
    </Box>
  );
};
