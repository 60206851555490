import { convertIntoGivenTimezone } from "../../../services/functions";

export function main(str, pKey, pLabel = "", objFamily, index, currentUserDetail) {
  switch (str) {
    case "fncscheduleName":
      return fncscheduleName(objFamily, pKey);
    case "fnrouteName":
      return fnrouteName(objFamily, pKey);
    case "fnisDisabled":
      return fnisDisabled(objFamily, pKey);
    case "addedBy.name":
      return fnAddedBy(objFamily, pKey);
    case "createdAt":
      return createdAt(objFamily, pKey, currentUserDetail);
    default:
      try {
        let valueStr = getNestedValues(objFamily, pKey);
        return `<span style="font-size:13px ">${valueStr}</span>`;
      } catch (error) {
        return pLabel && pLabel != "" ? `${pLabel} : - ` : "-";
      }
  }

  function fncscheduleName(objFamily, key) {
    if (objFamily) {
      const value = objFamily?.scheduleName ?? "-";

      if (value?.length > 14) {
        let shortText =
          value && value?.length > 14
            ? value?.substring(0, 14) + "..."
            : value ?? "-";
        return `<div class="tooltip-container">
                  <span style=" cursor: pointer; font-size:13px;">${shortText}</span>
                  <span class="tooltip-content">${value}</span>
              </div>`;
      } else {
        return `<span style="font-size:13px;"> ${value} </span>`;
      }
    }else {
      return "-"
    }
  }

  function fnrouteName(objFamily, key) {
    if (objFamily) {
      const value = objFamily?.data
        ? objFamily?.data[0]?.route?.routeName
        : "-";

      if (value?.length > 14) {
        let shortText =
          value && value?.length > 14
            ? value?.substring(0, 14) + "..."
            : value ?? "-";
        return `<div class="tooltip-container">
                  <span style=" cursor: pointer; font-size:13px;">${shortText}</span>
                  <span class="tooltip-content">${value}</span>
              </div>`;
      } else {
        return `<span style="font-size:13px;"> ${value} </span>`;
      }
    }
  }

  function fnisDisabled(objFamily, key) {
    const value = objFamily?.isDisabled;
    if(value){
      return `<span style="color: red; font-size: 13px; font-weight: 400">Disable</span>`;
    }else{
      return `<span style="color: #008000; font-size: 13px; font-weight: 400">Active</span>`;
    }
  }

  function fnAddedBy(objFamily, key) {
    if(objFamily){
      const value = objFamily?.addedBy ? `${objFamily?.addedBy?.name} - ${objFamily?.addedBy?.orgName}` : "-";
      return value;
    }else{
      return "-"
    }
  }

  function createdAt(objFamily, key, currentUserDetail) {
    if(objFamily && currentUserDetail){
      const value = convertIntoGivenTimezone(
        objFamily?.createdAt,
        currentUserDetail.timeZone.offset
      )
      return value || "-";
    }else{
      return "-"
    }
  }

  function getNestedValues(objFamily, keyPath) {
    const keys = keyPath.split(".");
    let value = objFamily;
    for (const key of keys) {
      if (value && value.hasOwnProperty(key)) {
        value = value[key] || "-";
      } else {
        return "-";
      }
    }
    return value;
  }
}
