import { Paper, TablePagination } from "@mui/material";
import React from "react";

const OrganisationTable = ({
  testId,
  tableColumns = [],
  data,
  totalRecords,
  pagination,
  setPagination,
  themeType,
  handlePaginationChange,
}) => {


  const {
    textClasses,
    tableClasses,
  } = themeType;


  return (
    <>
      <table style={{ width: "100%" }}>
        <thead
          style={{
            backgroundColor: themeType.default.themeGray,
            position: "sticky",
            top: "-1px",
            zIndex: 1,
          }}
        >
          <tr className="roundedRow">
            {tableColumns?.map((column, index) => {
              return (
                <td
                  key={index}
                  style={{
                    ...textClasses.boldText,
                    ...tableClasses.tableHeader,
                    textAlign: "center",
                    padding: '3.5px'
                  }}
                >
                  {column?.header}
                </td>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {data?.length > 0 ? (
            <>
              {data?.map((entry, key) => (
                <tr data-testid='tablerow' key={key} style={{ borderBottom: "1px solid #0000002B" }}>
                  {tableColumns?.map((column, index) => {
                    return (<td
                      data-testid="tablecell"
                      key={index}
                      style={{ textAlign: "center", ...textClasses.normalText, ...tableClasses.tableBodyClass, ...tableClasses.tableBodyClass, textAlign: "center", padding: '5px' }}
                    >
                      {column?.key === "Sno." || column?.header === "Actions"
                        ? column?.cell(key) : column?.selector === "checkbox" ? column?.cell(key, entry)
                          : column?.cell(entry)}
                    </td>)
                  }
                  )}
                </tr>
              ))}
            </>
          ) : (
            <tr sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
              <td
                component="th"
                scope="row"
                style={{
                  ...textClasses.normalText,
                  ...tableClasses.dark,
                  textAlign: "left",
                  overflowWrap: "break-word",
                }}
              >
                No Data Found
              </td>
            </tr>
          )}
        </tbody>
      </table>
        <TablePagination
          data-testid="tablePagination"
          component="div"
          sx={{
          ...tableClasses.tableHeader,
          ...tableClasses.tablePagination,
          display: "flex",
          justifyContent: "center",
          position: "sticky",
          bottom: "0px",
          alignItems: "center",
          mt: "0em",
          "& .MuiTablePagination-selectLabel ": {
            margin: "0",
          },
          "& .MuiTablePagination-displayedRows": {
            margin: "0",
          },
        }}
          count={totalRecords || 0}
          page={(pagination.page || 1) - 1}
          onPageChange={(e, newPage) => {
            setPagination({ ...pagination, "page": newPage + 1 })
            handlePaginationChange()
          }}
          rowsPerPage={pagination.limit}
          onRowsPerPageChange={(e) => {
            setPagination({ ...pagination, "page": 1, "limit": e.target.value })
            handlePaginationChange()
          }}
        />
        </>
  );
};

export default OrganisationTable;
