import Modal from "@mui/material/Modal";
import styles from "./CreateUserRoleModal.module.scss";
import { showToast } from "../../../../../services/functions";
import React, { useState, useMemo, useCallback, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import AlertDialog from "../../../../../views/Components/DialogBox/dialogbox";
import {
  FormControl,
  Grid,
  OutlinedInput,
  Button,
  FormLabel,
  Typography,
  Tab,
  Tabs,
  Box,
  Checkbox,
  Breadcrumbs,
  Card,
  CircularProgress,
  InputLabel,
} from "@mui/material";
import { ToastContainer } from "react-toastify";
import CustomSelect from "../../../../../components/FormUI/CustomSelect";
import {
  createPermissionRole,
  getOrganisation,
  getPermissionRoles,
  updatePermissionRole,
} from "../../../../../redux/actions";
import Actions from "../../../../../utils/constants";
import getActiveOrganisations from "../../../../../utils/getActiveOrganisations";
import { Controller, useForm } from "react-hook-form";
import CustomModel from "../../../../Components/CustomModels/CustomModel1";
const userRoleTypeOptions = [
  { value: "admin", label: "Administrative" },
  { value: "user", label: "Sub user" },
];

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  transition: "all 0.3s ease-out",
  minWidth: 400,
  maxWidth: 800,
  maxHeight: "90vh",
  bgcolor: "background.paper",
  borderRadius: "10px",
  boxShadow: 24,
  pt: 3,
  pb: 3,
  pl: 3,
  pr: 3,
  overflow: "auto",
  border: "none",
};

export default function CreateUserRoleModal({
  themeType,
  userDetails,
  // permissionRoles,
  setAction,
  setSelectedCard,
  loader,
  openModal,
  hide,
  selectedCard,
  action,
  selectStylesOverride,
  isSuperAdmin,
  OrganisationListing,
  handleSearchOrgForCreate,
  searchFields,
  setSearchFields,
}) {
  const { buttonClasses, inputClasses, textClasses } = themeType;

  const [addRoleData, setAddRoleData] = useState({
    name: action === Actions.ADD ? "" : selectedCard.name,
    permissions: {},
    type: "user",
  });
  const [permissionRoles, setPermissionRoles] = useState(null);

  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const defaultRoleType = userRoleTypeOptions.find(
    (type) => type.value === "user"
  );
  const [selectedRoleType, setSelectedRoleType] = useState(defaultRoleType);
  const [selectedOrg, setSelectedOrg] = useState();
  const { message, error } = useSelector((state) => state.common);
  const [dialog, setDialog] = useState(false);
  const [callStatusChange, setCallStatusChange] = useState(false);
  const [editCallStatus, setEditCalStatus] = useState(false);

  // --> EDIT
  useEffect(() => {
    if (isSuperAdmin && OrganisationListing && action === Actions.EDIT) {
      const org = OrganisationListing.find(
        (org) => org._id === selectedCard?.orgId
      );
      if (org) {
        setSelectedOrg(org);
        setAddRoleData((prev) => ({ ...prev, orgId: org._id }));
        dispatch(
          getOrganisation({ id: org._id }, (res) => {
            if (res) {
              setPermissionRoles(res?.permissions);
            }
          })
        );
      }else if(selectedCard?.orgId){
        dispatch(
          getOrganisation({ id: selectedCard?.orgId }, (res) => {
            if (res) {
              setPermissionRoles(res?.permissions);
              setSelectedOrg(res);
              setAddRoleData((prev) => ({ ...prev, orgId: res?._id }));
            }
          })
        );
      }
      const type = userRoleTypeOptions.find(
        (type) => selectedCard?.type === type.value
      );
      setSelectedRoleType(type);
      setAddRoleData((prev) => ({ ...prev, type: type.value }));
    } else if (!isSuperAdmin && action === Actions.EDIT && userDetails) {
      const orgId = userDetails?.orgId;
      if (orgId) {
        dispatch(
          getOrganisation({ id: orgId }, (res) => {
            if (res) {
              setSelectedOrg(res);
              // add permissions
              setPermissionRoles(res?.permissions);
            }
          })
        );
        setAddRoleData((prev) => ({
          ...prev,
          // permissions: { ...userDetails?.userRole?.permissions },
          orgId: orgId,
        }));
        // setSelectedOrg({
        //   _id: orgId,
        //   name: userDetails?.orgName,
        // });
      }
      const type = userRoleTypeOptions.find(
        (type) => selectedCard?.type === type.value
      );
      // console.log("type: ", type)
      setSelectedRoleType(type);
      setAddRoleData((prev) => ({
        ...prev,
        // permissions: { ...userDetails?.userRole?.permissions },
        type: type.value,
      }));
    }
  }, [
    isSuperAdmin,
    OrganisationListing,
    setSelectedOrg,
    setSelectedRoleType,
    setAddRoleData,
    selectedCard,
    action,
    userDetails,
  ]);

  useEffect(() => {
    if (!isSuperAdmin) {
      // console.log("add, false superAdmin");
      const temp = { ...addRoleData };
      // console.log("tempppp: ", temp)
      temp["orgId"] = userDetails?.orgId;
      setAddRoleData(temp);
      if (userDetails?.orgId) {
        dispatch(
          getOrganisation({ id: userDetails?.orgId }, (res) => {
            if (res) {
              setSelectedOrg(res);
              // add permissions
              setPermissionRoles(res?.permissions);
            }
          })
        );
      }
    }
    if (selectedCard && selectedCard.permissions && action === Actions.EDIT) {
      const type = userRoleTypeOptions.find(
        (type) => selectedCard?.type === type.value
      );
      setAddRoleData((prev) => ({
        ...prev,
        type: type?.value,
        permissions: JSON.parse(JSON.stringify(selectedCard.permissions)), //comment it
      }));
    }
  }, [selectedCard, setAddRoleData, isSuperAdmin, userDetails]);

  // To set organisation Id when a user is creating a role
  // useEffect(() => {
  //   if (!isSuperAdmin) {
  //     const temp = { ...addRoleData };
  //     temp["orgId"] = userDetails?.orgId;
  //     setAddRoleData(temp);
  //   }
  // }, [setAddRoleData, userDetails, isSuperAdmin]);

  const disableSubmitButton = () => {
    if (action === Actions.EDIT && selectedOrg === undefined) return true;
    if (!addRoleData.name) return true;
    if (
      !addRoleData.name ||
      !addRoleData.permissions ||
      Object.keys(addRoleData.permissions).length == 0
    )
      return true;
    return false;
  };

  const handleClose = (e) => {
    setOpen(!open);
    setAddRoleData({
      name: "",
      permissions: {},
      type: "user",
    });
  };

  const areAllChecked = useMemo(() => {
    if (permissionRoles) {
      return Object.keys(permissionRoles)?.every((category) => {
        return Object.keys(permissionRoles[category])?.every(
          (permission) =>
            addRoleData.permissions &&
            addRoleData.permissions[category] &&
            addRoleData.permissions[category]?.hasOwnProperty(permission)
        );
      });
    }
  }, [permissionRoles, addRoleData]);

  const allChecked = useCallback(
    (e, item) => {
      if (e.target.checked) {
        const tempPermissions = {};
        const checkPermissions = {};
        for (const category in permissionRoles) {
          tempPermissions[category] = {};
          for (const permission in permissionRoles[category]) {
            if (permission === "label") {
              tempPermissions[category][permission] =
                permissionRoles[category].label;
            } else {
              tempPermissions[category][permission] = {
                label: permissionRoles[category][permission].label,
                value: true,
              };
            }
          }
        }
        setAddRoleData((prev) => {
          return { ...prev, permissions: tempPermissions };
        });
      } else {
        setAddRoleData((prev) => {
          return { ...prev, permissions: {} };
        });
      }
    },
    [permissionRoles, setAddRoleData]
  );

  const handleRoleTypeChange = useCallback(
    (e, value) => {
      if (value !== null) {
        setSelectedRoleType(
          userRoleTypeOptions.find((type) => type.value === value.value)
        );
        const temp = { ...addRoleData };
        temp["type"] = value?.value ?? null;
        setAddRoleData(temp);
      } else {
        setSelectedRoleType(null);
        const temp = { ...addRoleData };
        temp["type"] = undefined;
        setAddRoleData(temp);
      }
    },
    [setAddRoleData, addRoleData, setSelectedRoleType]
  );

  const handleOrganisation = useCallback(
    (e, value) => {
      if (value !== null) {
        const temp = { ...addRoleData };
        temp["orgId"] = value._id;
        setAddRoleData(temp);
        dispatch(
          getOrganisation({ id: value._id }, (res) => {
            if (res) {
              setPermissionRoles(res?.permissions);
            }
          })
        );
        setPermissionRoles(value?.permissions);
      } else {
        const temp = { ...addRoleData };
        temp["orgId"] = undefined;
        setAddRoleData(temp);
        setPermissionRoles(null);
      }
    },
    [setAddRoleData, addRoleData]
  );

  const handleRefresh = useCallback(() => {
    hide();
    setSelectedOrg();
    setSelectedRoleType(defaultRoleType);
    setSelectedCard([]);
    setAction(Actions.ADD);
  }, [
    hide,
    setSelectedOrg,
    setSelectedRoleType,
    setSelectedCard,
    defaultRoleType,
    setAction,
  ]);

  const handleSubmit = useCallback(() => {
    if (isSuperAdmin && !addRoleData?.orgId) {
      showToast("Please select organisation", true);
    } else if (addRoleData.name === "") {
      showToast("Please enter role name", true);
    } else if (Object.keys(addRoleData.permissions).length === 0) {
      showToast("Please select atleast one Permission", true);
    } else if (addRoleData.type === undefined || addRoleData.type === null) {
      showToast("Please select Role Type", true);
    } else if (action === Actions.ADD) {
      setCallStatusChange(true);

      const updatedPermissionsObj = JSON.parse(JSON.stringify(permissionRoles));
      const updatedPermissions = addRoleData.permissions;

      for (const key in updatedPermissions) {
        if (updatedPermissionsObj[key]) {
          for (const permissionKey in updatedPermissions[key]) {
            if (
              typeof updatedPermissionsObj[key][permissionKey] === "object" &&
              updatedPermissionsObj[key][permissionKey] !== null
            ) {
              updatedPermissionsObj[key][permissionKey].value =
                updatedPermissions[key][permissionKey].value;
            }
          }
        }
      }

      for (const key in permissionRoles) {
        if (updatedPermissionsObj[key]) {
          for (const permissionKey in permissionRoles[key]) {
            if (
              typeof updatedPermissionsObj[key][permissionKey] === "object" &&
              updatedPermissionsObj[key][permissionKey] !== null
            ) {
              if (
                !addRoleData.permissions[key] ||
                !addRoleData.permissions[key][permissionKey]
              ) {
                updatedPermissionsObj[key][permissionKey].value = false;
              }
            }
          }
        }
      }

      const payload = {
        ...addRoleData,
        permissions: updatedPermissionsObj,
      };
      dispatch(
        createPermissionRole(addRoleData, (message) => {
          setCallStatusChange(false);
          handleRefresh();
          setSelectedOrg();
          setSelectedRoleType(defaultRoleType);
        })
      );
    } else {
      setDialog(true);
    }
  }, [
    dispatch,
    action,
    defaultRoleType,
    setAddRoleData,
    addRoleData,
    setCallStatusChange,
    setDialog,
    handleRefresh,
    setSelectedOrg,
    setSelectedRoleType,
  ]);

  const checkboxChange = useCallback(
    (e, mainCategory) => {
      setAddRoleData((prevData) => {
        const temp = { ...prevData };
        if (!temp.permissions[mainCategory]) {
          temp.permissions[mainCategory] = {};
          temp.permissions[mainCategory]["label"] =
            permissionRoles[mainCategory].label;
        }

        if (
          temp.permissions[mainCategory][e] &&
          temp.permissions[mainCategory][e].value === true
        ) {
          delete temp.permissions[mainCategory][e];
        } else {
          temp.permissions[mainCategory][e] = {
            label: permissionRoles[mainCategory][e].label,
            value: true,
          };
        }
        return temp;
      });
    },
    [setAddRoleData, addRoleData, permissionRoles]
  );

  const categoryWiseCheck = useCallback(
    (e, item, index) => {
      setAddRoleData((prevData) => {
        const temp = { ...prevData };
        if (!temp.permissions[item]) {
          temp.permissions[item] = {};
          temp.permissions[item]["label"] = permissionRoles[item].label;
          Object.keys(permissionRoles[item])
            .filter((permission) => permission !== "label")
            .forEach((key) => {
              {
                temp.permissions[item][key] = {
                  label: permissionRoles[item][key].label,
                  value: true,
                };
              }
            });
        } else {
          const allKeysSelected = Object.keys(permissionRoles[item]).every(
            (key) => {
              return temp.permissions[item].hasOwnProperty(key);
            }
          );
          if (allKeysSelected) {
            delete temp.permissions[item];
          } else {
            delete temp.permissions[item];
            temp.permissions[item] = {};
            temp.permissions[item]["label"] = permissionRoles[item].label;

            Object.keys(permissionRoles[item])
              .filter((permission) => permission !== "label")
              .forEach((key) => {
                temp.permissions[item][key] = {
                  label: permissionRoles[item][key].label,
                  value: true,
                };
              });
          }
        }
        return temp;
      });
    },
    [setAddRoleData, permissionRoles]
  );

  useEffect(() => {
    if (message && editCallStatus) {
      showToast(message, false);
      setEditCalStatus(false);
      hide();
      dispatch(getPermissionRoles());
    }

    // if (error && editCallStatus) {
    //     showToast(error, true)
    //     setEditCalStatus(false)
    // }
  }, [
    message,
    dispatch,
    setEditCalStatus,
    handleRefresh,
    editCallStatus,
    hide,
  ]);

  useEffect(() => {
    if (message && callStatusChange) {
      showToast(message, false);
      setCallStatusChange(false);
      hide();
      dispatch(getPermissionRoles());
    }

    // if (error && callStatusChange) {
    //     showToast(error, true)
    //     setCallStatusChange(false)
    // }
  }, [message, dispatch, callStatusChange, setCallStatusChange, hide]);

  // console.log("addRoleData: ", addRoleData)
  // console.log("selectedCard: ", selectedCard)

  const handleEdit = () => {
    const temp = { ...addRoleData, id: selectedCard._id };
    // console.log("addRoleData inside edit: ", addRoleData)

    delete temp?.orgId;

    if (selectedCard.name === addRoleData.name) {
      delete temp.name;
    }

    const updatedPermissionsObj = JSON.parse(JSON.stringify(permissionRoles));
    const updatedPermissions = addRoleData.permissions;

    for (const key in updatedPermissions) {
      if (updatedPermissionsObj[key]) {
        for (const permissionKey in updatedPermissions[key]) {
          if (
            typeof updatedPermissionsObj[key][permissionKey] === "object" &&
            updatedPermissionsObj[key][permissionKey] !== null
          ) {
            updatedPermissionsObj[key][permissionKey].value =
              updatedPermissions[key][permissionKey].value;
          }
        }
      }
    }

    for (const key in permissionRoles) {
      if (updatedPermissionsObj[key]) {
        for (const permissionKey in permissionRoles[key]) {
          if (
            typeof updatedPermissionsObj[key][permissionKey] === "object" &&
            updatedPermissionsObj[key][permissionKey] !== null
          ) {
            if (
              !addRoleData.permissions[key] ||
              !addRoleData.permissions[key][permissionKey]
            ) {
              updatedPermissionsObj[key][permissionKey].value = false;
            }
          }
        }
      }
    }

    const payload = {
      ...temp,
      permissions: updatedPermissionsObj,
    };
    setEditCalStatus(true);
    dispatch(
      updatePermissionRole(payload, (success) => {
        setEditCalStatus(false);
        handleRefresh();
        setSelectedRoleType(defaultRoleType);
      })
    );
  };

  const activeOrganisations = getActiveOrganisations(OrganisationListing);
  return (
    <CustomModel
      autoClose={false}
      show={openModal || false}
      onClose={() => {
        hide();
        setAddRoleData({});
      }}
      minWidth={770}
      maxWidth={870}
      pb={3}
      pl={3}
      pr={3}
      maxHeight={"80vh"}
      childrenMaxHeight={"80vh"}
      Title={
        action === Actions.ADD ? "Create Custom Role" : "Update Custom Role"
      }
      viewCloseIcon={openModal}
    >
      <Grid container mb={2} data-testid="editModel">
        <Card sx={{ width: "100%" }}>
          <Grid container>
            <Grid
              item
              sm
              flexDirection={"column"}
              paddingRight={2}
              paddingTop={1}
              sx={{
                borderRight: "1px solid #D9D9D9",
                height: "auto",
                overflow: "auto",
              }}
            >
              <Box marginTop={1} sx={{ height: "55vh", overflow: "auto" }}>
                {isSuperAdmin && (
                  <Box margin={2}>
                    <CustomSelect
                      label="Select Organisation *"
                      options={activeOrganisations}
                      onChange={handleOrganisation}
                      labelKey="name"
                      getOptionLabel={(option) => option?.name}
                      selectStylesOverride={selectStylesOverride}
                      value={selectedOrg}
                      disabled={action === Actions.EDIT}
                      searchValue={
                        searchFields?.createOrg && searchFields?.createOrg !== ""
                          ? searchFields?.createOrg
                          : null
                      }
                      onSearch={handleSearchOrgForCreate}
                      onInputChange={(e) => {
                        if (e?.type === "change") {
                          if (e?.target?.value === "") handleSearchOrgForCreate(true);
                          setSearchFields((prev) => ({
                            ...prev,
                            createOrg: e?.target?.value?.trim(),
                          }));
                        }
                      }}
                    />
                  </Box>
                )}

                <Box margin={2}>
                  <FormControl
                    variant="outlined"
                    size="small"
                    fullWidth
                    sx={{ ...selectStylesOverride }}
                  >
                    <InputLabel
                      htmlFor="outlined-adornment-password"
                      sx={{ fontSize: "12px" }}
                    >
                      Role Name *
                    </InputLabel>
                    <OutlinedInput
                      data-testid="inputElem"
                      value={addRoleData.name ?? ""}
                      onChange={(e) => {
                        setAddRoleData({
                          ...addRoleData,
                          name: e.target.value,
                        });
                      }}
                      // disabled={action === "view" ? true : false}
                      // error={errorMessage.name ? true : false}
                      required
                      type="text"
                      sx={{
                        ...selectStylesOverride,
                        fontSize: "12px",
                        boxShadow: 1,
                        "& input::placeholder": {
                          fontSize: "14px",
                          fontWeight: "400",
                        },
                        borderRadius: "10px",
                        boxShadow: "none",
                      }}
                      placeholder="Role Name *"
                      label="Role Name *"
                    />
                  </FormControl>
                </Box>

                <Box margin={2}>
                  <CustomSelect
                    label="Role Type *"
                    options={userRoleTypeOptions}
                    value={selectedRoleType}
                    onChange={handleRoleTypeChange}
                    selectStylesOverride={selectStylesOverride}
                  />
                </Box>

                <Box
                  sx={{
                    marginTop: "25px",
                    padding: "2px 10px 2px 10px",
                    display: "flex",
                    justifyContent: "flex-end",
                    gap: "10px",
                  }}
                >
                  <Button
                    data-testid="createBtn"
                    variant="outlined"
                    sx={{
                      ...buttonClasses?.lynkitBlackFill,
                      minHeight: "36px",
                      minWidth: "100px",
                    }}
                    onClick={handleRefresh}
                  >
                    Cancel
                  </Button>

                  <Button
                    data-testid="createBtn"
                    variant="outlined"
                    disabled={disableSubmitButton()}
                    sx={{
                      ...buttonClasses?.lynkitOrangeFill,
                      minHeight: "36px",
                      minWidth: "100px",
                    }}
                    onClick={() => handleSubmit()}
                  >
                    {action === Actions.ADD ? "Save" : "Update"}
                  </Button>
                </Box>
              </Box>
            </Grid>

            {permissionRoles && Object.keys(permissionRoles)?.length > 0 && (
              <Grid
                item
                sm
                paddingRight={2}
                paddingTop={2}
                sx={{ height: "auto", overflow: "auto" }}
              >
                <Typography
                  variant="h6"
                  sx={{
                    paddingLeft: "15px",
                    fontSize: "20px",
                    fontWeight: "600",
                  }}
                >
                  User Role Control
                </Typography>
                <Box marginTop={1} sx={{ height: "55vh", overflow: "auto" }}>
                  <Box margin={1}>
                    <Box>
                      <div style={{ display: "block" }}>
                        <Checkbox
                          data-testid="selectall"
                          onChange={(e) => {
                            allChecked(e);
                          }}
                          checked={areAllChecked}
                          size="small"
                          sx={{
                            "&, &.Mui-checked": {
                              color: themeType.default.themeOrange,
                            },
                            marginBottom: "4px",
                          }}
                        />
                        <span style={{ color: "#000", fontWeight: "900" }}>
                          Select All
                        </span>
                      </div>
                    </Box>
                  </Box>
                  {loader ? (
                    <Grid
                      data-testid="loader"
                      container
                      direction={"column"}
                      justifyContent={"center"}
                      alignItems="center"
                      xs={12}
                      sx={{ p: 15 }}
                    >
                      <Grid item>
                        <CircularProgress />
                      </Grid>
                    </Grid>
                  ) : permissionRoles ? (
                    Object.keys(permissionRoles).map((item, index) => {
                      const permissions = item
                        ? Object.keys(permissionRoles[item])
                        : [];
                      const filteredPermissions = permissions.filter(
                        (permission) =>
                          permission !== "label" &&
                          permissionRoles[item][permission]?.isVisible ===
                            undefined &&
                          (isSuperAdmin ||
                            permissionRoles[item][permission]?.isVisible !==
                              false)
                      );

                      if (filteredPermissions?.length === 0) {
                        return null;
                      } else {
                        return (
                          <div
                            key={index}
                            className="priyanshuCheckBox"
                            style={{ marginTop: "-3px" }}
                          >
                            <div className="priyanshuMainCheck">
                              <Box width={"100%"} pl={"35px"}>
                                <Checkbox
                                  data-testid="categoryCheckbox"
                                  onChange={(e) =>
                                    categoryWiseCheck(e, item, index)
                                  }
                                  checked={
                                    addRoleData.permissions &&
                                    addRoleData.permissions[item]
                                      ? Object.keys(
                                          permissionRoles[item]
                                        ).every((value) => {
                                          return Object.keys(
                                            addRoleData.permissions[item]
                                          ).includes(value)
                                            ? true
                                            : false;
                                        })
                                      : false
                                  }
                                  size="small"
                                  // sx={{ marginBottom: '4px' }}
                                  sx={{
                                    "&, &.Mui-checked": {
                                      color: themeType.default.themeOrange,
                                    },
                                    marginBottom: "4px",
                                  }}
                                />
                                <span className={styles.permissionsHeading}>
                                  {" "}
                                  {permissionRoles[item].label ?? "-"}{" "}
                                </span>
                              </Box>
                            </div>
                            {filteredPermissions?.map((data, ind) => {
                              return (
                                <Box
                                  key={ind}
                                  className="priyanshuTwoContainer"
                                  width={"100%"}
                                  pl={"60px"}
                                >
                                  <Checkbox
                                    data-testid="subcategory"
                                    size="small"
                                    onChange={() => checkboxChange(data, item)}
                                    checked={
                                      addRoleData.permissions &&
                                      addRoleData.permissions[item]
                                        ? addRoleData.permissions[item][data]
                                            ?.value === true
                                          ? true
                                          : false
                                        : false
                                    }
                                    // sx={{ marginBottom: '4px' }}
                                    sx={{
                                      "&, &.Mui-checked": {
                                        color: themeType.default.themeOrange,
                                      },
                                      marginBottom: "4px",
                                    }}
                                  />
                                  <span
                                    data-testid="checkHead"
                                    className="priyanshuCheckText"
                                    style={{ paddingLeft: "5px" }}
                                  >
                                    {permissionRoles[item][data].label ?? "-"}
                                  </span>
                                </Box>
                              );
                            })}
                          </div>
                        );
                      }
                    })
                  ) : (
                    ""
                  )}
                </Box>
              </Grid>
            )}
          </Grid>
        </Card>
        {dialog && (
          <AlertDialog
            viewDialog={dialog}
            sx={{ borderRadius: "50px" }}
            title={`Are you sure ? `}
            subTitle={`You want to update User Role.`}
            body={
              "Once you update the user role, it will automatically affect the existing team member's accessibility on portal."
            }
          >
            <Grid container sx={{ p: 1, mt: 3 }} justifyContent="center">
              <Button
                variant="outlined"
                sx={{
                  ...buttonClasses.lynkitBlackFill,
                  minWidth: "150px",
                }}
                onClick={() => setDialog(false)}
              >
                No, Cancel
              </Button>

              <Button
                variant="contained"
                size="small"
                sx={{
                  ml: 1,
                  ...buttonClasses.small,
                  backgroundColor: themeType.default.themeOrange,
                  minWidth: "150px",
                }}
                onClick={() => handleEdit()}
              >
                Confirm
              </Button>
            </Grid>
          </AlertDialog>
        )}
        <ToastContainer
          position="bottom-center"
          autoClose={false}
          closeOnClick={false}
        />
      </Grid>
    </CustomModel>
  );
}
