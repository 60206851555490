import React from "react";
import { Button, Grid, Typography } from "@mui/material";
import { PlusOneOutlined } from "@mui/icons-material";
import "../../static/css/dashboard2.css";
import NO_DATA_FOUND from "../../static/images/components/no_data_img_new.svg";
import ImgContainer from "../Components/Generic/ImgContainer";

export default function EmptyPage({ title = "", body = " No Data found ", height="230px" }) {
  return (
    <>
      <ImgContainer src={NO_DATA_FOUND} title={title} body={body} height={height} />
    </>
  );
}
