import moment from "moment";
import { convertIntoGivenTimezone } from "../../../../services/functions";

// export const convertIntoGivenTimezone2 = (
//   date,
//   offset = 5.5,
//   timeFormat = "DD/MM/YYYY hh:mm:ss A",
//   z = "",
//   inputFormat = "DD-MM-YYYY hh:mm"
// ) => {
//   if (z) {
//     return moment(date, inputFormat)
//       .utc()
//       .utcOffset(offset * 60)
//       .format(timeFormat);
//   } else {
//     let formatteddate = moment(date, inputFormat)
//       .utc()
//       .utcOffset(offset * 60)
//       .format(timeFormat);
//     if (formatteddate === "Invalid date") {
//       return null;
//     } else {
//       return formatteddate;
//     }
//   }
// };

export const convertIntoGivenTimezone2 = (
  date,
  offset = 5.5,
  timeFormat = "DD/MM/YYYY hh:mm:ss A",
  z = ""
) => {
  // Define possible input formats
  const inputFormats = ["YYYY-MM-DD HH:mm", "YYYY-MM-DD HH:mm:ss"];

  // Try parsing the date with each input format until one succeeds
  let parsedDate = null;
  for (let format of inputFormats) {
    parsedDate = moment(date, format);
    if (parsedDate.isValid()) {
      break;
    }
  }

  if (!parsedDate || !parsedDate.isValid()) {
    return null;  // Return null if the date is invalid
  }

  if (z) {
    return parsedDate
      .utc()
      .utcOffset(offset * 60)
      .format(timeFormat);
  } else {
    let formattedDate = parsedDate
      .utc()
      .utcOffset(offset * 60)
      .format(timeFormat);
    return formattedDate === "Invalid date" ? null : formattedDate;
  }
};

const getTargetCurrentTime = (details,currentUserDetail) => {
  if(details?.tripData?.etaType){
    if(!isNaN(details?.tripData?.etaType)){
      const targetETA = convertIntoGivenTimezone(details?.tripData?.route?.viaPoints[Number(details?.tripData?.etaType)]?.targetETA, currentUserDetail?.timeZone?.offset) || "-";
      let currentETA = '';

      const currentViapoint = details?.tripData?.route?.viaPoints[Number(details?.tripData?.etaType)];
      if(details?.tripData?.viaData?.length){
        const viaWithSameName = details?.tripData?.viaData?.filter(_ => _?.name === currentViapoint?.itemName && _?.arrivalTime);
        if(viaWithSameName?.length){
          currentETA = convertIntoGivenTimezone(viaWithSameName[0]?.arrivalTime, currentUserDetail?.timeZone?.offset) || "-";
        }else{
          currentETA = convertIntoGivenTimezone(details?.tripData?.route?.viaPoints[Number(details?.tripData?.etaType)]?.currentETA, currentUserDetail?.timeZone?.offset) || "-";
        }
      }else{
        currentETA = convertIntoGivenTimezone(details?.tripData?.route?.viaPoints[Number(details?.tripData?.etaType)]?.currentETA, currentUserDetail?.timeZone?.offset) || "-";
      }

      return {targetETA, currentETA};
    }else if(details?.tripData?.etaType === "destination"){
      const tripNewStatus = details?.newStatus || details?.status;
      const destinationObj = details?.tripData?.route?.routeDestination;
      const targetETA = convertIntoGivenTimezone(destinationObj?.targetETA, currentUserDetail?.timeZone?.offset) || '-';
      const currentETA = convertIntoGivenTimezone(tripNewStatus === "reached" ? details?.tripData?.arrivalTime : destinationObj?.currentETA, currentUserDetail?.timeZone?.offset)

      return {targetETA, currentETA};
    }else if(details?.tripData?.etaType === "awaiting"){
      return {targetETA: "Awaiting", currentETA: "Awaiting"};
    }else{
      return {targetETA: "-", currentETA: "-"};
    }
  }
}

export const getETAValue = (details, currentUserDetail) => {
  if (details) {
    let targetETA = "";
    let currentETA = "";
    let tripStatus = details?.newStatus || details?.status;

    if (details?.tripData?.route?.scheduleId) {
      switch (tripStatus) {
        case "mapped":
          targetETA =
            convertIntoGivenTimezone(
              details?.tripData?.route?.routeSource?.STDGmt,
              currentUserDetail?.timeZone?.offset
            ) || "-";
          currentETA = "Awaiting Departure";
          break;
        case "in_transit":
          targetETA = details?.tripData?.etaType
            ? getTargetCurrentTime(details, currentUserDetail)?.targetETA
            : "-";
          currentETA = details?.tripData?.etaType
            ? getTargetCurrentTime(details, currentUserDetail)?.currentETA
            : "-";
          break;
        default:
          targetETA = "-";
          currentETA = "-";
      }

      return { targetETA, currentETA };
    } else {
      switch (tripStatus) {
        case "mapped":
          targetETA = "Inside Source";
          currentETA = "Inside Source";
          break;
        case "in_transit":
          targetETA = details?.tripData?.etaType
            ? getTargetCurrentTime(details, currentUserDetail)?.targetETA
            : "-";
          currentETA = details?.tripData?.etaType
            ? getTargetCurrentTime(details, currentUserDetail)?.currentETA
            : "-";
          break;
        case "via":
          targetETA = "Inside Viapoint";
          currentETA = "Inside Viapoint";
          break;
        case "reached":
          targetETA = "Inside Destination";
          currentETA = "Inside Destination";
          break;
        default:
          targetETA = "-";
          currentETA = "-";
      }

      return { targetETA, currentETA };
    }
  }
};