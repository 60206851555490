import React from "react";
import CustomModel from "../../../Components/CustomModels/CustomModel1";
import { Box, Button, CircularProgress, Grid, Typography } from "@mui/material";
import { CustomInput } from "../../../../components/FormUI";
import { Controller, useForm } from "react-hook-form";
import { sendCustomEventAnalytics } from "../../../../utils/util";

const FixedLockPasscodeModal = ({ 
  open,
  setOpen,
  themeType,
  selectStylesOverride,
  handleVerifyFn,
  passcodeValue,
  setPasscodeValue,
  loading = false,
}) => {
  const { inputClasses, buttonClasses, textClasses } = themeType;
  const {
    handleSubmit,
    control,
    reset,
    setValue,
    formState: { errors },
  } = useForm();

  const hide = () => {
    setOpen(false);
    setPasscodeValue(null);
    setValue("randomNo", undefined);
  };

  const onSubmit = (data) => {
    sendCustomEventAnalytics("fixed-lock", "generate-passcode", "generate-passcode");
    if (data) {
      handleVerifyFn(data, () => {
        setValue("randomNo", undefined);
      });
    }
  };

  return (
    <CustomModel
      autoClose={false}
      show={open || false}
      onClose={() => hide()}
      minWidth={500}
      maxWidth={500}
      pb={3}
      pl={3}
      pr={3}
      maxHeight={"81vh"}
      childrenMaxHeight={"81vh"}
      Title={"Enter number displayed on lock"}
      viewCloseIcon={open}
    >
      <Grid container spacing={2} py={1} mb={2}>
        <Grid item xs={12} justifyContent="center" alignItems="center">
          <Typography sx={{ ...textClasses?.normalText, textAlign: "center" }}>
            Please enter 6 digit number which is displayed on lock screen by
            pressing star (*) button.
          </Typography>
        </Grid>
        <Grid item container xs={12} rowGap={2}>
          <Grid item xs={12}>
            <Controller
              name="randomNo"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: "Number is required",
                },
                pattern: {
                  value: /^\d+$/,
                  message: "Enter a valid number",
                },
                maxLength: {
                  value: 6,
                  message: "Number should be 6 digits long",
                },
                minLength: {
                  value: 6,
                  message: "Number should be 6 digits long",
                },
              }}
              render={({ field: { onChange, value, name } }) => {
                return (
                  <CustomInput
                    label="Number"
                    name={name}
                    errors={errors}
                    placeholder="Enter Number here"
                    selectStylesOverride={selectStylesOverride}
                    inputClasses={inputClasses}
                    value={value}
                    onChange={(e) => {
                      onChange(e);
                    }}
                  />
                );
              }}
            />
          </Grid>
          {passcodeValue && (
            <Grid item container xs={12} rowGap={2}>
              <Grid
                item
                xs={12}
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <Box
                  p={0.5}
                  px={1}
                  sx={{
                    borderRadius: "25px",
                    border: `1px solid ${themeType?.default?.themeOrange}`,
                    background: "transparent",
                    color: themeType?.default?.themeOrange,
                    fontSize: "17px",
                    fontWeight: 700,
                  }}
                >
                  {passcodeValue}
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  sx={{ ...textClasses?.normalText, textAlign: "center" }}
                >
                  Please enter above 6 digit passcode on lock & after entering
                  the passcode please press # button for unlocking.
                </Typography>
              </Grid>
            </Grid>
          )}
          <Grid
            item
            xs={12}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Button
              variant="contained"
              sx={{ ...buttonClasses?.lynkitOrangeFill }}
              onClick={handleSubmit(onSubmit)}
            >
              {loading && (
                <CircularProgress
                  size={20}
                  sx={{
                    color: "#383838",
                  }}
                />
              )}
              {loading
                ? "Loading..."
                : passcodeValue
                ? "Re-generate Passcode"
                : "Generate Passcode"}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </CustomModel>
  );
};

export default FixedLockPasscodeModal;
