import {
  Button,
  Typography,
  IconButton,
  Grid,
  Box,
  FormControlLabel,
  Radio,
  RadioGroup,
  Card,
  CardHeader,
  CardContent,
  TextField,
  TextareaAutosize,
} from "@mui/material";
import CustomModel from "../../../Components/CustomModels/CustomModel1";
import { useCallback, useEffect, useRef, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { fontFamily } from "@mui/system";
import {
  Check,
  Close,
  Create,
  FilterAlt,
  KeyboardArrowDown,
  DeleteOutline,
  Delete,
  Search,
} from "@mui/icons-material";
import { showToast } from "../../../../services/functions";

const EditTemplateModal = ({
  dataToSend,
  dynamicKeys,
  getDynamicKeys,
  getOrganisationImages,
  handleClearUploadedImages,
  hide,
  onUpdateAlert,
  onUploadImage,
  open,
  organisationImages,
  selectedAlert,
  selectedTabName,
  selectStylesOverride,
  setDataToSend,
  setShowModal,
  themeType,
  selectedImage,
  setSelectedImage,
}) => {
  const {
    theme,
    buttonClasses,
    cardClasses,
    inputClasses,
    textClasses,
    tableClasses,
  } = themeType;

  const mediaQuery = window.matchMedia("(max-width: 1400px)");
  const fileInputRef = useRef(null);

  useEffect(() => {
    getDynamicKeys();
  }, []);

  const [subject, setSubject] = useState("");
  const [copiedText, setCopiedText] = useState("");
  const [cursorPosition, setCursorPosition] = useState(null);
  const textAreaRef = useRef(null);

  useEffect(() => {
    setCopiedText(selectedAlert?.message);
  }, []);

  useEffect(() => {
    setSubject(selectedAlert?.subject);
  }, []);

  useEffect(() => {
    const waitForEditor = async () => {
      while (!textAreaRef.current || !textAreaRef.current.editor) {
        await new Promise((resolve) => setTimeout(resolve, 100));
      }
      if (textAreaRef.current) {
        const editor = textAreaRef.current.editor;
        editor.ui.focusTracker.on("change", () => {
          if (!editor.editing.view.document.hasFocus) {
            const selection = editor.model.document.selection;
            setCursorPosition(selection.getFirstRange());
          }
        });
      }
    };
    waitForEditor();
  }, [textAreaRef]);

  const handleButtonClick = (buttonKey) => {
    if (textAreaRef?.current) {
      const position = cursorPosition?.end;
      textAreaRef.current.editor.model.change((writer) => {
        position && writer.insertText(`{{${buttonKey}}}`, position);
      });
    }
  };

  const handleUpdateAlertMessage = useCallback(
    (value, key) => {
      // console.log('v---',value);
      const temp = dataToSend;
      temp[key]["message"] = value;
      setDataToSend(temp);
    },
    [dataToSend]
  );

  const handleUpdateAlertSubject = useCallback(
    (value, key) => {
      const temp = dataToSend;
      temp[key]["subject"] = value;
      setDataToSend(temp);
    },
    [dataToSend]
  );

  const config = {
    language: "en", // fa - for persian language ( rtl )
    toolbar: ["bold", "italic", "underline", "textColor", "|", "undo", "redo"],
    textColor: {
      colors: ["rgb(0, 0, 0)", "rgb(255, 0, 0)", "rgb(0, 0, 255)"], // Add your desired colors
    },
    table: {
      // Define the styles for tables and table elements
      styles: {
        // Style for the table element itself
        'table': 'border-collapse: collapse; width: 100%;',
        // Style for table row (tr)
        'tr': 'border-bottom: 1px solid #ccc;',
        // Style for table header (th)
        'th': 'border: 1px solid #000; padding: 5px;',
        // Style for table data/cell (td)
        'td': 'border: 1px solid #000; padding: 5px;',
      }
    },
    dataProcessor: {
      // Define a dataFilter to modify the output HTML
      dataFilter: function (element) {
        if (element.name === 'table') {
          // Add inline styles to the table element
          element.attributes.style = 'border-collapse: collapse; width: 100%;';
        }
        // You can add more conditions for tr, th, td, etc., if needed
  
        return element;
      }
    }
  };

  const handleFileChange = useCallback(
    (e) => {
      const file = e.target.files[0];
      if (file) {
        const reader = new FileReader();

        reader.readAsDataURL(file);

        reader.onload = () => {
          onUploadImage([reader.result]);
        };
      }
    },
    [onUploadImage]
  );

  const onAddImageInPayloadData = (data) => {
    const temp = dataToSend;
    if (data?.length > 0) {
      data?.map((_) => {
        if (_?.isDefault && _?.isDefault == true) {
          temp[selectedAlert?.key]["lynktracLogo"] = _?.path;
        } else {
          temp[selectedAlert?.key]["userLogo"] = _?.path;
        }
      });
    }
  };

  const handleSelectImage = (image) => {
    //---- Before pushing in array, need to check if any image is already there. If yes, then it should be "isDefault: true", else dont allow 2 "other" images
    let temp = [];
    if (selectedImage?.length > 0) {
      if (selectedImage[0]?.isDefault && selectedImage[0]?.isDefault == true) {
        temp = [...selectedImage];
        temp.push(image);
        setSelectedImage(temp);
      } else if (!selectedImage[0]?.isDefault && !image?.isDefault) {
        showToast("!!!!", true);
      } else if (
        !selectedImage[0]?.isDefault &&
        image?.isDefault &&
        image?.isDefault === true
      ) {
        temp = [...selectedImage];
        temp.push(image);
        setSelectedImage(temp);
      }
    } else {
      temp = [];
      temp.push(image);
      setSelectedImage(temp);
    }
    onAddImageInPayloadData(temp);
    //handleClearUploadedImages();
  };

  const handleRemoveImage = (image) => {
    let tempData = dataToSend;
    let temp = selectedImage?.filter((_) => _?.path !== image?.path);
    setSelectedImage(temp);
    if (tempData[selectedAlert?.key]["lynktracLogo"] == image?.path)
      tempData[selectedAlert?.key]["lynktracLogo"] = "";
    if (tempData[selectedAlert?.key]["userLogo"] == image?.path)
      tempData[selectedAlert?.key]["userLogo"] = "";
    setDataToSend(tempData);
  };


    
      const addInlineCSSToTable = (htmlString) => {
        const tableStyles = 'border-collapse: collapse; width: 100%; text-align: center;'; // Example table style
        const tdStyles = 'width: 50%; text-align: center; border: 1px solid #dddddd; padding: 5px;'; // Example td style
    
        const tableRegex = /<table(.*?)>/g;
        const tdRegex = /<td(.*?)>/g;
    
        const modifiedTableHTML = htmlString.replace(tableRegex, `<table$1 style="${tableStyles}">`);
    
        const modifiedHTML = modifiedTableHTML.replace(tdRegex, `<td$1 style="${tdStyles}">`);
    
        return modifiedHTML;
  };



  return (
    <>
      <CustomModel
        autoClose={false}
        show={open || false}
        onClose={() => {
          setSubject("");
          setCopiedText("");
          setSelectedImage([]);
          handleClearUploadedImages();
          hide();
        }}
        minWidth={880}
        maxWidth={880}
        pb={3}
        pl={3}
        pr={3}
        maxHeight={"100vh"}
        childrenMaxHeight={"100vh"}
        Title={"Edit Template"}
        viewCloseIcon={open}
      >
        <Grid container mb={2} mt={2}>
          <Grid
            container
            mb={2}
            item
            md={12}
            spacing={2}
            mt={0}
            sx={{
              maxHeight: mediaQuery?.matches ? "500px" : undefined,
              overflowY: mediaQuery?.matches ? "scroll" : undefined,
            }}
          >
            <Grid
              md={8}
              container
              item
              height={"max-content"}
              rowGap={"16px"}
              maxHeight={"500px"}
              sx={{ overflowY: "scroll" }}
            >
              {selectedTabName === "templates" && (
                <Grid
                  item
                  md={12}
                  border={"1px solid #D9D9D9"}
                  display={"flex"}
                  flexDirection={"row"}
                  gap={2}
                  height={"100px"}
                  alignItems={"center"}
                  pl={2}
                >
                  {selectedImage && selectedImage?.length > 0 && (
                    <>
                      {selectedImage?.map((image, i) => {
                        return (
                          <div style={{ position: "relative" }} key={i}>
                            <IconButton
                              aria-label="close"
                              onClick={() => handleRemoveImage(image)}
                              sx={{
                                position: "absolute",
                                right: -8,
                                top: -8,
                                padding: 0,
                                color: "#FF7200",
                                background: "#ffffff8f",
                              }}
                            >
                              <Close fontSize="small" />
                            </IconButton>
                            <img
                              src={image?.base64}
                              style={{
                                width: "80px",
                                height: "80px",
                                objectFit: "contain",
                              }}
                            />
                          </div>
                        );
                      })}
                    </>
                  )}

                  <Button
                    variant="outlined"
                    size="small"
                    disabled={selectedImage && selectedImage?.length >= 2}
                    sx={{
                      // ...buttonClasses.small,
                      minHeight: "80px",
                      minWidth: "100px",
                      color: buttonClasses.lynkitOrangeEmpty,
                      border: "3px dotted #FF7200",
                      "&:hover": {
                        border: "3px dotted #FF7200",
                      },
                    }}
                    onClick={getOrganisationImages}
                  >
                    Select Logo
                  </Button>
                  <label class="label">
                    <input
                      type="file"
                      required
                      ref={fileInputRef}
                      onChange={handleFileChange}
                      style={{ display: "none" }}
                    />
                    <Button
                      variant="outlined"
                      size="small"
                      sx={{
                        minHeight: "80px",
                        minWidth: "100px",
                        color: buttonClasses.lynkitOrangeEmpty,
                        border: "3px dotted #FF7200",
                        "&:hover": {
                          border: "3px dotted #FF7200",
                        },
                      }}
                      onClick={() => {
                        fileInputRef.current.click();
                      }}
                    >
                      Upload Logo
                    </Button>
                  </label>
                </Grid>
              )}
              {selectedTabName === "templates" &&
                organisationImages.length > 0 &&
                selectedImage &&
                selectedImage?.length < 2 && (
                  <Grid
                    item
                    md={12}
                    height={"100px"}
                    display={"flex"}
                    flexDirection={"row"}
                    gap={"16px"}
                    border={"1px solid #D9D9D9"}
                    overflow={"scroll"}
                    alignItems={"center"}
                    pl={2}
                  >
                    {organisationImages?.map((image, index) => {
                      // disable selected images from this list.
                      return (
                        <div
                          key={index}
                          onClick={
                            selectedImage?.find((_) => _?.path === image?.path)
                              ? null
                              : () => handleSelectImage(image)
                          }
                          style={{
                            cursor: selectedImage?.find(
                              (_) => _?.path === image?.path
                            )
                              ? "not-allowed"
                              : "pointer",
                            opacity: selectedImage?.find(
                              (_) => _?.path === image?.path
                            )
                              ? "0.5"
                              : 1,
                          }}
                        >
                          <img
                            src={image?.base64}
                            style={{
                              width: "80px",
                              height: "80px",
                              objectFit: "contain",
                            }}
                          />
                        </div>
                      );
                    })}
                  </Grid>
                )}
              <Grid
                item
                md={12}
                sx={{
                  paddingLeft: "0px !important",
                  paddingTop: "0px !important",
                }}
              >
                <TextField
                  id="outlined-textarea"
                  sx={{
                    ...inputClasses?.textfield,

                    ...selectStylesOverride,
                    width: "100%",
                  }}
                  label="Subject"
                  multiline
                  variant="outlined"
                  onChange={(e) => {
                    setSubject(e.target.value);
                    handleUpdateAlertSubject(
                      e.target.value,
                      selectedAlert?.key
                    );
                  }}
                  value={subject || ""}
                  defaultValue={selectedAlert?.subject}
                  inputProps={{
                    style: {
                      resize: "both",
                    },
                  }}
                />
              </Grid>
              <Grid item md={12}>
                <CKEditor
                  editor={ClassicEditor}
                  ref={textAreaRef}
                  config={{ config }}
                  data={copiedText}
                  onReady={(editor) => {
                  }}
                  onChange={(event, editor) => {
                    let data = editor.getData();
                    const modifiedData = addInlineCSSToTable(data);
                    setCopiedText(prevText => data); 
                    handleUpdateAlertMessage(modifiedData, selectedAlert?.key);
                  }}
                  onBlur={(event, editor) => {
                  }}
                  onFocus={(event, editor) => {
                  }}
                />
              </Grid>
            </Grid>
            <Grid md={4} item>
              <Card>
                <CardHeader
                  sx={{
                    height: "46px",
                    backgroundColor: themeType.default.themeOrange,
                  }}
                  title={
                    <Typography
                      sx={{
                        ...textClasses.boldText,
                        color: "white",
                        fontSize: "16px",
                      }}
                    >
                      Data Keys
                    </Typography>
                  }
                />
                <CardContent
                  sx={{
                    height: "400px",
                    overflowY: "scroll",
                    display: "flex",
                    flexDirection: "column",
                    gap: "1rem",
                  }}
                >
                  {dynamicKeys?.map((key, index) => {
                    return (
                      <Button
                        variant="outlined"
                        size="small"
                        sx={{
                          ...buttonClasses.small,
                          minHeight: "36px",
                          borderColor: themeType.default.themeOrange,
                          color: buttonClasses.lynkitOrangeEmpty,
                        }}
                        onClick={() => handleButtonClick(key?.key)}
                      >
                        {key?.label}
                      </Button>
                    );
                  })}
                </CardContent>
              </Card>
            </Grid>
          </Grid>
          <Grid
            item
            display="flex"
            justifyContent="center"
            gap={2}
            alignItems="center"
            sx={{ width: "100%", paddingTop: "0 !important" }}
          >
            <Button
              variant="outlined"
              sx={{
                ...buttonClasses?.lynkitBlackFill,
                minHeight: "36px",
                minWidth: "140px",
              }}
              onClick={() => setShowModal(false)}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              sx={{
                ...buttonClasses?.lynkitOrangeFill,
                minHeight: "36px",
                minWidth: "140px",
              }}
              onClick={() => onUpdateAlert(dataToSend)}
            >
              Update
            </Button>
          </Grid>
        </Grid>
      </CustomModel>
    </>
  );
};

export default EditTemplateModal;
