import { Download, Delete, Visibility } from "@mui/icons-material";

import { Box, Button, FormControl, Grid, Paper } from "@mui/material";
import { Controller, useForm, useWatch } from "react-hook-form";
import CustomSelect from "../../../components/FormUI/CustomSelect";
import { ExcelRenderer, OutTable } from "react-excel-renderer";
import Download_Sample from "../../../static/images/DownloadSample.svg";
import ImgContainer from "../../Components/Generic/ImgContainer";

const ExcelForm = ({
  superAdmin,
  handleSubmit,
  onHandleCreateGeofenceInBulk,
  control,
  handleDownloadFile,
  handleCancel,
  showTablePreview,
  setShowTablePreview,
  getValues,
  setValue,
  errors,
  selectedFile,
  setSelectedFile,
  usersListing,
  OrganisationListing,
  themeType,
  selectStylesOverride,
  headers,
  setHeaders,
  rowData,
  setRowData,
}) => {
  const {
    buttonClasses,
    inputClasses,
    textClasses,
    dividerClass,
    tableClasses,
  } = themeType;

  const handleUpload = (fileData) => {
    const file = fileData;

    if (file) {
      ExcelRenderer(file, (err, resp) => {
        if (err) {
          console.log(err);
        } else {
          setHeaders(resp.rows[0]);
          setRowData(resp.rows?.slice(1));
        }
      });
    }
  };

  const handleDeleteFile = () => {
    setSelectedFile(null);
    setHeaders([]);
    setRowData([]);
    showTablePreview && setShowTablePreview(false);
  };

  const handleSaveDetails = (data) => {
    const selectedOrganisation = getValues("organization");
    const selectedUser = getValues("user");
    let user;
    if (
      Object.keys(superAdmin).length &&
      selectedOrganisation &&
      selectedUser
    ) {
      user = {
        id: selectedUser?._id,
        name: selectedUser?.name,
        orgId: selectedOrganisation?._id,
        orgName: selectedOrganisation?.name,
      };
    }

    let tableInfo = [];
    if (rowData?.length > 0 && headers?.length > 0) {
      tableInfo = rowData.map((row) => {
        const rowTemp = {};
        headers.forEach((header, index) => {
          rowTemp[header] = row[index] ? row[index].toString() : null;
        });
        return rowTemp;
      });
    }
    const payload = {
      excelData: tableInfo,
    };

    if (Object.keys(superAdmin).length) {
      payload["user"] = user;
    }
    onHandleCreateGeofenceInBulk(payload);
  };

  return (
    <Box
      width="100%"
      // sx={{ minHeight: "550px", height: "calc(100vh - 200px)" }}
    >
      <Grid
        item
        container
        xs={12}
        p={2}
        // my={2}
        borderRadius="10px"
        border="1px solid #E0E0E0"
        rowSpacing={2}
        height="100%"
      >
        <Grid container item xs={12}>
          <Grid item xs={12} md={8} spacing={2}>
            {Object.keys(superAdmin).length ? (
              <Grid item xs={12} mb={2}>
                <Box maxWidth="250px">
                  <Controller
                    name="organization"
                    control={control}
                    rules={{
                      required: {
                        value: true,
                        message: "Organisation is required.",
                      },
                    }}
                    render={({ field: { onChange, value, name } }) => {
                      return (
                        <CustomSelect
                          //disabled={true}
                          selectStylesOverride={selectStylesOverride}
                          label="Select Organization"
                          value={value || null}
                          onChange={(e, newVal) => {
                            onChange(newVal);
                          }}
                          name={name}
                          errors={errors}
                          getOptionLabel={(option) => option?.name}
                          renderOption={(props, option) => (
                            <li {...props} style={{ fontSize: "13px" }}>
                              {option.name}
                            </li>
                          )}
                          labelKey="name"
                          options={OrganisationListing && OrganisationListing}
                        />
                      );
                    }}
                  />
                </Box>
              </Grid>
            ) : null}

            <Grid item xs={12} md>
              <Grid item display="flex" alignItems="center" gap={2}>
                <FormControl
                  spacing={1}
                  sx={{ marginRight: "5px", marginTop: "2px" }}
                >
                  <input
                    type="file"
                    name="upload"
                    id="upload"
                    className="RF_fileupload-input"
                    onChange={(e) => {
                      console.log(e.target.files, "check e");
                      setSelectedFile(e?.target?.files[0]);
                      handleUpload(e?.target?.files[0]);
                    }}
                    //ref={fileInputRef}
                    //key={shellState}
                  />
                </FormControl>
                <Button
                  variant="outlined"
                  spacing={0}
                  className="RF_outline-btn"
                  sx={{ marginRight: "5px", borderRadius: "8px" }}
                  disabled={selectedFile == null}
                  onClick={handleDeleteFile}
                >
                  <Delete sx={{ color: themeType.default.themeRed }} />
                </Button>
                <Button
                  variant="outlined"
                  sx={{
                    ...buttonClasses?.lynkitOrangeFill,
                    minHeight: "36px",
                    minWidth: "140px",
                    marginLeft: "10px",
                  }}
                  startIcon={<Visibility />}
                  disabled={selectedFile === null}
                  onClick={() => {
                    setShowTablePreview(true);
                  }}
                >
                  Preview
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs md display="flex" justifyContent="flex-end">
            <Button
              variant="outlined"
              sx={{
                ...buttonClasses?.lynkitBlackFill,
                height: "36px",
                minWidth: "140px",
                marginLeft: "auto",
              }}
              startIcon={<Download />}
              onClick={handleDownloadFile}
              // disabled={!deviceModelInfo || deviceModelInfo?.length === 0}
            >
              Sample Download
            </Button>
          </Grid>
        </Grid>
        {/* preview table area ----- */}
        {!showTablePreview ? (
          <ImgContainer
            src={Download_Sample}
            title={"Download the sample file first... "}
            body={
              " Hey, please download the sample file and add your geofence data in same format, to bulk upload the geofence master."
            }
          />
        ) : (
          headers?.length > 0 &&
          rowData?.length > 0 && (
            <Grid
              xs={12}
              my={3}
              sx={{
                maxHeight: 200,
                overflowY: "scroll",
              }}
            >
              <Paper
                className="muitable"
                sx={{
                  maxWidth: "100%",
                  overflowX: "auto",
                  background: tableClasses.dark.backgroundColor,
                }}
              >
                <table style={{ width: "100%", ...tableClasses.table }}>
                  <thead
                    style={{ backgroundColor: themeType?.default?.themeGray }}
                  >
                    <tr>
                      {headers?.map((header, index) => (
                        <th
                          key={index}
                          style={{
                            ...textClasses.boldText,
                            paddingLeft: "6px",
                            borderRight: "1px solid #0000002B",
                          }}
                        >
                          {header}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {rowData?.map((row, rowIndex) => (
                      <tr
                        key={rowIndex}
                        style={{ borderBottom: "1px solid #0000002B" }}
                      >
                        {row.map((cell, cellIndex) => (
                          <td
                            key={cellIndex}
                            style={{
                              padding: "8px 8px",
                              borderRight: "1px solid #0000002B",
                              ...textClasses.normalText,
                            }}
                          >
                            {cell === null ? " " : cell?.toString()}
                          </td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </Paper>
            </Grid>
          )
        )}

        <Grid
          item
          xs={12}
          display="flex"
          justifyContent="center"
          alignItems="center"
          gap="7px"
        >
          <Button
            variant="outlined"
            sx={{
              ...buttonClasses?.lynkitBlackFill,
              minHeight: "36px",
              minWidth: "140px",
            }}
            onClick={handleCancel}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            sx={{
              ...buttonClasses?.lynkitOrangeFill,
              minHeight: "36px",
              minWidth: "140px",
            }}
            disabled={!selectedFile}
            onClick={handleSubmit(handleSaveDetails)}
          >
            Upload
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ExcelForm;
