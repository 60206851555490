import {
  Typography,
  TextField,
  FormControl,
  Box,
  FormLabel,
  Grid,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Checkbox,
  RadioGroup,
  Radio,
  FormGroup,
} from "@mui/material";
import theme, { inputClasses, textClasses } from "../../../static/styles/theme";
import { Controller } from "react-hook-form";
import no_data_found from "../../../static/images/no_data_found.svg";
import { selectStylesOverride } from "../../../utils/util";
import React, { useEffect, useState } from "react";
import axios from "axios";
import config from "../../../services/config";
import { useDispatch, useSelector } from "react-redux";

export const getInputField = (component) => {
  switch (component.type) {
    case "input":
      return (
        <TextField
          disabled={true}
          required={component.required}
          size="small"
          fullWidth
          label={component.label}
          placeholder={component.placeholder}
          sx={{ ...inputClasses.shadowField }}
          InputLabelProps={{ sx: { fontSize: "13px", paddingTop: "2px" } }}
        />
      );
    case "number":
      return (
        <TextField
          disabled={true}
          required={component.required}
          type="number"
          size="small"
          fullWidth
          label={component.label}
          placeholder={component.placeholder}
          sx={{ ...inputClasses.shadowField }}
          InputLabelProps={{ sx: { fontSize: "13px", paddingTop: "2px" } }}
        />
      );
    case "date":
      return (
        <TextField
          disabled={true}
          required={component.required}
          InputLabelProps={{ shrink: true }}
          type="date"
          size="small"
          fullWidth
          label={component.label}
          sx={{ ...inputClasses.shadowField }}
        />
      );
    case "textarea":
      return (
        <TextField
          disabled={true}
          required={component.required}
          multiline
          rows={3}
          size="small"
          fullWidth
          label={component.label}
          placeholder={component.placeholder}
          sx={{ ...inputClasses.shadowField }}
          InputLabelProps={{ sx: { fontSize: "13px", paddingTop: "2px" } }}
        />
      );
    case "searchTags":
      return (
        <TextField
          disabled={true}
          required={component.required}
          size="small"
          fullWidth
          label={component.label}
          placeholder={component.placeholder}
          sx={{ ...inputClasses.shadowField }}
          InputLabelProps={{ sx: { fontSize: "13px", paddingTop: "2px" } }}
        />
      );
    case "file":
      return (
        <TextField
          disabled={true}
          required={component.required}
          type={"file"}
          size="small"
          focused
          fullWidth
          label={component.label}
          sx={{ ...inputClasses.shadowField }}
        />
      );
    case "select":
      return (
        <FormControl fullWidth size="small" required={component.required}>
          <InputLabel sx={{ color: "#909090", fontWeight: "100" }}>
            {component.label}
          </InputLabel>
          <Select
            sx={{ ...inputClasses.shadowField }}
            label={component.label}
            disabled={true}
          >
            {component.values.map((op, i) => {
              return (
                <MenuItem value={op.value} key={i}>
                  {op.label}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      );
    case "checkbox":
      return (
        <div
          style={{
            ...inputClasses.shadowField,
            border: "1px solid #D9D9D9",
            width: "100%",
            padding: "10px",
          }}
        >
          <FormLabel component="legend">{component.label}</FormLabel>
          {component.values.map((op, i) => {
            return (
              <FormControlLabel
                disabled={true}
                control={<Checkbox value={op.value} key={i} size="small" />}
                label={
                  <Typography sx={{ fontSize: "13px" }}>{op.label}</Typography>
                }
              />
            );
          })}
        </div>
      );
    case "radio":
      return (
        <div
          style={{
            ...inputClasses.shadowField,
            border: "1px solid #D9D9D9",
            width: "100%",
            padding: "10px",
          }}
        >
          <FormControl>
            <FormLabel id="demo-row-radio-buttons-group-label">
              {component.label}
            </FormLabel>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              sx={{ mt: 1 }}
              disabled={true}
            >
              {component.values.map((op, i) => {
                return (
                  <FormControlLabel
                    control={<Radio value={op.value} key={i} size="small" />}
                    label={op.label}
                    key={i}
                  />
                );
              })}
            </RadioGroup>
          </FormControl>
        </div>
      );
    default:
      <TextField
        required={component.required}
        size="small"
        fullWidth
        label={component.label}
        placeholder={component.placeholder}
        sx={{ ...inputClasses.shadowField }}
      />;
  }
};

export const getFieldsLayout = (fields) => {
  let layout = {
    single: [],
    multi: [],
  };
  for (let i = 0; i < fields.length; i++) {
    if (
      ["input", "number", "date", "searchTags", "file", "select"].indexOf(
        fields[i].type
      ) >= 0
    ) {
      layout.single.push(getInputField(fields[i]));
    } else {
      layout.multi.push(getInputField(fields[i]));
    }
  }
  return layout;
};

export const getTemplateFieldsTripSchedule = (
  component,
  items,
  handleTemplateChange,
  width,
  isDarkThemeEnabledSelector,
  additionalData
) => {
  let index = 0;
  switch (component.type) {
    case "input":
    case "number":
    case "textarea":
    case "date":
      return (
        <>
          <FormControl
            variant="outlined"
            size="small"
            fullWidth
            sx={{
              ...selectStylesOverride(isDarkThemeEnabledSelector),
              // width: width,
            }}
          >
            <CustomInput
              // style={{ width: width }}
              component={component}
              disabled={
                additionalData
                  ? additionalData?.status === "completed"
                    ? true
                    : false
                  : ""
              }
              field={{}}
              value={items && items[index] && items[index][component._id] && items[index][component._id] ? items[index][component._id] : ""}
              onChange={(e) => handleTemplateChange(e, component._id)}
            />
          </FormControl>
        </>
      );
    case "select":
      return (
        <>
          <FormControl
            variant="outlined"
            size="small"
            fullWidth
            sx={{
              ...selectStylesOverride(isDarkThemeEnabledSelector),
              // width: width,
            }}
          >
            <CustomInput
              // style={{ width: width }}
              component={component}
              disabled={
                additionalData
                  ? additionalData?.status === "completed"
                    ? true
                    : false
                  : ""
              }
              field={{}}
              value={items && items[index] && items[index][component._id] && items[index][component._id] ? items[index][component._id] : ""}
              onChange={(e) => handleTemplateChange(e, component._id)}
            />
          </FormControl>
        </>
      );
    case "checkbox":
      return (
        <>
          <FormControl
            variant="outlined"
            size="small"
            fullWidth
            sx={{
              ...selectStylesOverride(isDarkThemeEnabledSelector),
              marginBottom: "8px",
              border: "1px solid rgb(217, 217, 217)",
              borderRadius: "12px",
              padding: "10px",
            }}
          >
            <FormLabel>{component.label}</FormLabel>

            <FormGroup row sx={{ mt: 1 }}>
              {component.values &&
                component.values.map((op, i) => {
                  return (
                    <FormControlLabel
                      key={i}
                      label={
                        <Typography sx={{ fontSize: "13px" }}>
                          {op.label}
                        </Typography>
                      }
                      control={
                        <Checkbox
                          disabled={
                            additionalData
                              ? additionalData?.status === "completed"
                                ? true
                                : false
                              : ""
                          }
                          checked={
                            items &&
                            items[index] &&
                            items[index][component._id] &&
                            items[index][component._id]
                              ? items[index][component._id].includes(op.value)
                              : ""
                          }
                          onChange={(e) =>
                            handleTemplateChange(
                              { checked: e.target.checked, value: op.value },
                              component._id
                            )
                          }
                          size="small"
                          sx={{
                            "&, &.Mui-checked": { color: theme.themeOrange },
                          }}
                        />
                      }
                    />
                  );
                })}
            </FormGroup>
          </FormControl>
        </>
      );
    case "radio":
      return (
        <>
          <FormControl
            variant="outlined"
            size="small"
            fullWidth
            sx={{
              ...selectStylesOverride(isDarkThemeEnabledSelector),
              marginBottom: "8px",
              border: "1px solid rgb(217, 217, 217)",
              borderRadius: "12px",
              padding: "10px",
            }}
          >
            <FormLabel>{component.label}</FormLabel>
            <RadioGroup
              row
              disabled={
                additionalData
                  ? additionalData?.status === "completed"
                    ? true
                    : false
                  : ""
              }
              value={
                items &&
                items[index] &&
                items[index][component._id] &&
                items[index][component._id]
                  ? items[index][component._id]
                  : ""
              }
              onChange={(e) => handleTemplateChange(e, component._id)}
              // sx={{ width: width }}
            >
              {component.values &&
                component.values.map((op, i) => {
                  return (
                    <FormControlLabel
                      // style={{ width: width }}
                      disabled={
                        additionalData
                          ? additionalData?.status === "completed"
                            ? true
                            : false
                          : ""
                      }
                      key={i}
                      label={
                        <Typography sx={{ fontSize: "13px" }}>
                          {op.label}
                        </Typography>
                      }
                      control={
                        <Radio
                          sx={{
                            "&, &.Mui-checked": { color: theme.themeOrange },
                          }}
                          value={op.value}
                          size="small"
                        />
                      }
                    />
                  );
                })}
            </RadioGroup>
          </FormControl>
        </>
      );
    default:
      <TextField
        value={items[index][component._id] ? items[index][component._id] : ""}
        onChange={(e) => handleTemplateChange(e, component._id)}
        required
        size="small"
        fullWidth
        label={component.label}
        placeholder={component.placeholder}
        sx={{ ...inputClasses.shadowField }}
      />;
  }
};

export const getInputFieldForTableRow = (
  component,
  items,
  index,
  handleChange,
  width,
  isDarkThemeEnabledSelector,
  additionalData
) => {
  switch (component.type) {
    case "input":
    case "number":
    case "textarea":
    case "date":
      return (
        <>
          <Grid item md={4} sx={{ marginBottom: "8px" }}>
            <FormControl
              variant="outlined"
              size="small"
              fullWidth
              sx={{
                ...selectStylesOverride(isDarkThemeEnabledSelector),
                width: width,
              }}
            >
              <CustomInput
                style={{ width: width }}
                component={component}
                disabled={
                  additionalData
                    ? additionalData?.status === "completed"
                      ? true
                      : false
                    : ""
                }
                field={{}}
                value={
                  items &&
                  items[index] &&
                  items[index][component._id] &&
                  items[index][component._id]
                    ? items[index][component._id]
                    : ""
                }
                onChange={(e) => handleChange(e, index, component._id)}
              />
            </FormControl>
          </Grid>
        </>
      );

    case "select":
      return (
        <>
          <Grid item md={4} sx={{ marginBottom: "8px" }}>
            <FormControl
              variant="outlined"
              size="small"
              fullWidth
              sx={{
                ...selectStylesOverride(isDarkThemeEnabledSelector),
                width: width,
              }}
            >
              <CustomInput
                style={{ width: width }}
                component={component}
                disabled={
                  additionalData
                    ? additionalData?.status === "completed"
                      ? true
                      : false
                    : ""
                }
                field={{}}
                value={
                  items &&
                  items[index] &&
                  items[index][component._id] &&
                  items[index][component._id]
                    ? items[index][component._id]
                    : ""
                }
                onChange={(e) => handleChange(e, index, component._id)}
              />
            </FormControl>
          </Grid>
        </>
      );
    case "checkbox":
      return (
        <>
          <Grid item md={4}>
            <FormControl
              variant="outlined"
              size="small"
              fullWidth
              sx={{
                ...selectStylesOverride(isDarkThemeEnabledSelector),
                width: width,
                marginBottom: "8px",
                border: "1px solid rgb(217, 217, 217)",
                borderRadius: "12px",
                padding: "10px",
              }}
            >
              <FormLabel>{component.label}</FormLabel>

              <FormGroup row sx={{ mt: 1 }}>
                {component.values &&
                  component.values.map((op, i) => {
                    return (
                      <FormControlLabel
                        key={i}
                        label={
                          <Typography sx={{ fontSize: "13px" }}>
                            {op.label}
                          </Typography>
                        }
                        control={
                          <Checkbox
                            disabled={
                              additionalData
                                ? additionalData?.status === "completed"
                                  ? true
                                  : false
                                : ""
                            }
                            checked={
                              items &&
                              items[index] &&
                              items[index][component._id] &&
                              items[index][component._id]
                                ? items[index][component._id].includes(op.value)
                                : ""
                            }
                            onChange={(e) =>
                              handleChange(
                                { checked: e.target.checked, value: op.value },
                                index,
                                component._id
                              )
                            }
                            size="small"
                            sx={{
                              "&, &.Mui-checked": { color: theme.themeOrange },
                            }}
                          />
                        }
                      />
                    );
                  })}
              </FormGroup>
            </FormControl>
          </Grid>
        </>
      );
    case "radio":
      return (
        <>
          <Grid item md={4}>
            <FormControl
              variant="outlined"
              size="small"
              fullWidth
              sx={{
                ...selectStylesOverride(isDarkThemeEnabledSelector),
                width: width,
                marginBottom: "8px",
                border: "1px solid rgb(217, 217, 217)",
                borderRadius: "12px",
                padding: "10px",
              }}
            >
              <FormLabel>{component.label}</FormLabel>
              <RadioGroup
                row
                disabled={
                  additionalData
                    ? additionalData?.status === "completed"
                      ? true
                      : false
                    : ""
                }
                value={
                  items &&
                  items[index] &&
                  items[index][component._id] &&
                  items[index][component._id]
                    ? items[index][component._id]
                    : ""
                }
                onChange={(e) => handleChange(e, index, component._id)}
                sx={{ width: width }}
              >
                {component.values &&
                  component.values.map((op, i) => {
                    return (
                      <FormControlLabel
                        style={{ width: width }}
                        disabled={
                          additionalData
                            ? additionalData?.status === "completed"
                              ? true
                              : false
                            : ""
                        }
                        key={i}
                        label={
                          <Typography sx={{ fontSize: "13px" }}>
                            {op.label}
                          </Typography>
                        }
                        control={
                          <Radio
                            sx={{
                              "&, &.Mui-checked": { color: theme.themeOrange },
                            }}
                            value={op.value}
                            size="small"
                          />
                        }
                      />
                    );
                  })}
              </RadioGroup>
            </FormControl>
          </Grid>
        </>
      );
    default:
      <TextField
        value={items[index][component._id] ? items[index][component._id] : ""}
        onChange={(e) => handleChange(e, index, component._id)}
        required
        size="small"
        fullWidth
        label={component.label}
        placeholder={component.placeholder}
        sx={{ ...inputClasses.shadowField }}
      />;
  }
};

export const getInputFieldForEntry = (component, register, control) => {
  switch (component.type) {
    case "input":
      return (
        <Controller
          control={control}
          name={component._id}
          render={({ field }) => (
            <TextField
              onChange={(e) => field.onChange(e.target.value)}
              InputLabelProps={{
                style: {
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  width: "100%",
                  fontSize: "13px",
                  color: "#909090",
                },
              }}
              // {...register(component.key)}
              required={component.required}
              size="small"
              fullWidth
              label={component.label}
              placeholder={component.placeholder}
              selectStylesOverride={selectStylesOverride}
              sx={{ ...inputClasses.shadowField }}
            />
          )}
        />
      );
    case "number":
      return (
        <Controller
          control={control}
          name={component.key}
          render={({ field }) => (
            <TextField
              onChange={(e) => field.onChange(e.target.value)}
              InputLabelProps={{
                style: {
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  width: "100%",
                  fontSize: "13px",
                  color: "#909090",
                },
              }}
              type="number"
              required={component.required}
              size="small"
              fullWidth
              label={component.label}
              placeholder={component.placeholder}
              sx={{ ...inputClasses.shadowField }}
            />
          )}
        />
      );
    case "date":
      return (
        <TextField
          {...register(component._id, { required: component.required })}
          InputLabelProps={{ shrink: true }}
          type="date"
          required={component.required}
          size="small"
          fullWidth
          label={component.label}
          sx={{ ...inputClasses.shadowField }}
        />
      );
    case "textarea":
      return (
        <TextField
          {...register(component._id, { required: component.required })}
          multiline
          rows={3}
          required={component.required}
          size="small"
          fullWidth
          label={component.label}
          placeholder={component.placeholder}
          sx={{ ...inputClasses.shadowField }}
        />
      );
    case "searchTags":
      return (
        <TextField
          {...register(component._id, { required: component.required })}
          required={component.required}
          size="small"
          fullWidth
          label={component.label}
          placeholder={component.placeholder}
          sx={{ ...inputClasses.shadowField }}
        />
      );
    case "file":
      return (
        <Controller
          control={control}
          name={component._id}
          defaultValue={""}
          render={({ field }) => (
            <TextField
              onChange={(e) => field.onChange(e.target.files)}
              type={"file"}
              required={component.required}
              size="small"
              focused
              fullWidth
              label={component.label}
              sx={{ ...inputClasses.shadowField }}
            />
          )}
        />
        // <TextField {...register(component._id, {required: component.required})} type={"file"} required={component.required} size='small' focused fullWidth label={component.label} sx={{...inputClasses.shadowField}}/>
      );
    case "select":
      return (
        <FormControl fullWidth size="small">
          <InputLabel>{component.label}</InputLabel>
          <Controller
            control={control}
            name={component._id}
            defaultValue={component.values[0].value}
            render={({ field }) => (
              <Select
                {...field}
                size="small"
                sx={{ ...inputClasses.shadowField }}
                label={component.label}
              >
                {component.values.map((op, i) => {
                  return (
                    <MenuItem value={op.value} key={i}>
                      {op.label}
                    </MenuItem>
                  );
                })}
              </Select>
            )}
          />
        </FormControl>
      );
    case "checkbox":
      return (
        <div
          style={{
            ...inputClasses.shadowField,
            border: "1px solid #D9D9D9",
            width: "100%",
            padding: "10px",
          }}
        >
          <FormControl fullWidth size="small">
            <FormLabel>{component.label}</FormLabel>
            <Controller
              control={control}
              name={component._id}
              defaultValue={component.values[0].value}
              render={({ field }) => (
                <RadioGroup
                  {...field}
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  sx={{ mt: 1 }}
                >
                  {component.values.map((op, i) => {
                    return (
                      <FormControlLabel
                        control={
                          <Checkbox value={op.value} key={i} size="small" />
                        }
                        label={
                          <Typography sx={{ fontSize: "13px" }}>
                            {op.label}
                          </Typography>
                        }
                      />
                    );
                  })}
                </RadioGroup>
              )}
            />
          </FormControl>
        </div>
      );
    case "radio":
      return (
        <div
          style={{
            ...inputClasses.shadowField,
            border: "1px solid #D9D9D9",
            width: "100%",
            padding: "10px",
          }}
        >
          <FormControl fullWidth size="small">
            <FormLabel>{component.label}</FormLabel>
            <Controller
              control={control}
              name={component._id}
              defaultValue={component.values[0].value}
              render={({ field }) => (
                <RadioGroup {...field} row sx={{ mt: 1 }}>
                  {component.values.map((op, i) => {
                    return (
                      <FormControlLabel
                        control={
                          <Radio value={op.value} key={i} size="small" />
                        }
                        label={op.label}
                        key={i}
                      />
                    );
                  })}
                </RadioGroup>
              )}
            />
          </FormControl>
        </div>
      );
    default:
      <TextField
        required
        size="small"
        fullWidth
        label={component.label}
        placeholder={component.placeholder}
        sx={{ ...inputClasses.shadowField }}
      />;
  }
};

export const getFieldsLayoutForEntry = (
  fields,
  register,
  control,
  handleFocus,
  errors,
  action,
  isDarkThemeEnabledSelector
) => {
  let layout = {
    single: [],
    multi: [],
  };
  for (let i = 0; i < fields.length; i++) {
    if (
      ["input", "number", "date", "searchTags", "file", "select"].indexOf(
        fields[i].type
      ) >= 0
    ) {
      layout.single.push(
        <CustomControlledInput
          action={action}
          register={register}
          component={fields[i]}
          control={control}
          errors={errors}
          autoFillOnFocus={handleFocus}
          isDarkThemeEnabledSelector={isDarkThemeEnabledSelector}
        />
      );
    } else {
      layout.multi.push(
        <CustomControlledInput
          action={action}
          register={register}
          component={fields[i]}
          control={control}
          errors={errors}
          autoFillOnFocus={handleFocus}
          isDarkThemeEnabledSelector={isDarkThemeEnabledSelector}
        />
      );
    }
  }
  return layout;
};
export const DataNotFound = () => {
  return (
    <>
      <Box>
        <img src={no_data_found} height="250px" width="250px" />
        <Typography>No Data Found</Typography>
      </Box>
    </>
  );
};

const CustomInput = ({
  component,
  autoFillOnFocus,
  errors,
  field,
  multiline,
  inputProps,
  ...other
}) => {
  const convertDocsBase64 = (file) => {
    if (file) {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          const fileReader = new FileReader();
          fileReader.readAsDataURL(file);
          fileReader.onload = () => {
            resolve(fileReader.result);
          };
          fileReader.onerror = (error) => {
            reject(error);
          };
        }, 400);
      });
    } else {
    }
  };

  const handleSelect = (idChecked, values = []) => {
    const newValue = values?.includes(idChecked)
      ? values?.filter((id) => id !== idChecked)
      : [...values, idChecked];
    return newValue;
  };

  let basicProps = {
    size: "small",
    fullWidth: true,
    type: component.type,
    required: component.required,
    multiline: component.type == "textarea",
    rows: component.type == "textarea" ? 3 : 1,
    label: component.label,
    placeholder: component.placeholder,
    onFocus: autoFillOnFocus ? autoFillOnFocus : () => {},
    error: errors && errors[component._id] ? true : false,
  };

  if (component.type == "number") {
    basicProps.onWheel = (event) => event.target.blur();
    basicProps.onKeyDown = (e) => {
      if (["e", "E", "+", "-"].includes(e.key)) {
        e.preventDefault();
      } else if (
        e.key == "0" &&
        (e.target.value == "" || e.target.value[0] == "0")
      ) {
        e.preventDefault();
      }
    };
  }
  if (component.type == "date") {
    basicProps.InputLabelProps = { shrink: true };
  }
  if (component.type == "file") {
    basicProps.focused = true;
    basicProps.onChange = async (e) => {
      const file = await convertDocsBase64(e.target.files[0]);
      field.onChange(file);
    };
  }
  if (component.type != "file") {
    basicProps = {
      ...field,
      ...basicProps,
    };
  }

  if (["input", "number", "file"].indexOf(component.type) >= 0) {
    return (
      <TextField
        {...basicProps}
        {...other}
        // selectStylesOverride={selectStylesOverride}
        sx={{ ...inputClasses.textfield }}
        md={12}
        InputProps={inputProps ? { endAdornment: inputProps } : {}}
        InputLabelProps={{
          style: {
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden",
            width: "100%",
            fontSize: "13px",
            paddingTop: "2px",
            // color: '#f',
          },
        }}
      />
    );
  } else if (["textarea"].indexOf(component.type) >= 0) {
    return (
      <TextField
        {...basicProps}
        {...other}
        // selectStylesOverride={selectStylesOverride}
        sx={{ ...inputClasses.textfield }}
        md={12}
        InputProps={inputProps ? { endAdornment: inputProps } : {}}
        InputLabelProps={{
          style: {
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden",
            width: "100%",
            fontSize: "13px",
            paddingTop: "2px",
            // color: '#f',
          },
        }}
      />
    );
  } else if (component.type == "date") {
    return (
      <TextField
        {...basicProps}
        {...other}
        InputLabelProps={{ shrink: true, fontSize: "13px", paddingTop: "2px" }}
        type="date"
        required
        size="small"
        fullWidth
      />
    );
  } else if (component.type == "select") {
    return (
      <>
        <FormControl
          fullWidth={basicProps.fullWidth}
          size={basicProps.size}
          required={basicProps.required}
          error={basicProps.error}
        >
          <InputLabel
            sx={{
              color: "#B0B0B0",
              fontWeight: component.disabled ? "100" : "",
              fontSize: "13px",
              paddingTop: "1px",
            }}
          >
            {component.label}
          </InputLabel>
          <Select
            {...field}
            label={`${component.label}${component.required ? "*" : ""}`}
            onFocus={basicProps.onFocus}
            {...other}
            sx={{ ...inputClasses.themeBoundary, height: "36px" }}
          >
            <MenuItem sx={{ color: "gray" }} value={""}>
              {component.placeholder ? component.placeholder : "Select"}
            </MenuItem>
            {/* {
                            component.dynamic && selectOptions.map((op, i) => {
                                return <MenuItem value={op[component.keyForValue]} key={i}>{op[component.keyForLabel]}</MenuItem>
                            })
                        } */}
            {!component.dynamic &&
              component.values &&
              component.values.map((op, i) => {
                return (
                  <MenuItem value={op.value} key={i}>
                    {op.label}
                  </MenuItem>
                );
              })}
          </Select>
        </FormControl>
      </>
    );
  } else if (component.type == "radio") {
    const innerComp = (
      <RadioGroup row {...field} sx={{ mt: 1 }}>
        {component.values &&
          component.values.map((op, i) => {
            return (
              <FormControlLabel
                key={i}
                {...other}
                label={
                  <Typography sx={{ fontSize: "13px" }}>{op.label}</Typography>
                }
                control={
                  <Radio
                    value={op.value}
                    size="small"
                    sx={{ "&, &.Mui-checked": { color: theme.themeOrange } }}
                  />
                }
              />
            );
          })}
      </RadioGroup>
    );
    if (multiline != null && multiline == false) {
      return (
        <Grid container justifyContent={"left"} alignItems="center">
          {innerComp}
        </Grid>
      );
    }
    return (
      <div
        style={{
          ...inputClasses.shadowField,
          border: `1px solid ${basicProps.error ? "#e74c3c" : "#D9D9D9"}`,
          width: "100%",
          padding: "10px",
        }}
      >
        <FormControl fullWidth size="small" {...other}>
          <FormLabel
            sx={{
              color: "rgb(0 0 0 / 60%) !important",
              "& .MuiFormLabel-root.Mui-focused": {
                color: "#F77200",
              },
            }}
          >
            {component.label}
          </FormLabel>
          {innerComp}
        </FormControl>
      </div>
    );
  } else if (component.type == "checkbox") {
    const innerComp = (
      <FormGroup row sx={{ mt: 1 }}>
        {component.values &&
          component.values.map((op, i) => {
            return (
              <FormControlLabel
                key={i}
                sx={{ width: "100%" }}
                {...other}
                label={
                  <Typography sx={{ fontSize: "13px" }}>{op.label}</Typography>
                }
                control={
                  <Checkbox
                    checked={field.value.includes(op.value)}
                    onChange={() =>
                      field.onChange(handleSelect(op.value, field.value))
                    }
                    size="small"
                    sx={{ "&, &.Mui-checked": { color: theme.themeOrange } }}
                  />
                }
              />
            );
          })}
      </FormGroup>
    );
    if (multiline != null && multiline == false) {
      return (
        <Grid container justifyContent={"left"} alignItems="center">
          {innerComp}
        </Grid>
      );
    }
    return (
      <div
        style={{
          ...inputClasses.shadowField,
          border: "1px solid #D9D9D9",
          width: "100%",
          padding: "10px",
        }}
      >
        <FormControl fullWidth size="small">
          <FormLabel
            sx={{
              color: "rgb(0 0 0 / 60%) !important",
              "& .MuiFormLabel-root.Mui-focused": {
                color: "#F77200",
              },
            }}
          >
            {component.label}
          </FormLabel>
          {innerComp}
        </FormControl>
      </div>
    );
  } else {
  }
};
export const CustomControlledInput = ({
  component,
  control,
  errors,
  autoFillOnFocus,
  register,
  action,
  multiline,
  inputProps,
  isDarkThemeEnabledSelector,
}) => {
  return (
    <>
      <Grid md={12} container justifyContent={"center"} alignItems="center">
        <FormControl
          variant="outlined"
          size="small"
          fullWidth
          sx={{
            ...selectStylesOverride(isDarkThemeEnabledSelector),
            width: "100%",
          }}
        >
          <Controller
            name={component._id}
            control={control}
            // ref={register}
            forwardRef={register}
            rules={{
              required: {
                value: component.required,
                message: `${component.label} is required`,
              },
              pattern: {
                value: component.regex ? new RegExp(component.regex) : "",
                message: `Invalid value for ${component.label}`,
              },
            }}
            defaultValue={component.defaultValue || ""}
            render={({ field }) => (
              <CustomInput
                style={{ width: "100%" }}
                component={component}
                autoFillOnFocus={autoFillOnFocus}
                errors={errors}
                field={field}
                disabled={(() => {
                  if (action == "view") {
                    return true;
                  }
                  if (component.disabled != null) {
                    return component.disabled;
                  }
                  return false;
                  // disabled != null ? disabled : false
                })()}
                inputProps={inputProps}
                // disabled={action === 'view' ? true : (component.disabled)}
                multiline={multiline != null ? multiline : null}
              />
            )}
          />
        </FormControl>
      </Grid>
      {
        <Grid container>
          <Typography
            sx={{
              ...textClasses.t12n,
              color: "#e74c3c",
              textAlign: "left",
              mt: "5px",
              ml: "5px",
            }}
          >
            {errors && errors[component._id]
              ? errors[component._id].message
              : ""}
          </Typography>
        </Grid>
      }
    </>
  );
};
