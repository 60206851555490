import {
  SHOW_MESSAGE,
  FETCH_START,
  FETCH_SUCCESS,
  FETCH_ERROR,
  TRIP_LIST,
  TRIP_MARKERS,
  AVAILABLE_DEVICE_TYPE,
  AVAILABLE_MESSAGE,
  TRIP_DETAILS,
  EMPTY_TRIP_DETAILS,
  TRIP_STATUS_CHART,
  TRIP_LOCK_STATUS_CHART,
  TRIP_ASSET_STATUS_CHART,
  TRIP_TRACK_LIST,
  EMPTY_TRIP_TRACK_LISTING,
  TRIP_ALERT_LIST,
  EMPTY_TRIP_ALERT_LISTING,
  REPORT_KEYS,
  EMPTY_REPORT_KEYS,
  TRIP_SUMMARY,
  EMPTY_TRIP_LIST,
  TRIP_PERFORMANCE_DATA,
  TRIP_LIST_FOR_ROUTE,
  EMPTY_TRIP_SUMMARY,
  CLOSURE_MESSAGE,
  TRIP_LIST_COUNT,
  TRIP_SCHEDULE_LIST,
  EMPTY_TRIP_SCHEDULE_LIST,
  TRIP_SCHEDULE_LIST_LOADING,
  BSJ_CAMERA_ALERTS,
  EMPTY_BSJ_ALERTS,
  BSJ_CAMERA_VIDEO_LIST,
  EMPTY_BSJ_VIDEO_LIST,
  FLIGHTDETAILS

} from "../types";
import axios, { geoAxios, reportAxios } from "../../services/Api";
import { showToast } from "../../services/functions";
import history from "../../services/history";
import { convertToGMTTime } from "../../services/constants";

export const createTrip = (payload, callback) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    geoAxios
      .post("/createTrip", payload)
      .then(({ data }) => {
        if (data.success) {
          dispatch({ type: FETCH_SUCCESS, payload: data.success });
          dispatch({ type: SHOW_MESSAGE, payload: data.message });
        } else {
          dispatch({ type: FETCH_ERROR, payload: data.message });
          showToast(data?.message, true);
        }

        if (callback) {
          callback({ success: data.success || false, message: data.message });
        }
      })
      .catch(function (error) {
        dispatch({
          type: FETCH_ERROR,
          payload: error?.response?.data?.message,
        });
        if (callback) {
          callback({ success: false, message: error?.response?.data?.message });
        }
        showToast(error?.response?.data?.message, true);
        console.log("Error****:", error.message);
      });
  };
};

export const affixTrip = (payload, callback) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    geoAxios
      .post("/mobile/affixDeviceToTrip", payload)
      .then(({ data }) => {
        if (data.success) {
          dispatch({ type: FETCH_SUCCESS, payload: data.success });
          dispatch({ type: SHOW_MESSAGE, payload: data.message });
        } else {
          dispatch({ type: FETCH_ERROR, payload: data.message });
          // showToast(data?.message, true);
        }

        if (callback) {
          callback({ success: data.success || false, message: data.message });

        }
      })
      .catch(function (error) {
        dispatch({
          type: FETCH_ERROR,
          payload: error?.response?.data?.message,
        });
        if (callback) {
          callback({ success: false, message: error?.response?.data?.message });
        }
        showToast(error?.response?.data?.message, true);
        console.log("Error****:", error.message);
      });
  };
};

export const editTrip = (payload, callback) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    geoAxios
      .post("/editTrip", payload)
      .then(({ data }) => {
        if (data.success) {
          dispatch({ type: FETCH_SUCCESS, payload: data.success });
          dispatch({ type: SHOW_MESSAGE, payload: data.message });
        } else {
          dispatch({ type: FETCH_ERROR, payload: data.message });
        }

        if (callback) {
          callback({ success: data.success || false, message: data.message });

        }
      })
      .catch(function (error) {
        dispatch({
          type: FETCH_ERROR,
          payload: error?.response?.data?.message,
        });
        if (callback) {
          callback({ success: false, message: error?.response?.data?.message });
        }
        console.log("Error****:", error.message);
      });
  };
};

export const getAllTrips = (payload, cb) => {
  return async (dispatch) => {
    dispatch({ type: FETCH_START });
    await geoAxios
      .post("/listTrip", { ...payload, count: false })
      .then(({ data }) => {
        if (data.success) {
          callback(data);
        } else {
          cb({ success: false, message: data?.message });
          dispatch({
            type: TRIP_LIST,
            payload: { rows: [], count: 0 },
          });
        }
        function callback(data) {
          dispatch({ type: FETCH_SUCCESS });
          dispatch({
            type: TRIP_LIST,
            payload: { rows: data.data, count: data.count },
          });
          if (cb) {
            cb(data);
          }
        }
      })
      .catch(function (error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
        dispatch({ type: TRIP_LIST, payload: { rows: [], count: 0 } });
        console.log("Error****:", error.message);
        if (cb) {
          cb({ success: false, message: error?.response?.data?.message });
          // cb(error);
        }
      });
  };
};

export const getTripId = (payload, cb) => {
  return async (dispatch) => {
    dispatch({ type: "TRIP_ID_LOADING", payload: true });
    await geoAxios
      .post("/getTripId", { ...payload })
      .then(({ data }) => {
        if (data.success) {
          callback(data);
        } else {
          cb({ success: false, message: data?.message });
          showToast(data?.message, true);
        }
        function callback(data) {
          if (cb) {
            cb(data);
          }
        }
        dispatch({ type: "TRIP_ID_LOADING", payload: false });
      })
      .catch(function (error) {
        console.log("Error****:", error.message);
        if (cb) {
          cb({ success: false, message: error?.response?.data?.message });
        }
        dispatch({ type: "TRIP_ID_LOADING", payload: false });
        showToast(error?.response?.data?.message, true);
      });
  };
};

export const getMarkerForTrips = (payload, cb) => {
  return async (dispatch) => {
    dispatch({ type: FETCH_START });
    await geoAxios
      .post("/getMarkerForMap", { ...payload })
      .then(({ data }) => {
        if (data.success) {
          callback(data);
        } else {
          cb({ success: false, message: data?.message });
          dispatch({
            type: TRIP_MARKERS,
            payload: { rows: [], count: 0 },
          });
        }
        function callback(data) {
          dispatch({ type: FETCH_SUCCESS });
          dispatch({
            type: TRIP_MARKERS,
            payload: { rows: data.data, count: data.count },
          });
          if (cb) {
            cb(data);
          }
        }
      })
      .catch(function (error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
        dispatch({ type: TRIP_MARKERS, payload: { rows: [], count: 0 } });
        console.log("Error****:", error.message);
        if (cb) {
          cb({ success: false, message: error?.response?.data?.message });
          // cb(error);
        }
      });
  };
};
export const getTripsListCount = (payload, cb) => {
  return async (dispatch) => {
    dispatch({ type: FETCH_START });
    dispatch({ type: "TRIP_PAGINATION_LOADING", payload: true });
    await geoAxios
      .post("/countTrip", { ...payload })
      .then(({ data }) => {
        if (data.success) {
          callback(data);
        } else {
          cb({ success: false, message: data?.message });
          dispatch({
            type: TRIP_LIST_COUNT,
            payload: 0,
          });
        }
        function callback(data) {
          dispatch({ type: FETCH_SUCCESS });
          dispatch({
            type: TRIP_LIST_COUNT,
            payload: data.count,
          });
          if (cb) {
            cb(data);
          }
        }
        dispatch({ type: "TRIP_PAGINATION_LOADING", payload: false });
      })
      .catch(function (error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
        dispatch({ type: TRIP_LIST_COUNT, payload: 0 });
        console.log("Error****:", error.message);
        if (cb) {
          cb({ success: false, message: error?.response?.data?.message });
          // cb(error);
        }
        dispatch({ type: "TRIP_PAGINATION_LOADING", payload: false });
      });
  };
};

export const checkAvailability = (payload, callback) => {
  return (dispatch) => {
    geoAxios
      .post("/checkAvailability", payload)
      .then(({ data }) => {
        if (data.success) {
          // dispatch({ type: FETCH_SUCCESS, payload: data.success });
          // dispatch({ type: SHOW_MESSAGE, payload: data.message });
        }
        else {
          // dispatch({ type: FETCH_ERROR, payload: data.message });
        }

        if (callback) {
          // callback({ success: data.success || false, message: data.message });
          dispatch({ type: AVAILABLE_DEVICE_TYPE, payload: { rows: data.data }, });
          dispatch({ type: AVAILABLE_MESSAGE, payload: { mssg: data.message, success: data.success }, });


        }
        if (data.success) {
          showToast(data.message, false);
        }
        else {
          showToast(data.message, true);
        }
      })
      .catch(function (error) {
        // dispatch({
        //   type: FETCH_ERROR,
        //   payload: error?.response?.data?.message,
        // });
        if (callback) {
          // callback({ success: false, message: error?.response?.data?.message });
        }
        console.log("Error****:", error.message);
      });
  };
};
export const saveDraftTrip = (payload, callback) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    geoAxios
      .post("/saveAsDraftTrip", payload)
      .then(({ data }) => {
        if (data.success) {
          dispatch({ type: FETCH_SUCCESS, payload: data.success });
          dispatch({ type: SHOW_MESSAGE, payload: data.message });
        } else {
          dispatch({ type: FETCH_ERROR, payload: data.message });
        }

        if (callback) {
          callback({ success: data.success || false, message: data.message });
        }
      })
      .catch(function (error) {
        dispatch({
          type: FETCH_ERROR,
          payload: error?.response?.data?.message,
        });
        if (callback) {
          callback({ success: false, message: error?.response?.data?.message });
        }
        console.log("Error****:", error.message);
      });
  };
};

export const tripDetails = (payload, cb) => {
  return async (dispatch) => {
    dispatch({ type: FETCH_START });
    dispatch({ type: EMPTY_TRIP_DETAILS });
    await geoAxios
      .post("/detailsTrip", { ...payload })
      .then(({ data }) => {
        if (data.success) {
          callback(data);
        } else {
          cb && cb({ success: false, message: data?.message });
        }
        function callback(data) {
          dispatch({ type: FETCH_SUCCESS });
          dispatch({
            type: TRIP_DETAILS,
            payload: { rows: data.data, count: data.count },
          });
          if (cb) {
            cb(data);
          }
        }
      })
      .catch(function (error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
        dispatch({ type: TRIP_DETAILS, payload: { rows: [], count: 0 } });
        console.log("Error****:", error.message);
        if (cb) {
          cb({ success: false, message: error?.response?.data?.message });
          // cb(error);
        }
      });
  };
};

export const getTripById = (payload, cb) => {
  return async (dispatch) => {
    dispatch({ type: FETCH_START });
    dispatch({ type: EMPTY_TRIP_DETAILS });
    await geoAxios
      .post("/getTripById", { ...payload })
      .then(({ data }) => {
        if (data.success) {
          callback(data);
        } else {
          cb && cb({ success: false, message: data?.message });
        }
        function callback(data) {
          dispatch({ type: FETCH_SUCCESS });
          dispatch({
            type: TRIP_DETAILS,
            payload: { rows: data.data, count: data.count },
          });
          if (cb) {
            cb(data);
          }
        }
      })
      .catch(function (error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
        dispatch({ type: TRIP_DETAILS, payload: { rows: [], count: 0 } });
        console.log("Error****:", error.message);
        if (cb) {
          cb({ success: false, message: error?.response?.data?.message });
          // cb(error);
        }
      });
  };
};

export const getSharedTripTrackingData = (payload, cb) => {
  return async (dispatch) => {
    dispatch({ type: FETCH_START });
    await geoAxios
      .post("/getSharedTripTrackingData", { ...payload })
      .then(({ data }) => {
        if (data.success) {
          callback(data);
        } else {
          cb({ success: false, message: data?.message });
          showToast(data?.message, true)
        }
        function callback(data) {
          dispatch({ type: FETCH_SUCCESS });
          if (cb) {
            cb(data);
          }
        }
      })
      .catch(function (error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
        console.log("Error****:", error.message);
        if (cb) {
          cb({ success: false, message: error?.response?.data?.message });
          showToast(error?.response?.data?.message, true)
          // cb(error);
        }
      });
  };
};

export const getSharedDeviceTrackingData = (payload, cb) => {
  return async (dispatch) => {
    dispatch({ type: FETCH_START });
    await geoAxios
      .post("/getSharedTrackingDataDevice", { ...payload })
      .then(({ data }) => {
        if (data.success) {
          callback(data);
        } else {
          cb({ success: false, message: data?.message });
          showToast(data?.message, true)
        }
        function callback(data) {
          dispatch({ type: FETCH_SUCCESS });
          if (cb) {
            cb(data);
          }
        }
      })
      .catch(function (error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
        console.log("Error****:", error.message);
        if (cb) {
          cb({ success: false, message: error?.response?.data?.message });
          showToast(error?.response?.data?.message, true)
          // cb(error);
        }
      });
  };
};
export const emptyTripSchduleList = (payload, callback) => {
  return (dispatch) => {
    dispatch({ type: EMPTY_TRIP_SCHEDULE_LIST });
  }
}
export const emptyTripList = (payload, callback) => {
  return (dispatch) => {
    dispatch({ type: EMPTY_TRIP_LIST });
  }
}
export const emptyTripDetails = (payload, callback) => {
  return (dispatch) => {
    dispatch({ type: EMPTY_TRIP_DETAILS });
  }
}
export const emptyTripSummary = (payload, callback) => {
  return (dispatch) => {
    dispatch({ type: EMPTY_TRIP_SUMMARY });
  }
}
export const tripStatusChart = (payload, cb) => {
  return async (dispatch) => {
    dispatch({ type: FETCH_START });
    await geoAxios
      .post("/getTripStatusGraphData", { ...payload })
      .then(({ data }) => {
        if (data.success) {
          callback(data);
        } else {
          cb && cb({ success: false, message: data?.message });
        }
        function callback(data) {
          dispatch({ type: FETCH_SUCCESS });
          dispatch({
            type: TRIP_STATUS_CHART,
            payload: { rows: data.data, count: data.count },
          });
          if (cb) {
            cb(data);
          }
        }
      })
      .catch(function (error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
        dispatch({ type: TRIP_STATUS_CHART, payload: { rows: [], count: 0 } });
        console.log("Error****:", error.message);
        if (cb) {
          cb({ success: false, message: error?.response?.data?.message });
          // cb(error);
        }
      });
  };
};
export const tripLockStatusChart = (payload, cb) => {
  return async (dispatch) => {
    dispatch({ type: FETCH_START });
    await geoAxios
      .post("/getTripLockStatusData", { ...payload })
      .then(({ data }) => {
        if (data.success) {
          callback(data);
        } else {
          cb && cb({ success: false, message: data?.message });
        }
        function callback(data) {
          dispatch({ type: FETCH_SUCCESS });
          dispatch({
            type: TRIP_LOCK_STATUS_CHART,
            payload: { rows: data.data, count: data.count },
          });
          if (cb) {
            cb(data);
          }
        }
      })
      .catch(function (error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
        dispatch({ type: TRIP_LOCK_STATUS_CHART, payload: { rows: [], count: 0 } });
        console.log("Error****:", error.message);
        if (cb) {
          cb({ success: false, message: error?.response?.data?.message });
          // cb(error);
        }
      });
  };
};
export const tripAssetStatusChart = (payload, cb) => {
  return async (dispatch) => {
    dispatch({ type: FETCH_START });
    await geoAxios
      .post("/getTripAssetStatusData", { ...payload })
      .then(({ data }) => {
        if (data.success) {
          callback(data);
        } else {
          cb && cb({ success: false, message: data?.message });
        }
        function callback(data) {
          dispatch({ type: FETCH_SUCCESS });
          dispatch({
            type: TRIP_ASSET_STATUS_CHART,
            payload: { rows: data.data, count: data.count },
          });
          if (cb) {
            cb(data);
          }
        }
      })
      .catch(function (error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
        dispatch({ type: TRIP_ASSET_STATUS_CHART, payload: { rows: [], count: 0 } });
        console.log("Error****:", error.message);
        if (cb) {
          cb({ success: false, message: error?.response?.data?.message });
          // cb(error);
        }
      });
  };
};
export const shareTrackingLink = (payload, callback) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    geoAxios
      .post("/shareTripTracking", payload.data)
      .then(({ data }) => {
        if (data.success) {
          dispatch({ type: FETCH_SUCCESS, payload: data.success });
          dispatch({ type: SHOW_MESSAGE, payload: data.message });
          if (payload.param === 'copy') {
            navigator.clipboard.writeText(data?.url)
            showToast('Tracking link copied successfully.', false)
          }
        } else {
          dispatch({ type: FETCH_ERROR, payload: data.message });
        }

        if (callback) {
          callback({ success: data.success || false, message: data.message });
        }
      })
      .catch(function (error) {
        dispatch({
          type: FETCH_ERROR,
          payload: error?.response?.data?.message,
        });
        if (callback) {
          callback({ success: false, message: error?.response?.data?.message });
        }
        console.log("Error****:", error.message);
      });
  };
};
export const tripTrackListing = (payload, cb) => {
  return async (dispatch) => {
    dispatch({ type: FETCH_START });
    await geoAxios
      .post("/getSharedTripTrackListing", { ...payload })
      .then(({ data }) => {
        if (data.success) {
          callback(data);
        } else {
          cb && cb({ success: false, message: data?.message });
        }
        function callback(data) {
          dispatch({ type: FETCH_SUCCESS });
          if (data?.data?.length === 0) {
            showToast('No data found.', true)
          }
          dispatch({
            type: TRIP_TRACK_LIST,
            payload: { rows: data.data, count: data.count },
          });
          if (cb) {
            cb(data);
          }
        }
      })
      .catch(function (error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
        dispatch({ type: TRIP_TRACK_LIST, payload: { rows: [], count: 0 } });
        console.log("Error****:", error.message);
        if (cb) {
          cb({ success: false, message: error?.response?.data?.message });
          // cb(error);
        }
      });
  };
};
export const deleteTrackingLink = (payload, callback) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    geoAxios
      .post("/deleteSharedTripTrackingData", payload)
      .then(({ data }) => {
        if (data.success) {
          dispatch({ type: FETCH_SUCCESS, payload: data.success });
          dispatch({ type: SHOW_MESSAGE, payload: data.message });
        } else {
          dispatch({ type: FETCH_ERROR, payload: data.message });
        }

        if (callback) {
          callback({ success: data.success || false, message: data.message });
        }
      })
      .catch(function (error) {
        dispatch({
          type: FETCH_ERROR,
          payload: error?.response?.data?.message,
        });
        if (callback) {
          callback({ success: false, message: error?.response?.data?.message });
        }
        console.log("Error****:", error.message);
      });
  };
};
export const emptyTripTRackListing = (payload, callback) => {
  return (dispatch) => {
    dispatch({ type: EMPTY_TRIP_TRACK_LISTING });
  }
}
export const shareTripAlert = (payload, callback) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    geoAxios
      .post("/sharingTripAlert", payload)
      .then(({ data }) => {
        if (data.success) {
          dispatch({ type: FETCH_SUCCESS, payload: data.success });
          dispatch({ type: SHOW_MESSAGE, payload: data.message });
        } else {
          dispatch({ type: FETCH_ERROR, payload: data.message });
        }

        if (callback) {
          callback({ success: data.success || false, message: data.message });
        }
      })
      .catch(function (error) {
        dispatch({
          type: FETCH_ERROR,
          payload: error?.response?.data?.message,
        });
        if (callback) {
          callback({ success: false, message: error?.response?.data?.message });
        }
        console.log("Error****:", error.message);
      });
  };
};
export const alertListing = (payload, cb) => {
  return async (dispatch) => {
    dispatch({ type: FETCH_START });
    await geoAxios
      .post("/getSharingTripAlert", { ...payload })
      .then(({ data }) => {
        if (data.success) {
          callback(data);
        } else {
          cb({ success: false, message: data?.message });
        }
        function callback(data) {
          dispatch({ type: FETCH_SUCCESS });
          if (data?.data?.length === 0) {
            showToast('No data found.', true)
          }
          dispatch({
            type: TRIP_ALERT_LIST,
            payload: { rows: data.data, count: data.count },
          });
          if (cb) {
            cb(data);
          }
        }
      })
      .catch(function (error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
        dispatch({ type: TRIP_ALERT_LIST, payload: { rows: [], count: 0 } });
        console.log("Error****:", error.message);
        if (cb) {
          cb({ success: false, message: error?.response?.data?.message });
          // cb(error);
        }
      });
  };
};
export const emptyTripAlertListing = (payload, callback) => {
  return (dispatch) => {
    dispatch({ type: EMPTY_TRIP_ALERT_LISTING });
  }
}
export const deleteAlert = (payload, callback) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    geoAxios
      .post("/updateSharingAlert", payload)
      .then(({ data }) => {
        if (data.success) {
          dispatch({ type: FETCH_SUCCESS, payload: data.success });
          dispatch({ type: SHOW_MESSAGE, payload: data.message });
        } else {
          dispatch({ type: FETCH_ERROR, payload: data.message });
        }

        if (callback) {
          callback({ success: data.success || false, message: data.message });
        }
      })
      .catch(function (error) {
        dispatch({
          type: FETCH_ERROR,
          payload: error?.response?.data?.message,
        });
        if (callback) {
          callback({ success: false, message: error?.response?.data?.message });
        }
        console.log("Error****:", error.message);
      });
  };
};
export const updateAlert = (payload, callback) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    geoAxios
      .post("/updateSharingTripAlert", payload)
      .then(({ data }) => {
        if (data.success) {
          dispatch({ type: FETCH_SUCCESS, payload: data.success });
          dispatch({ type: SHOW_MESSAGE, payload: data.message });
        } else {
          dispatch({ type: FETCH_ERROR, payload: data.message });
        }

        if (callback) {
          callback({ success: data.success || false, message: data.message });
        }
      })
      .catch(function (error) {
        dispatch({
          type: FETCH_ERROR,
          payload: error?.response?.data?.message,
        });
        if (callback) {
          callback({ success: false, message: error?.response?.data?.message });
        }
        console.log("Error****:", error.message);
      });
  };
};
export const getTripReportKeys = (payload, cb) => {
  return async (dispatch) => {
    dispatch({ type: FETCH_START });
    dispatch({ type: EMPTY_REPORT_KEYS });
    await reportAxios
      .post("/getTripReportKeys", { ...payload })
      .then(({ data }) => {
        if (data.success) {
          callback(data);
        } else {
          cb({ success: false, message: data?.message });
        }
        function callback(data) {
          dispatch({ type: FETCH_SUCCESS });
          dispatch({
            type: REPORT_KEYS,
            payload: { rows: data.data, count: data.count },
          });
          if (cb) {
            cb(data);
          }
        }
      })
      .catch(function (error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
        dispatch({ type: REPORT_KEYS, payload: { rows: [], count: 0 } });
        console.log("Error****:", error.message);
        if (cb) {
          cb({ success: false, message: error?.response?.data?.message });
          // cb(error);
        }
      });
  };
};
export const downloadTripReport = (payload, callback) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    reportAxios
      .post("/getTripReportExcel", payload)
      .then(({ data }) => {
        if (data.success) {
          dispatch({ type: FETCH_SUCCESS, payload: data.success });
          dispatch({ type: SHOW_MESSAGE, payload: data.message });
        } else {
          dispatch({ type: FETCH_ERROR, payload: data.message });
        }

        if (callback) {
          callback({ success: data.success || false, message: data.message });
        }
      })
      .catch(function (error) {
        dispatch({
          type: FETCH_ERROR,
          payload: error?.response?.data?.message,
        });
        if (callback) {
          callback({ success: false, message: error?.response?.data?.message });
        }
        console.log("Error****:", error.message);
      });
  };
};
export const downloadTripSummaryReport = (payload, callback) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    reportAxios
      .post("/tripSummaryReport", payload)
      .then(({ data }) => {
        if (data.success) {
          dispatch({ type: FETCH_SUCCESS, payload: data.success });
          dispatch({ type: SHOW_MESSAGE });
        } else {
          dispatch({ type: FETCH_ERROR });
        }

        if (callback) {
          callback({ success: data.success || false, message: data.message });
        }
      })
      .catch(function (error) {
        dispatch({
          type: FETCH_ERROR,
          payload: error?.response?.data?.message,
        });
        if (callback) {
          callback({ success: false, message: error?.response?.data?.message });
        }
        console.log("Error****:", error.message);
      });
  };
};
export const saveDefaultReportKeys = (payload, callback) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    reportAxios
      .post("/saveDefaultKeysUserwise", payload)
      .then(({ data }) => {
        if (data.success) {
          dispatch({ type: FETCH_SUCCESS, payload: data.success });
          dispatch({ type: SHOW_MESSAGE, payload: data.message });
        } else {
          dispatch({ type: FETCH_ERROR, payload: data.message });
        }

        if (callback) {
          callback({ success: data.success || false, message: data.message });
        }
      })
      .catch(function (error) {
        dispatch({
          type: FETCH_ERROR,
          payload: error?.response?.data?.message,
        });
        if (callback) {
          callback({ success: false, message: error?.response?.data?.message });
        }
        console.log("Error****:", error.message);
      });
  };
};
export const closeTripForcefully = (payload, callback) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    geoAxios
      .post("/closeTripForcefully", payload)
      .then(({ data }) => {
        if (data.success) {
          dispatch({ type: FETCH_SUCCESS, payload: data.success });
          dispatch({ type: CLOSURE_MESSAGE, payload: data.message });
          if (callback) {
            callback({ success: data.success || false, message: data.message });
          }
        } else {
          dispatch({ type: FETCH_ERROR, payload: data.message });
          dispatch({ type: CLOSURE_MESSAGE, payload: data.message });
          if (callback) {
            callback({ success: false, message: data?.message });
          }
        }
      })
      .catch(function (error) {
        dispatch({
          type: FETCH_ERROR,
          payload: error?.response?.data?.message,
        });
        showToast(error?.response?.data?.message || "Something went wrong", true);
        if (callback) {
          callback({ success: false, message: error?.response?.data?.message });
        }
        console.log("Error****:", error.message);
      });
  };
};
export const getTripSummary = (payload, cb) => {
  return async (dispatch) => {
    dispatch({ type: FETCH_START });
    await geoAxios
      .post("/getTripSummary", { ...payload })
      .then(({ data }) => {
        if (data.success) {
          callback(data);
        } else {
          cb({ success: false, message: data?.message });
        }
        function callback(data) {
          dispatch({ type: FETCH_SUCCESS });
          dispatch({
            type: TRIP_SUMMARY,
            payload: { rows: data.data, count: data.count },
          });
          if (cb) {
            cb(data);
          }
        }
      })
      .catch(function (error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
        dispatch({ type: TRIP_SUMMARY, payload: { rows: [], count: 0 } });
        console.log("Error****:", error.message);
        if (cb) {
          cb({ success: false, message: error?.response?.data?.message });
          // cb(error);
        }
      });
  };
};
export const deletetTrip = (payload, callback) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    geoAxios
      .post("/deleteTrip", payload)
      .then(({ data }) => {
        if (data.success) {
          dispatch({ type: FETCH_SUCCESS, payload: data.success });
          dispatch({ type: SHOW_MESSAGE, payload: data.message });
        } else {
          dispatch({ type: FETCH_ERROR, payload: data.message });
        }

        if (callback) {
          callback({ success: data.success || false, message: data.message });
        }
      })
      .catch(function (error) {
        dispatch({
          type: FETCH_ERROR,
          payload: error?.response?.data?.message,
        });
        if (callback) {
          callback({ success: false, message: error?.response?.data?.message });
        }
        console.log("Error****:", error.message);
      });
  };
};
export const getTripPerformanceData = (payload, cb) => {
  return async (dispatch) => {
    dispatch({ type: FETCH_START });
    await geoAxios
      .post("/getTripPerformanceData", { ...payload })
      .then(({ data }) => {
        if (data.success) {
          callback(data);
        } else {
          cb({ success: false, message: data?.message });
        }
        function callback(data) {
          dispatch({ type: FETCH_SUCCESS });
          dispatch({
            type: TRIP_PERFORMANCE_DATA,
            payload: { rows: data.data, count: data.count },
          });
          if (cb) {
            cb(data);
          }
        }
      })
      .catch(function (error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
        dispatch({ type: TRIP_PERFORMANCE_DATA, payload: { rows: [], count: 0 } });
        console.log("Error****:", error.message);
        if (cb) {
          cb({ success: false, message: error?.response?.data?.message });
          // cb(error);
        }
      });
  };
};

export const getAllRoutesForTrips = (payload, cb) => {
  return async (dispatch) => {
    dispatch({ type: FETCH_START });
    await geoAxios
      .post("/getRoutesForTripModule", { ...payload })
      .then(({ data }) => {
        if (data.success) {
          callback(data);
        } else {
          cb({ success: false, message: data?.message });
          dispatch({
            type: TRIP_LIST_FOR_ROUTE,
            payload: { rows: [], count: 0 },
          });
        }
        function callback(data) {
          dispatch({ type: FETCH_SUCCESS });
          dispatch({
            type: TRIP_LIST_FOR_ROUTE,
            payload: { rows: data.data, count: data.count },
          });
          if (cb) {
            cb(data);
          }
        }
      })
      .catch(function (error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
        dispatch({ type: TRIP_LIST, payload: { rows: [], count: 0 } });
        console.log("Error****:", error.message);
        if (cb) {
          cb({ success: false, message: error?.response?.data?.message });
          // cb(error);
        }
      });
  };
};

export const getAllHeadersForBulkAddTrips = (payload, cb) => {
  return async (dispatch) => {
    dispatch({ type: FETCH_START });
    await geoAxios
      .post("/bulkGetHeaders", { ...payload })
      .then(({ data }) => {
        if (data.success) {
          callback(data);
        } else {
          cb && cb({ success: false, message: data?.message });
          dispatch({
            type: "BULK_ADD_TRIP_HEADERS",
            payload: [],
          });
          showToast(data?.message, true)
        }
        function callback(data) {
          dispatch({ type: FETCH_SUCCESS });
          dispatch({
            type: "BULK_ADD_TRIP_HEADERS",
            payload: data.data,
          });
          if (cb) {
            cb(data);
          }
        }
      })
      .catch(function (error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
        dispatch({ type: TRIP_LIST, payload: { rows: [], count: 0 } });
        showToast(error?.response?.data?.message, true)
        if (cb) {
          cb({ success: false, message: error?.response?.data?.message });
          // cb(error);
        }
      });
  };
};

export const checkBulkTripData = (payload, cb) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    geoAxios
      .post("/checkBulkTripData", payload)
      .then(({ data }) => {
        if (data.success) {
          dispatch({ type: FETCH_SUCCESS, payload: data.success });
          dispatch({ type: SHOW_MESSAGE, payload: data.message });
          cb && cb(data)
        } else {
          dispatch({ type: FETCH_ERROR, payload: data.message });
          cb && cb(data);
          if (data && !data?.success && !data?.data) {
            showToast(data?.message, true)
          } else if (data && !data?.success && data?.data) {

            const foundObject = data?.data.find(obj =>
              obj?.error && Object.keys(obj?.error)?.length > 0
            );
            if (foundObject) {
              showToast(foundObject?.error[Object.keys(foundObject?.error)[0]], true)
            }
          }
        }

      })
      .catch(function (error) {
        dispatch({
          type: FETCH_ERROR,
          payload: error?.response?.data?.message,
        });
        showToast(error?.response?.data?.message, true)
        cb && cb(error?.response?.data);
        console.log("error while submitting: ", error);
      });
  };
}

export const createBulkTrip = (payload, cb) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    geoAxios
      .post("/createBulkTrip", payload)
      .then(({ data }) => {
        if (data.success) {
          dispatch({ type: FETCH_SUCCESS, payload: data.success });
          dispatch({ type: SHOW_MESSAGE, payload: data.message });
          cb && cb(data)
          showToast(data?.message);
        } else {
          dispatch({ type: FETCH_ERROR, payload: data.message });
          cb && cb(data);
          showToast(data?.message, true)
        }

      })
      .catch(function (error) {
        dispatch({
          type: FETCH_ERROR,
          payload: error?.response?.data?.message,
        });
        cb && cb(error?.response?.data);
        showToast(error?.response?.data?.message, true)
        console.log("error while submitting: ", error);
      });
  };
}

export const saveTripImage = (payload, cb) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    geoAxios
      .post("/saveTripImage", payload)
      .then(({ data }) => {
        if (data.success) {
          dispatch({ type: FETCH_SUCCESS, payload: data.success });
          dispatch({ type: SHOW_MESSAGE, payload: data.message });
          cb && cb(data)
          showToast(data?.message);
        } else {
          dispatch({ type: FETCH_ERROR, payload: data.message });
          cb && cb(data);
          showToast(data?.message, true)
        }

      })
      .catch(function (error) {
        dispatch({
          type: FETCH_ERROR,
          payload: error?.response?.data?.message,
        });
        cb && cb(error?.response?.data);
        showToast(error?.response?.data?.message, true)
        console.log("error while submitting: ", error);
      });
  };
}

export const getAllFilteredGeofences = (payload, cb) => {
  return async (dispatch) => {
    dispatch({ type: FETCH_START });
    // dispatch({ type : FLUSH_BOARD });
    await geoAxios
      .post("/listGeofence", { ...payload })
      .then(({ data }) => {
        if (data.success) {
          callback(data);
        } else {
          if (cb) {
            cb(data);
          }
        }
        function callback(data) {
          dispatch({ type: FETCH_SUCCESS });
          if (cb) {
            cb(data);
          }
        }
      })
      .catch(function (error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
        console.log("Error****:", error.message);
        if (cb) {
          cb(error?.response);
        }
      });
  };
};

export const getAllFilteredRoutes = (payload, cb) => {
  return async (dispatch) => {
    dispatch({ type: FETCH_START });
    // dispatch({ type : FLUSH_BOARD });
    await geoAxios
      .post("/listRoutes", { ...payload })
      .then(({ data }) => {
        if (data.success) {
          callback(data);
        } else {
          if (cb) {
            cb(data);
          }
        }
        function callback(data) {
          dispatch({ type: FETCH_SUCCESS });
          if (cb) {
            cb(data);
          }
        }
      })
      .catch(function (error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
        console.log("Error****:", error.message);
        if (cb) {
          cb(error?.response);
        }
      });
  };
};

export const getTripETA = (payload, cb) => {
  return async (dispatch) => {
    dispatch({ type: "TRIP_ETA_LOADING", payload: true });
    await reportAxios
      .post("/getTripETA", { ...payload })
      .then(({ data }) => {
        if (!data?.success) {
          showToast(data?.message, true);
          dispatch({ type: "TRIP_ETA", payload: null });
        } else {
          dispatch({ type: "TRIP_ETA", payload: data?.data });
        }
        cb && cb(data);
        dispatch({ type: "TRIP_ETA_LOADING", payload: false });
      })
      .catch(function (error) {
        showToast(error?.response?.data?.message, true);
        cb && cb(error?.response?.data);
        dispatch({ type: "TRIP_ETA_LOADING", payload: false });
        dispatch({ type: "TRIP_ETA", payload: null });
      });
  };
};

/* --------- TRIP SCHEDULE ----------- */
export const getAllTripSchedules = (payload, cb) => {
  return async (dispatch) => {
    dispatch({ type: TRIP_SCHEDULE_LIST_LOADING, payload: true });
    dispatch(emptyTripSchduleList())
    await geoAxios
      .post("/getScheduleTrip", { ...payload })
      .then(({ data }) => {
        if (data.success) {
          callback(data);
        } else {
          if (cb) {
            cb(data);
          }
        }
        function callback(data) {
          dispatch({ type: TRIP_SCHEDULE_LIST, payload: data?.data, count: data?.count });
          if (cb) {
            cb(data);
          }
        }
        dispatch({ type: TRIP_SCHEDULE_LIST_LOADING, payload: false });
      })
      .catch(function (error) {
        console.log("Error****:", error.message);
        if (cb) {
          cb(error?.response?.data);
        }
        dispatch({ type: TRIP_SCHEDULE_LIST_LOADING, payload: false });
      });
  };
};

export const getTripScheduleCount = (payload, cb) => {
  return async (dispatch) => {
    dispatch({ type: TRIP_SCHEDULE_LIST_LOADING, payload: true });
    await geoAxios
      .post("/getTripScheduleCount", { ...payload })
      .then(({ data }) => {
        if (data.success) {
          callback(data);
        } else {
          if (cb) {
            cb(data);
          }
        }
        function callback(data) {
          dispatch({ type: "TRIP_SCHEDULE_COUNT", payload: data?.data });
          if (cb) {
            cb(data);
          }
        }
        dispatch({ type: TRIP_SCHEDULE_LIST_LOADING, payload: false });
      })
      .catch(function (error) {
        console.log("Error****:", error.message);
        if (cb) {
          cb(error?.response);
        }
        dispatch({ type: TRIP_SCHEDULE_LIST_LOADING, payload: false });
      });
  };
};

export const createScheduleTrip = (payload, cb) => {
  return async (dispatch) => {
    dispatch({ type: "CREATE_TRIP_SCHEDULE_LOADING", payload: true });
    await geoAxios
      .post("/createScheduleTrip", { ...payload })
      .then(({ data }) => {
        if (data.success) {
          callback(data);
        } else {
          showToast(data?.message, true)
          if (cb) {
            cb(data);
          }
        }
        function callback(data) {
          showToast(data?.message)
          if (cb) {
            cb(data);
          }
        }
        dispatch({ type: "CREATE_TRIP_SCHEDULE_LOADING", payload: false });
      })
      .catch(function (error) {
        console.log("Error****:", error.message);
        if (cb) {
          cb(error?.response);
        }
        showToast(error?.response?.data?.message, true)
        dispatch({ type: "CREATE_TRIP_SCHEDULE_LOADING", payload: false });
      });
  };
};

export const updateScheduleTrip = (payload, cb) => {
  return async (dispatch) => {
    dispatch({ type: "UPDATE_TRIP_SCHEDULE_LOADING", payload: true });
    await geoAxios
      .post("/updateScheduleTrip", { ...payload })
      .then(({ data }) => {
        if (data.success) {
          callback(data);
        } else {
          showToast(data?.message, true)
          if (cb) {
            cb(data);
          }
        }
        function callback(data) {
          showToast(data?.message)
          if (cb) {
            cb(data);
          }
        }
        dispatch({ type: "UPDATE_TRIP_SCHEDULE_LOADING", payload: false });
      })
      .catch(function (error) {
        console.log("Error****:", error.message);
        if (cb) {
          cb(error?.response);
        }
        showToast(error?.response?.data?.message, true)
        dispatch({ type: "UPDATE_TRIP_SCHEDULE_LOADING", payload: false });
      });
  };
};

export const getBsjCameraAlerts = (payload, cb) => {
  return async (dispatch) => {
    dispatch(emptyBsjCameraAlerts())
    dispatch({ type: FETCH_START });
    await geoAxios
      .post("/getCameraAlerts", payload)
      .then(({ data }) => {
        if (data.success) {
          callback(data);
        } else {
          cb({ success: false, message: data?.message });
        }
        function callback(data) {
          dispatch({ type: FETCH_SUCCESS });
          dispatch({
            type: BSJ_CAMERA_ALERTS,
            payload: { rows: data.data, count: data.count },
          });
          if (cb) {
            cb(data);
          }
        }
      })
      .catch(function (error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
        dispatch({ type: BSJ_CAMERA_ALERTS, payload: { rows: [], count: 0 } });
        console.log("Error****:", error.message);
        if (cb) {
          cb({ success: false, message: error?.response?.data?.message });
          // cb(error);
        }
      });
  };
};
export const emptyBsjCameraAlerts = (payload, callback) => {
  return (dispatch) => {
    dispatch({ type: EMPTY_BSJ_ALERTS });
  }
}

export const getBsjCameraVideoLists = (payload, cb) => {
  return async (dispatch) => {
    dispatch(emptyBsjCameraVideoLists())
    dispatch({ type: FETCH_START });
    await geoAxios
      .post("/camera/getPlayBackData", payload)
      .then(({ data }) => {
        if (data.success) {
          callback(data);
        } else {
          cb({ success: false, message: data?.message });
        }
        function callback(data) {
          dispatch({ type: FETCH_SUCCESS });
          dispatch({
            type: BSJ_CAMERA_VIDEO_LIST,
            payload: { rows: data.data, count: data.count },
          });
          if (cb) {
            cb(data);
          }
        }
      })
      .catch(function (error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
        dispatch({ type: BSJ_CAMERA_VIDEO_LIST, payload: { rows: [], count: 0 } });
        console.log("Error****:", error.message);
        if (cb) {
          cb({ success: false, message: error?.response?.data?.message });
          // cb(error);
        }
      });
  };
};

export const emptyBsjCameraVideoLists = (payload, callback) => {
  return (dispatch) => {
    dispatch({ type: EMPTY_BSJ_VIDEO_LIST });
  }
}

export const fetchFlightDetails = (payload, callback) => {

  const userOffset = payload?.offset;
  let adjustedTime = new Date(payload.startDateTime) - userOffset * 60 * 60 * 1000;
  const timestamp = adjustedTime;
  const date = new Date(timestamp);
  const startTime = date.toUTCString();

  const endTimeTimestamp = timestamp + 60 * 1000;
  const endDate = new Date(endTimeTimestamp);
  const endTime = endDate.toUTCString();

  delete payload?.offset;


  return (dispatch) => {
    dispatch({ type: FETCH_START });

    geoAxios.post(`getFlightDetails`, { ...payload})
      .then(({ data }) => {
        if (data.success) {
          dispatch({ type: FETCH_SUCCESS, payload: data.success });
          dispatch({ type: SHOW_MESSAGE, payload: data.message });
          dispatch({
            type: FLIGHTDETAILS,
            payload: { rows: data.data, count: data.count },
          });
        } else {
          dispatch({ type: FETCH_ERROR, payload: data.message });
        }

        if (callback) {
          callback(data);
        }
      })
      .catch(function (error) {
        dispatch({
          type: FETCH_ERROR,
          payload: error?.response?.data?.message,
        });
        if (callback) {
          callback(error?.response?.data);
        }
        showToast(error?.response?.data?.message, true);
        console.log("Error****:", error.message);
      });
  };
};


