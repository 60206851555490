import React from 'react'
import { TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Paper, Checkbox, TablePagination, Grid } from '@mui/material'
// import theme, { textClasses } from '../../../static/styles/theme';
import { DataNotFound } from '../../Components/Utils';
import EmptyPage from '../EmptyPage';


const TemplatesTable = ({ header, rows, totalRows, page, limit, setPage, setLimit, themeType }) => {
    const {
        theme,
        buttonClasses,
        cardClasses,
        inputClasses,
        textClasses,
        tableClasses,
    } = themeType;
    return (
        <>
            {rows.length ?
                <>

                    <table style={{ width: "100%" }}>
                        <thead
                            style={{
                                backgroundColor: themeType.default.themeGray,
                                position: "sticky",
                                top: "-1px",
                                zIndex: 1,
                            }}
                        >
                            <tr className='roundedRow'>
                                {
                                    header.slice(0, 2).map((head, i) => {
                                        return (
                                            <td
                                                style={{
                                                    ...textClasses.boldText,
                                                    ...tableClasses.tableHeader,
                                                    textAlign: "center",
                                                    padding: '3.5px'
                                                }}
                                            >{head}</td>
                                        )
                                    })
                                }
                                {
                                    header.slice(2, header.length).map((head, i) => {
                                        return (
                                            head !== null ?
                                                <td style={{
                                                    ...textClasses.boldText,
                                                    ...tableClasses.tableHeader,
                                                    textAlign: "center",
                                                    padding: '3.5px'
                                                }}>
                                                    {head}</td>
                                                : ''
                                        )
                                    })
                                }
                            </tr>
                        </thead>
                        <tbody>
                            {
                                <>
                                    {
                                        rows.map((row, i) => (
                                            <tr
                                                key={i}
                                                style={{ borderBottom: "1px solid #0000002B" }}
                                            >
                                                {
                                                    row.map((col, j) => {
                                                        return (
                                                            // <td component="th" scope="row" style={{...textClasses.normalText, textAlign: "left", overflowWrap: "break-word", padding: "5px"}}>
                                                            <td style={{ textAlign: "center", ...textClasses.normalText, ...tableClasses.tableBodyClass, ...tableClasses.tableBodyClass, textAlign: "center", padding: '5px' }}>
                                                                {col}
                                                            </td>
                                                        )
                                                    })
                                                }

                                            </tr>
                                        ))
                                    }

                                </>
                            }
                        </tbody>
                    </table>
                        <TablePagination
                            component="div"
                            count={totalRows}
                            page={page}
                            onPageChange={(e, newPage) => setPage(newPage)}
                            rowsPerPage={limit}
                            onRowsPerPageChange={(e) => { setLimit(e.target.value); setPage(0) }}
                            sx={{
                                ...tableClasses.tableHeader,
                                ...tableClasses.tablePagination,
                                display: "flex",
                                justifyContent: "center",
                                position: "sticky",
                                bottom: "0px",
                                alignItems: "center",
                                mt: "0em",
                                "& .MuiTablePagination-selectLabel ": {
                                    margin: "0",
                                },
                                "& .MuiTablePagination-displayedRows": {
                                    margin: "0",
                                },
                            }}
                        />

                </>


                :
                <Grid container justifyContent={"center"} alignItems="center">
                    <EmptyPage body="No Template found.." />
                </Grid>

            }
        </>
    );
}

export default TemplatesTable;
