import { Button, FormControl, Grid, Paper, capitalize } from "@mui/material";
import React from "react";
import { Controller, useForm, useWatch } from "react-hook-form";
import {
  downloadFile,
  getAllDeviceModels,
  getObj,
} from "../../../../redux/actions";
import { debounce } from "lodash";
import CustomSelect from "../../../../components/FormUI/CustomSelect";
import { Download, Delete, Visibility } from "@mui/icons-material";
import XLSX from "sheetjs-style";
import { read, utils } from "xlsx";
import { saveAs } from "file-saver";
import { ExcelRenderer, OutTable } from "react-excel-renderer";
import AdminsSection from "./AdminsSection";
import config from "../../../../services/config";
import { memo } from "react";
import { showToast } from "../../../../services/functions";

const SAExcelForm = memo(({ otherFormProps, adminSectionProps }) => {
  const {
    selectedFile,
    setSelectedFile,
    showTablePreview,
    setShowTablePreview,
    sampleFile,
    setSampleFile,
    headers,
    setHeaders,
    rowData,
    setRowData,
    dispatch,
    handleSubmit,
    deviceTypeEntered,
    deviceModelInfo,
    themeType,
    allPorts,
    allDeviceModels,
    handleCreateDeviceInBulk,
    handleCancelButton,
    operatorOptions,
    allDeviceCategoryNames,
    handleSearchDeviceModels,
    handleSearchDeviceType,
    handleSearchPortGroup,
  } = otherFormProps;

  const {
    errors,
    control,
    loading,
    setLoading,
    selectStylesOverride,
    setValue,
    searchFields,
    setSearchFields,
  } = adminSectionProps;

  const {
    buttonClasses,
    inputClasses,
    textClasses,
    dividerClass,
    tableClasses,
  } = themeType;

  const [modelSelected, setModelSelected] = React.useState(undefined)

  //-----handlers---------
  const downloadSample = async () => {
    const fileUrl = `${sampleFile}`;
    // dispatch(downloadFile(fileUrl));

    dispatch(
      getObj({ listObjects: [sampleFile] }, (res) => {
        if (res?.data && res?.data?.length > 0) {
          const base64Content = res?.data[0]?.base64?.split(";base64,")[1];
          const binaryString = atob(base64Content);

          const byteArray = new Uint8Array(binaryString.length);
          for (let i = 0; i < binaryString.length; i++) {
            byteArray[i] = binaryString.charCodeAt(i);
          }

          const blob = new Blob([byteArray], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });

          // Save Blob as Excel file
          saveAs(blob, `${capitalize(deviceTypeEntered ? deviceTypeEntered: "")}-${modelSelected}.xlsx`);
        }
      })
    );

  };

  const handleDeleteFile = () => {
    setSelectedFile(null);
    setHeaders([]);
    setRowData([]);
    showTablePreview && setShowTablePreview(false);
  };

  const handleSaveDetails = (data) => {
    let tableInfo = [];
    if (rowData?.length > 0 && headers?.length > 0) {
      tableInfo = rowData.map((row) => {
        const rowTemp = {};
        headers.forEach((header, index) => {
          rowTemp[header] = row[index];
        });
        return rowTemp;
      });
    }
    const payload = {
      deviceModel: data?.deviceModel,
      deviceType: data?.deviceType,
      groupType: data?.groupType,
      adminId: data?.admin,
      networkAdminsIds: data?.networkAdmins,
      excelData: tableInfo,
    };
    handleCreateDeviceInBulk(payload);
  };

  const handleUpload = (fileData) => {
    const file = fileData;
    if (file) {
      const reader = new FileReader();

      reader.onload = (e) => {
        const workbook = XLSX.read(e.target.result, { type: "binary" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const parsedData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

        if (parsedData.length > 0) {
          const filledArr1 = parsedData?.slice(1).map((subArray) => {
            const filledSubArray = [];
            for (let i = 0; i < subArray.length; i++) {
              if (typeof subArray[i] === "undefined") {
                filledSubArray.push(null);
              } else {
                filledSubArray.push(subArray[i]);
              }
            }

            return filledSubArray;
          });
          setHeaders(parsedData[0]);
          setRowData(filledArr1);
        }
      };

      reader.readAsBinaryString(file);
      // ExcelRenderer(file, (err, resp) => {
      //   if (err) {
      //     console.log(err);
      //   } else {
      //     setHeaders(resp.rows[0]);
      //     setRowData(resp.rows?.slice(1));
      //   }
      // });
    }
  };

  //------effects--------

  const verify = React.useCallback(
    debounce((name) => {
      dispatch(
        getAllDeviceModels({
          filter: {
            category: name,
          },
          selection: ["info", "deviceModel", "lynkDeviceModel", "samplefile"],
        })
      )?.then((res) => {});
    }, 200),
    []
  );

  React.useEffect(() => {
    if (deviceTypeEntered && deviceTypeEntered !== "") {
      verify(deviceTypeEntered);
    }
  }, [deviceTypeEntered, verify]);

  return (
    <>
      <Grid container spacing={2} xs={12}>
        {/* device type */}
        <Grid item md={3}>
          <Controller
            name="deviceType"
            control={control}
            rules={{
              required: {
                value: true,
                message: "Device Type is required",
              },
            }}
            render={({ field: { onChange, value, name } }) => {
              return (
                <CustomSelect
                  data-testid="device-type-select"
                  selectStylesOverride={selectStylesOverride}
                  value={value}
                  searchValue={searchFields?.deviceType}
                  onSearch={handleSearchDeviceType}
                  onInputChange={(e) => {
                    if (e?.type === "change") {
                      setSearchFields((prev) => ({
                        ...prev,
                        deviceType: e?.target?.value?.trim(),
                      }));
                    }
                  }}
                  onChange={(e, newVal) => {
                    onChange(newVal?.category);
                    setValue("deviceType", newVal?.category);
                  }}
                  name={name}
                  filterOptions={(options) => options}
                  label="Device Type"
                  errors={errors}
                  getOptionLabel={(option) => {
                    if (typeof option === "string") {
                      return allDeviceCategoryNames?.find(
                        (_) => _?.category == value
                      )?.label;
                    } else {
                      return option?.label;
                    }
                  }}
                  labelKey="label"
                  options={allDeviceCategoryNames && allDeviceCategoryNames}
                />
              );
            }}
          />
        </Grid>

        {/* Device model */}
        <Grid item md={3}>
          <Controller
            name="deviceModel"
            defaultValue={undefined}
            control={control}
            rules={{
              required: {
                value: true,
                message: "Device Model is required",
              },
            }}
            render={({ field: { onChange, value, name } }) => {
              return (
                <CustomSelect
                  data-testid="device-model-select"
                  selectStylesOverride={selectStylesOverride}
                  value={value}
                  disabled={!deviceTypeEntered}
                  filterOptions={(options) => options}
                  onChange={(e, newVal) => {
                    onChange(newVal?.deviceModel);
                    //selectedDevice ? newVal : newVal?.deviceModel
                    let onlyTruePermissions =
                      newVal?.info?.length > 0 &&
                      newVal?.info?.filter((item) => item?.visibility == true);
                    setValue("deviceModelInfo", onlyTruePermissions);
                    setSampleFile(newVal?.samplefile);
                    setModelSelected(newVal?.lynkDeviceModel);
                  }}
                  name={name}
                  label="Device Model"
                  searchValue={searchFields?.deviceModel}
                  onSearch={handleSearchDeviceModels}
                  onInputChange={(e) => {
                    if (e?.type === "change") {
                      setSearchFields((prev) => ({
                        ...prev,
                        deviceModel: e?.target?.value?.trim(),
                      }));
                    }
                  }}
                  errors={errors}
                  getOptionLabel={(option) => {
                    if (typeof option === "string") {
                      return allDeviceModels?.find(
                        (_) => _?.deviceModel == value
                      )?.lynkDeviceModel;
                    } else {
                      return option?.lynkDeviceModel;
                    }
                  }}
                  labelKey="lynkDeviceModel"
                  options={allDeviceModels && allDeviceModels}
                />
              );
            }}
          />
        </Grid>

        {/* Port Group */}
        <Grid item md={3}>
          <Controller
            name="groupType"
            control={control}
            rules={{
              required: {
                value: true,
                message: "Post Group Type is required",
              },
            }}
            render={({ field: { onChange, value, name } }) => {
              return (
                <CustomSelect
                  selectStylesOverride={selectStylesOverride}
                  data-testid="port-group-select"
                  value={value}
                  onChange={(e, newVal) => {
                    onChange(newVal?.value);
                  }}
                  name={name}
                  searchValue={searchFields?.portGroup}
                  onSearch={handleSearchPortGroup}
                  onInputChange={(e) => {
                    if (e?.type === "change") {
                      setSearchFields((prev) => ({
                        ...prev,
                        portGroup: e?.target?.value?.trim(),
                      }));
                    }
                  }}
                  label="Port Group Type"
                  errors={errors}
                  getOptionLabel={(option) => {
                    if (typeof option === "string") {
                      return allPorts?.find((_) => _?.value == value)?.label;
                    } else {
                      return option?.label;
                    }
                  }}
                  options={allPorts && allPorts}
                />
              );
            }}
          />
        </Grid>

        <Grid item md={3} display="flex">
          <Button
            variant="outlined"
            sx={{
              ...buttonClasses?.lynkitBlackFill,
              minHeight: "36px",
              minWidth: "140px",
              marginLeft: "auto",
            }}
            startIcon={<Download />}
            onClick={downloadSample}
            disabled={!deviceModelInfo || deviceModelInfo?.length === 0}
          >
            Sample Download
          </Button>
        </Grid>
      </Grid>
      {/* upload file options ---- */}
      <Grid xs={12} mt={2}>
        <Grid item display="flex" alignItems="center" gap={2}>
          <FormControl
            spacing={1}
            sx={{ marginRight: "5px", marginTop: "2px" }}
          >
            <input
              type="file"
              name="upload"
              id="upload"
              className="RF_fileupload-input"
              onChange={(e) => {
                setSelectedFile(e?.target?.files[0]);
                handleUpload(e?.target?.files[0]);
              }}
              //ref={fileInputRef}
              //key={shellState}
            />
          </FormControl>
          <Button
            variant="outlined"
            spacing={0}
            className="RF_outline-btn"
            sx={{ marginRight: "5px", borderRadius: "8px" }}
            disabled={selectedFile == null}
            onClick={handleDeleteFile}
          >
            <Delete />
          </Button>
          <Button
            variant="outlined"
            sx={{
              ...buttonClasses?.lynkitOrangeFill,
              minHeight: "36px",
              minWidth: "140px",
              marginLeft: "10px",
            }}
            startIcon={<Visibility />}
            disabled={selectedFile === null}
            onClick={() => {
              setShowTablePreview(true);
            }}
          >
            Preview
          </Button>
        </Grid>
      </Grid>

      {/* preview table area ----- */}
      {showTablePreview && headers?.length > 0 && rowData?.length > 0 && (
        <Grid
          xs={12}
          my={3}
          sx={{
            maxHeight: 200,
            overflowY: "scroll",
          }}
        >
          <Paper
            className="muitable"
            sx={{
              maxWidth: "100%",
              overflowX: "auto",
              background: tableClasses.dark.backgroundColor,
            }}
          >
            <table style={{ width: "100%", ...tableClasses.table }}>
              <thead style={{ backgroundColor: themeType?.default?.themeGray }}>
                <tr>
                  {headers?.map((header, index) => (
                    <th
                      key={index}
                      style={{
                        ...textClasses.boldText,
                        paddingLeft: "6px",
                        borderRight: "1px solid #0000002B",
                      }}
                    >
                      {header}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {rowData?.map((row, rowIndex) => (
                  <tr
                    key={rowIndex}
                    style={{ borderBottom: "1px solid #0000002B" }}
                  >
                    {row.map((cell, cellIndex) => (
                      <td
                        key={cellIndex}
                        style={{
                          padding: "8px 8px",
                          borderRight: "1px solid #0000002B",
                          ...textClasses.normalText,
                        }}
                      >
                        {cell === null ? " " : cell?.toString()}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </Paper>
        </Grid>
      )}

      <AdminsSection allProps={adminSectionProps} />

      <Grid
        item
        xs={12}
        display="flex"
        justifyContent="center"
        alignItems="center"
        gap="7px"
      >
        <Button
          variant="outlined"
          sx={{
            ...buttonClasses?.lynkitBlackFill,
            minHeight: "36px",
            minWidth: "140px",
          }}
          onClick={handleCancelButton}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          sx={{
            ...buttonClasses?.lynkitOrangeFill,
            minHeight: "36px",
            minWidth: "140px",
          }}
          disabled={!selectedFile}
          onClick={handleSubmit(handleSaveDetails)}
        >
          Create
        </Button>
      </Grid>
    </>
  );
});

export default SAExcelForm;
