import {
  FETCH_SETTINGS,
  FETCH_DYNAMIC_KEYS_FOR_TEMPLATES,
  ORGANISATION_IMAGES,
  CLEAR_UPLOADED_IMAGES,
  CLEAR_SETTINGS,
} from "../types";

const intialState = {
  settingsData: null,
  dynamicKeysForTemplates: [],
  organisationImages: [],
  accountLinkageLoading: false,
  accountLinkageListing: [],
  accountLinkageCount: 0,
  userAccountDetails: null,
  accountLinkageFlag: null,
};

export default (state = intialState, { type, payload }) => {
  switch (type) {
    case FETCH_SETTINGS:
      return { ...state, settingsData: payload };

    case FETCH_DYNAMIC_KEYS_FOR_TEMPLATES:
      return { ...state, dynamicKeysForTemplates: payload };
    case ORGANISATION_IMAGES:
      return { ...state, organisationImages: payload };
    case CLEAR_UPLOADED_IMAGES:
      return { ...state, organisationImages: [] };
    case CLEAR_SETTINGS:
      return { ...state, settingsData: [] };
    case "ACCOUNT_LINKAGE_LIST_FLAG":
      return { ...state, accountLinkageFlag: payload };
    case "ACCOUNT_LINKAGE_LIST_LOADING":
      return { ...state, accountLinkageLoading: payload };
    case "ACCOUNT_LINKAGE_LIST":
      return { ...state, accountLinkageListing: payload };
    case "ACCOUNT_LINKAGE_COUNT":
      return { ...state, accountLinkageCount: payload };
    case "USER_ACCOUNT_DETAILS":
      return { ...state, userAccountDetails: payload };

    default:
      return state;
  }
};

export const setAccountLinkageFlag = (payload) => ({
    type: "ACCOUNT_LINKAGE_LIST_FLAG", payload: payload
});

export const flushAccountLinkageList = () => ({
    type: "ACCOUNT_LINKAGE_LIST", payload: []
});

export const flushUserAccountDetails = () => ({
    type: "USER_ACCOUNT_DETAILS", payload: null
});
