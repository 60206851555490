import {
  DEVICES_LIST,
  FETCH_START,
  FETCH_SUCCESS,
  FETCH_ERROR,
  DEVICE_MODELS,
  LIST_DEVICE_CATEGORY_NAMES,
  LIST_ALERT_SETTINGS,
  DEVICE_MODEL_DETAILS,
  DEVICE_MODEL_SETTINGS,
  DEVICE_PERMISSIONS,
  GET_PORTS,
  LIST_DEVICE_PERMISSION,
  GET_DEVICE_DETAILS,
  SET_FLAG,
  DEVICE_TEMPLATE,
  DEVICE_MODEL_STATUS_COUNT,
  DEVICE_STATUS,
  FETCH_ADDRESS,
  CLEAR_DEVICE_TEMPLATE,
  FETCH_DEVICES_FLAG,
  HISTORY_DATA,
  ADMINS_LIST,
  CAMERA_TOKEN,
  CAMERA_ALERTS,
  CAMERA_STATUS,
  CAMERA_PLAYBACKS,
  SHOW_MESSAGE,
  FETCH_ALERTS_FLAG,
  DEVICE_TRACK_LIST,
  EMPTY_DEVICE_TRACK_LISTING,
  DEVICE_ALERT_LIST,
  EMPTY_DEVICE_ALERT_LISTING,
  DEVICE_MARKERS,
  EMPTY_DEVICE_DETAILS,
  WEB_COMMAND_NAMES,
  SMS_COMMAND_NAMES,
  GET_COMMANDS
} from "../types";

import axios, { commonAxios, devicesAxios, geoAxios, lynctracAxios, reportAxios } from "../../services/Api";
import { showToast } from "../../services/functions";
import axiosMain from "axios";
import { locationUrl } from "../../services/config";

/**
 * @description : To fetch all devices
 *
 * @param {*} payload : contains query, pageNumber and limit
 * @param {*} cb : callback function
 * @param {*} errorCb : callback function for error
 *
 * @returns nothing
 */
export const getAllDevices = (payload, cb, errorCb) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    devicesAxios
      .post("/listDevice", payload)
      .then(({ data }) => {
        if (data.success) {
          callback();
        } else {
          dispatch({ type: FETCH_ERROR, payload: data.message });
          dispatch({ type: DEVICES_LIST, payload: { rows: [], count: 0 } });
          errorCb && errorCb();
        }
        function callback() {
          dispatch({ type: FETCH_SUCCESS });
          dispatch({
            type: DEVICES_LIST,
            payload: { rows: data?.data, count: data?.count },
          });
          if (cb) {
            cb(data?.data);
          }
        }
      })
      .catch(function (error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
        errorCb && errorCb();
        console.log("Error****:", error.message);
      });
  };
};

/**
 * @description To get history track of a device according to the filters.
 * @param {object} payload {id[clientId], fromDate, toDate, type[deviceType], userType, userId, socketId, overspeed, overstop}
 * @callback requestCallback
 * @param {requestCallback} cb
 */
export const getHistoryTrack = (payload, cb) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    geoAxios
      .get(
        `historyTripMap?id=${payload?.id}&fromDate=${
          payload?.fromDate
        }&toDate=${payload?.toDate ?? ""}&type=${
          payload?.type ?? ""
        }&userType=${payload?.userType ?? ""}&userId=${
          payload?.userId
        }&socketId=${payload?.socketId}&overspeed=${
          payload?.overspeed
        }&overstop=${payload?.overstop}&client=`
      )
      .then(({ data }) => {
        if (data.success) {
          callback();
        } else {
          dispatch({ type: FETCH_ERROR, payload: data.message });
          dispatch({ type: HISTORY_DATA, payload: null });
          cb && cb(null);
          showToast(data?.message, true);
        }
        function callback() {
          dispatch({ type: FETCH_SUCCESS });
          dispatch({
            type: HISTORY_DATA,
            payload: data?.data,
          });
          if (cb) {
            cb(data);
          }
        }
      })
      .catch(function (error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
        cb && cb(null);
      });
  };
};

export const getTrackData = (payload, cb) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    geoAxios
      .get(`/getGeoJsonData/?Key=geojson/historyTrack/${payload?.file}`)
      .then(({ data }) => {
        if (data.features) {
          callback();
        } else {
          dispatch({ type: FETCH_ERROR, payload: data.message });
          // dispatch({ type: HISTORY_DATA, payload: null });
          cb && cb(null);
        }
        function callback() {
          dispatch({ type: FETCH_SUCCESS });
          if (cb) {
            cb(data);
          }
        }
      })
      .catch(function (error) {
        console.log("error: ", error);
        dispatch({ type: FETCH_ERROR, payload: error.message });
        cb && cb(null);
      });
  };
};

export const getMarkerForDevice = (payload, cb) => {
  return async (dispatch) => {
    dispatch({ type: FETCH_START });
    await geoAxios
      .post("/getMarkerForMapDeviceWise", { ...payload })
      .then(({ data }) => {
        if (data.success) {
          callback(data);
        } else {
          cb({ success: false, message: data?.message });
          dispatch({
            type: DEVICE_MARKERS,
            payload: { rows: [], count: 0 },
          });
        }
        function callback(data) {
          dispatch({ type: FETCH_SUCCESS });
          dispatch({
            type: DEVICE_MARKERS,
            payload: { rows: data.data, count: data.count },
          });
          if (cb) {
            cb(data);
          }
        }
      })
      .catch(function (error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
        dispatch({ type: DEVICE_MARKERS, payload: { rows: [], count: 0 } });
        console.log("Error****:", error.message);
        if (cb) {
          cb({ success: false, message: error?.response?.data?.message });
          // cb(error);
        }
      });
  };
}; 

/**
 * @description : fetch all device models
 * @returns nothing
 */
export const getAllDeviceModels = (payload, cb, errorCb) => {
  return async (dispatch) => {
    try {
      dispatch({ type: FETCH_START });

      const response = await devicesAxios.post("/listDeviceCategory", payload);
      const data = response.data;

      if (data?.success) {
        dispatch({ type: FETCH_SUCCESS });
        dispatch({
          type: DEVICE_MODELS,
          payload: { rows: data?.data, count: data?.count },
        });
        if (cb) {
          cb();
        }
      } else {
        dispatch({ type: FETCH_ERROR, payload: data.message });
        dispatch({ type: DEVICE_MODELS, payload: { rows: [], count: 0 } });
        if (errorCb) {
          errorCb();
        }
      }
    } catch (error) {
      dispatch({ type: FETCH_ERROR, payload: error.message });
      dispatch({ type: DEVICE_MODELS, payload: { rows: [], count: 0 } });
      //showToast(error.response?.data?.message, true);
      if (errorCb) {
        errorCb();
      }
    }
  };
};

/**
 * @description : get all device model settings (permissions)
 * @returns nothing
 */
export const getAllDeviceModelSettings = (cb) => {
  return async (dispatch) => {
    try {
      dispatch({ type: FETCH_START });

      const response = await devicesAxios.post("/listModelSetting");
      const data = response.data;

      if (data?.success) {
        dispatch({ type: FETCH_SUCCESS });
        dispatch({
          type: DEVICE_MODEL_SETTINGS,
          payload: data?.data,
        });
        if (cb) {
          cb();
        }
      } else {
        dispatch({ type: FETCH_ERROR, payload: data.message });
        dispatch({ type: DEVICE_MODEL_SETTINGS, payload: [] });
        if (cb) {
          cb();
        }
      }
    } catch (error) {
      dispatch({ type: FETCH_ERROR, payload: error.message });
      console.log("Error****:", error.message);
      if (cb) {
        cb();
      }
    }
  };
};

export const getDeviceDetails = (payload, cb) => {
  return async (dispatch) => {
    try {
      dispatch({ type: "DEVICE_DETAILS_LOADING", payload: true });
      const response = await devicesAxios.post("/detailsDevice", payload);
      const data = response.data;

      if (data?.success) {
        dispatch({ type: GET_DEVICE_DETAILS, payload: data?.data });
        cb && cb(data);
      } else {
        dispatch({ type: GET_DEVICE_DETAILS, payload: null });
        showToast(data?.message, true);
        cb && cb();
      }
      dispatch({ type: "DEVICE_DETAILS_LOADING", payload: false });
    } catch (error) {
      dispatch({ type: "DEVICE_DETAILS_LOADING", payload: false });
      dispatch({ type: GET_DEVICE_DETAILS, payload: null });
      showToast(error?.response?.data?.message, true);
      cb && cb();
      console.log("Error****:", error.message);
    }
  };
};

export const getDeviceModelDetails = (payload, cb) => {
  return async (dispatch) => {
    try {
      dispatch({ type: FETCH_START });

      const response = await devicesAxios.post(
        "/detailsDeviceCategory",
        payload
      );
      const data = response.data;

      if (data?.success) {
        dispatch({ type: FETCH_SUCCESS });
        dispatch({ type: DEVICE_MODEL_DETAILS, payload: data?.data });
      } else {
        dispatch({ type: FETCH_ERROR, payload: data.message });
        if (cb) {
          cb();
        }
      }
    } catch (error) {
      dispatch({ type: FETCH_ERROR, payload: error.message });
      console.log("Error****:", error.message);
      if (cb) {
        cb();
      }
    }
  };
};

export const getAllDeviceCategoryNames = (payload, cb) => {
  return async (dispatch) => {
    try {
      dispatch({ type: FETCH_START });
      //role based category types ---
      const response = await devicesAxios.post(
        "/listDeviceCategoryType",
        payload
      );
      const data = response.data;

      if (data?.success) {
        dispatch({ type: FETCH_SUCCESS });
        dispatch({ type: LIST_DEVICE_CATEGORY_NAMES, payload: data?.data });
      } else {
        dispatch({ type: FETCH_ERROR, payload: data.message });
      }
      if (cb) {
        cb(data);
      }
    } catch (error) {
      dispatch({ type: FETCH_ERROR, payload: error.message });
      console.log("Error****:", error.message);
      if (cb) {
        cb({success: false, message: error?.response?.data?.message});
      }
    }
  };
};

export const getWebCommandLogs = (payload, cb) => {
  return async (dispatch) => {
    try {
      dispatch({ type: FETCH_START });
      const response = await devicesAxios.post(
        "/getCommand ",
        payload
      );
      const data = response.data;

      if (data?.success) {
        dispatch({ type: FETCH_SUCCESS });
        dispatch({ type: WEB_COMMAND_NAMES, payload: data?.data });
      } else {
        dispatch({ type: FETCH_ERROR, payload: data.message });
      }
      if (cb) {
        cb(data);
      }
    } catch (error) {
      dispatch({ type: FETCH_ERROR, payload: error.message });
      console.log("Error****:", error.message);
      if (cb) {
        cb({success: false, message: error?.response?.data?.message});
      }
    }
  };
};

export const getSmsCommandLogs = (payload, cb) => {
  return async (dispatch) => {
    try {
      dispatch({ type: FETCH_START });
      const response = await devicesAxios.post(
        "/smsCommandLogs ",
        payload
      );
      const data = response.data;

      if (data?.success) {
        dispatch({ type: FETCH_SUCCESS });
        dispatch({ type: SMS_COMMAND_NAMES, payload: data?.data });
      } else {
        dispatch({ type: FETCH_ERROR, payload: data.message });
      }
      if (cb) {
        cb(data);
      }
    } catch (error) {
      dispatch({ type: FETCH_ERROR, payload: error.message });
      console.log("Error****:", error.message);
      if (cb) {
        cb({success: false, message: error?.response?.data?.message});
      }
    }
  };
};



export const getAllAlertSettings = (payload, cb) => {
  return async (dispatch) => {
    try {
      dispatch({ type: FETCH_START });

      const response = await devicesAxios.post("/listSettingsAlert", payload);
      const data = response.data;

      if (data?.success) {
        dispatch({ type: FETCH_SUCCESS });
        dispatch({ type: LIST_ALERT_SETTINGS, payload: data?.data });
      } else {
        dispatch({ type: FETCH_ERROR, payload: data.message });
        if (cb) {
          cb();
        }
      }
    } catch (error) {
      dispatch({ type: FETCH_ERROR, payload: error.message });
      console.log("Error****:", error.message);
      if (cb) {
        cb();
      }
    }
  };
};

export const getAllDevicePermissions = (cb) => {
  return async (dispatch) => {
    try {
      dispatch({ type: FETCH_START });

      const response = await devicesAxios.post("/listDevicePermission");
      const data = response.data;

      if (data?.success) {
        dispatch({ type: FETCH_SUCCESS });
        dispatch({ type: LIST_DEVICE_PERMISSION, payload: data?.data });
      } else {
        dispatch({ type: FETCH_ERROR, payload: data.message });
        if (cb) {
          cb();
        }
      }
    } catch (error) {
      dispatch({ type: FETCH_ERROR, payload: error.message });
      console.log("Error****:", error.message);
      if (cb) {
        cb();
      }
    }
  };
};

export const getDeviceModelStatusCounts = (cb) => {
  return async (dispatch) => {
    try {
      dispatch({ type: FETCH_START });

      const response = await devicesAxios.post("/statCategoryStatus");
      const data = response.data;

      if (data?.success) {
        dispatch({ type: FETCH_SUCCESS });
        dispatch({ type: DEVICE_MODEL_STATUS_COUNT, payload: data?.data });
      } else {
        dispatch({ type: FETCH_ERROR, payload: data.message });
        if (cb) {
          cb();
        }
      }
    } catch (error) {
      dispatch({ type: FETCH_ERROR, payload: error.message });
      console.log("Error****:", error.message);
      if (cb) {
        cb();
      }
    }
  };
};

export const updateNetworkAdminACL = (payload, cb) => {
  return async (dispatch) => {
    try {
      dispatch({ type: FETCH_START });

      const response = await devicesAxios.post(
        "/updateNetworkAdminACL",
        payload
      );
      const data = response.data;

      if (data?.success) {
        dispatch({ type: FETCH_SUCCESS });
        showToast(data.message);
        if (cb) {
          cb(data?.success);
        }
      } else {
        dispatch({ type: FETCH_ERROR, payload: data.message });
        cb && cb(false);
      }
    } catch (error) {
      dispatch({ type: FETCH_ERROR, payload: error.message });
      showToast(error?.response?.data?.message, true);
      cb && cb(false);
      console.log("Error****:", error.message);
    }
  };
};

export const allocateDevice = (payload, cb = () => {}) => {
  return async (dispatch) => {
    try {
      dispatch({ type: "ALLOCATE_DEVICE_LOADING", payload: true });
      const response = await devicesAxios.post("/allocateDevice", payload);
      const data = response.data;
      if (data?.success) {
        dispatch({ type: FETCH_SUCCESS });
        cb && cb(true);
      } else {
        cb && cb(null);
        dispatch({ type: FETCH_ERROR, payload: data.message });
        showToast(data?.message, true);
      }
      dispatch({ type: "ALLOCATE_DEVICE_LOADING", payload: false });
    } catch (error) {
      dispatch({ type: FETCH_ERROR, payload: error.message });
      showToast(error?.response?.data?.message, true);
      cb && cb(null);
      dispatch({ type: "ALLOCATE_DEVICE_LOADING", payload: false });
    }
  };
};

export const addNewDevice = (payload, cb = () => {}) => {
  return async (dispatch) => {
    try {
      dispatch({ type: FETCH_START });

      const response = await devicesAxios.post("/addDevice", payload);
      const data = response.data;
      if (data?.success) {
        dispatch({ type: FETCH_SUCCESS });
        cb && cb(true);
        showToast(data?.message);
      } else {
        dispatch({ type: FETCH_ERROR, payload: data.message });
        cb && cb(null);
      }
    } catch (error) {
      dispatch({ type: FETCH_ERROR, payload: error.message });
      showToast(error?.response?.data?.message, true);
      console.log("Error****:", error);
      cb && cb(null);
    }
  };
};

export const addDeviceAdditionalDetails = (payload, cb = () => {}) => {
  return async (dispatch) => {
    try {
      dispatch({ type: FETCH_START });

      const response = await devicesAxios.post(
        "/add/additionalDetails",
        payload
      );
      const data = response.data;
      if (data?.success) {
        dispatch({ type: FETCH_SUCCESS });
        cb && cb();
        showToast(data?.message);
      } else {
        dispatch({ type: FETCH_ERROR, payload: data.message });
      }
    } catch (error) {
      dispatch({ type: FETCH_ERROR, payload: error.message });
      showToast(error?.response?.data?.message, true);
      console.log("Error****:", error);
    }
  };
};

export const addNewDeviceModelInBulk = (payload, cb = () => {}) => {
  return async (dispatch) => {
    try {
      dispatch({ type: FETCH_START });

      const response = await devicesAxios.post(
        "/bulkAddDeviceFromExcel",
        payload
      );
      const data = response.data;
      if (data?.success) {
        dispatch({ type: FETCH_SUCCESS });
        cb && cb();
        showToast(data?.message);
      } else {
        showToast(data?.message,true);
        dispatch({ type: FETCH_ERROR, payload: data.message });
      }
    } catch (error) {
      dispatch({ type: FETCH_ERROR, payload: error.message });
      showToast(error?.response?.data?.message, true);
      console.log("Error****:", error.message);
    }
  };
};

export const updateDeviceStatus = (payload, cb = () => {}) => {
  return async (dispatch) => {
    try {
      dispatch({ type: FETCH_START });
      const response = await devicesAxios.post("/updateDeviceStatus", payload);
      const data = response.data;
      if (data?.success) {
        dispatch({ type: FETCH_SUCCESS });
        cb && cb(data);
        showToast(data?.message);
      } else {
        dispatch({ type: FETCH_ERROR, payload: data.message });
        cb && cb(null);
      }
    } catch (error) {
      dispatch({ type: FETCH_ERROR, payload: error?.response?.data?.message });
      showToast(error?.response?.data?.message, true);
      cb && cb(null);
      console.log("Error****:", error?.response?.data);
    }
  };
};

export const addNewDeviceModel = (payload, cb = () => {}) => {
  return async (dispatch) => {
    try {
      dispatch({ type: FETCH_START });

      const response = await devicesAxios.post("/addDeviceCategory", payload);
      const data = response.data;
      if (data?.success) {
        dispatch({ type: FETCH_SUCCESS });
        cb && cb(true);
        showToast(data?.message);
      } else {
        dispatch({ type: FETCH_ERROR, payload: data.message });
        cb && cb(false);
      }
    } catch (error) {
      dispatch({ type: FETCH_ERROR, payload: error.message });
      showToast(error?.response?.data?.message, true);
      cb && cb(false);
    }
  };
};

/**
 * @description : Action creator to update or delete a device model. (To delete - send isActive field as false)
 *
 * @param {*} payload
 * @param {*} isDelete - if true, it will show toast message for deletion.
 * @param {*} cb - callback
 * @returns
 */
export const updateDeviceModel = (payload, isDelete, cb = () => {}) => {
  return async (dispatch) => {
    try {
      dispatch({ type: FETCH_START });
      const response = await devicesAxios.post(
        "/updateDeviceCategory",
        payload
      );
      const data = response.data;
      if (data?.success) {
        dispatch({ type: FETCH_SUCCESS });
        cb && cb(true);
        showToast(
          isDelete ? "Device model successfully deleted!" : data?.message
        );
      } else {
        cb && cb(false);
        dispatch({ type: FETCH_ERROR, payload: data.message });
      }
    } catch (error) {
      dispatch({ type: FETCH_ERROR, payload: error.message });
      showToast(error?.response?.data?.message, true);
      console.log("Error****:", error.message);
      cb && cb(false);
    }
  };
};

export const bulkUpdateDeviceModelStatus = (payload, cb = () => {}) => {
  return async (dispatch) => {
    try {
      dispatch({ type: FETCH_START });
      const response = await devicesAxios.post(
        "/updateCategoryStatus",
        payload
      );
      const data = response.data;
      if (data?.success) {
        dispatch({ type: FETCH_SUCCESS });
        cb && cb();
        showToast(data?.message);
      } else {
        dispatch({ type: FETCH_ERROR, payload: data.message });
      }
    } catch (error) {
      dispatch({ type: FETCH_ERROR, payload: error.message });
      showToast(error?.response?.data?.message, true);
      console.log("Error****:", error.message);
    }
  };
};

export const editDevice = (payload, cb = () => {}) => {
  return async (dispatch) => {
    try {
      dispatch({ type: FETCH_START });
      const response = await devicesAxios.post("/editDevice/", payload);
      const data = response.data;
      if (data?.success) {
        dispatch({ type: FETCH_SUCCESS });
        cb && cb(true);
        showToast(data?.message);
      } else {
        cb && cb(null);
        dispatch({ type: FETCH_ERROR, payload: data.message });
      }
    } catch (error) {
      dispatch({ type: FETCH_ERROR, payload: error.message });
      cb && cb(null);
      showToast(error?.response?.data?.message, true);
      console.log("Error****:", error.message);
    }
  };
};

export const getDeviceStatusGraph = (payload, cb = () => {}) => {
  return async (dispatch) => {
    try {
      dispatch({ type: FETCH_START });
      const response = await devicesAxios.post("/getCount/", payload);
      const data = response.data;
      if (data?.success) {
        dispatch({ type: FETCH_SUCCESS });
        dispatch({ type: "DEVICE_STATUS_GRAPH", payload: data?.data });
        cb && cb(data);
      } else {
        cb && cb(null);
        dispatch({ type: FETCH_ERROR, payload: data.message });
      }
    } catch (error) {
      dispatch({ type: FETCH_ERROR, payload: error.message });
      cb && cb(null);
      showToast(error?.response?.data?.message, true);
      console.log("Error****:", error.message);
    }
  };
};

export const getLockStatusGraph = (payload, cb = () => {}) => {
  return async (dispatch) => {
    try {
      dispatch({ type: FETCH_START });
      const response = await devicesAxios.post(
        "/getDeviceLockStatus/",
        payload
      );
      const data = response.data;
      if (data?.success) {
        dispatch({ type: FETCH_SUCCESS });
        dispatch({ type: "DEVICE_LOCK_STATUS_GRAPH", payload: data?.data });
        cb && cb(data);
      } else {
        cb && cb(null);
        dispatch({ type: FETCH_ERROR, payload: data.message });
      }
    } catch (error) {
      dispatch({ type: FETCH_ERROR, payload: error.message });
      cb && cb(null);
      showToast(error?.response?.data?.message, true);
      console.log("Error****:", error.message);
    }
  };
};

//movement report
export const getDeviceWiseSummary = (payload, cb = () => {}) => {
  return async (dispatch) => {
    try {
      dispatch({ type: FETCH_START });
      const response = await geoAxios.post("/getSummaryDeviceWise/", payload);
      const data = response.data;
      if (data?.success) {
        dispatch({ type: FETCH_SUCCESS });
        dispatch({ type: "DEVICE_MOVEMENT_REPORT", payload: data });
        cb && cb(data);
        showToast(data?.message);
      } else {
        cb && cb(null);
        dispatch({ type: FETCH_ERROR, payload: data.message });
        showToast(data?.message, true);
      }
    } catch (error) {
      dispatch({ type: FETCH_ERROR, payload: error.message });
      cb && cb(null);
      showToast(error?.response?.data?.message, true);
      console.log("Error****:", error.message);
    }
  };
};

export const getDeviceTrackReport = (payload, cb = () => {}) => {
  return async (dispatch) => {
    try {
      dispatch({ type: FETCH_START });
      const response = await geoAxios.post(
        "/downloadHistoryTrackExcel",
        payload
      );
      const data = response.data;
      if (data?.success) {
        dispatch({ type: FETCH_SUCCESS });
        cb && cb(data);
      } else {
        cb && cb(data);
        dispatch({ type: FETCH_ERROR, payload: data.message });
        showToast(data?.message, true);
      }
    } catch (error) {
      dispatch({ type: FETCH_ERROR, payload: error.message });
      cb && cb(null);
      showToast(error?.response?.data?.message, true);
      console.log("Error****:", error.message);
    }
  };
};

export const downloadMovementReport = (payload, cb = () => {}) => {
  return async (dispatch) => {
    try {
      dispatch({ type: FETCH_START });
      const response = await geoAxios.post("/downloadMovementReport", payload);
      const data = response.data;
      if (data?.success) {
        dispatch({ type: FETCH_SUCCESS });
        cb && cb(data);
        showToast(data?.message);
      } else {
        cb && cb(null);
        showToast(data?.message, true);
        dispatch({ type: FETCH_ERROR, payload: data.message });
      }
    } catch (error) {
      dispatch({ type: FETCH_ERROR, payload: error.message });
      cb && cb(null);
      showToast(error?.response?.data?.message, true);
      console.log("Error****:", error.message);
    }
  };
};

// passcode for elock
export const getPasscodeOTP = (payload, cb = () => {}) => {
  return async (dispatch) => {
    try {
      dispatch({ type: FETCH_START });
      const response = await geoAxios.post("/getOTPForShowPasscode", payload);
      const data = response.data;
      if (data?.success) {
        dispatch({ type: FETCH_SUCCESS });
        cb && cb(data);
        showToast(data?.message);
      } else {
        cb && cb(null);
        dispatch({ type: FETCH_ERROR, payload: data.message });
        showToast(data?.message, true);
      }
    } catch (error) {
      dispatch({ type: FETCH_ERROR, payload: error.message });
      cb && cb(null);
      showToast(error?.response?.data?.message, true);
      console.log("Error****:", error.message);
    }
  };
};

export const verifyPasscodeOTP = (payload, cb = () => {}) => {
  return async (dispatch) => {
    try {
      dispatch({ type: FETCH_START });
      const response = await geoAxios.post("/verifyShowPasscodeOTP", payload);
      const data = response.data;
      if (data?.success) {
        dispatch({ type: FETCH_SUCCESS });
        cb && cb(data);
      } else {
        cb && cb(null);
        dispatch({ type: FETCH_ERROR, payload: data.message });
        showToast(data?.message, true);
      }
    } catch (error) {
      dispatch({ type: FETCH_ERROR, payload: error.message });
      cb && cb(null);
      showToast(error?.response?.data?.message, true);
      console.log("Error****:", error.message);
    }
  };
};

export const showPasscode = (payload, cb = () => {}) => {
  return async (dispatch) => {
    try {
      dispatch({ type: FETCH_START });
      const response = await geoAxios.post("/showPassword", payload);
      const data = response.data;
      if (data?.success) {
        dispatch({ type: FETCH_SUCCESS });
        cb && cb(data);
        showToast(data?.message);
      } else {
        cb && cb(null);
        dispatch({ type: FETCH_ERROR, payload: data.message });
        showToast(data?.message, true);
      }
    } catch (error) {
      dispatch({ type: FETCH_ERROR, payload: error.message });
      cb && cb(null);
      showToast(error?.response?.data?.message, true);
      console.log("Error****:", error.message);
    }
  };
};

// lock unlock device
export const getLockUnlockOTP = (payload, cb = () => {}) => {
  return async (dispatch) => {
    try {
      dispatch({ type: FETCH_START });
      const response = await geoAxios.post("/deviceUnlockOTP", payload);
      const data = response.data;
      if (data?.success) {
        dispatch({ type: FETCH_SUCCESS });
        cb && cb(data);
        showToast(data?.message);
      } else {
        cb && cb(null);
        dispatch({ type: FETCH_ERROR, payload: data.message });
        showToast(data?.message, true);
      }
    } catch (error) {
      dispatch({ type: FETCH_ERROR, payload: error.message });
      cb && cb(null);
      showToast(error?.response?.data?.message, true);
      console.log("Error****:", error.message);
    }
  };
};

export const verifyLockUnlockOTP = (payload, cb = () => {}) => {
  return async (dispatch) => {
    try {
      dispatch({ type: FETCH_START });
      const response = await geoAxios.post("/verifyDeviceUnlockOTP", payload);
      const data = response.data;
      if (data?.success) {
        dispatch({ type: FETCH_SUCCESS });
        cb && cb(data);
      } else {
        cb && cb(null);
        dispatch({ type: FETCH_ERROR, payload: data.message });
        showToast(data?.message, true);
      }
    } catch (error) {
      dispatch({ type: FETCH_ERROR, payload: error.message });
      cb && cb(null);
      showToast(error?.response?.data?.message, true);
      console.log("Error****:", error.message);
    }
  };
};

export const lockUnlockDevice = (payload, cb = () => {}) => {
  return async (dispatch) => {
    try {
      dispatch({ type: FETCH_START });
      const response = await geoAxios.post("/deviceLockUnlockCommand", payload);
      const data = response.data;
      if (data?.success) {
        dispatch({ type: FETCH_SUCCESS });
        cb && cb(data);
        showToast(data?.message);
      } else {
        cb && cb(null);
        dispatch({ type: FETCH_ERROR, payload: data.message });
        showToast(data?.message, true);
      }
    } catch (error) {
      dispatch({ type: FETCH_ERROR, payload: error.message });
      cb && cb(null);
      showToast(error?.response?.data?.message, true);
      console.log("Error****:", error.message);
    }
  };
};

// passcode for fixed lock
export const generatePasscodeForFixedLock = (payload, cb = () => {}) => {
  return async (dispatch) => {
    try {
      dispatch({ type: FETCH_START });
      const response = await geoAxios.post("/generateOTPForFixedlock", payload);
      const data = response.data;
      if (data?.success) {
        dispatch({ type: FETCH_SUCCESS });
        cb && cb(data);
        // showToast(data?.message);
      } else {
        cb && cb(null);
        dispatch({ type: FETCH_ERROR, payload: data.message });
        showToast(data?.message, true);
      }
    } catch (error) {
      dispatch({ type: FETCH_ERROR, payload: error.message });
      cb && cb(null);
      showToast(error?.response?.data?.message, true);
      console.log("Error****:", error.message);
    }
  };
}; 

export const getCommands = (payload, cb = () => {}) => {
  return async (dispatch) => {
    try {
      dispatch({ type: FETCH_START });
      const response = await geoAxios.post("/getCommands", payload);
      const data = response.data;
      if (data?.success) {
        dispatch({ type: FETCH_SUCCESS });
        dispatch({ type: "GET_COMMANDS", payload: data?.data });
        cb && cb(data);
        // showToast(data?.message);
      } else {
        cb && cb(null);
        dispatch({ type: FETCH_ERROR, payload: data.message });
        showToast(data?.message, true);
      }
    } catch (error) {
      dispatch({ type: FETCH_ERROR, payload: error.message });
      cb && cb(null);
      showToast(error?.response?.data?.message, true);
      console.log("Error****:", error.message);
    }
  };
};

// send command
export const getUserCommands = (payload, cb = () => {}) => {
  return async (dispatch) => {
    try {
      dispatch({ type: FETCH_START });
      dispatch({ type: "USER_COMMANDS_LOADING", payload: true });
      const response = await geoAxios.post("/getUserCommands", payload);
      const data = response.data;
      if (data?.success) {
        dispatch({ type: FETCH_SUCCESS });
        dispatch({ type: "GET_USER_COMMANDS", payload: data?.data });
        cb && cb(data);
        // showToast(data?.message);
      } else {
        cb && cb(null);
        dispatch({ type: FETCH_ERROR, payload: data.message });
        dispatch({ type: "GET_USER_COMMANDS", payload: [] });
        showToast(data?.message, true);
      }
      dispatch({ type: "USER_COMMANDS_LOADING", payload: false });
    } catch (error) {
      dispatch({ type: FETCH_ERROR, payload: error.message });
      dispatch({ type: "USER_COMMANDS_LOADING", payload: false });
      dispatch({ type: "GET_USER_COMMANDS", payload: [] });
      cb && cb(null);
      showToast(error?.response?.data?.message, true);
      console.log("Error****:", error.message);
    }
  };
};

export const addAssignCommand = (payload, cb = () => {}) => {
  return async (dispatch) => {
    try {
      dispatch({ type: "USER_COMMANDS_LOADING", payload: true });
      const response = await geoAxios.post("/addAssignCommand", payload);
      const data = response.data;
      if (data?.success) {
        showToast(data?.message);
      } else {
        showToast(data?.message, true);
      }
      cb && cb(data);
      dispatch({ type: "USER_COMMANDS_LOADING", payload: false });
    } catch (error) {
      dispatch({ type: "USER_COMMANDS_LOADING", payload: false });
      cb && cb(error?.response?.data);
      showToast(error?.response?.data?.message, true);
      console.log("Error****:", error.message);
    }
  };
};

export const deleteCommand = (payload, cb = () => {}) => {
  return async (dispatch) => {
    try {
      dispatch({ type: "USER_COMMANDS_LOADING", payload: true });
      const response = await geoAxios.post("/deleteCommand", payload);
      const data = response.data;
      if (data?.success) {
        showToast(data?.message);
      } else {
        showToast(data?.message, true);
      }
      cb && cb(data);
      dispatch({ type: "USER_COMMANDS_LOADING", payload: false });
    } catch (error) {
      dispatch({ type: "USER_COMMANDS_LOADING", payload: false });
      cb && cb(error?.response?.data);
      showToast(error?.response?.data?.message, true);
      console.log("Error****:", error.message);
    }
  };
};

export const sendOTPForSmsCommand = (payload, cb = () => {}) => {
  return async (dispatch) => {
    try {
      dispatch({ type: FETCH_START });
      const response = await geoAxios.post("/sendOTPForSmsCommand", payload);
      const data = response.data;
      if (data?.success) {
        dispatch({ type: FETCH_SUCCESS });
        cb && cb(data);
        showToast(data?.message);
      } else {
        cb && cb(null);
        dispatch({ type: FETCH_ERROR, payload: data.message });
        showToast(data?.message, true);
      }
    } catch (error) {
      dispatch({ type: FETCH_ERROR, payload: error.message });
      cb && cb(null);
      showToast(error?.response?.data?.message, true);
      console.log("Error****:", error.message);
    }
  };
};

export const sendCommands = (payload, cb = () => {}) => {
  return async (dispatch) => {
    try {
      dispatch({ type: FETCH_START });
      const response = await geoAxios.post("/sendCommands", payload);
      const data = response.data;
      if (data?.success) {
        dispatch({ type: FETCH_SUCCESS });
        cb && cb(data);
        showToast(data?.message);
      } else {
        cb && cb(null);
        dispatch({ type: FETCH_ERROR, payload: data.message });
        showToast(data?.message, true);
      }
    } catch (error) {
      dispatch({ type: FETCH_ERROR, payload: error.message });
      cb && cb(null);
      showToast(error?.response?.data?.message, true);
      console.log("Error****:", error.message);
    }
  };
};

//////
export const assignDeassignDevices = (payload, cb = () => {}) => {
  return async (dispatch) => {
    try {
      dispatch({ type: FETCH_START });
      const response = await devicesAxios.post(
        "/assignDeassignDevices/",
        payload
      );
      const data = response.data;
      if (data?.success) {
        dispatch({ type: FETCH_SUCCESS });
        cb && cb();
      } else {
        dispatch({ type: FETCH_ERROR, payload: data.message });
      }
    } catch (error) {
      dispatch({ type: FETCH_ERROR, payload: error.message });
      //showToast(error?.response?.data?.message, true);
      console.log("Error****:", error.message);
    }
  };
};

/**
 * @description -- to get devices that are assigned to a user [will be used in "add/update user"]
 * @param {*} payload -- takes userId & orgId
 * @param {*} cb
 * @returns
 */
export const getUserAssignedDevices = (payload, cb) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    devicesAxios
      .post("/listAssignDevices", payload)
      .then(({ data }) => {
        if (data.success) {
          callback();
        } else {
          dispatch({ type: FETCH_ERROR, payload: data.message });
        }
        function callback() {
          dispatch({ type: FETCH_SUCCESS });
          if (cb) {
            cb(data?.data);
          }
        }
      })
      .catch(function (error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
        showToast(error?.response?.data?.message, true);
        console.log("Error****:", error.message);
      });
  };
};

/**
 * @description : Action creator to get list of all ports
 *
 * @param {*} payload
 * @param {*} cb - callback
 * @returns
 */
export const getAllPortGroups = (payload, cb = () => {}) => {
  return async (dispatch) => {
    try {
      dispatch({ type: FETCH_START });
      const response = await devicesAxios.post("/listPorts", payload);
      const data = response.data;
      if (data?.success) {
        dispatch({ type: FETCH_SUCCESS });
        dispatch({ type: GET_PORTS, payload: data?.data });
        cb && cb();
      } else {
        dispatch({ type: FETCH_ERROR, payload: data.message });
      }
    } catch (error) {
      dispatch({ type: FETCH_ERROR, payload: error.message });
      console.log("Error****:", error.message);
    }
  };
};

/**
 * @description : Action creator to get device detail template
 *
 * @param {*} payload
 * @param {*} cb - callback
 * @returns
 */
export const getEditDeviceTemplate = (payload, cb = () => {}) => {
  return async (dispatch) => {
    try {
      dispatch({ type: FETCH_START });
      dispatch({ type: CLEAR_DEVICE_TEMPLATE });
      const response = await devicesAxios.post(
        "/detailsDeviceTemplate/",
        payload
      );
      const data = response.data;
      if (data?.success) {
        dispatch({ type: FETCH_SUCCESS });
        dispatch({ type: DEVICE_TEMPLATE, payload: data?.data });
        cb && cb(data?.data);
      } else {
        dispatch({ type: FETCH_ERROR, payload: data.message });
        cb && cb(null);
      }
    } catch (error) {
      dispatch({ type: FETCH_ERROR, payload: error.message });
      console.log("Error****:", error.message);
      cb && cb(null);
    }
  };
};

export const getListDeviceStatus = (payload, cb = () => {}) => {
  return async (dispatch) => {
    try {
      dispatch({ type: FETCH_START });
      const response = await devicesAxios.post("/listDeviceStatus/", payload);
      const data = response.data;
      if (data?.success) {
        dispatch({ type: FETCH_SUCCESS });
        //dispatch({ type: DEVICE_STATUS, payload: data?.data });
        cb && cb();
      } else {
        dispatch({ type: FETCH_ERROR, payload: data.message });
      }
    } catch (error) {
      dispatch({ type: FETCH_ERROR, payload: error.message });
      console.log("Error****:", error.message);
    }
  };
};

/* For getting device status counts --  */
export const getStatDeviceStatus = (payload, cb = () => {}) => {
  return async (dispatch) => {
    try {
      dispatch({ type: FETCH_START });
      const response = await devicesAxios.post("/statDeviceStatus/", payload);
      const data = response.data;
      if (data?.success) {
        dispatch({ type: FETCH_SUCCESS });
        dispatch({ type: DEVICE_STATUS, payload: data?.data });
        cb && cb();
      } else {
        dispatch({ type: FETCH_ERROR, payload: data.message });
      }
    } catch (error) {
      dispatch({ type: FETCH_ERROR, payload: error.message });
      console.log("Error****:", error.message);
    }
  };
};

export const getListDevicePermissions = (payload, cb) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    devicesAxios
      .post("/listDevicePermission", payload)
      .then(({ data }) => {
        if (data.success) {
          callback();
        } else {
          dispatch({ type: FETCH_ERROR, payload: data.message });
          dispatch({
            type: DEVICE_PERMISSIONS,
            payload: { rows: [], count: 0 },
          });
          if (cb) {
            cb();
          }
        }
        function callback() {
          dispatch({ type: FETCH_SUCCESS });
          dispatch({
            type: DEVICE_PERMISSIONS,
            payload: { rows: data?.data, count: data?.count },
          });
          if (cb) {
            cb();
          }
        }
      })
      .catch(function (error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
        console.log("Error****:", error.message);
        if (cb) {
          cb();
        }
      });
  };
};

export const fetchAddressFromCoords = (payload, cb) => {
  return (dispatch) => {
    axiosMain
      .post(`${locationUrl}fetchAddress`, payload, {
        headers: {
          "Content-Type": "application/json",
          authorization: "oLjJEPvkqYloUOfU1aCyAvOBMQKKG9Am",
        },
      })
      .then(({ data }) => {
        if (data.status) {
          callback();
        } else {
          dispatch({ type: FETCH_ERROR, payload: data.message });
          dispatch({
            type: FETCH_ADDRESS,
            payload: null,
          });
          cb && cb();
        }
        function callback() {
          dispatch({ type: FETCH_SUCCESS });
          dispatch({
            type: FETCH_ADDRESS,
            payload: data?.data,
          });
          cb && cb(data?.data);
        }
      })
      .catch(function (error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
        console.log("Error****:", error.message);
        cb && cb();
      });
  };
};

export const getAllAdmins = (payload = {}, cb) => {
  return async (dispatch) => {
    try {
      dispatch({ type: FETCH_START });

      const response = await geoAxios.post(
        "/fetchDeviceWiseAdminList",
        payload
      );
      const data = response.data;

      if (data?.success) {
        dispatch({ type: FETCH_SUCCESS });
        dispatch({
          type: ADMINS_LIST,
          payload: data?.data,
        });
        if (cb) {
          cb();
        }
      } else {
        dispatch({ type: FETCH_ERROR, payload: data.message });
        dispatch({ type: ADMINS_LIST, payload: [] });
        if (cb) {
          cb();
        }
      }
    } catch (error) {
      dispatch({ type: FETCH_ERROR, payload: error.message });
      console.log("Error****:", error.message);
      if (cb) {
        cb();
      }
    }
  };
};

export const getUserRolesFromOrg = (bodydata, callBackFunction) => {
  return async (dispatch) => {
    dispatch({ type: FETCH_START });
    await axios
      .post("/listPermissionsRoles", bodydata)
      .then(({ data }) => {
        if (data.success) {
          dispatch({ type: FETCH_SUCCESS, payload: data.success });
          // dispatch({ type: USER_PERMISSIONS, payload: data.data });
          // dispatch({ type: "USER_PERMISSIONS_COUNT", payload: data.count });
        }
        if (callBackFunction) {
          callBackFunction(data);
        }
      })
      .catch(function (error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
        console.log("Error****:", error.message);
        if (callBackFunction) {
          callBackFunction(error?.response?.data);
        }
      });
  };
};

export const getOrgListUserWise = (payload, cb) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    axios
      .post("/listOrganizations", { ...payload })
      .then(({ data }) => {
        if (data.success) {
          callback();
        } else {
          dispatch({ type: FETCH_ERROR, payload: data.message });
          if (cb) {
            cb(null);
          }
        }
        function callback() {
          dispatch({ type: FETCH_SUCCESS });
          if (cb) {
            cb(data);
          }
        }
      })
      .catch(function (error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
        console.log("Error****:", error.message);
        if (cb) {
          cb(null);
        }
      });
  };
};

export const currentStatusReport = (payload, cb) => {
  return async (dispatch) => {
    try {
      dispatch({ type: FETCH_START });

      const response = await reportAxios.post(
        "/deviceCurrentStatusReport",
        payload
      );
      const data = response.data;

      if (data?.success) {
        dispatch({ type: FETCH_SUCCESS });
        showToast(data.message);
        if (cb) {
          cb(data?.success);
        }
      } else {
        dispatch({ type: FETCH_ERROR, payload: data.message });
        cb && cb(false);
      }
    } catch (error) {
      dispatch({ type: FETCH_ERROR, payload: error.message });
      showToast(error?.response?.data?.message, true);
      cb && cb(false);
      console.log("Error****:", error.message);
    }
  };
};

export const deviceWiseEvents = (payload, cb) => {
  return async (dispatch) => {
    try {
      dispatch({ type: FETCH_START });

      const response = await reportAxios.post(
        "/deviceWiseTripEventReport",
        payload
      );
      const data = response.data;

      if (data?.success) {
        dispatch({ type: FETCH_SUCCESS });
        showToast(data.message);
        if (cb) {
          cb(data?.success);
        }
      } else {
        dispatch({ type: FETCH_ERROR, payload: data.message });
        cb && cb(false);
      }
    } catch (error) {
      dispatch({ type: FETCH_ERROR, payload: error.message });
      showToast(error?.response?.data?.message, true);
      cb && cb(false);
      console.log("Error****:", error.message);
    }
  };
};

export const deviceEvents = (payload, cb) => {
  return async (dispatch) => {
    try {
      dispatch({ type: FETCH_START });

      const response = await reportAxios.post("/deviceEventReport", payload);
      const data = response.data;

      if (data?.success) {
        dispatch({ type: FETCH_SUCCESS });
        showToast(data.message);
        if (cb) {
          cb(data?.success);
        }
      } else {
        dispatch({ type: FETCH_ERROR, payload: data.message });
        cb && cb(false);
      }
    } catch (error) {
      dispatch({ type: FETCH_ERROR, payload: error.message });
      showToast(error?.response?.data?.message, true);
      cb && cb(false);
      console.log("Error****:", error.message);
    }
  };
};

export const getCameraToken = (payload, cb) => {
  return async (dispatch) => {
    try {
      dispatch({ type: FETCH_START });

      const response = await geoAxios.post("/camera/cameraLogin", payload);
      const data = response.data;

      if (data?.success) {
        dispatch({ type: FETCH_SUCCESS });
        dispatch({ type: CAMERA_TOKEN, payload: data?.data });
        cb && cb(data);
      } else {
        dispatch({ type: FETCH_ERROR, payload: data.message });
        dispatch({ type: CAMERA_TOKEN, payload: null });
        cb && cb();
      }
    } catch (error) {
      dispatch({ type: FETCH_ERROR, payload: error.message });
      dispatch({ type: CAMERA_TOKEN, payload: null });
      showToast(error?.response?.data?.message, true);
      cb && cb();
      console.log("Error****:", error.message);
    }
  };
};

export const getCameraAlerts = (payload, cb) => {
  return async (dispatch) => {
    try {
      dispatch({ type: FETCH_START });

      const response = await geoAxios.post("/camera/getAlerts", payload);
      const data = response.data;

      if (data?.success) {
        dispatch({ type: FETCH_SUCCESS });
        dispatch({ type: CAMERA_ALERTS, payload: data?.data });
        cb && cb(data?.data);
      } else {
        dispatch({ type: FETCH_ERROR, payload: data.message });
        dispatch({ type: CAMERA_ALERTS, payload: null });
        cb && cb();
      }
    } catch (error) {
      dispatch({ type: FETCH_ERROR, payload: error.message });
      dispatch({ type: CAMERA_ALERTS, payload: null });
      showToast(error?.response?.data?.message, true);
      cb && cb();
      console.log("Error****:", error.message);
    }
  };
}; 

export const getCameraPlaybacks = (payload, cb) => {
  return async (dispatch) => {
    try {
      dispatch({ type: FETCH_START });

      const response = await geoAxios.post("/camera/getPlayBackFiles", payload);
      const data = response.data;

      if (data?.success) {
        dispatch({ type: FETCH_SUCCESS });
        dispatch({ type: CAMERA_PLAYBACKS, payload: data?.data });
        cb && cb(data?.data);
      } else {
        dispatch({ type: FETCH_ERROR, payload: data.message });
        dispatch({ type: CAMERA_PLAYBACKS, payload: null });
        cb && cb();
      }
    } catch (error) {
      dispatch({ type: FETCH_ERROR, payload: error.message });
      dispatch({ type: CAMERA_PLAYBACKS, payload: null });
      showToast(error?.response?.data?.message, true);
      cb && cb();
      console.log("Error****:", error.message);
    }
  };
};

export const getCameraStatus = (payload, cb) => {
  return async (dispatch) => {
    try {
      dispatch({ type: FETCH_START });

      const response = await geoAxios.post("/camera/getCameraStatus", payload);
      const data = response.data;

      if (data?.success) {
        dispatch({ type: FETCH_SUCCESS });
        dispatch({ type: CAMERA_STATUS, payload: data?.data });
        cb && cb(data);
      } else {
        dispatch({ type: FETCH_ERROR, payload: data.message });
        dispatch({ type: CAMERA_STATUS, payload: null });
        cb && cb();
      }
    } catch (error) {
      dispatch({ type: FETCH_ERROR, payload: error.message });
      dispatch({ type: CAMERA_STATUS, payload: null });
      showToast(error?.response?.data?.message, true);
      cb && cb();
      console.log("Error****:", error.message);
    }
  };
};

/* --------- REDUCERS --------- */
export const updateAlertsFlag = (payload) => {
  return (dispatch) => {
    dispatch({ type: FETCH_ALERTS_FLAG, payload: payload });
  };
};

export const changeEditFlag = (payload) => {
  return (dispatch) => {
    dispatch({ type: SET_FLAG, payload: payload });
  };
};

export const updateDeviceFlag = (payload) => {
  return (dispatch) => {
    dispatch({ type: FETCH_DEVICES_FLAG, payload: payload });
  };
};

export const shareDeviceTrackingLink = (payload, callback) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    geoAxios
      .post("/shareDeviceTracking", payload.data)
      .then(({ data }) => {
        if (data.success) {
          dispatch({ type: FETCH_SUCCESS, payload: data.success });
          dispatch({ type: SHOW_MESSAGE, payload: data.message });
          if (payload.param === 'copy') {
            navigator.clipboard.writeText(data?.url)
            showToast('Device Tracking link copied successfully.', false)
          }
        } else {
          dispatch({ type: FETCH_ERROR, payload: data.message });
        }

        if (callback) {
          callback({ success: data.success || false, message: data.message });
        }
      })
      .catch(function (error) {
        dispatch({
          type: FETCH_ERROR,
          payload: error?.response?.data?.message,
        });
        if (callback) {
          callback({ success: false, message: error?.response?.data?.message });
        }
        console.log("Error****:", error.message);
      });
  };
};

export const deviceTrackListing = (payload, cb) => {
  return async (dispatch) => {
    dispatch({ type: FETCH_START });
    await geoAxios
      .post("/getSharedDeviceTrackingData", { ...payload })
      .then(({ data }) => {
        if (data.success) {
          callback(data);
        } else {
          cb && cb({ success: false, message: data?.message });
        }
        function callback(data) {
          dispatch({ type: FETCH_SUCCESS });
          if (data?.data?.length === 0) {
            showToast('No data found.', true)
          }
          dispatch({
            type: DEVICE_TRACK_LIST,
            payload: { rows: data.data, count: data.count },
          });
          if (cb) {
            cb(data);
          }
        }
      })
      .catch(function (error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
        dispatch({ type: DEVICE_TRACK_LIST, payload: { rows: [], count: 0 } });
        console.log("Error****:", error.message);
        if (cb) {
          cb({ success: false, message: error?.response?.data?.message });
          // cb(error);
        }
      });
  };
};
export const emptyDevIceTrackListing = (payload, callback) => {
  return (dispatch) => {
    dispatch({ type: EMPTY_DEVICE_TRACK_LISTING });
  }
}
export const emptyDevIceDetails = (payload, callback) => {
  return (dispatch) => {
    dispatch({ type: EMPTY_DEVICE_DETAILS });
  }
}
export const deleteDeviceTrackingLink = (payload, callback) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    geoAxios
      .post("/deleteSharedDeviceTrackingData", payload)
      .then(({ data }) => {
        if (data.success) {
          dispatch({ type: FETCH_SUCCESS, payload: data.success });
          dispatch({ type: SHOW_MESSAGE, payload: data.message });
        } else {
          dispatch({ type: FETCH_ERROR, payload: data.message });
        }

        if (callback) {
          callback({ success: data.success || false, message: data.message });
        }
      })
      .catch(function (error) {
        dispatch({
          type: FETCH_ERROR,
          payload: error?.response?.data?.message,
        });
        if (callback) {
          callback({ success: false, message: error?.response?.data?.message });
        }
        console.log("Error****:", error.message);
      });
  };
};

export const shareDeviceAlert = (payload, callback) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    geoAxios
      .post("/sharingDeviceAlert", payload)
      .then(({ data }) => {
        if (data.success) {
          dispatch({ type: FETCH_SUCCESS, payload: data.success });
          dispatch({ type: SHOW_MESSAGE, payload: data.message });
        } else {
          dispatch({ type: FETCH_ERROR, payload: data.message });
        }

        if (callback) {
          callback({ success: data.success || false, message: data.message });
        }
      })
      .catch(function (error) {
        dispatch({
          type: FETCH_ERROR,
          payload: error?.response?.data?.message,
        });
        if (callback) {
          callback({ success: false, message: error?.response?.data?.message });
        }
        console.log("Error****:", error.message);
      });
  };
};

export const device_alert_Listing = (payload, cb) => {
  return async (dispatch) => {
    dispatch({ type: FETCH_START });
    await geoAxios
      .post("/getSharingDeviceAlert", { ...payload })
      .then(({ data }) => {
        if (data.success) {
          callback(data);
        } else {
          cb({ success: false, message: data?.message });
        }
        function callback(data) {
          dispatch({ type: FETCH_SUCCESS });
          if (data?.data?.length === 0) {
            showToast('No data found.', true)
          }
          dispatch({
            type: DEVICE_ALERT_LIST,
            payload: { rows: data.data, count: data.count },
          });
          if (cb) {
            cb(data);
          }
        }
      })
      .catch(function (error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
        dispatch({ type: DEVICE_ALERT_LIST, payload: { rows: [], count: 0 } });
        console.log("Error****:", error.message);
        if (cb) {
          cb({ success: false, message: error?.response?.data?.message });
          // cb(error);
        }
      });
  };
};
export const emptyDeviceAlertListing = (payload, callback) => {
  return (dispatch) => {
    dispatch({ type: EMPTY_DEVICE_ALERT_LISTING });
  }
}

export const deleteDeviceAlert = (payload, callback) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    geoAxios
      .post("/updateDeviceSharingAlert", payload)
      .then(({ data }) => {
        if (data.success) {
          dispatch({ type: FETCH_SUCCESS, payload: data.success });
          dispatch({ type: SHOW_MESSAGE, payload: data.message });
        } else {
          dispatch({ type: FETCH_ERROR, payload: data.message });
        }

        if (callback) {
          callback({ success: data.success || false, message: data.message });
        }
      })
      .catch(function (error) {
        dispatch({
          type: FETCH_ERROR,
          payload: error?.response?.data?.message,
        });
        if (callback) {
          callback({ success: false, message: error?.response?.data?.message });
        }
        console.log("Error****:", error.message);
      });
  };
};
export const updateDeviceAlert = (payload, callback) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    geoAxios
      .post("/updateDeviceSharingAlert", payload)
      .then(({ data }) => {
        if (data.success) {
          dispatch({ type: FETCH_SUCCESS, payload: data.success });
          dispatch({ type: SHOW_MESSAGE, payload: data.message });
        } else {
          dispatch({ type: FETCH_ERROR, payload: data.message });
        }

        if (callback) {
          callback({ success: data.success || false, message: data.message });
        }
      })
      .catch(function (error) {
        dispatch({
          type: FETCH_ERROR,
          payload: error?.response?.data?.message,
        });
        if (callback) {
          callback({ success: false, message: error?.response?.data?.message });
        }
        console.log("Error****:", error.message);
      });
  };
};