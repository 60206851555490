import React, { useEffect, useRef } from 'react'
import { useState } from 'react'
import { FormControl, Grid, InputAdornment, OutlinedInput, Button, TextField, Select, MenuItem, FormLabel, InputLabel, Typography, Tab, Tabs, Box, Checkbox, CircularProgress, Dialog, DialogContent, Chip, Paper, IconButton, Menu, Tooltip, Autocomplete } from '@mui/material'
// import { makeStyles } from '@mui/system'
import { DataGrid } from '@mui/x-data-grid'
import EmptyPage from './EmptyPage';
import { Add, ArrowDownward, Cancel, Clear, Delete, ExpandMore, KeyboardArrowDown, Search, WrongLocation } from '@mui/icons-material';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { changeTemplateStatus, getAllTemplates, getAllUsers, getStatusCount, removeTemplates, getTemplateTypes, getAllOrganisations } from '../../../redux/actions';
import theme, { buttonClasses, inputClasses, textClasses } from '../../../static/styles/theme';
import { Link } from 'react-router-dom';
// import TemplatesTable from "./TemplatesTable"
import TemplatesTable from "./TemplateTable"
import { templateTypes, statusTypes, getTemplateTypeName } from '../../../services/constants';
import { capitalizeSentence, getCamelCaseText, getShortText, convertIntoGivenTimezone } from '../../../services/functions';
import { useForm } from 'react-hook-form';
import history from '../../../services/history';
import { useGetPermissions } from "../../../services/functions"
// import { DatePicker } from '@mui/x-date-pickers';
import { selectStylesOverride } from "../../../utils/util";
import loaderGif from "../../../static/images/drawer/loader.gif";
import AddIcon from "@mui/icons-material/Add";
import { havePermission } from '../../../utils/permissionCheck';
import CustomSelect from '../../../components/FormUI/CustomSelect';

const ConfirmDialog = ({ status, handleClose, selectedTemplates, setSelectedTemplates, refreshTableData, handleRemoveSelection }) => {
    const dispatch = useDispatch();

    const [showAll, setShowAll] = useState(false);
    const size = selectedTemplates.length;
    ///

    const isDraft = status && selectedTemplates.length > 0 && status == "Publish" && selectedTemplates[0].status == "drafted";
    const callBack = (data) => {
        refreshTableData();
        handleClose();
        if (setSelectedTemplates) {
            setSelectedTemplates([]);
        }
    }
    const handleStatusChange = () => {

        let payload = {
            template_id: selectedTemplates.length == 1 ? selectedTemplates[0]._id : selectedTemplates.map(st => st._id)
        }
        if (status == "Publish") {
            payload["status"] = "published"
            dispatch(changeTemplateStatus(payload, callBack))
        }
        else if (status == "Disable") {
            payload["status"] = "disabled";
            dispatch(changeTemplateStatus(payload, callBack))
        }
        else if (status == "Delete") {
            dispatch(removeTemplates(payload, callBack))
        }

    }
    return (
        <Dialog
            open={status != null}
            onClose={handleClose}
            scroll={"paper"}
            PaperProps={{ sx: { width: "100%" } }}
            maxWidth={"sm"}
        >
            <DialogContent>
                <Grid container justifyContent={"center"} alignItems="center">
                    <Typography sx={{ ...textClasses.cardTitle }}>Are you Sure ?</Typography>
                </Grid>
                <Grid container justifyContent={"center"} alignItems="center">
                    <Typography sx={{ ...textClasses.cardTitle }}>{status} the selected templates</Typography>
                </Grid>
                <Grid container sx={{ mt: 1 }} spacing={1}>
                    {
                        selectedTemplates.slice(0, showAll ? size : 5).map((st, i) => {
                            return (
                                <Grid item sm={4} xs={6} key={i}>
                                    <Grid container direction={"row"} justifyContent="center" alignItems={"center"} sx={{ backgroundColor: theme.themeLightOrange, border: `1px solid ${theme.themeOrange}`, borderRadius: "12px", pl: 1, pr: 1, minHeight: "40px" }}>
                                        <Grid item sm={10} xs={8}>
                                            <Typography sx={{ ...textClasses.normalText, color: theme.themeOrange, textAlign: "start" }}>
                                                {(st.name && st.name.length > 15) ? st.name.substring(0, 15) + "..." : st.name}
                                            </Typography>

                                        </Grid>
                                        {
                                            handleRemoveSelection &&
                                            <Grid item sm={2} xs={4}>
                                                <IconButton sx={{ alignContent: "right" }} onClick={() => handleRemoveSelection(st._id)}>
                                                    <Cancel sx={{ color: theme.themeOrange }} />
                                                </IconButton>
                                            </Grid>
                                        }

                                    </Grid>
                                </Grid>
                            )
                        })
                    }
                    {
                        size > 5 &&
                        <>
                            <Grid container item sm={4} xs={6} justifyContent="center" alignItems={"center"}>
                                <Typography sx={{ ...textClasses.boldText, color: theme.themeOrange, textDecoration: "underline", "cursor": "pointer" }} onClick={() => setShowAll(!showAll)}>
                                    {
                                        showAll
                                            ?
                                            <>Collapse</>
                                            :
                                            <>View All ({size})</>
                                    }
                                </Typography>
                            </Grid>

                        </>
                    }
                </Grid>
                <Grid container sx={{ p: 1, mt: 3 }} justifyContent="center">
                    <Button variant='outlined' size='small' sx={{ ...buttonClasses.outlined, borderColor: theme.themeOrange, color: theme.themeOrange, minWidth: "150px" }} onClick={handleClose}>
                        No, Cancel
                    </Button>
                    {
                        isDraft &&
                        <Button variant='outlined' size='small' sx={{ ml: 1, ...buttonClasses.outlined, borderColor: theme.themeOrange, color: theme.themeOrange, minWidth: "150px" }} onClick={() => history.push(`/dashboard/templates/edit/${selectedTemplates[0]._id}`)}>
                            Edit & Publish
                        </Button>
                    }

                    <Button variant='contained' size='small' sx={{ ml: 1, ...buttonClasses.small, backgroundColor: theme.themeOrange, minWidth: "150px" }} disabled={selectedTemplates.length <= 0} onClick={handleStatusChange}>
                        Yes, {status}
                    </Button>
                </Grid>
            </DialogContent>
        </Dialog>
    )
}


function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export const getSpentTime = (then, now) => {
    if (then != null) {
        then = moment(then);
        now = now ? moment(now) : moment();

        let dayDiff = now.diff(then, "days");
        let hourDiff = now.diff(then, "hours");
        let minDiff = now.diff(then, "minutes");
        let timeSpent;

        if (dayDiff < 1) {
            if (hourDiff < 1) {
                timeSpent = `${minDiff} min ago`
            }
            else {
                timeSpent = `${hourDiff} hrs ago`
            }
        }
        else {
            timeSpent = `${dayDiff} d ago`
        }
        return timeSpent
    }
    else {
        return "-"
    }
}

export default function Home() {

    const { register, handleSubmit } = useForm();
    const dispatch = useDispatch();
    const templates = useSelector(state => state.templates.allTemplates)
    const totalRecords = useSelector(state => state.templates.totalRows)
    const statusCount = useSelector(state => state.templates.statusCount);
    const { AllUserData } = useSelector((state) => state.users);
    const allTemplateTypes = useSelector(state => state.templates.templateTypes);
    const [tabIndex, setTabIndex] = useState(0);
    const [templateFilter, setTemplateFilter] = useState(null);
    const [orgDetail, setOrgDetails] = useState(null);
    const [statusFilter, setStatusFilter] = useState(null)
    const [nameFilter, setNameFilter] = useState("")
    const [createdFilter, setCreatedFilter] = useState(null);
    const [dateFilter, setDateFilter] = useState(null);
    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(10);
    const [loader, setLoader] = useState(false);
    const [searchFields, setSearchFields] = useState({
        org: null
    });
    const isDarkThemeEnabledSelector = useSelector(
        (state) => state.theme.themeDark
    );
    const userDetails = useSelector((state) => state.auth.currentUserDetail);
    const orgListing = useSelector(
        (state) => state.Organisationlisting.OrganisationListing
    );
    const superAdmin =
        useSelector((state) => state.auth.currentUserDetail)
            ?.adminSpecificPermissions || {};
    const themeType = isDarkThemeEnabledSelector
        ? require("../../../static/styles/darktheme")
        : require("../../../static/styles/theme");
    const { buttonClasses, inputClasses, textClasses } = themeType;
    const isFilterApplied = (() => {
        if (nameFilter != "" || templateFilter != null || createdFilter != null || dateFilter != null || orgDetail != null) {
            return true;
        }
        return false;
    })();

    const [anchorEl, setAnchorEl] = useState(null);
    const [actionIndex, setActionIndex] = useState(null);
    const openAction = Boolean(anchorEl);
    // const { permissions } = useGetPermissions();

    const handleOpenAction = (event, i) => {
        setAnchorEl(event.currentTarget);
        setActionIndex(i);

    }
    const handleCloseAction = () => {
        setActionConfirmModal(null);
        setActionIndex(null);
        setTimeout(() => {
            setAnchorEl(null);
        }, 1);

    }
    const [selectedTemplates, setSelectedTemplates] = useState([]);
    const [confirmModal, setConfirmModal] = useState(null);
    const [actionConfirmModal, setActionConfirmModal] = useState(null);

    const isSelectionPublishable = (() => {
        let flag = true;
        if (selectedTemplates.filter(st => ["published", "drafted"].indexOf(st.status) >= 0).length > 0) {
            flag = false;
        }
        if (selectedTemplates.length == 1 && selectedTemplates[0].status == "drafted") {
            flag = true;
        }
        return flag;
    })();

    const isSelectionDisable = (() => {
        let flag = true;
        if (selectedTemplates.filter(st => (st.status == "drafted" || st.status == "disabled")).length > 0) {
            flag = false;
        }
        return flag;
    })();

    const handleTabChange = (event, value) => {
        switch (value) {
            case 0: setStatusFilter(null); break;
            case 1: setStatusFilter("published"); break;
            case 2: setStatusFilter("disabled"); break;
            default: setStatusFilter(null)
        }
        setPage(0);
        setTabIndex(value)
    }

    const handleCheckAll = () => {
        if (selectedTemplates.length < templates.length) {
            let sT = templates.map(t => ({ _id: t._id, status: t.status, name: t.name }))
            setSelectedTemplates(sT);
        }
        else {
            setSelectedTemplates([]);
        }
    }

    //"Action"
    const tableHeader = [<Checkbox checked={templates.length != 0 && selectedTemplates.length == templates.length} onChange={handleCheckAll} size='small' sx={{ "&, &.Mui-checked": { color: themeType.default.themeOrange } }} />, "#", "Template Name","Organisation Name", "Template Type", "Description", "Fields", "Current Status", "Created By", "Created On", Object.keys(superAdmin).length ? "Organsiation" : null, Object.keys(superAdmin).length ? "Default Template" : null, "Action"]
    const tableRows = templates.map((row, i) => {
        let items = [];
        items.push(
            <Checkbox
                size="small"
                sx={{
                    "&, &.Mui-checked": { color: themeType.default.themeOrange },
                }}
                checked={selectedTemplates.map(o => o._id).indexOf(row._id) >= 0}
                onChange={(e) => handleCheckChange(e, row._id, row.status, row.name)}
            />
        );
        items.push(
            <Typography sx={{ ...textClasses.normalText, textAlign: "center" }}>
                {i + 1}
            </Typography>
        );

        items.push(
            <Tooltip arrow title={row.name} placement="top" >
                <Typography sx={{ ...textClasses.normalText, textAlign: "center" }}>
                    <Link to={`/dashboard/templates/view/${row._id}`} style={{ ...textClasses.normalText, textDecoration: "none", color: theme.themeOrange }}>
                        {getShortText(row.name, 18)}
                    </Link>
                </Typography>
            </Tooltip>
        );
        items.push(
            <Typography sx={{ ...textClasses.normalText, textAlign: "center" }}>
                {row?.defaultTemplate ? row?.createdBy?.orgName || '-' : row?.createdBy?.company || '-'}
            </Typography>
        );
        items.push(
            <Typography sx={{ ...textClasses.normalText, textAlign: "center" }}>
                {capitalizeSentence(row.templateType)}
            </Typography>
        );
        items.push(
            <Tooltip arrow title={row.description} placement="top">
                <Typography sx={{ ...textClasses.normalText, textAlign: "center" }}>
                    {
                        row.description
                            ?
                            getShortText(row.description, 18)
                            :
                            "-"
                    }
                </Typography>
            </Tooltip>
        );
        // let otherFields = row.otherTemplate.map((tem) => tem.attributes.length);
        // let otherCount = 0;
        // for (let index = 0; index < otherFields.length; index++) {
        //     otherCount += otherFields[index]
        // }
        items.push(
            <Typography sx={{ ...textClasses.normalText, textAlign: "center" }}>
                {
                    row.attributes.filter((f) => f.templateType === row.templateType).length
                }
            </Typography>
        );
        const statusColor = {
            published: "#008000",
            drafted: "#FF7200",
            disabled: "#E9001A"
        }
        items.push(
            <Typography sx={{ ...textClasses.normalText, textAlign: "center", color: statusColor[row.status] }}>{capitalizeSentence(row?.status)}</Typography>
        );
        items.push(
            <Typography sx={{ ...textClasses.normalText, textAlign: "center" }}>
                {row?.createdBy?.name || "-"}
            </Typography>
        );
        items.push(
            <Typography sx={{ ...textClasses.normalText, textAlign: "center" }}>
                {convertIntoGivenTimezone(row?.createdAt, userDetails.timeZone.offset) || "-"}
            </Typography>
        );
        if (Object.keys(superAdmin).length) {
            items.push(
                <Typography sx={{ ...textClasses.normalText, textAlign: "center" }}>
                    {row?.orgName || "-"}
                </Typography>
            );
        }
        if (Object.keys(superAdmin).length) {
            items.push(
                <Typography sx={{ ...textClasses.normalText, textAlign: "center", color: row?.defaultTemplate ? 'green' : 'red' }}>
                    {row?.defaultTemplate ? "True" : 'False'}
                </Typography>
            );
        }
        // actions
        items.push(
            <Typography sx={{ ...textClasses.normalText, color: theme.themeOrange, cursor: "pointer" }} onClick={(e) => handleOpenAction(e, i)}>Action <KeyboardArrowDown /></Typography>
        )
        return items;

    })

    const handleCheckChange = (event, templateId, status, name) => {
        if (event.target.checked) {
            handleAddSelection(templateId, status, name);
        }
        else {
            handleRemoveSelection(templateId);
        }
    }


    const handleAddSelection = (templateId, status, name) => {
        let obj = {
            _id: templateId,
            status: status,
            name: name
        }
        setSelectedTemplates([...selectedTemplates, obj]);
    }

    const handleRemoveSelection = (templateId) => {
        let oldTemplates = [...selectedTemplates];
        const i = oldTemplates.map(o => o._id).indexOf(templateId);
        if (i >= 0) {
            oldTemplates.splice(i, 1);
            setSelectedTemplates(oldTemplates);
            if (oldTemplates.length == 0) {
                handleCloseConfirmModal();
            }
        }

    }

    const handleCloseConfirmModal = () => {
        setConfirmModal(null);
    }

    const handleClearFilter = () => {
        setTemplateFilter(null)
        setStatusFilter(null)
        setNameFilter("")
        setCreatedFilter(null);
        setDateFilter(null);
        setOrgDetails(null)
        setPage(0);
        setLimit(10);
    }
    
    const handleSearchOrg = (empty) => {
        if (empty === true) {
        dispatch(getAllOrganisations({ selection: ["name"] }));
        } else {
        if (searchFields?.org || searchFields?.org == "")
            dispatch(
            getAllOrganisations({
                selection: ["name"],
                searchValue: searchFields.org,
            })
            );
        }
    };

    useEffect(() => {
        // dispatch(getAllUsers({ "isAdminIncluded": true }));
        dispatch(
            getTemplateTypes()
        )
    }, [])

    const refreshTableData = (pageNo, limitNo) => {
        setLoader(true);
        const payload = {
            type: "",
            page: pageNo,
            limit: limitNo,
            templateName: nameFilter.length >= 3 ? nameFilter : "",
            templateType: templateFilter ? templateFilter.templateTypekey : "",
            status: statusFilter ? statusFilter : "",
            createdOn: dateFilter,
            orgId: orgDetail ? orgDetail?._id : "",
            orgName: orgDetail ? orgDetail?.name : ""
        }
        if (createdFilter && createdFilter != "" && createdFilter != null) {
            payload["createdBy"] = createdFilter._id
        }
        if (dateFilter && dateFilter != "") {
            payload["createdOn"] = dateFilter;
        }
        dispatch(
            getAllTemplates(payload, () => {
                setLoader(false);
            })
        )
        delete payload.status;
        dispatch(getStatusCount(payload));
    }

    useEffect(() => {
        refreshTableData(page + 1, limit);
        setSelectedTemplates([]);
        setConfirmModal(null);
        setActionConfirmModal(null);
        setAnchorEl(null);
        setActionIndex(null);

    }, [templateFilter, statusFilter, createdFilter, dateFilter, page, limit, orgDetail])

    useEffect(() => {
        if (nameFilter.length >= 3 || nameFilter.length == 0) {
            refreshTableData(page + 1, limit);
            setSelectedTemplates([]);
            setConfirmModal(null);
            setActionConfirmModal(null);
            setAnchorEl(null);
            setActionIndex(null);
        }
    }, [nameFilter])

    useEffect(() => {
        if (Object.keys(superAdmin).length) {
            dispatch(getAllOrganisations({ selection: ["name"] }));
        }
    }, [dispatch, superAdmin]);

    return (
        <>
            {
                // !loader && templates.length == 0
                // ?
                //     <EmptyPage/>
                // :
                <>
                    <Grid container>
                        <Grid item sm={6}>
                            <Typography sx={{ ...textClasses.cardTitle }}>Manage Templates</Typography>
                        </Grid>
                        <Grid container item sm={6} justifyContent="end" alignItems="end">
                            {havePermission(userDetails, "dynamicTemplates", "addTemplate") && (
                                <Button variant='outlined' size='small' startIcon={<Add />} data-testid='createNew' sx={{ ...buttonClasses.outlined }} onClick={() => history.push("/dashboard/templates/create")}>
                                    Create New
                                </Button>
                            )}
                            {/* <Button
                                variant="outlined"
                                size="small"
                                sx={{
                                    ...buttonClasses.small,
                                    minHeight: "36px",
                                    borderColor: themeType.default.themeOrange,
                                    color: buttonClasses.lynkitOrangeEmpty,
                                }}
                                onClick={() => history.push("/dashboard/templates/create")}
                                startIcon={<AddIcon />}
                            >
                                Create Templatesss
                            </Button> */}
                        </Grid>
                    </Grid>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 2 }}>
                        <Tabs value={tabIndex} aria-label="disabled tabs example" onChange={handleTabChange} sx={{ '& .MuiTabs-indicator': { backgroundColor: theme.themeOrange }, "& .MuiTab-root.Mui-selected": { color: theme.themeOrange } }}>
                            <Tab data-testid="All Templates" label={`All Templates (${statusCount ? statusCount.total : 0})`} {...a11yProps(0)} sx={{ ...textClasses.normalText, textTransform: "none" }} />
                            <Tab data-testid="Published" label={`Published (${statusCount ? statusCount.published : 0})`} {...a11yProps(1)} sx={{ ...textClasses.normalText, textTransform: "none" }} />
                            {/* <Tab data-testid="Drafted" label={`Drafted (${statusCount ? statusCount.drafted : 0})`} {...a11yProps(1)} sx={{ ...textClasses.normalText, textTransform: "none" }} /> */}
                            <Tab data-testid="Disabled" label={`Disabled (${statusCount ? statusCount.disabled : 0})`} {...a11yProps(1)} sx={{ ...textClasses.normalText, textTransform: "none" }} />
                        </Tabs>
                    </Box>
                    <Grid container spacing={2}>
                        <Grid item sx={4} sm={2}>
                            <FormControl
                                variant='outlined' size='small' fullWidth
                                sx={{ ...selectStylesOverride(isDarkThemeEnabledSelector), borderRadius: '12px' }}>
                                <TextField
                                    data-testid='searchInput'
                                    size="small"
                                    value={nameFilter}
                                    onChange={(e) => { setNameFilter(e.target.value); setPage(0); setLimit(10); }}
                                    label='Search by Template name'
                                    sx={{ ...inputClasses.filterField, height: "36px" }}

                                    InputLabelProps={{
                                        style: {
                                            textOverflow: 'ellipsis',
                                            whiteSpace: 'nowrap',
                                            overflow: 'hidden',
                                            width: '100%',
                                            fontSize: '13px'
                                        }
                                    }}
                                    placeholder='Search by Template name'
                                />
                            </FormControl>
                        </Grid>
                        {Object.keys(superAdmin).length ?
                            <Grid item sx={4} sm={2}>
                                <FormControl
                                    variant='outlined'
                                    fullWidth
                                    sx={{ ...selectStylesOverride(isDarkThemeEnabledSelector), borderRadius: '12px' }}>
                                    {/* <Autocomplete
                                        value={orgDetail}
                                        onChange={(e, option) => {
                                            setOrgDetails(option);
                                            setPage(0);
                                            setLimit(10);
                                        }}
                                        data-testid='orgName'
                                        getOptionLabel={(option) => option.name}
                                        size='small'
                                        fullWidth
                                        // options={orgListing && orgListing}
                                        options={orgListing && orgListing}
                                        sx={{
                                            ...inputClasses.filterField,
                                        }}

                                        renderInput={(params) => <TextField sx={{ fontSize: "12px", color: "red" }} {...params} label={
                                            <Typography sx={{ fontSize: "13px" }}>
                                                Organsiation
                                            </Typography>
                                        } />}
                                    /> */}
                                    <CustomSelect
                                        selectStylesOverride={selectStylesOverride(isDarkThemeEnabledSelector)}
                                        onChange={(e, newVal, reason) => {
                                            if(reason === "clear") handleSearchOrg(true);
                                            setOrgDetails(newVal);
                                            setPage(0);
                                            setLimit(10);
                                        }}
                                        searchValue={
                                            searchFields?.org && searchFields?.org !== ""
                                                ? searchFields?.org
                                                : null
                                        }
                                        onSearch={handleSearchOrg}
                                        onInputChange={(e) => {
                                            if (e?.type === "change") {
                                                if (e?.target?.value === "") handleSearchOrg(true);
                                                setSearchFields((prev) => ({
                                                ...prev,
                                                org: e?.target?.value?.trim(),
                                                }));
                                            }
                                        }}
                                        sx={{
                                        ...inputClasses.filterField,
                                        }}
                                        data-testid='orgName'
                                        label="Organisation"
                                        getOptionLabel={(option) => {
                                            return option?.name;
                                        }}
                                        filterSelectedOptions
                                        labelKey="name"
                                        isOptionEqualToValue={(option, value) =>
                                            option._id === value.id && option.name === value.name
                                        }
                                        options={orgListing && orgListing}
                                    />
                                </FormControl>
                            </Grid>
                            : null}

                        <Grid item sx={4} sm={2}>
                            <FormControl
                                variant='outlined'
                                fullWidth
                                sx={{ ...selectStylesOverride(isDarkThemeEnabledSelector), borderRadius: '12px' }}>
                                <Autocomplete
                                    value={templateFilter}
                                    onChange={(e, option) => {
                                        setTemplateFilter(option);
                                        setPage(0);
                                        setLimit(10);
                                    }}
                                    data-testid="type"
                                    getOptionLabel={(option) => option.templateTypeLabel}
                                    size='small'
                                    fullWidth
                                    options={allTemplateTypes.map(t => t.allTemplateTypes).flat(1)}
                                    sx={{
                                        ...inputClasses.filterField,
                                    }}

                                    renderInput={(params) => <TextField sx={{ fontSize: "12px", color: "red" }} {...params} label={
                                        <Typography sx={{ fontSize: "13px" }}>
                                            Template Type
                                        </Typography>
                                    } />}
                                />
                            </FormControl>
                        </Grid>

                        {/* {
                            tabIndex == 0 &&
                            <Grid item sx={4} sm={2}>
                                <FormControl
                                    variant='outlined'
                                    fullWidth
                                    sx={{ ...selectStylesOverride(isDarkThemeEnabledSelector), borderRadius: '12px' }}>
                                    <Autocomplete
                                        // value={statusFilter}
                                        value={{ value: statusFilter, label: statusTypes.find(dl => dl.value === statusFilter)?.label || "" }}
                                        onChange={(e, option) => {
                                            setStatusFilter(option?.value);
                                            setPage(0);
                                            setLimit(10);
                                        }}
                                        size='small'
                                        data-testid='status'
                                        fullWidth
                                        options={statusTypes}
                                        sx={{
                                            ...inputClasses.filterField,
                                        }}
                                        renderInput={(params) => <TextField sx={{ fontSize: "12px", color: "red" }} {...params} label={
                                            <Typography sx={{ fontSize: "13px" }}>
                                                Status
                                            </Typography>
                                        } />}
                                    />
                                </FormControl>
                            </Grid>
                        } */}


                        {/* <Grid item sx={4} sm={2}>
                            <FormControl
                                variant='outlined'
                                fullWidth
                                sx={{ ...selectStylesOverride(isDarkThemeEnabledSelector) }}>
                                <DatePicker
                                    label="Created on"
                                    value={dateFilter}
                                    className="customDatePicker"
                                    onChange={(d) => {
                                        setDateFilter(d);
                                        setPage(0);
                                        setLimit(10);
                                    }}
                                    format="dd/MM/yyyy"
                                    sx={{ ...inputClasses.filterField, fontSize: "9px" }}
                                    slotProps={{
                                        textField: { size: "small" }, actionBar: {
                                            actions: ['clear']
                                        }
                                    }}
                                />
                            </FormControl>
                        </Grid> */}
                        {
                            isFilterApplied &&
                            <Grid container item sm={Object.keys(superAdmin).length ? 6 : 8} alignItems={"center"} justifyContent={"end"}>
                                <Typography sx={{ ...textClasses.boldText, color: theme.themeOrange, textDecoration: "underline", cursor: "pointer" }} onClick={handleClearFilter}>
                                    Clear Filter(s)
                                </Typography>
                            </Grid>
                        }

                    </Grid>
                    <ConfirmDialog status={confirmModal} handleClose={handleCloseConfirmModal} selectedTemplates={selectedTemplates} setSelectedTemplates={setSelectedTemplates} refreshTableData={() => refreshTableData(page + 1, limit)} handleRemoveSelection={handleRemoveSelection} />
                    {
                        selectedTemplates.length > 0 &&
                        <Grid container sx={{ mt: 2, textTransform: "none" }}>
                            {
                                selectedTemplates.length == 1 &&
                                <>

                                    {/* <Button size='small' variant="contained" className="UM_uploadfile-btn" sx={{ ...buttonClasses.lynkitOrangeFill, mr: 1, textTransform: "none" }} onClick={() => history.push(`/dashboard/templates/edit/${selectedTemplates[0]._id}`)}>Edit</Button> */}


                                    {/* <Button size='small' variant="contained" sx={{ ...buttonClasses.lynkitOrangeFill, mr: 1, textTransform: "none" }} onClick={() => history.push(`/dashboard/templates/view/${selectedTemplates[0]._id}`)}>View</Button> */}

                                    {havePermission(userDetails, "dynamicTemplates", "editTemplate") && (
                                        <Button
                                            size="small"
                                            variant="contained"
                                            className="UM_uploadfile-btn"
                                            sx={{
                                                fontSize: "12px",
                                                borderRadius: "8px",
                                                margin: "5px",
                                                color: "black",
                                                border: "1px solid black",
                                                ...buttonClasses.lynkitOrangeFill,
                                                mr: 1,
                                                textTransform: "none",
                                            }}
                                            onClick={() => history.push(`/dashboard/templates/edit/${selectedTemplates[0]._id}`)}
                                        >
                                            Edit
                                        </Button>
                                    )}

                                    {havePermission(userDetails, "dynamicTemplates", "viewTemplate") && (
                                        <Button
                                            size="small"
                                            variant="contained"
                                            className="UM_uploadfile-btn"
                                            sx={{
                                                fontSize: "12px",
                                                borderRadius: "8px",
                                                margin: "5px",
                                                color: "black",
                                                border: "1px solid black",
                                                ...buttonClasses.lynkitOrangeFill,
                                                mr: 1,
                                                textTransform: "none",
                                            }}
                                            onClick={() => history.push(`/dashboard/templates/view/${selectedTemplates[0]._id}`)}
                                        >
                                            View
                                        </Button>
                                    )}
                                </>
                            }

                            {havePermission(userDetails, "dynamicTemplates", "disableTemplate") && isSelectionDisable &&
                                <Button size='small' variant="contained"
                                    sx={{
                                        fontSize: "12px",
                                        margin: "5px",
                                        color: "black",
                                        border: "1px solid black",
                                        textTransform: "none",
                                        ...buttonClasses.lynkitDisabled,
                                    }}
                                    onClick={() => setConfirmModal("Disable")}>
                                    Disable
                                </Button>
                            }


                            {
                                havePermission(userDetails, "dynamicTemplates", "editTemplate") &&
                                isSelectionPublishable &&
                                <Button size='small' variant="contained"
                                    sx={{
                                        fontSize: "12px",
                                        margin: "5px",
                                        color: "black",
                                        border: "1px solid black",
                                        textTransform: "none",
                                        ...buttonClasses.lynkitGreenFill,
                                    }}
                                    onClick={() => setConfirmModal("Publish")}>Publish</Button>

                            }

                            {havePermission(userDetails, "dynamicTemplates", "deleteTemplate") && (
                                <Button size='small' variant="contained"
                                    sx={{
                                        fontSize: "12px",
                                        borderRadius: "8px",
                                        margin: "5px",
                                        color: "black",
                                        border: "1px solid black",
                                        textTransform: "none",
                                        ...buttonClasses.lynkitBlackFill,
                                    }}
                                    onClick={() => setConfirmModal("Delete")}>Delete</Button>
                            )}

                        </Grid>
                    }
                    <Grid container sx={{ mt: 2 }}>
                        {
                            loader
                                ?
                                <Grid container direction={"column"} justifyContent={"center"} alignItems="center" sx={{ p: 15 }}>
                                    <Grid item >
                                        <img src={loaderGif} />
                                    </Grid>
                                </Grid>
                                :
                                <Paper
                                    sx={{
                                        height: "64.8vh",
                                        overflow: "auto",
                                        width: "100%",
                                        display: "block",
                                    }}
                                >
                                    <TemplatesTable
                                        header={tableHeader}
                                        rows={tableRows}
                                        totalRows={totalRecords}
                                        page={page}
                                        limit={limit}
                                        setPage={setPage}
                                        setLimit={setLimit}
                                        TemplatesTable={TemplatesTable}
                                        themeType={themeType}
                                    />
                                </Paper>
                        }

                    </Grid>
                    <ConfirmDialog status={actionConfirmModal} handleClose={handleCloseAction} selectedTemplates={actionIndex != null ? [templates[actionIndex]] : []} refreshTableData={() => refreshTableData(page + 1, limit)} onSuccess={handleCloseAction} />
                    {
                        actionIndex != null &&
                        <Menu
                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={actionIndex != null}
                            onClose={handleCloseAction}
                            MenuListProps={{
                                'aria-labelledby': 'basic-button',
                            }}
                            sx={{ display: actionIndex != null ? "block !important" : "none !important" }}
                        >
                            {havePermission(userDetails, "dynamicTemplates", "editTemplate") && (
                                <MenuItem key={1} onClick={() => history.push(`/dashboard/templates/edit/${templates[actionIndex]._id}`)}>Edit</MenuItem>
                            )}

                            {havePermission(userDetails, "dynamicTemplates", "viewTemplate") && (
                                <MenuItem key={2} onClick={() => history.push(`/dashboard/templates/view/${templates[actionIndex]._id}`)}>View</MenuItem>
                            )}

                            {havePermission(userDetails, "dynamicTemplates", "disableTemplate") &&["published"].indexOf(templates[actionIndex].status) >= 0 &&
                                <MenuItem key={3} onClick={() => setActionConfirmModal("Disable")}>Disable</MenuItem>
                            }
                            {
                                havePermission(userDetails, "dynamicTemplates", "editTemplate") && ["disabled"].indexOf(templates[actionIndex].status) >= 0 &&
                                <MenuItem key={5} onClick={() => setActionConfirmModal("Publish")}>Publish</MenuItem>
                            }
                            {
                                havePermission(userDetails, "dynamicTemplates", "editTemplate") && ["drafted"].indexOf(templates[actionIndex].status) >= 0 &&
                                <MenuItem key={5} onClick={() => setActionConfirmModal("Publish")}>Publish</MenuItem>
                            }

                            {havePermission(userDetails, "dynamicTemplates", "deleteTemplate") && (
                                <MenuItem key={4} onClick={() => setActionConfirmModal("Delete")}>Delete</MenuItem>
                            )}





                        </Menu>

                    }


                </>
            }
        </>
    )
}

