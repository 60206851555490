import { ORGANISATIONS_LIST, FETCH_START,FETCH_SUCCESS, FETCH_ERROR, SHOW_MESSAGE, GET_ORGANISATION, ORGANISATION_FEATURES, ORGANISATIONS_COUNT } from "../types";

import axios from "../../services/Api";
import { showToast } from "../../services/functions";

export const getOrganisationFeatures = (payload, cb) =>
{
    return (dispatch) =>{
        dispatch({type: FETCH_START});
        axios.post('/projectDetails', {...payload})
        .then(({data}) => {
            if (data.success) {
                callback();
            } 
            else {
                dispatch({type: FETCH_ERROR, payload: data.message});
                dispatch({ type: ORGANISATION_FEATURES, payload: {rows: [], count: 0}})
                if(cb){
                    cb();
                }
            }
            function callback() {
                dispatch({ type: FETCH_SUCCESS });
                dispatch({ type: ORGANISATION_FEATURES, payload: {rows: data.data, count: data.count}})
                if(cb){
                    cb();
                }
            }
        }).catch(function (error) {
            dispatch({type: FETCH_ERROR, payload: error.message});
            console.log("Error****:", error.message);
            if(cb){
                cb();
            }
        });
    }
};


export const getAllOrganisations = (payload, cb) =>
{
    return (dispatch) =>{
        dispatch({type: FETCH_START});
        axios.post('/listOrganizations', {...payload})
        .then(({data}) => {
            if (data.success) {
                callback();
            } 
            else {
                dispatch({type: FETCH_ERROR, payload: data.message});
                dispatch({ type: ORGANISATIONS_LIST, payload: {rows: [], count: 0}})
                if(cb){
                    cb(null);
                }
            }
            function callback() {
                dispatch({ type: FETCH_SUCCESS });
                dispatch({ type: ORGANISATIONS_LIST, payload: {rows: data.data, count: data.count}})
                if(cb){
                    cb(data);
                }
            }
        }).catch(function (error) {
            dispatch({type: FETCH_ERROR, payload: error.message});
            console.log("Error****:", error.message);
            if(cb){
                cb(null);
            }
        });
    }
};

export const getOrganisation = (payload, cb) =>
{
    return (dispatch) =>{
        dispatch({type: FETCH_START});
        axios.post('/organizationDetails', {...payload})
        .then(({data}) => {
            if (data.success) {
                callback();
            } 
            else {
                dispatch({type: FETCH_ERROR, payload: data.message});
                dispatch({ type: GET_ORGANISATION, payload: data.message})
                if(cb){
                    cb(null);
                }
            }
            function callback() {
                dispatch({ type: FETCH_SUCCESS });
                dispatch({ type: GET_ORGANISATION, payload:data.data })
                if(cb){
                    cb(data.data);
                }
            }
        }).catch(function (error) {
            dispatch({type: FETCH_ERROR, payload: error.message});
            console.log("Error****:", error.message);
            if(cb){
                cb(null);
            }
        });
    }
};


export const addOrganisation = (payload, cb) =>
{
    return (dispatch) =>{
        dispatch({type: FETCH_START});
        axios.post('/addOrganization', {...payload})
        .then(({data}) => {
            if (data.success) {
                callback(data);
            } 
            else {
                dispatch({type: FETCH_ERROR, payload: data.message});
                // dispatch({ type: ORGANISATIONS_LIST, payload: {rows: [], count: 0}})
                if(cb){
                    cb();
                }
            }
            function callback(data) {
                dispatch({ type: FETCH_SUCCESS });
                dispatch({type: SHOW_MESSAGE, payload: data.message});
                if(cb){
                    cb(data);
                }
            }
        }).catch(function (error) {
            // dispatch({type: FETCH_ERROR, payload: error.message});
            console.log("Error****:", error.message);
            // if(cb){
            //     cb();
            // }
            showToast(error?.response?.data?.message, true);
        });

    }
};


export const editOrganisation = (payload, cb) =>
{
    return (dispatch) =>{
        dispatch({type: FETCH_START});
        axios.post('/updateOrganization', {...payload})
        .then(({data}) => {
            if (data.success) {
                callback();
            } 
            else {
                dispatch({type: FETCH_ERROR, payload: data.message});
                // dispatch({ type: ORGANISATIONS_LIST, payload: {rows: [], count: 0}})
                
            }
            function callback() {
                dispatch({ type: FETCH_SUCCESS });
                dispatch({type: SHOW_MESSAGE, payload: data.message});
                // dispatch({ type: ORGANISATIONS_LIST, payload: {rows: data.data, count: data.count}})
                if(cb){
                    cb();
                }
            }
        }).catch(function (error) {
            dispatch({type: FETCH_ERROR, payload: error.message});
            console.log("Error****:", error.message);
            
        });
    }
};


export const getOrganisationsStatusWiseCount = (payload, cb) =>
{
    return (dispatch) =>{
        dispatch({type: FETCH_START});
        axios.post('/organizationsStatusWiseCount', {...payload})
        .then(({data}) => {
            if (data.success) {
                callback();
            } 
            else {
                dispatch({type: FETCH_ERROR, payload: data.message});
                dispatch({ type: ORGANISATIONS_COUNT, payload: {}})
                if(cb){
                    cb();
                }
            }
            function callback() {
                dispatch({ type: FETCH_SUCCESS });
                dispatch({ type: ORGANISATIONS_COUNT, payload: data.data})
               
                if(cb){
                    cb();
                }
            }
        }).catch(function (error) {
            dispatch({type: FETCH_ERROR, payload: error.message});
            console.log("Error****:", error.message);
            if(cb){
                cb();
            }
        });
    }
};


































