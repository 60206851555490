import { Box, Button, Dialog, DialogContent, IconButton } from "@mui/material";
import React from "react";
import ThreeDotsLoader from "../ThreeDots/ThreeDotsLoader";
import { Close } from "@mui/icons-material";

/**
 * @description Modal with integrated iFrame
 * @param {string} label Button label name (Add Route, Add Geofence etc)
 * @param {string} iFrameUrl url/path to open iFrame
 * @param submitAction a function handler to be called after submitting iFrame form
 * @returns JSX element
 */
const IframeModal = ({ label, iFrameUrl, submitAction }) => {
  const iframeRef = React.useRef(null);
  const [open, setOpen] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(true);

  const hideSidebarAndNavbar = () => {
    // Access the iframe document and hide elements
    // setIsLoading(false);
    // const iframeDoc = iframeRef.current.contentDocument;
    // const sidebar = iframeDoc.querySelector(".main-sidebar-drawer");
    // const navbar = iframeDoc.querySelector(".navbar-spacing");
    // if (sidebar) {
    //   sidebar.style.display = "none";
    // }
    // if (navbar) {
    //   navbar.style.display = "none";
    // }
  };

  const handleIframeMessage = (event) => {
    if (
      event.origin === window.location.origin &&
      event.data === "formSubmitted"
    ) {
      setOpen(false);
      submitAction && submitAction();
    }
  };

  React.useEffect(() => {
    window.addEventListener("message", handleIframeMessage);

    return () => {
      window.removeEventListener("message", handleIframeMessage);
    };
  }, []);

  React.useEffect(() => {
    if (open) {
      setIsLoading(true);
      setTimeout(() => {
        setIsLoading(false);
      }, 2500);
    }
  }, [open]);

  // React.useEffect(() => {
  //   const iframe = document.querySelector('.modal-iframe');
  //   let timeoutId;
  //   if (iframe) {
  //     timeoutId = setTimeout(() => setIsLoading(false), 5000); // Set timeout after 5 seconds
  //     iframe.onload = () => {
  //       clearTimeout(timeoutId); // Clear timeout if onload fires first
  //       setIsLoading(false);
  //     };
  //   }

  //   return () => {
  //     // Cleanup function to remove event listener and timeout
  //     if (iframe) {
  //       clearTimeout(timeoutId);
  //       iframe.onload = null;
  //     }
  //   };
  // }, [iFrameUrl]);

  return (
    <Box>
      <Button
        size="small"
        sx={{ textDecoration: "underline", textTransform: "capitalize" }}
        onClick={() => {
          setOpen(true);
          localStorage.setItem("iframeee", true);
        }}
      >
        {label}
      </Button>

      <Dialog
        fullWidth={true}
        maxWidth="lg"
        open={open}
        onClose={() => {
          setOpen(false)
          localStorage.removeItem("iframeee")
        }}
        PaperProps={{style: {
          overflowY: "unset"
        }}}
      >
        <Box sx={{ position: "relative" }}>
          <Box sx={{ position: "absolute", right: "-14px", top: "-14px"}}>
            <IconButton sx={{background: "rgba(185, 185, 185, 0.85)", color: "#000", zIndex: 5000}} size="large" onClick={() => {
              setOpen(false);
              localStorage.removeItem("iframeee")
              }}>
              <Close sx={{fontWeight: 800}} />
            </IconButton>
          </Box>
        </Box>
        <DialogContent sx={{ height: "91vh"}}>
          <Box height="100%" width="100%">
            {isLoading ? (
              <ThreeDotsLoader />
            ) : (
              <iframe
                src={iFrameUrl}
                ref={iframeRef}
                className="modal-iframe"
                onLoad={hideSidebarAndNavbar}
                width="100%"
                height="100%"
                frameborder="0"
              ></iframe>
            )}
            
          </Box>
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default IframeModal;
