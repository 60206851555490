import {
  Button,
  Autocomplete,
  TextField,
  Grid,
  MenuItem,
  Paper,
  Menu,
  Checkbox,
  Tab,
  Tabs,
  Typography,
  Box,
  FormControl,
  OutlinedInput,
  InputAdornment,
  InputLabel,
  Tooltip,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import React, { useState, useEffect, useCallback, useMemo } from "react";
import { selectStylesOverride } from "../../../../utils/util";
import { useDispatch, useSelector } from "react-redux";
import GenericFilterTabs from "../../../Components/Generic/GenericFilterTabs";
import {
  Search,
  KeyboardArrowDown,
  QrCodeScannerOutlined,
  ConstructionOutlined,
} from "@mui/icons-material";
import OrganisationTable from "../components/OrganisationTable";
import {
  getAllOrganizations,
  deleteOrganization,
  disableEnableOrganizations,
  getOrganisationsStatusWiseCount,
} from "../../../../redux/actions";
import DisableDeleteOrganization from "../components/DisableDeleteOrganization";
import Actions from "../../../../utils/constants";
import AddOrganisationModal from "../components/AddOrganisationModal";
import OrganisationDetailCard from "../components/OrganisationDetailCard";
import loaderGif from "../../../../static/images/drawer/loader.gif";
import EmptyPage from "../../EmptyPage";

let payload = {
  page: 1,
  limit: 10,
};

const Organisation = () => {
  const dispatch = useDispatch();

  const isDarkThemeEnabledSelector = useSelector(
    (state) => state.theme.themeDark
  );
  const themeType = isDarkThemeEnabledSelector
    ? require("../../../../static/styles/darktheme")
    : require("../../../../static/styles/theme");
  const { buttonClasses, inputClasses, textClasses } = themeType;
  const [filter, setFilter] = useState({ ...payload });
  const [anchorEl, setAnchorEl] = useState(null);
  const [loader, setLoader] = useState(true);
  const [checkedObj, setCheckedObj] = useState([]);
  const [actionStatus, setActionStatus] = useState(Actions.ADD);
  const [editBlock, setEditBlock] = useState(false);
  const [selectedOrganisation, setSelectedOrganisation] = useState(null);
  const organizations = useSelector((state) => state.userlist.allOrganizations);
  // const userId = useSelector(state => state.auth.userId);
  // const userPermissionRoles = useSelector(state => state.userPermission.userPermissionRoles);
  const [tableData, setTableData] = useState([]);
  const totalRows = useSelector((state) => state.userlist.totalRows);
  const [selectedTemplates, setSelectedTemplates] = useState([]);
  const [tableIndex, setTableIndex] = useState("");
  const [actionIndex, setActionIndex] = useState(null);
  const [selectedOrg, setSelectedOrg] = useState();
  const [open, setOpen] = React.useState(false);
  const userDetails = useSelector((state) => state.auth.currentUserDetail);
  const [deleteDisableBlock, setDeleteDisableBlock] = useState(false);
  const [showAddOrganisation, setShowAddOrganisation] = useState(false);
  const organisationsCount = useSelector(
    (state) => state.Organisationlisting.organisationsCount
  );

  useEffect(() => {
    dispatch(getOrganisationsStatusWiseCount());
  }, []);

  useEffect(() => {
    let timeoutId;
    if (filter && filter?.searchValue && filter?.searchValue?.length > 3) {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(debounceSearch, 700);
      return () => {
        clearTimeout(timeoutId);
        setLoader(false);
      };
    }
    if (filter && filter?.searchValue && filter?.searchValue?.length < 3) {
      setLoader(false);
      return;
    }
    if (filter && filter?.searchValue !== "") {
      dispatch(
        getAllOrganizations(filter, () => {
          setLoader(false);
        })
      );
    }
  }, [dispatch, filter, setLoader]);

  const debounceSearch = () => {
    dispatch(getAllOrganizations(filter));
  };

  useEffect(() => {
    if (organizations) {
      setTableData(organizations);
    }
  }, [organizations]);

  let tableColumns = [
    {
      selector: "checkbox",
      header: (
        <Typography sx={{ color: themeType.default.themeOrange }}>
          <Checkbox
            sx={{
              "&, &.Mui-checked": { color: themeType.default.themeOrange },
            }}
            checked={
              (tableData || [])
                .map((item) => {
                  return item._id;
                })
                .every((el) => checkedObj.includes(el)) &&
              tableData.length !== 0
                ? true
                : false
            }
            onChange={(event) => allHandleChecked(event)}
            size="small"
          />
        </Typography>
      ),
      cell: (index, entry) => {
        return (
          <Typography
            sx={{ color: themeType.default.themeOrange, textAlign: "center" }}
          >
            <Checkbox
              sx={{
                "&, &.Mui-checked": { color: themeType.default.themeOrange },
              }}
              data-testid="checkbox"
              checked={checkedObj.includes(entry._id) ? true : false}
              onChange={(event) => handleSelect(event, index)}
              size="small"
            />
          </Typography>
        );
      },
    },
    {
      key: "Sno.",
      header: "#",
      cell: (key) => {
        return (
          <Typography
            sx={{
              ...textClasses.t13n,
              textAlign: "center",
            }}
          >
            {(filter.page - 1) * filter.limit + key + 1}
          </Typography>
        );
      },
    },
    {
      key: "name",
      header: "Organisation Name",
      cell: (entry) => {
        return (
          <Tooltip
            title={entry?.name?.length > 20 ? entry?.name : ""}
            arrow
            placement="top"
          >
            <Typography
              sx={{
                ...textClasses.t13n,
                textAlign: "center",
                color: themeType.default.themeOrange,
                cursor: "pointer",
              }}
              onClick={() => setSelectedOrganisation(entry)}
            >
              {entry && entry?.name.length > 20
                ? entry?.name.substring(0, 20) + "..."
                : entry?.name ?? "-"}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      key: "address",
      header: "Address",
      cell: (entry) => {
        return (
          <Tooltip
            arrow
            title={
              entry?.addressDetails?.address?.length > 20
                ? entry?.addressDetails?.address
                : ""
            }
            placement="top"
          >
            <Typography
              sx={{
                ...textClasses.t13n,
                textAlign: "center",
              }}
            >
              {entry && entry?.addressDetails?.address
                ? entry?.addressDetails?.address.length > 20
                  ? entry?.addressDetails?.address.substring(0, 20) + "..."
                  : entry?.addressDetails?.address ?? "-"
                : "-"}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      key: "state",
      header: "State",
      cell: (entry) => {
        return (
          <Typography sx={{ ...textClasses.t13n, textAlign: "center" }}>
            {entry?.addressDetails?.state ? entry?.addressDetails?.state : "-"}
          </Typography>
        );
      },
    },
    {
      key: "city",
      header: "City",
      cell: (entry) => {
        return (
          <Typography sx={{ ...textClasses.t13n, textAlign: "center" }}>
            {entry?.addressDetails?.city ? entry?.addressDetails?.city : "-"}
          </Typography>
        );
      },
    },
    {
      header: "Contact Person",
      cell: (entry) => {
        return (
          <Tooltip
            title={
              entry?.representativeName?.length > 20
                ? entry?.representativeName
                : ""
            }
            arrow
            placement="top"
          >
            <Typography sx={{ ...textClasses.t13n, textAlign: "center" }}>
              {entry && entry?.representativeName?.length > 20
                ? entry?.representativeName.substring(0, 20) + "..."
                : entry?.representativeName ?? "-"}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      key: "representativeMobile",
      header: "Mobile No",
      cell: (entry) => {
        return (
          <Typography sx={{ ...textClasses.t13n, textAlign: "center" }}>
            {entry?.representativeMobile ?? "-"}
          </Typography>
        );
      },
    },
    {
      key: "representativeEmail",
      header: "Email ID",
      cell: (entry) => {
        return (
          <Tooltip
            title={
              entry?.representativeEmail?.length > 25
                ? entry?.representativeEmail
                : ""
            }
            arrow
            placement="top"
          >
            <Typography sx={{ ...textClasses.t13n, textAlign: "center" }}>
              {entry && entry?.representativeEmail?.length > 25
                ? entry?.representativeEmail?.substring(0, 25) + "..."
                : entry?.representativeEmail ?? "-"}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      key: "status",
      header: "Status",
      cell: (entry) => {
        return (
          <Typography
            sx={{
              ...textClasses.t13n,
              textAlign: "center",
              textTransform: "capitalize",
              fontWeight: 600,
              color:
                entry?.isApproved == true
                  ? themeType?.default.themeGreen
                  : themeType?.default.themeRed,
            }}
          >
            {entry?.isApproved == true ? "Active" : "Blocked"}
          </Typography>
        );
      },
    },
    {
      header: "Actions",
      cell: (index) => {
        return (
          <>
            <Typography
              data-testid="actions"
              sx={{
                ...textClasses.normalText,
                color: themeType.default.themeOrange,
                cursor: "pointer",
                textAlign: "center",
              }}
              onClick={(e) => handleOpenAction(e, index)}
            >
              Action <KeyboardArrowDown />
            </Typography>
          </>
        );
      },
    },
  ];

  const handleSelect = (e, index) => {
    let data = [...tableData];
    let result = [...checkedObj];
    let id = data[index]._id;
    let name = data[index].name;
    let approved = data[index].isApproved;

    if (!result.includes(id)) {
      setTableIndex(index);
      result.push(id);

      setCheckedObj(result);
      handleAddSelection(id, name, approved);
    } else {
      result.splice(result.indexOf(id), 1);
      setCheckedObj(result);
      handleRemoveSelection(id);
    }
  };

  const handleRefresh = useCallback(() => {
    setLoader(true);
    dispatch(
      getAllOrganizations(filter, () => {
        setLoader(false);
      })
    );
    dispatch(getOrganisationsStatusWiseCount());
  }, [dispatch, filter]);

  // useEffect(() => {
  //   if (userId) {
  //     // setLoader(true)
  //     dispatch(getUserPermissionForSubUsers({ id: userId }, () => {
  //       // setLoader(false)
  //     }))
  //   }
  // }, [userId, dispatch])

  const handleAction = (action, index) => {
    setSelectedTemplates([]);
    let data = [...tableData];
    let obj = { ...data[index] };
    let result = [...checkedObj];
    if (action === Actions.VIEW || action === Actions.EDIT) {
      let id = data[index]._id;
      result.push(id);
      setSelectedOrg(id);
      setShowAddOrganisation(true);
      // setAddNew(true)
      setActionStatus(action);
      setCheckedObj(result);
    }
    if (
      action === Actions.DELETE ||
      action === Actions.BLOCK ||
      action === Actions.ACTIVE
    ) {
      let id = data[index]._id;
      let name = data[index].name;
      let approved = data[index].isApproved;
      result.push(id);
      setDeleteDisableBlock(true);
      setActionStatus(action);
      setCheckedObj(result);
      let obj = {
        name: name,
        id: id,
        status: approved,
      };
      setSelectedTemplates([obj]);
    }
  };

  const isFilterApplied = useMemo(() => {
    if (
      Object.keys(filter).some((filter) => ["searchValue"].includes(filter))
    ) {
      return true;
    }
    return false;
  }, [filter]);

  const handleClose = (action) => {
    checkedObj.length = 0;
    setAnchorEl(null);
    handleAction(action, tableIndex);
    setActionIndex(null);
  };

  const handleOpenAction = (event, i) => {
    setAnchorEl(event.currentTarget);
    setActionIndex(i);
    setTableIndex(i);
  };

  const handleCloseAction = () => {
    setActionIndex(null);
    setTimeout(() => {
      setAnchorEl(null);
    }, 1);
  };

  const handleAddSelection = (id, name, approved) => {
    let obj = {
      name: name,
      id: id,
      status: approved,
    };
    setSelectedTemplates([...selectedTemplates, obj]);
  };

  const handleActionSubmission = useCallback(
    (remark) => {
      let bodyData;
      if (actionStatus === Actions.DELETE) {
        bodyData = {
          id:
            selectedTemplates.length == 1
              ? selectedTemplates[0].id
              : selectedTemplates.map((st) => st.id),
        };
        dispatch(
          deleteOrganization(bodyData, () => {
            setCheckedObj([]);
            setSelectedTemplates([]);
            handleRefresh();
          })
        );
      } else if (
        actionStatus === Actions.BLOCK ||
        actionStatus === Actions.ACTIVE
      ) {
        bodyData = {
          ids:
            selectedTemplates.length == 1
              ? [selectedTemplates[0].id]
              : selectedTemplates.map((st) => st.id),
          isApproved: actionStatus === Actions.ACTIVE ? true : false,
        };
        dispatch(
          disableEnableOrganizations({ ...bodyData, remark: remark }, () => {
            setCheckedObj([]);
            setSelectedTemplates([]);
            handleRefresh();
          })
        );
      }

      setDeleteDisableBlock(false);
    },
    [
      dispatch,
      setDeleteDisableBlock,
      actionStatus,
      selectedTemplates,
      setSelectedTemplates,
      setCheckedObj,
      handleRefresh,
    ]
  );

  const handleRemoveSelection = (id) => {
    let tempId = id || "";
    let oldTemplates = [...selectedTemplates];
    const i = oldTemplates.map((o) => o.id).indexOf(tempId);
    if (i >= 0) {
      oldTemplates.splice(i, 1);
      setSelectedTemplates(oldTemplates);
    }
  };

  const allHandleChecked = (e) => {
    let data = [...tableData];
    let result = [...checkedObj];
    if (e.target.checked === true) {
      result.length = 0;
      for (let i = 0; i < tableData.length; i++) {
        let id = data[i]._id;
        result.push(id);
        setCheckedObj(result);
        let sT = tableData.map((data) => ({
          name: data?.name || "",
          id: data?._id,
          status: data?.isApproved,
        }));
        setSelectedTemplates(sT);
      }
    } else {
      result.length = 0;
      setCheckedObj(result);
      setSelectedTemplates([]);
    }
  };

  const handleViewEdit = (action) => {
    if (selectedOrg && selectedOrg.length < 2) {
      setActionStatus(action);
      // setAddNew(true)
      setShowAddOrganisation(true);
    }
  };

  const handleOptions = (action) => {
    // checkedObj.length = 0
    handleActionForEdit(action, tableIndex);
  };

  const handleActionForEdit = (action, index) => {
    let data = [...tableData];
    let obj = { ...data[index] };

    if (action === Actions.EDIT || action === Actions.VIEW) {
      setSelectedOrg(data[index]._id);
      setShowAddOrganisation(true);
      setActionStatus(action);
    }
    // else if (action === 'Activate' || action === 'Delete' || action === 'Block') {
    //     setEditBlock(true)
    //     setActionStatus(action);
    // }
    // else { }
  };

  const cardData = useMemo(() => {
    if (selectedOrganisation) {
      return tableColumns.reduce((acc, entry) => {
        if (entry.key) {
          acc[entry.header] = selectedOrganisation[entry.key] ?? "-";
        }
        return acc;
      }, {});
    }
  }, [selectedOrganisation, tableColumns]);

  function checkIsActive() {
    return (
      !!selectedTemplates?.length && selectedTemplates.every((st) => st?.status)
    );
  }
  function checkIfBothStatus() {
    return (
      !!selectedTemplates?.length &&
      selectedTemplates.some((st) => st?.status) &&
      selectedTemplates.some((st) => !st?.status)
    );
  }

  const isActive = checkIsActive();
  const isBlock = !isActive;
  const isBoth = checkIfBothStatus();

  const handlePaginationChange = useCallback(() => {
    setSelectedTemplates([]);
    setCheckedObj([]);
  }, [setSelectedTemplates, setCheckedObj]);

  return (
    <>
      <Grid item sm={12} xs={12}>
        <Grid container>
          <Grid item sm={6} xs={4} data-testid="pageTitle">
            <Typography sx={{ ...textClasses.cardTitle }}>
              Manage Organisation
            </Typography>
          </Grid>

          <Grid
            container
            item
            sm={6}
            xs={6}
            sx={{ justifyContent: "flex-end" }}
          >
            <Grid
              item
              sm={6}
              xs={6}
              sx={{
                displey: "flex",
                display: "flex",
                justifyContent: "flex-end",
                height: "36px",
              }}
            >
              <Button
                variant="outlined"
                size="small"
                sx={{
                  ...buttonClasses.lynkitOrangeEmpty,
                  minHeight: "36px",
                  // borderColor: themeType.default.themeOrange,
                  // color: buttonClasses.lynkitOrangeEmpty,
                }}
                startIcon={<AddIcon />}
                onClick={() => {
                  setShowAddOrganisation(true);
                  setActionStatus(Actions.ADD);
                }}
              >
                Add Organisation
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <GenericFilterTabs
        setFilter={setFilter}
        filter={filter}
        isFilterApplied={isFilterApplied}
        count={organisationsCount}
        module={"Organisations"}
      />
      <Grid container spacing={2} mt={0}>
        <Grid
          container
          item
          display={"flex"}
          flexDirection={"row"}
          sm={12}
          sx={4}
          spacing={2}
          justifyContent="flex-start"
        >
          <Grid item sx={4} sm={3} data-testid="search">
            <FormControl
              variant="outlined"
              size="small"
              fullWidth
              sx={selectStylesOverride(isDarkThemeEnabledSelector)}
            >
              <InputLabel
                htmlFor="outlined-adornment-password"
                sx={{ fontSize: "12px" }}
              >
                Search by name, email, phone
              </InputLabel>
              <OutlinedInput
                sx={{ ...inputClasses.filterField, height: "36px" }}
                value={filter?.searchValue ?? ""}
                onChange={(e) => {
                  setFilter((v) => {
                    if (e.target.value === "" || e.target.value === undefined) {
                      const { searchValue, ...rest } = v;
                      return {
                        ...rest,
                      };
                    } else {
                      return {
                        ...v,
                        searchValue: e.target.value.trimStart(),
                        page: 1,
                        limit: 10,
                      };
                    }
                  });
                }}
                placeholder="Search by name, email, phone"
                label="Search by name, email, phone"
                endAdornment={
                  <InputAdornment position="end">
                    <Search fontSize="small" />
                  </InputAdornment>
                }
              />
            </FormControl>
          </Grid>
          {/* <Grid item sx={4} sm={2} data-testid="status">
            <FormControl
              variant="outlined"
              size="small"
              fullWidth
              sx={selectStylesOverride(isDarkThemeEnabledSelector)}
            >
              <Autocomplete
                size="small"
                options={statusFilterOptions}
                onChange={(event, option) => {
                  setFilter((f) => ({
                      ...f,
                      isApproved: option?.value,
                      page: 1,
                      limit: 10,
                  }))
              }}
                renderInput={(params) => (
                  <TextField
                    sx={{ ...inputClasses.filterField }}
                    {...params}
                    label={
                      <Typography
                        sx={
                          isDarkThemeEnabledSelector
                            ? {
                                fontSize: "12px",
                                color: "#808080",
                                paddingTop: "1px",
                              }
                            : { fontSize: "12px", paddingTop: "1px" }
                        }
                      >
                        Status
                      </Typography>
                    }
                  />
                )}
                renderOption={(props, option) => (
                  <li {...props} style={{ fontSize: "13px" }}>
                    {option.label}
                  </li>
                )}
                value={{ value: filter.isApproved, label:   statusFilterOptions.find(dl => dl.value == filter.isApproved)?.label || "" }}
              />
            </FormControl>
          </Grid> */}
        </Grid>
        {checkedObj.length > 0 ? (
          <Box pl={"16px"} mt={0.7} data-testid="edit" pt={"8px"}>
            {checkedObj.length < 2 ? (
              <Button
                size="small"
                data-testid="edit"
                variant="contained"
                className="UM_uploadfile-btn"
                disabled={checkedObj.length >= 2 ? true : false}
                sx={{
                  fontSize: "12px",
                  borderRadius: "8px",
                  margin: "5px",
                  color: "black",
                  border: "1px solid black",
                  ...buttonClasses.lynkitOrangeFill,
                  mr: 1,
                  textTransform: "none",
                }}
                onClick={() => {
                  handleViewEdit(Actions.EDIT);
                  handleOptions(Actions.EDIT);
                }}
                // sx={{ fontSize: "12px", borderRadius: "8px", margin: "5px", color: "black", border: "1px solid black", ...buttonClasses.lynkitOrangeFill, mr: 1, textTransform: "none" }}
              >
                {" "}
                Edit{" "}
              </Button>
            ) : (
              ""
            )}
            {checkedObj.length < 2 ? (
              <Button
                size="small"
                data-testid="view"
                variant="contained"
                className="UM_uploadfile-btn"
                disabled={checkedObj.length >= 2 ? true : false}
                onClick={() => {
                  setSelectedOrganisation(tableData[tableIndex]);
                }}
                sx={{
                  fontSize: "12px",
                  borderRadius: "8px",
                  margin: "5px",
                  color: "black",
                  border: "1px solid black",
                  ...buttonClasses.lynkitOrangeFill,
                  mr: 1,
                  textTransform: "none",
                }}
              >
                {" "}
                View{" "}
              </Button>
            ) : (
              ""
            )}

            {isActive && !isBoth ? (
              <Button
                size="small"
                data-testid="block"
                variant="contained"
                sx={{
                  fontSize: "12px",
                  borderRadius: "8px",
                  margin: "5px",
                  color: "black",
                  border: "1px solid black",
                  ...buttonClasses.lynkitDisabled,
                  mr: 1,
                  textTransform: "none",
                }}
                onClick={() => {
                  setActionStatus(Actions.BLOCK);
                  setDeleteDisableBlock(true);
                }}
              >
                Block
              </Button>
            ) : (
              ""
            )}

            {isBlock && !isBoth ? (
              <Button
                size="small"
                data-testid="block"
                variant="contained"
                className="UM_uploadfile-btn"
                onClick={() => {
                  setActionStatus(Actions.ACTIVE);
                  setDeleteDisableBlock(true);
                }}
                sx={{
                  fontSize: "12px",
                  borderRadius: "8px",
                  margin: "5px",
                  color: "black",
                  border: "1px solid black",
                  ...buttonClasses.lynkitGreenFill,
                  mr: 1,
                  textTransform: "none",
                }}
              >
                {" "}
                Active
              </Button>
            ) : (
              ""
            )}

            <Button
              size="small"
              data-testid="delete"
              variant="contained"
              className="UM_delete-btn"
              onClick={() => {
                setActionStatus(Actions.DELETE);
                setDeleteDisableBlock(true);
              }}
              sx={{
                fontSize: "12px",
                borderRadius: "8px",
                margin: "5px",
                color: "black",
                border: "1px solid black",
                textTransform: "none",
                ...buttonClasses.lynkitBlackFill,
              }}
            >
              {" "}
              Delete{" "}
            </Button>
          </Box>
        ) : (
          ""
        )}

        {loader ? (
          <Grid
            container
            direction={"column"}
            justifyContent={"center"}
            alignItems="center"
            xs={12}
            sx={{ p: 15 }}
          >
            <Grid item>
              <img src={loaderGif} />
            </Grid>
          </Grid>
        ) : (
          <>
            {tableData && tableData.length === 0 ? (
              <EmptyPage body="No Organisation found.." />
            ) : (
              <Grid container sx={{ mt: 2, pl: "16px" }} data-testid="table">
                <Grid item sm={selectedOrganisation ? 9 : 12} xs={6}>
                  <Paper
                    sx={{
                    width: "98%",
                    margin: "auto",
                    display: "block",
                    height: "66vh",
                    overflow: "auto",
                  }}
                  > 
                    <OrganisationTable
                      testId="organizationTable"
                      tableColumns={tableColumns}
                      data={tableData ?? []}
                      totalRecords={totalRows}
                      pagination={filter}
                      setPagination={setFilter}
                      themeType={themeType}
                      handlePaginationChange={handlePaginationChange}
                    />
                  </Paper>
                </Grid>
                {selectedOrganisation && (
                  <Grid item sm={3} xs={6} sx={{ p: 1, pt: 0 }}>
                    {/* <DetailCard
                  cardData={cardData}
                  onClose= { ()=>{
                     setSelectedOrganisation(null);
                  }}
                /> */}
                    <OrganisationDetailCard
                      selectedOrg={selectedOrganisation}
                      onClose={() => {
                        setSelectedOrganisation(null);
                      }}
                      userDetails={userDetails}
                    />
                  </Grid>
                )}
              </Grid>
            )}
          </>
        )}
      </Grid>
      {actionIndex != null && (
        <Menu
          data-testid="menu"
          id="basic-menu"
          anchorEl={anchorEl}
          open={actionIndex != null}
          onClose={handleCloseAction}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
          sx={{
            display:
              actionIndex != null ? "block !important" : "none !important",
          }}
        >
          <MenuItem
            key={1}
            onClick={() => {
              // showAddOrganisation(true)
              // setSelectedOrganisation(tableData[tableIndex]);
              handleClose(Actions.EDIT);
            }}
          >
            Edit
          </MenuItem>
          <MenuItem
            key={3}
            onClick={() =>
              handleClose(
                tableData[actionIndex].isApproved
                  ? Actions.BLOCK
                  : Actions.ACTIVE
              )
            }
          >
            {tableData[actionIndex].isApproved ? "Block" : "Active"}
          </MenuItem>
          <MenuItem
            data-testid="delete"
            key={4}
            onClick={() => handleClose(Actions.DELETE)}
          >
            Delete
          </MenuItem>
        </Menu>
      )}

      {deleteDisableBlock && (
        <DisableDeleteOrganization
          testId="deletePopUp"
          open={editBlock}
          hide={() => {
            setDeleteDisableBlock(false);
          }}
          onSubmit={handleActionSubmission}
          tableData={tableData}
          checkedObj={checkedObj}
          action={actionStatus}
          refresh={handleRefresh}
          selectStylesOverride={selectStylesOverride(
            isDarkThemeEnabledSelector
          )}
          // callFetchUsers={clearSearch}
          setEditBlock={() => {
            setDeleteDisableBlock(false);
          }}
          setSelectedTemplates={setSelectedTemplates}
          selectedTemplates={selectedTemplates}
          setCheckedObj={setCheckedObj}
          pageLimits={filter.limit}
          pageNumber={filter.page}
          status={filter.approved}
        />
      )}
      {showAddOrganisation && (
        <AddOrganisationModal
          show={showAddOrganisation}
          handleClose={() => {
            setShowAddOrganisation(false);
            setSelectedOrg();
            setCheckedObj([]);
          }}
          open={showAddOrganisation}
          hide={() => {
            setShowAddOrganisation(false);
          }}
          themeType={themeType}
          selectStylesOverride={selectStylesOverride(
            isDarkThemeEnabledSelector
          )}
          refresh={handleRefresh}
          selectedOrg={selectedOrg}
          // permissions={userPermissionRoles.permissions}
          action={actionStatus}
        />
      )}
    </>
  );
};

export default Organisation;
