import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Table,
  Typography,
  Breadcrumbs,
  Tooltip
} from "@mui/material";
import { useHistory, useParams, Link } from "react-router-dom/cjs/react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState, useRef } from "react";
import DataTable from "./components/DataTable";
import { Flag, Upload } from "@mui/icons-material";
import { getTripSummary, fetchAddressFromCoords, getTripPerformanceData, userDetails, emptyTripSummary, getTripReportKeys } from "../../../redux/actions";
import { capitalizeSentence, convertIntoGivenTimezone, showToast } from "../../../services/functions";
import ViaPointsModal from "./components/ViaPointsModal";
import loaderGif from "../../../static/images/drawer/loader.gif";
// import DoughnutChart from "../../Components/DoughnutChart/DoughnutChart";
import DonutChart from "../../Components/D3/DonutChart";
import { graphColors } from "../../../services/constants";
import { BarChart } from "../../Components/BarChart/BarChart";
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import DownloadIcon from "@mui/icons-material/Download";
import EmptyPage from "../EmptyPage";
import ThreeDotsLoader from "../../Components/ThreeDots/ThreeDotsLoader";
import TripReport from "./components/ReportModal";
const TripSummary = (props) => {
  let propsShared = props?.location?.state?.currentDeviceId
  const dispatch = useDispatch()
  const history = useHistory();
  const isDarkThemeEnabledSelector = useSelector(
    (state) => state.theme.themeDark
  );
  const themeType = isDarkThemeEnabledSelector
    ? require("../../../static/styles/darktheme")
    : require("../../../static/styles/theme");
  const summarDetails = useSelector((state) => state.tripsData?.tripSummary || []);
  const performanceDetails = useSelector((state) => state.tripsData.tripPerformanceData || []);
  const [trackData, setTrackData] = useState([])
  const [viaModal, setViaModal] = useState(false)
  const [summaryLoader, setSummaryLoader] = useState(false)
  const [eventSummaryLoader, setEventSummaryLoader] = useState(false)
  const [viaData, setViaData] = useState([])
  const [errMsg, setErrMssg] = useState('')
  const [d3DougnutArray, setD3DougnutArray] = useState([]);
  const [eventsCheck, setEventsCheck] = useState(true)
  const { textClasses, buttonClasses } = themeType;
  const [summaryData, setSummaryData] = useState({})
  const [eventsPagination, setEventsPagination] = useState(false)
  const themeBorderColor = isDarkThemeEnabledSelector ? "#282828" : "#D9D9D9";
  const userTimeZone = useSelector((state) => state.auth.currentUserDetail);
  const [reportsModal, setReportsModal] = useState(false)
  const reportKeys = useSelector((state) => state.tripsData.reportKeys);
  const [eventsFilter, setEventsFilter] = useState({
    page: 1,
    limit: 10,
  });
  // const pageref = useRef(false);
  // useEffect(() => {
  //   // Code to run only once on mount
  //   pageref.current = true;

  //   // Cleanup function to run on unmount
  //   return () => {
  //     pageref.current = false;
  //   };
  // }, []);

  // useEffect(() => {
  //   if (Object.keys(summarDetails)?.length) {
  //     setSummaryData(summarDetails);

  //     let tempSummary = [];
  //     if (summarDetails && summarDetails?.trackEventData?.length) {
  //       tempSummary = summarDetails.trackEventData;
  //     }
  //     setTrackData([...tempSummary]);
  //     // pageref.current = true;
  //   }
  // }, [summarDetails]);

  let { id } = useParams();

  useEffect(() => {
    if (id) {
      let payload = {
        projection: ['event', 'trip', 'analytics'],
        trip_id: id,
        "dataType": "few"
      }
      dispatch(emptyTripSummary())
      setSummaryLoader(true)
      dispatch(getTripSummary({ ...payload, deviceId: propsShared }, (res) => {
        if (!res.success) {
          setErrMssg(res?.message)
          setTrackData([])
        }
        else {
          setEventsPagination(true)
          setSummaryData(res?.data);

          let tempSummary = [];
          if (res && res?.data && res?.data?.trackEventData?.length) {
            tempSummary = res?.data?.trackEventData;
          }
          setTrackData([...tempSummary]);
        }
        setSummaryLoader(false);
      })
      );
      dispatch(getTripPerformanceData({ trip_id: id, deviceId: propsShared }))
    }
  }, [id])



  const getAddressFromCoords = (i, item) => {
    let temp = [...summaryData?.trackEventData]
    const payload = {
      latitude: item?.lat,
      longitude: item?.lng,
      appType: "GOOGLE",
    };
    dispatch(fetchAddressFromCoords(payload, (res) => {
      if (res && res?.length > 0) {
        const { address } = res[0];

        if (temp[i]) {
          temp[i] = { ...temp[i], address: address };
        }
        setTrackData([...temp]);
      }
    })
    );
  }

  const columns = [

    {
      header: "#",
      cell: (entry, key) => {
        return (
          <Typography
            sx={{
              ...textClasses.t13n,
              textAlign: "center",
              textTransform: "capitalize",
            }}
          >
            {(eventsFilter?.page - 1) * eventsFilter?.limit + key + 1}
          </Typography>
        );
      },
    },
    {
      header: "Date-Time",
      cell: (entry) => {
        return (
          <Typography
            sx={{
              ...textClasses.t13n,
              textAlign: "center",
              textTransform: "capitalize",
            }}
          >
            {convertIntoGivenTimezone(entry?.date, userTimeZone.timeZone.offset) ?? "-"}
          </Typography>
        );
      },
    },
    {
      header: "Event",
      cell: (entry) => {
        const events = entry?.event?.split(',') || ["-"];

        return (
          <div>
            {events.map((event, index) => (
              <Typography
                key={index}
                sx={{
                  ...textClasses.t13n,
                  textAlign: "center",
                  textTransform: "capitalize",
                  display: "block",
                  margin: "5px 0"
                }}
              >
                {event.trim()}
              </Typography>
            ))}
          </div>
        );
      },
    },
    {
      header: "Status",
      cell: (entry) => {
        return (
          <Typography
            sx={{
              ...textClasses.t13n,
              textAlign: "center",
              textTransform: "capitalize",
            }}
          >
            {capitalizeSentence(entry?.lockStatus) ?? "-"}
          </Typography>
        );
      },
    },
    {
      header: "Location",
      cell: (entry, key) => {
        return (
          <Typography
            sx={{
              ...textClasses.t13n,
              textAlign: "center",
              textTransform: "capitalize",
              cursor: 'pointer',
            }}
            onClick={() => getAddressFromCoords(key, entry)}
          >
            {entry?.address && Object.keys(entry.address).length !== 0 ? (
              <Grid sx={{ display: 'flex', maxWidth: '250px', margin: 'auto', justifyContent: 'center', alignItems: 'center' }}>
                <Typography
                  sx={{
                    ...textClasses.t13n,
                    textAlign: "center",
                    cursor: 'pointer',
                    // color: '#ff7200',
                    textDecoration: 'none',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexWrap: 'wrap',
                    whiteSpace: 'initial',
                    // color: '#000'
                  }}
                  onClick={() => getAddressFromCoords(key, entry)}
                > {entry.address}
                </Typography>
              </Grid>
            ) : (
              <Typography sx={{
                fontSize: '13px', '&:hover': {
                  color: '#ff7200',
                  textDecoration: 'none',
                },
              }}>{entry?.lat ?? "-"} / {entry?.lng ?? "-"}</Typography>
            )}
            <br />
            {entry?.geo_name && entry?.geo_name !== 'nil' ? (
              <Tooltip
                arrow
                title={entry.geo_name}
                placement="top"
              >
                <Typography
                  sx={{
                    fontSize: '13px', fontWeight: '600', '&:hover': {
                      color: '#000',
                      cursor: 'pointer',
                    },
                  }}
                >
                  {entry.geo_name.length > 20
                    ? entry.geo_name.substring(0, 20) + "..."
                    : entry.geo_name}
                </Typography>
              </Tooltip>
            ) : (
              <Typography
                sx={{
                  fontSize: '13px', fontWeight: '600', '&:hover': {
                    color: '#000',
                    cursor: 'pointer',
                  },
                }}
              >
                -
              </Typography>
            )}
          </Typography>
        );
      },
    },
    summaryData?.tripData?.detailsDeviceID?.assetType === 'elock' || summaryData?.tripData?.detailsDeviceID?.assetType === 'assetTracker' ?
      {
        header: "Battery",
        cell: (entry) => {
          return (
            <Typography
              sx={{
                ...textClasses.t13n,
                textAlign: "center",
                textTransform: "capitalize",
              }}
            >
              {entry?.battery ? entry?.battery + ' %' : "-"}
            </Typography>
          );
        },
      } : null,
    {
      header: "Speed",
      cell: (entry) => {
        return (
          <Typography
            sx={{
              ...textClasses.t13n,
              textAlign: "center",
              textTransform: "capitalize",
            }}
          >
            {entry?.speed ?? "-"}
          </Typography>
        );
      },
    },
  ];
  const getStatus = (id, item) => {
    let res = item?.tripData?.devices?.find(item => item.deviceId === id)?.status
    if (res === 'in_transit') {
      return "In-Transit"
    }
    else {
      return capitalizeSentence(res)
    }
  }
  const details = [
    {
      title: "Vehicle Number",
      key: "vehicleNo",
      value: summaryData && summaryData?.tripData && summaryData?.tripData?.vehicleDetails ? summaryData?.tripData?.vehicleDetails?.vehicleNumber : '-',
    },
    {
      title: "Trip Id",
      key: "tripId",
      value: summaryData && summaryData?.tripData ? summaryData?.tripData?.tripId : '-',
    },
    {
      title: "Device Id",
      key: "client_id",
      value: propsShared || '-',
    },
    {
      title: "Trip Status",
      key: "status",
      value: getStatus(propsShared, summaryData)
    },
    {
      title: "Remarks",
      key: "remarks",
    },
  ];
  const table1 = [
    {
      title: "Total Trip Time",
      key: "totalTripTime",
      value: summaryData && summaryData?.analyticsData && summaryData?.analyticsData?.total_trip_time ? summaryData?.analyticsData?.total_trip_time : '-',
    },
    {
      title: "Total Stoppage Time",
      key: "Total Stoppage Time",
      value: summaryData && summaryData?.analyticsData && summaryData?.analyticsData?.total_stoppage_time ?
        (summaryData.analyticsData.total_stoppage_time === 'NaN:NaN:NaN' ?
          '0' :
          summaryData.analyticsData.total_stoppage_time) :
        '-'
    },

    {
      title: "Total Moving Time",
      key: "totalTripTime",
      value: summaryData && summaryData?.analyticsData && summaryData?.analyticsData?.total_moving_time ? summaryData?.analyticsData?.total_moving_time : '-',
    },
    {
      title: "Total Distance Covered",
      key: "totalDistanceCovered",
      value: summaryData && summaryData?.analyticsData && summaryData?.analyticsData?.total_distance_covered ? summaryData?.analyticsData?.total_distance_covered + ' kms' : '-',
    },
    {
      title: "Average Speed",
      key: "averageSpeed",
      value: summaryData && summaryData?.analyticsData && summaryData?.analyticsData?.average_speed ? summaryData?.analyticsData?.average_speed + ' kmph' : '-',
    },
    {
      title: "Average Moving Speed",
      key: "averageMovingSpeed",
      value: summaryData?.analyticsData?.average_moving_speed ?
        (summaryData.analyticsData.average_moving_speed === 'NaN' || summaryData.analyticsData.average_moving_speed === 'Infinity' ?
          '0 kmph' :
          `${summaryData.analyticsData.average_moving_speed} kmph`) :
        '-'
    },
    {
      title: "Average Transit Journey Speed",
      key: "averageTransitJourneySpeed",
      value: summaryData && summaryData?.analyticsData && summaryData?.analyticsData?.average_transit_journey_speed ? summaryData?.analyticsData?.average_transit_journey_speed + ' kmph' : '-',
    },
    {
      title: "Time Spent in Origin",
      key: "timeSpentInOrigin",
      value: summaryData && summaryData?.analyticsData && summaryData?.analyticsData?.time_spent_in_origin ? summaryData?.analyticsData?.time_spent_in_origin : '-',
    },
    {
      title: "Time Spent in Transit",
      key: "timeSpentInTransit",
      value: summaryData && summaryData?.analyticsData && summaryData?.analyticsData?.time_spent_in_transit ? summaryData?.analyticsData?.time_spent_in_transit : '-',
    },
    {
      title: "Time Spent in Destination",
      key: "timeSpentInDestination",
      value: summaryData && summaryData?.analyticsData && summaryData?.analyticsData?.time_spent_in_destination ? summaryData?.analyticsData?.time_spent_in_destination : '-',
    },
    {
      title: "Time Spent in Via Points",
      key: "timeSpentInViaPoints",
      value: summaryData && summaryData?.analyticsData && summaryData?.analyticsData?.time_spent_in_viapoints ? summaryData?.analyticsData?.time_spent_in_viapoints[summaryData?.analyticsData?.time_spent_in_viapoints.length - 1]?.totalTimeSpentInViaPoints : '-',
      data: summaryData && summaryData?.analyticsData && summaryData?.analyticsData?.time_spent_in_viapoints ? summaryData?.analyticsData?.time_spent_in_viapoints : []
    },
    {
      title: "Origin - Destination",
      key: "originDestination",
      value: summaryData && summaryData?.analyticsData && summaryData?.analyticsData?.origin_destination ? summaryData?.analyticsData?.origin_destination : '-',
    },
    {
      title: "Sealing Time",
      key: "sealingTime",
      value: summaryData && summaryData?.analyticsData && summaryData?.analyticsData?.sealing_time
        ? convertIntoGivenTimezone(summaryData?.analyticsData?.sealing_time, userTimeZone.timeZone.offset)
        : '-'

    },
    {
      title: "Trip Begin Transit Time",
      key: "tripBeginTransitTime",
      value: summaryData && summaryData?.analyticsData && summaryData?.analyticsData?.trip_begin_transit_time
        ? convertIntoGivenTimezone(summaryData?.analyticsData?.trip_begin_transit_time, userTimeZone.timeZone.offset)
        : '-'
    },
    {
      title: "Arrival Time",
      key: "arrivalTime",
      value: summaryData && summaryData?.analyticsData && summaryData?.analyticsData?.arrival_time ?
        convertIntoGivenTimezone(summaryData?.analyticsData?.arrival_time, userTimeZone.timeZone.offset)
        : '-',
    },
    {
      title: "Trip Completed Time",
      key: "completedTime",
      value: summaryData && summaryData?.analyticsData && summaryData?.analyticsData?.trip_completed_time ?
        convertIntoGivenTimezone(summaryData?.analyticsData?.trip_completed_time, userTimeZone.timeZone.offset) : '-'
    },

  ];
  const moveBack = () => {
    history.goBack()
  }
  const openViaModal = (item, i) => {
    setViaModal(true)
    setViaData(item?.data)
  }

  const calculatePercentage = (time, item) => {
    if (item === 'duration') {
      if (time && summaryData?.analyticsDataChart) {
        let timeInDestination = summaryData?.analyticsDataChart?.time_spent_in_destination || 0
        let timeInOrigin = summaryData?.analyticsDataChart?.time_spent_in_origin || 0
        let timeInTransit = summaryData?.analyticsDataChart?.time_spent_in_transit || 0

        let totalTime = Number(timeInDestination) + Number(timeInOrigin) + Number(timeInTransit)

        let res = ((time / totalTime) * 100).toFixed(2);

        return res;
      }
    }
    else if (item === 'time') {
      if (time && summaryData?.analyticsDataChart) {
        let movingTime = summaryData?.analyticsDataChart?.total_moving_time
        let stoppageTime = summaryData?.analyticsDataChart?.total_stoppage_time
        let totalTime = movingTime + stoppageTime
        let res = ((time / totalTime) * 100).toFixed(2);

        return res;
      }
    }
    else return '-';

  }

  useEffect(() => {
    let d3Array = [];
    if (summaryData && summaryData?.analyticsDataChart || {}) {
      let tripDuartion = [
        { label: "In Origin", value: calculatePercentage(summaryData?.analyticsDataChart?.time_spent_in_origin, 'duration'), color: graphColors?.blue, param: 'percentage' },
        { label: "In Transit", value: calculatePercentage(summaryData?.analyticsDataChart?.time_spent_in_transit, 'duration'), color: graphColors?.orange, param: 'percentage' },
        { label: "In Destination", value: calculatePercentage(summaryData?.analyticsDataChart?.time_spent_in_destination, 'duration'), color: graphColors?.brightGreen, param: 'percentage' },
        { chartTitle: "Trip Duration" }
      ];
      let moving_Stoppage = [
        { label: "Moving Time", value: calculatePercentage(summaryData?.analyticsDataChart?.total_moving_time, 'time'), color: graphColors?.brightGreen, param: 'percentage' },
        { label: "Stoppage", value: calculatePercentage(summaryData?.analyticsDataChart?.total_stoppage_time, 'time'), color: graphColors?.red, param: 'percentage' },
        { chartTitle: "Total Moving vs\n Total Stoppage" }
      ];

      d3Array.push(
        [...tripDuartion],
        [...moving_Stoppage]
      );
    }
    setD3DougnutArray(d3Array);
  }, [summaryData, summaryData.analyticsDataChart]);

  const onCheck = (e) => {
    let status = e.target.checked
    if (status) {
      setEventSummaryLoader(true);
      setEventsCheck(true)
      let payload = {
        projection: ['event'],
        trip_id: id,
        "dataType": "few"
      }
      dispatch(getTripSummary({ ...payload, deviceId: propsShared }, (res) => {
        if (!res.success) {
          setErrMssg(res?.message)
          setTrackData([])
        }
        setEventSummaryLoader(false);
        setSummaryData({ ...summaryData, trackEventData: res?.data?.trackEventData, count: res?.data?.count })

        setEventsFilter({
          page: 1,
          limit: 10,
        })


        setTrackData([...res?.data?.trackEventData]);
      })
      );
    }
    else {
      setEventSummaryLoader(true);
      setEventsCheck(false)
      let payload = {
        projection: ['event'],
        trip_id: id,
        "page": 1,
        "limit": 10,
        "dataType": "all"
      }
      dispatch(getTripSummary({ ...payload, deviceId: propsShared }, (res) => {
        if (!res.success) {
          setErrMssg(res?.message)
          setTrackData([])
        }
        setSummaryData({ ...summaryData, trackEventData: res?.data?.trackEventData, count: res?.data?.count })
        setTrackData([...res?.data?.trackEventData]);
        setEventSummaryLoader(false);
        setEventsFilter({
          page: 1,
          limit: 10,
        })

      })
      );
    }

  }

  const getReportFields = () => {
    let payload = {
      "reportType": "trip summary",
      "keyType": "all"

    }
    dispatch(
      getTripReportKeys({ ...payload }, (res) => {
        if (res?.success) {
          setReportsModal(true)
        }
        else {
          showToast(res?.message, true)
        }
      })
    );
  }


  return (
    <>

      {summaryLoader ? (
        <Grid
          container
          item
          direction={"column"}
          justifyContent={"center"}
          alignItems="center"
          xs={12}
          sx={{ p: 15 }}
        >
          <Grid item>
            <img data-testid="loaderImg" src={loaderGif} />
          </Grid>
        </Grid>
      ) : (
        <>
          {/* {trackData && trackData.length === 0 ? (
            <Grid container justifyContent={"center"} height={'85vh'} alignItems="center">
              <EmptyPage body={errMsg} />
            </Grid>
          ) : ( */}
          <Grid container>
            <Grid item={true} sm={12} xs={12} >
              <Grid container>
                <Grid item={true} sm={6} xs={4} >
                  <Typography sx={{ ...textClasses.cardTitle }}>
                    Trip Summary
                  </Typography>
                </Grid>
                <Grid item={true} sm={6} xs={6} sx={{ displey: 'flex', display: 'flex', justifyContent: 'flex-end', height: '36px' }}  >
                  {/* <Button
              sx={{ ...buttonClasses.lynkitOrangeFill, marginRight: '15px' }}
              startIcon={<Upload />}
            >
              Export to Excel
            </Button> */}
                  {/* <Button onClick={() => moveBack()} variant='outlined' size='small' sx={{ ...buttonClasses.small, color: buttonClasses.lynkitBlackFill }}>Back</Button> */}
                </Grid>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12}>
                <Grid>
                  <Breadcrumbs sx={{ mb: 1 }}>
                    <Typography
                      sx={{
                        ...textClasses.normalText,
                        fontSize: "12px",
                        color: themeType.default.themeOrange,
                      }}
                    >
                      <Link
                        style={{ color: themeType.default.themeOrange, textDecoration: "none" }}
                        to="/dashboard/trips"
                      >
                        Trips
                      </Link>
                    </Typography>

                    <Typography
                      sx={{
                        ...textClasses.normalText,
                        fontSize: "12px",
                        color: themeType.default.themeOrange,
                      }}
                    >
                      <Link
                        style={{ color: themeType.default.themeOrange, textDecoration: "none" }}
                        to={`/dashboard/trips/trip-map/${id}?id=${propsShared}`}
                      >
                        Trip Map
                      </Link>
                    </Typography>

                    <Typography
                      sx={{ ...textClasses.normalText, fontSize: "12px" }}
                    >
                      Trip Summary
                    </Typography>
                  </Breadcrumbs>
                </Grid>
                <Card>
                  <CardContent sx={{ padding: "0px !important" }}>
                    <Box display="flex" alignItems="center" width="100%">
                      {summaryData?.tripData && details?.map((detail, i) => {
                        return (
                          <Box
                            key={i}
                            width="100%"
                            p={2}
                            borderRight={
                              i !== details?.length - 1
                                ? `1px solid ${themeBorderColor}`
                                : "none"
                            }
                            marginRight={i !== details?.length - 1 ? 1 : 0}
                          >
                            <Typography
                              sx={{ ...textClasses.normalText, fontWeight: 700 }}
                            >
                              {detail?.title}
                            </Typography>
                            <Typography sx={{ ...textClasses.normalText }}>
                              {detail?.value || "-"}
                            </Typography>
                          </Box>
                        );
                      })}
                    </Box>
                  </CardContent>
                </Card>

                {!summaryLoader ?
                  <Card sx={{ mt: 3, }}>
                    <CardContent>
                      <Box display="flex" gap={1}>
                        <Box width="100%">
                          <Table>
                            {table1?.map((_, i) => {
                              return (
                                <tr key={i}>
                                  <td
                                    style={{
                                      padding: "10px",
                                      border: `1px solid ${themeBorderColor}`,
                                    }}
                                  >
                                    <Typography
                                      sx={{ ...textClasses.normalText, fontWeight: 700 }}
                                    >
                                      {_?.title}
                                    </Typography>
                                  </td>
                                  <td
                                    style={{
                                      padding: "10px",
                                      paddingRight: "30px",
                                      border: `1px solid ${themeBorderColor}`,
                                    }}
                                  >
                                    <Typography sx={{ ...textClasses.normalText, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                      {_?.value || '-'}
                                      {_.title === 'Time Spent in Via Points' ? _.data.length ?
                                        <div className="outlined-theme-btn" style={{ margin: '0px', marginLeft: '6px' }} onClick={() => openViaModal(_, i)}><i class="fa fa-info" aria-hidden="true"></i>
                                        </div> : null
                                        : null}
                                    </Typography>

                                  </td>
                                </tr>
                              );
                            })}
                          </Table>
                        </Box>
                        <Box className='graphBox' width="100%" justifyContent='center' display="flex" alignItems="center">
                          <Grid container justifyContent="center" mt={2}>
                            <DonutChart data={d3DougnutArray} widthProp={6} />
                          </Grid>
                        </Box>
                      </Box>
                      {trackData && trackData?.length ?
                        
                          <>
                            <Grid sx={{ border: '1px solid', borderColor: theme => theme.palette.divider, display: 'flex', justifyContent: 'flex-end' }} mt={2} mb={1}>
                              <Button
                                sx={{
                                  ...buttonClasses.lynkitBlackFill,
                                  minHeight: "30px",
                                  margin: 2,
                                  width: '96px',
                                }}
                                startIcon={<DownloadIcon />}
                                onClick={() => {
                                  getReportFields()
                                }}
                              >
                                Report
                              </Button>



                              <FormControlLabel
                                control={
                                  <Switch
                                    checked={eventsCheck}
                                    onChange={onCheck}
                                    name="eventsCheck" />
                                }
                                label="Events"
                              />
                            </Grid>
                            
                            <Box sx={{ width: "100%", height: '52vh', display: 'block', overflow: 'auto' }}>
                              <DataTable
                                tableColumns={columns}
                                data={trackData || []}
                                themeType={themeType}
                                eventsFilter={eventsFilter}
                                setEventsFilter={setEventsFilter}
                                summarDetails={summaryData}
                                eventsCheck={eventsCheck}
                                tripId={id}
                                propsShared={propsShared}
                                setErrMssg={setErrMssg}
                                setTrackData={setTrackData}
                                summaryLoader={eventSummaryLoader}
                                setSummaryLoader={setEventSummaryLoader}
                                eventsPagination={eventsPagination}
                                summaryData={summaryData}
                                setSummaryData={setSummaryData}
                              />
                            </Box>
                          </>
                        :
                        <Grid sx={{ height: '65px', display: 'flex', justifyContent: 'center', alignItems: 'end' }} >
                          <span>No data found..</span>
                        </Grid>
                      }
                    </CardContent>
                  </Card>
                  :
                  <Grid
                    container
                    item
                    direction={"column"}
                    justifyContent={"center"}
                    alignItems="center"
                    xs={12}
                    sx={{ p: 15 }}
                  >
                    <Grid item>
                      <img src={loaderGif} />
                    </Grid>
                  </Grid>
                }
              </Grid>
            </Grid>
          </Grid>
          {/* )} */}
        </>
      )}


      {viaModal && (
        <ViaPointsModal
          open={viaModal}
          hide={() => {
            setViaModal(false);
          }}
          viaData={viaData}
        />
      )}

      {reportsModal && (
        <TripReport
          open={reportsModal}
          hide={() => {
            setReportsModal(false);
          }}
          setReportsModal={setReportsModal}
          reportKeys={reportKeys}
          type='tripSummary'
          tripDeviceId={{ tripId: id, deviceId: propsShared }}
          eventsCheck={eventsCheck}
        // filter={filter}
        // reportKeysLoader={reportKeysLoader}
        />
      )}
    </>
  );
};

export default TripSummary;
