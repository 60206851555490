
import {
  Tab,
  Tabs,
  Typography,
  Box,
  Button,
  // Typography,
  FormControl,
  OutlinedInput,
  InputAdornment,
  CircularProgress,
  Card,
  CardHeader,
  IconButton,
  CardContent,
} from "@mui/material";
import React, { useState } from "react";
import { useSelector } from "react-redux";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const GenericFilterTabs = ({ filter, setFilter, count, isFilterApplied = false, isUserRole, module }) => {
  const isDarkThemeEnabledSelector = useSelector(state => state.theme.themeDark);
  const themeType = isDarkThemeEnabledSelector ? require('../../../static/styles/darktheme') : require('../../../static/styles/theme');
  const {
    buttonClasses,
    inputClasses,
    textClasses,
  } = themeType;

  const [tabIndex, setTabIndex] = useState(0);

  const handleTabChange = (event, value) => {
    setTabIndex(value)
  }

  return (
    <Box sx={{ borderBottom: 1, borderColor: "divider", display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
      <Tabs
        value={tabIndex}
        aria-label="disabled tabs example"
        onChange={handleTabChange}
        style={{ height: "20px" }}
        sx={{
          "& .MuiTabs-indicator": {
            backgroundColor: themeType.default.themeOrange,
          },
          "& .MuiTab-root.Mui-selected": {
            color: themeType.default.themeOrange,
          },
        }}
      >
        <Tab
          data-testid="allMember"
          label={`All ${module} (${count?.total || 0}) `}
          {...a11yProps(0)}
          sx={{ fontSize: "14px", textTransform: "none" }}
          onClick={() => {
            if (isUserRole) {
              const { isActive, ...updatedFilter } = filter;
              setFilter(updatedFilter);
            } else {
              const { isApproved, ...updatedFilter } = filter;
              setFilter(updatedFilter);
            }
          }}
        />
        <Tab
          data-testid="active"
          label={`Active (${count?.active || 0})`}
          {...a11yProps(1)}
          sx={{ fontSize: "14px", textTransform: "none" }}
          onClick={() => {
            if (isUserRole) {
              setFilter({ ...filter, isActive: true })
            } else {
              setFilter({ ...filter, isApproved: true })
            }
          }}
        />
        <Tab
          data-testid="block"
          label={`Blocked (${count?.disabled || 0})`}
          {...a11yProps(2)}
          sx={{ fontSize: "14px", textTransform: "none" }}
          onClick={() => {
            if (isUserRole) {
              setFilter({ ...filter, isActive: false })
            } else {
              setFilter({ ...filter, isApproved: false })
            }
          }}
        />
      </Tabs>
      {isFilterApplied && <Button variant="text">
        <Typography
          data-testid='clear'
          sx={{
            ...textClasses.boldText,
            color: themeType.default.themeOrange, textDecoration: "underline", cursor: "pointer",
            textTransform: "none"
          }}
          onClick={() => {
            if (isUserRole) {
              const { isActive } = filter;
              setFilter({ isActive, page: 1, limit: 10 })
            } else {
              const { isApproved } = filter;
              setFilter({ isApproved, page: 1, limit: 10 })
            }
          }}
        >
         Clear All Filter(s){``}
        </Typography>
      </Button>}
    </Box>
  )
}


export default GenericFilterTabs;