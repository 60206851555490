import React, { useState } from "react";
import {
  Button,
  Typography,
  IconButton,
  Dialog,
  DialogContent,
  Grid,
  FormControl,
  OutlinedInput,
} from "@mui/material";
import theme, {
  buttonClasses,
  textClasses,
} from "../../../../static/styles/theme";
import { Cancel } from "@mui/icons-material";
import Actions, { getDisplayValue } from "../../../../utils/constants";
import { useCallback } from "react";

const DisableDeleteUserRole = ({
  selectedCard,
  action,
  onSubmit,
  onHide,
  selectStylesOverride,
  show,
}) => {
  const [comment, setComment] = useState("");

  const handleCloseConfirmModal = useCallback(() => {
    onHide();
    setComment("");
  }, [onHide, setComment]);

  const handleAction = useCallback(
    (index) => {
      onSubmit(comment);
      setComment("");
    },
    [onSubmit, setComment]
  );

  return (
    <Dialog
      open={show}
      onClose={handleCloseConfirmModal}
      scroll={"paper"}
      PaperProps={{ sx: { width: "100%" } }}
      maxWidth={"sm"}
    >
      <DialogContent>
        <Grid container justifyContent={"center"} alignItems="center">
          <Typography sx={{ ...textClasses.cardTitle }}>
            Are you Sure ?
          </Typography>
        </Grid>
        <Grid container justifyContent={"center"} alignItems="center">
          <Typography sx={{ ...textClasses.cardTitle }}>
            {getDisplayValue(action)} the selected User role(s)
          </Typography>
        </Grid>
        <Grid container sx={{ mt: 1 }} spacing={1}>
          <Grid item sm={4} xs={6}>
            <Grid
              container
              direction={"row"}
              justifyContent="center"
              alignItems={"center"}
              sx={{
                backgroundColor: theme.themeLightOrange,
                border: `1px solid ${theme.themeOrange}`,
                borderRadius: "12px",
                pl: 1,
                pr: 1,
                minHeight: "40px",
              }}
            >
              <Grid item sm={10} xs={8}>
                <Typography
                  sx={{
                    ...textClasses.normalText,
                    color: theme.themeOrange,
                    textAlign: "start",
                  }}
                >
                  {selectedCard.name && selectedCard.name.length > 15
                    ? selectedCard.name.substring(0, 15) + "..."
                    : selectedCard.name}
                </Typography>
              </Grid>
              {/* {handleRemoveSelection && (
                    <Grid item sm={2} xs={4}>
                      <IconButton
                        sx={{ alignContent: "right" }}
                        // onClick={() => handleRemoveSelection()}
                      >
                        <Cancel sx={{ color: theme.themeOrange }} />
                      </IconButton>
                    </Grid>
                  )} */}
            </Grid>
          </Grid>
        </Grid>
        {(action === Actions.BLOCK ||
          action === Actions.ACTIVE ||
          action === Actions.DELETE) && (
          <Grid>
            <FormControl
              size="small"
              fullWidth
              sx={{ ...selectStylesOverride, mt: 2 }}
            >
              <OutlinedInput
                data-testid="inputElem"
                value={comment}
                onChange={(e) => {
                  setComment(e.target.value);
                }}
                type="text"
                sx={{
                  ...selectStylesOverride,
                  fontSize: "12px",
                  boxShadow: 1,
                  "& input::placeholder": {
                    fontSize: "14px",
                    fontWeight: "400",
                  },
                  borderRadius: "10px",
                }}
                placeholder="Write Comment, if any"
              />
            </FormControl>
          </Grid>
        )}
        <Grid container sx={{ p: 1, mt: 3 }} justifyContent="center">
          <Button
            variant="outlined"
            sx={{
              ...buttonClasses.lynkitBlackFill,
              minWidth: "150px",
            }}
            onClick={handleCloseConfirmModal}
          >
            No, Cancel
          </Button>

          <Button
            variant="contained"
            size="small"
            sx={{
              ml: 1,
              ...buttonClasses.lynkitOrangeFill,
              minWidth: "150px",
            }}
            onClick={handleAction}
          >
            Submit
          </Button>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default DisableDeleteUserRole;
