import { Badge, Box, Button, Grid, IconButton, Typography } from "@mui/material";
import { DateTimePicker, PickersDay } from "@mui/x-date-pickers";
import {
  dateTimePickerSlotProps,
  selectStylesOverride,
} from "../../../../utils/util";
import React from "react";
import { ArrowBack, FiberManualRecordTwoTone } from "@mui/icons-material";
import history from "../../../../services/history";
import CustomSubmitBtn from "../../../../components/FormUI/CustomSubmitBtn";
import { format } from "date-fns";
import { convertIntoGivenTimezone } from "../../../../services/functions";

const BsjCameraPlaybackDrawer = ({
  themeType,
  isDark,
  handleTimeBtnClicked,
  handleEventsPlayback,
  handleCameraPlayback,
  filters,
  setFilters,
  tripData,
  fiveMinbtnLoader,
  tenMinbtnLoader,
  thirtyMinbtnLoader,
  timeIntervalLoader,
  alertsLoader,
  currentUserDetail
}) => {
  const { buttonClasses, inputClasses } = themeType;
  function convertToISTFormat(unixTimestamp) {
    if (!unixTimestamp) {
      return null; // Handle missing timestamp
    }

    const date = new Date(unixTimestamp);

    try {
      const timezoneOffset = currentUserDetail?.timeZone.offset || 0; // Handle missing offset
      const formattedDate = convertIntoGivenTimezone(date, timezoneOffset);

      const dateParts = formattedDate.split("/");
      const year = parseInt(dateParts[2], 10);
      const month = parseInt(dateParts[1], 10) - 1; // Months are 0-indexed
      const day = parseInt(dateParts[0], 10);

      const timeParts = formattedDate.split(" ")[1].split(":");
      const hours = parseInt(timeParts[0], 10);
      const minutes = parseInt(timeParts[1], 10);
      const seconds = parseInt(timeParts[2], 10);

      const desiredTime = new Date(
        year,
        month,
        day,
        hours,
        minutes,
        seconds,
        0
      );
      return desiredTime;
    } catch (error) {
      console.error("Error parsing date string:", error);
      return null; // Handle parsing errors
    }
  }

  let tripStartTime = tripData?.detailsDeviceID ? convertToISTFormat(tripData?.detailsDeviceID?.time, "start") : null;
  let tripEndTime = tripData?.endTime ? convertToISTFormat(tripData?.endTime) : null;

  const ServerDay = (props) => {
    const { highlightedDays = [], day, outsideCurrentMonth, ...other } = props;
    const isStartDate = tripStartTime ? format(tripStartTime || new Date(), "MM/dd/yyyy") : null;
    const isEndDate = tripEndTime ? format(tripEndTime || new Date(), "MM/dd/yyyy") : null;
    const formattedDate = format(new Date(day), "MM/dd/yyyy");

    return (
      <Badge
        key={props.day.toString()}
        overlap="circular"
        badgeContent={
          !props.outsideCurrentMonth &&
          (isStartDate === formattedDate || isEndDate === formattedDate)
            ? isStartDate === formattedDate
              ? <FiberManualRecordTwoTone sx={{color: "green", fontSize: "12px"}} />
              : isEndDate && isEndDate === formattedDate
              ? <FiberManualRecordTwoTone sx={{color: "red", fontSize: "12px"}} />
              : "" 
            : undefined
        }
      >
        <PickersDay
          {...other}
          outsideCurrentMonth={outsideCurrentMonth}
          day={day}
        />
      </Badge>
    );
  };




 console.log('filters',filters)

  return (
    <Box
      position="relative"
      sx={{
        width: 500,
        opacity: 1,
        transition: "all 0.6s ease-in-out",
        border: `1px solid`,
        borderColor: (theme) => theme.palette.divider,
        borderRadius: "10px",
      }}
      data-testid="controls-container"
    >
      {/* -----header----- */}
      <Box
        mb={1}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          position: "sticky",
          width: "100%",
          top: "-1px",
          background: (theme) => theme.palette.background.paper,
          zIndex: 300,
          borderBottom: `1px solid`,
          borderColor: (theme) => theme.palette.divider,
          borderRadius: "10px 10px 0px 0px",
        }}
      >
        {/* <Box display="flex" alignItems='center' justifyContent="center">

        </Box> */}
        <Button
          startIcon={<ArrowBack />}
          sx={{ width: "50%" }}
          onClick={() => history?.goBack()}
        >
          Go Back
        </Button>
        <Typography
          sx={{
            fontSize: "17px",
            fontWeight: "700",
            width: "100%",
            textAlign: "center",
            // ...textClasses.themeColor,
          }}
        >
          Apply Filter Detail
        </Typography>
      </Box>

      <Grid container px={1}>
        {tripData?.status !== "completed" ? (
          <Grid
            item
            xs={12}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            gap="6px"
            sx={{ paddingTop: "0px !important" }}
            mb={2}
          >
            <CustomSubmitBtn
              fullWidth
              size="small"
              sx={{ ...buttonClasses.lynkitOrangeFill,
                "&.Mui-disabled": {
                backgroundColor: "#827878 !important",
                border: `1px solid #827878 !important`,
                color: "#EAEAEA !important",
              }, }}
              onClick={() => handleTimeBtnClicked(5)}
              label="5 Mins"
              disabled={fiveMinbtnLoader}
              loading={fiveMinbtnLoader}
            />

            <CustomSubmitBtn
              fullWidth
              size="small"
              sx={{ ...buttonClasses.lynkitOrangeFill,
                "&.Mui-disabled": {
                backgroundColor: "#827878 !important",
                border: `1px solid #827878 !important`,
                color: "#EAEAEA !important",
              }, }}
              onClick={() => handleTimeBtnClicked(10)}
              label="10 Mins"
              disabled={tenMinbtnLoader}
              loading={tenMinbtnLoader}
            />

            <CustomSubmitBtn
              fullWidth
              size="small"
              sx={{ ...buttonClasses.lynkitOrangeFill,
                "&.Mui-disabled": {
                backgroundColor: "#827878 !important",
                border: `1px solid #827878 !important`,
                color: "#EAEAEA !important",
              } }}
              onClick={() => handleTimeBtnClicked(30)}
              label="30 Mins"
              disabled={thirtyMinbtnLoader}
              loading={thirtyMinbtnLoader}
            />
          </Grid>
        ) : null}

        <Grid xs={12} mb={2}>
          <DateTimePicker
            label="From"
            value={filters?.from || null}
            onChange={(date) => setFilters((prev) => ({ ...prev, from: date }))}
            className="customDatePicker"
            sx={{
              width: "100%",
              ...selectStylesOverride(isDark),
            }}
            // slotProps={dateTimePickerSlotProps(inputClasses, isDark)}
            slots={{
              day: ServerDay,
            }}
            slotProps={{
              ...dateTimePickerSlotProps(inputClasses, isDark),
              day: {
                highlightedDays: [tripStartTime, tripEndTime],
              },
            }}
            
          />
        </Grid>
        <Grid xs={12} mb={2}>
          <DateTimePicker
            label="To"
            value={filters?.to || null}
            onChange={(date) => setFilters((prev) => ({ ...prev, to: date }))}
            className="customDatePicker"
            sx={{
              width: "100%",
              ...selectStylesOverride(isDark),
            }}
            // slotProps={dateTimePickerSlotProps(inputClasses, isDark)}
            slots={{
              day: ServerDay,
            }}
            slotProps={{
              ...dateTimePickerSlotProps(inputClasses, isDark),
              day: {
                highlightedDays: [tripStartTime, tripEndTime],
              },
            }}
          />
        </Grid>
        <Grid xs={12} mb={1.5}>
          <CustomSubmitBtn
            fullWidth
            size="small"
            sx={{
              ...buttonClasses.lynkitOrangeFill,
              padding: "6px 16px",
              height: "34px",
              "&.Mui-disabled": {
                backgroundColor: "#827878 !important",
                border: `1px solid #827878 !important`,
                color: "#EAEAEA !important",
              },
            }}
            onClick={() => {
              handleCameraPlayback();
            }}
            label="Camera Playback"
            disabled={
              timeIntervalLoader ||
              (filters.from === null && filters.to === null)
            }
            loading={timeIntervalLoader}
          />
        </Grid>
        <Grid xs={12} mb={2}>
          <CustomSubmitBtn
            fullWidth
            size="small"
            sx={{
              ...buttonClasses.lynkitOrangeFill,
              padding: "6px 16px",
              height: "34px",
              "&.Mui-disabled": {
                backgroundColor: "#827878 !important",
                border: `1px solid #827878 !important`,
                color: "#EAEAEA !important",
              },
            }}
            onClick={() => {
              handleEventsPlayback();
            }}
            label="Events Playback"
            disabled={alertsLoader}
            loading={alertsLoader}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default BsjCameraPlaybackDrawer;
