import { GET_FILTERS } from "../types";

const intialState = {
  allFilters: null,
};

const FiltersReducer = (state = intialState, { type, payload }) => {
  switch (type) {
    case GET_FILTERS:
      return { ...state, allFilters: payload };
    default:
      return state;
  }
};

export default FiltersReducer;
