import React from 'react'
import { Grid, ButtonGroup, TextField, IconButton, Input, FormControl, FormLabel, FormGroup, InputLabel, Select, MenuItem, FormControlLabel, Checkbox, RadioGroup, Radio, Button } from '@mui/material'
import { DeleteOutline, Edit, SwapVert } from '@mui/icons-material'
import { useDispatch } from 'react-redux'
import { setEditField } from '../../../redux/actions';
import { selectStylesOverride } from '../../../utils/util';
// import { DatePicker } from '@mui/x-date-pickers';

export default function GenericInputField({ component, sectionId, type, index, removable, editable, movable, handleRemove, isDarkThemeEnabledSelector, inputClasses }) {
    const dispatch = useDispatch();
    const getInputField = () => {
        switch (component.type) {
            case "input": return (
                <FormControl
                    variant='outlined' size='small' fullWidth
                    sx={{ ...selectStylesOverride(isDarkThemeEnabledSelector) }}>
                    <TextField
                        disabled={component.disabled}
                        required={component.required}
                        size='small'
                        fullWidth
                        label={component.label}
                        placeholder={component.placeholder}
                        InputLabelProps={{
                            sx: { fontSize: '13px', paddingTop: '2px' }
                        }}
                    />
                </FormControl>
            )
            case "number": return (
                <FormControl
                    variant='outlined' size='small' fullWidth
                    sx={{ ...selectStylesOverride(isDarkThemeEnabledSelector) }}>
                    <TextField
                        disabled={component.disabled}
                        type="number"
                        required={component.required}
                        size='small'
                        fullWidth label={component.label}
                        placeholder={component.placeholder}
                        InputLabelProps={{
                            sx: { fontSize: '13px', paddingTop: '2px' }
                        }}
                    />
                </FormControl>
            )
            case "date": return (
                <FormControl
                    variant='outlined' size='small' fullWidth
                    sx={{ ...selectStylesOverride(isDarkThemeEnabledSelector) }}>
                    <TextField
                        disabled={component.disabled}
                        InputLabelProps={{ shrink: true, fontSize: '13px', paddingTop: '2px' }}
                        type="date" required size='small'
                        fullWidth label={component.label}
                    />
                </FormControl>
            )
            case "textarea": return (
                <FormControl
                    variant='outlined' size='small' fullWidth
                    sx={{ ...selectStylesOverride(isDarkThemeEnabledSelector) }}>
                    <TextField
                        disabled={component.disabled}
                        multiline rows={2}
                        required={component.required}
                        size='small'
                        fullWidth
                        label={component.label}
                        placeholder={component.placeholder}
                        InputLabelProps={{
                            sx: { fontSize: '13px', paddingTop: '2px' }
                        }}
                    />
                </FormControl>
            )
            case "file": return (
                <FormControl
                    variant='outlined' size='small' fullWidth
                    sx={{ ...selectStylesOverride(isDarkThemeEnabledSelector) }}>
                    <TextField
                        disabled={component.disabled}
                        type={"file"}
                        required={component.required}
                        size='small' focused fullWidth
                        label={component.label}
                        InputLabelProps={{
                            sx: { fontSize: '13px', paddingTop: '2px' }
                        }}
                    />
                </FormControl>
            )
            case "select": return (

                <FormControl
                    variant='outlined' size='small' fullWidth required={component.required}
                    sx={{ ...selectStylesOverride(isDarkThemeEnabledSelector) }}>
                    <InputLabel sx={{ color: "#B0B0B0", fontWeight: component.disabled ? '100' : "", fontSize: '13px', paddingTop: '3px' }}>{component.label}</InputLabel>
                    <Select
                        label={component.label}
                        disabled={component.disabled}
                    >
                        {
                            component.values.map((op, i) => {
                                return <MenuItem value={op.value} key={i}>{op.label}</MenuItem>
                            })
                        }
                    </Select>
                </FormControl>
            )
            case "checkbox": return (
                <div style={{ border: "1px solid #C8C8C8", padding: "10px", borderRadius: "5px" }}>
                    <FormLabel component="legend" required={component.required}>{component.label}</FormLabel>
                    {
                        component.values.map((op, i) => {
                            return <FormControlLabel control={<Checkbox
                                sx={{
                                    marginBottom: "4px",
                                    padding: "10px",
                                    color: "#ff7200",
                                    "&.Mui-checked": {
                                        color: "#ff7200",
                                    },
                                }}
                                value={op.value} />} label={op.label} />
                        })
                    }

                </div>
            )
            case "radio": return (
                <div style={{ border: "1px solid #C8C8C8", padding: "10px", borderRadius: "5px" }}>
                    <FormControl>
                        <FormLabel required={component.required} sx={{ color: '#ff7200' }} id="demo-row-radio-buttons-group-label">{component.label}</FormLabel>
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                        >
                            {
                                component.values.map((op, i) => {
                                    return <FormControlLabel control={
                                        <Radio
                                            sx={{
                                                marginBottom: "4px",
                                                padding: "10px",
                                                color: "#ff7200",
                                                "&.Mui-checked": {
                                                    color: "#ff7200",
                                                },
                                            }}
                                            value={op.value} />} label={op.label} />
                                })
                            }
                        </RadioGroup>
                    </FormControl>
                </div>
            )
            default:
                <FormControl
                    variant='outlined' size='small' fullWidth
                    sx={{ ...selectStylesOverride(isDarkThemeEnabledSelector) }}>
                    <TextField required={component.required} size='small' fullWidth label={component.label} placeholder={component.placeholder}  InputLabelProps={{ sx: { fontSize: '13px', paddingTop: '2px' }}}/>
                </FormControl>
        }

    }
    // 
    const handleEdit = (sectionId, type, index) => {
        dispatch(setEditField(sectionId, type, index))
        // console.log('sectionId, type, index---',sectionId, type, index);
    }
    return (
        <Grid container spacing={1} sx={{ mt: 1 }}>
            <Grid item xs={8} sm={10}>
                {
                    getInputField()
                }
            </Grid>
            <Grid container item xs={4} sm={2} justifyContent="center" alignContent={"center"}>
                <Grid item direction={"row"}>
                    <Button color='inherit' variant="outlined" sx={{ p: 1, minWidth: "30px" }} disabled={!removable} onClick={() => handleRemove(index)}>
                        <DeleteOutline />
                    </Button>
                    <Button color='inherit' variant="outlined" sx={{ p: 1, minWidth: "30px", ml: 1 }} disabled={!editable} onClick={() => handleEdit(sectionId, type, index)}>
                        <Edit />
                    </Button>
                    {/* <Button color='inherit' variant="outlined" sx={{ p: 1, minWidth: "30px", ml: 1}} disabled={true}>
                        <SwapVert/>
                    </Button> */}
                </Grid>
                {/* <ButtonGroup variant='outline'>
                    <IconButton size='small' disabled={!removable} onClick={()=> handleRemove(index)} sx={{border: "1px solid black"}}>
                        <DeleteOutline/>
                    </IconButton >
                    <IconButton size='small' disabled={!editable} onClick={()=> dispatch(setEditField(sectionId, type, index))} >
                        <Edit/>
                    </IconButton>
                    <IconButton size='small' disabled={!movable}>
                        <SwapVert/>
                    </IconButton>
                </ButtonGroup> */}
            </Grid>
        </Grid>
    )
}
//change