import { Paper, TablePagination } from "@mui/material";
import React from "react";
//import theme, { textClasses } from "../../../../static/styles/theme";

const DevicesTable = ({
  header,
  rows,
  showAllColumns,
  tableColumns,
  data,
  totalRecords,
  pagination,
  setPagination,
  pageFilterKey = "pageNumber",
  onRowChange = () => { },
  themeType,
  dataTestid,
  allDevices
}) => {
  const { textClasses, tableClasses } = themeType;


  return (
    <>
      {showAllColumns && tableColumns && data ? (
        <table style={{ ...tableClasses.table }}>
          <thead
            style={{
              backgroundColor: themeType.default.themeGray,
              position: "sticky",
              top: "-1px",
              zIndex: 1,
            }}
          >
            <tr >
              {tableColumns?.map((column, index) => {
                return (
                  <td
                    key={index}
                    style={{
                      ...textClasses.boldText,
                      ...tableClasses.tableHeader,
                      textAlign: "center",
                    }}
                  >
                    {column?.header}
                  </td>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {data?.length > 0 ? (
              <>
                {data?.map((entry, key) => (
                  <tr
                    onClick={() => onRowChange(entry)}
                    key={key}
                    data-testid="tablerow"
                    style={{ borderBottom: "1px solid #0000002B" }}
                  >
                    {tableColumns?.map((column, index) => (
                      <td
                        key={index}
                        data-testid="tablecell"

                        style={{
                          textAlign: "center",
                          ...textClasses.normalText,
                          ...tableClasses.dark,
                          ...tableClasses.tableBodyClass,
                          textAlign: "center",
                         
                        }}
                      >
                        {column?.header === "#" || column?.header === "Action"
                          ? column?.cell(key)
                          : column?.selector === "deviceStatus" ||
                            column?.selector === "checkbox"
                            ? column?.cell(entry, key)
                            : column?.cell(entry)}
                      </td>
                    ))}
                  </tr>
                ))}
              </>
            ) : (
              <tr sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                <td
                  component="th"
                  scope="row"
                  style={{
                    ...textClasses.normalText,
                    ...tableClasses.dark,
                    display:'flex',
                    textAlign: "center",
                    overflowWrap: "break-word",
                  }}
                >
                  No Data Found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      ) : (
        <table style={{ ...tableClasses.table }}>
          <thead
            style={{
              backgroundColor: themeType.default.themeGray,
              position: "sticky",
              top: "-1px",
              zIndex: 1,
            }}>
            <tr>
              {header.slice(0, 2).map((head, i) => {
                return (
                  <td
                    key={i}
                    style={{
                    ...textClasses.boldText,
                    ...tableClasses.tableHeader,
                    textAlign: "center",
                  }}
                  >
                    {head}
                  </td>
                );
              })}
              {header.slice(2, header.length).map((head, i) => {
                if (head !== null) {
                  return (
                    <td
                      key={i}
                      style={{
                    ...textClasses.boldText,
                    ...tableClasses.tableHeader,
                    textAlign: "center",
                  }}
                    >
                      {head}
                    </td>
                  );
                }
              })}
            </tr>
          </thead>
          <tbody>
            {rows.length > 0 ? (
              <>
                {rows.map((row, i) => (
                  <tr data-testid="tablerow" key={i} >
                    {row.slice(0, 2).map((col, j) => {
                      return (
                        <td
                          key={j}
                          data-testid="tablecell"
                          style={{
                          textAlign: "center",
                          ...textClasses.normalText,
                          ...tableClasses.dark,
                          ...tableClasses.tableBodyClass,
                          textAlign: "center",
                          // background : allDevices[i].device_status === "DISABLE" ? '1px solid #F0F0F0' :  allDevices[i].device_status === "REQUEST_FOR_REPAIRING" || allDevices[i].device_status === "UNDER_REPAIRING" || allDevices[i].device_status === "REPAIRED" ? '1px solid  #FF0000' :  '#fff'
                        }}
                        >
                          {col}
                        </td>
                      );
                    })}
                    {row.slice(2, row.length).map((col, j) => {
                      return (
                        <td
                          key={j}
                          style={{
                          textAlign: "center",
                          ...textClasses.normalText,
                          ...tableClasses.dark,
                          ...tableClasses.tableBodyClass,
                          textAlign: "center",
                          // color:'#fff',
                          // border : allDevices[i].device_status === "DISABLE" ? '1px solid #F0F0F0' :  allDevices[i].device_status === "REQUEST_FOR_REPAIRING" || allDevices[i].device_status === "UNDER_REPAIRING" || allDevices[i].device_status === "REPAIRED" ? '1px solid #FF0000' :  '#fff'
                        }}
                        >
                          {col}
                        </td>
                      );
                    })}
                  </tr>
                ))}
              </>
            ) : (
              <tr sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                <td
                  data-testid="nodata"
                  component="th"
                  scope="row"
                  style={{
                    ...textClasses.normalText,
                    textAlign: "left",
                    overflowWrap: "break-word",
                  }}
                ></td>
              </tr>
            )}
          </tbody>
        </table>
      )}

      {pagination && (
        <TablePagination
          component="div"
          count={totalRecords}
          page={pagination[pageFilterKey] - 1}
          onPageChange={(e, newPage) => {
            setPagination((v) => ({ ...v, [pageFilterKey]: newPage + 1 }));
          }}
          rowsPerPage={pagination?.limit}
          onRowsPerPageChange={(e) => {
            setPagination((v) => ({
              ...v,
              [pageFilterKey]: 1,
              limit: e.target.value,
            }));
          }}
          sx={{
            ...tableClasses.tableHeader,
            ...tableClasses.tablePagination,
            display: "flex",
            justifyContent: "center",
            position: "sticky",
            bottom: "0px",
            alignItems: "center",
            mt: "0em",
            "& .MuiTablePagination-selectLabel ": {
              margin: "0",
            },
            "& .MuiTablePagination-displayedRows": {
              margin: "0",
            },
          }}
        />
      )}
    </>
  );
};

export default DevicesTable;
