export const Constant = {
    LogsDropdown : [
        {label : "Alert Sent Logs", value : "Alert Sent Logs"},
        {label : "Web Lock/Unlock Request Logs", value : "Web Lock/Unlock Request Logs"},
        {label : "Bluetooth Lock/Unlock Request Logs", value : "Bluetooth Lock/Unlock Request Logs"},
        {label : "Rfid Lock/Unlock Request Logs", value : "Rfid Lock/Unlock Request Logs"},
        {label : "Passcode Lock/Unlock Request Logs", value : "Passcode Lock/Unlock Request Logs"},
        {label : "Passcode View Logs", value : "Passcode View Logs"},
        // {label : "", value : ""},

    ],
    ELock : [
        {label : "E-Lock", value : "E-lock"},
        {label : "Fixed E-Lock", value : "Fixed E-Lock"},
    ],
    AlertTrip : [
        {label : "Alert With Trip", value : "withTrip"},
        {label : "Alert Without Trip", value : "withoutTrip"},
    ]

}