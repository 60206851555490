export let preDefineElement = [
    // {
    //     "handle": true,
    //     "_id": "searchTags",
    //     "type": "searchTags",
    //     "icon": "simple-icon-note",
    //     "label": "Search Tags",
    //     "description": "Enter search tags",
    //     "placeholder": "Enter search tags",
    //     "className": "form-control",
    //     "subtype": "text",
    //     "forType": "table",
    //     "required": false,
    //     "multiple": false,
    //     "hideLabel": false,
    //     "delete": false,
    //     "value": [],
    //     "hideDataForInvoice": false,
    //     "staticLabel":true
    // },
    {
        "_id": "checkList",
        "type": "radio",
        "icon": "iconsmind-Bulleted-List",
        "label": "Is it the right question ?",
        "description": "Radio boxes",
        "placeholder": "select checklist",
        "values": [
            {
                "label": "Yes",
                "value": "yes"
            },
            {
                "label": "No",
                "value": "no"
            },
        ],
        "forType": "all",
        "elementType": "Selection Type",
        "sortType": 2
    },
    {
        "handle": true,
        "staticLabel": true,
        "_id": "expiryTime",
        "type": "date",
        "icon": "simple-icon-calendar",
        "label": "Expiry Date",
        "placeholder": "Date",
        "className": "form-control datepicker",
        "subtype": "text",
        "forType": "all",
        "elementType": "Text and input",
        "sortType": 1,
        "delete": false,
        "hideDataForInvoice": false,
        "hideLabel": false,
        "multiple": false,
        "required": false,
        "value": "",
        "conditionalView": false,
        "sourceElement": "",
        "sourceValue": "",
        "hideDataForInvoice": false
    },
    {
        "handle": true,
        "staticLabel": true,
        "_id": "productName",
        "type": "input",
        "icon": "simple-icon-note",
        "label": "Product Name",
        "description": "Enter product name",
        "placeholder": "Enter product name",
        "className": "form-control",
        "subtype": "text",
        "forType": "table",
        "required": false,
        "multiple": false,
        "hideLabel": false,
        "delete": false,
        "value": '',
        "hideDataForInvoice": false
    },
    {
        "handle": true,
        "staticLabel": true,
        "_id": "weight",
        "type": "number",
        "icon": "simple-icon-note",
        "label": "Actual Weight",
        "description": "Enter weight ",
        "placeholder": "Enter weight",
        "className": "form-control",
        "subtype": "text",
        "regex": "^((\\d+(\\.\\d*)?)|(\\.\\d+))$",
        // "regex": "^((\\d+(\\.\\d*)?)|(\\.\\d+))$",
        // "regex": "^[0-9]*$",
        "forType": "table",
        "errorText": "Enter numeric value only",
        "min": "",
        "max": "",
        "required": false,
        "multiple": false,
        "hideLabel": false,
        "delete": true,
        "value": '',
        "hideDataForInvoice": false
    },
    {
        "handle": true,
        "staticLabel": true,
        "_id": "standardWeight",
        "type": "number",
        "icon": "simple-icon-note",
        "label": "Standard Weight (in Kgs)",
        "description": "Enter weight ",
        "placeholder": "Enter weight in kgs",
        "className": "form-control",
        "subtype": "text",
        "regex": "^((\\d+(\\.\\d*)?)|(\\.\\d+))$",
        // "regex": "^[0-9]*$",
        "forType": "table",
        "errorText": "Enter numeric value only",
        "min": "",
        "max": "",
        "required": false,
        "multiple": false,
        "hideLabel": false,
        "delete": true,
        "value": '',
        "hideDataForInvoice": false
    },
    {
        "handle": true,
        "staticLabel": true,
        "_id": "price",
        "type": "number",
        "icon": "",
        "label": "Price",
        "description": "Enter price",
        "placeholder": "Enter price",
        "className": "form-control",
        "subtype": "text",
        "regex": "^((\\d+(\\.\\d*)?)|(\\.\\d+))$",
        // "regex": "^[0-9]*$",
        "forType": "table",
        "errorText": "Enter numeric value only",
        "min": "",
        "max": "",
        "required": false,
        "multiple": false,
        "hideLabel": false,
        "delete": true,
        "value": '',
        "hideDataForInvoice": false
    },
    {
        "handle": true,
        "staticLabel": true,
        "_id": "length",
        "type": "number",
        "icon": "simple-icon-note",
        "label": "Length (in inches)",
        "description": "Enter length ",
        "placeholder": "Enter length in inches",
        "className": "form-control",
        "subtype": "text",
        "regex": "^((\\d+(\\.\\d*)?)|(\\.\\d+))$",
        //"regex": "^[0-9]*$",
        "forType": "table",
        "errorText": "Enter numeric value only",
        "min": "",
        "max": "",
        "required": false,
        "multiple": false,
        "hideLabel": false,
        "delete": true,
        "value": '',
        //"maxValue": "603",
        "hideDataForInvoice":false
    },
    {
        "handle": true,
        "staticLabel": true,
        "_id": "breadth",
        "type": "number",
        "icon": "simple-icon-note",
        "label": "Breadth",
        "description": "Enter width ",
        "placeholder": "Enter width",
        "className": "form-control",
        "subtype": "text",
        "regex": "^((\\d+(\\.\\d*)?)|(\\.\\d+))$",
        //"regex": "^[0-9]*$",
        "forType": "table",
        "errorText": "Enter numeric value only",
        "min": "",
        "max": "",
        "required": false,
        "multiple": false,
        "hideLabel": false,
        "delete": true,
        "value": '',
        //"maxValue": "204",
        "hideDataForInvoice": false
    },

    {
        "handle": true,
        "staticLabel": true,
        "_id": "height",
        "type": "number",
        "icon": "simple-icon-note",
        "label": "Height",
        "description": "Enter height ",
        "placeholder": "Enter height",
        "className": "form-control",
        "subtype": "text",
        "regex": "^((\\d+(\\.\\d*)?)|(\\.\\d+))$",
        //"regex": "^[0-9]*$",
        "forType": "table",
        "errorText": "Enter numeric value only",
        "min": "",
        "max": "",
        "required": false,
        "multiple": false,
        "hideLabel": false,
        "delete": true,
        "value": '',
        //"maxValue": "192",
        "hideDataForInvoice": false
    },
]