import React, { useState, useEffect } from "react";
import { Switch } from "react-router-dom";
import Route from "./Route";
import Landing from "../components/App";
import Dashboard from "../components/Dashboard";
import history from "../services/history";
import { useDispatch, useSelector } from "react-redux";
import loaderGif from "../static/images/drawer/loader.gif";
import axios from 'axios'
import { Grid } from "@mui/material";
import { getGeofenceDetails, currentUserDetails } from "../redux/actions";
import { parseJwt } from "../utils/services";
import LandingPage from "../views/Landing/LandingPage";

import {
  checkLogin,
  connectToSocket,
  disconnectFromSocket,
  getTripSummary,
  updateTheme,
  validateToken,
  getUlipData
} from "../redux/actions";
import { ThemeProvider, createTheme } from "@mui/material";
import { clearConsole } from "../utils/util";
import Signup from "../views/Landing/Signup";
import SharingTrackMap from "../views/Modules/SharingTrackMap/SharingTrackMap";
import HomeScreen from "../views/Modules/HomeScreen";
import { loginURL } from "../services/config";
import { CHECK_LOGIN } from "../redux/types";
import { useLocation } from 'react-router-dom';

export default function Routes() {
  const location = useLocation();
  const dispatch = useDispatch();
  const isDarkThemeEnabledSelector = useSelector(
    (state) => state.theme.themeDark
  );
  const { currentUserDetail } = useSelector((state) => state.auth);
  const { connected, socketInstance } = useSelector((state) => state.socket);
  const [ioUser, setIoUser] = useState(true)


  if (process.env.REACT_APP_ENV === "development") {
    // console.log = function () {};
    // console.error = function () {};
    // console.warn = function () { };
  }

  const appFont = createTheme({
    palette: {
      // mode: "dark",
      mode: isDarkThemeEnabledSelector ? "dark" : "light",
      primary: {
        main: "#FF7200",
      },
    },
    typography: {
      fontFamily: ["Nunito", "serif"].join(","),
    },
  });

  // clearConsole();
  if (process.env.REACT_APP_ENV !== "development") {
    console.log = function () { };
    console.error = function () { };
    console.warn = function () { };
  }

  useEffect(() => {
    const userTheme = localStorage?.getItem("theme");
    dispatch(checkLogin(currentUserDetail));
    if (userTheme) {
      dispatch(updateTheme(userTheme === "light" ? false : true));
    }
    window.addEventListener("beforeunload", () => {
      localStorage.removeItem("iframeee");
    });

    return () => {
      window.removeEventListener("beforeunload", () => {
        localStorage.removeItem("iframeee");
      });
    };
  }, [dispatch]);

  useEffect(() => {
    const handleUnload = () => {
      if (socketInstance && connected) {
        dispatch(disconnectFromSocket());
      }
    };

    window.addEventListener("beforeunload", handleUnload);

    return () => {
      window.removeEventListener("beforeunload", handleUnload);
    };
  }, [socketInstance]);

  if (
    !connected &&
    socketInstance === null &&
    currentUserDetail &&
    currentUserDetail?._id
  ) {
    const connectionObj = {
      userId: currentUserDetail?._id,
      userName: currentUserDetail?.name,
      iat: Date.now(),
      socketID: "",
    };
    dispatch(connectToSocket(connectionObj));
  }


  // useEffect(() => {
  //   if (window.opener) {
  //     setIoUser(true)
  //     window.opener.postMessage({ perkey: 'sendphrase' }, '*');
  //     localStorage.setItem('sidebar', true)
  //   }
  //   else {
  //     localStorage.removeItem('sidebar')
  //     setIoUser(false)
  //   }

  //   window.addEventListener("message", receivedMessage);
  //   function receivedMessage(evt) {
  //     if (evt?.data?.phrase) {
  //       localStorage.setItem("token", JSON.stringify(evt?.data?.phrase));
  //       const decodedToken = parseJwt(JSON.stringify(evt?.data?.phrase));
  //       dispatch({ type: CHECK_LOGIN, payload: decodedToken?.userId });
  //       dispatch(currentUserDetails({ id: decodedToken?.userId }, (res) => {
  //         if (res) {

  //         }
  //       })
  //       );

  //       axios.post(`${loginURL}/validateToken`, {}, { headers: { 'Authorization': evt.data.phrase } }).then(response => {
  //         if (response.data.success) {
  //           if (evt?.data?.geoId) {
  //             let obj = {
  //               id: evt?.data?.geoId,
  //               selection: [
  //                 "name",
  //                 "description",
  //                 "geofence",
  //                 "centerCoordinates",
  //                 "radius",
  //                 "polygonType",
  //                 "accessID",
  //                 "status",
  //                 // "orgId",
  //                 "addedOn",
  //                 "addedBy",
  //                 "modifiedOn",
  //                 "modifiedBy",
  //                 "dynamicData",
  //                 "address",
  //                 "latitude",
  //                 "longitude",
  //                 "user",
  //               ],
  //             };
  //             dispatch(getGeofenceDetails(obj, (res) => {
  //               if (res.success) {
  //                 history.push(`/dashboard/geo/edit/${evt.data.geoId}`);
  //                 setIoUser(false)
  //               }
  //             })
  //             );
  //           }
  //           else if (evt?.data?.type === 'tracking') {
  //             setIoUser(false)
  //           }
  //           else if (evt?.data?.type === 'fast_tag') {
  //             let payload = {
  //               "vehicleNumber": evt?.data?.vehicle_number,
  //               "size": "10000",
  //               "page": 1
  //             }
  //             // TN39CU6944
  //             dispatch(getUlipData(payload, (response) => {
  //               history.replace(`/dashboard/vehicle-detail/${evt.data.vehicle_number}`);
  //               setIoUser(false)
  //             }))
  //           }
  //           else {
  //             history.replace("/dashboard/geomap/create");
  //             setIoUser(false)
  //           }
  //         }

  //         else {
  //           localStorage.removeItem("token");
  //           history.push("/login");
  //           setIoUser(false)
  //         }
  //       })
  //         .catch(error => {
  //           localStorage.removeItem("token");
  //           history.push("/login");
  //           console.error('Error:', error);
  //         });

  //     }
  //     else {
  //     }
  //   }
  //   return () => {
  //     window.removeEventListener("message", receivedMessage);
  //     localStorage.removeItem('sidebar')
  //   };
  // }, []);


  useEffect(() => {
    if (window.opener) {
      setIoUser(true)
      window.opener.postMessage({ perkey: 'sendphrase' }, '*');
      localStorage.setItem('sidebar', true)
    }
    else {
      localStorage.removeItem('sidebar')
      setIoUser(false)
    }
    window.addEventListener("message", receivedMessage);
    function receivedMessage(evt) {
      setTimeout(() => {
        if (evt?.data?.phrase) {
          localStorage.setItem("token", JSON.stringify(evt?.data?.phrase));
          const decodedToken = parseJwt(JSON.stringify(evt?.data?.phrase));
          dispatch({ type: CHECK_LOGIN, payload: decodedToken?.userId });
          dispatch(currentUserDetails({ id: decodedToken?.userId }, (res) => {
            if (res) {
            }
          })
          );

          axios.post(`${loginURL}/validateToken`, {}, { headers: { 'Authorization': evt.data.phrase } }).then(response => {
            if (response.data.success) {
              if (evt?.data?.geoId) {
                let obj = {
                  id: evt?.data?.geoId,
                  selection: [
                    "name",
                    "description",
                    "geofence",
                    "centerCoordinates",
                    "radius",
                    "polygonType",
                    "accessID",
                    "status",
                    // "orgId",
                    "addedOn",
                    "addedBy",
                    "modifiedOn",
                    "modifiedBy",
                    "dynamicData",
                    "address",
                    "latitude",
                    "longitude",
                    "user",
                  ],
                };
                dispatch(getGeofenceDetails(obj, (res) => {
                  if (res.success) {
                    history.push(`/dashboard/geo/edit/${evt.data.geoId}`);
                    setIoUser(false)
                  }
                })
                );
              }
              else if (evt?.data?.type === 'tracking') {
                setIoUser(false)
              }
              else if (evt?.data?.type === 'fast_tag') {
                let payload = {
                  "vehicleNumber": evt?.data?.vehicle_number.replace(/[\s-]/g, '').toUpperCase(),
                  "size": "10000",
                  "page": 1
                }
                // TN39CU6944
                dispatch(getUlipData(payload, (response) => {
                  history.replace(`/dashboard/vehicle-detail/${evt.data.vehicle_number}`);
                  setIoUser(false)
                }))
              }
              else {
                history.replace("/dashboard/geomap/create");
                setIoUser(false)
              }
            }

            else {
              localStorage.removeItem("token");
              history.push("/login");
              setIoUser(false)
            }
          })
            .catch(error => {
              localStorage.removeItem("token");
              history.push("/login");
              console.error('Error:', error);
            });



        }
        else {
        }
      }, 0)
    }
    return () => {
      window.removeEventListener("message", receivedMessage);
      localStorage.removeItem('sidebar')
    };
  }, []);

  // useEffect(() => {
  //   if (window.CvNetVideo) {
  //     const url = location.pathname; 

  //     if (url.includes('trips/camera')) {
  //     } else {
  //       setTimeout(() => {
  //         window?.CvNetVideo?.Stop()
  //       }, 2500)
  //     }
  //   }
  // }, [location, window.CvNetVideo]);


  return (

    <ThemeProvider theme={appFont}>
      {ioUser && !localStorage.getItem('token') ?
        <Grid
          container
          item={true}
          direction={"column"}
          justifyContent={"center"}
          alignItems="center"
          xs={12}
          sx={{ p: 15 }}
        >
          <Grid item>
            <img src={loaderGif} />
          </Grid>
        </Grid>
        :
        <Switch>
          <Route path="/" exact homePage component={HomeScreen} />
          <Route path="/login" exact component={Landing} />
          <Route path="/register" render={() => <Signup />} />
          <Route path="/sharingTrack" isPrivate={false} component={SharingTrackMap} />
          {/* <Route path="/landing-page" isPrivate={false} component={LandingPage} /> */}
          <Route path="/" component={Dashboard} ioUser={ioUser} isPrivate currentUserDetail={currentUserDetail} />
          {/* redirect user to SignIn page if route does not exist and user is not authenticated */}
          <Route component={Landing} />
        </Switch>}
    </ThemeProvider>
  );
}
