/* eslint-disable */
// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax
import {
  Button,
  Grid,
  Typography,
  Breadcrumbs,
  Box,
  useTheme,
  styled,
} from "@mui/material";
import React, { useEffect, useState, useRef, useCallback } from "react";
import { Controller, useForm, useWatch } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import history from "../../../services/history";
import { Link } from "react-router-dom";
import TemporaryDrawer from "./GeofenceDrawer";
import DrawerFloaterLabel from "../../Components/Drawer/DrawerFloaterLabel";
import { showToast } from "../../../services/functions";
import loaderGif from "../../../static/images/drawer/loader.gif";
import pin from "../../../../src/assets/img/trackingicons/pin.png";

import {
  getGeofenceDetails,
  getAllOrganisations,
  getAllUsers,
  downloadFile,
  bulkUploadGeofence,
  getAllGeofencesForMap,
  flushGeofenceMapList,
  flushGeofenceDetails,
  getObj,
} from "../../../redux/actions";
import { DeleteOutline, Map, Upload } from "@mui/icons-material";
import PostAddIcon from "@mui/icons-material/PostAdd";
import ExcelForm from "./ExcelForm";
import { selectStylesOverride, sendCustomEventAnalytics } from "../../../utils/util";
import { mapDarkModeStyles } from "../../Components/Utils/mapDarkModeStyles";
import { saveAs } from "file-saver";

const drawerWidth = 350;
const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    maxWidth: open ? `calc(100% - ${drawerWidth}px)` : "100%",
    transition: theme.transitions.create("all", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginRight: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create("all", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginRight: 0,
    }),
  })
);

const BulkUploadGeofence = (params) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const objForUpdate = params?.location?.state;
  const [radii, setRadius] = useState(0);
  const [tabIndex, setTabIndex] = useState(0);
  const [isDrawerOpen, setIsDrawerOpen] = useState(true);
  const searchInput = useRef(null);
  const [mapZoom, setMapZoom] = useState(4);
  const orgListing = useSelector(
    (state) => state.Organisationlisting.OrganisationListing
  );
  const Users = useSelector((state) => state.userlist.allUsers);
  const isDarkThemeEnabledSelector = useSelector(
    (state) => state.theme.themeDark
  );

  const geofenceDetails = useSelector(
    (state) => state.geofencelist.geofenceDetails
  );
  const themeType = isDarkThemeEnabledSelector
    ? require("../../../static/styles/darktheme")
    : require("../../../static/styles/theme");

  const { buttonClasses, textClasses } = themeType;
  const templateExistence = useSelector(
    (state) => state.templates.templateExistence
  );
  const [polygonCoordinates, setpolygonCoordinates] = useState([]);
  const [circleCrordinates, setCircleCoordinates] = useState([]);
  const [centerLat, setCenterLat] = useState("");
  const [centerLng, setCenterLng] = useState("");
  const [geofenceType, setGeofenceType] = useState("Polygon");
  const [addressLat, setAddressLat] = useState(null);
  const [addressLng, setAddressLng] = useState(null);
  const [address, setAddress] = useState("");
  const [circle, setCircle] = useState(null);
  const [editCircle, setEditCircle] = useState(null);
  const [drawingradii, setDrawingRadius] = useState(0);
  const [myMap, setMyMap] = useState(null);
  const [mapLoader, setMapLoader] = useState(false);
  const [drawnOverlays, setDrawnOverlays] = useState([]);
  const [markersArray, setMarkersArray] = useState([]);
  const [userLocation, setUserLocation] = useState(null);
  const [showTablePreview, setShowTablePreview] = React.useState(false);
  const [toggleExcelPage, setToggleExcelPage] = useState(false);
  const [headers, setHeaders] = useState([]);
  const [rowData, setRowData] = useState([]);
  const [searchFields, setSearchFields] = useState({
    users: null,
    org: null,
  });
  const [organisationId, setOrganisationId] = useState("");
  const [userId, setUserId] = useState(undefined);
  const currentUser = useSelector((state) => state.auth.currentUserDetail);
  const superAdmin = currentUser?.adminSpecificPermissions || {};
  var drawingManager;
  var newgeofence;
  var temp;

  const { OrganisationListing } = useSelector(
    (state) => state.Organisationlisting
  );
  const usersListing = useSelector((state) => state.userlist.allUsers);
  const { geofenceListingForMap } = useSelector((_) => _?.geofencelist);
  const [selectedFile, setSelectedFile] = useState(null);
  const {
    handleSubmit,
    control,
    register,
    reset,
    setValue,
    setError,
    getValues,
    formState: { errors },
  } = useForm();

  const selectedOrganisation = useWatch({
    control,
    name: "organization",
  });

  var objs = {
    lat: Number(
      geofenceListingForMap &&
        geofenceListingForMap[0] &&
        geofenceListingForMap[0].features[0] &&
        geofenceListingForMap[0].features[0]?.geometry?.coordinates[0][0][1]
    ),
    lng: Number(
      geofenceListingForMap &&
        geofenceListingForMap[0] &&
        geofenceListingForMap[0].features[0] &&
        geofenceListingForMap[0].features[0]?.geometry?.coordinates[0][0][0]
    ),
  };

  useEffect(() => {
    // dispatch(getAllGeofencesForMap());
    dispatch(flushGeofenceMapList());
    if (!navigator.geolocation) {
      setUserLocation({ lat: 28.88846998075509, lng: 77.89163770268443 });
      setMapZoom(4);
      return;
    }

    navigator.geolocation.getCurrentPosition(
      (position) => {
        const { latitude, longitude } = position.coords;
        setMapZoom(12);
        setUserLocation({ lat: latitude, lng: longitude });
      },
      (error) => {
        setUserLocation({ lat: 28.88846998075509, lng: 77.89163770268443 });
        setMapZoom(4);
        console.error("Error getting geolocation:", error);
      }
    );
  }, []);

  useEffect(() => {
    if (geofenceListingForMap && geofenceListingForMap?.length > 0) {
      setTimeout(() => {
        if (
          typeof google === "undefined" ||
          typeof google.maps === "undefined"
        ) {
          return null;
        }
        const mapElement = document.getElementById("mapDimensions");
        if (!mapElement) {
          return null;
        }

        if (objs?.lat && objs?.lng) {
          const mapOptions = {
            zoom: mapZoom,
            center: { lat: parseFloat(objs.lat), lng: parseFloat(objs.lng) },
            mapTypeId: "terrain",
            zoomControl: true,
            zoomControlOptions: {
              style: google.maps.ZoomControlStyle.SMALL,
              position: google.maps.ControlPosition.LEFT_BOTTOM,
            },
            options: {
              styles: isDarkThemeEnabledSelector && mapDarkModeStyles,
            },
          };
          const map = new google.maps.Map(mapElement, mapOptions);
          setMyMap(map);
          return map;
        }
      }, 50);
    } else if (
      (!geofenceListingForMap && userLocation && mapLoader) ||
      (geofenceListingForMap &&
        geofenceListingForMap?.length === 0 &&
        userLocation &&
        mapLoader)
    ) {
      setTimeout(() => {
        if (
          typeof google === "undefined" ||
          typeof google.maps === "undefined"
        ) {
          return null;
        }
        const mapElement = document.getElementById("mapDimensions");
        if (!mapElement) {
          return null;
        }

        const mapOptions = {
          zoom: mapZoom,
          center: userLocation,
          mapTypeId: "terrain",
          zoomControl: true,
          zoomControlOptions: {
            style: google.maps.ZoomControlStyle.SMALL,
            position: google.maps.ControlPosition.LEFT_BOTTOM,
          },
          options: { styles: isDarkThemeEnabledSelector && mapDarkModeStyles },
        };
        const map = new google.maps.Map(mapElement, mapOptions);
        setMyMap(map);
        return map;
      }, 50);
    }

    setTimeout(() => {
      setMapLoader(true);
    }, 50);
  }, [userLocation, mapLoader, geofenceListingForMap]);

  useEffect(() => {
    if (myMap) {
      var htmlElement = document.getElementById("searchTextField");
      var autocomplete = new window.google.maps.places.Autocomplete(
        htmlElement
      );

      new google.maps.event.addListener(
        autocomplete,
        "place_changed",
        function () {
          onChangeAddress(myMap, autocomplete);
        }
      );

      if (Object.keys(geofenceDetails).length) {
        plotEditGeofences();
        setGeofenceType(geofenceDetails?.polygonType);

        let coords = geofenceDetails?.geofence?.coordinates;

        if (geofenceDetails?.polygonType === "Polygon") {
          setpolygonCoordinates(coords);
        } else if (geofenceDetails?.polygonType === "Circular") {
          setCircleCoordinates(coords);
        }
      } else {
        drawing();
      }

      if (geofenceListingForMap && geofenceListingForMap?.length > 0) {
        geofenceListingForMap.forEach((featureCollection) => {
          featureCollection.features.forEach((feature) => {
            geofenceRenderer(feature);
          });
        });
      }
    }
  }, [myMap, geofenceDetails, geofenceListingForMap]);

  useEffect(() => {
    if (currentUser) {
      if (currentUser?.adminSpecificPermissions) {
        dispatch(getAllOrganisations({ selection: ["name"] }));
      } else {
        handleFetchAllGeofenceForMap({
          filter: { orgId: currentUser?.orgId, userId: currentUser?._id },
        });
      }
    }
  }, [currentUser]);

  useEffect(() => {
    if (Object.keys(superAdmin).length && selectedOrganisation) {
      dispatch(
        getAllUsers({ orgId: selectedOrganisation?._id, selection: ["name"] })
      );
    }
  }, [selectedOrganisation, dispatch, superAdmin]);

  useEffect(() => {
    if (geofenceType) {
      geofenceType === "Polygon" ? setTabIndex(0) : setTabIndex(1);
    }
  }, [geofenceType]);

  useEffect(() => {
    if (Object.keys(superAdmin).length && organisationId) {
      dispatch(getAllUsers({ orgId: organisationId, selection: ["name"] }));
    }
  }, [organisationId, superAdmin]);

  useEffect(() => {
    // call all geofence maps API for superadmin
    if (Object.keys(superAdmin).length && userId) {
      handleFetchAllGeofenceForMap({
        filter: { orgId: organisationId, userId: userId },
      });
    }
  }, [userId, superAdmin]);

  function drawing() {
    if (drawnOverlays.length > 0) {
      for (var i = 0; i < drawnOverlays.length; i++) {
        drawnOverlays[i].setMap(null);
      }
      setDrawnOverlays([]);
      setCircleCoordinates([]);
      setpolygonCoordinates([]);
    }
    drawingManager = new google.maps.drawing.DrawingManager({
      drawingMode: google.maps.drawing.OverlayType.POLYGON,
      drawingControl: false,
      drawingControlOptions: {
        position: google.maps.ControlPosition.TOP_CENTER,
        drawingModes: [google.maps.drawing.OverlayType.POLYGON],
      },
    });
    drawingManager.setMap(myMap);
    const toggleCircleButton = document.getElementById("toggleToCircle");
    const togglePolygonButton = document.getElementById("toggleToPolygon");
    const clearButton = document.getElementById("clearMap");

    if (toggleCircleButton) {
      toggleCircleButton.addEventListener("click", () => {
        drawingManager.setOptions({
          drawingMode: google.maps.drawing.OverlayType.CIRCLE,
        });
        setGeofenceType("Circular");
        for (var i = 0; i < drawnOverlays.length; i++) {
          drawnOverlays[i].setMap(null);
        }
        setDrawnOverlays([]);
        setCircleCoordinates([]);
      });
    }
    if (togglePolygonButton) {
      togglePolygonButton.addEventListener("click", () => {
        drawingManager.setOptions({
          drawingMode: google.maps.drawing.OverlayType.POLYGON,
        });
        setGeofenceType("Polygon");

        for (var i = 0; i < drawnOverlays.length; i++) {
          drawnOverlays[i].setMap(null);
        }
        setDrawnOverlays([]);
        setpolygonCoordinates([]);
      });
    }
    if (clearButton) {
      clearButton.addEventListener("click", () => {
        clearMap();
        for (var i = 0; i < drawnOverlays.length; i++) {
          drawnOverlays[i].setMap(null);
        }
        setDrawnOverlays([]);
        setMyMap(null);
        setCenterLat("");
        setCenterLng("");
        setRadius(0);
      });
    }
    google.maps.event.addListener(
      drawingManager,
      "overlaycomplete",
      (event) => {
        if (event.type === google.maps.drawing.OverlayType.POLYGON) {
          for (var i = 0; i < drawnOverlays.length; i++) {
            drawnOverlays[i].setMap(null);
          }
          setDrawnOverlays([]);
          setpolygonCoordinates([]);
          setCircleCoordinates([]);
          setMyMap(null);
          setAddress("");
          setAddressLat("");
          setAddressLng("");
          setIsDrawerOpen(true);

          const polygon = event.overlay;
          polygon.setOptions({
            strokeColor: "#00008B",
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: "#6082B6",
            fillOpacity: 0.45,
          });
          const paths = polygon.getPaths();
          const coordinates = [];
          const bounds = new google.maps.LatLngBounds();

          paths.forEach((path) => {
            path.getArray().forEach((coord) => {
              coordinates.push([coord.lng(), coord.lat()]);
              bounds.extend(coord);
            });
          });
          const center = bounds.getCenter();
          setCenterLat(center.lat());
          setCenterLng(center.lng());
          if (coordinates.length > 0) {
            coordinates.push(coordinates[0]);
          }

          const formattedCoordinates = [coordinates];
          drawnOverlays.push(polygon);
          setpolygonCoordinates(formattedCoordinates);
        } else if (event.type === google.maps.drawing.OverlayType.CIRCLE) {
          for (var i = 0; i < drawnOverlays.length; i++) {
            drawnOverlays[i].setMap(null);
          }
          setDrawnOverlays([]);
          setpolygonCoordinates([]);
          setCircleCoordinates([]);
          setMyMap(null);
          setAddress("");
          setAddressLat("");
          setAddressLng("");
          setCenterLat("");
          setCenterLng("");
          setpolygonCoordinates([]);
          const circle = event.overlay;
          circle.setOptions({
            strokeColor: "#00008B",
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: "#6082B6",
            fillOpacity: 0.45,
          });
          const center = circle.getCenter();
          const radius = circle.getRadius();
          const coordinates = [];

          if (radius < 10 || radius > 500) {
            showToast("Radius should be between 10m - 500m.", true);
            circle.setMap(null);
          } else {
            coordinates.push([center.lng(), center.lat()]);
            drawnOverlays.push(circle);
            const formattedCoordinates = [coordinates];
            setCircleCoordinates(formattedCoordinates);
            setDrawingRadius(radius);
            setCenterLat(center.lat());
            setCenterLng(center.lng());
            setCircle(circle);
          }
        }
      }
    );
  }

  function onChangeAddress(map, autocomplete) {
    var place = autocomplete.getPlace();
    if (place.geometry && place.geometry.location) {
      var selectedLocation = place.geometry.location;
      setAddressLat(selectedLocation.lat());
      setAddressLng(selectedLocation.lng());
      setAddress(place.formatted_address);
      addMarker(selectedLocation.lat(), selectedLocation.lng());
    }
  }

  const addMarker = (lat, lng) => {
    if (myMap) {
      myMap.setCenter({ lat: Number(lat), lng: Number(lng) });
      myMap.panTo({ lat: Number(lat), lng: Number(lng) });
      myMap.setZoom(12);
      if (markersArray) {
        for (let i = 0; i < markersArray.length; i++) {
          markersArray[i].setMap(null);
        }
        markersArray.length = 0;
      }

      let marker = new google.maps.Marker({
        map: myMap,
        position: { lat: Number(lat), lng: Number(lng) },
        icon: pin,
      });
      markersArray.push(marker);
    }
  };

  const updateMarker = (lat, lng) => {
    if (myMap) {
      myMap.setCenter({ lat: Number(lat), lng: Number(lng) });
      myMap.panTo({ lat: Number(lat), lng: Number(lng) });
      myMap.setZoom(12);

      if (markersArray) {
        for (let i = 0; i < markersArray.length; i++) {
          markersArray[i].setMap(null);
        }
        markersArray.length = 0;
      }

      let marker = new google.maps.Marker({
        map: myMap,
        position: { lat: Number(lat), lng: Number(lng) },
        // icon: pin ,
        visible: false,
      });
      markersArray.push(marker);
    }
  };

  function clearMap() {
    for (var i = 0; i < drawnOverlays.length; i++) {
      drawnOverlays[i].setMap(null);
    }
    setGeofenceType("Polygon");
    dispatch(flushGeofenceDetails());
    setDrawnOverlays([]);
    setpolygonCoordinates([]);
    setCircleCoordinates([]);
    setMyMap(null);
    setAddress("");
    setAddressLat("");
    setAddressLng("");
    if (markersArray) {
      for (let i = 0; i < markersArray.length; i++) {
        markersArray[i].setMap(null);
      }
      markersArray.length = 0;
    }
  }

  const handleFetchAllGeofenceForMap = (filter) => {
    dispatch(getAllGeofencesForMap(filter));
  };

  useEffect(() => {
    if (circle) {
      circle.setRadius(Number(radii));
    }
    if (editCircle) {
      editCircle.setRadius(Number(radii));
    }
  }, [radii]);

  //update Geofence

  useEffect(() => {
    if (objForUpdate?.param === "singleViewEdit" && objForUpdate?.id !== "") {
      let obj = {
        id: objForUpdate?.id,
        selection: [
          "name",
          "description",
          "geofence",
          "centerCoordinates",
          "radius",
          "polygonType",
          "accessID",
          "status",
          // "orgId",
          "addedOn",
          "addedBy",
          "modifiedOn",
          "modifiedBy",
          "dynamicData",
          "address",
          "latitude",
          "longitude",
          "user",
        ],
      };
      dispatch(getGeofenceDetails(obj));
    }
  }, [objForUpdate]);

  function debounce(func, delay) {
    let timeoutId;
    return function (...args) {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        func.apply(this, args);
      }, delay);
    };
  }

  function plotEditGeofences() {
    if (Object.keys(geofenceDetails).length) {
      let originalCoordinates = geofenceDetails?.geofence?.coordinates;
      let geoType = geofenceDetails?.polygonType;
      const convertedCoordinates = originalCoordinates[0].map((point) => ({
        lat: point[1],
        lng: point[0],
      }));
      const centerForCircle = {
        lat: Number(geofenceDetails?.latitude),
        lng: Number(geofenceDetails?.longitude),
      };
      if (geoType === "Polygon") {
        newgeofence = new google.maps.Polygon({
          paths: convertedCoordinates,
          strokeColor: "#00008B",
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: "#6082B6",
          fillOpacity: 0.45,
          editable: true,
          draggable: true,
          map: myMap,
        });
        drawnOverlays.push(newgeofence);
        setGeofenceType(geofenceDetails?.polygonType);
        const debouncedDragEnd = debounce(() => {
          const bounds = new google.maps.LatLngBounds();
          const coordinates = [];
          newgeofence
            .getPath()
            .getArray()
            .forEach((coord) => {
              coordinates.push([coord.lng(), coord.lat()]);
              bounds.extend(coord);
            });
          const center = bounds.getCenter();
          setCenterLat(center.lat());
          setCenterLng(center.lng());

          if (coordinates.length > 0) {
            coordinates.push(coordinates[0]);
          }
          const formattedCoordinates = [coordinates];

          setpolygonCoordinates(formattedCoordinates);
          setCircleCoordinates([]);
        }, 100);

        window.google.maps.event.addListener(newgeofence, "dragend", () => {
          debouncedDragEnd();
        });

        const debouncedSetAt = debounce(() => {
          const bounds = new google.maps.LatLngBounds();
          const coordinates = [];
          newgeofence
            .getPath()
            .getArray()
            .forEach((coord) => {
              coordinates.push([coord.lng(), coord.lat()]);
              bounds.extend(coord);
            });
          const center = bounds.getCenter();
          setCenterLat(center.lat());
          setCenterLng(center.lng());

          if (coordinates.length > 0) {
            coordinates.push(coordinates[0]);
          }
          const formattedCoordinates = [coordinates];
          drawnOverlays.push(newgeofence);
          setpolygonCoordinates(formattedCoordinates);
          setCircleCoordinates([]);
        }, 100);

        window.google.maps.event.addListener(
          newgeofence.getPath(),
          "set_at",
          () => {
            debouncedSetAt();
          }
        );
      } else if (geoType === "Circular") {
        let lastCenterCoordinates = null;
        temp = new google.maps.Circle({
          strokeColor: "#00008B",
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: "#6082B6",
          fillOpacity: 0.45,
          center: centerForCircle,
          radius: Number(geofenceDetails?.radius),
          editable: true,
          draggable: true,
          map: myMap,
        });
        setGeofenceType(geofenceDetails?.polygonType);
        drawnOverlays.push(temp);
        google.maps.event.addListener(temp, "radius_changed", () => {
          const updatedRadius = temp.getRadius();
          setRadius(Math.ceil(updatedRadius));
        });

        google.maps.event.addListener(temp, "center_changed", () => {
          const updatedCenter = temp.getCenter();
          lastCenterCoordinates = [updatedCenter.lng(), updatedCenter.lat()];
          const formattedCoordinates = [lastCenterCoordinates];
          setCircleCoordinates([formattedCoordinates]);
        });

        setEditCircle(temp);
      }
    }
  }

  function debounce(func, delay) {
    let timeoutId;
    return function (...args) {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        func.apply(this, args);
      }, delay);
    };
  }

  const handleToggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const handleDownloadFile = useCallback(() => {
    if (templateExistence?.samplefile) {
      sendCustomEventAnalytics("geofence", "Sample Download geofence", "Sample Download geofence")
      const imgBaseURL = process.env.REACT_APP_ENV === "development" ? "https://testv2.lynktrac.io/"  : "https://lynktrac.io/";
      const sampleFileUrl = `${imgBaseURL}api/v1/common/getFile/${templateExistence?.samplefile}`;

      const link = document.createElement('a');
      link.href = sampleFileUrl;
      link.download = `geofenceSampleFile.xlsx`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      // dispatch(
      //   downloadFile(sampleFileUrl, () => {
      //     showToast("File Downloaded successfully");
      //   })
      // );
    }else{
      showToast("No geofence template found", true);
    }
  }, [templateExistence, dispatch]);

  const handleCreateGeofenceInBulk = useCallback(
    (payload) => {
      sendCustomEventAnalytics("trips", "Geofence Bulk Upload", "Geofence Bulk Upload")
      dispatch(bulkUploadGeofence(payload, () => {}));
    },
    [dispatch]
  );

  const handleCancel = useCallback(() => {
    history.push("/dashboard/geofence");
    reset();
  }, [history]);

  const _goBack = () => {
    history.goBack();
  };

  const geofenceRenderer = (feature) => {
    if (feature.geometry.type === "Polygon" && feature.geometry.coordinates) {
      const coordinates = feature.geometry.coordinates[0];
      const geofencePolygon = new google.maps.Polygon({
        paths: coordinates.map((coord) => ({
          lat: Number(coord[1]),
          lng: Number(coord[0]),
        })),
        map: myMap,
        strokeColor: "#404040",
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: "#808080",
        fillOpacity: 0.45,
        // editable: true
      });
    } else if (
      feature.geometry.type === "Circle" &&
      feature.geometry.center &&
      feature.geometry.radius
    ) {
      const circle = new google.maps.Circle({
        center: {
          lat: Number(feature.geometry.center[1]),
          lng: Number(feature.geometry.center[0]),
        },
        radius: parseFloat(feature.geometry.radius),
        map: myMap,
        strokeColor: "#404040",
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: "#808080",
        fillOpacity: 0.45,
        // editable:true
      });
    }
  };

  const handleSearchUsers = (empty) => {
    if (empty === true) {
      dispatch(
        getAllUsers({
          selection: ["name"],
          searchValue: "",
          orgId: filterObj?.orgId ? filterObj?.orgId : "",
        })
      );
    } else {
      if (searchFields?.users || searchFields?.users == "")
        dispatch(
          getAllUsers({
            selection: ["name"],
            searchValue: searchFields?.users,
            orgId: filterObj?.orgId ? filterObj?.orgId : "",
          })
        );
    }
  };

  const handleSearchOrg = (empty) => {
    if (empty === true) {
      dispatch(getAllOrganisations({ selection: ["name"] }));
    } else {
      if (searchFields?.org || searchFields?.org == "")
        dispatch(
          getAllOrganisations({
            selection: ["name"],
            searchValue: searchFields.org,
          })
        );
    }
  };

  return (
    <>
      {/* <Grid
        container
        sx={{
          height: "88vh",
          position: "relative",
          overflowY: "auto"
        }}
      > */}
      <Box sx={{
        // height: "88vh",
        // overflowY: "auto",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        gap: 2,
      }}>
        {window.location.pathname == "/dashboard/geomap/create" ||
        window.location.pathname.includes("dashboard/geo/edit") ? null : (
          <Box height="70px" width="100%">
          {/* <Grid item sm={12} xs={12} my={0} height="70px"> */}
            <Grid container>
              <Grid item sm={6} xs={4}>
                <Typography sx={{ ...textClasses.cardTitle }}>
                  {!Object.keys(geofenceDetails).length
                    ? " Create Geofence"
                    : " Edit Geofence"}
                </Typography>
              </Grid>
              <Grid
                item
                sm={6}
                xs={6}
                sx={{
                  displey: "flex",
                  display: "flex",
                  justifyContent: "flex-end",
                  height: "36px",
                }}
              >
                {/* Hide in edit */}
                <Button
                  variant="outlined"
                  size="small"
                  data-testid="upload"
                  sx={{
                    ...buttonClasses.small,
                    minHeight: "36px",
                    borderColor: themeType.default.themeOrange,
                    color: buttonClasses.lynkitOrangeFill,
                  }}
                  startIcon={<PostAddIcon />}
                  onClick={() => {
                    // setToggleExcelPage(!toggleExcelPage);
                    history.push("/dashboard/geomap/create-geo");
                    reset();
                  }}
                >
                  Form Data Entry
                </Button>
              </Grid>
            </Grid>
            <Grid>
              <Breadcrumbs sx={{ mb: 1 }}>
                <Typography
                  sx={{
                    ...textClasses.normalText,
                    fontSize: "12px",
                    color: themeType.default.themeOrange,
                  }}
                >
                  <Link
                    style={{
                      color: themeType.default.themeOrange,
                      textDecoration: "none",
                    }}
                    to="/dashboard/geofence"
                  >
                    Geofence
                  </Link>
                </Typography>

                <Typography
                  sx={{ ...textClasses.normalText, fontSize: "12px" }}
                >
                  {!Object.keys(geofenceDetails).length ? "Create" : "Edit"}
                </Typography>
              </Breadcrumbs>
            </Grid>
          {/* </Grid> */}
          </Box>
        )}
        <ExcelForm
          onHandleCreateGeofenceInBulk={handleCreateGeofenceInBulk}
          usersListing={usersListing}
          selectedFile={selectedFile}
          setSelectedFile={setSelectedFile}
          control={control}
          handleSubmit={handleSubmit}
          getValues={getValues}
          setValue={setValue}
          errors={errors}
          headers={headers}
          setHeaders={setHeaders}
          rowData={rowData}
          setRowData={setRowData}
          handleDownloadFile={handleDownloadFile}
          superAdmin={superAdmin}
          handleCancel={handleCancel}
          showTablePreview={showTablePreview}
          setShowTablePreview={setShowTablePreview}
          OrganisationListing={OrganisationListing}
          themeType={themeType}
          selectStylesOverride={selectStylesOverride(
            isDarkThemeEnabledSelector
          )}
        />
      {/* </Grid> */}
      </Box>
    </>
  );
};

export default BulkUploadGeofence;
