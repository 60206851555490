const intialState = {
    AllUserData: {},
    counts :{}
};


const ManageTeamReducer = (state = intialState, { type, payload }) => {
    switch (type) {
        case "GET_ALL_USERS_DATA":
            return { ...state, AllUserData: payload }
        case "GET_COUNTS":
            return { ...state, counts: payload }
        default:
            return state;
    }
}
export default ManageTeamReducer;