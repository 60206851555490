/* eslint-disable */
import { Box, styled, Grid, Typography, Button, Breadcrumbs, FormControlLabel, Checkbox } from "@mui/material";
import React, { useState, useEffect, useRef } from "react";
import TripMapDrawer from "./components/TripMapDrawer";
import DrawerFloaterLabel from "../../Components/Drawer/DrawerFloaterLabel";
import { useDispatch, useSelector } from "react-redux";
import { Map, Google, MemoryOutlined } from "@mui/icons-material";
import { useHistory, Link } from "react-router-dom/cjs/react-router-dom";
import { getTripDataByTripId, tripGeofenceWithRoute, tripGeofenceWithoutRoute, emptyTripObj, emptyTripData, getTripEvents, getUlipData, getRouteDetails } from "../../../redux/actions";
import routeIcon from "../../../assets/images/routeIcon.png"
import speed from "../../../assets/images/speed.png"
import airplane from "../../../assets/images/airplane.png"
import tagIcon from "../../../assets/images/tagIcon.png"


import distance from "../../../assets/images/distance.png"
import dateTime from "../../../assets/images/dateandtime.png"
import location from "../../../assets/infoWindow/location.png"
import truck from "../../../assets/infoWindow/truck.png"
import battery from "../../../assets/infoWindow/low-battery.png"
import pin from "../../../assets/img/trackingicons/maps_red.png"
import tag from "../../../assets/infoWindow/tag.png"
import lock from "../../../assets/infoWindow/lock.png"
import unlock from "../../../assets/infoWindow/unlock.png"
import { darkStyles } from "../../../services/constants";
import { capitalizeSentence, showToast } from "../../../services/functions";
import { URLFORTRIPMAP } from "../../../services/Api";
import axios from 'axios';
import { locationUrl, baseURL } from "../../../services/config";
import loaderGif from "../../../static/images/drawer/loader.gif";
import { convertIntoGivenTimezone } from "../../../services/functions";
import livepositionMarker from "../../../assets/marker/marker.png"
import Fastag from "../../../static/images/map/Fastag.svg";
import toll from "../../../assets/infoWindow/toll.png";
import dateTimeBold from "../../../assets/images/calendar_bold.png";
import truckIcon from "../../../assets/infoWindow/truckIcon.svg";
import EditGeofenceDrawer from "./components/EditGeofenceDrawer";
import { number } from "zod";

const colors = [
  "#ff7200", // Original color
  "#ff7200", // Lighter shade
  "#ff7200", // Even lighter shade
];

const TripMap = (props) => {
  const searchInput = useRef(null);
  const dispatch = useDispatch()
  let urlIcon = 'https://lynktrac.io/lynktracV2/'
  const [openDrawer, setOpenDrawer] = useState(true);
  const isDarkThemeEnabledSelector = useSelector((state) => state.theme.themeDark);
  const tripDetails = useSelector((state) => state.tripMap.tripDataById);
  const geofencesWithRoute = useSelector((state) => state.tripMap?.tripGeofenceWithRoute?.geoData || {});
  const geofencesWithoutRoute = useSelector((state) => state.tripMap?.tripGeofenceWithoutRoute?.geoData || {});
  const tripEvents = useSelector((state) => state.tripMap?.tripEvents || {});
  const themeType = isDarkThemeEnabledSelector ? require("../../../static/styles/darktheme") : require("../../../static/styles/theme");
  const history = useHistory();
  const [mapLoaded, setMapLoaded] = useState(false);
  // const [mapLoader, setMapLoader] = useState(false)
  const [myMap, setMymap] = useState(null)
  const [mapCenter, setMapCenter] = useState({ lat: 28.6139, lng: 77.209 })
  const [idParam, setIdParam] = useState(null);
  const [tripParam, setTripParam] = useState(null);
  const [routeObj, setRouteObj] = useState({});
  const [geoEditDrawer, setGeoEditDrawer] = useState(false);
  const [viewPaths, setViewPaths] = useState(false);
  const [address, setAddress] = useState(null)
  const [tripDetailsLoader, setTripDetailsLoader] = useState(false)
  const [currentAddress, setCurrentAddress] = useState({
    lat: null,
    lng: null
  })
  const [geoJsonLayer, setGeoJsonLayer] = useState(null);
  const [markers, setMarkers] = useState([]);
  const iconPath = `https://lynktrac.io/lynktracV2/trip-icons`;
  const mapElement = document.getElementById("tripmap") || '';
  const userDetails = useSelector((state) => state.auth.currentUserDetail);
  var map;
  var marker;
  const imgBaseURL = process.env.REACT_APP_ENV === "development" ? "https://testv2.lynktrac.io/" : "https://lynktrac.io/";
  const { textClasses } = themeType;
  const [infoWindow, setInfoWindow] = useState(null);
  const [newInfoWindow, setNewInfoWindow] = useState(null);
  const [geofenceWindow, setGeofenceWindows] = useState(null)
  const [fastTageWindow, setFastTagWindow] = useState(null);
  const [geofencePlotArray, setGeofencePlotArray] = useState([])
  const [imageWindow, setImageWindow] = useState(null);
  const [pathPolylines, setPathPolylines] = useState([])

  useEffect(() => {
    const currentUrl = window.location.href;
    const path = new URL(currentUrl).pathname;
    const tripId = path.split('/trip-map/')[1];
    const urlParamsforId = new URLSearchParams(currentUrl.split('?')[1]);
    const id = urlParamsforId.get('id');

    setTripParam(tripId)
    setIdParam(id)
    setRouteObj({})
    setMymap(null)
  }, [window.location.href]);


  useEffect(() => {
    if (myMap) {
      const mapStyles = isDarkThemeEnabledSelector ? darkStyles : null;
      myMap.setOptions({ styles: mapStyles });
    }
  }, [isDarkThemeEnabledSelector, myMap])



  useEffect(() => {
    if (mapLoaded && !myMap) {
      if (typeof google === 'undefined' || typeof google.maps === 'undefined') {
        return null;
      }
      // setMapLoader(true)
      if (!mapElement) {
        return null;
      }
      if (!mapCenter.lat || !mapCenter.lng) {
        // console.log("Latitude or longitude not provided.");
        return null;
      }

      const mapOptions = {
        zoom: 15,
        center: { lat: parseFloat(mapCenter?.lat), lng: parseFloat(mapCenter?.lng) },
        mapTypeId: "terrain",
        zoomControl: true,
        zoomControlOptions: {
          style: google.maps.ZoomControlStyle.SMALL,
          position: google.maps.ControlPosition.LEFT_BOTTOM,
        },
      };
      map = new google.maps.Map(mapElement, mapOptions);

      var htmlElement = document.getElementById("searchTextField");
      var autocomplete = new window.google.maps.places.Autocomplete(
        htmlElement
      );

      new google.maps.event.addListener(
        autocomplete,
        "place_changed",
        function () {
          onChangeAddress(map, autocomplete);
        }
      );


      setMymap(map);
      return map;
    };
  }, [mapLoaded, mapElement, myMap]);

  function onChangeAddress(map, autocomplete) {
    let temp = { ...currentAddress }
    var place = autocomplete.getPlace();
    if (place.geometry && place.geometry.location) {
      var selectedLocation = place.geometry.location;
      setAddress(place.formatted_address);
      temp.lat = selectedLocation.lat()
      temp.lng = selectedLocation.lng()
      setCurrentAddress(temp)

      if (marker) {
        marker.setMap(null);
      }

      var icon = {
        url: pin,
        scaledSize: new google.maps.Size(30, 30),
      };


      marker = new google.maps.Marker({
        position: selectedLocation,
        map: map,
        title: '',
        icon: icon
      });
      map.setCenter(selectedLocation);
    }
  }

  useEffect(() => {
    if (tripParam && idParam) {
      setTripDetailsLoader(true)
      let body = {
        trip_id: tripParam,
        deviceId: idParam
      }
      dispatch(getTripDataByTripId(body, (res) => {
        setTripDetailsLoader(false)
      }));
    }
  }, [tripParam, idParam])

  useEffect(() => {
    if (tripDetails && Object.keys(tripDetails).length && idParam) {
      let currentTime = new Date().getTime();
      let tripEventBody = {
        tripId: tripDetails?.tripData?._id || '',
        fromDate: tripDetails?.tripData?.detailsDeviceID?.time ? tripDetails?.tripData?.detailsDeviceID?.time : tripDetails?.tripData?.addedOnEpoch,
        toDate: tripDetails?.tripData?.status === 'completed' ? tripDetails?.tripData?.endTime : currentTime,
        deviceId: idParam,
        destinationId: tripDetails?.tripData?.route?.routeDestination?._id || ''
      }
      dispatch(getTripEvents(tripEventBody))
    }
  }, [tripDetails, idParam])



  const bgColor = (data) => {
    let lockStatus = data?.lockStatus || ''
    let color = '';
    if (lockStatus === 'locked') {
      color = 'green';
    }
    else if (lockStatus === 'unlocked') {
      color = '#D2042D';
    }
    else if (lockStatus === 'tampered') {
      color = '#D2042D';
    }
    else if (lockStatus === 'motorUnlocked') {
      color = '#FFAA1D';
    }

    return { color };
  }

  const getTripStatus = (status) => {
    if (status === 'in_transit') {
      return "In Transit"
    }
    else if (status === 'initiated') {
      return 'Created'
    }
    else if (status === 'mapped') {
      return 'Mapped'
    }
    else if (status === 'completed') {
      return 'Completed'
    }
    else if (status === 'reached') {
      return 'Reached'
    }
    else {
      return status
    }
  }

  useEffect(() => {
    if (myMap && Object.keys(tripDetails).length) {
      setTimeout(() => {
        if (tripDetails?.tripData?.flightDetails?.length) {
          myMap?.data?.loadGeoJson(`${URLFORTRIPMAP}/getTripTrackDataOfDeviceAndFlight?deviceId=${idParam}&trip_Id=${tripParam}`, null, (feature) => {

            myMap.data.setStyle((feature) => {
              let color = '';
              let icon = {};
              if (feature.getProperty('icon')) {
                let iconSize = feature.getProperty('size') ? feature.getProperty('size') : 20;
                let iconImage;
                const featureIcon = feature.getProperty('icon');
                const trackingIconsCheck = "/img/trackingicons";
                let heading = feature.getProperty('heading') || 0;

                if (featureIcon === 'Sealed') {
                  iconImage = `${urlIcon}trip-icons/start.png`;
                  icon = {
                    url: iconImage,
                    scaledSize: new google.maps.Size(iconSize, iconSize),
                  };
                }
                else if (featureIcon === 'Begin_Transit_Journey') {
                  iconImage = `${urlIcon}trip-icons/maps.png`;
                  icon = {
                    url: iconImage,
                    scaledSize: new google.maps.Size(iconSize, iconSize),
                  };
                }
                else if (featureIcon && featureIcon.includes(trackingIconsCheck)) {
                  const splitArray = featureIcon.split(trackingIconsCheck);
                  const iconName = splitArray[1] ? splitArray[1] : splitArray[0];
                  iconImage = `${urlIcon}trip-icons${iconName}`;
                  icon = {
                    url: iconImage,
                    scaledSize: new google.maps.Size(iconSize, iconSize),
                  };
                }
                else if (featureIcon === 'assets/img/airplane.png') {
                  icon = {
                    path: "M19.996 13.507l-5.996-3.426v-5.956c0-.827-.673-1.5-1.5-1.5s-1.5.673-1.5 1.5v5.956l-5.996 3.426c-.439.251-.622.79-.426 1.256.197.466.711.713 1.196.573l5.226-1.492v4.451l-1.625 1.3c-.387.31-.488.856-.239 1.284s.776.608 1.235.425l2.129-.852 2.129.852c.121.048.247.071.371.071.347 0 .681-.181.864-.497.249-.428.147-.975-.239-1.284l-1.625-1.3v-4.451l5.226 1.493.274.039c.394 0 .762-.233.922-.612.196-.466.014-1.005-.426-1.256zm-7.496-9.132c-.276 0-.5-.224-.5-.5s.224-.5.5-.5.5.224.5.5-.224.5-.5.5z",
                    scale: 2,
                    rotation: heading,
                    fillColor: '#29ad4b',
                    fillOpacity: 1,
                    strokeWeight: 1,
                    anchor: new google.maps.Point(11, 2)
                  };

                }
                else {
                  const splitArray = featureIcon ? featureIcon.split("/img") : [];
                  const iconName = splitArray[1] ? splitArray[1] : splitArray[0];
                  iconImage = `${urlIcon}trip-icons${iconName}`;
                  icon = {
                    url: iconImage,
                    scaledSize: new google.maps.Size(iconSize, iconSize),
                  };
                }
              }
              if (feature.getProperty('isColorful')) {
                color = feature.getProperty('color');
              }
              return {
                strokeColor: color,
                strokeWeight: 2,
                icon,
              };
            });

            myMap.data.forEach((feature) => {
              if (feature.getGeometry().getType() === "LineString") {
                const coordinates = feature.getGeometry().getArray();
                const lineSymbol = {
                  path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
                  scale: 3,
                  strokeColor: "#ff7200",
                  fillColor: "#ff7200",
                };
                for (let i = 0; i < coordinates.length - 1; i++) {
                  const segmentPath = [coordinates[i], coordinates[i + 1]];

                  const line = new google.maps.Polyline({
                    path: segmentPath,
                    icons: [
                      {
                        icon: lineSymbol,
                        offset: "50%",
                      },
                    ],
                    strokeColor: "#ff7200",
                    map: myMap,
                  });
                }

              }

            });


          });
          myMap.data.addListener('click', async (event) => {
            let trackingType = event.feature.getProperty('trackingType') || null
            if (trackingType === 'air-plane') {


              let markerObj = {
                _id: event.feature.getProperty('packet_id')
              }
              await axios.post(`${URLFORTRIPMAP}/flightPacketDetail`, { ...markerObj }, {
                headers: {
                  Authorization: JSON.parse(localStorage.getItem('token'))
                }
              })
                .then(response => {
                  let data = response?.data?.data




                  let address = data?.address || '-'
                  let flightNumber = tripDetails?.tripData?.flightDetails[0]?.flightId?.toUpperCase() || '-'
                  let time = convertIntoGivenTimezone(data?.timestamp, userDetails.timeZone.offset) || '-';
                  let tripId = tripDetails?.tripData?.tripDetails?.tripId || '-'

                  let tripStatus = getTripStatus(data?.trip_status) || '-'


                  let infoWindowContent =
                    `<div class='infoDiv' style="width: 380px;">
                          <div class='infoChild2'>
                          <div class='subChild2'>
                              <img class='imgCss'  src=${airplane} alt='' />
                              <span class='infoLabel label2'>Flight Number</span>
                              <span class='infoValue infoValue2'>${flightNumber}</span>
                          </div>
        
                        <div class='subChild2 '>
                          <img class='imgCss'  src=${tagIcon} alt='' />
                          <span class='infoLabel label2'>Trip Id </span>
                          <span class='infoValue infoValue2'>${tripId}</span>
                        </div>

                          <div class='subChild2 '>
                          <img class='imgCss'  src=${routeIcon} alt='' />
                          <span class='infoLabel label2'>Trip  Status</span>
                          <span class='infoValue infoValue2'>${tripStatus}</span>
                        </div>

                      <div class='subChild2'>
                        <img class='imgCss'  src=${dateTime} alt='' />
                        <span class='infoLabel label2'>Date & Time</span>
                        <span class='infoValue infoValue2'>${time}</span>
                      </div>
              </div>

              <div style='display: flex; height: auto; margin-top: 2px;'>
                <div style='flex: 0 0 20%; display: flex; justify-content: center; align-items: center; padding-right: 12px;'>
                  <img src='${location}' alt='' style='height: 24px; width: 24px;' />
                </div>

                  <p style='margin: 0; padding-top: 4px;'>${address}</p>
                </div>
                
              </div>
            `
                  let temp = newInfoWindow

                  if (newInfoWindow) {
                    newInfoWindow.close();
                  }

                  if (geofenceWindow) {
                    geofenceWindow.close();
                  }

                  if (fastTageWindow) {
                    fastTageWindow.close();
                  }
                  if (imageWindow) {
                    imageWindow.close();
                  }

                  temp.setContent(infoWindowContent);
                  temp.setPosition(event.latLng);
                  temp.open(myMap);
                  setInfoWindow(temp);
                  setNewInfoWindow(temp)
                })
                .catch(error => {
                  console.error('Error:', error);
                });

            }
            else {
              let markerObj = {
                deviceId: idParam,
                packet_id: event.feature.getProperty('packet_id'),
                status: tripDetails?.tripData?.tripDetails?.status || '',
                eventAfterTampered: event.feature.getProperty("icon") === 'assets/img/trackingicons/maps_red.png' ? true : false,
                icon: event.feature.getProperty("icon"),
                gps: tripDetails?.tripData?.sealingDetails?.gps || '',
                offlineLimit: tripDetails?.tripData?.limitThreshold?.offlineLimit || '',
              }
              await axios.post(`${URLFORTRIPMAP}/getTripMapPacketData`, { ...markerObj }, {
                headers: {
                  Authorization: JSON.parse(localStorage.getItem('token'))
                }
              })
                .then(response => {
                  let data = response?.data?.trackData
                  const lockStatus = data?.lockStatus || '-';
                  const batteryPercent = data?.battery + '%' || '-';
                  const tripStatus = data?.trip_status || '-';
                  const speedVal = data?.speed.toFixed(2) + ' kmph' || '-';
                  const distanceVal = data?.distance || '-';
                  const time = convertIntoGivenTimezone(data?.packet_time, userDetails.timeZone.offset) || '-';
                  const tripEventStatus = data?.event || null
                  const activeDevice = data?.deviceType || "-"
                  const color = data?.color === 'yellow' ? '#FFAA1D' : data?.color
                  const backgroundColor = bgColor(data)
                  let address = data?.address
                  let infoWindowContent = `<div class='infoDiv'>
                <div class='infoChild1'>
                
                
                ${tripEventStatus !== '-' ?
                      `<div class='subChild' style="background-color: ${color}" >
                      <img class='lockImg'  src=${truck} alt='' />
                      <span class='deviceLabel'>Trip Event </span>
                      <span class='devicevalue'>${tripEventStatus}</span>
                  </div>`
                      : ''
                    }
                    ${lockStatus !== '-' ?
                      `<div class='subChild' style="background-color: ${backgroundColor?.color}">
                  <img class='lockImg'  src=${lockStatus === 'motorUnlocked' ? unlock : lock} alt='' />
                  <span class='deviceLabel'>Device Status </span>
                  <span class='devicevalue'>${lockStatus === 'motorUnlocked' ? 'Motor Unlocked' : capitalizeSentence(lockStatus)}</span>
                </div>  `
                      : ''
                    }
                <div class='subChild bgblue'>
                    <img class='lockImg'  src=${tag} alt='' />
                    <span class='deviceLabel'> Device Id </span>
                    <span class='devicevalue'> ${idParam}</span>
                </div>
              </div>
              <div class='infoChild2'>
  
  
              ${activeDevice !== "simTracking" && activeDevice !== "fixedELock" ?
                      `<div class='subChild2 '>
              <img class='imgCss'  src=${battery} alt='' />
              <span class='infoLabel label2'>Battery</span>
              <span class='infoValue infoValue2'>${batteryPercent}</span>
                </div>`
                      : ''}
  
        
                <div class='subChild2 '>
                  <img class='imgCss'  src=${routeIcon} alt='' />
                  <span class='infoLabel label2'>Trip Status</span>
                  <span class='infoValue infoValue2'>${tripStatus}</span>
                </div>
        
                  <div class='subChild2'>
                    <img class='imgCss'  src=${speed} alt='' />
                    <span class='infoLabel label2'>Speed </span>
                    <span class='infoValue infoValue2'>${speedVal}</span>
                  </div>
        
                <div class='subChild2'>
                    <img class='imgCss'  src=${distance} alt='' />
                    <span class='infoLabel label2'>Distance </span>
                    <span class='infoValue infoValue2'>${distanceVal}</span>
                </div>
        
                <div class='subChild2'>
                  <img class='imgCss'  src=${dateTime} alt='' />
                  <span class='infoLabel label2'>Date & Time</span>
                  <span class='infoValue infoValue2'>${time}</span>
                </div>
              </div>
        
              <div class='infoChild3'>
                <div class='addressDiv'>
                  <div class='addressImg'>
                  <img class='imgCss' src=${location} alt='' />
                  </div>
                  <div class='addressBox'>
                  <span class='infoLabel'> ${address}</span>
                  </div>
                </div>
                
              </div>
              </div>
            `
                  let temp = newInfoWindow

                  if (newInfoWindow) {
                    newInfoWindow.close();
                  }

                  if (geofenceWindow) {
                    geofenceWindow.close();
                  }

                  if (fastTageWindow) {
                    fastTageWindow.close();
                  }

                  if (imageWindow) {
                    imageWindow.close();
                  }
                  temp.setContent(infoWindowContent);
                  temp.setPosition(event.latLng);
                  temp.open(myMap);
                  setInfoWindow(temp);
                  setNewInfoWindow(temp)
                })
                .catch(error => {
                  console.error('Error:', error);
                });
            }

          });
          tripDetails?.liveLocation?.forEach((location, index) => {
            marker = new window.google.maps.Marker({
              position: { lat: Number(location?.latitude), lng: Number(location?.longitude) },
              map: myMap,
              icon: livepositionMarker
            });
          });


        }
        else {
          myMap?.data?.loadGeoJson(`${URLFORTRIPMAP}/getTripMapTrack?trip_Id=${tripParam}&deviceId=${idParam}`, null, (feature) => {
            myMap.data.setStyle((feature) => {
              let color = 'gray';
              let icon = {};
              if (feature.getProperty('icon')) {
                let iconSize = feature.getProperty('size') ? feature.getProperty('size') : 20;
                let iconImage;
                const featureIcon = feature.getProperty('icon');
                const trackingIconsCheck = "/img/trackingicons";

                if (featureIcon === 'Sealed') {
                  iconImage = `${urlIcon}trip-icons/start.png`;
                }
                else if (featureIcon === 'Begin_Transit_Journey') {
                  iconImage = `${urlIcon}trip-icons/maps.png`;
                }
                else if (featureIcon && featureIcon.includes(trackingIconsCheck)) {
                  const splitArray = featureIcon.split(trackingIconsCheck);
                  const iconName = splitArray[1] ? splitArray[1] : splitArray[0];
                  iconImage = `${urlIcon}trip-icons${iconName}`;
                }
                else {
                  const splitArray = featureIcon ? featureIcon.split("/img") : [];
                  const iconName = splitArray[1] ? splitArray[1] : splitArray[0];
                  iconImage = `${urlIcon}trip-icons${iconName}`;
                }
                icon = {
                  url: iconImage,
                  scaledSize: new google.maps.Size(iconSize, iconSize),
                };
              }
              if (feature.getProperty('isColorful')) {
                color = feature.getProperty('color');
              }
              return {
                strokeColor: color,
                strokeWeight: 2,
                icon,
              };
            });

            myMap.data.forEach((feature) => {
              if (feature.getGeometry().getType() === "LineString") {
                const coordinates = feature.getGeometry().getArray();
                const lineSymbol = {
                  path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW, // Arrow symbol
                  scale: 3, // Size of the arrow
                  strokeColor: "#ff7200", // Arrow color
                  fillColor: "#ff7200",
                };
                // Create Polylines for each segment
                for (let i = 0; i < coordinates.length - 1; i++) {
                  const segmentPath = [coordinates[i], coordinates[i + 1]];

                  // Create the Polyline with arrow icon
                  const line = new google.maps.Polyline({
                    path: segmentPath,
                    icons: [
                      {
                        icon: lineSymbol,
                        offset: "50%", // Adjust offset for the first arrow
                      },
                    ],
                    strokeColor: "#ff7200",
                    map: myMap,
                  });
                }

              }

            });


          });
          myMap.data.addListener('click', async (event) => {
            let markerObj = {
              deviceId: idParam,
              packet_id: event.feature.getProperty('packet_id'),
              status: tripDetails?.tripData?.tripDetails?.status || '',
              eventAfterTampered: event.feature.getProperty("icon") === 'assets/img/trackingicons/maps_red.png' ? true : false,
              icon: event.feature.getProperty("icon"),
              gps: tripDetails?.tripData?.sealingDetails?.gps || '',
              offlineLimit: tripDetails?.tripData?.limitThreshold?.offlineLimit || '',
            }
            await axios.post(`${URLFORTRIPMAP}/getTripMapPacketData`, { ...markerObj }, {
              headers: {
                Authorization: JSON.parse(localStorage.getItem('token'))
              }
            })
              .then(response => {
                let data = response?.data?.trackData
                const lockStatus = data?.lockStatus || '-';
                const batteryPercent = data?.battery + '%' || '-';
                const tripStatus = data?.trip_status || '-';
                const speedVal = data?.speed.toFixed(2) + ' kmph' || '-';
                const distanceVal = data?.distance || '-';
                const time = convertIntoGivenTimezone(data?.packet_time, userDetails.timeZone.offset) || '-';
                const tripEventStatus = data?.event || null
                const activeDevice = data?.deviceType || "-"
                const color = data?.color === 'yellow' ? '#FFAA1D' : data?.color
                const backgroundColor = bgColor(data)



                let address = data?.address
                let infoWindowContent = `<div class='infoDiv'>
              <div class='infoChild1'>
              
              
              ${tripEventStatus !== '-' ?
                    `<div class='subChild' style="background-color: ${color}" >
                    <img class='lockImg'  src=${truck} alt='' />
                    <span class='deviceLabel'>Trip Event </span>
                    <span class='devicevalue'>${tripEventStatus}</span>
                </div>`
                    : ''
                  }
                  ${lockStatus !== '-' ?
                    `<div class='subChild' style="background-color: ${backgroundColor?.color}">
                <img class='lockImg'  src=${lockStatus === 'motorUnlocked' ? unlock : lock} alt='' />
                <span class='deviceLabel'>Device Status </span>
                <span class='devicevalue'>${lockStatus === 'motorUnlocked' ? 'Motor Unlocked' : capitalizeSentence(lockStatus)}</span>
              </div>  `
                    : ''
                  }
              <div class='subChild bgblue'>
                  <img class='lockImg'  src=${tag} alt='' />
                  <span class='deviceLabel'> Device Id </span>
                  <span class='devicevalue'> ${idParam}</span>
              </div>
            </div>
            <div class='infoChild2'>


            ${activeDevice !== "simTracking" && activeDevice !== "fixedELock"  ?
                    `<div class='subChild2 '>
            <img class='imgCss'  src=${battery} alt='' />
            <span class='infoLabel label2'>Battery</span>
            <span class='infoValue infoValue2'>${batteryPercent}</span>
              </div>`
                    : ''}

      
              <div class='subChild2 '>
                <img class='imgCss'  src=${routeIcon} alt='' />
                <span class='infoLabel label2'>Trip Status</span>
                <span class='infoValue infoValue2'>${tripStatus}</span>
              </div>
      
                <div class='subChild2'>
                  <img class='imgCss'  src=${speed} alt='' />
                  <span class='infoLabel label2'>Speed </span>
                  <span class='infoValue infoValue2'>${speedVal}</span>
                </div>
      
              <div class='subChild2'>
                  <img class='imgCss'  src=${distance} alt='' />
                  <span class='infoLabel label2'>Distance </span>
                  <span class='infoValue infoValue2'>${distanceVal}</span>
              </div>
      
              <div class='subChild2'>
                <img class='imgCss'  src=${dateTime} alt='' />
                <span class='infoLabel label2'>Date & Time</span>
                <span class='infoValue infoValue2'>${time}</span>
              </div>
            </div>
      
            <div class='infoChild3'>
              <div class='addressDiv'>
                <div class='addressImg'>
                <img class='imgCss' src=${location} alt='' />
                </div>
                <div class='addressBox'>
                <span class='infoLabel'>${address}</span>
                </div>
              </div>
              
            </div>
            </div>
          `
                let temp = newInfoWindow

                if (newInfoWindow) {
                  newInfoWindow.close();
                }

                if (geofenceWindow) {
                  geofenceWindow.close();
                }

                if (fastTageWindow) {
                  fastTageWindow.close();
                }
                if (imageWindow) {
                  imageWindow.close();
                }

                temp.setContent(infoWindowContent);
                temp.setPosition(event.latLng);
                temp.open(myMap);
                setInfoWindow(temp);
                setNewInfoWindow(temp)
              })
              .catch(error => {
                console.error('Error:', error);
              });
          });
          tripDetails?.liveLocation?.forEach((location, index) => {
            marker = new window.google.maps.Marker({
              position: { lat: Number(location?.latitude), lng: Number(location?.longitude) },
              map: myMap,
              icon: livepositionMarker
            });
          });
        }

      }, 50)
    }
  }, [myMap, idParam, tripDetails])

  useEffect(() => {
    if (Object.keys(tripDetails).length && myMap) {
      setTimeout(() => {
        let routeFlag = tripDetails?.tripData?.isTripWithoutRoute
        if (!routeFlag) {
          let temp = []
          let sourceId = tripDetails?.tripData?.route.routeSource?._id || ''
          temp.push(sourceId)
          for (let i = 0; i < tripDetails?.tripData?.route?.viaPoints?.length; i++) {
            let viaPointId = tripDetails?.tripData?.route?.viaPoints[i]._id
            temp.push(viaPointId)
          }
          let destinationId = tripDetails?.tripData?.route?.routeDestination?._id || ''
          temp.push(destinationId)

          if (tripDetails?.tripData?.route?.geoOpsID.length) {
            tripDetails?.tripData?.route?.geoOpsID.forEach(id => {
              if (!temp.includes(id)) {
                temp.push(id);
              }
            });
          }
          dispatch(tripGeofenceWithRoute({ geofenceIdArr: temp, trip_id: tripDetails?.tripData?._id }, (res) => {
            if (res) {
              if (res.data?.geoData) {
                geofenceRenderer(res?.data?.geoData);
                setGeofencePlotArray(res?.data?.geoData)
              }
            }
          })
          );
        }
        else if (routeFlag) {
          let body = {
            viewAllGeofences: tripDetails?.tripData?.tripDetails?.viewAllGeofences || '',
            deviceId: tripDetails?.tripData?.detailsDeviceID?.deviceID || '',
            trip_id: tripDetails?.tripData?._id
          }
          dispatch(tripGeofenceWithoutRoute(body, (res) => {
            if (res) {
              if (res.data?.geoData) {
                geofenceRenderer(res?.data?.geoData);
                setGeofencePlotArray(res?.data?.geoData)
              }
            }
          })
          );
        }
      }, 50)
    }
  }, [tripDetails, myMap])

  useEffect(() => {
    if (Object.keys(geofencesWithRoute).length) {
      setRouteObj(geofencesWithRoute)
    }
    else if (Object.keys(geofencesWithoutRoute).length) {
      setRouteObj(geofencesWithoutRoute)
    }
    else { }


  }, [geofencesWithRoute, geofencesWithoutRoute])

  const loadGoogleMapsApi = () => {
    return new Promise((resolve, reject) => {
      if (window.google) {
        resolve(window.google);
      } else {
        const script = document.createElement("script");
        script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyANnEwTq59T8s30-hXFPNOS5t9_OP47i2A&libraries=geometry,drawing,places`;
        script.onload = () => {
          resolve(window.google);
        };
        script.onerror = (error) => {
          reject(error);
        };
        document.body.appendChild(script);
      }
    });
  };

  const plotPaths = () => {
    if (tripDetails && tripDetails?.tripData?.route && tripDetails?.tripData?.route?._id) {
      dispatch(getRouteDetails({ id: tripDetails?.tripData?.route?._id, selection: ["routeLegs", "routeLegs1", "routeLegs2"] }, (res) => {
        if (res) {
          let routeLegsArr = [];
          routeLegsArr.push(res?.routeLegs);
          if (res?.routeLegs1?.length) {
            routeLegsArr.push(res?.routeLegs1);
          }
          if (res?.routeLegs2?.length) {
            routeLegsArr.push(res?.routeLegs2);
          }

          const newPolylines = routeLegsArr.map((legs, index) => {
            const routePath = new window.google.maps.Polyline({
              path: legs.flatMap((leg) => leg.steps.map((step) => step.start_location)),
              geodesic: true,
              strokeColor: colors[index],
              strokeOpacity: 0.7, // Set opacity to 70%
              strokeWeight: 7,
            });
            routePath.setMap(myMap);
            return routePath;
          });
          setPathPolylines(newPolylines);
        }
      }))
    }
  };

  const clearPaths = () => {
    pathPolylines.forEach((polyline) => polyline.setMap(null));
    setPathPolylines([]);
    // setPathPolylines(null)
  };

  useEffect(() => {
    let isMounted = true;
    loadGoogleMapsApi()
      .then((google) => {
        if (isMounted) {
          setMapLoaded(true);
          setNewInfoWindow(new google.maps.InfoWindow());
          setFastTagWindow(new google.maps.InfoWindow());
          setGeofenceWindows(new google.maps.InfoWindow());
          setImageWindow(new google.maps.InfoWindow());
        }
      })
      .catch((error) => {
        console.error("Error loading Google Maps API:", error);
      });
    setViewPaths(false);
    return () => {
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    if (tripEvents && Object.keys(tripEvents).length && myMap) {
      const newMapCenter = new window.google.maps.LatLng(Number(tripEvents?.mapCenterLocation?.lat), Number(tripEvents?.mapCenterLocation?.lng));
      myMap.setCenter(newMapCenter);
    }
  }, [tripEvents, myMap])

  useEffect(() => {
    if (myMap && viewPaths) {
      plotPaths();
    } else if (myMap && !viewPaths) {
      clearPaths();
    }
  }, [myMap, viewPaths]);

  const geofenceRenderer = (geoJsonData) => {
    try {
      if (geoJsonLayer) {
        geoJsonLayer.setMap(null);
        setGeoJsonLayer(null);
      }
      markers.forEach(marker => marker.setMap(null));
      setMarkers([]);

      const outputJson = {
        type: "FeatureCollection",
        features: geoJsonData.map(location => ({
          type: "Feature",
          properties: location?.features[0]?.properties,
          geometry: location?.features[0]?.geometry
        }))
      };

      const newGeoJsonLayer = new google.maps.Data();
      newGeoJsonLayer.addGeoJson(outputJson);

      newGeoJsonLayer.setStyle(function (feature) {
        return {
          fillColor: feature.getProperty('fillColor'),
          strokeColor: feature.getProperty('fillColor'),
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillOpacity: 0.5,
        };
      });

      newGeoJsonLayer.setMap(myMap);
      setGeoJsonLayer(newGeoJsonLayer);
      handleFastTagData();
      // if (tripDetails?.tripData?.images?.length) {
      //   plotImagesonMap()
      // }

      const newMarkers = [];
      const ids = {
        srcId: null,
        destId: null
      };

      outputJson.features.forEach((feature) => {
        const lat = feature.properties.centerCoordinates[0][0][1];
        const lng = feature.properties.centerCoordinates[0][0][0];
        let center = { lat: lat, lng: lng };
        const iconFile = feature?.properties?.icon ? feature.properties.icon?.split("/") : "";
        let iconImg = iconFile && Array.isArray(iconFile) ? iconFile[iconFile?.length - 1] : null;
        const title = feature.properties.title;

        if (title === 'Source') {
          ids.srcId = feature.properties?._id;
        } else if (title === 'Destination') {
          ids.destId = feature.properties?._id;
        }

        if (ids.srcId && ids.destId && ids.srcId === ids.destId) {
          center = {
            lat: lat + 0.0003,
            lng: lng + 0.0003
          };
        }
        const marker = new google.maps.Marker({
          position: center,
          map: myMap,
          title: title,
          icon: {
            url: `${iconPath}/${iconImg}`,
            scaledSize: new google.maps.Size(30, 45),
            anchor: new google.maps.Point(11, 32.5),
          }
        });

        marker.addListener("click", () => {
          infoWindowRederer(feature.properties);
        });

        newMarkers.push(marker);
      });

      setMarkers(newMarkers);

      newGeoJsonLayer.addListener('click', function (event) {
        let props = {
          name: event.feature.getProperty('name'),
          addedOn: event.feature.getProperty('addedOn'),
          addedBy: event.feature.getProperty('addedBy'),
          centerCoordinates: event.feature.getProperty('centerCoordinates'),
        };
        infoWindowRederer(props);
      });
    } catch (error) {
      console.error("An error occurred while rendering geofences and markers:", error);
    }
  };


  const infoWindowRederer = (properties) => {
    let addedOn = convertIntoGivenTimezone(properties.addedOn, userDetails.timeZone.offset)
    const coordinates = properties.centerCoordinates[0];
    const infoWindowContent = `
    <div style="min-width:310px">
        <div> <span style="display: inline-block; width: 80px; color: #909090; ">Name:</span> <span style="color: #ff7200; font-Size: 20px; font-Weight: 500; f">${properties.name}</span></div><br>
        <div><span style="display: inline-block; width: 80px; color: #909090; ">Added By:</span><span style="color: #000; font-Weight: 500; "> ${properties?.addedBy?.name}</span><br><br>
        <div><span style="display: inline-block; width: 80px; color: #909090; ">Added On:</span><span style="color: #000; font-Weight: 500; "> ${addedOn}</span><br><br>
    </div>
    `;
    const infoWindow = createInfoWindow(infoWindowContent, {
      lat: Number(coordinates[0][1]),
      lng: Number(coordinates[0][0]),
    });
    myMap.panTo({ lat: Number(coordinates[0][1]), lng: Number(coordinates[0][0]) });

    if (infoWindow) {
      infoWindow.close();
    }

    if (fastTageWindow) {
      fastTageWindow.close();
    }
    if (imageWindow) {
      imageWindow.close();
    }
    // infoWindows.forEach((window) => window.close());
    // infoWindow.open(myMap);
    // infoWindows.push(infoWindow);

    let temp = geofenceWindow

    if (geofenceWindow) {
      geofenceWindow.close();
    }
    if (newInfoWindow) {
      newInfoWindow.close();
    }
    if (fastTageWindow) {
      fastTageWindow.close();
    }
    if (imageWindow) {
      imageWindow.close();
    }
    temp.setContent(infoWindowContent);
    temp.setPosition({ lat: Number(coordinates[0][1]), lng: Number(coordinates[0][0]) });
    temp.open(myMap);
    // setInfoWindow(temp);
    setGeofenceWindows(temp)


  }

  function createInfoWindow(content, position) {
    return new google.maps.InfoWindow({
      content: content,
      position: position,
    });
  }

  const changeTimeStamp = (timestamp) => {

    let date = new Date(timestamp);

    let year = date.getUTCFullYear();
    let month = (date.getUTCMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
    let day = date.getUTCDate().toString().padStart(2, '0');

    let formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
  }

  const currentTimeFun = () => {
    let currentDate = new Date();

    let year = currentDate.getFullYear();
    let month = (currentDate.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
    let day = currentDate.getDate().toString().padStart(2, '0');

    let formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
  }

  const handleFastTagData = () => {
    let payload = {
      vehicleNumber: tripDetails?.tripData?.vehicleDetails?.vehicleNumber?.replace(/[\s-]/g, '').toUpperCase() || "",
      size: "10000",
      page: 1,
      "fromDate": changeTimeStamp(tripDetails?.tripData?.detailsDeviceID?.time || ''),
      "toDate": tripDetails?.tripData?.status === 'completed' ? changeTimeStamp(tripDetails?.tripData?.endTime || '') : currentTimeFun(),
    };

    dispatch(
      getUlipData(payload, (response) => {
        if (myMap && mapLoaded && response?.data?.features?.length && !response?.data?.hasOwnProperty("success")) {
          let temp = response?.data?.features.slice(0, -1)
          let geoJson = {
            "type": "FeatureCollection",
            "features": temp
          }
          const geoJsonLayer = new google.maps.Data();
          geoJsonLayer.addGeoJson(geoJson);
          geoJsonLayer.setStyle(function (feature) {
            return {
              strokeOpacity: 0.8,
              strokeWeight: 2,
              fillOpacity: 0.5,
              icon: {
                url: Fastag,
                scaledSize: new google.maps.Size(24, 24)
              }
            };
          });
          geoJsonLayer.setMap(myMap);

          geoJsonLayer?.addListener("click", async (event) => {

            let markerData = {
              readTime: event.feature.getProperty("readerReadTime")
                ? convertIntoGivenTimezone(
                  event.feature.getProperty("readerReadTime"),
                  userDetails?.timeZone?.offset
                ) || "NA"
                : "",
              tollPlazaName: event.feature.getProperty("tollPlazaName"),
              vehicleRegNo: event.feature.getProperty("vehicleRegNo"),
              vehicleLatLng: event.feature.getProperty("tollPlazaGeocode"),
            };

            const payload = {
              latitude: markerData?.vehicleLatLng[1],
              longitude: markerData?.vehicleLatLng[0],
              appType: "GOOGLE",
            };

            console.log('2222222222')
            axios.post(`${locationUrl}fetchAddress`, payload, {
              headers: {
                "Content-Type": "application/json",
                authorization: "oLjJEPvkqYloUOfU1aCyAvOBMQKKG9Am",
              },
            }).then((response) => {
              let address = response?.data?.data[0]?.address;
              let infoWindowContent = ` 
                      <div class='infoDiv' style="width:390px;">
                      <div class='infoChild2'>
                        <div class='subChild2' style="width:33%;">
                          <img class='imgCss'  src=${toll} alt='' />
                          <span class='infoLabel label2'>Toll Plaza</span>
                          <span class='infoValue infoValue2'>${markerData?.tollPlazaName}</span>
                        </div>
      
                          <div class='subChild2' style="width:33%;">
                            <img class='imgCss' style='height:34px; width:34px;'  src=${truckIcon} alt='' />
                            <span class='infoLabel label2'>Vehicle Number </span>
                            <span class='infoValue infoValue2'>${markerData?.vehicleRegNo}</span>
                          </div>
      
                        <div class='subChild2' style="width:335;">
                            <img class='imgCss'  src=${dateTimeBold} alt='' />
                            <span class='infoLabel label2'>Time </span>
                            <span class='infoValue infoValue2'>${markerData?.readTime}</span>
                        </div>
      
      
                      </div>
      
                      <div class='infoChild3'>
                      <div class='addressDiv'>
                        <div class='addressImg'>
                        <img class='imgCss' src=${location} alt='' />
                        </div>
                        <div class='addressBox'>
                        <span class='infoLabel'>${address}</span>
                        </div>
                      </div>
      
                    </div>
                      </div>
                        `;

              const infoWindow = createInfoWindow(infoWindowContent, {
                lat: Number(event.latLng.lat()),
                lng: Number(event.latLng.lng()),
              });
              myMap.panTo({ lat: Number(event.latLng.lat()), lng: Number(event.latLng.lng()) });
              // infoWindows.forEach((window) => window.close());
              // infoWindow.open(myMap);
              // infoWindows.push(infoWindow);

              if (newInfoWindow) {
                newInfoWindow.close();
              }

              if (geofenceWindow) {
                geofenceWindow.close();
              }

              if (fastTageWindow) {
                fastTageWindow.close();
              }
              if (imageWindow) {
                imageWindow.close();
              }
              let temp = fastTageWindow

              if (fastTageWindow) {
                fastTageWindow.close();
              }

              temp.setContent(infoWindowContent);
              temp.setPosition(event.latLng);
              temp.open(myMap);
              // setInfoWindow(temp);
              setFastTagWindow(temp)

            })
              .catch((error) => {
                console.error("Error:", error);
              });
          });
        }
        else {
          // showToast(response?.data?.message, true);
        }
      })
    );
  };

  const plotImagesonMap = () => {
    if (myMap && mapLoaded && tripDetails?.tripData?.images?.length) {
      tripDetails.tripData.images.forEach(image => {
        const latLng = {
          lat: parseFloat(image.latitude),
          lng: parseFloat(image.longitude)
        };

        const imgName = image?.filePath?.split("/").pop();

        const marker = new google.maps.Marker({
          position: latLng,
          map: myMap,
          icon: {
            url: pin,
            scaledSize: new google.maps.Size(24, 24)
          }
        });

        marker.addListener('click', () => {

          const infoWindowContent = `
  <div class='infoDiv' style="width:260px; overflow: hidden;">
    <div class='infoChild2' style="display: flex; justify-content: center; align-items: center; height: auto;">
      <div class='subChild2' style="width:100%; height: 100%; ">
        <img class='imgCss' src='${imgBaseURL}/api/v1/geo-route/getFile/${imgName}' alt='' style="width: 100%; height: auto; object-fit: contain;" />
      </div>
    </div>
  </div>
`;

          // const infoWindow = createInfoWindow(infoWindowContent, latLng);
          myMap.panTo(latLng);

          if (newInfoWindow) {
            newInfoWindow.close();
          }

          if (geofenceWindow) {
            geofenceWindow.close();
          }

          if (fastTageWindow) {
            fastTageWindow.close();
          }
          if (imageWindow) {
            imageWindow.close();
          }
          let temp = imageWindow

          if (imageWindow) {
            imageWindow.close();
          }

          temp.setContent(infoWindowContent);
          temp.setPosition(latLng);
          temp.open(myMap);
          // setInfoWindow(temp);
          setImageWindow(temp)


        });
      });
    }
  };




  return (
    <>
      <Grid className='parentModal' container item={true} xs={12} sx={{ height: '89vh', overflow: 'auto' }}>
        <Grid item sm={12} >
          <Box
            // sx={{ height: "calc(100vh - 90px)", display: "flex" }}
            sx={{ height: "100%", display: "flex" }}
            position="relative"
          >
            {!mapLoaded ?
              <Grid
                container
                item
                direction={"column"}
                justifyContent={"center"}
                alignItems="center"
                xs={12}
                sx={{ p: 15 }}
              >
                <Grid item>
                  <img src={loaderGif} />
                </Grid>
              </Grid>
              :
              mapLoaded && window.google && window.google.maps.Map ?
                <Box width="100%">
                  {!localStorage.getItem('sidebar') ?
                    <Box height="58px">
                      <Typography sx={{ ...textClasses.cardTitle }}>
                        Trip Map
                      </Typography>
                      <Breadcrumbs data-testid="breadcrumbs">
                        <Typography
                          sx={{
                            ...textClasses.normalText,
                            fontSize: "12px",
                            color: themeType.default.themeOrange,
                          }}
                        >
                          <Link
                            style={{
                              color: themeType.default.themeOrange,
                              textDecoration: "none",
                            }}
                            data-testid="link-to-go-back"
                            to={{ pathname: "/dashboard/trips", state: { from: "trip-map-page" } }}
                          >
                            Trips
                          </Link>
                        </Typography>

                        <Typography
                          sx={{ ...textClasses.normalText, fontSize: "12px" }}
                        >
                          Trip Map
                        </Typography>
                      </Breadcrumbs>
                    </Box>
                    : null}
                  <Box bgcolor="#DADADA" width="100%" height="calc(100% - 58px)" sx={{ position: 'relative' }}>
                    <div id="tripmap" style={{ width: '100%', height: '100%' }}></div>
                    <Grid sx={{ width: '200px', position: 'absolute', top: '0px', left: "50%", transform: "translateX(-50%)", justifyContent: 'center', display: 'flex', alignItem: 'center' }}>
                      <Grid
                        item={true}
                        md={12}
                        pl={3}
                        pr={3}
                        pt={2}
                        position="relative"
                      >
                        <input
                          type="text"
                          placeholder="Search Places"
                          ref={searchInput}
                          data-testid="locations"
                          className="mapInput"
                          style={{
                            background: "#fff",
                            borderColor: isDarkThemeEnabledSelector
                              ? "rgb(81, 81, 81)"
                              : "#c6c6c6",
                          }}
                          id="searchTextField"
                          value={address}
                          onChange={(e) => {
                            setAddress(e.target.value);
                          }}
                        />
                        <Box
                          sx={{
                            position: "absolute",
                            right: "35px",
                            top: "40%",
                          }}
                        >
                          <Google
                            fontSize="15px"
                            sx={{
                              color: isDarkThemeEnabledSelector ? "#fff" : "#696969",
                            }}
                          />
                        </Box>
                      </Grid>
                    </Grid>
                    <Box
                      sx={{
                        position: "absolute",
                        height: "40px",
                        padding: 1,
                        borderRadius: "2px",
                        boxShadow: "rgba(0, 0, 0, 0.3) 0px 1px 4px -1px",
                        top: "60px",
                        left: "10px",
                        background: "#ffffffa3",
                      }}
                      className="d-flex-row"
                    >
                      <FormControlLabel
                        sx={{ color: "#000", fontWeight: "700" }}
                        control={
                          <Checkbox
                            sx={{ color: "#ff7200" }}
                            checked={viewPaths}
                            onChange={e => {
                              setViewPaths(e?.target?.checked ? true : false);
                            }}
                          />
                        }
                        label="View Paths"
                      />
                    </Box>
                  </Box>
                </Box>
                :
                null

            }
            {!geoEditDrawer ?
              <TripMapDrawer
                geofencePlotArray={geofencePlotArray}
                geoEditDrawer={geoEditDrawer}
                setGeoEditDrawer={setGeoEditDrawer}
                open={openDrawer}
                themeType={themeType}
                history={history}
                details={tripDetails}
                tripEventsDetails={tripEvents}
                tripDetails={tripDetails}
                setTripParam={setTripParam}
                setIdParam={setIdParam}
                setMymap={setMymap}
                idParam={idParam}
                myMap={myMap}
                infoWindow={infoWindow}
                newInfoWindow={newInfoWindow}
                setInfoWindow={setInfoWindow}
                userDetails={userDetails}
                tripDetailsLoader={tripDetailsLoader}
                geofenceWindow={geofenceWindow}
                fastTageWindow={fastTageWindow}
              />
              :
              <EditGeofenceDrawer
                open={geoEditDrawer}
                themeType={themeType}
                history={history}
                geoEditDrawer={geoEditDrawer}
                setGeoEditDrawer={setGeoEditDrawer}
                geofencePlotArray={geofencePlotArray}
                setGeofencePlotArray={setGeofencePlotArray}
                geofenceRenderer={geofenceRenderer}
                myMap={myMap}
                tripParam={tripParam}
                idParam={idParam}
                setTripDetailsLoader={setTripDetailsLoader}
              />

            }
            <DrawerFloaterLabel
              show={openDrawer}
              setShow={setOpenDrawer}
              text="Details"
              Icon={<Map sx={{ fontSize: "20px", color: "#ffffff" }} />}
            />
          </Box>
        </Grid>

      </Grid>
    </>

  );
};

export default TripMap;
