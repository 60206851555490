import { MODULE_LISTING, MODULE_NAMES, GET_BOARD, FLUSH_BOARD } from "../types";

const INIT_STATE = {
  moduleLists: [],
  moduleNames: [],
  boardsListing: [],
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case MODULE_LISTING:
      return { ...state, moduleLists: action.payload.rows };
    case MODULE_NAMES:
      return { ...state, moduleNames: action.payload.rows };
    case GET_BOARD:
      return {
        boardsListing:[],
        ...state,
        boardsListing: action.payload.rows,
        totalRecords: action.payload?.count,
      };
    case FLUSH_BOARD:
      return { ...state, boardsListing: [], totalRecords: 0 };
    default:
      return state;
  }
};
//chnage


