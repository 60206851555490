/* eslint-disable */
import React, { useState, useEffect, useRef } from 'react';
import { selectStylesOverride } from "../../../utils/util";
import {
    Button,
    Autocomplete,
    TextField,
    Grid,
    MenuItem,
    Paper,
    Menu,
    CardContent,
    Typography,
    FormControl,
    FormControlLabel,
    Checkbox
} from "@mui/material";
import { locationUrl } from "../../../services/config";
import { darkStyles } from "../../../services/constants";
import { FilterAlt } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import FiltersWrapper from "../../Components/FiltersWrapper/FiltersWrapper";
import loaderGif from "../../../static/images/drawer/loader.gif";
import { useForm, Controller } from "react-hook-form";
import CustomSelect from '../../../components/FormUI/CustomSelect';
import CustomMultiSelectWithLimit from '../../../components/FormUI/CustomMultiSelectWithLimit';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import EditIcon from '@mui/icons-material/Edit';
import ClearIcon from '@mui/icons-material/Clear';
import axios from 'axios';
import {
    getAllGeofences,
    getAllRoutesForTrips,
    getAllDevices,
    getAllDeviceCategoryNames,
    getAllGeofencesForMap,
    getMarkerForTrips,
    getMarkerForDevice

} from "../../../redux/actions";

import dateTime from "../../../assets/images/dateandtime.png"
import location from "../../../assets/infoWindow/location.png"
import truck from "../../../assets/infoWindow/truck.png"
import pin from "../../../assets/img/trackingicons/maps_red.png"
import tag from "../../../assets/infoWindow/tag.png"
import battery from "../../../assets/infoWindow/low-battery.png"
import speedImg from "../../../assets/images/speed.png"
import navigation from "../../../assets/images/navigation.png"
import setting from "../../../assets/infoWindow/setting.png"
import { capitalizeSentence, convertIntoGivenTimezone, showToast } from '../../../services/functions';
import history from '../../../services/history';
const MapsPage = () => {
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const [myMap, setMymap] = useState(null)
    const [mapLoaded, setMapLoaded] = useState(false);
    const [filter, setFilter] = useState();
    const [tripMarkerCluster, setTripMarkerCluster] = useState([]);
    const [deviceCluster, setDeviceCluster] = useState([]);
    const [searhFlag, setSearchFlag] = useState(false)
    const [geofenceArray, setGeofenceArray] = useState([])
    const [routeArray, setRouteArray] = useState([])
    const GeofencesListing = useSelector((state) => state.geofencelist.geofenceListing);
    const [dStatusFilterOptions, setDStatusFilterOptions] = useState([]);
    const tripMarkerListing = useSelector((state) => state.tripsData.tripMarkers);
    const deviceMarkerListing = useSelector((state) => state.deviceslist.deviceMarkers);
    const routeListing = useSelector((state) => state.tripsData.routeListForTrips);
    const { allDevices, allDeviceCategoryNames } = useSelector((state) => state.deviceslist);
    const [firstLoad, setFirstLoad] = useState(false)
    const [geoCount, setGeoCount] = useState(0)
    const [tripCount, setTripCount] = useState(0)
    const [deviceCount, setDeviceCount] = useState(0)
    const [checks, setChecks] = useState({
        selectAllGeofence: false,
        selectAllRoutes: false,
        selectAlltripLocations: false,
        geofenceShowCheck: true,
        tripShowCheck: true,
        deviceShowCheck: true
    })

    // const [newInfoWindow, setNewInfoWindow] = useState(null);
    const [searchFields, setSearchFields] = useState({
        geofence: null,
        trips: null,
        routes: null,
        tripLocations: null,
        devices: null
    });
    const geofenceListingForMap = useSelector((state) => state.geofencelist.geofenceListingForMap);
    var objs = {
        lat: Number(20.5937),
        lng: Number(78.9629),
    };
    const [selectedGeofences, setSelectedGeofences] = useState([])
    const [selectedRoutes, setSelectedRoutes] = useState([])
    const [selectedTripLocations, setSelectedTripLocations] = useState([])
    const {
        control,
        formState: { errors, dirtyFields },
    } = useForm();
    let map;
    const isDarkThemeEnabledSelector = useSelector((state) => state.theme.themeDark);
    const [geofencesList, setGeofencesList] = useState([]);
    const themeType = isDarkThemeEnabledSelector ? require("../../../static/styles/darktheme") : require("../../../static/styles/theme");
    const {
        theme,
        buttonClasses,
        cardClasses,
        inputClasses,
        textClasses,
        tableClasses,
        dividerClass,
    } = themeType;

    const [loadingGeofence, setLoadingGeofence] = useState(false);
    const [loadingTrips, setLoadingTrips] = useState(false);
    const [loadingDevices, setLoadingDevices] = useState(false);
    const [refreshTime, setRefreshTime] = useState(2)
    const [editIcon, setEditIcon] = useState(false)
    const isLoading = loadingGeofence || loadingTrips || loadingDevices;
    const infoWindowRef = useRef(null);
    const infoWindowRefTrip = useRef(null);
    const infoWindowRefDevice = useRef(null);
    const userDetails = useSelector((state) => state.auth.currentUserDetail);
    useEffect(() => {
        if (myMap) {
            const mapStyles = isDarkThemeEnabledSelector ? darkStyles : null;
            myMap.setOptions({ styles: mapStyles });
        }
    }, [isDarkThemeEnabledSelector, myMap])

    useEffect(() => {
        const fetchAll = () => {
            if (checks.geofenceShowCheck) {
                setLoadingGeofence(true);
                dispatch(getAllGeofencesForMap({}, () => {
                    setLoadingGeofence(false);
                }));
            }
            if (checks.tripShowCheck) {
                setLoadingTrips(true);
                dispatch(getMarkerForTrips(
                    {
                        status: [
                            "in_transit",
                            "reached",
                            "mapped"
                        ]
                    }, () => {
                        setLoadingTrips(false);
                    }));
            }
            if (checks.deviceShowCheck) {
                setLoadingDevices(true);
                dispatch(getMarkerForDevice({ status: "all" }, () => {
                    setLoadingDevices(false);
                }));
            }
        };
        setFilter((prev) => ({
            ...prev,
            status: 'allTrips',
        }));
        fetchAll();
    }, []);

    useEffect(() => {
        if (geofenceListingForMap.length) {
            setGeofencesList(geofenceListingForMap);
        }
    }, [geofenceListingForMap]);

    useEffect(() => {
        if (GeofencesListing && !searhFlag) {
            setGeofenceArray(GeofencesListing)
            setRouteArray(GeofencesListing)
        }
    }, [GeofencesListing, searhFlag])

    const tripStatusOptions = [
        { label: "All Trips", value: 'allTrips', },
        // { label: "Created", value: "initiated" },
        { label: "Mapped", value: "mapped" },
        { label: "In Transit", value: "in_transit" },
        { label: "Reached", value: "reached" },
    ];

    const handleApplyFilters = () => {
        let geofencePayload = {
            _id: filter?.filter?.geofenceIds
        }
        let tripsPayload = {
            status: filter?.status === 'allTrips' ? ["in_transit", "reached", "mapped"] : filter?.status,
            routes: filter?.filter?.routeId || [],
            locations: filter?.filter?.tripGeofenceId
        }
        let devicePayload = {
            deviceType: filter?.deviceType,
            clientId: filter?.clientId,
            status: filter?.deviceStatus
        }
        let refreshTimePayload = { autoRefreshLimit: refreshTime }

        if (checks.geofenceShowCheck) {
            dispatch(getAllGeofencesForMap({ filter: { ...geofencePayload } }, (res) => {
                if (res.success) {
                    myMap.data.forEach((feature) => {
                        myMap.data.remove(feature);
                    });
                    if (infoWindowRef.current) {
                        infoWindowRef.current.close();
                    }
                    setGeoCount(res?.data?.length)
                    setFirstLoad(true)
                    setGeofencesList(res?.data);
                }
                else {
                    // myMap.data.forEach((feature) => {
                    //     myMap.data.remove(feature);
                    // });
                    // if (infoWindowRef.current) {
                    //     infoWindowRef.current.close();
                    // }
                    // setGeofencesList([]);
                }
            }));

        }
        else {
            setGeoCount(0)
        }

        if (checks.tripShowCheck) {

            dispatch(getMarkerForTrips({ ...tripsPayload }, (res) => {
                if (!res.success) {
                    tripMarkerCluster.clearMarkers();
                    if (infoWindowRefTrip.current) {
                        infoWindowRefTrip.current.close();
                    }
                    showToast(res?.message, true)
                }
                else {
                    setTripCount(res?.data?.length)
                }
            }))
        }
        else {
            setTripCount(0)
        }
        if (checks.deviceShowCheck) {
            if (!filter?.deviceType) {
                showToast('Please select device type first.', true)
            }
            else {
                dispatch(getMarkerForDevice({ ...devicePayload }, (res) => {
                    // setDeviceCluster([])
                    if (!res.success) {
                        deviceCluster.clearMarkers();
                        if (infoWindowRefDevice.current) {
                            infoWindowRefDevice.current.close();
                        }
                        showToast(res?.message, true)
                    }
                    else {
                        setDeviceCount(res?.data?.length)
                    }
                }))
            }

        }
        else {
            setDeviceCount(0)
        }

        let finalPayload = { geofencePayload, tripsPayload, devicePayload, ...refreshTimePayload }
    };

    useEffect(() => {
        dispatch(getAllGeofences({ ...filter, filter: { status: 'ACTIVE' }, selection: ['name'] }, () => { }));
        dispatch(getAllRoutesForTrips({ limit: 20 }, (res) => { }));
        dispatch(getAllDeviceCategoryNames({ selection: ["label", "category"] }, () => {
            if (res) {

            }
        }));
    }, []);

    const handleSearchGeofence = (item) => {
        setSearchFlag(true)

        if (item === 'geofence') {
            let modifiedFilter = { ...filter };
            delete modifiedFilter?.status;
            delete modifiedFilter?.deviceStatus;
            delete modifiedFilter?.deviceType;



            // dispatch(getAllGeofences({ ...modifiedFilter, filter: { orgId: localStorage.getItem("orgId"), name: searchFields?.geofence }, selection: ['name'] }, (res) => {
            dispatch(getAllGeofences({ ...modifiedFilter, searchValue: searchFields?.geofence, selection: ['name'] }, (res) => {
                if (res?.success) {
                    setGeofenceArray(res?.data)
                    let temp = { ...searchFields }
                    temp.geofence = null
                    setSearchFields(temp)
                }
            }));

        }
        else if (item === 'tripGeofence') {
            dispatch(getAllGeofences({ filter: { orgId: localStorage.getItem("orgId"), name: searchFields?.tripLocations }, selection: ['name'] }, (res) => {
                if (res?.success) {
                    setRouteArray(res?.data)
                    let temp = { ...searchFields }
                    temp.tripLocations = null
                    setSearchFields(temp)
                }
            }));
        }
        else if (item === 'clearTripGeofence') {
            dispatch(getAllGeofences({ selection: ['name'] }, (res) => {
                if (res?.success) {
                    setRouteArray(res?.data)
                }
            }));
        }

        else {
            dispatch(getAllGeofences({ selection: ['name'] }, (res) => {
                if (res?.success) {
                    setGeofenceArray(res?.data)
                }
            }));
        }

    };

    const handleSearchRoutes = (item) => {
        if (item === 'routes') {
            dispatch(getAllRoutesForTrips({ limit: 20, regex: searchFields.routes }, (res) => {
                let temp = { ...searchFields }
                temp.routes = null
                setSearchFields(temp)
            }));
        }
        else {
            dispatch(getAllRoutesForTrips({ limit: 20 }, (res) => { }));
        }
    };
    const handleSearchDevices = (item) => {
        let modifiedFilter = { ...filter };
        delete modifiedFilter?.status;

        if (item === 'devices') {
            dispatch(getAllDevices({ filter: { ...modifiedFilter, deviceType: modifiedFilter?.deviceType }, q: searchFields.devices, selection: ['client_id', 'deviceType', '_id'] }, () => { }));
        }
        else {
            dispatch(getAllDevices({ filter: { ...modifiedFilter, deviceType: modifiedFilter?.deviceType }, limit: 50, pageNumber: 1, selection: ['client_id', 'deviceType', '_id'] }, () => { }));
        }
    }

    const handleToggleSelectAll = (e, label) => {
        let temp = { ...checks }
        if (label === 'Geofence') {
            if (!checks.selectAllGeofence) {
                temp.selectAllGeofence = true
                setChecks(temp)
                const selectedIds = geofenceArray.map(option => option._id);
                setFilter(prev => ({
                    ...prev,
                    filter: {
                        ...prev?.filter,
                        geofenceIds: selectedIds
                    },
                }));
                setSelectedGeofences(GeofencesListing.map(option => ({ _id: option._id, name: option.name })));
            }
            else {
                temp.selectAllGeofence = false
                setChecks(temp)
                setFilter(prev => ({
                    ...prev,
                    filter: {
                        ...prev?.filter,
                        geofenceIds: []
                    },
                }));
                setSelectedGeofences([]);
            }
        }
        else if (label === 'Routes') {
            if (!checks.selectAllRoutes) {
                temp.selectAllRoutes = true
                setChecks(temp)
                const selectedIds = routeListing?.routes?.map(option => option._id);
                setFilter(prev => ({
                    ...prev,
                    filter: {
                        ...prev?.filter,
                        routeId: selectedIds
                    },
                }));
                setSelectedRoutes(routeListing?.routes?.map(option => ({ _id: option._id, routeName: option.routeName })));
            }
            else {
                temp.selectAllRoutes = false
                setChecks(temp)
                setFilter(prev => ({
                    ...prev,
                    filter: {
                        ...prev?.filter,
                        routeId: []
                    },
                }));
                setSelectedRoutes([]);
            }
        }
        else if (label === 'Locations') {
            if (!checks.selectAlltripLocations) {
                temp.selectAlltripLocations = true
                setChecks(temp)
                const selectedIds = routeArray?.map(option => option._id);
                setFilter(prev => ({
                    ...prev,
                    filter: {
                        ...prev?.filter,
                        tripGeofenceId: selectedIds
                    },
                }));
                setSelectedTripLocations(routeArray?.map(option => ({ _id: option._id, name: option.name })));
            }
            else {
                temp.selectAlltripLocations = false
                setChecks(temp)
                setFilter(prev => ({
                    ...prev,
                    filter: {
                        ...prev?.filter,
                        tripGeofenceId: []
                    },
                }));
                setSelectedTripLocations([]);
            }
        }
    }

    const toggleTime = (item) => {
        if (item === 'minus') {
            if (refreshTime > 2) {
                setRefreshTime(prev => prev - 1)
            }
        }
        else {
            setRefreshTime(prev => prev + 1)
        }
    }
    useEffect(() => {
        const loadGoogleMap = () => {
            if (mapLoaded && !isLoading) {
                const mapElement = document.getElementById("completeMap") || '';
                if (
                    typeof google === "undefined" ||
                    typeof google.maps === "undefined"
                ) {
                    return null;
                }
                if (!mapElement) {
                    console.error("Element with id 'completeMap' not found.");
                    return null;
                }
                if (!objs.lat || !objs.lng) {
                    console.error("Latitude or longitude not provided.");
                    return null;
                }
                const mapOptions = {
                    zoom: 4,
                    center: { lat: parseFloat(objs.lat), lng: parseFloat(objs.lng) },
                    mapTypeId: "terrain",
                    zoomControl: true,
                    zoomControlOptions: {
                        style: google.maps.ZoomControlStyle.SMALL,
                        position: google.maps.ControlPosition.LEFT_BOTTOM,
                    },
                };

                const map = new google.maps.Map(mapElement, mapOptions);
                setMymap(map);
                return map;
            }
        };

        const timeout = setTimeout(loadGoogleMap, 40);
        setTimeout(() => {
            setMapLoaded(true);
            if (!infoWindowRef.current) {
                infoWindowRef.current = new google.maps.InfoWindow();
            }
            if (!infoWindowRefTrip.current) {
                infoWindowRefTrip.current = new google.maps.InfoWindow();
            }
            if (!infoWindowRefDevice.current) {
                infoWindowRefDevice.current = new google.maps.InfoWindow();
            }

        }, 100);
        return () => clearTimeout(timeout);
    }, [objs.lat, objs.lng, isLoading]);

    useEffect(() => {
        if (geofenceListingForMap && geofenceListingForMap?.length && checks.geofenceShowCheck) {
            setGeoCount(geofenceListingForMap?.length)
        }
        if (deviceMarkerListing && deviceMarkerListing?.length && checks.deviceShowCheck) {
            setDeviceCount(deviceMarkerListing?.length)
        }
        if (tripMarkerListing && tripMarkerListing?.length && checks.tripShowCheck) {
            setTripCount(tripMarkerListing?.length)
        }

    }, [geofenceListingForMap, deviceMarkerListing, tripMarkerListing, checks])

    // useEffect(() => {
    //     const initMap = () => {
    //         if (!mapLoaded || !myMap || !geofenceListingForMap.length || !checks.geofenceShowCheck) return;

    //         // const outputJson = {
    //         //     type: "FeatureCollection",
    //         //     features: geofenceListingForMap.map((location) => ({
    //         //         type: "Feature",
    //         //         properties: location.features[0].properties,
    //         //         geometry: location.features[0].geometry,
    //         //     })),
    //         // };
    //         // myMap.data.addGeoJson(outputJson);

    //         // const newMarkers = [];
    //         // myMap.data.forEach((feature) => {
    //         //     const geometry = feature.getProperty("centerCoordinates");
    //         //     let lat = geometry[0][0][1];
    //         //     let lng = geometry[0][0][0];
    //         //     let marker = new google.maps.Marker({
    //         //         position: new google.maps.LatLng(lat, lng),
    //         //         map: myMap,
    //         //     });
    //         //     newMarkers.push(marker);
    //         // });

    //         const newMarkers = geofenceListingForMap?.map((item) => {
    //             let lat = item.features[0].geometry.coordinates[0][0][1];
    //             let lng = item.features[0].geometry.coordinates[0][0][0];
    //             // return new google.maps.Marker({
    //             //     position: new google.maps.LatLng(lat, lng),
    //             //     map: myMap,
    //             // });
    //             const marker = new google.maps.Marker({
    //                 position: new google.maps.LatLng(lat, lng),
    //                 map: myMap,
    //             });
    //             marker.addListener("click", () => {
    //                 infoWindowRedererforGeofence(myMap, item);
    //             });
    //             return marker;
    //         });

    //         if (Object.keys(markerCluster).length) {
    //             markerCluster?.clearMarkers();
    //             markerCluster?.addMarkers(newMarkers);
    //         }
    //         else {
    //             let newCluster = new markerClusterer.MarkerClusterer({
    //                 map: myMap,
    //                 markers: newMarkers
    //             });
    //             setMarkersCluster(newCluster);
    //         }
    //         myMap.data.setStyle({ visible: false });
    //     };
    //     if (mapLoaded && geofenceListingForMap?.length) {
    //         initMap();
    //     }
    // }, [mapLoaded, myMap, geofenceListingForMap, checks.geofenceShowCheck, infoWindowGeofence]);
    // const infoWindowRedererforGeofence = (map, feature) => {

    useEffect(() => {
        if (!mapLoaded || !myMap || !geofencesList.length || !checks.geofenceShowCheck) return;

        const outputJson = {
            type: "FeatureCollection",
            features: geofencesList.map((location) => ({
                type: "Feature",
                properties: location.features[0].properties,
                geometry: location.features[0].geometry,
            })),
        };
        myMap?.data?.addGeoJson(outputJson);
        myMap?.data?.setStyle(function (feature) {
            return {
                fillColor: "#0059ff",
                strokeColor: "#0059ff",
                strokeOpacity: 0.8,
                strokeWeight: 1.5,
                fillOpacity: 0.4,
            };
        });
        if (firstLoad) {
            myMap.panTo({
                lat: Number(outputJson?.features[0]?.properties?.centerCoordinates[0][0][1]),
                lng: Number(outputJson?.features[0]?.properties?.centerCoordinates[0][0][0]),
            });
            myMap.setZoom(8);
        }


        myMap?.data?.addListener("click", function (event) {
            let props = {
                name: event.feature.getProperty("name"),
                status: event.feature.getProperty("status"),
                addedOn: convertIntoGivenTimezone(
                    event.feature.getProperty("addedOn"),
                    userDetails.timeZone.offset
                ),
                addedBy: event.feature.getProperty("addedBy"),
                centerCoordinates: event.feature.getProperty("centerCoordinates"),
            };
            infoWindowRedererForGeofence(props);
        });
        if (mapLoaded && geofencesList?.length) {
            initMap();
        }

    }, [myMap, mapLoaded, geofencesList, checks.geofenceShowCheck, firstLoad]);

    useEffect(() => {
        const initMap = () => {
            if (!mapLoaded || !myMap || !tripMarkerListing.length || !checks.tripShowCheck) return;
            const newMarkers = tripMarkerListing?.map((feature) => {
                let lat = feature?.latitude;
                let lng = feature?.longitude;

                const marker = new google.maps.Marker({
                    position: new google.maps.LatLng(lat, lng),
                    map: myMap,
                });
                marker.addListener("click", () => {
                    infoWindowRedererforTrips(myMap, feature);
                });
                return marker;
            });
            if (Object.keys(tripMarkerCluster).length) {
                tripMarkerCluster?.clearMarkers();
                tripMarkerCluster?.addMarkers(newMarkers);
            }
            else {
                let newCluster = new markerClusterer.MarkerClusterer({
                    map: myMap,
                    markers: newMarkers
                });
                setTripMarkerCluster(newCluster);
            }

        };
        if (mapLoaded && tripMarkerListing?.length) {
            initMap();
        }
    }, [mapLoaded, myMap, tripMarkerListing, checks.tripShowCheck]);

    useEffect(() => {
        const initMap = () => {
            if (!mapLoaded || !myMap || !deviceMarkerListing.length || !checks.deviceShowCheck) return;

            const newMarkers = deviceMarkerListing?.map((feature) => {
                let lat = feature?.latitude;
                let lng = feature?.longitude;
                // return new google.maps.Marker({
                //     position: new google.maps.LatLng(lat, lng),
                //     map: myMap,
                // });
                const marker = new google.maps.Marker({
                    position: new google.maps.LatLng(lat, lng),
                    map: myMap,
                });
                marker.addListener("click", () => {
                    infoWindowRedererForDevices(myMap, feature);
                });
                return marker;
            });

            if (Object.keys(deviceCluster).length) {
                deviceCluster?.clearMarkers();
                deviceCluster?.addMarkers(newMarkers);
            }
            else {
                let newCluster = new markerClusterer.MarkerClusterer({
                    map: myMap,
                    markers: newMarkers
                });
                setDeviceCluster(newCluster);
            }
        };

        if (mapLoaded && deviceMarkerListing?.length) {
            initMap();
        }
    }, [mapLoaded, myMap, deviceMarkerListing, checks.deviceShowCheck]);

    function ParseDate(date) {
        var t = Date.parse(date);
        if (isNaN(t)) {
            console.log('11', new Date(Date.parse(date.replace(/-/g, "/"))))
            // return new Date(Date.parse(date.replace(/-/g, "/")));
        } else {
            return new Date(t);
        }
    }


    useEffect(() => {
        let date = '27-05-2024 17:26:00'
        // let res = Date.parse(ParseDate(date)) / 1000;
        // console.log('res',res)

        let res1 = ParseDate("20" + date.substring(0, 2) + "-" + date.substring(2, 4) + "-" + date.substring(4, 6)
            + " " + date.substring(6, 8) + ":" + date.substring(8, 10) + ":" + date.substring(10, 12));


        console.log('res11', res1)

    }, [])





    const infoWindowRedererForGeofence = (props) => {
        const coordinates = props.centerCoordinates[0];
        const infoWindowContent = `
            <div style="min-width:310px">
                <div> <span style="display: inline-block; width: 80px; color: #909090; ">Name:</span> <span style="color: #ff7200; font-Size: 20px; font-Weight: 500; ">${props.name}</span></div><br>
                <div><span style="display: inline-block; width: 80px; color: #909090; " >Status:</span> <span style="color: #000; font-Weight: 500;">  ${props?.status}</span><br><br>
                <div><span style="display: inline-block; width: 80px; color: #909090; ">Added By:</span><span style="color: #000; font-Weight: 500; "> ${props?.addedBy?.name}</span><br><br>
                <div><span style="display: inline-block; width: 80px; color: #909090; ">Added On:</span><span style="color: #000; font-Weight: 500; "> ${props?.addedOn}</span><br><br>
            </div>
            `;

        if (infoWindowRefTrip.current) {
            infoWindowRefTrip.current.close();
        }

        if (infoWindowRefDevice.current) {
            infoWindowRefDevice.current.close();
        }



        infoWindowRef.current.setContent(infoWindowContent);
        infoWindowRef.current.setPosition({
            lat: Number(coordinates[0][1]),
            lng: Number(coordinates[0][0]),
        });
        infoWindowRef.current.open(myMap);

        myMap.panTo({
            lat: Number(coordinates[0][1]),
            lng: Number(coordinates[0][0]),
        });

    };


    const getCount = (feature) => {
        let val = feature?.satellite_count
        if (val <= 5) {
            return 'Poor';
        } else if (val >= 5 && val <= 11) {
            return 'Average';
        } else {
            return 'Good';
        }
    }

    const InfoWindowBtns = () => {
        return (
          <div class="d-flex-row gap-1 info-buttons-container">
            <div
              class="info-window-green-btn"
              onclick={() => {
                console.log("clickedddd");
              }}
            >
              Device Map
            </div>
            <div class="info-window-green-btn">Trip Map</div>
          </div>
        );
    }
    const infoWindowRedererforTrips = (map, feature) => {
        console.log("feature trips: ", feature);
        let date = convertIntoGivenTimezone(feature?.packet_time, userDetails.timeZone.offset) || '-';
        let speed = feature?.speed !== 0 || feature?.speed !== undefined || feature?.speed !== 'NA' ? feature?.speed + ' km/h' : 0
        let tripStatus = feature?.ignition_status || ''
        let deviceType = feature?.deviceType || ''
        const batteryPercentage = feature.batteryPercentage !== undefined && feature.batteryPercentage !== null
            ? `${feature.batteryPercentage} %`
            : '0 %';
        let getSatelliteCount = getCount(feature)

        const payload = {
            latitude: feature?.latitude,
            longitude: feature?.longitude,
            appType: "GOOGLE",
        };
        axios.post(`${locationUrl}fetchAddress`, payload, {
            headers: {
                "Content-Type": "application/json",
                authorization: "oLjJEPvkqYloUOfU1aCyAvOBMQKKG9Am",
            },
        }).then(response => {
            let address = response?.data?.data[0]?.address;
            let infoWindowHtml = `<div class='infoDiv'>
            <div class='d-flex-row gap-1 info-buttons-container'>
                <div class='info-window-green-btn' id='redirect-to-devicemap'>Device Map</div>
                <div class='info-window-green-btn' id='redirect-to-tripmap'>Trip Map</div>
            </div>
            <div class='infoChild1'>
              <div class='subChild' style="background-color: #FFAA1D" " >
              <img class='lockImg'  src=${setting} alt='' />
              <span class='deviceLabel'>Device Status </span>
              <span class='devicevalue'>${capitalizeSentence(tripStatus)}</span>
            </div>

         
              
            <div class='subChild bgblue'>
                <img class='lockImg'  src=${tag} alt='' />
                <span class='deviceLabel'> Device Id </span>
                <span class='devicevalue'>${feature.client_id}</span>
            </div>

            </div>
            <div class='infoChild2'>
            ${deviceType === "elock" || deviceType == 'assetTracker' ?
                    `<div class='subChild2 '>
                <img class='imgCss'  src=${battery} alt='' />
                <span class='infoLabel label2'>Battery</span>
                <span class='infoValue infoValue2'>${batteryPercentage}</span>
            </div>`
                    : ''}
            <div class='subChild2 '>
                <img class='imgCss'  src=${navigation} alt='' />
                <span class='infoLabel label2'>GPS</span>
                <span class='infoValue infoValue2'>${getSatelliteCount}</span>
            </div>

            <div class='subChild2'>
                <img class='imgCss'  src=${speedImg} alt='' />
                <span class='infoLabel label2'>Speed </span>
                <span class='infoValue infoValue2'>${speed}</span>
            </div>

        

            <div class='subChild2'>
                <img class='imgCss'  src=${dateTime} alt='' />
                <span class='infoLabel label2'>Date & Time</span>
                <span class='infoValue infoValue2'>${date}</span>
            </div>
            </div>

            <div class='infoChild3'>
            <div class='addressDiv'>
                <div class='addressImg'>
                <img class='imgCss' src=${location} alt='' />
                </div>
                <div class='addressBox'>
                <span class='infoLabel'>${address}</span>
                </div>
            </div>
            
            </div>
            </div>
        `
            if (infoWindowRef.current) {
                infoWindowRef.current.close();
            }

            if (infoWindowRefDevice.current) {
                infoWindowRefDevice.current.close();
            }

            infoWindowRefTrip.current.setContent(infoWindowHtml);
            infoWindowRefTrip.current.setPosition({
                lat: Number(feature?.latitude),
                lng: Number(feature?.longitude),
            });
            infoWindowRefTrip.current.open(map);

            map.panTo({
                lat: Number(feature?.latitude),
                lng: Number(feature?.longitude),
            });

            infoWindowRefTrip.current.open(map);
            
            window.google.maps.event.addListener(
              infoWindowRefTrip.current,
              "domready",
              () => {
                document
                  .querySelector("#redirect-to-devicemap")
                  .addEventListener("click", () => {
                    history.push(`/dashboard/devices/map/${feature?.client_id}`);
                  });
              }
            );
            
            window.google.maps.event.addListener(
              infoWindowRefTrip.current,
              "domready",
              () => {
                document
                  .querySelector("#redirect-to-tripmap")
                  .addEventListener("click", () => {
                    history.push(`/dashboard/trips/trip-map/${feature.trip?._id}?id=${feature?.trip?.detailsDeviceID?.deviceID}`)
                  });
              }
            );


        }).catch(error => {
            console.error('Error creating data:', error);
        });
    };

    const infoWindowRedererForDevices = (map, feature) => {
        console.log("feature: ", feature);
        let date = convertIntoGivenTimezone(feature?.packet_time * 1000, userDetails.timeZone.offset) || '-';
        let speed = feature?.speed !== 0 || feature?.speed !== undefined || feature?.speed !== 'NA' ? feature?.speed + ' km/h' : 0
        let tripStatus = feature?.ignition_status || ''
        let deviceType = feature?.deviceType || ''
        const batteryPercentage = feature.batteryPercentage !== undefined && feature.batteryPercentage !== null
            ? `${feature.batteryPercentage} %`
            : '0 %';
        let getSatelliteCount = getCount(feature)

        const payload = {
            latitude: feature?.latitude,
            longitude: feature?.longitude,
            appType: "GOOGLE",
        };
        axios.post(`${locationUrl}fetchAddress`, payload, {
            headers: {
                "Content-Type": "application/json",
                authorization: "oLjJEPvkqYloUOfU1aCyAvOBMQKKG9Am",
            },
        }).then(response => {
            let address = response?.data?.data[0]?.address
            let infoWindowHtml = `<div class='infoDiv'>
            <div class='d-flex-row gap-1 info-buttons-container'>
                <div class='info-window-green-btn' id='redirect-to-devicemap'>Device Map</div>
                <!-- <div class='info-window-green-btn' id='redirect-to-tripmap'>Trip Map</div> -->
            </div>
            <div class='infoChild1'>
              <div class='subChild' style="background-color: #FFAA1D" " >
              <img class='lockImg'  src=${setting} alt='' />
              <span class='deviceLabel'>Device Status </span>
              <span class='devicevalue'>${capitalizeSentence(tripStatus)}</span>
            </div>
              
            <div class='subChild bgblue'>
                <img class='lockImg'  src=${tag} alt='' />
                <span class='deviceLabel'> Device Id </span>
                <span class='devicevalue'>${feature.client_id}</span>
            </div>

            </div>
            <div class='infoChild2'>

            ${deviceType === "elock" || deviceType == 'assetTracker' ?
                    `<div class='subChild2 '>
        <img class='imgCss'  src=${battery} alt='' />
        <span class='infoLabel label2'>Battery</span>
        <span class='infoValue infoValue2'>${batteryPercentage}</span>
    </div>`
                    : ''}

            <div class='subChild2 '>
                <img class='imgCss'  src=${navigation} alt='' />
                <span class='infoLabel label2'>GPS</span>
                <span class='infoValue infoValue2'>${getSatelliteCount}</span>
            </div>

            <div class='subChild2'>
                <img class='imgCss'  src=${speedImg} alt='' />
                <span class='infoLabel label2'>Speed </span>
                <span class='infoValue infoValue2'>${speed}</span>
            </div>

        

            <div class='subChild2'>
                <img class='imgCss'  src=${dateTime} alt='' />
                <span class='infoLabel label2'>Date & Time</span>
                <span class='infoValue infoValue2'>${date}</span>
            </div>
            </div>

            <div class='infoChild3'>
            <div class='addressDiv'>
                <div class='addressImg'>
                <img class='imgCss' src=${location} alt='' />
                </div>
                <div class='addressBox'>
                <span class='infoLabel'>${address}</span>
                </div>
            </div>
            
            </div>
            </div>
        `

            if (infoWindowRef.current) {
                infoWindowRef.current.close();
            }

            if (infoWindowRefTrip.current) {
                infoWindowRefTrip.current.close();
            }

            infoWindowRefDevice.current.setContent(infoWindowHtml);
            infoWindowRefDevice.current.setPosition({
                lat: Number(feature?.latitude),
                lng: Number(feature?.longitude),
            });
            infoWindowRefDevice.current.open(map);

            map.panTo({
                lat: Number(feature?.latitude),
                lng: Number(feature?.longitude),
            });

            infoWindowRefDevice.current.open(map);

            window.google.maps.event.addListener(
                infoWindowRefDevice.current,
                "domready",
                () => {
                  document
                    .querySelector("#redirect-to-devicemap")
                    .addEventListener("click", () => {
                      history.push(`/dashboard/devices/map/${feature?.client_id}`);
                    });
                }
              );
              
            //   window.google.maps.event.addListener(
            //     infoWindowRefDevice.current,
            //     "domready",
            //     () => {
            //       document
            //         .querySelector("#redirect-to-tripmap")
            //         .addEventListener("click", () => {
            //         //   history.push(`/dashboard/trips/trip-map/${feature._id}?id=${feature?.client_id}`)
            //         });
            //     }
            //   );


        }).catch(error => {
            console.error('Error creating data:', error);
        });


    };

    const clearAllFilter = () => {
        // let temp = { ...filter }
        // temp.status = ''
        // temp.deviceType = ''
        // temp.deviceStatus = ''
        // temp.clientId = ''
        // temp.filter.geofenceIds = []
        // temp.filter.routeId = []
        // temp.filter.routeId = []
        setFilter(null)

        setSelectedGeofences([])
        setSelectedRoutes([])
        setSelectedTripLocations([])
    }




    return (
        <FiltersWrapper
            handleDrawerToggle={() => setOpen(!open)}
            open={open}
            isDarkThemeEnabledSelector={isDarkThemeEnabledSelector}
            selectStylesOverride={selectStylesOverride(isDarkThemeEnabledSelector)}
            themeType={themeType}
            handleApplyFilters={handleApplyFilters}
            additionalFilter={
                <Grid container sx={{ position: 'relative', top: '-16px' }}>
                    <>
                        <Grid data-testid='geofenceBox' item md={12} ml={2} mr={2} style={{ width: "100%", margin: '0px', padding: '10px', paddingTop: '0px', paddingBottom: '5px', borderBottom: '1px dashed #D0D0D0' }}>
                            <Grid item xs={12} mb={1} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <FormControlLabel
                                    sx={{
                                        color: "rgb(128, 128, 128)",
                                        "& .MuiFormControlLabel-label": { fontSize: "15px", paddingTop: '2px' },
                                    }}
                                    label="Geofence"
                                    control={
                                        <Checkbox
                                            id="select-all-checkbox"
                                            sx={{
                                                color: "#ff7200",
                                                "&.Mui-checked": {
                                                    color: "#ff7200",
                                                }
                                            }}
                                            data-testid='geofencecheckbox'
                                            checked={checks.geofenceShowCheck}
                                            onClick={(e) => {
                                                e.preventDefault();

                                                setChecks(prevChecks => ({
                                                    ...prevChecks,
                                                    geofenceShowCheck: !prevChecks.geofenceShowCheck
                                                }));
                                                if (checks.geofenceShowCheck) {
                                                    myMap.data.forEach((feature) => {
                                                        myMap.data.remove(feature);
                                                    });
                                                    if (infoWindowRef.current) {
                                                        infoWindowRef.current.close();
                                                    }
                                                    setGeofencesList([]);
                                                    setGeoCount(0)
                                                }
                                                else {
                                                    setGeofencesList(geofenceListingForMap)
                                                    setGeoCount(geofenceListingForMap?.length)
                                                }
                                            }}
                                        />
                                    }
                                />
                                {/* <Typography sx={{ color: '#000', fontSize: '14px', paddingRight: '8px', fontWeight: '500' }}>: {geoCount || 0}</Typography> */}
                            </Grid>

                            <Grid item md={12} mt={0} mb={1} style={{ width: "100%" }}>
                                <FormControl
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                    sx={{ ...selectStylesOverride(isDarkThemeEnabledSelector) }}
                                >
                                    <CustomMultiSelectWithLimit
                                        sx={{
                                            ...inputClasses.filterField,
                                        }}
                                        handleToggleSelectAll={handleToggleSelectAll}
                                        limitTags={1}
                                        checkName={checks.selectAllGeofence}
                                        errors={errors}
                                        options={geofenceArray.filter(obj1 => !selectedGeofences.some(obj2 => obj2._id === obj1._id))}
                                        data-testid='geofenceDropdown'
                                        defaultValue={[]}
                                        value={selectedGeofences || []}
                                        label="Geofence"
                                        searchValue={
                                            searchFields?.geofence && searchFields?.geofence !== ""
                                                ? searchFields?.geofence
                                                : null
                                        }
                                        inputValue={searchFields?.geofence && searchFields?.geofence !== ""
                                            ? searchFields?.geofence
                                            : ''}
                                        onSearch={() => handleSearchGeofence('geofence')}

                                        getOptionLabel={(option) => {
                                            return option?.name
                                        }}
                                        onInputChange={(e) => {
                                            if (e?.type === "change") {
                                                if (e?.target?.value === "") handleSearchGeofence(true);
                                                setSearchFields((prev) => ({
                                                    ...prev,
                                                    geofence: e?.target?.value,
                                                }));
                                            }
                                        }}
                                        onChange={(e, selectedOptions) => {
                                            if (selectedOptions && selectedOptions.length > 0) {
                                                const selectedIds = selectedOptions.map(option => option._id);
                                                setFilter(prev => ({
                                                    ...prev,
                                                    filter: {
                                                        ...prev?.filter,
                                                        geofenceIds: selectedIds,
                                                    },
                                                }));
                                                setSelectedGeofences(selectedOptions.map(option => ({ _id: option._id, name: option.name })));
                                            } else {
                                                let temp = { ...checks }
                                                temp.selectAllGeofence = false
                                                setChecks(temp)
                                                setFilter(prev => {
                                                    const temp = { ...prev };
                                                    if (temp.filter && temp.filter.geofenceIds) {
                                                        delete temp.filter.geofenceIds;
                                                    }
                                                    return temp;
                                                });
                                                setSelectedGeofences([]);
                                            }
                                        }}

                                        filterSelectedOptions
                                        labelKey="name"
                                    />




                                </FormControl>
                            </Grid>

                        </Grid>

                        <Grid data-testid='tripBox' item md={12} ml={2} mr={2} style={{ width: "100%", margin: '0px', padding: '10px', paddingBottom: '5px', borderBottom: '1px dashed #D0D0D0' }}>
                            <Grid item xs={12} mb={0} mt={-1} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <FormControlLabel
                                    sx={{
                                        color: "rgb(128, 128, 128)",
                                        "& .MuiFormControlLabel-label": { fontSize: "15px", paddingTop: '2px' },
                                    }}
                                    label="Trips"
                                    control={
                                        <Checkbox
                                            id="select-all-checkbox"
                                            sx={{
                                                color: "#ff7200",
                                                "&.Mui-checked": {
                                                    color: "#ff7200",
                                                }
                                            }}
                                            data-testid='tripscheckbox'
                                            checked={checks.tripShowCheck}
                                            onClick={(e) => {
                                                e.preventDefault();

                                                setChecks(prevChecks => ({
                                                    ...prevChecks,
                                                    tripShowCheck: !prevChecks.tripShowCheck
                                                }));
                                                if (checks.tripShowCheck) {
                                                    setTripCount(0)
                                                    tripMarkerCluster.clearMarkers();
                                                    if (infoWindowRefTrip.current) {
                                                        infoWindowRefTrip.current.close();
                                                    }
                                                }
                                                else {
                                                    setTripCount(tripMarkerListing?.length)
                                                }
                                            }}
                                        />
                                    }
                                />
                                {/* <Typography sx={{ color: '#000', fontSize: '14px', paddingRight: '8px', fontWeight: '500' }}>: {tripCount || 0}</Typography> */}
                            </Grid>

                            <Grid item xs={12} mb={2} >
                                <FormControl
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                    sx={{ ...selectStylesOverride }}
                                >

                                    <CustomSelect
                                        selectStylesOverride={selectStylesOverride(isDarkThemeEnabledSelector)}
                                        sx={{ ...inputClasses.filterField }}
                                        label="Status"
                                        labelKey="label"
                                        searchValue={null}
                                        value={filter?.status || null}
                                        onChange={(e, newVal) => {
                                            if (newVal) {
                                                setFilter((prev) => ({
                                                    ...prev,
                                                    status: newVal?.value,
                                                }));
                                            } else {
                                                let temp = { ...filter };
                                                temp.status = "";
                                                setFilter(temp);
                                            }

                                        }}
                                        getOptionLabel={(option) => {
                                            if (typeof option === "string") {
                                                return (
                                                    tripStatusOptions?.find((_) => _?.value == filter?.status)
                                                        ?.label || ""
                                                );
                                            } else {
                                                return option?.label || "";
                                            }
                                        }}
                                        options={tripStatusOptions && tripStatusOptions}
                                    />
                                </FormControl>
                            </Grid>



                            <Grid item md={12} mb={2} style={{ width: "100%" }}>
                                <FormControl
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                    sx={{ ...selectStylesOverride(isDarkThemeEnabledSelector) }}
                                >
                                    <CustomMultiSelectWithLimit
                                        sx={{
                                            ...inputClasses.filterField,
                                        }}
                                        limitTags={1}
                                        errors={errors}
                                        checkName={checks.selectAllRoutes}
                                        handleToggleSelectAll={handleToggleSelectAll}
                                        options={routeListing?.routes?.filter(obj1 => !selectedRoutes.some(obj2 => obj2?._id === obj1?._id))}
                                        value={selectedRoutes || []}
                                        data-testid='routesDropdown'
                                        defaultValue={[]}
                                        label="Routes"
                                        searchValue={
                                            searchFields?.routes && searchFields?.routes !== ""
                                                ? searchFields?.routes
                                                : null
                                        }
                                        inputValue={searchFields?.routes && searchFields?.routes !== ""
                                            ? searchFields?.routes
                                            : ''}
                                        onSearch={() => handleSearchRoutes('routes')}
                                        getOptionLabel={(option) => {
                                            return option?.routeName
                                        }}
                                        onInputChange={(e) => {
                                            if (e?.type === "change") {
                                                if (e?.target?.value === "") handleSearchRoutes(true);
                                                setSearchFields((prev) => ({
                                                    ...prev,
                                                    routes: e?.target?.value,
                                                }));
                                            }
                                        }}
                                        onChange={(e, selectedOptions) => {
                                            if (selectedOptions && selectedOptions.length > 0) {
                                                const selectedIds = selectedOptions.map(option => option._id);
                                                setFilter(prev => ({
                                                    ...prev,
                                                    filter: {
                                                        ...prev?.filter,
                                                        routeId: selectedIds,
                                                    },
                                                }));
                                                setSelectedRoutes(selectedOptions.map(option => ({ _id: option._id, routeName: option.routeName })));
                                            }
                                            else {
                                                let temp = { ...checks }
                                                temp.selectAllRoutes = false
                                                setChecks(temp)

                                                setFilter(prev => {
                                                    const temp = { ...prev };
                                                    if (temp.filter && temp.filter.routeId) {
                                                        delete temp.filter.routeId;
                                                    }
                                                    return temp;
                                                });
                                                setSelectedRoutes([])
                                            }
                                        }}

                                        filterSelectedOptions
                                        labelKey="routeName"

                                    />



                                </FormControl>
                            </Grid>

                            <Grid item md={12} mb={1} style={{ width: "100%" }}>
                                <FormControl
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                    sx={{ ...selectStylesOverride(isDarkThemeEnabledSelector) }}
                                >
                                    <CustomMultiSelectWithLimit
                                        sx={{
                                            ...inputClasses.filterField,
                                        }}
                                        limitTags={1}
                                        errors={errors}
                                        checkName={checks.selectAlltripLocations}
                                        options={routeArray?.filter(obj1 => !selectedTripLocations.some(obj2 => obj2?._id === obj1?._id))}
                                        defaultValue={[]}
                                        value={selectedTripLocations || []}
                                        label="Locations"
                                        data-testid='tripLocations'
                                        handleToggleSelectAll={handleToggleSelectAll}
                                        searchValue={
                                            searchFields?.tripLocations && searchFields?.tripLocations !== ""
                                                ? searchFields?.tripLocations
                                                : null
                                        }
                                        inputValue={searchFields?.tripLocations && searchFields?.tripLocations !== ""
                                            ? searchFields?.tripLocations
                                            : ''}
                                        onSearch={() => handleSearchGeofence('tripGeofence')}
                                        getOptionLabel={(option) => {
                                            return option?.name
                                        }}

                                        onInputChange={(e) => {
                                            if (e?.type === "change") {
                                                if (e?.target?.value === "") handleSearchGeofence('clearTripGeofence');
                                                setSearchFields((prev) => ({
                                                    ...prev,
                                                    tripLocations: e?.target?.value,
                                                }));
                                            }
                                        }}
                                        onChange={(e, selectedOptions) => {
                                            if (selectedOptions && selectedOptions.length > 0) {
                                                const selectedIds = selectedOptions.map(option => option._id);
                                                setFilter(prev => ({
                                                    ...prev,
                                                    filter: {
                                                        ...prev?.filter,
                                                        tripGeofenceId: selectedIds,
                                                    },
                                                }));
                                                setSelectedTripLocations(selectedOptions.map(option => ({ _id: option._id, name: option.name })));
                                            } else {
                                                let temp = { ...checks }
                                                temp.selectAlltripLocations = false
                                                setChecks(temp)

                                                setFilter(prev => {
                                                    const temp = { ...prev };
                                                    if (temp.filter && temp.filter.tripGeofenceId) {
                                                        delete temp.filter.tripGeofenceId;
                                                    }
                                                    return temp;
                                                });
                                                setSelectedTripLocations([])
                                            }
                                        }}
                                        filterSelectedOptions
                                        labelKey="name"

                                    />



                                </FormControl>
                            </Grid>

                        </Grid>

                        <Grid data-testid='DeviceBox' item md={12} ml={2} mr={2} style={{ width: "100%", margin: '0px', padding: '10px', borderBottom: '1px dashed #D0D0D0' }} >
                            <Grid item xs={12} mb={0} mt={-1} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <FormControlLabel
                                    sx={{
                                        color: "rgb(128, 128, 128)",
                                        "& .MuiFormControlLabel-label": { fontSize: "15px", paddingTop: '2px' },
                                    }}
                                    label="Devices"
                                    control={
                                        <Checkbox
                                            id="select-all-checkbox"
                                            sx={{
                                                color: "#ff7200",
                                                "&.Mui-checked": {
                                                    color: "#ff7200",
                                                }
                                            }}
                                            data-testid='devicescheckbox'
                                            checked={checks.deviceShowCheck}
                                            onClick={(e) => {
                                                e.preventDefault();

                                                setChecks(prevChecks => ({
                                                    ...prevChecks,
                                                    deviceShowCheck: !prevChecks.deviceShowCheck
                                                }));
                                                if (checks.deviceShowCheck) {
                                                    deviceCluster.clearMarkers();
                                                    setDeviceCount(0)
                                                    if (infoWindowRefDevice.current) {
                                                        infoWindowRefDevice.current.close();
                                                    }
                                                }
                                                else {
                                                    setDeviceCount(deviceMarkerListing?.length)
                                                }
                                            }}
                                        />
                                    }
                                />
                                {/* <Typography sx={{ color: '#000', fontSize: '14px', paddingRight: '8px', fontWeight: '500' }}>: {deviceCount || 0}</Typography> */}
                            </Grid>

                            <Grid item md={12} mt={0} mb={2} style={{ width: "100%" }}>
                                <FormControl
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                    sx={{ ...selectStylesOverride }}
                                >
                                    <CustomSelect
                                        selectStylesOverride={selectStylesOverride(isDarkThemeEnabledSelector)}
                                        sx={{ ...inputClasses.filterField }}
                                        label="Device Type"
                                        labelKey="label"
                                        data-testid='deviceType'
                                        searchValue={null}
                                        value={filter?.deviceType || null}
                                        onChange={(e, newVal) => {
                                            if (newVal) {
                                                let modifiedFilter = { ...filter };
                                                delete modifiedFilter?.status;
                                                
                                                setFilter((prev) => ({ ...prev, deviceType: newVal?.category }));
                                                if (newVal.category === 'fixedTracker') {
                                                    setDStatusFilterOptions([
                                                        { label: "Idle", value: "IDLE" },
                                                        { label: "Moving", value: "MOTION" },
                                                        { label: "Offline", value: "OFFLINE" },
                                                        { label: "Ignition Off", value: "IGNITION_OFF" },
                                                    ]);
                                                }
                                                else if (newVal.category === "fixedELock") {
                                                    setDStatusFilterOptions([
                                                        { label: "Idle", value: "IDLE" },
                                                        { label: "Moving", value: "MOTION" },
                                                        { label: "Offline", value: "OFFLINE" },
                                                    ]);
                                                }

                                                else {
                                                    setDStatusFilterOptions([
                                                        { label: "Idle", value: "IDLE" },
                                                        { label: "Moving", value: "MOTION" },
                                                        { label: "Offline", value: "OFFLINE" },
                                                    ]);
                                                }
                                                dispatch(getAllDevices({ filter: { ...modifiedFilter, deviceType: newVal?.category }, limit: 50, pageNumber: 1, selection: ['client_id', 'deviceType', '_id'] }));
                                            } else {
                                                let temp = { ...filter };
                                                temp.deviceType = "";
                                                setFilter(temp);
                                            }
                                        }}
                                        getOptionLabel={(option) => {
                                            if (typeof option === "string") {
                                                return (
                                                    allDeviceCategoryNames?.find((_) => _?.category == filter?.deviceType)
                                                        ?.label || ""
                                                );
                                            } else {
                                                return option?.label || "";
                                            }
                                        }}
                                        options={allDeviceCategoryNames && allDeviceCategoryNames}
                                    />
                                </FormControl>
                            </Grid>

                            <Grid item md={12} mt={0} mb={2} style={{ width: "100%" }}>
                                <FormControl
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                    sx={{ ...selectStylesOverride }}
                                >
                                    <CustomSelect
                                        selectStylesOverride={selectStylesOverride(isDarkThemeEnabledSelector)}
                                        sx={{ ...inputClasses.filterField }}
                                        label="Device Status"
                                        labelKey="label"
                                        data-testid='deviceStatus'
                                        searchValue={null}
                                        value={filter?.deviceStatus || null}
                                        onChange={(e, newVal) => {
                                            if (newVal) {
                                                setFilter((prev) => ({
                                                    ...prev,
                                                    deviceStatus: newVal?.label,
                                                }));
                                            } else {
                                                let temp = { ...filter };
                                                temp.deviceStatus = "";
                                                setFilter(temp);
                                            }
                                        }}
                                        getOptionLabel={(option) => {
                                            if (typeof option === "string") {
                                                return (
                                                    dStatusFilterOptions?.find((_) => _?.label == filter?.deviceStatus)
                                                        ?.label || ""
                                                );
                                            } else {
                                                return option?.label || "";
                                            }
                                        }}
                                        options={dStatusFilterOptions && dStatusFilterOptions}
                                    />
                                </FormControl>
                            </Grid>

                            <Grid item md={12} mt={0} mb={0} style={{ width: "100%" }}>
                                <FormControl
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                    sx={{ ...selectStylesOverride }}
                                >
                                    <CustomSelect
                                        selectStylesOverride={selectStylesOverride(isDarkThemeEnabledSelector)}
                                        sx={{ ...inputClasses.filterField }}
                                        label="All Devices"
                                        labelKey="client_id"
                                        data-testid='deviceId'

                                        searchValue={
                                            searchFields?.devices && searchFields?.devices !== ""
                                                ? searchFields?.devices
                                                : null
                                        }
                                        onSearch={() => handleSearchDevices('devices')}
                                        onInputChange={(e) => {
                                            if (e?.type === "change") {
                                                if (e?.target?.value === "") handleSearchDevices(true);
                                                setSearchFields((prev) => ({
                                                    ...prev,
                                                    devices: e?.target?.value,
                                                }));
                                            }
                                        }}

                                        value={filter?.clientId || null}
                                        onChange={(e, newVal) => {
                                            if (newVal) {
                                                setFilter((prev) => ({
                                                    ...prev,
                                                    clientId: newVal?.client_id,
                                                }));
                                            } else {
                                                let temp = { ...filter };
                                                temp.clientId = "";
                                                setFilter(temp);
                                            }
                                        }}
                                        getOptionLabel={(option) => {
                                            if (typeof option === "string") {
                                                return (
                                                    allDevices?.find((_) => _?.client_id == filter?.clientId)
                                                        ?.client_id || ""
                                                );
                                            } else {
                                                return option?.client_id || "";
                                            }
                                        }}
                                        options={allDevices && allDevices}
                                    />

                                </FormControl>
                            </Grid>

                        </Grid>

                        {/* <Grid data-testid='refreshBox' item md={12} ml={2} mr={2} style={{ width: "100%", margin: '0px', padding: '10px', borderBottom: '1px dashed #D0D0D0' }} >
                            <Grid item xs={12} mb={0} mt={0} sx={{ display: 'flex' }}>
                                <Grid item xs={5} >
                                    <Typography sx={{ fontWeight: '600', fontSize: '14px' }}>Auto Refresh :</Typography>

                                </Grid>
                                <Grid item xs={5} sx={{ display: 'flex', justifyContent: editIcon ? 'space-between' : 'center' }}>
                                    {editIcon && (
                                        <RemoveCircleOutlineIcon sx={{ fontSize: '20px', cursor: 'pointer', color: 'red' }} onClick={() => toggleTime('minus')} />
                                    )}
                                    <Typography sx={{ fontWeight: '600', fontSize: '15px', textAlign: 'center', color: '#ff7200' }}>{refreshTime} mins</Typography>
                                    {editIcon && (
                                        <ControlPointIcon sx={{ fontSize: '20px', cursor: 'pointer', color: 'green' }} onClick={() => toggleTime('add')} />
                                    )}

                                </Grid>
                                <Grid item xs={2} sx={{ display: 'flex', justifyContent: 'flex-end', cursor: 'pointer' }}>
                                    {!editIcon ?
                                        <EditIcon sx={{ fontSize: '20px', marginRight: '6px', color: 'green' }} onClick={() => setEditIcon(!editIcon)} />
                                        :
                                        <ClearIcon sx={{ color: 'red', fontSize: '20px', marginRight: '6px' }} onClick={() => setEditIcon(!editIcon)} />
                                    }
                                </Grid>
                            </Grid>
                        </Grid> */}

                    </>

                </Grid>
            }
        >
            <Grid container>
                <Grid item sm={12} xs={12}>
                    <Grid container>
                        <Grid item sm={6} xs={4}>

                            <Grid item={true} sm={6} xs={4}>
                                <Typography data-testid="title" sx={{ ...textClasses.cardTitle }}>
                                    Map Page
                                </Typography>
                            </Grid>
                        </Grid>

                        <Grid
                            container
                            item
                            sm={6}
                            xs={6}
                            sx={{ justifyContent: "flex-end" }}
                        >

                            <Grid
                                container
                                item
                                sm
                                xs={6}
                                sx={{ justifyContent: "flex-end" }}
                            >
                                <Grid
                                    item
                                    sm={6}
                                    xs={6}
                                    sx={{
                                        displey: "flex",
                                        display: "flex",
                                        justifyContent: "flex-end",
                                        height: "36px",
                                        display: "flex",
                                        position: "relative",
                                        marginRight: "5px",
                                    }}
                                >
                                    {filter?.status || filter?.deviceType || filter?.deviceStatus || filter?.clientId || filter?.filter?.geofenceIds || filter?.filter?.routeId || filter?.filter?.routeId ?
                                        <Button variant="text">
                                            <Typography
                                                sx={{
                                                    ...textClasses.boldText,
                                                    color: themeType.default.themeOrange,
                                                    textDecoration: "underline",
                                                    cursor: "pointer",
                                                    textTransform: "none",
                                                }}
                                                data-testid="clearFilter"
                                                onClick={() => {
                                                    clearAllFilter()
                                                }}
                                            >
                                                Clear All Filter(s)
                                            </Typography>
                                        </Button>
                                        : null}

                                </Grid>
                            </Grid>

                            {!open && (
                                <Button
                                    variant="contained"
                                    sx={{
                                        ...buttonClasses?.lynkitOrangeEmpty,
                                        minHeight: "36px",
                                    }}
                                    onClick={() => setOpen(!open)}
                                    startIcon={<FilterAlt />}
                                    data-testid="filter-btn"
                                >
                                    Filters
                                </Button>
                            )}

                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            <Grid container>
                <Grid item xs={12} sm={12} mt={2}>
                    <Paper
                        sx={{
                            height: "82vh",
                            overflow: "auto",
                            width: "100%",
                            display: "block",
                        }}
                    >
                        {isLoading ?
                            <Grid
                                container
                                item
                                direction={"column"}
                                justifyContent={"center"}
                                alignItems="center"
                                xs={12}
                                sx={{ p: 15 }}
                            >
                                <Grid item>
                                    <img src={loaderGif} />
                                </Grid>
                            </Grid>
                            :
                            <div data-testid="completeMap" id="completeMap" style={{ width: '100%', height: '100%' }}></div>
                        }
                    </Paper>
                </Grid>

            </Grid>

        </FiltersWrapper>
    );
};

export default MapsPage;