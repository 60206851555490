import React, { useState, useEffect } from "react";
import {
  Grid,
  Typography,
  Button,
  Breadcrumbs,
  Autocomplete,
  FormControl,
  TextField,
  OutlinedInput,
  InputLabel,
} from "@mui/material";
import CustomSelect from "../../../components/FormUI/CustomSelect";
import { useDispatch, useSelector } from "react-redux";
import history from "../../../services/history";
import { Link } from "react-router-dom";
import { selectStylesOverride } from "../../../utils/util";
import { Controller, useForm, useWatch } from "react-hook-form";
import { DndContext, DragOverlay, rectIntersection } from "@dnd-kit/core";

import {
  useSensors,
  useSensor,
  PointerSensor,
  KeyboardSensor,
  closestCenter,
} from "@dnd-kit/core";
import {
  restrictToWindowEdges,
  restrictToParentElement,
} from "@dnd-kit/modifiers";
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  horizontalListSortingStrategy,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import Draggable from "./Draggable";
import Droppable from "./Droppable";
import EmptyPage from "../EmptyPage";
import {
  getModuleListing,
  saveBoard,
  getModuleNames,
  editBoard,
  getBoards,
  getAllOrganisations
} from "../../../redux/actions";
import { showToast, capitalizeSentence } from "../../../services/functions";
import AddIcon from "@mui/icons-material/Add";

let payload = {
  module: "geofence",
  search: "",
  page: 1,
  limit: 10,
};
export default function BoardCreation(props) {
  let sharedObj = props?.location?.state;
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(true);
  const { message, error } = useSelector((state) => state.common);
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, { coordinateGetter: sortableKeyboardCoordinates })
  );
  const {
    control,
    setValue,
    getValues,
    formState: { errors, dirtyFields },
  } = useForm();
  const [module, setModule] = useState("");
  const [allKeysUpdated, setAllKeys] = useState({ data: [] });
  const [boardName, setBoardName] = useState("");
  // const [moduleName, setModuleName] = useState('')
  const [deviceType, setDeviceType] = useState("");
  const isDarkThemeEnabledSelector = useSelector(
    (state) => state.theme.themeDark
  );
  const nameOfModules = useSelector(
    (state) => state.dynamicDashboard.moduleNames
  );
  const [keysPerTrip, setKeysPerTrip] = useState({});
  const [columns, setColumns] = useState([
    { id: new Date().getTime(), columnName: "", group: [] },
  ]);
  const [isDropped, setIsDropped] = useState(false);
  const [parentID, setParentID] = useState(null);
  const [searchFields, setSearchFields] = useState({
    org: null
  });
  const [allDroppables, setAllDroppables] = useState([]);
  const [activeDrag, setActiveDrag] = useState(null);
  const [activeDragColumn, setActiveDragColumn] = useState(null);
  const superAdmin = useSelector((state) => state.auth.currentUserDetail)?.adminSpecificPermissions || {};
  const orgListing = useSelector((state) => state.Organisationlisting.OrganisationListing);
  const keys = useSelector((state) => state.dynamicDashboard.moduleLists);
  const devicesLists = [
    "E-Lock",
    "Sim Tracking",
    "Fixed Tracker(fuelSensor)",
    "Lora Lock",
    "Fixed Tracker",
    "Asset Tracker",
    "B-Lock",
    "Camera",
    "Fixed E-Lock",
  ];
  const themeType = isDarkThemeEnabledSelector
    ? require("../../../static/styles/darktheme")
    : require("../../../static/styles/theme");
  const {
    theme,
    buttonClasses,
    cardClasses,
    inputClasses,
    textClasses,
    tableClasses,
    dividerClass,
  } = themeType;

  useEffect(() => {
    if (Object?.keys(superAdmin)?.length) {
      dispatch(getAllOrganisations({ selection: ["name"] }));
    }
  }, [dispatch, superAdmin]);

  useEffect(() => {
    if (sharedObj?.onChange?.boardData) {
      setValue("orgId", sharedObj?.onChange?.orgId);
      var columnsToEdit = [];
      var arr = [...sharedObj?.onChange?.boardData];
      arr.map((e, index) => {
        var time = new Date().getTime();
        e.group.map((x) => {
          var a = { ...x };
          var key = a.key + "_in";
          x = { ...a, key: key };
        });
        var a = { id: time + index, ...e };
        columnsToEdit.push(a);
      });
      setColumns(columnsToEdit);

      if (sharedObj?.onChange?.module === "Device") {
        dispatch(
          getModuleListing({
            searchType: "moduleType",
            searchValue: sharedObj?.onChange?.subModule,
          })
        );
      } else {
        dispatch(
          getModuleListing({
            searchType: "moduleType",
            searchValue: sharedObj?.onChange?.module,
          })
        );
      }
    }
    setModule(
      devicesLists.includes(sharedObj?.onChange?.module)
        ? "Device"
        : sharedObj?.onChange?.module
    );

    setDeviceType(sharedObj?.onChange?.subModule || "");
    setBoardName(sharedObj?.onChange?.boardName);
  }, [sharedObj?.onChange]);

  useEffect(() => {
    if (keys && keys.moduleData) {
      setAllKeys({ data: keys.moduleData });
    }
  }, [keys]);

  const modulesList =
    nameOfModules &&
    nameOfModules.map((item) => {
      return {
        label: item._id === "device" ? "Device" : item.rows[0].label,
        value: item.rows[0].moduleType,
      };
    });

  const deviceTypes =
    nameOfModules &&
    nameOfModules
      .find((item) => item._id === "device")
      ?.rows.map((row) => ({ label: row?.moduleType, value: row?.label }));

  const handleChange = (option) => {
    if (option?.label !== "Device") {
      if (option?.value) {
        setModule(option?.value);
        setKeysPerTrip(keys);
        dispatch(
          getModuleListing({
            searchType: "moduleType",
            searchValue: option?.value,
          })
        );
      } else {
        setModule("");
        setAllKeys({ data: [] });
        setBoardName("");
        setColumns([{ id: new Date().getTime(), columnName: "", group: [] }]);
        setIsDropped(false);
        setParentID("");
        setAllDroppables([]);
        setActiveDrag("");
      }
    } else {
      setModule(option?.label);
      setAllKeys({ data: [] });
    }
  };

  const handleDeviceChange = (option) => {
    setDeviceType(option?.label);
    setModule(option?.value);
    if (option?.value) {
      setKeysPerTrip(keys);
      dispatch(
        getModuleListing({
          searchType: "moduleType",
          searchValue: option?.label,
        })
      );
    } else {
      setModule("");
      setAllKeys({ data: [] });
      setBoardName("");
      setColumns([{ id: new Date().getTime(), columnName: "", group: [] }]);
      setIsDropped(false);
      setParentID("");
      setAllDroppables([]);
      setActiveDrag("");
    }
  };

  const handleSearchOrg = (empty) => {
    if (empty === true) {
      dispatch(getAllOrganisations({ selection: ["name"] }));
    } else {
      if (searchFields?.org || searchFields?.org == "")
        dispatch(
          getAllOrganisations({
            selection: ["name"],
            searchValue: searchFields.org,
          })
        );
    }
  };

  const save = () => {
    const currentData = getValues();
    setLoader(true);
    var form = checkValidityForm();
    if (form) {
      columns.map((e, i) => {
        e.sort = i;
        e.identifier = i + 1;
        e.group.map((x, index) => {
          var a = { ...x };
          var sort = index;
          x = { ...a, sort };
        });
      });

      let obj = {
        boardName: boardName,
        boardData: columns,
        module: module,
        subModule: deviceType,
        orgId: currentData?.orgId
      };
      if (sharedObj?.onChange?.boardData) {
        obj.boardId = sharedObj?.onChange?._id;
        dispatch(editBoard(obj, () => {
          setLoader(false)
        }));
      }
      //create
      else {
        dispatch(
          saveBoard(obj, () => {
            setLoader(false);
          })
        );

      }
    } else {
      showToast("Please fill all details", true);
    }
  };

  const checkValidityForm = () => {
    if (!boardName) {
      return false;
    } else if (!module) {
      return false;
    } else if (!columns || columns.length == 0) {
      return false;
    } else {
      var check = true;
      columns.map((item) => {
        if (
          check &&
          (!item.columnName || !item.group || item.group.length == 0)
        ) {
          check = false;
        }
      });

      if (!check) {
        return false;
      }
    }

    return true;
  };
  function handleDragStart(e) {
    const droppedKey = e.active.id;
    if (droppedKey.includes("_dragdrop")) {
      const droppedOriginalData = columns.filter(
        (data) => data.id == droppedKey.split("_dragdrop")[0]
      )[0];
      setActiveDragColumn(droppedOriginalData);
    } else {
      const droppedOriginalData =
        keys &&
        keys.moduleData &&
        keys.moduleData.filter((data) => data.key == droppedKey)[0];
      setActiveDrag(droppedOriginalData);
    }
  }

  function handleDragEnd(e) {
    // && (!e.active.data.current?.parent || e.over?.id != e.active.data.current?.parent)

    if (e.delta.x != 0 && e.delta.x != 0 && e.over) {
      var droppedColumnKey = e.over?.id;
      var droppedKey = e.active?.id;
      var oldColumnKey = e.active.data.current?.parent ?? undefined;

      if (droppedKey.includes("_dragdrop")) {
      } else {
        setActiveDrag(null);
        if (droppedKey.includes("_in")) {
          droppedKey = droppedKey.split("_in")[0];
        }

        var originalKey =
          keys &&
          keys.moduleData &&
          keys.moduleData.filter((data) => data.key == droppedKey)[0];
        var droppedOriginalData = {
          current: { parent: e.over.id },
          ...originalKey,
        };

        const containerIndex =
          columns && columns.findIndex((data) => data.id == droppedColumnKey);

        var newDroppables = checkIfDroppableExists(
          droppedKey,
          droppedOriginalData
        );

        if (droppedOriginalData && newDroppables) {
          var arr = [...columns];

          if (oldColumnKey) {
            var oldParentIndex = columns.findIndex(
              (data) => data.id == oldColumnKey
            );

            if (arr[oldParentIndex].group) {
              var oldParentNewDroppables = arr[oldParentIndex].group.filter(
                (data) => data.key != droppedKey
              );
              arr[oldParentIndex].group = oldParentNewDroppables;

              setDataIntoColumns(containerIndex, arr);
            } else {
              setDataIntoColumns(containerIndex, arr);
            }
          } else {
            setDataIntoColumns(containerIndex, arr);
          }
        }

        if (e.active?.id !== e.over?.id) {
          setColumns((items) => {
            const oldIndex = items.indexOf(e.active.id);
            const newIndex = items.indexOf(e.over.id);

            return arrayMove(items, oldIndex, newIndex);
          });
        }

        function setDataIntoColumns(containerIndex, arr) {
          if (
            !columns[containerIndex].group ||
            columns[containerIndex].group.length == 0
          ) {
            arr[containerIndex].group = [droppedOriginalData];
            setAllDroppables([droppedOriginalData]);
            setColumns(arr);
          } else {
            arr[containerIndex].group = [
              ...arr[containerIndex].group,
              droppedOriginalData,
            ];
            setAllDroppables([...allDroppables, droppedOriginalData]);
            setColumns(arr);
          }

          // var arr1 = []
          // arr1 = allKeysUpdated.data.filter(data => data.key != droppedKey)
          // setAllKeys({ data: arr1 })
        }

        setParentID(e.over ? e.over.id : null);
      }
    }

    function checkIfDroppableExists(droppedKey, droppedOriginalData) {
      var newDroppables = true;

      var found = allDroppables.find((data) => data.key.includes(droppedKey));
      // if(found != -1)
      // {
      //   newDroppables = false;
      // }

      return newDroppables;
    }
  }
  const addMoreColumns = () => {
    var arr = [...columns];
    arr.push({ id: new Date().getTime() });
    setColumns(arr);
  };

  const deleteColumn = (value) => {
    if (columns.length > 1) {
      var arr = columns.filter((item) => item.id !== value);
      setColumns(arr);
    }
  };

  const setColumnName = (e, i) => {
    let { name, value } = e.target;
    var arr = [...columns];
    arr[i].columnName = value;
    setColumns(arr);
  };

  const handleRemove = (e, data) => {
    var arr = [...columns];
    if (data.key.includes("_in")) {
      data.key = data.key.split("_in")[0];
    }
    var newDeletedArray = arr[data.parentIndex].group.filter(
      (item) => item.key != data.key
    );
    var newAddedArray = arr[data.parentIndex].group.filter(
      (item) => item.key == data.key
    );

    arr[data.parentIndex].group = newDeletedArray;
    setColumns([...arr]);

    var arrNew = [...allKeysUpdated.data];

    arrNew.push(newAddedArray[0]);
    let res = arrNew.filter(item => item !== undefined)

    setAllKeys({ data: res });
  };
  const handleColumnChange = (boardId, newColumns) => {
    const updatedBoards = columns.map((board) =>
      board.id === boardId ? { ...board, columns: newColumns } : board
    );
    setColumns(updatedBoards);
  };


  useEffect(() => {
    // if (!sharedObj?.onChange?.boardData) {
    // console.log('yyyyyyyyyyyyyyyyyyyyyyyy');
    dispatch(getModuleNames());
    // }
  }, []);

  useEffect(() => {
    if (message && loader) {
      showToast(message, false);
      setLoader(false);
      history.push("/dashboard/board");
      setAllKeys({ data: [] });
      // dispatch(getAllGeofences(geopayload))
    }
    if (error && loader) {
      showToast(error, true);
      setLoader(false);
    }
  }, [message, error]);

  const moveBack = () => {
    setAllKeys({ data: [] });
    setModule("");
    setDeviceType("");
    history.goBack();
  };

  return (
    <>
      <Grid container>
        <Grid item sm={12} xs={12}>
          <Grid container>
            <Grid item sm={6} xs={4}>
              <Typography sx={{ ...textClasses.cardTitle }}>
                {`${sharedObj?.onChange?.boardData ? "Edit" : "Create"} Board`}
              </Typography>
            </Grid>

            <Grid
              item
              sm={6}
              xs={6}
              sx={{
                displey: "flex",
                display: "flex",
                justifyContent: "flex-end",
                height: "36px",
              }}
            >
              {/* <Button
                onClick={() => moveBack()}
                variant="outlined"
                size="small"
                sx={{
                  ...buttonClasses.small,
                  color: buttonClasses.lynkitBlackFill,
                }}
              >
                Back
              </Button> */}
            </Grid>
          </Grid>
        </Grid>

        <Grid>
          <Breadcrumbs sx={{ mb: 1 }} data-testid="breadcrumbs">
            <Typography
              sx={{
                ...textClasses.normalText,
                fontSize: "12px",
                color: themeType.default.themeOrange,
              }}
            >
              <Link
                style={{
                  color: themeType.default.themeOrange,
                  textDecoration: "none",
                }}
                to="/dashboard/board"
                data-testid="link-to-go-back"
              >
                Board
              </Link>
            </Typography>

            <Typography sx={{ ...textClasses.normalText, fontSize: "12px" }}>
              {`${sharedObj?.onChange?.boardData ? "Edit" : "Create"}`}
            </Typography>
          </Breadcrumbs>
        </Grid>

        <Grid item container spacing={2} mt={0}>
          <Grid item md={2.5}>
            <FormControl
              variant="outlined"
              fullWidth
              sx={{
                ...selectStylesOverride(isDarkThemeEnabledSelector),
                borderRadius: "12px",
              }}
            >
              <Autocomplete
                onChange={(e, option) => {
                  handleChange(option);
                }}
                size="small"
                fullWidth
                options={modulesList}
                sx={{
                  ...inputClasses.filterField,
                }}
                disabled={sharedObj?.case === "edit" ? true : false}
                value={capitalizeSentence(module) || ""}
                renderInput={(params) => (
                  <TextField
                    sx={{ fontSize: "12px" }}
                    {...params}
                    label={
                      <Typography sx={{ fontSize: "12.5px" }}>
                        Module*
                      </Typography>
                    }
                  />
                )}
              />
            </FormControl>
          </Grid>



          {devicesLists.includes(module) || module === "device" || module === "Device"
            ? deviceTypes &&
            Array.isArray(deviceTypes) &&
            deviceTypes.length > 0 && (
              <Grid item md={2.5}>
                <FormControl
                  variant="outlined"
                  fullWidth
                  sx={{
                    ...selectStylesOverride(isDarkThemeEnabledSelector),
                    borderRadius: "12px",
                  }}
                >
                  <Autocomplete
                    value={deviceType || ""}
                    onChange={(e, option) => {
                      handleDeviceChange(option);
                    }}
                    size="small"
                    required={false}
                    fullWidth
                    options={deviceTypes}
                    disabled={sharedObj?.case === "edit" ? true : false}
                    sx={{
                      ...inputClasses.filterField,
                    }}
                    renderInput={(params) => (
                      <TextField
                        sx={{ fontSize: "12px" }}
                        {...params}
                        label={
                          <Typography sx={{ fontSize: "12.5px" }}>
                            Select Device Type*
                          </Typography>
                        }
                      />
                    )}
                  />
                </FormControl>
              </Grid>
            )
            : ""}

          <Grid item md={2.5}>
            <FormControl
              variant="outlined"
              size="small"
              fullWidth
              sx={{
                ...selectStylesOverride(isDarkThemeEnabledSelector),
                borderRadius: "12px",
              }}
            >
              <InputLabel
                htmlFor="outlined-adornment-password"
                sx={{ fontSize: "13px" }}
              >
                Board Name*
              </InputLabel>
              <OutlinedInput
                sx={{ ...inputClasses.filterField, height: "36px" }}
                name="boardName"
                value={boardName}
                onChange={(e) => setBoardName(e.target.value)}
                placeholder="Enter Board Name"
                label="Enter Board Name"
              />
            </FormControl>
          </Grid>

          <Grid item md={2.5}>
            {superAdmin && Object.keys(superAdmin).length ?
              <FormControl
                variant="outlined"
                size="small"
                fullWidth
                sx={{ ...selectStylesOverride(isDarkThemeEnabledSelector) }}
              >
                <Controller
                  name="orgId"
                  control={control}
                  render={({ field: { onChange, value, name } }) => {
                    return (
                      <CustomSelect
                        selectStylesOverride={selectStylesOverride}
                        value={value || null}
                        name={name}
                        onChange={(e, newVal, reason) => {
                          if (reason === "clear") handleSearchOrg(true);
                          onChange(newVal?._id);
                        }}
                        sx={{
                          ...inputClasses.filterField,
                        }}
                        label="Organisation*"
                        errors={errors}
                        getOptionLabel={(option) => {
                          if (value === undefined) {
                            return "";
                          }
                          if (typeof option === "string") {
                            return (
                              orgListing?.find((_) => _?._id == value)?.name ||
                              ""
                            );
                          } else {
                            return option?.name || "";
                          }
                        }}
                        searchValue={
                          searchFields?.org && searchFields?.org !== ""
                            ? searchFields?.org
                            : null
                        }
                        onSearch={handleSearchOrg}
                        onInputChange={(e) => {
                          if (e?.type === "change") {
                            if (e?.target?.value === "") handleSearchOrg(true);
                            setSearchFields((prev) => ({
                              ...prev,
                              org: e?.target?.value?.trim(),
                            }));
                          }
                        }}
                        filterSelectedOptions
                        labelKey="name"
                        options={orgListing && orgListing}
                      />


                    );
                  }}
                />
              </FormControl>
              : null}
          </Grid>



          <Grid
            container
            item
            md={
              devicesLists.includes(module) ||
                module === "device" ||
                module === "Device"
                ? 2
                : 4.5
            }
            justifyContent="end"
          >
            {module || deviceType ? (
              <Button
                variant="outlined"
                size="small"
                sx={{
                  ...buttonClasses.small,
                  borderColor: themeType.default.themeOrange,
                  color: buttonClasses.lynkitOrangeEmpty,
                }}
                startIcon={<AddIcon />}
                onClick={addMoreColumns}
              >
                {" "}
                Add Column
              </Button>
            ) : null}
            <Button
              data-testid="customRole"
              variant="contained"
              size="small"
              disabled={!boardName || !module}
              sx={{
                ...buttonClasses.lynkitOrangeFill,
                paddingTop: "5.5px",
                paddingBottom: "5.5px",
                textTransform: "none",
                width: "100px",
                height: "32px",
                marginLeft: "10px",
                "&.Mui-disabled": {
                  backgroundColor: "#827878 !important",
                  border: `1px solid #827878 !important`,
                  color: "#EAEAEA !important",
                },



              }}
              onClick={save}
            >
              Save
            </Button>
          </Grid>

          {module || deviceType ? (
            module &&
            keys &&
            keys.moduleData &&
            keys.moduleData.length > 0 && (
              <Grid
                container
                mt={2}
                pb={2}
                sx={{ maxHeight: "455px" }}
                direction={"row"}
              >
                <DndContext
                  collisionDetection={rectIntersection}
                  sensors={sensors}
                  modifiers={[restrictToWindowEdges]}
                  onDragStart={handleDragStart}
                  onDragEnd={handleDragEnd}
                >
                  <Grid container sx={{ mt: 2, pr: 2 }} item xs={6} sm={10}>
                    <Grid
                      item
                      sm={12}
                      xs={6}
                      sx={{
                        width: "100%",
                        p: 1,
                        pt: 2,
                        pb: 2,
                        border: "1px solid #E8E8E8",
                      }}
                      direction={"row"}
                    >
                      <Grid
                        className="scrollableDiv"
                        sx={{
                          width: "100%",
                          borderRadius: "10px",
                          display: "flex",
                          flexDirection: "row",
                          overflow: "auto",
                          height: "100%",
                          paddingBottom: "20px",
                        }}
                      >
                        <SortableContext
                          key={"sort_context"}
                          items={columns.map((column) => column.id)}
                          strategy={verticalListSortingStrategy}
                        >
                          {columns.map((column, i) => (
                            <Grid
                              sx={{
                                borderRadius: "10px",
                                minWidth: "300px",
                                marginLeft: "12px",
                                border: "2px solid #F0F0F0",
                                boxShadow: "0px 0px 5px 1px #E8E8E8",
                              }}
                            >
                              <Droppable
                                time={new Date().getTime()}
                                parent={parentID}
                                key={column.id}
                                columns={columns}
                                column={column}
                                i={i}
                                group={column.group}
                                deleteColumn={deleteColumn}
                                setColumnName={setColumnName}
                                deletable={true}
                                deleteDraggable={handleRemove}
                                onChange={(newColumns) =>
                                  handleColumnChange(column.id, newColumns)
                                }
                              />
                            </Grid>
                          ))}
                        </SortableContext>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    sx={{ mt: 2, height: "100%", border: "2px solid #E8E8E8" }}
                    item
                    xs={6}
                    sm={2}
                  >
                    <Grid
                      item
                      sm={12}
                      xs={6}
                      sx={{
                        display: "flex",
                        height: "45px",
                        background: "#000",
                        color: "#fff",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Typography textAlign={"center"}>
                        Drag and Drop to Columns{" "}
                      </Typography>
                    </Grid>
                    <Grid
                      container
                      pt={1}
                      pb={2}
                      sx={{ height: "89%", overflow: "auto" }}
                      item
                      xs={6}
                      sm={12}
                    >
                      {allKeysUpdated &&
                        allKeysUpdated.data &&
                        allKeysUpdated.data.map((dbKey) => (
                          <Draggable
                            deletable={false}
                            key={dbKey.key}
                            id={dbKey.key}
                            activationConstraint={{ distance: 20 }}
                          >
                            <span>{dbKey.label}</span>
                          </Draggable>
                        ))}
                    </Grid>
                    <DragOverlay
                      dropAnimation={{
                        duration: 500,
                        easing: "cubic-bezier(0.18, 0.67, 0.6, 1.22)",
                      }}
                      modifiers={[restrictToWindowEdges]}
                    >
                      {activeDrag ? (
                        <Draggable
                          deletable={false}
                          key={activeDrag.key}
                          id={activeDrag.key}
                        >
                          {activeDrag.label}
                        </Draggable>
                      ) : null}
                    </DragOverlay>
                  </Grid>
                </DndContext>
              </Grid>
            )
          ) : (
            <EmptyPage body="Please choose Module from Dropdown.." />
          )}
        </Grid>
      </Grid>
    </>
  );
}
