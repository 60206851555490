import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import HomeScreen from '../views/Modules/HomeScreen';
export default function RouteWrapper({
  component: Component,
  path,
  isPrivate,
  currentUserDetail,
  homePage,
  ioUser,
  ...rest
}) {
  var signed = false;

  if (localStorage.getItem('token')) {
    signed = true
  }
  if (!signed && !isPrivate && path === "/" && homePage ) {
    // return <Route path="/" component={HomeScreen} />
  }
  
  /**
   * Redirect user to SignIn page if he tries to access a private route
   * without authentication.
   */
  if (isPrivate && !signed) {
    return <Redirect to="/" />;
  }
 
  if (!isPrivate && signed && path !== "/sharingTrack" && path !== "/landing-page") {
    return <Redirect to={"/dashboard"} />
  }
  // if (!ioUser) {
  //   console.log('i---',ioUser);
  //   return <Redirect to={"/login"} />
  // }
  /**
   * If not included on both previous cases, redirect user to the desired route.
   */
  return <Route {...rest} component={Component} />;
}
RouteWrapper.propTypes = {
  isPrivate: PropTypes.bool,
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.func])
    .isRequired,
};
RouteWrapper.defaultProps = {
  isPrivate: false,
};