import { THEME_DARK, THEME_LIGHT } from "../types";

const INIT_STATE = {
    themeDark: false,

};
  
export default (state = INIT_STATE, action) => {
    switch (action.type) {
      case THEME_DARK: {
        return {...state, themeDark: false};
      }
      case THEME_LIGHT: {
        return {...state, themeDark: true};
      }
      case "UPDATE_THEME": {
        return {...state, themeDark: action?.payload};
      }
      
      default:
        return state;
    }
}