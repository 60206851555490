import React from "react";
import CustomModel from "../../../Components/CustomModels/CustomModel1";
import { Button, CircularProgress, Grid, Typography } from "@mui/material";
import { CustomInput } from "../../../../components/FormUI";
import { Controller, useForm } from "react-hook-form";

const OTPModal = ({
  open,
  setOpen,
  themeType,
  loading = false,
  selectStylesOverride,
  handleVerifyFn,
}) => {
  const { inputClasses, buttonClasses } = themeType;
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm();

  const hide = () => {
    setOpen(false);
    reset();
  };

  const onSubmit = (data) => {
    if (data) {
      handleVerifyFn(data);
    }
  };

  return (
    <CustomModel
      autoClose={false}
      show={open || false}
      onClose={() => hide()}
      minWidth={500}
      maxWidth={500}
      pb={3}
      pl={3}
      pr={3}
      maxHeight={"81vh"}
      childrenMaxHeight={"81vh"}
      Title={"Verify OTP"}
      viewCloseIcon={open}
    >
      <Grid container spacing={2} py={1} mb={2}>
        <Grid item xs={12} justifyContent="center" alignItems="center">
          <Typography sx={{ alignItems: "center" }}>
            An OTP was shared with you. Please enter it below and verify.
          </Typography>
        </Grid>
        <Grid item container xs={12} columnGap={2}>
          <Grid item xs>
            <Controller
              name="otp"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: "OTP is required",
                },
                pattern: {
                  value: /^\d+$/,
                  message: "Enter a valid number",
                },
                maxLength: {
                  value: 6,
                  message: "OTP should be 6 digits long",
                },
                minLength: {
                  value: 6,
                  message: "OTP should be 6 digits long",
                },
              }}
              render={({ field: { onChange, value, name } }) => {
                return (
                  <CustomInput
                    label="OTP"
                    name={name}
                    errors={errors}
                    placeholder="Enter OTP"
                    selectStylesOverride={selectStylesOverride}
                    inputClasses={inputClasses}
                    value={value}
                    onChange={(e) => {
                      onChange(e);
                    }}
                  />
                );
              }}
            />
          </Grid>
          <Grid item xs>
            <Button
              variant="contained"
              sx={{ ...buttonClasses?.lynkitOrangeFill }}
              onClick={handleSubmit(onSubmit)}
              disabled={loading}
            >
              {loading && (
                <CircularProgress
                  size={20}
                  sx={{
                    color: "#383838",
                  }}
                />
              )}
              {loading ? "Loading..." : "Verify"}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </CustomModel>
  );
};

export default OTPModal;
