import { FiberManualRecordTwoTone, Lock } from "@mui/icons-material";
import { Box, Typography, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { convertIntoGivenTimezone, dateTimeFormater } from "../../../../services/functions";
import config from "../../../../services/config";
import { useDispatch, useSelector } from "react-redux";
import { fetchAddressFromCoords } from "../../../../redux/actions";
import ThreeDotsLoader from "../../../Components/ThreeDots/ThreeDotsLoader";
import InfoIcon from '@mui/icons-material/Info';
import EvidenceModal from "./EvidenceModal";

const CameraEventSummary = ({ themeType, cameraAlerts, alertsLoader, deviceDetails }) => {
  const dispatch = useDispatch();
  const [alertsArray, setAlertsArray] = useState([])
  const [evidenceModal, setEvidenceModal] = useState(false)
  const [eventObject, setEventObject] = useState({})
  const { inputClasses, buttonClasses, textClasses } = themeType;
  const userDetails = useSelector((state) => state.auth.currentUserDetail);

  useEffect(() => {
    if (cameraAlerts?.length) {
      setAlertsArray(cameraAlerts)
    }
  }, [cameraAlerts])

  const getAddressFromCoords = (item, i) => {
    let temp = [...alertsArray];
    const payload = {
      latitude: item?.latitude,
      longitude: item?.longitude,
      appType: "GOOGLE",
    };
    dispatch(
      fetchAddressFromCoords(payload, (res) => {
        if (res && res?.length > 0) {
          const { address } = res[0];
          if (temp[i]) {
            temp[i] = { ...(temp[i]), newAddress: address };
          }
          setAlertsArray(temp)
        }
      })
    );
  }

  const modalToggle = (item) => {
    setEventObject(item)
    if (evidenceModal) {
      setEvidenceModal(false)
    }
    else {
      setEvidenceModal(true)
    }
  }

  return (
    <>
      {alertsLoader ?
        <Grid item container direction="column" justifyContent="center" alignItems="center" xs={12} sx={{ p: 1 }}>
          <ThreeDotsLoader />
        </Grid>
        :
        <Box p={1} height="70%" sx={{ overflowY: "scroll"}} display="flex" flexDirection="column" >
          {alertsArray?.length ?
            alertsArray?.map((item, i) => {
              return (
                <Box sx={{ display: "flex", gap: "4px" }} data-testid="event-container">
                  <Box display="flex" flexDirection="column" alignItems="center">
                    <FiberManualRecordTwoTone sx={{ color: "#FF7200" }} />
                    <div
                      style={{ background: "#FF7200", width: "2px", height: "100%" }}
                    />
                  </Box>
                  <Box pt="3px" pb="6px" display="flex" flexDirection="column" sx={{ width: '100%' }}>


                    <Grid sx={{ width: '100%', display: 'flex', flexDirection: "row", justifyContent: 'space-between' }}>
                      <Typography
                        sx={{
                          // ...textClasses.t13n,
                          fontWeight: 700,
                          textTransform: "uppercase",
                          color: themeType.default.themeOrange,

                        }}
                      >
                          <Typography>{item?.alertType?.replace(/_/g, ' ')}</Typography>
                        {/* {alarmData.filter(elem => elem.code == item.alarmType).map(filterData => (
                          <Typography>{filterData.type}</Typography>
                        ))} */}
                      </Typography> 
                      <Typography onClick={() => modalToggle(item)} sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end', color: 'grey', cursor: 'pointer' }}><InfoIcon /></Typography>
                    </Grid>



                    <Typography sx={{ ...textClasses.normalText, fontSize: "11px" }}>
                      {convertIntoGivenTimezone(item?.createdAt, userDetails.timeZone.offset) || "-"}
                    </Typography>
                    {/* <Typography sx={{ ...textClasses.normalText, fontSize: "11px" }}>
                {item?.deviceID || "-"}
              </Typography> */}
                    {item?.newAddress ? (
                      <Typography
                        sx={{ ...textClasses.normalText, fontSize: "11px" }}
                      >{item?.newAddress}</Typography>
                    ) : (
                      <Typography
                        sx={{ ...textClasses.normalText, fontSize: "11px", cursor: "pointer", color: themeType.default.themeOrange }}
                        onClick={() => getAddressFromCoords(item, i)}
                      >
                        Get Address
                      </Typography>
                    )}
                  </Box>
                </Box>
              );
            })
            :
            <Grid sx={{ width: '100%', padding: '20px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <Typography>Alerts not found.</Typography>
            </Grid>
          }
        </Box>

      }
      {evidenceModal && (
        <EvidenceModal
          open={evidenceModal}
          eventObject={eventObject}
          userDetails={userDetails}
          deviceDetails={deviceDetails}
          hide={() => {
            setEvidenceModal(false);
          }}
        />
      )}
    </>
  );
};

export default CameraEventSummary;

let alarmData = [
  {
    "code": "126",
    "type": "No Driver"
  },
  {
    "code": "132",
    "type": "Driver authentication failed"
  },
  {
    "code": "121",
    "type": "Eyes closing"
  },
  {
    "code": "1",
    "type": "Video Lost"
  },
  {
    "code": "31",
    "type": "Acc on"
  },
  {
    "code": "0",
    "type": "UnKnown"
  },
  {
    "code": "2",
    "type": "Motion detection"
  },
  {
    "code": "3",
    "type": "Video overshadowed"
  },
  {
    "code": "4",
    "type": "Alarm input triggered"
  },
  {
    "code": "5",
    "type": "Alarm of emergency"
  },
  {
    "code": "6",
    "type": "Alarm of low speed"
  },
  {
    "code": "7",
    "type": "Alarm of high speed"
  },
  {
    "code": "8",
    "type": "Alarm of low temperature"
  },
  {
    "code": "9",
    "type": "Alarm of high temperature"
  },
  {
    "code": "10",
    "type": "Alarm of humidity"
  },
  {
    "code": "11",
    "type": "Park Overtime"
  },
  {
    "code": "12",
    "type": "Alarm of vibratin"
  },
  {
    "code": "13",
    "type": "Electronic fence"
  },
  {
    "code": "14",
    "type": "Electronic route"
  },
  {
    "code": "15",
    "type": "Alarm of open or close door"
  },
  {
    "code": "16",
    "type": "Storage abnormal"
  },
  {
    "code": "17",
    "type": "Fatigue driving"
  },
  {
    "code": "18",
    "type": "Exceptional volume of gas"
  },
  {
    "code": "19",
    "type": "Illegal ignition"
  },
  {
    "code": "20",
    "type": "Location module abnormal"
  },
  {
    "code": "21",
    "type": "Front panel open"
  },
  {
    "code": "22",
    "type": "RFID tagged"
  },
  {
    "code": "23",
    "type": "IBUTTON"
  },
  {
    "code": "24",
    "type": "Rapid acceleration"
  },
  {
    "code": "25",
    "type": "Rapid deceleration"
  },
  {
    "code": "26",
    "type": "Low speed pre-alarm"
  },
  {
    "code": "27",
    "type": "High speed pre-alarm"
  },
  {
    "code": "28",
    "type": "Voltage Alarm"
  },
  {
    "code": "29",
    "type": "Population statistics"
  },
  {
    "code": "30",
    "type": "DMS/ADAS alarm (Active safety alarm)"
  },
  {
    "code": "31",
    "type": "Acc on"
  },
  {
    "code": "32",
    "type": "Idle speed"
  },
  {
    "code": "33",
    "type": "Gps antenna disconnected"
  },
  {
    "code": "34",
    "type": "Gps antenna short circuit"
  },
  {
    "code": "35",
    "type": "IO output"
  },
  {
    "code": "36",
    "type": "CANBus connection abnormal"
  },
  {
    "code": "37",
    "type": "Towing alarm"
  },
  {
    "code": "38",
    "type": "Glide in neutral"
  },
  {
    "code": "39",
    "type": "RPM too high 40 vehicle move"
  },
  {
    "code": "40",
    "type": "vehicle move"
  },
  {
    "code": "41",
    "type": "Trip start (st/et/dtu time is the same)"
  },
  {
    "code": "42",
    "type": "In trip"
  },
  {
    "code": "43",
    "type": "End of the trip (reported periodically after acc off)"
  },
  {
    "code": "44",
    "type": "GPS location recovery"
  },
  {
    "code": "45",
    "type": "Abnormal video"
  },
  {
    "code": "768",
    "type": "End of trip notification"
  },
  {
    "code": "769",
    "type": "Upgrade notfication"
  },
  {
    "code": "101",
    "type": "Forward collision warning"
  },
  {
    "code": "102",
    "type": "Lane departure warning"
  },
  {
    "code": "103",
    "type": "Front vehicle distance is too close warning"
  },
  {
    "code": "104",
    "type": "Pedestrian collision warning Howen VSS Web API"
  },
  {
    "code": "105",
    "type": "Frequent lane change alarm"
  },
  {
    "code": "106",
    "type": "Road marking violation alarm"
  },
  {
    "code": "107",
    "type": "Harsh acceleration"
  },
  {
    "code": "108",
    "type": "Harsh breaking"
  },
  {
    "code": "109",
    "type": "Road sign recognition incident"
  },
  {
    "code": "110",
    "type": "FCW Forward relative velocity collision"
  },
  {
    "code": "111",
    "type": "HMW Forward absolute velocity collision"
  },
  {
    "code": "112",
    "type": "LDW_L Left lane departure"
  },
  {
    "code": "113",
    "type": "LDW_R Right lane departure"
  },
  {
    "code": "114",
    "type": "VB Low-speed forward collision alarm"
  },
  {
    "code": "115",
    "type": "Fatigue driving alarm"
  },
  {
    "code": "116",
    "type": "Calling Alarm"
  },
  {
    "code": "117",
    "type": "Smoking alarm"
  },
  {
    "code": "118",
    "type": "Distracted driving alarm"
  },
  {
    "code": "119",
    "type": "Driver abnormal alarm"
  },
  {
    "code": "120",
    "type": "Driver change event"
  },
  {
    "code": "122",
    "type": "Yawning"
  },
  {
    "code": "123",
    "type": "Camera cover alarm"
  },
  {
    "code": "124",
    "type": "Glance right and left"
  },
  {
    "code": "125",
    "type": "Not wearing a seat belt"
  },
  {
    "code": "126",
    "type": "No driver"
  },
  {
    "code": "127",
    "type": "Drinking water"
  },
  {
    "code": "128",
    "type": "Driver changing"
  },
  {
    "code": "129",
    "type": "Driver returns"
  },
  {
    "code": "130",
    "type": "Infrared sunglasses"
  },
  {
    "code": "131",
    "type": "Driver authentication succeeded"
  },
  {
    "code": "133",
    "type": "No face detected"
  },
  {
    "code": "200",
    "type": "Infrared sunglasses"
  },
  {
    "code": "201",
    "type": "Leave the fence"
  },
  {
    "code": "202",
    "type": "Overspeed alarm in fence"
  },
  {
    "code": "203",
    "type": "Overspeed warning inside the fence"
  },
  {
    "code": "204",
    "type": "Low speed alarm in fence"
  },
  {
    "code": "205",
    "type": "Low speed warning inside the fence"
  },
  {
    "code": "207",
    "type": "Engine on in fence"
  },
  {
    "code": "208",
    "type": "Engine off in fence"
  },
  {
    "code": "209",
    "type": "Timeout inside the fence"
  },
  {
    "code": "210",
    "type": "Refuel"
  },
  {
    "code": "211",
    "type": "Fuel Theft"
  },
  {
    "code": "212",
    "type": "Rapid acceleration (gsebsor)"
  },
  {
    "code": "213",
    "type": "Rapid deceleration (gsebsor)"
  },
  {
    "code": "214",
    "type": "Sharp turn (gsebsor)"
  },
  {
    "code": "215",
    "type": "entry fence warning"
  },
  {
    "code": "216",
    "type": "Leave the fence warning"
  },
  {
    "code": "10001",
    "type": "Insufficient disk space on server"
  },
  {
    "code": "11001",
    "type": "Excessive device traffic"
  },
  {
    "code": "11003",
    "type": "The device is offline for a long time"
  },
  {
    "code": "121",
    "type": "Eyes closed"
  },
  {
    "code": "123",
    "type": "DMS Camera Covered"
  },
  {
    "code": "122",
    "type": "Yawning"
  },
  {
    "code": "117",
    "type": "Smoking alarm"
  },
  {
    "code": "124",
    "type": "Distracted Driving"
  },
  {
    "code": "116",
    "type": "Phone call alarm"
  },
  {
    "code": "132",
    "type": "Driver authentication failed"
  },
  {
    "code": "118",
    "type": "Distracted driving alarm"
  },
  {
    "code": "119",
    "type": "Driver abnormal alarm"
  },
  {
    "code": "120",
    "type": "Driver change event"
  },
  {
    "code": "125",
    "type": "Not wearing a seat belt"
  },
  {
    "code": "115",
    "type": "Fatigue driving alarm"
  },
]

