import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  Paper,
  Typography,
  Breadcrumbs,
} from "@mui/material";
import React from "react";
import {
  selectStylesOverride,
  sendCustomEventAnalytics,
} from "../../../utils/util";
import loaderGif from "../../../static/images/drawer/loader.gif";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { useForm, useWatch } from "react-hook-form";
import {
  addNewDevice,
  addNewDeviceModelInBulk,
  allocateDevice,
  changeEditFlag,
  editDevice,
  flushUsersList,
  getAllDeviceCategoryNames,
  getAllDeviceModels,
  getAllDevicePermissions,
  getAllOrganisations,
  getAllPortGroups,
  getAllUsers,
  getDeviceDetails,
  getEditDeviceTemplate,
  getOrgListUserWise,
  getPermissionRoles,
  getUserRolesFromOrg,
  updateNetworkAdminACL,
} from "../../../redux/actions";
import { flushAllDeviceModels, flushDeviceDetails } from "../../../redux/reducers/DevicesReducers";
import axios, { devicesAxios } from "../../../services/Api";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import SAFormData from "./addDeviceComponents/SAFormData";
import SAExcelForm from "./addDeviceComponents/SAExcelForm";
import { DeleteOutline, Upload } from "@mui/icons-material";
import SUForm from "./addDeviceComponents/SUForm";
import { useParams, Link } from "react-router-dom/cjs/react-router-dom";
import NetworkAdminsUpdate from "./addDeviceComponents/NetworkAdminsUpdate";
import { showToast } from "../../../services/functions";
import history from "../../../services/history";

const operatorOptions = [
  { label: "Vodafone/Idea", value: "Vodafone/Idea" },
  { label: "BSNL", value: "BSNL" },
  { label: "Sensorise", value: "Sensorise" },
  { label: "Jio", value: "Jio" },
  { label: "Airtel", value: "Airtel" },
];

const userRoleOptions = [
  { label: "Administrator", value: "admin" },
  { label: "Sub User", value: "user" },
];

const AddDeviceIndex = () => {
  //local states ----
  const [adminPermissions, setAdminPermissions] = React.useState([]);
  const [networkAdminPermissions, setNetworkAdminPermissions] = React.useState(
    []
  );
  const [deviceData, setDeviceData] = React.useState(null);
  const [addMoreCheck, setAddMoreCheck] = React.useState(false);
  const [searchInput, setSearchInput] = React.useState("");
  const [userList, setUserList] = React.useState({
    admins: [],
    networkAdmins: [],
  });
  const [orgList, setOrgList] = React.useState({
    admins: [],
    networkAdmins: [],
  });
  const [loading, setLoading] = React.useState({
    initialLoading: false,
    userListLoading: false,
  });
  const [toggleExcelPage, setToggleExcelPage] = React.useState(false);
  const [selectedFile, setSelectedFile] = React.useState(null);
  const [showTablePreview, setShowTablePreview] = React.useState(false);
  const [showNetworkPopup, setShowNetworkPopup] = React.useState(false);
  const [sampleFile, setSampleFile] = React.useState(null);
  const [organizationListing, setOrganizationListing] = React.useState([]);
  const [headers, setHeaders] = React.useState([]);
  const [rowData, setRowData] = React.useState([]);
  const [showAll, setShowAll] = React.useState(false);
  const [multipleNetworkAdmins, setMultipleNetworkAdmins] = React.useState([]);
  const [showResetSearchNA, setShowResetSearchNA] = React.useState(false);
  const [networkAdminAll, setNetworkAdminAll] = React.useState(false);
  const [searchValue, setSearchValue] = React.useState(""); // final search val after clicking on search [SUForm]
  const [editableNAPermissions, setEditableNAPermissions] = React.useState([]);
  const [searchFields, setSearchFields] = React.useState({
    adminOrg: null,
    networkAdminOrg: null,
    admin: null,
    networkAdmins: null,
    deviceModel: null,
    deviceType: null,
    portGroup: null,
  });

  //history ----
  // const history = useHistory();
  const { id } = useParams();

  //dispatcher ----
  const dispatch = useDispatch();

  //selector ----
  const {
    allDeviceCategoryNames,
    allDeviceModels,
    allPorts,
    alldevicePermissions,
    deviceDetails,
    deviceDetailsTemplate,
    editFlag,
    allocateDeviceLoading,
  } = useSelector((state) => state.deviceslist);
  const isDarkThemeEnabledSelector = useSelector(
    (state) => state.theme.themeDark
  );
  // const { organizationListing } = useSelector(
  //   (state) => state.organizationListing
  // );
  const { userId, currentUserDetail } = useSelector((state) => state.auth);
  const { allUsers } = useSelector((state) => state.userlist);
  const allPermissionRoles = useSelector(
    (state) => state.userPermission.permissionRoles
  );

  const {
    control,
    getValues,
    reset,
    setValue,
    handleSubmit,
    formState: { errors, isSubmitting, isDirty },
  } = useForm();

  const deviceTypeEntered = useWatch({
    control,
    name: "deviceType",
  });

  const deviceModelInfo = useWatch({
    control,
    name: "deviceModelInfo",
  });

  const adminSelected = useWatch({
    control,
    name: "adminSelected",
  });

  const networkAdminsDropdownVal = useWatch({
    control,
    name: "networkAdmins",
  });

  const networkAdminSelected = useWatch({
    control,
    name: "networkAdminSelected",
  });

  /** For edit device particularly */
  const existingNetworkAdmins = useWatch({
    control,
    name: "existingNetworkAdmins",
  });

  const adminOrgUser = useWatch({
    control,
    name: ["adminOrganization", "adminUserRole"],
  });

  const networkAdminOrgUser = useWatch({
    control,
    name: ["networkAdminOrganization", "networkAdminUserRole"],
  });

  const themeType = isDarkThemeEnabledSelector
    ? require("../../../static/styles/darktheme")
    : require("../../../static/styles/theme");

  const { buttonClasses, inputClasses, textClasses, dividerClass } = themeType;

  let networkTableColumns = [
    {
      header: (
        <Typography
          sx={{
            ...textClasses.boldText,
            padding: "5px 10px",
            textAlign: "center",
          }}
        >
          User
        </Typography>
      ),
      cell: (entry) => {
        return (
          <Box display="flex" flexDirection="column" alignItems="center">
            <Typography
              sx={{
                ...textClasses.t13n,
                textAlign: "center",
                textTransform: "capitalize",
                cursor: "pointer",
                fontWeight: 700,
              }}
            >
              {entry?.name ?? "--"}
            </Typography>
            <Typography
              sx={{
                ...textClasses.t11n,
                textAlign: "center",
                color: "#808080",
                textTransform: "capitalize",
                cursor: "pointer",
              }}
            >
              {entry?.orgName ?? "--"}
            </Typography>
          </Box>
        );
      },
    },
    {
      header: (
        <Typography
          sx={{
            ...textClasses.boldText,
            padding: "5px 10px",
            textAlign: "center",
          }}
        >
          Action
        </Typography>
      ),
      cell: (entry) => {
        return (
          <IconButton
            size="small"
            sx={{ color: themeType.default.themeRed }}
            onClick={() => {
              const { id, ...rest } = entry;

              handleRemoveNetworkAdmin({ ...rest, userId: id });
            }}
          >
            <DeleteOutline fontSize="inherit" color="error" />
          </IconButton>
        );
      },
    },
  ];

  if (alldevicePermissions && alldevicePermissions?.deviceSpecificPermission) {
    const { deviceSpecificPermission } = alldevicePermissions;
    const { label, ...rest } = deviceSpecificPermission;

    Object.keys(rest)?.length > 0 &&
      Object.keys(rest)?.map((key) => {
        networkTableColumns?.splice(1, 0, {
          header: (
            <Typography
              sx={{
                ...textClasses.boldText,
                textAlign: "center",
                whiteSpace: "normal",
              }}
            >
              {deviceSpecificPermission[key]?.label}
            </Typography>
          ),
          selector: "checkbox",
          cell: (entry, index) => {
            return (
              <Typography
                sx={{
                  ...textClasses.t13n,
                  textTransform: "capitalize",
                  color: themeType.default.themeOrange,
                }}
              >
                <Checkbox
                  size="small"
                  sx={{
                    marginBottom: "4px",
                    padding: "0px",
                    color: "#ff7200",
                    "&.Mui-checked": {
                      color: "#ff7200",
                    },
                  }}
                  checked={
                    entry?.permissions &&
                    entry?.permissions?.deviceSpecificPermission
                      ? entry?.permissions?.deviceSpecificPermission[key]?.value
                        ? true
                        : false
                      : entry?.permissions && entry?.permissions[key]?.value
                      ? true
                      : false
                  }
                  onChange={(event) => {
                    handleNAPermissionChange(event, entry, key, index);
                  }}
                />
              </Typography>
            );
          },
        });
      });
  }

  //* ----handlers -----
  const handleCreateDevice = (data) => {
    if (data?.sim2no && data?.sim1no?.trim() === data?.sim2no?.trim()) {
      showToast("Sim 1 Number and Sim 2 Number cannot be same", true);
      return;
    }
    try {
      setLoading((prev) => ({
        ...prev,
        initialLoading: true,
      }));

      sendCustomEventAnalytics("devices", "add-device", "Add Device");

      const {
        admin,
        adminSelected,
        networkAdmins,
        networkAdminSelected,
        deviceModelInfo,
        adminOrganization,
        adminUserRole,
        networkAdminOrganization,
        networkAdminUserRole,
        ...rest
      } = data;

      let tempNAFinal = [];

      const adminPermissionsUpdated = {
        deviceSpecificPermission: {
          label: "",
          ...adminPermissions,
        },
      };
      const networkAdminPermissionsUpdated = {
        deviceSpecificPermission: {
          label: "",
          ...networkAdminPermissions,
        },
      };

      if (networkAdminSelected && networkAdminSelected?.length > 0) {
        networkAdminSelected?.map((_) => {
          tempNAFinal?.push({
            id: _,
            permissions: networkAdminPermissionsUpdated,
          });
        });
      }

      const filteredTempNAFinal = tempNAFinal?.filter(
        (obj) => typeof obj.id === "string"
      );

      // "rest" will be the payload for "Add Device" API
      // device allocation to admins

      let allocationPayload = {};
      let networkAdminsData = [];
      if (admin) {
        allocationPayload = {
          deviceId: data?.deviceId,
          clientId: data?.clientId,
          adminId: admin,
          adminPermissions: adminPermissionsUpdated,
        };

        if (networkAdminAll) {
          networkAdminsData?.push({
            user: "all",
            orgId: networkAdminOrgUser[0],
            permissions: networkAdminPermissionsUpdated,
          });
          if (networkAdminOrgUser[1])
            networkAdminsData[0]["userRole.id"] = networkAdminOrgUser[1];
        } else if (filteredTempNAFinal && filteredTempNAFinal?.length > 0) {
          networkAdminsData?.push({
            user: "few",
            networkAdmins: filteredTempNAFinal,
          });
        }
        if (multipleNetworkAdmins && multipleNetworkAdmins?.length > 0) {
          multipleNetworkAdmins?.map((na, i) => {
            // console.log("naaaaa --- ", na);
            const filteredNetworkAdmins = getFilteredNetworkAdmins(
              na?.networkAdmins,
              na?.devicePermissions
            );
            if (na?.networkAdminAllFromOrg) {
              networkAdminsData?.push({
                user: "all",
                orgId: na?.networkAdminOrganization,
                permissions: {
                  deviceSpecificPermission: {
                    label: "",
                    ...na?.devicePermissions,
                  },
                },
              });
              if (na?.networkAdminUserRole)
                networkAdminsData[i + 1]["userRole.id"] =
                  na?.networkAdminUserRole;
            } else if (
              filteredNetworkAdmins &&
              filteredNetworkAdmins?.length > 0
            ) {
              networkAdminsData?.push({
                user: "few",
                networkAdmins: filteredNetworkAdmins,
              });
            }
          });
        }

        allocationPayload["networkAdmins"] = [...networkAdminsData];

        // if (networkAdminAll) {
        //   allocationPayload["user"] = "all";
        //   allocationPayload["orgId"] = networkAdminOrgUser[0];
        //   allocationPayload["permissions"] = networkAdminPermissionsUpdated;
        //   if (networkAdminOrgUser[1])
        //     allocationPayload["userRole.id"] = networkAdminOrgUser[1];
        // } else if (filteredTempNAFinal && filteredTempNAFinal?.length > 0) {
        //   allocationPayload["user"] = "few";
        //   allocationPayload["networkAdmins"] = filteredTempNAFinal;
        // }
      }

      dispatch(
        addNewDevice(rest, (res) => {
          if (res) {
            setTimeout(() => {
              if (allocationPayload && allocationPayload?.adminId) {
                dispatch(
                  allocateDevice(allocationPayload, (res) => {
                    if (res) {
                      setLoading((prev) => ({
                        ...prev,
                        initialLoading: false,
                      }));
                      if (addMoreCheck) {
                        setValue("deviceId", undefined);
                        setValue("imei", undefined);
                        setValue("clientId", undefined);
                      } else {
                        reset();
                        history.push({
                          pathname: "/dashboard/devices/all-devices",
                          state: { from: "create-device-page" },
                        });
                      }
                    } else {
                      setLoading((prev) => ({
                        ...prev,
                        initialLoading: false,
                      }));
                    }
                  })
                );
              } else {
                setLoading((prev) => ({
                  ...prev,
                  initialLoading: false,
                }));
                if (addMoreCheck) {
                  setValue("deviceId", undefined);
                  setValue("imei", undefined);
                  setValue("clientId", undefined);
                } else {
                  reset();
                  history.push({
                    pathname: "/dashboard/devices/all-devices",
                    state: { from: "create-device-page" },
                  });
                }
              }
            }, 100);
          } else {
            setLoading((prev) => ({
              ...prev,
              initialLoading: false,
            }));
          }
        })
      );
    } catch (e) {
      setLoading((prev) => ({
        ...prev,
        initialLoading: false,
      }));
      console.log("error- creating device: ", e);
    } finally {
      // setLoading((prev) => ({
      //   ...prev,
      //   initialLoading: false,
      // }));
    }
  };

  const handleCreateDeviceInBulk = (data) => {
    try {
      setLoading((prev) => ({
        ...prev,
        initialLoading: true,
      }));
      sendCustomEventAnalytics(
        "devices",
        "create-bulk-device",
        "Create Bulk Device"
      );
      const { networkAdminsIds, adminId, ...rest } = data;
      let tempNAFinal = [];
      const adminPermissionsUpdated = {
        deviceSpecificPermission: {
          label: "",
          ...adminPermissions,
        },
      };
      const networkAdminPermissionsUpdated = {
        deviceSpecificPermission: {
          label: "",
          ...networkAdminPermissions,
        },
      };

      if (networkAdminSelected && networkAdminSelected?.length > 0) {
        networkAdminSelected?.map((_) => {
          tempNAFinal?.push({
            id: _,
            permissions: networkAdminPermissionsUpdated,
          });
        });
      }

      const filteredTempNAFinal = tempNAFinal?.filter(
        (obj) => typeof obj.id === "string"
      );

      let payload = {
        ...rest,
      };

      if (adminId) {
        const temp = {
          ...payload,
          adminId: adminId,
          adminPermissions: adminPermissionsUpdated,
        };
        payload = temp;
      }

      if (filteredTempNAFinal && filteredTempNAFinal?.length > 0) {
        payload.networkAdminsIds = filteredTempNAFinal;
      }

      dispatch(
        addNewDeviceModelInBulk(payload, () => {
          setLoading((prev) => ({
            ...prev,
            initialLoading: false,
          }));
          history.push({
            pathname: "/dashboard/devices/all-devices",
            state: { from: "create-device-page" },
          });
        })
      );
    } catch (e) {
      setLoading((prev) => ({
        ...prev,
        initialLoading: false,
      }));
    } finally {
      setLoading((prev) => ({
        ...prev,
        initialLoading: false,
      }));
    }
  };

  const handleUpdateDevice = async (data) => {
    if (
      data?.sim2no &&
      data?.sim1no?.toString()?.trim() === data?.sim2no?.toString()?.trim()
    ) {
      showToast("Sim 1 Number and Sim 2 Number cannot be same", true);
      return;
    }
    sendCustomEventAnalytics("devices", "update-device", "Update Device");
    try {
      setLoading((prev) => ({
        ...prev,
        initialLoading: true,
      }));
      const {
        __v,
        _id,
        admin,
        adminSelected,
        networkAdmins,
        networkAdminSelected,
        addedBy,
        addedOn,
        adminOrganization,
        adminUserRole,
        assignedToAdmin,
        deviceModelInfo,
        formData,
        modifiedBy,
        modifiedOn,
        networkAdminOrganization,
        networkAdminUserRole,
        status,
        ...rest
      } = data;
      let tempNAFinal = [];

      const payload = { ...rest };
      payload.deviceModel = rest?.deviceModel?.deviceModel;
      payload.id = id;

      const adminPermissionsUpdated = {
        deviceSpecificPermission: {
          label: "",
          ...adminPermissions,
        },
      };

      const networkAdminPermissionsUpdated = {
        deviceSpecificPermission: {
          label: "",
          ...networkAdminPermissions,
        },
        // ...networkAdminPermissions,
      };

      if (networkAdminSelected && networkAdminSelected?.length > 0) {
        networkAdminSelected?.map((_) => {
          if (_ !== undefined) {
            // const selectedAdmin = userList?.networkAdmins?.find(na => na?._id === _);
            const selectedAdmin = networkAdmins?.find((na) => na?._id === _);
            tempNAFinal?.push({
              userId: selectedAdmin?._id,
              userName: selectedAdmin?.name,
              orgId: selectedAdmin?.orgId,
              orgName: selectedAdmin?.orgName,
              deviceType: data?.deviceType,
              deviceModel: data?.deviceModel?.deviceModel,
              permissions: networkAdminPermissionsUpdated,
            });
          }
        });
      }

      const filteredTempNAFinal = tempNAFinal
        ?.filter((obj) => typeof obj.userId === "string") // Filter out objects with "id" not being a string
        ?.reduce(
          (acc, obj) => {
            if (!acc.idSet.has(obj.userId)) {
              acc.idSet.add(obj.userId);
              acc.result.push(obj);
            }
            return acc;
          },
          { idSet: new Set(), result: [] }
        )?.result;

      let allocationPayload = {};
      let networkAdminAclPayload = {
        clientId: deviceDetailsTemplate?.clientId,
        deviceId: deviceDetailsTemplate?.deviceId,
        type: "append",
      };
      let networkAdminsData = [];
      let currentAdminPermission = {};

      if (
        deviceDetailsTemplate["assignedToAdmin"]?.permissions &&
        Object.keys(deviceDetailsTemplate["assignedToAdmin"]?.permissions)
          ?.length
      ) {
        const { deviceSpecificPermission } =
          deviceDetailsTemplate["assignedToAdmin"]?.permissions;
        const { label, ...restPermissions } = deviceSpecificPermission;
        currentAdminPermission = restPermissions;
      }

      let isSamePermissions = _?.isEqual(
        currentAdminPermission,
        adminPermissions
      );

      if (
        admin &&
        (admin !== deviceDetailsTemplate["assignedToAdmin"]?.id ||
          !isSamePermissions)
      ) {
        allocationPayload = {
          deviceId: data?.deviceId,
          clientId: data?.clientId,
          adminId: admin,
          adminPermissions: adminPermissionsUpdated,
        };
      } else if (!admin && deviceDetailsTemplate["assignedToAdmin"]?.id) {
        allocationPayload = {
          deviceId: data?.deviceId,
          clientId: data?.clientId,
          removeAdmin: true,
        };
      }

      if (networkAdminAll) {
        networkAdminsData?.push({
          user: "all",
          orgId: networkAdminOrgUser[0],
          permissions: networkAdminPermissionsUpdated,
        });

        if (networkAdminOrgUser[1])
          networkAdminsData[0]["userRole.id"] = networkAdminOrgUser[1];
      } else if (filteredTempNAFinal && filteredTempNAFinal?.length > 0) {
        networkAdminsData?.push({
          networkAdmins: filteredTempNAFinal,
          user: "few",
        });
      }

      if (multipleNetworkAdmins && multipleNetworkAdmins?.length > 0) {
        multipleNetworkAdmins?.map((na, i) => {
          const filteredNetworkAdmins = getFilteredNetworkAdminsUpdate(
            na?.networkAdmins,
            na?.devicePermissions,
            data?.deviceType,
            data?.deviceModel?.deviceModel
          );
          if (na?.networkAdminAllFromOrg) {
            networkAdminsData?.push({
              user: "all",
              orgId: na?.networkAdminOrganization,
              permissions: {
                deviceSpecificPermission: {
                  label: "",
                  ...na?.devicePermissions,
                },
                // ...na?.devicePermissions,
              },
            });
            if (na?.networkAdminUserRole)
              networkAdminsData[i + 1]["userRole.id"] =
                na?.networkAdminUserRole;
          } else if (
            filteredNetworkAdmins &&
            filteredNetworkAdmins?.length > 0
          ) {
            networkAdminsData?.push({
              user: "few",
              networkAdmins: filteredNetworkAdmins,
            });
          }
        });
      }
      networkAdminAclPayload.networkAdmins = [...networkAdminsData];
      // console.log("networkAdminAclPayload: ", networkAdminAclPayload);

      Object.keys(payload)?.map((key) => {
        if (payload[key] === undefined) payload[key] = "";
      });

      dispatch(
        editDevice(payload, async (res) => {
          if (res) {
            if (allocationPayload?.adminId || allocationPayload?.removeAdmin) {
              dispatch(
                allocateDevice(allocationPayload, (res) => {
                  if (res) {
                    if (
                      networkAdminAclPayload &&
                      ((networkAdminAclPayload?.networkAdmins &&
                        networkAdminAclPayload?.networkAdmins?.length > 0) ||
                        (networkAdminAclPayload?.orgId &&
                          networkAdminAclPayload?.user === "all"))
                    ) {
                      dispatch(
                        updateNetworkAdminACL(networkAdminAclPayload, (res) => {
                          if (res) {
                            history.push({
                              pathname: "/dashboard/devices/all-devices",
                              state: { from: "create-device-page" },
                            });
                          }
                        })
                      );
                    } else {
                      history.push({
                        pathname: "/dashboard/devices/all-devices",
                        state: { from: "create-device-page" },
                      });
                    }
                  }
                })
              );
            } else if (
              networkAdminAclPayload &&
              ((networkAdminAclPayload?.networkAdmins &&
                networkAdminAclPayload?.networkAdmins?.length > 0) ||
                (networkAdminAclPayload?.orgId &&
                  networkAdminAclPayload?.user === "all"))
            ) {
              dispatch(
                updateNetworkAdminACL(networkAdminAclPayload, (res) => {
                  if (res) {
                    history.push({
                      pathname: "/dashboard/devices/all-devices",
                      state: { from: "create-device-page" },
                    });
                  }
                })
              );
            } else {
              history.push({
                pathname: "/dashboard/devices/all-devices",
                state: { from: "create-device-page" },
              });
            }
          }
          setLoading((prev) => ({
            ...prev,
            initialLoading: false,
          }));
        })
      );
    } catch (e) {
      console.log("error while updating: ", e);
      setLoading((prev) => ({
        ...prev,
        initialLoading: false,
      }));
    }
  };

  const handleFetchDeviceDetails = React.useCallback(
    (data) => {
      try {
        if (data) {
          setLoading((prev) => ({
            ...prev,
            initialLoading: true,
          }));
          dispatch(
            getDeviceDetails(data, () => {
              setLoading((prev) => ({
                ...prev,
                initialLoading: false,
              }));
            })
          );
        }
      } catch (e) {
        setLoading((prev) => ({
          ...prev,
          initialLoading: false,
        }));
      }
    },
    [dispatch]
  );

  /* --- for adding device --- */
  const getFilteredNetworkAdmins = (currentNetworkAdmins, permissions) => {
    if (currentNetworkAdmins && currentNetworkAdmins?.length > 0) {
      let tempNetworkAdmins = [];
      currentNetworkAdmins?.map((_) => {
        tempNetworkAdmins?.push({
          userId: _?._id,
          permissions: {
            deviceSpecificPermission: {
              label: "",
              ...permissions,
            },
          },
        });
      });

      return tempNetworkAdmins?.filter((obj) => typeof obj.id === "string");
    }
  };

  /* --- for updating device --- */
  const getFilteredNetworkAdminsUpdate = (
    currentNetworkAdmins,
    permissions,
    deviceType,
    deviceModel
  ) => {
    if (currentNetworkAdmins && currentNetworkAdmins?.length > 0) {
      let tempNetworkAdmins = [];
      currentNetworkAdmins?.map((_) => {
        tempNetworkAdmins?.push({
          userId: _?._id,
          userName: _?.name,
          orgId: _?.orgId,
          orgName: _?.orgName,
          deviceType: deviceType,
          deviceModel: deviceModel,
          permissions: {
            deviceSpecificPermission: {
              label: "",
              ...permissions,
            },
          },
        });
      });

      return tempNetworkAdmins?.filter((obj) => typeof obj.userId === "string");
    }
  };

  const handleCheckboxChange = React.useCallback(
    (e, key, type) => {
      if (type === "admin") {
        setAdminPermissions((prevData) => {
          if (prevData[key]) {
            return {
              ...prevData,
              [key]: {
                ...prevData[key],
                value: e?.target?.checked ? true : false,
              },
            };
          } else {
            return prevData;
          }
        });
      } else if (type === "networkAdmins") {
        setNetworkAdminPermissions((prevData) => {
          if (prevData[key]) {
            return {
              ...prevData,
              [key]: {
                ...prevData[key],
                value: e?.target?.checked ? true : false,
              },
            };
          } else {
            return prevData;
          }
        });
      }
    },
    [adminPermissions, networkAdminPermissions]
  );

  const handleMultipleNaCheckboxChange = (e, key, idx) => {
    setMultipleNetworkAdmins((prevArray) => {
      const newArray = [...prevArray];
      const objectToUpdate = newArray[idx];

      if (objectToUpdate.hasOwnProperty("devicePermissions")) {
        objectToUpdate.devicePermissions = {
          ...objectToUpdate.devicePermissions,
          [key]: {
            ...objectToUpdate.devicePermissions[key],
            value: e?.target?.checked ? true : false,
          },
        };
      }

      newArray[idx] = objectToUpdate;
      return newArray;
    });
  };

  const handleSelectAllPermission = React.useCallback(
    (e, type) => {
      if (type === "admin") {
        //we will make deep copy of adminPermisison & networkAdminPermissions state to avoid making changes to original (allDevicePermission) object.
        let tempAdmin = _.cloneDeep(adminPermissions);
        Object.keys(tempAdmin)?.map((key) => {
          tempAdmin[key].value = e?.target?.checked ? true : false;
        });
        setAdminPermissions(tempAdmin);
      } else {
        let tempNetworkAdmin = _.cloneDeep(networkAdminPermissions);
        Object.keys(tempNetworkAdmin)?.map((key) => {
          tempNetworkAdmin[key].value = e?.target?.checked ? true : false;
        });
        setNetworkAdminPermissions(tempNetworkAdmin);
      }
    },
    [adminPermissions, networkAdminPermissions]
  );

  const handleMultipleSelectAllPermission = (e, idx) => {
    setMultipleNetworkAdmins((prevArray) => {
      const newArray = [...prevArray];
      const objectToUpdate = newArray[idx];

      Object.keys(objectToUpdate?.devicePermissions)?.map((key) => {
        objectToUpdate.devicePermissions[key].value = e?.target?.checked
          ? true
          : false;
      });
      objectToUpdate.allPermissionSelected = e?.target?.checked ? true : false;

      newArray[idx] = objectToUpdate;
      return newArray;
    });
  };

  const handleSearchOrgMultipleNa = (payload, idx) => {
    if (payload) {
      setLoading((prev) => ({
        ...prev,
        initialLoading: false,
        userListLoading: true,
      }));
      axios
        .post("/listOrganizations", {
          ...payload,
          selection: ["name"],
        })
        .then(({ data }) => {
          if (data?.success) {
            setMultipleNetworkAdmins((prev) => {
              const existing = [...prev];
              const objectToUpdate = existing[idx];

              objectToUpdate.organizationListing = data?.data;
              existing[idx] = objectToUpdate;

              return existing;
            });
            setLoading((prev) => ({
              ...prev,
              initialLoading: false,
              userListLoading: false,
            }));
          } else if (organizationListing) {
            setMultipleNetworkAdmins((prev) => {
              const existing = [...prev];
              const objectToUpdate = existing[idx];

              objectToUpdate.organizationListing = organizationListing;
              existing[idx] = objectToUpdate;

              return existing;
            });
          }
        })
        ?.catch((e) => {
          setLoading((prev) => ({
            ...prev,
            initialLoading: false,
            userListLoading: false,
          }));
          setMultipleNetworkAdmins((prev) => {
            const existing = [...prev];
            const objectToUpdate = existing[idx];

            objectToUpdate.organizationListing = organizationListing;
            existing[idx] = objectToUpdate;

            return existing;
          });
        });
    }
  };

  const handleSearchUsersMultipleNa = (payload, idx) => {
    if (payload && allUsers) {
      setLoading((prev) => ({
        ...prev,
        initialLoading: false,
        userListLoading: true,
      }));
      axios
        .post("/listUsers", {
          ...payload,
          selection: ["name", "orgId", "orgName"],
        })
        .then(({ data }) => {
          if (data?.success) {
            setMultipleNetworkAdmins((prev) => {
              const existing = [...prev];
              const objectToUpdate = existing[idx];

              objectToUpdate.networkAdminList = data?.data;
              existing[idx] = objectToUpdate;

              return existing;
            });
            setLoading((prev) => ({
              ...prev,
              initialLoading: false,
              userListLoading: false,
            }));
          } else {
            setMultipleNetworkAdmins((prev) => {
              const existing = [...prev];
              const objectToUpdate = existing[idx];

              objectToUpdate.networkAdminList = allUsers;
              existing[idx] = objectToUpdate;

              return existing;
            });
          }
        })
        ?.catch((e) => {
          setLoading((prev) => ({
            ...prev,
            initialLoading: false,
            userListLoading: false,
          }));
          setMultipleNetworkAdmins((prev) => {
            const existing = [...prev];
            const objectToUpdate = existing[idx];

            objectToUpdate.networkAdminList = allUsers;
            existing[idx] = objectToUpdate;

            return existing;
          });
        });
    }
  };

  const handleFetchAndCatchUsers = React.useCallback(
    (payload, type) => {
      if (type === "admin") payload.userRoleType = "admin";
      if (payload && allUsers) {
        setLoading((prev) => ({
          ...prev,
          initialLoading: false,
          userListLoading: true,
        }));
        axios
          .post("/listUsers", {
            ...payload,
            selection: ["name", "orgId", "orgName"],
          })
          .then(({ data }) => {
            if (data?.success) {
              if (type === "admin") {
                setUserList((prevData) => ({
                  ...prevData,
                  admins: data?.data,
                }));
                setLoading((prev) => ({
                  ...prev,
                  initialLoading: false,
                  userListLoading: false,
                }));
              } else {
                setUserList((prevData) => ({
                  ...prevData,
                  networkAdmins: data?.data,
                  networkAdminCount: data?.count,
                }));
                setLoading((prev) => ({
                  ...prev,
                  initialLoading: false,
                  userListLoading: false,
                }));
              }
            } else if (allUsers) {
              setUserList((prevData) => ({
                ...prevData,
                networkAdmins: allUsers,
                admins: allUsers,
              }));
            }
          })
          ?.catch((e) => {
            setLoading((prev) => ({
              ...prev,
              initialLoading: false,
              userListLoading: false,
            }));
            setUserList((prevData) => ({
              ...prevData,
              networkAdmins: allUsers,
              admins: allUsers,
            }));
          });
      }
    },
    [allUsers]
  );

  const handleSearchOrgAdminWise = React.useCallback(
    (payload, type) => {
      if (payload && organizationListing) {
        setLoading((prev) => ({
          ...prev,
          initialLoading: false,
          userListLoading: true,
        }));
        axios
          .post("/listOrganizations", {
            ...payload,
            selection: ["name"],
          })
          .then(({ data }) => {
            if (data?.success) {
              if (type === "admin") {
                setOrgList((prevData) => ({
                  ...prevData,
                  admins: data?.data,
                }));
                setLoading((prev) => ({
                  ...prev,
                  initialLoading: false,
                  userListLoading: false,
                }));
              } else {
                setOrgList((prevData) => ({
                  ...prevData,
                  networkAdmins: data?.data,
                }));
                setLoading((prev) => ({
                  ...prev,
                  initialLoading: false,
                  userListLoading: false,
                }));
              }
            } else if (organizationListing) {
              setOrgList((prevData) => ({
                ...prevData,
                networkAdmins: organizationListing,
                admins: organizationListing,
              }));
            }
          })
          ?.catch((e) => {
            setLoading((prev) => ({
              ...prev,
              initialLoading: false,
              userListLoading: false,
            }));
            setOrgList((prevData) => ({
              ...prevData,
              networkAdmins: organizationListing,
              admins: organizationListing,
            }));
          });
      }
    },
    [organizationListing]
  );

  const handleAllocateDeviceToUser = React.useCallback(() => {
    try {
      if (deviceDetails) {
        const allocationPayload = {
          deviceId: deviceDetails?.asset_id,
          clientId: deviceDetails?.client_id,
          adminId: currentUserDetail?._id,
          adminPermissions: {
            deviceSpecificPermission: {
              label: "",
              viewTrip: {
                label: "View Trip",
                value: true,
              },
              createTrip: {
                label: "Create Trip",
                value: true,
              },
              alertWithTrip: {
                label: "Alert with Trip",
                value: true,
              },
              alertWithoutTrip: {
                label: "Alert without Trip",
                value: true,
              },
              unlockDevice: {
                label: "Unlock Device",
                value: true,
              },
              updateAdditionalDetail: {
                label: "Update additional Detail",
                value: true,
              },
              allocateToSubuser: {
                label: "Allocate to sub user",
                value: false,
              },
              allocateToNetworkAdmin: {
                label: "Allocation to network admin",
                value: false,
              },
              viewPermission: {
                label: "View Permission",
                value: false,
              },
              editPermission: {
                label: "Edit permission",
                value: false,
              },
            },
          },
          networkAdmins: [],
        };
        dispatch(
          allocateDevice(allocationPayload, (res) => {
            if(res){
              history.push({
                pathname: "/dashboard/devices/all-devices",
                state: { from: "create-device-page" },
              });
            }
          })
        );
      }
    } catch (e) {
      console.log("error allocating device to user", e);
    }
  }, [dispatch, deviceDetails, currentUserDetail]);

  const openUpdateNetworkAdmins = React.useCallback(() => {
    setEditableNAPermissions([...deviceDetailsTemplate["networkAdmins"]]);
    setTimeout(() => {
      setShowNetworkPopup(true);
    }, 400);
  }, [setShowNetworkPopup, setEditableNAPermissions, deviceDetailsTemplate]);

  const handleNAPermissionChange = React.useCallback(
    (event, entry, key, index) => {
      // Create a deep copy of editableNAPermissions, this wont change the "deviceDetailTemplate" state directly.
      let tempAllPermissions = JSON.parse(
        JSON.stringify(editableNAPermissions)
      );
      if (tempAllPermissions && tempAllPermissions?.length > 0) {
        // tempAllPermissions[index].permissions.deviceSpecificPermission[key].value = event?.target?.checked ? true : false;
        const permission = tempAllPermissions[index]?.permissions
          ?.deviceSpecificPermission
          ? tempAllPermissions[index]?.permissions?.deviceSpecificPermission?.[
              key
            ]
          : tempAllPermissions[index]?.permissions?.[key];

        if (permission) {
          permission.value = event?.target?.checked ? true : false;
        }
        setEditableNAPermissions(tempAllPermissions);
      }
    },
    [dispatch, editableNAPermissions, deviceDetailsTemplate]
  );

  const handleCancelButton = React.useCallback(() => {
    history.push({
      pathname: "/dashboard/devices/all-devices",
      state: { from: "create-device-page" },
    });
  }, [history]);

  const handleUpdateNetworkAdminsACL = React.useCallback(() => {
    try {
      setLoading((prev) => ({
        ...prev,
        initialLoading: true,
      }));
      if (editableNAPermissions?.length > 0 && deviceDetailsTemplate) {
        const updatedNetworkAdmins = editableNAPermissions.map((obj) => {
          const { name, id, ...rest } = obj;
          return { ...rest, userName: name, userId: id };
        });
        const payload = {
          deviceId: deviceDetailsTemplate?.deviceId,
          clientId: deviceDetailsTemplate?.clientId,
          networkAdmins: updatedNetworkAdmins,
          type: "append",
        };
        dispatch(
          updateNetworkAdminACL(payload, (res) => {
            if (res) {
              setShowNetworkPopup(false);
              dispatch(getEditDeviceTemplate({ id }));
            }
            setLoading((prev) => ({
              ...prev,
              initialLoading: false,
            }));
          })
        );
      }
    } catch (e) {
      setLoading((prev) => ({
        ...prev,
        initialLoading: false,
      }));
    } finally {
      setLoading((prev) => ({
        ...prev,
        initialLoading: false,
      }));
    }
  }, [editableNAPermissions, deviceDetailsTemplate]);

  const handleRemoveNetworkAdmin = React.useCallback(
    (networkAdmin) => {
      try {
        setLoading((prev) => ({
          ...prev,
          initialLoading: true,
        }));
        if (networkAdmin && deviceDetailsTemplate) {
          const payload = {
            // id: deviceDetailsTemplate?._id,
            type: "delete",
            clientId: deviceDetailsTemplate?.clientId,
            networkAdmins: [networkAdmin],
          };
          dispatch(
            updateNetworkAdminACL(payload, () => {
              dispatch(
                getEditDeviceTemplate({ id }, (res) => {
                  if (res) {
                    setEditableNAPermissions([...res["networkAdmins"]]);
                  }
                })
              );
              setLoading((prev) => ({
                ...prev,
                initialLoading: false,
              }));
            })
          );
        }
      } catch (e) {
        setLoading((prev) => ({
          ...prev,
          initialLoading: false,
        }));
      } finally {
        setLoading((prev) => ({
          ...prev,
          initialLoading: false,
        }));
      }
    },
    [deviceDetailsTemplate]
  );

  const handleFetchUserRoles = (orgId) => {
    if (orgId) {
      dispatch(
        getPermissionRoles({
          orgId,
        })
      );
    }
  };

  const handleFetchMultipleUserRoles = (orgId, idx) => {
    if (orgId) {
      dispatch(
        getUserRolesFromOrg(
          {
            orgId,
          },
          (res) => {
            if (res && res?.success) {
              let tempMultipleNA = multipleNetworkAdmins;
              tempMultipleNA[idx].userRoleList = res?.data;
              setMultipleNetworkAdmins([...tempMultipleNA]);
            }
          }
        )
      );
    }
  };

  const handleFetchUsersForMultipleNA = (payload, index) => {
    let tempMultiple = multipleNetworkAdmins;
    if (payload) {
      setLoading((prev) => ({
        ...prev,
        initialLoading: false,
        userListLoading: true,
      }));

      axios
        .post("/listUsers", {
          ...payload,
          selection: ["name", "orgId", "orgName"],
        })
        .then(({ data }) => {
          if (data?.success) {
            tempMultiple[index].networkAdminList = data?.data;
            tempMultiple[index].networkAdminCount = data?.count;

            setLoading((prev) => ({
              ...prev,
              initialLoading: false,
              userListLoading: false,
            }));
          } else if (allUsers) {
            tempMultiple[index].networkAdminList = allUsers;
            tempMultiple[index].networkAdminCount = 0;
          }
        })
        ?.catch((e) => {
          setLoading((prev) => ({
            ...prev,
            initialLoading: false,
            userListLoading: false,
          }));
          tempMultiple[index].networkAdminList = allUsers;
          tempMultiple[index].networkAdminCount = 0;
        });
    }

    setMultipleNetworkAdmins([...tempMultiple]);
  };

  const handleSearchAdminOrg = (empty) => {
    if (empty === true) {
      handleSearchOrgAdminWise({}, "admin");
    } else {
      if (searchFields?.adminOrg || searchFields?.adminOrg == "")
        handleSearchOrgAdminWise(
          { searchValue: searchFields?.adminOrg },
          "admin"
        );
    }
  };

  const handleSearchNetworkAdminOrg = (empty) => {
    if (empty === true) {
      handleSearchOrgAdminWise({}, "networkAdmins");
    } else {
      if (searchFields?.networkAdminOrg || searchFields?.networkAdminOrg == "")
        handleSearchOrgAdminWise(
          { searchValue: searchFields?.networkAdminOrg },
          "networkAdmins"
        );
    }
  };

  const handleSearchAdmins = (empty) => {
    if (empty) {
      const payload = {
        orgName: adminOrgUser[0] ?? undefined,
        searchValue: "",
      };
      handleFetchAndCatchUsers(payload, "admin");
    } else {
      if (
        searchFields?.admin?.searchValue ||
        searchFields?.admin?.searchValue == ""
      )
        handleFetchAndCatchUsers(searchFields?.admin, "admin");
    }
  };

  const handleSearchNetworkAdmins = (empty) => {
    if (empty) {
      const payload = {
        orgName: networkAdminOrgUser[0] ?? undefined,
        searchValue: "",
        userRole: networkAdminOrgUser[1] ?? undefined,
      };
      handleFetchAndCatchUsers(payload, "networkAdmins");
      setShowResetSearchNA(false);
    } else {
      if (
        searchFields?.networkAdmins?.searchValue ||
        searchFields?.networkAdmins?.searchValue == ""
      ) {
        handleFetchAndCatchUsers(searchFields?.networkAdmins, "networkAdmins");
      }
      setShowResetSearchNA(true);
    }
  };

  const handleSearchDeviceModels = () => {
    if (searchFields?.deviceModel || searchFields?.deviceModel == "")
      dispatch(
        getAllDeviceModels({
          filter: {
            category: deviceTypeEntered,
            lynkDeviceModel: searchFields?.deviceModel,
          },
          selection: ["info", "deviceModel", "lynkDeviceModel"],
        })
      );
  };

  const handleSearchDeviceType = () => {
    if (searchFields?.deviceType || searchFields?.deviceType == "")
      dispatch(
        getAllDeviceCategoryNames({
          searchValue: searchFields?.deviceType,
        })
      );
  };

  const handleSearchPortGroup = () => {
    if (searchFields?.portGroup || searchFields?.portGroup == "")
      dispatch(
        getAllPortGroups(
          {
            isUniqueGroupType: true,
            filter: {
              groupName: searchFields?.portGroup,
            },
          },
          () => {
            setSearchFields((prev) => ({
              ...prev,
              portGroup: null,
            }));
          }
        )
      );
  };

  /** ---- Effects ------ */
  React.useEffect(() => {
    setRowData([]);
    setHeaders([]);
    setSelectedFile(null);
    dispatch(flushUsersList());
    setNetworkAdminAll(false);
    setMultipleNetworkAdmins([]);
    setShowResetSearchNA(false);
    reset();
  }, []);

  /* To fetch all necessary data on initial load */
  React.useEffect(() => {
    try {
      setLoading((prev) => ({
        ...prev,
        initialLoading: true,
      }));
      const dispatchers = [
        dispatch(getAllDeviceCategoryNames()),
        // dispatch(
        //   getAllOrganisations({ selection: ["name"] }, (res) => {
        //     if (res && res?.success) {
        //       setOrgList((prev) => ({
        //         ...prev,
        //         admins: res?.data,
        //         networkAdmins: res?.data,
        //       }));

        //     }
        //   })
        // ),
        dispatch(
          getOrgListUserWise({ selection: ["name"] }, (res) => {
            console.log("getOrgListUserWise - res: ", res);
            if (res && res?.success) {
              setOrgList((prev) => ({
                ...prev,
                admins: res?.data,
                networkAdmins: res?.data,
              }));
              setOrganizationListing(res?.data);
            }
          })
        ),
        dispatch(
          getAllPortGroups({
            isUniqueGroupType: true,
          })
        ),
        dispatch(flushAllDeviceModels()),
      ];
      reset();
      setMultipleNetworkAdmins([]);
      Promise.all(dispatchers)
        ?.then(() => {
          setLoading((prev) => ({
            ...prev,
            initialLoading: false,
          }));
        })
        ?.catch((e) => {
          setLoading((prev) => ({
            ...prev,
            initialLoading: false,
          }));
        });
    } catch (e) {
      setLoading((prev) => ({
        ...prev,
        initialLoading: false,
      }));
    }
  }, [dispatch]);

  /* Fetch all Device Permissions only for admins --- use currentUserDetail */
  React.useEffect(() => {
    if (currentUserDetail && currentUserDetail?.adminSpecificPermissions) {
      // !allUsers ||
      //   (allUsers?.length < 1 &&
      //     dispatch(getAllUsers({ selection: ["name", "orgId", "orgName"] })));
      dispatch(getAllUsers({ selection: ["name", "orgId", "orgName"] }));
      dispatch(getAllDevicePermissions());
      handleFetchAndCatchUsers(
        {
          userRoleType: "admin",
        },
        "admin"
      );
    }else{
      dispatch(flushDeviceDetails());
      setSearchInput("");
      setSearchValue("");
    }
  }, [currentUserDetail]);

  /* Only setting networkAdmins here because we need to set admins list by roleType - "admin" */
  React.useEffect(() => {
    if (allUsers && allUsers?.length > 0) {
      setUserList((prevData) => ({
        ...prevData,
        networkAdmins: allUsers,
      }));
    }
  }, [allUsers]);

  /* To set device details in local state (not for superadmins) */
  React.useEffect(() => {
    if (deviceDetails) {
      if (id) {
        // Object?.keys(deviceDetails)?.map((key) => {
        //   setValue(key, deviceDetails[key]);
        // });
      } else {
        let details = [];
        details.push(deviceDetails);
        setDeviceData(details);
      }
    } else {
      setDeviceData(null);
    }
  }, [deviceDetails]);

  /* Setting local admin & networkAdmin permissions when "alldevicePermissions" is fetched */
  React.useEffect(() => {
    if (
      alldevicePermissions &&
      alldevicePermissions?.deviceSpecificPermission
    ) {
      const { deviceSpecificPermission } = alldevicePermissions;
      const { label, ...rest } = deviceSpecificPermission;
      setAdminPermissions(rest);
      setNetworkAdminPermissions(rest);
    }
  }, [alldevicePermissions]);

  /* For setting field values in form while editing */
  React.useEffect(() => {
    if (
      deviceDetailsTemplate &&
      !editFlag &&
      window.location.pathname?.includes("edit")
    ) {
      if (deviceDetailsTemplate) {
        if (allDeviceCategoryNames && allDeviceCategoryNames?.length > 0) {
          setValue("deviceType", deviceDetailsTemplate["deviceType"]);
        }
        if (allDeviceModels && allDeviceModels?.length > 0) {
          const dm = allDeviceModels?.find(
            (_) => _?.deviceModel === deviceDetailsTemplate["deviceModel"]
          );
          let onlyTruePermissions =
            dm?.info?.length > 0 &&
            dm?.info?.filter((item) => item?.visibility == true);

          setValue("deviceModel", dm);
          setValue("deviceModelInfo", onlyTruePermissions);
          dm && dispatch(changeEditFlag(true));
        }
      }

      setTimeout(() => {
        Object?.keys(deviceDetailsTemplate)?.map((key) => {
          if (key !== "deviceModel" && key !== "deviceType")
            setValue(key, deviceDetailsTemplate[key]);
          if (key === "assignedToAdmin") {
            setValue("adminOrganization", deviceDetailsTemplate[key]?.orgName);

            const adminObjObject = userList?.admins.find(
              (obj) => obj._id === deviceDetailsTemplate[key]?._id
            );
            setValue("admin", deviceDetailsTemplate[key]?.id);
            if (!adminObjObject) {
              handleFetchAndCatchUsers(
                { searchValue: deviceDetailsTemplate[key]?.name },
                "admin"
              );
            }
            setValue("adminSelected", deviceDetailsTemplate[key]?.id);
            if (
              deviceDetailsTemplate[key]?.permissions &&
              Object.keys(deviceDetailsTemplate[key]?.permissions)?.length > 0
            ) {
              const { deviceSpecificPermission } =
                deviceDetailsTemplate[key]?.permissions;
              const { label, ...rest } = deviceSpecificPermission;
              setAdminPermissions(rest);
            }
          }
          if (key === "networkAdmins") {
            setEditableNAPermissions(deviceDetailsTemplate[key]);
          }
        });
        if (deviceDetailsTemplate?.formData) {
          Object?.keys(deviceDetailsTemplate?.formData)?.map((key) => {
            setValue(key, deviceDetailsTemplate?.formData[key]);
          });
        }
      }, 800);
    }
  }, [
    deviceDetailsTemplate,
    allDeviceModels,
    allDeviceCategoryNames,
    setValue,
  ]);

  /* fetching details template for editing device */
  React.useEffect(() => {
    if (id) {
      reset();
      setMultipleNetworkAdmins([]);
      dispatch(changeEditFlag(false));
      dispatch(getEditDeviceTemplate({ id }));
    } else {
      setTimeout(() => {
        if (
          alldevicePermissions &&
          alldevicePermissions?.deviceSpecificPermission
        ) {
          const { deviceSpecificPermission } = alldevicePermissions;
          const { label, ...rest } = deviceSpecificPermission;

          setAdminPermissions(rest);
          setNetworkAdminPermissions(rest);
        }
      }, 400);
      reset();
      setMultipleNetworkAdmins([]);
    }
  }, [id]);

  return (
    <>
      <Grid container>
        {/* Page title and action button ----- */}
        <Grid
          item
          md={12}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Box>
            <Typography data-testid="title" sx={{ ...textClasses.cardTitle }}>
              {id ? "Edit Device" : "Add Device"}
            </Typography>
            <Breadcrumbs sx={{ mb: 1 }}>
              <Typography
                sx={{
                  ...textClasses.normalText,
                  fontSize: "12px",
                  color: themeType.default.themeOrange,
                }}
              >
                <Link
                  style={{
                    color: themeType.default.themeOrange,
                    textDecoration: "none",
                  }}
                  to={{
                    pathname: "/dashboard/devices",
                    state: { from: "create-device-page" },
                  }}
                >
                  Devices
                </Link>
              </Typography>

              <Typography sx={{ ...textClasses.normalText, fontSize: "12px" }}>
                Add Device
              </Typography>
            </Breadcrumbs>
          </Box>
          {!id && currentUserDetail?.adminSpecificPermissions && (
            <Button
              variant="outlined"
              size="small"
              data-testid="toggle-form-btn"
              sx={{
                ...buttonClasses.small,
                minHeight: "36px",
                borderColor: themeType.default.themeOrange,
                color: buttonClasses.lynkitOrangeFill,
              }}
              startIcon={<Upload />}
              onClick={() => {
                sendCustomEventAnalytics(
                  "devices",
                  `${toggleExcelPage ? "toggle-to-upload" : "toggle-to-form"}`,
                  `${toggleExcelPage ? "toggle-to-upload" : "toggle-to-form"}`
                );
                setToggleExcelPage(!toggleExcelPage);
                reset();
                setMultipleNetworkAdmins([]);
              }}
            >
              {toggleExcelPage ? "Form Data" : "Upload"}
            </Button>
          )}
        </Grid>

        {loading?.initialLoading ? (
          <Grid
            container
            direction={"column"}
            justifyContent={"center"}
            alignItems="center"
            xs={12}
            sx={{ p: 15 }}
          >
            <Grid item>
              <img src={loaderGif} />
            </Grid>
          </Grid>
        ) : (
          <Grid
            item
            container
            xs={12}
            p={2}
            my={2}
            borderRadius="10px"
            border="1px solid #E0E0E0"
          >
            {currentUserDetail &&
              currentUserDetail?.adminSpecificPermissions && (
                <>
                  {/* only for super admin--------- */}
                  {toggleExcelPage ? (
                    <SAExcelForm
                      otherFormProps={{
                        selectedFile,
                        setSelectedFile,
                        showTablePreview,
                        setShowTablePreview,
                        sampleFile,
                        setSampleFile,
                        headers,
                        setHeaders,
                        rowData,
                        setRowData,
                        dispatch,
                        deviceTypeEntered,
                        deviceModelInfo,
                        themeType,
                        handleSubmit,
                        handleCreateDevice,
                        handleCreateDeviceInBulk,
                        handleUpdateDevice,
                        handleCancelButton,
                        handleSearchDeviceModels,
                        handleSearchDeviceType,
                        handleSearchPortGroup,
                        allPorts,
                        allDeviceModels,
                        operatorOptions,
                        allDeviceCategoryNames,
                      }}
                      adminSectionProps={{
                        errors,
                        control,
                        reset,
                        showAll,
                        setShowAll,
                        showResetSearchNA,
                        setShowResetSearchNA,
                        deviceDetailsTemplate,
                        editableNAPermissions,
                        networkAdminsDropdownVal,
                        themeType,
                        searchFields,
                        setSearchFields,
                        loading: loading?.userListLoading,
                        setLoading,
                        selectStylesOverride: selectStylesOverride(
                          isDarkThemeEnabledSelector
                        ),
                        setValue,
                        handleSearchAdminOrg,
                        handleSearchNetworkAdminOrg,
                        handleSearchAdmins,
                        handleSearchNetworkAdmins,
                        userList,
                        adminSelected,
                        networkAdminAll,
                        setNetworkAdminAll,
                        networkAdminSelected,
                        userRoleOptions,
                        multipleNetworkAdmins,
                        setMultipleNetworkAdmins,
                        adminOrgUser,
                        networkAdminOrgUser,
                        adminPermissions,
                        networkAdminPermissions,
                        orgList,
                        allPermissionRoles,
                        handleFetchUserRoles,
                        handleFetchMultipleUserRoles,
                        handleFetchUsersForMultipleNA,
                        organizationListing,
                        handleFetchAndCatchUsers,
                        handleCheckboxChange,
                        handleMultipleNaCheckboxChange,
                        handleSelectAllPermission,
                        handleMultipleSelectAllPermission,
                        handleSearchOrgMultipleNa,
                        handleSearchUsersMultipleNa,
                      }}
                    />
                  ) : (
                    <SAFormData
                      otherFormProps={{
                        isEdit: id ? true : false,
                        dispatch,
                        deviceTypeEntered,
                        deviceModelInfo,
                        themeType,
                        handleSubmit,
                        handleCreateDevice,
                        handleUpdateDevice,
                        handleCancelButton,
                        allPorts,
                        addMoreCheck,
                        setAddMoreCheck,
                        allDeviceModels,
                        operatorOptions,
                        allDeviceCategoryNames,
                        handleSearchDeviceModels,
                        handleSearchDeviceType,
                        handleSearchPortGroup,
                      }}
                      adminSectionProps={{
                        errors,
                        showAll,
                        setShowAll,
                        control,
                        getValues,
                        reset,
                        loading: loading?.userListLoading,
                        selectStylesOverride: selectStylesOverride(
                          isDarkThemeEnabledSelector
                        ),
                        themeType,
                        editableNAPermissions,
                        setValue,
                        userList,
                        deviceDetailsTemplate,
                        searchFields,
                        setSearchFields,
                        adminSelected,
                        showResetSearchNA,
                        setShowResetSearchNA,
                        networkAdminsDropdownVal,
                        openUpdateNetworkAdmins,
                        alldevicePermissions,
                        networkAdminSelected,
                        userRoleOptions,
                        adminOrgUser,
                        networkAdminOrgUser,
                        adminPermissions,
                        networkAdminPermissions,
                        orgList,
                        allPermissionRoles,
                        networkAdminAll,
                        setNetworkAdminAll,
                        handleFetchUserRoles,
                        handleFetchMultipleUserRoles,
                        handleFetchUsersForMultipleNA,
                        multipleNetworkAdmins,
                        setMultipleNetworkAdmins,
                        organizationListing,
                        handleFetchAndCatchUsers,
                        handleCheckboxChange,
                        handleMultipleNaCheckboxChange,
                        handleSelectAllPermission,
                        handleMultipleSelectAllPermission,
                        handleSearchOrgMultipleNa,
                        handleSearchUsersMultipleNa,
                        handleSearchAdminOrg,
                        handleSearchNetworkAdminOrg,
                        handleSearchAdmins,
                        handleSearchNetworkAdmins,
                      }}
                    />
                  )}
                </>
              )}
            {currentUserDetail &&
              !currentUserDetail?.adminSpecificPermissions && (
                <>
                  {/* for sub user and admins ---------- */}
                  <SUForm
                    selectStylesOverride={selectStylesOverride(
                      isDarkThemeEnabledSelector
                    )}
                    deviceData={deviceData}
                    handleFetchDeviceDetails={handleFetchDeviceDetails}
                    themeType={themeType}
                    searchInput={searchInput}
                    setSearchInput={setSearchInput}
                    allocateDeviceLoading={allocateDeviceLoading}
                    handleAllocateDeviceToUser={handleAllocateDeviceToUser}
                    searchValue={searchValue}
                    setSearchValue={setSearchValue}
                  />
                </>
              )}
          </Grid>
        )}
      </Grid>
      <NetworkAdminsUpdate
        open={showNetworkPopup}
        handleClose={() => {
          setShowNetworkPopup(false);
          setEditableNAPermissions([...deviceDetailsTemplate["networkAdmins"]]);
        }}
        loading={loading?.initialLoading}
        submitAction={handleUpdateNetworkAdminsACL}
        removeAction={handleRemoveNetworkAdmin}
        alldevicePermissions={alldevicePermissions}
        themeType={themeType}
        networkTableColumns={networkTableColumns}
        tableData={editableNAPermissions}
        selectStylesOverride={selectStylesOverride}
      />
    </>
  );
};

export default AddDeviceIndex;
