import React, { useEffect, useMemo, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button, Grid, Typography, IconButton, Box } from "@mui/material";
import CustomModel from "../../../Components/CustomModels/CustomModel1";
import { convertIntoGivenTimezone, showToast } from "../../../../services/functions";
import { ToastContainer } from "react-toastify";
import { fetchAddressFromCoords } from "../../../../redux/actions";
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import DownloadIcon from '@mui/icons-material/Download';
import DataTable from "../../../Components/DataTable/DataTable"
import Iframe from 'react-iframe'
import SlideshowIcon from '@mui/icons-material/Slideshow';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { baseURL } from "../../../../services/config";

function EvidenceModal(props) {
    const dispatch = useDispatch();
    const { open, hide, eventObject, userDetails, deviceDetails } = props;
    const [iframeUrl, setIFrameUrl] = useState(null)
    const [imgUrl, setImgUrl] = useState(null)
    const [eventListing, setEventListing] = useState({})
    const [activeIndex, setActiveIndex] = useState(null)
    const { currentUserDetail } = useSelector((_) => _?.auth);
    const isDarkThemeEnabledSelector = useSelector(
        (state) => state.theme.themeDark
    );
    const themeType = isDarkThemeEnabledSelector
        ? require("../../../../static/styles/darktheme")
        : require("../../../../static/styles/theme");

    const { buttonClasses, textClasses, tableClasses } = themeType;

    useEffect(() => {
        if (eventObject && Object.keys(eventObject).length) {
            setEventListing(eventObject)
            setIFrameUrl(null);
            setImgUrl(null)
            setActiveIndex(null);
        }
    }, [eventObject])

    const tableData = useMemo(() => {
        if (eventListing && Object.keys(eventListing).length) {
            let temp = JSON.parse(JSON.stringify(eventListing));
            const imgs = temp?.evidenceId?.images.map((item, index) => ({ name: `Image-${index + 1}`, path: item, deviceId: temp?.deviceId, createdAt: temp?.createdAt }));
            const vid = [{ name: "Video", path: eventListing?.evidenceId?.video, deviceId: temp?.deviceId, createdAt: temp?.createdAt }]
            return [...imgs, ...vid];
        } else {
            return []
        }

    }, [eventListing])


    const getAddressFromCoords = () => {
        let temp = { ...eventListing };
        const payload = {
            latitude: eventListing.latitude,
            longitude: eventListing.longitude,
            appType: "GOOGLE",
        };
        dispatch(
            fetchAddressFromCoords(payload, (res) => {
                if (res && res?.length > 0) {
                    const { address } = res[0];
                    if (temp) {
                        temp = { ...(temp), address: address };
                    }
                    setEventListing(temp)
                }
            })
        );
    }

    const tableColumns = [
        {
            header: "File Type",
            cell: (entry, key) => {
                return (
                    <Typography
                        sx={{
                            ...textClasses.t13n,
                            textAlign: "center",
                            textTransform: "capitalize",
                        }}
                    >
                        {entry?.name ?? "-"}
                    </Typography>
                );
            },
        },
        {
            header: "Actions",
            cell: (entry, key) => {
                return (
                    <Typography
                        data-testid="action-menu-btn"
                        sx={{
                            ...textClasses.normalText,
                            color: themeType.default.themeOrange,
                        }}
                    >
                        <Box display="flex" justifyContent="center" >
                            {entry.name === 'Video' ?
                                <IconButton size="small"
                                    onClick={() => {
                                        let date = entry.createdAt.slice(0, 10).replace(/-/g, '')
                                        let res = `${baseURL}/getCameraEvidence/${date}/${entry.deviceId}/${entry.path}`
                                        const sourceUrl = `${baseURL}/getCameraEvidence/${date}/${entry.deviceId}/${entry.path}`;
                                        console.log('sourceUrl',sourceUrl)
                                        setIFrameUrl(sourceUrl);
                                        setActiveIndex(key)
                                        setImgUrl(null)
                                    }}>
                                    <PlayArrowIcon />
                                </IconButton>
                                :

                                <VisibilityIcon size="small" sx={{ cursor: 'pointer', color: '#696969', paddingTop: '5px', position: 'relative', top: '4px' }}
                                    onClick={() => {
                                        let date = entry.createdAt.slice(0, 10).replace(/-/g, '')
                                      
                                        const sourceUrl = `${baseURL}/getCameraEvidence/${date}/${entry.deviceId}/${entry.path}`;
                                        setImgUrl(sourceUrl);
                                        setActiveIndex(key)
                                        setIFrameUrl(null);
                                    }}>
                                </VisibilityIcon>
                            }
                            <IconButton
                                size="small"
                                onClick={() => {
                                    let date = entry.createdAt.slice(0, 10).replace(/-/g, '')
                                    const sourceUrl = `${baseURL}/getCameraEvidence/${date}/${entry.deviceId}/${entry.path}`;
                                    handleDownload(sourceUrl, entry);
                                }}
                            >
                                <DownloadIcon />
                            </IconButton>
                        </Box>
                    </Typography>
                );
            },
        },
    ];


    const handleDownload = async (url,entry) => {
        try {
            const response = await fetch(url);
            if (!response.ok) throw new Error('Network response was not ok');
            const blob = await response.blob();
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = entry.path;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(link.href);
        } catch (error) {
            console.error('There has been a problem with your fetch operation:', error);
        }
    };


    return (
        <>
            <CustomModel
                autoClose={false}
                show={open || false}
                onClose={() => hide()}
                minWidth={880}
                maxWidth={880}
                pb={3}
                pl={3}
                pr={3}
                maxHeight={"80vh"}
                childrenMaxHeight={"80vh"}
                Title="Evidence Details"
                viewCloseIcon={open}
            >
                <Grid container mb={2}>
                    <Grid
                        item
                        sm={12}
                        flexDirection={"column"}
                        mt={2}
                        sx={{ width: "100%", height: "70%", paddingTop: '14px', }}
                    >

                        <Grid container spacing={0} sx={{ border: isDarkThemeEnabledSelector ? '1px solid rgba(255, 255, 255, 0.22)' : '1px solid #D9D9D9', borderRadius: '9px', minHeight: '60px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <Grid item xs={3} sx={{ paddingLeft: '3px' }}>
                                <Typography sx={{ ...textClasses.t14n, fontWeight: '600' }}>Device ID :</Typography>
                                <Typography sx={{ fontSize: '15px' }}>{eventListing?.deviceId || '-'}</Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography sx={{ ...textClasses.t14n, fontWeight: '600' }}>Alert Name :</Typography>
                                <Typography>
                                    <Typography sx={{ fontSize: '15px' }}>{eventListing?.alertType?.toLowerCase().replace(/_/g, ' ').replace(/\b\w/g, (char) => char.toUpperCase())}</Typography>
                                    {/* {alarmData.filter(elem => elem.code == eventListing?.alarmType).map(filterData => (
                                        <Typography sx={{ fontSize: '15px' }}>{filterData.type}</Typography>
                                    ))} */}
                                </Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography sx={{ ...textClasses.t14n, fontWeight: '600' }}>Alert Time :</Typography>
                                <Typography sx={{ fontSize: '15px' }}> {convertIntoGivenTimezone(eventListing?.createdAt, userDetails?.timeZone.offset) || "-"}</Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography sx={{ ...textClasses.t14n, fontWeight: '600' }}>Location :</Typography>
                                {eventListing?.address ?
                                    <Typography sx={{ fontSize: '14px' }}>{eventListing?.address}</Typography>
                                    :
                                    <Typography onClick={() => getAddressFromCoords()} sx={{ fontSize: '15px', cursor: 'pointer' }}>{eventListing?.latitude} , {eventListing?.longitude}</Typography>
                                }
                            </Grid>
                        </Grid>

                        <Grid container spacing={0} mt={3}>
                            <Grid item xs={5} sx={{ overflow: 'auto', maxHeight: '40vh' }}>

                                <DataTable
                                    tableColumns={tableColumns}
                                    data={tableData || []}
                                    activeIndex={activeIndex}
                                    themeType={themeType}
                                />

                            </Grid>

                            <Grid item xs={6.8} sx={{ marginLeft: '8px' }}>

                                {iframeUrl ? (
                                    <video className='iframe-camera' controls>
                                        <source src={iframeUrl} type="video/mp4" />
                                    </video>
                                ) : imgUrl ? (
                                    <img className='iframe-camera' src={imgUrl} alt='' />
                                ) : (
                                    <>
                                        <Grid item container direction="column" justifyContent="center" alignItems="center" xs={12} sx={{ height: '100%', border: '1px solid #E8E8E8' }}>
                                            <SlideshowIcon sx={{ fontSize: '5rem ! important', color: '#ff7200' }} />
                                            <Typography>Select file from the table .</Typography>
                                        </Grid>
                                    </>
                                )}


                            </Grid>




                        </Grid>


                    </Grid>
                </Grid>

            </CustomModel>
            <ToastContainer
                position="bottom-center"
                autoClose={false}
                closeOnClick={false}
            />
        </>
    );
}

export default EvidenceModal;


let alarmData = [
    {
        "code": "126",
        "type": "No Driver"
    },
    {
        "code": "132",
        "type": "Driver authentication failed"
    },
    {
        "code": "121",
        "type": "Eyes closing"
    },
    {
        "code": "1",
        "type": "Video Lost"
    },
    {
        "code": "31",
        "type": "Acc on"
    },
    {
        "code": "0",
        "type": "UnKnown"
    },
    {
        "code": "2",
        "type": "Motion detection"
    },
    {
        "code": "3",
        "type": "Video overshadowed"
    },
    {
        "code": "4",
        "type": "Alarm input triggered"
    },
    {
        "code": "5",
        "type": "Alarm of emergency"
    },
    {
        "code": "6",
        "type": "Alarm of low speed"
    },
    {
        "code": "7",
        "type": "Alarm of high speed"
    },
    {
        "code": "8",
        "type": "Alarm of low temperature"
    },
    {
        "code": "9",
        "type": "Alarm of high temperature"
    },
    {
        "code": "10",
        "type": "Alarm of humidity"
    },
    {
        "code": "11",
        "type": "Park Overtime"
    },
    {
        "code": "12",
        "type": "Alarm of vibratin"
    },
    {
        "code": "13",
        "type": "Electronic fence"
    },
    {
        "code": "14",
        "type": "Electronic route"
    },
    {
        "code": "15",
        "type": "Alarm of open or close door"
    },
    {
        "code": "16",
        "type": "Storage abnormal"
    },
    {
        "code": "17",
        "type": "Fatigue driving"
    },
    {
        "code": "18",
        "type": "Exceptional volume of gas"
    },
    {
        "code": "19",
        "type": "Illegal ignition"
    },
    {
        "code": "20",
        "type": "Location module abnormal"
    },
    {
        "code": "21",
        "type": "Front panel open"
    },
    {
        "code": "22",
        "type": "RFID tagged"
    },
    {
        "code": "23",
        "type": "IBUTTON"
    },
    {
        "code": "24",
        "type": "Rapid acceleration"
    },
    {
        "code": "25",
        "type": "Rapid deceleration"
    },
    {
        "code": "26",
        "type": "Low speed pre-alarm"
    },
    {
        "code": "27",
        "type": "High speed pre-alarm"
    },
    {
        "code": "28",
        "type": "Voltage Alarm"
    },
    {
        "code": "29",
        "type": "Population statistics"
    },
    {
        "code": "30",
        "type": "DMS/ADAS alarm (Active safety alarm)"
    },
    {
        "code": "31",
        "type": "Acc on"
    },
    {
        "code": "32",
        "type": "Idle speed"
    },
    {
        "code": "33",
        "type": "Gps antenna disconnected"
    },
    {
        "code": "34",
        "type": "Gps antenna short circuit"
    },
    {
        "code": "35",
        "type": "IO output"
    },
    {
        "code": "36",
        "type": "CANBus connection abnormal"
    },
    {
        "code": "37",
        "type": "Towing alarm"
    },
    {
        "code": "38",
        "type": "Glide in neutral"
    },
    {
        "code": "39",
        "type": "RPM too high 40 vehicle move"
    },
    {
        "code": "40",
        "type": "vehicle move"
    },
    {
        "code": "41",
        "type": "Trip start (st/et/dtu time is the same)"
    },
    {
        "code": "42",
        "type": "In trip"
    },
    {
        "code": "43",
        "type": "End of the trip (reported periodically after acc off)"
    },
    {
        "code": "44",
        "type": "GPS location recovery"
    },
    {
        "code": "45",
        "type": "Abnormal video"
    },
    {
        "code": "768",
        "type": "End of trip notification"
    },
    {
        "code": "769",
        "type": "Upgrade notfication"
    },
    {
        "code": "101",
        "type": "Forward collision warning"
    },
    {
        "code": "102",
        "type": "Lane departure warning"
    },
    {
        "code": "103",
        "type": "Front vehicle distance is too close warning"
    },
    {
        "code": "104",
        "type": "Pedestrian collision warning Howen VSS Web API"
    },
    {
        "code": "105",
        "type": "Frequent lane change alarm"
    },
    {
        "code": "106",
        "type": "Road marking violation alarm"
    },
    {
        "code": "107",
        "type": "Harsh acceleration"
    },
    {
        "code": "108",
        "type": "Harsh breaking"
    },
    {
        "code": "109",
        "type": "Road sign recognition incident"
    },
    {
        "code": "110",
        "type": "FCW Forward relative velocity collision"
    },
    {
        "code": "111",
        "type": "HMW Forward absolute velocity collision"
    },
    {
        "code": "112",
        "type": "LDW_L Left lane departure"
    },
    {
        "code": "113",
        "type": "LDW_R Right lane departure"
    },
    {
        "code": "114",
        "type": "VB Low-speed forward collision alarm"
    },
    {
        "code": "115",
        "type": "Fatigue driving alarm"
    },
    {
        "code": "116",
        "type": "Calling Alarm"
    },
    {
        "code": "117",
        "type": "Smoking alarm"
    },
    {
        "code": "118",
        "type": "Distracted driving alarm"
    },
    {
        "code": "119",
        "type": "Driver abnormal alarm"
    },
    {
        "code": "120",
        "type": "Driver change event"
    },
    {
        "code": "122",
        "type": "Yawning"
    },
    {
        "code": "123",
        "type": "Camera cover alarm"
    },
    {
        "code": "124",
        "type": "Glance right and left"
    },
    {
        "code": "125",
        "type": "Not wearing a seat belt"
    },
    {
        "code": "126",
        "type": "No driver"
    },
    {
        "code": "127",
        "type": "Drinking water"
    },
    {
        "code": "128",
        "type": "Driver changing"
    },
    {
        "code": "129",
        "type": "Driver returns"
    },
    {
        "code": "130",
        "type": "Infrared sunglasses"
    },
    {
        "code": "131",
        "type": "Driver authentication succeeded"
    },
    {
        "code": "133",
        "type": "No face detected"
    },
    {
        "code": "200",
        "type": "Infrared sunglasses"
    },
    {
        "code": "201",
        "type": "Leave the fence"
    },
    {
        "code": "202",
        "type": "Overspeed alarm in fence"
    },
    {
        "code": "203",
        "type": "Overspeed warning inside the fence"
    },
    {
        "code": "204",
        "type": "Low speed alarm in fence"
    },
    {
        "code": "205",
        "type": "Low speed warning inside the fence"
    },
    {
        "code": "207",
        "type": "Engine on in fence"
    },
    {
        "code": "208",
        "type": "Engine off in fence"
    },
    {
        "code": "209",
        "type": "Timeout inside the fence"
    },
    {
        "code": "210",
        "type": "Refuel"
    },
    {
        "code": "211",
        "type": "Fuel Theft"
    },
    {
        "code": "212",
        "type": "Rapid acceleration (gsebsor)"
    },
    {
        "code": "213",
        "type": "Rapid deceleration (gsebsor)"
    },
    {
        "code": "214",
        "type": "Sharp turn (gsebsor)"
    },
    {
        "code": "215",
        "type": "entry fence warning"
    },
    {
        "code": "216",
        "type": "Leave the fence warning"
    },
    {
        "code": "10001",
        "type": "Insufficient disk space on server"
    },
    {
        "code": "11001",
        "type": "Excessive device traffic"
    },
    {
        "code": "11003",
        "type": "The device is offline for a long time"
    },
    {
        "code": "121",
        "type": "Eyes closed"
    },
    {
        "code": "123",
        "type": "DMS Camera Covered"
    },
    {
        "code": "122",
        "type": "Yawning"
    },
    {
        "code": "117",
        "type": "Smoking alarm"
    },
    {
        "code": "124",
        "type": "Distracted Driving"
    },
    {
        "code": "116",
        "type": "Phone call alarm"
    },
    {
        "code": "132",
        "type": "Driver authentication failed"
    },
    {
        "code": "118",
        "type": "Distracted driving alarm"
    },
    {
        "code": "119",
        "type": "Driver abnormal alarm"
    },
    {
        "code": "120",
        "type": "Driver change event"
    },
    {
        "code": "125",
        "type": "Not wearing a seat belt"
    },
    {
        "code": "115",
        "type": "Fatigue driving alarm"
    },
]
