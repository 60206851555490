import React from 'react'

const MonitoringIcon = ({height, width, fill}) => (
    <svg id="Layer_1" height={height} width={width} data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 257.08 231.92">
  <defs>
    <style>{`
      .cls-monitoring-1 {
        stroke-width: 0px;
        fill: ${fill} !important;
      }
    `}</style>
  </defs>
  <path class="cls-monitoring-1" d="m257.08,5.04v185.42c-1.39,3.93-3.05,5.25-6.58,5.25-81.31,0-162.63,0-243.94.04C3.31,195.75,0,193.44,0,189.2.01,128.32.01,67.43,0,6.54c0-.88.12-1.73.42-2.56C1.4,1.32,3.48,0,6.75,0,35.94,0,65.14,0,94.33,0c51.88,0,103.77,0,155.65,0,.86,0,1.73-.06,2.59.18,2.51.7,3.77,2.53,4.5,4.85Zm-128.54,150.97c38.32,0,72.42,0,110.74.02,1.09,0,1.46-.18,1.46-1.39-.04-47.04-.04-81.45,0-128.49,0-1.19-.34-1.39-1.44-1.38-76.63.03-144.84.03-221.48,0-1.24,0-1.46.33-1.45,1.49.03,46.96.03,81.29,0,128.25,0,1.27.36,1.53,1.56,1.52,38.28-.03,72.34-.03,110.62-.03Z"/>
  <path class="cls-monitoring-1" d="m128.54,231.9c-18.07,0-36.14-.02-54.21.02-2.24,0-4.12-.94-3.75-2.5.09-.37.37-.68.69-.97,8.99-8.48,17.99-16.95,26.97-25.43.78-.74,1.79-1.08,3.2-1.08,18.07.01,36.14.01,54.21,0,1.42,0,2.42.34,3.2,1.08,8.98,8.48,17.97,16.96,26.97,25.43.81.76,1,1.54.35,2.35-.67.82-1.83,1.11-3.29,1.11-18.11-.01-36.23,0-54.34,0Z"/>
  <path class="cls-monitoring-1" d="m161.64,93.02c0-12.92.01-25.84-.02-38.76,0-.96.13-1.33,1.23-1.32,8.09.06,16.18.05,24.27,0,1.01,0,1.18.31,1.18,1.23-.02,25.92-.03,51.84,0,77.77,0,1.02-.24,1.29-1.28,1.29-8.01-.05-16.02-.06-24.02,0-1.2,0-1.39-.38-1.39-1.45.03-12.92.02-25.84.02-38.76Z"/>
  <path class="cls-monitoring-1" d="m221.72,106.46c0,8.46-.02,16.92.02,25.38,0,1.02-.14,1.39-1.31,1.38-8.05-.06-16.1-.05-24.14-.01-.97,0-1.24-.25-1.23-1.23.03-17.05.03-34.09,0-51.14,0-.91.2-1.16,1.14-1.16,8.13.04,16.26.04,24.39,0,.91,0,1.16.19,1.16,1.13-.04,8.54-.02,17.09-.02,25.63Z"/>
  <path class="cls-monitoring-1" d="m154.83,113.17c0,6.31-.02,12.63.02,18.94,0,.85-.19,1.11-1.08,1.1-8.17-.04-16.34-.04-24.51,0-.89,0-1.09-.28-1.09-1.12.02-12.67.03-25.34,0-38.01,0-.84.26-1.01,1.04-1,8.21.03,16.43.03,24.64,0,.83,0,1.01.24,1.01,1.03-.03,6.36-.02,12.71-.02,19.07Z"/>
  <g>
    <path class="cls-monitoring-1" d="m73.21,69.04c0,6.65.04,13.29-.03,19.94-.01,1.25.37,1.45,1.51,1.44,13.34-.04,26.67-.02,40.01-.05,1.02,0,1.42.14,1.36,1.31-.63,13.12-5.98,23.86-16.15,32.16-5.9,4.81-12.69,7.74-20.19,8.85-21.68,3.19-41.91-10.09-47.66-31-6.47-23.51,7.96-47.51,31.79-52.98,2.74-.63,5.51-.94,8.31-1.05.9-.03,1.08.21,1.08,1.06-.03,6.77-.02,13.54-.02,20.31Z"/>
    <path class="cls-monitoring-1" d="m97.78,83.44c-5.45,0-10.9-.03-16.35.03-.96,0-1.13-.27-1.13-1.16.03-10.9.04-21.79,0-32.69,0-1.16.39-1.28,1.33-1.05,17.74,4.25,28.86,15.21,33.36,32.88.51,1.99.46,2.01-1.61,2.01-5.2,0-10.4,0-15.6,0Z"/>
  </g>
</svg>
)

export default MonitoringIcon