import { RestartAlt, Search } from "@mui/icons-material";
import {
    Autocomplete,
    Box,
    Checkbox,
    Divider,
    FormControlLabel,
    IconButton,
    MenuItem,
    Paper,
    TextField,
    Tooltip,
    Typography,
} from "@mui/material";
import React from "react";

const CustomMultiSelectWithLimit = ({
    name,
    label,
    options = [],
    value,
    checkName,
    extraClasses,
    required = false,
    onChange,
    disabled,
    allSelected,
    inputValue,
    renderOptions,
    errors = {},
    selectStylesOverride,
    getOptionLabel,
    resetSearch = false,
    searchValue = null,
    onSearch = () => { },
    onResetSearch = () => { },
    labelKey = "label",
    ExtraCheckbox = () => { },
    defaultValue,
    limitTags,
    handleToggleSelectAll,
    ...props
}) => {
    return (
        <Box display="flex" flexDirection="column" sx={{ height: "max-content" }}>
            <Autocomplete
                sx={{
                    "& .MuiInputLabel-root": {
                        fontSize: "0.8rem",
                    },
                    "& .MuiInputBase-root": {
                        fontSize: "0.8rem",
                    }
                }}
                ListboxProps={
                    {
                        style: {
                            maxHeight: '150px',
                            overflow: 'scroll',
                        },
                    }
                }
                multiple
                limitTags={limitTags}
                id="multiple-limit-tags"
                options={options}
                filterOptions={(options) => options}
                getOptionLabel={getOptionLabel}
                defaultValue={defaultValue}
                inputValue={inputValue || ''}
                renderInput={params => {
                    const { InputProps, ...restParams } = params;
                    const { startAdornment, ...restInputProps } = InputProps;
                    return(
                    <TextField
                        sx={{
                            fontSize: "12px",
                            ...selectStylesOverride,
                            ...extraClasses,
                        }}
                        placeholder={label}
                        {...params}
                        label={
                            label ? (
                                <Typography sx={{ fontSize: "12px" }}>
                                    {label}
                                    {required && "*"}
                                </Typography>
                            ) : null
                        }
                        InputProps={{
                            ...params.InputProps,
                            startAdornment: (
                                    <div style={{
                                        maxHeight: '200px',
                                        overflowY: 'auto',
                                    }}
                                    >
                                        {startAdornment}
                                    </div>
                                ),
                            endAdornment: (
                                <React.Fragment>
                                    {searchValue && searchValue !== null ? (
                                        <Tooltip placement="top" title="Search">
                                            <IconButton
                                                size="small"
                                                color=""
                                                onClick={onSearch ? () => onSearch() : null}
                                            >
                                                <Search fontSize="12px" />
                                            </IconButton>
                                        </Tooltip>
                                    ) : null}
                                    {resetSearch ? (
                                        <Tooltip placement="top" title="Reset Search">
                                            <IconButton
                                                size="small"
                                                color=""
                                                onClick={onResetSearch ? () => onResetSearch() : null}
                                            >
                                                <RestartAlt fontSize="12px" />
                                            </IconButton>
                                        </Tooltip>
                                    ) : null}
                                    {params.InputProps.endAdornment}
                                </React.Fragment>
                            ),
                        }}
                    />
                )}}
                

                onChange={onChange}
                disabled={disabled}
                {...props}
                value={value}
                size="small"
                disableCloseOnSelect={true}
                error={errors[name] ? true : false}
                renderOption={renderOptions ? renderOptions : (props, option, { selected }) => {
                    return (
                        <MenuItem key={option[labelKey]} value={value || ""} selected={selected} {...props}>
                            <Typography
                                sx={{
                                    fontSize: ".8rem",
                                    overflow: "hidden",
                                    whiteSpace: "nowrap",
                                    textOverflow: "ellipsis",
                                }}
                            >
                                {option[labelKey] || ""}
                            </Typography>
                        </MenuItem>
                    );
                }}
                PaperComponent={
                    (paperProps) => {
                        const { children, ...restPaperProps } = paperProps;
                        return (
                            <Paper {...restPaperProps}>
                                <Box
                                    onMouseDown={(e) => e.preventDefault()} // prevent blur
                                    pl={1.5}
                                    py={0.5}
                                    display="flex"
                                    gap={2}
                                >
                                    <FormControlLabel
                                        sx={{
                                            color: "rgb(128, 128, 128)",
                                            "& .MuiFormControlLabel-label": { fontSize: "13px" },
                                        }}
                                        label="Select all"
                                        control={
                                            <Checkbox
                                                id="select-all-checkbox"
                                                sx={{
                                                    color: "#ff7200",
                                                    "&.Mui-checked": {
                                                        color: "#ff7200",
                                                    },
                                                }}
                                                checked={checkName}
                                                onClick={(e) => {
                                                    e.preventDefault(); // prevent blur
                                                    handleToggleSelectAll(e, label);
                                                }}
                                            />
                                        }
                                    />

                                </Box>
                                <Divider />
                                {children}
                            </Paper>
                        );
                    }

                }


            />
            {errors[name] && (
                <span role="alert" style={{ color: "#FF0101", fontSize: 11 }}>
                    {errors[name]?.message}
                </span>
            )}
        </Box>
    );
};

export default CustomMultiSelectWithLimit;

