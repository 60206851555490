import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useDispatch, useSelector } from "react-redux";
import {
  Grid,
  Typography,
  Select,
  MenuItem,
  Box,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  CardMedia,
  Avatar,
  Divider,
  Paper,
  Tabs,
  Tab,
  Breadcrumbs,
  FormControl,
  Button,
} from "@mui/material";

import theme, { textClasses } from "../../../static/styles/theme";
import { selectStylesOverride } from "../../../utils/util";
import SelectTemplate from "./SelectTemplate";
import ComponentCanvas from "./ComponentCanvas";
import StandardComponents from "./StandardComponents";
import PreviousUsedComponents from "./PreviousUsedComponents";

import {
  getPreviousComonents,
  getStandardComonents,
  initCanvas,
  saveTemplate,
  selectTemplate,
  getTemplateTypes,
} from "../../../redux/actions";
import { templateTypes } from "../../../services/constants";
import history from "../../../services/history";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 2 }}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function CreateTemplate() {
  const dispatch = useDispatch();
  let usedComponents = useSelector((state) => state.templates.usedComponents);
  const isDarkThemeEnabledSelector = useSelector(
    (state) => state.theme.themeDark
  );
  const themeType = isDarkThemeEnabledSelector
    ? require("../../../static/styles/darktheme")
    : require("../../../static/styles/theme");
  // usedComponents = null;

  const {buttonClasses} = themeType;

  const stdComponents = useSelector(
    (state) => state.templates.standardComponents
  );
  const components = useSelector((state) => state.canvas.components);
  const selectedTemplate = useSelector(
    (state) => state.canvas.selectedTemplate
  );
  const selectedSubTemplate = useSelector(
    (state) => state.canvas.selectedSubTemplate
  );
  const allTemplateTypes = useSelector(
    (state) => state.templates.templateTypes
  );
  const counterElements = useSelector(
    (state) => state.templates.countElements
  );

  const [tabIndex, setTabIndex] = useState(0);
  const [sectionStep, setSectionStep] = useState(1);
  const [selectModal, setSelectModal] = useState(false);

  const previousExists = usedComponents && usedComponents.length > 0;

  const handleOpenModal = () => setSelectModal(true);
  const handleCloseModal = () => setSelectModal(false);

  const handleTemplateSelect = (templateType, subTemplateType) => {
    dispatch(selectTemplate(templateType, subTemplateType));
    setSectionStep(sectionStep + 1);
  };

  const handleTabChange = (event, value) => {
    setTabIndex(value);
  };

  const handleTemplateChange = (event) => {
    let tType;
    let sTType;
    if (event.target.value == "-") {
      dispatch(selectTemplate("-", "-"));
      setSectionStep(1);
      return;
    }
    for (let i = 0; i < allTemplateTypes.length; i++) {
      const t = allTemplateTypes[i];
      for (let j = 0; j < t.allTemplateTypes.length; j++) {
        const st = t.allTemplateTypes[j];
        if (st.templateTypekey == event.target.value) {
          tType = t.templateCategorykey;
          sTType = st.templateTypekey;
          dispatch(selectTemplate(tType, sTType));
          setSectionStep(2);
          return;
        }
      }
    }
  };

  useEffect(() => {
    dispatch(initCanvas());
    dispatch(getStandardComonents({ type: "wms" }));
    // dispatch(
    //     getPreviousComonents({type: "wms"})
    // )
    dispatch(getTemplateTypes());
    dispatch(selectTemplate("-", "-"));
  }, []);

  const getSectionStep = () => {
    if (sectionStep == 1) {
      return (
        <SelectTemplate
          showModal={selectModal}
          handleTemplateSelect={handleTemplateSelect}
          handleOpenModal={handleOpenModal}
          handleCloseModal={handleCloseModal}
        />
      );
    } else if (sectionStep == 2) {
      return (
        <ComponentCanvas
          components={components}
          setComponents={null}
          handleSaveTemplate={handleSaveTemplate}
        />
      );
    }
    return <div>Coming Soon..</div>;
  };

  const getTabStyles = (tIndex) => {
    return {
      backgroundColor:
        tabIndex == tIndex ? themeType.default.themeOrange : "white",
      color: tabIndex == tIndex ? "white" : themeType.default.themeOrange,
      border: "1px solid",
      boxShadow: "0px 2px 6px gray",
      borderColor: themeType.default.themeOrange,
      p: 0,
      pt: "10px",
      pb: "10px",
      fontSize: "14px",
      lineHeight: "19px",
      fontWeight: "400",
      textTransform: "none",
    };
  };

  const handleSaveTemplate = (data, callback) => {
    const payload = {
      name: data.name,
      templateType: selectedSubTemplate,
      templateCategory: selectedTemplate,
      status: data.status,
      description: data.description,
      countElements: counterElements,
      orgId: data.orgId,
      attributes: [
        ...components.attributes.other.filter(
          (f) => f.templateType == selectedSubTemplate
        ),
      ],
    };
    dispatch(saveTemplate(payload, callback));
  };

  return (
    <>
      <DndProvider backend={HTML5Backend}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box>
            <Typography sx={{ ...textClasses.cardTitle }}>
              Create Templates
            </Typography>
            <Breadcrumbs sx={{ mb: 1 }}>
              <Typography
                sx={{
                  ...textClasses.normalText,
                  fontSize: "12px",
                  color: themeType.default.themeOrange,
                }}
              >
                <Link
                  style={{
                    color: themeType.default.themeOrange,
                    textDecoration: "none",
                  }}
                  to="/dashboard/manage-templates"
                >
                  Manage Templates
                </Link>
              </Typography>

              <Typography data-testid="createTemplate" sx={{ ...textClasses.normalText, fontSize: "12px" }}>
                Create Template
              </Typography>
            </Breadcrumbs>
          </Box>
          {/* <Button sx={{...buttonClasses.lynkitBlackFill}} onClick={() => history.goBack()}>Back</Button> */}
        </Box>
        <Grid container spacing={2}>
          <Grid item sm={8} xs={10}>
            <Card sx={{ borderRadius: "8px" }}>
              <CardHeader
                sx={{
                  pt: 1,
                  pl: 2,
                  pb: 1,
                  borderRadius: "8px 8px 0px 0px",
                  backgroundColor: themeType.default.themeOrange,
                  color: "white",
                  boxShadow: "0px 2px 6px gray",
                }}
                action={
                  // sectionStep > 1 &&
                  <FormControl
                    fullWidth
                    size="small"
                    variant="outlined"
                    sx={{ ...selectStylesOverride(isDarkThemeEnabledSelector) }}
                  >
                    <Select
                      sx={{
                        backgroundColor: themeType.default.themeGray,
                        color: themeType.default.themeOrange,
                        minWidth: "200px",
                      }}
                      size="small"
                      data-testid="dropdown"
                      labelId="demo-select-small-label"
                      id="demo-select-small"
                      value={selectedSubTemplate}
                      onChange={handleTemplateChange}
                    >
                      <MenuItem value={"-"}>Select Template Type</MenuItem>
                      {allTemplateTypes.map((item, i) => {
                        if (item.allTemplateTypes.length > 1) {
                          let menus = item.allTemplateTypes.map((s, j) => {
                            return (
                              <MenuItem
                                value={s.templateTypekey}
                                key={`${i}-${j}`}
                              >
                                {item.templateCategoryLabel} -{" "}
                                {s.templateTypeLabel}
                              </MenuItem>
                            );
                          });
                          return menus;
                        } else {
                          return (
                            <MenuItem
                              value={item.allTemplateTypes[0].templateTypekey}
                              key={i}
                            >
                              {item.allTemplateTypes[0].templateTypeLabel}
                            </MenuItem>
                          );
                        }
                      })}
                    </Select>
                  </FormControl>
                }
                title={<Typography data-testid='drops' variant="h6">Drop Section</Typography>}
              />
              <CardContent
                sx={{
                  zIndex: 100,
                  mr: 2,
                  overflowY: "scroll",
                  maxHeight: "450px",
                  borderRadius: "8px",
                }}
              >
                <Grid container sx={{ mb: 1 }}>
                  <Grid
                    container
                    item
                    sm={2}
                    direction="column"
                    alignItems="center"
                    justifyContent={"center"}
                  >
                    <Avatar
                      sx={{
                        width: 32,
                        height: 32,
                        backgroundColor:
                          sectionStep == 1
                            ? themeType.default.themeOrange
                            : null,
                      }}
                    >
                      1
                    </Avatar>
                    <Typography
                      data-testid="choose"
                      variant="caption"
                      sx={{ mt: 1, fontSize: "12px" }}
                    >
                      {" "}
                      choose template type
                    </Typography>
                  </Grid>
                  <Grid item sm={3} justifyContent="center">
                    <Divider
                      sx={{
                        mt: 2,
                        borderBottomWidth: "3px",
                        backgroundColor:
                          sectionStep == 1
                            ? themeType.default.themeOrange
                            : null,
                      }}
                    />
                  </Grid>
                  <Grid
                    container
                    item
                    sm={2}
                    direction="column"
                    alignItems="center"
                    justifyContent={"center"}
                  >
                    <Avatar
                      sx={{
                        width: 32,
                        height: 32,
                        backgroundColor:
                          sectionStep == 2
                            ? themeType.default.themeOrange
                            : null,
                      }}
                    >
                      2
                    </Avatar>
                    <Typography
                    data-testid="drag"
                      variant="caption"
                      sx={{ mt: 1, fontSize: "12px" }}
                    >
                      drag and drop comp
                    </Typography>
                  </Grid>
                  <Grid item sm={3}>
                    <Divider
                      sx={{
                        mt: 2,
                        borderBottomWidth: "3px",
                        backgroundColor:
                          sectionStep == 2
                            ? themeType.default.themeOrange
                            : null,
                      }}
                    />
                  </Grid>
                  <Grid
                    container
                    item
                    sm={2}
                    direction="column"
                    alignItems="center"
                    justifyContent={"center"}
                  >
                    <Avatar
                      sx={{
                        width: 32,
                        height: 32,
                        backgroundColor:
                          sectionStep == 3
                            ? themeType.default.themeOrange
                            : null,
                      }}
                    >
                      3
                    </Avatar>
                    <Typography
                    data-testid="preview"
                      variant="caption"
                      sx={{ mt: 1, fontSize: "12px" }}
                    >
                      preview & submit form
                    </Typography>
                  </Grid>
                </Grid>

                {getSectionStep()}
              </CardContent>
            </Card>
          </Grid>
          <Grid item sm={4} xs={2}>
            <Paper
              sx={{
                position: "fixed",
                zIndex: 100,
                mr: 2,
                overflowY: "scroll",
                maxHeight: "500px",
                borderRadius: "8px",
              }}
            >
              <Tabs
                value={tabIndex}
                aria-label="disabled tabs example"
                variant="fullWidth"
                sx={{
                  "& .MuiTabs-indicator": { display: "none" },
                  "& .MuiTab-root.Mui-selected": { color: "#FFFFFF" },
                }}
              >
                {/* {
                                    previousExists &&
                                    <Tab disabled={!previousExists} label="Previously Used Components"  {...a11yProps(0)} sx={{...getTabStyles(0), backgroundColor: previousExists ? (tabIndex == 0 ? theme.themeOrange: "white") : "#D7D7D7", marginRight: "1px", borderTopLeftRadius: "8px"}} />
                                } */}
                <Tab
                  label="Standard Form Components"
                  {...a11yProps(1)}
                  sx={{
                    ...getTabStyles(0),
                    marginLeft: previousExists ? "1px" : "0px",
                    borderTopRightRadius: "8px",
                  }}
                />
              </Tabs>
              {/* {
                                previousExists &&
                                <TabPanel value={tabIndex} index={0} style={{backgroundColor: "#EEEEEE"}} >
                                    <PreviousUsedComponents usedComponents={usedComponents} />
                                </TabPanel>
                            }
                             */}
              <TabPanel
                value={tabIndex}
                index={0}
                style={{ backgroundColor: themeType.default.themeGray }}
              >
                <StandardComponents data-testid='stdComp' stdComponents={stdComponents} />
              </TabPanel>
            </Paper>
          </Grid>
        </Grid>
      </DndProvider>
    </>
  );
}
