// import { Button } from "reactstrap";
import { Box, Button, IconButton } from "@mui/material";
import { selectStylesOverride } from "../../../utils/util";
import { Close, Search } from "@mui/icons-material";
import { CustomInput } from "../../../components/FormUI";
// import SearchBar from "../util/searchbar/SearchBar";
// import { globleFromDate } from ".";

export const Panel = ({
  onSubmit,
  onFilterClick,
  onClear,
  onClearInput,
  fromDate,
  isFilterPanelOpen,
  filterOpen,
  themeType,
  isDark,
  searchDeviceValue,
  setSearchDeviceValue,
  setSearchTemp,
  searchTemp,
}) => {
  const { buttonClasses, inputClasses } = themeType;
  return (
    <div className="panel">
      {/* <SearchBar
        placeholder="Search by Device ID"
        ariaLabel="search"
        onSubmit={onSubmit}
        onInputClear={onClearInput}
      /> */}
      <Box
        style={{
          // width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: "5px",
        }}
      >
        <CustomInput
          placeholder="Search..."
          label="Search"
          sx={{ ...inputClasses.filterField }}
          selectStylesOverride={selectStylesOverride(isDark)}
          inputClasses={inputClasses}
          value={searchTemp}
          onChange={(e) => {
            setSearchTemp(e?.target?.value);
            // setSearchDeviceValue(e?.target?.value);
          }}
        />
        <IconButton
          onClick={() => {
            if (searchTemp && searchTemp !== "") {
              setSearchDeviceValue(searchTemp);
              onSubmit(searchTemp);
            }else{
              onClearInput();
            }
            // if (searchDeviceValue && searchDeviceValue !== "") {
            //   onSubmit(searchDeviceValue);
            // }
          }}
        >
          <Search />
        </IconButton>
        {searchDeviceValue && searchDeviceValue !== "" && (
          <IconButton
            onClick={() => {
              if (searchDeviceValue && searchDeviceValue !== "") {
                onClearInput();
              }
            }}
          >
            <Close />
          </IconButton>
        )}
      </Box>
      <Button
        onClick={onFilterClick}
        style={{ ...buttonClasses.lynkitOrangeFill, fontSize: "16px" }}
      >
        {" "}
        <i className="fa fa-filter"></i>{" "}
      </Button>
      {/* <Button onClick={onFilterClick} color="primary" style={{ margin: "1px 0", padding: "0 12px" }}> <i className="fa fa-filter"></i> </Button> */}
      {filterOpen ? (
        <Button
          onClick={onClear}
          variant="text"
          style={{
            margin: "1px 0",
            //padding: "0 12px",
            color: themeType.default.themeOrange,
          }}
        >
          Clear
        </Button>
      ) : (
        ""
      )}
    </div>
  );
};
