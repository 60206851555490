import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button, Grid, Typography, FormControl, IconButton } from "@mui/material";
import CustomModel from "../../../Components/CustomModels/CustomModel1";
import { Cancel } from "@mui/icons-material";
import { convertIntoGivenTimezone, showToast } from "../../../../services/functions";
import { ToastContainer } from "react-toastify";
import { Controller, useForm, useWatch } from "react-hook-form";
import { selectStylesOverride } from "../../../../utils/util";
import { trackingLinksOptions, tripShareNotes } from "../../../../services/constants";
import { CustomInput } from '../../../../components/FormUI';
import CustomSelect from "../../../../components/FormUI/CustomSelect";
import { DateTimePicker, } from "@mui/x-date-pickers";
import EditIcon from '@mui/icons-material/Edit';
import moment from 'moment';
import ThreeDotsLoader from "../../../Components/ThreeDots/ThreeDotsLoader"
import { shareDeviceTrackingLink, deviceTrackListing, deleteDeviceTrackingLink, emptyDevIceTrackListing, shareDeviceAlert, device_alert_Listing, emptyDeviceAlertListing, deleteDeviceAlert, updateDeviceAlert,getAllAlertSettings, getAllGeofences } from "../../../../redux/actions";
import DeleteIcon from '@mui/icons-material/Delete';
import CustomMultiSelect from "../../../../components/FormUI/CustomMultiSelect";
import { havePermission } from "../../../../utils/permissionCheck";



function DeviceShareModal(props) {
    const dispatch = useDispatch();
    const { message, error } = useSelector((state) => state.common);
    const { open, hide, deviceShareObj, setShareDeviceModal } = props;

    const [totalDiffInMinutes, setTotalDiffInMinutes] = useState(0);
    const [timeCalculator, setTimeCalculator] = useState({
        daysValue : 0,
        hoursValue: 0,
        minsValue: 0
    })

    const [trackingLink, setTrackingLink] = useState(false)
    const [deleteTrackStatus, setTrackDeleteStatus] = useState(false)
    const [alertTrackStatus, setAlertDeleteStatus] = useState(false)
    const [alertStatus, setAlertStatus] = useState(false)
    const [tripStatus, setTripStatus] = useState(false)
    const [updateStatus, setUpdateStaus] = useState(false)
    const [trackListingStatus, setTrackListingStatus] = useState(false)
    const isDarkThemeEnabledSelector = useSelector((state) => state.theme.themeDark);
    const deviceTrackList = useSelector((state) => state.deviceslist.deviceTrackListing);
    const tripAlertList = useSelector((state) => state.deviceslist.deviceAlertListing);
    const themeType = isDarkThemeEnabledSelector ? require("../../../../static/styles/theme") : require("../../../../static/styles/theme");
    const { buttonClasses, inputClasses, textClasses, tableClasses } = themeType;
    const [buttonState, setButtonState] = useState('1')
    const [showAll, setShowAll] = useState(false);
    const [editBtn, setEditBtn] = useState(false)
    const GeofencesListing = useSelector(
        (state) => state.geofencelist.geofenceListing
      );
    const { allAlerts} =  useSelector((state) => state.deviceslist);
    const userPermissions = useSelector((state) => state.auth?.currentUserDetail) || {};
    const [deviceShareState, setDeviceShareState] = useState(
        {
            type: "send",
            email: [],
            option: ""
        }
    )
    const [alertState, setAlertState] = useState({
        emails: [],
        alertTypes: [],
        alertSocketName: []
    })
    useEffect(() => {
        dispatch(emptyDevIceTrackListing())
        dispatch(emptyDeviceAlertListing())
    }, [])

    useEffect(()=>{
        if(userPermissions){
            const emailsArray = userPermissions?.email.split(',').map(email => email.trim());
            setDeviceShareState(prevState => ({
                ...prevState,
                email: emailsArray
            }));


            setAlertState(prevState => ({
                ...prevState,
                emails: emailsArray || []
            }));
        }
    },[userPermissions])



    const dateTimePickerSlotProps = {
        textField: { size: "small", ...inputClasses.textfield },
        day: {
            sx: {
                "& .Mui-selected:hover": {
                    background: "#FF7200",
                },
            },
        },
        popper: {
            sx: {
                "& .MuiDateCalendar-root": {
                    maxHeight: "300px",
                },
                "& .MuiMultiSectionDigitalClock-root": {
                    maxHeight: "300px",
                },
            },
        },
        digitalClockSectionItem: {
            sx: {
                fontSize: "12px",
            },
        },
    };

    const activeStyle = {
        background: "#ff7200",
        color: "#fff",
        width: "30%",
        borderRadius: "0px",
        transition: "background 0.3s",
        "&:hover": {
            background: "#ff7200",
            color: "#fff",
        }
    }
    const inactiveStyle = {
        background: "#fff",
        color: "#ff7200",
        border: "1px solid #ff7200",
        width: "30%",
        borderRadius: "0px",
        transition: "background 0.3s",
        "&:hover": {
            background: "#fff",
            color: "#ff7200",
        }
    }
    const setBtnActive = (item) => {
        if (item === '1') {
            setButtonState('1')
            setAlertState({
                emails: [],
                alertTypes: [],
                alertSocketName:[]
            })
        }
        else {
            setButtonState('2')
            dispatch(getAllAlertSettings({selection: ["name","tripType","socketKey"]}));
            setDeviceShareState(
                {
                    type: "send",
                    email: [],
                    option: ""
                }
            )
        }
    }

    const {
        control,
        formState: { errors, isSubmitting, isValid, dirtyFields },
        handleSubmit,
        getValues,
        setValue
    } = useForm();

    const _handleChange = (e, newVal, item) => {
        let temp = { ...deviceShareState }
        if (item === 'trackingLink') {
            if (newVal.value === 'datetime') {
                temp.option = newVal?.value
                setDeviceShareState(temp)
            }
            else if (newVal.value === 'duration') {
                temp.option = newVal?.value
                setDeviceShareState(temp)
            }
            else if (newVal.value === 'geofence') {
                  dispatch(getAllGeofences(
                    {
                        selection: ['name'],
                    }
                  ))
                temp.option = newVal?.value
                setDeviceShareState(temp)
            }
        }
        else if (item === 'dateTime') {
            moment(newVal).format("YYYY-MM-DD HH:mm")
            const fDate = moment(newVal).format('MM-DD-YYYY hh:mm A');
            const cDate = moment(new Date()).format('MM-DD-YYYY hh:mm A');
            const diff = new Date(fDate).getTime() - new Date(cDate).getTime();
            const diffInMinutes = Math.floor(diff / 60000);
            setDeviceShareState({ ...temp, sharingInterval: diffInMinutes })

            if (diffInMinutes > 15 * 24 * 60) {
                showToast("Max validation of 15 days exceeded", true)
            }
        }
        else if (item === 'geofence') {
            if (newVal) {
                setDeviceShareState({
                    ...temp, sharingGeofence: {
                        id: newVal?._id,
                        itemName: newVal?.name
                    }
                })

            }
        }
        else if (item === 'email') {
            const emailsString = e.target.value;
            let emailsArray = [];
            if(emailsString){
                if(emailsString?.includes(",")){
                emailsArray = emailsString.split(",").map((email) => email.trim());
                }else{
                emailsArray = [emailsString];
                }
            }

            setDeviceShareState(prevState => ({
                ...prevState,
                email: emailsArray
            }));
        }
    }

 const handleDaysHoursMins = (e, newVal, item) => {
        let temp = { ...deviceShareState };
        let dummy = {...timeCalculator}

        if (item === 'days') {
            dummy.daysValue = e.target.value !== "" ? parseInt(e.target.value) : 0
            setTimeCalculator(dummy)
        }

        if (item === 'hours') {
            dummy.hoursValue = e.target.value !== "" ? parseInt(e.target.value) : 0
            setTimeCalculator(dummy)
        }

        if (item === 'mins') {
            dummy.minsValue = e.target.value !== "" ? parseInt(e.target.value) : 0
            setTimeCalculator(dummy)
        }

        const newTotalDiffInMinutes = dummy.daysValue * 24 * 60 + dummy.hoursValue * 60 + dummy.minsValue;
        setTotalDiffInMinutes(newTotalDiffInMinutes);
        setDeviceShareState({ ...temp, sharingInterval: newTotalDiffInMinutes });

        if (newTotalDiffInMinutes > 15 * 24 * 60) {
            showToast("Max validation of 15 days exceeded", true);
        }
    }
    const saveEntry = (item) => {
        if (item === 'save') {
            setTrackingLink(true)
            dispatch(
                shareDeviceTrackingLink({ data: { ...deviceShareState, deviceId: deviceShareObj.client_id }, param: 'share' }, () => {
                    setTrackingLink(false);
                })
            );
        }
        else {
            dispatch(
                shareDeviceTrackingLink({ data: { ...deviceShareState, deviceId: deviceShareObj.client_id, email: [], type: 'copy' }, param: 'copy' }, () => {
                })
            );
        }
    }

    useEffect(() => {
        if (message && trackingLink) {
            showToast(message, false);
            setTrackingLink(false);
            setShareDeviceModal(false)
        }
        if (error && trackingLink) {
            setTrackingLink(false);
            showToast(error, true);
        }
    }, [message, error]);

    const trackListing = (item) => {
        if (item === "trackListing") {
            setTrackListingStatus(true)
            dispatch(deviceTrackListing({ deviceId: deviceShareObj?.client_id }, () => {
                setTrackListingStatus(false)
            }));
        }
        else if (item === "alertsListing") {
            // console.log('deviceShareObj',deviceShareObj);
            setTripStatus(true)
            dispatch(device_alert_Listing({ assetId: deviceShareObj?.client_id }, () => {
                setTripStatus(false)
            }))
        }

    }
    const deleteTrackData = (id, item) => {
        if (item === 'track') {
            setTrackDeleteStatus(true)
            dispatch(deleteDeviceTrackingLink({ _id: id }, () => {
                setTrackDeleteStatus(false);
            })
            );
        }
        else if (item === 'alerts') {
            setAlertDeleteStatus(true)
            dispatch(deleteDeviceAlert({ _id: id, type: "delete" }, () => {
                setAlertDeleteStatus(false);
            })
            );
            setAlertState({
                emails:[],
                alertTypes:[],
                alertSocketName:[]
            })
        }
    }
    useEffect(() => {
        if (message && deleteTrackStatus) {
            setTrackDeleteStatus(false);
            trackListing('trackListing')
        }
        if (error && deleteTrackStatus) {
            setTrackDeleteStatus(false);
        }
    }, [message, error]);

    useEffect(() => {
        if (message && alertTrackStatus) {
            setAlertDeleteStatus(false);
            trackListing('alertsListing')
            dispatch(emptyDeviceAlertListing())
        }
        if (error && alertTrackStatus) {
            setAlertDeleteStatus(false);
        }
    }, [message, error]);

    const handle_change = (e, newVal, item) => {
        let temp = { ...alertState }
        if (item === "tripemail") {
            const emailsString = e.target.value;
            let emailsArray = [];
            if(emailsString){
                if(emailsString?.includes(",")){
                emailsArray = emailsString.split(",").map((email) => email.trim());
                }else{
                emailsArray = [emailsString];
                }
            }

            setAlertState(prevState => ({
                ...prevState,
                emails: emailsArray
            }));
        }
        else if (item === 'alerts') {
            temp.alertTypes.push(newVal[0].name);
            temp.alertSocketName.push(newVal[0].socketKey);
            setAlertState(temp)
        }
         else if (item === 'alertsTill') {
            setAlertState({ ...temp, validTill: newVal })
        }
    }

    const handleRemoveSelection = (i) => {
        let temp = { ...alertState }
        temp.alertSocketName.splice(i,1)
        temp.alertTypes.splice(i, 1)
        setAlertState(temp)
    } 


    const handleToggleSelectAllAlerts = (e, onChange) => {
        let temp = { ...alertState }; 
        let filteredArr = allAlerts.filter(item => item.tripType !== "alertWithTrip");
        temp.alertTypes = [];
        temp.alertSocketName= []
        for (let i = 0; i < filteredArr.length; i++) {
            temp.alertTypes.push(filteredArr[i].name);
            temp.alertSocketName.push(filteredArr[i].socketKey);
        }
        setAlertState(temp)
    };

    const addAlert = () => {
        let temp = { ...alertState }
        setAlertState({ ...temp,  assetId: deviceShareObj.detailsDeviceID?.deviceID })
        setAlertStatus(true)
        dispatch(shareDeviceAlert({ ...temp, alertTypes: temp?.alertSocketName, assetId: deviceShareObj?.client_id, validTill: moment(temp?.validTill).format("DD-MM-YYYY HH:mm:ss A") }), () => {
            setAlertStatus(false)
        })
    }
    useEffect(() => {
        if (message && alertStatus) {
            showToast(message, false);
            setAlertStatus(false);
            trackListing('alertsListing')
            // delete alertState?.validTill
            setAlertState({
                emails: [],
                alertTypes: [],
                alertSocketName: []
            })
        }
        if (error && alertStatus) {
            setAlertStatus(false);
            showToast(error, true);
        }
    }, [message, error]);


    const editAlert = (item) => {
        setEditBtn(true)
        setAlertState({
            ...alertState,
            emails: item.emails ? [...item.emails] : [],
            validTill: item?.validTill ,
            alertTypes: item.alertTypes ? [...item.alertTypes] : [],
        });

    }

    const updateAlertFunction = () => {
        let temp = { ...alertState }
        let finalPayload = {
            _id: tripAlertList && tripAlertList[0] && tripAlertList[0]._id || '-',
            type: "update",
            updateDetails: {
                emails: temp.emails,
                alertTypes: temp.alertTypes,
                validTill: moment(temp?.validTill).format("DD-MM-YYYY HH:mm:ss A") 
            }
        }
        setUpdateStaus(true)
        dispatch(updateDeviceAlert(finalPayload),()=>{
            setUpdateStaus(false)
        })
    }
    useEffect(() => {
        if (message && updateStatus) {
            showToast(message, false);
            setUpdateStaus(false);
            trackListing('alertsListing')
            setEditBtn(false)
        }
        if (error && updateStatus) {
            setUpdateStaus(false);
            showToast(error, true);
        }
    }, [message, error]);

    return (
        <>
            <CustomModel
                autoClose={false}
                show={open || false}
                onClose={() => hide()}
                minWidth={770}
                maxWidth={770}
                pb={3}
                pl={3}
                pr={3}
                maxHeight={"80vh"}
                childrenMaxHeight={"80vh"}
                Title="Share Device"
                viewCloseIcon={open}
            >
                <Grid container mb={2}>
                    <Grid
                        item
                        sm={12}
                        flexDirection={"column"}
                        sx={{ width: "100%", height: "auto" }}
                    >
                        <Grid mt={2} item sm={12} maxHeight="60vh" xs={12}
                            style={{
                                overflowY: "auto",
                                border: "1px solid #E8E8E8",
                                overflowX: "hidden",
                                padding: "10px",
                                paddingBottom: "20px",
                            }}
                        >
   
                            <Grid item xs={12} display={'flex'} justifyContent={'center'}>
                                <Button
                                  disabled ={userPermissions && havePermission(userPermissions, "trip", "allowSharingTripTrackingLink") ? false : true}
                                  onClick={() => setBtnActive('1')} sx={buttonState === "1" ? activeStyle : inactiveStyle}>Tracking Link</Button>
                                <Button
                                     disabled ={userPermissions && havePermission(userPermissions, "trip", "allowSharingTripTrackingLink") ? false : true}
                                  onClick={() => setBtnActive('2')} sx={buttonState === "2" ? activeStyle : inactiveStyle}>Device Alert</Button>
                            </Grid>
                            {buttonState === "1" ?
                                <form>

                                    <Grid item md={12} mt={4} style={{ width: '100%', display: 'flex' }}>
                                        <Grid item md={6} mr={2}>
                                            <FormControl sx={{ ...selectStylesOverride, display: 'flex' }}>
                                                <Controller
                                                    name={`tracking-link`}
                                                    control={control}
                                                    render={({ field: { onChange, value, name } }) => {
                                                        return (
                                                            <CustomSelect
                                                                selectStylesOverride={selectStylesOverride(isDarkThemeEnabledSelector)}
                                                                key={`trackingLink`}
                                                                required={true}
                                                                label="Tracking Link Validity Option"
                                                                onChange={(e, newVal) => {
                                                                    _handleChange(e, newVal, 'trackingLink')
                                                                }}
                                                                name={name}
                                                                errors={errors}
                                                                labelKey="label"
                                                                filterSelectedOptions={false}
                                                                options={trackingLinksOptions && trackingLinksOptions}
                                                            />
                                                        );
                                                    }}
                                                />
                                            </FormControl>
                                        </Grid>
                                        {deviceShareState?.option === 'datetime' ?
                                            <Grid item md={6} style={{ width: '100%' }}>

                                                <FormControl sx={{ ...selectStylesOverride(isDarkThemeEnabledSelector), width: '100%' }}>
                                                    <DateTimePicker
                                                        label="Date & time"
                                                        // value={new Date(scheduleData?.routeSource?.STD)}
                                                        minDate={new Date()}
                                                        onChange={(date) => _handleChange(null, date, 'dateTime')}
                                                        className="customDatePicker"
                                                        sx={{
                                                            width: "100%",
                                                            ...selectStylesOverride
                                                        }}
                                                        slotProps={dateTimePickerSlotProps}
                                                    />
                                                </FormControl>
                                            </Grid>
                                            : null}
                                        {deviceShareState?.option === 'duration' ?
                                            <Grid item md={6} style={{ width: '100%' }}>
                                                <FormControl sx={{ ...selectStylesOverride, width: '30%', marginRight: '12px' }}>
                                                    <Controller
                                                        name="days"
                                                        control={control}
                                                        id={`days`}
                                                        render={({ field: { onChange, value, name } }) => {
                                                            return (
                                                                <CustomInput
                                                                    placeholder="Days"
                                                                    label="Days"
                                                                    type={'number'}
                                                                    selectStylesOverride={selectStylesOverride(isDarkThemeEnabledSelector)}
                                                                    inputClasses={inputClasses}
                                                                    // name={name}
                                                                    InputProps={{ inputProps: { min: 0, max: 10 } }}
                                                                    errors={errors}
                                                                    // value={item?.tripId}
                                                                    onChange={(e, newVal) => {
                                                                        handleDaysHoursMins(e, newVal, 'days')
                                                                    }}
                                                                />
                                                            );
                                                        }}
                                                    />
                                                </FormControl>
                                                <FormControl sx={{ ...selectStylesOverride, width: '30%', marginRight: '12px' }}>
                                                    <Controller
                                                        name="hours"
                                                        control={control}
                                                        id={`hours`}
                                                        render={({ field: { onChange, value, name } }) => {
                                                            return (
                                                                <CustomInput
                                                                    placeholder="Hours"
                                                                    label="Hours"
                                                                    type={'number'}
                                                                    selectStylesOverride={selectStylesOverride(isDarkThemeEnabledSelector)}
                                                                    inputClasses={inputClasses}
                                                                    // name={name}
                                                                    errors={errors}
                                                                    // value={item?.tripId}
                                                                    onChange={(e, newVal) => {
                                                                        handleDaysHoursMins(e, newVal, 'hours')
                                                                    }}
                                                                />
                                                            );
                                                        }}
                                                    />
                                                </FormControl>
                                                <FormControl sx={{ ...selectStylesOverride, width: '30%' }}>
                                                    <Controller
                                                        name="mins"
                                                        control={control}
                                                        id={`mins`}
                                                        render={({ field: { onChange, value, name } }) => {
                                                            return (
                                                                <CustomInput
                                                                    placeholder="Mins"
                                                                    label="Mins"
                                                                    type={'number'}
                                                                    selectStylesOverride={selectStylesOverride(isDarkThemeEnabledSelector)}
                                                                    inputClasses={inputClasses}
                                                                    // name={name}
                                                                    errors={errors}
                                                                    // value={item?.tripId}
                                                                    onChange={(e, newVal) => {
                                                                        handleDaysHoursMins(e, newVal, 'mins')
                                                                    }}
                                                                />
                                                            );
                                                        }}
                                                    />
                                                </FormControl>
                                            </Grid>
                                            : null
                                        }
                                        {deviceShareState?.option === 'geofence' ?
                                            <Grid item md={6} style={{ width: '100%' }}>
                                                <FormControl sx={{ ...selectStylesOverride, display: 'flex' }}>
                                                    <Controller
                                                        name={`geofence`}
                                                        control={control}
                                                        render={({ field: { onChange, value, name } }) => {
                                                            return (
                                                                <CustomSelect
                                                                    selectStylesOverride={selectStylesOverride(isDarkThemeEnabledSelector)}
                                                                    key={`geofence`}
                                                                    label="Geofence"
                                                                    // value={deviceShareState?.sharingGeofence || null}
                                                                    onChange={(e, newVal) => {
                                                                        _handleChange(e, newVal, 'geofence')
                                                                    }}
                                                                    name={name}
                                                                    errors={errors}
                                                                    getOptionLabel={(option) => { return option?.name || '' }}
                                                                    labelKey="name"
                                                                    filterSelectedOptions={false}
                                                                    options={GeofencesListing && GeofencesListing}

                                                                    isOptionEqualToValue={(option, value) =>
                                                                        option._id === value._id && option.name === value.name
                                                                    }

                                                                />



                                                            );
                                                        }}
                                                    />
                                                </FormControl>
                                            </Grid>
                                            : null
                                        }

                                    </Grid>
                                    {deviceShareState?.option ?
                                    <Grid item md={12} mt={1} >
                                        <Typography sx={{ ...textClasses.t12n }}>Note : {tripShareNotes(deviceShareState?.option)}</Typography>
                                    </Grid>
                                    :null}
                                    <Grid item md={12} mt={2}>
                                        <FormControl sx={{ ...selectStylesOverride, width: '100%' }}>
                                            <Controller
                                                name="email"
                                                control={control}
                                                id={`email`}
                                                render={({ field: { onChange, value, name } }) => {
                                                    return (
                                                        <CustomInput
                                                            placeholder="Email Id"
                                                            label="Email Id(s)"
                                                            required={true}
                                                            selectStylesOverride={selectStylesOverride(isDarkThemeEnabledSelector)}
                                                            inputClasses={inputClasses}
                                                            // name={name}
                                                            errors={errors}
                                                            value={deviceShareState?.email || null}
                                                            onChange={(e, newVal) => {
                                                                _handleChange(e, newVal, 'email')
                                                            }}
                                                        />
                                                    );
                                                }}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item md={12} mt={1} >
                                        <Typography sx={{ ...textClasses.t12n }}>Add multiple email ids by comma <b>( , )</b> separated.</Typography>
                                    </Grid>
                                    {trackListingStatus ? (
                                        <Grid item={true} container direction={"column"} justifyContent={"center"} alignItems="center" xs={12} sx={{ p: 1 }}>
                                            <ThreeDotsLoader />
                                        </Grid>
                                    ) : (

                                        deviceTrackList && deviceTrackList?.length === 0 && !trackListingStatus ? (
                                            null
                                        ) : (
                                            <Grid item md={12} mt={1} >
                                                <table style={{ ...tableClasses.table }}>
                                                    <thead style={{ backgroundColor: themeType.default.themeGray }}>
                                                        <tr>
                                                            <td
                                                                style={{
                                                                    ...textClasses.boldText,
                                                                    ...tableClasses.tableHeader,
                                                                    textAlign: "center",
                                                                }}
                                                            >#
                                                            </td>
                                                            <td
                                                                style={{
                                                                    ...textClasses.boldText,
                                                                    ...tableClasses.tableHeader,
                                                                    textAlign: "center",
                                                                }}
                                                            >
                                                                Date & Time
                                                            </td>
                                                            <td
                                                                style={{
                                                                    ...textClasses.boldText,
                                                                    ...tableClasses.tableHeader,
                                                                    textAlign: "center",
                                                                }}
                                                            >
                                                                Shared With
                                                            </td>
                                                            <td
                                                                style={{
                                                                    ...textClasses.boldText,
                                                                    ...tableClasses.tableHeader,
                                                                    textAlign: "center",
                                                                }}
                                                            >
                                                                Valid Upto
                                                            </td>
                                                            <td
                                                                style={{
                                                                    ...textClasses.boldText,
                                                                    ...tableClasses.tableHeader,
                                                                    textAlign: "center",
                                                                }}
                                                            >
                                                                Action
                                                            </td>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {deviceTrackList && deviceTrackList?.map((item, index) => {
                                                            return (
                                                                <tr key={index}>
                                                                    <td
                                                                        style={{
                                                                            textAlign: "center",
                                                                            ...textClasses.normalText,
                                                                            ...tableClasses.dark,
                                                                            textAlign: "center",
                                                                        }}
                                                                    >
                                                                        {index + 1}
                                                                    </td>
                                                                    <td
                                                                        style={{
                                                                            textAlign: "center",
                                                                            ...textClasses.normalText,
                                                                            ...tableClasses.dark,
                                                                            textAlign: "center",
                                                                        }}
                                                                    >
                                                                        {item?.time || '-'}
                                                                    </td>
                                                                    <td
                                                                        style={{
                                                                            textAlign: "center",
                                                                            ...textClasses.normalText,
                                                                            ...tableClasses.dark,
                                                                            textAlign: "center",
                                                                        }}
                                                                    >
                                                                        {item?.sharingMethod === 'copy' ? 'Link Copied' :
                                                                            item.email.map((data, ind) => {
                                                                                return (
                                                                                    <p key={ind}>{data}</p>
                                                                                )
                                                                            })

                                                                        }
                                                                    </td>
                                                                    <td
                                                                        style={{
                                                                            textAlign: "center",
                                                                            ...textClasses.normalText,
                                                                            ...tableClasses.dark,
                                                                            textAlign: "center",
                                                                        }}
                                                                    >
                                                                        {item?.sharingGeofence?.itemName ? 'Arrival at ' + item?.sharingGeofence?.itemName : convertIntoGivenTimezone(item?.validity,userPermissions?.timeZone?.offset)  || '-'}


                                                                    </td>
                                                                    <td
                                                                        style={{
                                                                            textAlign: "center",
                                                                            ...textClasses.normalText,
                                                                            ...tableClasses.dark,
                                                                            textAlign: "center",
                                                                        }}
                                                                    >
                                                                        <DeleteIcon onClick={() => { deleteTrackData(item?._id, 'track') }} sx={{ color: 'red', cursor: 'pointer' }} />
                                                                    </td>


                                                                </tr>
                                                            )
                                                        })}


                                                    </tbody>
                                                </table>

















                                            </Grid>
                                        )
                                    )}
                                </form>

                                :
                                <>
                                    <Grid item md={12} mr={0} mt={4} >
                                        <FormControl sx={{ ...selectStylesOverride, display: 'flex' }}>
                                            <Controller
                                                name="alerts"
                                                control={control}
                                                render={({ field: { onChange, value, name } }) => {
                                                    return (
                                                        <CustomMultiSelect
                                                            value={value || []}
                                                            required
                                                            selectStylesOverride={selectStylesOverride(isDarkThemeEnabledSelector)}
                                                            onChange={(e, newVal) => { handle_change(e, newVal, 'alerts') }}
                                                            name={name}
                                                            label="Select Alerts"
                                                            errors={errors}
                                                            options={allAlerts && allAlerts.filter(item => item.tripType !== "alertWithTrip" && !alertState?.alertTypes.includes(item.name))}
                                                            allSelected={
                                                                allAlerts &&
                                                                allAlerts.filter(item => item.tripType !== "alertWithTrip")?.length === alertState?.alertTypes?.length
                                                            }
                                                            handleToggleSelectAll={e => handleToggleSelectAllAlerts(e, onChange)}
                                                            labelKey="name"
                                                            disableCloseOnSelect={true}
                                                            renderTags={() => null}
                                                            getOptionLabel={(option) => {
                                                                if (typeof option === "string") {
                                                                    return (
                                                                        alertState?.alertTypes &&
                                                                        alertState?.alertTypes?.find((_) => _?.name === option)?.name
                                                                    );
                                                                } else {
                                                                    return option?.name;
                                                                }
                                                            }}
                                                        />
                                                    );
                                                }}
                                            />
                                        </FormControl>
                                    </Grid>

                                    <Grid container my={0} spacing={2}>
                                        {alertState && alertState?.alertTypes?.slice(0, showAll ? alertState?.alertTypes?.length : 5)?.map((st, i) => {
                                            return (
                                                <Grid item sm={4} xs={6} key={i}>
                                                    <Grid
                                                        container
                                                        direction={"row"}
                                                        justifyContent="center"
                                                        alignItems={"center"}
                                                        sx={{
                                                            backgroundColor: themeType.default.themeLightOrange,
                                                            border: `1px solid ${themeType.default.themeOrange}`,
                                                            borderRadius: "12px",
                                                            pl: 1,
                                                            pr: 1,
                                                            minHeight: "35px",
                                                        }}
                                                    >
                                                        <Grid item sm={10} xs={8}>
                                                            <Typography
                                                                sx={{
                                                                    ...textClasses.normalText,
                                                                    color: themeType.default.themeOrange,
                                                                    textAlign: "start",
                                                                }}
                                                            >
                                                                {st && st.length > 15
                                                                    ? st.substring(0, 15) + "..."
                                                                    : st}
                                                            </Typography>
                                                        </Grid>
                                                        {handleRemoveSelection && (
                                                            <Grid
                                                                item
                                                                sm={2}
                                                                xs={4}
                                                                display="flex"
                                                                justifyContent="end"
                                                            >
                                                                <IconButton
                                                                    sx={{ alignContent: "right", padding: "5px" }}
                                                                    onClick={() => handleRemoveSelection(i)}
                                                                >
                                                                    <Cancel
                                                                        sx={{ color: themeType.default.themeOrange }}
                                                                    />
                                                                </IconButton>
                                                            </Grid>
                                                        )}
                                                    </Grid>
                                                </Grid>
                                            );
                                        })}
                                        {alertState?.alertTypes && alertState?.alertTypes?.length > 5 && (
                                            <>
                                                <Grid
                                                    container
                                                    item
                                                    sm={4}
                                                    xs={6}
                                                    justifyContent="start"
                                                    alignItems={"center"}
                                                >
                                                    <Typography
                                                        sx={{
                                                            ...textClasses.boldText,
                                                            color: themeType.default.themeOrange,
                                                            textDecoration: "underline",
                                                            cursor: "pointer",
                                                        }}
                                                        onClick={() => setShowAll(!showAll)}
                                                    >
                                                        {showAll ? (
                                                            <>Collapse</>
                                                        ) : (
                                                            <>View All ({alertState?.alertTypes?.length})</>
                                                        )}
                                                    </Typography>
                                                </Grid>
                                            </>
                                        )}
                                    </Grid>

                                    <Grid item md={12} mt={2} >

                                                <FormControl sx={{ ...selectStylesOverride(isDarkThemeEnabledSelector), width: '100%' }}>
                                                    <DateTimePicker
                                                        label="Alerts Till"
                                                        value={alertState?.validTill ? new Date(alertState?.validTill) : null}
                                                        minDate={new Date()}
                                                        onChange={(date) => handle_change(null, date, 'alertsTill')}
                                                        className="customDatePicker"
                                                        sx={{
                                                            width: "100%",
                                                            ...selectStylesOverride
                                                        }}
                                                        slotProps={dateTimePickerSlotProps}
                                                    />
                                                </FormControl>
                                            </Grid>





















                                    <Grid item md={12} mt={2}>
                                        <FormControl sx={{ ...selectStylesOverride, width: '100%' }}>
                                            <Controller
                                                name="email"
                                                control={control}
                                                render={({ field: { onChange, value, name } }) => {
                                                    return (
                                                        <CustomInput
                                                            placeholder="Email Id"
                                                            label="Email Id(s)"
                                                            required={true}
                                                            selectStylesOverride={selectStylesOverride(isDarkThemeEnabledSelector)}
                                                            inputClasses={inputClasses}
                                                            // name={name}
                                                            errors={errors}
                                                            value={alertState?.emails || null}
                                                            onChange={(e, newVal) => {
                                                                onChange()
                                                                handle_change(e, newVal, 'tripemail')
                                                            }}
                                                        />
                                                    );
                                                }}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item md={12} mt={1} >
                                        <Typography sx={{ ...textClasses.t12n }}>Add multiple email ids by comma <b>( , )</b> separated.</Typography>
                                    </Grid>

                                    {tripStatus ? (

                                        <Grid item={true} container direction={"column"} justifyContent={"center"} alignItems="center" xs={12} sx={{ p: 1 }}>
                                            <ThreeDotsLoader />
                                        </Grid>
                                    ) : (

                                        tripAlertList && tripAlertList?.length === 0 && !tripStatus ? (
                                            null
                                        ) : (
                                            <Grid item md={12} mt={1} >
                                                <table style={{ ...tableClasses.table }}>
                                                    <thead style={{ backgroundColor: themeType.default.themeGray }}>
                                                        <tr>
                                                            <td
                                                                style={{
                                                                    ...textClasses.boldText,
                                                                    ...tableClasses.tableHeader,
                                                                    textAlign: "center",
                                                                }}
                                                            >#
                                                            </td>
                                                            <td
                                                                style={{
                                                                    ...textClasses.boldText,
                                                                    ...tableClasses.tableHeader,
                                                                    textAlign: "center",
                                                                }}
                                                            >
                                                                Alerts
                                                            </td>
                                                            <td
                                                                style={{
                                                                    ...textClasses.boldText,
                                                                    ...tableClasses.tableHeader,
                                                                    textAlign: "center",
                                                                }}
                                                            >
                                                                Email Id(s)
                                                            </td>

                                                            <td
                                                                style={{
                                                                    ...textClasses.boldText,
                                                                    ...tableClasses.tableHeader,
                                                                    textAlign: "center",
                                                                }}
                                                            >
                                                                Action
                                                            </td>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {tripAlertList && tripAlertList?.map((item, index) => {
                                                            return (
                                                                <tr key={index}>
                                                                    <td
                                                                        style={{
                                                                            textAlign: "center",
                                                                            ...textClasses.normalText,
                                                                            ...tableClasses.dark,
                                                                            textAlign: "center",
                                                                        }}
                                                                    >
                                                                        {index + 1}
                                                                    </td>
                                                                    <td
                                                                        style={{
                                                                            textAlign: "center",
                                                                            ...textClasses.normalText,
                                                                            ...tableClasses.dark,
                                                                            textAlign: "center",
                                                                        }}
                                                                    >
                                                                        {item?.alertTypes.map((data, ind) => {
                                                                            return (
                                                                                <p key={ind}>{data}</p>
                                                                            )
                                                                        })

                                                                        }
                                                                    </td>
                                                                    <td
                                                                        style={{
                                                                            textAlign: "center",
                                                                            ...textClasses.normalText,
                                                                            ...tableClasses.dark,
                                                                            textAlign: "center",
                                                                        }}
                                                                    >
                                                                        {item?.emails?.map((data, ind) => {
                                                                            return (
                                                                                <p key={ind}>{data}</p>
                                                                            )
                                                                        })

                                                                        }
                                                                    </td>
                                                                    <td
                                                                        style={{
                                                                            textAlign: "center",
                                                                            ...textClasses.normalText,
                                                                            ...tableClasses.dark,
                                                                            textAlign: "center",
                                                                        }}
                                                                    >
                                                                        <EditIcon onClick={() => { editAlert(item) }} sx={{ color: 'green', cursor: 'pointer', fontSize: '17px', paddingRight: '1px' }} />
                                                                        <DeleteIcon onClick={() => deleteTrackData(item?._id, 'alerts')} sx={{ color: 'red', cursor: 'pointer', fontSize: '18px' }} />
                                                                    </td>


                                                                </tr>
                                                            )
                                                        })}


                                                    </tbody>
                                                </table>
                                            </Grid>
                                        )
                                    )}

                                </>
                            }
                        </Grid>
                    </Grid>
                </Grid>
                <Grid
                    container
                    item
                    direction={"row"}
                    sm={12}
                    xs={12}
                    sx={{ ml: 1, pb: 3 }}
                >
                    <>
                        {buttonState === "1" ?
                            <Button
                                variant="contained"
                                size="small"
                                sx={{
                                    ...buttonClasses.small,
                                    minHeight: "36px",
                                    borderColor: themeType.default.themeOrange,
                                    color: buttonClasses.lynkitOrangeEmpty,
                                    minWidth: "110px",
                                    marginRight: "17px",
                                }}
                                onClick={() => saveEntry('link')}
                            >
                                Copy Link
                            </Button>
                            : null}
                        {buttonState === "1" ?
                            <Button
                                variant="contained"
                                size="small"
                                sx={{
                                    ...buttonClasses.lynkitOrangeFill,
                                    ml: 0,
                                    fontSize: ".7rem",
                                    border: "none",
                                    height: "37px",
                                    minWidth: "110px",
                                    marginRight: "17px",
                                }}
                                onClick={() => saveEntry('save')}
                            >
                                Send
                            </Button>
                            : null}
                        {buttonState === "2" && !editBtn ?
                            <Button
                                variant="contained"
                                size="small"
                                sx={{
                                    ...buttonClasses.lynkitOrangeFill,
                                    ml: 0,
                                    fontSize: ".7rem",
                                    border: "none",
                                    height: "37px",
                                    minWidth: "110px",
                                    marginRight: "17px",
                                }}
                                onClick={() => addAlert()}
                            >
                                Add
                            </Button>
                            : null}

                        {buttonState === "2" && editBtn ? <Button
                            variant="contained"
                            size="small"
                            sx={{
                                ...buttonClasses.lynkitOrangeFill,
                                ml: 0,
                                fontSize: ".7rem",
                                border: "none",
                                height: "37px",
                                minWidth: "110px",
                                marginRight: "17px",
                            }}
                            onClick={() => updateAlertFunction()}
                        >
                            Update
                        </Button>
                            : null}
                        <Button
                            variant="contained"
                            size="small"
                            sx={{
                                ...buttonClasses.small,
                                minHeight: "36px",
                                borderColor: themeType.default.themeOrange,
                                color: buttonClasses.lynkitOrangeEmpty,
                                minWidth: "110px",
                            }}
                            onClick={() => { buttonState === "1" ? trackListing('trackListing') : trackListing('alertsListing') }}
                        >
                            View
                        </Button>



                    </>
                </Grid>
            </CustomModel>
            <ToastContainer
                position="bottom-center"
                autoClose={false}
                closeOnClick={false}
            />
        </>
    );
}

export default DeviceShareModal;
