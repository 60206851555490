import { Button, Card, CardActions, CardContent, Typography } from "@mui/material";
import React from "react";

export const confirmToast = ({
  message,
  isConfirm = true,
  callback = () => {},
}) => {
  const Confirm = ({ closeToast, toastProps }) => {
    return (
      <Card>
        <CardContent>
          <Typography sx={{ mb: 1.5, fontSize: "20px" }} color="text.secondary">
            {message}
          </Typography>
        </CardContent>
        <CardActions>
          <Button onClick={closeToast}>Cancel</Button>
          <Button variant="contained" onClick={() => callback(closeToast)}>Yes</Button>
        </CardActions>
      </Card>
      // <div className="confirmation-toast">
      // 	{message}
      // 	<div className="confirmation-toast_btnWrapper">
      // 		<button
      // 			className="confirmation-toast-btn toast-okay"
      // 			onClick={() => {
      // 				closeToast();
      // 				callback();
      // 			}}
      // 		>
      // 			{isConfirm ? "Yes" : "Okay"}
      // 		</button>
      // 		{isConfirm ? (
      // 			<button
      // 				className="confirmation-toast-btn toast-cancel"
      // 				onClick={closeToast}
      // 			>
      // 				No
      // 			</button>
      // 		) : null}
      // 	</div>
      // </div>
    );
  };
  return Confirm;
};
