import React, { useEffect, useState } from "react";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { useDispatch, useSelector } from "react-redux";
import { Box, Card, CardContent, Grid,  Typography } from "@mui/material";
import loaderGif from "../../../../static/images/drawer/loader.gif";
import { styled } from "@mui/system";
import { convertIntoGivenTimezone } from "../../../../services/functions";
import { PlayArrow } from "@mui/icons-material";
import ThreeDotsLoader from "../../../Components/ThreeDots/ThreeDotsLoader";

const Arrow = ({ distance }) => {
  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      {distance && <Typography sx={{ fontSize: "12px", fontWeight: "800" }}>{`${distance} Km`}</Typography>}
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Box sx={{ width: "100px", minWidth: "100px", height: "0px !important", border: `1px dashed black` }} />
        <PlayArrow sx={{ fontSize: "15px", color: "black" }} />
      </Box>
    </Box>
  )
}

const RouteBoxRowContainer = styled(Box)(({ theme }) => ({
  marginLeft: 1.5,
  marginRight: 1.5,
  minWidth: "150px",
  display: "flex",
  alignItems: "center",
  gap: "10px",
}));

const RouteBoxRow = ({ label, value }) => {
  return (
    <RouteBoxRowContainer>
      <Typography sx={{ fontSize: "13px", fontWeight: 700 }}>
        {label}
        {" :"}
      </Typography>
      <Typography sx={{ fontSize: "13px" }}>{value}</Typography>
    </RouteBoxRowContainer>
  );
};

const RouteBox = ({ obj, currentUserDetail, isDark, isSource = false }) => {
  const getTextColor = (text) => {
    if(text?.toLowerCase()?.includes("on time") || text?.toLowerCase()?.includes("early")){
      return "green";
    }else{
      return "red";
    }
  }
  
  return (
    <Box
      sx={{
        boxShadow: `1px 3px 8px 0px ${isDark ? "rgb(30, 25, 25)" : "rgba(191,191,191,1)"}`,
        minHeight: "145px",
        height: "145px",
        width: "300px",
        minWidth: "300px",
        padding: 0,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        height: "100%",
        borderRadius: "13px",
        background: getRouteBoxBg(obj, isDark, isSource),
      }}
    >
      <RouteBoxRowContainer sx={{ justifyContent: "center", mb: 1.5,mt:1 }}>
        <Typography variant="body1" sx={{ fontWeight: "600" }}>
          {obj?.name ?? "-"}
        </Typography>
      </RouteBoxRowContainer>
      {[
        "targetETA",
        "currentETA",
        "STA",
        "STD",
        "ETA",
        "ETD",
        "ATA",
        "ATD",
      ]?.map((field, idx) => {
        if (obj[field]) {
          return (
            <RouteBoxRow
              key={idx}
              label={getFieldLabel(field)}
              value={convertIntoGivenTimezone(
                obj[field],
                currentUserDetail?.timeZone?.offset,
                "DD/MM/YYYY hh:mm A"
              )}
            />
          );
        }
      })}

      {obj?.timeDiff && (
        <RouteBoxRowContainer sx={{ mt: 1, mb:1 }}>
          <Typography sx={{ fontSize: "13px", fontWeight: 600,  color : getTextColor(obj?.timeDiff)}}>
            {obj?.timeDiff ?? "-"}
          </Typography>
        </RouteBoxRowContainer>
      )}
    </Box>
  );
};

const getFieldLabel = (field) => {
  switch (field) {
    case "targetETA":
      return "Target ETA";
    case "currentETA":
      return "Current ETA";
    case "STA":
      return "STA";
    case "STD":
      return "STD";
    case "ETA":
      return "ETA";
    case "ETD":
      return "ETD";
    case "ATA":
      return "ATA";
    case "ATD":
      return "ATD";
  }
};

const getRouteBoxBg = (obj, isDark, isSource) => {
  if (obj?.ATA || obj?.ATD || isSource) {
    return isDark ? "#ce5700" : "#fba363"
  } else {
    return isDark ? "#3e3e3e" : "rgb(240, 240, 240)"
  }
}

const TripViaComponent = (props) => {
  const { viaModalArray, index } = props;
  const [routeObject, setRouteObject] = useState({});
  const { currentUserDetail } = useSelector((_) => _?.auth);
  const { tripETAData, tripETALoading } = useSelector((_) => _?.tripsData);
  const isDarkThemeEnabledSelector = useSelector(
    (state) => state.theme.themeDark
  );
  const themeType = isDarkThemeEnabledSelector
    ? require("../../../../static/styles/darktheme")
    : require("../../../../static/styles/theme");
  const { textClasses } = themeType;

  useEffect(() => {
    if (
      viaModalArray &&
      viaModalArray?.routeSchedule &&
      Object.keys(viaModalArray.routeSchedule).length
    ) {
      let temp = viaModalArray.routeSchedule;
      temp.viaPoints.forEach((point) => {
        point.totalDistance = point.travelKm;
        point.etaTime = point.travelTime;
      });
      temp.routeDestination.totalDistance = temp?.routeDestination?.travelKm;
      temp.routeDestination.etaTime = temp?.routeDestination?.travelTime;
      setRouteObject(temp);
    } else {
      setRouteObject(
        viaModalArray && viaModalArray.route ? viaModalArray.route : {}
      );
    }
  }, [viaModalArray]);

  const slide = (index) => {
    const buttonRight = document.getElementById(`slideRight-${index}`);
    const buttonLeft = document.getElementById(`slideLeft-${index}`);

    buttonRight.onclick = function () {
      document.getElementById(`container-${index}`).scrollLeft += 150;
    };
    buttonLeft.onclick = function () {
      document.getElementById(`container-${index}`).scrollLeft -= 150;
    };
  };

  return (
    <div data-testid="running-status-container">
      {tripETALoading ? (
        <Grid item={true} container direction={"column"} justifyContent={"center"} alignItems="center" xs={12} sx={{ p: 1 }}>
          <ThreeDotsLoader />
        </Grid>
      ) : (
        <>
          {tripETAData &&
            tripETAData?.route &&
            Object.keys(tripETAData?.route)?.length ? (
            <Box width="96%" mx={"auto"}  sx={{ overflowX: "auto" ,height:'215px', display:'flex'}}>
              <Box sx={{ display: "flex", alignItems: "center", gap: 1, width: "auto", margin:'auto', position:'relative', top:'10px' }}>
                {/* Source */}
                <RouteBox
                  obj={tripETAData?.route?.source}
                  currentUserDetail={currentUserDetail}
                  isDark={isDarkThemeEnabledSelector}
                  isSource={true}
                />

                {/* Via Points */}
                {tripETAData?.route?.viaPoints.map((viaPoint, index) => (
                  <React.Fragment key={viaPoint._id}>
                    <Arrow distance={viaPoint?.totalDistance ?? null} />
                    <RouteBox
                      obj={viaPoint}
                      currentUserDetail={currentUserDetail}
                      isDark={isDarkThemeEnabledSelector}
                    />
                  </React.Fragment>
                ))}

                {/* Arrow and Destination Box */}
                <Arrow distance={tripETAData?.route?.destination?.totalDistance ?? null} />
                <RouteBox
                  obj={tripETAData?.route?.destination}
                  currentUserDetail={currentUserDetail}
                  isDark={isDarkThemeEnabledSelector}
                />
              </Box>
            </Box>
          ) : (
            <div
              style={{
                marginTop: "2%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100px",
              }}
            >
              No Data Found..
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default TripViaComponent;
