import { Visibility } from "@mui/icons-material";
import {
  Box,
  FormControl,
  FormControlLabel,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";

export const InputField = ({
  name,
  required = false,
  value,
  onChange,
  errors = {},
  placeholder,
  label = "",
  disabled,
  inputProps,
  inputClasses,
  multiline,
  rows,
  defaultValue,
  type,
  width = "100%",
  maxWidth,
  minValue,
  maxValue,
  helperText,
  selectStylesOverride,
  extraStyles = null,
  ...props
}) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      width={width}
      maxWidth={maxWidth}
    >
      <FormControl sx={{ width: "100%" }}>
        <TextField
          value={value}
          onChange={onChange}
          helperText={helperText}
          defaultValue={defaultValue}
          label={
            label || placeholder ? (
              <Typography sx={{ fontSize: "15px",}}>
                {label ? label : placeholder}
                {required && "*"}
              </Typography>
            ) : null
          }
          sx={{
            fieldset:{borderColor: "#dbdbdb"},
            label:{color: "#dbdbdb", fontSize: "16px"},
            '.MuiInputBase-input': { fontSize: '1rem !important', color: "#a4a2a2 !important" },
          }}
          // inputProps={{style:{fontSize: 20}}}
          type={type}
          disabled={disabled}
          error={errors[name] ? true : false}
          placeholder={placeholder}
          {...props}
        />
      </FormControl>
      {errors[name] && (
        <span role="alert" style={{ color: "#FF0101", fontSize: 11 }}>
          {errors[name]?.message}
        </span>
      )}
    </Box>
  );
};
