import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button, Grid, Typography } from "@mui/material";
import CustomModel from "../../Components/CustomModels/CustomModel1";
import { showToast } from "../../../services/functions";
import { ToastContainer } from "react-toastify";
import { useForm } from "react-hook-form";
import DynamicFormRender from "../../Components/DynamicFormRender";
import {
    checkTemplateExistance,
    getEditDeviceTemplate,
} from "../../../redux/actions";
import { addDeviceAdditionalDetails } from "../../../redux/actions";

function ImagePreviewModal(props) {

    const { open, hide, imgInfo } = props;
    const isDarkThemeEnabledSelector = useSelector((state) => state.theme.themeDark);
    const themeType = isDarkThemeEnabledSelector ? require("../../../static/styles/theme") : require("../../../static/styles/theme");
    const { buttonClasses } = themeType;

    return (
        <>
            <CustomModel
                autoClose={false}
                show={open || false}
                onClose={() => hide()}
                minWidth={770}
                maxWidth={770}
                pb={1}
                pl={0}
                pr={0}
                maxHeight={"80vh"}
                childrenMaxHeight={"80vh"}
                Title={"Uploaded Document"}
                viewCloseIcon={open}
            >
                <Grid
                    item
                    sm={12}
                    mb={2}
                    flexDirection={"column"}
                    sx={{ width: "100%", height: "400px", marginTop: '12px' }}
                >
                    {
                        imgInfo?.includes("image") ? (
                            <img style={{ width: '100%', height: '100%' }} src={imgInfo} alt='' />
                        )
                            :

                            <object
                                data={imgInfo}
                                type="application/pdf"
                                width="100%"
                                height='100%'
                            ></object>
                        }


                </Grid>
            </CustomModel>

        </>
    );
}

export default ImagePreviewModal;
