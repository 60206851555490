import {
  Radio,
  RadioGroup,
  Grid,
  FormControlLabel,
  Typography,
  FormControl,
  IconButton,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectStylesOverride } from "../../../../utils/util";
import { useForm, Controller } from "react-hook-form";
import CustomSelect from "../../../../components/FormUI/CustomSelect";
import CustomMultiSelect from "../../../../components/FormUI/CustomMultiSelect";
import { Cancel } from "@mui/icons-material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { getShortText } from "../../../../services/functions";

const AdminSection = ({
  _handleSelect,
  handleRemoveSelection,
  Users,
  selectedUser,
  setSelectedUser,
  showAll,
  setShowAll,
  size,
  searchFields,
  setSearchFields,
  handleSearchUser,
  adminRadio,
  setAdminRadio,
  onSubmit,
  handleSubmit,
  control,
  errors,
  setValue,
}) => {
  const isDarkThemeEnabledSelector = useSelector(
    (state) => state.theme.themeDark
  );
  const themeType = isDarkThemeEnabledSelector
    ? require("../../../../static/styles/darktheme")
    : require("../../../../static/styles/theme");
  const { buttonClasses, cardClasses, inputClasses, textClasses } = themeType;

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container>
        <Grid item sm={12} mt={2} ml={0.2}>
          <Accordion defaultExpanded disableGutters={true}>
            <AccordionSummary
              sx={{
                background: themeType.default.themeOrange,
              }}
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography sx={{ ...textClasses.boldTextBlack }}>
                Assign Reporting Admin(s)
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                The user visibility is up to the selected reporting admins. If
                not selecting any reporting admin, the created user will be the
                default reporting admin.
              </Typography>
              <Grid
                container
                borderRadius={2}
                border="1px solid #E0E0E0"
                my={0}
                mt={2}
                p={2}
                sx={{ width: "100%" }}
              >
                <Grid item container spacing={2} mb={0} md={12}>
                  <Grid item md={4}>
                    <Controller
                      name="radio-buttons-group"
                      control={control}
                      // rules={{
                      //     required: {
                      //         value: true,
                      //         message: "Choose 1 radio option.",
                      //     },
                      // }}
                      render={({ field }) => (
                        <RadioGroup
                          aria-labelledby="demo-radio-buttons-group-label"
                          name="radio-buttons-group"
                          defaultValue={adminRadio}
                          value={adminRadio}
                          {...field}
                          // required
                          // errors={errors}
                        >
                          <FormControlLabel
                            sx={{
                              color: "rgb(128, 128, 128)",
                              "& .MuiFormControlLabel-label": {
                                fontSize: "13px",
                              },
                            }}
                            value="All"
                            label="All"
                            checked={adminRadio === "All"}
                            control={
                              <Radio
                                size="small"
                                sx={{
                                  "&, &.Mui-checked": {
                                    color: themeType.default.themeOrange,
                                  },
                                }}
                              />
                            }
                          />
                          <FormControlLabel
                            sx={{
                              color: "rgb(128, 128, 128)",
                              "& .MuiFormControlLabel-label": {
                                fontSize: "13px",
                              },
                            }}
                            value="select"
                            label="Select User(s)"
                            checked={adminRadio === "select"}
                            control={
                              <Radio
                                size="small"
                                sx={{
                                  "&, &.Mui-checked": {
                                    color: themeType.default.themeOrange,
                                  },
                                }}
                              />
                            }
                          />
                        </RadioGroup>
                      )}
                    />
                    {errors["radio-buttons-group"] && (
                      <p style={{ color: "red", fontSize: "11px" }}>
                        {errors["radio-buttons-group"].message}
                      </p>
                    )}
                  </Grid>

                  {adminRadio === "select" ? (
                    <Grid item md={4} mt={"auto"}>
                      <FormControl
                        variant="outlined"
                        size="small"
                        fullWidth
                        sx={{
                          ...selectStylesOverride(isDarkThemeEnabledSelector),
                        }}
                      >
                        <Controller
                          name="user"
                          control={control}
                          render={({ field: { onChange, value, name } }) => {
                            return (
                              <CustomMultiSelect
                                selectStylesOverride={selectStylesOverride}
                                value={value}
                                disableCloseOnSelect={true}
                                onChange={(e, newVal) => {
                                  //let idSet = newVal?.map((_) => _?._id);
                                  let idSet = newVal?.map((_) => ({
                                    id: _?._id,
                                    name: _?.name,
                                  }));
                                  onChange([...idSet]);
                                  setValue("user", [...idSet]);
                                }}
                                allSelected={
                                  Users &&
                                  Users?.length === selectedUser?.length
                                }
                                handleToggleSelectAll={(e) => {
                                  if (e?.target?.checked) {
                                    let arr =
                                      Users && Users?.map((item) => ({id: item._id, name: item?.name}));
                                    onChange([...arr]);
                                    setValue("user", [...arr]);
                                  } else {
                                    onChange([]);
                                    setValue("user", []);
                                  }
                                }}
                                name={name}
                                label="Users"
                                searchValue={searchFields?.users}
                                onSearch={handleSearchUser}
                                onInputChange={(e) => {
                                  if (e?.type === "change") {
                                    if (e?.target?.value?.trim() === "") {
                                      handleSearchUser(true);
                                    } else {
                                      setSearchFields((prev) => ({
                                        ...prev,
                                        users: e?.target?.value?.trim(),
                                      }));
                                    }
                                  }
                                }}
                                errors={errors}
                                getOptionLabel={(option) => {
                                  if (typeof option === "string") {
                                    return Users?.find((_) => _?._id == value)
                                      ?.name;
                                  } else {
                                    return option?.name;
                                  }
                                }}
                                labelKey="name"
                                options={
                                  Users &&
                                  Users.filter(
                                    (user) =>
                                      !selectedUser.some(
                                        (selected) => selected === user._id
                                      )
                                  )
                                }
                              />
                            );
                          }}
                        />
                      </FormControl>
                    </Grid>
                  ) : null}
                </Grid>
              </Grid>
            </AccordionDetails>
            <Grid container sx={{ mb: 2, pl: 3, pr: 3 }} spacing={1}>
              {selectedUser.slice(0, showAll ? size : 5).map((st, i) => {
                return (
                  <Grid item sm={3} xs={6} key={i}>
                    <Grid
                      container
                      direction={"row"}
                      justifyContent="center"
                      alignItems={"center"}
                      sx={{
                        backgroundColor: themeType.default.themeLightOrange,
                        border: `1px solid ${themeType.default.themeOrange}`,
                        borderRadius: "12px",
                        pl: 1,
                        pr: 1,
                        minHeight: "40px",
                      }}
                    >
                      <Grid item sm={10} xs={8}>
                        <Typography
                          sx={{
                            ...textClasses.normalText,
                            color: themeType.default.themeOrange,
                            textAlign: "start",
                          }}
                        >
                          {/* {Users &&
                            Users.find(
                              (user) => user._id === st
                            )?.name.substring(0, 15) + "..."} */}
                          {getShortText(st?.name, 15)}
                        </Typography>
                      </Grid>
                      {handleRemoveSelection && (
                        <Grid item sm={2} xs={4}>
                          <IconButton
                            sx={{ alignContent: "right" }}
                            disabled={adminRadio === "All"}
                            onClick={() => handleRemoveSelection(i, "users")}
                          >
                            <Cancel
                              sx={{ color: themeType.default.themeOrange }}
                            />
                          </IconButton>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                );
              })}
              {size > 5 && (
                <>
                  <Grid
                    container
                    item
                    sm={4}
                    xs={6}
                    justifyContent="center"
                    alignItems={"center"}
                  >
                    <Typography
                      sx={{
                        ...textClasses.boldText,
                        color: themeType.default.themeOrange,
                        textDecoration: "underline",
                        cursor: "pointer",
                      }}
                      onClick={() => setShowAll(!showAll)}
                    >
                      {showAll ? <>Collapse</> : <>View All ({size})</>}
                    </Typography>
                  </Grid>
                </>
              )}
            </Grid>
          </Accordion>
        </Grid>
      </Grid>
    </form>
  );
};

export default AdminSection;
