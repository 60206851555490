import React, { useEffect } from 'react'
import { Grid, Paper, Typography, Button, Modal, Card, FormControlLabel, FormControl, Radio } from '@mui/material'

import SelectTemplateIcon from "../../../static/images/templates/select_template.svg"

import theme, { buttonClasses, textClasses } from '../../../static/styles/theme';
import { useForm } from 'react-hook-form';
import { useState } from 'react';
import { templateTypes } from '../../../services/constants';
import { useDispatch, useSelector } from 'react-redux';
import { getTemplateTypes } from '../../../redux/actions';
import { parseCamelCase } from '../../../utils/util';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "60%",
    bgcolor: 'background.paper',
    border: '1px solid #000',
    boxShadow: 24,
    p: 3,
};

export default function SelectTemplate({ showModal, handleTemplateSelect, handleOpenModal, handleCloseModal }) {

    const dispatch = useDispatch();
    const allTemplateTypes = useSelector(state => state.templates.templateTypes);
    const { register, handleSubmit } = useForm();
    const [template, setTemplate] = useState("-");
    const [subTemplate, setSubTemplate] = useState("-");
    const [step, setStep] = useState(1);

    const handleCreateTemplate = () => {
        handleTemplateSelect(template, subTemplate);
        handleCloseModal();
    }

    const handleClose = () => {
        setTemplate("-");
        setSubTemplate("-");
        setStep(1);
        handleCloseModal();
    }

    const handleSelectTemplate = (template) => {
        setTemplate(template)
        // setStep(2);
        setSubTemplate(template);
    }

    useEffect(() => {
        dispatch(
            getTemplateTypes()
        )
    }, [])

    return (
        <Grid container alignItems={"center"} direction="column" justifyContent="center" sx={{ p: 3 }}>
            <img src={SelectTemplateIcon} height="150" />
            <Typography sx={{ mt: 2, ...textClasses.cardTitle }}>Select template type first</Typography>
            <Typography sx={{ ...textClasses.normalText, mt: 1 }}>create different types of templates /forms to collect the right information for data entry..</Typography>
            <Button size='small' variant="contained" sx={{ mt: 2, ...buttonClasses.small, backgroundColor: theme.themeOrange, minHeight: "40px" }} onClick={handleOpenModal}>Select Template Type</Button>
            <Modal
                open={showModal}
                onClose={handleClose}
            >
                <Card sx={style}>
                    {
                        step == 1
                            ?
                            <>
                                <Typography sx={{ ...textClasses.cardTitle }}>Select Template Type</Typography>

                                <Typography sx={{ ...textClasses.normalText, mt: 1 }}>
                                    create multiple templates to collect the right information for your inventories and warehouse operations.
                                </Typography>
                                <Grid container sx={{ mt: 1 }}>

                                    {
                                        allTemplateTypes.map((templ, i) => {

                                            return (

                                                <Grid container item direction="column" xs={6} sm={4} key={i} sx={{ height: "100%" }}>
                                                    <Paper sx={{ p: 1, m: 1, minHeight: "180px", border: templ.templateCategorykey == template ? `1px solid ${theme.themeOrange}` : "1px solid white" }}>
                                                        <FormControl sx={{ ml: 1 }}>
                                                            <FormControlLabel
                                                                control={
                                                                    <Radio size='small' sx={{ color: theme.themeOrange, "&, &.Mui-checked": { color: theme.themeOrange } }} onClick={() => handleSelectTemplate(templ.templateCategorykey)} checked={templ.templateCategorykey == template} />
                                                                }
                                                                label={
                                                                    <Typography sx={{ color: theme.themeOrange, fontSize: "16px" }}>
                                                                        {templ.templateCategoryLabel}
                                                                    </Typography>
                                                                }
                                                            />
                                                        </FormControl>
                                                        <br />
                                                        <div style={{ padding: "0px 10px 0px 10px" }}>
                                                            <Typography sx={{ fontSize: "14px", textAlign: "center" }}>
                                                                {templ.templateCategoryDescription}
                                                            </Typography>
                                                        </div>

                                                    </Paper>


                                                </Grid>
                                            )
                                        })
                                    }
                                </Grid>
                            </>
                            :
                            <>
                                <Typography sx={{ ...textClasses.cardTitle }}>Selected Template Type: <span style={{ color: theme.themeOrange }}>{parseCamelCase(template)}</span></Typography>

                                <Typography sx={{ ...textClasses.normalText, mt: 1 }}>
                                    create multiple templates to collect the right information for your inventories and warehouse operations.
                                </Typography>

                                <Grid container sx={{ mt: 1 }}>

                                    {
                                        allTemplateTypes.filter(t => t.templateCategorykey == template)[0].allTemplateTypes.sort((a, b) => { return Number(a.order) - Number(b.order) }).map((templ, i) => {

                                            return (

                                                <Grid container item direction="column" xs={6} sm={4} key={i}>
                                                    <Paper sx={{ p: 1, m: 1, minHeight: "180px", border: templ.templateTypekey == subTemplate ? `1px solid ${theme.themeOrange}` : "1px solid white" }}>
                                                        <FormControl sx={{ ml: 1 }}>
                                                            <FormControlLabel
                                                                control={
                                                                    <Radio size='small' sx={{ color: theme.themeOrange, "&, &.Mui-checked": { color: theme.themeOrange } }} onClick={() => setSubTemplate(templ.templateTypekey)} checked={templ.templateTypekey == subTemplate} />
                                                                }
                                                                label={
                                                                    <Typography sx={{ color: theme.themeOrange, fontSize: "16px" }}>
                                                                        {templ.templateTypeLabel}
                                                                    </Typography>
                                                                }
                                                            />
                                                        </FormControl>
                                                        <br />
                                                        <div style={{ padding: "5px 10px 0px 10px" }}>
                                                            <Typography sx={{ fontSize: "14px", textAlign: "center" }}>
                                                                {templ.templateTypeDescription}
                                                            </Typography>
                                                        </div>

                                                    </Paper>
                                                </Grid>
                                            )
                                        })
                                    }
                                </Grid>
                            </>
                    }

                    <Grid container sx={{ p: 1, mt: 1 }}>
                        {
                            step == 2 &&
                            <Button variant='outlined' size='small' sx={{ ...buttonClasses.lynkitBlackEmpty, ...textClasses.normalText, p: 1, mr: 1, minWidth: "180px" }} onClick={() => { setTemplate("-"); setSubTemplate("-"); setStep(1); }}>
                                Go Back
                            </Button>
                        }
                        <Button variant='outlined' size='small' sx={{ ...buttonClasses.lynkitBlackFill, ...textClasses.normalText, p: 1, minWidth: "180px" }} onClick={handleClose}>
                            Cancel
                        </Button>
                        <Button variant='contained' size='small' sx={{ ml: 1, ...buttonClasses.small, ...textClasses.normalText, p: 1, backgroundColor: theme.themeOrange, minWidth: "180px" }} onClick={handleCreateTemplate} disabled={subTemplate == "-"}>
                            Create Template
                        </Button>
                    </Grid>
                </Card>
            </Modal>
        </Grid>
    )
}
