import { Box, Paper, Typography, Grid, TablePagination } from "@mui/material";
import React, { useState, useEffect } from "react";
import CameraEventSummary from "./CameraEventSummary";
import ThreeDotsLoader from "../../../Components/ThreeDots/ThreeDotsLoader";
import { getBsjCameraAlerts } from "../../../../redux/actions";
import { useDispatch } from "react-redux";

const CameraEventsDrawer = ({ mapLoaded, id,setCameraAlertListing, alertsFlag,   themeType, alertsLoader, cameraAlerts,bsjCount, deviceDetails, currentTime, endTime, propsShared,  formatDateTime, alertListFlag, isDarkThemeEnabledSelector }) => {
  const dispatch = useDispatch();
  let payload = {
    page: 1,
    limit: 20,
    filter: {},
  };
  const [filter, setFilter] = useState({
    page: 1,
    limit: 10,
    filter: {},
  });
  const { tableClasses } = themeType;

  useEffect(() => {
    if ((filter?.page || filter?.limit) && alertsFlag) {
      let temp = { ...filter };
      dispatch(getBsjCameraAlerts(
        {
          "startDate": new Date(propsShared?.tripData?.detailsDeviceID?.time),
          "endDate": propsShared?.tripData?.endTime ? new Date(propsShared?.tripData?.endTime) : new Date(),
          "deviceId": id,
          "tripId": propsShared?.tripData?._id || null,
          "page": temp?.page,
          "limit": temp?.limit,
        }, (res) => {
          if(res?.success){
         
            setCameraAlertListing(res?.data)
          }
          else{
            setCameraAlertListing([])
          }  
        }
      ))
    }
  }, [filter?.page, filter?.limit,alertsFlag]);



  return (
    <Box
      position="relative"
      sx={{
        width: 750,
        opacity: 1,
        transition: "all 0.6s ease-in-out",
      }}
    >
      <Box height="50%" width="100%" bgcolor="#f9f9f9">
        {alertsLoader ? (
          <Grid item container direction="column" justifyContent="center" alignItems="center" xs={12} sx={{ p: 1 }}>
            <ThreeDotsLoader />
          </Grid>
        ) : (
          <div
            id="viewallmap"
            data-testid="camera-map"
            // className="drawMap"
            style={{ width: "100%", height: "100%" }}
          />
        )}
      </Box>
      <Box height="50%" width="100%" mt={0.5}>
        <Paper sx={{ boxShadow: 'none', borderBottom: 1, borderColor: "divider" }}>
          <Box p={1} width="100%" display="flex" alignItems="center" justifyContent="space-between">
            <Typography >Events Summary</Typography>
          </Box>
        </Paper>
        <Box width="100%" height="90%" > 
          <CameraEventSummary themeType={themeType} alertsLoader={alertsLoader} cameraAlerts={cameraAlerts} deviceDetails={deviceDetails} />
          {cameraAlerts?.length ?
            <Box sx={{ border: isDarkThemeEnabledSelector ? '0.3px solid #404040	' : '1px solid #F5F5F5', marginTop: '10px', borderRadius: '3px' }}>
              <TablePagination
                component="div"
                data-testid="pagination"
                sx={{
                  ...tableClasses.tableHeader,
                  ...tableClasses.tablePagination,
                  display: "flex",
                  justifyContent: "center",
                  position: "sticky",
                  bottom: "0px",
                  alignItems: "center",
                  mt: "0em",
                  "& .MuiTablePagination-selectLabel ": {
                    margin: "0",
                  },
                  "& .MuiTablePagination-displayedRows": {
                    margin: "0",
                  },
                }}
                count={bsjCount || 0}
                page={(filter.page || 1) - 1}
                onPageChange={(e, newPage) =>
                  setFilter({ ...filter, page: newPage + 1 })
                }
                rowsPerPage={filter.limit}
                onRowsPerPageChange={(e) =>
                  setFilter({ ...filter, page: 1, limit: e.target.value })
                }
              />
            </Box>
            : null}
        </Box>
      </Box>
    </Box>
  );
};

export default CameraEventsDrawer;


