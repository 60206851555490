import {
    Button,
    Autocomplete,
    TextField,
    Grid,
    MenuItem,
    Paper,
    Menu,
    CardContent,
    Typography,
    FormControl,
    OutlinedInput,
    IconButton,
    InputAdornment,
    Card,
    InputLabel,
    TablePagination,
    CardHeader,
    Checkbox,
    Box,
    Tabs,
    Tab,
} from "@mui/material";
import Tooltip from "@mui/material";
import {
    Close,
    ControlPoint,
    KeyboardArrowDown,
    Label,
    QrCodeScanner,
    QuestionMark,
    Search,
    Sell,
    SellOutlined,
    FilterAlt,
} from "@mui/icons-material";
import dayjs from 'dayjs';
import React, { useEffect, useState, useMemo, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
// actions
import { getAlertLogs, getAlertLogsExcel, getWebUnlockRequests, getWebUnlockRequestsExcel, getBluetoothLogs, emptyLogsReport, getBluetoothLogsExcel, downloadFixedLock_LogsExcel, getRfid_PasscodeLogs, getFixedLockLogs, getViewPassCodeLogs, getViewPassCodeLogsExcel, downloadRfid_PasscodeExcel, alertListingForWithTrip, alertListingForWithoutTrip } from "../../../redux/actions/logs";
//  
import { havePermission } from "../../../utils/permissionCheck";
import EmptyPage from "../EmptyPage";
import loaderGif from "../../../static/images/drawer/loader.gif";
import FiltersWrapper from '../../Components/FiltersWrapper/FiltersWrapper';
import { selectStylesOverride } from "../../../utils/util";
import { useForm, Controller } from "react-hook-form";
// import UsersTable from "../Users/components/UsersTable";
import CustomSelect from "../../../components/FormUI/CustomSelect";
import { dateTimePickerSlotProps } from "../../../utils/util";
import { DateTimePicker } from "@mui/x-date-pickers";
import { Constant } from "./components/Constant";
import { TableColumns } from "./components/TableColumns";
import LogsTable from "./components/LogsTable";
import Loader from "./components/Loader";

// import CustomSearchDropdown from "../../../components/FormUI/CustomSearchDropdown";

let payload = {
    page: 1,
    limit: 10,
    selectElock: null,
    searchLogs: null,
    alertTripType: "withTrip",
    selectElock: "Fixed E-Lock"
};

export default function Logs() {
    const dispatch = useDispatch();
    // selectors ---
    const logsData = useSelector((state) => state.logsList);
    const logsDocData = useSelector((state) => state.logsList.logDoc);
    const alertList = useSelector((state) => state.logsList.alertListForAdminAndSubuser);
    const { currentUserDetail } = useSelector((state) => state.auth);

    const isDarkThemeEnabledSelector = useSelector(
        (state) => state.theme.themeDark
    );
    const themeType = isDarkThemeEnabledSelector
        ? require("../../../static/styles/darktheme")
        : require("../../../static/styles/theme");
    const {
        theme,
        buttonClasses,
        cardClasses,
        inputClasses,
        textClasses,
        tableClasses,
        dividerClass,
    } = themeType;
    // state declare ---
    const [open, setOpen] = useState(true);
    const [loader, setLoader] = useState(false);
    const [downloadloader, setDownloadLoader] = useState(false);
    const [logsDropdown, setLogsDropdown] = useState([]);
    const [alertType, setAlertType] = useState({ label: "Alert With Trip", value: "withTrip" })
    const [alert, setAlert] = useState();
    const [elock, setElock] = useState({ label: "Fixed E-Lock", value: "Fixed E-Lock" });
    const [alertListData, setAlertListData] = useState([]);
    const [tableData, setTableData] = useState([]);
    const [tableCount, setTableCount] = useState(0);
    const [errorsObj, setErrorsObj] = useState({});
    const [filter, setFilter] = useState({ ...payload });
    // const [applyFilter, setApplyFilter] = useState(false);
    const [selectedSearchLogs, setSelectedSearchLogs] = useState(null)

    let tableColumns = TableColumns({ filter: filter, selectedLog: selectedSearchLogs })


    const handleFilter = (e, newVal, item) => {
        if (item === 'elock') {
            if (newVal) {
                setFilter((existing) => ({
                    ...existing,
                    selectElock: newVal?.value,
                }));
                setElock(newVal)
            } else {
                setFilter((existing) => ({
                    ...existing,
                    selectElock: "",
                }));
                setElock(null)
            }

        } else if (item === 'alertType') {
            if (newVal) {
                setFilter((existing) => ({
                    ...existing,
                    alertTripType: newVal?.value,
                    alertType: "",
                }));
                dispatch(emptyLogsReport())
                setAlertType(newVal);
                setAlert(null)
                if (filter?.searchLogs === 'Alert Sent Logs') {
                    if (currentUserDetail?._id) {
                        dispatch(
                            alertListingForWithTrip({
                                "type": "alert",
                                "orgId": currentUserDetail?.orgId,
                                "userId": currentUserDetail?._id,
                                "subType": "permissions",
                                "alertType": newVal?.value
                            })
                        );
                    }
                }
            } else {
                setFilter((existing) => ({
                    ...existing,
                    alertTripType: "",
                    alertType: "",
                }));
                setAlertType(null)
                setAlert(null)
            }

        } else if (item === 'alertList') {
            if (newVal) {
                console.log("newVal", newVal);
                setFilter((existing) => ({
                    ...existing,
                    alertType: newVal?.value,
                }));
                setAlert(newVal)
            } else {
                setFilter((existing) => ({
                    ...existing,
                    alertType: "",
                }));
                setAlert(null)
            }

        } else {
            if (newVal) {
                setFilter((existing) => ({
                    ...existing,
                    searchLogs: newVal?.value,
                }));
                if (newVal?.value === 'Alert Sent Logs') {
                    dispatch(emptyLogsReport())
                    if (currentUserDetail?._id) {
                        dispatch(
                            alertListingForWithTrip({
                                "type": "alert",
                                "orgId": currentUserDetail?.orgId,
                                "userId": currentUserDetail?._id,
                                "subType": "permissions",
                                "alertType": "withTrip"
                            })
                        );
                    }
                }
            } else {
                setFilter((existing) => ({
                    ...existing,
                    searchLogs: "",
                }));
            }

        }

    }

    const handleApplyFilters = () => {
        setSelectedSearchLogs(filter?.searchLogs);
        if (!filter?.searchLogs && !filter?.searchBy) {
            return setErrorsObj({
                ...errorsObj, "SearchLogs": {
                    type: "custom",
                    message: "Logs is required",
                }, "name": {
                    type: "custom",
                    message: "Search By Trip ID / Device ID is required",
                }
            })
        }
        if (!filter?.searchLogs) {
            return setErrorsObj({
                ...errorsObj, "SearchLogs": {
                    type: "custom",
                    message: "Logs is required",
                }, "name": ""
            })
        }
        if (!filter?.searchBy) {
            return setErrorsObj({
                ...errorsObj, "name": {
                    type: "custom",
                    message: "Search By Trip ID / Device ID is required",
                }, "SearchLogs": ""
            })
        }
        if (!filter?.alertTripType) {
            return setErrorsObj({
                ...errorsObj, "alertTripType": {
                    type: "custom",
                    message: "Alert Trip Type is Required",
                }, "SearchLogs": "", "name": "",
            })
        }
        if (!filter?.selectElock) {
            return setErrorsObj({
                ...errorsObj, "selectElock": {
                    type: "custom",
                    message: "E-lock is Required",
                }, "SearchLogs": "", "name": "",
            })
        }
        setErrorsObj({
            ...errorsObj, "SearchLogs": "", "name": "", "fromDate": "", "toDate": "", "alertTripType": "", "selectElock": ""
        })
        setLoader(true);
        if (filter.searchLogs === "Alert Sent Logs") {
            dispatch(
                getAlertLogs({
                    "limit": filter.limit,
                    "skip": filter.page,
                    "regex": filter.searchBy,
                    "alertType": filter.alertType,
                    "alertTripType": filter?.alertTripType,
                    "toDate": filter.toDate,
                    "fromDate": filter.fromDate
                }, (res) => {
                    setLoader(false);
                    setTableData(res?.data)
                    setTableCount(res?.count)
                })
            );
        } else if (filter.searchLogs === "Web Lock/Unlock Request Logs") {
            dispatch(
                getWebUnlockRequests({
                    "limit": filter.limit,
                    "skip": filter.page,
                    "regex": filter.searchBy,
                    "toDate": filter.toDate,
                    "fromDate": filter.fromDate
                }, (res) => {
                    setLoader(false);
                    setTableData(res?.data)
                    setTableCount(res?.count)
                })
            );


        } else if (filter.searchLogs === "Bluetooth Lock/Unlock Request Logs") {
            dispatch(
                getBluetoothLogs({
                    "limit": filter.limit,
                    "skip": filter.page,
                    "regex": filter.searchBy,
                    "toDate": filter.toDate,
                    "fromDate": filter.fromDate
                }, (res) => {
                    setLoader(false);
                    setTableData(res?.data)
                    setTableCount(res?.count)
                })
            );
        } else if (filter.searchLogs === "Rfid Lock/Unlock Request Logs") {
            dispatch(
                getRfid_PasscodeLogs({
                    "limit": filter.limit,
                    "skip": filter.page,
                    "regex": filter.searchBy,
                    "toDate": filter.toDate,
                    "fromDate": filter.fromDate,
                    "type": "rfid"
                }, (res) => {
                    setLoader(false);
                    setTableData(res?.data)
                    setTableCount(res?.count)
                })
            );
        } else if (filter.searchLogs === "Passcode Lock/Unlock Request Logs") {
            if (filter?.selectElock === "Fixed E-Lock") {
                dispatch(
                    getFixedLockLogs({
                        "limit": filter.limit,
                        "skip": filter.page,
                        "regex": filter.searchBy,
                        "toDate": filter.toDate,
                        "fromDate": filter.fromDate
                    }, (res) => {
                        setLoader(false);
                        setTableData(res?.data)
                        setTableCount(res?.count)
                    })
                );
            } else {
                dispatch(
                    getRfid_PasscodeLogs({
                        "limit": filter.limit,
                        "skip": filter.page,
                        "regex": filter.searchBy,
                        "toDate": filter.toDate,
                        "fromDate": filter.fromDate,
                        "type": "passcode"
                    }, (res) => {
                        setLoader(false);
                        setTableData(res?.data)
                        setTableCount(res?.count)
                    })
                );

            }

        } else if (filter.searchLogs === "Passcode View Logs") {
            dispatch(
                getViewPassCodeLogs({
                    "limit": filter.limit,
                    "page": filter.page,
                    "skip": filter.page,
                    "deviceId": filter.searchBy,
                    "dateto": filter.toDate,
                    "datefrom": filter.fromDate
                }, (res) => {
                    setLoader(false);
                    setTableData(res?.data)
                    setTableCount(res?.count)
                })
            );
        } else {
            return;
        }

    }

    const getReportFields = () => {
        if (!filter?.searchLogs) {
            return setErrorsObj({
                ...errorsObj, "SearchLogs": {
                    type: "custom",
                    message: "Logs is required",
                }
            })
        }
        if (!filter?.toDate && !filter?.fromDate) {
            return setErrorsObj({
                ...errorsObj, "toDate": {
                    type: "custom",
                    message: "To Date is required",
                }, "fromDate": {
                    type: "custom",
                    message: "From Date is required",
                }
            })
        }
        if (!filter?.toDate) {
            return setErrorsObj({
                ...errorsObj, "toDate": {
                    type: "custom",
                    message: "To Date is required",
                }, "fromDate": "", "SearchLogs": ""
            })
        }
        if (!filter?.fromDate) {
            return setErrorsObj({
                ...errorsObj, "fromDate": {
                    type: "custom",
                    message: "From Date is required",
                }, "toDate": "", "SearchLogs": ""
            })
        }
        setErrorsObj({
            ...errorsObj, "SearchLogs": "", "name": "", "fromDate": "", "toDate": ""
        })
        setDownloadLoader(true)
        if (filter.searchLogs === "Alert Sent Logs") {
            dispatch(
                getAlertLogsExcel({
                    "regex": filter.searchBy,
                    "alertType": filter.alertType,
                    "alertTripType": filter.alertTripType,
                    "toDate": filter.toDate,
                    "fromDate": filter.fromDate
                }, () => {
                    setDownloadLoader(false);
                })
            );

        } else if (filter.searchLogs === "Web Lock/Unlock Request Logs") {
            dispatch(
                getWebUnlockRequestsExcel({
                    "regex": filter.searchBy,
                    "toDate": filter.toDate,
                    "fromDate": filter.fromDate
                }, () => {
                    setDownloadLoader(false);
                })
            );
        } else if (filter.searchLogs === "Bluetooth Lock/Unlock Request Logs") {
            dispatch(
                getBluetoothLogsExcel({
                    "regex": filter.searchBy,
                    "toDate": filter.toDate,
                    "fromDate": filter.fromDate
                }, () => {
                    setDownloadLoader(false);
                })
            );
        } else if (filter.searchLogs === "Rfid Lock/Unlock Request Logs") {
            dispatch(
                downloadRfid_PasscodeExcel({
                    "regex": filter.searchBy,
                    "toDate": filter.toDate,
                    "fromDate": filter.fromDate,
                    "type": "rfid"
                }, () => {
                    setDownloadLoader(false);
                })
            );
        } else if (filter.searchLogs === "Passcode Lock/Unlock Request Logs") {
            dispatch(
                downloadFixedLock_LogsExcel({
                    "regex": filter.searchBy,
                    "toDate": filter.toDate,
                    "fromDate": filter.fromDate
                }, () => {
                    setDownloadLoader(false);
                })
            );
        } else if (filter.searchLogs === "Passcode View Logs") {
            dispatch(
                getViewPassCodeLogsExcel({
                    "deviceId": filter.searchBy,
                    "toDate": filter.toDate,
                    "fromDate": filter.fromDate
                }, () => {
                    setDownloadLoader(false);
                })
            );
        } else {
            return;
        }
    }

    const handlePaginationChange = (newfilter) => {
        setLoader(true);
        if (filter.searchLogs === "Alert Sent Logs") {
            dispatch(
                getAlertLogs({
                    "limit": newfilter.limit,
                    "skip": newfilter.page,
                    "regex": newfilter.searchBy,
                    "alertTripType": newfilter.alertTripType,
                    "alertType": newfilter.alertType,
                    "toDate": newfilter.toDate,
                    "fromDate": newfilter.fromDate
                }, (res) => {
                    setLoader(false);
                    setTableData(res?.data)
                    setTableCount(res?.count)
                })
            );
        } else if (filter.searchLogs === "Web Lock/Unlock Request Logs") {
            dispatch(
                getWebUnlockRequests({
                    "limit": newfilter.limit,
                    "skip": newfilter.page,
                    "regex": newfilter.searchBy,
                    "toDate": newfilter.toDate,
                    "fromDate": newfilter.fromDate
                }, (res) => {
                    setLoader(false);
                    setTableData(res?.data)
                    setTableCount(res?.count)
                })
            );
        } else if (filter.searchLogs === "Bluetooth Lock/Unlock Request Logs") {
            dispatch(
                getBluetoothLogs({
                    "limit": newfilter.limit,
                    "skip": newfilter.page,
                    "regex": newfilter.searchBy,
                    "toDate": newfilter.toDate,
                    "fromDate": newfilter.fromDate
                }, (res) => {
                    setLoader(false);
                    setTableData(res?.data)
                    setTableCount(res?.count)
                })
            );
        } else if (filter.searchLogs === "Rfid Lock/Unlock Request Logs") {
            dispatch(
                getRfid_PasscodeLogs({
                    "limit": newfilter.limit,
                    "skip": newfilter.page,
                    "regex": newfilter.searchBy,
                    "toDate": newfilter.toDate,
                    "fromDate": newfilter.fromDate,
                    "type": "rfid"
                }, (res) => {
                    setLoader(false);
                    setTableData(res?.data)
                    setTableCount(res?.count)
                })
            );
        } else if (filter.searchLogs === "Passcode Lock/Unlock Request Logs") {
            dispatch(
                getFixedLockLogs({
                    "limit": newfilter.limit,
                    "skip": newfilter.page,
                    "regex": newfilter.searchBy,
                    "toDate": newfilter.toDate,
                    "fromDate": newfilter.fromDate
                }, (res) => {
                    setLoader(false);
                    setTableData(res?.data)
                    setTableCount(res?.count)
                })
            );
        } else if (filter.searchLogs === "Passcode View Logs") {
            dispatch(
                getViewPassCodeLogs({
                    "limit": newfilter.limit,
                    "page": newfilter.page,
                    "skip": newfilter.page,
                    "deviceId": newfilter.searchBy,
                    "dateto": newfilter.toDate,
                    "datefrom": newfilter.fromDate
                }, (res) => {
                    setLoader(false);
                    setTableData(res?.data)
                    setTableCount(res?.count)
                })
            );
        } else {
            setLoader(false);
            return;
        }
    }

    useEffect(() => {
        if (logsDocData?.message) {
            const downloadLink = logsDocData?.message
            const link = document.createElement('a');
            link.href = downloadLink;
            link.download = `${filter.searchLogs}_logs.xlsx`;
            document.body.appendChild(link);
            link.click();
            document.body.appendChild(link);
        }

        if (alertList[0]?.hasOwnProperty('alertType')) {
            const result = Object.keys(alertList[0]?.settings).map((ele) => {
                return { "label": alertList[0]?.settings[ele].label, "value": alertList[0]?.settings[ele].alertKey || alertList[0]?.settings[ele].socketKey }
            })
            setAlertListData(result)
        }

    }, [logsDocData, alertList])

    useEffect(() => {
        if (havePermission(currentUserDetail, "alertLogs", "viewAlertLogs")) {
            setLogsDropdown(prevLogs => [...prevLogs, { label: "Alert Sent Logs", value: "Alert Sent Logs" }]);
        }
        if (havePermission(currentUserDetail, "unlockingLogs", "viewBtUnlockingLogs")) {
            setLogsDropdown(prevLogs => [...prevLogs, { label: "Bluetooth Lock/Unlock Request Logs", value: "Bluetooth Lock/Unlock Request Logs" }]);

        }
        if (havePermission(currentUserDetail, "unlockingLogs", "viewWebUnlockingLogs")) {
            setLogsDropdown(prevLogs => [...prevLogs, { label: "Web Lock/Unlock Request Logs", value: "Web Lock/Unlock Request Logs" }]);

        }
        if (havePermission(currentUserDetail, "unlockingLogs", "viewRfidUnlockingLogs")) {
            setLogsDropdown(prevLogs => [...prevLogs, { label: "Rfid Lock/Unlock Request Logs", value: "Rfid Lock/Unlock Request Logs" }]);

        }
        if (havePermission(currentUserDetail, "unlockingLogs", "viewPasscodeUnlockingLogs")) {
            setLogsDropdown(prevLogs => [...prevLogs, { label: "Passcode Lock/Unlock Request Logs", value: "Passcode Lock/Unlock Request Logs" }]);
        }
        if (havePermission(currentUserDetail, "unlockingLogs", "viewPasscodeLogs")) {
            setLogsDropdown(prevLogs => [...prevLogs, { label: "Passcode View Logs", value: "Passcode View Logs" }]);
        }
    }, [])

    return (
        <>
            <FiltersWrapper
                handleDrawerToggle={() => setOpen(!open)}
                open={open}
                isDarkThemeEnabledSelector={isDarkThemeEnabledSelector}
                selectStylesOverride={selectStylesOverride(isDarkThemeEnabledSelector)}
                themeType={themeType}
                handleApplyFilters={handleApplyFilters}
                searchElement={
                    <Grid container spacing={2} mt={0}>
                        <Grid item md={12} ml={2} mr={2} style={{ width: "100%" }}>
                            <FormControl
                                variant="outlined"
                                size="small"
                                fullWidth
                                sx={{ ...selectStylesOverride(isDarkThemeEnabledSelector) }}
                            >
                                <CustomSelect
                                    selectStylesOverride={selectStylesOverride}
                                    onChange={(e, newVal) => {
                                        handleFilter(e, newVal, "logs");
                                    }}
                                    sx={{
                                        ...inputClasses.filterField,
                                    }}
                                    name={"SearchLogs"}
                                    label="Search Logs"
                                    errors={errorsObj}
                                    getOptionLabel={(option) => {
                                        return option?.label;
                                    }}
                                    filterSelectedOptions
                                    labelKey="label"
                                    isOptionEqualToValue={(option, value) =>
                                        option.label === value.label && option.value === value.value
                                    }
                                    options={logsDropdown}
                                />
                            </FormControl>
                        </Grid>
                        {filter.searchLogs === "Alert Sent Logs" && <Grid item md={12} ml={2} mr={2} style={{ width: "100%" }}>
                            <FormControl
                                variant="outlined"
                                size="small"
                                fullWidth
                                sx={{ ...selectStylesOverride(isDarkThemeEnabledSelector) }}
                            >
                                <CustomSelect
                                    selectStylesOverride={selectStylesOverride}
                                    value={alertType}
                                    onChange={(e, newVal) => {
                                        handleFilter(e, newVal, "alertType");
                                    }}
                                    sx={{
                                        ...inputClasses.filterField,
                                    }}
                                    label="Select Alert Trip Type"
                                    name={"alertTripType"}
                                    errors={errorsObj}
                                    getOptionLabel={(option) => {
                                        return option?.label;
                                    }}
                                    filterSelectedOptions
                                    labelKey="label"
                                    isOptionEqualToValue={(option, value) =>
                                        option.label === value.label && option.value === value.value
                                    }
                                    options={Constant.AlertTrip}
                                />
                            </FormControl>
                        </Grid>}
                        {filter.searchLogs === "Alert Sent Logs" && <Grid item md={12} ml={2} mr={2} style={{ width: "100%" }}>
                            <FormControl
                                variant="outlined"
                                size="small"
                                fullWidth
                                sx={{ ...selectStylesOverride(isDarkThemeEnabledSelector) }}
                            >
                                <CustomSelect
                                    selectStylesOverride={selectStylesOverride}
                                    value={alert}
                                    onChange={(e, newVal) => {
                                        handleFilter(e, newVal, "alertList");
                                    }}
                                    sx={{
                                        ...inputClasses.filterField,
                                    }}
                                    label="Select Alerts"
                                    // errors={errors}
                                    getOptionLabel={(option) => {
                                        return option?.label;
                                    }}
                                    filterSelectedOptions
                                    labelKey="label"
                                    isOptionEqualToValue={(option, value) =>
                                        option.label === value.label && option.value === value.value
                                    }
                                    options={alertListData}
                                />
                            </FormControl>
                        </Grid>}
                        <Grid item md={12} ml={2} mr={2} style={{ width: "100%" }}>
                            <FormControl
                                variant="outlined"
                                size="small"
                                fullWidth
                                sx={{
                                    ...selectStylesOverride(isDarkThemeEnabledSelector),
                                    borderRadius: "12px",
                                }}
                            >
                                <InputLabel
                                    htmlFor="outlined-adornment-password"
                                    sx={{ ...inputClasses.labelField, fontSize: "12px", marginLeft: '-2px' }} // Adjust the left margin as needed
                                >
                                    {filter.searchLogs === "Bluetooth Lock/Unlock Request Logs" ? "Search by Device ID." : "Search by Trip ID / Device ID."}
                                </InputLabel>
                                <OutlinedInput
                                    sx={{ ...inputClasses.filterField, height: "36px" }}
                                    name="name"
                                    data-testid="searchelement"
                                    value={filter?.searchBy || ""}
                                    onChange={(e) =>
                                        setFilter({
                                            ...filter,
                                            searchBy: e.target.value
                                                .trimStart()
                                                .replace(/\s{2,}/g, " "),
                                        })
                                    }
                                    // inputValue.replace(/ +/g, ' ').trim();
                                    placeholder={filter.searchLogs === "Bluetooth Lock/Unlock Request Logs" ? "Search by Device ID." : "Search by Trip ID / Device ID."}
                                    label="Search by name."
                                    error={errorsObj["name"] ? true : false}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <Search fontSize="small" />
                                        </InputAdornment>
                                    }
                                />
                                {errorsObj["name"] && (
                                    <span role="alert" style={{ color: "#FF0101", fontSize: 11, marginTop: "5px" }}>
                                        {errorsObj["name"]?.message}
                                    </span>
                                )}
                            </FormControl>
                        </Grid>
                        <Grid item md={12} ml={2} mr={2} style={{ width: '100%' }}>
                            <FormControl sx={{ ...selectStylesOverride(isDarkThemeEnabledSelector), width: '100%' }}>
                                <DateTimePicker
                                    label="From"
                                    onChange={(date) => {
                                        if (date) {
                                            setFilter((prev) => ({ ...prev, fromDate: date }))
                                        }
                                        else {
                                            let temp = { ...filter };
                                            temp.fromDate = "";
                                            setFilter(temp);
                                        }
                                    }}
                                    className="customDatePicker"
                                    sx={{
                                        ...inputClasses.filterField,
                                        fontSize: "8px",
                                        width: "100%",
                                    }}
                                    slotProps={{
                                        ...dateTimePickerSlotProps(
                                            inputClasses,
                                            isDarkThemeEnabledSelector
                                        ),
                                        actionBar: { actions: ["clear", "accept"] },
                                        textField: { size: 'small' },
                                        inputProps:{ size: 'small' }
                                        
                                    }}
                                    size="small"
                                    error={errorsObj["fromDate"] ? true : false}
                                    minDate={filter?.toDate ? new Date(Substractfifteendays(filter?.toDate)) : ""}
                                />
                                {errorsObj["fromDate"] && (
                                    <span role="alert" style={{ color: "#FF0101", fontSize: 11, marginTop: "5px" }}>
                                        {errorsObj["fromDate"]?.message}
                                    </span>
                                )}
                            </FormControl>
                        </Grid>
                        <Grid item md={12} ml={2} mr={2} style={{ width: '100%' }}>
                            <FormControl sx={{ ...selectStylesOverride(isDarkThemeEnabledSelector), width: '100%' }}>
                                <DateTimePicker
                                    label="To"
                                    // value={new Date(scheduleData?.routeSource?.STD)}
                                    onChange={(date) => {
                                        if (date) {
                                            setFilter((prev) => ({ ...prev, toDate: date }))
                                        }
                                        else {
                                            let temp = { ...filter };
                                            temp.toDate = "";
                                            setFilter(temp);
                                        }
                                    }}
                                    className="customDatePicker"
                                    sx={{
                                        ...inputClasses.filterField,
                                        fontSize: "8px",
                                        width: "100%",
                                    }}
                                    size="small"
                                    error={errorsObj["toDate"] ? true : false}
                                    slotProps={{
                                        textField: { size: "small" },
                                        actionBar: {
                                            actions: ["clear", "accept"],
                                        },
                                    }}
                                    // slotProps={dateTimePickerSlotProps(inputClasses)}
                                    maxDate={filter?.fromDate ? new Date(Addfifteendays(filter?.fromDate)) : ""}
                                />
                                {errorsObj["toDate"] && (
                                    <span role="alert" style={{ color: "#FF0101", fontSize: 11, marginTop: "5px" }}>
                                        {errorsObj["toDate"]?.message}
                                    </span>
                                )}
                            </FormControl>
                        </Grid>
                        {filter.searchLogs === "Passcode Lock/Unlock Request Logs" && <Grid item md={12} ml={2} mr={2} style={{ width: "100%" }}>
                            <FormControl
                                variant="outlined"
                                size="small"
                                fullWidth
                                sx={{ ...selectStylesOverride(isDarkThemeEnabledSelector) }}
                            >
                                <CustomSelect
                                    selectStylesOverride={selectStylesOverride}
                                    value={elock}
                                    onChange={(e, newVal) => {
                                        handleFilter(e, newVal, "elock");
                                    }}
                                    sx={{
                                        ...inputClasses.filterField,
                                    }}
                                    label="Filter By Device Type"
                                    name={"selectElock"}
                                    errors={errorsObj}
                                    getOptionLabel={(option) => {
                                        return option?.label;
                                    }}
                                    filterSelectedOptions
                                    labelKey="label"
                                    isOptionEqualToValue={(option, value) =>
                                        option.label === value.label && option.value === value.value
                                    }
                                    options={Constant.ELock}
                                />
                            </FormControl>
                        </Grid>}

                    </Grid>
                }
                additionalActions={
                    <Grid container>
                        <Grid item md={12} ml={2} mr={2} style={{ width: "100%" }}>
                            <FormControl sx={{ ...selectStylesOverride(isDarkThemeEnabledSelector), width: '100%' }}>
                                <Button
                                    sx={{
                                        ...buttonClasses.lynkitBlackFill,
                                        minHeight: "36px",
                                        // margin: 2,
                                        width: '100%',
                                        mt: 2,
                                        mb: 1.5,
                                    }}
                                    disabled={downloadloader}
                                    onClick={() => { getReportFields() }}
                                >
                                    Download Report &nbsp;{downloadloader ? <Loader /> : ""}
                                </Button>
                            </FormControl>
                        </Grid>
                    </Grid>
                }
            >
                <Grid container>
                    <Grid item sm={12} xs={12}>
                        <Grid container>
                            <Grid item sm={6} xs={4}>
                                <Typography sx={{ ...textClasses.cardTitle }} data-testid="title">
                                    Logs
                                </Typography>

                            </Grid>
                            <Grid
                                container
                                item
                                sm={6}
                                xs={6}
                                sx={{ justifyContent: "flex-end" }}
                            >
                                {!open && (
                                    <Button
                                        variant="contained"
                                        sx={{
                                            ...buttonClasses?.lynkitOrangeEmpty,
                                            minHeight: "36px",
                                        }}
                                        onClick={() => setOpen(!open)}
                                        startIcon={<FilterAlt />}
                                        data-testid="filtersBtn"
                                    >
                                        Filters
                                    </Button>
                                )}

                            </Grid>
                        </Grid>
                        {loader ? (
                            <Grid
                                container
                                direction={"column"}
                                justifyContent={"center"}
                                alignItems="center"
                                item
                                xs={12}
                                sx={{ p: 15 }}
                            >
                                <Grid item>
                                    <img data-testid="loaderImg" src={loaderGif} />
                                </Grid>
                            </Grid>
                        ) : (
                            <Grid container sx={{ mt: 2 }} minHeight="500px" maxHeight="70vh" >
                                <Grid height="100%" item /* sm={selectedUser ? 8 : 12} */ xs={12}>
                                    {tableData?.length > 0 ? (
                                        <Paper
                                            sx={{

                                                width: "100%",
                                                margin: "auto",
                                                overflow: "auto",
                                                display: "block",
                                                minHeight: "500px",
                                                maxHeight: "82vh",

                                            }}
                                        >
                                            <LogsTable
                                                tableColumns={tableColumns}
                                                data={tableData ? tableData : []}
                                                totalRecords={tableCount}
                                                pagination={filter}
                                                setPagination={setFilter}
                                                themeType={themeType}
                                                handlePaginationChange={handlePaginationChange}
                                            />
                                        </Paper>
                                    ) : (
                                        <>
                                            <Grid
                                                container
                                            // border={'1px solid black'}
                                            // sx={{ justifyContent: "flex-end" }}
                                            >
                                                {/* <p>lll</p> */}
                                                {logsData?.allLogs?.length === 0 ?
                                                    <EmptyPage body={'No matching records found.'} />
                                                    :
                                                    <EmptyPage body={'Please select filters to view data. No matching records found.'} />
                                                }


                                            </Grid>

                                        </>

                                    )}
                                    {/* </Paper> */}
                                </Grid>
                            </Grid>
                        )}
                    </Grid>
                </Grid>

            </FiltersWrapper>
        </>
    )
}


export const Addfifteendays = (date) => {
    const originalDate = new Date(date);

    // Check if the original date is valid
    if (isNaN(originalDate.getTime())) {
        console.error("Invalid date");
    } else {
        // Add 15 days to the original date
        const newDate = new Date(originalDate);
        newDate.setDate(originalDate.getDate() + 15);

        // Check if the new date is valid
        if (isNaN(newDate.getTime())) {
            console.error("Invalid date after adding 15 days");
        } else {
            // Format the new date as a string
            const formattedDate = newDate.toISOString();
            return formattedDate
        }
    }


}

export const Substractfifteendays = (date) => {
    const originalDate = new Date(date);

    // Check if the original date is valid
    if (isNaN(originalDate.getTime())) {
        console.error("Invalid date");
    } else {
        // Add 15 days to the original date
        const newDate = new Date(originalDate);
        newDate.setDate(originalDate.getDate() - 15);

        // Check if the new date is valid
        if (isNaN(newDate.getTime())) {
            console.error("Invalid date after adding 15 days");
        } else {
            // Format the new date as a string
            const formattedDate = newDate.toISOString();
            return formattedDate
        }
    }


}
