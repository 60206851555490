/* eslint-disable */
// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax
import React, { useState, useRef, useEffect } from 'react'
import CameraEventsDrawer from './components/CameraEventsDrawer';
import loaderGif from "../../../static/images/drawer/loader.gif";
import {
  AppBar,
  Box,
  Button,
  Grid,
  IconButton,
  Paper,
  Toolbar,
  Typography,
  capitalize,
  styled,
  useTheme,
} from "@mui/material";
import config, { locationUrl } from "../../../services/config";
import { useDispatch, useSelector } from "react-redux";
import { selectStylesOverride } from "../../../utils/util";
import axios from 'axios'
import Iframe from 'react-iframe'
import history from "../../../services/history";
import { useParams } from "react-router-dom/cjs/react-router-dom";
import { getDeviceDetails, getBsjCameraAlerts, emptyBsjCameraAlerts, emptyDevIceDetails } from "../../../redux/actions";
import speedLogo from "../../../assets/images/speed.png";
import dateTime from "../../../assets/images/dateandtime.png";
import locationLogo from "../../../assets/infoWindow/location.png";
import { convertIntoGivenTimezone, showToast } from "../../../services/functions";
import ThreeDotsLoader from '../../Components/ThreeDots/ThreeDotsLoader';
import BsjCamera from './BsjCamera';
import { bsjCameraConstants, darkStyles } from "../../../services/constants";
import EmptyPage from '../EmptyPage';
import { Fullscreen } from '@mui/icons-material';
import CampaignIcon from '@mui/icons-material/Campaign';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
const Bsj = (props) => {
  let propsShared = props?.location?.state?.tripDetails
  const { id } = useParams();
  let infoWindow = null;
  const dispatch = useDispatch();
  const isDarkThemeEnabledSelector = useSelector(
    (state) => state.theme.themeDark
  );

  const [alertsLoader, setAlertsLoader] = useState(false)
  const [myMap, setMyMap] = useState(null)
  const { currentUserDetail } = useSelector((_) => _?.auth);
  const [audio, setAudio] = useState(false)
  const [currentLocation, setCurrentLocation] = React.useState({});
  const currentTime = new Date();
  const endTime = new Date(currentTime.getTime() - 2 * 60 * 60 * 1000);
  const [channelsCount, setChannelsCount] = useState(null)
  const { deviceDetails, alertListFlag } = useSelector((_) => _?.deviceslist);
  const [zoom, setZoom] = useState(false)
  const bsjCameraAlerts = useSelector((state) => state.tripsData.bsjCameraAlerts);
  const [cameraAlertListing, setCameraAlertListing] = useState([])
  const bsjCount = useSelector((state) => state.tripsData.totalRows);
  const themeType = isDarkThemeEnabledSelector
    ? require("../../../static/styles/darktheme")
    : require("../../../static/styles/theme");
  const [showButton, setShowButton] = useState(false);
  const { buttonClasses } = themeType;
  const [showAudio, setShowAudio] = useState(false)
  const [mapLoaded, setMapLoaded] = useState(false);
  const [alertsFlag, setAlertsFlag] = useState(false)
  const divRef = useRef(null);
  const formatDateTime = (date) => {
    return (
      date.getFullYear() +
      '-' +
      String(date.getMonth() + 1).padStart(2, '0') +
      '-' +
      String(date.getDate()).padStart(2, '0') +
      ' ' +
      String(date.getHours()).padStart(2, '0') +
      ':' +
      String(date.getMinutes()).padStart(2, '0') +
      ':' +
      String(date.getSeconds()).padStart(2, '0')
    );
  };

  React.useEffect(() => {
    if (myMap) {
      const mapStyles = isDarkThemeEnabledSelector ? darkStyles : null;
      myMap.setOptions({ styles: mapStyles });
    }
  }, [isDarkThemeEnabledSelector, myMap])

  React.useEffect(() => {
    dispatch(emptyBsjCameraAlerts())
    let isMounted = true;
    loadGoogleMapsApi()
      .then((google) => {
        if (isMounted) {
          setMapLoaded(true);
        }
      })
      .catch((error) => {
        console.error("Error loading Google Maps API:", error);
      });

    return () => {
      isMounted = false;
    };
  }, []);

  const loadGoogleMapsApi = () => {
    return new Promise((resolve, reject) => {
      if (window.google) {
        resolve(window.google);
      } else {
        const script = document.createElement("script");
        script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyANnEwTq59T8s30-hXFPNOS5t9_OP47i2A&libraries=geometry,drawing,places`;
        script.onload = () => {
          resolve(window.google);
        };
        script.onerror = (error) => {
          reject(error);
        };
        document.body.appendChild(script);
      }
    });
  };

  function getTotalChannels(response) {
    let activeChannels = [];

    if (response.channel_1_Status === true) activeChannels.push(1);
    if (response.channel_2_Status === true) activeChannels.push(2);
    if (response.channel_3_Status === true) activeChannels.push(3);
    if (response.channel_4_Status === true) activeChannels.push(4);

    return activeChannels;
  }

  React.useEffect(() => {
    if (deviceDetails && Object.keys(deviceDetails).length && deviceDetails.formData) {
      setTimeout(() => {
        const parsedData = JSON.parse(deviceDetails.formData);

        const channelStatuses = [
          parsedData.channel_1_Status,
          parsedData.channel_2_Status,
          parsedData.channel_3_Status,
          parsedData.channel_4_Status
        ];

        if (channelStatuses.some(status => status === true)) {
          let activeChannels = getTotalChannels(parsedData);
          setChannelsCount(activeChannels);
        } else {
          setChannelsCount('noChannelPresent');
        }
      }, 500);
    } else {
      setTimeout(() => {
        setChannelsCount('noChannelPresent');
      }, 500);
    }
  }, [deviceDetails && deviceDetails.formData]);


  React.useEffect(() => {
    if (deviceDetails && deviceDetails?.latitude && deviceDetails?.longitude) {
      var pos = {
        lat: Number(deviceDetails?.latitude),
        lng: Number(deviceDetails?.longitude),
      };

      setCurrentLocation(pos);
    }
    else {
      setCurrentLocation({});
    }
  }, [deviceDetails]);

  React.useEffect(() => {
    setTimeout(() => {
      if (typeof google === "undefined" || typeof google.maps === "undefined") {
        return null;
      }
      const mapElement = document.getElementById("viewallmap");

      if (!mapElement) {
        console.error("Element with id 'viewallmap' not found.");
        return null;
      }
      const mapOptions = {
        zoom: currentLocation && Object.keys(currentLocation).length ? 11 : 4,
        center: currentLocation && Object.keys(currentLocation).length ? currentLocation : { lat: 20.5937, lng: 78.9629 },
        mapTypeId: "terrain",
        zoomControl: true,
        zoomControlOptions: {
          style: google.maps.ZoomControlStyle.SMALL,
          position: google.maps.ControlPosition.LEFT_BOTTOM,
        },
      };

      const map = new google.maps.Map(mapElement, mapOptions);
      const marker = new window.google.maps.Marker({
        position: currentLocation,
        map: map,
        title: "Center Marker",
        optimized: false,
      });

      marker?.addListener("click", (event) => {
        if (infoWindow) {
          infoWindow.close();
        }
        const payload = {
          latitude: currentLocation.lat,
          longitude: currentLocation.lng,
          appType: "GOOGLE",
        };
        axios.post(`${locationUrl}fetchAddress`, payload, {
          headers: {
            "Content-Type": "application/json",
            authorization: "oLjJEPvkqYloUOfU1aCyAvOBMQKKG9Am",
          },
        })
          .then((response) => {
            let address = response?.data?.data[0]?.address;
            trackInfoRenderer(address, event, map, marker);


          })
          .catch((error) => {
            console.error("Error:", error);
          });
      });
      setMyMap(map)
      return map;
    }, 40);
  }, [currentLocation, alertsLoader]);

  const trackInfoRenderer = (address, event, map, marker) => {
    let statusColor = ''
    if (deviceDetails.ignition_status == 'IDLE') {
      statusColor = "#f1c232"
    }
    else if (deviceDetails.ignition_status == 'MOVING') {
      statusColor = "#34a853"
    }
    else if (deviceDetails.ignition_status == 'OFFLINE') {
      statusColor = "#000"
    }
    else if (deviceDetails.ignition_status == 'IGNITION_OFF') {
      statusColor = "#b7b7b7"
    }
    else if (deviceDetails.ignition_status == 'STOP') {
      statusColor = "#b7b7b7"
    }
    else {
      statusColor = "#989898"
    }



    infoWindow = new google.maps.InfoWindow({
      content: `
      <div style="padding: 5px; width: 100%; height: 100%; box-sizing: border-box;" class="t-menu-item">
      <div style="display: flex; flex-direction: column; width: 100%; height: 100%;">
        <div style="display: flex; justify-content: space-between; width: 100%;">
          <div style="flex: 1; margin-right: 5px;">
            <div style="display: flex; justify-content: center; align-items: center; height: 60px; border-radius: 5px; background-color: ${statusColor}; box-shadow: 2px 4px 6px rgba(81, 81, 87, 0.29);">
              <div style="width: 100%; text-align: center; color: #fff; padding: 10px;">
                <div style="font-size: 11px;">Device Status</div>
                <div style="font-weight: 700; font-size: 12px;">${deviceDetails?.ignition_status}</div>
              </div>
            </div>
          </div>
          <div style="flex: 1;">
            <div style="display: flex; justify-content: center; align-items: center; height: 60px; border-radius: 5px; background-color: #6495ED; box-shadow: 2px 4px 6px rgba(81, 81, 87, 0.29);">
              <div style="width: 100%; text-align: center; color: #fff; padding: 10px;">
                <div style="font-size: 11px;">Device ID</div>
                <div style="font-weight: 700; font-size: 12px;">${deviceDetails?.client_id}</div>
              </div>
            </div>
          </div>
        </div>
        <div style="display: flex; justify-content: space-between; margin-top: 10px; width: 100%;">
          <div style="flex: 1; text-align: center; border-right: 1px dotted #32333561;">
            <img src="${speedLogo}" width="20" style="margin-bottom: 5px;">
            <h3 style="font-weight: 700; font-size: 12px; color: #e72b2b; margin: 0;">Speed</h3>
            <span style="line-height: 15px; font-size: 11px;">${deviceDetails.speed}</span>
          </div>
          <div style="flex: 1; text-align: center;">
            <img src="${dateTime}" width="20" style="margin-bottom: 5px;">
            <h3 style="font-weight: 700; font-size: 12px; color: #084c5b; margin: 0;">Date & Time</h3>
            <span style="line-height: 15px; font-size: 11px;">${convertIntoGivenTimezone(deviceDetails.packet_time * 1000, currentUserDetail?.timeZone?.offset) || "NA"}</span>
          </div>
        </div>
        <div style="display: flex; margin-top: 10px; width: 100%;">
          <div style="flex: 1; text-align: center;">
            <img src="${locationLogo}" style="width: 35px;">
          </div>
          <div style="flex: 3; font-size: 12px; color: #475a6d; margin-top: 5px;">
            <span style="line-height: 15px;">${deviceDetails.geo_name}, ${address}</span>
          </div>
        </div>
      </div>
    </div>
    
`
    });
    infoWindow.open(map, marker);
  }


  React.useEffect(() => {
    if (id) {
      try {
        dispatch(emptyDevIceDetails())
        setChannelsCount(null)
        setAlertsLoader(true)
        const fetchDeviceDetails = () => {
          dispatch(getDeviceDetails({ id }, () => { }));
        };
        const fetchAlertsList = () => {
          dispatch(getBsjCameraAlerts(
            {
              "startDate": new Date(propsShared?.tripData?.detailsDeviceID?.time),
              "endDate": propsShared?.tripData?.endTime ? new Date(propsShared?.tripData?.endTime) : new Date(),
              "deviceId": propsShared?.tripData?.cameraId,
              "tripId": propsShared?.tripData?._id || null,
              "page": "1",
              "limit": "10",
            }, (res) => {
              if (res?.success) {
                setAlertsFlag(true)
                setAlertsLoader(false)
                setCameraAlertListing(res?.data)
              }
              else {
                setAlertsLoader(false)
                setCameraAlertListing([])
              }

            }
          ))
        }


        fetchDeviceDetails();
        fetchAlertsList();
        const interval = setInterval(() => { fetchDeviceDetails(); fetchAlertsList(); }, 60000);

        return () => clearInterval(interval)
      }
      catch (e) {
        console.log("error initial: ", e)
      }
    }

  }, [id]);

  const handleDivClick = () => {
    if (divRef.current) {
      if (!document.fullscreenElement) {
        setZoom(true)
        divRef.current.requestFullscreen().catch(err => {
        });
      } else {
        setZoom(false)
        document.exitFullscreen();
      }
    }
  };

  function SpeakAudio() {
    let ret = CvNetVideo.StartSpeek(
      id,
      1,
      {
        clusterHost: bsjCameraConstants.server,
        clusterPort: bsjCameraConstants.port,
        protocol: bsjCameraConstants.protocol
      }
    );
    if (!ret) {
      showToast('Failed to obtain the microphone, please confirm that blocking is set.', true)
    }
    else {
      setAudio(true)
    }
  }
  React.useEffect(() => {
    const timer = setTimeout(() => {
      setShowButton(true);
    }, 2000);

    return () => {
      clearTimeout(timer);
      if (propsShared?.tripData?.status !== 'completed') {
        CvNetVideo.Stop();
      }
    };
    
  }, []);


  return (

    <>
      <Box width="100%" height="90vh" position="relative" display="flex">
        <Box
          width="100%"
          height="100%"
          display="flex"
          flexDirection="column"
          mr={0}
        >
          {/* Live camera */}
          <Box width="100%">
            <Paper sx={{ px: 2, py: 1, marginBottom: '8px', boxShadow: 'none', borderBottom: 1, borderColor: "divider" }}>
              <Box
                width="100%"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Box display="flex" alignItems="center" data-testid="page-title">
                  <Typography variant="h6">Live Camera</Typography>

                </Box>
                <Box display="flex" alignItems="center">
                  {showAudio && (
                    !audio && (
                      <Button
                        variant="outlined"
                        size="small"
                        sx={{
                          ...buttonClasses.small,
                          minHeight: "33px",
                          borderColor: themeType.default.themeOrange,
                          color: buttonClasses.lynkitOrangeEmpty,
                          marginRight: "9px",
                        }}
                        onClick={() => SpeakAudio()}
                        startIcon={<CampaignIcon />}
                      >
                        Start Intercom
                      </Button>
                    )
                  )}
                  {audio && (
                    <Button
                      variant="outlined"
                      size="small"
                      sx={{
                        ...buttonClasses.small,
                        minHeight: "33px",
                        borderColor: themeType.default.themeOrange,
                        color: buttonClasses.lynkitOrangeEmpty,
                        marginRight: "9px",
                      }}
                      onClick={() => { CvNetVideo.StopSpeak(); setAudio(false) }}
                      startIcon={<VolumeOffIcon />}
                    >
                      Stop Intercom
                    </Button>
                  )}
                  {showButton && (

                    <Button variant="contained" sx={{ ...buttonClasses?.lynkitOrangeFill, minHeight: "33px", }}
                      onClick={() => {
                        if (propsShared?.tripData?.status !== 'completed') {
                          CvNetVideo.Stop();
                        }
                        history.push({
                          pathname: `/dashboard/camera-playback/${id}`,
                          state: propsShared
                        });

                      }}
                    >
                      Playback
                    </Button>
                  )}

                  <IconButton sx={{ ml: 1 }} size="large" onClick={() => handleDivClick()}>
                    <Fullscreen />
                  </IconButton>
                </Box>
              </Box>
            </Paper>
          </Box>

          <Box width="100%" height="100%" className='boxFullSize' ref={divRef} size="large" id='fullscreenDiv'>

            {propsShared?.tripData?.status === 'completed' ?
              <Grid sx={{ height: '100%', minHeight: '100px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Typography>Trip is completed.</Typography>
              </Grid>
              :
              channelsCount === null ? (
                <Grid
                  container
                  item={true}
                  direction={"column"}
                  justifyContent={"center"}
                  alignItems="center"
                  xs={12}
                  sx={{ p: 15 }}
                >
                  <Grid item>
                    <img src={loaderGif} />
                  </Grid>
                </Grid>
              )
                : channelsCount === "noChannelPresent" ?
                  <Grid
                    container
                    item={true}
                    direction={"column"}
                    justifyContent={"center"}
                    alignItems="center"
                    xs={12}
                    sx={{ p: 15 }}
                  >
                    <Grid item>
                      <Typography>No Active Channel.</Typography>
                    </Grid>
                  </Grid>
                  :
                  <Grid sx={{ height: '100%', minHeight: '100%' }}>
                    <BsjCamera setShowAudio={setShowAudio} zoom={zoom} channelsCount={channelsCount} deviceDetails={deviceDetails}/>
                  </Grid>
            }
          </Box>
        </Box>
        <CameraEventsDrawer alertsFlag={alertsFlag} mapLoaded={mapLoaded} id={id} alertsLoader={alertsLoader} cameraAlerts={cameraAlertListing} propsShared={propsShared} bsjCount={bsjCount} themeType={themeType} deviceDetails={deviceDetails} currentTime={currentTime} endTime={endTime} formatDateTime={formatDateTime} alertListFlag={alertListFlag} isDarkThemeEnabledSelector={isDarkThemeEnabledSelector} setCameraAlertListing={setCameraAlertListing} />
      </Box>
    </>

  );
};

export default Bsj;







