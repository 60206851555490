import { Box, Typography } from "@mui/material";
import React from "react";

const Unauthorized = () => {
  return (
    <Box display="flex" justifyContent="center" alignItems="center">
      <Typography variant="h3" sx={{ textAlign: "center" }}>
        Sorry, you don't have access to this page.
      </Typography>
    </Box>
  );
};

export default Unauthorized;
