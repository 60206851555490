import { showToast } from "../../services/functions";
import { DRAWER_OPEN, DRAWER_CLOSE, FETCH_START } from "../types";
import axios from "axios";

export const openDrawer = () => {
  return {
    type: DRAWER_OPEN,
  };
};

export const closeDrawer = () => {
  return {
    type: DRAWER_CLOSE,
  };
};

export const downloadFile = (
  payload = "https://lynktrac-test.s3.ap-south-1.amazonaws.com/Sample_File_2023-10-30T08%3A25%3A33.991Z.xlsx?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Credential=AKIA4JOUSSMKNK5XFNHC%2F20231030%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20231030T082534Z&X-Amz-Expires=86400&X-Amz-Signature=d2b538e14e528c91c07e8c57f57ab84a61370b7e100e266a7edb503e0a89844b&X-Amz-SignedHeaders=host&x-id=GetObject",
  cb
) => {
  return async (dispatch) => {
    dispatch({ type: FETCH_START });
    axios
      .get(payload, {
        responseType: "blob",
      })
      .then(({ data }) => {
        // Create a Blob object from the response data
        const blob = new Blob([data.data], {
          type: "application/vnd.ms-excel",
        });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = "Sample_File.xlsx";
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => {
        showToast("Some problem occurred while downloading file", true);
      });
  };
};
