import { io } from "socket.io-client";
import { disconnectSocket, socketConnection } from "../../socket";
import config from "../../services/config";


//new ---
export const connectToSocket = (queryData) => {
  return (dispatch, getState) => {
    const { socket } = getState();
    if (!socket.connected) { // Check if socket is not already connected
      // const socketConnection = io('https://testv2.lynktrac.io/', {
        const socketConnection = io(config?.lynctracBaseURL, {
        transports: ["websocket"],
        query: queryData,
      });

      socketConnection.on('connect', (res) => {
        dispatch({ type: 'SOCKET_CONNECTED' });
      });

      dispatch({ type: 'SOCKET_INSTANCE', payload: socketConnection });
    }
  };
};

export const disconnectFromSocket = () => {
  return (dispatch, getState) => {
    const {socket} = getState();
    let tempSocketInstance = socket?.socketInstance;
    if(tempSocketInstance){
      tempSocketInstance.disconnect();
      dispatch({ type: 'SOCKET_DISCONNECTED' });
      tempSocketInstance.on("disconnect", () => {
        console.log("disconnected from socket");
      })
    }
    dispatch({ type: 'SOCKET_INSTANCE', payload: tempSocketInstance });
  };
}

export const logoutFromSocket = (userId) => {
  return (dispatch, getState) => {
    const {socket} = getState();
    let tempSocketInstance = socket?.socketInstance;
    if(tempSocketInstance){
      tempSocketInstance.emit("logout", { userId });
    }
    dispatch({ type: 'SOCKET_INSTANCE', payload: tempSocketInstance });
  }
}

/// -------- old
export const connectSocket = (queryObj) => {
  return dispatch => {
    socketConnection(queryObj);
    // Additional actions if needed after successful connection
    dispatch({ type: 'SOCKET_CONNECTED' });
  };
};

export const disconnectReduxSocket = () => {
  return dispatch => {
    disconnectSocket();
    // Additional actions if needed after disconnection
    dispatch({ type: 'SOCKET_DISCONNECTED' });
  };
};