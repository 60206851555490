import React, { useEffect, useState } from "react";
import CustomModel from "../../../Components/CustomModels/CustomModel1";
import {
  Box,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  Tooltip,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  addAssignCommand,
  deleteCommand,
  getCommands,
  getUserCommands,
} from "../../../../redux/actions";
import { selectStylesOverride } from "../../../../utils/util";
import CustomSelect from "../../../../components/FormUI/CustomSelect";
import CustomSubmitButton from "../../../../components/FormUI/CustomSubmitBtn";
import EmptyPage from "../../EmptyPage";
import { showToast } from "../../../../services/functions";
import CustomMultiSelect from "../../../../components/FormUI/CustomMultiSelect";
import { Cancel, DeleteOutline } from "@mui/icons-material";
import DataTable from "../../../Components/DataTable/DataTable";

const CommandModal = ({ open, setCommandModal, themeType, userDetails }) => {
  const dispatch = useDispatch();

  const [selectedCommands, setSelectedCommands] = useState([]);
  const [otpNeeded, setOtpNeeded] = useState(null);
  const [showAll, setShowAll] = useState(false);

  const isDarkThemeEnabledSelector = useSelector(
    (state) => state.theme.themeDark
  );
  const { allCommands, allUserCommands, userCommandsLoading } = useSelector(
    (state) => state?.deviceslist
  );
  const { textClasses, buttonClasses } = themeType;

  const otpOptions = [
    { label: "Yes", value: "yes" },
    { label: "No", value: "no" },
  ];

  const commandTableColumns = [
    {
      header: "#",
      cell: (entry, i) => {
        return (
          <Typography
            sx={{
              ...textClasses.t13n,
              textAlign: "center",
              textTransform: "capitalize",
            }}
          >
            {i + 1}
            {/* {(filter?.pageCount - 1) * filter?.pageLimit + i + 1} */}
          </Typography>
        );
      },
    },
    {
      header: "Command Name",
      cell: (entry, i) => {
        return (
          <Typography
            sx={{
              ...textClasses.t13n,
              textAlign: "center",
              textTransform: "capitalize",
            }}
          >
            {entry?.command?.commandName ?? "-"}
          </Typography>
        );
      },
    },
    {
      header: "OTP Needed",
      cell: (entry, i) => {
        return (
          <Typography
            sx={{
              ...textClasses.t13n,
              textAlign: "center",
              textTransform: "capitalize",
            }}
          >
            {entry?.otpNeeded ?? "-"}
          </Typography>
        );
      },
    },
    {
      header: "Action",
      cell: (entry, i) => {
        return (
          <IconButton
            onClick={() => {
              if (entry?._id) {
                dispatch(
                  deleteCommand({ assignId: entry?._id }, (res) => {
                    if (res && res?.success) {
                      dispatch(
                        getUserCommands({
                          userId: userDetails?._id,
                        })
                      );
                    }
                  })
                );
              }
            }}
          >
            <DeleteOutline sx={{ color: themeType.default.themeOrange }} />
          </IconButton>
        );
      },
    },
  ];

  const handleToggleSelectAllCommands = (e) => {
    if (allCommands?.length > 0) {
      if (e?.target?.checked) {
        setSelectedCommands(allCommands);
      } else {
        setSelectedCommands([]);
      }
    }
  };

  const handleRemoveSelection = (st) => {
    const updatedCommands = selectedCommands?.filter((command) => {
      return command?._id !== st?._id;
    });
    setSelectedCommands(updatedCommands);
  };

  const hide = () => {
    setCommandModal(false);
  };

  const addUserCommand = () => {
    if (!selectedCommands || selectedCommands?.length === 0) {
      showToast("Please select a command", true);
      return;
    }
    if (!otpNeeded) {
      showToast("Please select if OTP is needed or not", true);
      return;
    }

    let commands = selectedCommands?.map((_) => {
      return {
        commandId: _?._id,
        otpNeeded: otpNeeded,
      };
    });

    const payload = {
      userId: userDetails?._id,
      commands: commands,
    };

    dispatch(
      addAssignCommand({ ...payload }, (res) => {
        if (res && res?.success) {
          dispatch(
            getUserCommands({
              userId: userDetails?._id,
            })
          );
          setSelectedCommands([]);
          setOtpNeeded(null);
        }
      })
    );
  };

  useEffect(() => {
    if (userDetails) {
      dispatch(
        getUserCommands({
          userId: userDetails?._id,
        })
      );
    }
  }, [userDetails]);

  useEffect(() => {
    dispatch(getCommands());
  }, []);

  return (
    <CustomModel
      autoClose={false}
      show={open || false}
      onClose={() => hide()}
      minWidth={650}
      maxWidth={650}
      pb={3}
      pl={3}
      pr={3}
      maxHeight={"81vh"}
      childrenMaxHeight={"73vh"}
      Title="Assign Commands"
      viewCloseIcon={open}
    >
      <Box maxHeight="73vh" sx={{ overflowY: "auto", position: "relative" }}>
        <Grid container my={2} rowGap={2} columnSpacing={2}>
          <Grid item xs={12} md={8}>
            <CustomMultiSelect
              value={selectedCommands || []}
              required
              selectStylesOverride={selectStylesOverride(
                isDarkThemeEnabledSelector
              )}
              onChange={(e, newVal) => {
                // let idSet = newVal?.map((_) =>
                //   typeof _ !== "string" ? _?._id : _
                // );
                // onChange(newVal);
                setSelectedCommands(newVal);
              }}
              label="Commands"
              // options={
              //   allCommands &&
              //   allCommands?.filter((_) =>
              //     selectedCommands && selectedCommands?.length > 0
              //       ? !selectedCommands
              //           ?.map((existing) => existing?._id)
              //           ?.includes(_?._id)
              //       : allCommands
              //   )
              // }
              renderOptions={(props, option, { selected }) => {
                return (
                  <MenuItem
                    value={selectedCommands || ""}
                    selected={selected}
                    {...props}
                  >
                    <Typography
                      sx={{
                        fontSize: ".7rem",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {`${option?.commandName} | ${option?.deviceModel}` || ""}
                    </Typography>
                  </MenuItem>
                );
              }}
              options={allCommands || []}
              allSelected={
                selectedCommands &&
                selectedCommands?.length === allCommands?.length
              }
              handleToggleSelectAll={(e) => handleToggleSelectAllCommands(e)}
              labelKey="commandName"
              disableCloseOnSelect={true}
              renderTags={() => null}
              getOptionLabel={(option) => {
                return `${option?.commandName} | ${option?.deviceModel}` || "";
              }}
            />
          </Grid>
          <Grid item xs={12} md>
            <CustomSelect
              label="OTP Needed"
              selectStylesOverride={selectStylesOverride(
                isDarkThemeEnabledSelector
              )}
              options={otpOptions || []}
              value={otpNeeded || null}
              labelKey="label"
              onChange={(e, newValue, reason) => {
                setOtpNeeded(newValue?.value);
              }}
              getOptionLabel={(option) => {
                if (typeof option === "string") {
                  return (
                    otpOptions?.find((_) => _?.value == otpNeeded)?.label || ""
                  );
                } else {
                  return option?.label || "";
                }
              }}
            />
          </Grid>
        </Grid>
        {selectedCommands && selectedCommands?.length ? (
          <Grid container columnSpacing={2} rowSpacing={1}>
            {allCommands &&
              selectedCommands &&
              selectedCommands
                ?.slice(0, showAll ? selectedCommands?.length : 5)
                ?.map((st, i) => {
                  return (
                    <Grid item sm={4} xs={6} key={i}>
                      <Grid
                        container
                        direction={"row"}
                        justifyContent="center"
                        alignItems={"center"}
                        sx={{
                          backgroundColor: themeType.default.themeLightOrange,
                          border: `1px solid ${themeType.default.themeOrange}`,
                          borderRadius: "12px",
                          pl: 1,
                          pr: 1,
                          minHeight: "35px",
                        }}
                      >
                        <Grid item sm={10} xs={8}>
                          <Typography
                            sx={{
                              ...textClasses.normalText,
                              color: themeType.default.themeOrange,
                              textAlign: "start",
                            }}
                          >
                            {st?.commandName && st?.commandName?.length > 15
                              ? st?.commandName?.substring(0, 15) + "..."
                              : st?.commandName}
                          </Typography>
                        </Grid>
                        {handleRemoveSelection && (
                          <Grid
                            item
                            sm={2}
                            xs={4}
                            display="flex"
                            justifyContent="end"
                          >
                            <IconButton
                              sx={{ alignContent: "right", padding: "5px" }}
                              onClick={() => handleRemoveSelection(st)}
                            >
                              <Cancel
                                sx={{ color: themeType.default.themeOrange }}
                              />
                            </IconButton>
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                  );
                })}

            {selectedCommands && selectedCommands?.length > 5 && (
              <>
                <Grid
                  container
                  item
                  sm={4}
                  xs={6}
                  justifyContent="start"
                  alignItems={"center"}
                >
                  <Typography
                    sx={{
                      ...textClasses.boldText,
                      color: themeType.default.themeOrange,
                      textDecoration: "underline",
                      cursor: "pointer",
                    }}
                    onClick={() => setShowAll(!showAll)}
                  >
                    {showAll ? (
                      <>Collapse</>
                    ) : (
                      <>View All ({selectedCommands?.length})</>
                    )}
                  </Typography>
                </Grid>
              </>
            )}
          </Grid>
        ) : null}
        <Grid container>
          {!allUserCommands || allUserCommands?.length === 0 ? (
            <Box
              width="100%"
              mb={9}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <EmptyPage />
            </Box>
          ) : (
            <Paper
              sx={{
                height: "100%",
                width: "100%",
                maxHeight: "50vh",
                overflow: "auto",
                display: "block",
                marginBottom: 2,
                marginTop: 2,
              }}
            >
              <DataTable
                tableColumns={commandTableColumns}
                data={allUserCommands ?? []}
                themeType={themeType}
              />
            </Paper>
          )}
        </Grid>

        <Grid
          container
          display="flex"
          justifyContent="center"
          mb={2}
          sx={{
            position: "sticky",
            bottom: 0,
            left: 0,
            bgcolor: "background.paper",
            py: 1,
          }}
        >
          <CustomSubmitButton
            variant="contained"
            sx={{
              ...buttonClasses?.lynkitOrangeFill,
              minHeight: "36px",
              minWidth: "140px",
            }}
            disabled={userCommandsLoading}
            loading={userCommandsLoading}
            onClick={addUserCommand}
            label={"Add"}
          />
        </Grid>
      </Box>
    </CustomModel>
  );
};

export default CommandModal;
