import React from 'react';

const GeoIcon = ({ height, width, fill }) => (
    <svg id="Layer_1" height={height} width={width} data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 211.68 282.41" fill={fill}>
    <defs>
    <style>{`
      .cls-1 { fill: ${fill} !important; }
      .cls-2 { fill: ${fill} !important; }
    `}</style>
    </defs>
    <path fill={fill} class="cls-1" d="m99.22,1.08c1.08,0,3.03.61,4.55.91,24.04,4.71,40.06,18.76,47.98,41.8,3.88,11.29,3.77,22.77-.17,34.23-5.04,14.67-12.09,28.36-19.8,41.73-11,19.08-23.21,37.37-36.17,55.17-1.59,2.18-2.17,2.19-3.76,0-16.75-23.16-32.51-46.94-45.54-72.43-4.74-9.28-8.89-18.82-11.72-28.9-2.91-10.37-1.94-20.59,1.36-30.63,4.18-12.74,11.88-23.01,22.84-30.76,7.9-5.58,16.85-9.74,26.44-11.11C85.23,1.08,89.53,0,90.61,0c4.3,0,3.23,0,8.61,1.08Zm-5.48,82.66c12,0,21.77-9.74,21.81-21.75.04-11.93-9.78-21.8-21.74-21.84-12.01-.04-21.97,9.9-21.9,21.87.07,12.02,9.84,21.73,21.84,21.72Z"/>
    <path fill={fill} class="cls-1" d="m211.68,180.2l-39.97-51.01-6.4-29.19-22.03-2.83c-.88,1.85-1.79,3.69-2.71,5.52l9.57,1.23,10.41,1.3,5.81,26.49,37.9,48.37-30.59,36.57,13.6,59.99H24.81L6.21,196.28l26.47-37.37L10.18,85.86l30.56,3.94c-.88-2.04-1.72-4.08-2.51-6.16l-36.2-4.66,24.31,78.91L0,195.06l20.22,87.35h174.28l-14.57-64.25,31.75-37.97Z"/>
    <g>
      <polygon fill={fill} class="cls-1" points="104.1 174.21 180.56 257.88 170.46 218.93 112.75 161.22 104.1 174.21"/>
      <path fill={fill} class="cls-1" d="m115.64,156.89l56.27,56.27,12.13-15.31-56.82-58.4c-2.75,4.2-8.76,13.28-11.58,17.44Z"/>
      <path fill={fill} class="cls-1" d="m34.84,94.85l-19.62-1.82,54.25,60.98c-2.52-5.34-31.41-53.33-34.63-59.15Z"/>
      <path fill={fill} class="cls-1" d="m163.25,133.81l-4.33-24.53-11.54-1.44h-.12c-1.26,2.12-15.92,26.73-17.2,28.85l57.71,57.71,12.98-14.43-37.51-46.17Z"/>
      <polygon fill={fill} class="cls-1" points="28.46 272.31 49.27 272.31 18.97 239.13 28.46 272.31"/>
      <path fill={fill} class="cls-1" d="m96.54,184.53c-2.39,3.28-3.26,3.29-5.65-.01-5.84-8.07-12.89-15.1-18.54-23.29l-53.38-57.71,10.82,32.46,133.45,137.78h20.2l-4.33-11.54-77.91-85.12c-.19.25-4.48,7.18-4.67,7.43Z"/>
      <polygon fill={fill} class="cls-1" points="27.95 171.55 131.51 273.75 157.48 273.75 33.4 146.79 36.29 159.78 27.95 171.55"/>
      <polygon fill={fill} class="cls-1" points="20.03 233.02 57.92 272.31 86.78 272.31 13.2 200.17 20.03 233.02"/>
      <polygon fill={fill} class="cls-1" points="12.98 192.67 95.44 273.75 124.29 273.75 24.74 177.09 12.98 192.67"/>
    </g>
  </svg>
);

export default GeoIcon;