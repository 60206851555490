import {
    FETCH_START,
    FETCH_SUCCESS,
    FETCH_ERROR,
    GET_TRIP_DATA_BY_TRIP_ID,
    TRIP_GEOFENCE_WITH_ROUTE,
    TRIP_GEOFENCE_WITHOUT_ROUTE,
    GET_TRIP_EVENTS,
    EMPTY_TRIP_DATA,
    EMPTY_TRIP_ROUTE,
    MAP_TRACK,
    MARKER_DATA,
    EMPTY_MARKER_EVENT
  } from "../types";
  import axios, { geoAxios } from "../../services/Api";
  import { showToast } from "../../services/functions";
  import history from "../../services/history";
  
  export const getTripDataByTripId = (payload, cb) => {
    return async (dispatch) => {
      dispatch({ type: FETCH_START });
      dispatch({type: EMPTY_TRIP_DATA})
      await geoAxios
        .post("/getTripDataByTripId", { ...payload })
        .then(({ data }) => {
          if (data.success) {
            callback(data);
          }else{
            cb({success: false, message: data?.message});
          }
          function callback(data) {
            dispatch({ type: FETCH_SUCCESS });
            dispatch({
              type: GET_TRIP_DATA_BY_TRIP_ID,
              payload: { rows: data.data, count: data.count },
            });
            if (cb) {
              cb(data);
            }
          }
        })
        .catch(function (error) {
          dispatch({ type: FETCH_ERROR, payload: error.message });
          dispatch({ type: GET_TRIP_DATA_BY_TRIP_ID, payload: { rows: [], count: 0 } });
          console.log("Error****:", error.message);
          if (cb) {
            cb({success: false, message: error?.response?.data?.message});
            // cb(error);
          }
        });
    };
  };

  export const tripGeofenceWithRoute = (payload, cb) => {
    return async (dispatch) => {
      dispatch({ type: FETCH_START });
      dispatch({ type: EMPTY_TRIP_ROUTE });
      await geoAxios
        .post("/getGeofencesForTripWithRoute", { ...payload })
        .then(({ data }) => {
          if (data.success) {
            callback(data);
          }else{
            cb({success: false, message: data?.message});
          }
          function callback(data) {
            dispatch({ type: FETCH_SUCCESS });
            dispatch({
              type: TRIP_GEOFENCE_WITH_ROUTE,
              payload: { rows: data.data, count: data.count },
            });
            if (cb) {
              cb(data);
            }
          }
        })
        .catch(function (error) {
          dispatch({ type: FETCH_ERROR, payload: error.message });
          dispatch({ type: TRIP_GEOFENCE_WITH_ROUTE, payload: { rows: [], count: 0 } });
          console.log("Error****:", error.message);
          if (cb) {
            cb({success: false, message: error?.response?.data?.message});
            // cb(error);
          }
        });
    };
  };
  export const tripGeofenceWithoutRoute = (payload, cb) => {
    return async (dispatch) => {
      dispatch({ type: FETCH_START });
      dispatch({ type: EMPTY_TRIP_ROUTE });
      await geoAxios
        .post("/getGeofencesForTripWithoutRoute", { ...payload })
        .then(({ data }) => {
          if (data.success) {
            callback(data);
          }else{
            cb({success: false, message: data?.message});
          }
          function callback(data) {
            dispatch({ type: FETCH_SUCCESS });
            dispatch({
              type: TRIP_GEOFENCE_WITHOUT_ROUTE,
              payload: { rows: data.data, count: data.count },
            });
            if (cb) {
              cb(data);
            }
          }
        })
        .catch(function (error) {
          dispatch({ type: FETCH_ERROR, payload: error.message });
          dispatch({ type: TRIP_GEOFENCE_WITHOUT_ROUTE, payload: { rows: [], count: 0 } });
          console.log("Error****:", error.message);
          if (cb) {
            cb({success: false, message: error?.response?.data?.message});
            // cb(error);
          }
        });
    };
  };
  export const emptyTripObj = (payload, callback) => {
    return (dispatch) => {
      dispatch({ type: EMPTY_TRIP_ROUTE });
  }}
  export const emptyTripData = (payload, callback) => {
    return (dispatch) => {
      dispatch({ type: EMPTY_TRIP_DATA });
  }}
  export const getTripEvents = (payload, cb) => {
    return async (dispatch) => {
      dispatch({ type: FETCH_START });
      await geoAxios
        .post("/getTripEvents", { ...payload })
        .then(({ data }) => {
          if (data.success) {
            callback(data);
          }else{
            cb({success: false, message: data?.message});
          }
          function callback(data) {
            dispatch({ type: FETCH_SUCCESS });
            dispatch({
              type: GET_TRIP_EVENTS,
              payload: { rows: data.data, count: data.count },
            });
            if (cb) {
              cb(data);
            }
          }
        })
        .catch(function (error) {
          dispatch({ type: FETCH_ERROR, payload: error.message });
          dispatch({ type: GET_TRIP_EVENTS, payload: { rows: [], count: 0 } });
          console.log("Error****:", error.message);
          if (cb) {
            cb({success: false, message: error?.response?.data?.message});
            // cb(error);
          }
        });
    };
  };
  export const mapTrack = (payload, cb) => {
    return async (dispatch) => {
      dispatch({ type: FETCH_START });
      await geoAxios
        .get(`/getTripMapTrack?trip_Id=${payload.tripId}&deviceId=${payload.deviceId}`, { ...payload })
        .then(({ data }) => {
          if (data.success) {
            callback(data);
          }else{
            cb({success: false, message: data?.message});
          }
          function callback(data) {
            dispatch({ type: FETCH_SUCCESS });
            dispatch({
              type: MAP_TRACK,
              payload: { rows: data, count: data.count },
            });
            if (cb) {
              cb(data);
            }
          }
        })
        .catch(function (error) {
          dispatch({ type: FETCH_ERROR, payload: error.message });
          dispatch({ type: MAP_TRACK, payload: { rows: [], count: 0 } });
          console.log("Error****:", error.message);
          if (cb) {
            cb({success: false, message: error?.response?.data?.message});
            // cb(error);
          }
        });
    };
  };
  export const markerEvent = (payload, cb) => {
    return async (dispatch) => {
      dispatch({ type: FETCH_START });
      dispatch({ type: EMPTY_MARKER_EVENT });
      await geoAxios
        .post(`/getTripMapPacketData`, { ...payload })
        .then(({ data }) => {
          if (data.success) {
            callback(data);
          }else{
            cb({success: false, message: data?.message});
          }
          function callback(data) {
            dispatch({ type: FETCH_SUCCESS });
            dispatch({
              type: MARKER_DATA,
              payload: { rows: data, count: data.count },
            });
            if (cb) {
              cb(data);
            }
          }
        })
        .catch(function (error) {
          dispatch({ type: FETCH_ERROR, payload: error.message });
          dispatch({ type: MARKER_DATA, payload: { rows: [], count: 0 } });
          console.log("Error****:", error.message);
          if (cb) {
            cb({success: false, message: error?.response?.data?.message});
            // cb(error);
          }
        });
    };
  };