import {
  Radio,
  RadioGroup,
  Checkbox,
  Grid,
  FormControlLabel,
  Typography,
  FormControl,
  IconButton,
  Button,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectStylesOverride } from "../../../../utils/util";
import { useForm, Controller } from "react-hook-form";
import CustomSelect from "../../../../components/FormUI/CustomSelect";
import CustomMultiSelect from "../../../../components/FormUI/CustomMultiSelect";
import { Cancel } from "@mui/icons-material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Box from "@mui/material/Box";

const DeviceSection = ({
  _handleSelect,
  handleRemoveSelection,
  deviceRadio,
  setDeviceRadio,
  deviceTypeList,
  devices,
  searchFields,
  setSearchFields,
  handleSearchDeviceType,
  handleSearchDevice,
  selectedDeviceCategory,
  setSelectedDeviceCategory,
  selectedDevice,
  deviceSelected,
  showAllDevice,
  setShowAllDevice,
  deviceSize,
  permissions,
  handleAddDevice,
  handleCheckboxChange,
  control,
  errors,
  handleSubmit,
  setValue,
  onSubmit,
  title = "Assign Device(s)",
}) => {
  const isDarkThemeEnabledSelector = useSelector(
    (state) => state.theme.themeDark
  );
  const themeType = isDarkThemeEnabledSelector
    ? require("../../../../static/styles/darktheme")
    : require("../../../../static/styles/theme");
  const { buttonClasses, cardClasses, inputClasses, textClasses } = themeType;

  return (
    <Grid container>
      <Grid item sm={12} mt={2} ml={0.2}>
        <Accordion disableGutters={true}>
          <AccordionSummary
            sx={{ background: themeType.default.themeOrange }}
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography sx={{ ...textClasses.boldTextBlack }}>
              {title}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              User can be able to view the assigned devices and can operate the
              as per the assigned devices permissions.
            </Typography>
            <Grid
              container
              borderRadius={2}
              border="1px solid #E0E0E0"
              my={0}
              mt={2}
              p={2}
              sx={{ width: "100%" }}
            >
              <Grid item container spacing={2} mb={0} md={12}>
                <Grid item md={3}>
                  <Controller
                    name="radio-buttons-group-device"
                    control={control}
                    render={({ field }) => (
                      <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        name="radio-buttons-group-device"
                        defaultValue={deviceRadio}
                        {...field}
                      >
                        <FormControlLabel
                          sx={{
                            color: "rgb(128, 128, 128)",
                            "& .MuiFormControlLabel-label": {
                              fontSize: "13px",
                            },
                          }}
                          checked={deviceRadio === "None"}
                          value="None"
                          label="None"
                          control={
                            <Radio
                              size="small"
                              sx={{
                                "&, &.Mui-checked": {
                                  color: themeType.default.themeOrange,
                                },
                              }}
                            />
                          }
                        />
                        <FormControlLabel
                          sx={{
                            color: "rgb(128, 128, 128)",
                            "& .MuiFormControlLabel-label": {
                              fontSize: "13px",
                            },
                          }}
                          value="All"
                          label="All"
                          checked={deviceRadio === "All"}
                          control={
                            <Radio
                              size="small"
                              sx={{
                                "&, &.Mui-checked": {
                                  color: themeType.default.themeOrange,
                                },
                              }}
                            />
                          }
                        />
                        {/* <FormControlLabel
                          sx={{
                            color: "rgb(128, 128, 128)",
                            "& .MuiFormControlLabel-label": {
                              fontSize: "13px",
                            },
                          }}
                          checked={deviceRadio === "select"}
                          value="select"
                          label="Select Device(s)"
                          control={
                            <Radio
                              size="small"
                              sx={{
                                "&, &.Mui-checked": {
                                  color: themeType.default.themeOrange,
                                },
                              }}
                            />
                          }
                        /> */}
                      </RadioGroup>
                    )}
                  />
                  {errors["radio-buttons-group-device"] && (
                    <p style={{ color: "red", fontSize: "11px" }}>
                      {errors["radio-buttons-group-device"].message}
                    </p>
                  )}
                </Grid>
                {deviceRadio === "select" ? (
                  <Grid
                    item
                    md={9}
                    mt={"auto"}
                    sx={{ display: "flex", flexDirection: "row" }}
                  >
                    <FormControl
                      variant="outlined"
                      size="small"
                      fullWidth
                      sx={{
                        ...selectStylesOverride(isDarkThemeEnabledSelector),
                      }}
                    >
                      <Controller
                        name="deviceType"
                        control={control}
                        render={({ field: { onChange, value, name } }) => {
                          return (
                            <CustomSelect
                              selectStylesOverride={selectStylesOverride}
                              value={value || null}
                              onChange={(e, newVal, reason) => {
                                if (reason === "clear") {
                                  setSelectedDeviceCategory(null);
                                  onChange(undefined);
                                  setValue("deviceType", undefined);
                                } else {
                                  onChange(newVal?.category);
                                  setValue("deviceType", newVal?.category);
                                }
                              }}
                              name={name}
                              label="Device Type"
                              searchValue={searchFields?.deviceType}
                              onSearch={handleSearchDeviceType}
                              onInputChange={(e) => {
                                if (e?.type === "change") {
                                  if (e?.target?.value?.trim() === "") {
                                    handleSearchDeviceType(true);
                                  } else {
                                    setSearchFields((prev) => ({
                                      ...prev,
                                      deviceType: e?.target?.value?.trim(),
                                    }));
                                  }
                                }
                              }}
                              getOptionLabel={(option) => {
                                if (typeof option === "string") {
                                  return (
                                    deviceTypeList?.find(
                                      (_) => _?.category == value
                                    )?.label || ""
                                  );
                                } else {
                                  return option?.label || "";
                                }
                              }}
                              labelKey="label"
                              options={deviceTypeList && deviceTypeList}
                            />
                          );
                        }}
                      />
                    </FormControl>

                    <FormControl
                      variant="outlined"
                      size="small"
                      fullWidth
                      sx={{
                        ...selectStylesOverride(isDarkThemeEnabledSelector),
                      }}
                    >
                      <Controller
                        name="device"
                        control={control}
                        render={({ field: { onChange, value, name } }) => {
                          return (
                            <CustomSelect
                              selectStylesOverride={selectStylesOverride}
                              value={value || null}
                              disabled={!selectedDeviceCategory}
                              onChange={(e, newVal) => {
                                onChange(newVal?.client_id);
                                setValue("device", newVal?.client_id);
                              }}
                              getOptionDisabled={(option) =>
                                selectedDevice
                                  ? selectedDevice.some(
                                      (device) =>
                                        device.clientId === option.client_id
                                    )
                                  : false
                              }
                              name={name}
                              label={
                                !selectedDeviceCategory
                                  ? "Select device model first"
                                  : "Device"
                              }
                              sx={{ marginLeft: "5px" }}
                              searchValue={searchFields?.devices}
                              onSearch={handleSearchDevice}
                              onInputChange={(e) => {
                                if (e?.type === "change") {
                                  if (e?.target?.value?.trim() === "") {
                                    handleSearchDevice(true);
                                  } else {
                                    setSearchFields((prev) => ({
                                      ...prev,
                                      devices: e?.target?.value?.trim(),
                                    }));
                                  }
                                }
                              }}
                              getOptionLabel={(option) => {
                                if (typeof option === "string") {
                                  return devices?.find(
                                    (_) => _?.client_id == value
                                  )?.client_id;
                                } else {
                                  return option?.client_id;
                                }
                              }}
                              labelKey="client_id"
                              options={
                                devices &&
                                devices.filter(
                                  (item) =>
                                    !selectedDevice.some(
                                      (selected) => selected === item._id
                                    )
                                )
                              }
                            />
                          );
                        }}
                      />
                    </FormControl>

                    <Button
                      disabled={!deviceSelected}
                      onClick={handleAddDevice}
                      variant="outlined"
                      fullWidth
                      size="small"
                      sx={{
                        ...buttonClasses.lynkitOrangeFill,
                        marginLeft: "5px",
                      }}
                    >
                      Add Device
                    </Button>
                  </Grid>
                ) : null}
              </Grid>
            </Grid>
          </AccordionDetails>

          {(deviceRadio === "select" && selectedDeviceCategory) ||
          (deviceRadio === "All" && selectedDevice?.length === 0) ? (
            <Grid
              container
              borderRadius={"12px"}
              border="1px solid #E0E0E0"
              sx={{ width: "97%", margin: "auto", marginBottom: "10px" }}
            >
              <Box p={1} component="fieldset">
                <Typography sx={{ fontSize: "20px", fontWeight: "700" }}>
                  Device Permission(s)
                </Typography>
                <Grid container>
                  {Object.keys(permissions)
                    ?.slice(0)
                    .map((key, i) => {
                      if (key !== "label") {
                        return (
                          <Grid item md={4} key={i}>
                            <FormControlLabel
                              sx={{
                                color: "rgb(128, 128, 128)",
                                "& .MuiFormControlLabel-label": {
                                  fontSize: "13px",
                                },
                              }}
                              label={permissions[key]?.label}
                              control={
                                <Checkbox
                                  sx={{
                                    color: "#ff7200",
                                    "&.Mui-checked": {
                                      color: "#ff7200",
                                    },
                                  }}
                                  errors={errors}
                                  checked={permissions[key]?.value}
                                  onChange={(e) => {
                                    handleCheckboxChange(e, key);
                                  }}
                                  name={key}
                                />
                              }
                            />
                          </Grid>
                        );
                      }
                    })}
                </Grid>
              </Box>
              {/* {deviceRadio === "All" && (
                <Box width="100%" display="flex" justifyContent="center" mb={1.5}>
                  <Button
                    // disabled={!deviceSelected}
                    onClick={handleAddDevice}
                    variant="outlined"
                    size="small"
                    sx={{
                      ...buttonClasses.lynkitOrangeFill,
                      marginLeft: "5px",
                    }}
                  >
                    Add Device
                  </Button>
                </Box>
              )} */}
            </Grid>
          ) : null}

          {/* <Grid container sx={{ mb: 2, pl: 3, pr: 3 }} spacing={1}>
            {selectedDevice
              .slice(0, showAllDevice ? deviceSize : 5)
              .map((st, i) => {
                return (
                  <Grid item sm={3} xs={6} key={i}>
                    <Grid
                      container
                      direction={"row"}
                      justifyContent="center"
                      alignItems={"center"}
                      sx={{
                        backgroundColor: themeType.default.themeLightOrange,
                        border: `1px solid ${themeType.default.themeOrange}`,
                        borderRadius: "12px",
                        pl: 1,
                        pr: 1,
                        minHeight: "40px",
                      }}
                    >
                      <Grid item sm={10} xs={8}>
                        <Typography
                          sx={{
                            ...textClasses.normalText,
                            color: themeType.default.themeOrange,
                            textAlign: "start",
                          }}
                        >
                          {st?.clientId || "-"}
                        </Typography>
                      </Grid>
                      {handleRemoveSelection && (
                        <Grid item sm={2} xs={4}>
                          <IconButton
                            sx={{ alignContent: "right" }}
                            onClick={() => handleRemoveSelection(i, "device")}
                          >
                            <Cancel
                              sx={{ color: themeType.default.themeOrange }}
                            />
                          </IconButton>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                );
              })}
            {deviceSize > 5 && (
              <>
                <Grid
                  container
                  item
                  sm={4}
                  xs={6}
                  justifyContent="center"
                  alignItems={"center"}
                >
                  <Typography
                    sx={{
                      ...textClasses.boldText,
                      color: themeType.default.themeOrange,
                      textDecoration: "underline",
                      cursor: "pointer",
                    }}
                    onClick={() => setShowAllDevice(!showAllDevice)}
                  >
                    {showAllDevice ? (
                      <>Collapse</>
                    ) : (
                      <>View All ({deviceSize})</>
                    )}
                  </Typography>
                </Grid>
              </>
            )}
          </Grid> */}
          {/* {deviceRadio && deviceRadio !== "All" && (
          )} */}
        </Accordion>
      </Grid>
    </Grid>
  );
};

export default DeviceSection;
