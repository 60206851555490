import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button, Grid, Typography, FormControl, IconButton, FormControlLabel, Checkbox } from "@mui/material";
import CustomModel from "../../../Components/CustomModels/CustomModel1";
import ThreeDotsLoader from "../../../Components/ThreeDots/ThreeDotsLoader";
import { ToastContainer } from "react-toastify";
import { capitalizeSentence } from "../../../../services/functions";
import { getCamelCaseText } from "../../../../services/functions";

function DriverDetails(props) {
    const dispatch = useDispatch();
    const { message, error } = useSelector((state) => state.common);
    const { open, hide, driverDetails } = props;
    const isDarkThemeEnabledSelector = useSelector((state) => state.theme.themeDark);
    const themeType = isDarkThemeEnabledSelector ? require("../../../../static/styles/theme") : require("../../../../static/styles/theme");
    const { buttonClasses, inputClasses, textClasses, tableClasses } = themeType;
    return (
        <>
            <CustomModel
                autoClose={false}
                show={open || false}
                onClose={() => hide()}
                minWidth={650}
                maxWidth={650}
                pb={3}
                pl={3}
                pr={3}
                maxHeight={"80vh"}
                childrenMaxHeight={"80vh"}
                Title="Driver Details"
                viewCloseIcon={open}
            >
                <Grid container mb={2}>
                    <Grid
                        item
                        sm={12}
                        flexDirection={"column"}
                        sx={{ width: "100%", height: "auto" }}
                    >
                        <Grid mt={2} item sm={12} maxHeight="60vh" xs={12}
                            style={{
                                // overflowY: "auto",
                                overflow: 'auto',
                                overflowX: "hidden",
                                paddingBottom: "15px",
                            }}
                        >

                            <Grid container>
                                <Grid item md={12} mt={1} >
                                    <table style={{ ...tableClasses.table }}>
                                        <thead style={{ backgroundColor: themeType.default.themeGray }}>
                                            <tr>
                                                <td
                                                    style={{
                                                        ...textClasses.boldText,
                                                        ...tableClasses.tableHeader,
                                                        textAlign: "center",
                                                    }}
                                                >#
                                                </td>
                                                <td
                                                    style={{
                                                        ...textClasses.boldText,
                                                        ...tableClasses.tableHeader,
                                                        textAlign: "center"
                                                    }}
                                                >
                                                    Name
                                                </td>
                                                <td
                                                    style={{
                                                        ...textClasses.boldText,
                                                        ...tableClasses.tableHeader,
                                                        textAlign: "center",
                                                    }}
                                                >
                                                    Mobile Number
                                                </td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {driverDetails && driverDetails?.vehicleDetails && driverDetails?.vehicleDetails?.driver && driverDetails?.vehicleDetails?.driver?.map((item, index) => {
                                                return (
                                                    <tr key={index} >
                                                        <td
                                                            style={{
                                                                textAlign: "center",
                                                                ...textClasses.normalText,
                                                                ...tableClasses.dark,
                                                                textAlign: "center",
                                                            }}
                                                        >
                                                            {index + 1}
                                                        </td>
                                                        <td
                                                            style={{
                                                                textAlign: "center",
                                                                ...textClasses.normalText,
                                                                ...tableClasses.dark,
                                                                textAlign: "center"
                                                            }}
                                                        >
                                                            {item?.driverName || '-'}
                                                        </td>
                                                        <td
                                                            style={{
                                                                textAlign: "center",
                                                                ...textClasses.normalText,
                                                                ...tableClasses.dark,
                                                                textAlign: "center",
                                                            }}
                                                        >
                                                            {item?.mobileNo || '-'}
                                                        </td>
                                                    </tr>
                                                )
                                            })}


                                        </tbody>
                                    </table>

                                </Grid>

                            </Grid>










                        </Grid>
                    </Grid>
                </Grid>

            </CustomModel>
            <ToastContainer
                position="bottom-center"
                autoClose={false}
                closeOnClick={false}
            />
        </>
    );
}

export default DriverDetails;
