import { Paper, TablePagination, Grid, Box } from "@mui/material";
import React, { useEffect } from "react";
import ThreeDotsLoader from "../../../Components/ThreeDots/ThreeDotsLoader";
import { getBsjCameraAlerts, getTripSummary } from "../../../../redux/actions";
import { useDispatch } from "react-redux";
const DataTable = ({
  tableColumns,
  data,
  pagination,
  onRowChange = () => { },
  themeType,
  activeIndex,
  dynamicCols = false,
  dynamicHeaders = null,
  dynamicRows = null,
  tableactiveIndex,
  queryLoader,
  alertsLoader,
  paginationFilter,
  tripData,
  summarDetails,
  bsjCount,
  setPaginationFilter,
  id,
  cameraPlayback,
  eventPlayback,
  cameraAlertListing,
  setCameraAlertListing,
  eventsFilter,
  setEventsFilter,
  eventsCheck,
  tripId,
  propsShared,
  setErrMssg,
  setTrackData,
  summaryLoader=false,
  setSummaryLoader,
  eventsPagination,
  summaryData,
  setSummaryData,
}) => {
  const { textClasses, tableClasses } = themeType;
  const dispatch = useDispatch();


  useEffect(() => {
    if (id) {
      if (paginationFilter?.page || paginationFilter?.limit) {
        let temp = { ...paginationFilter };
        dispatch(getBsjCameraAlerts(
          {
            "startDate": new Date(tripData?.detailsDeviceID?.time),
            "endDate": tripData?.endTime ? new Date(tripData?.endTime) : new Date(),
            "deviceId": id,
            "tripId": tripData?._id || null,
            "page": temp?.page,
            "limit": temp?.limit,
          }, (res) => {
            if (res?.success) {
              setCameraAlertListing(res?.data)

            }
            else {
              setCameraAlertListing([])

            }
          }
        ))
      }
    }
  }, [paginationFilter?.page, paginationFilter?.limit]);

  return (
    <Paper
      className="muitable"
      sx={{
        maxWidth: "100%",
        height: "100%",
        overflowX: "auto",
        background: tableClasses.dark.backgroundColor,
      }}
      elevation={0}
    >
      {dynamicCols && dynamicHeaders ? (
        <table style={{ ...tableClasses.table }}>
          <thead
            style={{
              backgroundColor: themeType.default.themeGray,
              position: "sticky",
              top: "-1px",
              zIndex: 1,
            }}
          >
            <tr>
              {dynamicHeaders?.slice(0, 2).map((head, i) => {
                return (
                  <td
                    key={i}
                    style={{
                      ...textClasses.boldText,
                      ...tableClasses.tableHeader,
                      textAlign: "center",
                    }}
                  >
                    {head}
                  </td>
                );
              })}
              {dynamicHeaders
                ?.slice(2, dynamicHeaders.length)
                .map((head, i) => {
                  if (head !== null) {
                    return (
                      <td
                        key={i}
                        style={{
                          ...textClasses.boldText,
                          ...tableClasses.tableHeader,
                          textAlign: "center",
                        }}
                      >
                        {head}
                      </td>
                    );
                  }
                })}
            </tr>
          </thead>
          <tbody>
            {!summaryLoader ? (
              <Grid
                item={true}
                data-testid="loader"
                container
                direction={"column"}
                justifyContent={"center"}
                alignItems="center"
                xs={12}
                sx={{ p: 1 }}
              >
                <ThreeDotsLoader />
              </Grid>
            ) : (
              <>
                {dynamicRows.length > 0 ? (
                  <>
                    {dynamicRows.map((row, i) => (
                      <tr data-testid="tablerow" key={i}>
                        {row.slice(0, 2).map((col, j) => {
                          return (
                            <td
                              key={j}
                              data-testid="tablecell"
                              style={{
                                textAlign: "center",
                                ...textClasses.normalText,
                                ...tableClasses.dark,
                                ...tableClasses.tableBodyClass,
                                textAlign: "center",
                                // background : allDevices[i].device_status === "DISABLE" ? '1px solid #F0F0F0' :  allDevices[i].device_status === "REQUEST_FOR_REPAIRING" || allDevices[i].device_status === "UNDER_REPAIRING" || allDevices[i].device_status === "REPAIRED" ? '1px solid  #FF0000' :  '#fff'
                              }}
                            >
                              {col}
                            </td>
                          );
                        })}
                        {row.slice(2, row.length).map((col, j) => {
                          return (
                            <td
                              key={j}
                              style={{
                                textAlign: "center",
                                ...textClasses.normalText,
                                ...tableClasses.dark,
                                ...tableClasses.tableBodyClass,
                                textAlign: "center",
                                // color:'#fff',
                                // border : allDevices[i].device_status === "DISABLE" ? '1px solid #F0F0F0' :  allDevices[i].device_status === "REQUEST_FOR_REPAIRING" || allDevices[i].device_status === "UNDER_REPAIRING" || allDevices[i].device_status === "REPAIRED" ? '1px solid #FF0000' :  '#fff'
                              }}
                            >
                              {col}
                            </td>
                          );
                        })}
                      </tr>
                    ))}
                  </>
                ) : (
                  <tr
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <td
                      data-testid="nodata"
                      component="th"
                      scope="row"
                      style={{
                        ...textClasses.normalText,
                        textAlign: "left",
                        overflowWrap: "break-word",
                      }}
                    ></td>
                  </tr>
                )}
              </>
            )}
          </tbody>
        </table>
      ) : (
        <>
          {tableColumns && data && (
            <table style={{ ...tableClasses.table }}>
              <thead
                style={{
                  backgroundColor: themeType?.default?.themeGray,
                  position: "sticky",
                  top: "-1px",
                  zIndex: 1,
                }}
              >
                <tr className="roundedRow">
                  {tableColumns?.map((column, index) => {
                    return (
                      <td
                        key={index}
                        style={{
                          ...textClasses.boldText,
                          ...tableClasses.tableHeader,
                          textAlign: "center",
                          width: column?.width ?? "auto",
                        }}
                      >
                        {column?.header}
                      </td>
                    );
                  })}
                </tr>
              </thead>
              <tbody style={{ position: "relative" }}>
                {summaryLoader ? (
                  <tr>
                    <td
                      colSpan={tableColumns.length}
                      style={{ textAlign: "center", padding: "20px 0" }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "100%",
                        }}
                      >
                        <ThreeDotsLoader />
                      </div>
                    </td>
                  </tr>
                ) : (
                  <>
                    {queryLoader || alertsLoader ? (
                      <tr>
                        <td
                          colSpan={tableColumns.length}
                          style={{ textAlign: "center", padding: "20px 0" }}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              height: "100%",
                            }}
                          >
                            <div className="spinner" style={{ margin: "10px" }}>
                              <div className="bounce1"></div>
                              <div className="bounce2"></div>
                              <div className="bounce3"></div>
                            </div>
                          </div>
                        </td>
                      </tr>
                    ) : data?.length > 0 ? (
                      <>
                        {data?.map((entry, key) => (
                          <tr
                            onClick={() => onRowChange(entry)}
                            key={key}
                            style={{
                              borderBottom: "1px solid #0000002B",
                              background:
                                key === tableactiveIndex ? "#F5F5F5" : null,
                            }}
                            data-testid="table-row"
                          >
                            {tableColumns?.map((column, index) => (
                              <td
                                key={index}
                                style={{
                                  textAlign: "center",
                                  padding: "8px 8px",
                                  ...textClasses.normalText,
                                  ...tableClasses.dark,
                                  background:
                                    activeIndex === key ? "#F5F5F5" : null,
                                  width: column?.width ?? "auto",
                                }}
                              >
                                {column?.header === "#" ||
                                column?.header === "Action"
                                  ? column?.cell(entry, key)
                                  : column?.cell(entry, key)}
                              </td>
                            ))}
                          </tr>
                        ))}
                      </>
                    ) : (
                      <tr>
                        <td
                          colSpan={tableColumns.length}
                          style={{ textAlign: "center", padding: "20px 0" }}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              height: "100%",
                            }}
                          >
                            No Data Found.
                          </div>
                        </td>
                      </tr>
                    )}
                  </>
                )}
              </tbody>
            </table>
          )}
        </>
      )}

      {cameraAlertListing && cameraAlertListing?.length ? (
        <TablePagination
          component="div"
          count={bsjCount || 0}
          page={(paginationFilter.page || 1) - 1}
          onPageChange={(e, newPage) =>
            setPaginationFilter({ ...paginationFilter, page: newPage + 1 })
          }
          rowsPerPage={paginationFilter.limit}
          onRowsPerPageChange={(e) =>
            setPaginationFilter({
              ...paginationFilter,
              page: 1,
              limit: e.target.value,
            })
          }
          sx={{
            ...tableClasses.tableHeader,
            ...tableClasses.tablePagination,
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "sticky",
            bottom: "0px",
            mt: "0em",
            "& .MuiTablePagination-selectLabel ": {
              margin: "0",
            },
            "& .MuiTablePagination-displayedRows": {
              margin: "0",
            },
          }}
        />
      ) : null}

      {data && data?.length && !eventsCheck ? (
        <TablePagination
          component="div"
          count={summarDetails?.count || 0}
          page={(eventsFilter.page || 1) - 1}
          onPageChange={(e, newPage) => {
            setSummaryLoader(true);
            let payload = {
              projection: ["event"],
              trip_id: tripId,
              page: newPage + 1,
              limit: eventsFilter?.limit,
              dataType: eventsCheck ? "few" : "all",
              deviceId: propsShared,
            };
            console.log("eventsCheck", eventsCheck);
            if (eventsCheck) {
              delete payload?.page;
              delete payload?.limit;
            }
            dispatch(
              getTripSummary({ ...payload }, (res) => {
                if (!res.success) {
                  setErrMssg(res?.message);
                  setTrackData([]);
                }
                setSummaryLoader(false);
                setSummaryData({
                  ...summaryData,
                  trackEventData: res?.data?.trackEventData,
                  count: res?.data?.count,
                });
                setTrackData([...res?.data?.trackEventData]);
              })
            );
            setEventsFilter({ ...eventsFilter, page: newPage + 1 });
          }}
          rowsPerPage={eventsFilter.limit}
          onRowsPerPageChange={(e) => {
            setSummaryLoader(true);
            setEventsFilter({
              ...eventsFilter,
              page: 1,
              limit: e.target.value,
            });
            let payload = {
              projection: ["event"],
              trip_id: tripId,
              page: 1,
              limit: e.target.value,
              dataType: eventsCheck ? "few" : "all",
              deviceId: propsShared,
            };
            if (eventsCheck) {
              delete payload?.page;
              delete payload?.limit;
            }
            dispatch(
              getTripSummary({ ...payload }, (res) => {
                if (!res.success) {
                  setErrMssg(res?.message);
                  setTrackData([]);
                }
                setSummaryLoader(false);
                setSummaryData({
                  ...summaryData,
                  trackEventData: res?.data?.trackEventData,
                  count: res?.data?.count,
                });
                setTrackData([...res?.data?.trackEventData]);
              })
            );
          }}
          sx={{
            ...tableClasses.tableHeader,
            ...tableClasses.tablePagination,
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "sticky",
            bottom: "0px",
            mt: "0em",
            "& .MuiTablePagination-selectLabel ": {
              margin: "0",
            },
            "& .MuiTablePagination-displayedRows": {
              margin: "0",
            },
          }}
        />
      ) : null}
    </Paper>
  );
};

export default DataTable;
